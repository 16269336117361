<template>
<div>
      <div class="action-buttons">
        <a href="" v-on:click.prevent="viewApp=true" v-if="!viewApp">View</a>
        <a href="" v-on:click.prevent="uploadFile(signedApplicationField)" v-if="!viewApp">Upload signed</a>
        <a href="" v-on:click.prevent="viewApp=false" v-else>Back</a>
      </div>
      <div class="content-table-container file-list-container" v-if="!viewApp">
      <table class="file-list content-table">
        <thead>
          <tr>
            <th></th>
            <th>AgentID</th>
            <th>Submitted</th>
            <th>Status</th>
            <th>Legal Name</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><input type="radio" checked></td>
            <td>{{ item.agentId }}</td>
            <td>{{ formatDate(item.agentDb.createdAt, "YYYY-MM-DD") }}</td>
            <td>{{ item.agentDb.status }}</td>
            <td>{{ item.agentDb.name }}</td>
          </tr>
          <tr>
            <td colspan="3">
              <a target="_blank" :href="agentPdfUrl" style="display: block">Completed Agent Application</a>
            </td>
            <td colspan="3">
              <a target="_blank" :href="agentPdfUrl" style="display: block">{{ item.applicationId }} {{ item.agentDb.name }} {{ formatDate(item.agentDb.createdAt, "YYYY-MM-DD hh:mm") }}</a>
            </td>
          </tr>
          <tr v-for="signedDoc in signedApplications">
            <td colspan="3">
              <a target="_blank" :href="fileLink(signedDoc.filename, signedDoc.id)" style="display: block">Signed Agent Application</a>
            </td>
            <td colspan="3">
              <a target="_blank" :href="fileLink(signedDoc.filename, signedDoc.id)" style="display: block">{{ item.applicationId }} {{ item.agentDb.name }} {{ formatDate(signedDoc.created, "YYYY-MM-DD hh:mm") }}</a>
            </td>
          </tr>
        </tbody>
      </table>
      </div>
      <div class="app-details" v-else>
        <p><label>Agent ID:</label> {{ item.agentId }}</p>
        <p><label>Submitted Date:</label> {{ formatDate(item.agentDb.createdAt, "YYYY-MM-DD") }}</p>
        <p><label>Status:</label> {{ item.agentDb.status }}</p>
        <p><label>Legal Name:</label> {{ item.agentDb.name }}</p>
      </div>
</div>
</template>

<script>
export default {
  props: ["item"],
  data: ()=>({
    viewApp: false,
    agentPdfUrl: appData.pdfUrl,
  }),
  computed: {
    signedApplications() {
      var i=0;
      var result=[];
      var docs={};
      for(var j in this.item.agentDb.documents) {
        docs[this.item.agentDb.documents[j].type]=this.item.agentDb.documents[j];
      }
      while(docs['signedApplication'+(i?i:"")]) {
        result.push(docs['signedApplication'+(i?i:"")]);
        i++;
      }
      return result;
    },
    signedApplicationField() {
      var i=this.signedApplications.length;
      return "signedApplication"+(i?i:"");
    },
  },
  methods: {
    fileLink(fileName, docId) {
      if (!fileName)
        return null;
      if (docId)
        return "download/PartnerDocument/"+docId;
      return "files/agent/"+this.item.agentDb.id+"/"+fileName;
    },
    uploadFile(field=null) {
      if (field)
        this.selectedFile=field;
      var that=this;
      var input=$("<input />", {type: "file"});
      input.on("change", async function(e) {
        var file=this.files[0];
        startLoading();
        if (!window.validateFileExt(file))
          return stopLoading("Invalid file extension", "error");
        var id=(await api.create("PartnerDocument", {agentId: that.item.agentDb.id, type: that.selectedFile, created: "CURRENT_TIMESTAMP"})).id;
        if (id) {
          await api.upload("PartnerDocument", id, file);
          that.item.agentDb.documents=(await api.get("Partner", that.item.agentDb.id)).item.documents;
        }
        
        stopLoading("File uploaded");
        
      });
      input.click();
    },
  },
  mounted() {
  },
  mixins: [vueMixins.dateFormatter],
}
</script>
