<template>
<div>
  <div class="content-table-container resources dload">
    <table class="content-table resources dload">
      <thead>
        <tr>
          <th>Created</th>
          <th>User</th>
          <th>File Name</th>
          <th>Options</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items">
          <td class="text-left" style="width: 35%">
            {{ formatDate(item.created, 'MMMM D, YYYY [at] h:mm A z') }}
          </td>
          <td class="" style="width: 25%">
            <span class="username">{{ item.admin && item.admin.username }}</span>
          </td>
          <td class="" style="width: 30%">
            <a class="fname" target="_blank" :href="basepath+'/'+item.file_name">{{ item.file_name }}</a>
          </td>
          <td class="" style="width: 10%">
            <a class="remove" href="" v-on:click.prevent="deleteItem(index)">Delete</a>
          </td>
        </tr>
        <tr class="inend">
          <td colspan="4"></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
</template>

<script>
export default {
  data() { return {
    items: [],
    basepath: "",
  }},
  methods: {
    reload() {
      var that=this;
      api.list("Download", {}, ["id:ASC"]).then(function(data) {
        that.basepath=data.desc.file_name.basepath;
        that.items=JSON.parse(JSON.stringify(data.items));
      });
    },
    deleteItem: async function(index) {
      if (confirm("Are you sure?")) {
        startLoading();
        await api.delete("Download", this.items[index].id);
        stopLoading("Download item deleted");
        this.items.splice(index, 1);
      }
    },
  },
  mounted() {
    this.reload();
  },
  mixins: [vueMixins.dateFormatter],
}

</script>

