<template>
<div>
  <amadel-menu :page="currentPage" />
  
  <div class="ncontent touch-it">
    <div class="header split">
      <div>
        <ul class="breadcrumbs">
          <li><a>Lead Manager Overview</a></li>
        </ul>
        <div class="toolbar" />
        <form class="search-form">
          <h3>Search Options</h3>
          <div class="f2split">
            <div>
              <div class="field">
                <label>Date Range</label>
                <div class="field-group">
                  <input type="text" id="dp1569254393711" class="hasDatepicker">
                  <input type="text" id="dp1569254393712" class="hasDatepicker">
                </div>
              </div>
              <div class="field">
                <label>Trans ID</label>
                <input type="text">
              </div>
              <div class="field">
                <label>TID</label>
                <select>
                  <option value="">(all)</option>
                  <option value="2">Allium</option>
                  <option value="3">Acme Inc.</option>
                  <option value="4">Internal Agent</option>
                  <option value="5">David Collins Jr</option>
                  <option value="7">GBWEB</option>
                  <option value="14"></option>
                  <option value="15"></option>
                  <option value="20"></option>
                </select>
              </div>
              <div class="field">
                <label>Trans Type</label>
                <select>
                  <option value="">(all)</option>
                  <option value="A00">A00 - To be Assessed</option>
                  <option value="A01">A01 - Pre-Qualification Complete</option>
                  <option value="A02">A02 - New Application</option>
                  <option value="A03">A03 - In Process</option>
                  <option value="A04">A04 - Approved</option>
                  <option value="A05">A05 - Denied</option>
                  <option value="A06">A06 - Duplicate</option>
                </select>
              </div>
              <div class="field">
                <label>Entry Type</label>
                <select>
                  <option value="">(all)</option>
                  <option value="A00">A00 - To be Assessed</option>
                  <option value="A01">A01 - Pre-Qualification Complete</option>
                  <option value="A02">A02 - New Application</option>
                  <option value="A03">A03 - In Process</option>
                  <option value="A04">A04 - Approved</option>
                  <option value="A05">A05 - Denied</option>
                  <option value="A06">A06 - Duplicate</option>
                </select>
              </div>
            </div>
            <div>
              <div class="field">
                <label>Scheme</label>
                <select>
                  <option value="">(all)</option>
                  <option value="A00">A00 - To be Assessed</option>
                  <option value="A01">A01 - Pre-Qualification Complete</option>
                  <option value="A02">A02 - New Application</option>
                  <option value="A03">A03 - In Process</option>
                  <option value="A04">A04 - Approved</option>
                  <option value="A05">A05 - Denied</option>
                  <option value="A06">A06 - Duplicate</option>
                </select>
              </div>
              <div class="field">
                <label>Card</label>
                <input type="text">
              </div>
              <div class="field">
                <label>Ammount</label>
                <input type="text">
              </div>
              <div class="field">
                <label>Batch</label>
                <input type="text">
              </div>
              <div class="field">
                <label>Status</label>
                <select>
                  <option value="">(all)</option>
                  <option value="Pending">Pending</option>
                  <option value="Complete">Complete</option>
                  <option value="Expired">Expired</option>
                  <option value="Cancel">Cancel</option>
                  <option value="Resolved">Resolved</option>                
                </select>
              </div>
            </div>
          </div>
          <div class="buttons"><button class="btn btn-large btn-primary">Search</button></div>
        </form>
      </div>
      <aside>
        <h3>Summary Report</h3>
        <table class="breakdown-table">
          <thead>
            <tr>
              <th>Status</th>
              <th>Count</th>              
            </tr>
            <tr>
              <th colspan="2" class="spacer"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in list">
              <td>{{ item.name }}</td>
              <td>{{ item.data.length }}</td>             
            </tr>
          </tbody>
        </table>
      </aside>
    </div>
    <div class="hr"></div>
    <div class="content">
      <div>
        <p class="result-count">Your search returned <span>{{ data.length }}</span> result(s).</p>
        <ul class="acc-results">
          <li v-for="(item, type) in list" :key="type" :class="{ active: show[type] }">
            <h4 @click="show[type] = !show[type]">
              <span class="label">{{ item.name }}</span>
              <assign-unassign-buttons
                v-if="type === 'UNASSIGNED' && show[type]"
                v-model="bulkAssign"
                :allSelected="areAllSelected(type)"
                :anySelected="!!selected.length"
                @toggleSelectAll="toggleAll(type)"
                @assignSelected="assignSelected()" />
              <assign-unassign-buttons
                v-if="type === 'ASSIGNED' && show[type]"
                v-model="bulkUnassign"
                :allSelected="areAllSelected(type)"
                :anySelected="!!selectedUnassign.length"
                :assign="false"
                @toggleSelectAll="toggleAll(type)"
                @assignSelected="unassignLeads()" />
            </h4>
            <div v-show="show[type]" class="acc-list" style="display: block;">
              <table>
                <thead>
                  <tr>
                    <th>Source ID</th>
                    <th>Assigned</th>
                    <th>Lead File ID</th>
                    <th>Lead Type</th>
                    <th>Lead ID</th>
                    <th>Date/Time</th>
                    <th>Method Type</th>
                    <th>Business Name</th>
                    <th>Business DBA</th>
                    <th>Prov/State</th>
                    <th>Country</th>
                    <th>Status</th>
                    <th>Options</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="lead in item.data" :key="data.id"
                    :class="{ 'selectable-table': isSeelected(type, lead.id) }">
                    <td>{{ lead.SourceID.sourceId }}</td>
                    <td>{{ lead.assignedTo ? lead.assignedTo.agentId : 'Unassigned' }}</td>
                    <td>
                      <a v-if="lead.dataTransferFileId" class="link" @click="openPreviewLeadFile(type, lead)">
                        {{ lead.dataTransferFileId.id }}
                      </a>
                    </td>
                    <td>{{ lead.LeadType }}</td>
                    <td><a class="link" @click="preview(type, lead)">{{ formatLeadId(lead.id) }}</a></td>
                    <td>{{ lead.created }}</td>
                    <td>{{ lead.MethodType }}</td>
                    <td>{{ lead.BusinessName }}</td>
                    <td>{{ lead.BusinessDBA || 'N/A' }}</td>
                    <td>{{ lead.BusinessProvState || 'N/A' }}</td>
                    <td>{{ lead.BusinessCountry || 'N/A' }}</td>
                    <td>{{ lead.status }} - {{ getLeadStatus(lead.status) }}</td>
                    <td>
                      <template v-if="['UNASSIGNED', 'ASSIGNED'].includes(type)">
                        <div v-if="type === 'ASSIGNED' ? !bulkUnassign : !bulkAssign">
                          <a
                            class="btn btn-xxs"
                            @click="type === 'ASSIGNED' ? bulkUnassignLeads([lead]) : assignLeads = [lead]">
                            {{ type === 'ASSIGNED' ? 'Unassign' : 'Assign' }}
                          </a>
                        </div>
                        <a
                          v-else
                          class="btn btn-xxs"
                          @click="toggleSelect(type, lead.id)">
                          {{ isSeelected(type, lead.id) ? 'Unselect' : 'Select' }}
                        </a>
                      </template>                        
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <popup v-model="showPreviewLeadFileModal" @close="previewLeadFile = null">
    <div style="padding: 10px 30px 30px 30px; box-sizing: border-box;">
      <data-transfer-history :data="previewLeadFile" />
    </div>
  </popup>

  <lead-preview
    :lead="previewLead"
    @close="previewLead = null"
    @assign="assignLeads = [previewLead]"
    @unassign="bulkUnassignLeads([previewLead])"
    @edit="editLead($event)" />

  <assign-lead-modal :leads="assignLeads" @close="assignLeads = null" @assign="assigned($event)" />
</div>
</template>

<script>
export default {
  mixins: [vueMixins.helpers],

   data () {
    return {
      data: [],
      assignLeads: null,
      assignSelectedAssign: false,
      bulkAssign: false,
      bulkUnassign: false,
      selected: [],
      selectedUnassign: [],
      show: {
        UNASSIGNED: false,
        ASSIGNED: false,
        COMPLETED: false,
        INCOMPLETED: false,
        OTHER: false,
        ERROR: false
      },
      currentPage: {
        text: 'Lead Manager'
      },
      previewLead: null,
      previewLeadFile: null
    }
  },

   computed: {
     showPreviewLeadFileModal () {
       return !!this.previewLeadFile
     },

    unassignedList () {
      return this.data.filter(item => this.isUnassigned(item))
    },

    assignedList () {
      return this.data.filter(item => this.isAssigned(item))
    },

    completedList () {
      return this.data.filter(item => ['AMA20', 'AMA21', 'AMA22', 'AMA23', 'AMA24'].includes(item.status))
    },

    incompletedList () {
      return this.data.filter(item => ['AMA30', 'AMA31'].includes(item.status))
    },

    otherList () {
      return this.data.filter(item => ['AMA40', 'AMA41', 'AMA42', 'AMA43', 'AMA44'].includes(item.status))
    },

    errorList () {
      return this.data.filter(item => ['AMA50', 'AMA51'].includes(item.status))
    },

    list () {
      return {
        UNASSIGNED: {
          name: 'Unassigned Leads',
          data: this.unassignedList
        },
        ASSIGNED: {
          name: 'Assigned Leads',
          data: this.assignedList
        },
        COMPLETED: {
          name: 'Completed Leads',
          data: this.completedList
        },
        INCOMPLETED: {
          name: 'Incompleted Leads',
          data: this.incompletedList
        },
        OTHER: {
          name: 'Other',
          data: this.otherList
        },
        ERROR: {
          name: 'Error',
          data: this.errorList
        }
      }
    }
  },

  watch: {
    bulkAssign (val) {
      if (!val) {
        this.selected = []
      } else {
        alert('Please select leads to assign.')
      }
    },

    bulkUnassign (val) {
      if (!val) {
        this.selectedUnassign = []
      } else {
        alert('Please select leads to unassign.')
      }
    }
  },

  created() {
    this.getData()
  },

  methods: {
    isSeelected (type, id) {
      const list = type === 'UNASSIGNED' ? this.selected : this.selectedUnassign
      return list.includes(id)
    },

    areAllSelected (type) {
      return type === 'UNASSIGNED' ? this.selected.length === this.unassignedList.length : this.selectedUnassign.length === this.assignedList.length
    },

    isUnassigned (item) {
      return ['AMA00'].includes(item.status)
    },

    isAssigned (item) {
      return ['AMA10', 'AMA11', 'AMA12', 'AMA13'].includes(item.status)
    },

    unassignLeads () {
      this.bulkUnassignLeads(this.selectedUnassign.map(id => {
        return this.assignedList.find(lead => lead.id === id)
      }))
    },

    editLead (lead) {
      const index = this.data.findIndex(l => l.id === lead.id)
      this.$set(this.data, index, lead)

      if (this.previewLead) this.previewLead = this.data[index]
    },

    async bulkUnassignLeads (leads) {
      const leadIds = leads.map(lead => this.formatLeadId(lead.id)).join(', ')
      if(confirm(`Are you sure you want to unassign lead${leads.length > 1 ? 's' : ''} ${leadIds}?`)) {
        startLoading()
        for (const lead of leads) {
          const response = (await api.update('AmadelLead', lead.id, {
            assignedTo: null,
            status: 'AMA00'
          }, true)).item

          await this.createNote(lead.id, `Lead Unassigned from  ${lead.assignedTo.agentId}`)

          const index = this.data.findIndex(l => l.id === lead.id)
          this.$set(this.data, index, response)

          if (this.previewLead && this.previewLead.id === lead.id)
            this.previewLead = this.data[index]

          this.bulkUnassign = false
          this.selectedUnassign = []
        }
        stopLoading('Lead assigned successfully.')
      }
    },

    createNote (id, message) {
      const payload = {
        message,
        created: "CURRENT_TIMESTAMP",
        author: window.appData.currentUser.id,
        system: 1,
        type: 'Lead',
        parentId: id
      }

      return api.create("Note", payload)
    },

    getLeadStatus (status) {
      return window.leadStatuses[status]
    },

    assignSelected () {
      this.assignLeads = this.unassignedList.filter(item => this.selected.includes(item.id))
    },

    toggleAll (type) {
      if (type === 'UNASSIGNED')
        this.selected = (this.selected.length === this.unassignedList.length) ? [] : this.unassignedList.map(lead => lead.id)
      else
        this.selectedUnassign = (this.selectedUnassign.length === this.assignedList.length) ? [] : this.assignedList.map(lead => lead.id)
    },

    toggleSelect (type, id) {
      const list = type === 'UNASSIGNED' ? this.selected : this.selectedUnassign
      if (type === 'UNASSIGNED' ? !this.bulkAssign : !this.bulkUnassign) return
      const index = list.findIndex(i => i === id)
      if (index >= 0)
        list.splice(index, 1)
      else
        list.push(id)
    },

    assigned (leads) {
      this.assignLeads = null
      this.bulkAssign = false
      this.selected = []
      leads.forEach(lead => {
        const index = this.data.findIndex(l => l.id === lead.id)
        this.$set(this.data, index, lead)
        this.previewLead = (this.previewLead) ? this.data[index] : null
      })
    },

    openPreviewLeadFile (type, lead) {
      if (type === 'UNASSIGNED' ? this.bulkAssign : this.bulkUnassign) return
      this.previewLeadFile = [lead.dataTransferFileId]
    },

    preview (type, lead) {
      if (type === 'UNASSIGNED' ? this.bulkAssign : this.bulkUnassign) return
      this.previewLead = lead
    },

    async getData () {
      this.data = (await api.list('AmadelLead', {}, ["created:DESC"])).items
    }
  },
}
</script>
