<template>
<div :class="{grbackground: tab>0}">
  <div class="status" v-if="tab==0">
    <label>Current Status: <strong>{{ item.status }} ({{ statusDesc(item.status) }})</strong></label>
  </div>
  <form class="preq-form preq-view" v-if="tab==0">
    <section>
      <ul class="appview-list">
        <li>
          <label>Application ID</label>
          <div class="validator">
            <span>{{ item.applicationId }}</span>
          </div>
        </li>
        <li>
          <label>Legal Business Name</label>
          <div class="validator">
            <span>{{ item.companyName }}</span>
          </div>
        </li>
        <li>
          <label>Source / Agent</label>
          <div class="validator">
            <dyn-select v-if="editMode" :options="agentsToShow" v-model="item.sourceId"></dyn-select>
            <span v-else>{{ item.source && (item.source.agentId || "internal") || "internal" }}</span>
          </div>
        </li>
        <li>
          <label>Solution</label>
          <div class="validator">
            <dyn-select v-if="editMode" :options="solutions" v-model="item.solution"></dyn-select>
            <span v-else>{{ solution(item.solution) }}</span>
          </div>
        </li>
      </ul>
    </section>
    <div class="form-actions" v-if="editMode">
      <button class="btn btn-large" v-on:click.prevent="saveItem">Save</button>
    </div>
  </form>
  <form class="preq-form preq-view" v-else-if="tab==1">
    <section>
      <ul class="appview-list">
        <li>
          <label>New Business</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.companyNew==1?'Yes':'No' }}</span>
            <select v-else v-model="item.companyNew">
              <option value="0">No</option>
              <option value="1">Yes</option>
            </select>
          </div>
        </li>
        <li>
          <label>Legal Business Name</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.companyName }}</span>
            <input type="text" v-else v-model="item.companyName" />
          </div>
        </li>
        <li>
          <label>Trade Name/DBA</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.companyNameTrade }}</span>
            <input type="text" v-else v-model="item.companyNameTrade" />
          </div>
        </li>
        <li>
          <label>Address</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.companyAddress }}</span>
            <input type="text" v-else v-model="item.companyAddress" />
          </div>
        </li>
        <li>
          <label>City</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.companyAddressCity }}</span>
            <input type="text" v-else v-model="item.companyAddressCity" />
          </div>
        </li>
        <li>
          <label>Zip/Postal Code</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.companyAddressPostcode }}</span>
            <input type="text" v-else v-model="item.companyAddressPostcode" />
          </div>
        </li>
        <li>
          <label>State/Province</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.companyAddressState }}</span>
            <input type="text" v-else v-model="item.companyAddressState" />
          </div>
        </li>
        <li>
          <label>Country</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.companyAddressCountry }}</span>
            <input type="text" v-else v-model="item.companyAddressCountry" />
          </div>
        </li>
        <li>
          <label>Phone Number</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.companyPhone }}</span>
            <input type="text" v-else v-model="item.companyPhone" />
          </div>
        </li>
        <li>
          <label>Company Email</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.companyEmail }}</span>
            <input type="text" v-else v-model="item.companyEmail" />
          </div>
        </li>
        <li>
          <label>Business Start Date</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.companyDate }}</span>
            <datepicker v-else v-model="item.companyDate" />
          </div>
        </li>
      </ul>
    </section>
    <div class="form-actions" v-if="editMode">
      <button class="btn btn-large" v-on:click.prevent="saveItem">Save</button>
    </div>
  </form>
  <form class="preq-form preq-view" v-else-if="tab==2">
    <section>
      <ul v-for="(ubo, ui) in item.ubos" class="appview-list">
        <li>
          <label><strong>Owner {{ ui+1 }}</strong> First Name</label>
          <div class="validator">
            <span v-if="!editMode">{{ ubo.firstName }}</span>
            <input type="text" v-else v-model="ubo.firstName" />
          </div>
        </li>
        <li>
          <label><strong>Owner {{ ui+1 }}</strong> Last Name</label>
          <div class="validator">
            <span v-if="!editMode">{{ ubo.lastName }}</span>
            <input type="text" v-else v-model="ubo.lastName" />
          </div>
        </li>
        <li>
          <label><strong>Owner {{ ui+1 }}</strong> E-mail</label>
          <div class="validator">
            <span v-if="!editMode">{{ ubo.email }}</span>
            <input type="text" v-else v-model="ubo.email" />
          </div>
        </li>
        <li>
          <label><strong>Owner {{ ui+1 }}</strong> Phone</label>
          <div class="validator">
            <span v-if="!editMode">{{ ubo.phone }}</span>
            <input type="text" v-else v-model="ubo.phone" />
          </div>
        </li>
      </ul>
    </section>
    <div class="form-actions" v-if="editMode">
      <button class="btn btn-large" v-on:click.prevent="saveItem">Save</button>
    </div>
  </form>
  <form class="preq-form preq-view" v-else-if="tab==3">
    <section>
      <ul class="appview-list appl4">
        <li>
          <label>Website URL</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.website }}</span>
            <input type="text" v-else v-model="item.website" />
          </div>
        </li>
        <li>
          <label>Is Website Beta?</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.websiteBeta }}</span>
            <formel-toggle-date v-else v-model="item.websiteBeta" custom="" default="No" customLabel="No (If not when)" defaultLabel="Yes" />
          </div>
        </li>
        <li>
          <label>Username / Password</label>
          <div class="f2split">
            <div class="validator">
              <span v-if="!editMode">{{ item.websiteUsername }}</span>
              <input type="text" v-else v-model="item.websiteUsername" />
            </div>
            <div class="validator">
              <span v-if="!editMode">{{ item.websitePassword }}</span>
              <input type="text" v-else v-model="item.websitePassword" />
            </div>
          </div>
        </li>
        <li>
          <label>Full description of Products and/or Services</label>
          <div class="validator">
            <span v-if="!editMode" v-html="multilineText(item.businessDescription)"></span>
            <textarea v-else v-model="item.businessDescription"></textarea>
          </div>
        </li>
      </ul>
    </section>
    <div class="form-actions" v-if="editMode">
      <button class="btn btn-large" v-on:click.prevent="saveItem">Save</button>
    </div>
  </form>
  <form class="preq-form preq-view" v-else-if="tab==4">
    <section>
      <ul class="appview-list appl4">
        <li>
          <label>Mothly Limit Requesting</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.volume }}</span>
            <input type="text" v-else v-model="item.volume" />
          </div>
        </li>
        <li>
          <label>Highest Ticket</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.maxTransaction }}</span>
            <input type="text" v-else v-model="item.maxTransaction" />
          </div>
        </li>
        <li>
          <label>Average Ticket</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.avgTransaction }}</span>
            <input type="text" v-else v-model="item.avgTransaction" />
          </div>
        </li>
        <li>
          <label>Lowest Ticket</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.minTransaction }}</span>
            <input type="text" v-else v-model="item.minTransaction" />
          </div>
        </li>
        <li>
          <label>Are you currently processing?</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.acceptsCards==1?"Yes":"No" }}</span>
            <select v-else v-model="item.acceptsCards">
              <option value="0">No</option>
              <option value="1">Yes</option>
            </select>
          </div>
        </li>
        <li>
          <label>Have you previously processed?</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.acceptedCards==1?"Yes":"No" }}</span>
            <select v-else v-model="item.acceptedCards">
              <option value="0">No</option>
              <option value="1">Yes</option>
            </select>
          </div>
        </li>
        <li>
          <label>Do you offer an affiliate program?</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.affiliate==1?"Yes":"No" }}</span>
            <select v-else v-model="item.affiliate">
              <option value="0">No</option>
              <option value="1">Yes</option>
            </select>
          </div>
        </li>
        <li>
          <label>Terminated and/or placed on the TMF/Match List?</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.violationDetails=="0"?"No":item.violationDetails }}</span>
            <input type="text" v-else v-model="item.violationDetails" />
          </div>
        </li>
      </ul>
      <div class="appl-notes">
        <h4>
          Payment Information
        </h4>
      </div>
      <div class="validator appl-nd">
        <div class="f2split">
          <div>
            <label>E-Commerce % <span>(Internet)</span></label>
            <span v-if="!editMode">{{ item.methodECommerce }}</span>
            <input type="text" v-else v-model="item.methodECommerce" />
          </div>
          <div>
            <label>M-Commerce % <span>(Mobile Payments)</span></label>
            <span v-if="!editMode">{{ item.methodMCommerce }}</span>
            <input type="text" v-else v-model="item.methodMCommerce" />
          </div>
        </div>
        <div class="f2split">
          <div>
            <label>Card Present % <span>(Point of Sale)</span></label>
            <span v-if="!editMode">{{ item.methodPOS }}</span>
            <input type="text" v-else v-model="item.methodPOS" />
          </div>
          <div>
            <label>MOTO %</label>
            <span v-if="!editMode">{{ item.methodMOTO }}</span>
            <input type="text" v-else v-model="methodMOTO" />
          </div>
        </div>
        <div class="f2split">
          <div>
            <label>M-POS % <span>(Mobile POS)</span></label>
            <span v-if="!editMode">{{ item.methodMPOS }}</span>
            <input type="text" v-else v-model="item.methodMPOS" />
          </div>
          <div>
            <label>In-App Commerce %</label>
            <span v-if="!editMode">{{ item.methodApp }}</span>
            <input type="text" v-else v-model="item.methodApp" />
          </div>
        </div>
      </div>
    </section>
    <div class="form-actions" v-if="editMode">
      <button class="btn btn-large" v-on:click.prevent="saveItem">Save</button>
    </div>
  </form>
  <form class="preq-form" v-else-if="tab==5">
    <section>
      <div class="validator">
        <div class="single-field">
          <span v-if="!editMode" v-html="multilineText(item.additionalComments)"></span>
          <textarea v-else v-model="item.additionalComments"></textarea>
        </div>
      </div>
    </section>
    <div class="form-actions" v-if="editMode">
      <button class="btn btn-large" v-on:click.prevent="saveItem">Save</button>
    </div>
    <notes class="appl-notes" :notes="notes" type="Prequalification" :id="item.id" />
  </form>

</div>
</template>

<script>
export default {
  props: ["item", "tab", "notes", "editMode"],
  data() { return {
    now: 0,
    agents: appData.agents || [],
    solutions: [
      {value: "cardbiller-ca", label: "CardBiller - CA"},
    ],
  }},
  mounted() {
    this.solutions=appData.solutions;
    setInterval(()=>{ this.now++; }, 1000);
  },
  watch: {
    item: {
      immediate: true,
      handler() {
        if (this.item)
          this.$set(this.item, "sourceId", this.item.source && this.item.source.id);
      },
    },
  },
  methods: {
    solution(id) {
      for(var solution of this.solutions)
        if (id*1==solution.value*1)
          return solution.label;
      return "";
    },
    multilineText(text) {
      return (""+text).replace(/\</g, "&lt;")
                      .replace(/\>/g, "&gt;")
                      .replace(/\"/g, "&quot;")
                      .replace(/\&/g, "&amp;")
                      .replace(/\n/g, "<br />\n");
    },
    saveItem: async function() {
      startLoading();
      var updateItem=JSON.parse(JSON.stringify(this.item));
      updateItem.source=updateItem.sourceId;
      delete updateItem.sourceId;
      await api.update("Prequalification", this.item.id, updateItem);
      stopLoading("Prequalification updated");
      this.$emit("update:editMode", false);
      var resItem=(await api.get("Prequalification", this.item.id)).item;
      this.$parent.item.source=resItem.source;
      this.$parent.item.sourceId=resItem.source.id;
    },
    statusDesc(status) {
      for(var i in appData.statuses) {
        var xstatus=appData.statuses[i];
        if (xstatus.status==status)
          return xstatus.description;
      }
      return "";
    },
  },
  computed: {
    agentsToShow() {
      return this.agents.map(function(a) {
        var name=a.fullName;
        if (!name && a.contacts && a.contacts[0]) {
          name=a.contacts[0].firstName+" "+a.contacts[0].lastName;
        }
        var cn=a.companyName;
        if (!cn) {
          
        }
        return {
          value: a.id,
          label: (a.agentId || "")+" - "+name+" - "+cn,
        };
      });
    },
  },
}
</script>
