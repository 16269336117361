<template>
<div> 
  <panel title="Task List">
    <template v-slot:actions>
      <ch-button v-if="!showAddNewTask" @click="showAddNewTask = true">Add New Task</ch-button>
      <template v-else>
        <ch-button color="gray" @click="showAddNewTask = false">Cancel</ch-button>
        <ch-button @click="$refs.addTask.save()">Save New Task</ch-button>
      </template>
    </template>

    <transition name="slide">
      <add-task
        ref="addTask"
        v-show="showAddNewTask"
        :type="type"
        @save="onSave($event)" />    
    </transition>
    
    <span v-if="!tasks">Loading...</span>
    <ul
      v-else-if="tasks && tasks.length"
      class="list">
      <li v-for="(task, index) in tasks" class="list-item">
        <div class="display-inline mr-10">
          <ch-button v-if="task.done == 0" size="sm" color="success" @click="markAsDone(task.id, index)">&#10003;</ch-button>
          <ch-button v-if="!(isScheduler && task.createdBy.id !== userId)" size="sm" color="danger" @click="remove(task, index)">&#10006;</ch-button>
        </div>
        <span :class="{ 'striked-text': task.done == 1 }">
          {{ formatDate(task.date) }}
          <a v-if="task.employees.length" class="link"> - created by {{ task.createdBy.username }}</a>:
          <b>{{ task.text }}</b><br>
          <template v-if="task.employees.length">
            Associated Employees:
            <ul class="tags small">
              <li v-for="item in task.employees">{{ item.employee_id.first_name }} {{ item.employee_id.last_name }}
                <span v-if="task.createdBy.id === userId || item.employee_id.userId.id === userId" class="close" @click="removeEmployee(task, item)">x</span>
              </li>
            </ul>
          </template>
        </span>
      </li>
    </ul>
    <span v-else>There are no tasks.</span>
  </panel>
</div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      tasks: null,
      showAddNewTask: false,
    }
  },

  computed: {
    userId () {
      return window.appData.currentUser.id
    },

    isScheduler () {
      return this.type === 'SCHEDULER'
    }
  },

  created () {
    this.getData()
  },

  methods: {
    async removeEmployee (task, item) {
      const notTag = (task.createdBy.id === this.userId)
      const msg = notTag
        ? `Are you sure to remove ${item.employee_id.first_name} ${item.employee_id.last_name} from this task?`
        : 'Are you sure to remove this task from your scheduler?'
      if (confirm(msg)) {
        startLoading()
        await api.delete('TaskEmployee', item.id)
        if (notTag) {
          const idx = task.employees.findIndex(emp => emp.id === item.id)
          task.employees.splice(idx, 1)
        } else {
          const idx = this.tasks.findIndex(t => t.id === task.id)
          this.tasks.splice(idx, 1)
        }
        stopLoading('Tag removed successfully.')
      }
    },

    async markAsDone (id, index) {
      startLoading()
      await api.update('TaskList', id, { done: 1 })
      this.tasks[index].done = 1
      stopLoading('Task marked as done.')
    },

    async remove (task, index) {
      if (confirm(`Are you sure you want to delete this task: "${task.text}"?`)) {
        startLoading()
        await api.delete('TaskList', task.id)
        this.tasks.splice(index, 1)
        stopLoading('Task deleted successfully.')
      }
    },

    async getData () {
      startLoading()
      const today = moment().format('YYYY-MM-DD')
      const payload = window.appData.currentUser.groups !== 'admin-super' || this.isScheduler ? { createdBy: this.userId } : {}
      payload.type = this.type
      payload.done = ['=', 0]
      payload.date = ['>=', today]
      const future = (await api.list("TaskList", payload, ['date:ASC'])).items
      payload.date = ['<', today]
      const past = (await api.list('TaskList', payload, ['date:DESC'])).items

      const donePayload = {
        done: ['=', 1],
        type: this.type
      }
      if (payload.createdBy) {
        donePayload.createdBy = payload.createdBy
      }
      const done = (await api.list('TaskList', donePayload, ['date:DESC'])).items

      let doneShared = []
      let pastShared = []
      let furureShared = []
      if (this.isScheduler) {
        payload.createdBy = ['!=', this.userId]
        donePayload.createdBy = ['!=', this.userId]
        payload['employees.user_id'] = this.userId
        donePayload['employees.user_id'] = this.userId
        pastShared = (await api.list("TaskList", payload, ['date:ASC'])).items
        payload.date = ['>=', today]
        furureShared = (await api.list("TaskList", payload, ['date:ASC'])).items
        doneShared = (await api.list('TaskList', donePayload, ['date:DESC'])).items
      }

      this.tasks = [
        ...future,
        ...furureShared,
        ...past,
        ...pastShared,
        ...done,
        ...doneShared
      ]
      stopLoading()
    },

    onSave (task) {
      this.getData()
    },

    formatDate (date) {
      return moment(date).format('DD/MM/YYYY hh:mm A')
    }
  }
}
</script>
