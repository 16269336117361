import $ from 'jquery';

var sessionStorage=window.sessionStorage;

$(function($) {
  $(".side-menu>li>a").click(function(e) {
    e.preventDefault();
    $(this).parent().toggleClass("open").find(">ul").slideToggle(300);
    var openIndexes=[];
    $(".side-menu>li").each(function(i) {
      if ($(this).hasClass("open"))
        openIndexes.push(i);
    });
    sessionStorage.setItem("mainNavOpen", JSON.stringify(openIndexes));
  });

  $(".side-menu li.has-sub>a").click(function(e) {
    e.preventDefault();
    $(this).parent().toggleClass("open").find(">ul").slideToggle(300);
    var openIndexes=[];
    $(".side-menu>li").each(function(i) {
      if ($(this).hasClass("open")) {
        openIndexes.push(i);
        $(this).find(">ul>li.open").each(function(j) {
          openIndexes.push(i+"-"+j);
        });
      }
    });
    sessionStorage.setItem("mainNavOpen", JSON.stringify(openIndexes));
  });

  var opened=JSON.parse(sessionStorage.getItem("mainNavOpen") || "[]");
  for(var i in opened) {
    var j=(opened[i]+"").split("-");
    console.log(opened);
    if (j.length==2) {
      $(".side-menu>li:eq("+j[0]+")>ul>li:eq("+j[1]+")").addClass("open").find(">ul").show();
    } else
      $(".side-menu>li").eq(opened[i]).addClass("open").find(">ul").show();
  }
  
  
  
  $(".new-user").click(function() {
    userEdit.showPopup(null);
  });
  
  
  
  var generateTempLink=async function(type) {
    var result=await api.action("TempLink", "new", "generate", {type: type, solution: window.solutionSelect?window.solutionSelect.solution:"1"});
    var link=result.action.link;
    
    if (type=="Prequalification") {
      $(".link-gen-preq>div>input").val(link);
      $(".link-gen-preq").addClass("generated");
    } else if (type=="Application") {
      $(".link-gen-app.app>div>input").val(link);
      $(".link-gen-app.app").addClass("generated");      
    } else if (type=="Facilitator") {
      $(".link-gen-app.fac>div>input").val(link);
      $(".link-gen-app.fac").addClass("generated");      
    } else if (type=="Agent") {
      $(".link-gen-agent>div>input").val(link);
      $(".link-gen-agent").addClass("generated");      
    }
  };
  
  $(".link-gen-preq").click(function() {
    generateTempLink("Prequalification");
  });
  $(".link-gen-preq>div").click(function(e) {
    e.stopPropagation();
  });
  $(".link-gen-app.app").click(function() {
    generateTempLink("Application");
  });
  $(".link-gen-app.app>div").click(function(e) {
    e.stopPropagation();
  });
  $(".link-gen-app.fac").click(function() {
    generateTempLink("Facilitator");
  });
  $(".link-gen-app.fac>div").click(function(e) {
    e.stopPropagation();
  });
  $(".link-gen-agent").click(function() {
    generateTempLink("Agent");
  });
  $(".link-gen-agent>div").click(function(e) {
    e.stopPropagation();
  });
  $(".link-gen-agent>div>button, .link-gen-app>div>button, .link-gen-preq>div>button").click(function(e) {
    e.preventDefault();
    var input=$(this).parent().find("input")[0];
    
    input.focus();
    input.select();
    var successful=false;
    try {
      successful=document.execCommand('copy');
    } catch (err) {
    }
    if (successful) {
      stopLoading("Copied to clipboard", "success");
      this.hide();
    } else
      stopLoading("Failed to copy to clipboard", "error");
    
  });
  
  
  $(".profile-actions a:last-child").click(function() {
    sessionStorage.removeItem("mainNavOpen");
  });
  
});


window.startLoading=function(steps) {
  window.loadingSteps=steps;
  $(".loading").addClass("visible");
  if (steps)
    $(".loading").addClass("progress");
  else
    $(".loading").removeClass("progress");
  $(".loading .progress .step").html(1);
  $(".loading .progress .steps").html(steps);
  $(".loading .progress .bar").css("width", "0%").css("transition", "0s");
}

window.progressLoading=function(step) {
  $(".loading .progress .step").html(step+1);
  $(".loading .progress .bar").css("width", step*100/window.loadingSteps+"%").css("transition", ".3s");
}

window.stopLoading=function(message, status) {
  $(".loading").removeClass("visible");
  if (message)
    window.appNotifications.show(message, status || "success");
}

window.employeePortals = {
  hq: 'HQ'
}

window.leadStatuses = {
  AMA00: 'OPEN',
  AMA10: 'NEW',
  AMA11: 'APPTSET',
  AMA12: 'IN PROC',
  AMA13: 'CALL BK',
  AMA20: 'APPLY',
  AMA21: 'IN UW',
  AMA22: 'SIGNED',
  AMA23: 'DECLINE',
  AMA24: 'APPROVE',
  AMA30: 'NOT INT',
  AMA31: 'NOSTMNT',
  AMA40: 'DISC PH',
  AMA41: 'INVL PH',
  AMA42: 'INVLEML',
  AMA43: 'BUSSOLD',
  AMA44: 'BUSCLOS',
  AMA50: 'DUPLICATE',
  AMA51: 'ERROR',
}

window.userGroups={
  "admin-super": "Super Admin",
  "admin-system": "System Administrator",
  "admin": "Administrator",
  "analyst-admin": "Admin Analyst",
  "manager-compliance": "Compliance Manager",
  "analyst-risk": "Risk Analyst",
  "analyst-compliance": "Compliance Analyst",
  "finance": "Finance",
  "manager-operations": "Operations Manager",
  "manager-account": "Account Manager",
  "manager-relationship": "Relationship Manager",
  "support-partner": "Partner Support",
  "services-merchant": "Merchant Services",
  "manager-business": "Business Services Manager",
  "services-business": "Business Services",
//   "agent-inside": "Inside Agent",
//   "agent-outside": "Outside Agent",
  "support-tech": "Tech Support",
  "agent-iso": "Independent Sales Organization",
  "agent-isa": "Independent Sales Agent",
  "agent-auth": "Authorized Agent",
  "agent-ref": "Referral Partner",
  "agent-isv": "Integrated Software Vendor",
//   "agent-accex": "Account Executive",
//   "agent-sales": "Sales Associate",
  "merchant-master": "Master Merchant",
  "merchant": "Merchant",
  "senior-executive": "Senior Executive",
  "executive": "Executive",
};

window.employeeRoles = Object.keys(window.userGroups).filter(role => !['agent-iso', 'agent-isa', 'agent-auth', 'agent-ref', 'agent-accex', 'agent-sales', 'merchant-master', 'merchant'].includes(role))

window.userGroupList=[];
for(var i in window.userGroups)
  window.userGroupList.push({label: window.userGroups[i], value: i});

window.validateFileExt=function(file) {
  var whitelistedExtensions=[".jpg", ".jpeg", ".gif", ".tif", ".tiff", ".pdf", ".png"];
  if (!file || !file.name)
    return false;
  var matches=file.name.match(/\.[^.]+$/);
  if (!matches)
    return false;
  var ext=matches[0].toLowerCase();
  return whitelistedExtensions.indexOf(ext)!=-1;
}
