import jQuery from 'jquery';
window.$=jQuery;

import moment from 'moment';
window.moment=moment;

import Vue from 'vue';
window.Vue=Vue;

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

import MaestroCompat from './lib/maestro-compat';
Vue.use(MaestroCompat)

import PortalVue from 'portal-vue'
Vue.use(PortalVue)

import './lib/date-formatter';
import './lib/jquery-ui.min';
import './lib/amadel-lead-mixin';
import './lib/api';
import './lib/currency-mixin';
import './lib/common';
import './lib/extapi';
import './lib/helper-mixin';
import './lib/login';
import './lib/new-app-selector';
import './lib/user-profile';
import './lib/validation';
import './lib/countries';
import './lib/globals';

var VueComponents = {};

var vueComponentList = [
  require.context(".", false, /.+\.vue$/),
  require.context("./amadel", false, /.+\.vue$/),
  require.context("./apply-form", false, /.+\.vue$/),
  require.context("./bank-form", false, /.+\.vue$/),
  require.context("./calendar", false, /.+\.vue$/),
  require.context("./common", false, /.+\.vue$/),
  require.context("./compliance", false, /.+\.vue$/),
  require.context("./employees", false, /.+\.vue$/),
  require.context("./other-form", false, /.+\.vue$/),
  require.context("./support", false, /.+\.vue$/),
  require.context("./tasklist", false, /.+\.vue$/),
  require.context("./tools", false, /.+\.vue$/),
  require.context("./formfields", false, /.+\.vue$/),
  require.context("./onboarding", false, /.+\.vue$/),
  require.context("./business", false, /.+\.vue$/),
  require.context("./profile", false, /.+\.vue$/),
  require.context("./components", false, /.+\.vue$/),
  require.context("./components/layout", false, /.+\.vue$/),
  require.context("./treasury", false, /.+\.vue$/),
  require.context("./idv", false, /.+\.vue$/),
  require.context("./footer", false, /.+\.vue$/),
];

for(let vueComponentListItem of vueComponentList)
  for(let key of vueComponentListItem.keys()) {
    let actualKey=key.replace(/\.vue|.*\//g, "");
    let actualComponent=vueComponentListItem(key).default;
    VueComponents[actualKey]=actualComponent;
    Vue.component(actualKey, actualComponent);
  }

import './lib/vuedyn';
