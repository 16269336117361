<template>
<div class="sup-ticket">
  <form v-on:submit.prevent="createTicket">
    <h3>Create Support Ticket</h3>
    <div class="sf-row2">
    <div class="sf-row">
      <label>Account #</label>
      <input type="text" readonly v-model="user.agentId" />
    </div>
    <div class="sf-row">
      <label>Your Name</label>
      <input type="text" readonly :value="user.companyName || user.fullName || (user.contacts && user.contacts[0] && (user.contacts[0].firstName+' '+user.contacts[0].lastName))" />
    </div>
    </div>
    <div class="sf-row">
      <label>Your E-Mail</label>
      <input type="email" readonly :value="user.companyEmail || user.email || (user.agentDb && user.agentDb.email)" />
    </div>
    <div class="sf-row">
      <label>Subject</label>
      <dyn-select :options="subjOptions" v-model="subject" />
    </div>
    <div class="sf-row">
      <label>Description of Issue</label>
      <textarea v-model="newMessage"></textarea>
    </div>
    <div class="sf-row">
      <button>Submit Ticket</button>
    </div>
  </form>
  <div class="faq-box">
    <div>
      <div class="fq-title">Frequently Asked Questions</div>
      <a href="" class="fq-btn">View FAQ</a>
    </div>
  </div>
</div>
</template>

<script>
export default {
  mixins: [ vueMixins.helpers ],

  data() { return {
//     agentId: window.appData.agentId,
    user: window.currentUser,
    manager: window.managerUser,
    newMessage: "",
    subject: "",
    subjOptions: [
      {label: "Subject 1", value: 1},
      {label: "Subject 2", value: 2},
      {label: "Subject 3", value: 3},
      {label: "Subject 4", value: 4},
      {label: "Subject 5", value: 5},
      {label: "Other", value: 6},
    ],
  }},

  methods: {
    createTicket: async function() {
      startLoading();
      var ticketId=(await api.create("SupportTicket", {
        user: this.user.id,
        admin: this.manager.id,
        status: "Pending",
        archived: false,
        subject: this.subject,
      })).id;
      if (ticketId) {
        var id=(await api.create("SupportUpdate", {
          ticketId: ticketId,
          user: this.user.id,
          created: 'CURRENT_TIMESTAMP',
          message: this.newMessage,
        })).id;
      }
      this.newMessage="";
      this.subject="";
      stopLoading("Ticket created");
      if (window.supportUserList) {
        window.supportUserList.page=0;
        window.supportUserList.update();
      }
    },
  },

  created () {
    if (this.getUrl('amadel')) {
      this.subjOptions[0].label = 'Enroll - New Service'
      this.subject = 1
      this.newMessage = 'I would like to enroll to Amadel.'
    }
  }
}
</script>
