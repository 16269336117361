<template>
    <form class="search-form">
      <h3>Search Options</h3>
      <div class="f2split">
        <div>
          <div class="field">
            <label>Partner ID</label>
            <input type="text" v-model="search.merchantId" />
          </div>
          <div class="field">
            <label>Relationship Manager</label>
            <dyn-select v-model="search.manager" :options="managersOpt" />
          </div>
          <div class="field">
            <label>Status</label>
            <dyn-select v-model="search.status" :options="statusesOpt" />
          </div>
        </div>
        <div>
          <div class="field">
            <label>Company Name</label>
            <input type="text" v-model="search.companyName" />
          </div>
          <div class="field">
            <label>Email</label>
            <input type="text" v-model="search.email" />
          </div>
        </div>
      </div>

      <div class="f2split">
        <div class="buttons">
          <button class="btn btn-small btn-primary" v-on:click.prevent="updateResults">Search</button>
          <button class="btn btn-small btn-default" v-on:click.prevent="resetResults">Reset</button>
        </div>
        <div>
          &nbsp;
        </div>
      </div>
    </form>
</template>

<script>
export default {
  props: ["followup"],
  data() { return {
    search: {
      manager: "",
      status: "",
      merchantId: "",
      companyName: "",
      email: "",
    },
    statuses: [],
    managers: [],
  }; },
  computed: {
    statusesOpt() {
      var result=[{value: "", label: ""}];
      for(var i in this.statuses)
        result.push({
          value: this.statuses[i].status,
          label: this.statuses[i].status+" - "+this.statuses[i].description});
      return result;
    },
    managersOpt() {
      var result=[{value: "", label: ""}];
      for(var i in this.managers)
        result.push({
          value: this.managers[i].id,
          label: this.managers[i].fullName});
      return result;
    }
  },
  methods: {
    updateResults() {
      var q={}; // TODO
      q['agentDb.status']=["LIKE", "A%"];
      if (this.search.accountId) {
        q["agentDb.accountId"]=this.search.accountId;
      } else {
        if (this.search.merchantId)
          q["agentId"]=this.search.merchantId;
        if (this.search.manager)
          q["manager"]=this.search.manager;
       if (this.search.companyName)
         q["agentDb.name"]=["LIKE", "%"+this.search.companyName+"%"];
       if (this.search.email)
         q["agentDb.email"]=["LIKE", "%"+this.search.email+"%"];
        if (this.search.status)
          q['agentDb.status']=this.search.status;
      }
      var that=this;
      api.list("User", q, ["id:ASC"]).then(function(data) {
        accordeonResults.items=data.items.map(function(a) {
          a.type="Agent";
          for(var field in a.agentDb)
            if (a[field]===undefined)
              a[field]=a.agentDb[field];
          a.manager=that.managers.find(function(m) {
            return m.id==a.manager;
          });
          a.pendingApproval=a.agentDb.pendingApproval && a.status=='A02';
          return a;
        });
        breakdownTable.items=data.items;
      });
    },

    resetResults() {
      var q={}; // TODO
      q['agentDb.status']=["LIKE", "P%"];
      this.search = {
        manager: "",
          status: "",
          merchantId: "",
          companyName: "",
          email: "",
      }
      var that=this;
      api.list("User", q, ["id:ASC"]).then(function(data) {
        accordeonResults.items=data.items.map(function(a) {
          a.type="Agent";
          for(var field in a.agentDb)
            if (a[field]===undefined)
              a[field]=a.agentDb[field];
          a.manager=that.managers.find(function(m) {
            return m.id==a.manager;
          });
          a.pendingApproval=a.agentDb.pendingApproval && a.status=='A02';
          return a;
        });
        breakdownTable.items=data.items;
      });
    },
  },
  async created() {
    this.statuses = (await api.list('Status', {category: 'Profiles / Accounts'}, ["status:ASC"])).items;
    this.managers=JSON.parse(JSON.stringify(appData.managers || []));
    this.updateResults();
  },
}
</script>
