<template>
<button
  class="ch-button"
  :class="buttonClasses"
  :disabled="disabled"
  @click="$emit('click', $event)">
  <slot />
</button>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      validator: (value) => ['gray', 'white', 'primary', 'success', 'danger', 'warning'].includes(value),
      default: 'primary'
    },

    size: {
      type: String,
      validator: (value) => ['xs', 'sm', 'md', 'lg'].indexOf(value) !== -1,
      default: 'md'
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    buttonClasses () {
      const classes = []
      classes.push(`btn-${this.size}`)
      classes.push(`btn-${this.color}`)
      return classes
    }
  }
}
</script>
