<template>
<div>
  <div class="content-table-container" v-if="companyId" style="overflow: auto">
    <table class="content-table" style="width: 1000px">
      <thead>
        <tr>
          <th style="width: 8%">Brand ID</th>
          <th style="width: 8%">DBA</th>
          <th style="width: 8%">Website</th>
          <th style="width: 8%">Phone</th>
          <th style="width: 8%">Email</th>
          <th style="width: 8%">Address</th>
          <th style="width: 8%">City</th>
          <th style="width: 8%">State</th>
          <th style="width: 8%">Zip</th>
          <th style="width: 8%">Country</th>
          <th style="width: 1%">&nbsp;</th>
        </tr>
        <tr>
          <th class="spacer" colspan="4"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items">
          <td class="text-left main-col">
            <input type="text" v-model="item.brandId" />
          </td>
          <td class="text-left">
            <input type="text" v-model="item.dba" />
          </td>
          <td class="text-left">
            <input type="text" v-model="item.website" />
          </td>
          <td class="text-left">
            <input type="text" v-model="item.phone" />
          </td>
          <td class="text-left">
            <input type="text" v-model="item.email" />
          </td>
          <td class="text-left">
            <input type="text" v-model="item.address" />
          </td>
          <td class="text-left">
            <input type="text" v-model="item.city" />
          </td>
          <td class="text-left">
            <input type="text" v-model="item.state" />
          </td>
          <td class="text-left">
            <input type="text" v-model="item.zip" />
          </td>
          <td class="text-left">
            <input type="text" v-model="item.country" />
          </td>
          <td>
            <a class="remove" href="" v-on:click.prevent="deleteItem(index)">&times;</a>
          </td>
        </tr>
        <tr class="new-row">
          <td colspan="11">
            <a class="add" href="" v-on:click.prevent="newItem">+</a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <!--<div class="buttons">
    <button class="btn btn-large btn-primary" v-on:click.prevent="updateStatusCodes">Submit</button>
  </div>-->
</div>
</template>

<script>
export default {
  props: ["companyId"],
  data() { return {
    items: [],
    origItems: [],
    categories: [],
  }},
  methods: {
    reload() {
      if (!this.companyId) {
        this.origItems=[];
        this.items=[];
        this.categories=[];
        return;
      }
      var that=this;
      api.list("SalesBrand", {company: this.companyId}, ["`brandId`:ASC"]).then(function(data) {
        that.origItems=JSON.parse(JSON.stringify(data.items));
        that.items=JSON.parse(JSON.stringify(data.items));
        that.categories=[];
        for(var i in that.items) {
          var item=that.items[i];
          if (that.categories.indexOf(item.category)==-1)
            that.categories.push(item.category);
        }
        that.categories.sort();
      });
    },
    deleteItem(index) {
      var that=this;
      if (confirm("Are you sure?")) {
        that.items.splice(index, 1);
      }
    },
    newItem() {
      this.items.push({
        status: "",
        category: "",
        description: "",
        company: this.companyId,
      });
    },
    validate() {
      var statuses={};
      for(var i in this.items) {
        if (statuses[this.items[i].brandId])
          return false;
        statuses[this.items[i].brandId]=true;
      }
      
      return true;
    },
    updateStatusCodes() {
      if (!this.validate()) {
        stopLoading("Duplicate brand IDs detected", "error");
        return false;
      }
      var itemsToDelete=[];
      for(var index in this.origItems) {
        var item=this.origItems[index];
        if (!this.items.find(function(e) { return e.id==item.id; }))
          itemsToDelete.push(item);
      }
      var itemsToAdd=[];
      var itemsToUpdate=[];
      for(var index in this.items) {
        var item=this.items[index];
        var foundItem=this.origItems.find(function(e) { return e.id==item.id; });
        if (!foundItem)
          itemsToAdd.push(item);
        else if (JSON.stringify(foundItem)!=JSON.stringify(item))
          itemsToUpdate.push(item);
      }
      this.updateStatusApi(itemsToUpdate, itemsToAdd, itemsToDelete);
      this.origItems=JSON.parse(JSON.stringify(this.items));
      return true;
    },
    updateStatusApi: async function(update, add, remove) {
//       startLoading();
      for(var i in update) {
        var item=update[i];
        await api.update("SalesBrand", item.id, item);
      }
      for(var i in add) {
        var item=add[i];
        await api.create("SalesBrand", item);
      }
      for(var i in remove) {
        var item=remove[i];
        await api.delete("SalesBrand", item.id);
      }
//       stopLoading("Updated sales brands");
    },
  },
  watch: {
    companyId() {
      this.reload();
    },
  },
  mounted() {
    this.reload();
  },
}
</script>

