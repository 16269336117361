<template>
<div class="element-checkbox-line">
  <label :class="{checked: value}">
    <input type="checkbox" v-model="actualValue" v-on:change="update" />
    <span></span>
  </label>
  <div class="title" v-on:click.prevent="more=!more">
    <slot />
    <div class="more" v-show="more">
      <slot name="more"></slot>  
    </div>
  </div>
  <div class="toggleMore" :class="{opened: more}" v-on:click.prevent="more=!more">
    <a href=""></a>
  </div>
</div>
</template>

<script>
export default {
  props: ["value"],
  data() { return {
    actualValue: false,
    more: false,
  }},
  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        this.actualValue=newVal==1?true:false;
      },
    },
  },
  methods: {
    update($event) {
      this.$emit('input', this.actualValue?1:0);
    },
  },
}
</script>
