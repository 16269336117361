<template>
<form class="edit-system search-form" v-if="(isNew || itemId) && !newComplete">
  <div class="twocol">
    <div>
      <div class="field">
        <label>Solution Prefix:</label>
        <input v-model="item.solutionAccountPrefix"
               oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
               type = "number"
               maxlength = "6"
               minlength = "1"
               class="pin-code"
               required
               :disabled="!isNew && !editable"
        />
      </div>
      <div class="field">
        <label>Solution Nickname:</label>
        <input v-model="item.nameSolution" :disabled="!isNew && !editable" />
      </div>
      <div class="field">
        <label>Solution Name:</label>
        <input v-model="item.name" :disabled="!isNew && !editable" />
      </div>
      <div class="field">
        <label>Solution DBA Name:</label>
        <input v-model="item.nameDba" :disabled="!isNew && !editable" />
      </div>
      <div class="field">
        <label>Solution Address:</label>
        <input v-model="item.address" :disabled="!isNew && !editable" />
      </div>
      <div class="field">
        <label>Solution City:</label>
        <input v-model="item.city" :disabled="!isNew && !editable" />
      </div>
      <div class="field">
        <label>Solution State:</label>
        <input v-model="item.state" :disabled="!isNew && !editable" />
      </div>
      <div class="field">
        <label>Solution Zip/Postal Code:</label>
        <input v-model="item.postcode" :disabled="!isNew && !editable" />
      </div>
<!--      <div class="field">
        <label>Fax:</label>
        <input v-model="item.fax" />
      </div>-->
      <div class="field">
        <label>Send-From Email:</label>
        <input v-model="item.sendFrom" :disabled="!isNew && !editable" />
      </div>
      <div class="field" v-if="type=='Employees'">
        <label>Email Suffix:</label>
        <input v-model="item.emailSuffix" :disabled="!isNew && !editable" />
      </div>
      <div class="field">
        <label>Logo:</label>
        <photo-obj :basepath="basePath" v-model="logoFile" :disabled="!isNew && !editable" />
      </div>
    </div>
    <div>
<!--      <div class="field">
        <label>Telephone:</label>
        <input v-model="item.telephone" />
      </div>-->
      <div class="field">
        <label>Solution Support Telephone?:</label>
        <input v-model="item.supportTel" :disabled="!isNew && !editable" />
      </div>
      <div class="field">
        <label>Solution Email:</label>
        <input v-model="item.email" :disabled="!isNew && !editable" />
      </div>
<!--      <div class="field">
        <label>Business Services Email:</label>
        <input v-model="item.businessServicesEmail" />
      </div>-->
      <div class="field">
        <label>Solution Support Email:</label>
        <input v-model="item.supportEmail" :disabled="!isNew && !editable" />
      </div>
      <div class="field">
        <label>Solution Website:</label>
        <input v-model="item.website" :disabled="!isNew && !editable" />
      </div>
      <div class="field">
        <label>Solution Slogan:</label>
        <input v-model="item.slogan" :disabled="!isNew && !editable" />
      </div>
      <template v-if="type=='Merchants'">
        <div class="field">
          <label>Qualification Link:</label>
          <a target="_blank" :href="pubLink+'-q'">{{ pubLink+'-q' }}</a>
        </div>
        <div class="field">
          <label>Application Link:</label>
          <a target="_blank" :href="pubLink+'-m'">{{ pubLink+'-m' }}</a>
        </div>
      </template>
      <template v-if="type=='Banking'">
        <div class="field">
          <label>Application Link:</label>
          <a target="_blank" :href="pubLink+'-t'">{{ pubLink+'-t' }}</a>
        </div>
      </template>
      <template v-if="type=='Partners'">
        <div class="field">
          <label>Application Link:</label>
          <a target="_blank" :href="pubLink+'-p'">{{ pubLink+'-p' }}</a>
        </div>
      </template>
    </div>
  </div>
  
  <edit-payment-opts2 :item="item" :isNew="isNew" :editable="editable" ref="payOpts" v-if="type=='Merchants'" :key="item.id || 'new'" />
  <edit-payment-opts3 :item="item" :isNew="isNew" :editable="editable" ref="payOpts" v-if="type=='Banking'" :key="item.id || 'new'" />

<!--   <sales-brands-edit ref="brandsEdit" :companyId="item && item.id"></sales-brands-edit> -->
  
  
  <h2 v-if="type=='Partners'">Agent signup form</h2>
  <div class="twocol" v-if="type=='Partners'">
    <div>
      <div class="field">
        <label>Header Assistance Text:</label>
        <input v-model="item.agentHeadText" :disabled="!isNew && !editable" />
      </div>
      <div class="field">
        <label>Header Assistance Email:</label>
        <input v-model="item.agentHeadEmail" :disabled="!isNew && !editable" />
      </div>
      <div class="field">
        <label>Header Assistance Phone:</label>
        <input v-model="item.agentHeadPhone" :disabled="!isNew && !editable" />
      </div>
    </div>
    <div>
      <div class="field">
        <label>Header Logo:</label>
        <photo-obj :basepath="basePath" v-model="agentHeadLogo" :disabled="!isNew && !editable" />
      </div>
    </div>
  </div>
  <div class="field" v-if="type=='Partners'">
    <label style="width: calc(16% - 10px)">Header Description:</label>
    <textarea v-model="item.agentHeadDesc" style="width: 84%" :disabled="!isNew && !editable"></textarea>
  </div>
  
  <template v-if="type=='Merchants'">
    <h2>Merchant application form</h2>
    <div class="twocol">
      <div>
        <div class="field">
          <label>Header Assistance Text:</label>
          <input v-model="item.appHeadText" :disabled="!isNew && !editable" />
        </div>
        <div class="field">
          <label>Header Assistance Email:</label>
          <input v-model="item.appHeadEmail" :disabled="!isNew && !editable"  />
        </div>
        <div class="field">
          <label>Header Assistance Phone:</label>
          <input v-model="item.appHeadPhone" :disabled="!isNew && !editable" />
        </div>
      </div>
      <div>
        <div class="field">
          <label>Header Logo:</label>
          <photo-obj :basepath="basePath" v-model="appHeadLogo" :disabled="!isNew && !editable" />
        </div>
      </div>
    </div>
    <div class="field">
      <label style="width: calc(16% - 10px)">Header Description:</label>
      <textarea v-model="item.appHeadDesc" style="width: 84%"   :disabled="!isNew && !editable"></textarea>
    </div>
  </template>
  <template v-if="type=='Banking'">
    <h2>Treasury application form</h2>
    <div class="twocol">
      <div>
        <div class="field">
          <label>Header Assistance Text:</label>
          <input v-model="item.appHeadText" :disabled="!isNew && !editable" />
        </div>
        <div class="field">
          <label>Header Assistance Email:</label>
          <input v-model="item.appHeadEmail" :disabled="!isNew && !editable" />
        </div>
        <div class="field">
          <label>Header Assistance Phone:</label>
          <input v-model="item.appHeadPhone" :disabled="!isNew && !editable" />
        </div>
      </div>
      <div>
        <div class="field">
          <label>Header Logo:</label>
          <photo-obj :basepath="basePath" v-model="appHeadLogo" :disabled="!isNew && !editable" />
        </div>
      </div>
    </div>
    <div class="field">
      <label style="width: calc(16% - 10px)">Header Description:</label>
      <textarea v-model="item.appHeadDesc" style="width: 84%"  :disabled="!isNew && !editable"></textarea>
    </div>
  </template>
  <template v-if="type=='Merchants'">
    <h2>Merchant prequalification form</h2>
    <div class="twocol">
      <div>
        <div class="field">
          <label>Header Assistance Text:</label>
          <input v-model="item.preqHeadText" :disabled="!isNew && !editable" />
        </div>
        <div class="field">
          <label>Header Assistance Email:</label>
          <input v-model="item.preqHeadEmail" :disabled="!isNew && !editable" />
        </div>
        <div class="field">
          <label>Header Assistance Phone:</label>
          <input v-model="item.preqHeadPhone" :disabled="!isNew && !editable" />
        </div>
      </div>
      <div>
        <div class="field">
          <label>Header Logo:</label>
          <photo-obj :basepath="basePath" v-model="preqHeadLogo" :disabled="!isNew && !editable" />
        </div>
      </div>
    </div>
    <div class="field">
      <label style="width: calc(16% - 10px)">Header Description:</label>
      <textarea v-model="item.preqHeadDesc" style="width: 84%" :disabled="!isNew && !editable"></textarea>
    </div>
  </template>
  <template v-if="type=='Merchants'">
    <h2>Merchant Profile</h2>
    <div class="twocol">
      <div>
        <div class="field">
          <label>Header Assistance Text:</label>
          <input v-model="item.intakeHeadText" :disabled="!isNew && !editable" />
        </div>
        <div class="field">
          <label>Header Assistance Email:</label>
          <input v-model="item.intakeHeadEmail" :disabled="!isNew && !editable" />
        </div>
        <div class="field">
          <label>Header Assistance Phone:</label>
          <input v-model="item.intakeHeadPhone" :disabled="!isNew && !editable" />
        </div>
      </div>
      <div>
        <div class="field">
          <label>Header Logo:</label>
          <photo-obj :basepath="basePath" v-model="intakeHeadLogo" :disabled="!isNew && !editable" />
        </div>
      </div>
    </div>

    <div class="field">
      <label style="width: calc(16% - 10px)">Header Description:</label>
      <textarea v-model="item.intakeHeadDesc" style="width: 84%" :disabled="!isNew && !editable"></textarea>
    </div>
  </template>
    
    <!-- <h2>Agent Setup</h2>
    <div v-if="type=='Banking'" class="field">
      <label>Agent setup form template:</label>
      <dyn-select v-model="item.setupTemplate" :options="setupTemplates" />
    </div> -->
    
    
    <div class="buttons">
      <template v-if="isNew">
        <button class="btn btn-large btn-primary" v-on:click.prevent="updateSettings">Save</button>
      </template>
      <template v-else>
        <button v-if="!editable" class="btn btn-large btn-primary" v-on:click.prevent="editable = true">Update</button>
        <button v-else class="btn btn-large btn-primary" v-on:click.prevent="updateSettings">Save</button>
      </template>
    </div>  
  </form>
  <div v-else-if="newComplete" style="text-align: center">
    (refreshing)
  </div>
  <div v-else style="text-align: center">
    (no solution selected)
  </div>
</template>

<script>
export default {
  props: ["itemId", "isNew", "type"],
  data() { return {
    item: {},
    editable: false,
    newComplete: false,
    logoFile: null,
    agentHeadLogo: null,
    appHeadLogo: null,
    preqHeadLogo: null,
    intakeHeadLogo: null,
    basePath: "",
//     itemId: 0,
    setupTemplates: [
      {value: "ca", label: "Cardbiller CA"},
    ],
  }},
  mounted() {
    this.refresh();
    window.companyEdit=this;
  },
  watch: {
    itemId() {
      this.refresh();
    },
  },
  methods: {
    refresh() {
      this.item={};
      if (this.itemId) {
        var that=this;
        api.get("Solution", this.itemId).then(function(data) {
          that.item=data.item;
          that.logoFile=data.item.file_name;
          that.basePath=data.desc.file_name.basepath;
          that.agentHeadLogo=data.item.agentHeadLogo;
          that.appHeadLogo=data.item.appHeadLogo;
          that.preqHeadLogo=data.item.preqHeadLogo;
          that.intakeHeadLogo=data.item.intakeHeadLogo;
        });
      } else {
        this.item={};
        this.logoFile=null;
      }
    },
    updateSettings: async function() {
      startLoading();
      if(!this.item.solutionAccountPrefix) {
        return stopLoading("Please input Solution Prefix", "error");
      }
      var newItem=JSON.parse(JSON.stringify(this.item));
      delete newItem.file_name;
      if (this.logoFile===null)
        newItem.file_name=null;
      var itemId=this.itemId;
      if (itemId)
        await api.update("Solution", this.itemId, newItem);
      else {
        newItem.solutionType=this.type;
        itemId=(await api.create("Solution", newItem)).id;
      }

      if (this.logoFile instanceof File) {
        if (!window.validateFileExt(this.logoFile))
          return stopLoading("Invalid file extension", "error");
        await api.upload("Solution", itemId, this.logoFile);
      }

      if (this.agentHeadLogo instanceof File) {
        if (!window.validateFileExt(this.agentHeadLogo))
          return stopLoading("Invalid file extension", "error");
        await api.upload("Solution", itemId, this.agentHeadLogo, {field: "agentHeadLogo"});
      }

      if (this.appHeadLogo instanceof File) {
        if (!window.validateFileExt(this.appHeadLogo))
          return stopLoading("Invalid file extension", "error");
        await api.upload("Solution", itemId, this.appHeadLogo, {field: "appHeadLogo"});
      }

      if (this.preqHeadLogo instanceof File) {
        if (!window.validateFileExt(this.preqHeadLogo))
          return stopLoading("Invalid file extension", "error");
        await api.upload("Solution", itemId, this.preqHeadLogo, {field: "preqHeadLogo"});
      }

      if (this.intakeHeadLogo instanceof File) {
        if (!window.validateFileExt(this.intakeHeadLogo))
          return stopLoading("Invalid file extension", "error");
        await api.upload("Solution", itemId, this.intakeHeadLogo, {field: "intakeHeadLogo"});
      }
      

//       if (!this.$refs.brandsEdit.updateStatusCodes())
//         return;
      this.editable = false
      stopLoading("Solution information updated");
      if (this.itemId) {
        if (window.companySelect) {
          await window.companySelect.refresh();
  //         window.companySelect.selectCompany(itemId);
        }
      } else {
        this.newComplete=true;
        setTimeout(()=>{
          location.replace(location.href);
        }, 2000);
      }
      this.refresh();
    },
  },
  computed: {
    pubLink() {
      var solModId=(this.itemId%4096)^2650;
      var solVal=97-solModId%97;
      var solCode=solVal.toString(16).padStart(2, "0")+
                  solModId.toString(16).padStart(3, "0");
      return location.origin+"/register/"+solCode;
    },
  },
}
</script>
