<template>
      <div>
        <div class="action-sub-buttons" v-if="!uwShown">
          <a class="btn" v-on:click="newFinalCheck" :disabled="item.agentDb.status!='A04'">Start</a>
          <a class="btn" v-if="uwSelected" v-on:click.prevent="doShowItems(uwSelected)">View</a>
        </div>
        <div class="action-sub-buttons" v-else>
<!--           <a class="btn" v-on:click="uwShown=null">Back</a> -->
<!--           <a class="btn" :href="printUrl" target="_blank">Print</a> -->
<!--           <a style="margin-left: auto" class="btn" v-show="animFinished" :class="{disabled: !passed}" v-on:click="passed && doFinal()">Final Approval</a> -->
        </div>
        <table class="file-list content-table" v-if="false && !uwShown">
          <thead>
            <tr>
              <th style="width: 1%"></th>
              <th>ID</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(uwItem, uwI) in uwList">
              <td><input type="radio" v-model="uwSelected" :value="uwItem.id"></td>
              <td>{{ uwList.length-uwI }}</td>
              <td>{{ formatDate(uwItem.created, "YYYY-MM-DD HH:mm") }}</td>
            </tr>
          </tbody>
        </table>
        <div class="pre-check-details" v-else-if="uwShown" ref="detailsBox">
          <div class="t2precheck">
            <div class="t2sect">
              <div class="t2box">
                <h3>General Information</h3>
                <ul>
                  <li>
                    <div class="field" :class="{visible: shownItem>4}">
                      <label>Solution:</label>
                      <span v-if="item.agentDb.solutions">{{ findSolution(item.agentDb.solutions) }}</span>
                      <span class="empty" v-else>NO</span>
                    </div>
                  </li>
                  <li>
                    <label>Status:</label>
                    <span>{{ item.agentDb.status }}</span>
                  </li>
                  <li>
                    <label>Type:</label>
                    <span v-if="item.groups">{{ groups[item.groups] }}</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                  <li>
                    <label>Tier:</label>
                    <span v-if="item.agentDb.agentClass">{{ item.agentDb.agentClass }}</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                  <li>
                    <label>Relationship Manager:</label>
                    <span v-if="uwShownItem.manager">{{ uwShownItem.manager.fullName }}</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                  <li>
                    <label>Referral Source:</label>
                    <span v-if="item.agentDb.referrerSite">{{ item.agentDb.referrerSite }}</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                  <li>
                    <label>Langauges:</label>
                    <span v-if="uwShownItem.language">{{ uwShownItem.language }}</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                  <li v-if="false">
                    <label>Sales Brand:</label>
                    <span v-if="item.agentDb.salesBrand">{{ salesBrandForId(item.agentDb.salesBrand).dba }}</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="t2sect">
              <div class="t2box">
                <h3>Company Information</h3>
                <ul>
                  <li>
                    <label>Legal Name:</label>
                    <span v-if="item.agentDb.name">{{ item.agentDb.name }} {{ item.agentDb.companyType }}</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                  <li>
                    <label>DBA:</label>
                    <span v-if="item.agentDb.nameTrade">{{ item.agentDb.nameTrade }}</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                  <li>
                    <label>Address:</label>
                    <span v-if="item.agentDb.address">{{ item.agentDb.address }}</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                  <li>
                    <label>Country:</label>
                    <span v-if="item.agentDb.country">{{ item.agentDb.country }}</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                  <li>
                    <label>Email:</label>
                    <span v-if="item.agentDb.email">{{ item.agentDb.email }}</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                  <li>
                    <label>Phone:</label>
                    <span v-if="item.agentDb.phone">{{ item.agentDb.phone }}</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                  <li>
                    <label>Website:</label>
                    <span v-if="item.agentDb.website">{{ item.agentDb.website }}</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="t2sect">
              <div class="t2box">
                <h3>Principals Information</h3>
                <ul>
                  <li>
                    <label>Number of contacts:</label>
                    <span v-if="item.contacts">{{ item.contacts.length }}</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                  <template v-for="(contact, ci) in item.contacts">
                    <li>
                      <label>Contact {{ ci+1 }} First Name</label>
                      <span v-if="contact.firstName">{{ contact.firstName }}</span>
                      <span class="empty" v-else>NO</span>
                    </li>
                    <li>
                      <label>Contact {{ ci+1 }} Last Name</label>
                      <span v-if="contact.lastName">{{ contact.lastName }}</span>
                      <span class="empty" v-else>NO</span>
                    </li>
                    <li>
                      <label>Contact {{ ci+1 }} Email</label>
                      <span v-if="contact.email">{{ contact.email }}</span>
                      <span class="empty" v-else>NO</span>
                    </li>
                    <li>
                      <label>Contact {{ ci+1 }} Phone</label>
                      <span v-if="contact.phone">{{ contact.phone }}</span>
                      <span class="empty" v-else>NO</span>
                    </li>
                  </template>
                </ul>
              </div>
            </div>
            <div class="t2sect">
              <div class="t2box">
                <h3>Documents Information</h3>
                <ul>
                  <li>
                    <label>Completed Agreement:</label>
                    <span v-if="uwShownItem.agreement">YES</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                  <li>
                    <label>Signed Agreement:</label>
                    <span v-if="uwShownItem.signedAgr">YES</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="t2sect">
              <div class="t2box">
                <h3>Banking Information</h3>
                <ul>
                  <template v-for="(subi, si) in item.agentDb.accounts">
                    <li>
                      <label>Account Name:</label>
                      <span v-if="subi.accountHolder">{{ subi.accountHolder }}</span>
                      <span class="empty" v-else>NO</span>
                    </li>
                    <li>
                      <label>Account Nbr:</label>
                      <span v-if="subi.accountNumber">{{ subi.accountNumber }}</span>
                      <span class="empty" v-else>NO</span>
                    </li>
                    <li>
                      <label>Bank Name:</label>
                      <span v-if="subi.bankName">{{ subi.bankName }}</span>
                      <span class="empty" v-else>NO</span>
                    </li>
                    <li>
                      <label>Institution Nbr:</label>
                      <span v-if="subi.accountSwift">{{ subi.accountSwift }}</span>
                      <span class="empty" v-else>NO</span>
                    </li>
                    <li>
                      <label>Transit/Branch Nbr:</label>
                      <span v-if="subi.accountIBAN">{{ subi.accountIBAN }}</span>
                      <span class="empty" v-else>NO</span>
                    </li>
                    <li>
                      <label>ABA/Routing Nbr:</label>
                      <span v-if="subi.accountRouting">{{ subi.accountRouting }}</span>
                      <span class="empty" v-else>NO</span>
                    </li>
                  </template>
                </ul>
              </div>
            </div>
            <div class="t2sect">
              <div class="t2box">
                <h3>System</h3>
                <ul>
                  <li>
                    <label>CRISTINE Score:</label>
                    <span>YES</span> <!-- TODO -->
                  </li>
                  <li>
                    <label>Website Check Complete:</label>
                    <span v-if="uwShownItem.webCheck==1">YES</span>
                    <span class="" v-else>NO</span>
                  </li>
                  <li>
                    <label>Compliance Review Days:</label>
                    <span v-if="uwShownItem.reviewDays">{{ uwShownItem.reviewDays }}</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                  <li>
                    <label>Duplicates:</label>
                  </li>
                  <li>
                  </li>
                  <li>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <template v-if="animFinished">
          <div class="status-success" v-if="passed">
            Approved! Pending final aproval.
          </div>
          <div class="status-error" v-else>
            Not approved
          </div>
          </template>
        </div>
        <div class="pre-check-details" v-else-if="uwShown">
          <div class="t2col toAnimate">
            <div style="width: 100%">
              <div v-for="(field, index) in fieldIds" class="field" :class="{visible: shownItem>index}">
                <label>{{ fields[field][0] }}:</label>
                <span v-if="uwShownItem[field] || fields[field][1]=='app'" v-html="showField(uwShownItem, field)"></span>
                <span class="empty" v-else>NO</span>
              </div>
            </div>
          </div>
          <template v-if="animFinished">
          <div class="status-success" v-if="passed">
            Approved! Pending final aproval.
          </div>
          <div class="status-error" v-else>
            Not approved
          </div>
          </template>
        </div>
<div class="popup" :class="{shown: finalPopup}"><div class="pop-med"><div class="pop-inner" style="max-width: 500px; margin-left: auto; margin-right: auto">
<h2 style="text-align: center">Implementation Settings</h2>
    <ul class="column-form" style="display: flex; flex-wrap: wrap">
      <li style="width: 50%; margin-bottom: 10px">
        <label>Compliance review days</label>
        <div class="field">
          <validator :value="0" reqs="">
            <dyn-select :options="reviewDaysOpts" v-model="item.agentDb.reviewDays" style="width: 75%" />
          </validator>
        </div>
      </li>
      <li style="width: 50%; margin-bottom: 10px">
        <label>BIN/ICA Rental Fee:</label>
        <div class="field">
          <validator :value="0" reqs="">
            <formel-money-amount4 v-model="item.agentDb.bicRentalFee" suffix="%" style="width: 75%" />
          </validator>
        </div>
      </li>
      <li style="width: 50%; margin-bottom: 10px">
        <label>Partner Setup Fee:</label>
        <div class="field">
          <validator :value="0" reqs="">
            <formel-money-amount4 v-model="item.agentDb.setupFee" prefix="$" style="width: 75%" />
          </validator>
        </div>
      </li>
      <li style="width: 50%; margin-bottom: 10px">
        <label>Monthly Minimum:</label>
        <div class="field">
          <validator :value="0" reqs="">
            <formel-money-amount4 v-model="item.agentDb.monthlyMinimum" prefix="$" style="width: 75%" />
          </validator>
        </div>
      </li>
    </ul>
<h2 style="text-align: center">Implementation Message</h2>
    <textarea v-model="implementationMessage" style="border: 1px solid #ccc; border-radius: 4px; width: 100%; box-sizing: border-box; height: 100px; padding: 6px; margin-bottom: 20px; font-size: 14px;"></textarea>
<button class="btn btn-large" style="margin-left: auto; margin-right: auto; display: block; width: 330px;" v-on:click.prevent="doFinal2">Submit</button>
</div></div></div>
      </div>
</template>


<script>
export default {
  props: ["uwList", "preq", "acParent", "skipAuto"],
  data() { return {
    finalPopup: false,
    implementationMessage: "",
    uwSelected: null,
    uwShown: null,
    uwManager: null,
    shownItem: 0,
    animFinished: false,
    item: {agentDb: {}},
    fieldGroups: {
      "General Information": [
        "status", "solutions", "agentDbId", "agentType", "agentClass", "", "language", "", ""],
      "Company Information": [
        "", "", "", "", "", "", "", "", ""],
      "Principals Information": [
        "", "", "", "", "", "", "", "", ""],
      "Business Information": [
        "", "", "", "", "", "", "", "", ""],
      "Banking Information": [
        "", "", "", "", "", "", "", "", ""],
      "Documents Information": [
        "", "completedApp", "signedApp", "agreement", "signedAgr"],
      "System": [
        "", "", "preCheck", "g2Check", "idmCheck", "ofacCheck", ""],
    },
    fields: {
//       status: ["Status is A02", "flag"],
      dupCheck: ["Passed DUP Check", "flag"],
      negCheck: ["Passed Negative List Check", "flag"],
      agentDbId: ["Has Signup Form ID"],
      agentId: ["Has Agent ID"],
      agentType: ["Has Agent Type", "list"],
      agentClass: ["Has Agent Tier"],
      manager: ["Manager", "user"],
      language: ["Language"],
      solutions: ["Solutions", "list"],
      regions: ["Regions", "list"],
      completedApp: ["Completed Sign-up Form", "app"],
//       signedApp: ["Signed Sign-up Form", "doc"],
      agreement: ["Completed Agreement", "agr"],
      signedAgr: ["Signed Agreement", "agrs"],
//       preCheck: ["Pre-Check was run", "flag"],
      docCheck: ["Doc-Check was run", "flag"],
      matchVmasCheck: ["Match/VMAS Scan was run", "flag"],
      ofacCheck: ["OFAC Scan was run", "flag"],
      kycCheck: ["KYC Scan was run", "flag"],
      amlCheck: ["AML Scan was run", "flag"],
      giactCheck: ["GIACT Scan was run", "flag"],
      idmCheck: ["IDM Scan was run", "flag"],
      g2Check: ["G2 Scan was run", "flag"],
    },
    currencies: {
      "036": "AUD",
      "986": "BRL",
      "124": "CAD",
      "756": "CHF",
      "978": "EUR",
      "826": "GBP",
      "344": "HKD",
      "392": "JPY",
      "554": "NZD",
      "840": "USD",
    },
    groups: window.userGroups,
    salesBrands: [],
    reviewDaysOpts: [
      {value: "30", label: "30"},
      {value: "60", label: "60"},
      {value: "90", label: "90"},
      {value: "180", label: "180"},
      {value: "365", label: "365"},
    ],
  }},
  watch: {
    implementationMessage() {
      this.implementationMessage=(this.implementationMessage || "").substring(0, 250);
    },
  },
  mounted() {
    this.item=this.actualParent.item;
    this.loadSalesBrands();
    this.listFinalChecks();
    if (!this.skipAuto)
      this.newFinalCheck();
  },

  methods: {
    loadSalesBrands: async function() {
      this.salesBrands=[];
      var items=(await api.list("SalesBrand", {}, ["brandId:ASC"])).items || [];
      for(var i in items) {
        var item=items[i];
        this.salesBrands.push({label: item.dba, value: item.id, item: item});
      }
    },
    findSolution(id) {
      for(var sol of window.appData.solutions)
        if (sol.value==id)
          return sol.label;
      return null;
    },
    createNote (message) {
      return api.create("Note", {
        created: "CURRENT_TIMESTAMP",
        message,
        author: appData.currentUser.id,
        system: 1,
        type: 'Employee',
        parentId: this.actualParent.item.id
      });
    },
    salesBrandForId(id) {
      for(var i in this.salesBrands)
        if (this.salesBrands[i].value==id)
          return this.salesBrands[i].item;
      return {};
    },

    showField(item, field) {
      if (this.fields[field][1]=="flag")
        return "Yes";
      if (this.fields[field][1]=="app")
        return appData.existingAgent.id+" - "+appData.existingAgent.companyName;
      if (this.fields[field][1]=="user")
        return item[field].companyName || item[field].fullName;
      if (this.fields[field][1]=="doc")
        return item[field].filename;
      if (this.fields[field][1]=="agr")
        return item[field].name;
      if (this.fields[field][1]=="agrs")
        return item[field].name+' signed';
      if (this.fields[field][1]=="list") {
        var result=[];
        for(var i in item[field]) {
          var val=item[field][i];
          result.push(val);
        }
        return result.join("<br />");
      }
      return item[field];
    },
    doShowItems(id, animate) {
      this.uwShown=id;
      var that=this;
      this.$nextTick(()=>{
        $(this.$refs.detailsBox).find(".t2box, .t2box li, .t2box h4").addClass("visible");
        this.animFinished=true;
      });
    },
    listFinalChecks: async function() {
      await this.actualParent.listFinalChecks();
      if (this.uwList.length)
        this.doShowItems(this.uwList[0].id);
    },
    newFinalCheck: async function() {
      startLoading();
      var id=(await api.create("PartnerApprovalCheck", {})).id;
      
      var newItem={}
      
      newItem.id=null;
      newItem.agentDbId=this.actualParent.item.agentDb.id;
      newItem.userId=appData.currentUser.id;
      newItem.created="CURRENT_TIMESTAMP";
      
      await api.update("PartnerApprovalCheck", id, newItem);
      await this.listFinalChecks();
      setTimeout(()=>{
        this.doShowItems(id, false);
        this.createNote(this.passed ? "Approved" : "Not Approved")
      }, 30);
      stopLoading();
    },
    sendApprovalEmail: async function() {
      return; // this is not needed anymore
      var existingApprovalMail=await api.list("PartnerEmail", {emailType: "Approved", agentId: this.actualParent.item.agentDb.id});
      if (!existingApprovalMail.items.length) {
        await api.create("PartnerEmail", {emailType: "Approved", agentId: this.actualParent.item.agentDb.id, created: "CURRENT_TIMESTAMP"})
      }
    },
    doFinal: async function() {
      this.finalPopup=true;
      this.implementationMessage="";
    },
    doFinal2: async function() {
      this.finalPopup=false;
//       if (!confirm("Are you sure?"))
//         return;
      startLoading();
      await this.sendApprovalEmail();
      
      
      await api.update("Partner", this.actualParent.item.agentDb.id, {
        status: "A04",
        approvalDate: "CURRENT_TIMESTAMP",
        implMessage: this.implementationMessage,
        reviewDays: this.item.agentDb.reviewDays,
        bicRentalFee: this.item.agentDb.bicRentalFee,
        setupFee: this.item.agentDb.setupFee,
        monthlyMinimum: this.item.agentDb.monthlyMinimum,
      });
      this.item.agentDb.status = 'A04'
      this.createNote("Approved - Final Approval")
      
      stopLoading("Agent approved");
      this.uwShown=null;
      this.$emit('final');
    },
  },
  computed: {
    actualParent() {
      if (this.acParent)
        return this.acParent;
      return this.$parent.signedApplications?this.$parent:this.$parent.$parent;
    },
    passed() {
      if (!this.uwShownItem)
        return false;
//       return true;
      for(var i in this.fields) {
        if (this.fields[i][1]=="app")
          continue;
        if (i=="salesBrand" && !this.uwShownItem[i]) {
//           if (item.type=="agent-accex" || item.type=="agent-sales")
//             return false;
        } else if (i=="webCheck") {
          continue;
        } else if (!this.uwShownItem[i])
          return false;
      }
      return true;
    },
    validItems() {
      if (!this.uwShownItem)
        return false;
      var valid=43;
      for(var i in this.fields) {
        if (this.fields[i][1]=="app")
          continue;
        if (!this.uwShownItem[i])
          valid--;
      }
      return valid;
      
    },
    fieldIds() {
      var result=[];
      for(var i in this.fields)
        result.push(i);
      return result;
    },
    uwShownItem() {
      var result={};
      if (this.uwShown) {
        var item=this.uwList.find((a)=>a.id==this.uwShown);
        for(var i in item)
          result[i]=item[i];
      }
      return result;
    },
    printUrl() {
      if (!this.uwShownItem)
        return null;
      if (!appData.docCheckPrintUrl)
        return null;
      return appData.docCheckPrintUrl.replace("%d", this.uwShownItem.id);
    },
  },
  mixins: [vueMixins.dateFormatter],  
}
</script>
