<template>
    <form class="search-form">
      <h3>Search Options</h3>
      <div class="f2split">
        <div>
          <div class="field">
            <label>Date Range</label>
            <div class="field-group">
              <datepicker type="text" v-model="search.dateStart" />
              <datepicker type="text" v-model="search.dateEnd" />
            </div>
          </div>
          <div class="field">
            <label>Source</label>
            <dyn-select v-model="search.source" :options="sourcesOpt" />
          </div>
          <div class="field" v-if="!followup">
            <label>Status</label>
            <dyn-select v-model="search.status" :options="statusesOpt" />
          </div>
        </div>
        <div>
          <div class="field">
            <label>Account ID</label>
            <input type="text" v-model="search.accountId" />
          </div>
          <div class="field">
            <label>Company Name</label>
            <input type="text" v-model="search.companyName" />
          </div>
          <div class="field">
            <label>Email</label>
            <input type="text" v-model="search.email" />
          </div>
          <div class="field">
            <label>Filter</label>
            <dyn-select v-model="search.type" :options="types" />
          </div>
        </div>
      </div>

      <div class="buttons">
        <button class="btn btn-large btn-primary" v-on:click.prevent="updateResults">Search</button>
      </div>
    </form>
</template>

<script>
export default {
  props: ["saved", "followup", "auxtype"],
  data() { return {
    search: {
      dateStart: moment().subtract(7, "day").format("YYYY-MM-DD"),
      dateEnd: moment().format("YYYY-MM-DD"),
      source: "",
      status: "",
      accountId: "",
      companyName: "",
      email: "",
    },
    statuses: [],
    sources: [],
    types: [
      {value: null, label: "All"},
      {value: "merchants", label: "Merchants"},
      {value: "facilitators", label: "Facilitators"},
      {value: "submerchants", label: "Sub-Merchants"},
    ],
  }; },
  computed: {
    statusesOpt() {
      var result=[{value: "", label: "(all)"}];
      for(var i in this.statuses)
        result.push({
          value: this.statuses[i].status,
          label: this.statuses[i].status+" - "+this.statuses[i].description});
      return result;
    },
    sourcesOpt() {
      var result=[{value: "", label: "(all)"}];
      for(var i in this.sources)
        result.push({
          value: this.sources[i].id,
          label: this.sources[i].companyName || this.sources[i].fullName});
      return result;
    }
  },
  methods: {
    async updateResults() {
      
    },
  },
  mounted() {
    this.statuses=JSON.parse(JSON.stringify(appData.statuses));
    this.sources=JSON.parse(JSON.stringify(appData.sources));
    if (this.followup || true) {
      this.search.dateStart=null;
      this.search.dateEnd=null;
    }
    this.updateResults();
  },
}
</script>
