<template>
      <div>
        <div class="action-sub-buttons" v-if="!uwShown">
          <a class="btn" v-on:click="newCheck">Run new</a>
          <a class="btn" v-if="uwSelected && uwSelectedItem.response && uwSelectedItem.response.complete" v-on:click.prevent="doShowItems(uwSelected)">View</a>
          <a class="btn" v-if="uwSelected && uwSelectedItem.response && !uwSelectedItem.response.complete" v-on:click.prevent="refresh(uwSelected)">Refresh</a>
        </div>
        <div class="action-sub-buttons" v-else>
          <a class="btn" v-on:click="uwShown=null">Back</a>
          <a class="btn" :href="printUrl" target="_blank" v-if="printUrl">View Report</a>
        </div>
        <table class="file-list content-table" v-if="!uwShown">
          <thead>
            <tr>
<!--               <th style="width: 1%"></th> -->
              <th>ID</th>
              <th>Date</th>
              <th>Filename</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(uwItem, uwI) in uwList">
<!--               <td><input type="radio" v-model="uwSelected" :value="uwItem.id"></td> -->
              <td>{{ uwList.length-uwI }}</td>
              <td>{{ formatDate(uwItem.createdDate, "YYYY-MM-DD HH:mm") }}</td>
              <td><a :href="printUrlForItem(uwItem)" target="_blank">{{ docNameForItem(uwItem) }}</a></td>
              <td v-if="uwItem.type=='g2'">{{ uwItem.response.complete?'Completed':'Pending' }}</td>
              <td v-else>{{ uwItem.response && uwItem.response.res || 'ERROR' }}</td>
            </tr>
          </tbody>
        </table>
        <div class="pre-check-details" v-else-if="uwShown">
          <div class="t2col toAnimate">
            <div style="width: 100%" v-for="r in [uwShownItem.response || {}]" class="kycg2">
              <h4>Messages</h4>
              <ul class="messagesx">
                <li class="info" v-for="item in r.messages.info">{{ item }}</li>
                <li class="warn" v-for="item in r.messages.warn">{{ item }}</li>
                <li class="ok" v-for="item in r.messages.ok">{{ item }}</li>
                <li class="crit" v-for="item in r.messages.crit">{{ item }}</li>
              </ul>
              <h4>Invalid Addresses</h4>
              <ul class="addresses">
                <li class="info" v-if="!r.invalid_addresses.length">(none)</li>
                <li v-for="addr in r.invalid_addresses">
                  {{ addr.street+" "+addr.city+" "+addr.postcode+" "+addr.state+" "+addr.country }}
                </li>
              </ul>
              <h4 v-if="r.alexa">Alexa</h4>
              <ul v-if="r.alexa">
                <li>Adult: {{ r.alexa.adult?"Yes":"No" }}</li>
                <li>Rank: {{ r.alexa.rank || "N/A" }}</li>
              </ul>
              <h4 v-if="r.bbb_results.exists">BBB</h4>
              <ul v-if="r.bbb_results.exists">
                <li>Exists: {{ r.bbb_results.exists?"Yes":"No" }}</li>
                <li>Grade: {{ r.bbb_results.grade }}</li>
                <li>Report: <a target="_blank" :href="r.bbb_results.bbb_api.ReportUrl">View</a></li>
                <li>Email: {{ r.bbb_results.bbb_api.ContactEmailAddress[0] }}</li>
                <li>Url: {{ r.bbb_results.bbb_api.BusinessURLs[0] }}</li>
                <li>Phone: {{ r.bbb_results.bbb_api.Phones[0] }}</li>
                <li>Start date: {{ r.bbb_results.bbb_api.DateBusinessStarted }}</li>
                <li>Category: {{ r.bbb_results.bbb_api.PrimaryCategory }}</li>
              </ul>
              <template v-if="r.google_places_results">
              <h4>Google Places</h4>
              <ul v-for="place in r.google_places_results">
                <template v-for="p in [place.results[0]]">
                <li>Search Query: {{ place.query }}</li>
                <li>Name: {{ p.name }}</li>
                <li>Rating: {{ p.rating }}</li>
                <li>Price level: {{ p.price_level }}</li>
                <li>Phone: {{ p.formatted_phone_number }}</li>
                <li>Address: {{ p.formatted_address }}</li>
                <li>Url: {{ p.website }}</li>
                </template>
              </ul>
              </template>
              <template v-if="r.ms_entities_results!='disabled'">
              <h4>Microsoft Entities</h4>
              <ul v-for="place in r.ms_entities_results">
                <template v-for="p in [place.places.value[0]]">
                <li>Name: {{ p.name }}</li>
                <li>Url: {{ p.url }}</li>
                <li>Phone: {{ p.telephone }}</li>
                <li>Address: {{ p.address.neighborhood+" "+p.address.postalCode+" "+p.address.addressLocality+" "+p.address.addressRegion+" "+p.address.addressCountry }}</li>
                </template>
              </ul>
              </template>
              <h4 v-if="r.malware_detections">Malware</h4>
              <ul v-if="r.malware_detections">
                <li>Phishings: {{ r.malware_detections.phishings }}</li>
                <li>Malwares: {{ r.malware_detections.malwares }}</li>
              </ul>
              <h4 v-if="r.ofac_results">OFAC</h4>
              <ul v-if="r.ofac_results">
                <li v-for="ofac in r.ofac_results.name">
                  <template v-for="(n, name) in ofac">{{ name }}</template>
                </li>
                <li v-for="ofac in r.ofac_results.location">
                  <template v-for="(n, name) in ofac">{{ name }}</template>
                </li>
              </ul>
              <template v-if="r.pipl_results">
              <h4>PIPL</h4>
              <ul v-for="person in r.pipl_results.length?r.pipl_results[0].possible_persons:[]">
                <li>{{ person.names.map((a)=>a.display).join(", ") }}</li>
              </ul>
              </template>
              <h4 v-if="r.whois_results">Whois</h4>
              <ul v-if="r.whois_results">
                <li>Created: {{ r.whois_results.creation_date.join(" ") }}</li>
                <li>Expires: {{ r.whois_results.expiration_date.join(" ") }}</li>
                <li>Updated: {{ r.whois_results.updated_date.join(" ") }}</li>
                <li>Registrar: {{ r.whois_results.registrar.join(" ") }}</li>
                <li v-for="ns in r.whois_results.nameservers">Nameserver: {{ ns }}</li>
                <li v-for="ns in r.whois_results.nameservers">Nameserver: {{ ns }}</li>
              </ul>
              <h4>Yellow pages</h4>
              <ul v-for="y in r.yellowpages_result">
                <li>Search term: {{ y.search_term }}</li>
                <li>Business name: {{ y.business_name }}</li>
                <li>Category: {{ y.primary_category }}</li>
                <li>Open hours: {{ y.open_hours }}</li>
                <li>Payment methods: {{ y.payment_methods }}</li>
                <li>Address: {{ y.street+" "+y.zip+" "+y.city+", "+y.state }}</li>
                <li>Phone: {{ y.phone }}</li>
                <li>Rating: {{ y.averageRating }}</li>
                <li>Url: {{ y.websiteURL }}</li>
                <li>Slogan: {{ y.slogan }}</li>
              </ul>
              <h4>Yelp pages</h4>
              <ul v-for="y in r.yelp_results">
                <li>Name: {{ y.name }}</li>
                <li>Rating: {{ y.rating }}</li>
                <li>Categories: {{ y.categories.map((a)=>a.title).join(",") }}</li>
                <li>Price level: {{ y.price }}</li>
                <li>Phone: {{ y.display_phone }}</li>
                <li>Address: {{ y.location.address1+" "+y.location.address2+" "+y.location.address3+" "+y.location.postal_code+" "+y.location.city+" "+y.location.state+" "+y.location.country }}</li>
              </ul>
            </div>
          </div>

        </div>
      </div>
</template>


<script>
export default {
  props: ["uwList", "type", "preq"],
  data() { return {
    uwSelected: null,
    uwShown: null,    
    fields: {
    },
  }},
  methods: {
    doShowItems(id) {
      this.uwShown=id;
    },
    async refresh(id) {
      startLoading();
      await api.update("ExtCheck", id, {type: "g2"});
      await this.listItems();
      stopLoading();
    },
    listItems: async function() {
      var methodName="";
      if (this.type=="g2")
        methodName="listG2";
      if (methodName)
        await this.$parent[methodName]();
    },
    newCheck: async function() {
      startLoading();
      if (this.preq)
        var id=(await api.create("ExtCheck", {
          type: this.type,
          userId: appData.currentUser.id,
          createdDate: "CURRENT_TIMESTAMP",
          preqId: this.$parent.item.id,
        })).id;
      else
        var id=(await api.create("ExtCheck", {
          type: this.type,
          userId: appData.currentUser.id,
          createdDate: "CURRENT_TIMESTAMP",
          applicationId: this.$parent.item.id,
        })).id;
      
      var newItem={}
      
      await this.listItems();
//       this.doShowItems(this.uwList[this.uwList.length-1].id);
      this.$emit('update');
      stopLoading();
    },
    printUrlForItem(item) {
      if (!item)
        return null;
      if (!item.response || !item.response.pdf_url)
        return null;
      return item.response.pdf_url;
    },
    docNameForItem(item) {
      var date=moment(item.createdDate);
      return this.$parent.item.accountId+" "+date.format("D M YYYY")+".pdf";
    },
  },
  computed: {
    uwShownItem() {
      var result={};
      if (this.uwShown) {
        var item=this.uwList.find((a)=>a.id==this.uwShown);
        for(var i in item)
          result[i]=item[i];
      }
      return result;
    },
    uwSelectedItem() {
      var result={};
      if (this.uwSelected) {
        var item=this.uwList.find((a)=>a.id==this.uwSelected);
        for(var i in item)
          result[i]=item[i];
      }
      return result;
    },
    printUrl() {
      if (!this.uwShownItem)
        return null;
      if (!this.uwShownItem.response || !this.uwShownItem.response.pdf_url)
        return null;
      return this.uwShownItem.response.pdf_url;
    },
  },
  mixins: [vueMixins.dateFormatter],  
}
</script>
