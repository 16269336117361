<template>
<div>
  <page-header title="Business Services" :breadcrumbs="breadcrumbs" />

  <sub-menu :back="true" :menu="menu" />
</div>
</template>

<script>
export default {
  data () {
    return {
      breadcrumbs: [
//         {
//           text: 'System',
//         },
        {
          text: 'Business Services'
        }
      ],

      menu: [
        {
          text: 'Orders Overview',
          list: [
            {
              text: 'Orders Overview',
              to: '/business/orders'
            }
          ]
        },
      ]
    }
  }
}
</script>
