<template>
<div class="form-popup" v-show="shown"><div>
  <div class="ncontent appf appnat">
    <a class="close" href="" v-on:click.prevent="dismiss">&times;</a>
    
<!--     <form-progress :step="step" :steps="['General', 'Company Info', 'Banking', 'Payment Methods', 'Modules', 'Equipment', 'Options', 'Pricing']" /> -->
    <form-progress :step="step" :steps="['General', 'Company Info', 'Banking', 'Pricing Schedule A', 'Equipment']" />
    <h3>New Agreement</h3>

  
<form class="form-step" v-if="step==0" key="step1">
  <section>
    <h2>General</h2>
    <ul class="column-form">
      <li>
        <label>Solution</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly :value="solution(appl.solution)" />
          </validator>
        </div>
      </li>
      <li>
        <label>Application ID</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly v-model="appl.applicationId" />
          </validator>
        </div>
      </li>
      <li>
        <label>Account ID</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly v-model="appl.accountId" />
          </validator>
        </div>
      </li>
      <li>
        <label>Account Class</label>
        <div class="field">
          <validator :value="item.accClass" reqs="required">
            <dyn-select v-model="item.accClass" :options="accountClassesList" />
          </validator>
        </div>
      </li>
      <li>
        <label>Account Manager</label>
        <div class="field">
          <validator :value="item.managerId" reqs="">
            <dyn-select v-model="item.managerId" :options="managers" />
          </validator>
        </div>
      </li>
      <li>
        <label>Partner</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly v-model="appl.source && appl.source.agentDb && appl.source.agentDb.name" />
          </validator>
        </div>
      </li>
      <li>
        <label>Industry</label>
        <div class="validator">
          <validator :value="item.industry" reqs="required">
            <dyn-select v-model="item.industry" :options="industries" />
          </validator>
        </div>
      </li>
      <li>
        <label>MCC</label>
        <div class="field">
          <validator :value="item.mcc" reqs="required">
            <mcc-edit v-model="item.mcc" />
          </validator>
        </div>
      </li>
      <li v-if="false">
        <label>Plan</label>
        <div class="field">
          <validator :value="item.plan" reqs="required">
            <label class="" :class="{checked: item.plan=='Lite'}">
              <input type="radio" name="plan" v-model="item.plan" value="Lite" />
              <span></span>Lite
            </label>
            <label class="" :class="{checked: item.plan=='Full'}">
              <input type="radio" name="plan" v-model="item.plan" value="Full" />
              <span></span>Full
            </label>
          </validator>
        </div>
      </li>
    </ul>
    <div class="notes important" v-if="internalNotes && internalNotes.agrGeneral">Note: {{ internalNotes.agrGeneral }} </div>
  </section>
  <div class="buttons">
    <a class="btn btn-large btn-plain btn-back" v-on:click.prevent="">Back</a>
    <a class="btn btn-large btn-success btn-next" v-on:click.prevent="nextStep">Next</a>
  </div>
</form>
<form class="form-step" v-if="step==1" key="step2">
  <section>
    <h2>Company Info</h2>
    <ul class="column-form">
      <li>
        <label>Legal Business Name</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly :value="appl.companyName" />
          </validator>
        </div>
      </li>
      <li>
        <label>Trade Name</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly :value="appl.companyNameTrade" />
          </validator>
        </div>
      </li>
      <li>
        <label>Company Address</label>
        <div class="field">
          <validator :value="0" reqs="" style="text-align: left; padding-left: 12px">
            {{ appl.companyAddress }}
          </validator>
        </div>
      </li>
      <li>
        <label>Postcode</label>
        <div class="field">
          <validator :value="0" reqs="" style="text-align: left; padding-left: 12px">
            {{ appl.companyAddressPostcode }}
          </validator>
        </div>
      </li>
      <li>
        <label>City</label>
        <div class="field">
          <validator :value="0" reqs="" style="text-align: left; padding-left: 12px">
            {{ appl.companyAddressCity }}
          </validator>
        </div>
      </li>
      <li>
        <label>Province / State</label>
        <div class="field">
          <validator :value="0" reqs="" style="text-align: left; padding-left: 12px">
            {{ appl.companyAddressState }}
          </validator>
        </div>
      </li>
      <li>
        <label>Country</label>
        <div class="field">
          <validator :value="0" reqs="" style="text-align: left; padding-left: 12px">
            {{ appl.companyAddressCountry }}
          </validator>
        </div>
      </li>
      <li>
        <label>Reg #</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly :value="appl.companyRegnum" />
          </validator>
        </div>
      </li>
      <li>
        <label>Tax ID</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly :value="appl.companyTaxId" />
          </validator>
        </div>
      </li>
      <li v-for="(director, di) in appl.directors">
        <label>Director {{ di+1 }}</label>
        <div class="field">
          <validator :value="0" reqs="" style="text-align: left; padding-left: 12px">
            {{ director.firstName+" "+director.lastName+" "+director.dob+", "+director.address+", "+director.addressPostcode+", "+director.addressCity+", "+director.addressNumber+", "+director.addressCountry+", "+director.phone+", "+director.passportNum+", "+director.ssn }}
          </validator>
        </div>
      </li>
      <li v-for="(director, di) in appl.ubos">
        <label>UBO {{ di+1 }}</label>
        <div class="field">
          <validator :value="0" reqs="" style="text-align: left; padding-left: 12px">
            {{ director.firstName+" "+director.lastName+" "+director.dob+", "+director.address+", "+director.addressPostcode+", "+director.addressCity+", "+director.addressNumber+", "+director.addressCountry+", "+director.phone+", "+director.passportNum+", "+director.ssn+", "+director.ownership+"%" }}
          </validator>
        </div>
      </li>
      <li>
        <label>Website</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly :value="appl.website" />
          </validator>
        </div>
      </li>
      <li>
        <label>Website Beta</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly :value="appl.websiteBeta" />
          </validator>
        </div>
      </li>
    </ul>
    <div class="notes important" v-if="internalNotes && internalNotes.argCompany">Note: {{ internalNotes.argCompany }} </div>
  </section>
  <div class="buttons">
    <a class="btn btn-large btn-plain btn-back" v-on:click.prevent="step--">Back</a>
    <a class="btn btn-large btn-success btn-next" v-on:click.prevent="nextStep">Next</a>
  </div>
</form>
<form class="form-step" v-if="step==2" key="step3">
  <section>
    <h2>Banking</h2>
    <ul class="column-form">
      <li>
        <label>Settlement Currency</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly :value="currencies[appl.accounts[0].accountCurrency]" />
          </validator>
        </div>
      </li>
      <li>
        <label>Bank account holder</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly v-model="appl.accounts[0].accountHolder" />
          </validator>
        </div>
      </li>
      <li>
        <label>Bank Account #</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly v-model="appl.accounts[0].accountNumber" />
          </validator>
        </div>
      </li>
      <li>
        <label>Institution Number</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly v-model="appl.accounts[0].accountSwift" />
          </validator>
        </div>
      </li>
      <li>
        <label>Transit/Branch Number</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly v-model="appl.accounts[0].accountIBAN" />
          </validator>
        </div>
      </li>
      <li>
        <label>ABA / routing number</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly :value="(appl.accounts[0].accountRouting || '')+' '+(appl.accounts[0].accountSwift || '')" />
          </validator>
        </div>
      </li>
      <li>
        <label>Bank Name</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly v-model="appl.accounts[0].bankName" />
          </validator>
        </div>
      </li>
    </ul>
    <div class="notes important" v-if="internalNotes && internalNotes.agrBanking">Note: {{ internalNotes.agrBanking }} </div>
  </section>
  <div class="buttons">
    <a class="btn btn-large btn-plain btn-back" v-on:click.prevent="step--">Back</a>
    <a class="btn btn-large btn-success btn-next" v-on:click.prevent="nextStep">Next</a>
  </div>
</form>
<form class="form-step" v-if="false" key="step4">
  <section>
    <h2>Payment Methods</h2>
    <div class="content-table-container">
      <table class="content-table">
        <tbody>
          <tr>
            <td>
              <dyn-select v-model="methodTemplateId" :options="currentMethodTemplates" />
            </td>
          </tr>
          <tr>
            <th><label>
              <input type="checkbox" v-model="item.payoptions" value="CardBiller" :disabled="enabledPayOpts.indexOf('CardBiller')==-1" />
              CardBiller
            </label></th>
          </tr>
          <tr>
            <td style="text-align: left">
              <label>
                <input type="checkbox" v-model="item.payoptions" value="Visa" :disabled="item.payoptions.indexOf('CardBiller')==-1 || enabledPayOpts.indexOf('Visa')==-1" />
                Visa
              </label>
              <label>
                <input type="checkbox" v-model="item.payoptions" value="Mastercard" :disabled="item.payoptions.indexOf('CardBiller')==-1 || enabledPayOpts.indexOf('Mastercard')==-1" />
                Mastercard
              </label>
              <label>
                <input type="checkbox" v-model="item.payoptions" value="Discover" :disabled="item.payoptions.indexOf('CardBiller')==-1 || enabledPayOpts.indexOf('Discover')==-1" />
                Discover
              </label>
              <label>
                <input type="checkbox" v-model="item.payoptions" value="AMEX" :disabled="item.payoptions.indexOf('CardBiller')==-1 || enabledPayOpts.indexOf('AMEX')==-1" />
                AMEX
              </label>
              <label>
                <input type="checkbox" v-model="item.payoptions" value="China UnionPay" :disabled="item.payoptions.indexOf('CardBiller')==-1 || enabledPayOpts.indexOf('China UnionPay')==-1" />
                China UnionPay
              </label>
            </td>
          </tr>
          <tr>
            <th><label>
              <input type="checkbox" v-model="item.payoptions" value="CardBiller Plus" :disabled="enabledPayOpts.indexOf('CardBiller Plus')==-1" />
              CardBiller Plus
            </label></th>
          </tr>
          <tr>
            <td style="text-align: left">
              <label>
                <input type="checkbox" v-model="item.payoptions" value="Apple Pay" :disabled="item.payoptions.indexOf('CardBiller Plus')==-1 || enabledPayOpts.indexOf('Apple Pay')==-1" />
                Apple Pay
              </label>
              <label>
                <input type="checkbox" v-model="item.payoptions" value="Google Pay" :disabled="item.payoptions.indexOf('CardBiller Plus')==-1 || enabledPayOpts.indexOf('Google Pay')==-1" />
                Google Pay
              </label>
              <label>
                <input type="checkbox" v-model="item.payoptions" value="Interac Online" :disabled="item.payoptions.indexOf('CardBiller Plus')==-1 || enabledPayOpts.indexOf('Interac Online')==-1" />
                Interac Online
              </label>
            </td>
          </tr>
          <tr>
            <th><label>
              <input type="checkbox" v-model="item.payoptions" value="BankBiller" :disabled="enabledPayOpts.indexOf('BankBiller')==-1" />
              BankBiller
            </label></th>
          </tr>
          <tr>
            <td style="text-align: left">
              <label>
                <input type="checkbox" v-model="item.payoptions" value="EFT" :disabled="item.payoptions.indexOf('BankBiller')==-1 || enabledPayOpts.indexOf('EFT')==-1" />
                EFT
              </label>
              <label>
                <input type="checkbox" v-model="item.payoptions" value="ACH" :disabled="item.payoptions.indexOf('BankBiller')==-1 || enabledPayOpts.indexOf('ACH')==-1" />
                ACH
              </label>
            </td>
          </tr>
          <tr>
            <th><label>
              <input type="checkbox" v-model="item.payoptions" value="CoinBiller" :disabled="enabledPayOpts.indexOf('CoinBiller')==-1" />
              CoinBiller
            </label></th>
          </tr>
          <tr>
            <td style="text-align: left">
              <label>
                <input type="checkbox" v-model="item.payoptions" value="Bitcoin" :disabled="item.payoptions.indexOf('CoinBiller')==-1 || enabledPayOpts.indexOf('Bitcoin')==-1" />
                Bitcoin
              </label>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="notes important" v-if="internalNotes && internalNotes.agrModules">Note: {{ internalNotes.agrModules }} </div>
  </section>
  <div class="buttons">
    <a class="btn btn-large btn-plain btn-back" v-on:click.prevent="step--">Back</a>
    <a class="btn btn-large btn-success btn-next" v-on:click.prevent="nextStep">Next</a>
  </div>
</form>
<form class="form-step" v-if="false" key="step5">
  <section>
    <h2>Modules</h2>
    <div class="content-table-container">
      <table class="content-table">
        <thead>
          <tr>
            <th style="width: 80%">Module</th>
            <th style="width: 15%">Enabled</th>
          </tr>
          <tr>
            <th class="spacer" colspan="2"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style="width: 80%"></th>
            <td style="width: 15%">
              <dyn-select v-model="moduleTemplateId" :options="currentModTemplates" />
            </td>
          </tr>
          <tr v-for="(item, index) in modules">
            <td class="text-left" style="width: 45%">
              {{ item.name }}
            </td>
            <td>
              <dyn-select :options="item.mode=='Count'?optsCount:availOptEnabled" v-model="modulesEnabled[index]" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="notes important" v-if="internalNotes && internalNotes.agrModules">Note: {{ internalNotes.agrModules }} </div>
  </section>
  <div class="buttons">
    <a class="btn btn-large btn-plain btn-back" v-on:click.prevent="step--">Back</a>
    <a class="btn btn-large btn-success btn-next" v-on:click.prevent="nextStep">Next</a>
  </div>
</form>
<form class="form-step" v-if="step==4" key="step6">
  <section class="appf pfx">
    <h2>Equipment</h2>
    <div class="column-form type2">

    <template v-for="q in [item.equipment]">
      <template v-for="ti in terminals">
      <li class="intake-title">Terminals Type #{{ti}}</li>
      <li class="" style="width: calc(19.75% - 5px)">
        <label>Model</label>
        <div class="field complex">
          <validator :value="q['terminal'+ti+'Type']" reqs="">
            <dyn-select  v-model="q['terminal'+ti+'Type']" :options="equipmentOpt" /> <br />
            ${{ (equi(ti)[q['terminal'+ti+'PurchaseOpt']?'purchase':(q['terminal'+ti+'RentalOpt']?'rental':'reprogram')] || 0).toFixed(2) }}
<!--             <input type="text" v-model="q['terminal'+ti+'Type']" /> -->
          </validator>
        </div>
      </li>
      <li class="" style="width: calc(6.25% - 5px)">
        <label>QTY</label>
        <div class="field complex">
          <validator :value="q['terminal'+ti+'Qty']" reqs="">
            <input type="text" v-model="q['terminal'+ti+'Qty']" />
          </validator>
        </div>
      </li>
      <li class="" style="width: calc(12% - 5px)">
        <label>Purchase</label>
        <div class="field conditional">
          <checkbox-plain2 v-model="q['terminal'+ti+'PurchaseOpt']" v-on:input="$set(q, 'terminal'+ti+'RentalOpt', false); $set(q, 'terminal'+ti+'LeasingOpt', false); $set(q, 'terminal'+ti+'ReprogramOpt', false)" :disabled="!equi(ti).purchase" />
<!--           <span>Qty:</span> -->
<!--           <input type="text" v-model="q['terminal'+ti+'Purchase']" :disabled="!q['terminal'+ti+'PurchaseOpt']" /> -->
        </div>
      </li>
      <li class="" style="width: calc(12% - 5px)">
        <label>Rental</label>
        <div class="field conditional">
          <checkbox-plain2 v-model="q['terminal'+ti+'RentalOpt']" v-on:input="$set(q, 'terminal'+ti+'PurchaseOpt', false); $set(q, 'terminal'+ti+'LeasingOpt', false); $set(q, 'terminal'+ti+'ReprogramOpt', false)" :disabled="!equi(ti).rental" />
<!--           <span>Qty:</span> -->
<!--           <input type="text" v-model="q['terminal'+ti+'Rental']" :disabled="!q['terminal'+ti+'RentalOpt']" /> -->
        </div>
      </li>
      <li class="" style="width: calc(12% - 5px)">
        <label>Reprogram</label>
        <div class="field conditional">
          <checkbox-plain2 v-model="q['terminal'+ti+'ReprogramOpt']" v-on:input="$set(q, 'terminal'+ti+'PurchaseOpt', false); $set(q, 'terminal'+ti+'RentalOpt', false); $set(q, 'terminal'+ti+'LeasingOpt', false)" />
<!--           <span>Qty:</span> -->
<!--           <input type="text" v-model="q['terminal'+ti+'Reprogram']" :disabled="!q['terminal'+ti+'ReprogramOpt']" /> -->
        </div>
      </li>
      <li class="" style="width: calc(12% - 5px)">
        <label>Leasing</label>
        <div class="field conditional">
          <checkbox-plain2 disabled v-model="q['terminal'+ti+'LeasingOpt']" v-on:input="$set(q, 'terminal'+ti+'PurchaseOpt', false); $set(q, 'terminal'+ti+'RentalOpt', false); $set(q, 'terminal'+ti+'ReprogramOpt', false)" />
<!--           <span>Qty:</span> -->
<!--           <input type="text" v-model="q['terminal'+ti+'Leasing']" :disabled="!q['terminal'+ti+'LeasingOpt']" /> -->
        </div>
      </li>
      <li v-if="false" class="" style="width: calc(12% - 5px)">
        <label>Merchant Own</label>
        <div class="field conditional">
          <checkbox-plain2 v-model="q['terminal'+ti+'OwnOpt']" />
          <span>Qty:</span>
          <input type="text" v-model="q['terminal'+ti+'Own']" :disabled="!q['terminal'+ti+'OwnOpt']" />
        </div>
      </li>
      <li v-if="false" class="" style="width: calc(12% - 5px)">
        <label>Financing</label>
        <div class="field conditional">
          <checkbox-plain2 v-model="q['terminal'+ti+'FinancingOpt']" />
          <span>Qty:</span>
          <input type="text" v-model="q['terminal'+ti+'Financing']" :disabled="!q['terminal'+ti+'FinancingOpt']" />
        </div>
      </li>
      <li v-if="false" class="" style="width: calc(12% - 5px)">
        <label>Insurance</label>
        <div class="field conditional">
          <checkbox-plain2 v-model="q['terminal'+ti+'InsuranceOpt']" />
          <span>Qty:</span>
          <input type="text" v-model="q['terminal'+ti+'Insurance']" :disabled="!q['terminal'+ti+'InsuranceOpt']" />
        </div>
      </li>
      </template>
    </template>
    </div>
    
      <li style="width: 100%">
        <a class="terminals-del" href="" v-on:click.prevent="terminalCount--" v-if="terminalCount>1">‒</a>
        <a class="terminals-add" href="" v-on:click.prevent="terminalCount++">+</a>
      </li>


    <ul v-for="q in [intake.formData]" class="column-form type2">
      <li class="intake-title">Shipping</li>
      <li class="fwidth fwx">
        <label>Ship to</label>
        <div class="field">
          <validator :value="q.shipTo&&q.shipTo.length?q.shipTo:q.shipToOther" reqs="">
            <multiselect v-model="q.shipTo" :other.sync="q.shipToOther" :options="['Company', 'Location', 'Other']" :onlyone="true" />
          </validator>
        </div>
      </li>
      <li class="fwidth fwx">
        <label>Shipping Method</label>
        <div class="field">
          <validator :value="q.shippingMethod&&q.shippingMethod.length" reqs="">
            <multiselect v-model="q.shippingMethod" :options="['Regular', '2 Day', 'Next Day', 'Overnight']" :onlyone="true" />
          </validator>
        </div>
      </li>
      <li class="">
        <label>Ship to Other Address</label>
        <div class="field complex">
          <validator :value="q.shipOtherAddress" :reqs="q.shipTo && q.shipTo.indexOf('Other')!=-1?'required':''">
            <input type="text" v-model="q.shipOtherAddress" :disabled="!q.shipTo || q.shipTo.indexOf('Other')==-1" />
          </validator>
        </div>
      </li>
      <li class="">
        <label>City</label>
        <div class="field complex">
          <validator :value="q.shipOtherCity" :reqs="q.shipTo && q.shipTo.indexOf('Other')!=-1?'required':''">
            <input type="text" v-model="q.shipOtherCity" :disabled="!q.shipTo || q.shipTo.indexOf('Other')==-1" />
          </validator>
        </div>
      </li>
      <li class="">
        <label>Province/State</label>
        <div class="field complex">
          <validator :value="q.shipOtherState" :reqs="q.shipTo && q.shipTo.indexOf('Other')!=-1?'required':''">
            <input type="text" v-model="q.shipOtherState" :disabled="!q.shipTo || q.shipTo.indexOf('Other')==-1" />
          </validator>
        </div>
      </li>
      <li class="">
        <label>Postal Code/Zip</label>
        <div class="field complex">
          <validator :value="q.shipOtherPostal" :reqs="q.shipTo && q.shipTo.indexOf('Other')!=-1?'required':''">
            <input type="text" v-model="q.shipOtherPostal" :disabled="!q.shipTo || q.shipTo.indexOf('Other')==-1" />
          </validator>
        </div>
      </li>
      <li class="" style="width: 100%">
        <label>Instructions</label>
        <div class="field complex">
          <validator :value="q.shipOtherInstructions" :reqs="''">
            <input type="text" v-model="q.shipOtherInstructions" />
          </validator>
        </div>
      </li>
    </ul>

    
    <div class="notes important" v-if="internalNotes && internalNotes.agrEquipment">Note: {{ internalNotes.agrEquipment }} </div>
  </section>
  <div class="buttons">
    <a class="btn btn-large btn-plain btn-back" v-on:click.prevent="step--">Back</a>
    <a class="btn btn-large btn-success" v-on:click.prevent="save">Create Agreement</a>
  </div>
</form>
<form class="form-step" v-if="false" key="step7">
  <section>
    <h2>Options</h2>
    <div class="content-table-container">
      <table class="content-table">
        <thead>
          <tr>
            <th style="width: 80%">Option</th>
            <th style="width: 15%">Enabled</th>
          </tr>
          <tr>
            <th class="spacer" colspan="2"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style="width: 80%"></th>
            <td style="width: 15%">
              <dyn-select v-model="optionTemplateId" :options="currentOptTemplates" />
            </td>
          </tr>
          <tr v-for="(item, index) in options">
            <td class="text-left" style="width: 45%">
              {{ item.name }}
            </td>
            <td>
              <dyn-select :options="item.mode=='Count'?optsCount:availOptEnabled" v-model="optionsEnabled[index]" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="notes important" v-if="internalNotes && internalNotes.agrOptions">Note: {{ internalNotes.agrOptions }} </div>
  </section>
  <div class="buttons">
    <a class="btn btn-large btn-plain btn-back" v-on:click.prevent="step--">Back</a>
    <a class="btn btn-large btn-success btn-next" v-on:click.prevent="nextStep">Next</a>
  </div>
</form>
<form class="form-step" v-if="step==3" key="step8">
  <section>
    <h2>Pricing</h2>
<!--    <form class="edit-system search-form">
      <dyn-select :options="currentTemplates" v-model="templateId"  style="width: 100%; margin-bottom: 20px"  />
    </form>-->
    <pricing-details :currentTemplate="intake.formData" :solution="appl.solution" />
    <div class="notes important" v-if="internalNotes && internalNotes.agrPricing">Note: {{ internalNotes.agrPricing }} </div>
  </section>
  <div class="buttons">
    <a class="btn btn-large btn-plain btn-back" v-on:click.prevent="step--">Back</a>
    <a class="btn btn-large btn-success btn-next" v-on:click.prevent="nextStep">Next</a>
  </div>
</form>
  
  </div>
  
  
</div></div>
</template>

<script>
export default {
  props: ["applicationId"],
  data() { return {
    optsCount: [{value: '0', label: '0'}, {value: '1', label: '1'},
                {value: '2', label: '2'}, {value: '3', label: '3'},
                {value: '4', label: '4'}, {value: '5', label: '5'},
                {value: '6', label: '6'}, {value: '7', label: '7'},
                {value: '8', label: '8'}, {value: '9', label: '9'},
                {value: '10', label: '10'}, {value: '11', label: '11'},
                {value: '12', label: '12'}, {value: '13', label: '13'},
                {value: '14', label: '14'}, {value: '15', label: '15'},
                {value: '16', label: '16'}, {value: '17', label: '17'},
                {value: '18', label: '18'}, {value: '19', label: '19'},
                {value: '20', label: '20'}],
    shown: false,
    step: 0,
    item: {},
    appl: {},
    managers: [],
    internalNotes: null,
    industries: [
      {value: "Hospitality", label: "Hospitality"},
      {value: "Retail", label: "Retail"},
      {value: "Spa/Salon/Gym", label: "Spa/Salon/Gym"},
      {value: "Gas & Oil", label: "Gas & Oil"},
      {value: "E-Commerce", label: "E-Commerce"},
      {value: "Professional Services", label: "Professional Services"},
      {value: "Convenience & Supermarket", label: "Convenience & Supermarket"},
      {value: "Tourism", label: "Tourism"},
      {value: "Community Groups & Orgs", label: "Community Groups & Orgs"},
      {value: "Business to Business", label: "Business to Business"},
      {value: "Taxi & Delivery", label: "Taxi & Delivery"},
      {value: "Utilities", label: "Utilities"},
      {value: "Other", label: "Other"},
    ],
    currencies: {"840": "USD", "124": "CAD"},
    availQuantities: [
      {label: "0", value: 0}, {label: "1", value: 1}, {label: "2", value: 2},
      {label: "3", value: 3}, {label: "4", value: 4}, {label: "5", value: 5},
      {label: "6", value: 6}, {label: "7", value: 7}, {label: "8", value: 8},
      {label: "9", value: 9}, {label: "10", value: 10},
    ],
    equipment: [],
    quantities: [],
    rental: [],
    options: [],
    modules: [],
    availOptEnabled: [{label: "No", value: false}, {label: "Yes", value: true}],
    optionsEnabled: [],
    modulesEnabled: [],
    pricingTemplates: [],
    templateId: null,
    accountClasses: [],
    optionTemplates: [],
    optionTemplateId: "0",
    moduleTemplates: [],
    moduleTemplateId: "0",
    enabledPayOpts: [],
    methodTemplateId: "0",
    methodTemplates: [],
    solutions: [],
    terminalCount: 0,
    intake: {formData: {}},
    existingApp: null,
  }},
  watch: {
    "item.accClass": {
      handler() {
        var accClass=null;
        for(var i in this.accountClasses)
          if (this.accountClasses[i].id==this.item.accClass)
            accClass=this.accountClasses[i];
        if (accClass && accClass.modTemplate)
          this.moduleTemplateId=accClass.modTemplate;
        if (accClass && accClass.optTemplate)
          this.optionTemplateId=accClass.optTemplate;
        if (accClass && accClass.pricingTemplate)
          this.templateId=accClass.pricingTemplate;
        if (accClass && accClass.plan)
          this.item.plan=accClass.plan;
        if (accClass && accClass.methodTemplate)
          this.methodTemplateId=accClass.methodTemplate;
      },
      immediate: true,
    },
    templateId() {
      var that=this;
      var template=this.pricingTemplates.find(function(a) {
        return a.id==that.templateId;
      });
      if (template)
        for(var field in template)
          if (field!="id" && field!="name")
            this.$set(this.item, field, template[field]);
    },
    optionTemplateId() {
      var that=this;
      var template=this.optionTemplates.find(function(a) {
        return a.id==that.optionTemplateId;
      }) || {};
      var selected=template.selected || {};
      for(var i in this.options) {
        this.$set(this.optionsEnabled, i, this.options[i].mode=="Count"?selected[this.options[i].id]:selected[this.options[i].id]=="Yes");
      }
    },
    moduleTemplateId() {
      var that=this;
      var template=this.moduleTemplates.find(function(a) {
        return a.id==that.moduleTemplateId;
      }) || {};
      var selected=template.selected || {};
      for(var i in this.modules) {
        this.$set(this.modulesEnabled, i, this.modules[i].mode=="Count"?selected[this.modules[i].id]:selected[this.modules[i].id]=="Yes");
      }
    },
    methodTemplateId() {
      var that=this;
      var template=this.methodTemplates.find(function(a) {
        return a.id==that.methodTemplateId;
      }) || {};
      var selected=template.methods || [];
      this.$set(this.item, "payoptions", selected);
    },
  },
  async mounted() {
    this.existingApp = (await api.get('Application', this.applicationId)).item;
    window.agreementPopup=this;
    var appmanagers = (await api.list("User", {groups: 'manager-account', deleted :['<>', 'Yes']}, ['id:ASC'])).items || [];
    this.managers=appmanagers.map(function(a) {
      return {value: a.id, label: a.fullName};
    });
    this.internalNotes = (await api.get('InternalNotes', 1)).item;
    var appsolutions = (await api.list("Solution", {solutionType: 'Merchants'}, ['id:ASC'])).items || [];
    this.solutions=appsolutions.map(function(a) {
      return {value: a.id, label: a.nameSolution};
    });
    this.loadEquipment();
    this.loadPricingTemplates();
    this.loadAccountClasses();
    this.loadOptions();
    this.loadOptionTemplates();
    this.loadModules();
    this.loadModuleTemplates();
    this.loadMethodTemplates();
    this.loadSysOptions();
    this.loadIntake();
  },
  computed: {
    terminals() {
      var result=[];
      for(var i=0;i<this.terminalCount;i++)
        result.push(i+1);
      return result;
    },
    currentOptTemplates() {
      var result=this.optionTemplates.map(function(a) {
        return {value: a.id, label: a.name};
      });
      result.unshift({value: 0, label: "(custom)"});
      return result;
    },
    currentModTemplates() {
      var result=this.moduleTemplates.map(function(a) {
        return {value: a.id, label: a.name};
      });
      result.unshift({value: 0, label: "(custom)"});
      return result;
    },
    currentMethodTemplates() {
      var result=this.methodTemplates.map(function(a) {
        return {value: a.id, label: a.name};
      });
      result.unshift({value: 0, label: "(custom)"});
      return result;
    },
    currentTemplates() {
      var result=this.pricingTemplates.map(function(a) {
        return {value: a.id, label: a.name};
      });
      return result;
    },
    accountClassesList() {
      var result=this.accountClasses.map(function(a) {
        return {value: a.id, label: a.name+" "+a.description};
      });
      return result;
    },
    equipmentOpt() {
      return this.equipment.map(a=>({
        value: a.id,
        label: a.name,
        purchase: a.purchase*1 || 0,
        rental: a.rental*1 || 0,
        reprogram: a.reprogram*1 || 0,
      }));
    },
  },
  methods: {
    equi(index) {
      return this.equipmentOpt.find((a)=>a.value==this.item.equipment['terminal'+index+'Type']) || {};
    },
    solution(id) {
      for(var solution of this.solutions)
        if (id*1==solution.value*1)
          return solution.label;
      return "";
    },
    loadIntake: async function() {
      var items=(await api.list("Intake", {appId: this.appl.id}, ["id:DESC"])).items;
      if (items.length) {
        this.intake.formData=items[0].formData || {
          methods:[],
        };
        this.$set(this.intake, "id", items[0].id);
      } else {
        this.intake.formData=this.existingApp?{methods: [], ...this.existingApp}:{methods: []};
        var types={
          cardbiller: ['Visa', 'MasterCard', 'Discover', 'AMEX', 'China UnionPay', 'Interac Debit'],
          cardbillerPlus: ['Apple Pay', 'Google Pay', 'Interac Online'],
          bankbiller: ['EFT', 'ACH'],
          coinbiller: ['BitCoin'],
        };
        this.$set(this.intake.formData, "cardbiller", this.intake.formData.cardTypes.filter((a)=>types.cardbiller.indexOf(a)!=-1));
        this.$set(this.intake.formData, "cardbillerPlus", this.intake.formData.cardTypes.filter((a)=>types.cardbillerPlus.indexOf(a)!=-1));
        this.$set(this.intake.formData, "bankbiller", this.intake.formData.cardTypes.filter((a)=>types.bankbiller.indexOf(a)!=-1));
        this.$set(this.intake.formData, "coinbiller", this.intake.formData.cardTypes.filter((a)=>types.coinbiller.indexOf(a)!=-1));
        this.$set(this.intake.formData, "cardTypesOtherFlag", this.intake.formData.cardTypesOther?['']:[]);
      }
    },
    loadAccountClasses: async function() {
      this.accountClasses=(await api.list("AccountClass")).items || [];
    },
    loadPricingTemplates: async function() {
      this.pricingTemplates=(await api.list("PricingTemplate")).items || [];
    },
    loadOptionTemplates: async function() {
      this.optionTemplates=(await api.list("CompanyOptTemplate")).items || [];
    },
    loadOptions: async function() {
      this.options=(await api.list("CompanyOption", [], ["id:ASC"])).items || [];
      for(var i in this.options)
        this.optionsEnabled[i]=false;
    },
    loadModuleTemplates: async function() {
      this.moduleTemplates=(await api.list("CompanyModTemplate")).items || [];
    },
    loadMethodTemplates: async function() {
      this.methodTemplates=(await api.list("MethodTemplate")).items || [];
    },
    loadModules: async function() {
      this.modules=(await api.list("CompanyModule", [], ["id:ASC"])).items || [];
      for(var i in this.modules)
        this.modulesEnabled[i]=false;
    },
    loadEquipment: async function() {
      this.equipment=(await api.list("Equipment", {enabled: 1})).items || [];
      for(var i in this.equipment)
        this.quantities[i]=0;
    },
    loadSysOptions: async function() {
      var item=(await api.get("Options", 1)).item || {};
      this.enabledPayOpts=item.enabledMethods || [];
    },
    dismiss() {
      this.shown=false;
      this.step=0;
      this.appl={};
      this.item={};
      if (typeof(this.returnCb)=="function") {
        this.returnCb();
      }
    },
    async show(application) {
      this.shown=true;
      this.step=0;
      this.appl=application;
      this.templateId=null;
      for(var i in this.options)
        this.optionsEnabled[i]=false;
      for(var i in this.equipment)
        this.quantities[i]=0;
      this.item={
        applicationId: application.id,
        created: "CURRENT_TIMESTAMP",
        name: application.companyName+" "+application.applicationId+" "+application.accountId,
        accClass: application.classId,
        managerId: application.manager && application.manager.id,
        mcc: application.mcc,
        options: [],
        modules: [],
        equipment: {},
        plan: "Lite",
        filename: null,
        signed: null,
        industry: this.appl.industry,
        payoptions: [],
      };
      this.application=application;
      
      var intake=(await api.list("Intake", {appId: this.appl.id}, ["id:DESC"])).items[0];
      if (intake) {
        var equipmentData={};
        for(var field in intake.formData)
          if (field.startsWith("terminal"))
            equipmentData[field]=intake.formData[field];
        this.item.equipment=equipmentData;
      }
      this.loadIntake();
      
      this.terminalCount=0;
      while(this.item.equipment['terminal'+(this.terminalCount+1)+'Type']) {
        this.terminalCount++;
      }
    },
    nextStep() {
      if (!this.validate())
        return stopLoading("Please fill all required fields", "error");
      this.step++;
    },
    sendEmail: async function(type) {
      var res=await api.create("ApplicationEmail", {appId: this.appl.id, emailType: type, created: "CURRENT_TIMESTAMP"});
    },
    save: async function() {
      if (!this.templateId) {
        window.appNotifications.show("Plase select pricing template", "error");
        return;
      }
      startLoading();
      var result=JSON.parse(JSON.stringify(this.item));
      result.options=[];
      for(var i in this.options) {
        if (this.optionsEnabled[i])
          result.options.push({
            id: this.options[i].id,
            name: this.options[i].name,
            count: this.optionsEnabled[i],
          });
      }
      result.modules=[];
      for(var i in this.modules) {
        if (this.modulesEnabled[i])
          result.modules.push({
            id: this.modules[i].id,
            name: this.modules[i].name,
            count: this.modulesEnabled[i],
          });
      }
      result.equipment=this.item.equipment;
//       for(var i in this.equipment) {
//         if (this.quantities[i] || this.rental[i])
//           result.equipment.push({
//             quantity: this.quantities[i],
//             rental: this.rental[i],
//             price: this.equipment[i].purchase,
//             rentalPrice: this.equipment[i].rental,
//             id: this.equipment[i].id,
//             name: this.equipment[i].name
//           });
//       }
      await api.create("Agreement", result);
      await api.update("Application", this.application.id, {
        class: result.accClass,
        mcc: result.mcc,
        industry: result.industry,
        manager: result.managerId,
      });
      if (this.intake.id)
        await api.update("Intake", this.intake.id, {formData: this.intake.formData});
//       this.application.class=result.accClass;
      this.sendEmail("AgreementAtt");
      stopLoading("Agreement created");
      this.dismiss();
      viewApplication.listAgreements();
    },
  },
  mixins: [formValidation.mixin]
}
</script>
