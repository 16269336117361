<template>
<div>
  <div class="search-form" style="margin-bottom: 10px">
    <datepicker type="text" v-model="dateStart" :future="false" />
    <datepicker type="text" v-model="dateEnd" :future="false" />
  </div>
  <div class="content-table-container usermgmt">
    <table class="content-table usermgmt">
      <thead>
        <tr>
          <th style="width: 25%; cursor: pointer" v-on:click="sortColumn=null">Username</th>
          <th style="width: 25%; cursor: pointer" v-on:click="sortColumn='recorded'">Time</th>
          <th style="width: 25%; cursor: pointer" v-on:click="sortColumn='transferred'">Data transfer</th>
          <th style="width: 25%; cursor: pointer" v-on:click="sortColumn='ipAddress'">IP Address</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in sortedLogins">
          <td class="main-col">
            {{ users[item.userId] && users[item.userId].username }}
          </td>
          <td class="">
            {{ item.recorded }}
          </td>
          <td class="">
            {{ formatData(item.transferred) }}
          </td>
          <td class="">
            {{ item.ipAddress }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
</template>

<script>
export default {
  data() { return {
    sortColumn: null,
    users: {},
    logins: [],
    dateStart: moment().subtract(0, "days").format("YYYY-MM-DD"),
    dateEnd: moment().format("YYYY-MM-DD"),
  }},
  watch: {
    dateStart() {
      this.loadLogins();
    },
    dateEnd() {
      this.loadLogins();
    },
  },
  computed: {
    sortedLogins() {
      var sortColumn=this.sortColumn;
      if (!sortColumn)
        return this.logins;
      return this.logins.sort(function(a, b) {
        if (a[sortColumn]<b[sortColumn]) return 1;
        if (a[sortColumn]>b[sortColumn]) return -1;
        return 0;
      });
    },
  },
  methods: {
    updateMerchants: async function() {
      var merchants=await api.list("Application", {"status": ["='A03' OR base.status='A04' OR base.status=", "A02"]});
      this.merchants=merchants.items;
    },
    loadUsers: async function() {
      var res={};
      var users=(await api.list("User")).items;
      for(var i in users) {
        res[users[i].id]=users[i];
      }
      this.users=res;
    },
    loadLogins: async function() {
      this.logins=[];
      this.logins=(await api.list("UserLogin", {
        "recorded": [">=", this.dateStart+" 00:00:00"], 
        "recordedEnd": ["<=", this.dateEnd+" 23:59:59", "recorded"], 
      }, ["recorded:DESC"], 1000)).items;
    },
    formatData(bytes) {
      bytes=bytes || 0;
      if (bytes<1000)
        return bytes+" B";
      bytes/=1024;
      if (bytes<1000)
        return bytes.toFixed(3)+" kB";
      bytes/=1024;
      if (bytes<1000)
        return bytes.toFixed(3)+" MB";
      bytes/=1024;
      return bytes.toFixed(3)+" GB";
    },
  },
  mounted: async function() {
    await this.loadUsers();
    this.loadLogins();
  },
}
</script>
