<template>
<div class="file-obj">
  <a v-if="!value">(no file)</a>
  <a v-else-if="changed">{{ fileName }}</a>
  <a v-else target="_blank" :href="basepath+'/'+value">{{ fileName }}</a>
  <a class="btn-upload">
    +
    <input type="file" v-on:change="setFile" />
  </a>
  <a class="btn-remove" v-if="value" v-on:click="removeFile">&times;</a>
</div>
</template>

<script>
export default {
  props: ["value", "basepath"],
  computed: {
    fileName: function() {
      return this.value.name || this.value;
    },
  },
  data: function() { return {
    changed: false,
  }},
  methods: {
    setFile: function($e) {
      var file=$e.target.files[0];
      if (!validateFileExt(file))
        return stopLoading("This file type is not allowed", "error");

      this.$emit("input", file);
      this.changed=true;
    },
    removeFile: function($e) {
      this.changed=true;
      this.$emit("input", null);
    },
  },
}
</script>
