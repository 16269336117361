<template>
  <div class="acc-list" style="display: block">
    <table class="">
      <thead>
        <tr>
          <th style="width: 25%;">Username</th>
          <th style="width: 25%;">Time</th>
          <th style="width: 25%;">Data transfer</th>
          <th style="width: 25%;">IP Address</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in logins">
          <td class="main-col">
            {{ usersById[item.userId] && usersById[item.userId].username }}
          </td>
          <td class="">
            {{ item.recorded }}
          </td>
          <td class="">
            {{ item.ipAddress }}
          </td>
        </tr>
      </tbody>
    </table>

    <portal to="actions">
      <ul>
        <template>
            <li v-for="(optLabel, optMethod) in actions">
              <a href="" v-on:click.prevent="$parent.$parent.doAction(optMethod)">{{ optMethod }}</a>
            </li>
        </template>
      </ul>
    </portal>
  </div>
</template>

<script>
export default {
  props: ["item"],
//   mixins: [vueMixins.userProfile],
  data: ()=>({
    logins: [],
    users: [],
  }),
  computed: {
    usersById() {
      var result={};
      for(var i in this.users)
        result[this.users[i].id]=this.users[i];
      result[this.item.id]=this.item;
      return result;
    }
  },
  async mounted() {
    this.users=(window.appData.users || []).slice();
    if (this.users.length)
    this.logins=(await api.list("UserLogin", {userMainId: this.users[0].id}, ["recorded:DESC"], 10)).items;
  },
}
</script>
