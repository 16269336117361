<template>
<div class="merchant-panel">
  <div class="data-content">
    <ul class="tabs">
      <li :class="{active: dataTab==0}" v-on:click="dataTab=0">General</li>
      <li :class="{active: dataTab==1}" v-on:click="dataTab=1">Company</li>
      <li :class="{active: dataTab==2}" v-on:click="dataTab=2">Ownership</li>
      <li :class="{active: dataTab==3}" v-on:click="dataTab=3">Business</li>
      <li :class="{active: dataTab==4}" v-on:click="dataTab=4">Banking</li>
      <li :class="{active: dataTab==5}" v-on:click="dataTab=5">Contact</li>
      <li :class="{active: dataTab==6}" v-on:click="dataTab=6">Notes</li>
    </ul>
    <template v-if="isMerchant(this.item)">
    <p class="tabs">Merchant Settings</p>
    <ul class="tabs-smaller">
      <li :class="{active: dataTab==7, disabled: !merchant}" v-on:click="merchant && (dataTab=7)">Account</li>
      <li :class="{active: dataTab==8, disabled: !merchant}" v-on:click="merchant && (dataTab=8)">Methods</li>
      <li :class="{active: dataTab==9, disabled: !merchant}" v-on:click="merchant && (dataTab=9)">Modules</li>
      <li :class="{active: dataTab==10, disabled: !merchant}" v-on:click="merchant && (dataTab=10)">Options</li>
      <li :class="{active: dataTab==11, disabled: !merchant}" v-on:click="merchant && (dataTab=11)">Equipment</li>
      <li :class="{active: dataTab==12, disabled: !merchant}" v-on:click="merchant && (dataTab=12)">Pricing</li>
      <li :class="{active: dataTab==16, disabled: !merchant}" v-on:click="merchant && (dataTab=16)">Compliance</li>
      <li :class="{active: dataTab==13, disabled: !merchant}" v-on:click="merchant && (dataTab=13)">Financial</li>
      <li :class="{active: dataTab==14, disabled: !merchant}" v-on:click="merchant && (dataTab=14)">Processing</li>
      <li :class="{active: dataTab==15, disabled: !merchant}" v-on:click="merchant && (dataTab=15)">Accounting</li>    
      <li :class="{active: dataTab==17, disabled: !merchant}" v-on:click="merchant && (dataTab=17)">Access</li>    
    </ul>
    </template>
    <div class="main">
      <formview-app-main :item="item" :merchant="merchant" :tab="dataTab" :notes="notes" :messagesApi="messagesApi" :editMode.sync="editMode" :lastAgreement="lastAgreement" />
    </div>
  </div>
  <aside class="actions">
    <ul class="tabs">
      <li :class="{active: actionsTab==0}" v-on:click="actionsTab=0">General</li>
      <li :class="{active: actionsTab==1}" v-on:click="actionsTab=1">Application</li>
      <li :class="{active: actionsTab==2}" v-on:click="actionsTab=2">Documents</li>
      <li :class="{active: actionsTab==3}" v-on:click="actionsTab=3">Compliance</li>
      <li :class="{active: actionsTab==7}" v-on:click="actionsTab=7">Agreements</li>
      <li :class="{active: actionsTab==5}" v-on:click="actionsTab=5; listEmails()">Email</li>
      <li :class="{active: actionsTab==4}" v-on:click="actionsTab=4; listSetups()">Merchant Setup</li>
      <li :class="{active: actionsTab==6}" v-on:click="actionsTab=6">Accounting</li>
    </ul>
    <div class="main" v-if="actionsTab==0">
      <div class="action-buttons">
        <a href="" v-on:click.prevent="listChecklist();showFinalApproval=false">Setup Checklist</a>
        <a href="" v-on:click.prevent="uwTab=2;showFinalApproval=false">Status Update</a>
        <a href="" v-on:click.prevent="editMode=true" :class="{disabled: editMode}">Edit</a>
        <a href="" v-on:click.prevent="!negativeList && doAction(item, 'negative')" :class="{disabled: negativeList}">Negative List</a>
        <a href="" v-on:click.prevent="negativeList && doAction(item, 'removeNeg')" :class="{disabled: !negativeList}">Remove Neg List</a>
        <a href="" :class="{disabled: !(isMerchant(item) && !item.followup)}" v-on:click.prevent="(isMerchant(item) && !item.followup) && doAction(item, 'followUp')">Follow-up</a>
        <a href="" :class="{disabled: !(isMerchant(item) && item.followup)}" v-on:click.prevent="(isMerchant(item) && !item.followup) && doAction(item, 'removeFup')">No follow-up</a>
      </div>
      <view-app-setupcl v-if="uwTab==3" key="setupcl" :prechecks="uwList" :weblist="webList" :item="item" :merchant="merchant" />
      <view-app-finalcheck key="finalcheck" :uwList="uwListFinal" v-if="showFinalApproval" />
      <div v-if="uwTab==2">
        <div class="action-sub-buttons">
          <a href="" v-for="(optLabel, optMethod) in allActions" v-on:click.prevent="!isDisabledAction(optMethod) && doAction(item, optMethod)" :class="{disabled: isDisabledAction(optMethod)}">{{ optLabel }}</a>
          <a href="" v-on:click.prevent="" class="disabled">Hold Account</a>
          <a href="" v-on:click.prevent="" class="disabled">Close Account</a>
          <a href="" v-on:click.prevent="" class="disabled">App reminder</a>
        </div>

        <table class="file-list content-table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Status Update</th>              
              <th>Updated By</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="uwItem in notes" v-if="uwItem.system && uwItem.message.indexOf('Updated to ')==0">
              <td>{{ formatDate(uwItem.created, "YYYY-MM-DD h:mm a") }}</td>
              <td>{{ uwItem.message.substring(11) }}</td>              
              <td>{{ uwItem.author.username }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="main" v-if="actionsTab==1">
      <div class="action-buttons">
        <a href="" v-on:click.prevent="viewApp=true" v-if="!viewApp">View</a>
        <a href="" v-on:click.prevent="uploadFile(signedApplicationField)" v-if="!viewApp">Upload signed</a>
        <a href="" v-on:click.prevent="viewApp=false" v-else>Back</a>
      </div>
      <div class="content-table-container file-list-container" v-if="!viewApp">
      <table class="file-list content-table">
        <thead>
          <tr>
            <th></th>
            <th>AppID</th>
            <th>AccountID</th>
            <th>Submitted</th>
            <th>Status</th>
            <th>Legal Name</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><input type="radio" checked></td>
            <td>{{ item.applicationId }}</td>
            <td>{{ item.accountId }}</td>
            <td>{{ formatDate(item.submitDate, "YYYY-MM-DD") }}</td>
            <td>{{ item.status }}</td>
            <td>{{ item.companyName }}</td>
          </tr>
          <tr>
            <td colspan="3">
              <a target="_blank" :href="appPdfUrl" style="display: block">Completed Merchant Application</a>
            </td>
            <td colspan="3">
              <a target="_blank" :href="appPdfUrl" style="display: block">{{ item.applicationId }} {{ item.companyName }} {{ item.companyType }} {{ formatDate(item.submitDate, "YYYY-MM-DD hh:mm") }}</a>
            </td>
          </tr>
          <tr v-for="signedDoc in signedApplications">
            <td colspan="3">
              <a target="_blank" :href="fileLink(signedDoc.filename, signedDoc.id)" style="display: block">Signed Merchant Application</a>
            </td>
            <td colspan="3">
              <a target="_blank" :href="fileLink(signedDoc.filename, signedDoc.id)" style="display: block">{{ item.applicationId }} {{ item.companyName }} {{ formatDate(signedDoc.created, "YYYY-MM-DD hh:mm") }}</a>
            </td>
          </tr>
        </tbody>
      </table>
      </div>
      <div class="app-details" v-else>
        <p><label>Application ID:</label> {{ item.applicationId }}</p>
        <p><label>Account ID:</label> {{ item.accountId }}</p>
        <p><label>Submitted Date:</label> {{ formatDate(item.submitDate, "YYYY-MM-DD") }}</p>
        <p><label>Status:</label> {{ item.status }}</p>
        <p><label>Legal Name:</label> {{ item.companyName }}</p>
      </div>
    </div>
    <div class="main" v-else-if="actionsTab==2">
      <div class="action-buttons">
        <a href="" v-on:click.prevent="newFile=true">New File</a>
        <a href="" v-on:click.prevent="uploadFile()" :class="{disabled: !selectedFile || fileFields[selectedFile].indexOf('*')!=-1}">Upload</a>
      </div>
      <div class="content-table-container file-list-container">
      <table class="file-list content-table">
        <thead>
          <tr>
            <th></th>
            <th>Type</th>
            <th>Filename</th>
            <th>Uploaded</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="newFile">
            <td><a class="close" v-on:click.prevent="newFile=false">&times;</a></td>
            <td><input v-model="newFileType" style="width: 100%" /></td>
            <td>
              <input type="file" ref="newFileFile" style="width: 100%"/>
            </td>
            <td>
              <button v-on:click.prevent="uploadNew">Upload</button>
            </td>
          </tr>
          <tr v-for="file in item.customDocuments">
            <td><input type="radio" v-model="selectedFile" :value="'custom'+file.id"></td>
            <td>{{ file.type }}</td>
            <td>
              <a :href="fileLinkCustom(file.filename, file.id)" target="_blank">{{ file.filename || "(no file)" }}</a>
            </td>
            <td>{{ formatDate(file.created, "YYYY-MM-DD") }}</td>
          </tr>
          <tr>
            <td></td>
            <td>Completed Merchant Application</td>
            <td>
              <a :href="appPdfUrl" target="_blank">{{ item.applicationId }} - {{ item.companyName }}</a>
            </td>
            <td>{{ formatDate(item.submitDate, "YYYY-MM-DD") }}</td>
          </tr>
          <tr v-for="(fileLabel, fileField) in fileFields">
            <td><input type="radio" v-model="selectedFile" :value="fileField"></td>
            <td>{{ fileLabel }}</td>
            <td>
              <a :href="fileLink(documents[fileField] && documents[fileField].filename, documents[fileField] && documents[fileField].id)" target="_blank">{{ documents[fileField] && documents[fileField].filename || "(no file)" }}</a>
            </td>
            <td>{{ formatDate(documents[fileField] && documents[fileField].created, "YYYY-MM-DD") }}</td>
          </tr>
        </tbody>
      </table>
      </div>
    </div>
    <div class="main" v-if="actionsTab==3">
      <div class="action-buttons">
        <a href="" v-on:click.prevent="" class="disabled">Merchant Checklist</a>
        <a href="" v-on:click.prevent="uwTab=5">Merchant Submission Form</a>
        <a href="" v-on:click.prevent="listPreChecks">Pre-Checks</a>
        <a href="" v-on:click.prevent="listWebsiteChecks">Website Check</a>
        <a v-if="false" href="" v-on:click.prevent="listDocChecks">Doc-Checks</a>
<!--         <a href="" v-on:click.prevent="" class="disabled">Match/VMAS</a> -->
<!--         <a href="" v-on:click.prevent="" class="disabled">OFAC</a> -->
<!--         <a href="" v-on:click.prevent="" class="disabled">KYC</a> -->
<!--         <a href="" v-on:click.prevent="" class="disabled">AML</a> -->
        <a href="" v-on:click.prevent="toBeReviewed && startReview()" :class="{disabled: !toBeReviewed}">Start Account Review</a>
        <a href="" v-on:click.prevent="uwTab=4" class="">Risk Exposure Calculator</a>
        <a href="" v-on:click.prevent="listG2">G2</a>
        <a href="" v-on:click.prevent="listIDM" class="">Acuant</a>
<!--         <a href="" v-on:click.prevent="" class="disabled">Acuant</a> -->
      </div>
      <view-app-precheck v-if="uwTab==1" key="precheck" :uwList="uwList" />
      <view-app-websitecheck v-if="uwTab==2" key="websitecheck" :uwList="webList" />
      <view-app-doccheck v-if="uwTab==3" key="doccheck" :uwList="docList" />
      
      <div class="action-sub-buttons" v-if="uwTab==10">
        <a class="btn" v-on:click.prevent="uwTabSub=1">KYC Reports</a>
        <a class="btn" v-on:click.prevent="uwTabSub=0">G2 Monitoring</a>
      </div>
      
      <view-app-extcheck type="g2" v-if="uwTab==10 && uwTabSub==1" key="extcheckg2" :uwList="checkList" />
      <view-app-extcheck type="idm" v-if="uwTab==11" key="extcheckidm" :uwList="checkList" v-on:update="listIDM" />
      <div v-if="reviewStarted" key="review">
        <div class="action-sub-buttons">
          <a style="margin-left: auto" class="btn" v-on:click="resetReview(); reviewStarted=false">Confirm Review</a>
        </div>
        <h3>Account Compliance Review</h3>
        <ul>
          <li>Pre-Check</li>
          <li>IDM Review Scan</li>
          <li>G2 Review Scan</li>
        </ul>
      </div>
      <div v-if="uwTab==4">
        <view-app-risk :item2="item"></view-app-risk>
      </div>
      <div v-if="uwTab==5">
        <view-app-intake :item="item"></view-app-intake>
      </div>
    </div>
    <div class="main" v-if="actionsTab==4">
      <div class="action-buttons">
        <a href="" v-on:click.prevent="isMerchantSetupCompleted && openAccountConfirmation()" :class="{disabled: !isMerchantSetupCompleted}">Account Confirmation</a>
        <a href="" v-on:click.prevent="item.hasIntake*1 && startSetup()" :class="{disabled: !item.hasIntake*1}">Start New Setup</a>
        <a href="" v-on:click.prevent="currentSetup && !currentSetup.status && resumeSetup()" :class="{disabled: !currentSetup || currentSetup.status}">Continue Setup</a>
        <a href="" v-on:click.prevent="currentSetup && currentSetup.status && viewSetup()" :class="{disabled: !currentSetup || !currentSetup.status}">View Setup</a>        
      </div>
      <table class="content-table file-list">
        <thead>
          <tr>
            <th style="width: 1%">#</th>
            <th>Build Date</th>
            <th>Build Id</th>
            <th>Build By</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(setup, ai) in setups">
            <td><input type="radio" checked v-model="currentSetupId" :value="setup.id"></td>
            <td>{{ formatDate(setup.created, "YYYY-MM-DD hh:mm") }}</td>
            <td>{{ setup.buildId }}</td>
            <td>{{ setup.id }}</td>
            <td>{{ setup.status || "Started" }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="main" v-if="actionsTab==5">
      <div class="action-buttons">
        <a href="" v-on:click.prevent="" class="disabled">Pre-Approval</a>
        <a href="" v-on:click.prevent="sendEmail('ApplicationReminder')">Application Reminder</a>
        <a href="" v-on:click.prevent="sendEmail('ApplicationAtt')">Applications</a>
        <a href="" v-on:click.prevent="sendEmail('Declined')">Declined</a>
        <a href="" v-on:click.prevent="sendEmail('AgreementAtt')">Agreements</a>
        <a href="" v-on:click.prevent="sendEmail('Approved')">Approved</a>
        <a href="" v-on:click.prevent="">Merchant Welcome</a>
<!--         <a href="" v-on:click.prevent="">Gateway Info</a> -->
        <a href="" :class="{disabled: !approvedSent || item.status=='M00'}" v-on:click.prevent="approvedSent && item.status!='M00' && sendEmail('ConfigSetup')">Account Confirmation</a>
        <a href="" :class="{disabled: agreementSigned}"  v-on:click.prevent="!agreementSigned && sendEmail('Questionnaire')">Questionnaire</a>
      </div>
      <table class="file-list content-table">
        <thead>
          <tr>
            <th>Type</th>
            <th>Created</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="email in emails">
            <td>{{ email.emailType }}</td>
            <td>{{ formatDate(email.created, "YYYY-MM-DD HH:mm:ss") }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="main" v-if="actionsTab==6">
      <div class="action-buttons">
        <a href="" v-on:click.prevent="">Generate New</a>
      </div>
    </div>
    <div class="main" v-if="actionsTab==7">
      <div class="action-buttons">
        <a href="" v-on:click.prevent="item.hasIntake*1 && startAgreement()" :class="{disabled: !item.hasIntake*1}">Start Agreement</a>
      </div>
      <table class="content-table file-list">
        <thead>
          <tr>
            <th style="width: 1%">#</th>
            <th>Date Created</th>
            <th>Agreement</th>
            <th>Date Signed</th>
            <th>Signed Agreement</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(agreement, ai) in agreements">
            <td>{{ ai+1 }}</td>
            <td>{{ formatDate(agreement.created, "YYYY-MM-DD hh:mm") }}</td>
            <td><a target="_blank" :href="agreementPath(agreement)">{{ agreement.name }}</a></td>
            <td><a target="_blank" v-if="agreement.filename" :href="fileLink(agreement.filename)">{{ agreement.name+' signed' }}</a></td>
            <td>{{ formatDate(agreement.signed, "YYYY-MM-DD hh:mm") }}</td>
            <td><a href="" class="button" v-on:click.prevent="uploadSigned(agreement, ai)">Upload</a></td>
          </tr>
        </tbody>
      </table>
    </div>
  </aside>
</div>
</template>


<script>
export default {
  data() { return {
    dataTab: 0,
    actionsTab: 0,
    uwTab: 0,
    uwTabSub: 0,
    uwList: [],
    webList: [],
    docList: [],
    checkList: [],
    uwListFinal: [],
    item: {},
    merchant: null,
    notes: [],
    messagesApi: [],
    actions: {},
    allActions: {},
    documents: {},
    emails: [],
    agreements: [],
    newFile: false,
    newFileType: "",
    selectedFile: null,
    negativeList: null,
    viewApp: false,
    editMode: false,
    showFinalApproval: false,
    reviewStarted: false,
    setups: [],
    currentSetupId: null,
    fileFields: {
      signedApplication: "Signed Merchant Application*",
      pParent: "Parent company incorporated documents",
      incor: "Incorporated documents",
      passport: "Passport copy",
      utility: "Recent Utility Bill",
      statement: "3 Months Company Bank Statement",
      voidCheck: "Void Check",
      processing: "5 Months Processing Statement",
      govId: "Government Issued ID",
      structure: "Corporate Structure Diagram",
      financial: "Financial Statements",
      licenses: "Copy of License(s)",
      pciDss: "PCI DSS Certificate",
      plan: "Business Plan with Projections",
      bankStatement: "Bank Statement",
      padAuth: "Sample PAD Authorization",
//       motoQuestionnaire: "MOTO/Internet Questionnaire",
      questionnaire: "MOTO/Internet Questionnaire",
      marketingMaterials: "Marketing Materials",
      businessSummary: "Complete Business Summary",
      personalGuarantee: "Personal Guarantee",
      businessSummary2: "Complete Business Summary",
    },
    solutions: appData.solutions || [],
    agents: appData.agents || [],
  }},
  mounted() {
    if (window.appData.negativeList)
      this.negativeList=JSON.parse(JSON.stringify(window.appData.negativeList));
    if (window.appData.existingApp)
      this.item=JSON.parse(JSON.stringify(window.appData.existingApp));
    if (window.appData.existingMerch)
      this.merchant=JSON.parse(JSON.stringify(window.appData.existingMerch));
    if (window.appData.existingAppDocuments)
      this.documents=JSON.parse(JSON.stringify(window.appData.existingAppDocuments));
    if (window.appData.actions)
      this.actions=JSON.parse(JSON.stringify(window.appData.actions));
    if (window.appData.allActions)
      this.allActions=JSON.parse(JSON.stringify(window.appData.allActions));
    if (window.appData.notes)
      this.notes=JSON.parse(JSON.stringify(window.appData.notes));
    if (window.appData.messagesApi)
      this.messagesApi=JSON.parse(JSON.stringify(window.appData.messagesApi));
    this.listAgreements();
    window.viewApplication=this;
  },
  methods: {
    isDisabledAction(method) {
      if (method=="A01" || method=="A00" || method=="A04")
        return true;
      for(var i in this.actions[this.item.status])
        if (i==method)
          return false;
      return true;
    },
    startAgreement() {
      agreementPopup.show(this.item);
    },
    agreementPath(agreement) {
      return `applications/agreement/${agreement.id}/pdf`;
    },
    uploadSigned(agreement, index) {
      var that=this;
      var input=$("<input />", {type: "file"});
      input.on("change", async function(e) {
        var file=this.files[0];
        if (!window.validateFileExt(file))
          return stopLoading("Invalid file extension", "error");
        startLoading();
        await api.update("Agreement", agreement.id, {signed: "CURRENT_TIMESTAMP"});
        await api.upload("Agreement", agreement.id, file);
        that.$set(that.agreements, index, (await api.get("Agreement", agreement.id)).item);
        stopLoading("File uploaded");
      });
      input.click();
    },
    isMerchant(item) {
      if (this.merchant)
        return (this.merchant.status || "").startsWith("M");
      return (item.status || "").startsWith("M");
    },
    doAction: async function(item, targetMethod) {
      if (targetMethod=="A03") {
//         var error="";
        var classId=item.classId || item.class && item.class.id;
        if (!classId)
          return appErrors.show("Cannot proceed", "Account class is not assigned");
        var managerId=item.managerId || item.manager && item.manager.id;
        if (!item.managerId)
          return appErrors.show("Cannot proceed", "Account Manager is not assigned");
        var sourceAgentId=item.sourceId || item.source && item.source.id;
        if (!sourceAgentId)
          return appErrors.show("Cannot proceed", "Source Agent is missing");
        if (!this.agent(sourceAgentId))
          return appErrors.show("Cannot proceed", "Source Agent is missing");
        if (!(await api.list("Intake", {appId: this.item.id})).items.length)
          return appErrors.show("Cannot proceed", "Merchant profile form is not submitted");
        if (!item.mcc)
          return appErrors.show("Cannot proceed", "Mcc code is not selected");
        if (!item.accountType)
          return appErrors.show("Cannot proceed", "Account type is not set");
//         if (!item.merchantType)
//           return appErrors.show("Cannot proceed", "Merchant type is not set");
        if (!item.language)
          return appErrors.show("Cannot proceed", "Language is not set");
      }
      if (targetMethod=="A04") {
        this.showFinalApproval=true;
        this.listFinalChecks();
        return;
      }
      if (targetMethod=="A05") {
        await this.sendEmail("Declined");
      }
      startLoading();
      if (targetMethod=="negative") {
        var id=(await api.create("NegativeList", {application: item.id})).id;
        stopLoading("Added to negative list");
        this.negativeList=(await api.get("NegativeList", id)).item;
      } else if (targetMethod=="removeNeg") {
        await api.delete("NegativeList", this.negativeList.id);
        stopLoading("Removed from negative list");
        this.negativeList=null;
      } else if (targetMethod=="followUp") {
        await api.update("Application", item.id, {followup: "CURRENT_TIMESTAMP"});
        item.followup=true;
        stopLoading("Added to follow up");
      } else if (targetMethod=="removeFup") {
        await api.update("Application", item.id, {followup: null});
        item.followup=null;
        stopLoading("Removed from follow up");
      } else {
        if (targetMethod=="A05" && item.status=="A04") {
          if (!confirm("Are you sure you want to delete all related merchant data?")) {
            stopLoading();
            return;
          }
          await this.deleteRelatedMerchant(item);
        }
        await api.update("Application", item.id, {status: targetMethod});
        item.status=targetMethod;
        stopLoading("Status updated");
        if (targetMethod=="A05") {
          location.replace("/applications/"+item.id);
        }
      }
    },
    deleteRelatedMerchant: async function(item) {
      var merchants=(await api.list("Merchant", {app: item.id})).items;
      for(var i in merchants)
        api.delete("Merchant", merchants[i].id);
    },
    fileLink(fileName, docId) {
      if (!fileName)
        return null;
      if (docId)
        return "download/ApplicationDocument/"+docId;
      return "files/application/"+this.item.id+"/"+fileName;
    },
    fileLinkCustom(fileName, docId) {
      if (!fileName)
        return null;
      if (docId)
        return "download/ApplicationCustomDocument/"+docId;
      return "files/application/"+this.item.id+"/"+fileName;
    },
    uploadNew: async function() {
      var file=this.$refs.newFileFile.files[0];
      if (file) {
        startLoading();
        if (!window.validateFileExt(file))
          return stopLoading("Invalid file extension", "error");
        var id=(await api.create("ApplicationCustomDocument", {appId: this.item.id, type: this.newFileType, created: "CURRENT_TIMESTAMP"})).id;
        if (id) {
          await api.upload("ApplicationCustomDocument", id, file);
          this.item.customDocuments=(await api.get("Application", this.item.id)).item.customDocuments;
        }
        stopLoading("File uploaded");
        this.newFile=false;
        this.newFileType="";
      }
    },
    uploadFile(field=null) {
      if (field)
        this.selectedFile=field;
      var that=this;
      var input=$("<input />", {type: "file"});
      input.on("change", async function(e) {
        var file=this.files[0];
        startLoading();
        if (!window.validateFileExt(file))
          return stopLoading("Invalid file extension", "error");
        if ((that.selectedFile || "").startsWith("custom")) {
          var id=that.selectedFile.substring(6);
          await api.update("ApplicationCustomDocument", id, {created: "CURRENT_TIMESTAMP"});
          await api.upload("ApplicationCustomDocument", id, file);
          that.item.customDocuments=(await api.get("Application", that.item.id)).item.customDocuments;          
        } else {
          if (that.documents[that.selectedFile]) {
            await api.update("ApplicationDocument", that.documents[that.selectedFile].id, {created: "CURRENT_TIMESTAMP"});
            await api.upload("ApplicationDocument", that.documents[that.selectedFile].id, file);
            that.documents[that.selectedFile]=(await api.get("ApplicationDocument", that.documents[that.selectedFile].id)).item;
          } else {
            var id=(await api.create("ApplicationDocument", {appId: that.item.id, type: that.selectedFile, created: "CURRENT_TIMESTAMP"})).id;
            if (id) {
              await api.upload("ApplicationDocument", id, file);
              that.$set(that.documents, that.selectedFile, (await api.get("ApplicationDocument", id)).item);
            }
          }
        }
        stopLoading("File uploaded");
        
      });
      input.click();
    },
    listSetups: async function() {
      this.setups=(await api.list("MerchantSetup", {appid: this.item.id})).items;
    },
    listPreChecks: async function() {
      this.uwTab=1;
      this.uwList=(await api.list("PreCheck", {applicationId: this.item.id}, ["id:DESC"])).items || [];
    },
    listFinalChecks: async function() {
      this.uwTab=1;
      this.uwListFinal=(await api.list("ApprovalCheck", {applicationId: this.item.id}, ["id:DESC"])).items || [];
    },
    listWebsiteChecks: async function() {
      this.uwTab=2;
      this.webList=(await api.list("WebsiteCheck", {appId: this.item.id}, ["id:DESC"])).items || [];
    },
    listDocChecks: async function() {
      this.uwTab=3;
      this.docList=(await api.list("DocCheck", {applicationId: this.item.id}, ["id:DESC"])).items || [];
    },
    listG2: async function() {
      this.uwTab=10;
      this.checkList=[];
      this.checkList=(await api.list("ExtCheck", {applicationId: this.item.id, type: "g2"}, ["id:DESC"])).items || [];
    },
    listIDM: async function() {
      this.uwTab=11;
      this.checkList=[];
      this.checkList=(await api.list("ExtCheck", {applicationId: this.item.id, type: "idm"}, ["id:DESC"])).items || [];
    },
    listEmails: async function() {
      this.emails=(await api.list("ApplicationEmail", {appId: this.item.id}, ["id:DESC"])).items || [];
    },
    listAgreements: async function() {
      this.agreements=(await api.list("Agreement", {applicationId: this.item.id}, ["id:DESC"])).items || [];
    },
    updateNotes: async function() {
      const filter = {
        type: 'Application',
        parentId:  this.item.id
      }
      this.notes=(await api.list("Note", filter, ["id:ASC"])).items || [];
    },
    sendEmail: async function(type) {
      startLoading();
      var res=await api.create("ApplicationEmail", {appId: this.item.id, emailType: type, created: "CURRENT_TIMESTAMP"});
      stopLoading("Email sent");
      var newItem=await api.get("ApplicationEmail", res.id);
      if (newItem.item)
        this.emails.unshift(newItem.item);
    },
    startReview: async function() {
      this.reviewStarted=true;
    },
    resetReview: async function() {
      if (!this.merchant || !this.merchant.id)
        return;
      startLoading();
      var reviewDays=365;
      
      var lastAgreement=(await api.list("Agreement", {applicationId: this.item.id}, ["id:DESC"])).items[0];
      if (lastAgreement) {
        var accClass=(await api.get("AccountClass", lastAgreement.accClass));
        if (accClass.reviewDays)
          reviewDays=accClass.reviewDays;
      }
      
      var res=await api.update("Merchant", this.merchant.id, {reviewDate: "", reviewDays: reviewDays});
      stopLoading("Review timer reset");
      this.merchant=(await api.get("Merchant", this.merchant.id)).item;
    },
    solution(id) {
      for(var solution of this.solutions)
        if (id*1==solution.value*1)
          return solution.label;
      return "";
    },
    agent(id) {
      for(var solution of this.agents)
        if (id*1==solution.id*1)
          return solution.id;
      return "";
    },
    listChecklist: async function() {
      this.uwTab=3;
      this.uwList=(await api.list("PreCheck", {applicationId: this.item.id}, ["id:DESC"])).items || [];
      this.webList=(await api.list("WebsiteCheck", {appId: this.item.id}, ["id:DESC"])).items || [];
    },
    viewSetup() {
      // TODO
      appNotifications.show("Not yet implemented", "error");
    },
    async startSetup() {
      var id=(await api.create("MerchantSetup", {
        merchant: this.merchant.id,
        app: this.item.id,
        created: "CURRENT_TIMESTAMP",
        buildId: "APP_ID_GEN_MERCHANTBUILD",
        setupData: {accountId: this.item.accountId},
      })).id;
      window.open("implementation/merchantsetup/"+id, "_self");
    },
    async resumeSetup() {
      if (!this.currentSetup)
        return;
      window.open("implementation/merchantsetup/"+this.currentSetup.id, "_self");
    },
  },
  computed: {
    currentSetup() {
      return this.setups.find(a=>a.id==this.currentSetupId);
    },
    isMerchantSetupCompleted() {
      if (!this.merchant)
        return false;
      return !!this.merchant.methodPos ||
             !!this.merchant.methodMobile ||
             !!this.merchant.methodApi ||
             !!this.merchant.methodCheckout ||
             !!this.merchant.methodVirtual;
    },
    agreementSigned() {
      for(var agr of this.agreements) {
        if (agr.signed)
          return true;
      }
    },
    lastAgreement() {
      return this.agreements && this.agreements[0] || {};
    },
    toBeReviewed() {
      if (this.merchant && this.merchant.reviewDate) {
        var m=moment(this.merchant.reviewDate).add(serverDiff, "seconds");
        return m.diff(moment())<0;
      }
      return false;
    },
    approvedSent() {
      for(var i in this.emails)
        if (this.emails[i].emailType=='Approved')
          return true;
      return false;
    },
    signedApplications() {
      var result=[];
      for(var i=0;i<50;i++) {
        if (this.documents['signedApplication'+(i?i:"")])
          result.push(this.documents['signedApplication'+(i?i:"")]);
      }
      return result;
    },
    signedApplicationField() {
      var i=0;
      while(this.documents['signedApplication'+(i?i:"")])
        i++;
      return "signedApplication"+(i?i:"");
    },
    appPdfUrl() {
      return location.href+"/pdf";
    },
    signedPdfUrl() {
      if (!this.documents.signedApplication)
        return "";
      return this.fileLink(this.documents.signedApplication.filename);
    },
    openAccountConfirmation() {
      window.open("/applications/"+this.item.id+"/confirmation", "_blank");
    },
  },
  mixins: [vueMixins.dateFormatter],
}
</script>
