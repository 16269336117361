<template>
  <div>
    <tabs>
      <tab title="Profile" :active="open === 0" @click="open = 0" />
      <tab title="Login activity" :active="open === 1" @click="open = 1" />
    </tabs>

    <ul class="up-acc">
      <li v-if="open === 0" class="open">
        <div class="title">Admin Profile</div>
        <div class="content">
          <div class="general">
            <div class="uinfo">
              <ul class="ui-prop">
                <li>
                  <label>Username</label>
                  <div>{{ profile.username }}</div>
                </li>
                <li>
                  <label>Role</label>
                  <div>{{ groups[profile.groups] }}</div>
                </li>
              </ul>
              <a href="" class="tool-btn" @click.prevent="resetPassword"
                >Reset password</a
              >
            </div>
            <aside></aside>
          </div>
          <ul class="mo-info">
            <li>
              <ul>
                <li>
                  <label>Name:</label>
                  <div>{{ profile.fullName }}</div>
                </li>
                <li>
                  <label>Email:</label>
                  <div>{{ profile.email }}</div>
                </li>
                <li>
                  <label>Phone:</label>
                  <div>{{ profile.phone }}</div>
                </li>
                <li>
                  <label>Phone Ext:</label>
                  <div>{{ profile.phoneExt }}</div>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </li>

      <li v-if="open === 1" class="open">
        <div class="title">Login activity</div>
        <div class="acc-list">
          <table class="">
            <thead>
              <tr>
                <th style="width: 33%">Username</th>
                <th style="width: 33%">Time</th>
                <th style="width: 34%">IP Address</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in logins" :key="index">
                <td class="main-col">
                  {{ profile.username }}
                </td>
                <td class="">
                  {{ item.recorded }}
                </td>
                <td class="">
                  {{ item.ipAddress }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import Tab from "./components/tabs/Tab";
import Tabs from "./components/tabs/Tabs";

export default {
  components: {
    Tab,
    Tabs,
  },

  data() {
    return {
      open: 0,
      profile: {},
      groups: window.userGroups,
    };
  },
  mounted() {
    this.profile = window.userProfile;
  },
  mixins: [vueMixins.userProfile],
};
</script>
