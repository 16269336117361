<template>
<div class="form-popup popup-detail activex" :class="{ active: value }" v-if="value">
  <div class="ncontent appnat" :style="autoWidth ? 'width: auto;' : null">
    <div class="title"><a class="close" @click.prevent="$emit('close')">&times;</a></div>
    <slot />
  </div>
</div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true
    },
    
    autoWidth: {
      type: Boolean,
      default: false
    }
  }
}
</script>
