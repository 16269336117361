<template>
<div class="boarding-container">
  <aside class="boarding-steps">
    <ul>
      <li v-for="(stepLabel, si) in steps" :class="{current: step==si, available: si<=doneSteps}">
        <a href="" v-on:click.prevent="goToStep(si)">{{ stepLabel }}</a>
      </li>
    </ul>
  </aside>
  <section class="boarding-main">
    <merchant-boarding-profile v-if="step==0" v-on:next="goToNext" :item="item" />
    <app-profile-popup :inline="true" v-if="step==1" v-on:next="goToNext" :item="item" />
    <merchant-boarding-documents v-if="step==2" v-on:next="goToNext" v-on:back="goToStep(1)" :item="item" />
    <merchant-boarding-compliance v-if="step==3" v-on:next="goToNext" v-on:back="goToStep(2)" :item="item" />
    <view-app-finalcheck :inline="true" key="finalcheck" :uwList="uwListFinal" v-if="step==4" v-on:back="goToStep(3)" v-on:next="goToNext"  />

<!--     <partner-boarding-precheck v-if="step==3" v-on:next="goToNext" :item="item" :precheckList="precheckList" /> -->
<!--     <partner-boarding-final v-if="step==4" v-on:next="goToNext" :item="item" :approvalList="approvalList" /> -->
    <merchant-boarding-boarding v-if="step==5" :item="item" v-on:next="goToNext" v-on:back="goToStep(4)" :merchant="merchant" />
    <div v-if="step==6">
      Boarding completed
    </div>
  </section>
</div>
</template>

<script>
export default {
  props: ["merchant", "item", "notes", "precheckList", "approvalList"],
  data: ()=>({
    steps: [
      "Merchant Submission",
      "Settings",
      "Documents",
      "Compliance",
      "Approval",
      "Boarding"
    ],
    step: 0,
    done: [],
    uwListFinal: [],
  }),
  computed: {
    doneSteps() {
      return this.done.length;
    },
    boardingProgress() {
      return this.item.boardingprogress || {step: 0};
    },
  },
  watch: {
    step() {
      if (this.step==4) {
        this.listFinalChecks();
      }
    },
  },
  methods: {
    goToStep(index) {
      if (this.doneSteps>=index)
        this.step=index;
    },
    async goToNext() {
      this.step++;
      if (this.step>this.done.length) {
        this.done.push("");
        this.item.boardingprogress=this.item.boardingprogress || {step: 0}
        this.item.boardingprogress.step=this.step;
        startLoading();
        await api.update("Application", this.item.id, {boardingprogress: this.item.boardingprogress});
        stopLoading();
      }
    },
    async listFinalChecks() {
      this.uwListFinal=(await api.list("ApprovalCheck", {applicationId: this.item.id}, ["id:DESC"])).items || [];
    },

  },
  mounted() {
//     if (location.hash=="#boarding/documents")
//       this.goToNext();
    while(this.boardingProgress.step>this.step) {
      this.step++;
      if (this.step>this.done.length)
        this.done.push("");
    }
  },
}
</script>
