<template>
    <div>
        <ul>
            <li><a @click.prevent="termsModal = true">Terms of Service</a></li>
            <li><a @click.prevent="privacyModal = true">Privacy Policy</a></li>
        </ul>

        <popup :open.sync="termsModal">
            <div class="privacy-popup scrollable">
                <terms-popup-footer></terms-popup-footer>
            </div>
        </popup>
        <popup :open.sync="privacyModal" >
            <div class="privacy-popup scrollable">
                <privacy-popup-footer></privacy-popup-footer>
            </div>
        </popup>
    </div>
</template>

<script>
import Popup from '../components/popup'
import PrivacyPopupFooter from './PrivacyPopupFooter.vue'
import TermsPopupFooter from './TermsPopupFooter.vue'

export default {
    components: {
        Popup,
        PrivacyPopupFooter,
        TermsPopupFooter
    },

    data: ()=>({
        termsModal: false,
        privacyModal: false,
    }),
}
</script>
<style lang="scss" scoped>
    a {
        cursor: pointer;
    }
  </style>