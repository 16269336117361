<template>
<div>
  <div class="content-table-container file-list-container">
    <table class="file-list content-table">
      <tbody>
        <tr>
          <td>Website Check</td>
          <td class="partner-boarding-doc-actions">
            <button v-on:click.prevent="startWebsite">Start</button>
          </td>
          <td v-if="websiteStatus">
            {{ websiteStatus }}
          </td>
          <td v-else>Pending</td>
        </tr>
        <tr>
          <td>KYC Boarding Report</td>
          <td class="partner-boarding-doc-actions">
            <button v-on:click.prevent="startG2">Start</button>
          </td>
          <td v-if="g2Status">
            {{ g2Status }}
          </td>
          <td v-else>Pending</td>
        </tr>
        <tr>
          <td>Boarding Scans</td>
          <td class="partner-boarding-doc-actions">
            <button v-on:click.prevent="startAcuant">Start</button>
          </td>
          <td v-if="acuantStatus">
            {{ acuantStatus }}
          </td>
          <td v-else>Pending</td>
        </tr>
        <tr>
          <td>Risk Exposure Calculator</td>
          <td class="partner-boarding-doc-actions">
            <button v-on:click.prevent="startRisk">Start</button>
          </td>
          <td v-if="riskStatus">
            {{ riskStatus }}
          </td>
          <td v-else>Pending</td>
        </tr>
        <tr v-if="boardingMessage">
          <td colspan="3">
            Message:<br />
            {{ boardingMessage }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="actions">
    <button v-on:click.prevent="goBack">Back</button>
    <button v-on:click.prevent="doNext">Next</button>
  </div>
</div>
</template>

<script>
export default {
  props: ["item"],
  data: ()=>({
    websiteChecks: [],
    g2: [],
    acuant: [],
    risk: [],
  }),
  computed: {
    websiteStatus() {
      if (this.websiteChecks.length) {
        var pass=true;
        for(var field of ["phoneNumber", "businessName", "contactInfo", "shippingInfo", "returnPolicy", "termsPrivacy", "goodsDescription", "currency", "links", "legalRestrictions", "billingDescriptor", "secure", "shippingClear", "goodsDescription2", "screenshots"]) {
          if (this.websiteChecks[0][field]==0)
            pass=false;
        }
        return pass?'Complete':'Failed';
      }
    },
    g2Status() {
      if (this.g2.length)
        return this.g2[0].response?'Complete':null;
    },
    acuantStatus() {
      if (this.acuant.length)
        return this.acuant[0].response?this.acuant[0].response.res:null;
    },
    riskStatus() {
      if (this.risk.length)
        return 'Complete';
    },
    boardingMessage() {
      if (this.websiteStatus=='Complete' &&
          this.g2Status=='Complete' &&
          this.acuantStatus &&
          this.riskStatus=='Complete') {
        return "All checks passed";
      }
    },
  },
  methods: {
    goBack() {
      this.$emit("back");
    },
    doNext() {
      if (!this.boardingMessage)
        return false;
      this.$emit("next");
    },
    async startG2() {
      this.$parent.$parent.$parent.goToTab('compliance', 'g2check');
    },
    async startAcuant() {
      this.$parent.$parent.$parent.goToTab('compliance', 'idmcheck');
    },
    async startWebsite() {
      this.$parent.$parent.$parent.goToTab('compliance', 'wscheck');
    },
    async startRisk() {
      this.$parent.$parent.$parent.goToTab('compliance', 'risk');
    },

    async loadWebsite() {
      this.websiteChecks=(await api.list("WebsiteCheck", {appId: this.item.id}, ["id:DESC"])).items;
    },
    async loadG2() {
      this.g2=(await api.list("ExtCheck", {type: "g2", applicationId: this.item.id}, ["id:DESC"])).items;
    },
    async loadAcuant() {
      this.acuant=(await api.list("ExtCheck", {type: "idm", applicationId: this.item.id}, ["id:DESC"])).items;
    },
    async loadRisk() {
      this.risk=(await api.list("ApplicationRiskCalc", {applicationId: this.item.id}, ["id:DESC"])).items;
    },
  },
  mounted() {
    this.loadWebsite();
    this.loadG2();
    this.loadAcuant();
    this.loadRisk();
  },
}
</script>
<style scoped>
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  margin-right: 8px;
  color: black;
  font-size: 16px;
  display: block;
  padding: 6px 20px;
  box-sizing: border-box;
  border: 1px solid #b3b3b3;
  border-radius: 6px;
  background: #e4e4e4;
  background: linear-gradient(to bottom, #e4e4e4 0%, #cfcfcf 100%);
}
button:hover, button:focus {
  background: #e9f2ff;
  background: linear-gradient(to bottom, #e9f2ff 0%, #deebff 100%);
  border-color: #6192fc;
  color: #6192fc;
}
</style>
