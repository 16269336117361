<template>
<div>
  <form class="edit-system search-form">
    <template>Type</template>
    <dyn-select :options="types" v-model="typeId"  style="width: 100%; margin-bottom: 20px"  />
    <template v-if="typeId">Solution</template>
    <dyn-select :options="solutions" v-model="solutionId"  style="width: 100%; margin-bottom: 20px" v-if="typeId == 'Payment'" />
    <dyn-select :options="treasuries" v-model="solutionId"  style="width: 100%; margin-bottom: 20px" v-if="typeId == 'Treasury'" />
    <template v-if="solutionId">Template</template>
    <dyn-select :options="currentTemplates" v-model="templateId"  style="width: 100%; margin-bottom: 20px" v-if="solutionId"  />
    <template v-if="solutionId">Name</template>
    <validator v-if="solutionId" :value="currentTemplate.name" reqs="required">
      <input :disabled="!editMode" type="text" placeholder="Template name" v-model="currentTemplate.name" style="width: 100%; margin-bottom: 20px" v-if="solutionId" />
    </validator>
  </form>

  <pricing-details :currentTemplate="currentTemplate" :solution="solutionId" v-if="solutionId && typeId == 'Payment'" />
  <pricing-details-treasury :currentTemplate="currentTemplate" :solution="solutionId" :editMode="editMode" v-if="solutionId && typeId == 'Treasury'" />
  <div class="buttons" v-if="solutionId">
    <button v-if="!editMode" class="btn btn-large btn-primary" v-on:click.prevent="editMode = true">Update</button>
    <template v-else>
      <button class="btn btn-large btn-primary" v-on:click.prevent="updateTemplate">Submit</button>
      <button class="btn btn-large btn-danger" v-on:click.prevent="deleteTemplate">Delete</button>
    </template>
    
  </div>
</div>
</template>

<script>
export default {
  data() { return {
    templates: [],
    solutionId: null,
    typeId: null,
    templateId: 0,
    currentTemplate: {},
    solutions: [{label: "Please select solution", value: null}, ...appData.solutions],
    treasuries: [{label: "Please select solution", value: null}, ...appData.treasuries],
    types: [{label: "Select type", value: null}, {label: "Payment", value: 'Payment'}, {label: "Treasury", value: 'Treasury'},],
    setting: null,
    editMode: false,
  }},
  watch: {
    shownTemplates() {
      this.templateId=(this.shownTemplates[0] || {id: 0}).id;
    },
    templateId() {
      var that=this;
      if (this.templateId*1)
        this.currentTemplate=this.templates.find(function(a) {
          return a.id==that.templateId;
        }) || {};
      else
        this.currentTemplate={
          name: "",
          flatRatePricing:"", interchangePricing:"", tieredPricing:"",
          tier1:"", tier2:"", tier3:"",
          liteAccessMonthly:"", treasuryAccessMonthly:"", fullAccessMonthly:"",
          swipedTappedInserted:"", swipedTappedInsertedTx:"",
          swipedTappedInsertedInt:"", eCommerceKeyed:"",
          eCommerceKeyedTx:"", eCommerceKeyedInt:"",
          customPay:"", customPayTx:"",
          interacInsertedSwiped:"", interacInsertedSwipedTx:"",
          interacTappedFlash:"",interacTappedFlashTx:"",
          interacOnline:"",
          bbTransactionRate:"",
          bbTransactionFee:"",
          bbRejectsFee:"",
          bbReturnsFee:"",
          bbLateReturnsFee:"",
          bbUnauthorizedReturns:"",
          bbAuthorizationNonCompliance:"",
          multiCurrency:"",
          additionalTid:"",
          chargebacks:"",
          chammpGuard:"",
          chammpGuardPlus:"",
          chammpAlerts:"",
          pciNonCompliance:"",
          systemRiskFee:"",
          accountChanges:"",
          eftAchFee:"",
          wireFee:"",
          internationalWireFee:"",
          equipmentUpgradeFee:"",
          equipmentSwapFee:"",
          equipmentTradeBuyBack:"",
          financeFee:"",
          tidActivations:"",
          cardBiller:"",
          cardBillerPlus:"",
          coinBiller:"",
          bankBiller:"",
          invoBiller:"",
          virtualTerminal:"",
          chammpCheckout:"",
          chammpApiApiKey:"",
          chammpGuardOpt:"",
          chammpGuardPlusOpt:"",
          chammpNotifications:"",
          coinSwipedTappedInserted:"",
          coinSwipedTappedInsertedTx:"",
          coineCommerceKeyed:"",
          coineCommerceKeyedTx:"",
          pricingModules:{},
          pricingOptions:{},
          retrievals:"",
          reversals:"",
          ivrAuthorizations:"",
          voiceAuthorizations:"",
          tokenization:"",
          level2Processing:"",
          level3Processing:"",
          terminalSupport:"",
          dailyPricing:"",
          monthlyPricing:"",
          liteAccessMonthlyToggle:"",
          fullAccessMonthlyToggle:"",
          amexMonthly:null,
          amex:null,
          amexTx:null,
          amexIntl:null,
          debitTx:null,
          debitTx1:null,
          debitTx2:null,
          debitTx3:null,
          cbAsessment:null,
          cbAsessment2:null,
          cbAsessment3:null,
          cbPlusMontly:null,
          cbPlus:null,
          cbPlusTx:null,
          cbPlusInt:null,
          bbSetup:null,
          bbMontly:null,
          bbNonComp:null,
          bbRate:null,
          bbTx:null,
          bbBatch:null,
          bbRejects:null,
          bbReturns:null,
          bbUnauthorized:null,
          bbLate:null,
          bbAuthShield:"",
          bbAuthShieldMontly:null,
          bbAuthVerTx:null,
          bbAuthScreenTx:null,
          bbTransBox:"",
          bbTransBoxMonthly:null,
          bbVaultTx:null,
          bbRecurringTx:null,
          chammpBilling:"",
          chammpBillingSetup:null,
          chammpBillingMontly:null,
          vivpos:"",
          vivposSetup:null,
          vivposMonthly:null,
          payAxis:"",
          payAxisSetup:null,
          payAxisMonthly:null,
          chammpMobile:"",
          chammpMobileSetup:null,
          chammpMobileMonthly:null,
          chammpGift:"",
          chammpGiftSetup:null,
          chammpGiftMonthly:null,
          chammpRewards:"",
          chammpRewardsSetup:null,
          chammpRewardsMonthly:null,
          tids:"",
          tidsIncluded:null,
          tidsFee:null,
          keys:"",
          keysIncluded:null,
          keysFee:null,
          multiCurrencyToggle:"",
          multiCurrencyMonthly:null,
          virtualTerminalToggle:"",
          virtualTerminalMonhly:null,
          chammpCheckoutMonthly:null,
          wirelessActivation:null,
          wirelessData:null,
          titan:null,
          titanPlus:null,
          batchFee:null,
          autoAccountUpdater:null,
          avs:null,
          cellularFee:null,
          autoAccountUpdaterM:null,
          chammpAlertsM:null,
          dataSecurityFee:null,
          freeTerminal:"",
          freeTerminalIncluded:null,
          referralAuthorization:null,
          wirelessFee:"",
          wirelessCDMA:"",
          wirelessGPRS:"",
          applicationFee:"",
          accountSetup:"",
          annualFee:"",
          mailedStatements:"",
          returnItemNsf:"",
          protectionPlan:"",
          protectionPlanPlatinum:"",
          cbOnSite:"",
          methods:[],
          cbPlusToggle:"",
          bbToggle:"",
          accessPlan:null,
          billingModel:null,
          billingOptions:null,
          solution: this.solutionId,
          feeSchedule : {
            volumeFee : {
              text: 'Volume Fee',
              fee: null 
            },
            monthlyAccessFee: { 
              text: 'Monthly Access Fee',
              fee: null 
            },
            domesticWire: { 
              text: 'Domestic Wire',
              per_deposit: null, 
              per_withdrawal: null, 
            },
            internationalWire: { 
              text: 'International Wire',
              per_deposit: null, 
              per_withdrawal: null, 
            },
            ach: { 
              text: 'ACH',
              per_deposit: null, 
              per_withdrawal: null, 
            },
            accountTransfer: { 
              text: 'Account Transfer',
              per_withdrawal: null, 
            },
            checks: { 
              text: 'Checks',
              per_deposit: null, 
              per_withdrawal: null, 
            },
            exchange: { 
              text: 'Exchange',
              fee: null, 
            },
            assets: { 
              text: 'Assets',
              fee: null, 
              per_deposit: null, 
              per_withdrawal: null, 
            },
            dormantAccounts: { 
              text: 'Dormant Accounts',
              fee: null, 
            },
            debitCard: { 
              text: 'Debit Card',
              fee: null, 
              per_withdrawal: null, 
            },
            accountType:null,
          }
        };
    },
    typeId() {
      this.solutionId = null
    }
  },
  computed: {
    currentTemplates() {
      var result=this.shownTemplates.map(function(a) {
        return {value: a.id, label: a.name};
      });
      result.push({value: 0, label: "(new)"});
      return result;
    },
    shownTemplates() {
      return this.templates.filter((a)=>a.solution==(this.solutionId || null));
    },
  },
  methods: {
    refresh: async function() {
      this.templates=(await api.list("PricingTemplate", {})).items || [];
      this.settings=(await api.get("Settings", 1)).item;
//       this.templateId=(this.templates[0] || {id: 0}).id;
    },
    updateTemplate: async function() {
      if (!this.validate())
        return stopLoading("Please fill all required fields", "error");
      startLoading();
      if(this.currentTemplate.accountLimitsDaily) {
        if(parseFloat(this.currentTemplate.accountLimitsDaily) >  parseFloat(this.settings.modules.banking.dailyLimit)) {
          stopLoading("Error: Account Daily Limit");
          return
        }
      }
      if(this.currentTemplate.accountLimitsMonthly) {
        if(parseFloat(this.currentTemplate.accountLimitsMonthly) >  parseFloat(this.settings.modules.banking.monthlyLimit)) {
          stopLoading("Error: Account Monthly Limit");
          return
        }
      }
      if (this.currentTemplate.id) {
        var oldId = this.currentTemplate.id
        await api.update("PricingTemplate", this.currentTemplate.id, this.currentTemplate);
        stopLoading("Template updated");
        await this.refresh();
        this.templateId=oldId;
      } else {
        var id=(await api.create("PricingTemplate", this.currentTemplate)).id;
        stopLoading("Template added");
        await this.refresh();
        this.templateId=id;
      }
      this.editMode = false
    },
    deleteTemplate: async function() {
      if (!this.currentTemplate)
        return;
      if (!confirm("Are you sure?"))
        return;
      startLoading();
      await api.delete("PricingTemplate", this.currentTemplate.id);
      stopLoading("Template deleted");
      await this.refresh();
      this.templateId="0";
    },
  },
  mounted() {
    this.refresh();
  },

  mixins: [formValidation.mixin]
}
</script>
