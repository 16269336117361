<template>
<div>
  <amadel-menu :page="currentPage" />

  <panel>
    <template v-slot:actions>
      <ch-button color="gray" :disabled="!selected" @click="viewSelectedDate()">View selected day</ch-button>
      <ch-button @click="openAddEvent = true">Add new Event</ch-button>
    </template>
    <template v-slot:title>
      <calendar-date-select
        :month.sync="month"
        :year.sync="year" />
    </template>

    <calendar ref="calendar" type="AMADEL" :month="month" :year="year" @selected="selected = $event" />
  </panel>

  <manage-event-modal
    v-model="openAddEvent"
    type="AMADEL"
    :preselectedDate="selected && selected.isInFuture ? selected.date : null"
    @save="addedEvent($event)" />
</div>
</template>

<script>
export default {
  data () {
    return {
      currentPage: {
        text: 'Calendar'
      },
      openAddEvent: false,
      selected: null,
      month: (new Date()).getMonth(),
      year: (new Date()).getFullYear()
    }
  },

  watch: {
    year () {
      this.resetSelected()
    },

    month () {
      this.resetSelected()
    }
  },

  methods: {
    viewSelectedDate () {
     if (this.selected) {
       let date = moment(this.selected.date).format('YYYY-MM-DD')
       location.href = `/calendar/date-view/${date}`
     }
    },

    resetSelected () {
      this.$refs.calendar.selected = null
    },

    addedEvent (event) {
      this.openAddEvent = false
      this.$refs.calendar.events.push(event)
    }
  }
}
</script>
