<template>
  <div class="ncontent nc-flat">
    <tabs class="tabs">
      <tab
        v-if="settings && settings.settings.partners.partnerAdvance"
        title="RESIDUAL COMMISSION - ADVANCE"
        :active="open === 0"
        @click="open = 0"
      />
      <tab title="Language" :active="open === 1" @click="open = 1" />
      <tab title="Time zone" :active="open === 2" @click="open = 2" />
    </tabs>

    <div class="ncontent opt-cont">
      <div class="header opt-head">
        <h3>Partner Options</h3>
      </div>

      <ul class="opt-setboxes">
        <li
          v-if="
            open === 0 && settings && settings.settings.partners.partnerAdvance
          "
        >
          <div class="osb-head">
            <span>RESIDUAL COMMISSION <br />ADVANCE</span>
          </div>
          <div class="osb-body">
            <label class="current"
              ><strong>You are eligible</strong> to <br />receive up to</label
            >
            <span class="value emph"><strong>$2373.95</strong></span>
            <label class="current"
              >in a <strong>Residual<br />Commission Advance</strong></label
            >
            <form class="opts-f2">
              <div class="osb-btns">
                <a href="" class="osb-btn">Claim</a>
                <a href="" class="osb-btn">Free Eval</a>
              </div>
              <label>Last Eval: <strong>September 1, 2019</strong></label>
            </form>
          </div>
        </li>
        <li v-if="open === 1">
          <div class="osb-head">Language</div>
          <div class="osb-body">
            <label class="current">Current Language:</label>
            <span class="value"><strong>English</strong></span>
            <form>
              <label>Select New Language:</label>
              <div class="select">
                <span>&nbsp;</span>
                <select v-if="settings">
                  <option
                    v-for="language in settings.language"
                    :key="language"
                    :value="language"
                  >
                    {{ language }}
                  </option>
                </select>
              </div>
              <div class="osb-btns">
                <button disabled>Update</button>
              </div>
            </form>
          </div>
        </li>
        <li v-if="open === 2">
          <div class="osb-head">Time Zone</div>
          <div class="osb-body">
            <label class="current">Current Time Zone:</label>
            <span class="value"><strong>GMT -5 (EST)</strong></span>
            <form>
              <label>Select New Time Zone:</label>
              <div class="select">
                <span>&nbsp;</span>
                <select v-if="settings">
                  <option
                    v-for="timezone in settings.timezone"
                    :key="timezone"
                    :value="timezone"
                  >
                    {{ timezone }}
                  </option>
                </select>
              </div>
              <div class="osb-btns">
                <button disabled>Update</button>
              </div>
            </form>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Tab from "./components/tabs/Tab";
import Tabs from "./components/tabs/Tabs";

export default {
  components: {
    Tab,
    Tabs,
  },

  data() {
    return {
      open: 0,
      settings: null,
    };
  },

  async created() {
    this.settings = (await api.get("Settings", 1)).item;
    this.open = this.settings?.settings?.partners?.partnerAdvance ? 0 : 1;
  },
};
</script>

<style lang="scss" scoped>
.ncontent.opt-cont {
  margin: 20px 0 50px 0;
}
</style>

