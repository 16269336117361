export default {
  install(Vue, options) {
    Vue.prototype.$toast={
      info(message) {
        if (window.appNotifications) {
          window.appNotifications.show(message, 'success');
        }
      },
      success(message) {
        if (window.appNotifications) {
          window.appNotifications.show(message, 'success');
        }
      },
      error(message) {
        if (window.appNotifications) {
          window.appNotifications.show(message, 'error');
        }
      },
      warning(message) {
        if (window.appNotifications) {
          window.appNotifications.show(message, 'warn');
        }
      },
    };
  },
}
