<template>
<form class="form-step h3lg quest">
  <section v-for="q in [app.questionnaire]" :class="{shaded: !isRequired}">
    <h2>Moto / Internet / Future Services Questionnaire</h2>
    <h3>General</h3>
    <ul class="column-form type2">
      <li>
        <label>Date</label>
        <div class="field">
          <validator :value="q.date" :reqs="isRequired?'required':''">
            <datepicker v-model="q.date" future="today" />
          </validator>
        </div>
      </li>
      <li>
        <label>Legal Business Name</label>
        <div class="field">
          <validator>
            <input type="text" :value="q.companyName" readonly />
          </validator>
        </div>
      </li>
      <li>
        <label>Business DBA Name</label>
        <div class="field">
          <validator>
            <input type="text" :value="q.companyNameDba" readonly />
          </validator>
        </div>
      </li>
    </ul>
    
    <ul class="column-form type2">
      <!--<li>
        <label>Your percentage of sales</label>
        <div class="field">
          <validator>
            <div class="business-length"><input type="text"> <label>% Businesses</label> <input type="text"> <label>% Consumers</label></div>
          </validator>
        </div>
      </li>-->
      <li class="fwidth fwx">
        <label><em>{{ (q.percentBusiness*1 || 0) + (q.percentConsumers*1 || 0) }}%</em> Your percentage of sales <span>(total should equal 100%)</span></label>
        <div class="field">
          <validator :value="[q.percentBusiness, q.percentConsumers]" reqs="sum100" class="multipercent">
            <div>
              <label>Business</label>
              <input type="text" v-model="q.percentBusiness" /><span>%</span>
            </div>
            <div>
              <label>Consumers</label>
              <input type="text" v-model="q.percentConsumers" /><span>%</span>
            </div>
          </validator>
        </div>
      </li>
      <li class="fwidth fwx">
        <label>Method of Marketing <br />(check all that apply)</label>
        <div class="field">
          <validator :value="q.marketingMethod&&q.marketingMethod.length?q.marketingMethod:q.marketingMethodOther" :reqs="isRequired?'required':''">
            <multiselect v-model="q.marketingMethod" :other.sync="q.marketingMethodOther" hasOther="1" :options="['Direct Mail/Brochure/Catalog', 'Newspaper / Magazine', 'Social Media', 'Television / Radio', 'Internet', 'Inbound / Outbound Telemarketing', 'Trade Shows']" />
          </validator>
        </div>
      </li>
      <li class="fwidth fwx">
        <label><em>{{ (q.originDomestic*1 || 0) + (q.originEurope*1 || 0) + (q.originUSA*1 || 0) + (q.originOther*1 || 0) }}%</em> % of Customer Base<br />(E-Commerce) <span>(total should equal 100%)</span></label>
        <div class="field">
          <validator :value="[q.originDomestic, q.originEurope, q.originUSA, q.originOther]" reqs="sum100" class="multipercent">
            <div>
              <label>Canada</label>
              <input type="text" v-model="q.originDomestic" /><span>%</span>
            </div>
            <div>
              <label>USA</label>
              <input type="text" v-model="q.originUSA" /><span>%</span>
            </div>
            <div>
              <label>Europe</label>
              <input type="text" v-model="q.originEurope" /><span>%</span>
            </div>
            <div>
              <label>Rest</label>
              <input type="text" v-model="q.originOther" /><span>%</span>
              <span class="pfx-info">(Rest of the world)</span>
            </div>
          </validator>
        </div>
      </li>
      <li class="fwidth fwx">
        <label>When do you charge the card?</label>
        <div class="field">
          <validator :value="q.chargePoint&&q.chargePoint.length?q.chargePoint:q.chargePointOther" :reqs="isRequired?'required':''">
            <multiselect v-model="q.chargePoint" :other.sync="q.chargePointOther" hasOther="1" :options="['Day of Order', 'Day of Shipment']" :onlyone="true" />
          </validator>
        </div>
      </li>
      <li class="fwidth fwx">
        <label>If future delivery, do you require a deposit?</label>
        <div class="field complex">
          <validator :value="q.depositRequired" :reqs="isRequired?'required':''">
            <formel-toggle-slot :hideslot="true" v-model="q.depositRequired" class="horiz">
              <div class="">
                If yes, percent of sale required <formel-money-amount v-model="q.depositPercent" type="text" suffix="%" /> or flat fee <formel-money-amount type="text" prefix="$" v-model="q.depositFee" />
              </div>
            </formel-toggle-slot>
          </validator>
        </div>
      </li>
      <li class="fwidth fwx">
        <label>Is final payment due before fulfillment?</label>
        <div class="field complex">
          <validator :value="q.advancePayment" :reqs="isRequired?'required':''">
            <formel-toggle-slot v-model="q.advancePayment" class="horiz">
              <div class="">
                If yes, number of days <input type="text" v-model="q.depositDays" />
              </div>
            </formel-toggle-slot>
          </validator>
        </div>
      </li>
      <li class="fwidth fwx">
        <label>Do your sales involve automatic or negative option billing?</label>
        <div class="field">
          <validator :value="q.automaticBilling" :reqs="isRequired?'required':''">
            <formel-toggle v-model="q.automaticBilling" />
          </validator>
        </div>
      </li>
      <li class="fwidth fwx">
        <label>Do you offer a product guarantee or warranty?</label>
        <div class="field complex">
          <validator :value="q.warranty" :reqs="isRequired?'required':''">
            <formel-toggle-slot :hideslot="true" v-model="q.warranty">
              <div class="">
                If yes, is it <multiselect :onlyone="true" v-model="q.warrantyMethod" :options="['Replacement', 'Refund', 'Partial Refund']" />
              </div>
            </formel-toggle-slot>
          </validator>
        </div>
      </li>
      
      <li class="fwidth fwx">
        <label>Refund policy is within how many days</label>
        <div class="field">
          <validator :value="q.refundDays&&q.refundDays.length" :reqs="isRequired?'required':''">
            <multiselect :onlyone="true" v-model="q.refundDays" :options="['30 Days', '31-90 Days', 'Greater than 90 days', 'No Refunds']" />
          </validator>
        </div>
      </li>
      <li class="fwidth fwx">
        <label>Do you offer recurring or subscription billing?</label>
        <div class="field complex">
          <validator :value="q.recurring==='0' || (q.recurringType&&q.recurringType.length?q.recurringType:q.recurringTypeOther)" :reqs="isRequired?'required':''">
            <formel-toggle-slot :hideslot="true" v-model="q.recurring">
              <multiselect :onlyone="true" v-model="q.recurringType" :other.sync="q.recurringTypeOther" hasOther="1" :options="['Weekly', 'Monthly', 'Quarterly', 'Annually']" />
            </formel-toggle-slot>
          </validator>
        </div>
      </li>
      <li class="fwidth fwx">
        <label>How is the card payment information entered into the system?</label>
        <div class="field">
          <validator :value="q.cardEntry&&q.cardEntry.length?q.cardEntry:q.cardEntryOther" :reqs="isRequired?'required':''">
            <multiselect :onlyone="true" v-model="q.cardEntry" :other.sync="q.cardEntryOther" hasOther="1" :options="['Merchant', 'Consumer', 'Both']" />
          </validator>
        </div>
      </li>
      <li class="fwidth fwx">
        <label>Is card payment information entered via the Internet?</label>
        <div class="field">
          <validator :value="q.viaInternet" :reqs="isRequired?'required':''">
            <formel-toggle v-model="q.viaInternet" />
          </validator>
        </div>
      </li>
      <li class="fwidth fwx">
        <label>Is the payment channel encrypted by SSL or better?</label>
        <div class="field">
          <validator :value="q.encrypted" :reqs="isRequired?'required':''">
            <formel-toggle v-model="q.encrypted" />
          </validator>
        </div>
      </li>
      
    </ul>
    <h3>Not Applicable For Services, Virtual or Downloadable Products</h3>
    <ul class="column-form type2">
      <li class="fwidth fwx">
        <label>Do you own the product/inventory?</label>
        <div class="field complex">
          <validator :value="q.ownsProduct" :reqs="isRequired?'required':''">
            <formel-toggle-slot v-model="q.ownsProduct" :hideslot="'inverse'">
              If no, who owns the product/inventory?
              <textarea v-model="q.ownsProductOther" style="resize: none"></textarea>
            </formel-toggle-slot>
          </validator>
        </div>
      </li>
      <li class="fwidth fwx">
        <label>Where is the product stored/shipped from?</label>
        <div class="field">
          <validator :value="q.shippedFrom" reqs="">
            <ul class="multiselect">
              <li><label :class="{checked: q.shippedFrom.indexOf('Business Location')!=-1}"><input type="checkbox" value="Business Location" v-model="q.shippedFrom" v-on:click.prevent="q.shippedFrom=['Business Location']"> <span></span> <i class="bn-con">Business Location</i></label></li>
              <li><label :class="{checked: q.shippedFrom.indexOf('Own Warehouse')!=-1}"><input type="checkbox" value="Own Warehouse" v-model="q.shippedFrom" v-on:click.prevent="q.shippedFrom=['Own Warehouse']"> <span></span> <i class="bn-con">Own Warehouse</i></label></li>
              <li class="other"><label :class="{checked: q.shippedFrom.indexOf('Fulfillment Center')!=-1}"><input type="checkbox" value="Fulfillment Center" v-model="q.shippedFrom" v-on:click.prevent="q.shippedFrom=['Fulfillment Center']"><span></span>Fulfillment Center</label>
              </li>
            </ul>
            <div class="validator" style="font-weight: bold; font-size: 12px">
              Company <input :disabled="q.shippedFrom.indexOf('Fulfillment Center')==-1" type="text" v-model="q.fcCompany" style="width: 140px; margin-right: 20px" :style="{background: q.shippedFrom.indexOf('Fulfillment Center')==-1?'#e8e8e7':''}" />
              Contact name <input :disabled="q.shippedFrom.indexOf('Fulfillment Center')==-1" type="text" v-model="q.fcContactName" style="width: 140px; margin-right: 20px" :style="{background: q.shippedFrom.indexOf('Fulfillment Center')==-1?'#e8e8e7':''}" />
              Contact phone <input :disabled="q.shippedFrom.indexOf('Fulfillment Center')==-1" type="text" v-model="q.fcContactPhone" style="width: 140px" :style="{background: q.shippedFrom.indexOf('Fulfillment Center')==-1?'#e8e8e7':''}" />
            </div>
          </validator>
        </div>
      </li>
      <li class="fwidth fwx">
        <label>Method of Delivery?</label>
        <div class="field">
          <validator :value="q.deliveryMethod&&q.deliveryMethod.length?q.deliveryMethod:q.deliveryMethodOther" :reqs="isRequired?'':''">
            <multiselect :onlyone="true" v-model="q.deliveryMethod" :other.sync="q.deliveryMethodOther" hasOther="1" :options="['Canada Post', 'USPS', 'FedEx', 'UPS', 'Courier', 'DHL']" />
          </validator>
        </div>
      </li>
      <li class="fwidth fwx">
        <label>Is a tracking number provided?</label>
        <div class="field">
          <validator :value="q.trackingProvided" :reqs="isRequired && q.deliveryMethod && q.deliveryMethod.length?'required':''">
            <formel-toggle v-model="q.trackingProvided" />
          </validator>
        </div>
      </li>
    </ul>
    <h3>TRADE REFERENCE</h3>
    <ul class="column-form type2">
      <li>
        <label>Contact Name</label>
        <div class="field">
          <validator :value="q.refName" :reqs="isRequired?'':''">
            <input type="text" v-model="q.refName" />
          </validator>
        </div>
      </li>
      <li>
        <label>Contact Title</label>
        <div class="field">
          <validator :value="q.refTitle" :reqs="isRequired?'':''">
            <input type="text" v-model="q.refTitle" />
          </validator>
        </div>
      </li>
      <li>
        <label>Contact Phone</label>
        <div class="field">
          <validator :value="q.refPhone" :reqs="isRequired?'phone':''">
            <input type="text" v-model="q.refPhone" />
          </validator>
        </div>
      </li>
      <li>
        <label>Contact Email</label>
        <div class="field">
          <validator :value="q.refEmail" :reqs="isRequired?'':''">
            <input type="text" v-model="q.refEmail" />
          </validator>
        </div>
      </li>
      <li>
        <label>Company Name</label>
        <div class="field">
          <validator :value="q.refCompanyName" :reqs="isRequired?'':''">
            <input type="text" v-model="q.refCompanyName" />
          </validator>
        </div>
      </li>
      <li>
        <label>Company Address</label>
        <div class="field">
          <validator :value="q.refCompanyAddress" :reqs="isRequired?'':''">
            <input type="text" v-model="q.refCompanyAddress" />
          </validator>
        </div>
      </li>
      <li class="extw">
        <label>Company City, Prov/State, Postal/Zip</label>
        <div class="field">
          <validator :value="q.refCompanyAddress2" :reqs="isRequired?'':''">
            <input type="text" v-model="q.refCompanyAddress2" />
          </validator>
        </div>
      </li>
      <li>
        &nbsp;
      </li>
    </ul>
    <div class="shade" v-if="!isRequired"></div>
    <div class="shade-message shade-high" v-if="!isRequired">
      <p>This form is not required to be completed for your type of business</p>
    </div>
  </section>

  
  <div class="buttons" v-if="!onlyStep">
    <a class="btn btn-large btn-plain btn-back" v-on:click.prevent="$emit('prev')">Back</a>
    <a class="btn btn-large btn-warn" v-on:click.prevent="$emit('save')">Save</a>
    <a class="btn btn-large btn-success btn-next" v-on:click.prevent="nextStep">Next Step</a>
  </div>  
  <div class="buttons" v-else>
    <a class="btn btn-large btn-success btn-next" v-on:click.prevent="$emit('generate')">Submit</a>
  </div>  
</form>
</template>


<script>
export default {
  props: ["app", "onlyStep"],
  data() { return {
    ctypes: [
      {type: "general", emph: true, title: "General Contact <span>(Primary)</span>"},
      {type: "technical", emph: false, title: "Technical Contact"},
      {type: "financial", emph: false, title: "Financial Contact"},
      {type: "risk", emph: false, title: "Risk / Chargeback Contact"},
    ],
  }},
  computed: {
    isRequired() {
      if ((this.app.frequency || []).filter((a)=>a.startsWith("Recurring")).length)
        return true;
      if (this.app.methodECommerce*1 || this.app.methodMCommerce*1 || this.app.methodMOTO*1 || this.app.methodApp*1)
        return true;
      if (this.app.deliveryTime2*1 || this.app.deliveryTime3*1 || this.app.deliveryTime4*1)
        return true;
      return false;
    },
  },
  created() {
    this.$set(this.app.questionnaire, "companyName", this.app.companyName);
    this.$set(this.app.questionnaire, "companyNameDba", this.app.companyNameTrade);
    if (!this.app.questionnaire.shippedFrom)
      this.$set(this.app.questionnaire, "shippedFrom", []);
  },
  methods: {
    findContact(type) {
      for(var i in this.app.contacts)
        if (this.app.contacts[i].type==type)
          return this.app.contacts[i];
      return {};
    },
    nextStep() {
      if (this.isRequired && !this.validate())
        return stopLoading("Please fill all required fields", "error");
      this.$emit("next");      
    },
    checkType(xtype, event) {
      if (event.target.checked) {
        var fields=["firstName", "lastName", "email", "phone"];
        for(var i in fields)
          this.$set(xtype, fields[i], this.findContact("general")[fields[i]]);
      } else {
        var fields=["firstName", "lastName", "email", "phone"];
        for(var i in fields)
          this.$set(xtype, fields[i], "");
      }
    },
  },
  mixins: [formValidation.mixin]
}
</script>
