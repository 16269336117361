<template>
  <div class="ncontent nc-flat">
    <tabs class="tabs">
      <tab title="Language" :active="open === 0" @click="open = 0" />
      <tab title="Time Zone" :active="open === 1" @click="open = 1" />
    </tabs>
    <div class="ncontent opt-cont">
      <div class="header opt-head">
        <h3>Admin Options</h3>
      </div>

      <ul class="opt-setboxes">
        <li v-if="open === 0">
          <div class="osb-head">Language</div>
          <div class="osb-body">
            <label class="current">Current Language:</label>
            <span class="value"><strong>English</strong></span>
            <form>
              <label>Select New Language:</label>
              <div class="select">
                <span>English</span>
                <select>
                  <option>English</option>
                </select>
              </div>
              <div class="osb-btns">
                <button>Update</button>
                <a href="" class="osb-btn">Cancel</a>
              </div>
            </form>
          </div>
        </li>
        <li v-if="open === 1">
          <div class="osb-head">Time Zone</div>
          <div class="osb-body">
            <label class="current">Current Time Zone:</label>
            <span class="value"><strong>GMT -5 (EST)</strong></span>
            <form>
              <label>Select New Time Zone:</label>
              <div class="select">
                <span>GMT -5</span>
                <select>
                  <option>GMT -5</option>
                </select>
              </div>
              <div class="osb-btns">
                <button>Update</button>
                <a href="" class="osb-btn">Cancel</a>
              </div>
            </form>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Tab from "./components/tabs/Tab";
import Tabs from "./components/tabs/Tabs";

export default {
  components: {
    Tab,
    Tabs,
  },

  data() {
    return {
      open: 0,
    };
  },
};
</script>

<style lang="scss" scoped>
.ncontent.opt-cont {
  margin: 20px 0 50px 0;
}

.opt-setboxes li {
  width: 100%;
}
</style>