<template>
<div class="up-acc">
  <div class="open">
    <div 
      class="title">Login activity
    </div>
    <div class="acc-list">
      <table class="">
        <thead>
          <tr>
            <th style="width: 33%">Username</th>
            <th style="width: 33%">Time</th>
            <th style="width: 34%">IP Address</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in logins">
            <td class="main-col">
              {{ employee.userId.username }}
            </td>
            <td class="">
              {{ item.recorded }}
            </td>
            <td class="">
              {{ item.ipAddress }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <portal to="actions">
    <ul>
      <li>
        <a href="" v-on:click.prevent="$emit('update:editMode', true)">Edit</a>
      </li>
    </ul>
  </portal>
</div>
</template>

<script>
export default {
  props: {
    employee: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      logins: []
    }
  },

  computed: {
    userId () {
      return this.employee.userId.id
    }
  },

  watch: {
    userId: {
      handler (value) {
        this.loadLogins()
      },
      immediate: true
    }
  },

  methods: {
    async loadLogins () {
      this.logins = (await api.list("UserLogin", {
        userId: this.userId
      }, ["recorded:DESC"], 15)).items;
    }
  },
}
</script>
