<template>
<popup v-model="open" @close="open = false">
  <div class="pad20">
    <div class="floatr">
      <template v-if="event && readonly && !viewOnly">
        <ch-button @click="$emit('setEdit', true)">Edit Event</ch-button>
        <ch-button color="danger" @click="deleteEvent(event.id)">Delete Event</ch-button>
      </template>

      <template v-if="!event">
        <ch-button color="gray" @click="clear()">Clear Event</ch-button>
      </template>

      <ch-button v-if="!event || !readonly" @click="save()">Save Event</ch-button>
    </div>
    <div class="clear-after" />

    <create-event-form
      v-if="open"
      ref="event"
      :type="type"
      :event="event"
      :preselectedDate="preselectedDate"
      :readonly="readonly"
      @save="onEventUpdate($event)"
      @remove="removeTag($event)" />
  </div>
</popup>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true
    },

    edit: {
      type: Boolean,
      default: false
    },

    event: {
      type: Object,
      default: null
    },

    preselectedDate: {
      type: Date,
      default: null
    },

    type: {
      type: String,
      required: true
    },

    viewOnly: {
      type: Boolean,
      default: false
    },
  },

  computed: {
    readonly () {
      return this.event && !this.edit
    },

    open: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },

  methods: {
    save () {
      this.$refs.event.save()
    },

    async deleteEvent (id) {
      if (confirm('Are you sure to delete this event?')) {
        startLoading()
        try {
          await api.delete("Event", id)
          this.$emit('delete', id)
          stopLoading("Event deleted.")
        } catch (e) {
          stopLoading(e, 'error')
        }
      }
    },

    removeTag (item) {
      this.$emit('remove-tag', { event: this.event, item })
    },

    clear () {
      this.$refs.event.clear()
    },

    onEventUpdate (event) {
      this.$emit('setEdit', false)
      this.$emit('save', event)
    }
  }
}
</script>
