<template>
<div class="acc-list" style="display: block">
  <table>
    <thead>
      <tr>
        <th>Date/Time</th>
        <th>Souce Name</th>
        <th>Source ID</th>
        <th>Lead File ID</th>
        <th>Lead ID's</th>
        <th>File Name</th>
        <th># of Records</th>
        <th>File Status</th>
      </tr>
    </thead>
    <tbody v-if="data && data.length">
      <tr v-for="(item, index) in data" :key="index">
        <td>{{ formatDate(item.created, 'MM/DD/YYYY') }}</td>
        <td>{{ item.sourceId ? item.sourceId.name : 'N/A' }}</td>
        <td>{{ item.sourceId ? item.sourceId.sourceId : 'N/A' }}</td>
        <td>
          <a v-if="item.status === 'Successful'" class="link" @click="openPreview(item)">{{ item.leadFileId || item.id }}</a>
          <span v-else>{{ item.leadFileId || item.id }}</span>
        </td>
        <td>100{{ item.leadIds }} - 100{{ parseInt(item.leadIds) + parseInt(item.records) - 1 }}</td>
        <td>{{ item.filename }}</td>
        <td>{{ item.records }}</td>
        <td>
          <span :class="item.status === 'Successful' ? 'text-success' : 'text-danger'">{{ item.status }}</span>
        </td>
      </tr>
      <tr v-if="!data.length">
        <td colspan="9" class="text-center">No data</td>
      </tr>
    </tbody>
  </table>
  <amadel-data-transfer-preview v-if="preview" :data="preview" @close="preview = null" />
</div>
</template>

<script>
export default {
  mixins: [ vueMixins.dateFormatter ],

  props: {
    data: {
      type: Array,
      required: true
    }
  },

  data () {
    return {
      preview: null
    }
  },

  methods: {
    async openPreview (item) {
      startLoading()
      this.preview = (await api.list('AmadelLead', { dataTransferFileId: item.id })).items
      stopLoading()
    }
  },
}
</script>
