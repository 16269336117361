<template>
<div class="ncontent touch-it">
  <div class="header split">
    <div>
      <ul class="breadcrumbs">
        <li><a href="https://secure.chammp.net/dashboard">Forms Report</a></li>
      </ul>
      <div class="toolbar" />
      <form class="search-form">
        <h3>Search Options</h3>
        <div class="f2split">
          <div>
            <div class="field">
              <label>Date Range</label>
              <div class="field-group">
                <input type="text" id="dp1569254393711" class="hasDatepicker">
                <input type="text" id="dp1569254393712" class="hasDatepicker">
              </div>
            </div>
            <div class="field">
              <label>Trans ID</label>
              <input type="text">
            </div>
            <div class="field">
              <label>TID</label>
              <select>
                <option value="">(all)</option>
                <option value="2">Allium</option>
                <option value="3">Acme Inc.</option>
                <option value="4">Internal Agent</option>
                <option value="5">David Collins Jr</option>
                <option value="7">GBWEB</option>
                <option value="14"></option>
                <option value="15"></option>
                <option value="20"></option>
              </select>
            </div>
            <div class="field">
              <label>Trans Type</label>
              <select>
                <option value="">(all)</option>
                <option value="A00">A00 - To be Assessed</option>
                <option value="A01">A01 - Pre-Qualification Complete</option>
                <option value="A02">A02 - New Application</option>
                <option value="A03">A03 - In Process</option>
                <option value="A04">A04 - Approved</option>
                <option value="A05">A05 - Denied</option>
                <option value="A06">A06 - Duplicate</option>
              </select>
            </div>
            <div class="field">
              <label>Entry Type</label>
              <select>
                <option value="">(all)</option>
                <option value="A00">A00 - To be Assessed</option>
                <option value="A01">A01 - Pre-Qualification Complete</option>
                <option value="A02">A02 - New Application</option>
                <option value="A03">A03 - In Process</option>
                <option value="A04">A04 - Approved</option>
                <option value="A05">A05 - Denied</option>
                <option value="A06">A06 - Duplicate</option>
              </select>
            </div>
          </div>
          <div>
            <div class="field">
              <label>Scheme</label>
              <select>
                <option value="">(all)</option>
                <option value="A00">A00 - To be Assessed</option>
                <option value="A01">A01 - Pre-Qualification Complete</option>
                <option value="A02">A02 - New Application</option>
                <option value="A03">A03 - In Process</option>
                <option value="A04">A04 - Approved</option>
                <option value="A05">A05 - Denied</option>
                <option value="A06">A06 - Duplicate</option>
              </select>
            </div>
            <div class="field">
              <label>Card</label>
              <input type="text">
            </div>
            <div class="field">
              <label>Ammount</label>
              <input type="text">
            </div>
            <div class="field">
              <label>Batch</label>
              <input type="text">
            </div>
            <div class="field">
              <label>Status</label>
              <select>
                <option value="">(all)</option>
                <option value="Pending">Pending</option>
                <option value="Complete">Complete</option>
                <option value="Expired">Expired</option>
                <option value="Cancel">Cancel</option>
                <option value="Resolved">Resolved</option>                
              </select>
            </div>
          </div>
        </div>
        <div class="buttons"><button class="btn btn-large btn-primary">Search</button></div>
      </form>
    </div>
    <aside>
      <h3>Summary Report</h3>
      <table class="breakdown-table">
        <thead>
          <tr>
            <th>Status</th>
            <th>Count</th>              
          </tr>
          <tr>
            <th colspan="2" class="spacer"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Fraud Risk Policy Receipt</td>
            <td>{{ list.FRPR.data.length }}</td>             
          </tr>
          <tr>
            <td>AML Training Certification</td>
            <td>{{ list.AMLTC.data.length }}</td>              
          </tr>
          <tr>
            <td>Internal Unusual Activity Report</td>
            <td>{{ list.IUAR.data.length }}</td>              
          </tr>
        </tbody>
      </table>
    </aside>
  </div>
  <div class="hr"></div>
  <div class="content">
    <div>
      <p class="result-count">Your search returned <span>{{ countResults }}</span> result(s).</p>
      <ul class="acc-results">
        <li v-for="(item, type) in list" :key="type" :class="{ active: show[type] }">
          <h4 @click="show[type] = !show[type]"><span class="label">{{ item.name }}</span></h4>
          <div v-show="show[type]" class="acc-list" style="display: block;">
            <table>
              <thead>
                <tr>
                  <th>Form ID</th>
                  <th>Status</th>
                  <th>Submit Date</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email</th>
                  <th>Dept</th>
                  <th>Signed</th>
                  <th>Signed Date</th>                                      
                  <th>Options</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="data in item.data" :key="data.id">
                  <td>
                    <a @click="openPreview(data)" class="link">{{ formatID(type, data.docIndex) }}</a>
                  </td>
                  <td>{{ data.status }}</td>
                  <td>{{ formatDate(data.created, 'MM/DD/YYYY') }}</td>
                  <td>{{ showFromForm(data.type) ? getData(data, 'first_name') : 'First Name' }}</td>
                  <td>{{ showFromForm(data.type) ? getData(data, 'last_name') : 'Last Name' }}</td>
                  <td>{{ showFromForm(data.type) ? getData(data, 'email') : data.userId.email }}</td>
                  <td>{{ showFromForm(data.type) ? userGroup(getData(data, 'department')) : userGroup(data.userId.groups) }}</td>
                  <td>{{ data.signed ? 'Y' : 'N' }}</td>
                  <td>{{ data.signed ? formatDate(data.signed, 'MM/DD/YYYY') : 'N/A' }}</td>          
                  <td>
                    <div v-if="!['EXPIRED', 'CXL', 'RESOLVED'].includes(data.status)">
                      <a v-if="data.status !== 'COMPLETE'" class="btn-like ext-btn" style="padding:2px;display:inline-block" @click="cancel(data)">CXL</a>
                      <a v-if="['COMPLETE', 'PENDING'].includes(data.status)" class="btn-like ext-btn" style="padding:2px;display:inline-block" @click="openUpload(data)">UPL</a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <recmatrix-form-doc-preview
    v-if="openedDoc"
    ref="preview"
    :document="openedDoc"
    :documentId="openedDoc ? formatID(openedDoc.type, openedDoc.docIndex) : null"
    @cxl="prepCancel($event)"
    @res="resolve($event)"
    @upl="openUpload()"
    @close="openedDoc = null" />

  
  <input v-show="false" ref="upload" type="file" @change="uploadFile" />
</div>
</template>

<script>
export default {
  mixins: [vueMixins.dateFormatter, vueMixins.helpers],

  data () {
    return {
      openedDoc: null,
      uploadDoc: null,
      show: {
        FRPR: false,
        AMLTC: false,
        IUAR: false
      },
      data: []
    }
  },

  computed: {
    FRPRlist () {
      return this.data.filter(item => item.type === 'FRPR')
    },

    AMLTClist () {
      return this.data.filter(item => item.type === 'AMLTC')
    },

    IUARlist () {
      return this.data.filter(item => item.type === 'IUAR')
    },

    countResults () {
      return this.FRPRlist.length + this.AMLTClist.length + this.IUARlist.length
    },

    list () {
      return {
        FRPR: {
          name: 'Fraud Risk Policy Receipts - FRPR',
          data: this.FRPRlist
        },
        AMLTC: {
          name: 'AML Training Certifications - AMLTC',
          data: this.AMLTClist
        },
        IUAR: {
          name: 'Internal Unusual Activity Reports - IUAR',
          data: this.IUARlist
        }
      }
    }
  },

  created () {
    this.load()
  },

  methods: {
    openUpload (data = null) {
      this.uploadDoc = data
      this.$refs.upload.click()
    },

    async uploadFile ($e) {
      startLoading()
      const file = $e.target.files[0]
      const data = this.openedDoc || this.uploadDoc
      const docId = this.formatID(data.type, data.docIndex)
      let fileName = docId + '-signed'
      if (data.documents.length) fileName = `${fileName}-${data.documents.length + 1}`
      if (!validateFileExt(file)) return stopLoading('This file type is not allowed.', 'error')

      // Upload file
      const fileId = (await api.create("ComplianceDocumentFile", {
        compDocId: data.id,
        type: 'SIGNED_FILE',
        created: "CURRENT_TIMESTAMP"
      })).id
      if (!fileId) return stopLoading('There was an error.', 'error')
      const updatedFile = this.changeFileName(file, fileName)
      await api.upload("ComplianceDocumentFile", fileId, updatedFile)

      // Update Document to COMPLEATE and SIGNED + add note
      const note = docId + ' signed uploaded'
      const payload = {
        status: 'COMPLETE',
        signed: 'CURRENT_TIMESTAMP'
      }
      this.updateDoc(data, payload, note)
    },

    showFromForm (type) {
      return ['AMLTC', 'FRPR'].includes(type)
    },

    openPreview (data) {
      this.openedDoc = data
    },

    prepCancel (id) {
      this.cancel(this.data.find(item => item.id === id))
    },

    resolve (id) {
      const data = this.data.find(item => item.id === id)
      const message = `${this.formatID(data.type, data.docIndex)} Form Resolved`
      this.updateDoc(data, { status: 'RESOLVED' }, message)
    },

    cancel (data) {
      const note = `${data.type} Form Canceled`
      this.updateDoc(data, { status: 'CXL' }, note)
    },

    async updateDoc (data, payload, note) {
      startLoading()
      const response = await api.update("ComplianceDocument", data.id, payload, true)
      let index = this.data.findIndex(doc => doc.id === data.id)
      this.$set(this.data, index, response.item)
      if (note) {
        await this.addNote(data.id, note)
        if (this.openedDoc) {
          this.$refs.preview.loadNotes()
          this.openedDoc = this.data[index]
        }
      }
      stopLoading(note)
    },

    addNote (compDocId, message) {
      const data = {
        message,
        created: "CURRENT_TIMESTAMP",
        author: window.appData.currentUser.id,
        system: 1,
        type: 'ComplianceDocument',
        parentId: compDocId
      }
      return api.create("Note", data)
    },

    async load () {
      const data = await api.list('ComplianceDocument')
      let noExpiredFRPRs = await this.checkForOldFRPR(data.items.filter(item => item.type === 'FRPR'))
      if (noExpiredFRPRs)
        this.data = data.items
      else
        this.load()
    },

    // This is logic for updating all FRPR to status EXPIRED if they are older then 3 days and they are PENDING
    checkForOldFRPR (data) {
      let promises = []
      for (let item of data) {
        const now = moment(new Date())
        const created = moment(item.created)
        if (now.diff(created, 'days') > 3 && item.status === 'PENDING') {
          promises.push(api.update("ComplianceDocument", item.id, { status: 'EXPIRED' }))
        }
      }
      return promises.length ? Promise.all(promises).then(() => false) : Promise.resolve(true)
    },

    getData (data, key) {
      const item = data.form.find(item => item.name === key)
      return item ? item.value : 'N/A'
    },
  }
}
</script>
