<template>
<table class="calendar">
  <thead>
    <tr>
      <th
        v-for="day in daysOfWeek"
        :key="day">
        {{ day }}
      </th>
    </tr>
    <tr
      v-for="(week, index) in weeks"
      :key="year + month + index">
      <td
        v-for="(day, idx) in week"
        :key="idx"
        :class="getDayClasses(day)"
        @click="selectDate(day)">
        <div v-if="day">
          <span
            class="day"
            :class="{ today: day.isToday }">
            {{ day.day }}
          </span>
          <span
            v-for="event in day.events"
            :key="event.id"
            class="event"
            :class="event.color ? `event-${event.color.toLowerCase()}` : 'event-blue'">
            {{ formatEventText(event) }}
          </span>
        </div>
      </td>
    </tr>
  </thead>
</table>
</template>

<script>
export default {
  props: {
    month: {
      type: Number,
      required: true
    },
    year: {
      type: Number,
      required: true
    },

    type: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      now: new Date(),
      selected: null,
      events: [],
      daysOfWeek: ['Sunday', 'Monday', 'Tuesday', 'Wednnesday', 'Thursday', 'Friday', 'Saturday']
    }
  },

  computed: {
    isScheduler () {
      return this.type === 'SCHEDULER'
    },

    firstDay () {
      return moment(new Date(this.year, this.month, 1)).day()
    },

    lastDay () {
      return moment(new Date(this.year, this.month, this.daysInMonth)).day()
    },

    daysInMonth() { 
      return 32 - new Date(this.year, this.month, 32).getDate()
    },

    today () {
      return new Date()
    },

    weeks () {
      let weeks = []
      const days = []

      if (this.firstDay > 0)
        for(let i = 1; i <= this.firstDay; i++)
          days.push(null)
      
      for(let i = 1; i <= this.daysInMonth; i++) days.push(i)

      if (this.lastDay !== 6)
        for(let i = this.lastDay; i <= 6; i++)
          days.push(null)

      let week = []
      days.forEach((day, index) => {
        const date = new Date(this.year, this.month, day)
        week.push(day ? {
          day: day,
          date,
          isToday: date.toDateString() === this.today.toDateString(),
          isInFuture: date.getTime() > this.today.getTime(),
          events: this.getEventsForDay(date)
        } : null)

        if ((index + 1) % 7 === 0) {
          weeks.push(week)
          week = []
        }
      })

      return weeks
    }
  },

  watch: {
    selected (value) {
      this.$emit('selected', value)
    }
  },

  created () {
    this.loadEvents()
  },

  methods: {
    async loadEvents () {
      const payload = { type: this.type }
      const userId = window.appData.currentUser.id
      if (this.isScheduler) payload.createdBy = userId
      this.events = (await api.list("Event", payload)).items
      
      if (this.isScheduler) {
        payload.createdBy = ['!=', userId]
        payload['employees.user_id'] = userId
        this.events = this.events.concat((await api.list("Event", payload)).items)
      }
    },

    getEventsForDay (date) {
      return this.events.filter(event => moment(event.date_from).format('YYYY/MM/DD') === moment(date).format('YYYY/MM/DD'))
    },

    getDayClasses (day) {
      return {
        selectable: day,
        selected: day && this.selected === day
      }
    },

    selectDate (day) {
      if (this.selected === day) {
        this.selected = null
      } else if (day) {
        this.selected = day
      }
    },

    formatEventText (event) {
      const from = moment(event.date_from, 'YYYY-MM-DD HH:mm:ss').format('h:mm A')
      const to = moment(event.date_to, 'YYYY-MM-DD HH:mm:ss').format('h:mm A')
      return `${from} - ${to}: ${event.subject}`
    }
  }
}
</script>
