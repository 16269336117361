<template>
<div>
  <form ref="form" v-on:submit.prevent="sendSignupEmail">
  <input required type="email" v-model="email" placeholder="email">
  <button>Send</button>
  </form>
</div>
</template>

<script>
export default {
  props: ["type", "solution"],
  data() { return {
    email: "",
  }},
  methods: {
    sendSignupEmail: async function() {
      startLoading();
      await api.create("PartnerEmail", {genLink: 1, emailType: this.type, created: "CURRENT_TIMESTAMP", emailTo: this.email, solution: this.solution});
      stopLoading("Email sent");
      this.email="";
      this.$refs.form.reset();
    },
  },
}
</script>
