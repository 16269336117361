<template>
  <div id="form-business">
    <template v-if="step===undefined || step===1">
    <h4>{{ $t('register.business_acc_info_header') }} <span>*</span></h4>
    <div class="form-row form-split">
      <form-field-text v-model="form.companyName" :validator="v.companyName" :label="$t('register.buss_legal_name')" :maxlength="50" required />
      <form-field-text v-model="form.companyNameTrade" :validator="v.companyNameTrade" :label="$t('register.buss_dba_name')" :maxlength="50" required />
    </div>
    <div class="form-row form-split">
      <form-field-date v-model="form.companyDate" :dirty="v.$dirty" :label="$t('register.buss_inc_date')" required />
      <form-field-text v-model="form.companyTaxId" :validator="v.companyTaxId" :label="$t('register.buss_tax_name')" :maxlength="20" required />
    </div>
    <div class="form-row form-split">
      <div style="position: relative;">
        <form-field-select v-model="form.companyAddressCountry" :validator="v.companyAddressCountry" :label="$t('register.country_formation')" :options="countryOptions" required />
        <a class="what-this" @click.prevent="countryModal = true">{{ $t('register.prohibited_c') }}</a>
      </div>
      <form-field-select v-if="stateOptions" v-model="form.companyFormationState" :validator="v.companyFormationState" :label="$t('register.state_formation')" :options="stateOptions" :maxlength="25" required />
      <form-field-text v-else v-model="form.companyFormationState" :validator="v.companyFormationState" :label="$t('register.state_formation')" required />
    </div>
    <div class="form-row form-fullw">
      <form-field-select v-model="form.companyType" :label="$t('register.business_types')" :options="businessTypeOptions" :validator="v.companyType" required />
    </div>
    <div class="form-row form-split">
      <form-field-text :label="$t('register.business_address')" v-model="form.companyAddress" :validator="v.companyAddress" :maxlength="50" required />
      <form-field-text v-model="form.companyAddressCity" :validator="v.companyAddressCity" :label="$t('register.business_city')" :maxlength="25" required />
    </div>
    <div class="form-row form-split">
      <form-field-text :label="$t('register.business_zip')" v-model="form.companyAddressPostcode" :validator="v.companyAddressPostcode" :maxlength="15" required />
      <form-field-text :label="$t('register.business_phone')" v-model="form.companyPhone" :validator="v.companyPhone" :maxlength="20" required />
    </div>
    <div class="form-row form-split">
      <form-field-text :label="$t('register.business_email')" v-model="form.companyEmail" :validator="v.companyEmail" required />
      <form-field-text :label="$t('register.business_website')" v-model="form.companyWebsite" :validator="v.companyWebsite" />
    </div>
    </template>

    <template v-if="step===undefined || step===2">
    <div v-for="(contact, index) in form.contacts" :key="index">
      <h4>{{ $t('register.account_contacts') }} <template v-if="index"> {{ index + 1 }}</template> <span>*</span></h4>
      <bisiness-contact v-model="form.contacts[index]" :v="v.contacts.$each[index]" :solution="solution" />
    </div>
    <div class="form-row form-split">
      <div>
        <a v-if="form.contacts.length < 6" @click="$emit('add-contact')">+ {{ $t('register.add_another_contact') }}</a>
      </div>
      <a v-if="form.contacts.length > 1" @click="$emit('remove-contact')">- {{ $t('register.remove_contact') }}</a>
    </div>
    </template>

    <template v-if="step===undefined || step===3">
    <h4>{{ $t('register.business_desc_questions') }}</h4>
    <div
      v-for="(group, index) in groupedQuestions"
      :key="'group_' + index"
      class="form-row form-split">
      <form-field-select
        v-for="question in group"
        :key="question.key"
        v-model="form.questions[question.key]"
        :label="question.question"
        :validator="v.questions[question.key]"
        :options="question.options"
        :required="true" />
    </div>
    </template>
    <template v-if="step===undefined || step===4">
    <h4>{{ $t('register.business_desc') }}</h4>
    <div class="form-row form-fullw">
      <form-field-textarea
        v-model="form.description"
        :label="$t('register.business_nature')"
        :rows="3"
        :maxlength="250"
        :validator="v.description"
        :required="true" />
    </div>
    </template>
    <template v-if="step===undefined || step===5">
    <h4>
      {{ $t('register.business_doc_submission') }}
      <span>*</span>
    <!--   <label class="check">
        <input type="checkbox" v-model="form.submit_later" />
        <span></span>
       <a>Submit later</a> 
      </label>-->
    </h4>

    <p v-if="form.submit_later">{{ $t('register.final_acc_activation') }}</p>          
    <div class="form-row form-fullw" style="position: relative;">
      <form-field-upload v-model="form.doc_incorporation" :validator="v.doc_incorporation" :label="`${form.companyName} ${$t('register.business_inc_doc')}`" :disabled="form.submit_later" required />
      <a class="what-this" @click.prevent="incModal = true">{{ $t('register.whats_this') }}</a>
    </div>
    <div class="form-row form-fullw" style="position: relative;">
      <form-field-upload v-model="form.doc_address_confirmation" :validator="v.doc_address_confirmation" :label="`${form.companyName} ${$t('register.business_address_confirmation')}`" :disabled="form.submit_later" required />
      <a class="what-this" @click.prevent="addressModal = true">{{ $t('register.whats_this') }}</a>
    </div>
    <div v-for="(contact, index) in form.contacts" :key="'docs_' + index">
      <div
        v-for="(type, idx) in contact.id_types"
        :key="'subdocs_' + idx"
        class="form-row form-fullw" style="position: relative;">
        <form-field-upload v-model="form.contacts[index].id_types[idx].document" :validator="v.contacts.$each[index].id_types.$each[idx].document" :label="`${contact.firstName} ${contact.lastName} ${type.id_type || $t('register.contact_increment_ID_text')} (10MB max)`" :disabled="form.submit_later" required />
        <a class="what-this" @click.prevent="issuedModal = true">{{ $t('register.whats_this') }}</a>
      </div>
      <div
        :key="'subdocs_address'"
        class="form-row form-fullw" style="position: relative;">
        <form-field-upload v-model="form.contacts[index].doc_address_confirmation" :validator="v.contacts.$each[index].doc_address_confirmation" :label="`${contact.firstName} ${contact.lastName} ${$t('register.contact_increment_address_text')} (10MB max)`" :disabled="form.submit_later" required />
        <a class="what-this" @click.prevent="issuedModal = true">{{ $t('register.whats_this') }}</a>
      </div>
    </div>
    </template>
    <popup :open.sync="incModal">
      <div class="scrollable" v-on:scroll.passive="handleScroll">
        <div class="">
          <h2><strong>{{ $t('register.bus_inc_doc_header') }}</strong></h2>
          <p>
            {{ $t('register.bus_inc_doc_text') }}
          </p>
        </div>
      </div>
    </popup>
    <popup :open.sync="addressModal">
      <div class="scrollable" v-on:scroll.passive="handleScroll">
        <div class="">
          <h2><strong>{{ $t('register.bus_address_conf_header') }}</strong></h2>
          <p>
            {{ $t('register.bus_address_conf_text') }}
          </p>
        </div>
      </div>
    </popup>
    <popup :open.sync="issuedModal">
      <div class="scrollable" v-on:scroll.passive="handleScroll">
        <div class="">
          <h2><strong>{{ $t('register.bus_gov_issues_ID_popup_header') }}</strong></h2>
          <p>
           {{ $t('register.bus_gov_issues_ID_popup_line1') }}
          </p>
          <br/>
          <p>
            {{ $t('register.bus_gov_issues_ID_popup_line2') }}
          </p>
          <br/>
          <p>
            {{ $t('register.bus_gov_issues_ID_popup_line3') }}
          </p>
        </div>
      </div>
    </popup>
    <popup :open.sync="countryModal">
      <div class="scrollable" v-on:scroll.passive="handleScroll">
        <div class="">
          <h2><strong>{{ $t('register.prohibited_c') }}</strong></h2>
          <br/>
          <ul style="column-count: 3;">
            <li v-for="item in prohibitedCountries">
              {{ item }}
            </li>
          </ul>
        </div>
      </div>
    </popup>
  </div>
</template>

<script>
import BisinessContact from './RegisterForm2BusinessContact'

export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    v: {
      type: Object,
      required: true
    },
    
    step: {
      type: Number,
      required: false,
    },

    solution: {
      type: Object,
      required: true
    },
  },

  components: {
    BisinessContact
  },

  data () {
    return {
      form: this.value,
      incModal: false,
      addressModal: false,
      issuedModal: false,
      countryModal: false,
    }
  },

  computed: {
    system () {
      return window.appData.company || {};
    },

    groupedQuestions () {
      const list = []

      let group = []
      this.questions.forEach(question => {
        group.push(question)
        if (group.length === 2) {
          list.push(group)
          group = []
        }
      })

      if (group.length) {
        list.push(group)
      }

      return list
    },

    questions () {
      return window.questionsLang[this.$i18n.locale];
    },

    countryOptions () {
      if(this.solution.businessFormation) {
        return window.fullCountries.filter(a => {
          return this.solution.businessFormation.includes(a.id)
        })
      }else{
        return window.countriesOptions
      }
    },

    prohibitedCountries () {
      if(this.system.formationCountry) { 
        return window.fullCountries.filter(a => {
            return !this.system.formationCountry.includes(a.id)
          }).map(a =>(a.text))
      }else{
        return window.prohibitedCountries
      }
    },

    stateOptions () {
      if (!['CA', 'US'].includes(this.form.companyAddressCountry)) return null

      return window.states[this.form.companyAddressCountry].reduce((acc, state) => {
        acc[state.id] = state.text
        return acc
      }, {})
    },

    businessTypeOptions () {
      return [
        {
          id: "Sole Proprietor",
          text: this.$t("register.bus_type_sole")
        },
        {
          id: "Single Member LLC",
          text: this.$t("register.bus_type_smllc")
        },
        {
          id: "LLC",
          text: this.$t("register.bus_type_llc")
        },
        {
          id: "General Partnership",
          text: this.$t("register.bus_type_gen_par")
        },
        {
          id: "Corporation",
          text: this.$t("register.bus_type_corp")
        },
        {
          id: "Publicly Traded Corporation",
          text: this.$t("register.bus_type_ptc")
        },
        {
          id: "Association",
          text: this.$t("register.bus_type_association")
        },
        {
          id: "Non Profit",
          text: this.$t("register.bus_type_n_profit")
        },
        {
          id: "Revocable Trust",
          text: this.$t("register.bus_type_rev_trust")
        },
        {
          id: "Irrevocable Trust",
          text: this.$t("register.bus_type_irr_trust")
        },
        {
          id: "Estate",
          text: this.$t("register.bus_type_estate")
        },
      ]
    }
  },

  watch: {
    'form.companyAddressCountry' (value) {
      this.form.companyFormationState = null
      this.form.companyFormationState = null
    },

    form: {
      handler (value) {
        this.$emit('input', value)
      },
      deep: true
    }
  }
}
</script>
<style lang="scss" scoped>
  .what-this {
    position: absolute;
    right: 10px;
    top: 54px;
    cursor: pointer;
    font-size: 13px;
  }
</style>
