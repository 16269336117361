<template>
<form class="form">
  <div class="form-group">
    <label>Subject:</label>
    <validator :value="form.subject" reqs="required">
      <input v-model="form.subject" maxlength="100" class="w100" :disabled="readonly">
    </validator>
  </div>
  <div class="inline-form-group">
    <div class="form-group">
      <label>Date:</label>
      <validator :value="form.date" reqs="required">
        <datepicker v-model="form.date" type="text" :future="true" :disabled="readonly" />
      </validator>
    </div>
    <div class="form-group">
      <label>Time from:</label>
      <validator class="inline-block" :value="form.time_from_hours" reqs="required">
        <select v-model="form.time_from_hours" :disabled="readonly">
          <option v-for="i in 12" :key="i">{{ i }}</option>
        </select>
      </validator>
      
      <validator class="inline-block" :value="form.time_from_time" reqs="required">
        <select v-model="form.time_from_time" :disabled="readonly">
          <option v-for="t in timeOptions" :key="t" :value="t">{{ t }}</option>
        </select>
      </validator>
      
      <validator class="inline-block" :value="form.time_from_am_pm" reqs="required">
        <select v-model="form.time_from_am_pm" :disabled="readonly">
          <option v-for="type in amPm" :key="type" :value="type">{{ type }}</option>
        </select>
      </validator>
    </div>
    <div class="form-group">
      <label>Time to</label>
      <validator class="inline-block" :value="form.time_to_hours" reqs="required">
        <select v-model="form.time_to_hours" :disabled="readonly">
          <option v-for="i in 12" :key="i">{{ i }}</option>
        </select>
      </validator>
      <validator class="inline-block" :value="form.time_to_time" reqs="required">
        <select v-model="form.time_to_time" :disabled="readonly">
          <option v-for="t in timeOptions" :key="t" :value="t">{{ t }}</option>
        </select>
      </validator>
      <validator class="inline-block" :value="form.time_to_am_pm" reqs="required">
        <select v-model="form.time_to_am_pm" :disabled="readonly">
          <option v-for="type in amPm" :key="type" :value="type">{{ type }}</option>
        </select>
      </validator>
    </div>
    <div class="form-group">
      <label>Timezone:</label>
      <select v-model="form.timezone" :disabled="readonly">
        <option v-for="i in timezones" :key="i">{{ i }}</option>
      </select>
    </div>
    <div class="form-group">
      <label>Color Code:</label>
      <select v-model="form.color" :disabled="readonly">
        <option v-for="color in colors" :key="color">{{ color }}</option>
      </select>
    </div>
  </div>

  <tag-employees
    ref="tagEmployees"
    v-if="isScheduler"
    v-model="selectedEmployees"
    :readonly="readonly"
    :self-remove="selfTagRemove"
    @remove="$emit('remove', $event)" />

  <div class="form-group">
    <label>Note:</label>
    <textarea v-model="form.note" maxlength="500" :disabled="readonly" />
  </div>
</form>
</template>

<script>
export default {
  mixins: [ formValidation.mixin ],

  props: {
    preselectedDate: {
      type: Date,
      default: null
    },

    event: {
      type: Object,
      default: null
    },

    readonly: {
      type: Boolean,
      default: false
    },

    type: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      form: {},
      selectedEmployees: [],
      timezones: [
        'Ottawa, ON, Canada (GMT-4)',
        'Newfoundland (GMT-2:30)',
        'Halifax, NS, Canada (GMT-3)',
        'Winnipeg (GMT-5)',
        'Regina/Edmonton (GMT-6)',
        'Vancouver (GMT-7)'
      ],
      timeOptions: ['00', 15, 30, 45],
      colors: ['Red', 'Green', 'Orange', 'Yellow'],
      amPm: ['AM', 'PM'],
      format: 'YYYY-MM-DD HH:mm:ss'
    }
  },

  computed: {
    userId () {
      return window.appData.currentUser.id
    },

    isScheduler () {
      return this.type === 'SCHEDULER'
    },

    selfTagRemove () {
      return this.event ? this.event.createdBy.id !== this.userId : false
    }
  },

  watch: {
    event: {
      handler () {
        this.clear()
      },
      immediate: true
    }
  },

  methods: {
    async save () {
      if (!this.validate()) return stopLoading('Please fill all required fields', 'error')
      startLoading()
      try {
        const from = this.getFormatedDatetime (this.form.date, this.form.time_from_hours, this.form.time_from_time, this.form.time_from_am_pm)
        const to = this.getFormatedDatetime (this.form.date, this.form.time_to_hours, this.form.time_to_time, this.form.time_to_am_pm)

        if (moment(from, this.format).diff(moment(to, this.format)) > 0) return stopLoading('"Time to" must be greater than "Time from"', 'error')
        
        const overlap = await this.overlapCheck()
        if (overlap) return stopLoading(`There is already event happening from ${moment(overlap.date_from).format('hh:mm A')} to ${moment(overlap.date_to).format('hh:mm A')}`, 'error')

        const payload = {
          subject: this.form.subject,
          note: this.form.note,
          timezone: this.form.timezone,
          color: this.form.color,
          date_from: from,
          date_to: to,
          type: this.type
        }
        if (!this.event) {
          payload.created = 'CURRENT_TIMESTAMP'
          payload.createdBy = appData.currentUser.id
        }

        const event = !this.event ? (await api.create("Event", payload, true)).item : (await api.update("Event", this.event.id, payload, true)).item
        
        if (this.isScheduler) {
          if (!this.selectedEmployees.length && this.event) {
            await api.update("Event", this.event.id, { employees: {} })
          }
          if (this.selectedEmployees.length) {
            const allEmployees = this.$refs.tagEmployees.employees
            const props = this.selectedEmployees.map(id => {
              return { employee_id: id, user_id: allEmployees.find(emp => emp.id === id).userId.id }
            })
            await api.update("Event", event.id, { employees: props })
          }
        }
        
        this.$emit('save', event)
        this.clear()
        this.validateReset()
        stopLoading("Saved")
      } catch (e) {
        stopLoading(e, 'error')
      }
    },

    async overlapCheck () {
      const from = this.getFormatedDatetime (this.form.date, this.form.time_from_hours, this.form.time_from_time, this.form.time_from_am_pm, true)
      const to = this.getFormatedDatetime (this.form.date, this.form.time_to_hours, this.form.time_to_time, this.form.time_to_am_pm, false)
      let res = (await api.list("Event", { type: this.type, date_from: ["<", to], date_to: [">", from]})).items
      if (this.event) res = res.filter(event => event.id !== this.event.id)
      const overlap = res.length ? res[0] : null
      return overlap
    },

    getFormatedDatetime (date, time_from_hours, time_from_time, time_from_am_pm, check = null) {
      const md = moment(`${date}, ${time_from_hours}:${time_from_time} ${time_from_am_pm}`, 'YYYY-MM-DD, hh:mm A')
      if (check !== null) {
        if (check)
          md.add(1, 'minutes')
        else
          md.subtract(1, 'minutes')
      }

      return md.format(this.format)
    },

    clear () {
      if (this.event) {
        const date = moment(this.event.date_from)
        const dateTo = moment(this.event.date_to)
        
        this.form = {
          date: date.format('YYYY-MM-DD'),
          subject: this.event.subject,
          color: this.event.color,
          time_from_hours: date.format('h'),
          time_from_time: date.format('mm'),
          time_from_am_pm: date.format('A'),
          time_to_hours: dateTo.format('h'),
          time_to_time: dateTo.format('mm'),
          time_to_am_pm: dateTo.format('A'),
          timezone: this.event.timezone,
          note: this.event.note
        }
        if (this.isScheduler && this.event.employees) {
          this.selectedEmployees = this.event.employees.map(emp => emp.employee_id.id)
        }
      } else {
        const currentDate = new Date()
        const date = this.preselectedDate > currentDate ? moment(this.preselectedDate) : moment()
        this.form = {
          date: date.format('YYYY-MM-DD'),
          subject: null,
          color: null,
          time_from_hours: null,
          time_from_time: null,
          time_from_am_pm: null,
          time_to_hours: null,
          time_to_time: null,
          time_to_am_pm: null,
          timezone: null,
          note: null
        }
        this.selectedEmployees = []
      }
      this.validateReset()
    }
  }
}
</script>
