<template>
<div>
  <div class="content-table-container">
    <table class="content-table">
      <tbody>
        <tr>
          <th><label>
            <input type="checkbox" v-model="options.enabledMethods" value="CardBiller" />
            Payment Methods
          </label></th>
        </tr>
        <tr>
          <td style="text-align: left">
            <label>
              <input type="checkbox" v-model="options.enabledMethods" value="Visa" />
              Visa
            </label>
            <label>
              <input type="checkbox" v-model="options.enabledMethods" value="Mastercard" />
              Mastercard
            </label>
            <label>
              <input type="checkbox" v-model="options.enabledMethods" value="Discover" />
              Discover
            </label>
            <label>
              <input type="checkbox" v-model="options.enabledMethods" value="AMEX" />
              AMEX
            </label>
            <label>
              <input type="checkbox" v-model="options.enabledMethods" value="China UnionPay" />
              China UnionPay
            </label>
            <label>
              <input type="checkbox" v-model="options.enabledMethods" value="Debit" />
              Debit
            </label>
          </td>
        </tr>
        <tr>
          <th><label>
            <input type="checkbox" v-model="options.enabledMethods" value="CardBiller Plus" />
            Advanced Payment Methods
          </label></th>
        </tr>
        <tr>
          <td style="text-align: left">
            <label>
              <input type="checkbox" v-model="options.enabledMethods" value="Apple Pay" />
              Apple Pay
            </label>
            <label>
              <input type="checkbox" v-model="options.enabledMethods" value="Google Pay" />
              Google Pay
            </label>
            <label>
              <input type="checkbox" v-model="options.enabledMethods" value="Interac Online" />
              Interac Online
            </label>
          </td>
        </tr>
        <tr>
          <th><label>
            <input type="checkbox" v-model="options.enabledMethods" value="BankBiller" />
            Bank Clearing
          </label></th>
        </tr>
        <tr>
          <td style="text-align: left">
            <label>
              <input type="checkbox" v-model="options.enabledMethods" value="EFT" />
              EFT
            </label>
            <label>
              <input type="checkbox" v-model="options.enabledMethods" value="ACH" />
              ACH
            </label>
          </td>
        </tr>
        <tr>
          <th><label>
            <input type="checkbox" v-model="options.enabledMethods" value="CoinBiller" />
            CoinBiller
          </label></th>
        </tr>
        <tr>
          <td style="text-align: left">
            <label>
              <input type="checkbox" v-model="options.enabledMethods" value="Bitcoin" />
              Bitcoin
            </label>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="buttons" v-if="!hideSubmit">
    <button class="btn btn-large btn-primary" v-on:click.prevent="updateSettings">Submit</button>
  </div>  
</div>
</template>

<script>
export default {
  props: ["hideSubmit"],
  data() { return {
    options: {
      enabledMethods: [],
    },
  }},
  mounted() {
    this.refresh();
  },
  methods: {
    refresh() {
      var that=this;
      api.get("Options", 1).then(function(data) {
        that.options=data.item;
        that.options.enabledMethods=that.options.enabledMethods || [];
      });
    },
    updateSettings: async function(disableMsg) {
      startLoading();
      await api.update("Options", 1, this.options);
      if (disableMsg)
        stopLoading();
      else
        stopLoading("Payment methods updated");
    },
  },
}

</script>

