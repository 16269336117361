<template>
<section class="ncontent view-profile">
  <template v-if="header">
  <a class="back-button">Dashboard</a>
  <div class="header">
    <div>
      <ul class="breadcrumbs">
        <li v-for="headerItem in header"><a href="">{{ headerItem }}</a></li>
      </ul>
    </div>
  </div>
  </template>
  
  <div class="tabs-header">
    <slot name="tabsheader" />
  </div>
  <div class="tabs-main">
    <slot name="tabsmain" />
  </div>
  <div class="tabs-sub" v-if="$slots.tabssub">
    <slot name="tabssub" />
  </div>
  <div class="actions" v-if="$slots.actions">
    <slot name="actions" />
  </div>
  <div class="content">
    <slot />
  </div>
</section>
</template>

<script>
export default {
  props: ["header"],
  data: ()=>({
  }),
}
</script>
