<template>
<div class="boarding-container">
  <aside class="boarding-steps">
    <ul>
      <li v-for="(stepLabel, si) in steps" :class="{current: step==si, available: si<=doneSteps}">
        <a href="" v-on:click.prevent="goToStep(si)">{{ stepLabel }}</a>
      </li>
    </ul>
  </aside>
  <section class="boarding-main">
    <treasury-boarding-settings v-if="step==0" v-on:next="goToNext" :item="item" />
    <treasury-documents v-if="step==1" :item="item" :onboarding="onboarding" v-on:next="goToNext" v-on:back="goToStep(0)" v-on:reloadnotes="$emit('reloadnotes')" />
    <treasury-boarding-compliance v-if="step==2" v-on:next="goToNext" v-on:back="goToStep(1)" :item="item" />
    <treasury-boarding-submission v-if="step==3" v-on:next="goToNext" v-on:back="goToStep(2)" :item="item" />
    <div v-if="step==4">
      Boarding completed
    </div>
  </section>
</div>
</template>

<script>
export default {
  props: ["item"],
  data: ()=>({
    steps: [
      "Settings",
      "Documents",
      "Compliance",
      "Submission",
    ],
    step: 0,
    done: [],
    uwListFinal: [],
    onboarding: true,
  }),
  computed: {
    doneSteps() {
      return this.done.length;
    },
    boardingProgress() {
      return this.item.boardingprogress || {step: 0};
    },
  },
  watch: {
    step() {
      if (this.step==4) {
        this.listFinalChecks();
      }
    },
  },
  methods: {
    goToStep(index) {
      if (this.doneSteps>=index)
        this.step=index;
    },
    async goToNext() {
      this.step++;
      if (this.step>this.done.length) {
        this.done.push("");
        this.item.boardingprogress=this.item.boardingprogress || {step: 0}
        this.item.boardingprogress.step=this.step;
        startLoading();
        await api.update("TreasuryAccount", this.item.id, {boardingprogress: this.item.boardingprogress});
        stopLoading();
      }
    },
    async listFinalChecks() {
      this.uwListFinal=(await api.list("ApprovalCheck", {applicationId: this.item.id}, ["id:DESC"])).items || [];
    },

  },
  mounted() {
    while(this.boardingProgress.step>this.step) {
      this.step++;
      if (this.step>this.done.length)
        this.done.push("");
    }
  },
}
</script>
