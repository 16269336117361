<template>
  <div v-if="form" id="form-personal">
    <template v-if="step===undefined || step===1">
    <h4>{{ $t('register.personal_acc_heading') }} <span>*</span></h4>
    <div class="form-row form-split">
      <form-field-select v-model="form.sex" :validator="v.sex" :label="$t('register.contact_gender')" required :options="{Male: $t('register.gender_m'), Female: $t('register.gender_f')}" />
      <form-field-date v-model="form.dob" :dirty="v.$dirty" :label="$t('register.contact_birth')" required />
    </div>
    <div class="form-row form-split">
      <form-field-text v-model="form.firstName" :validator="v.firstName" :label="$t('register.contact_FN')" :maxlength="25" required  />
      <form-field-text v-model="form.lastName" :validator="v.lastName" :label="$t('register.contact_LN')" :maxlength="25" required />
    </div>
    <div class="form-row form-split">
      <form-field-text :validator="v.phone" :label="$t('register.contact_phone')" :maxlength="20" required v-model="form.phone" />
      <form-field-text :validator="v.phone2" :label="$t('register.contact_mobile')" :maxlength="20" required v-model="form.phone2" />
    </div>
    <div class="form-row form-split" style="position: relative;margin-bottom: 10px;">            
      <form-field-text :validator="v.email" :label="$t('register.contact_email')" required v-model="form.email" />
      <form-field-text :validator="v.ssn" :label="$t('register.contact_ssn')" :maxlength="15" required v-model="form.ssn" />
      <a class="what-this" @click.prevent="ssnModal = true">{{ $t('register.whats_this') }}</a>
    </div>
    <div
      v-for="(type, index) in form.id_types"
      :key="index"
      class="form-row form-split">
      <form-field-select v-model="form.id_types[index].id_type" :validator="v.id_types.$each[index].id_type" :label="$t('register.gov_issued_id_type')" :options="issueIdTypes(form.id_types[index].id_type)" required />
      <form-field-text v-model="form.id_types[index].id_number" :maxlength="20" :validator="v.id_types.$each[index].id_number" :label="$t('register.gov_issued_id_num')" required />
    </div>
    <div class="form-row form-split">
      <div>
        <a v-if="form.id_types.length < idTypes.length" @click="addIssueType()">+ {{ $t('register.add_ID') }}</a>
      </div>
      <a v-if="form.id_types.length > 1" @click="removeIssueTypeId()">- {{ $t('register.remove_ID') }}</a>
    </div>
    </template>
    
    <template v-if="step===undefined || step===2">
    <h4>{{ $t('register.personal_address_conf_header') }} <span>*</span></h4>
    <div class="form-row form-split">
      <div style="position: relative;">
        <form-field-select :validator="v.addressCountry" :label="$t('register.contact_country')" required :options="countryOptions" v-model="form.addressCountry" />
        <a class="what-this" @click.prevent="countryModal = true">{{ $t('register.prohibited_c') }}</a>
      </div>
      <form-field-text :validator="v.addressCity" :label="$t('register.contact_city')" required v-model="form.addressCity" :maxlength="25" />
    </div>
    <div class="form-row form-split">
      <form-field-text :validator="v.address" :maxlength="50" :label="$t('register.contact_address')" required v-model="form.address" />
    </div>
    <div class="form-row form-split">
      <form-field-select v-if="stateOptions" v-model="form.addressProvince" :validator="v.addressProvince" :label="$t('register.contact_state')" :options="stateOptions" required />
      <form-field-text v-else v-model="form.addressProvince" :validator="v.addressProvince" :label="$t('register.contact_state')" required :maxlength="50" />

      <form-field-text :validator="v.addressPostcode" :maxlength="15" :label="$t('register.contact_zip')" required v-model="form.addressPostcode" />
    </div>
    </template>

    <template v-if="step===undefined || step===3">
    <h4>
      {{ $t('register.personal_doc_submission') }}
      <span>*</span>
  <!--    <label class="check">
        <input type="checkbox" v-model="form.submit_later" />
        <span></span>
        <a>Submit later</a> 
      </label> -->
    </h4>

    <p v-if="form.submitLater">{{ $t('register.final_acc_activation') }}</p>
    <div
      v-for="(type, idx) in form.id_types"
      :key="'subdocs_' + idx"
      class="form-row form-fullw" style="position: relative;">
      <form-field-upload v-model="form.id_types[idx].document" :validator="v.id_types.$each[idx].document" :label="`${form.firstName} ${form.lastName} ${type.id_type || $t('register.contact_increment_ID_text')} (10MB max)`" :disabled="form.submit_later" required />
      <a class="what-this" @click.prevent="issuedModal = true">{{ $t('register.whats_this') }}</a>
    </div>
<!--    <div class="form-row form-fullw" style="position: relative;">
      <form-field-upload v-model="form.doc_address_confirmation" :validator="v.doc_address_confirmation" :label="`Contact Address Confirmation`" :disabled="form.submit_later" required />
    </div>-->
    </template>
    <popup :open.sync="issuedModal">
      <div class="scrollable" v-on:scroll.passive="handleScroll">
        <div class="">
          <h2><strong>{{ $t('register.bus_gov_issues_ID_popup_header') }}</strong></h2>
          <p>
            {{ $t('register.bus_gov_issues_ID_popup_line1') }}
          </p>
          <br/>
          <p>
            {{ $t('register.bus_gov_issues_ID_popup_line2') }}
          </p>
          <br/>
          <p>
            {{ $t('register.bus_gov_issues_ID_popup_line3') }}
          </p>
        </div>
      </div>
    </popup>
    <popup :open.sync="ssnModal">
      <div class="scrollable" v-on:scroll.passive="handleScroll">
        <div class="">
          <h2><strong>{{ $t('register.modal_ssn') }}</strong></h2>
          <p>
            {{ $t('register.modal_ssn_line1') }}
          </p>
          <br/>
          <p>
           {{ $t('register.modal_ssn_line2') }}
          </p>
          <br/>
          <p>
          </p>
        </div>
      </div>
    </popup>
    <popup :open.sync="countryModal">
      <div class="scrollable" v-on:scroll.passive="handleScroll">
        <div class="">
          <h2><strong>{{ $t('register.prohibited_c') }}</strong></h2>
          <br/>
          <ul style="column-count: 3;">
            <li v-for="item in prohibitedCountries">
              {{ item }}
            </li>
          </ul>
        </div>
      </div>
    </popup>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    v: {
      type: Object,
      required: true
    },
    
    step: {
      type: Number,
      required: false,
    },

    solution: {
      type: Object,
      required: true
    },

  },

  data () {
    return {
      form: this.value,
      idTypes: ['Passport', 'Drivers License'],
      issuedModal: false,
      ssnModal: false,
      countryModal: false,
    }
  },

  computed: {
    system () {
      return window.appData.company || {};
    },
    
    countryOptions () {
      if(this.solution.contacts) {
        return window.fullCountries.filter(a => {
          return this.solution.contacts.includes(a.id)
        })
      }else{
        return window.countriesOptions
      }
    },

    prohibitedCountries () {
      if(this.system.contactsCountry) { 
        return window.fullCountries.filter(a => {
            return !this.system.contactsCountry.includes(a.id)
          }).map(a =>(a.text))
      }else{
        return window.prohibitedCountries
      }
    },

    stateOptions () {
      if (!['CA', 'US'].includes(this.form.addressCountry)) return null

      return window.states[this.form.addressCountry].reduce((acc, state) => {
        acc[state.id] = state.text
        return acc
      }, {})
    }
  },

  watch: {
    form: {
      handler (value) {
        this.$emit('input', value)
      },
      deep: true
    }
  },

  methods: {
    issueIdTypes (current) {
      const used = this.form.id_types.map(type => type.id_type).filter(id => id)
      return this.idTypes.filter(key => current === key || !used.includes(key)).reduce((acc, key) => {
        var k = key.toLowerCase().replace(/ /g, "_");
        acc[key] = this.$t("register.gov_doc_"+k);
        return acc
      }, {})
    },

    addIssueType () {
      this.form.id_types.push({
        id_type: null,
        id_number: null,
        document: null
      })
    },

    removeIssueTypeId () {
      this.form.id_types.splice(this.form.id_types.length - 1, 1)
    }
  }
}
</script>
<style lang="scss" scoped>
  .what-this {
    position: absolute;
    right: 10px;
    top: 54px;
    cursor: pointer;
    font-size: 13px;
  }
</style>
