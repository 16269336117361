<template>
<div>
  <div class="content-table-container">
    <table class="content-table">
      <thead>
        <tr>
          <th style="width: 10%">Status code</th>
          <th style="width: 25%">Category</th>
          <th style="width: 64%">Description</th>
        </tr>
        <tr>
          <th class="spacer" colspan="4"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items">
          <td class="text-center main-col">
            {{ item.status }}
          </td>
          <td class="text-center">
            {{ item.category }}
          </td>
          <td class="text-center">
            {{ item.description }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
</template>

<script>
export default {
  data() { return {
    items: [],
    origItems: [],
    categories: [],
  }},
  methods: {
    reload() {
      var that=this;
      api.list("Status", {}, ["`status`:ASC"]).then(function(data) {
        that.origItems=JSON.parse(JSON.stringify(data.items));
        that.items=JSON.parse(JSON.stringify(data.items));
        that.categories=[];
        for(var i in that.items) {
          var item=that.items[i];
          if (that.categories.indexOf(item.category)==-1)
            that.categories.push(item.category);
        }
        that.categories.sort();
      });
    },
    deleteItem(index) {
      var that=this;
      if (confirm("Are you sure?")) {
        api.list("Application", {status: this.items[index].status}, ["id:ASC"], 1).then(function(data) {
          if (data.items.length)
            alert("Can't delete status: used by application or merchant");
          else {
            that.items.splice(index, 1);
          }
        });
      }
    },
    newItem() {
      this.items.push({
        status: "",
        category: "",
        description: "",
      });
    },
    validate() {
      var statuses={};
      for(var i in this.items) {
        this.items[i].status=(this.items[i].status || "").toUpperCase();
        if (statuses[this.items[i].status])
          return false;
        statuses[this.items[i].status]=true;
      }
      
      return true;
    },
    updateStatusCodes() {
      if (!this.validate()) {
        stopLoading("Duplicate codes detected", "error");
        return false;
      }
      var itemsToDelete=[];
      for(var index in this.origItems) {
        var item=this.origItems[index];
        if (!this.items.find(function(e) { return e.id==item.id; }))
          itemsToDelete.push(item);
      }
      var itemsToAdd=[];
      var itemsToUpdate=[];
      for(var index in this.items) {
        var item=this.items[index];
        var foundItem=this.origItems.find(function(e) { return e.id==item.id; });
        if (!foundItem)
          itemsToAdd.push(item);
        else if (JSON.stringify(foundItem)!=JSON.stringify(item))
          itemsToUpdate.push(item);
      }
      this.updateStatusApi(itemsToUpdate, itemsToAdd, itemsToDelete);
      this.origItems=JSON.parse(JSON.stringify(this.items));
    },
    updateStatusApi: async function(update, add, remove) {
      startLoading();
      for(var i in update) {
        var item=update[i];
        await api.update("Status", item.id, item);
      }
      for(var i in add) {
        var item=add[i];
        await api.create("Status", item);
      }
      for(var i in remove) {
        var item=remove[i];
        await api.delete("Status", item.id);
      }
      stopLoading("Updated status codes");
    },
  },
  mounted() {
    this.reload();
  },
}
</script>

