<template>
      <div>
        <div class="action-sub-buttons" v-if="!uwShown">
          <a class="btn" v-on:click="newPreCheck">Run new</a>
          <a class="btn" v-if="uwSelected" v-on:click.prevent="doShowItems(uwSelected)">View</a>
        </div>
        <div class="action-sub-buttons" v-else>
          <a class="btn" v-on:click="uwShown=null; hideShown()">Back</a>
          <a class="btn" :href="printUrl" target="_blank">Print</a>
        </div>
        <table class="file-list content-table" v-if="!uwShown">
          <thead>
            <tr>
              <th style="width: 1%"></th>
              <th>ID</th>
              <th>Legal Name</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(uwItem, uwI) in uwList">
              <td><input type="radio" v-model="uwSelected" :value="uwItem.id"></td>
              <td>{{ uwList.length-uwI }}</td>
              <td>{{ uwItem.companyName }}</td>
              <td>{{ formatDate(uwItem.createdDate, "YYYY-MM-DD HH:mm") }}</td>
            </tr>
          </tbody>
        </table>
        <div class="pre-check-details" v-if="uwShown" ref="detailsBox">
          <div class="t2precheck">
            <div class="t2sect">
              <div class="t2box">
                <h3>General Information</h3>
                <ul>
                  <li>
                    <label>Solution:</label>
                    <span v-if="uwShownItem.solution">{{ solution(uwShownItem.solution) }}</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                  <li>
                    <label>Status:</label>
                    <span v-if="uwShownItem.status">{{ uwShownItem.status }}</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                  <li>
                    <label>Account ID:</label>
                    <span v-if="uwShownItem.accountId">{{ uwShownItem.accountId }}</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                  <li>
                    <label>Merchant Industry:</label>
                    <span v-if="uwShownItem.industry">{{ uwShownItem.industry }}</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="t2sect">
              <div class="t2box">
                <h3>Business Information</h3>
                <ul>
                  <li>
                    <label>Business Legal Name:</label>
                    <span v-if="uwShownItem.companyName">{{ uwShownItem.companyName }}</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                  <li>
                    <label>Business Trade Name:</label>
                    <span v-if="uwShownItem.companyNameTrade">{{ uwShownItem.companyNameTrade }}</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                  <li>
                    <label>Business Email:</label>
                    <span :class="{duplicate: uwShownItem.dupCompanyEmail}" v-if="uwShownItem.companyEmail">{{ uwShownItem.companyEmail }}</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                  <li>
                    <label>Business Country:</label>
                    <span v-if="uwShownItem.companyAddressCountry">{{ uwShownItem.companyAddressCountry }}</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                  <li>
                    <label>Business Address:</label>
                    <span :class="{duplicate: uwShownItem.dupAddress}" v-if="uwShownItem.companyAddress">
                      {{ uwShownItem.companyAddress }}
                      {{ uwShownItem.companyAddressNum }}
                      {{ uwShownItem.companyAddressPostcode }}
                      {{ uwShownItem.companyAddressCity }}
                      {{ uwShownItem.companyAddressState }}
                    </span>
                    <span class="empty" v-else>NO</span>
                  </li>
                  <li>
                    <label>URL:</label>
                    <span v-if="uwShownItem.website">{{ uwShownItem.hasWebsite?"No website":uwShownItem.website }}</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="t2sect">
              <div class="t2box">
                <h3>Principals Information</h3>
                <ul>
                  <li>
                    <label>Number Owners:</label>
                    <span :class="{duplicate: uwShownItem.numberDirectors!=uwShownItem.ubos.length}">{{ uwShownItem.ubos.length }}</span>
                  </li>
                  <template v-for="(subi, si) in uwShownItem.ubos">
                    <li>
                      <label>Owner {{ si+1 }} Name:</label>
                      <span :class="{duplicate: uwShownItem.ubos[si] && uwShownItem.ubos[si].firstName!=subi.firstName}" v-if="subi.firstName">{{ subi.firstName }}</span>
                      <span class="empty" v-else>NO</span>
                    </li>
                    <li>
                      <label>Owner {{ si+1 }} Last Name:</label>
                      <span :class="{duplicate: uwShownItem.ubos[si] && uwShownItem.ubos[si].lastName!=subi.lastName}" v-if="subi.lastName">{{ subi.lastName }}</span>
                      <span class="empty" v-else>NO</span>
                    </li>
                    <li>
                      <label>Owner {{ si+1 }} Phone:</label>
                      <span v-if="subi.phone">{{ subi.phone }}</span>
                      <span class="empty" v-else>NO</span>
                    </li>
                    <li>
                      <label>Owner {{ si+1 }} Email:</label>
                      <span v-if="subi.email">{{ subi.email }}</span>
                      <span class="empty" v-else>NO</span>
                    </li>
                  </template>
                  <li v-for="(contact, ci) in uwShownItem.contacts">
                    <label>{{ contact.type[0].toUpperCase()+contact.type.substring(1) }} contact:</label>
                    <span :class="{duplicate: uwShownItem.dupContacts}" v-if="contact.firstName">{{ contact.firstName+" "+contact.lastName }}</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="t2sect">
              <div class="t2box">
                <h3>Documents Information</h3>
                <ul v-if="uwShownItem.documents">
                  <li>
                    <label>Completed Merchant Application:</label>
                    <span>Yes</span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="t2sect">
              <div class="t2box">
                <h3>System</h3>
                <ul>
                  <li>
                    <label>Duplicates:</label>
                  </li>
                  <li v-if="uwShownItem.dupCompanyName">
                    Company Name: 
                    <template v-for="dupId in uwShownItem.dupCompanyName.split(',')">
                      <a _target="blank" :href="'/applications/'+dupId">{{ appIds[dupId] || dupId }}</a>&nbsp;
                    </template>
                  </li>
                  <li v-if="uwShownItem.dupCompanyNameTrade">
                    Company Trade Name: 
                    <template v-for="dupId in uwShownItem.dupCompanyNameTrade.split(',')">
                      <a _target="blank" :href="'/applications/'+dupId">{{ appIds[dupId] || dupId }}</a>&nbsp;
                    </template>
                  </li>
                  <li v-if="uwShownItem.dupCompanyEmail">
                    Company Name: 
                    <template v-for="dupId in uwShownItem.dupCompanyEmail.split(',')">
                      <a _target="blank" :href="'/applications/'+dupId">{{ appIds[dupId] || dupId }}</a>&nbsp;
                    </template>
                  </li>
                  <li v-if="uwShownItem.dupWebsite">
                    Website: 
                    <template v-for="dupId in uwShownItem.dupWebsite.split(',')">
                      <a _target="blank" :href="'/applications/'+dupId">{{ appIds[dupId] || dupId }}</a>&nbsp;
                    </template>
                  </li>
                  <li v-if="uwShownItem.dupWebsiteBeta">
                    Website Beta: 
                    <template v-for="dupId in uwShownItem.dupWebsiteBeta.split(',')">
                      <a _target="blank" :href="'/applications/'+dupId">{{ appIds[dupId] || dupId }}</a>&nbsp;
                    </template>
                  </li>
                  <li v-if="uwShownItem.dupEmailService">
                    Support Email: 
                    <template v-for="dupId in uwShownItem.dupEmailService.split(',')">
                      <a _target="blank" :href="'/applications/'+dupId">{{ appIds[dupId] || dupId }}</a>&nbsp;
                    </template>
                  </li>
                  <li v-if="uwShownItem.dupPhoneService">
                    Support Phone: 
                    <template v-for="dupId in uwShownItem.dupPhoneService.split(',')">
                      <a _target="blank" :href="'/applications/'+dupId">{{ appIds[dupId] || dupId }}</a>&nbsp;
                    </template>
                  </li>
                  <li v-if="uwShownItem.dupAddress">
                    Address: 
                    <template v-for="dupId in uwShownItem.dupAddress.split(',')">
                      <a _target="blank" :href="'/applications/'+dupId">{{ appIds[dupId] || dupId }}</a>&nbsp;
                    </template>
                  </li>
                  <li v-if="uwShownItem.dupDirectors">
                    Director: 
                    <template v-for="dupId in uwShownItem.dupDirectors">
                      <a _target="blank" :href="'/applications/'+dupId[1]">{{ appIds[dupId[1]] || dupId }}</a>&nbsp;
                    </template>
                  </li>
                  <li v-if="uwShownItem.dupUbos">
                    UBO:
                    <template v-for="dupId in uwShownItem.dupUbos">
                      <a _target="blank" :href="'/applications/'+dupId[1]">{{ appIds[dupId[1]] || dupId }}</a>&nbsp;
                    </template>
                  </li>
                  <li v-if="uwShownItem.dupAccounts">
                    Bank Account:
                    <template v-for="dupId in uwShownItem.dupAccounts">
                      <a _target="blank" :href="'/applications/'+dupId[1]">{{ appIds[dupId[1]] || dupId }}</a>&nbsp;
                    </template>
                  </li>
                  <li v-if="uwShownItem.dupContacts">
                    Contacts:
                    <template v-for="dupId in uwShownItem.dupContacts">
                      <a _target="blank" :href="'/applications/'+dupId[1]">{{ appIds[dupId[1]] || dupId }}</a>&nbsp;
                    </template>
                  </li>
                </ul>
              </div>
            </div>
            <div class="t2sect">
              <div class="t2box">
                <h3>Pre-Check Score Report</h3>
                <ul>
                  <li>
                    <label>=</label> {{ validItems }} / 16
                  </li>
                  <li>
                    <label>Negative List Report:</label>
                    <span>{{ 0 }}</span>
                  </li>
                  <li>
                    <label>Duplicate List Report:</label>
                    <span>{{ dupCount }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          
        </div>
      </div>
</template>


<script>
export default {
  props: ["uwList", "preq"],
  data() { return {
    appIds: {},
    uwSelected: null,
    uwShown: null,    
    currencies: {
      "036": "AUD",
      "986": "BRL",
      "124": "CAD",
      "756": "CHF",
      "997": "EUR",
      "826": "GBP",
      "344": "HKD",
      "392": "JPY",
      "554": "NZD",
      "840": "USD",
    },
    shownItem: 0,
    accountTypes: [
      {value: "master", label: "Master Merchant"},
      {value: "merchant", label: "Merchant"},
      {value: "sub", label: "Sub-Account"},
    ],
    facilitators: appData.facilitators || [],
    solutions: appData.solutions || [],
    dupCount: 0,
  }},
  methods: {
    hideShown() {
      clearInterval(this.shownItemInterval);
      $(this.$refs.detailsBox).find(".t2box").addClass("visible");
      $(this.$refs.detailsBox).find(".t2box li").addClass("visible");
      $(this.$refs.detailsBox).find(".t2box h4").addClass("visible");
      
    },
    doShowItems(id, animate) {
      this.uwShown=id;
      var that=this;
      /*if (animate) {
        this.shownItem=0;
        clearInterval(this.shownItemInterval);
        this.shownItemInterval=setInterval(function() {
          that.shownItem++;
          if (that.shownItem>100)
            clearInterval(that.shownItemInterval);
        }, 300);
      } else {
        this.shownItem=100;
      }*/
      if (animate) {
        this.$nextTick(()=>{
          var itemsToShow=$(this.$refs.detailsBox).find(".t2box, .t2box li, .t2box h4");
          var itemIndex=0;
          this.shownItemInterval=setInterval(()=>{
            itemsToShow.eq(itemIndex).addClass("visible");
            itemIndex++;
            if (itemsToShow.length==itemIndex)
              clearInterval(this.shownItemInterval);
          }, 150);
        });
      } else {
        this.$nextTick(()=>{
          $(this.$refs.detailsBox).find(".t2box, .t2box li, .t2box h4").addClass("visible");
        });
      }
      
      if (this.uwShownItem) {
        this.appIds={};
        var duplicates={};
        for(var id of (this.uwShownItem.dupCompanyName || "").split(","))
          if (id) duplicates[id]=true;
        for(var id of (this.uwShownItem.dupCompanyNameTrade || "").split(","))
          if (id) duplicates[id]=true;
        for(var id of (this.uwShownItem.dupWebsite || "").split(","))
          if (id) duplicates[id]=true;
        for(var id of (this.uwShownItem.dupWebsiteBeta || "").split(","))
          if (id) duplicates[id]=true;
        for(var id of (this.uwShownItem.dupEmailService || "").split(","))
          if (id) duplicates[id]=true;
        for(var id of (this.uwShownItem.dupPhoneService || "").split(","))
          if (id) duplicates[id]=true;
        for(var id of (this.uwShownItem.dupAddress || "").split(","))
          if (id) duplicates[id]=true;
        for(var id of (this.uwShownItem.dupDirectors || []))
          duplicates[id[1]]=true;
        for(var id of (this.uwShownItem.dupUbos || []))
          duplicates[id[1]]=true;
        for(var id of (this.uwShownItem.dupAccounts || []))
          duplicates[id[1]]=true;
        for(var id of (this.uwShownItem.dupContacts || []))
          duplicates[id[1]]=true;
        
        var dups=[];
        for(var i in duplicates)
          dups.push(i);
        
        this.dupCount=dups.length;
        api.list("Application", {
          id: ["IN ("+dups.join(",")+")"]
        }).then(function(data) {
          for(var i in data.items)
            that.$set(that.appIds, data.items[i].id, data.items[i].applicationId);
        });
        
        
      }
      
    },
    listPreChecks: async function() {
      await this.$parent.listPreChecks();
    },
    newPreCheck: async function() {
      startLoading();
      var id=(await api.create("PreCheck", {})).id;
      
      if (this.preq) {
        var it=JSON.parse(JSON.stringify(this.$parent.item));
        var paymentMethods=[];
        var cTypes=[];
        var newItem={
          accountId: it.applicationId,
          companyName: it.companyName,
          companyNameTrade: it.companyNameTrade,
          companyAddress: it.companyAddress,
          companyAddressCountry: it.companyAddressCountry,
          companyType: it.companyType,
          companyEmail: it.companyEmail,
          companyDeclined: it.companyDeclined,
          companyTerminated: it.companyTerminated,
          emailService: it.emailService,
          phoneService: it.phoneService,
          use3dSecure: it.use3dSecure,
          useCVC: it.useCVC,
          useAVS: it.useAVS,
          website: it.website,
          acceptedCards: it.acceptedCards,
          paymentMethods: paymentMethods.join(", "),
          paymentTypes: cTypes.join(", "),
          websiteBeta: it.websiteBeta,
          violation: it.violationDetails!="No"?1:0,
          violationDetails: it.violationDetails,
          volume: it.volume,
          volumeCurrency: it.volumeCurrency,
          avgTransaction: it.avgTransaction,
          avgTransactionCurrency: it.avgTransactionCurrency,
          maxTransaction: it.maxTransaction,
          maxTransactionCurrency: it.maxTransactionCurrency,
          solution: it.solution,
          class: it.class && it.class.name,
          accountType: it.accountType,
          facilitator: it.facilitator,
          merchantType: it.merchantType,
          industry: it.industry,
          directors: [],
          contacts: [],
          accounts: [],
          ubos: it.ubos,
          prequalId: it.id,
        };
      } else {
        var it=JSON.parse(JSON.stringify(this.$parent.item));
        var paymentMethods=[];
        if (it.methodApp*1) paymentMethods.push("App");
        if (it.methodECommerce*1) paymentMethods.push("ECommerce");
        if (it.methodMCommerce*1) paymentMethods.push("MCommerce");
        if (it.methodMOTO*1) paymentMethods.push("MOTO");
        if (it.methodMPOS*1) paymentMethods.push("MPOS");
        if (it.methodPOS*1) paymentMethods.push("POS");
        var cTypes=it.cardTypes.slice();
        if (it.cardTypesOther)
          cTypes.push(it.cardTypesOther);
        newItem=JSON.parse(JSON.stringify(this.$parent.item));
        newItem.appId=it.applicationId;
        newItem.accountId=it.accountId;
        newItem.applicationId=this.$parent.item.id;
        newItem.paymentMethods=paymentMethods.join(", ");
        newItem.paymentTypes=cTypes.join(", ");
        newItem.class=it.class && it.class.name;
      }
      newItem.id=null;
      newItem.userId=appData.currentUser.id;
      newItem.createdDate="CURRENT_TIMESTAMP";
      for(var director of newItem.directors) {
        director.id=null;
        director.appId=null;
        director.precheckId=id;
      }
      for(var ubo of newItem.ubos) {
        ubo.id=null;
        ubo.appId=null;
        ubo.precheckId=id;
      }
      for(var contact of newItem.contacts) {
        contact.id=null;
        contact.appId=null;
        contact.precheckId=id;
      }
      for(var account of newItem.accounts) {
        account.id=null;
        account.appId=null;
        account.precheckId=id;
      }
      
      newItem.documents=this.newDocCheck();
      
      await api.update("PreCheck", id, newItem);
      await this.listPreChecks();
//       this.doShowItems(this.uwList[this.uwList.length-1].id, true);
      this.doShowItems(this.uwList[0].id, true);
      stopLoading();
    },
    newDocCheck() {
      var documents={};
      for(var i in this.$parent.documents) {
        var doc=this.$parent.documents[i];
        documents[i]={
          id: doc.id,
          created: doc.created,
          filename: doc.filename,
        };
      }
      if (this.$parent.agreements && this.$parent.agreements.length) {
        documents["agreement"]={
          id: this.$parent.agreements[0].id,
          filename: this.$parent.agreements[0].name,
        };
        if (this.$parent.agreements[0].filename) {
          documents["signedAgreement"]={
            id: this.$parent.agreements[0].id,
            filename: this.$parent.agreements[0].filename,
          };
        }
      }
      return documents;
    },
    accountType(id) {
      for(var accountType of this.accountTypes)
        if (id==accountType.value)
          return accountType.label;
      return "";
    },
    facilitator(id) {
      for(var facilitator of this.facilitators)
        if (id*1==facilitator.value*1)
          return facilitator.label;
      return "";
    },
    solution(id) {
      for(var solution of this.solutions)
        if (id*1==solution.value*1)
          return solution.label;
      return "";
    },
  },
  computed: {
    uwShownItem() {
      var result={};
      if (this.uwShown) {
        var item=this.uwList.find((a)=>a.id==this.uwShown);
        for(var i in item)
          result[i]=item[i];
        result.numberDirectors=result.directors && result.directors.length;
        result.numberAccounts=result.accounts && result.accounts.length;
        result.directors=(result.directors && result.directors.length)?result.directors:[{}];
        result.accounts=(result.accounts && result.accounts.length)?result.accounts:[{}];
      }
      return result;
    },
    printUrl() {
      if (!this.uwShownItem)
        return null;
      if (!appData.preCheckPrintUrl)
        return null;
      return appData.preCheckPrintUrl.replace("%d", this.uwShownItem.id);
    },
    validItems() {
      if (!this.uwShownItem)
        return 0;
      var i=this.uwShownItem;
      var invalid=0;
      
      if (!i.status) invalid++;
      if (!i.companyName) invalid++;
      if (!i.companyNameTrade) invalid++;
      if (!i.companyAddress) invalid++;
      if (!i.companyAddressCountry) invalid++;
      if (!i.companyEmail) invalid++;
      if (!i.website) invalid++;
      if (!i.industry) invalid++;

      
      for(var subi of i.ubos) {
        if (!subi.firstName) invalid++;
        if (!subi.lastName) invalid++;
        if (!subi.phone) invalid++;
        if (!subi.email) invalid++;
      }


      
      return 16-invalid;
    },
  },
  mixins: [vueMixins.dateFormatter],  
}
</script>
