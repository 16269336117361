<template>
    <form class="system-messages">
      <label>Enter new system message:</label>
      <textarea v-model="message">
        
      </textarea>
      <div class="buttons">
        <button class="btn btn-large btn-primary" v-on:click.prevent="newMessage">Create new message</button>
        <label class="checkbox">
          <input type="checkbox" v-model="dashboardOnly" />
          Dashboard only
        </label>
      </div>
    </form>
</template>

<script>
export default {
  data() { return {
    message: "",
    dashboardOnly: false,
  }; },
  methods: {
    newMessage() {
      var that=this;
      api.create("Message", {
        author: appData.currentUser.username,
        message: this.message,
        date: "CURRENT_TIMESTAMP",
        dashboard: this.dashboardOnly?1:0,
      }).then(function(data) {
        that.dashboardOnly=0;
        that.message="";
        window.messageList.reload();
      });
    },
  },
}
</script>
