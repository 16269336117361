<template>
<div>
  <div class="content-table-container resources">
    <table class="content-table resources">
      <tbody>
        <tr class="inend">
          <td colspan="3"></td>
        </tr>
        <tr v-for="(item, index) in items">
          <td class="text-left" style="width: 65%">
            {{ item.name }}
          </td>
          <td class="text-left" style="width: 15%">
            {{ item.type }}
          </td>
          <td class="" style="width: 20%">
            <a class="btn btn-default" target="_blank" :href="basepath+'/'+item.filename">Download</a>
          </td>
        </tr>
        <tr class="inend">
          <td colspan="3"></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
</template>

<script>
export default {
  data() { return {
    items: [],
    basepath: "",
  }},
  methods: {
    reload() {
      var that=this;
      api.list("Resources", {}, ["id:ASC"]).then(function(data) {
        that.basepath=data.desc.filename.basepath;
        that.items=JSON.parse(JSON.stringify(data.items));
      });
      this.basepath=that.basepath;
      this.items=that.items;
    },
  },
  mounted() {
    this.reload();
  },
}
</script>

