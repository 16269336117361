<template>
<ul class="notifications" :class="{visible: messages.length}">
  <li v-for="message in messages" :class="message.cls" v-on:click="dismissMessage(message)" :key="message.id">
    {{ message.message }}
  </li>
</ul>
</template>

<script>
export default {
  data() { return {
    messages: [],
    msgId: 0,
  };},
  mounted() {
    window.appNotifications=this;
  },
  methods: {
    show(message, status) {
      var that=this;
      var newMsg={
        message: message,
        cls: status,
        id: "message"+(this.msgId++),
      };
      this.messages.push(newMsg);
      setTimeout(function() {
        that.dismissMessage(newMsg);
      }, 5000);
    },
    dismissMessage(message) {
      var that=this;
      message.cls+=" dismissed";
      setTimeout(function() {
        var msgIndex=that.messages.indexOf(message);
        if (msgIndex!=-1)
          that.messages.splice(msgIndex, 1);
      }, 500);
    }
  },
}
</script>
