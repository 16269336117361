<template>
<div>
  <p>Profile Settings</p>
  <br/>
  <ul class="appview-list">
    <li>
      <label>Relationship Manager</label>
      <validator :value="item.manager" reqs="required">
        <dyn-select :options="sourcesToShow" v-model="item.manager"></dyn-select>
      </validator>
    </li>
    <li>
      <label>Referrer</label>
      <div class="validator">
        <dyn-select v-model="item.agentDb.referrer" :options="usersToShow" />
      </div>
    </li>
    <li>
      <label>Language</label>
      <validator :value="item.agentDb.language" reqs="required">
        <dyn-select :options="languages" v-model="item.agentDb.language" />
      </validator>
    </li>
  </ul>
  <div class="actions">
    <button :disabled="!item.manager || !item.agentDb.language" v-on:click.prevent="doNext">Save & Continue</button>
  </div>
</div>
</template>

<script>
import treasuryEmailsVue from '../treasury/treasury-emails.vue';
export default {
  props: ["item"],
  data: ()=>({
    sources: appData.sources || [],
    users: appData.users || [],
    accountClassesList: [
      {value: "10", label: "10"},
      {value: "20", label: "20"},
      {value: "30", label: "30"},
      {value: "40", label: "40"},
      {value: "50", label: "50"},
      {value: "CST", label: "CST"},
    ],
    settings: {}
  }),
  
  methods: {
    async doNext() {
      if (!this.validate())
        return stopLoading("Please fill all required fields", "error");
      await this.save();
      this.$emit("next");
    },
    async save() {
      startLoading();
      var updateItem=JSON.parse(JSON.stringify(this.item));
      delete updateItem.passChange;
      if(this.item.agentDb.status == 'A02') {
        updateItem.agentDb.status = 'A03'
        this.item.agentDb.status = 'A03';
      }
      await api.update("User", this.item.id, updateItem);
      stopLoading("Agent updated");
    },
  },
  computed: {
    sourcesToShow() {
      return this.sources.map(function(a) {
        return {
          value: a.id,
          label: a.fullName || a.username,
        };
      });
    },
    usersToShow() {
      return this.users.map(function(a) {
        return {
          value: a.id,
          label: a.username,
        };
      });
    },
    languages() {
      var options = [
        {value: null, label: ""}
      ];
      if(this.settings.language) {
        for(var i = 0; i < this.settings.language.length; i++){
          options.push(
            {
              value: this.settings.language[i],
              label: this.settings.language[i],
            }
          )
        };
      }
      return options
    }
  },

  async created () {
    this.settings=(await api.get("Settings", 1)).item;
  },
  mixins: [formValidation.mixin]
}
</script>
