<template>
  <svg
    id="Layer_1"
    style="enable-background: new 0 0 24 24"
    version="1.1"
    viewBox="0 0 24 24"
    :width="size + 'px'"
    :height="size + 'px'"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g>
      <circle
        fill="none"
        :stroke="color"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        cx="12"
        cy="12"
        r="11.3"
      />
      <path
        fill="none"
        :stroke="color"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        d="M12,14.9c-3.5,0-6.5,2-8,5c2,2.1,4.9,3.3,8,3.3s6-1.3,8-3.3C18.5,17,15.5,14.9,12,14.9z"
      />
      <circle
        fill="none"
        :stroke="color"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        cx="12"
        cy="8.7"
        r="3.6"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "#000",
    },

    size: {
      type: Number,
      default: 24,
    },
  },
};
</script>


<style lang="scss" scoped>
svg,
svg * {
  transition: all ease-in-out 0.2s;
}
</style>