<template>
<div class="wcontent dashboard">
  <div class="dash-row">
    <div class="col" />
    <div class="col">
      <table class="breakdown-table">
        <thead>
          <tr>
            <th colspan="2">My Calendar</th>
          </tr>
          <tr><th colspan="2" class="spacer"></th></tr>
        </thead>
        <tbody>
          <tr>
            <td colspan="2">
              <div class="font-16 mb-10">Todays Events:</div>
              <div class="event-list">
                <template v-if="events">
                  <ul v-if="events.length" class="list">
                    <li
                      v-for="event in events"
                      :key="event.id"
                      class="list-item">
                      <div
                        class="circle"
                        :class="event.color ? `event-${event.color.toLowerCase()}` : 'event-blue'" />
                      <b>{{ formatEventTime(event) }}</b>: {{ event.subject }}
                      <a :href="getUrl(event.id)">
                        <ch-button class="floatr" size="xs">View</ch-button>
                      </a>
                    </li>
                  </ul>
                  <span v-else>No Events Today.</span>
                </template>
                <div v-else>
                  Loading...
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
</template>

<script>
export default {
  data () {
    return {
      events: null
    }
  },

  computed: {
    today () {
      return moment().format('YYYY-MM-DD')
    }
  },

  created() {
    this.loadEvents()
  },

  methods: {
    getUrl (id) {
      let url = `/tools/chammp-scheduler/calendar/date-view/${this.today}`
      return id ? `${url}?event=${id}` : url
    },

    isAssigned (item) {
      return ['AMA11', 'AMA12', 'AMA13'].includes(item.status)
    },

    async loadEvents () {
      const userId = window.appData.currentUser.id
      const payload = {
        type: 'SCHEDULER',
        createdBy: userId,
        date_from: ["LIKE", `${this.today}%`]
      }
      this.events = (await api.list("Event", payload)).items
      payload.createdBy = ['!=', userId]
      payload['employees.user_id'] = userId
      this.events = this.events.concat((await api.list("Event", payload)).items)
    },

    formatEventTime (event) {
      const from = moment(event.date_from, 'YYYY-MM-DD HH:mm:ss').format('h:mm A')
      const to = moment(event.date_to, 'YYYY-MM-DD HH:mm:ss').format('h:mm A')
      return `${from} - ${to}`
    }
  }
}
</script>
