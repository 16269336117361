<template>
<div class="popup usermgmt" :class="{shown: visible}">
  <div class="pop-med">
    <div class="pop-inner">
      <a href="" class="close" v-on:click.prevent="visible=false"></a>
      <h2>{{ user.id?'Editing user: '+user.username+" "+(user.agentId?'('+user.agentId+')':''):'New user' }}</h2>
      <form class="search-form">
        <div class="f2split">
          <div>
            <validator class="field" :value="user.username" reqs="required">
              <label>Username</label>
              <input tabindex="1" type="text" v-model="user.username" />
            </validator>
          </div>
          <div>
            <validator class="field" :value="user.email" reqs="required">
              <label>Email</label>
              <input tabindex="1" type="text" v-model="user.email" />
            </validator>
          </div>
        </div>
        <div class="f2split">
          <div>
            <validator class="field" :value="user.fullName" reqs="required">
              <label>First name</label>
              <input tabindex="1" type="text" v-model="user.fullName" />
            </validator>
          </div>
          <div>
            <validator class="field" :value="user.lastName" reqs="required">
              <label>Last name</label>
              <input tabindex="1" type="text" v-model="user.lastName" />
            </validator>
          </div>
        </div>
        <a v-if="user.id" href="" class="newcon" v-on:click.prevent="resetPassword">Reset Password</a>
        <div class="buttons">
          <button class="btn btn-large btn-primary" v-on:click.prevent="send">{{ user.id?'Update':'Create' }}</button>
        </div>
      </form>
    </div>
  </div>
</div>
</template>

<script>
export default {
  data() { return {
    visible: false,
    user: {},
  }},
  computed: {
  },
  methods: {
    showPopup(user) {
      this.validateReset();
      this.user=user || {
        parent: window.userProfile.id,
        active: "Yes",
        agentId: window.userProfile.agentId,
        applicationId: window.userProfile.applicationId,
        username: "",
        fullName: "",
        lastName: "",
        email: "",
        password: "",
        groups: window.userProfile.groups,
        companyName: null,
        companyAddress: null,
        companyCity: null,
        companyZip: null,
        companyState: null,
        companyCountry: null,
        companyPhone: null,
        companyEmail: null,
        contacts: []
      };
      this.visible=true;
    },
    send: async function() {
      var isNew=!this.user.id;
      if (!this.validate())
        appNotifications.show("Please fill in all required fields", "error");
      else {
        this.visible=false;
        startLoading();
        if (isNew) {
          this.user.password=this.user.username+"123";
          this.user.passChange=1;
          await api.create("User", this.user);
        } else
          await api.update("User", this.user.id, this.user);
        stopLoading(isNew?"Profile created":"Profile updated", "success");
        setTimeout(function() {
          location.replace(location.href);
        }, 3000);
      }
    },
    resetPassword: async function() {
      if (!this.user.id)
        return;
      startLoading();
      await api.update("User", this.user.id, {
        password: this.user.username+"123",
        passChange: 1,
      });
      stopLoading("Password reset", "success");
    },
  },
  mounted: async function() {
    window.userEditSub=this;
  },
  mixins: [formValidation.mixin]
}
</script>
