<template>
  <drawer
    title="Filters"
    @close="close()">
    <BasicForm>
      <div class="cof-field">
        <label for="">Date Created From</label>
        <input type="date" v-model="form.dateFrom" :max="today" class="w100" />
      </div>

      <div class="cof-field">
        <label for="">Date Created To</label>
        <input type="date" v-model="form.dateTo" :min="form.dateFrom" :max="today" class="w100" />
      </div>

      <ChSelect
        v-model="form.type"
        label="Type"
        :options="typeOptions" />
        
      <ChInput
        v-model="form.accountId"
        label="Account ID" />

      <ChInput
        v-model="form.name"
        label="Name" />

      <ChInput
        v-model="form.email"
        label="Email" />

      <ChSelect
        v-model="form.status"
        label="Status"
        :options="statusOptions" />
    </BasicForm>

    <template v-slot:footer>
      <div class="buttons">
        <ChButton color="gray" size="lg" class="btn" @click="close()">Cancel</ChButton>
        <ChButton size="lg" class="btn" @click="filter()">Filter</ChButton>
      </div>
    </template>
  </drawer>
</template>

<script>
  
  import Drawer from '../components/Drawer'
  import ChButton from '../common/ChButton'
  import BasicForm from '../components/ui/forms/Form'
  import ChInput from '../components/ui/forms/Input'
  import ChSelect from '../components/ui/forms/Select'

export default {
  components: {
    Drawer,
    ChInput,
    ChSelect,
    ChButton,
    BasicForm,
  },

  props: {
    filters: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      form: {
        dateFrom: null,
        dateTo: null,
        type: null,
        accountId: null,
        name: null,
        email: null,
        status: null
      },

      typeOptions: [
        {
          id: 'Partner',
          text: 'Partner'
        },
        {
          id: 'Customer',
          text: 'Customer'
        },
        {
          id: 'Sandbox',
          text: 'Sandbox'
        },
        {
          id: 'Dashboard',
          text: 'Dashboard'
        },
      ],

      statusOptions: [
        {
          id: 'Pending',
          text: 'Pending'
        },
        {
          id: 'Open',
          text: 'Open'
        },
        {
          id: 'Closed',
          text: 'Closed'
        },
        {
          id: 'Resolved',
          text: 'Resolved'
        },
      ]
    }
  },

  computed: {
    today () {
      return moment().format('YYYY-MM-DD')
    },

    dateLimiter () {
      return this.form.dateFrom ? new Date(this.form.dateFrom) : null
    }
  },

  watch: {
    filters: {
      handler () {
        if (this.filters) this.form = { ...this.filters }
      },
      deep: true,
      immediate: true
    }
  },

  methods: {
    filter () {
      this.$emit('filter', this.form)
      this.close()
    },

    close () {
      this.$emit('close')
    }
  }
}
</script>
<style lang="scss" scoped>
  .buttons {
    width: 100%;
    display: flex;
    .btn {
      flex: 1;
      &:first-of-type {
        margin-right: 15px;
      }
    }
  }
</style>
