<template>
<div>
  <div class="content-table-container">
    <table class="content-table">
      <tbody>
        <tr>
          <td>
            <div style="display: flex; align-items: center;">
            <label style="white-space: nowrap; margin-right: 10px;">Template:</label>
            <input v-model="template.name" style="text-align: left; margin-right: 10px; width: 71%; flex-shrink: 0" />
            <dyn-select v-model="templateId" :options="currentTemplates" style="text-align: left; " />
            <a style="margin-left: 45px; margin-right: 45px" v-if="templateId*1" class="remove" href="" v-on:click.prevent="deleteTemplate">&times;</a>
            <a style="margin-left: 45px; margin-right: 45px; width: 10px; flex-shrink: 0;" v-else></a>
            </div>
          </td>
        </tr>
        <tr>
          <th><label>
            <input type="checkbox" v-model="template.methods" value="CardBiller" />
            Payment Methods
          </label></th>
        </tr>
        <tr>
          <td style="text-align: left">
            <label>
              <input type="checkbox" v-model="template.methods" value="Visa" />
              Visa
            </label>
            <label>
              <input type="checkbox" v-model="template.methods" value="Mastercard" />
              Mastercard
            </label>
            <label>
              <input type="checkbox" v-model="template.methods" value="Discover" />
              Discover
            </label>
            <label>
              <input type="checkbox" v-model="template.methods" value="AMEX" />
              AMEX
            </label>
            <label>
              <input type="checkbox" v-model="template.methods" value="China UnionPay" />
              China UnionPay
            </label>
            <label>
              <input type="checkbox" v-model="template.methods" value="Debit" />
              Debit
            </label>
          </td>
        </tr>
        <tr>
          <th><label>
            <input type="checkbox" v-model="template.methods" value="CardBiller Plus" />
            Advanced Payment Methods
          </label></th>
        </tr>
        <tr>
          <td style="text-align: left">
            <label>
              <input type="checkbox" v-model="template.methods" value="Apple Pay" />
              Apple Pay
            </label>
            <label>
              <input type="checkbox" v-model="template.methods" value="Google Pay" />
              Google Pay
            </label>
            <label>
              <input type="checkbox" v-model="template.methods" value="Interac Online" />
              Interac Online
            </label>
          </td>
        </tr>
        <tr>
          <th><label>
            <input type="checkbox" v-model="template.methods" value="BankBiller" />
            Bank Clearing
          </label></th>
        </tr>
        <tr>
          <td style="text-align: left">
            <label>
              <input type="checkbox" v-model="template.methods" value="EFT" />
              EFT
            </label>
            <label>
              <input type="checkbox" v-model="template.methods" value="ACH" />
              ACH
            </label>
          </td>
        </tr>
        <tr>
          <th><label>
            <input type="checkbox" v-model="template.methods" value="CoinBiller" />
            CoinBiller
          </label></th>
        </tr>
        <tr>
          <td style="text-align: left">
            <label>
              <input type="checkbox" v-model="template.methods" value="Bitcoin" />
              Bitcoin
            </label>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="buttons">
    <button class="btn btn-large btn-primary" v-on:click.prevent="updateSettings">Submit</button>
  </div>  
</div>
</template>

<script>
export default {
  data() { return {
    template: {
      name: "",
      methods: [],
    },
    templates: [],
    templateId: 0,
    options: {
      enabledMethods: [],
    },
  }},
  watch: {
    templateId: {
      immediate: true,
      handler() {
        var that=this;
        if (this.templateId*1)
          this.template=this.templates.find(function(a) {
            return a.id==that.templateId;
          }) || {
            methods: []};
        else
          this.template={
            methods: []};
      },
    },
    templates: {
      immediate: true,
      handler() {
        var that=this;
        if (this.templateId*1)
          this.template=this.templates.find(function(a) {
            return a.id==that.templateId;
          }) || {
            methods: []};
        else
          this.template={
            methods: []};
      },
    },
  },
  mounted() {
    this.refresh();
  },
  computed: {
    currentTemplates() {
      var result=this.templates.map(function(a) {
        return {value: a.id, label: a.name};
      });
      result.unshift({value: 0, label: "(new)"});
      return result;
    },
  },
  methods: {
    refresh: async function() {
      var that=this;
      api.get("Options", 1).then(function(data) {
        that.options=data.item;
        that.options.enabledMethods=that.options.enabledMethods || [];
      });
      this.templates=(await api.list("MethodTemplate")).items || [];
    },
    updateSettings: async function() {
      startLoading();
      if (this.templateId) {
        await api.update("MethodTemplate", this.templateId, this.template);
        await this.refresh();
      } else {
        var newId=(await api.create("MethodTemplate", this.template)).id;
        await this.refresh();
        this.templateId=newId;
      }
      stopLoading("Payment method template updated");
    },
    deleteTemplate: async function() {
      if (!confirm("Are you sure?"))
        return;
      startLoading();
      if (this.templateId)
        await api.delete("MethodTemplate", this.templateId);
      await this.refresh();
      this.templateId=0;
      stopLoading("Payment method template deleted");
    },
  },
}
</script>
