<template>
<header class="page-header">
  <h1>{{ title }}</h1>
  <ul v-if="breadcrumbs" class="breadcrumbs">
    <li v-for="(item, index) in breadcrumbs">
      <i v-if="index > 0" />
      <a v-if="item.to" :href="item.to">{{ item.text }}</a>
      <template v-else>
        <span>{{ item.text }}</span>
      </template>
    </li>
  </ul>
</header>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },

    breadcrumbs: {
      type: Array,
      default: null
    }
  }
}
</script>
