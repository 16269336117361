<template>
  <div class="setup-box">
    <div class="ncontent appf appnat" :class="{'in-popup': popup}">
      <div class="implementation-note">
        <p>{{ appl.agentDb.implMessage }}</p>
      </div>
  
      <form class="form-step" key="step1">
        <section v-if="false">
          <h2>General Information</h2>
          <ul class="column-form column-form-compact">
            <li v-if="false">
              <label>Signup Form ID</label>
              <div class="field">
                <validator :value="0" reqs="">
                  <input type="text" readonly v-model="appl.id" />
                </validator>
              </div>
            </li>
            <li>
              <label>Partner Solutions</label>
              <div class="field">
                <validator :value="item.solutions" reqs="required">
                  <label v-for="solution in solutions" class="" :class="{checked: item.solutions.indexOf(solution.value)!=-1}">
                    <input type="checkbox" name="plan" v-model="item.solutions" :value="solution.value" />
                    <span></span>{{ solution.label }}
                  </label>
                </validator>
              </div>
            </li>
            <li>
              <label>Relationship Manager</label>
              <div class="field">
                <validator :value="item.managerId" reqs="required">
                  <dyn-select v-model="item.managerId" :options="managers" />
                </validator>
              </div>
            </li>
            <li>
              <label>Language</label>
              <div class="field">
                <validator :value="0" reqs="">
                  <dyn-select v-model="item.language" :options="languages" />
                </validator>
              </div>
            </li>
          </ul>
        </section>
        <section>
          <h2>Schedule A</h2>
          <ul class="column-form column-form-compact">
            <li>
              <label>Partner Tier</label>
              <div class="field">
                <validator :value="0" reqs="">
                  <dyn-select v-model="item.accClass" :options="accountTiers" />
                </validator>
              </div>
            </li>
            <li>
              <label>BIN/ICA Rental Fee</label>
              <div class="field">
                <validator :value="0" reqs="">
                  <formel-money-amount4 v-model="item.rentalFee" suffix="%" />
                </validator>
              </div>
            </li>
            <li>
              <label>Partner Setup Fee</label>
              <div class="field">
                <validator :value="0" reqs="">
                  <formel-money-amount4 v-model="item.setupFee" prefix="$" />
                </validator>
              </div>
            </li>
            <li>
              <label>Monthly Minimum</label>
              <div class="field">
                <validator :value="0" reqs="">
                  <formel-money-amount4 v-model="item.monthlyMinimum" prefix="$" />
                </validator>
              </div>
            </li>
            <li>
              <label>Compliance Review (days):</label>
              <div class="field">
                <validator :value="0" reqs="">
                  <dyn-select :options="reviewDaysOpts" v-model="item.complianceReview" />
                </validator>
              </div>
            </li>
          </ul>
          <h2>Banking</h2>
          <ul class="column-form column-form-compact" v-if="false && ['agent-inside', 'agent-outside'].indexOf(appl.groups)!=-1">
            <li><label>Settlement Currency</label><div class="field"><div class="validator"></div></div></li>
            <li><label>Name on Account</label><div class="field"><div class="validator"></div></div></li>
            <li><label>Bank Country</label><div class="field"><div class="validator"></div></div></li>
            <li><label>Bank State</label><div class="field"><div class="validator"></div></div></li>
            <li><label>Bank Name</label><div class="field"><div class="validator"></div></div></li>
            <li><label>Bank Account Number</label><div class="field"><div class="validator"></div></div></li>
            <li><label>Institution Number</label><div class="field"><div class="validator"></div></div></li>
            <li><label>Transit Number</label><div class="field"><div class="validator"></div></div></li>
            <li><label>Routing number</label><div class="field"><div class="validator"></div></div></li>
          </ul>
          <ul class="column-form column-form-compact" v-else-if="appl.agentDb.accounts.length">
            <li>
              <label>Account Currency</label>
              <div class="field">
                <validator :value="0" reqs="">
                  <dyn-select v-model="appl.agentDb.accounts[0].accountCurrency" :options="currencies2" />
                </validator>
              </div>
            </li>
            <li>
              <label>Name on Account</label>
              <div class="field">
                <validator :value="0" reqs="">
                  <input type="text" v-model="appl.agentDb.accounts[0].accountHolder" />
                </validator>
              </div>
            </li>
            <li>
              <label>Bank Country</label>
              <div class="field">
                <validator :value="appl.agentDb.accounts[0].bankAddressCountry" reqs="required">
                  <dyn-select v-model="appl.agentDb.accounts[0].bankAddressCountry" :options="countries" />
                </validator>
              </div>
            </li>
            <li>
              <label>Bank State</label>
              <div class="field">
                <validator :value="appl.agentDb.accounts[0].bankAddressState" reqs="required">
                  <dyn-select v-if="states.length" v-model="appl.agentDb.accounts[0].bankAddressState" :options="states" />
                  <input type="texta"  v-model="appl.agentDb.accounts[0].bankAddressState" v-else />
                </validator>
              </div>
            </li>
            <li>
              <label>Bank Name</label>
              <div class="field">
                <validator :value="0" reqs="">
                  <input type="text" v-model="appl.agentDb.accounts[0].bankName" />
                </validator>
              </div>
            </li>
            <li>
              <label>Bank Account Number</label>
              <div class="field">
                <validator :value="0" reqs="">
                  <input type="text" v-model="appl.agentDb.accounts[0].accountNumber" />
                </validator>
              </div>
            </li>
            <li>
              <label>Institution Number</label>
              <div class="field">
                <validator :value="0" reqs="">
                  <input type="text" v-model="appl.agentDb.accounts[0].accountSwift" />
                </validator>
              </div>
            </li>
            <li>
              <label>Transit Number</label>
              <div class="field">
                <validator :value="0" reqs="">
                  <input type="text" v-model="appl.agentDb.accounts[0].accountIBAN" />
                </validator>
              </div>
            </li>
            <li>
              <label>Routing number</label>
              <div class="field">
                <validator :value="0" reqs="">
                  <input type="text" v-model="appl.agentDb.accounts[0].accountRouting" />
                </validator>
              </div>
            </li>
          </ul>
        </section>

        <section>
          <h2>Access Information</h2>
          <ul class="column-form column-form-compact">
            <li>
              <label>Portal</label>
              <div class="field">
                <validator :value="0" reqs="">
                  <dyn-select v-model="item.portal" :options="portals" />
                </validator>
              </div>
            </li>
            <li>
              <label>Username</label>
              <div class="field">
                <validator :value="0" reqs="">
                  <input type="text" v-model="item.username" />
                </validator>
              </div>
            </li>
            <li>
              <label>Send welcome email</label>
              <div class="field">
                <validator :value="item.sendEmail" reqs="required">
                  <dyn-select v-model="item.sendEmail" :options="toggleYesNo" />
                </validator>
              </div>
            </li>
            <li v-if="['agent-inside', 'agent-outside'].indexOf(appl.groups)!=-1">
              <label>Company Email</label>
              <div class="field">
                <validator :value="0" reqs="">
                <input type="text" readonly v-model="agentFormattedEmail" />
                </validator>
              </div>
            </li>
            <li v-else>
              <label>Company Email</label>
              <div class="field">
                <validator :value="0" reqs="">
                <input type="text" readonly v-model="appl.agentDb.email" />
                </validator>
              </div>
            </li>
            <li>
              <label>PIN</label>
              <div class="field">
                <validator :value="item.pin" reqs="required">
                  <input type="text" readonly v-model="item.pin" />
                  <button v-on:click.prevent="generatePin">Generate PIN</button>
                </validator>
              </div>
            </li>
          </ul>
          <h2>Solutions</h2>
          <ul class="appview-list">
            <li style="width: 33%">
              <div style="display: flex;" class="validator">
                <span style="width: 66%">Payments</span>
                <mnb-switch v-if="settings && settings.modules.rPayments" :disabled="!settings.modules.rPayments" v-model="item.payments" />
                <mnb-switch v-else :disabled="true" :set="item.payments = false" />
              </div>
              <div style="display: flex;" class="validator" v-for="msolution in msolutions">
                <span style="width: 66%">{{ msolution.label }}</span>
                <mnb-switch :disabled="!item.payments" v-model="msolutionsSwitches[msolution.value]" />
              </div>
            </li>
            <li style="width: 33%">
              <div style="display: flex;" class="validator">
                <span style="width: 66%">Treasury</span>
                <mnb-switch v-if="settings && settings.modules.rBanking" :disabled="!settings.modules.rBanking" v-model="item.treasury" />
                <mnb-switch v-else :disabled="true" :set="item.treasury = false" />
              </div>
              <div style="display: flex;" class="validator" v-for="tsolution in tsolutions">
                <span style="width: 66%">{{ tsolution.label }}</span>
                <mnb-switch :disabled="!item.treasury" v-model="tsolutionsSwitches[tsolution.value]" />
              </div>
            </li>
          </ul>
          <h2>Modules</h2>
          <ul class="appview-list">
            <li style="width: 33%">
              <div style="display: flex;" class="validator">
                <span style="width: 66%">Amadel</span>
                <mnb-switch v-if="settings && settings.settings.partners.amadel" :disabled="!settings.settings.partners.amadel" v-model="appl.agentDb.amadel" />
                <mnb-switch v-else :disabled="true" :set="appl.agentDb.amadel = false" />
              </div>
              <div style="display: flex;" class="validator">
                <span style="width: 66%">Scheduler</span>
                <mnb-switch v-if="settings && settings.settings.partners.scheduler" :disabled="!settings.settings.partners.scheduler" v-model="appl.agentDb.scheduler" />
                <mnb-switch v-else :disabled="true" :set="appl.agentDb.scheduler = false" />
              </div>
            </li>
          </ul>
        </section>

        <section>
          <h2>Advanced Information</h2>
          <ul class="column-form column-form-compact">
            <li>
              <label>Plugg & Co. Registration</label>
              <div class="field">
                <validator :value="item.pluggReg" reqs="required">
                  <dyn-select v-model="item.pluggReg" :options="toggleYesNo" />
                </validator>
              </div>
            </li>
          </ul>
        </section>

        <section>
          <h2>Additional Information</h2>
          <ul class="column-form column-form-compact">
            <li>
              <label>Additional Build Notes</label>
              <div class="field">
                <validator :value="0" reqs="">
                  <textarea v-model="item.notes"></textarea>
                </validator>
              </div>
            </li>
          </ul>
        </section>
      </form>
    <!-- <form class="form-step" v-if="step==5" key="step9">
 
      <div class="buttons" v-if="!buildStarted">
        <a class="btn btn-large btn-success" v-on:click.prevent="startBuild">Start Build</a>
      </div>
      <div class="start-build" v-if="buildStarted" :class="{visible: buildStarted && !buildFinalized }"><div></div>
        <ul>
          <li :class="{visible: buildStep>=1}">
            <label>Generating PartnerID... {{ progress(1,2) }}</label>
            <span :class="{done: buildStep>=2}">✔</span>
          </li>
          <li :class="{visible: buildStep>=2}">
            <label>Portal... {{ progress(2,3) }}</label>
            <span :class="{done: buildStep>=3}">✔</span>
          </li>
          <li :class="{visible: buildStep>=3}">
            <label>Username... {{ progress(3,4) }}</label>
            <span :class="{done: buildStep>=4}">✔</span>
          </li>
          <li :class="{visible: buildStep>=4}">
            <label>Generating Activation Code.... {{ progress(4,5) }}</label>
            <span :class="{done: buildStep>=5}">✔</span>
          </li>
          <li :class="{visible: buildStep>=5}">
            <label>Account Security PIN... {{ progress(5,6) }}</label>
            <span :class="{done: buildStep>=6}">✔</span>
          </li>
          <li :class="{visible: buildStep>=6}">
            <label>Creating Plugg & Co registration... {{ progress(6,7) }}</label>
            <span :class="{done: buildStep>=7}">✔</span>
          </li>
          <li :class="{visible: buildStep>=7}">
            <label>Sending data... {{ progress(7,9) }}</label>
            <span :class="{done: buildStep>=9}">✔</span>
          </li>
          <li :class="{visible: buildStep>=9}">
            <label><strong>Receiving data (Registration ID, username, Activation Code)... {{ progress(9,11) }}</strong></label>
            <span :class="{done: buildStep>=11}">✔</span>
          </li>
          <li :class="{visible: buildStep>=11}">
            <label>Note... {{ progress(11,12) }}</label>
            <span :class="{done: buildStep>=12}">✔</span>
          </li>
        </ul>
      </div>
      <div class="buttons">
        <a class="btn btn-large btn-plain btn-back" v-on:click.prevent="prevStep">Back</a>
        <a class="btn btn-large btn-success" :class="{'btn-disabled': !buildFinalized}" v-on:click.prevent="save">Finalize Build</a>
      </div>
    </form> -->
    </div>
  </div>
</template>

<script>
import MnbSwitch from './switch.vue'
export default {
  components: {
    MnbSwitch,
  },
  props: ['popup'],
  data() { return {
    groups: [
      {value: "agent-iso", label: "Independent Sales Organization - ISO"},
      {value: "agent-isa", label: "Independent Sales Agent - ISA"},
      {value: "agent-auth", label: "Authorized Agent"},
      {value: "agent-ref", label: "Referral Partner/Intergrated Software Vendor - ISV"},
      {value: "agent-isv", label: "Integrated Software Vendor"},
//       {value: "agent-accex", label: "Account Executive"},
//       {value: "agent-sales", label: "Sales Associate"},
    ],
    settings: null,
    portals: [{value: appData.settings.partnerLoginURL, label: appData.settings.partnerLoginURL}],
    optsCount: [{value: '0', label: '0'}, {value: '1', label: '1'},
                {value: '2', label: '2'}, {value: '3', label: '3'},
                {value: '4', label: '4'}, {value: '5', label: '5'},
                {value: '6', label: '6'}, {value: '7', label: '7'},
                {value: '8', label: '8'}, {value: '9', label: '9'},
                {value: '10', label: '10'}, {value: '11', label: '11'},
                {value: '12', label: '12'}, {value: '13', label: '13'},
                {value: '14', label: '14'}, {value: '15', label: '15'},
                {value: '16', label: '16'}, {value: '17', label: '17'},
                {value: '18', label: '18'}, {value: '19', label: '19'},
                {value: '20', label: '20'}],
    shown: false,
    step: 0,
    item: {solutions: [], msolutions: [], tsolutions: [], regions: "", pluggReg: 0, pluggRegId: "", agentDb: {}},
    appl: {agentDb: {}},
    managers: [],
    internalNotes: {},
    validMapps: [
      {label: "0-30", value: "0-30"},
      {label: "31-50", value: "31-50"},
      {label: "51-75", value: "51-75"},
      {label: "76-99", value: "76-99"},
      {label: "100+", value: "100+"},
    ],
    languages: [
      {value: "English", label: "English"},
    ],
    accountClassesList: [
      {value: "Class 100", label: "Class 100"},
      {value: "Class 150", label: "Class 150"},
      {value: "Class 200", label: "Class 200"},
      {value: "Class 250", label: "Class 250"},
      {value: "Class 300", label: "Class 300"},
      {value: "Class 350", label: "Class 350"},
      {value: "Class 400", label: "Class 400"},
      {value: "Class 450", label: "Class 450"},
      {value: "Class 500", label: "Class 500"},
      {value: "Class 550", label: "Class 550"},
      {value: "Class 600", label: "Class 600"},
      {value: "Class 650", label: "Class 650"},
      {value: "Class 700", label: "Class 700"},
      {value: "Class 750", label: "Class 750"},
      {value: "Class 800", label: "Class 800"},
      {value: "Class 850", label: "Class 850"},
      {value: "Class 900", label: "Class 900"},
    ],
    accountTiers: [
      {value: "10", label: "10"},
      {value: "20", label: "20"},
      {value: "30", label: "30"},
      {value: "40", label: "40"},
      {value: "50", label: "50"},
    ],
    salesBrands: [],
    currencies: {"840": "USD", "124": "CAD"},
    currencies2: [
      {value: "124", label: "CAD"},
      {value: "840", label: "USD"},
    ],
    availQuantities: [
      {label: "0", value: 0}, {label: "1", value: 1}, {label: "2", value: 2},
      {label: "3", value: 3}, {label: "4", value: 4}, {label: "5", value: 5},
      {label: "6", value: 6}, {label: "7", value: 7}, {label: "8", value: 8},
      {label: "9", value: 9}, {label: "10", value: 10},
    ],
    reviewDaysOpts: [
      {value: "30", label: "30"},
      {value: "60", label: "60"},
      {value: "90", label: "90"},
      {value: "180", label: "180"},
      {value: "365", label: "365"},
    ],
    equipment: [],
    quantities: [],
    rental: [],
    options: [],
    modules: [],
    availOptEnabled: [{label: "No", value: false}, {label: "Yes", value: true}],
    optionsEnabled: [],
    modulesEnabled: [],
    pricingTemplates: [],
    templateId: null,
    accountClasses: [],
    optionTemplates: [],
    optionTemplateId: "0",
    moduleTemplates: [],
    moduleTemplateId: "0",
    enabledPayOpts: [],
    methodTemplateId: "0",
    methodTemplates: [],
    solutions: [],
    msolutions: [],
    tsolutions: [],
    msolutionsSwitches: [],
    tsolutionsSwitches: [],
    editing: false,
    toggleYesNo: [
      {label: "Yes", value: 1},
      {label: "No", value: 0},
    ],
    buildStarted: false,
    buildStep: 0,
    buildFinalized: false,
    countries: [
      {label: "United States", value: "United States"},
      {label: "Canada", value: "Canada"},
    ],
    tobOptions: [
      "Inc.",
      "Corp.",
      "LLC",
      "LLP",
      "Ltd.",
      "Sole Proprietor",
      "",
    ],
  }},
  mounted() {
    this.solutions=appData.solutions;
    this.msolutions=appData.msolutions;
    this.tsolutions=appData.tsolutions;
    window.setupPopup=this;
    this.managers=appData.sources.map(function(a) {
      return {value: a.id, label: a.fullName};
    });
    if (appData.internalNotes)
      this.internalNotes=appData.internalNotes;
    this.loadSalesBrands();
    this.generatePassword();
  },
  computed: {
    states() {
      return allProvinces[this.appl.agentDb.accounts[0].bankAddressCountry] || [];
    },
//     currentOptTemplates() {
//       var result=this.optionTemplates.map(function(a) {
//         return {value: a.id, label: a.name};
//       });
//       result.unshift({value: 0, label: "(custom)"});
//       return result;
//     },
    agentFormattedEmail() {
      var email=this.salesBrandForId(this.item.salesBrand).email || "";
      var contact=this.appl.contacts[0] || {};
      var emailFN=contact.firstName.toLowerCase().replace(/[^a-z]/g, "");
      var emailLN=contact.lastName.toLowerCase().replace(/[^a-z]/g, "");
      return emailFN+"."+emailLN+(email.replace(/^[^@]*/, ""));
    },
  },
  methods: {
    async generatePassword() {
      this.item.password=(await api.password()).password;
    },
    progress(min, max) {
      if (this.buildStep<min)
        return "";
      if (this.buildStep>=max)
        return "";
      var percent=Math.round(((this.buildStep-min)/(max-min))*100);
      return "("+percent+" of 100%)";
    },
    startBuild() {
      this.buildStarted=true;
      var buildInterval=setInterval(()=>{
        this.buildStep+=0.02;
        if (this.buildStep>=12) {
          this.buildFinalized=true;
          clearInterval(buildInterval);
        }
      }, 18);
      
      this.updatePlugg();
    },
    async updatePlugg() {
      if (!this.item.id)
        return;
      var result=(await api.action("PartnerSetup", this.item.id, "pluggReg")).action;
      this.item.pluggRegId=result.pluggRegId;
    },
    groupLabel(id) {
      for(var item of this.groups)
        if (item.value==id)
          return item.label;
      return id;
    },
    viewSolutions(solutions) {
      if (!solutions.join) {
        solutions=[solutions];
      }
      var result=[];
      for(var solutionId of solutions)
        for(var solution of this.solutions)
          if (solution.value==solutionId)
            result.push(solution.label);
      return result.join(", ");
    },
    loadSalesBrands: async function() {
      this.salesBrands=[{label: '(none)', value: null}];
      var items=(await api.list("SalesBrand", {}, ["brandId:ASC"])).items || [];
      for(var i in items) {
        var item=items[i];
        this.salesBrands.push({label: item.brandId, value: item.id, item: item});
      }
    },
    salesBrandForId(id) {
      for(var i in this.salesBrands)
        if (this.salesBrands[i].value==id)
          return this.salesBrands[i].item;
      return {};
    },
    loadModules: async function() {
      this.modules=(await api.list("CompanyModule", [], ["id:ASC"])).items || [];
      for(var i in this.modules)
        this.modulesEnabled[i]=false;
    },
    loadEquipment: async function() {
      this.equipment=(await api.list("Equipment", {enabled: 1})).items || [];
      for(var i in this.equipment)
        this.quantities[i]=0;
    },
    loadSysOptions: async function() {
      var item=(await api.get("Options", 1)).item || {};
      this.enabledPayOpts=item.enabledMethods || [];
    },
    dismiss() {
      this.shown=false;
      this.step=0;
      this.appl={};
      this.item={solutions: [], regions: []};
    },
    show(agent) {
      this.shown=true;
      this.step=0;
      this.appl=agent;
      this.item={
        agentId: agent.id,
        created: "CURRENT_TIMESTAMP",
        name: agent.companyName+" "+agent.agentId,
        accClass: "",
        managerId: agent.manager,
        filename: null,
        signed: null,
        salesBrand: null,
        solutions: [],
        msolutions: agent.msolutions || [],
        tsolutions: agent.tsolutions || [],
        regions: [],
        portal: 'amadel',
        pin: null,
        username: null,
        payments: agent.payments || false,
        treasury: agent.treasury || false,
      };
      if (this.appl.agentDb.accounts.length==0) {
        this.appl.agentDb.accounts.push({
          accountCurrency:"",
          accountCurrencies:"",
          accountHolder:"",
          accountNumber:"",
          accountSwift:"",
          accountIBAN:"",
          accountBankCode:"",
          accountRouting:"",
          bankName:"",
          bankAddress:"",
          bankAddressPostCode:"",
          bankAddressCity:"",
          bankAddressState:"",
          bankAddressCountry:"",
          bankPhone:""
        });
      }
      setTimeout(()=>{
        this.generatePassword();
        this.item.username=this.generateUsername();
      }, 300);


      var asolutions = this.item.msolutions;
      for(var msolution of this.msolutions) {
        for(var solutionId of asolutions)
          if(msolution.value == solutionId){
            this.msolutionsSwitches[msolution.value] = true;
            break;
          }
          else
            this.msolutionsSwitches[msolution.value] = false;
      }

      var rsolutions = this.item.tsolutions;
      for(var tsolution of this.tsolutions) {
        for(var solutionId of rsolutions)
          if(tsolution.value == solutionId){
            this.tsolutionsSwitches[tsolution.value] = true;
            break;
          }
          else
            this.tsolutionsSwitches[tsolution.value] = false;
      }

    },
    showExisting(setup, agent) {
      this.show(agent);
      this.item=setup;
      this.$set(this.item, "tsolutions", this.item.tsolutions || []);
      this.step=this.item.currentStep || 0;
    },
    nextStep() {
      if (!this.validate())
        return stopLoading("Please fill all required fields", "error");
      this.step++;
      if (this.step==4) {
        this.item.username=this.generateUsername();
      }
    },
    generatePin: async function() {
      this.item.pin=(await api.pin()).pin;
    },
    generateUsername() {
      var value="";
      if (["agent-inside", "agent-outside"].indexOf(this.appl.groups)!=-1) {
        value=(this.appl.fullName || this.appl.contacts[0].firstName)+" "+
              (this.appl.lastName || this.appl.contacts[0].lastName);
      } else {
        value=this.appl.agentDb.name;
      }
      return (value||"").toLowerCase().replace(/[^a-zA-Z0-9]+/g, "-").replace(/^-|-$/, "");
    },
    save: async function() {
      startLoading();
      var salesBrand=this.salesBrandForId(this.item.salesBrand);
      var result=JSON.parse(JSON.stringify(this.item));
      result.status="Completed";
      if (result.id)
        await api.update("PartnerSetup", result.id, result);
      else
        await api.create("PartnerSetup", result);


      this.item.tsolutions = [];
      for(var i in this.msolutionsSwitches){
        if(this.msolutionsSwitches[i]) {
          this.item.msolutions.push(i)
        }
      }

      this.item.tsolutions = [];
      for(var i in this.tsolutionsSwitches){
        if(this.tsolutionsSwitches[i]) {
          this.item.tsolutions.push(i)
        }
      }
      var updatedItem=(await api.update("User", this.appl.id, {
        groups: this.appl.groups,
        manager: this.item.managerId,
//         agentId: "APP_ID_GEN_AGENT",
        username: this.item.username,
        active: "Yes",
        agentDb: {
          id: this.appl.agentDb.id,
          accounts: this.appl.agentDb.accounts,
          agentClass: this.item.accClass,
          language: this.item.language,
          pin: this.item.pin,
          solutions: this.item.solutions,
          msolutions: this.item.msolutions,
          tsolutions: this.item.tsolutions,
          regions: this.item.regions,
          status: "A07",
          amadel: this.appl.agentDb.amadel,
          scheduler: this.appl.agentDb.scheduler,
        },
        skipNote: true,
        companyName: this.appl.agentDb.name || salesBrand.dba,
        companyPhone: this.appl.agentDb.phone || salesBrand.phone,
        companyEmail: this.appl.agentDb.email || this.agentFormattedEmail,
      }, true)).item;
      await api.update("Partner", updatedItem.agentDb.id, {partnerId: updatedItem.agentId});
      await api.create("PartnerEmail", {agentId: this.appl.agentDb.id, emailType: "Approved", created: "CURRENT_TIMESTAMP", newPassword: this.item.password});
      
      stopLoading("Setup completed");
      this.dismiss();

      if (this.popup) {
        this.$parent.$emit("next");
      } else {
        window.open("/implementation/onboarding", "_self");
      }
        
//       viewAgent.listSetups();
      location.reload();
    },
    saveDraft: async function() {
      this.editing=false;
      startLoading();
      var salesBrand=this.salesBrandForId(this.item.salesBrand);
      var result=JSON.parse(JSON.stringify(this.item));
      result.currentStep=this.step;
      if (result.id)
        await api.update("PartnerSetup", this.item.id, result);
      else
        await api.create("PartnerSetup", result);
      await api.update("User", this.appl.id, {
        groups: this.appl.groups,
        manager: this.item.managerId,
        agentDb: {
          id: this.appl.agentDb.id,
          accounts: this.appl.agentDb.accounts,
          agentClass: this.item.accClass,
          language: this.item.language,
          pin: this.item.pin,
          solutions: this.item.solutions,
          msolutions: this.item.msolutions,
          regions: this.item.regions,
          amadel: this.appl.agentDb.amadel,
          scheduler: this.appl.agentDb.scheduler,
        },
        companyName: this.appl.agentDb.name || salesBrand.dba,
        companyPhone: this.appl.agentDb.phone || salesBrand.phone,
        companyEmail: this.appl.agentDb.email || this.agentFormattedEmail,
      });
      
      stopLoading("Setup saved");
      viewAgent.listSetups();
      this.dismiss();
    },
    validateStep: function($event) {
//       if (this.editing)
//         return;
      this.editing=false;
      if (!this.validate())
        return stopLoading("Please fill all required fields", "error");      
    },
    prevStep: function() {
      this.step--;
      this.$nextTick(()=>{ this.validateStep("noanim"); });
    },
  },

  async created() {
    this.settings=(await api.get("Settings", 1)).item;
  },

  mixins: [formValidation.mixin, vueMixins.dateFormatter]
}
</script>
