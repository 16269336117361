<template>
<div>
  <div class="ncontent nc-flat custom-width">
    <div style="margin-left: 25px;">
        <ul class="breadcrumbs">
          <li><a>New Employee</a></li>
        </ul>
      </div>
    <div class="ncontent touch-it">
      <div class="merchant-panel">
        <div class="data-content">
          <ul class="tabs">
            <li v-for="(tab, idx) in tabs" :class="{ active: activeTab == idx }">{{ tab }}</li>
          </ul>
        </div>
      </div>

      <div v-if="activeTab === 0" class="custom-form pad20">
        <div class="input-group">
          <label>First Name</label>
          <validator :value="form.first_name" reqs="required">
            <input type="text" v-model="form.first_name" @keypress="allowLetterNumber($event, { numbers: false })">
          </validator>
        </div>
        <div class="input-group">
          <label>Email First Name</label>
          <validator :value="form.email_first_name" reqs="required">
            <input type="text" v-model="form.email_first_name" @keypress="allowLetterNumber($event, { numbers: false })">
          </validator>
        </div>
        <div class="input-group">
          <label>Last Name</label>
          <validator :value="form.last_name" reqs="required">
            <input type="text" v-model="form.last_name" @keypress="allowLetterNumber($event, { numbers: false })">
          </validator>
        </div>
        <div class="input-group" v-if="companies">
          <label>Employee Solution</label>
          <validator :value="form.companyId" reqs="required">
            <select v-model="form.companyId">
            <option :value="company.id" v-for="company in companies">{{ company.nameSolution }}</option>
            </select>
          </validator>
        </div>
<!--        <div class="input-group">
          <label>Sales Brand</label>
          <validator :value="form.salesBrandId" reqs="required">
            <select v-model="form.salesBrandId">
              <option v-for="brand in salesBrand" :key="brand.id" :value="brand.id">{{ brand.brandId }} - {{ brand.dba }}</option>
            </select>
          </validator>
        </div>-->
        <div class="input-group">
          <label>Company Email</label>
          <validator :value="'1'" reqs="required">
            <select :value="null">
              <option :value="null">{{ currentCompany.emailSuffix }}</option>
            </select>
          </validator>
        </div>
        <div class="input-group">
          <label>Title</label>
          <validator :value="form.title" reqs="required">
            <input type="text" v-model="form.title">
          </validator>
        </div>
        <div class="input-group">
          <label>Email</label>
          <validator :value="uniqueEmail" reqs="required email">
            <input type="text" v-model="form.email" :readonly="!customEmail">
          </validator>
          <label class="checkbox">
            <input type="checkbox" v-model="customEmail" />
            Custom email
          </label>
        </div>
        <div class="input-group phone-input">
          <div class="w100">
            <label>Phone</label>
            <validator :value="form.phone" reqs="required">
              <input type="text" v-model="form.phone" class="w100">
            </validator>
          </div>
          <div class="ext">
            <label>Ext</label>
            <validator :value="form.phone_ext" reqs="">
              <input type="text" v-model="form.phone_ext" maxlength="5">
            </validator>
          </div>
        </div>
        <div class="input-group">
          <label>Role</label>
          <validator :value="form.role" reqs="required">
            <select v-model="form.role">
              <option v-for="group in titleGroups" :key="group" :value="group">{{ groups[group] }}</option>
            </select>
          </validator>
        </div>
      </div>

      <div v-if="activeTab === 1" class="custom-form pad20">
        <h3 class="subtitle">Personal Information</h3>
        <div class="input-group large">
          <label>Address</label>
          <validator :value="form.address" reqs="required">
            <input type="text" v-model="form.address">
          </validator>
        </div>
        <div class="input-group medium">
          <label>City</label>
          <validator :value="form.city" reqs="required">
            <input type="text" v-model="form.city" @keypress="allowLetterNumber($event)">
          </validator>
        </div>
        <div class="input-group medium">
          <label>Province</label>
          <validator :value="form.province" reqs="required">
            <input type="text" v-model="form.province" maxlength="2" @keypress="allowLetterNumber($event)">
          </validator>
        </div>
        <div class="input-group medium">
          <label>Postal</label>
          <validator :value="form.postal" reqs="required">
            <input type="text" v-model="form.postal" maxlength="7" @keypress="allowLetterNumber($event, postalRule)">
          </validator>
        </div>
        <div class="input-group medium">
          <label>Country</label>
          <validator :value="form.country" reqs="required">
            <input type="text" v-model="form.country" @keypress="allowLetterNumber($event)">
          </validator>
        </div>
        <h3 class="subtitle"></h3>
        <div class="input-group large">
          <label>Email</label>
          <validator :value="form.personalemail" reqs="required email">
            <input type="text" v-model="form.personalemail">
          </validator>
        </div>
        <div class="input-group large">
          <label>Phone</label>
          <validator :value="form.personalphone" reqs="required phone">
            <input type="text" v-model="form.personalphone">
          </validator>
        </div>

        <h3 class="subtitle">Emergency Contact</h3>

        <div class="input-group w25">
          <label>First Name</label>
          <validator :value="form.ec_first_name" reqs="required">
            <input type="text" v-model="form.ec_first_name">
          </validator>
        </div>
        <div class="input-group w25">
          <label>Last Name</label>
          <validator :value="form.ec_last_name" reqs="required">
            <input type="text" v-model="form.ec_last_name">
          </validator>
        </div>
        <div class="input-group w25">
          <label>Email</label>
          <validator :value="form.ec_email" reqs="required email">
            <input type="email" v-model="form.ec_email">
          </validator>
        </div>
        <div class="input-group w25">
          <label>Phone</label>
          <validator :value="form.ec_phone" reqs="required">
            <input type="text" v-model="form.ec_phone">
          </validator>
        </div>
      </div>

      <div v-if="activeTab === 2" class="custom-form pad20 inline-form">
        <div class="input-group w100">
          <label>Portal:</label>
          <validator :value="form.portal" reqs="required" class="inline-block">
            <select v-model="form.portal">
              <option v-for="(opt, key) in portalOptions" :key="key" :value="key">{{ opt }}</option>
            </select>
          </validator>
        </div>
        <div class="input-group w100">
          <label>Username: {{ username }}</label>
        </div>
        <div class="input-group w100">
          <label>Email: {{ autogeneratedEmail }}</label>
        </div>
        <div class="input-group w100">
          <label>Role: {{ userGroup(form.role) }}</label>
        </div>

        <h3 class="subtitle">Permissions</h3>
      </div>

      <div v-if="activeTab === 3" class="pad20">
        <notes
          ref="notes"
          class="appl-notes"
          :notes="[]"
          type="Employee"
          await />
      </div>
      <div class="buttons clear-after">
        <button v-if="activeTab > 0" class="btn btn-large btn-primary btn-back" @click="prevNext(false)">Previous Step</button>
        <button v-if="activeTab < 3" class="btn btn-large btn-primary floatr no-margin" @click="prevNext()">Next Step</button>
        <button v-if="activeTab === 3" class="btn btn-large btn-primary floatr no-margin" @click="save()">FINALIZE</button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  mixins: [ vueMixins.helpers, formValidation.mixin ],

  data () {
    return {
      activeTab: 0,
      tabs: ['Profile', 'Personal', 'Access', 'Notes'],
      salesBrand: [],
      companies: null,
      groups: window.userGroups,
      titleGroups: window.employeeRoles,
      form: {},
      uniqueEmail: null,
      customEmail: false,
      postalRule: {
        numbers: true,
        allow: [45]
      }
    }
  },

  watch: {
    autogeneratedEmail (value) {
      this.form.email = value
      this.form.username = this.username
    }
  },

  computed: {
    portalOptions () {
      return window.employeePortals
    },

    username () {
      if (!this.form.email_first_name || !this.form.last_name) return ''
      let firstName = this.formatName(this.form.email_first_name)
      let lastName = this.formatName(this.form.last_name)
      return firstName + '-' + lastName
    },

    autogeneratedEmail () {
      if (!this.form.email_first_name || !this.form.last_name || !this.currentCompany.emailSuffix) return ''
      //let salesBrand = this.salesBrand.find(item => item.id === this.form.salesBrandId)
      let firstName = this.formatName(this.form.email_first_name)
      let lastName = this.formatName(this.form.last_name)
      return firstName + '.' + lastName + this.currentCompany.emailSuffix;
    },
    
    currentCompany() {
      for(var company of (this.companies || [])) {
        if (company.id==this.form.companyId)
          return company;
      }
      return {};
    },
  },

  created () {
    this.initForm()
    api.list("SalesBrand").then((data) => {
      this.salesBrand = data.items
    })
    api.list("Solution", {solutionType: "Employees"}).then((data) => {
      this.companies = data.items
    })
  },

  methods: {
    async save () {
      if (!this.validate()) return stopLoading('Please fill all required fields', 'error')
      startLoading()
      try {
        const userId = (await this.createUser()).id
        
        const payload = {
          ...this.form,
          userId,
          status: 'PENDING',
          created: 'CURRENT_TIMESTAMP',
          createdBy: appData.currentUser.id,
        }

        const empId = (await api.create("Employee", payload)).id

        const formattedId = this.formatID('81', empId)
        await this.addNote(`Employee File Created - ${formattedId}`, empId)

        await api.update("Employee", empId, {employeeId: formattedId});
        await api.action("User", userId, "WelcomeEmailPassword")
        await this.addNote('Welcome email sent', empId)

        
        if (this.$refs.notes.newNote) this.$refs.notes.createNote()

        await this.$refs.notes.bulkCreate(empId)
        
        this.initForm()
        this.validateReset()
        stopLoading("Saved")
      } catch (e) {
        stopLoading(e, 'error')
      }
    },

    createUser () {
      const payload = {
        active: "Yes",
        username: this.username,
        fullName: this.form.first_name + ' ' + this.form.last_name,
        lastName: this.form.last_name,
        email: this.form.email,
        title: this.form.title,
        groups: this.form.role,
        phone: this.form.phone,
        phoneExt: this.form.phone_ext,
        passChange: true,
        skipPasswordEmail: true,
      }
      return api.create("User", payload)
    },

    addNote (message, employeeId) {
      return api.create("Note", {
        message,
        parentId: employeeId,
        type: 'Employee',
        created: "CURRENT_TIMESTAMP",
        author: appData.currentUser.id,
        system: 1
      })
    },

    formatName (name) {
      return name.replace(/\s/g, '.').toLowerCase()
    },

    async prevNext (next = true) {
      startLoading()
      if (this.activeTab === 0 && next && this.autogeneratedEmail) {
        const response = await api.list('Employee', { email: this.autogeneratedEmail })
        this.uniqueEmail = response.items.length ? false : this.autogeneratedEmail
      }
      this.$nextTick(() => {
        if (next && !!this.validate()) {
          this.validateReset()
          this.activeTab++
        }
        if (!next) this.activeTab--
        if (!this.uniqueEmail && this.autogeneratedEmail) stopLoading('This email already exists.', 'error')
        else stopLoading()
      })
    },

    initForm () {
      this.activeTab = 0
      this.$set(this, 'form', {
        username: null,
        first_name: null,
        email_first_name: null,
        last_name: null,
        title: null,
        companyId: 1,
        salesBrandId: null,
        email: null,
        phone: null,
        phone_ext: null,
        role: null,
        address: null,
        city: null,
        province: null,
        postal: null,
        country: null,
        ec_first_name: null,
        ec_last_name: null,
        ec_phone: null,
        ec_email: null,
        portal: null
      })
    }
  },
}
</script>
