<template>
<div>
  <div class="ncontent">
    <form class="search-form">
    <div class="header split">
      <div>
        <ul class="breadcrumbs">
          <li><a>Employee Overview</a></li>
        </ul>
        <div class="toolbar" />
          <h3>Search Options</h3>
          <div class="f2split">
            <div>
              <div class="field">
                <label>Date</label>
                <div class="field-group">
                  <datepicker type="text" v-model="search.dateStart" />
                  <datepicker type="text" v-model="search.dateEnd" />
                </div>
              </div>
              <div class="field">
                <label>First Name</label>
                <input type="text" v-model="search.first_name" />
              </div>
              <div class="field">
                <label>Last Name</label>
                <input type="text" v-model="search.last_name" />
              </div>
              <div class="field">
                <label>Username</label>
                <input type="text" v-model="search.username">
              </div>
              <div class="field">
                <label>Email</label>
                <input type="email" v-model="search.email">
              </div>
              <div class="field">
                <label>Title</label>
                <select v-model="search.title">
                  <option v-for="group in titleGroups" :key="group" :value="group">{{ group }}</option>
                </select>
              </div>
            </div>
            <div>
              <div class="field">
                <label>Status</label>
                <select v-model="search.status">
                  <option value="">(all)</option>
                  <option value="PENDING">Pending</option>
                  <option value="ACTIVE">Active</option>
                  <option value="INACTIVE">Inactive</option>
                </select>
              </div>
            </div>
          </div>
          <button class="btn btn-small btn-primary" v-on:click.prevent="updateResults">Search</button>
          <button class="btn btn-small btn-primary" v-on:click.prevent="clearResults">Clear</button>
      </div>
      <aside>
        <h3>Summary Report</h3>
        <table class="breakdown-table">
          <thead>
            <tr>
              <th>Status</th>
              <th>Count</th>              
            </tr>
            <tr>
              <th colspan="2" class="spacer"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in list">
              <td>{{ item.name }}</td>
              <td><template v-if="item.data">{{ item.data.length }}</template></td>             
            </tr>
          </tbody>
        </table>
      </aside>
    </div>
  </form>
    <div class="hr"></div>
    <div class="content">
      <div>
        <p class="result-count">Your search returned <span>{{data.length}}</span> result(s).</p>
        <ul class="acc-results">
          <li>
            <h4 @click="show = !show"><span class="label">Employees</span></h4>
            <div v-show="show" class="acc-list" style="display: block;">
              <table>
                <thead>
                  <tr>
                    <th>Empl ID</th>
                    <th>Company</th>
                    <th>Name</th>
                    <th>Username</th>
                    <th>Email</th>
                    <th>Title</th>
                    <th>Status</th>
                    <th>Last Login</th>
                    <th>Options</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="emp in data" :key="data.id">
                    <td>
                      <a :href="`/employees/view/${emp.id}`" class="link">{{ emp.employeeId }}</a>
                    </td>
                    <td>{{ emp.companyId.name }}</td>
                    <td>{{ emp.first_name }} {{ emp.last_name }}</td>
                    <td>{{ emp.username }}</td>
                    <td>{{ emp.email }}</td>
                    <td>{{ emp.title }}</td>
                    <td>{{ emp.status }}</td>
                    <td>{{ emp.lastLogin ? formatDate(emp.lastLogin, 'MM/DD/YYYY') : 'N/A' }}</td>
                    <td v-if="emp.status=='PENDING'"><a href="" v-on:click.prevent="removeEmp(emp)">Remove</a></td>
                    <td v-else>-</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  mixins: [ vueMixins.helpers, vueMixins.dateFormatter ],

  data () {
    return {
      show: false,
      search: {
        dateStart: null,
        dateEnd: null,
        first_name: "",
        last_name: "",
        username: "",
        email: "",
        title: "",
        status: "",
      },
      titleGroups: {
        "Super Admin": "Super Admin",
        "System Administrator": "System Administrator",
        "Administrator": "Administrator",
        "Admin Analyst": "Admin Analyst",
        "Compliance Manager": "Compliance Manager",
        "Risk Analyst": "Risk Analyst",
        "Compliance Analyst": "Compliance Analyst",
        "Finance": "Finance",
        "Operations Manager": "Operations Manager",
        "Account Manager": "Account Manager",
        "Relationship Manager": "Relationship Manager",
        "Partner Support": "Partner Support",
        "Merchant Services": "Merchant Services",
        "Business Services Manager": "Business Services Manager",
        "Business Services": "Business Services",
        "Tech Support": "Tech Support",
        "Independent Sales Organization": "Independent Sales Organization",
        "Independent Sales Agent": "Independent Sales Agent",
        "Authorized Agent": "Authorized Agent",
        "Referral Partner": "Referral Partner",
        "Integrated Software Vendor": "Integrated Software Vendor",
        "Master Merchant": "Master Merchant",
        "Merchant": "Merchant",
        "Senior Executive": "Senior Executive",
        "Executive": "Executive",
      },
      data: []
    }
  },

  computed: {
    activeList () {
      return this.data.filter(item => item.status === 'ACTIVE')
    },

    inactiveList () {
      return this.data.filter(item => item.status === 'INACTIVE')
    },

    pendingList () {
      return this.data.filter(item => item.status === 'PENDING')
    },

    list () {
      return {
        ACTIVE: {
          name: 'Active',
          data: this.activeList
        },
      }
    }
  },

  created () {
    this.load()
  },

  methods: {
    openPreview (emp) {
      console.log('preview', emp)
    },

    async load () {
      this.data = (await api.list('Employee')).items
    },

    async updateResults() {
      var q={};
     
      if (this.search.dateStart)
        q.dateStart=[">=", this.search.dateStart, "created"];

      if (this.search.dateEnd)
        q.dateEnd=["<=", this.search.dateEnd+' 23:59:59', "created"];
     
      if (this.search.first_name) {
        q.first_name=["LIKE", "%"+this.search.first_name+"%"];
      }

      if (this.search.last_name) {
        q.last_name=["LIKE", "%"+this.search.last_name+"%"];
      }

      if (this.search.username) {
        q.username=["LIKE", "%"+this.search.username+"%"];
      }
        
      if (this.search.email)
        q.email=["LIKE", "%"+this.search.email+"%"];

      if (this.search.title)
        q.title=this.search.title;

      if (this.search.status)
        q.status=this.search.status;

      this.data = (await api.list("Employee", q)).items;
    },

    clearResults() {
      this.search = {
        dateStart: null,
        dateEnd: null,
        first_name: "",
        last_name: "",
        username: "",
        email: "",
        title: "",
        status: "",
      }
      this.updateResults()
    },

    async removeEmp(emp) {
      if (!confirm('Are you sure you want to remove this user? Employee will be removed from the system and cannot be recovered'))
        return;
      
      await api.update("User", emp.userId.id, {deleted: 'Yes'});
      emp.userId.deleted='Yes';
    },
  }
}
</script>
