<template>
  <div class="pricing-template">
    <ul v-if="!boarding" class="pricing-list pl2 madon">
      <li style="width: 100%">
        <label>Account Type</label>
        <validator style="width: 100%;" :value="currentTemplate.accountType" reqs="required">
          <multiselect-dropdown :disabled="!editMode" :options="accountTypeOpts" v-model="currentTemplate.accountType" :isMulti="true" />
        </validator>
      </li>
    </ul>

    <h2>MONTHLY PLAN</h2>
    <ul class="pricing-list">
      <li><label>Access Plan</label>
        <validator style="width: 100%;" :value="currentTemplate.accessPlan" reqs="required">
          <multiselect-dropdown :disabled="!editMode" :options="planOptions" v-model="currentTemplate.accessPlan" :isMulti="true" :isExclusive="true" />
        </validator>
      </li>
      <li v-if="methodOptions.enabledMethods.find(a=>methodOptions.allMethods.find(b=>a==b))"><label><em></em>Price</label>
        <ul>
          <li>
            <span class="prefix">$</span>
            <validator :value="currentTemplate.treasuryAccessMonthly" reqs="required">
              <number-edit :disabled="!editMode" step="0.01" v-model="currentTemplate.treasuryAccessMonthly" />
            </validator>
          </li>
        </ul>
      </li>
    </ul>

    <h2>Account Limits</h2>
    <ul class="pricing-list pl2 madon">
      <li><label><em></em>Account Limits</label>
        <ul>
          <li>
            <label>Daily</label>
            <span class="prefix">$</span>
            <number-edit :disabled="!editMode" step="0.01" v-model="currentTemplate.accountLimitsDaily" />
          </li>
          <li>
            <label>Monthly</label>
            <span class="prefix">$</span>
            <number-edit :disabled="!editMode" step="0.01" v-model="currentTemplate.accountLimitsMonthly" />
          </li>
        </ul>
      </li>
    </ul>

    <h2>PRICING</h2>
    <div class="fee-types">
      <div>
        <h3>Name</h3>
        <ul class="fee-list">
          <li><label>Volume Fee</label>
          </li>
          <li><label>Exchange</label>
          </li>
          <li><label>Dormant Accounts</label>
          </li>
          <li><label>Domestic Wire</label>
          </li>
          <li><label>International Wire</label>
          </li>
          <li><label>ACH</label>
          </li>
          <li><label>Account Transfer</label>
          </li>
          <li><label>Checks</label>
          </li>
          <li><label>Debit Card</label>
          </li>
          <li><label>Digital Assets</label>
          </li>
        </ul>
      </div>
      <div>
        <h3>&nbsp;</h3>
        <ul class="fee-list">
          <li>
          </li>
          <li>
          </li>
          <li>
          </li>
          <li>
            <mnb-switch :disabled="!editMode" v-if="treasurySolution.service.methods.domesticWires && (!parentTemplate || parentTemplate.feeSchedule.domesticWire.setting)" v-model="currentTemplate.feeSchedule.domesticWire.setting" />
            <mnb-switch v-else :disabled="true" :set="currentTemplate.feeSchedule.domesticWire.setting = false"  />
          </li>
          <li>
            <mnb-switch :disabled="!editMode" v-if="treasurySolution.service.methods.internationalWires && (!parentTemplate || parentTemplate.feeSchedule.internationalWire.setting)" v-model="currentTemplate.feeSchedule.internationalWire.setting" />
            <mnb-switch v-else :disabled="true" :set="currentTemplate.feeSchedule.internationalWire.setting = false" />
          </li>
          <li>
            <mnb-switch :disabled="!editMode" v-if="treasurySolution.service.methods.ach && (!parentTemplate || parentTemplate.feeSchedule.ach.setting)" v-model="currentTemplate.feeSchedule.ach.setting" />
            <mnb-switch v-else :disabled="true" :set="currentTemplate.feeSchedule.ach.setting = false"/>
          </li>
          <li>
            <mnb-switch :disabled="!editMode" v-if="treasurySolution.service.methods.accountTransfer && (!parentTemplate || parentTemplate.feeSchedule.accountTransfer.setting)" v-model="currentTemplate.feeSchedule.accountTransfer.setting" />
            <mnb-switch v-else :disabled="true" :set="currentTemplate.feeSchedule.accountTransfer.setting = false"/>
          </li>
          <li>
            <mnb-switch :disabled="!editMode" v-if="treasurySolution.service.methods.checks && (!parentTemplate || parentTemplate.feeSchedule.checks.setting)" v-model="currentTemplate.feeSchedule.checks.setting" />
            <mnb-switch v-else :disabled="true" :set="currentTemplate.feeSchedule.checks.setting = false"/>
          </li>
          <li>
            <mnb-switch :disabled="!editMode" v-if="treasurySolution.service.options.debitCards && (!parentTemplate || parentTemplate.feeSchedule.debitCard.setting)" v-model="currentTemplate.feeSchedule.debitCard.setting" />
            <mnb-switch v-else :disabled="true" :set="currentTemplate.feeSchedule.debitCard.setting = false"/>
          </li>
          <li>
            <mnb-switch :disabled="!editMode" v-if="treasurySolution.service.advancedMethods.digitalAssets && (!parentTemplate || parentTemplate.feeSchedule.assets.setting)" v-model="currentTemplate.feeSchedule.assets.setting" />
            <mnb-switch v-else :disabled="true" :set="currentTemplate.feeSchedule.assets.setting = false" />
          </li>
        </ul>
      </div>
      <div>
        <h3>Fee</h3>
        <ul class="fee-list">
          <li>
            <span class="prefix"></span>
            <validator :value="currentTemplate.feeSchedule.volumeFee.fee" reqs="required">
              <number-edit :disabled="!editMode" step="0.01" v-model="currentTemplate.feeSchedule.volumeFee.fee" oninput="javascript: if (!/^\d+(\.\d{0,2})?$/.test(this.value)) { this.value = this.value.slice(0, -1); }" />
            </validator>
            <span class="suffix">%</span>
          </li>
          <li>
            <span class="prefix">$</span>
            <validator :value="currentTemplate.feeSchedule.exchange.fee" reqs="required">
              <number-edit :disabled="!editMode" step="0.01" v-model="currentTemplate.feeSchedule.exchange.fee" oninput="javascript: if (!/^\d+(\.\d{0,2})?$/.test(this.value)) { this.value = this.value.slice(0, -1); }"/>
            </validator>
          </li>
          <li>
            <span class="prefix">$</span>
            <validator :value="currentTemplate.feeSchedule.dormantAccounts.fee" reqs="required">
              <number-edit :disabled="!editMode" step="0.01" v-model="currentTemplate.feeSchedule.dormantAccounts.fee" oninput="javascript: if (!/^\d+(\.\d{0,2})?$/.test(this.value)) { this.value = this.value.slice(0, -1); }"/>
            </validator>
          </li>
          <li>
          </li>
          <li>
          </li>
          <li>
          </li>
          <li>
          </li>
          <li>
          </li>
          <li>
            <span class="prefix">$</span>
            <validator :value="currentTemplate.feeSchedule.debitCard.fee" :reqs="currentTemplate.feeSchedule.debitCard.setting? 'required' : ''">
              <number-edit :disabled="!currentTemplate.feeSchedule.debitCard.setting || !editMode" step="0.01" v-model="currentTemplate.feeSchedule.debitCard.fee" oninput="javascript: if (!/^\d+(\.\d{0,2})?$/.test(this.value)) { this.value = this.value.slice(0, -1); }" />
            </validator>
          </li>
          <li>
            <span class="prefix">$</span>
            <validator :value="currentTemplate.feeSchedule.assets.fee" :reqs="currentTemplate.feeSchedule.assets.setting? 'required' : ''">
              <number-edit :disabled="!currentTemplate.feeSchedule.assets.setting || !editMode" step="0.01" v-model="currentTemplate.feeSchedule.assets.fee" oninput="javascript: if (!/^\d+(\.\d{0,2})?$/.test(this.value)) { this.value = this.value.slice(0, -1); }" />
            </validator>
          </li>
        </ul>
      </div>
      <div>
        <h3>Deposit</h3>
        <ul class="fee-list">
          <li>
            
          </li>
          <li>
          </li>
          <li>
          </li>
          <li>
            <span class="prefix">$</span>
            <validator :value="currentTemplate.feeSchedule.domesticWire.per_deposit" :reqs="currentTemplate.feeSchedule.domesticWire.setting? 'required' : ''">
              <number-edit :disabled="!currentTemplate.feeSchedule.domesticWire.setting || !editMode" step="0.01" v-model="currentTemplate.feeSchedule.domesticWire.per_deposit" oninput="javascript: if (!/^\d+(\.\d{0,2})?$/.test(this.value)) { this.value = this.value.slice(0, -1); }" />
            </validator>
          </li>
          <li>
            <span class="prefix">$</span>
            <validator :value="currentTemplate.feeSchedule.internationalWire.per_deposit" :reqs="currentTemplate.feeSchedule.internationalWire.setting? 'required' : ''">
              <number-edit :disabled="!currentTemplate.feeSchedule.internationalWire.setting || !editMode" step="0.01" v-model="currentTemplate.feeSchedule.internationalWire.per_deposit" oninput="javascript: if (!/^\d+(\.\d{0,2})?$/.test(this.value)) { this.value = this.value.slice(0, -1); }" />
            </validator>
          </li>
          <li>
            <span class="prefix">$</span>
            <validator :value="currentTemplate.feeSchedule.ach.per_deposit" :reqs="currentTemplate.feeSchedule.ach.setting? 'required' : ''">
              <number-edit :disabled="!currentTemplate.feeSchedule.ach.setting || !editMode" step="0.01" v-model="currentTemplate.feeSchedule.ach.per_deposit" oninput="javascript: if (!/^\d+(\.\d{0,2})?$/.test(this.value)) { this.value = this.value.slice(0, -1); }" />
            </validator>
          </li>
          <li>
          </li>
          <li>
            <span class="prefix">$</span>
            <validator :value="currentTemplate.feeSchedule.checks.per_deposit" :reqs="currentTemplate.feeSchedule.checks.setting? 'required' : ''">
              <number-edit :disabled="!currentTemplate.feeSchedule.checks.setting || !editMode" step="0.01" v-model="currentTemplate.feeSchedule.checks.per_deposit" oninput="javascript: if (!/^\d+(\.\d{0,2})?$/.test(this.value)) { this.value = this.value.slice(0, -1); }" />
            </validator>
          </li>
          <li>
          </li>
          <li>
            <span class="prefix">$</span>
            <validator :value="currentTemplate.feeSchedule.assets.per_deposit" :reqs="currentTemplate.feeSchedule.assets.setting? 'required' : ''">
              <number-edit :disabled="!currentTemplate.feeSchedule.assets.setting || !editMode" step="0.01" v-model="currentTemplate.feeSchedule.assets.per_deposit" oninput="javascript: if (!/^\d+(\.\d{0,2})?$/.test(this.value)) { this.value = this.value.slice(0, -1); }" />
            </validator>
          </li>
        </ul>
      </div>
      <div>
        <h3>Withdrawal</h3>
        <ul class="fee-list">
          <li>
          </li>
          <li>
          </li>
          <li>
          </li>
          <li>
            <span class="prefix">$</span>
            <validator :value="currentTemplate.feeSchedule.domesticWire.per_withdrawal" :reqs="currentTemplate.feeSchedule.domesticWire.setting? 'required' : ''">
              <number-edit :disabled="!currentTemplate.feeSchedule.domesticWire.setting || !editMode" step="0.01" v-model="currentTemplate.feeSchedule.domesticWire.per_withdrawal" oninput="javascript: if (!/^\d+(\.\d{0,2})?$/.test(this.value)) { this.value = this.value.slice(0, -1); }" />
            </validator>
          </li>
          <li>
            <span class="prefix">$</span>
            <validator :value="currentTemplate.feeSchedule.internationalWire.per_withdrawal" :reqs="currentTemplate.feeSchedule.internationalWire.setting? 'required' : ''">
              <number-edit :disabled="!currentTemplate.feeSchedule.internationalWire.setting || !editMode" step="0.01" v-model="currentTemplate.feeSchedule.internationalWire.per_withdrawal" oninput="javascript: if (!/^\d+(\.\d{0,2})?$/.test(this.value)) { this.value = this.value.slice(0, -1); }" />
            </validator>
          </li>
          <li>
            <span class="prefix">$</span>
            <validator :value="currentTemplate.feeSchedule.ach.per_withdrawal" :reqs="currentTemplate.feeSchedule.ach.setting? 'required' : ''">
              <number-edit :disabled="!currentTemplate.feeSchedule.ach.setting || !editMode" step="0.01" v-model="currentTemplate.feeSchedule.ach.per_withdrawal" oninput="javascript: if (!/^\d+(\.\d{0,2})?$/.test(this.value)) { this.value = this.value.slice(0, -1); }" />
            </validator>
          </li>
          <li>
            <span class="prefix">$</span>
            <validator :value="currentTemplate.feeSchedule.accountTransfer.per_withdrawal" :reqs="currentTemplate.feeSchedule.accountTransfer.setting? 'required' : ''">
              <number-edit :disabled="!currentTemplate.feeSchedule.accountTransfer.setting || !editMode" step="0.01" v-model="currentTemplate.feeSchedule.accountTransfer.per_withdrawal" oninput="javascript: if (!/^\d+(\.\d{0,2})?$/.test(this.value)) { this.value = this.value.slice(0, -1); }" />
            </validator>
          </li>
          <li>
            <span class="prefix">$</span>
            <validator :value="currentTemplate.feeSchedule.checks.per_withdrawal" :reqs="currentTemplate.feeSchedule.checks.setting? 'required' : ''">
              <number-edit :disabled="!currentTemplate.feeSchedule.checks.setting || !editMode" step="0.01" v-model="currentTemplate.feeSchedule.checks.per_withdrawal" oninput="javascript: if (!/^\d+(\.\d{0,2})?$/.test(this.value)) { this.value = this.value.slice(0, -1); }" />
            </validator>
          </li>
          <li>
            <span class="prefix">$</span>
            <validator :value="currentTemplate.feeSchedule.debitCard.per_withdrawal" :reqs="currentTemplate.feeSchedule.debitCard.setting? 'required' : ''">
              <number-edit :disabled="!currentTemplate.feeSchedule.debitCard.setting || !editMode" step="0.01" v-model="currentTemplate.feeSchedule.debitCard.per_withdrawal" oninput="javascript: if (!/^\d+(\.\d{0,2})?$/.test(this.value)) { this.value = this.value.slice(0, -1); }" />
            </validator>
          </li>
          <li>
            <span class="prefix">$</span>
            <validator :value="currentTemplate.feeSchedule.assets.per_withdrawal" :reqs="currentTemplate.feeSchedule.assets.setting? 'required' : ''">
              <number-edit :disabled="!currentTemplate.feeSchedule.assets.setting || !editMode" step="0.01" v-model="currentTemplate.feeSchedule.assets.per_withdrawal" oninput="javascript: if (!/^\d+(\.\d{0,2})?$/.test(this.value)) { this.value = this.value.slice(0, -1); }" />
            </validator>
          </li>
        </ul>
      </div>
      <div>
        <h3>Limits</h3>
        <ul class="fee-list">
          <li>
          </li>
          <li>
          </li>
          <li>
          </li>
          <li>
            <span class="prefix">$</span>
            <number-edit :disabled="!currentTemplate.feeSchedule.domesticWire.setting || !editMode" step="0.01" v-model="currentTemplate.feeSchedule.domesticWire.limit" oninput="javascript: if (!/^\d+(\.\d{0,2})?$/.test(this.value)) { this.value = this.value.slice(0, -1); }" />
          </li>
          <li>
            <span class="prefix">$</span>
            <number-edit :disabled="!currentTemplate.feeSchedule.internationalWire.setting || !editMode" step="0.01" v-model="currentTemplate.feeSchedule.internationalWire.limit" oninput="javascript: if (!/^\d+(\.\d{0,2})?$/.test(this.value)) { this.value = this.value.slice(0, -1); }" />
          </li>
          <li>
            <span class="prefix">$</span>
            <number-edit :disabled="!currentTemplate.feeSchedule.ach.setting || !editMode" step="0.01" v-model="currentTemplate.feeSchedule.ach.limit" oninput="javascript: if (!/^\d+(\.\d{0,2})?$/.test(this.value)) { this.value = this.value.slice(0, -1); }" />
          </li>
          <li>
            <span class="prefix">$</span>
            <number-edit :disabled="!currentTemplate.feeSchedule.accountTransfer.setting || !editMode" step="0.01" v-model="currentTemplate.feeSchedule.accountTransfer.limit" oninput="javascript: if (!/^\d+(\.\d{0,2})?$/.test(this.value)) { this.value = this.value.slice(0, -1); }" />
          </li>
          <li>
            <span class="prefix">$</span>
            <number-edit :disabled="!currentTemplate.feeSchedule.checks.setting || !editMode" step="0.01" v-model="currentTemplate.feeSchedule.checks.limit" oninput="javascript: if (!/^\d+(\.\d{0,2})?$/.test(this.value)) { this.value = this.value.slice(0, -1); }" />
          </li>
          <li>
          </li>
          <li>
          </li>
        </ul>
      </div>
    </div>
    
    <h2>MODULES</h2>
    <ul class="pricing-list pl2 madon">
      <li style="width: 100%"><label style="min-width: 16.66%"></label>
          <multiselect-dropdown :disabled="!editMode" :options="modulesOpts" v-model="currentTemplate.feeSchedule.modules" :isMulti="true" />
      </li>
      <li v-if="currentTemplate.feeSchedule.modules && currentTemplate.feeSchedule.modules.indexOf('exchangeDesk')!=-1">
        <label><em></em>Exchange Desk</label>
        <ul>
          <li>
            <label>Setup</label>
            <span class="prefix">$</span>
            <validator :value="currentTemplate.feeSchedule.exchangeDesksetup" :reqs="currentTemplate.feeSchedule.modules.indexOf('exchangeDesk')!=-1? 'required' : ''">
              <number-edit :disabled="!editMode" step="0.01" v-model="currentTemplate.feeSchedule.exchangeDesksetup" oninput="javascript: if (!/^\d+(\.\d{0,2})?$/.test(this.value)) { this.value = this.value.slice(0, -1); }" />
            </validator>
          </li>
          <li>
            <label>Monthly</label>
            <span class="prefix">$</span>
            <validator :value="currentTemplate.feeSchedule.exchangeDeskmonthly" :reqs="currentTemplate.feeSchedule.modules.indexOf('exchangeDesk')!=-1? 'required' : ''">
              <number-edit :disabled="!editMode" step="0.01" v-model="currentTemplate.feeSchedule.exchangeDeskmonthly" oninput="javascript: if (!/^\d+(\.\d{0,2})?$/.test(this.value)) { this.value = this.value.slice(0, -1); }" />
            </validator>
          </li>
        </ul>
      </li>
      <li v-if="currentTemplate.feeSchedule.modules && currentTemplate.feeSchedule.modules.indexOf('tradeDesk')!=-1">
        <label><em></em>Trade Desk</label>
        <ul>
          <li>
            <label>Setup</label>
            <span class="prefix">$</span>
            <validator :value="currentTemplate.feeSchedule.tradeDesksetup" :reqs="currentTemplate.feeSchedule.modules.indexOf('tradeDesk')!=-1? 'required' : ''">
              <number-edit :disabled="!editMode" step="0.01" v-model="currentTemplate.feeSchedule.tradeDesksetup" oninput="javascript: if (!/^\d+(\.\d{0,2})?$/.test(this.value)) { this.value = this.value.slice(0, -1); }" />
            </validator>
          </li>
          <li>
            <label>Monthly</label>
            <span class="prefix">$</span>
            <validator :value="currentTemplate.feeSchedule.tradeDeskmonthly" :reqs="currentTemplate.feeSchedule.modules.indexOf('tradeDesk')!=-1? 'required' : ''">
              <number-edit :disabled="!editMode" step="0.01" v-model="currentTemplate.feeSchedule.tradeDeskmonthly" oninput="javascript: if (!/^\d+(\.\d{0,2})?$/.test(this.value)) { this.value = this.value.slice(0, -1); }" />
            </validator>
          </li>
        </ul>
      </li>
    </ul>

    <h2>OPTIONS</h2>
    <ul class="pricing-list pl2 madon">
      <li style="width: 100%"><label style="min-width: 16.66%"></label>
        <multiselect-dropdown :disabled="!editMode" :options="optionsOpts" v-model="currentTemplate.feeSchedule.options" :isMulti="true" />
      </li>
    </ul>
  </div>
</template>

<script>
import $ from 'jquery';

import MnbSwitch from './switch.vue'
export default {
  components: {
    MnbSwitch,
  },
  props: ["currentTemplate", "selectedPlan", "solution", "v", "parentTemplate", "boarding", "editMode"],
  data() { return {
    modules: [],
    options: [],
    methodOptions: {
      enabledMethods: [],
      allMethods: [],
      allModules: [],
      allOptions: [],
    },
    ctCheckboxes: [],
    treasurySolution: null
  }},
  watch: {
    ctCheckboxes: {
      handler() {
        for(var opt of ["monthlyPricing", "dailyPricing", "flatRatePricing", "interchangePricing", "tieredPricing", "payAxis", "vivpos", "chammpGift", "chammpRewards", "multiCurrencyToggle", "virtualTerminalToggle", "chammpCheckout", "chammpUpdater", "chammpTitan"])
          this.$set(this.currentTemplate, opt, this.ctCheckboxes.find(a=>a==opt));
      }, deep: true,
    },
    currentTemplate: {
      immediate: true,
      handler() {
        if (this.currentTemplate && !this.currentTemplate.pricingModules)
          this.currentTemplate.pricingModules={};
        if (this.currentTemplate && !this.currentTemplate.pricingOptions)
          this.currentTemplate.pricingOptions={};
        if (this.currentTemplate && !this.currentTemplate.methods)
          this.currentTemplate.methods=[];
        for(var opt of ["monthlyPricing", "dailyPricing", "flatRatePricing", "interchangePricing", "tieredPricing", "payAxis", "vivpos", "chammpGift", "chammpRewards", "multiCurrencyToggle", "virtualTerminalToggle", "chammpCheckout", "chammpUpdater", "chammpTitan"])
          if (this.currentTemplate[opt])
            this.ctCheckboxes.push(opt);
        this.loadMethods();
      },
    },
  },
  computed: {
    planOptions() {
      var options = [
      ];
      console.log(this.methodOptions.enabledMethods)
      this.methodOptions.allMethods.forEach(item => {
        if(this.hasM(item)) {
          options.push(
            {label: item, value: item}
          )
        }
      })
      
      return options
    },

    cardSel() {
      var result={
        any: !!this.currentTemplate.methods.length,
        visa: this.currentTemplate.methods.indexOf('Visa')!=-1,
        mc: this.currentTemplate.methods.indexOf('Mastercard')!=-1,
        discover: this.currentTemplate.methods.indexOf('Discover')!=-1,
        amex: this.currentTemplate.methods.indexOf('AMEX')!=-1,
        unionpay: this.currentTemplate.methods.indexOf('China UnionPay')!=-1,
        debit: this.currentTemplate.methods.indexOf('Debit')!=-1,
      };
      result.ccard=result.visa || result.mc || result.discover || result.unionpay;
      return result;
    },
    cardOpts() {
      return ["Visa", "Mastercard", "Discover", "AMEX", "JCB", "Dinners", "Debit"].filter(card=>this.hasM(card)).map(a=>({label: a, value: a}));
    },
    advPayOpts() {
      return ["Apple Pay", "Google Pay", "Samsung Pay", "Interac Online"].filter(card=>this.hasM(card)).map(a=>({label: a, value: a}));
    },
    bankClearingOpts() {
      return ["EFT", "EFT Credits", "ACH", "ACH Credits"].filter(card=>this.hasM(card)).map(a=>({label: a, value: a}));
    },
    modulesOpts() {
      var result=[];
      if (this.treasurySolution.service.advancedMethods.exchangeDesk) result.push({value: 'exchangeDesk', label: 'Exchange Desk'});
      if (this.treasurySolution.service.advancedMethods.tradeDesk) result.push({value: 'tradeDesk', label: 'Trade Desk'});
      
      return result;
    },
    optionsOpts() {
      var result=[];
      if (this.treasurySolution.service.options.multiCurrency) result.push({value: 'multiCurrency', label: 'Multi-currency'});
      
      return result;
    },
    accountTypeOpts() {
      var result=[
        {
          value: 'Business',
          label: 'Business',
        },
        {
          value: 'Personal',
          label: 'Personal',
        }
      ];
      return result;
    },
    accountOpts() {
      var result=[];
      if (this.hasM('Multi-Currency')) result.push({value: 'multiCurrencyToggle', label: 'Multi-Currency'});
      if (this.hasM('Subscription Billing')) result.push({value: 'virtualTerminalToggle', label: 'Subscription Billing'});
      if (this.hasM('Mailed Statements')) result.push({value: 'chammpCheckout', label: 'Mailed Statements'});
      if (this.hasM('Auto Updater')) result.push({value: 'chammpUpdater', label: 'Auto Updater'});
      if (this.hasM('Titan')) result.push({value: 'chammpTitan', label: 'Titan'});
      return result;
    },
  },
  methods: {
    async loadMethods() {
      var data=await api.get("Solution", this.currentTemplate.solution || this.solution);
      if (data.item) {
        this.treasurySolution = data.item
        this.methodOptions={
          enabledMethods: data.item.enabledMethods || [],
          allMethods: data.item.allMethods || [],
          allModules: data.item.allModules || [],
          allOptions: data.item.service.options.multiCurrency? ['Multi-currency'] : [],
        };
      }
    },
    hasM(method) {
      return this.methodOptions.enabledMethods.indexOf(method)!=-1;
    },
  },
  mounted: async function() {
    this.loadMethods();
    if(this.currentTemplate.feeSchedule) {
      if(this.currentTemplate.feeSchedule.volumeFee.fee)
        this.currentTemplate.feeSchedule.volumeFee.fee = parseFloat(this.currentTemplate.feeSchedule.volumeFee.fee).toFixed(2)
      if(this.currentTemplate.feeSchedule.exchange.fee)
        this.currentTemplate.feeSchedule.exchange.fee = parseFloat(this.currentTemplate.feeSchedule.exchange.fee).toFixed(2)
      if(this.currentTemplate.feeSchedule.dormantAccounts.fee)
        this.currentTemplate.feeSchedule.dormantAccounts.fee = parseFloat(this.currentTemplate.feeSchedule.dormantAccounts.fee).toFixed(2)
      if(this.currentTemplate.feeSchedule.debitCard.fee)
        this.currentTemplate.feeSchedule.debitCard.fee = parseFloat(this.currentTemplate.feeSchedule.debitCard.fee).toFixed(2)
      if(this.currentTemplate.feeSchedule.assets.fee)
        this.currentTemplate.feeSchedule.assets.fee = parseFloat(this.currentTemplate.feeSchedule.assets.fee).toFixed(2)
      if(this.currentTemplate.feeSchedule.domesticWire.per_deposit)
        this.currentTemplate.feeSchedule.domesticWire.per_deposit = parseFloat(this.currentTemplate.feeSchedule.domesticWire.per_deposit).toFixed(2)
      if(this.currentTemplate.feeSchedule.internationalWire.per_deposit)
        this.currentTemplate.feeSchedule.internationalWire.per_deposit = parseFloat(this.currentTemplate.feeSchedule.internationalWire.per_deposit).toFixed(2)
      if(this.currentTemplate.feeSchedule.ach.per_deposit)
        this.currentTemplate.feeSchedule.ach.per_deposit = parseFloat(this.currentTemplate.feeSchedule.ach.per_deposit).toFixed(2)
      if(this.currentTemplate.feeSchedule.checks.per_deposit)
        this.currentTemplate.feeSchedule.checks.per_deposit = parseFloat(this.currentTemplate.feeSchedule.checks.per_deposit).toFixed(2)
      if(this.currentTemplate.feeSchedule.assets.per_deposit)
        this.currentTemplate.feeSchedule.assets.per_deposit = parseFloat(this.currentTemplate.feeSchedule.assets.per_deposit).toFixed(2)
      if(this.currentTemplate.feeSchedule.domesticWire.per_withdrawal)
        this.currentTemplate.feeSchedule.domesticWire.per_withdrawal = parseFloat(this.currentTemplate.feeSchedule.domesticWire.per_withdrawal).toFixed(2)
      if(this.currentTemplate.feeSchedule.internationalWire.per_withdrawal)
        this.currentTemplate.feeSchedule.internationalWire.per_withdrawal = parseFloat(this.currentTemplate.feeSchedule.internationalWire.per_withdrawal).toFixed(2)
      if(this.currentTemplate.feeSchedule.ach.per_withdrawal)
        this.currentTemplate.feeSchedule.ach.per_withdrawal = parseFloat(this.currentTemplate.feeSchedule.ach.per_withdrawal).toFixed(2)
      if(this.currentTemplate.feeSchedule.accountTransfer.per_withdrawal)
        this.currentTemplate.feeSchedule.accountTransfer.per_withdrawal = parseFloat(this.currentTemplate.feeSchedule.accountTransfer.per_withdrawal).toFixed(2)
      if(this.currentTemplate.feeSchedule.checks.per_withdrawal)
        this.currentTemplate.feeSchedule.checks.per_withdrawal = parseFloat(this.currentTemplate.feeSchedule.checks.per_withdrawal).toFixed(2)
      if(this.currentTemplate.feeSchedule.debitCard.per_withdrawal)
        this.currentTemplate.feeSchedule.debitCard.per_withdrawal = parseFloat(this.currentTemplate.feeSchedule.debitCard.per_withdrawal).toFixed(2)
      if(this.currentTemplate.feeSchedule.assets.per_withdrawal)
        this.currentTemplate.feeSchedule.assets.per_withdrawal = parseFloat(this.currentTemplate.feeSchedule.assets.per_withdrawal).toFixed(2)
      if(this.currentTemplate.feeSchedule.domesticWire.limit)
        this.currentTemplate.feeSchedule.domesticWire.limit = parseFloat(this.currentTemplate.feeSchedule.domesticWire.limit).toFixed(2)
      if(this.currentTemplate.feeSchedule.internationalWire.limit)
        this.currentTemplate.feeSchedule.internationalWire.limit = parseFloat(this.currentTemplate.feeSchedule.internationalWire.limit).toFixed(2)
      if(this.currentTemplate.feeSchedule.ach.limit)
        this.currentTemplate.feeSchedule.ach.limit = parseFloat(this.currentTemplate.feeSchedule.ach.limit).toFixed(2)
      if(this.currentTemplate.feeSchedule.accountTransfer.limit)
        this.currentTemplate.feeSchedule.accountTransfer.limit = parseFloat(this.currentTemplate.feeSchedule.accountTransfer.limit).toFixed(2)
      if(this.currentTemplate.feeSchedule.checks.limit)
        this.currentTemplate.feeSchedule.checks.limit = parseFloat(this.currentTemplate.feeSchedule.checks.limit).toFixed(2)
    }else{
      this.currentTemplate.feeSchedule = {
            volumeFee : {
              text: 'Volume Fee',
              fee: null 
            },
            monthlyAccessFee: { 
              text: 'Monthly Access Fee',
              fee: null 
            },
            domesticWire: { 
              text: 'Domestic Wire',
              per_deposit: null, 
              per_withdrawal: null, 
              limit: null, 
              setting: false, 
            },
            internationalWire: { 
              text: 'International Wire',
              per_deposit: null, 
              per_withdrawal: null, 
              limit: null, 
              setting: false, 
            },
            ach: { 
              text: 'ACH',
              per_deposit: null, 
              per_withdrawal: null, 
              limit: null, 
              setting: false, 
            },
            accountTransfer: { 
              text: 'Account Transfer',
              per_withdrawal: null, 
              limit: null, 
              setting: false, 
            },
            checks: { 
              text: 'Checks',
              per_deposit: null, 
              per_withdrawal: null, 
              limit: null, 
              setting: false, 
            },
            exchange: { 
              text: 'Exchange',
              fee: null, 
            },
            assets: { 
              text: 'Assets',
              fee: null, 
              per_deposit: null, 
              per_withdrawal: null, 
              limit: null, 
              setting: false, 
            },
            dormantAccounts: { 
              text: 'Dormant Accounts',
              fee: null, 
            },
            debitCard: { 
              text: 'Debit Card',
              fee: null, 
              per_withdrawal: null, 
              setting: false, 
            },
          }
    }
    var modPromise=api.list("CompanyModule", {});
    var optPromise=api.list("CompanyOption", {});
    this.modules=(await modPromise).items;
    this.options=(await optPromise).items;
  },
}
</script>
<style lang="scss" scoped>
  .fee-list li {
    min-height: 20px;
  }
</style>
