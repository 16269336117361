<template>
<div>
  <h2 class="doc-title">FRAUD RISK POLICY RECEIPT</h2>
  <p>I, the undersigned Company Staff or consultant, certify that I have received the confidential Company Policy, either in paper or electronic form.</p>
  <p>I undertake to promptly return a copy of this receipt bearing my original signature in paper form or scanned as an email PDF to the Compliance Officer of the Company for record-retention purposes within three (3) days of my receipt of this Policy. </p>
  <p>I further acknowledge that I will not appropriate, disclose, or make any unauthorized use of, or copy this Policy for the benefit of any third-party entity or for use outside of my job duties. I fully understand this Policy is the confidential, intellectual, and proprietary property of the Company.</p>
  <p>I undertake to read the Policy in full and to ensure that I understand all of its provisions. I understand I am to receive training where these policies and procedures, and the Company’s risk mitigation procedures related to the potential Money Laundering, Terrorist Financing or Fraud threats and how they relate to the Company’s products and services, thereby ensuring that I can conduct the tasks and duties I have been assigned to complete. I will comply with and assist in the enforcement of the provisions of the Policy in a manner that is consistent with my duties to the Company.</p>
  <p>I understand that after I have read this AML, ATF, Fraud and Risk Policy, I will address any questions that I may have regarding the Policy’s interpretation or application with the Compliance Officer or bring my questions to my training session to ensure I am clear on what my responsibilities are and that I comfortably understand the context of this Policy. I will furthermore keep up to date on all changes to this Policy. </p>
  <p>I understand that failure to maintain the confidentiality of this Policy is a violation that will be grounds for termination with cause and that the Company will be within its rights to take legal and equitable action against me.</p>
  <br>
  <form class="search-form" @submit.prevent="save()">
    <div class="field">
      <label>PRINTED NAME OF EMPLOYEE:</label>
      <div class="field-group">
        <validator :value="form.first_name" reqs="required" class="w-half">
          <input v-model="form.first_name" type="text" class="w100" placeholder="First Name" maxlength="30" @keypress="allowLetterNumber($event)">
        </validator>
        <validator :value="form.last_name" reqs="required" class="w-half">
          <input v-model="form.last_name" type="text" class="w100" placeholder="Last Name" maxlength="30" @keypress="allowLetterNumber($event)">
        </validator>
      </div>
    </div>

    <div class="field">
      <label>PRINTED EMAIL:</label>
      <validator :value="form.email" reqs="required email" class="field-group">
        <input v-model="form.email" type="email" class="w100">
      </validator>
    </div>

    <div class="field">
      <label>PRINTED DEPARTMENT NAME:</label>
      <validator :value="form.department" reqs="required" class="field-group">
        <select v-model="form.department" class="w100">
          <option v-for="(opt, key) in userGroups" :key="key" :value="key">{{ opt }}</option>
        </select>
      </validator>
    </div>

    <div class="field">
      <label>DATE RECEIVED:</label>
      <validator :value="form.date" reqs="required" class="field-group">
        <datepicker v-model="form.date" type="text" :future="false" :minDate="beforeYesterday" class="w100" />
      </validator>
    </div>
    <div class="field">
      <label>SIGNATURE:</label>
      <span class="signature-line"></span>
    </div>

    <div v-if="!hideButtons" class="buttons">
      <button type="submit" class="btn btn-small">Submit</button>
      <button class="btn btn-small" @click="clear()">Clear</button>
    </div>
  </form>
</div>
</template>

<script>
export default {
  mixins: [ vueMixins.helpers, formValidation.mixin ],

  props: {
    hideButtons: {
      type: Boolean,
      default: false
    },

    prepopulate: {
      type: Array,
      default: null
    }
  },

  data () {
    return {
      form: {},
      userGroups: window.userGroups
    }
  },
  
  computed: {
    beforeYesterday () {
      let date = new Date();
      date.setDate(date.getDate() - 2);
      return date;
    },

    isEmpty () {
      return Object.values(this.form).filter(item => !item).length > 0
    }
  },

  created () {
    this.clear()
    if (this.prepopulate) {
      this.prepopulate.forEach(item => {
        this.form[item.name] = item.value
      })
    }
  },

  methods: {
    async save () {
      if (!this.validate()) return stopLoading("Please fill all required fields", "error");
      let props = []
      startLoading();
      Object.keys(this.form).forEach(name => {
        props.push({ name, value: this.form[name] })
      })
      try {
        const response = await api.create("ComplianceDocument", {
          userId: window.appData.currentUser.id,
          type: 'FRPR',
          docIndex: 'GEN_DOC_ID',
          status: 'PENDING',
          signed: null,
          created: 'CURRENT_TIMESTAMP',
          form: props
        })
        await this.createNote(response.id)
        this.clear()
        stopLoading("Saved")
      } catch (e) {
        stopLoading(e, 'error')
      }
    },

    createNote (compDocId) {
      const data = {
        message: 'FRPR Form Created',
        created: "CURRENT_TIMESTAMP",
        author: window.appData.currentUser.id,
        system: 1,
        type: 'ComplianceDocument',
        parentId: compDocId
      }
      return api.create("Note", data)
    },

    clear () {
      this.form = {
        first_name: null,
        last_name: null,
        email: null,
        department: null,
        date: null
      }
      this.validateReset()
    }
  }
}
</script>
