<template>
  <svg
    class="feather feather-menu"
    fill="none"
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="2"
    viewBox="0 0 24 24"
    :stroke="color"
    :width="size + 'px'"
    :height="size + 'px'"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line x1="3" x2="21" y1="12" y2="12" />
    <line x1="3" x2="21" y1="6" y2="6" />
    <line x1="3" x2="21" y1="18" y2="18" />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "#000",
    },

    size: {
      type: Number,
      default: 24,
    },
  },
};
</script>


<style lang="scss" scoped>
svg {
  transition: all ease-in-out 0.1s;
}
</style>