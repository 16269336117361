window.vueMixins = window.vueMixins || {}

window.vueMixins.amadelLeadMixin = {
  data () {
    return {
      sources: [],
      yesNoOptions: {
        Y: 'Yes',
        N: 'No'
      },
      businessCountries: {
        CA: 'Canada',
        US: 'United States'
      },
      businessTypes: {
        'Inc.': 'Inc.',
        Corp: 'Corp',
        LLC: 'LLC',
        LLP: 'LLP',
        SoleP: 'SoleP',
        Other: 'Other'
      },
      leadTypes: {
        PA: 'PA',
        RQ: 'RQ',
        TP: 'TP'
      },
      methodTypes: {
        POS: 'Pos',
        ECOM: 'Ecom',
        MOB: 'Mob',
        MULTI: 'Multi',
        OTHER: 'Other'
      },
      currentPage: {
        text: 'New Lead'
      }
    }
  },

  created () {
    this.getSources()
  },

  methods: {
    slecectOptions (key) {
      const keys = {
        MethodType: this.methodTypes,
        BusinessType: this.businessTypes,
        BusinessCountry: this.businessCountries,
        CurrentlyAccept: this.yesNoOptions,
        EverAccept: this.yesNoOptions
      }
      return keys[key] || null
    },

    getMaxLength (key) {
      const max = {
        RecordId: 15,
        BusinessIndustry: 50,
        BusinessName: 50,
        BusinessDBA: 50,
        BusinessAddress: 100,
        BusinessCity: 30,
        BusinessProvState: 2,
        BusinessPostalZip: 9,
        BusinessPhone: 10,
        BusinessEmail: 50,
        BusinessWebite: 50,
        BusinessDescription: 100,
        Contact1FirstName: 25,
        Contact1LastName: 25,
        Contact1Email: 50,
        Contact1Phone: 10,
        Contact2FirstName: 25,
        Contact2LastName: 25,
        Contact2Email: 50,
        Contact2Phone: 10,
        VolMonthly: 9,
        Vol12Months: 9,
        HighTicket: 9,
        AvgTicket: 9,
        LowTicket: 9,
        TransCurr: 3
      }
      return max[key] || 524288
    },

    onInput (event, key) {
      switch(key) {
        case 'BusinessIndustry':
          this.allowLetterNumber(event, { numbers: true })
          break
        case 'BusinessName':
          this.allowLetterNumber(event, { numbers: true })
          break
        case 'BusinessDBA':
          this.allowLetterNumber(event, { numbers: true })
          break
        case 'BusinessAddress':
          this.allowLetterNumber(event, { numbers: true, allow: [35] })
          break
        case 'BusinessCity':
          this.allowLetterNumber(event, { numbers: true })
          break
        case 'BusinessProvState':
          this.allowLetterNumber(event, { numbers: true })
          break
        case 'BusinessPostalZip':
          this.allowLetterNumber(event, { numbers: true, allow: [45] })
          break
        case 'BusinessPhone':
          this.allowLetterNumber(event, { numbers: true, letters: false })
          break
        case 'BusinessDescription':
          this.allowLetterNumber(event, { numbers: true })
          break
        case 'Contact1FirstName':
          this.allowLetterNumber(event, { numbers: true })
          break
        case 'Contact1LastName':
          this.allowLetterNumber(event, { numbers: true })
          break
        case 'Contact1Phone':
          this.allowLetterNumber(event, { numbers: true, letters: false })
          break
        case 'Contact2FirstName':
          this.allowLetterNumber(event, { numbers: true })
          break
        case 'Contact2LastName':
          this.allowLetterNumber(event, { numbers: true })
          break
        case 'Contact2Phone':
          this.allowLetterNumber(event, { numbers: true, letters: false })
          break
        case 'VolMonthly':
          this.allowLetterNumber(event, { numbers: true, letters: false })
          break
        case 'Vol12Months':
          this.allowLetterNumber(event, { numbers: true, letters: false })
          break
        case 'HighTicket':
          this.allowLetterNumber(event, { numbers: true, letters: false })
          break
        case 'AvgTicket':
          this.allowLetterNumber(event, { numbers: true, letters: false })
          break
        case 'LowTicket':
          this.allowLetterNumber(event, { numbers: true, letters: false })
          break
        case 'TransCurr':
          this.allowLetterNumber(event)
      }
      return true
    },

    hasValidation (field) {
      const validation = {
        MethodType: 'required',
        BusinessName: 'required',
        BusinessProvState: 'required',
        BusinessPostalZip: 'required',
        BusinessCountry: 'required',
        BusinessPhone: 'required',
        BusinessEmail: 'email',
        BusinessWebite: 'url',
        BusinessDescription: 'required',
        Contact1FirstName: 'required',
        Contact1LastName: 'required',
        Contact1Email: 'email',
        Contact2Email: 'email'
      }
      return validation[field] || null
    },

    async getSources () {
      this.sources = (await api.list("AmadelSources")).items
    }
  }
}
