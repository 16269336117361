<template>
<div class="popup usermgmt" :class="{shown: visible}">
  <div class="pop-med">
    <div class="pop-inner">
      <a href="" class="close" v-on:click.prevent="visible=false"></a>
      <h2>Set new password for: {{ username }}</h2>
      <form class="search-form">
        <div>
          <validator class="field" :value="agentId" reqs="">
            <label>Account #</label>
            <input tabindex="2" type="text" v-model="agentId" readonly />
          </validator>
        </div>
        <div>
          <validator class="field" :value="username" reqs="">
            <label>Username</label>
            <input tabindex="2" type="text" v-model="username" readonly />
          </validator>
        </div>
        <div>
          <validator class="field" :value="password" reqs="password">
            <label>New Password</label>
            <input tabindex="2" type="password" v-model="password" />
          </validator>
        </div>
        <div>
          <validator class="field" :value="password2" reqs="password">
            <label>Confirm Password</label>
            <input tabindex="2" type="password" v-model="password2" />
          </validator>
        </div>
        <div class="buttons">
          <button class="btn btn-large btn-primary" v-on:click.prevent="send">Update</button>
        </div>
      </form>
    </div>
  </div>
</div>
</template>

<script>
export default {
  data() { return {
    visible: false,
    agentId: window.resetAgentId || "",
    username: window.resetUsername || "",
    password: "",
    password2: "",
  }},
  methods: {
    showPopup() {
      this.visible=true;
    },
    send: async function() {
      if (!this.password)
        return stopLoading("Please enter a password", "error");
      if (this.password!=this.password2)
        return stopLoading("Passwords do not match", "error");
      var that=this;
      startLoading();
      $.post(location.href, {
        resetCode: this.resetCode,
        newPassword: this.password,
      }, function(data) {
        if (data.success) {
          stopLoading("Password updated", "success");
          setTimeout(location.replace("/login"), 1000);
          that.visible=false;
        } else {
          stopLoading("Error updating your password", "error");
        }
      }, "json");
    },
  },
  mounted: async function() {
    window.userResetPw=this;
  },
  mixins: [formValidation.mixin]
}
</script>
