<template>
      <div>
        <table class="file-list content-table" v-if="!uwShown">
          <thead>
            <tr>
              <th style="width: 1%"></th>
              <th>ID</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(uwItem, uwI) in uwList">
              <td><input type="radio" v-model="uwSelected" :value="uwItem.id"></td>
              <td>{{ uwList.length-uwI }}</td>
              <td>{{ formatDate(uwItem.created, "YYYY-MM-DD HH:mm") }}</td>
            </tr>
          </tbody>
        </table>
        <div class="pre-check-details" v-else-if="uwShown" ref="detailsBox">
          <div class="t2precheck">
            <div class="t2sect">
              <div class="t2box">
                <h3>General Information</h3>
                <ul>
                  <li>
                    <label>Solution:</label>
                    <span v-if="uwShownItem.solution">{{ solution(uwShownItem.solution) }}</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                  <li>
                    <label>Status is A03:</label>
                    <span v-if="uwShownItem.status">YES</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                  <li>
                    <label>App ID:</label>
                    <span v-if="uwShownItem.appId">{{ uwShownItem.appId }}</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                  <li>
                    <label>Account ID:</label>
                    <span v-if="uwShownItem.accountId">{{ uwShownItem.accountId }}</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                  <li>
                    <label>Partner Name:</label>
                    <span v-if="uwShownItem.source">{{ uwShownItem.source.contacts[0]?(uwShownItem.source.contacts[0].firstName+" "+uwShownItem.source.contacts[0].lastName):"-" }}</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                  <li>
                    <label>Partner Company:</label>
                    <span v-if="uwShownItem.source">{{ uwShownItem.source.agentDb.name }}</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                  <li>
                    <label>Account Type:</label>
                    <span v-if="uwShownItem.accountType">{{ accountType(uwShownItem.accountType) }}</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                  <li v-if="false">
                    <label>Facilitator:</label>
                    <span v-if="uwShownItem.facilitator">{{ facilitator(uwShownItem.facilitator) }}</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                  <li>
                    <label>Merchant Type:</label>
                    <span v-if="uwShownItem.merchantType">{{ uwShownItem.merchantType }}</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                  <li>
                    <label>Merchant Industry:</label>
                    <span v-if="uwShownItem.industry">{{ uwShownItem.industry }}</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                  <li>
                    <label>MCC Classification:</label>
                    <span v-if="uwShownItem.mcc">{{ uwShownItemMcc.category }}</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                  <li>
                    <label>MCC Code:</label>
                    <span v-if="uwShownItem.mcc">{{ uwShownItemMcc.status }}</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                  <li>
                    <label>MCC Description:</label>
                    <span v-if="uwShownItem.mcc">{{ uwShownItemMcc.description }}</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                  <li>
                    <label>MCC Designation:</label>
                    <span v-if="uwShownItem.mcc">{{ uwShownItemMcc.designation }}</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                  <li>
                    <label>Account Class:</label>
                    <span v-if="uwShownItem.accountClass">{{ uwShownItem.accountClass.name }}</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                  <li>
                    <label>Account Class Description:</label>
                    <span v-if="uwShownItem.accountClass">{{ uwShownItem.accountClass.description }}</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                  <li>
                    <label>Account Class Designation:</label>
                    <span v-if="uwShownItem.accountClass">{{ uwShownItem.accountClass.designation }}</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                  <li>
                    <label>Account Manager:</label>
                    <span v-if="uwShownItem.manager">{{ uwShownItem.manager.fullName }}</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                  <li>
                    <label>Language:</label>
                    <span v-if="uwShownItem.language">{{ uwShownItem.language }}</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="t2sect">
              <div class="t2box">
                <h3>Business Information</h3>
                <ul>
                  <li>
                    <label>Business Legal Name:</label>
                    <span v-if="uwShownItem.companyName">{{ uwShownItem.companyName }}</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                  <li>
                    <label>Business Trade Name:</label>
                    <span v-if="uwShownItem.companyNameTrade">{{ uwShownItem.companyNameTrade }}</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                  <li>
                    <label>Business Type:</label>
                    <span v-if="uwShownItem.companyType">{{ uwShownItem.companyType }}</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                  <li>
                    <label>Business Email:</label>
                    <span :class="{duplicate: uwShownItem.dupCompanyEmail}" v-if="uwShownItem.companyEmail">{{ uwShownItem.companyEmail }}</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                  <li>
                    <label>Business Country:</label>
                    <span v-if="uwShownItem.companyAddressCountry">{{ uwShownItem.companyAddressCountry }}</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                  <li>
                    <label>Business Address:</label>
                    <span :class="{duplicate: uwShownItem.dupAddress}" v-if="uwShownItem.companyAddress">
                      {{ uwShownItem.companyAddress }}
                      {{ uwShownItem.companyAddressNum }}
                      {{ uwShownItem.companyAddressPostcode }}
                      {{ uwShownItem.companyAddressCity }}
                      {{ uwShownItem.companyAddressState }}
                    </span>
                    <span class="empty" v-else>NO</span>
                  </li>
                  <li>
                    <label>Location ID:</label>
                    <span v-if="uwShownItem.billingDifferent==1">002</span>
                    <span v-else></span>
                  <li>
                    <label>Location Country:</label>
                    <span v-if="uwShownItem.billingAddressCountry">{{ uwShownItem.billingAddressCountry }}</span>
                    <span v-else></span>
                  </li>
                  <li>
                    <label>Location Address:</label>
                    <span :class="{duplicate: uwShownItem.dupAddress}" v-if="uwShownItem.billingAddress">
                      {{ uwShownItem.billingAddress }}
                      {{ uwShownItem.billingAddressNum }}
                      {{ uwShownItem.billingAddressPostcode }}
                      {{ uwShownItem.billingAddressCity }}
                      {{ uwShownItem.billingAddressState }}
                    </span>
                    <span v-else></span>
                  </li>                    
                  <li>
                    <label>URL:</label>
                    <span v-if="uwShownItem.website">{{ uwShownItem.hasWebsite?"No website":uwShownItem.website }}</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                  <li>
                    <label>Currently Processing:</label>
                    <span v-if="uwShownItem.acceptedCards">{{ uwShownItem.acceptedCards?"Yes":"No" }}</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                  <li>
                    <label>Declined Processing:</label>
                    <span>{{ uwShownItem.companyDeclined*1?"Y":"N" }}</span>
                  </li>
                  <li>
                    <label>Terminated Processing:</label>
                    <span>{{ uwShownItem.companyTerminated*1?"Y":"N" }}</span>
                  </li>
                  <li>
                    <label>Violation:</label>
                    <span>{{ uwShownItem.violation*1?"Y":"N" }}</span>
                  </li>
                  <li>
                    <label>Payment Methods:</label>
                    <span v-if="uwShownItem.paymentMethods">{{ uwShownItem.paymentMethods }}</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                  <li>
                    <label>Payment Types:</label>
                    <span v-if="uwShownItem.paymentTypes">{{ uwShownItem.paymentTypes }}</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                  <li>
                    <label>Monthly Sales:</label>
                    <span v-if="uwShownItem.volume">{{ uwShownItem.volume }}</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                  <li>
                    <label>Avg Trans:</label>
                    <span v-if="uwShownItem.avgTransaction">{{ uwShownItem.avgTransaction }}</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                  <li>
                    <label>Max Trans:</label>
                    <span v-if="uwShownItem.maxTransaction">{{ uwShownItem.maxTransaction }}</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                  <li>
                    <label>Monthly Sales Currency:</label>
                    <span v-if="uwShownItem.volume">{{ currencies[uwShownItem.volumeCurrency] }}</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                  <li>
                    <label>Avg Trans Currency:</label>
                    <span v-if="uwShownItem.avgTransaction">{{ currencies[uwShownItem.avgTransactionCurrency] }}</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                  <li>
                    <label>Max Trans Currency:</label>
                    <span v-if="uwShownItem.maxTransaction">{{ currencies[uwShownItem.maxTransactionCurrency] }}</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                  <li>
                    <label>CS Email:</label>
                    <span v-if="uwShownItem.emailService">{{ uwShownItem.emailService }}</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                  <li>
                    <label>CS Phone:</label>
                    <span v-if="uwShownItem.phoneService">{{ uwShownItem.phoneService }}</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                  <li>
                    <label>3D Secure:</label>
                    <span v-if="uwShownItem.use3dSecure!==null">{{ uwShownItem.use3dSecure*1?"Y":"N" }}</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                  <li>
                    <label>CVC:</label>
                    <span v-if="uwShownItem.useCVC!==null">{{ uwShownItem.useCVC*1?"Y":"N" }}</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                  <li>
                    <label>AVS:</label>
                    <span v-if="uwShownItem.useAVS!==null">{{ uwShownItem.useAVS*1?"Y":"N" }}</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="t2sect">
              <div class="t2box">
                <h3>Principals Information</h3>
                <ul>
                  <li>
                    <label>Number of Directors:</label>
                    <span :class="{duplicate: uwShownItem.numberDirectors!=uwShownItem.ubos.length}">{{ uwShownItem.numberDirectors }}</span>
                  </li>
                  <template v-for="(subi, si) in uwShownItem.directors">
                    <li>
                      <label>Dir {{ si+1 }} Name:</label>
                      <span :class="{duplicate: uwShownItem.ubos[si] && uwShownItem.ubos[si].firstName!=subi.firstName}" v-if="subi.firstName">{{ subi.firstName }}</span>
                      <span class="empty" v-else>NO</span>
                    </li>
                    <li>
                      <label>Dir {{ si+1 }} Last Name:</label>
                      <span :class="{duplicate: uwShownItem.ubos[si] && uwShownItem.ubos[si].lastName!=subi.lastName}" v-if="subi.lastName">{{ subi.lastName }}</span>
                      <span class="empty" v-else>NO</span>
                    </li>
                    <li>
                      <label>Dir {{ si+1 }} Address:</label>
                      <span v-if="subi.address">{{ subi.address+" "+subi.addressNumber+" "+subi.addressPostcode+" "+subi.addressCity+" "+subi.addressCountry }}</span>
                      <span class="empty" v-else>NO</span>
                    </li>
                    <li>
                      <label>Dir {{ si+1 }} DOB:</label>
                      <span v-if="subi.dob">{{ subi.dob }}</span>
                      <span class="empty" v-else>NO</span>
                    </li>
                    <li>
                      <label>Dir {{ si+1 }} Passport:</label>
                      <span v-if="subi.passportNum">{{ subi.passportNum }}</span>
                      <span class="empty" v-else>NO</span>
                    </li>
                    <li>
                      <label>Dir {{ si+1 }} SSN:</label>
                      <span v-if="subi.ssn">{{ subi.ssn }}</span>
                      <span class="empty" v-else>NO</span>
                    </li>
                    <li>
                      <label>Dir {{ si+1 }} Phone:</label>
                      <span v-if="subi.phone">{{ subi.phone }}</span>
                      <span class="empty" v-else>NO</span>
                    </li>
                    <li>
                      <label>Dir {{ si+1 }} Email:</label>
                      <span v-if="subi.email">{{ subi.email }}</span>
                      <span class="empty" v-else>NO</span>
                    </li>
                  </template>
                  <li>
                    <label>Number Owners:</label>
                    <span :class="{duplicate: uwShownItem.numberDirectors!=uwShownItem.ubos.length}">{{ uwShownItem.ubos.length }}</span>
                  </li>
                  <template v-for="(subi, si) in uwShownItem.ubos">
                    <li>
                      <label>Owner {{ si+1 }} Name:</label>
                      <span :class="{duplicate: uwShownItem.ubos[si] && uwShownItem.ubos[si].firstName!=subi.firstName}" v-if="subi.firstName">{{ subi.firstName }}</span>
                      <span class="empty" v-else>NO</span>
                    </li>
                    <li>
                      <label>Owner {{ si+1 }} Last Name:</label>
                      <span :class="{duplicate: uwShownItem.ubos[si] && uwShownItem.ubos[si].lastName!=subi.lastName}" v-if="subi.lastName">{{ subi.lastName }}</span>
                      <span class="empty" v-else>NO</span>
                    </li>
                    <li>
                      <label>Owner {{ si+1 }} Address:</label>
                      <span v-if="subi.address">{{ subi.address+" "+subi.addressNumber+" "+subi.addressPostcode+" "+subi.addressCity+" "+subi.addressCountry }}</span>
                      <span class="empty" v-else>NO</span>
                    </li>
                    <li>
                      <label>Owner {{ si+1 }} DOB:</label>
                      <span v-if="subi.dob">{{ subi.dob }}</span>
                      <span class="empty" v-else>NO</span>
                    </li>
                    <li>
                      <label>Owner {{ si+1 }} Passport:</label>
                      <span v-if="subi.passportNum">{{ subi.passportNum }}</span>
                      <span class="empty" v-else>NO</span>
                    </li>
                    <li>
                      <label>Owner {{ si+1 }} SSN:</label>
                      <span v-if="subi.ssn">{{ subi.ssn }}</span>
                      <span class="empty" v-else>NO</span>
                    </li>
                    <li>
                      <label>Owner {{ si+1 }} Phone:</label>
                      <span v-if="subi.phone">{{ subi.phone }}</span>
                      <span class="empty" v-else>NO</span>
                    </li>
                    <li>
                      <label>Owner {{ si+1 }} Email:</label>
                      <span v-if="subi.email">{{ subi.email }}</span>
                      <span class="empty" v-else>NO</span>
                    </li>
                  </template>
                  <li v-for="(contact, ci) in uwShownItem.contacts">
                    <label>{{ contact.type[0].toUpperCase()+contact.type.substring(1) }} contact:</label>
                    <span :class="{duplicate: uwShownItem.dupContacts}" v-if="contact.firstName">{{ contact.firstName+" "+contact.lastName }}</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="t2sect">
              <div class="t2box">
                <h3>Documents Information</h3>
                <ul v-if="uwShownItem.documents">
                  <li>
                    <label>Completed Merchant Application:</label>
                    <span>Yes</span>
                  </li>
                  <li>
                    <label>Completed Merchant Profile Form:</label>
                    <span v-if="uwShownItem.hasIntake*1">Yes</span>
                    <span class="empty" v-else>NO</span>
                  </li>
                  <template v-for="(field, index) in docIds">
                  <li class="field" v-if="field.indexOf('title')!=0">
                    <label>{{ documents[field] }}:</label>
                    <span v-if="uwShownItem.documents[field] && field=='agreement'">
                      <a target="_blank" :href="$parent.agreementPath(uwShownItem.documents[field])">Yes</a>
                    </span>
                    <span v-else-if="uwShownItem.documents[field]">
                      <a target="_blank" :href="$parent.fileLink(uwShownItem.documents[field].filename)">Yes</a>
                    </span>
                    <span class="empty" v-else>NO</span>
                  </li>
                  <h4 v-else style="margin-top: 10px">{{ documents[field] }}</h4>
                  </template>
                </ul>
              </div>
            </div>
            <div class="t2sect">
              <div class="t2box">
                <h3>Banking Information</h3>
                <ul>
                  <template v-for="(subi, si) in uwShownItem.accounts">
                    <li>
                      <label>Account Name:</label>
                      <span v-if="subi.accountHolder">{{ subi.accountHolder }}</span>
                      <span class="empty" v-else>NO</span>
                    </li>
                    <li>
                      <label>Account Nbr:</label>
                      <span v-if="subi.accountNumber">{{ subi.accountNumber }}</span>
                      <span class="empty" v-else>NO</span>
                    </li>
                    <li>
                      <label>Bank Name:</label>
                      <span v-if="subi.bankName">{{ subi.bankName }}</span>
                      <span class="empty" v-else>NO</span>
                    </li>
                    <li>
                      <label>Institution Nbr:</label>
                      <span v-if="subi.accountSwift">{{ subi.accountSwift }}</span>
                      <span class="empty" v-else>NO</span>
                    </li>
                    <li>
                      <label>Transit/Branch Nbr:</label>
                      <span v-if="subi.accountIBAN">{{ subi.accountIBAN }}</span>
                      <span class="empty" v-else>NO</span>
                    </li>
                    <li>
                      <label>ABA/Routing Nbr:</label>
                      <span v-if="subi.accountRouting">{{ subi.accountRouting }}</span>
                      <span class="empty" v-else>NO</span>
                    </li>
                  </template>
                </ul>
              </div>
            </div>
            <div class="t2sect">
              <div class="t2box">
                <h3>System</h3>
                <ul>
                  <li>
                    <label>CRISTINE Score:</label>
                    <span></span>
                  </li>
                  <li>
                    <label>Merchant Config Form:</label>
                    <span></span>
                  </li>
                  <li>
                    <label>Checklist Started:</label>
                    <span></span>
                  </li>
                  <li>
                    <label>Checklist Completed:</label>
                    <span></span>
                  </li>
                  <li>
                    <label>Website Check Complete:</label>
                    <span></span>
                  </li>
                  <li>
                    <label>Risk Calculator:</label>
                    <span></span>
                  </li>
                  <li>
                    <label>Compliance Review Days:</label>
                    <span></span>
                  </li>
                  <li>
                    <label>Duplicates:</label>
                  </li>
                  <li v-if="uwShownItem.dupCompanyName">
                    Company Name: 
                    <template v-for="dupId in uwShownItem.dupCompanyName.split(',')">
                      <a _target="blank" :href="'/applications/'+dupId">{{ appIds[dupId] || dupId }}</a>&nbsp;
                    </template>
                  </li>
                  <li v-if="uwShownItem.dupCompanyNameTrade">
                    Company Trade Name: 
                    <template v-for="dupId in uwShownItem.dupCompanyNameTrade.split(',')">
                      <a _target="blank" :href="'/applications/'+dupId">{{ appIds[dupId] || dupId }}</a>&nbsp;
                    </template>
                  </li>
                  <li v-if="uwShownItem.dupCompanyEmail">
                    Company Name: 
                    <template v-for="dupId in uwShownItem.dupCompanyEmail.split(',')">
                      <a _target="blank" :href="'/applications/'+dupId">{{ appIds[dupId] || dupId }}</a>&nbsp;
                    </template>
                  </li>
                  <li v-if="uwShownItem.dupWebsite">
                    Website: 
                    <template v-for="dupId in uwShownItem.dupWebsite.split(',')">
                      <a _target="blank" :href="'/applications/'+dupId">{{ appIds[dupId] || dupId }}</a>&nbsp;
                    </template>
                  </li>
                  <li v-if="uwShownItem.dupWebsiteBeta">
                    Website Beta: 
                    <template v-for="dupId in uwShownItem.dupWebsiteBeta.split(',')">
                      <a _target="blank" :href="'/applications/'+dupId">{{ appIds[dupId] || dupId }}</a>&nbsp;
                    </template>
                  </li>
                  <li v-if="uwShownItem.dupEmailService">
                    Support Email: 
                    <template v-for="dupId in uwShownItem.dupEmailService.split(',')">
                      <a _target="blank" :href="'/applications/'+dupId">{{ appIds[dupId] || dupId }}</a>&nbsp;
                    </template>
                  </li>
                  <li v-if="uwShownItem.dupPhoneService">
                    Support Phone: 
                    <template v-for="dupId in uwShownItem.dupPhoneService.split(',')">
                      <a _target="blank" :href="'/applications/'+dupId">{{ appIds[dupId] || dupId }}</a>&nbsp;
                    </template>
                  </li>
                  <li v-if="uwShownItem.dupAddress">
                    Address: 
                    <template v-for="dupId in uwShownItem.dupAddress.split(',')">
                      <a _target="blank" :href="'/applications/'+dupId">{{ appIds[dupId] || dupId }}</a>&nbsp;
                    </template>
                  </li>
                  <li v-if="uwShownItem.dupDirectors">
                    Director: 
                    <template v-for="dupId in uwShownItem.dupDirectors">
                      <a _target="blank" :href="'/applications/'+dupId[1]">{{ appIds[dupId[1]] || dupId }}</a>&nbsp;
                    </template>
                  </li>
                  <li v-if="uwShownItem.dupUbos">
                    UBO:
                    <template v-for="dupId in uwShownItem.dupUbos">
                      <a _target="blank" :href="'/applications/'+dupId[1]">{{ appIds[dupId[1]] || dupId }}</a>&nbsp;
                    </template>
                  </li>
                  <li v-if="uwShownItem.dupAccounts">
                    Bank Account:
                    <template v-for="dupId in uwShownItem.dupAccounts">
                      <a _target="blank" :href="'/applications/'+dupId[1]">{{ appIds[dupId[1]] || dupId }}</a>&nbsp;
                    </template>
                  </li>
                  <li v-if="uwShownItem.dupContacts">
                    Contacts:
                    <template v-for="dupId in uwShownItem.dupContacts">
                      <a _target="blank" :href="'/applications/'+dupId[1]">{{ appIds[dupId[1]] || dupId }}</a>&nbsp;
                    </template>
                  </li>
                </ul>
              </div>
            </div>
            <div class="t2sect">
              <div class="t2box">
                <h3>Account Configuration</h3>
                <ul>
                  <li>
                    <label>Monthly Limit Set:</label>
                    <span></span>
                  </li>
                  <li>
                    <label>Max Trans Limit Set:</label>
                    <span></span>
                  </li>
                  <li>
                    <label>Payment Methods:</label>
                    <span></span>
                  </li>
                  <li>
                    <label>Pricing Model:</label>
                    <span></span>
                  </li>
                  <li>
                    <label>Monthly Plan:</label>
                    <span></span>
                  </li>
                  <li>
                    <label>Pricing Set:</label>
                    <span></span>
                  </li>
                  <li>
                    <label>Modules:</label>
                    <span></span>
                  </li>
                  <li>
                    <label>TIDS:</label>
                    <span></span>
                  </li>
                  <li>
                    <label>Keys:</label>
                    <span></span>
                  </li>
                  <li>
                    <label>Account Options:</label>
                    <span></span>
                  </li>
                  <li>
                    <label>Equipment:</label>
                    <span></span>
                  </li>
                  <li>
                    <label>Special Offers:</label>
                    <span></span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="t2sect">
              <div class="t2box">
                <h3>Final Approval Check Score Report</h3>
                <ul>
                  <li>
                    <label>=</label> {{ validItems }} / 124
                  </li>
                  <li>
                    <label>Negative List Report:</label>
                    <span>{{ 0 }}</span>
                  </li>
                  <li>
                    <label>Duplicate List Report:</label>
                    <span>{{ dupCount }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="t2col toAnimate" v-show="false">
            <div style="width: 100%">
              <div v-for="(field, index) in fieldIds" class="field" :class="{visible: shownItem>index}">
                <label>{{ fields[field][0] }}:</label>
                <span v-if="uwShownItem[field] || fields[field][1]=='app'" v-html="showField(uwShownItem, field)"></span>
                <span class="empty" v-else>NO</span>
              </div>
            </div>
          </div>
          <template v-if="shownItem>=fieldIds.length">
          <div class="status-success" v-if="passed">
            Approved! Pending final aproval.
          </div>
          <div class="status-error" v-else>
            Not approved
          </div>
          </template>
        </div>
        <div class="action-sub-buttons" v-if="!uwShown">
          <a class="btn" v-on:click="$emit('back')">Back</a>
          <a class="btn" v-on:click="newFinalCheck">Run new</a>
          <a class="btn" v-if="uwSelected" v-on:click.prevent="doShowItems(uwSelected)">View</a>
        </div>
        <div class="action-sub-buttons" v-else>
          <a class="btn" v-on:click="uwShown=null">Back</a>
          <a class="btn" :href="printUrl" target="_blank">Print</a>
          <a style="margin-left: auto" class="btn" v-show="shownItem>=fieldIds.length" :class="{disabled: !passed}" v-on:click="passed && doFinal()">Final Approval</a>
        </div>
      </div>
</template>


<script>
export default {
  props: ["uwList", "preq", "inline"],
  data() { return {
    appIds: {},
    uwSelected: null,
    uwShown: null,    
    shownItem: 0,
    currencies: {
      "036": "AUD",
      "986": "BRL",
      "124": "CAD",
      "756": "CHF",
      "997": "EUR",
      "826": "GBP",
      "344": "HKD",
      "392": "JPY",
      "554": "NZD",
      "840": "USD",
    },
    fields: {
      status: ["Status is A03", "flag"],
      dupCheck: ["Passed DUP Check", "flag"],
      negCheck: ["Passed Negative List Check", "flag"],
      appId: ["AppID"],
      source: ["Source", "user"],
      accountId: ["Account ID"],
      manager: ["Manager", "user"],
      completedApp: ["Completed Application", "app"],
      signedApp: ["Signed Application", "doc"],
      agreement: ["Completed Agreement", "agr"],
      signedAgr: ["Signed Agreement", "agrs"],
      mcc: ["Has MCC Code"],
      merchantType: ["Has Merchant Type"],
      merchantIndustry: ["Has Merchant Industry"],
      accountClass: ["Has Account Class", "class"],
      accountPlan: ["Has Account Plan"],
      pricing: ["Has Pricing Set", "pricing"],
      modules: ["Has Modules Set", "modules"],
      equipment: ["Has Equipment Set", "equipment"],
      accOptions: ["Has Account Options Set", "options"],
      tids: ["Has TIDs Set"],
      monthlyLimit: ["Monthly Limit Set"],
      preCheck: ["Pre-Check was run", "flag"],
//       docCheck: ["Doc-Check was run", "flag"],
      websiteCheck: ["Website-Check was run", "flag"],
      matchVmasCheck: ["Match/VMAS Scan was run", "flag"],
      ofacCheck: ["OFAC Scan was run", "flag"],
      kycCheck: ["KYC Scan was run", "flag"],
      amlCheck: ["AML Scan was run", "flag"],
      giactCheck: ["GIACT Scan was run", "flag"],
      idmCheck: ["IDM Scan was run", "flag"],
      g2Check: ["G2 Scan was run", "flag"],
    },
    documents: {
      signedApplication: "Signed Merchant Application",
      agreement: "Agreement",
      signedAgreement: "Signed Agreement",
      title1: "Required Documents",
      pParent: "Parent company incorporation documents",
      incor: "Incorporation documents",
      passport: "Passport copy",
      utility: "Recent Utility Bill",
      statement: "3 Months Company Bank Statement",
      voidCheck: "Void Check",
      title2: "Conditional Documents",
      processing: "6 Months Processing Statement",
      structure: "Corporate Structure Diagram",
      financial: "2 Years Financial Statements",
      licenses: "Copy of License(s)",
      pciDss: "PCI DSS Certificate",
      padAuth: "Sample PAD Authorization",
      questionnaire: "MOTO/Internet Questionnaire",
      marketingMaterials: "Marketing Materials if no Website",
      businessSummary: "Complete Business Summary",
      personalGuarantee: "Personal Guarantee",
      title3: "New Business Documents",
      plan: "Business Plan with Projections",
      bankStatement: "Bank Statement or signed bank letter",
    },
    facilitators: appData.facilitators || [],
    managers: appData.managers || [],
    solutions: appData.solutions || [],
    uwShownItemMcc: {},
    accountTypes: [
      {value: "master", label: "Payfac"},
      {value: "merchant", label: "Merchant"},
      {value: "sub", label: "Sub-Account"},
    ],
  }},
  methods: {
    showField(item, field) {
      if (this.fields[field][1]=="flag")
        return "Yes";
      if (this.fields[field][1]=="app")
        return appData.existingApp.applicationId+" - "+appData.existingApp.companyName;
      if (this.fields[field][1]=="user")
        return item[field].companyName || item[field].fullName;
      if (this.fields[field][1]=="doc")
        return item[field].filename;
      if (this.fields[field][1]=="agr")
        return item[field].name;
      if (this.fields[field][1]=="agrs")
        return item[field].name+' signed';
      if (this.fields[field][1]=="class")
        return item[field].name;
      if (this.fields[field][1]=="pricing")
        return item[field].name;
      if (this.fields[field][1]=="modules") {
        var result=[];
        for(var i in item[field]) {
          var val=item[field][i];
          result.push(val.name);
        }
        return result.join("<br />");
      }
      if (this.fields[field][1]=="options") {
        var result=[];
        for(var i in item[field]) {
          var val=item[field][i];
          result.push(val.name+" "+(val.count===true?"":val.count));
        }
        return result.join("<br />");
      }
      if (this.fields[field][1]=="equipment") {
        var result=[];
        for(var i in item[field]) {
          var val=item[field][i];
          result.push(val.name+" "+(val.quantity*1?val.quantity+" "+val.price:val.rental+" "+val.rentalPrice+" (rental)"));
        }
        return result.join("<br />");
      }
      
      return item[field];
    },
    doShowItems(id, animate) {
      this.uwShown=id;
      var that=this;
      if (animate) {
        this.shownItem=0;
        clearInterval(this.shownItemInterval);
        this.shownItemInterval=setInterval(function() {
          that.shownItem++;
          if (that.shownItem>100)
            clearInterval(that.shownItemInterval);
        }, 300);
      } else {
        this.shownItem=100;
      }
      if (animate) {
        this.$nextTick(()=>{
          var itemsToShow=$(this.$refs.detailsBox).find(".t2box, .t2box li, .t2box h4");
          var itemIndex=0;
          this.shownItemInterval=setInterval(()=>{
            itemsToShow.eq(itemIndex).addClass("visible");
            itemIndex++;
            if (itemsToShow.length==itemIndex)
              clearInterval(this.shownItemInterval);
          }, 150);
        });
      } else {
        this.$nextTick(()=>{
          $(this.$refs.detailsBox).find(".t2box, .t2box li, .t2box h4").addClass("visible");
        });
      }
    },
    listFinalChecks: async function() {
      await this.$parent.listFinalChecks();
    },
    newFinalCheck: async function() {
      startLoading();
      var id=(await api.create("ApprovalCheck", {})).id;
      
      var newItem={}
      
      newItem.id=null;
      newItem.applicationId=this.$parent.item.id;
      newItem.userId=appData.currentUser.id;
      newItem.created="CURRENT_TIMESTAMP";
      newItem.documents=this.newDocCheck();
      newItem.accounts=this.$parent.item.accounts;
      newItem.directors=this.$parent.item.directors;
      newItem.ubos=this.$parent.item.ubos;
      newItem.contacts=this.$parent.item.contacts;
      
      await api.update("ApprovalCheck", id, newItem);
      await this.listFinalChecks();
      setTimeout(()=>{
        this.doShowItems(id, true);
      }, 30);
      stopLoading();
    },
    newDocCheck() {
      var documents={};
      for(var i in this.$parent.documents) {
        var doc=this.$parent.documents[i];
        documents[i]={
          id: doc.id,
          created: doc.created,
          filename: doc.filename,
        };
      }
      if (this.$parent.agreements && this.$parent.agreements.length) {
        documents["agreement"]={
          id: this.$parent.agreements[0].id,
          filename: this.$parent.agreements[0].name,
        };
        if (this.$parent.agreements[0].filename) {
          documents["signedAgreement"]={
            id: this.$parent.agreements[0].id,
            filename: this.$parent.agreements[0].filename,
          };
        }
      }
      return documents;
    },
    sendApprovalEmail: async function() {
      var existingApprovalMail=await api.list("ApplicationEmail", {emailType: "Approved", appId: this.$parent.item.id});
      if (!existingApprovalMail.items.length) {
        await api.create("ApplicationEmail", {emailType: "Approved", appId: this.$parent.item.id, created: "CURRENT_TIMESTAMP"})
      }
    },
    doFinal: async function() {
      if (!confirm("Are you sure?"))
        return;
      appFinalapprovalPopup.show(this);
    },
    doFinal2: async function(formData) {
      startLoading();
      await this.sendApprovalEmail();
      
      var reviewDays=365;
      var lastAgreement=(await api.list("Agreement", {applicationId: this.$parent.item.id}, ["id:DESC"])).items[0];
      if (lastAgreement) {
        var accClass=(await api.get("AccountClass", lastAgreement.accClass));
        if (accClass.reviewDays)
          reviewDays=accClass.reviewDays;
      }
      
      var appUpdate=api.update("Application", this.$parent.item.id, {status: "A04", approveDate: "CURRENT_TIMESTAMP"});
      var merchCreate=api.create("Merchant", {app: this.$parent.item.id, status: "M00", createdDate: "CURRENT_TIMESTAMP", ...formData});
      await appUpdate;
      stopLoading();
      var merchId=(await merchCreate).id;
      if (this.inline)
        this.$emit("next");
      else
        location.href="merchants/"+merchId;
    },
    accountType(id) {
      for(var accountType of this.accountTypes || [])
        if (id==accountType.value)
          return accountType.label;
      return "";
    },
    facilitator(id) {
      for(var facilitator of this.facilitators)
        if (id*1==facilitator.value*1)
          return facilitator.label;
      return "";
    },
    manager(id) {
      for(var facilitator of this.managers)
        if (id*1==facilitator.value*1)
          return facilitator.label;
      return "";
    },
    solution(id) {
      for(var solution of this.solutions)
        if (id*1==solution.value*1)
          return solution.label;
      return "";
    },
  },
  computed: {
    dupCount() {
      return 0;
    },
    passed() {
      return true;
      if (!this.uwShownItem)
        return false;
      for(var i in this.fields) {
        if (this.fields[i][1]=="app")
          continue;
        if (!this.uwShownItem[i])
          return false;
      }
      return true;
    },
    fieldIds() {
      var result=[];
      for(var i in this.fields)
        result.push(i);
      return result;
    },
    docIds() {
      var result=[];
      for(var i in this.documents)
        result.push(i);
      return result;
    },
    uwShownItem() {
      var result={};
      if (this.uwShown) {
        var item=this.uwList.find((a)=>a.id==this.uwShown);
        for(var i in item)
          result[i]=item[i];
      }
      return result;
    },
    printUrl() {
      if (!this.uwShownItem)
        return null;
      if (!appData.docCheckPrintUrl)
        return null;
      return appData.docCheckPrintUrl.replace("%d", this.uwShownItem.id);
    },
    validItems() {
      if (!this.uwShownItem)
        return 0;
      var i=this.uwShownItem;
      var invalid=0;
      
      invalid++; // Added for Intake form TODO
      
      if (!i.companyName) invalid++;
      if (!i.companyNameTrade) invalid++;
      if (!i.companyType) invalid++;
      if (!i.companyEmail) invalid++;
      if (!i.website) invalid++;
      if (!i.emailService) invalid++;
      if (!i.phoneService) invalid++;
      if (i.use3dSecure===null) invalid++;
      if (i.useCVC===null) invalid++;
      if (i.useAVS===null) invalid++;
      if (!i.solution) invalid++;
      if (!i.class) invalid++;
      if (!i.accountType) invalid++;
      if (!i.facilitator) invalid++;
      if (!i.merchantType) invalid++;
      if (!i.paymentMethods) invalid++;
      if (!i.paymentTypes) invalid++;
      if (!i.industry) invalid++;
      if (!i.volume) invalid++;
      if (!i.avgTransaction) invalid++;
      if (!i.maxTransaction) invalid++;
      if (!i.volumeCurrency) invalid++;
      if (!i.avgTransactionCurrency) invalid++;
      if (!i.maxTransactionCurrency) invalid++;
      
      for(var subi of i.directors) {
        if (!subi.firstName) invalid++;
        if (!subi.lastName) invalid++;
        if (!subi.address) invalid++;
        if (!subi.dob) invalid++;
        if (!subi.passportNum) invalid++;
        if (!subi.ssn) invalid++;
        if (!subi.phone) invalid++;
        if (!subi.email) invalid++;
      }
      
      for(var subi of i.ubos) {
        if (!subi.firstName) invalid++;
        if (!subi.lastName) invalid++;
        if (!subi.address) invalid++;
        if (!subi.dob) invalid++;
        if (!subi.passportNum) invalid++;
        if (!subi.ssn) invalid++;
        if (!subi.phone) invalid++;
        if (!subi.email) invalid++;
      }
      
      for(var subi of i.contacts) {
        if (!subi.firstName) invalid++;
      }
      
      for(var subi of i.accounts) {
        if (!subi.accountHolder) invalid++;
        if (!subi.accountNumber) invalid++;
        if (!subi.bankName) invalid++;
        if (!subi.accountIBAN) invalid++;
        if (!subi.accountBankCode) invalid++;
        if (!subi.accountRouting) invalid++;
      }

      for(var field in this.fields) {
        if (field.indexOf("title")==0)
          continue;
        if (!i.documents || !i.documents[field]) invalid++;
      }

      
      return 124-invalid;
    },
  },
  watch: {
    async uwShownItem() {
      if (!this.uwShownItem)
        this.uwShownItemMcc={};
      else {
        var mccCode=(this.uwShownItem.mcc || "").split(" ")[0];
        var mcc=(await api.list("MCCCode", {status: mccCode})).items[0];
        this.uwShownItemMcc=mcc || {};
      }
    },
  },
  mixins: [vueMixins.dateFormatter],  
}
</script>
