<template>
<div>
  <table class="bordered">
    <tbody>
      <tr class="header text-left"><td colspan="4">MERCHANT UNDERWRITING CHECKLIST – CARDBILLER-CA v1.0</td></tr>
      <tr>
        <td>Start Date:</td><td></td><td>End Date:</td><td></td></tr>
      <tr>
        <td>Analyst:</td><td></td><td>Approved By:</td><td></td></tr>
      <tr>
        <td>Account ID:</td><td></td><td>Account Class:</td><td></td></tr>
      <tr>
        <td>Payment Methods Requested:</td><td></td><td>Agent:</td><td></td>
      </tr>
      <tr class="header text-left"><td colspan="4">GENERAL APPLICATION INFORMATION</td></tr>
      <tr>
        <td>New Business:</td>
        <td class="pen-checkbox">YES <i /> NO <i /></td>
        <td>Parent Company:</td>
        <td class="pen-checkbox">YES <i /> NO <i /></td>
      </tr>
      <tr>
        <td>Company Name:</td><td></td><td>Contact Name:</td><td></td>
      </tr>
      <tr>
        <td>Company Email:</td><td></td><td>Company Website:</td><td></td>
      </tr>
      <tr>
        <td>Company Country:</td><td></td><td>Solution:</td><td></td>
      </tr>
      <tr>
        <td>Merchant Type:</td>
        <td class="pen-checkbox">CP <i /> CNP <i /> MIX <i /></td>
        <td>Business Type:</td>
        <td></td>
      </tr>
      <tr>
        <td>Monthly Vol Requested</td><td></td><td>x 12 = Yearly Estimate</td><td></td>
      </tr>
      <tr>
        <td>Annual Vol Requested</td><td></td><td>/ 12 = Monthly Estimate</td><td></td>
      </tr>
      <tr>
        <td>Business Type Identified</td>
        <td class="pen-checkbox">YES <i /> NO <i /></td>
        <td>Business Description</td>
        <td></td>
      </tr>
      <tr>
        <td>Site Survey Completed</td>
        <td class="pen-checkbox">YES <i /> NO <i /></td>
        <td>Site Survey By</td>
        <td></td>
      </tr>
      <tr>
        <td>Credit Report Score</td><td></td><td>Proposed MCC</td><td></td>
      </tr>
      <tr>
        <td>Licensed Business</td>
        <td class="pen-checkbox">YES <i /> NO <i /></td>
        <td>Have Copy of License(s)</td>
        <td></td>
      </tr>
      <tr class="header text-left"><td colspan="4">PRE-CHECKS</td></tr>
      <tr>
        <td>Pre-Check Completed</td>
        <td class="pen-checkbox">YES <i /> NO <i /></td>
        <td>Result</td>
        <td class="pen-checkbox">Pass <i /> Fail <i /></td>
      </tr>
      <tr>
        <td>Verified Missing Items</td>
        <td class="pen-checkbox">YES <i /> NO <i /></td>
        <td>Explanation</td>
        <td></td>
      </tr>
      <tr>
        <td>Verified Duplicates</td>
        <td class="pen-checkbox">YES <i /> NO <i /></td>
        <td>Explanation</td>
        <td></td>
      </tr>
    </tbody>
  </table>
  <table class="bordered">
    <tbody>
      <tr class="header"><td colspan="9">MERCHANT CHECKLIST</td></tr>
      <tr class="header text-center">
        <td>Class Note<br>Class</td>
        <td>LR-AA<br>00</td>
        <td>LR-CP<br>10</td>
        <td>LR-CP<br>20</td>
        <td>MR-MIX<br>30</td>
        <td>HR-CNP<br>40</td>
        <td>HR-CNP<br>50</td>
        <td>HRS-CP<br>98</td>
        <td>HRS-CNP<br>99</td>
      </tr>
      <tr class="ver-allign">
        <td>Criteria</td>
        <td>Credit 500 +<br> >70% CP <br>Gets Pin or Sig<br> Avg Tkt <$1,000<br> <1.2M Yr Vol<br><br>NOT MCC’s: 1520; 4816; 4829; 5734; 5999; 6051; 7321; 7375</td>
        <td>Credit 400 +<br> >70% CP<br> Gets Pin or Sig<br> Avg Tkt <$1,000<br> <6M Yr Vol</td>
        <td>Credit 400 +<br> >70% CP<br> Gets Pin or Sig<br> Avg Tkt <$1,000 <br>>6M Yr Vol</td>
        <td>Credit 400 +<br> CP &amp; CNP<br> Gets Pin or Sig<br> Avg Tkt <$1,000<br> <10M Yr Vol</td>
        <td>Credit 400 +<br> >30% CNP <br>Avg Tkt <$1,000 <br><3M Yr Vol</td>
        <td>Credit 400 +<br> >30% CNP<br> Avg Tkt <$1,000<br> >3M Yr Vol</td>
        <td>Credit 400 +<br> >70% CP<br> Gets Pin or Sig<br> Avg Tkt >$1,000<br> >10M Yr Vol</td>
        <td>Credit 400 +<br> >30% CNP<br> Avg Ticket >$1,000<br> >10M Yr Vol</td>
      </tr>
      <tr>
        <td>Select One</td>
        <td class="pen-checkbox" v-for="i in 8" :key="i"><i /></td>
      </tr>
    </tbody>
  </table>
</div>
</template>

<script>
export default {
}
</script>
