<template>
<view-profile :header="['Partner Detail']" v-if="partner">
  <template v-slot:tabsheader>
    <p v-if="partner.agentDb.emailConfirmation">Email confirmed</p>
    <p v-else>Email is not confirmed. Boarding is disabled</p>
  </template>
  <template v-slot:tabsmain v-if="partner">
    <ul>
      <template v-for="(tabData, tabId) in tabs" :class="{active: tab==tabId}">
        <template v-if="tabData.disabled">
          <template v-if="partner.agentDb.emailConfirmation">
            <li :class="{active: tab==tabId}" v-if="!tabData.act || partner.agentId">
              <a href="" v-on:click.prevent="tab=tabId, editMode=false">{{ tabData.label }}</a>
            </li>
          </template>
          <template v-else>
            <li class="disabled">
              <a href="" v-on:click.prevent="">{{ tabData.label }}</a>
            </li>
          </template>
        </template>
        <template v-else>
          <li :class="{active: tab==tabId}" v-if="!tabData.act || partner.agentId">
            <a href="" v-on:click.prevent="tab=tabId, editMode=false">{{ tabData.label }}</a>
          </li>
        </template>
      </template>
    </ul>
    <div class="status" v-if="partner">
      <label><strong>{{ partner.agentId || partner.id }}</strong></label>
      <label><strong>{{ partner.agentDb && partner.agentDb.name }}</strong></label>
      <label><strong>{{ partner.agentDb && partner.agentDb.status }} - {{ statusDescription }}</strong></label>
    </div>
  </template>
  <template v-slot:tabssub v-for="subTabs in [sTabs[tab]]">
    <ul>
      <li v-for="(tabData, tabId) in subTabs" :class="{active: subTab==tabId}">
        <a href="" v-on:click.prevent="subTab=tabId, editMode=false">{{ tabData.label }}</a>
      </li>
    </ul>
  </template>
  <template v-slot:actions>
    <PortalTarget name="actions"></PortalTarget>
  </template>
  
  <div class="merchant-panel">
  <div class="data-content" v-if="partner">
    <formview-agent tab="0" :item="partner" :editMode.sync="editMode" v-if="subTab=='general'" :edit="sTabs[tab][subTab].edit" />
    <formview-agent tab="999" :item="partner" :editMode.sync="editMode" v-if="subTab=='status'" :edit="sTabs[tab][subTab].edit" />
    <formview-agent tab="100" :item="partner" :editMode.sync="editMode" v-if="subTab=='company'" :edit="sTabs[tab][subTab].edit" />
    <formview-agent tab="101" :item="partner" :editMode.sync="editMode" v-if="subTab=='directors'" :edit="sTabs[tab][subTab].edit" />
    <formview-agent tab="102" :item="partner" :editMode.sync="editMode" v-if="subTab=='business'" :edit="sTabs[tab][subTab].edit" />
    <formview-agent tab="2" :item="partner" :editMode.sync="editMode" v-if="subTab=='banking'" :edit="sTabs[tab][subTab].edit" />
    <formview-agent tab="110" :item="partner" :editMode.sync="editMode" v-if="subTab=='profile'" :edit="sTabs[tab][subTab].edit" />
    <formview-agent tab="111" :item="partner" :editMode.sync="editMode" v-if="subTab=='settings'" :edit="sTabs[tab][subTab].edit" />
    <formview-agent tab="8" :item="partner" :editMode.sync="editMode" v-if="subTab=='schedulea'" :edit="sTabs[tab][subTab].edit" />
    <partner-profile-activity :item="partner" :editMode.sync="editMode" v-if="subTab=='agents' || subTab=='activity'" />
    <partner-profile-users :item="partner" :editMode.sync="editMode" v-if="subTab=='users'" />
    <partner-notes class="" :notes="notes" type="Partner" :id="partner.id" ref="notes" v-if="subTab === 'notes'" />
    
    <partner-documents :item="partner" v-if="subTab=='documents'" ref="documents" v-on:reloadnotes="loadNotes" />
    <partner-email :item="partner" v-if="subTab=='emails'" :emailDisabled="emailDisabled" ref="emails" />
    <partner-profile-application :item="partner" v-if="subTab=='application'" ref="application" />
    <partner-profile-agreements :item="partner" v-if="subTab=='agreements'" ref="agreements" />
    <view-agent-precheck key="precheck" :uwList="precheckList" v-if="subTab=='precheck'" ref="precheck" />
    <view-agent-setupcl key="setupcl" :item="partner" :notes="notes" :prechecks="precheckList" v-if="subTab=='setupcl'" ref="precheck" />
<!--     <partner-profile-prechecks :item="partner" v-if="subTab=='precheck'" /> -->
    <partner-boarding key="boarding" :item="partner" :notes="notes" v-if="subTab=='boarding'" ref="boarding" :precheckList="precheckList" :approvalList="approvalList" v-on:reloadnotes="loadNotes" />
    <partner-scans :item="partner" :merchant="merchant" v-if="subTab=='scans'" ref="scans" />
    <view-app-websitecheck v-if="subTab=='wscheck'" :agent="true" :uwList="webList" ref="wscheck" />
    <view-agent-finalcheck v-if="subTab=='finalcheck'" :uwList="approvalList" ref="finalcheck" />
  
  </div>
  </div>
  
</view-profile>
</template>

<script>
export default {
  props: [],
  data: ()=>({
    tab: "general",
    subTab: "general",
    tabs: {
      boarding: {label: "Boarding", def: "boarding", disabled: true},
      general: {label: "General", def: "general"},
      partner: {label: "Partner", def: "company"},
      documents: {label: "Documents", def: "documents"},
      compliance: {label: "Compliance", def: "status"},
      notes: {label: "Notes", def: "notes"},
      emails: {label: "Emails", def: "emails"},
      access: {label: "Access", def: "users", act: true},
      profile: {label: "Settings", def: "settings", act: true},
    },
    sTabs: {
      boarding: {
        boarding: {label: "Onboarding"},
        precheck: {label: 'Pre-check'},
      },
      general: {
        general: {label: 'Profile', edit: true},
//         setupcl: {label: 'Setup Checklist'},
      },
      partner: {
        company: {label: 'Company', edit: true},
        directors: {label: 'Directors', edit: true},
        business: {label: 'Business', edit: true},
        banking: {label: 'Banking', edit: true},
        application: {label: 'Application'},
        agreements: {label: 'Agreements'},
      },
      documents: {
        documents: {label: 'Documents'},
      },
      emails: {
        emails: {label: 'Emails'},
      },
      notes: {
        notes: {label: 'Notes'},
      },
      compliance: {
        status: {label: 'Status'},
        wscheck: {label: 'Website Check'},
        scans: {label: 'Scans'},
      },
      access: {
        users: {label: "Users"/*, edit: true*/},
        activity: {label: "Activity"},
      },
      profile: {
        settings: {label: "Settings", edit: true},
        schedulea: {label: "Schedule A", edit: true},
        agents: {label: "Agents"},
      },
    },
    editMode: false,
    newFile: false,
    partner: null,
    notes: [],
    form: {},
    agreements: [],
    webList: [],
    precheckList: [],
    agents: [],
    setups: [],
    isWsCheck: false,
    isPreCheck: false,
    allActions: {
      "NEG": "Negative List",
      "RNE": "Restore Neg List",
//       "P04": "Denied",
//       "A02": "Restore",
//       "APP": "Approve",
      "FUP": "Follow-up",
      "NFU": "No Follow-up",
//       "P02": "Restrict",
//       "A08": "Un-Restrict",
//       "P03": "Close",
    },
    approvalList: [],
    statusDescription: null
  }),
  watch: {
    tab() {
      this.subTab=this.tabs[this.tab].def;
      this.isPreCheck=false;
      this.isWsCheck=false;
    },
    subTab() {
      this.isPreCheck=false;
      this.isWsCheck=false;
    },
  },
  computed: {
    lastSetup() {
      return this.setups[0];
    },
    emailDisabled() {
      return {
        Agreement: this.agreements.length==0,
        Declined: false,
        Closed: this.partner.agentDb.status!="A08",
      };
    },
    item() {
      return this.partner;
    },
    signedApplications() {
      var i=0;
      var result=[];
      var docs={};
      for(var j in this.item.agentDb.documents) {
        docs[this.item.agentDb.documents[j].type]=this.item.agentDb.documents[j];
      }
      while(docs['signedApplication'+(i?i:"")]) {
        result.push(docs['signedApplication'+(i?i:"")]);
        i++;
      }
      return result;
    },
  },
  methods: {
    isDisabledAction(action) {
      if (!this.partner)
        return true;
      var current=this.partner.agentDb.status;
      if (action=="FUP")
        return this.partner.agentDb.followup*1;
      if (action=="NFU")
        return !(this.partner.agentDb.followup*1);
      if (action=="NEG") {return false
        console.log("Action" +action)
        console.log(!!this.negativeList)
        return !!this.negativeList;
      }
        
      if (action=="RNE")
        return !this.negativeList;
      
      var enabledMatrix={
        "A02": ["P04", "APP"],
        "P04": ["A02"],
        "A08": ["P02", "P03"],
        "P02": ["A08", "P03"],
        "P03": ["A08"]
      };
      return (enabledMatrix[current] || []).indexOf(action)==-1;
    },
    doAction: async function(action) {
      if (action=="NEG") {
        startLoading();
        var id=(await api.create("NegativeList", {agent: this.partner.id})).id;
        stopLoading("Status updated");
        this.negativeList=(await api.get("NegativeList", id)).item;
      } else if (action=="RNE") {
        startLoading();
        if (this.negativeList)
          await api.delete("NegativeList", this.negativeList.id);
        this.negativeList=null;
        stopLoading("Status updated");
      } else if (action=="FUP") {
        startLoading();
        this.partner.agentDb.followup=1;
        await api.update("Partner", this.partner.agentDb.id, {followup: 1});
        stopLoading("Status updated");
      } else if (action=="NFU") {
        startLoading();
        this.partner.agentDb.followup=0;
        await api.update("Partner", this.partner.agentDb.id, {followup: 0});
        stopLoading("Status updated");
      } else if(action == "A08" || action == "A09" || action == "A10" || action == "A11" || action == "A12") {
        startLoading();
        this.partner.agentDb.status = action;
        await api.update("Partner", this.partner.agentDb.id, {status: action});
        await this.loadNotes()
        stopLoading("Status updated");
      }
    },
    createNote() {
      if (this.$refs.notes)
        this.$refs.notes.createNote();
    },
    async loadNotes () {
      const filter = {
        type: 'Partner',
        parentId: this.partner.agentDb.id
      }
      this.notes = (await api.list("Note", filter, ['id:ASC'])).items || [];
    },
    async loadPartner() {
      this.partner=appData.existingAgent;
      this.agents=window.agents;
      this.loadNotes();
      this.listAgreements();
      this.listWebsiteChecks();
      this.listPreChecks();
      this.listSetups();
      var status = (await api.list('Status', { status: this.partner.agentDb.status})).items
      this.statusDescription = status[0].description
    },
    listPreChecks: async function() {
      this.precheckList=(await api.list("PartnerPrecheck", {agentId: this.item.id}, ["id:DESC"])).items || [];
    },
    listAgreements: async function() {
      this.agreements=(await api.list("PartnerAgreement", {agentId: this.partner.id}, ["id:DESC"])).items || [];
    },
    listWebsiteChecks: async function() {
      this.webList=(await api.list("WebsiteCheck", {agentId: this.item.id}, ["id:DESC"])).items || [];
    },
    listSetups: async function() {
      this.setups=(await api.list("PartnerSetup", {agentId: this.item.id}, ["id:DESC"])).items || [];
    },
    uploadSigned(agreement, index) {
      var that=this;
      var input=$("<input />", {type: "file"});
      input.on("change", async function(e) {
        var file=this.files[0];
        startLoading();
        if (!window.validateFileExt(file))
          return stopLoading("Invalid file extension", "error");
        await api.update("PartnerAgreement", agreement.id, {signed: "CURRENT_TIMESTAMP"});
        await api.upload("PartnerAgreement", agreement.id, file);
        that.$set(that.agreements, index, (await api.get("PartnerAgreement", agreement.id)).item);
        stopLoading("File uploaded");
      });
      input.click();
    },
    uploadFile(field=null) {
      if (field)
        this.selectedFile=field;
      var that=this;
      var input=$("<input />", {type: "file"});
      input.on("change", async function(e) {
        var file=this.files[0];
        startLoading();
        if (!window.validateFileExt(file))
          return stopLoading("Invalid file extension", "error");
        var id=(await api.create("PartnerDocument", {agentId: that.item.agentDb.id, type: that.selectedFile, created: "CURRENT_TIMESTAMP"})).id;
        if (id) {
          await api.upload("PartnerDocument", id, file);
          that.item.agentDb.documents=(await api.get("Partner", that.item.agentDb.id)).item.documents;
        }
        
        stopLoading("File uploaded");
        
      });
      input.click();
    },
    async listFinalChecks() {
      this.approvalList=(await api.list("PartnerApprovalCheck", {agentDbId: this.item.agentDb.id}, ["id:DESC"])).items || [];
//       this.subTab='finalcheck';
    },
  },
  mounted() {
    this.loadPartner();
    if (location.hash.indexOf("#boarding/")==0) {
      this.tab="boarding";
      this.subTab="boarding";
    }
  },
}
</script>
