<template>
<div class="form-popup" v-show="title" v-on:click.prevent="dismiss"><div>
  <div class="error-popup">
    <h2>{{ title }}</h2>
    <p v-html="message"></p>
    <button v-on:click.prevent="dismiss">Dismiss</button>
  </div>
</div></div>
</template>

<script>
export default {
  data: ()=>({
    title: null,
    message: null,
  }),
  mounted() {
    window.appErrors=this;
  },
  methods: {
    show(title, message) {
      this.message=message;
      this.title=title;
    },
    dismiss() {
      this.message=null;
      this.title=null;
    }
  },
}
</script>
