<template>
<div class="ncontent newappsp">
  <div class="new-app-title">
    <h1>New Enrollment</h1>
    <p>What type of enrollment do you want to complete?</p>
  </div>
  <div class="partner-form">
    <div class="partner-item">
      <label>Solution</label>
      <dyn-select v-model="type" :options="solutions" />
    </div>
    <div class="partner-item">
      <label>Form</label>
      <dyn-select v-model="form" :options="forms" />
    </div>
    <template v-if="type && form">
      <div v-if="form == 'Treasury Application'">
        <div style="display: flex;">
          <div class="partner-item">
            <a class="btn btn-small btn-green" :href="linkApp+type">Start Application</a>
            <a :href="pubLink" target="_blank">{{ pubLink }}</a>
          </div>
          <div class="partner-item">
            <button class="btn btn-small btn-primary" v-if="!tempLink" v-on:click.prevent="generateTempLink">
              Get URL
            </button>
            <button class="btn btn-small btn-primary" v-else v-on:click.prevent="doCopy(tempLink)">Copy URL</button>
            <div v-if="tempLink">
              <input type="text" reaonly :value="tempLink" />
            </div>
          </div>
          <div class="partner-item">
            <label>
              Send Email
            </label>
            <dyn-select v-model="emailType" :options="emailTypes" />
          </div>
        </div>
        <div class="partner-input">
          <div v-if="links.Prequalification">
            <input type="text" reaonly :value="links.Prequalification" />
          </div>

          <div v-if="emailType == 'New Treasury Application'" class="form-link-ext">
            <form ref="form" v-on:submit.prevent="sendSignupEmail">
              <dyn-select required v-model="type" :options="solutions" v-show="false" />
              <input :disabled="!this.type" required type="email" v-model="email" placeholder="email">
              <button :disabled="!this.type">Send</button>
            </form>
          </div>
        </div>
      </div>
    </template>
  </div>
  <div class="new-application-note">
    <p>For assistance or more information, please contact <b>{{ company.applicationEmail }}</b> or <b>{{ company.servicesTel}}</b>. <br> You may also contact your Relationship Manager or Account Manager for assistance.</p>
  </div>
</div>
</template>
<script>
export default {
  props: ["partnerId", "isAgent", "companyId", "linkApp"],
  data: ()=>({
    type: null,
    links: {
      Prequalification: null,
      Application: null,
      Facilitator: null,
    },
    form: null,
    emailType: null,
    company: {},
    email: "",
    emailTypes: [
      {'value': null, label: ''},
      {'value': 'New Treasury Application', label: 'New Treasury Application'},
    ],
    settings: appData.settings.settings,
    solutions: [
      {
        "label" : "",
        "value" : null
      }
    ],
    tempLink: null,
  }),

  async created() {
    this.company = (await api.get("Solution", this.companyId)).item;
    if(this.isAgent) {
      this.partner = (await api.get("Partner", this.partnerId)).item;
      var items=(await api.list("Solution", {solutionType: "Banking", id : ["IN ("+this.partner.tsolutions+")"]})).items;
    }else{
      var items=(await api.list("Solution", {solutionType: "Banking"})).items;
    }
    for(var i in items)
      this.solutions.push(
        {
          "label" : items[i].nameSolution,
          "value" : items[i].id
        }
      )
  },

  computed: {
    pubLink() {
      var solModId=(this.type%4096)^2650;
      var solVal=97-solModId%97;
      var solCode=solVal.toString(16).padStart(2, "0")+
                  solModId.toString(16).padStart(3, "0");
      return location.origin+"/register/"+solCode+"-t";
    },
    forms() {
      var forms = [
        {'value': null, label: ''},
      ]
      if(this.settings?.enrollmentForms?.treasury) {
        forms.push(
          {'value': 'Treasury Application', label: 'Treasury Application'},
        )
      }
      return forms
    }
  },
  methods: {
    async generateTempLink() {
      if (!this.type)
        return;
      if (this.tempLink)
        return;
      var result=await api.action("TempLink", "new", "generate", {type: 'treasury', solution: this.type});
      this.tempLink=result.action.link;
    },
    doCopy(text) {
      copyToClipboard.doCopyText(text);
    },

    sendSignupEmail: async function() {
      if (!this.type)
        return;
      startLoading();
      
      var result=await api.action("TempLink", "new", "email", {type: 'treasury', email: this.email, solution: this.type});

      stopLoading("Email sent");
      this.email="";
      this.$refs.form.reset();
    },
  },

  watch: {
    form: {
      handler() {
        this.emailType = null
          this.links = {
            Prequalification: null,
            Application: null,
            Facilitator: null,
          }
      }
    },
    
    emailType: {
      handler() {
        if(this.emailType) {
          this.links = {
            Prequalification: null,
            Application: null,
            Facilitator: null,
          }
        }
      }
    },

  }
}
</script>
