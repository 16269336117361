<template>
  <aside :class="{ collapsed }">
    <div class="header">
      <div class="header-main">
        <button class="unstyled hamburger" @click="toggleCollapsed()">
          <MenuIcon />
        </button>

        <div class="actions">
          <div
            class="item-icon mr-5"
            @mouseenter="hoverOn = 'notifications'"
            @mouseleave="hoverOn = null"
          >
            <NotificationIcon />
          </div>

          <popper position="right-top" :offset="{ y: 5 }">
            <messages />

            <template v-slot:reference>
              <div
                class="item-icon"
                @mouseenter="hoverOn = 'messages'"
                @mouseleave="hoverOn = null"
              >
                <SystemMessagesIcon
                  :color="hoverOn === 'messages' ? '#51b13e' : '#292929'"
                />
              </div>
            </template>
          </popper>
        </div>
      </div>

      <div class="logo-wrapper">
        <img :src="logo" />
      </div>
    </div>

    <nav>
      <ul>
        <nav-item :item="dashboardItem" name="dashboard" />
        <nav-item
          v-for="(item, key) in navigation"
          :key="key"
          :name="key"
          :item="item"
        />
      </ul>
    </nav>

    <div>
      <div class="sidebar-footer">
        <span class="version">{{ changeLog.version }}</span>
        <a v-if="!user.agentId" href="/manage" class="manage-logo">
          <img src="/assets/img/plug-dark.png" />
        </a>
      </div>

      <popper
        :position="collapsed ? 'right-bottom' : 'top'"
        :offset="collapsed ? { x: 10, y: 10 } : { y: 10 }"
      >
        <account-dropdown />

        <template v-slot:reference>
          <div
            class="account"
            @mouseenter="hoverOn = 'account'"
            @mouseleave="hoverOn = null"
          >
            <account-icon
              :size="30"
              class="icon"
              :color="hoverOn === 'account' ? '#51b13e' : '#292929'"
            />
            <span>Account</span>
          </div>
        </template>
      </popper>
    </div>
  </aside>
</template>

<script>
import Popper from "./components/common/Popper";
import MenuIcon from "./components/layout/navigation/MenuIcon";
import AccountDropdown from "./components/layout/AccountDropdown";
import AccountIcon from "./components/layout/navigation/AccountIcon";
import NotificationIcon from "./components/layout/navigation/NotificationIcon";
import SystemMessagesIcon from "./components/layout/navigation/SystemMessagesIcon";

export default {
  name: "sidebar",

  components: {
    Popper,
    MenuIcon,
    AccountIcon,
    AccountDropdown,
    NotificationIcon,
    SystemMessagesIcon,
  },

  data() {
    return {
      hoverOn: null,
      collapsed: false,

      dashboardItem: ["Dashboard", ""],
    };
  },

  computed: {
    user() {
      return window.appData.currentUser2;
    },

    changeLog() {
      return window.appData.changeLog;
    },

    loginMode() {
      return window.appData.loginMode;
    },

    settings() {
      return window.appData.settings;
    },

    logo() {
      if (this.loginMode == "partners")
        return this.settings.partnerLogo
          ? `files/system/${this.settings.partnerLogo}`
          : null;
      if (this.loginMode == "developers")
        return this.settings.sandboxLogo
          ? `files/system/${this.settings.sandboxLogo}`
          : null;
      if (this.loginMode == "treasury")
        return this.settings.dashboardLogo
          ? `files/system/${this.settings.dashboardLogo}`
          : null;

      return this.settings.logo ? `files/system/${this.settings.logo}` : null;
    },

    navigation() {
      return window.appData.nav;
    },
  },

  methods: {
    toggleCollapsed() {
      const $sidebar = document.querySelector(".main-sidebar");
      const $content = document.querySelector(".main-content");

      this.collapsed = !this.collapsed;

      if (this.collapsed) {
        $sidebar.classList.add("collapsed");
        $content.classList.add("wide");
      } else {
        $sidebar.classList.remove("collapsed");
        $content.classList.remove("wide");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
aside {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .header {
    display: flex;
    flex-direction: column;
    padding: 20px 17px 50px 20px;
    .header-main {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;
      .hamburger {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding: 0;
      }

      .actions {
        display: flex;
        .item-icon {
          padding: 5px;
          height: 24px;
          cursor: pointer;
        }
      }
    }
    .logo-wrapper {
      width: 100%;
      img {
        width: 200px;
      }
    }
  }
  nav {
    height: 100%;
    width: calc(100% + 20px);
    padding: 0 20px;
    box-sizing: border-box;
    overflow-y: scroll;
  }

  .sidebar-footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0 20px;
    .version {
      font-size: 12px;
      color: #8f979e;
    }
    .manage-logo {
      padding: 5px 0 0 5px;
      cursor: auto;
      img {
        height: 25px;
      }
    }
  }

  .account {
    height: 70px;
    line-height: 70px;
    border-top: 1px solid #e9ecef;
    margin-top: 20px;
    padding: 0 20px;
    font-size: 14px;
    font-weight: 600;
    max-width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: all ease 0.2s;
    .icon {
      margin-right: 10px;
      transition: all ease 0.2s;
    }
    &:hover {
      color: #51b13e;
    }
  }

  &.collapsed {
    .header {
      .header-main {
        flex-direction: column;
        .actions {
          flex-direction: column;
          .item-icon {
            margin-top: 15px;
          }
        }
      }
      .logo-wrapper {
        display: none;
      }
    }
    nav {
      display: none;
    }

    .sidebar-footer {
      flex-direction: column;
      align-items: center;
      padding: 0 5px;
      .version {
        font-size: 11px;
      }
      .manage-logo {
        padding: 5px 0 0 5px;
        margin-top: 10px;
        img {
          height: 20px;
        }
      }
    }

    .account {
      .icon {
        margin-right: 0;
      }
      span {
        display: none;
      }
    }
  }
}
</style>
