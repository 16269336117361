<template>
<form class="form-step fx">
  <p  class="form-intro fx">
    Be sure to have the required documents as outlined below available as you will need to submit them at the end. <br /><strong>This application should take 15 minutes to complete.</strong> You may save the application and resume if necessary.
  </p>
  
  <section>
    <h2>Required Documents</h2>
    <checkbox-toggle v-model="app.companyParent">
      Parent Company
      <template v-slot:more>If yes, provide incorporation documents for parent company and information of beneficial owners, directors and shareholders if different than company applying.</template>
    </checkbox-toggle>
    <validator v-if="false" :value="app.requirements.merchantApplication" reqs="required">
    <checkbox-plain v-model="app.requirements.merchantApplication">
      Completed &amp; signed merchant application
      <template v-slot:more>Incomplete, handwritten or unsigned applications will not be accepted</template>
    </checkbox-plain>
    </validator>
    <validator :value="app.requirements.incorporationDocuments" reqs="required">
    <checkbox-plain v-model="app.requirements.incorporationDocuments">
      Incorporation Documents
      <template v-slot:more>Must include certificate of incorporation, memorandum and articles of association, certificates listing all current beneficial owners, directors and shareholders</template>
    </checkbox-plain>
    </validator>
    <validator :value="app.requirements.passportCopy" reqs="required">
    <checkbox-plain v-model="app.requirements.passportCopy">
      Passport Copy
      <template v-slot:more>Passport copy for all beneficial owners, directors and authorized signatories</template>
    </checkbox-plain>
    </validator>
    <validator :value="app.requirements.utilityBill" reqs="required">
    <checkbox-plain v-model="app.requirements.utilityBill">
      Recent Utility Bill
      <template v-slot:more>Utility bill for all beneficial owners, directors and authorized signatories.  No older than 60 days.</template>
    </checkbox-plain>
    </validator>
    <validator :value="app.requirements.bankStatement" reqs="required">
    <checkbox-plain v-model="app.requirements.bankStatement">
      3 Months Bank Statement or Signed Bank Letter
      <template v-slot:more>3 Months company bank statement clearly showing company name and address</template>
    </checkbox-plain>
    </validator>
    <validator :value="app.requirements.voidCheck" reqs="required">
    <checkbox-plain v-model="app.requirements.voidCheck">
      Void Check or Signed Bank Letter
      <template v-slot:more>Must be in company name</template>
    </checkbox-plain>
    </validator>
    <validator :value="app.requirements.businessPlan" reqs="required" v-if="app.industry=='Facilitator'">
    <checkbox-plain v-model="app.requirements.businessPlan">
      Business Plan and Projections
      <template v-slot:more>Provide business plan with projections</template>
    </checkbox-plain>
    </validator>
    <validator :value="app.requirements.riskPolicies" reqs="required" v-if="app.industry=='Facilitator'">
    <checkbox-plain v-model="app.requirements.riskPolicies">
      Copy of Rist, AML, ATF, Fraud Policies
      <template v-slot:more></template>
    </checkbox-plain>
    </validator>
    <validator :value="app.requirements.subApplication" reqs="required" v-if="app.industry=='Facilitator'">
    <checkbox-plain v-model="app.requirements.subApplication">
      Copy of Sub-Merchant Application
      <template v-slot:more></template>
    </checkbox-plain>
    </validator>
    <validator :value="app.requirements.subAgreement" reqs="required" v-if="app.industry=='Facilitator'">
    <checkbox-plain v-model="app.requirements.subAgreement">
      Copy of Sub-Merchant Agreement
      <template v-slot:more></template>
    </checkbox-plain>
    </validator>
    <validator :value="app.requirements.listDba" reqs="required" v-if="app.industry=='Facilitator'">
    <checkbox-plain v-model="app.requirements.listDba">
      List of All DBAs
      <template v-slot:more></template>
    </checkbox-plain>
    </validator>
  </section>
  <section>
    <h2>Conditional Documents</h2>
    <validator :value="app.requirements.processingStatement" reqs="">
    <checkbox-plain v-model="app.requirements.processingStatement">
      6 Months Processing Statement
      <template v-slot:more>6 most recent processing statements for company</template>
    </checkbox-plain>
    </validator>
    <validator :value="app.requirements.govId" reqs="">
    <checkbox-plain v-model="app.requirements.govId">
      Government Issued ID
      <template v-slot:more></template>
    </checkbox-plain>
    </validator>
    <validator :value="app.requirements.corporateStructure" reqs="">
    <checkbox-plain v-model="app.requirements.corporateStructure">
      Corporate Structure Diagram
      <template v-slot:more>Show parent and related companies and beneficial owners, directors and shareholders</template>
    </checkbox-plain>
    </validator>
    <validator :value="app.requirements.financialStatements" reqs="">
    <checkbox-plain v-model="app.requirements.financialStatements">
      Financial Statements
      <template v-slot:more>Most recent financial statements for company</template>
    </checkbox-plain>
    </validator>
    <validator :value="app.requirements.copyOfLicense" reqs="">
    <checkbox-plain v-model="app.requirements.copyOfLicense">
      Copy of License(s)
      <template v-slot:more>Provide if is a regulated business type</template>
    </checkbox-plain>
    </validator>
    <validator :value="app.requirements.pciDssCert" reqs="">
    <checkbox-plain v-model="app.requirements.pciDssCert">
      PCI DSS Certificate or Adherence
      <template v-slot:more>PCI DSS compliance certification</template>
    </checkbox-plain>
    </validator>
    <validator :value="app.requirements.padAuth" reqs="">
    <checkbox-plain v-model="app.requirements.padAuth">
      Sample PAD Authorization
      <template v-slot:more></template>
    </checkbox-plain>
    </validator>
    <validator :value="app.requirements.motoQuestionnaire" reqs="">
    <checkbox-plain v-model="app.requirements.motoQuestionnaire">
      Completed MOTO/Internet Questionnaire
      <template v-slot:more></template>
    </checkbox-plain>
    </validator>
    <validator :value="app.requirements.marketingMaterials" reqs="">
    <checkbox-plain v-model="app.requirements.marketingMaterials">
      Marketing Materials if no Website
      <template v-slot:more></template>
    </checkbox-plain>
    </validator>
    <validator :value="app.requirements.businessSummary" reqs="">
    <checkbox-plain v-model="app.requirements.businessSummary">
      Complete Business Summary
      <template v-slot:more></template>
    </checkbox-plain>
    </validator>
    <validator :value="app.requirements.personalGuarantee" reqs="">
    <checkbox-plain v-model="app.requirements.personalGuarantee">
      Personal Guarantee
      <template v-slot:more></template>
    </checkbox-plain>
    </validator>
  </section>
  <section v-if="app.industry!='Facilitator'">
    <h2>New Business <span></span>
      <span class="huge-checkbox website-checkbox">
      <label class="radio">
        <input type="radio" name="new" v-model="app.companyNew" :value="true" />
        <i></i>
        Yes
      </label>
      <label class="radio">
        <input type="radio" name="new" v-model="app.companyNew" :value="false" />
        <i></i>
        No
      </label>
      </span></h2>
    <validator :value="app.requirements.businessPlan" :reqs="app.companyNew=='1'?'required':''">
    <checkbox-plain v-model="app.requirements.businessPlan">
      Business Plan and Projections
      <template v-slot:more>Provide business plan with projections </template>
    </checkbox-plain>
    </validator>
    <validator :value="app.requirements.bankStatement2" :reqs="app.companyNew=='1'?'required':''">
    <checkbox-plain v-model="app.requirements.bankStatement2">
      Bank Statement or Signed Bank Letter
      <template v-slot:more>Provide bank statement for all beneficial owners, directors and shareholders</template>
    </checkbox-plain>
    </validator>
    <validator :value="app.requirements.businessSummary2" reqs="">
    <checkbox-plain v-model="app.requirements.businessSummary2">
      Complete Business Summary
      <template v-slot:more></template>
    </checkbox-plain>
    </validator>
  </section>
  <section>
    <h2>
      Website Requirements <span>Do you have a website?</span>
      <span class="huge-checkbox website-checkbox">
      <label class="radio">
        <input type="radio" name="web" v-model="app.hasWebsite" :value="'1'" disabled />
        <i></i>
        Yes
      </label>
      <label class="radio">
        <input type="radio" name="web" v-model="app.hasWebsite" :value="'0'" disabled />
        <i></i>
        No
      </label>
      </span>
    </h2>
    <template v-if="app.hasWebsite!='0'">
    <p class="section-intro">Website must be fully functional when applying and must contain:
      <label class="checkbox" :class="{checked: webAll}">
        <input type="checkbox" :checked="webAll" v-on:change="toggleWebAll" />
        <i></i>
        Select all
      </label>
    </p>
    <validator :value="app.requirements.webContact" reqs="required">
    <checkbox-plain v-model="app.requirements.webContact">
      Contact Information
      <template v-slot:more>Business name, address, telephone and email of company</template>
    </checkbox-plain>
    </validator>
    <validator :value="app.requirements.webCustService" reqs="boolean">
    <checkbox-plain v-model="app.requirements.webCustService">
      Customer Service Information
      <template v-slot:more>Telephone Number and Email</template>
    </checkbox-plain>
    </validator>
    <validator :value="app.requirements.webBilling" reqs="boolean" v-if="app.industry!='Facilitator'">
    <checkbox-plain v-model="app.requirements.webBilling">
      Billing Descriptor
      <template v-slot:more>Statement showing the billing descriptor which will appear</template>
    </checkbox-plain>
    </validator>
    <validator :value="app.requirements.webShipping" reqs="boolean" v-if="app.industry!='Facilitator'">
    <checkbox-plain v-model="app.requirements.webShipping">
      Shipping/Delivery Policy
      <template v-slot:more>Information on shipping/delivery time frames</template>
    </checkbox-plain>
    </validator>
    <validator :value="app.requirements.webReturn" reqs="boolean" v-if="app.industry!='Facilitator'">
    <checkbox-plain v-model="app.requirements.webReturn">
      Refund/Return Policy
      <template v-slot:more>Information on procedure for refunds/returns</template>
    </checkbox-plain>
    </validator>
    <validator :value="app.requirements.webCsInfo" reqs="boolean" v-else>
    <checkbox-plain v-model="app.requirements.webCsInfo">
      Customer Support Information
      <template v-slot:more></template>
    </checkbox-plain>
    </validator>
    <validator :value="app.requirements.webTerms" reqs="boolean">
    <checkbox-plain v-model="app.requirements.webTerms">
      Terms and Conditions
      <template v-slot:more>Complete terms and conditions</template>
    </checkbox-plain>
    </validator>
    <validator :value="app.requirements.webPrivacy" reqs="boolean">
    <checkbox-plain v-model="app.requirements.webPrivacy">
      Privacy Policy
      <template v-slot:more>Privacy statement</template>
    </checkbox-plain>
    </validator>
    <validator :value="app.requirements.webDescription" reqs="boolean">
    <checkbox-plain v-model="app.requirements.webDescription">
      Description of Goods/Services {{ app.industry=='Facilitator'?'offered to Sub-Merchants':'' }}
      <template v-slot:more>Complete description of goods/services offered</template>
    </checkbox-plain>
    </validator>
    <validator :value="app.requirements.webCurrency" reqs="boolean" v-if="app.industry!='Facilitator'">
    <checkbox-plain v-model="app.requirements.webCurrency">
      Transaction Currency
      <template v-slot:more>List all transaction currencies accepted</template>
    </checkbox-plain>
    </validator>
    <validator :value="app.requirements.webListProhibited" reqs="boolean" v-else>
    <checkbox-plain v-model="app.requirements.webListProhibited">
      List of restricted and prohibited merchant types
      <template v-slot:more></template>
    </checkbox-plain>
    </validator>
    <validator :value="app.requirements.webSsl" reqs="boolean">
    <checkbox-plain v-model="app.requirements.webSsl">
      SSL Secured
      <template v-slot:more>Secured payment page</template>
    </checkbox-plain>
    </validator>
    <validator :value="app.requirements.webUrlList" reqs="boolean">
    <checkbox-plain v-model="app.requirements.webUrlList">
      URL List {{ app.industry=='Facilitator'?'of all DBAs':'' }}
      <template v-slot:more>List of all URLs that will be accepting payments</template>
    </checkbox-plain>
    </validator>
    <validator :value="app.requirements.webUser" reqs="boolean" v-if="app.industry!='Facilitator'">
    <checkbox-plain v-model="app.requirements.webUser">
      Username and Password
      <template v-slot:more>Non-expiring username and password for each site</template>
    </checkbox-plain>
    </validator>
    <validator :value="app.requirements.webOnlineApp" reqs="boolean" v-else>
    <checkbox-plain v-model="app.requirements.webOnlineApp">
      Online application collecting all information
      <template v-slot:more></template>
    </checkbox-plain>
    </validator>
    </template>
  </section>
  <ul class="notes">
    <li>For assistance, please contact {{companydata.applicationEmail}} or call {{companydata.servicesTel}}</li>
    <li>Additional requirements may apply</li>
    <li>Ensure that all above requested items are complete when submitted</li>
    <li>Please submit/upload all requested items on the last page of application.</li>    
  </ul>
  <div class="buttons">
    <button class="btn btn-large btn-success" v-on:click.prevent="nextStep">Next Step</button>
  </div>
 
</form>
</template>


<script>
export default {
  props: ["app"],
  computed: {
    webAll() {
      var all=true;
      var fields=["webContact", "webCustService", "webBilling", "webShipping", 
          "webReturn", "webTerms", "webPrivacy", "webDescription", 
          "webCurrency", "webSsl", "webUrlList", "webUser"];
      for(var i in fields) {
        if (!(this.app.requirements[fields[i]]*1))
          all=false;
      }
      return all;
    },
  },
  watch: {
    'app.hasWebsite'() {
      if (this.app.hasWebsite) {
        var fields=["webContact", "webCustService", "webBilling", "webShipping", 
            "webReturn", "webTerms", "webPrivacy", "webDescription", 
            "webCurrency", "webSsl", "webUrlList", "webUser"];
        for(var i in fields) {
          this.$set(this.app.requirements, fields[i], false);
        }
      } 
    },
  },
  data() { return {
    companydata: appData.company,
  }},
  methods: {
    nextStep() {
      if (!this.validate())
        return stopLoading("Please fill all required fields", "error");
      this.$emit("next");      
    },
    toggleWebAll() {
      var fields=["webContact", "webCustService", "webBilling", "webShipping", 
          "webReturn", "webTerms", "webPrivacy", "webDescription", 
          "webCurrency", "webSsl", "webUrlList", "webUser"];
      var wasSelected=this.webAll;
      for(var i in fields) {
        this.$set(this.app.requirements, fields[i], !wasSelected);
      }
    },
  },
  mixins: [formValidation.mixin]
}
</script>
