<template>
<ul class="multiselect">
  <li v-for="(option, oi) in options || []">
    <label :class="{checked: actualValue.includes((option || '').replace('!time', ''))}">
      <input :disabled="readonly || option.indexOf('!disabled')!=-1" type="checkbox" :value="(option || '').replace('!time', '')" v-model="actualValue" v-on:change="update" />
      <span></span>
      <i class="bn-con" v-html="((labels || [])[oi] || option).replace('!disabled', '').replace('!time', '')"></i>
      <input type="text" :value="time" v-on:input="$emit('update:time', $event.target.value)" v-if="(option || '').indexOf('!time')!=-1" style="display: inline-block; width: 60px; padding: 0 2px" :disabled="!actualValue.includes((option || '').replace('!time', ''))" />

    </label>
  </li>
  <li class="other" v-if="hasOther">
    <label :class="{checked: isOther}">
      <input :disabled="readonly" type="checkbox" value="Other" v-model="isOther" v-on:change="updateOther"><span></span>
      Other
    </label>
    <validator :value="other" :reqs="isOther?'required':''">
      <input v-model="actualOther" v-on:input="updateOther" :disabled="!isOther" />
    </validator>
  </li>
</ul>
</template>

<script>
export default {
  props: ["value", "options", "other", "hasOther", "readonly", "labels", "onlyone", "time"],
  data: function() { return {
    actualValue: null,
    actualOther: "",
    isOther: false,
  }},
  watch: {
    value: {
      immediate: true,
      handler: function() {
        this.actualValue=this.value || [];
      }
    },
    other: {
      immediate: true,
      handler: function() {
        this.actualOther=this.other;
        this.isOther=!!this.other;
      }
    },
  },
  methods: {
    update: function($event) {
      if (this.onlyone) {
        this.actualValue=[$event.target.value];
        this.isOther=false;
        this.$emit("update:other", "");
      }
      this.$emit("input", this.actualValue);
    },
    updateOther: function() {
      if (this.onlyone && this.isOther) {
        this.actualValue=[];
        this.$emit("input", this.actualValue);
      }
      this.$emit("update:other", this.isOther?this.actualOther:"");
    },
  },
}
</script>
