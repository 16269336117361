<template>
<div class="preform-outer">
<div class="preformbg"></div>
<div class="appf preform ncontent">
<div class="wcontent">
  <div class="cb-logo" :style="{'background-image': 'url(&quot;/files/company/'+companydata.preqHeadLogo+'&quot;)' }">CardBiller</div>
  <h1>Merchant Qualification</h1>
  <div class="form-info">
    <p>{{ companydata.preqHeadText }}</p>
    <p><strong>{{ companydata.preqHeadEmail }}</strong></p>
    <p><strong>{{ companydata.preqHeadPhone }}</strong></p>
  </div>
  <p class="form-intro">
    {{ companydata.preqHeadDesc }}
  </p>
  <div class="temp-link">
    <label>Guest access to this application:</label>
    <span v-if="!link">
      <button v-on:click.prevent="generateLink">Generate Link</button>
    </span>
    <a v-else :href="link.url" target="_blank" v-on:click.prevent="copyLink">{{ link.url }}</a>
  </div>  
  <ul class="pqf-progress">
    <li v-for="(stepInfo) in steps" :class="{past: step>stepInfo.index, current: step==stepInfo.index}">
      <span>{{ stepInfo.index }}. {{ stepInfo.title }}</span>
      <span class="stat ip">In Progress</span>
      <span class="stat co">Complete</span>
    </li>
  </ul>
  
  <form class="preq-form" v-if="step<=4">
    <section class="pqcat-select" v-if="step==0">
      <h3>To get started, tell us what type of merchant you are:</h3>
      <validator :value="item.industry" reqs="required">
      <ul class="pqcat-list">
        <li class="card-select" :class="{selected: mainType=='cp'}"><a href="" v-on:click.prevent="mainType='cp'">Card Present (Point of Sale)</a></li>
        <li class="card-select" :class="{selected: mainType=='cnp'}"><a href="" v-on:click.prevent="mainType='cnp'">Card not Present (E-Commerce)</a></li>
        <li v-for="ind in industries" :class="ind.class+' '+(item.industry==ind.label?'selected':'')+' '+(indAvail(ind)?'':'disabled')"><a href="" v-on:click.prevent="indAvail(ind) && (item.industry=ind.label)">{{ ind.label }}</a></li>
      </ul>
      </validator>
    </section>
    <section v-if="step==2">
      <h2>
        <em>Company Information</em>
      </h2>
      <label class="checkbox maincheck" :class="{checked: item.companyNew}">
        <input type="checkbox" v-model="item.companyNew" /><span></span>
        This is a <strong>New Business</strong>
      </label>
      <ul class="preform-sp3">
        <li>
          <label>Legal Business Name</label>
          <validator :value="item.companyName" reqs="required legalName">
            <input type="text" v-model="item.companyName" />
          </validator>
        </li>
        <li>
          <label>Trade Name/DBA</label>
          <validator :value="item.companyNameTrade" reqs="legalName">
            <input type="text" v-model="item.companyNameTrade" />
          </validator>
        </li>
        <li class="">
          <label>Business Start Date</label>
          <validator :value="item.companyDate" reqs="required">
            <datepicker v-model="item.companyDate" :future="false" />
          </validator>
        </li>
      </ul>
      <ul class="preform-sp3">
        <li style="width: 31.3333333%">
          <label>Address</label>
          <validator :value="item.companyAddress" reqs="required address">
            <input type="text" v-model="item.companyAddress" />
          </validator>
        </li>
        <li style="width: 13.6666666%">
          <label>City</label>
          <validator :value="item.companyAddressCity" reqs="required city">
            <input type="text" v-model="item.companyAddressCity" />
          </validator>
        </li>
        <li style="width: 13.6666666%">
          <label>Postal Code</label>
          <validator :value="item.companyAddressPostcode" reqs="required postcode2">
            <formel-zipcode v-model="item.companyAddressPostcode" />
          </validator>
        </li>
        <li style="width: 31.3333333%">
          <label>Province</label>
          <validator v-if="item.companyAddressCountry == 'CA' || item.companyAddressCountry == 'US'" :value="item.companyAddressState" reqs="required">
            <province-select v-model="item.companyAddressState" :country="item.companyAddressCountry" />
          </validator>
          <validator v-else>
            <input type="text" v-model="item.companyAddressState" />
          </validator>
        </li>
      </ul>
      <ul class="preform-sp3">
        <li>
          <label>Country</label>
          <validator :value="item.companyAddressCountry" reqs="required postcode">
            <div class="combo-select">
            <dyn-select :options="validCountries" v-model="item.companyAddressCountry" />
            </div>
          </validator>
        </li>
        <li>
          <label>Phone Number</label>
          <validator :value="item.companyPhone" reqs="required phone">
            <input type="text" v-model="item.companyPhone" />
          </validator>
        </li>
        <li>
          <label>Company Email</label>
          <validator :value="item.companyEmail" reqs="required email">
            <input type="text" v-model="item.companyEmail" />
          </validator>
        </li>
      </ul>
    </section>
    <section v-if="step==1">
      <h2>
        <em>Contact information</em>
        <!--<span> - ALL Beneficial Owners</span>-->
      </h2>
      <ul v-for="(ubo, ui) in item.ubos" class="preform-sp3">
        <li class="remove" v-if="item.ubos.length>1 && ui">
          <a class="remove" href="" v-on:click.prevent="removeUbo(ui)">&times;</a>
        </li>
        <li>
          <label><strong>Contact {{ ui+1 }}</strong> First Name</label>
          <validator :value="ubo.firstName" reqs="required city">
            <input type="text" v-model="ubo.firstName" />
          </validator>
        </li>
        <li>
          <label><strong>Contact {{ ui+1 }}</strong> Last Name</label>
          <validator :value="ubo.lastName" reqs="required city">
            <input type="text" v-model="ubo.lastName" />
          </validator>
        </li>
        <li>
          <label><strong>Contact {{ ui+1 }}</strong> E-mail</label>
          <validator :value="ubo.email" reqs="required email">
            <input type="text" v-model="ubo.email" />
          </validator>
        </li>
        <li>
          <label><strong>Contact {{ ui+1 }}</strong> Phone</label>
          <validator :value="ubo.phone" reqs="required phone">
            <input type="text" v-model="ubo.phone" />
          </validator>
        </li>
      </ul>
      <div class="new-ubo" v-if="item.ubos.length<5">
        <a href="" v-on:click.prevent="newUbo">Add Additional Contact</a>
      </div>
    </section>
    <section v-if="step==3">
      <h2>
        <em>Business Information</em>
      </h2>
<!--      <label class="checkbox maincheck" :class="{checked: item.hasWebsite}">
        <input type="checkbox" v-model="item.hasWebsite" /><span></span>
        Does your company have a website?
      </label>-->
    <div class="pgf-main-cb type2">
      <p class="intro">Does this business have a website that accepts payments?</p>
      <validator :value="item.hasWebsite" reqs="required">
      <ul class="toggle-main">
        <li class="disabled">
          <label :class="{checked: item.hasWebsite==='1'}">
            <input type="radio" name="hasParent" value="1" v-model="item.hasWebsite" disabled>
            <i></i>
            <strong>Yes</strong>
            <!--<span>(provide details of applying company and parent company below)</span>-->
          </label>
        </li>
        <li class="disabled">
          <label :class="{checked: item.hasWebsite==='0'}">
            <input type="radio" name="hasParent" value="0" v-model="item.hasWebsite" disabled>
            <i></i>
            <strong>No</strong>
            <!--<span>(only provide details of applying company company below)</span>-->
          </label>
        </li>
      </ul>
      </validator>
    </div>
      
      <ul class="preform-sp3 tol" v-if="item.hasWebsite*1">
        <li>
          <label>Website URL</label>
          <validator :value="item.website" reqs="required url">
            <input type="text" v-model="item.website" />
          </validator>
        </li>
        <li>
          <label>Is Website Live?</label>
          <validator :value="item.websiteBeta" reqs="required" class="reds">
            <formel-toggle-date :future="true" v-model="item.websiteBeta" custom="" default="No" customLabel="No (If not when)" defaultLabel="Yes" />
          </validator>
        </li>
      </ul>
      <ul class="preform-sp3 tol" v-if="item.hasWebsite*1">
        <li>
          <label>Username</label>
          <validator :value="item.websiteUsername" reqs="">
            <input type="text" v-model="item.websiteUsername" />
          </validator>
        </li>
        <li>
          <label>Password</label>
          <validator :value="item.websitePassword" reqs="">
            <input type="text" v-model="item.websitePassword" />
          </validator>
        </li>
      </ul>
      <ul>
        <li class="fullw1">
          <label>Full Description of Products and/or Services</label>
          <validator :value="item.businessDescription" reqs="required char250">
            <textarea v-model="item.businessDescription"></textarea>
          </validator>
        </li>
      </ul>
    </section>
    <section v-if="step==3">
      <h2>
        <em>Business Details</em>
      </h2>
      <ul class="preform-sp3">
        <li class="wq">
          <label>Monthly Limit Requested</label>
          <validator :value="item.volume" reqs="required num10">
            <formel-money-amount v-model="item.volume" />
          </validator>
        </li>
        <li class="wq">
          <label>Highest Ticket</label>
          <validator :value="item.maxTransaction" reqs="required num10">
            <formel-money-amount v-model="item.maxTransaction" />
          </validator>
        </li>
        <li class="wq">
          <label>Average Ticket</label>
          <validator :value="item.avgTransaction" reqs="required num10">
            <formel-money-amount v-model="item.avgTransaction" />
          </validator>
        </li>
        <li class="wq">
          <label>Lowest Ticket</label>
          <validator :value="item.minTransaction" reqs="required num10">
            <formel-money-amount v-model="item.minTransaction" />
          </validator>
        </li>
      </ul>
      <ul class="preform-sp3">
        <li class="wf">
          <label>Are you currently processing?</label>
          <validator :value="item.acceptsCards" reqs="required">
            <formel-toggle v-model="item.acceptsCards" />
          </validator>
        </li>
        <li class="wf">
          <label>Have you previously processed?</label>
          <validator :value="item.acceptedCards" reqs="required">
            <formel-toggle v-model="item.acceptedCards" />
          </validator>
        </li>
        <li class="wf">
          <label>Do you offer an affiliate program?</label>
          <validator :value="item.affiliate" reqs="required">
            <formel-toggle v-model="item.affiliate" :class="{disabled: mainType=='cp'}" :style="mainType=='cp'?'pointer-events: none; opacity: 0.5':''" />
          </validator>
        </li>
        <li class="wf">
          <label>Has the company or any owner(s) ever been terminated and/or placed on the TMF/Match List?</label>
          <validator :value="item.violationDetails" reqs="required" class="noinput">
            <formel-toggle v-model="item.violationDetails" />
          </validator>
        </li>
      </ul>
      <h2>
        <em>Payment Acceptance</em>
        <span class="total-percent">{{ (item.methodECommerce*1 || 0) + (item.methodPOS*1 || 0) + (item.methodMPOS*1 || 0) + (item.methodMCommerce*1 || 0) + (item.methodMOTO*1 || 0) + (item.methodApp*1 || 0) }}%</span>
      </h2>
      <validator class="percentSplit" :value="[item.methodECommerce, item.methodMCommerce, item.methodPOS, item.methodMOTO, item.methodMPOS, item.methodApp]" reqs="sum100">
        <ul class="preform-sp3 preform-pi">
          <li :class="{disabled: mainType!='cnp'}">
            <label>E-Commerce %</label>
            <input type="text" v-model="item.methodECommerce" :disabled="mainType!='cnp'" />
          </li>
          <li v-if="false">
            <label>M-Commerce %</label>
            <input type="text" v-model="item.methodMCommerce" />
          </li>
          <li :class="{disabled: mainType!='cp'}">
            <label>Card Present %</label>
            <input type="text" v-model="item.methodPOS" :disabled="mainType!='cp'" />
          </li>
          <li :class="{disabled: mainType!='cnp'}">
            <label>MOTO %</label>
            <input type="text" v-model="item.methodMOTO" :disabled="mainType!='cnp'" />
          </li>
          <li :class="{disabled: mainType!='cp'}">
            <label>M-POS %</label>
            <input type="text" v-model="item.methodMPOS" :disabled="mainType!='cp'" />
          </li>
          <li :class="{disabled: mainType!='cnp'}">
            <label>In-App %</label>
            <input type="text" v-model="item.methodApp" :disabled="mainType!='cnp'" />
          </li>
        </ul>
      </validator>
    </section>
    <section v-if="step==4">
      <h2>
        <em>Additional Notes</em>
      </h2>
      <label class="forta"><span>Use this section to include any additional notes or comments</span></label>
      <validator :value="item.additionalComments" reqs="char250">
        <div class="single-field">
          <textarea class="more-details" v-model="item.additionalComments"></textarea>
        </div>
      </validator>
    </section>
    <section class="upload" v-if="step==4">
      <h2><em>Upload documents</em></h2>
      <p class="page-7-right-text">Only the following file types are accepted:<br>jpg, jpeg, gif, tif, tiff, pdf</p>
      <p class="intro">Please use the section below to upload documents:</p>
      <div class="clearfx"></div>
      <validator :value="files[0]" reqs="">
        <checkbox-file v-model="files[0]">
          6 Month Processing Statements
          <template v-slot:more>6 Most Recent Processing Statements for Company</template>
        </checkbox-file>
      </validator>
    </section>
    <ul class="notes" v-if="step==4">
      <!--<li>For assistance, please contact {{companydata.applicationEmail}} or call {{companydata.servicesTel}}</li>
      <li>Ensure this form is completed in full.  Incomplete forms are not accepted</li>-->
      <li>Additional requirements may apply</li>
      <li>Please submit all requested items on the following page</li>
      <li>By completing this form, you agree that your personal data may be shared with third parties for on boarding and Know-Your-Customer purposes.</li>
    </ul>
    <!--<div class="consent">
      <p>By completing this form, you agree that your personal data may be shared with third parties for on boarding and Know-Your-Customer purposes.</p>
    </div>-->
    <div class="buttons">
      <button class="btn btn-large btn-primary btn-back" v-on:click.prevent="prevStep" v-if="step>=1">Previous Step</button>
      <button v-if="step<4" class="btn btn-large btn-primary" v-on:click.prevent="nextStep">Next Step</button>
      <button v-else class="btn btn-large btn-primary" v-on:click.prevent="send" :disabled="sending">Submit</button>
    </div>

  </form>
  <div class="pre-done" v-else-if="step==5">
<!--     <div class="pd-title">Prequalification Form</div> -->
    <h1>Thank you for submitting your application!</h1>
    <p>One of our account Representatives will contact you shortly.</p>
    <a v-if="!guest" href="" class="btn btn-large btn-primary btn-black">Back to Dashboard</a>
  </div>
<!--  <a v-if="step==5" class="back-button" href="https://secure.chammp.net/dashboard">Dashboard</a>-->
<!--   <div class="version">v1.0</div> -->
</div>
</div>
</div>
</template>

<script>
export default {
  props: ["guest", "solutionId"],
  data() { return {
    mainType: null,
    step: 0,
    steps: [
      {index: 1, title: "Contact Info"},
      {index: 2, title: "Company Info"},
      {index: 3, title: "Business Info"},
      {index: 4, title: "Notes / Uploads"},
      {index: 5, title: "Finalize"},
    ],
    industries: [
      {class: "pc01", label: "Hospitality", type: 'cp'},
      {class: "pc02", label: "Retail", type: 'cp'},
      {class: "pc03", label: "Spas / Salons", type: 'cp'},
      {class: "pc04", label: "Taxis & Deliveries", type: 'cp'},
      {class: "pc05", label: "Convenience", type: 'cp'},
      {class: "pc06", label: "Gas / Oil", type: 'cp'},
      {class: "pc07", label: "E-Commerce", type: 'cnp'},
      {class: "pc08", label: "Professional Services"},
      {class: "pc09", label: "Tourism"},
      {class: "pc10", label: "B2B"},
      {class: "pc11", label: "Groups & Organizations", type: 'cp'},
      {class: "pc12", label: "Utilities", type: 'cp'},
      {class: "other", label: "Other"},
    ],
    sending: false,
    item: window.existingItem || {
      status: "A00",
      industry: "",
      companyNew: false,
      submitDate: "CURRENT_TIMESTAMP",
      os: "CURRENT_OS",
      useragent: "CURRENT_BROWSER",
      ip: "CURRENT_IP",
      source: appData.currentUser.id,
      solution: null,
      ubos: [
        {}],
    },
    files: [ null ],
    companydata: appData.company,
    link: appData.tempLinkUrl?{url: appData.tempLinkUrl}:null,
  }},

  computed: {
    validCountries() {
      var countries = [];
      var lists = [];
      if(this.solution.businessFormation) {
        lists = window.fullCountries.filter(a => {
          return this.solution.businessFormation.includes(a.id)
        })
      }

      lists.forEach(item => {
        countries.push({
          label: item.text,
          value: item.id
        })
      })

      return countries
    },
  },

  async created() {
    this.solution = (await api.get('Solution', this.solutionId)).item
  },

  methods: {
    indAvail(ind) {
      if (!this.mainType)
        return false;
      if (!ind.type)
        return true;
      return ind.type==this.mainType;
    },
    newUbo() {
      this.item.ubos.push({
      });
    },
    removeUbo(index) {
      this.item.ubos.splice(index, 1);
    },
    nextStep() {
      if (!this.validate())
        return stopLoading("Please fill all required fields", "error");
      if (this.step==0) {
        if (this.mainType=='cp') {
          this.$set(this.item, 'hasWebsite', '0');
          this.$set(this.item, 'affiliate', '0');
        } else if (this.mainType=='cnp') {
          this.$set(this.item, 'hasWebsite', '1');
        }
      }
      this.step++;
    },
    prevStep() {
      if (this.step)
        this.step--;
    },
    send: async function() {
      if (!this.validate())
        return stopLoading("Please fill all required fields", "error");
      startLoading(2);
      this.sending=true;
      this.item.applicationId="APP_ID_GEN_PREQUAL";
      var result=await api.create("Prequalification", this.item);
      progressLoading(1);
      this.sending=false;
      if (result.success) {
        await api.upload("Prequalification", result.id, this.files[0]);
      }
      if (result.success)
        await api.action("Prequalification", result.id, "negative");
      stopLoading();
      this.step=5;
    },
    generateLink: async function() {
      if (!this.link) {
        var result=await api.action("TempLink", "new", "generate", {type: "Prequalification", solution: this.item.solution});
        this.link=result.item;
        this.link.url=result.action.link;
      }      
    },
    copyLink() {
      copyToClipboard.text=this.link.url;
      copyToClipboard.doCopy();
    },
  },
  mixins: [formValidation.mixin]
}
</script>
