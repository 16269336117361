<template>
<form class="edit-system search-form">
  <div class="content-table-container">
    <table class="content-table">
      <thead>
        <tr>
          <th style="width: 70%">Options</th>
          <th style="width: 10%">Mode</th>
          <th style="width: 10%">Selected</th>
          <th style="width: 10%">&nbsp;</th>
        </tr>
        <tr>
          <th class="spacer" colspan="2"></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colspan="2">
            <div style="display: flex; align-items: center">
              Template:
              <input type="text" v-model="currentTemplate.name" style="display: block; width: 100%; margin-left: auto; text-align: left;" />
            </div>
          </td>
          <td>
            <dyn-select v-model="templateId" :options="currentTemplates" />
          </td>
          <td>
            <a v-if="templateId" class="remove" href="" v-on:click.prevent="deleteTemplate">&times;</a>
          </td>
        </tr>
        <tr v-for="(item, index) in options">
          <td class="text-left">
            <input type="text" v-model="item.name" style="text-align: left" />
          </td>
          <td>
            <dyn-select v-model="item.mode" :options="[{value: 'Toggle', label: 'Toggle'}, {value: 'Count', label: 'Count'}]" />
          </td>
          <td>
            <dyn-select v-if="item.id" v-model="currentTemplate.selected[item.id]" :options="item.mode=='Count'?optsCount:optsToggle" />
          </td>
          <td>
            <a class="remove" href="" v-on:click.prevent="deleteOption(index)">&times;</a>
          </td>
        </tr>
        <tr class="new-row">
          <td colspan="5">
            <a class="add" href="" v-on:click.prevent="newOption">+</a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="buttons">
    <button class="btn btn-large btn-primary" v-on:click.prevent="updateSettings">Submit</button>
  </div>  
</form>
</template>


<script>
export default {
  data() { return {
    optsToggle: [{value: 'No', label: 'No'}, {value: 'Yes', label: 'Yes'}],
    optsCount: [{value: '0', label: '0'}, {value: '1', label: '1'},
                {value: '2', label: '2'}, {value: '3', label: '3'},
                {value: '4', label: '4'}, {value: '5', label: '5'},
                {value: '6', label: '6'}, {value: '7', label: '7'},
                {value: '8', label: '8'}, {value: '9', label: '9'},
                {value: '10', label: '10'}, {value: '11', label: '11'},
                {value: '12', label: '12'}, {value: '13', label: '13'},
                {value: '14', label: '14'}, {value: '15', label: '15'},
                {value: '16', label: '16'}, {value: '17', label: '17'},
                {value: '18', label: '18'}, {value: '19', label: '19'},
                {value: '20', label: '20'}],
    options: [],
    origOptions: [],
    templates: [],
    templateId: 0,
    currentTemplate: {
      selected: {}},
    logoFile: null,
    basePath: "",
  }},
  watch: {
    templateId: {
      immediate: true,
      handler() {
        var that=this;
        if (this.templateId*1)
          this.currentTemplate=this.templates.find(function(a) {
            return a.id==that.templateId;
          }) || {
            selected: {}};
        else
          this.currentTemplate={
            selected: {}};
      },
    },
    templates: {
      immediate: true,
      handler() {
        var that=this;
        if (this.templateId*1)
          this.currentTemplate=this.templates.find(function(a) {
            return a.id==that.templateId;
          }) || {
            selected: {}};
        else
          this.currentTemplate={
            selected: {}};
      },
    }
  },
  computed: {
    currentTemplates() {
      var result=this.templates.map(function(a) {
        return {value: a.id, label: a.name};
      });
      result.unshift({value: 0, label: "(new)"});
      return result;
    },
  },
  mounted() {
    this.refresh();
    this.refreshTemplates();
  },
  methods: {
    refreshTemplates: async function() {
      var data=await api.list("CompanyModTemplate", {}, ["id:ASC"]);
      this.templates=JSON.parse(JSON.stringify(data.items));
    },
    refresh: async function() {
      var data=await api.list("CompanyModule", {}, ["id:ASC"]);
      this.options=JSON.parse(JSON.stringify(data.items));
      this.origOptions=JSON.parse(JSON.stringify(data.items));
    },
    updateSettings: async function() {
      startLoading();

      var optionsToDelete=[];
      for(var index in this.origOptions) {
        var item=this.origOptions[index];
        if (!this.options.find(function(e) { return e.id==item.id; }))
          optionsToDelete.push(item);
      }
      var optionsToAdd=[];
      var optionsToUpdate=[];
      for(var index in this.options) {
        var item=this.options[index];
        var foundItem=this.origOptions.find(function(e) { return e.id==item.id; });
        if (!foundItem)
          optionsToAdd.push(item);
        else if (JSON.stringify(foundItem)!=JSON.stringify(item))
          optionsToUpdate.push(item);
      }
      await this.updateOptionsApi(optionsToUpdate, optionsToAdd, optionsToDelete);
      this.origOptions=JSON.parse(JSON.stringify(this.options));
      
      if (this.currentTemplate.id)
        await api.update("CompanyModTemplate", this.currentTemplate.id, this.currentTemplate);
      else
        this.templateId=(await api.create("CompanyModTemplate", this.currentTemplate)).id;
      
      stopLoading("Merchant options updated");
      this.refreshTemplates();
      this.refresh();
      location.reload();
    },
    updateOptionsApi: async function(update, add, remove) {
      startLoading();
      for(var i in update) {
        var item=update[i];
        await api.update("CompanyModule", item.id, item);
      }
      for(var i in add) {
        var item=add[i];
        await api.create("CompanyModule", item);
      }
      for(var i in remove) {
        var item=remove[i];
        await api.delete("CompanyModule", item.id);
      }
    },
    newOption() {
      this.options.push({name: ""});
    },
    deleteOption(index) {
      if (!confirm("Are you sure?"))
        return;
      this.options.splice(index, 1);
    },
    deleteTemplate: async function() {
      if (!confirm("Are you sure?"))
        return;
      startLoading();
      await api.delete("CompanyModTemplate", this.currentTemplate.id);
      this.templateId=null;
      
      stopLoading("Merchant modules template deleted");
      this.refreshTemplates();      
    },
  },  
}
</script>
