<template>
<div>
  <ul class="acc-results">
    <li class="">
      <h4>
        <span class="label">Facilitators</span>
        <span class="count">{{ facilitators?facilitators.length:0 }} User(s)</span>
      </h4>
      <div class="acc-list" style="display: block">
        <table>
          <thead>
            <tr>
              <th>MMID</th>
              <th style="text-align: left">Name</th>
              <th style="text-align: left">Dba Name</th>
              <th style="text-align: left">Username</th>
              <th style="text-align: left">Password</th>
              <th style="text-align: left">IP Address</th>
              <th style="text-align: left">Last Connection</th>
              <th style="text-align: left">Status</th>
              <th style="text-align: left">Solution</th>
              <th style="text-align: left">Mode</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in facilitators">
              <td style="text-align: left">
                <span v-if="!item.editing">{{ item.apiId }}</span>
                <input v-else v-model="item.apiId" />
              </td>
              <td style="text-align: left">
                <span v-if="!item.editing">{{ item.name }}</span>
                <input v-else v-model="item.name" />
              </td>
              <td style="text-align: left">
                <span v-if="!item.editing">{{ item.dbaName }}</span>
                <input v-else v-model="item.dbaName" />
              </td>
              <td style="text-align: left">
                <span v-if="!item.editing">{{ item.username }}</span>
                <input v-else v-model="item.username" />
              </td>
              <td style="text-align: left">
                <span v-if="!item.editing">{{ item.password }}</span>
                <input v-else v-model="item.password" />
              </td>
              <td style="text-align: left">
                <span v-if="!item.editing">{{ item.ipaddress }}</span>
                <input v-else v-model="item.ipaddress" />
              </td>
              <td style="text-align: left">
                <span>{{ formatDate(item.lastConnection, "YYYY-MM-DD hh:mm:ss") || "never" }}</span>
              </td>
              <td style="text-align: left">
                <span>{{ item.merchantStatus }}</span>
              </td>
              <td style="text-align: left">
                <span v-if="!item.editing">{{ solution(item.company) }}</span>
                <dyn-select v-else v-model="item.company" :options="solutions" />
              </td>
              <td style="text-align: left">
                <span v-if="!item.editing">{{ item.status }}</span>
                <dyn-select v-else v-model="item.status" :options="item.disabled?statusesDis:statuses" />
              </td>
            
              <td>
                <a href="" v-if="!item.editing" v-on:click.prevent="item.editing=true">Edit</a>
                <a href="" v-else v-on:click.prevent="saveItem(item)">Save</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </li>
    <li class="">
      <h4>
        <span class="label">Other</span>
        <span class="count">{{ others?others.length:0 }} User(s)</span>
      </h4>
      <div class="acc-list" style="display: block">
        <table>
          <thead>
            <tr>
              <th style="text-align: left">Origin</th>
              <th style="text-align: left">FLYID</th>
              <th style="text-align: left">Name</th>
              <th style="text-align: left">Dba Name</th>
              <th style="text-align: left">Username</th>
              <th style="text-align: left">Password</th>
              <th style="text-align: left">IP Address</th>
              <th style="text-align: left">Last Connection</th>
              <th style="text-align: left">Solution</th>
              <th style="text-align: left">MSG</th>
              <th style="text-align: left">UPD</th>
              <th style="text-align: left">Mode</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="item in others">
            <tr>
              <td style="text-align: left">
                <span v-if="!item.editing">{{ item.origin }}</span>
                <dyn-select v-else v-model="item.origin" :options="origins" />
              </td>
              <td style="text-align: left">
                <span v-if="!item.editing">{{ item.apiId }}</span>
                <input v-else v-model="item.apiId" />
              </td>
              <td style="text-align: left">
                <span v-if="!item.editing">{{ item.name }}</span>
                <input v-else v-model="item.name" />
              </td>
              <td style="text-align: left">
                <span v-if="!item.editing">{{ item.dbaName }}</span>
                <input v-else v-model="item.dbaName" />
              </td>
              <td style="text-align: left">
                <span v-if="!item.editing">{{ item.username }}</span>
                <input v-else v-model="item.username" />
              </td>
              <td style="text-align: left">
                <span v-if="!item.editing">{{ item.password }}</span>
                <input v-else v-model="item.password" />
              </td>
              <td style="text-align: left">
                <span v-if="!item.editing">{{ item.ipaddress }}</span>
                <input v-else v-model="item.ipaddress" />
              </td>
              <td style="text-align: left">
                <span>{{ formatDate(item.lastConnection, "YYYY-MM-DD hh:mm:ss") || "never" }}</span>
              </td>
              <td style="text-align: left">
                <span v-if="!item.editing">{{ solution(item.company) }}</span>
                <dyn-select v-else v-model="item.company" :options="solutions" />
              </td>
              <td style="text-align: left">
                <input type="checkbox" :disabled="!item.editing" v-model="item.messaging" style="width: auto; height: auto;" />
              </td>
              <td style="text-align: left">
                <input type="checkbox" :disabled="!item.editing" v-model="item.updates" style="width: auto; height: auto;" />
              </td>
              <td style="text-align: left">
                <span v-if="!item.editing">{{ item.status }}</span>
                <dyn-select v-else v-model="item.status" :options="statuses" />
              </td>
              <td>
                <a href="" v-if="!item.editing" v-on:click.prevent="item.editing=true">Edit</a>
                <a href="" v-else v-on:click.prevent="saveItem(item)">Save</a>
              </td>
            </tr>
            <tr v-if="item.editing">
              <td colspan="12">
                <input type="text" placeholder="Notification URL" v-model="item.notificationUrl" />
              </td>
              <td>
              </td>
            </tr>
            </template>
            <tr class="new">
              <td colspan="13">
                <a href="" v-on:click.prevent="newOther">+</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </li>
    <li class="">
      <h4>
        <span class="label">History</span>
      </h4>
      <div class="acc-list" style="display: block">
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th style="text-align: left">Source Name</th>
              <th style="text-align: left">Source ID</th>
              <th style="text-align: left">Result</th>
              <th style="text-align: left">Message</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in history">
              <td style="text-align: left">
                <span>{{ formatDate(item.created, "YYYY-MM-DD hh:mm:ss") }}</span>
              </td>
              <td style="text-align: left">
                <span>{{ item.user && item.user.name }}</span>
              </td>
              <td style="text-align: left">
                <span>{{ item.user && item.user.apiId }}</span>
              </td>
              <td style="text-align: left">
                <span>{{ item.result }}</span>
              </td>
              <td style="text-align: left; width: 33%" v-on:click="item.expanded=!item.expanded">
                <span>{{ item.expanded || (item.resultText || "").length<=120?item.resultText:(item.resultText+"").substring(0, 120)+"..." }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </li>
  </ul>
</div>
</template>

<script>
export default {
  data() { return {
    statuses: [
      {value: "Disabled", label: "Disabled"},
      {value: "Enabled", label: "Enabled"},
      {value: "Test", label: "Test"},
    ],
    origins: [
      {value: "Partner", label: "Partner"},
      {value: "Facilitator", label: "Facilitator"},
    ],
    statusesDis: [
      {value: "Disabled", label: "Disabled"},
    ],
    facilitators: [],
    others: [],
    history: [],
    solutions: [],
  }},
  mounted() {
    this.loadData();
    this.loadHistory();
  },
  methods: {
    loadData: async function() {
      var userApiPromise=api.list("UserApi");
      var merchantsPromise=api.list("Application", {status: "A04", auxType: "Facilitator"});
      var merchants2Promise=api.list("Merchant", {});
      var solutionsPromise=api.list("Solution", {});
      var userApi=(await userApiPromise).items;
      var merchants=(await merchantsPromise).items;
      var merchants2=(await merchants2Promise).items;
      var solutions=(await solutionsPromise).items;
      this.facilitators=userApi.filter((a)=>a.app);
      this.others=userApi.filter((a)=>!a.app);
      for(var merchant of merchants) {
        if (!this.facilitators.find((a)=>a.app==merchant.id))
          this.facilitators.push({
            id: null,
            apiId: merchant.mmid,
            app: merchant.id,
            name: merchant.companyName,
            dbaName: merchant.companyNameTrade,
            username: "",
            password: "",
            ipaddress: merchant.ipAddress,
            lastConnection: null,
            merchantStatus: null,
            status: "Disabled",
            editing: false,
          });
      }
      for(var user of this.others) {
        this.$set(user, "editing", false);
        this.$set(user, "disabled", false);
      }
      for(var user of this.facilitators) {
        this.$set(user, "editing", false);
        var merch=merchants2.find((a)=>a.app.id==user.app);
        this.$set(user, "disabled", merch && merch.status!="M01");
        this.$set(user, "merchantStatus", merch && merch.status);
      }
      this.solutions=[];
      for(var solution of solutions) {
        this.solutions.push({value: solution.id, label: solution.nameSolution});
      }
    },
    solution(id) {
      var value="";
      for(var solution of this.solutions) {
        if (solution.value==id)
          value=solution.label;
      }
      return value;
    },
    loadHistory: async function() {
      var result=await api.list("UserApiLog", {}, ["id:DESC"], 100);
      this.history=result.items.map(function(a) {
        a.expanded=false;
        return a;
      });
    },
    newOther() {
      this.others.push({
        id: null,
        apiId: "",
        name: "",
        dbaName: "",
        username: "",
        password: "",
        ipaddress: "",
        lastConnection: null,
        status: "Disabled",
        editing: true,
      });
    },
    saveItem: async function(item) {
      if (item.id)
        await api.update("UserApi", item.id, item);
      else
        item.id=(await api.create("UserApi", item)).id;
      item.editing=false;
    },
  },
  mixins: [vueMixins.dateFormatter],
}
</script>
