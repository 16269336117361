<template>
<div class="form-popup" v-show="shown"><div>
  <div class="ncontent appf appnat">
    <a class="close" href="" v-on:click.prevent="dismiss">&times;</a>
    <h3>Set partner settings</h3>

<form class="form-step">
  <section>
    <h2>Company Information</h2>
    <ul class="column-form column-form-compact">
      <li>
        <label>Relationship Manager</label>
        <div class="field">
        <validator :value="appl.manager" reqs="required">
          <dyn-select :options="sourcesToShow" v-model="appl.manager"></dyn-select>
        </validator>
        </div>
      </li>
      <li>
        <label>Partner Tier</label>
        <div class="field">
        <validator :value="appl.agentDb.agentClass" reqs="required">
          <dyn-select v-model="appl.agentDb.agentClass" :options="accountClassesList" />
        </validator>
        </div>
      </li>
      <li>
        <label>Referrer</label>
        <div class="field">
        <validator :value="appl.agentDb.referrer" reqs="required">
          <dyn-select v-model="appl.agentDb.referrer" :options="usersToShow" />
        </validator>
        </div>
      </li>
      <li>
        <label>Language</label>
        <div class="field">
        <validator :value="appl.agentDb.language" reqs="required">
          <dyn-select v-model="appl.agentDb.language" :options="languages" />
        </validator>
        </div>
      </li>
    </ul>
  </section>
  <div class="buttons">
    <a class="btn btn-large btn-success" v-on:click.prevent="save">Set</a>
  </div>
</form>
  </div>
  
  
</div></div>
</template>

<script>
export default {
  data() { return {
    portals: [{value: appData.settings.partnerLoginURL, label: appData.settings.partnerLoginURL}],
    shown: false,
    appl: {agentDb: {}},
    managers: [],
    internalNotes: {},
    languages: [
      {value: "English", label: "English"},
    ],
    accountTiers: [
      {value: "10", label: "10"},
      {value: "20", label: "20"},
      {value: "30", label: "30"},
      {value: "40", label: "40"},
      {value: "50", label: "50"},
    ],
    accountClassesList: [
      {value: "10", label: "10"},
      {value: "20", label: "20"},
      {value: "30", label: "30"},
      {value: "40", label: "40"},
      {value: "50", label: "50"},
    ],
    salesBrands: [],
    currencies: {"840": "USD", "124": "CAD"},
    currencies2: [
      {value: "124", label: "CAD"},
      {value: "840", label: "USD"},
    ],
    toggleYesNo: [
      {label: "Yes", value: 1},
      {label: "No", value: 0},
    ],
    countries: [
      {label: "United States", value: "United States"},
      {label: "Canada", value: "Canada"},
    ],
    sources: appData.sources || [],
    users: appData.users || [],
  }},
  mounted() {
    window.agentProfilePopup=this;
  },
  computed: {
    sourcesToShow() {
      return this.sources.map(function(a) {
        return {
          value: a.id,
          label: a.fullName || a.username,
        };
      });
    },
    usersToShow() {
      return this.users.map(function(a) {
        return {
          value: a.id,
          label: a.username,
        };
      });
    },
  },
  methods: {
    dismiss() {
      this.shown=false;
      this.appl={agentDb: {}};
    },
    show(agent) {
      this.shown=true;
      this.appl=agent;
    },
    async save() {
      if (!this.validate())
        return stopLoading("Please fill all required fields", "error");      
      
      startLoading();
      await api.update("User", this.appl.id, {
        manager: this.appl.manager,
        agentDb: {
          id: this.appl.agentDb.id,
          agentClass: this.appl.agentDb.agentClass,
          referrer: this.appl.agentDb.referrer,
          language: this.appl.agentDb.language
        }
      });
      stopLoading("Partner profile update");
      this.shown=false;
    },
  },
  mixins: [formValidation.mixin, vueMixins.dateFormatter]
}
</script>
