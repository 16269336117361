<template>
  <aside>
    <div class="header">
      <div class="logo-wrapper">
        <img :src="logo" />
      </div>

      <popper position="right-top" :offset="{ y: 5 }">
        <messages />

        <template v-slot:reference>
          <div
            class="messages-icon"
            @mouseenter="hoverOn = 'messages'"
            @mouseleave="hoverOn = null"
          >
            <NotificationIcon
              :color="hoverOn === 'messages' ? '#51b13e' : '#292929'"
            />
          </div>
        </template>
      </popper>
    </div>

    <nav>
      <ul>
        <nav-item :item="dashboardItem" name="dashboard" />
        <nav-item
          v-for="(item, key) in navigation"
          :key="key"
          :name="key"
          :item="item"
        />
      </ul>
    </nav>

    <div>
      <div class="sidebar-footer">
        <span class="version">{{ changeLog.version }}</span>
        <a v-if="!user.agentId" href="/manage" class="manage-logo">
          <img src="/assets/img/plug-dark.png" />
        </a>
      </div>

      <popper position="top" :offset="{ x: 10, y: 10 }">
        <account-dropdown />

        <template v-slot:reference>
          <div
            class="account"
            @mouseenter="hoverOn = 'account'"
            @mouseleave="hoverOn = null"
          >
            <account-icon
              :size="30"
              class="icon"
              :color="hoverOn === 'account' ? '#51b13e' : '#292929'"
            />
            <span>Account</span>
          </div>
        </template>
      </popper>
    </div>
  </aside>
</template>

<script>
import Popper from "../common/Popper";
import AccountDropdown from "./AccountDropdown";
import AccountIcon from "./navigation/AccountIcon";
import NotificationIcon from "./navigation/NotificationIcon";

export default {
  components: {
    Popper,
    AccountIcon,
    AccountDropdown,
    NotificationIcon,
  },

  data() {
    return {
      hoverOn: null,

      dashboardItem: ["Dashboard", ""],
    };
  },

  computed: {
    user() {
      return window.appData.currentUser2;
    },

    changeLog() {
      return window.appData.changeLog;
    },

    loginMode() {
      return window.appData.loginMode;
    },

    settings() {
      return window.appData.settings;
    },

    logo() {
      if (this.loginMode == "partners")
        return this.settings.partnerLogo
          ? `files/system/${this.settings.partnerLogo}`
          : null;
      if (this.loginMode == "developers")
        return this.settings.sandboxLogo
          ? `files/system/${this.settings.sandboxLogo}`
          : null;
      if (this.loginMode == "treasury")
        return this.settings.dashboardLogo
          ? `files/system/${this.settings.dashboardLogo}`
          : null;

      return this.settings.logo ? `files/system/${this.settings.logo}` : null;
    },

    navigation() {
      return window.appData.nav;
    },
  },
};
</script>

<style lang="scss">
aside {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 17px 50px 24px;
    .logo-wrapper {
      img {
        width: 200px;
      }
    }
    .messages-icon {
      padding: 5px;
      height: 24px;
      cursor: pointer;
    }
  }
  nav {
    height: 100%;
    padding: 0 24px;
  }

  .sidebar-footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0 24px;
    .version {
      font-size: 12px;
      color: #8f979e;
    }
    .manage-logo {
      padding: 5px 0 0 5px;
      img {
        height: 40px;
      }
    }
  }

  .account {
    height: 70px;
    line-height: 70px;
    border-top: 1px solid #e9ecef;
    margin-top: 20px;
    padding: 0 24px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: all ease 0.2s;
    .icon {
      margin-right: 10px;
      transition: all ease 0.2s;
    }
    &:hover {
      color: #51b13e;
    }
  }
}
</style>
