<template>
<div class="zip-code">
  <input type="text" v-model="zip1" v-on:input="updateValue" />
  <span>-</span>
  <input type="text" v-model="zip2" v-on:input="updateValue" />
</div>
</template>

<script>
export default {
  data() { return {
    zip1: "",
    zip2: "",
  }},
  props: ["value"],
  watch: {
    value: {
      immediate: true,
      handler() {
        var val=(this.value || "").split("-");
        this.zip1=val[0] || "";
        this.zip2=val[1] || "";
      },
    },
  },
  methods: {
    updateValue() {
      this.$emit("input", this.zip1+"-"+this.zip2);
    },
  },
}
</script>
