<template>
 <form class="form-step"> 
  <!--<div class="huge-checkbox">
    <label :class="{checked: app.companyNew=='1'}">
      <formel-toggle-basic v-model="app.companyNew" yes="1" no="0" />
      <i></i>
      <em>This is a <strong>New Business</strong></em>
      <span>Click here if this is a new business</span>
    </label>
  </div>-->
  <section>
    <ul class="column-form type2" v-if="auxtype=='Facilitator'">
      <li>
        <label>MMID</label>
        <div class="field">
          <validator :value="app.mmid" reqs="required">
            <input type="text" v-model="app.mmid" />
          </validator>
        </div>
      </li>
      <li>
        <label>Username</label>
        <div class="field">
          <validator :value="app.mmidUsername" reqs="required">
            <input type="text" v-model="app.mmidUsername" />
          </validator>
        </div>
      </li>
      <li>
        <label>Password</label>
        <div class="field">
          <validator :value="app.mmidPassword" reqs="required">
            <input type="text" v-model="app.mmidPassword" />
          </validator>
        </div>
      </li>
      <li>
        <label>IP Address</label>
        <div class="field">
          <validator :value="app.ipAddress" reqs="required">
            <input type="text" v-model="app.ipAddress" />
          </validator>
        </div>
      </li>
    </ul>
  
    <h2>Company Profile</h2>
    <div class="pgf-main-cb">
      <p class="intro">Is applying company owned by a parent company?</p>
      <validator :value="app.hasParent" reqs="required">
      <ul class="toggle-main">
        <li>
          <label :class="{checked: app.hasParent==='1'}">
            <input type="radio" name="hasParent" value="1" v-model="app.hasParent">
            <i></i>
            <strong>Yes</strong>
            <span>(provide details of applying company and parent company below)</span>
          </label>
        </li>
        <li>
          <label :class="{checked: app.hasParent==='0'}">
            <input type="radio" name="hasParent" value="0" v-model="app.hasParent">
            <i></i>
            <strong>No</strong>
            <span>(only provide details of applying company company below)</span>
          </label>
        </li>
      </ul>
      </validator>
    </div>

    <ul class="column-form type2" v-if="hasParent">
      <li class="head">
        <label>&nbsp;</label>
        <div>
          <div>Details of Parent Company</div>
        </div>
      </li>
      <li>
        <label>Company registration number</label>
        <div class="field">
          
          <validator :value="app.parentRegnum" reqs="required regnum" >
            <input type="text" v-model="app.parentRegnum" />
          </validator>
        </div>
      </li>
      <li>
        <label>Legal name of company</label>
        <div class="field">
          
          <validator :value="app.parentName" reqs="required legalName" >
            <input type="text" v-model="app.parentName" />
          </validator>
        </div>
      </li>
      <li>
        <label>Registered DBA/Trade name</label>
        <div class="field">
          
          <validator :value="app.parentNameTrade" reqs="legalName" >
            <input type="text" v-model="app.parentNameTrade" />
          </validator>
          
        </div>
        <span class="pfx-info">(if other than legal name)</span>
      </li>
      <li>
        <label>Type of business</label>
        <div class="field">
          
          <validator :value="app.parentType" reqs="required businessType" >
            <combo-select-ext :options="tobOptions" type="text" v-model="app.parentType" />
          </validator>
        </div>
        <span class="pfx-info">(LLC, Ltd, Sole proprietor, etc)</span>
      </li>
      <li>
        <label>Registered street address</label>
        <div class="field">
          
          <validator :value="app.parentAddress" reqs="required address" >
            <input type="text" v-model="app.parentAddress" />
          </validator>
        </div>
      </li>
      <li v-if="false">
        <label>House number</label>
        <div class="field">
          
          <validator :value="app.parentAddressNum" reqs="number" >
            <input type="text" v-model="app.parentAddressNum" />
          </validator>
        </div>
        <span class="pfx-ingo">(P.O. Box not accepted)</span>
      </li>
      <li>
        <label>Postal code / ZIP code</label>
        <div class="field">
          
          <validator :value="app.parentAddressPostcode" reqs="required postcode" >
            <input type="text" v-model="app.parentAddressPostcode" />
          </validator>
        </div>
      </li>
      <li>
        <label>City</label>
        <div class="field">
          
          <validator :value="app.parentAddressCity" reqs="required city" >
            <input type="text" v-model="app.parentAddressCity" />
          </validator>
        </div>
      </li>
      <li>
        <label>Province / State</label>
        <div class="field">
          
          <validator :value="app.parentAddressState" reqs="required state" >
            <input type="text" v-model="app.parentAddressState" />
          </validator>
        </div>
      </li>
      <li>
        <label>Country</label>
        <div class="field">
          
          <validator :value="app.parentAddressCountry" reqs="required state" >
            <input type="text" v-model="app.parentAddressCountry" />
          </validator>
        </div>
      </li>
      <li>
        <label>Telephone number</label>
        <div class="field">
          
          <validator :value="app.parentPhone" reqs="required phone" >
            <input type="text" v-model="app.parentPhone" />
          </validator>
        </div>
      </li>
      <li>
        <label>Incorporation Date</label>
        <div class="field">
          
          <validator :value="app.parentFax" reqs="required" >
            <datepicker v-model="app.parentFax" />
          </validator>
        </div>
      </li>
      <li>
        <label>Company Email</label>
        <div class="field">
          
          <validator :value="app.parentEmail" reqs="required email char100" >
            <input type="text" v-model="app.parentEmail" />
          </validator>
        </div>
      </li>
      <!--<li>
        <label>Turnover last year</label>
        <div class="field">
          
          <validator :value="app.parentTurnover && app.parentTurnoverCurrency" reqs="" >
            <money :amount.sync="app.parentTurnover" :currency.sync="app.parentTurnoverCurrency" />
          </validator>
        </div>
        <span class="pfx-info">(plus currency)</span>
      </li>
      <li>
        <label>Incorporation date</label>
        <div class="field">
          
          <validator :value="app.parentDate" reqs="required" >
            <datepicker v-model="app.parentDate" reqs="past" />
          </validator>
        </div>
        <span class="pfx-info">(YYYY MM DD)</span>
      </li>
      <li>
        <label>Tax identification number</label>
        <div class="field">
          
          <validator :value="app.parentTaxId" reqs="required num20" >
            <input type="text" v-model="app.parentTaxId" />
          </validator>
        </div>
      </li>-->
      <!--<li>
        <label>Is company publicly listed on a stock exchange?</label>
        <div class="field">
          
          <validator :value="app.parentIsPublic" reqs="required" >
            <formel-toggle v-model="app.parentIsPublic" />
          </validator>
        </div>
      </li>
      <li>
        <label>Is company a registered "not-for-profit" organization?</label>
        <div class="field">
          
          <validator :value="app.parentIsNonProfit" reqs="required" >
            <formel-toggle v-model="app.parentIsNonProfit" />
          </validator>
        </div>
      </li>-->
      <li class="sthird">
        <label>Are there any current legal proceedings being taken against the business, parent copmany or any associated directors?</label>
        <div class="field">
          
          <validator :value="app.parentLawsuit" reqs="required" >
            <formel-toggle v-model="app.parentLawsuit" />
          </validator>
        </div>
      </li>
      <!--<li>
        <label>Has the business, parent company or any associated director ever been declined for processing?</label>
        <div class="field">
          
          <validator :value="app.parentDeclined" reqs="required" >
            <formel-toggle v-model="app.parentDeclined" />
          </validator>
        </div>
      </li>
      <li>
        <label>Has the business, parent company or any associated director ever been terminated for processing?</label>
        <div class="field">
          
          <validator :value="app.parentTerminated" reqs="required" >
            <formel-toggle v-model="app.parentTerminated" />
          </validator>
        </div>
      </li>-->
      <li class="sthird2">
<!--         <label>Has the business, parent company or any associated director ever been terminated for processing?</label> -->
        <div class="field">
          <validator :value="app.parentLawsuitDetails" :reqs="app.parentLawsuit*1?'required':''">
            <textarea :disabled="!(app.parentLawsuit*1)" v-model="app.parentLawsuitDetails" placeholder="Explain"></textarea>
          </validator>
          
        </div>
      </li>
    </ul>
    
    
    
    <ul class="column-form type2">
      <li class="head">
        <label>&nbsp;</label>
        <div>
          <div>Details of Applying Company</div>
        </div>
      </li>
      <li>
        <label>Company registration number</label>
        <div class="field">
          <validator :value="app.companyRegnum" reqs="required regnum">
            <input type="text" v-model="app.companyRegnum" />
          </validator>
          
        </div>
      </li>
      <li>
        <label>Legal name of company</label>
        <div class="field">
          <validator :value="app.companyName" reqs="required legalName">
            <input type="text" v-model="app.companyName" />
          </validator>
          
        </div>
      </li>
      <li>
        <label>Registered DBA/Trade name</label>
        <div class="field">
          <validator :value="app.companyNameTrade" reqs="legalName">
            <input type="text" v-model="app.companyNameTrade" />
          </validator>
          
        </div>
        <span class="pfx-info">(if other than legal name)</span>
      </li>
      <li>
        <label>Type of business</label>
        <div class="field">
          <validator :value="app.companyType" reqs="required businessType">
            <combo-select-ext :options="tobOptions" type="text" v-model="app.companyType" />
          </validator>
          
        </div>
        <span class="pfx-info">(LLC, Ltd, Sole proprietor, etc)</span>
      </li>
      <li>
        <label>Registered street address</label>
        <div class="field">
          <validator :value="app.companyAddress" reqs="required address">
            <input type="text" v-model="app.companyAddress" />
          </validator>
          
        </div>
        <span class="pfx-info">(P.O. Box not accepted)</span>
      </li>
      <li v-if="false">
        <label>House number</label>
        <div class="field">
          <validator :value="app.companyAddressNum" reqs="number">
            <input type="text" v-model="app.companyAddressNum" />
          </validator>
          
        </div>
      </li>
      <li>
        <label>Postal code</label>
        <div class="field">
          <validator :value="app.companyAddressPostcode" reqs="required postcode">
            <input type="text" v-model="app.companyAddressPostcode" />
          </validator>
          
        </div>
      </li>
      <li>
        <label>City</label>
        <div class="field">
          <validator :value="app.companyAddressCity" reqs="required city">
            <input type="text" v-model="app.companyAddressCity" />
          </validator>
          
        </div>
      </li>
      <li>
        <label>Province</label>
        <div class="field">
          <validator v-if="app.companyAddressCountry=='CA' || app.companyAddressCountry=='US'" :value="app.companyAddressState" reqs="required">
            <province-select v-model="app.companyAddressState" :country="app.companyAddressCountry"  />
          </validator>
          <validator v-else>
            <input type="text" v-model="app.companyAddressState" />
          </validator>
        </div>
      </li>
      <li>
        <label>Country</label>
        <div class="field">
          <validator :value="app.companyAddressCountry" reqs="required">
            <div class="combo-select">
            <dyn-select :options="validCountries" v-model="app.companyAddressCountry" />
            </div>
          </validator>
          
        </div>
      </li>
      <li>
        <label>Telephone number</label>
        <div class="field">
          <validator :value="app.companyPhone" reqs="required phone">
            <input type="text" v-model="app.companyPhone" />
          </validator>
          
        </div>
      </li>
      <li>
        <label>Company website</label>
        <div class="field">
          <validator :value="app.companyFax" reqs="">
            <input type="text" v-model="app.companyFax" />
          </validator>
          
        </div>
      </li>
      <li>
        <label>Company Email</label>
        <div class="field">
          <validator :value="app.companyEmail" reqs="required email char100">
            <input type="text" v-model="app.companyEmail" />
          </validator>
          
        </div>
      </li>
      <li class="sthird">
        <label>Turnover last year</label>
        <div class="field">
          <validator :value="app.companyTurnover && app.companyTurnoverCurrency" reqs="">
            <money :amount.sync="app.companyTurnover" :currency.sync="app.companyTurnoverCurrency" />
          </validator>
          
        </div>
        <span class="pfx-info">(plus currency)</span>
      </li>
      <li class="sthird">
        <label>Incorporation date</label>
        <div class="field">
          <validator :value="app.companyDate" reqs="required">
            <datepicker v-model="app.companyDate" reqs="past" />
          </validator>
          
        </div>
        <span class="pfx-info">(YYYY MM DD)</span>
      </li>
      <li class="sthird">
        <label>Tax identification number</label>
        <div class="field">
          <validator :value="app.companyTaxId" reqs="required num20">
            <input type="text" v-model="app.companyTaxId" />
          </validator>
          
        </div>
      </li>
      <li class="sthird">
        <label>Is company publicly listed on a stock exchange?</label>
        <div class="field">
          <validator :value="app.companyIsPublic" reqs="required">
            <formel-toggle v-model="app.companyIsPublic" />
          </validator>
          
        </div>
      </li>
      <li class="sthird">
        <label>Is company a registered "not-for-profit" organization?</label>
        <div class="field">
          <validator :value="app.companyIsNonProfit" reqs="required">
            <formel-toggle v-model="app.companyIsNonProfit" />
          </validator>
          
        </div>
      </li>
      <li class="sthird">
        <label>Are there any current legal proceedings being taken against the business, parent copmany or any associated directors?</label>
        <div class="field">
          <validator :value="app.companyLawsuit" reqs="required">
            <formel-toggle v-model="app.companyLawsuit" />
          </validator>
          
        </div>
      </li>
      <li class="sthird">
        <label>Has the business, parent company or any associated director ever been declined for processing?</label>
        <div class="field">
          <validator :value="app.companyDeclined" reqs="required">
            <formel-toggle v-model="app.companyDeclined" />
          </validator>
          
        </div>
      </li>
      <li class="sthird">
        <label>Has the business, parent company or any associated director ever been terminated for processing?</label>
        <div class="field">
          <validator :value="app.companyTerminated" reqs="required">
            <formel-toggle v-model="app.companyTerminated" />
          </validator>
          
        </div>
      </li>
      <!--<li class="sthird">
        <label>Has the business, parent company or any associated director ever been terminated for processing?</label>
        <div class="field">
          <validator :value="app.companyTerminated" reqs="required">
            <formel-toggle v-model="app.companyTerminated" />
          </validator>
          
        </div>
      </li>-->
      <li class="sthird">
<!--         <label>Has the business, parent company or any associated director ever been terminated for processing?</label> -->
        <div class="field">
          <validator :value="app.companyTerminatedDetails" :reqs="app.companyTerminated*1?'required':''">
            <textarea :disabled="!(app.companyTerminated*1)" v-model="app.companyTerminatedDetails" placeholder="Explain"></textarea>
          </validator>
          
        </div>
      </li>
    </ul>
    
    
    
    <ul class="footnotes">
      <li><sup>1</sup> Should be mentioned on incorporation documents</li>
      <li><sup>2</sup> Including international country code (eg. +1 for USA, +852 for Hong Kong, etc)</li>
      <li v-if="false"><sup>3</sup> If not provided, VAT may be applicable to transaction charges</li>
    </ul>
  </section>
  
  <section>
    <h2>Location Address</h2>
    <div class="pgf-main-cb">
      <p class="intro">Is location address different from registered address?</p>
      <validator :value="app.billingDifferent" reqs="required">
      <ul class="toggle-main">
        <li>
          <label :class="{checked: app.billingDifferent==='1'}">
            <input type="radio" name="billingDifferent" value="1" v-model="app.billingDifferent">
            <i></i>
            <strong>Yes</strong>
            <span>(provide location address below)</span>
          </label>
        </li>
        <li>
          <label :class="{checked: app.billingDifferent==='0'}">
            <input type="radio" name="billingDifferent" value="0" v-model="app.billingDifferent">
            <i></i>
            <strong>No</strong>
            <span>(proceed to section 2: Company Ownership Profile)</span>
          </label>
        </li>
      </ul>
      </validator>
    </div>
    <ul class="column-form type2" v-if="app.billingDifferent==='1'">
      <li>
        <label>Street address</label>
        <div class="field">
          <validator :value="app.billingAddress" reqs="required address">
            <input type="text" v-model="app.billingAddress" />
          </validator>
        </div>
        <span class="pfx-info">(for billing purposes)</span>
      </li>
      <li v-if="false">
        <label>House number</label>
        <div class="field">
          <validator :value="app.billingAddressNum" reqs="required address">
            <input type="text" v-model="app.billingAddressNum" />
          </validator>
        </div>
      </li>
      <li>
        <label>Postal code</label>
        <div class="field">
          <validator :value="app.billingAddressPostcode" reqs="required postcode">
            <input type="text" v-model="app.billingAddressPostcode" />
          </validator>
        </div>
      </li>
      <li>
        <label>City</label>
        <div class="field">
          <validator :value="app.billingAddressCity" reqs="required city">
            <input type="text" v-model="app.billingAddressCity" />
          </validator>
        </div>
      </li>
      <li>
        <label>Province</label>
        <div class="field">
          <validator v-if="app.billingAddressCountry == 'CA' || app.billingAddressCountry == 'US'" :value="app.billingAddressState" reqs="required state">
            <province-select v-model="app.billingAddressState" :country="app.billingAddressCountry" />
          </validator>
          <validator v-else>
            <input type="text" v-model="app.billingAddressState" />
          </validator>
        </div>
      </li>
      <li>
        <label>Country</label>
        <div class="field">
          <validator :value="app.billingAddressCountry" reqs="required state">
            <div class="combo-select">
            <dyn-select :options="validCountries" v-model="app.billingAddressCountry" />
            </div>
          </validator>
        </div>
      </li>
    </ul>
  </section>
  
  <div class="buttons">
    <a class="btn btn-large btn-plain btn-back" v-on:click.prevent="$emit('prev')">Back</a>
    <a class="btn btn-large btn-warn" v-on:click.prevent="$emit('save')">Save</a>
    <a class="btn btn-large btn-success btn-next" v-on:click.prevent="nextStep">Next Step</a>
  </div>
</form>
</template>


<script>
export default {
  props: ["app", "auxtype", "solution"],
  data() { return {
    tobOptions: [
      "Inc.",
      "Corp.",
      "LLC",
      "LLP",
      "Ltd.",
      "Sole Proprietor",
      "",
    ],
  }},
  computed: {
    hasParent() {
      return this.app.hasParent=='1';
    },

    validCountries() {
      var countries = [];
      var lists = [];
      if(this.solution && this.solution.businessFormation) {
        lists = window.fullCountries.filter(a => {
          return this.solution.businessFormation.includes(a.id)
        })
      }

      lists.forEach(item => {
        countries.push({
          label: item.text,
          value: item.id
        })
      })

      return countries
    },
  },
  methods: {
    nextStep() {
      if (!this.validate())
        return stopLoading("Please fill all required fields", "error");
      this.$emit("next");      
    },
  },
  mounted() {

  },
  mixins: [formValidation.mixin]  
}
</script>
