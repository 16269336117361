<template>
  <div>
    <template>
      <div class="provider-form pad20">
        <div class="input-group">
          <label>Account Class</label>
          <validator :value="form.name" reqs="char10">
            <input v-model="form.name" />
          </validator>
        </div>
        <div class="input-group">
          <label>Designation</label>
          <dyn-select v-model="form.designation" :options="designations" />
        </div>
        <div class="input-group">
          <label>Description</label>
            <input v-model="form.description" />
        </div>
        <div class="input-group">
          <label>Review Days</label>
            <dyn-select v-model="form.reviewDays" :options="reviewDays" />
        </div>
      </div>
      <br/>
      <button class="btn btn-small btn-primary mb-20" v-on:click.prevent="saveNewItem()">Add</button>
      <button class="btn btn-small btn-primary mb-20" v-on:click.prevent="resetItem()">Reset</button>
    </template>
    <ul class="acc-results">
      <li class="">
        <div class="acc-list" style="display: block">
          <table>
            <thead>
              <tr>
                <th style="text-align: left">Account Class</th>
                <th style="text-align: left">Designation</th>
                <th style="text-align: left">Description</th>
                <th style="text-align: left">Review Days</th>
                <th>Options</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in accounts">
                <td style="text-align: left">
                  <span>{{ item.name }}</span>
                </td>
                <td style="text-align: left">
                  <span>{{ item.designation }}</span>
                </td>
                <td style="text-align: left">
                  <span>{{ item.description }}</span>
                </td>
                <td style="text-align: left">
                  <span>{{ item.reviewDays }}</span>
                </td>
                <td>
                  <!-- <a href="" v-if="!item.editing && item.status=='Disabled'" v-on:click.prevent="doDelete(item)">Delete</a>
                  <a href="" v-if="!item.editing" v-on:click.prevent="item.editing=true">Edit</a>
                  <a href="" v-else v-on:click.prevent="saveItem(item)">Save</a> -->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </li>
    </ul>
  </div>
</template>


<script>
export default {
  props: ["hideadv"],
  data() { return {
    reviewDays: [
        {value: 30, label: 30},
        {value: 60, label: 60},
        {value: 90, label: 90},
        {value: 180, label: 180},
        {value: 365, label: 365},
      ],

      designations: [
        {value: 'LR', label: 'LR'},
        {value: 'LR+', label: 'LR+'},
        {value: 'MR', label: 'MR'},
        {value: 'HR', label: 'HR'},
        {value: 'HR+', label: 'HR+'},
      ],

      form : {
        name: null,
        designation: null,
        description: null,
        reviewDays: null,
        createdAt: 'CURRENT_TIMESTAMP',
      },
      
      newItem: false,
      accounts: [],
  }},
  mounted() {
      this.loadData();
    },
    methods: {
      doDelete: async function(item) {
        item.status = 'Archived'
        await api.update("AccountClass", item.id, item);
        this.providers = []
        this.loadData();
      },

      loadData: async function() {
        var connexPromise=api.list("AccountClass");
        var accounts=(await connexPromise).items;
        for(var account of accounts) {
          account.editing = false
            this.accounts.push(account);
        }
      },
  
      newProvider() {
        this.providers.push({
          id: null,
          providerType: "",
          providerName: "",
          version: "",
          username: "",
          providerPassword: "",
          providerKey: "",
          status: "",
          notes: "",
          certifiedDate: null,
          providerKeyExpiry: null,
          updatedAt: 'CURRENT_TIMESTAMP',
          editing: true,
        });
      },
      saveItem: async function(item) {
        if (item.id)
          await api.update("Provider", item.id, item);
        else
          await api.create("Provider", item)
  //      item.editing=false;
        this.providers = []
        this.loadData()
      },

      saveNewItem: async function() {
        if (!this.validate())
          return stopLoading("Please fill all required fields", "error");
        await api.create("AccountClass", this.form)
        this.accounts = []
        this.form = {
          name: null,
          designation: null,
          description: null,
          reviewDays: null,
          createdAt: 'CURRENT_TIMESTAMP',
        }
        this.loadData()
        return stopLoading("Account Class Added.", "success");
      },
      
      resetItem: async function() {
        this.form = {
          name: null,
          designation: null,
          description: null,
          reviewDays: null,
          createdAt: 'CURRENT_TIMESTAMP',
        }
      },

      showItem(item) {
        providerEdit.showPopup(item);
      },
    }, 
    mixins: [vueMixins.dateFormatter, formValidation.mixin],
}
</script>
