<template>
<div>
  <page-header title="Implementation" :breadcrumbs="breadcrumbs" />

  <sub-menu :back="true" :menu="menu" />
</div>
</template>

<script>
export default {
  data () {
    return {
      breadcrumbs: [
        {
          text: 'System',
        },
        {
          text: 'Implementation'
        }
      ],

      menu: [
        {
          text: 'On-Boarding',
          list: [
            {
              text: 'On-Boarding',
              to: '/implementation/onboarding'
            }
          ]
        },
      ]
    }
  }
}
</script>
