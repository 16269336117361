import { render, staticRenderFns } from "./treasury-simplified-profile.vue?vue&type=template&id=276ccf30&scoped=true&"
import script from "./treasury-simplified-profile.vue?vue&type=script&lang=js&"
export * from "./treasury-simplified-profile.vue?vue&type=script&lang=js&"
import style0 from "./treasury-simplified-profile.vue?vue&type=style&index=0&id=276ccf30&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "276ccf30",
  null
  
)

export default component.exports