<template>
  <div>
    <form class="preq-form preq-view">
      <div v-if="newFlag" class="new-note">
        <validator :value="newNote" reqs="required charmin5">
          <textarea minlength="5" v-model="newNote"></textarea>
        </validator>
      </div>
      <div class="notes-list appl-notes">
        <div class="toolbar" v-if="hasFilter">
          <a href="" class="btn" :class="{current: filter==null}" v-on:click.prevent="filter=null">All</a>
          <a href="" class="btn" :class="{current: filter==1}" v-on:click.prevent="filter=1">System</a>
          <a href="" class="btn" :class="{current: filter==0}" v-on:click.prevent="filter=0">User</a>
        </div>
        <h4>Notes</h4>
        <ul>
          <li v-for="(note, ni) in notesList" v-if="filter==null || note.system==filter">
            <a class="remove" v-if="note.system==0" href="" v-on:click.prevent="removeNote(ni)">&times;</a>
            <strong>{{ note.created === 'CURRENT_TIMESTAMP' ? now : note.created }}
              <em class="link">{{ note.author && note.author.username || currentUser.username }}</em>:
            </strong>
            {{ note.message }}
          </li>
        </ul>
        <portal to="actions">
          <ul>
            <template v-if="newFlag">
              <li>
                <a v-on:click.prevent="createNote">Submit</a>
              </li>
              <li>
                <a v-on:click.prevent="newFlag = false, newNote = ''">Cancel</a>
              </li>
            </template>
            <li v-else>
              <a href="" v-on:click.prevent="newFlag=true">New</a>
            </li>
          </ul>
        </portal>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  props: {
    notes: {
      type: Array,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    id: {
      type: [String, Number, Object],
      default: null
    },
    await: {
      type: Boolean,
      default: false
    },
    hasFilter: {
      type: Boolean,
      default: true
    },
  },

  data () {
    return {
      newFlag: false,
      filter: null,
      newNote: "",
      awaitList: [],
      now: moment().format('YYYY-MM-DD HH:mm:ss')
    }
  },

  computed: {
    notesList () {
      return this.await ? this.awaitList : this.notes
    },

    currentUser () {
      return appData.currentUser
    }
  },

  methods: {
    async createNote () {
      if (!this.validate())
        return stopLoading("Please fill all required fields", "error");
      startLoading();
      var data = {
        message: this.newNote,
        created: "CURRENT_TIMESTAMP",
        author: this.currentUser.id,
        type: this.type,
        system: 0
      }
      
      if (this.await) {
        this.awaitList.push(data)
      } else {
        data.parentId = this.id
        const response = await api.create("Note", data, true)
        this.notes.push(response.item)
      }
      stopLoading("New note created");
      this.newNote = "";
      this.newFlag = false
    },

    async removeNote (index) {
      if (this.await) return this.awaitList.splice(index, 1)
      startLoading()
      var note = this.notes[index]
      await api.delete("Note", note.id)
      stopLoading("Note deleted")
      this.notes.splice(index, 1)
    },

    async bulkCreate (id) {
      const promises = []
      for (var data of this.awaitList) {
        data.parentId = id
        promises.push(await api.create('Note', data, true))
      }
      return Promise.all(promises)
    }
  },
  mixins: [formValidation.mixin, vueMixins.dateFormatter],
}
</script>
