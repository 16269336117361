<template>
<div v-if="user" class="ncontent wide">
  <a class="back-button">Dashboard</a>
  <div class="header">
    <div>
      <ul class="breadcrumbs">
        <li><a href="">Partner Overview</a></li>
        <li><a href="">Partner</a></li>
      </ul>
    </div>
  </div>
  <div class="merchant-panel">
    <div class="data-content">
      <ul class="tabs">
        <li :class="{ active: activeTab === 'general' }" @click="activeTab = 'general'">General</li>
        <li :class="{ active: activeTab === 'notes' }" @click="activeTab = 'notes'">Notes</li>
      </ul>
      <p class="tabs">Partner Settings</p>
      <ul class="tabs-smaller">
        <li :class="{ active: activeTab === 'profile' }" @click="activeTab = 'profile'">Profile</li>
        <li :class="{ active: activeTab === 'access' }" @click="activeTab = 'access'">Access</li>
        <li :class="{ active: activeTab === 'activity' }" @click="activeTab = 'activity'">Activity</li>
      </ul>
      <div class="main">

        <form class="preq-form preq-view">
          <notes v-if="activeTab === 'notes'" class="appl-notes" :notes="notes" type="User" :id="id" />

          <div v-show="activeTab === 'general'">
            <ul class="appview-list">
              <li>
                <label>First Name</label>
                <div class="validator">
                  <validator v-if="editMode" :value="form.first_name" reqs="required">
                    <input type="text" v-model="form.first_name" @keypress="allowLetterNumber($event, { numbers: false })">
                  </validator>
                  <span v-else>{{ form.first_name }}</span>
                </div>
              </li>
              <li>
                <label>Last Name</label>
                <div class="validator">
                  <validator v-if="editMode" :value="form.last_name" reqs="required">
                    <input type="text" v-model="form.last_name" @keypress="allowLetterNumber($event, { numbers: false })">
                  </validator>
                  <span v-else>{{ form.last_name }}</span>
                </div>
              </li>
              <li>
                <label>Email</label>
                <div class="validator">
                  <validator v-if="editMode" :value="form.email" reqs="required email">
                    <input type="text" v-model="form.email">
                  </validator>
                  <span v-else>{{ form.email }}</span>
                </div>
              </li>
              <li>
                <label>Phone</label>
                <div class="validator">
                  <validator v-if="editMode" :value="form.phone" reqs="required">
                    <input type="text" v-model="form.phone" class="w100">
                  </validator>
                  <span v-else>{{ form.phone }}</span>
                </div>
              </li>
            </ul>

            
          </div>

          <div v-show="activeTab === 'profile'">
          </div>
          
          <employee-data-access
            v-show="activeTab === 'access'"
            :employee="{userId: user, username: user.username}"
            :value="form"
            :editMode="editMode"
            @active="user.active = $event" />
            

          
          <div class="up-acc" v-show="activeTab === 'activity'">
            <div class="open">
              <div 
                class="title">Login activity
              </div>
              <div class="acc-list">
                <table class="">
                  <thead>
                    <tr>
                      <th style="width: 33%">Username</th>
                      <th style="width: 33%">Time</th>
                      <th style="width: 34%">IP Address</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in logins">
                      <td class="main-col">
                        {{ user && user.username }}
                      </td>
                      <td class="">
                        {{ item.recorded }}
                      </td>
                      <td class="">
                        {{ item.ipAddress }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>


        </form>

        <template v-if="editMode">
          <button type="button" class="btn btn-small" @click="edit()">Save</button>
          <button type="button" class="btn btn-small" @click="cancel()">Cancel</button>
        </template>
      </div>
    </div>

    <aside class="actions">
      <ul class="tabs">
        <li>General</li>
      </ul>
      
      <div class="main">
        <div class="action-buttons">
          <a href="#" @click.prevent="editMode = true">Edit</a>
        </div>
      </div>
    </aside>
  </div>
</div>
</template>

<script>
export default {
  mixins: [ vueMixins.helpers, vueMixins.dateFormatter, formValidation.mixin ],
  props: ["id"],

  data () {
    return {
      activeTab: 'general',
      user: null,
      notes: [],
      form: {},
      editMode: false,
      logins: []
    }
  },

  created() {
    startLoading()
    this.loadUser()
    this.loadNotes()
    stopLoading()
  },

  methods: {
    async loadLogins () {
      this.logins = (await api.list("UserLogin", {
        userId: this.user.id,
      }, ["recorded:DESC"], 15)).items;
    },
    async edit () {
      if (!this.validate()) return stopLoading('Please fill all required fields', 'error')
      startLoading()
      try {
        const response = await api.update("User", this.id, this.getUpdate(), true)
        const edited = this.getEditedFields()
        const note = (await api.create("Note", {
          message: `User edited - ${edited}`,
          parentId: this.id,
          type: 'User',
          created: "CURRENT_TIMESTAMP",
          author: appData.currentUser.id,
          system: 1
        }, true)).item
        this.notes.push(note)
        this.user = response.item
        this.validateReset()
        this.clearForm()
        this.editMode = false
        stopLoading()
      } catch (e) {
        stopLoading(e, 'error')
      }
    },
    async loadNotes () {
      const filter = {
        type: 'User',
        parentId: this.id
      }
      this.notes = (await api.list("Note", filter, ['id:ASC'])).items || [];
    },
    async loadUser () {
      const response = await api.get('User', this.id)
      this.user = response.item
      this.clearForm()
      this.loadLogins()
    },
    getEditedFields() {
      var labels={
          username: "Username",
        first_name: "First Name",
        last_name: "Last Name",
        email: "Email",
        phone: "Phone",
        phone_ext: "Ext",
        address: "Address",
        city: "City",
        province: "Province",
        postal: "Postal",
        country: "Country",
        ec_first_name: "Emergency First Name",
        ec_last_name: "Emergency Last Name",
        ec_email: "Emergency Email",
        ec_phone: "Emergency Phone",
      };
      var origForm=this.getForm();
      var edited=[];
      for(var field in this.form)
        if (this.form[field]!=origForm[field])
          edited.push(labels[field]);
      return edited.join(", ");
    },
    getForm() {
      if (!this.user)
        return {};
      var su=this.user.emergencyContact || {};
      return {
        first_name: this.user.fullName,
        last_name: this.user.lastName,
        email: this.user.email,
        title: this.user.title,
        groups: this.user.groups,
        phone: this.user.phone,
        address: this.user.address,
        city: this.user.city,
        province: this.user.province,
        postal: this.user.postal,
        country: this.user.country,
        ec_first_name: su.firstName,
        ec_last_name: su.lastName,
        ec_email: su.email,
        ec_phone: su.phone,
      };
    },
    getUpdate() {
      return {
        firstName: this.form.first_name,
        lastName: this.form.last_name,
        email: this.form.email,
        city: this.form.city,
        address: this.form.address,
        country: this.form.country,
        province: this.form.province,
        postal: this.form.postal,
        phone: this.form.phone,
        emergencyContact: {
          firstName: this.form.ec_first_name,
          lastName: this.form.ec_last_name,
          email: this.form.ec_email,
          phone: this.form.ec_phone,
        },
      };
    },
    clearForm() {
      this.form=this.getForm();
    },
    cancel() {
      this.editMode=false;
      this.clearForm();
    },
  },
}
</script>
