window.vueMixins = window.vueMixins || {}

window.vueMixins.currency = {
  computed: {
    currencies () {
      return [
        { value: "124", label: "CAD" },
        { value: "840", label: "USD" },
        // {value: "036", label: "AUD"},
        // {value: "986", label: "BRL"},
        // {value: "756", label: "CHF"},
        // {value: "978", label: "EUR"},
        // {value: "826", label: "GBP"},
        // {value: "344", label: "HKD"},
        // {value: "392", label: "JPY"},
        // {value: "554", label: "NZD"},
      ]
    }
  }
}