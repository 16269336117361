<template>
<div>
  <div class="content-table-container">
    <table class="content-table">
      <thead>
        <tr>
          <th style="width: 5%">Enabled</th>
          <th style="width: 45%">Equipment</th>
          <th style="width: 20%">Purchase Price</th>
          <th style="width: 20%">Rental Price</th>
          <th style="width: 10%">&nbsp;</th>
        </tr>
        <tr>
          <th class="spacer" colspan="3"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items">
          <td class="text-left" style="width: 5%">
            <input type="checkbox" v-model="item.enabled" />
          </td>
          <td class="text-left" style="width: 45%">
            <input type="text" v-model="item.name" style="text-align: left" />
          </td>
          <td class="" style="width: 20%">
            <input type="text" step="0.01" v-model="item.purchase" style="text-align: left" />
          </td>
          <td class="" style="width: 20%">
            <input type="text" step="0.01" v-model="item.rental" style="text-align: left" />
          </td>
          <td>
            <a class="remove" href="" v-on:click.prevent="deleteItem(index)">&times;</a>
          </td>
        </tr>
        <tr class="new-row">
          <td colspan="5">
            <a class="add" href="" v-on:click.prevent="newItem">+</a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="buttons">
    <button class="btn btn-large btn-primary" v-on:click.prevent="updateEquipment">Submit</button>
  </div>
</div>
</template>

<script>
export default {
  data() { return {
    items: [],
    origItems: [],
  }},
  methods: {
    reload() {
      var that=this;
      api.list("Equipment", {}, ["id:ASC"]).then(function(data) {
        that.origItems=JSON.parse(JSON.stringify(data.items));
        that.items=JSON.parse(JSON.stringify(data.items));
        that.origItems=that.origItems.map(function(a) {
          return {
            id: a.id, name: a.name, purchase: a.purchase,
            rental: a.rental, enabled: a.enabled>0,
          }
        });
        that.items=that.items.map(function(a) {
          return {
            id: a.id, name: a.name, purchase: a.purchase,
            rental: a.rental, enabled: a.enabled>0,
          }
        });
      });
    },
    deleteItem(index) {
      var that=this;
      if (confirm("Are you sure?")) {
        that.items.splice(index, 1);
      }
    },
    newItem() {
      this.items.push({
        enabled: true,
        purchase: 0,
        rental: 0,
        name: "",
      });
    },
    updateEquipment() {
      var itemsToDelete=[];
      for(var index in this.origItems) {
        var item=this.origItems[index];
        if (!this.items.find(function(e) { return e.id==item.id; }))
          itemsToDelete.push(item);
      }
      var itemsToAdd=[];
      var itemsToUpdate=[];
      for(var index in this.items) {
        var item=this.items[index];
        var foundItem=this.origItems.find(function(e) { return e.id==item.id; });
        if (!foundItem)
          itemsToAdd.push(item);
        else if (JSON.stringify(foundItem)!=JSON.stringify(item))
          itemsToUpdate.push(item);
      }
      this.updateEquipmentApi(itemsToUpdate, itemsToAdd, itemsToDelete);
      this.origItems=JSON.parse(JSON.stringify(this.items));
    },
    updateEquipmentApi: async function(update, add, remove) {
      startLoading();
      for(var i in update) {
        var item=update[i];
        await api.update("Equipment", item.id, item);
      }
      for(var i in add) {
        var item=add[i];
        await api.create("Equipment", item);
      }
      for(var i in remove) {
        var item=remove[i];
        await api.delete("Equipment", item.id);
      }
      stopLoading("Updated resources");
    },
  },
  mounted() {
    this.reload();
  },
}

</script>

