<template>
  <div>
    <p>Compliance</p>
    <br/>
    <div class="content-table-container file-list-container">
      <table class="file-list content-table">
        <thead>
          <tr>
            <td>Type</td>
            <td></td>
            <td>Result</td>
            <td>Options</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="complianceScan in this.item.complianceScans">
            <td>{{ complianceScan.type }}</td>
            <td class="partner-boarding-doc-actions">
              <button v-on:click.prevent="startStatus(complianceScan)">Start</button>
              <button v-on:click.prevent="skipStatus(complianceScan)">Skip</button>
            </td>
            <td v-if="complianceScan.type != 'Scans'">
              {{ complianceScan.status }}
            </td>
            <td v-else></td>
            <td></td>
          </tr>
          <template v-if="item.accountType == 'Personal'">
            <tr>
              <td>{{ item.personal.firstName }} {{ item.personal.lastName }}</td>
              <td></td>
              <td>{{ scanStatus }}</td>
              <td></td>
            </tr>
          </template>
          <template v-if="item.accountType == 'Business'">
            <tr>
              <td>{{ item.business.companyName }}</td>
              <td></td>
              <td>{{ scanStatus }}</td>
              <td></td>
            </tr>
            <tr v-for="(ubo, ui) in item.business.contacts">
              <td>{{ ubo.firstName }} {{ ubo.lastName }}</td>
              <td></td>
              <td>{{ scanStatus }}</td>
              <td></td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <div class="actions">
      <button v-on:click.prevent="goBack">Back</button>
      <button :disabled="!boardingResult" v-on:click.prevent="doNext">Save & Continue</button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["item"],
  data: ()=>({
  }),

  computed: {
    boardingResult() {
      for(var i = 0; i < this.item.complianceScans.length; i++) {
        if(this.item.complianceScans[i].status != 'Pass' && this.item.complianceScans[i].status != 'Skipped')
          return false
      }
      return true
    },

    scanStatus() {
      var scan = this.item.complianceScans.find(item =>  item.type == 'Scans')
      return scan.status
    }
  },

  methods: {
    goBack() {
      this.$emit("back");
    },
    doNext() {
      this.$emit("next");
    },
    async startStatus(complianceScan) {
      var updatedObj=(await api.update("TreasuryAccount", this.item.id,             {complianceScans: [
          {id: complianceScan.id, status: "Pass"}
        ]}, true )).item;
      this.item.complianceScans=updatedObj.complianceScans;
    },
    async skipStatus(complianceScan) {
      var updatedObj=(await api.update("TreasuryAccount", this.item.id,             {complianceScans: [
          {id: complianceScan.id, status: "Skipped"}
        ]}, true )).item;
      this.item.complianceScans=updatedObj.complianceScans;
    },
  },

  async created() {
    if(this.item.complianceScans.length == 0) {
        var updatedObj=(await api.update("TreasuryAccount", this.item.id, {complianceScans: [
          {type: "KYC Boarding Report", status: "Pending"},
          {type: "Scans", status: "Pending"},
        ]}, true )).item;
      this.item.complianceScans=updatedObj.complianceScans;
    }
  },
}
</script>
<style scoped>
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  margin-right: 8px;
  color: black;
  font-size: 16px;
  padding: 6px 20px;
  box-sizing: border-box;
  border: 1px solid #b3b3b3;
  border-radius: 6px;
  background: #e4e4e4;
  background: linear-gradient(to bottom, #e4e4e4 0%, #cfcfcf 100%);
}
button:hover, button:focus {
  background: #e9f2ff;
  background: linear-gradient(to bottom, #e9f2ff 0%, #deebff 100%);
  border-color: #6192fc;
  color: #6192fc;
}
</style>
