<template>
  <div class="ncontent nc-flat">
    <tabs class="tabs">
      <tab title="New Enrollment" active />
      <tab title="Merchants Overview" @click="openLink('/applications')" />
    </tabs>

    <div class="ncontent newappsp marginless">
      <div class="new-app-title">
        <h1>New Enrollment</h1>
        <p>What type of enrollment do you want to complete?</p>
      </div>
      <div class="partner-form">
        <div class="partner-item">
          <label>Solution</label>
          <dyn-select v-model="type" :options="solutions" />
        </div>
        <div class="partner-item">
          <label> Form </label>
          <dyn-select :disabled="!type" v-model="form" :options="forms" />
        </div>
        <template v-if="type && form">
          <div v-if="form == 'Merchant Qualification'">
            <div style="display: flex">
              <div class="partner-item">
                <a class="btn btn-small btn-green" :href="linkPre + type"
                  >Start Qualification</a
                >
                <a :href="pubLink" target="_blank">{{ pubLink }}</a>
              </div>
              <div class="partner-item">
                <button
                  class="btn btn-small btn-primary"
                  v-if="!links.Prequalification"
                  v-on:click.prevent="generateTempLink('Prequalification')"
                >
                  Get URL
                </button>
                <button
                  class="btn btn-small btn-primary"
                  v-else
                  v-on:click.prevent="doCopy(links.Prequalification)"
                >
                  Copy URL
                </button>
              </div>
              <div class="partner-item">
                <label> Send Email </label>
                <dyn-select v-model="emailType" :options="emailTypes" />
              </div>
            </div>

            <div class="partner-input">
              <div v-if="links.Prequalification">
                <input type="text" reaonly :value="links.Prequalification" />
              </div>

              <div v-if="emailType == 'New Signup'" class="form-link-ext">
                <application-signup
                  :disabled="!type"
                  type="Prequalification"
                  :solutions="solutions"
                  :solution="type"
                />
              </div>
            </div>
          </div>
          <div v-if="form == 'Merchant Application'">
            <div style="display: flex">
              <div class="partner-item">
                <a class="btn btn-small btn-green" :href="linkApp + type"
                  >Start Application</a
                >
                <a :href="pubLink" target="_blank">{{ pubLink }}</a>
              </div>
              <div class="partner-item">
                <button
                  class="btn btn-small btn-primary"
                  v-if="!links.Application"
                  v-on:click.prevent="generateTempLink('Application')"
                >
                  Get URL
                </button>
                <button
                  class="btn btn-small btn-primary"
                  v-else
                  v-on:click.prevent="doCopy(links.Application)"
                >
                  Copy URL
                </button>
              </div>
              <div class="partner-item">
                <label> Send Email </label>
                <dyn-select v-model="emailType" :options="emailTypes" />
              </div>
            </div>

            <div class="partner-input">
              <div v-if="links.Application">
                <input type="text" reaonly :value="links.Application" />
              </div>

              <div v-if="emailType == 'New Signup'" class="form-link-ext">
                <application-signup
                  :disabled="!type"
                  type="Application"
                  :solutions="solutions"
                  :solution="type"
                />
              </div>
            </div>
          </div>
          <div v-if="form == 'Facilitator Application'">
            <div style="display: flex">
              <div class="partner-item">
                <a class="btn btn-small btn-green" :href="linkFac + type"
                  >Start Application</a
                >
                <a :href="pubLink" target="_blank">{{ pubLink }}</a>
              </div>
              <div class="partner-item">
                <button
                  class="btn btn-small btn-primary"
                  v-if="!links.Facilitator"
                  v-on:click.prevent="generateTempLink('Facilitator')"
                >
                  Get URL
                </button>
                <button
                  class="btn btn-small btn-primary"
                  v-else
                  v-on:click.prevent="doCopy(links.Facilitator)"
                >
                  Copy URL
                </button>
              </div>
              <div class="partner-item">
                <label> Send Email </label>
                <dyn-select v-model="emailType" :options="emailTypes" />
              </div>
            </div>

            <div class="partner-input">
              <div v-if="links.Facilitator">
                <input type="text" reaonly :value="links.Facilitator" />
              </div>

              <div v-if="emailType == 'New Signup'" class="form-link-ext">
                <application-signup
                  :disabled="!type"
                  type="Facilitator"
                  :solutions="solutions"
                  :solution="type"
                />
              </div>
            </div>
          </div>
        </template>
      </div>
      <div class="header new-app-header" style="display: none">
        <div class="toolbar" :class="{ 'two-way-split': isAgent }">
          <div>
            <div
              class="form-link-btn link-gen-preq"
              v-on:click.prevent="generateTempLink('Prequalification')"
              :class="{ generated: links.Prequalification }"
            >
              <strong>Prequalification Form</strong>
              <em>Generate URL</em>
              <div v-on:click.stop="">
                <input type="text" readonly :value="links.Prequalification" />
                <button v-on:click.prevent="doCopy(links.Prequalification)">
                  Copy
                </button>
              </div>
            </div>
            <div class="form-link-ext">
              <application-signup
                :disabled="!type"
                type="Prequalification"
                :solutions="solutions"
                :solution="type"
              />
            </div>
          </div>

          <div>
            <div
              class="form-link-btn link-gen-app"
              v-on:click.prevent="generateTempLink('Application')"
              :class="{ generated: links.Application }"
            >
              <strong>Merchant Application Form</strong>
              <em>Generate URL</em>
              <div>
                <input type="text" readonly :value="links.Application" />
                <button v-on:click.prevent="doCopy(links.Application)">
                  Copy
                </button>
              </div>
            </div>
            <div class="form-link-ext">
              <application-signup
                :disabled="!type"
                type="Application"
                :solutions="solutions"
                :solution="type"
              />
            </div>
          </div>

          <template v-if="!isAgent">
            <div>
              <div
                class="form-link-btn link-gen-app"
                v-on:click.prevent="generateTempLink('Facilitator')"
                :class="{ generated: links.Facilitator }"
              >
                <strong>Facilitator Application Form</strong>
                <em>Generate URL</em>
                <div>
                  <input type="text" readonly :value="links.Facilitator" />
                  <button v-on:click.prevent="doCopy(links.Facilitator)">
                    Copy
                  </button>
                </div>
              </div>
              <div class="form-link-ext">
                <application-signup
                  :disabled="!type"
                  type="Facilitator"
                  :solutions="solutions"
                  :solution="type"
                />
              </div>
            </div>
          </template>
        </div>
      </div>
      <div
        style="display: none"
        class="new-app-split"
        :class="{ 'two-way-split': isAgent }"
      >
        <div class="new-app-pre">
          <div>
            <h2>Merchant Qualification</h2>
            <p class="merchant-app-desc" v-show="false">
              Please select type of merchant qualification:
            </p>
          </div>
          <a
            v-if="type"
            class="btn btn-large ch-button btn-success"
            :href="linkPre + type"
            >Start Qualification</a
          >
          <button v-else class="btn btn-large ch-button btn-success" disabled>
            Start Qualification
          </button>
        </div>
        <div class="new-app-apply">
          <div>
            <h2>Merchant Application</h2>
            <p class="merchant-app-desc" v-show="false">
              Please select type of merchant application:
            </p>
            <dyn-select v-show="false" v-model="type" :options="solutions">
            </dyn-select>
          </div>
          <a
            v-if="type"
            class="btn btn-large ch-button btn-success"
            :href="linkApp + type"
            >Start Application</a
          >
          <button v-else class="btn btn-large ch-button btn-success" disabled>
            Start Application
          </button>
        </div>
        <template v-if="!isAgent">
          <div class="new-app-apply">
            <div>
              <h2>Facilitator Application</h2>
              <p class="merchant-app-desc" v-show="false">
                Please select type of merchant application:
              </p>
              <dyn-select v-show="false" v-model="type" :options="solutions">
              </dyn-select>
            </div>
            <a
              v-if="type"
              class="btn btn-large btn-green"
              :href="linkFac + type"
              >Start Application</a
            >
            <button v-else class="btn btn-large btn-green" disabled>
              Start Application
            </button>
          </div>
        </template>
      </div>
      <div class="new-application-note">
        <p>
          For assistance or more information, please contact
          <b>{{ company.applicationEmail }}</b> or
          <b>{{ company.servicesTel }}</b
          >. <br />
          You may also contact your Relationship Manager or Account Manager for
          assistance.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Tab from "../components/tabs/Tab";
import Tabs from "../components/tabs/Tabs";

export default {
  components: {
    Tab,
    Tabs,
  },

  props: ["partnerId", "isAgent", "company", "linkPre", "linkApp", "linkFac"],
  data: () => ({
    type: null,
    links: {
      Prequalification: null,
      Application: null,
      Facilitator: null,
    },
    form: null,
    emailType: null,
    emailTypes: [
      { value: null, label: "" },
      { value: "New Signup", label: "New Signup" },
    ],
    settings: appData.settings.settings,
    formPub: {
      "Merchant Qualification": "q",
      "Merchant Application": "m",
      "Facilitator Application": "f",
    },
    solutions: [
      {
        label: "",
        value: null,
      },
    ],
    partner: null,
  }),

  async created() {
    if (this.isAgent) {
      this.partner = (await api.get("Partner", this.partnerId)).item;
      if (this.partner.msolutions.length)
        var items = (
          await api.list("Solution", {
            solutionType: "Merchants",
            id: ["IN (" + this.partner.msolutions + ")"],
          })
        ).items;
      else
        var items = (await api.list("Solution", { solutionType: "Merchants" }))
          .items;
    } else {
      var items = (await api.list("Solution", { solutionType: "Merchants" }))
        .items;
    }

    for (var i in items)
      this.solutions.push({
        label: items[i].nameSolution,
        value: items[i].id,
      });
  },

  computed: {
    pubLink() {
      var solModId = this.type % 4096 ^ 2650;
      var solVal = 97 - (solModId % 97);
      var solCode =
        solVal.toString(16).padStart(2, "0") +
        solModId.toString(16).padStart(3, "0");
      return (
        location.origin + "/register/" + solCode + "-" + this.formPub[this.form]
      );
    },
    forms() {
      var forms = [{ value: null, label: "" }];
      if (this.settings?.enrollmentForms?.merchants) {
        forms.push(
          { value: "Merchant Qualification", label: "Merchant Qualification" },
          { value: "Merchant Application", label: "Merchant Application" }
        );
      }
      if (this.settings?.enrollmentForms?.facilitators) {
        forms.push({
          value: "Facilitator Application",
          label: "Facilitator Application",
        });
      }
      return forms;
    },
  },
  methods: {
    openLink(link) {
      window.location.href = link;
    },

    async generateTempLink(type) {
      if (!this.type) return;
      if (this.links[type]) return;
      var result = await api.action("TempLink", "new", "generate", {
        type: type,
        solution: this.type,
      });
      this.links[type] = result.action.link;
    },
    doCopy(text) {
      copyToClipboard.doCopyText(text);
    },
  },

  watch: {
    form: {
      handler() {
        this.emailType = null;
        this.links = {
          Prequalification: null,
          Application: null,
          Facilitator: null,
        };
      },
    },

    emailType: {
      handler() {
        if (this.emailType) {
          this.links = {
            Prequalification: null,
            Application: null,
            Facilitator: null,
          };
        }
      },
    },

    "links.Prequalification": {
      handler() {
        if (this.links.Prequalification) {
          this.emailType = null;
        }
      },
    },

    "links.Application": {
      handler() {
        if (this.links.Application) {
          this.emailType = null;
        }
      },
    },

    "links.Facilitator": {
      handler() {
        if (this.links.Facilitator) {
          this.emailType = null;
        }
      },
    },
  },
};
</script>
