<template>
    <form class="search-form">
      <h3>Search Options</h3>
      <div class="f2split">
        <div>
          <div class="field">
            <label>Account ID</label>
            <input type="text" v-model="search.accountId" />
          </div>
          <div class="field">
            <label>Source</label>
            <dyn-select v-model="search.source" :options="sourcesOpt" />
          </div>
          <div class="field">
            <label>Status</label>
            <dyn-select v-model="search.status" :options="statusesOpt" />
          </div>
        </div>
        <div>
          <div class="field">
            <label>Company Name</label>
            <input type="text" v-model="search.companyName" />
          </div>
          <div class="field">
            <label>Email</label>
            <input type="text" v-model="search.email" />
          </div>
          <div class="field">
            <label>Type</label>
            <dyn-select v-model="search.type" :options="types" />
          </div>
        </div>
      </div>

      <div class="f2split">
        <div class="buttons">
          <button class="btn btn-small btn-primary" v-on:click.prevent="updateResults">Search</button>
          <button class="btn btn-small btn-default" v-on:click.prevent="resetResults">Reset</button>
        </div>
        <div>
          &nbsp;
        </div>
      </div>
    </form>
</template>

<script>
export default {
  props: ["followup", "statuses"],
  data() { return {
    search: {
      source: "",
      status: "",
      accountId: "",
      companyName: "",
      email: "",
    },
    sources: [],
    types: [
      {value: null, label: "All"},
      {value: "mastermerchants", label: "Master Merchant"},
      {value: "merchants", label: "Merchant"},
      {value: "submerchants", label: "Sub-Merchant"},
    ],
  }; },
  computed: {
    statusesOpt() {
      var result=[{value: "", label: "(all)"}];
      for(var i in this.statuses)
        result.push({
          value: this.statuses[i].status,
          label: this.statuses[i].status+" - "+this.statuses[i].description});
      return result;
    },
    sourcesOpt() {
      var result=[{value: "", label: "(all)"}];
      for(var i in this.sources)
        result.push({
          value: this.sources[i].id,
          label: this.sources[i].companyName || this.sources[i].fullName});
      return result;
    }
  },
  methods: {
    updateResults() {
      var q={}; // TODO
      q.status=["LIKE", "M%"];
//       if (this.followup=='pending') {
//         q.status="M00";
//       }
      if (this.search.accountId) {
        q["app.accountId"]=this.search.accountId;
      } else {
        if (appData.currentUser.agentId)
          q["app.source"]=appData.currentUser.id;
        if (this.search.source)
          q["app.source"]=this.search.source;
        if (this.search.companyName)
          q["app.companyName"]=["LIKE", "%"+this.search.companyName+"%"];
        if (this.search.status)
          q.status=this.search.status;
        if (this.search.type=='facilitators')
          q.app={auxType: 'Facilitator'};
        if (this.search.type=='merchants')
          q.app={auxType: 'null'};
        if (this.search.type=='submerchants')
          q.app={auxType: 'Sub'};
      }
      var that=this;
      api.list("Merchant", q, ["id:ASC"]).then(function(data) {
        accordeonResults.items=data.items.map(function(a) {
          a.type="Application";
          for(var field in a.app)
            if (a[field]===undefined)
              a[field]=a.app[field];
          return a;
        });
        breakdownTable.items=data.items;
      });
    },

    resetResults() {
      var q={}; // TODO
      q.status=["LIKE", "M%"];
//       if (this.followup=='pending') {
//         q.status="M00";
//       }
      this.search = {
        source: "",
        status: "",
        accountId: "",
        companyName: "",
        email: ""
      };
      var that=this;
      api.list("Merchant", q, ["id:ASC"]).then(function(data) {
        accordeonResults.items=data.items.map(function(a) {
          a.type="Application";
          for(var field in a.app)
            if (a[field]===undefined)
              a[field]=a.app[field];
          return a;
        });
        breakdownTable.items=data.items;
      });
    },
  },
  mounted() {
    this.sources=JSON.parse(JSON.stringify(appData.sources));
    this.updateResults();
  },
}
</script>
