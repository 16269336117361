<template>
<div class="amount-currency">
  <formel-money-amount :value="amount" v-on:input="$emit('update:amount', $event)" v-on:keydown="keyDown" />
  <dyn-select :value="currency" v-on:input="$emit('update:currency', $event)" :options="currencies" />
</div>
</template>

<script>
export default {
  props: ["amount", "currency"],
  mixins: [vueMixins.currency],
  methods: {
    keyDown(event) {
//       if (["0", "1", "2", "3", "4", "5",
//            "6", "7", "8", "9", ".",
//            "ArrowLeft", "ArrowRight",
//            "ArrowUp", "ArrowDown",
//            "Backspace", "Delete",
//            "Shift", "Tab", "Home", "End"].indexOf(event.key)==-1)
//         event.preventDefault();
    },
  },
  mounted() {
    if (this.currency==null)
      this.$emit('update:currency', "124");
  },
}
</script>
