<template>
<div class="preform-outer">
<div class="preformbg agent"></div>
<div class="appf preform ncontent pfx">
<div class="wcontent">
  <div class="cb-logo intake" :style="{'background-image': 'url(&quot;/files/company/'+companydata.intakeHeadLogo+'&quot;)' }">CardBiller</div>
  <h1>Merchant Profile Form</h1>
  <div class="form-info">
    <p>{{ companydata.intakeHeadText }}</p>
    <p><strong>{{ companydata.intakeHeadEmail }}</strong></p>
    <p><strong>{{ companydata.intakeHeadPhone }}</strong></p>
  </div>
  <p class="form-intro">
    {{ companydata.intakeHeadDesc }}
  </p>
  
  <form class="form-step h3lg quest intake" v-if="step<4" v-for="q in [item.formData]">
    <section>
    <h2>Required to be completed by Partner prior to underwriting</h2>
    <h3>General</h3>
    <ul class="column-form type2">
      <li>
        <label>Solution</label>
        <div class="field">
          <validator :value="item.solution" reqs="required">
            <dyn-select disabled :options="solutions" v-model="item.solution" />
          </validator>
        </div>
      </li>
      <li>
        <label>Submit Date</label>
        <div class="field">
          <validator :value="item.submitted" reqs="required">
            <input type="text" disabled v-model="item.submitted" />
          </validator>
        </div>
      </li>
      <li>
        <label>MCC Code</label>
        <div class="field">
          <validator :value="q.mcc" reqs="required">
            <mcc-edit v-model="q.mcc" :short="true" />
          </validator>
        </div>
      </li>
      <li></li>
    </ul>
    
    <h3>Partner Information</h3>
    <ul class="column-form type2">
      <li>
        <label>Partner ID</label>
        <div class="field">
          <validator :value="''" reqs="">
            <input readonly type="text" :value="q.source && q.source.agentId" />
          </validator>
        </div>
      </li>
      <li>
        <label>Partner Company</label>
        <div class="field">
          <validator :value="''" reqs="">
            <input readonly type="text" :value="q.source && q.source.agentDb && q.source.agentDb.name" />
          </validator>
        </div>
      </li>
      <li>
        <label>Rep Name</label>
        <div class="field">
          <validator :value="''" reqs="">
            <input readonly type="text" :value="q.source && q.source.contacts && q.source.contacts[0] && (q.source.contacts[0].firstName+' '+q.source.contacts[0].lastName)" />
          </validator>
        </div>
      </li>
      <li></li>
    </ul>
    
    <h3>Merchant Information<span>ACCOUNT:<em>{{ q.accountId }}</em></span></h3>
    <ul class="column-form type2">
      <li>
        <label>Business Name</label>
        <div class="field">
          <validator :value="q.companyName" reqs="">
            <input readonly type="text" v-model="q.companyName" />
          </validator>
        </div>
      </li>
      <li>
        <label>Business DBA Name</label>
        <div class="field">
          <validator :value="q.companyNameDba" reqs="">
            <input readonly type="text" v-model="q.companyNameTrade" />
          </validator>
        </div>
      </li>
      <li>
        <label>Country</label>
        <div class="field">
          <validator :value="q.companyAddressCountry" reqs="">
            <input readonly type="text" v-model="q.companyAddressCountry" />
          </validator>
        </div>
      </li>
      <li>
        <label>Contact</label>
        <div class="field"> <!-- TODO -->
          <validator :value="q.companyNameDba" reqs="">
            <input readonly type="text" :value="q.contacts && (q.contacts[0].firstName+' '+q.contacts[0].lastName)" />
          </validator>
        </div>
      </li>
      <li>
        <label>Email</label>
        <div class="field">
          <validator :value="q.companyEmail" reqs="">
            <input readonly type="text" v-model="q.companyEmail" />
          </validator>
        </div>
      </li>
      <li>
        <label>Phone</label>
        <div class="field">
          <validator :value="q.companyPhone" reqs="">
            <input readonly type="text" v-model="q.companyPhone" />
          </validator>
        </div>
      </li>
      <li></li>
      <li></li>
    </ul>
    
    <h3>Site Survey</h3>
    <ul class="column-form type2">
      <li class="fwidth fwx">
        <label>Zoning</label>
        <div class="field">
          <validator :value="q.zoning&&q.zoning.length" reqs="required">
            <multiselect v-model="q.zoning" :options="['Business', 'Industrial', 'Residential']" :onlyone="true" />
          </validator>
        </div>
      </li>
      <li class="fwidth fwx">
        <label>Location</label>
        <div class="field">
          <validator :value="q.location&&q.location.length" reqs="required">
            <multiselect v-model="q.location" :options="['Mall', 'Office', 'Shopping Area', 'Mix', 'Home', 'Apt', 'Isolated']" :onlyone="true" />
          </validator>
        </div>
      </li>
      <li class="fwidth fwx">
        <label>Sq Ft</label>
        <div class="field">
          <validator :value="q.sqft&&q.sqft.length" reqs="required">
            <multiselect v-model="q.sqft" :options="['0-250', '251-500', '501-2000', '2001+']" :onlyone="true" />
          </validator>
        </div>
      </li>
    </ul>
    <h3>Pricing template</h3>
    <ul class="column-form type2">
      <li class="fwidth fwx">
        <div class="field">
          <dyn-select :options="currentTemplates" v-model="templateId"  style="width: 100%; margin-bottom: 20px"  />
        </div>
      </li>
    </ul>
    <pricing-details class="intake-pricing" :currentTemplate="q" />
    <h3 v-if="false">Payment Methods</h3>
    <ul v-if="false" class="column-form type2">
      <li class="fwidth fwx">
        <label>CardBiller</label>
        <div class="field">
          <validator :value="(q.cardbiller&&q.cardbiller.length) || (q.cardbillerPlus&&q.cardbillerPlus.length) || (q.bankbiller&&q.bankbiller.length) || (q.coinbiller&&q.coinbiller.length)" reqs="boolean">
            <multiselect v-model="q.cardbiller" :options="['Visa', 'MasterCard', 'Discover', 'AMEX', 'China UnionPay', 'Interac Debit']" />
          </validator>
        </div>
      </li>
      <li class="fwidth fwx">
        <label>CardBiller Plus</label>
        <div class="field">
          <validator :value="(q.cardbiller&&q.cardbiller.length) || (q.cardbillerPlus&&q.cardbillerPlus.length) || (q.bankbiller&&q.bankbiller.length) || (q.coinbiller&&q.coinbiller.length)" reqs="boolean">
            <multiselect v-model="q.cardbillerPlus" :options="['Apple Pay', 'Google Pay', 'Interac Online']" />
          </validator>
        </div>
      </li>
      <li class="fwidth fwx">
        <label>BankBiller</label>
        <div class="field">
          <validator :value="(q.cardbiller&&q.cardbiller.length) || (q.cardbillerPlus&&q.cardbillerPlus.length) || (q.bankbiller&&q.bankbiller.length) || (q.coinbiller&&q.coinbiller.length)" reqs="boolean">
            <multiselect v-model="q.bankbiller" :options="['EFT', 'ACH']" />
          </validator>
        </div>
      </li>
      <li class="fwidth fwx" v-if="false">
        <label>CoinBiller</label>
        <div class="field">
          <validator :value="(q.cardbiller&&q.cardbiller.length) || (q.cardbillerPlus&&q.cardbillerPlus.length) || (q.bankbiller&&q.bankbiller.length) || (q.coinbiller&&q.coinbiller.length)" reqs="boolean">
            <multiselect v-model="q.coinbiller" :options="['BitCoin']" />
          </validator>
        </div>
      </li>
      <li class="fwidth fwx">
        <label>Other</label>
        <div class="field">
          <validator :value="q.cardTypesOther" reqs="">
            <multiselect-desc v-model="q.cardTypesOtherFlag" :options="['']">
              <input type="text" v-model="q.cardTypesOther" :disabled="!q.cardTypesOtherFlag || q.cardTypesOtherFlag.length==0" />
            </multiselect-desc>
          </validator>
        </div>
      </li>
    </ul>
    
    <h3 v-if="false">Pricing &amp; Options</h3>
    
    <ul v-if="false" class="column-form type2">
      <li class="fwidth fwx">
        <label>Monthly Plan</label>
        <div class="field">
          <validator :value="q.marketingMethod&&q.marketingMethod.length?q.marketingMethod:q.marketingMethodOther" reqs="required">
            <multiselect v-model="q.marketingMethod" :other.sync="q.marketingMethodOther" :options="['Simple', 'Prime', 'Axis (E-Commerce)']" :onlyone="true" />
          </validator>
        </div>
      </li>
      <li class="intake-title">Service Fees</li>
      <li class="">
        <label>Setup Fee</label>
        <div class="field complex">
          <validator :value="q.setupFee" reqs="">
            <div class="intake-field">
               <formel-money-amount type="text" prefix="$" v-model="q.setupFee" />
              
            </div>
          </validator>
        </div>
      </li>
      <li class="">
        <label>Annual Fee</label>
        <div class="field complex">
          <validator :value="q.depositRequired" reqs="">
            <div class="intake-field">
               <formel-money-amount type="text" prefix="$" v-model="q.annualFee" />
              
            </div>
          </validator>
        </div>
      </li>
      <li></li><li></li>
      <li class="intake-title">
        <label class="checkbox" :class="{checked: q.pricingOption=='flat'}"><input type="radio" v-model="q.pricingOption" value="flat" v-on:click="deselectSelected('pricingOption', 'flat')" /><span></span>Flat Rate</label>
      </li>
      <li class="">
        <label>Per Swiped / Tapped / Inserted</label>
        <div class="field complex">
          <validator :value="q.depositRequired" reqs="">
            <div class="intake-field split">
              <formel-money-amount :disabled="q.pricingOption!='flat'" v-model="q.flatInserted" type="text" suffix="%" /> + <formel-money-amount :disabled="q.pricingOption!='flat'" type="text" prefix="$" v-model="q.flatInsertedFee" />
              
            </div>
          </validator>
        </div>
      </li>
      <li class="">
        <label>E-Commerce / Keyed</label>
        <div class="field complex">
          <validator :value="q.depositRequired" reqs="">
            <div class="intake-field split">
              <formel-money-amount :disabled="q.pricingOption!='flat'" v-model="q.flatKeyed" type="text" suffix="%" /> + <formel-money-amount :disabled="q.pricingOption!='flat'" type="text" prefix="$" v-model="q.flatKeyedFee" />
              
            </div>
          </validator>
        </div>
      </li>
      <li></li><li></li>
      <li class="intake-title">
        <label class="checkbox" :class="{checked: q.pricingOption=='iplus'}"><input type="radio" v-model="q.pricingOption" value="iplus" v-on:click="deselectSelected('pricingOption', 'iplus')" /><span></span>Interchange Plus</label>
      </li>
      <li class="">
        <label>Per Swiped / Tapped / Inserted</label>
        <div class="field complex">
          <validator :value="q.depositRequired" reqs="">
            <div class="intake-field split">
              <formel-money-amount :disabled="q.pricingOption!='iplus'" v-model="q.iplusInserted" type="text" suffix="%" /> + <formel-money-amount :disabled="q.pricingOption!='iplus'" type="text" prefix="$" v-model="q.iplusInsertedFee" />
              
            </div>
          </validator>
        </div>
      </li>
      <li class="">
        <label>E-Commerce / Keyed</label>
        <div class="field complex">
          <validator :value="q.depositRequired" reqs="">
            <div class="intake-field split">
              <formel-money-amount :disabled="q.pricingOption!='iplus'" v-model="q.iplusKeyed" type="text" suffix="%" /> + <formel-money-amount :disabled="q.pricingOption!='iplus'" type="text" prefix="$" v-model="q.iplusKeyedFee" />
              
            </div>
          </validator>
        </div>
      </li>
      <li></li><li></li>
      <li class="intake-title">
        <label class="checkbox" :class="{checked: q.pricingOption=='tiered'}"><input type="radio" v-model="q.pricingOption" value="tiered" v-on:click="deselectSelected('pricingOption', 'tiered')" /><span></span>Tiered</label>
      </li>
      <li class="">
        <label>Qualified</label>
        <div class="field complex">
          <validator :value="q.depositRequired" reqs="">
            <div class="intake-field">
              <formel-money-amount :disabled="q.pricingOption!='tiered'" v-model="q.tieredQualified" type="text" suffix="%" />
              
            </div>
          </validator>
        </div>
      </li>
      <li class="">
        <label>Mid-Qualified</label>
        <div class="field complex">
          <validator :value="q.depositRequired" reqs="">
            <div class="intake-field">
              <formel-money-amount :disabled="q.pricingOption!='tiered'" v-model="q.tieredMidQualified" type="text" suffix="%" />
              
            </div>
          </validator>
        </div>
      </li>
      <li class="">
        <label>Non-Qualified</label>
        <div class="field complex">
          <validator :value="q.depositRequired" reqs="">
            <div class="intake-field">
              <formel-money-amount :disabled="q.pricingOption!='tiered'" v-model="q.tieredNonQualified" type="text" suffix="%" />
              
            </div>
          </validator>
        </div>
      </li>
      <li class="">
        <label>Auth Fee</label>
        <div class="field complex">
          <validator :value="q.depositRequired" reqs="">
            <div class="intake-field">
              <formel-money-amount :disabled="q.pricingOption!='tiered'" type="text" prefix="$" v-model="q.tieredAuthFee" />
              
            </div>
          </validator>
        </div>
      </li>
      <li class="intake-title">
        <label class="checkbox" :class="{checked: q.optInteracDebit}"><input type="checkbox" v-model="q.optInteracDebit" /><span></span>Interac Debit</label>
      </li>
      <li class="">
        <label>Per Swiped / Inserted</label>
        <div class="field complex">
          <validator :value="q.depositRequired" reqs="">
            <div class="intake-field">
              <formel-money-amount :disabled="!q.optInteracDebit" type="text" prefix="$" v-model="q.interacInserted" :digits="3" />
            </div>
          </validator>
        </div>
      </li>
      <li class="">
        <label>Tapped/Flash</label>
        <div class="field complex">
          <validator :value="q.depositRequired" reqs="">
            <div class="intake-field">
              <formel-money-amount :disabled="!q.optInteracDebit" type="text" prefix="$" v-model="q.interacTapped" :digits="3" />
            </div>
          </validator>
        </div>
      </li>
      <li></li><li></li>
      
      <li class="intake-title">
        <label class="checkbox" :class="{checked: q.optCardbillerPlus}"><input type="checkbox" v-model="q.optCardbillerPlus" /><span></span>CardBiller Plus</label>
      </li>
      <li class="">
        <label>Apple Pay / Google Pay</label>
        <div class="field complex">
          <validator :value="q.depositRequired" reqs="">
            <div class="intake-field split">
              <formel-money-amount :disabled="!q.optCardbillerPlus" v-model="q.cbPlusPay" type="text" suffix="%" /> + <formel-money-amount :disabled="!q.optCardbillerPlus" type="text" prefix="$" v-model="q.cbPlusPayFee" />
              
            </div>
          </validator>
        </div>
      </li>
      <li class="">
        <label>Interac Online</label>
        <div class="field complex">
          <validator :value="q.depositRequired" reqs="">
            <div class="intake-field">
              <formel-money-amount :disabled="!q.optCardbillerPlus" type="text" prefix="$" v-model="q.cbPlusInterac" />
              
            </div>
          </validator>
        </div>
      </li>
      <li></li><li></li>
      
      <li class="intake-title">
        <label class="checkbox" :class="{checked: q.optBankbiller}"><input type="checkbox" v-model="q.optBankbiller" /><span></span>BankBiller</label>
      </li>
      <li class="">
        <label>Monthly Fee</label>
        <div class="field complex">
          <validator :value="q.depositRequired" reqs="">
            <div class="intake-field">
              <formel-money-amount :disabled="!q.optBankbiller" type="text" prefix="$" v-model="q.bbMonthly" />
              
            </div>
          </validator>
        </div>
      </li>
      <li class="">
        <label>Discount Rate</label>
        <div class="field complex">
          <validator :value="q.depositRequired" reqs="">
            <div class="intake-field">
              <formel-money-amount :disabled="!q.optBankbiller" type="text" suffix="%" v-model="q.bbDiscount" />
              
            </div>
          </validator>
        </div>
      </li>
      <li class="">
        <label>Trx Fee</label>
        <div class="field complex">
          <validator :value="q.depositRequired" reqs="">
            <div class="intake-field">
              <formel-money-amount :disabled="!q.optBankbiller" type="text" prefix="$" v-model="q.bbTrx" />
              
            </div>
          </validator>
        </div>
      </li>
      <li class="">
        <label>Rejects Fee</label>
        <div class="field complex">
          <validator :value="q.depositRequired" reqs="">
            <div class="intake-field">
              <formel-money-amount :disabled="!q.optBankbiller" type="text" prefix="$" v-model="q.bbRejects" />
              
            </div>
          </validator>
        </div>
      </li>
      <li class="">
        <label>Returns Fee</label>
        <div class="field complex">
          <validator :value="q.depositRequired" reqs="">
            <div class="intake-field">
              <formel-money-amount :disabled="!q.optBankbiller" type="text" prefix="$" v-model="q.bbReturns" />
              
            </div>
          </validator>
        </div>
      </li>
      <li class="">
        <label>Unauth Fee</label>
        <div class="field complex">
          <validator :value="q.depositRequired" reqs="">
            <div class="intake-field">
              <formel-money-amount :disabled="!q.optBankbiller" type="text" prefix="$" v-model="q.bbUnauth" />
              
            </div>
          </validator>
        </div>
      </li>
      <li class="">
        <label>Chargeback Fee</label>
        <div class="field complex">
          <validator :value="q.depositRequired" reqs="">
            <div class="intake-field">
              <formel-money-amount :disabled="!q.optBankbiller" type="text" prefix="$" v-model="q.bbLate" />
              
            </div>
          </validator>
        </div>
      </li>
      <li class="">
        <label>Non-Compliance</label>
        <div class="field complex">
          <validator :value="q.depositRequired" reqs="">
            <div class="intake-field">
              <formel-money-amount :disabled="!q.optBankbiller" type="text" prefix="$" v-model="q.bbNonCompliance" />
              
            </div>
          </validator>
        </div>
      </li>
      <li class="intake-title" v-if="false">
        <label class="checkbox" :class="{checked: q.optCoinbiller}"><input type="checkbox" v-model="q.optCoinbiller" /><span></span>CoinBiller</label>
      </li>
      <li class="">
        <label>Monthly Fee</label>
        <div class="field complex">
          <validator :value="q.depositRequired" reqs="">
            <div class="intake-field">
              <formel-money-amount :disabled="!q.optCoinbiller" type="text" prefix="$" v-model="q.cbMonthly" />
              
            </div>
          </validator>
        </div>
      </li>
      <li class="">
        <label>Discount Rate</label>
        <div class="field complex">
          <validator :value="q.depositRequired" reqs="">
            <div class="intake-field">
              <formel-money-amount :disabled="!q.optCoinbiller" type="text" suffix="%" v-model="q.cbDiscount" />
              
            </div>
          </validator>
        </div>
      </li>
      <li class="">
        <label>Trx Fee</label>
        <div class="field complex">
          <validator :value="q.depositRequired" reqs="">
            <div class="intake-field">
              <formel-money-amount :disabled="!q.optCoinbiller" type="text" prefix="$" v-model="q.cbTrx" />
              
            </div>
          </validator>
        </div>
      </li>
      <li></li>
    </ul>
    
    <h3 v-if="false">ACCOUNT SETTINGS</h3>
    
    <ul v-if="false" class="column-form type2">
      <!--<li class="intake-title">Modules</li>-->
      <li class="fwidth fwx">
        <label>Modules</label>
        <div class="field">
          <validator :value="q.accSet1&&q.accSet1.length" reqs="">
            <multiselect-desc v-model="q.accSet1" :options="['Chammp Billing']">
              $15.00 Monthly - Included with Prime and Axis Plan
            </multiselect-desc>
          </validator>
        </div>
      </li>
      <li class="fwidth fwx">
        <label>Settings</label>
        <div class="field">
          <validator :value="q.accSet2&&q.accSet2.length" reqs="">
            <multiselect-desc v-model="q.accSet2" :options="['PayAxis Gateway']">
              $25.00 Monthly – Included with Prime and Axis Plan
            </multiselect-desc>
          </validator>
        </div>
      </li>
      <li class="fwidth fwx">
        <label>Settings</label>
        <div class="field">
          <validator :value="q.accSet3&&q.accSet3.length" reqs="">
            <multiselect-desc v-model="q.accSet3" :options="['Virtual Terminal']">
              $15.00 Monthly - Included with Prime and Axis Plan
            </multiselect-desc>
          </validator>
        </div>
      </li>
      <li class="fwidth fwx">
        <label>Settings</label>
        <div class="field">
          <validator :value="q.accSet4&&q.accSet4.length" reqs="">
            <multiselect-desc v-model="q.accSet4" :options="['Chammp Checkout']">
              $15.00 Monthly - Included with Prime and Axis Plan
            </multiselect-desc>
          </validator>
        </div>
      </li>
      <li class="fwidth fwx">
        <label>Settings</label>
        <div class="field">
          <validator :value="q.accSet5&&q.accSet5.length" reqs="">
            <multiselect-desc v-model="q.accSet5" :options="['Multi-Currency']">
              $20.00 Monthly – Cost is Per TID
            </multiselect-desc>
          </validator>
        </div>
      </li>
      <li class="fwidth fwx">
        <label>Settings</label>
        <div class="field">
          <validator :value="q.accSet6&&q.accSet6.length" reqs="">
            <multiselect-desc v-model="q.accSet6" :options="['Recurring & Subscription']">
              Only available with Prime and Axis Plan
            </multiselect-desc>
          </validator>
        </div>
      </li>
      <li class="fwidth fwx">
        <label>Settings</label>
        <div class="field">
          <validator :value="q.accSet7&&q.accSet7.length" reqs="">
            <multiselect-desc v-model="q.accSet7" :options="['Card Tokenization']">
              <validator :value="q.cardTokenization" :reqs="(q.accSet7&&q.accSet7.length)?'required':''">
              <formel-money-amount :disabled="!(q.accSet7&&q.accSet7.length)" type="text" prefix="$" v-model="q.cardTokenization" style="width: 70px; padding: 0 2px" /> Per Card - Only available with Prime and Axis Plan; Includes Account Updater
              </validator>
            </multiselect-desc>
          </validator>
        </div>
      </li>
      <li class="fwidth fwx">
        <label>TID Activations</label>
        <div class="field">
          <validator :value="q.accSetTid" reqs="required">
            <input type="text" v-model="q.accSetTid" style="width: 60px">
          </validator>
        </div>
      </li>
      <li class="fwidth fwx">
        <label>Account Add-Ons</label>
        <div class="field">
          <validator :value="q.accSet8&&q.accSet8.length" reqs="">
            <multiselect-desc v-model="q.accSet8" :options="['Vivpos']">
              $69.00 Monthly - Per License
            </multiselect-desc>
          </validator>
        </div>
      </li>
      <li class="fwidth fwx">
        <label>Gift & Loyalty</label>
        <div class="field">
          <validator :value="q.accSet9&&q.accSet9.length" reqs="">
            <multiselect-desc v-model="q.accSet9" :options="['CHAMMP Rewards']">
              $59.00 Monthly – Cost is for program access only.
            </multiselect-desc>
          </validator>
        </div>
      </li>
      <li class="fwidth fwx">
        <label>Statements</label>
        <div class="field">
          <validator :value="q.accSet10&&q.accSet10.length" reqs="">
            <multiselect v-model="q.accSet10" :options="['Mailed', 'Portal']" :onlyone="true">
            </multiselect>
          </validator>
        </div>
      </li>
    </ul>
    
    
    <!--CHANGES FROM HERE-->
    <h3>EQUIPMENT</h3>
    
    <ul class="column-form type2">
      <li class="fwidth fwx" v-if="false">
        <label>Special Offers</label>
        <div class="field">
          <validator :value="q.specialOffer&&q.specialOffer.length" reqs="">
            <multiselect-desc v-model="q.specialOffer" :options="['Free Terminal Program']">
              Must ensure that merchant meets program criteria.
            </multiselect-desc>
          </validator>
        </div>
      </li>
      
      <template v-for="ti in terminals">
      <li class="intake-title">Terminals Type #{{ti}}</li>
      <li class="" style="width: calc(19.75% - 5px)">
        <label>Model</label>
        <div class="field complex">
          <validator :value="q['terminal'+ti+'Type']" reqs="">
            <dyn-select  v-model="q['terminal'+ti+'Type']" :options="equipmentOpt" /> <br />
            ${{ (equi(ti)[q['terminal'+ti+'PurchaseOpt']?'purchase':(q['terminal'+ti+'RentalOpt']?'rental':'reprogram')] || 0).toFixed(2) }}
<!--             <input type="text" v-model="q['terminal'+ti+'Type']" /> -->
          </validator>
        </div>
      </li>
      <li class="" style="width: calc(6.25% - 5px)">
        <label>QTY</label>
        <div class="field complex">
          <validator :value="q['terminal'+ti+'Qty']" reqs="">
            <input type="text" v-model="q['terminal'+ti+'Qty']" />
          </validator>
        </div>
      </li>
      <li class="" style="width: calc(12% - 5px)">
        <label>Purchase</label>
        <div class="field conditional">
          <checkbox-plain2 v-model="q['terminal'+ti+'PurchaseOpt']" v-on:input="$set(q, 'terminal'+ti+'RentalOpt', false); $set(q, 'terminal'+ti+'LeasingOpt', false); $set(q, 'terminal'+ti+'ReprogramOpt', false)" :disabled="!equi(ti).purchase" />
<!--           <span>Qty:</span> -->
<!--           <input type="text" v-model="q['terminal'+ti+'Purchase']" :disabled="!q['terminal'+ti+'PurchaseOpt']" /> -->
        </div>
      </li>
      <li class="" style="width: calc(12% - 5px)">
        <label>Rental</label>
        <div class="field conditional">
          <checkbox-plain2 v-model="q['terminal'+ti+'RentalOpt']" v-on:input="$set(q, 'terminal'+ti+'PurchaseOpt', false); $set(q, 'terminal'+ti+'LeasingOpt', false); $set(q, 'terminal'+ti+'ReprogramOpt', false)" :disabled="!equi(ti).rental" />
<!--           <span>Qty:</span> -->
<!--           <input type="text" v-model="q['terminal'+ti+'Rental']" :disabled="!q['terminal'+ti+'RentalOpt']" /> -->
        </div>
      </li>
      <li class="" style="width: calc(12% - 5px)">
        <label>Reprogram</label>
        <div class="field conditional">
          <checkbox-plain2 v-model="q['terminal'+ti+'ReprogramOpt']" v-on:input="$set(q, 'terminal'+ti+'PurchaseOpt', false); $set(q, 'terminal'+ti+'RentalOpt', false); $set(q, 'terminal'+ti+'LeasingOpt', false)" />
<!--           <span>Qty:</span> -->
<!--           <input type="text" v-model="q['terminal'+ti+'Reprogram']" :disabled="!q['terminal'+ti+'ReprogramOpt']" /> -->
        </div>
      </li>
      <li class="" style="width: calc(12% - 5px)">
        <label>Leasing</label>
        <div class="field conditional">
          <checkbox-plain2 disabled v-model="q['terminal'+ti+'LeasingOpt']" v-on:input="$set(q, 'terminal'+ti+'PurchaseOpt', false); $set(q, 'terminal'+ti+'RentalOpt', false); $set(q, 'terminal'+ti+'ReprogramOpt', false)" />
<!--           <span>Qty:</span> -->
<!--           <input type="text" v-model="q['terminal'+ti+'Leasing']" :disabled="!q['terminal'+ti+'LeasingOpt']" /> -->
        </div>
      </li>
      <li v-if="false" class="" style="width: calc(12% - 5px)">
        <label>Merchant Own</label>
        <div class="field conditional">
          <checkbox-plain2 v-model="q['terminal'+ti+'OwnOpt']" />
          <span>Qty:</span>
          <input type="text" v-model="q['terminal'+ti+'Own']" :disabled="!q['terminal'+ti+'OwnOpt']" />
        </div>
      </li>
      <li v-if="false" class="" style="width: calc(12% - 5px)">
        <label>Financing</label>
        <div class="field conditional">
          <checkbox-plain2 v-model="q['terminal'+ti+'FinancingOpt']" />
          <span>Qty:</span>
          <input type="text" v-model="q['terminal'+ti+'Financing']" :disabled="!q['terminal'+ti+'FinancingOpt']" />
        </div>
      </li>
      <li v-if="false" class="" style="width: calc(12% - 5px)">
        <label>Insurance</label>
        <div class="field conditional">
          <checkbox-plain2 v-model="q['terminal'+ti+'InsuranceOpt']" />
          <span>Qty:</span>
          <input type="text" v-model="q['terminal'+ti+'Insurance']" :disabled="!q['terminal'+ti+'InsuranceOpt']" />
        </div>
      </li>
      </template>
      <li style="width: 100%">
        <a class="terminals-del" href="" v-on:click.prevent="terminalCount--" v-if="terminalCount>1">‒</a>
        <a class="terminals-add" href="" v-on:click.prevent="terminalCount++">+</a>
      </li>
      
      
      <li class="fwidth fwx">
        <label>Communication</label>
        <div class="field">
          <validator :value="q.communicationMethod&&q.communicationMethod.length" reqs="">
            <multiselect v-model="q.communicationMethod" :options="['IP', 'Wireless', 'SIM Card Required', 'Batch Auto Close Time!time', 'OnSite Service Requested']" :time.sync="q.communicationTime">
            </multiselect>
          </validator>
        </div>
      </li>
      
      <li class="intake-title">Shipping</li>
      <li class="fwidth fwx">
        <label>Ship to</label>
        <div class="field">
          <validator :value="q.shipTo&&q.shipTo.length?q.shipTo:q.shipToOther" reqs="">
            <multiselect v-model="q.shipTo" :other.sync="q.shipToOther" :options="['Company', 'Location', 'Other']" :onlyone="true" />
          </validator>
        </div>
      </li>
      <li class="fwidth fwx">
        <label>Shipping Method</label>
        <div class="field">
          <validator :value="q.shippingMethod&&q.shippingMethod.length" reqs="">
            <multiselect v-model="q.shippingMethod" :options="['Regular', '2 Day', 'Next Day', 'Overnight']" :onlyone="true" />
          </validator>
        </div>
      </li>
      <li class="">
        <label>Ship to Other Address</label>
        <div class="field complex">
          <validator :value="q.shipOtherAddress" :reqs="q.shipTo && q.shipTo.indexOf('Other')!=-1?'required':''">
            <input type="text" v-model="q.shipOtherAddress" :disabled="!q.shipTo || q.shipTo.indexOf('Other')==-1" />
          </validator>
        </div>
      </li>
      <li class="">
        <label>City</label>
        <div class="field complex">
          <validator :value="q.shipOtherCity" :reqs="q.shipTo && q.shipTo.indexOf('Other')!=-1?'required':''">
            <input type="text" v-model="q.shipOtherCity" :disabled="!q.shipTo || q.shipTo.indexOf('Other')==-1" />
          </validator>
        </div>
      </li>
      <li class="">
        <label>Province/State</label>
        <div class="field complex">
          <validator :value="q.shipOtherState" :reqs="q.shipTo && q.shipTo.indexOf('Other')!=-1?'required':''">
            <input type="text" v-model="q.shipOtherState" :disabled="!q.shipTo || q.shipTo.indexOf('Other')==-1" />
          </validator>
        </div>
      </li>
      <li class="">
        <label>Postal Code/Zip</label>
        <div class="field complex">
          <validator :value="q.shipOtherPostal" :reqs="q.shipTo && q.shipTo.indexOf('Other')!=-1?'required':''">
            <input type="text" v-model="q.shipOtherPostal" :disabled="!q.shipTo || q.shipTo.indexOf('Other')==-1" />
          </validator>
        </div>
      </li>
      <li class="" style="width: 100%">
        <label>Instructions</label>
        <div class="field complex">
          <validator :value="q.shipOtherInstructions" :reqs="''">
            <input type="text" v-model="q.shipOtherInstructions" />
          </validator>
        </div>
      </li>
    </ul>

    </section>
    
    
   

    <section>
      <h2>
        <em>DISCLOSURE STATEMENTS</em>
      </h2>
      <ul class="disc-stat">
        <li>
          <validator :value="q.terms" reqs="boolean">
            <div class="element-toggle slin"><label :class="{checked: q.terms}"><span></span> <input type="checkbox" v-model="q.terms"><span>I acknowledge that all terms and conditions of service were clearly explained to merchant.</span></label></div>
          </validator>
        </li>
        <li>
          <validator :value="q.conduct" reqs="boolean">
            <div class="element-toggle slin"><label :class="{checked: q.conduct}"><span></span> <input type="checkbox" v-model="q.conduct"><span>I acknowledge that I followed the Code of Conduct Policy when offering these products and/or services.</span></label></div>
          </validator>
        </li>
      </ul>
    </section>


  </form>
    <div class="pre-done" v-else-if="step==4">
      <h1>Thank you for submitting the merchant profile form!</h1>
      <p></p>
      <a v-if="!guest" href="/" class="btn btn-large btn-primary btn-black">Back to Dashboard</a>
    </div>
    <div class="buttons" v-if="step!=4">
      <button class="btn btn-large btn-primary" v-on:click.prevent="send" :disabled="sending">Submit</button>
    </div>
  
</div>
</div>
</div>
</template>

<script>
export default {
  props: ["id"],
  data() { return {
    step: 0,
    terminalCount: 1,
    industries: [
      {class: "ac01", label: "ISO", value: "agent-iso"},
      {class: "ac02", label: "Independent Sales Agent", value: "agent-isa"},
      {class: "ac03", label: "Authorized Agent", value: "agent-auth"},
      {class: "ac04", label: "Referral Partners / ISV", value: "agent-ref"},
      {class: "ac03", label: "Account Executives", value: "agent-accex"},
      {class: "ac04", label: "Sales Associates", value: "agent-sales"},
    ],
    validCountries: [
      {label: "Canada", value: "Canada"},
//       {label: "United States", value: "United States"},
    ],
    validProvinces: [
      {label: "AB - Alberta", value: "AB - Alberta"},
      {label: "BC - British Columbia", value: "BC - British Columbia"},
      {label: "MB - Manitoba", value: "MB - Manitoba"},
      {label: "NB - New Brunswick", value: "NB - New Brunswick"},
      {label: "NL - Newfoundland", value: "NL - Newfoundland"},
      {label: "NT - Northwest Territories", value: "NT - Northwest Territories"},
      {label: "NS - Nova Scotia", value: "NS - Nova Scotia"},
      {label: "NU - Nunavut", value: "NU - Nunavut"},
      {label: "ON - Ontario", value: "ON - Ontario"},
      {label: "PE - Prince Edward Island", value: "PE - Prince Edward Island"},
      {label: "QC - Quebec", value: "QC - Quebec"},
      {label: "SK - Saskatchewan", value: "SK - Saskatchewan"},
      {label: "YT - Yukon Territories", value: "YT - Yukon Territories"},
    ],
    validMapps: [
      {label: "0-30", value: "0-30"},
      {label: "31-50", value: "31-50"},
      {label: "51-75", value: "51-75"},
      {label: "76-99", value: "76-99"},
      {label: "100+", value: "100+"},
    ],
    sending: false,
    item: {
      appId: null,
      solution: 1,
      ip: 'CURRENT_IP',
      submitted: moment().format("YYYY-MM-DD HH:mm:ss"),
      formData: {
        methods:[],
      },
    },
    files: [ null ],
    companydata: appData.company,
    solutions: appData.solutions,
    templates: [],
    templateId: 0,
    equipment: [],
  }},
  created() {
    this.item.appId=this.id;
    this.loadItem();
    this.loadEquipment();
    this.refresh();
  },
  computed: {
    terminals() {
      var result=[];
      for(var i=0;i<this.terminalCount;i++)
        result.push(i+1);
      return result;
    },
    currentTemplates() {
      var result=this.shownTemplates.map(function(a) {
        return {value: a.id, label: a.name};
      });
//       result.push({value: 0, label: "(new)"});
      return result;
    },
    shownTemplates() {
      return this.templates.filter((a)=>a.solution==(this.item?this.item.solution:null || null));
    },
    equipmentOpt() {
      return this.equipment.map(a=>({
        value: a.id,
        label: a.name,
        purchase: a.purchase*1 || 0,
        rental: a.rental*1 || 0,
        reprogram: a.reprogram*1 || 0,
      }));
    },
  },
  watch: {
    "item.formData.cardTypesOtherFlag"(newVal) {
      if (!newVal || newVal.length==0)
        this.item.formData.cardTypesOther="";
    },
    "item.formData.pricingOption"(newVal) {
      var fields=[];
      if (newVal!='flat')
        fields.push('flatInserted', 'flatInsertedFee', 'flatKeyed', 'flatKeyedFee');
      if (newVal!='iplus')
        fields.push('iplusInserted', 'iplusInsertedFee', 'iplusKeyed', 'iplusKeyedFee');
      if (newVal!='tiered')
        fields.push('tieredQualified', 'tieredMidQualified', 'tieredNonQualified', 'tieredAuthFee');
      for(var field of fields)
        this.$set(this.item.formData, field, "");
    },
    "item.formData.optInteracDebit"(newVal) {
      var fields=[];
      if (!newVal)
        fields.push('interacInserted', 'interacTapped');
      for(var field of fields)
        this.$set(this.item.formData, field, "");
    },
    "item.formData.optCardbillerPlus"(newVal) {
      var fields=[];
      if (!newVal)
        fields.push('cbPlusPay', 'cbPlusPayFee', 'cbPlusInterac');
      for(var field of fields)
        this.$set(this.item.formData, field, "");
    },
    "item.formData.optBankbiller"(newVal) {
      var fields=[];
      if (!newVal)
        fields.push('bbMonthly', 'bbDiscount', 'bbTrx', 'bbRejects', 'bbReturns', 'bbUnauth', 'bbLate', 'bbNonCompliance');
      for(var field of fields)
        this.$set(this.item.formData, field, "");
    },
    "item.formData.optCoinbiller"(newVal) {
      var fields=[];
      if (!newVal)
        fields.push('cbMonthly', 'cbDiscount', 'cbTrx');
      for(var field of fields)
        this.$set(this.item.formData, field, "");
    },
    templateId: {
      handler() {
        if (this.templateId*1) {
          var currentTemplate=this.templates.find((a)=>a.id==this.templateId) || {};
          this.item.formData={...this.item.formData, ...currentTemplate};
        }
      }, immediate: true
    },
  },
  methods: {
    equi(index) {
      return this.equipmentOpt.find((a)=>a.value==this.item.formData['terminal'+index+'Type']) || {};
    },
    async loadEquipment() {
      this.equipment=(await api.list("Equipment", {}, ["id:ASC"])).items;
    },
    async loadItem() {
      var items=(await api.list("Intake", {appId: this.id}, ["id:DESC"])).items;
      if (items.length)
        this.item.formData=items[0].formData || {
          methods:[],
        };
      else {
        this.item.formData=appData.existingApp?{methods: [], ...appData.existingApp}:{methods: []};
        var types={
          cardbiller: ['Visa', 'MasterCard', 'Discover', 'AMEX', 'China UnionPay', 'Interac Debit'],
          cardbillerPlus: ['Apple Pay', 'Google Pay', 'Interac Online'],
          bankbiller: ['EFT', 'ACH'],
          coinbiller: ['BitCoin'],
        };
        this.$set(this.item.formData, "cardbiller", this.item.formData.cardTypes.filter((a)=>types.cardbiller.indexOf(a)!=-1));
        this.$set(this.item.formData, "cardbillerPlus", this.item.formData.cardTypes.filter((a)=>types.cardbillerPlus.indexOf(a)!=-1));
        this.$set(this.item.formData, "bankbiller", this.item.formData.cardTypes.filter((a)=>types.bankbiller.indexOf(a)!=-1));
        this.$set(this.item.formData, "coinbiller", this.item.formData.cardTypes.filter((a)=>types.coinbiller.indexOf(a)!=-1));
        this.$set(this.item.formData, "cardTypesOtherFlag", this.item.formData.cardTypesOther?['']:[]);
      }
    },
    send: async function() {
      if (!this.validate())
        return stopLoading("Please fill all required fields", "error");
      startLoading(1);
      this.sending=true;
//       if (!this.item.id) {
        await api.update("Application", this.id, {hasIntake: 1, mcc: this.item.formData.mcc});
        var result=await api.create("Intake", this.item);
//       } else
//         var result=await api.update("Intake", this.item.id, this.item);
      this.sending=false;
      stopLoading();
      this.step=4;
    },
    deselectSelected(prop, value) {
      this.$nextTick(()=>{
        if (this.item.formData[prop]==value)
          this.item.formData[prop]=null;
      });
    },
    refresh: async function() {
      this.templates=(await api.list("PricingTemplate", {})).items || [];
      if (this.templates.length)
        this.templateId=this.templates[0].id;
    },
  },
  mixins: [formValidation.mixin]
}
</script>
