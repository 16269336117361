import $ from 'jquery';

var extapi={
  request(method, url, data) {
    return new Promise((resolve, reject)=>{
      $.ajax({
        url: '/apix'+url,
        data: method=="GET"?$.param(data):JSON.stringify(data),
        contentType: "application/json; charset=utf-8",
        dataType: "json",
        processData: false,
        error: (jqXHR, status, error)=>{
          reject(error);
        },
        type: method,
        success: (data)=>{
          resolve(data);
        },
      });
    });
  },
  upload(url, file, fields) {
    return new Promise((resolve, reject)=>{
      var formData=new FormData();
      if (fields)
        for(var field in fields)
          formData.append(field, fields[field]);
      formData.append("file", file);
      $.ajax({
        url: '/apix'+url,
        data: formData,
        contentType: false,
        dataType: "json",
        processData: false,
        error: (jqXHR, status, error)=>{
          reject(error);
        },
        type: "POST",
        success: (data)=>{
          resolve(data);
        },
      });
    });    
  },
  async createApplication(data) {
    var actualData=JSON.parse(JSON.stringify(data));
    for(var field in data) {
      if (data[field] instanceof File) {
        delete actualData[field];
      }
    }
    var result=await extapi.request("POST", "/merchantapplication", actualData);
    return result;
  },
  async createApplicationDoc(document, type, id, token, login) {
    var result=await extapi.upload("/merchantapplication/document", document, {token: token, type: type, id: id, mmid: login.mmid, username: login.username, password: login.password, ipAddress: login.ipAddress});
    return result;
  }
};

window.extapi=extapi;
