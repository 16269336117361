<template>
<form class="edit-system search-form system-information">
  <div>
    <ul class="breadcrumbs">
      <li><a>Organization Information</a></li>
    </ul>
  </div>
  <div class="twocol">
    <div>
      <div class="field">
        <label>PIN:</label>
        <validator :value="opts.pin" reqs="required">
          <input v-model="opts.pin" :readonly="readonly" :disabled="!editing" />
        </validator>
      </div>
      <div class="field">
        <label>Name:</label>
        <validator :value="form.companyName" reqs="required">
          <input v-model="form.companyName" :readonly="readonly" :disabled="!editing" />
        </validator>
      </div>
      <div class="field">
        <label>DBA Name:</label>
        <validator :value="form.companyDba" reqs="required">
          <input v-model="form.companyDba" :readonly="readonly" :disabled="!editing" />
        </validator>
      </div>
      <div class="field">
        <label>Address:</label>
        <validator :value="form.companyAddress" reqs="required">
          <input v-model="form.companyAddress" :readonly="readonly" :disabled="!editing" />
        </validator>
      </div>
      <div class="field">
        <label>City:</label>
        <validator :value="form.companyCity" reqs="required">
          <input v-model="form.companyCity" :readonly="readonly" :disabled="!editing" />
        </validator>
      </div>
      <div class="field">
        <label>State:</label>
        <validator :value="form.companyState" reqs="required">
          <input v-model="form.companyState" :readonly="readonly" :disabled="!editing" />
        </validator>
      </div>
      <div class="field">
        <label>Zip/Postal Code:</label>
        <validator :value="form.companyPostal" reqs="required">
          <input v-model="form.companyPostal" :readonly="readonly" :disabled="!editing" />
        </validator>
      </div>
      <div class="field">
        <label>Country:</label>
        <validator :value="form.companyCountry" reqs="required">
          <input v-model="form.companyCountry" :readonly="readonly" :disabled="!editing" />
        </validator>
      </div>
    </div>
    <div>
      <div class="field">
        <label>Email:</label>
        <validator :value="form.companyEmail" reqs="required">
          <input v-model="form.companyEmail" :readonly="readonly" :disabled="!editing" />
        </validator>
      </div>
      <div class="field">
        <label>Phone:</label>
        <validator :value="form.companyPhone" reqs="required">
          <input v-model="form.companyPhone" :readonly="readonly" :disabled="!editing" />
        </validator>
      </div>
      <div class="field">
        <label>Website:</label>
        <validator :value="form.companyWebsite" reqs="required">
          <input v-model="form.companyWebsite" :readonly="readonly" :disabled="!editing" />
        </validator>
      </div>
      <div class="field">
        <label>Helpdesk Phone:</label>
        <validator :value="form.helpdeskPhone" reqs="required">
          <input v-model="form.helpdeskPhone" :readonly="readonly" :disabled="!editing" />
        </validator>
      </div>
      <div class="field">
        <label>Helpdesk Email:</label>
        <validator :value="form.helpdeskEmail" reqs="required">
          <input v-model="form.helpdeskEmail" :readonly="readonly" :disabled="!editing" />
        </validator>
      </div>
      <div class="field">
        <label>Representative Name:</label>
        <validator :value="form.representativeName" reqs="required">
          <input v-model="form.representativeName" :readonly="readonly" :disabled="!editing" />
        </validator>
      </div>
      <div class="field">
        <label>Representative Title:</label>
        <validator :value="form.representativeTitle" reqs="required">
          <input v-model="form.representativeTitle" :readonly="readonly" :disabled="!editing" />
        </validator>
      </div>
      <div class="field">
        <label>Representative Signature:</label>
        <template v-if="readonly">
          <img class="representative-signature" :src="`${basePath}/${form.representativeSignature}`" />
        </template>
        <photo-obj v-else :basepath="basePath" v-model="files.representativeSignature"  :disabled="!editing" />
      </div>
    </div>
  </div>
  <div class="buttons" v-if="!readonly">
    <button v-if="!editing" class="btn btn-large btn-primary" v-on:click.prevent="editing = true">Update</button>
    <button v-if="editing" class="btn btn-large btn-primary" v-on:click.prevent="updateSettings">Save</button>
    <button v-if="editing" class="btn btn-large btn-danger" v-on:click.prevent="editing = false">Cancel</button>
  </div>  
</form>


</template>

<script>
export default {
  props: ["readonly"],
  data: ()=>({
    form: {
      companyName: '',
      companyDba: '',
      companyAddress: '',
      companyCity: '',
      companyState: '',
      companyPostal: '',
      companyCountry: '',
      companyEmail: '',
      companyPhone: '',
      companyFax: '',
      companyWebsite: '',
      supportEmail: '',
      supportPhone: '',
      loginLogo: null,
      loginText: '',
      logo: null,
      footerLogo: null,
      footerText: '',
      partnerLoginLogo: null,
      partnerLoginText: '',
      partnerLogo: null,
      merchantLoginLogo: null,
      merchantLoginText: '',
      merchantLogo: null,
      representativeName: null,
      representativeTitle: null,
      representativeSignature: null,
      helpdeskPhone: null,
      helpdeskEmail: null,
    },
    files: {
      representativeSignature: null,
    },
    opts: {
      pin: '',
    },
    basePath: '',
    editing: false
  }),
  mounted() {
    this.refresh();
    this.refresh2();
  },
  methods: {
    async refresh() {
      this.opts=(await api.get("Options", 1)).item;
    },

    async refresh2() {
      var data=await api.get("Settings", 1);
      this.form=data.item;
      this.basePath=data.desc.logo.basepath;
      for(var field in this.files)
        this.files[field]=this.form[field];
    },
    
    async updateSettings() {
      if (!this.validate())
          return stopLoading("Please fill all required fields", "error");
      startLoading();
      await api.update("Options", 1, this.opts);
      for(var field in this.files) {
        if (this.files[field] instanceof File) {
          if (!window.validateFileExt(this.files[field]))
            return stopLoading("Invalid file extension", "error");
          await api.upload("Settings", 1, this.files[field], {field});
        }
      }
      this.form.representativeSignature = this.files.representativeSignature
      await api.update("Settings", 1, this.form);
      stopLoading("Settings updated");
      await this.refresh2();
    },
  },

  mixins: [formValidation.mixin],
}
</script>
