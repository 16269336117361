<template>
  <div>
    <div class="form-row form-fullw">
      <form-field-select v-model="form.conctactType" :validator="v.conctactType" :label="$t('register.contact_type')" :options="contactTypeOptions" required />
    </div>
    <div class="form-row form-split">
      <form-field-select v-model="form.sex" :validator="v.sex" :label="$t('register.contact_gender')" :options="{Male: $t('register.gender_m'), Female: $t('register.gender_f')}" required />
      <form-field-date v-model="form.dob" :dirty="v.$dirty" :label="$t('register.contact_birth')" required />
    </div>
    <div class="form-row form-split">
      <form-field-text v-model="form.firstName" :maxlength="25" :validator="v.firstName" :label="$t('register.contact_FN')" required />
      <form-field-text v-model="form.lastName" :maxlength="25" :validator="v.lastName" :label="$t('register.contact_LN')" required />
    </div>
    <div class="form-row form-split">
      <div style="position: relative;">
        <form-field-select v-model="form.addressCountry" :validator="v.addressCountry" :label="$t('register.contact_country')" :options="countryOptions" required />
        <a class="what-this" @click.prevent="countryModal = true">{{ $t('register.prohibited_c') }}</a>
      </div>
      <form-field-text v-model="form.addressCity"  :maxlength="25" :validator="v.addressCity" :label="$t('register.contact_city')" required />
    </div>
    <div class="form-row form-split">
      <form-field-text :label="$t('register.contact_address')" v-model="form.address" :validator="v.address" :maxlength="50" required />
    </div>
    <div class="form-row form-split">
      <form-field-select v-if="stateOptions" v-model="form.addressProvince" :validator="v.addressProvince" :label="$t('register.contact_state')" :maxlength="25" :options="stateOptions" required />
      <form-field-text v-else v-model="form.addressProvince" :validator="v.addressProvince" :label="$t('register.contact_state')" required />

      <form-field-text :label="$t('register.contact_zip')" v-model="form.addressPostcode" :validator="v.addressPostcode" :maxlength="15" required />
    </div>
    <div class="form-row form-split">
      <form-field-text v-model="form.phone" :maxlength="20" :validator="v.phone" :label="$t('register.contact_phone')" required />
      <form-field-text v-model="form.mobile" :maxlength="20" :validator="v.mobile" :label="$t('register.contact_mobile')" required />
    </div>
    <div class="form-row form-split" style="position: relative;margin-bottom: 10px;">            
      <form-field-text v-model="form.email" :validator="v.email" :label="$t('register.contact_email')" required />
      <form-field-text :validator="v.ssn" :label="$t('register.contact_ssn')" :maxlength="15" required v-model="form.ssn" />
      <a class="what-this" @click.prevent="ssnModal = true">{{ $t('register.whats_this') }}</a>
    </div>
    <div
      v-for="(type, index) in form.id_types"
      :key="index"
      class="form-row form-split">
      <form-field-select v-model="form.id_types[index].id_type" :validator="v.id_types.$each[index].id_type" :label="$t('register.gov_issued_id_type')" :options="issueIdTypes(form.id_types[index].id_type)" required />
      <form-field-text v-model="form.id_types[index].id_number" :maxlength="20" :validator="v.id_types.$each[index].id_number" :label="$t('register.gov_issued_id_num')" required />
    </div>
    <div class="form-row form-split">
      <div>
        <a v-if="form.id_types.length < idTypes.length" @click="addIssueType()">+ {{ $t('register.add_ID') }}</a>
      </div>
      <a v-if="form.id_types.length > 1" @click="removeIssueTypeId()">- {{ $t('register.remove_ID') }}</a>
    </div>
    <div v-if="form.conctactType === 'Beneficial Owner'" class="form-row form-split">
      <form-field-text v-model="form.ownership" :validator="v.ownership" :label="$t('register.ownership_percent')" required />
    </div>

    <popup :open.sync="ssnModal">
      <div class="scrollable" v-on:scroll.passive="handleScroll">
        <div class="">
          <h2><strong>{{ $t('register.modal_ssn') }}</strong></h2>
          <p>
            {{ $t('register.modal_ssn_line1') }}
          </p>
          <br/>
          <p>
            {{ $t('register.modal_ssn_line2') }}
          </p>
          <br/>
          <p>
          </p>
        </div>
      </div>
    </popup>

    <popup :open.sync="countryModal">
      <div class="scrollable" v-on:scroll.passive="handleScroll">
        <div class="">
          <h2><strong>{{ $t('register.prohibited_c') }}</strong></h2>
          <br/>
          <ul style="column-count: 3;">
            <li v-for="item in prohibitedCountries">
              {{ item }}
            </li>
          </ul>
        </div>
      </div>
    </popup>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    v: {
      type: Object,
      required: true
    },

    solution: {
      type: Object,
      required: true
    },
  },

  data () {
    return {
      form: this.value,
      idTypes: ['Passport', 'Drivers License'],
      ssnModal: false,
      countryModal: false,
    }
  },
  
  computed: {
    system () {
      return window.appData.company || {};
    },

    contactTypeOptions () {
      return {
        'Beneficial Owner': this.$t("register.ben_owner"),
        'Authorized Person': this.$t("register.auth_person")
      }
    },

    countryOptions () {
      if(this.solution.contacts) {
          return window.fullCountries.filter(a => {
            return this.solution.contacts.includes(a.id)
          })
        }else{
          return window.countriesOptions
        }
    },

    prohibitedCountries () {
      if(this.system.contactsCountry) { 
        return window.fullCountries.filter(a => {
            return !this.system.contactsCountry.includes(a.id)
          }).map(a =>(a.text))
      }else{
        return window.prohibitedCountries
      }
    },

    stateOptions () {
      if (!['CA', 'US'].includes(this.form.addressCountry)) return null

      return window.states[this.form.addressCountry].reduce((acc, state) => {
        acc[state.id] = state.text
        return acc
      }, {})
    }
  },

  watch: {
    'form.addressCountry' (value) {
      this.form.addressProvince = null
    },

    value: {
      handler (value) {
        this.form = value
      },
      immediate: true,
      deep: true
    },

    form: {
      handler (value) {
        this.$emit('input', value)
      },
      deep: true
    }
  },

  methods: {
    issueIdTypes (current) {
      const used = this.form.id_types.map(type => type.id_type).filter(id => id)
      return this.idTypes.filter(key => current === key || !used.includes(key)).reduce((acc, key) => {
        var k = key.toLowerCase().replace(/ /g, "_");
        acc[key] = this.$t("register.gov_doc_"+k);
        return acc
      }, {})
    },

    addIssueType () {
      this.form.id_types.push({
        id_type: null,
        id_number: null,
        document: null
      })
    },

    removeIssueTypeId () {
      this.form.id_types.splice(this.form.id_types.length - 1, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
  .what-this {
    position: absolute;
    right: 10px;
    top: 54px;
    cursor: pointer;
    font-size: 13px;
  }
</style>
