<template>
<div>
  <amadel-menu :page="currentPage" />

  <div class="ncontent nc-flat custom-width">
    <div class="ncontent touch-it">
      <div class="custom-form pad20">
        <div class="input-group">
          <label>Source ID</label>
          <validator :value="form.SourceID" reqs="required">
            <select v-model="form.SourceID">
              <option v-for="source in sources" :key="source.id" :value="source.id">{{ source.sourceId }} - {{ source.name }}</option>
            </select>
          </validator>
        </div>
        <div class="input-group">
          <label>Lead Type</label>
          <validator :value="form.LeadType" reqs="required">
            <select v-model="form.LeadType">
              <option v-for="(type, key) in leadTypes" :key="key" :value="key">{{ type }}</option>
            </select>
          </validator>
        </div>
        <div class="input-group">
          <label>Record Id</label>
          <input type="text" v-model="form.RecordId" maxlength="15" @keypress="allowLetterNumber($event, { numbers: true, letters: false })">
        </div>
        <div class="input-group">
          <label>Method Type</label>
          <validator :value="form.MethodType" reqs="required">
            <select v-model="form.MethodType">
              <option v-for="(type, key) in methodTypes" :key="key" :value="key">{{ type }}</option>
            </select>
          </validator>
        </div>
        <div class="input-group">
          <label>Contact Date</label>
          <validator :value="form.ContactDate" reqs="required">
            <datepicker v-model="form.ContactDate" type="text" />
          </validator>
        </div>
        <div class="input-group">
          <label>Business Industry</label>
          <input type="text" v-model="form.BusinessIndustry" maxlength="50" @keypress="allowLetterNumber($event, { numbers: true })">
        </div>
        <div class="input-group">
          <label>Business Name</label>
          <validator :value="form.BusinessName" reqs="required">
            <input type="text" v-model="form.BusinessName" maxlength="50" @keypress="allowLetterNumber($event, { numbers: true })">
          </validator>
        </div>
        <div class="input-group">
          <label>Business DBA</label>
          <input type="text" v-model="form.BusinessDBA" maxlength="50" @keypress="allowLetterNumber($event, { numbers: true })">
        </div>
        <div class="input-group">
          <label>Business Type</label>
          <select v-model="form.BusinessType">
            <option v-for="(type, key) in businessTypes" :key="key" :value="key">{{ type }}</option>
          </select>
        </div>
        <div class="input-group">
          <label>Business Address</label>
          <input type="text" v-model="form.BusinessAddress" maxlength="100" @keypress="allowLetterNumber($event, { numbers: true, allow: [35] })">
        </div>
        <div class="input-group">
          <label>Business City</label>
          <input type="text" v-model="form.BusinessCity" maxlength="30" @keypress="allowLetterNumber($event, { numbers: true })">
        </div>
        <div class="input-group">
          <label>Business Prov/State</label>
          <validator :value="form.BusinessProvState" reqs="required">
            <input type="text" v-model="form.BusinessProvState" maxlength="2" @keypress="allowLetterNumber($event, { numbers: true })">
          </validator>
        </div>
        <div class="input-group">
          <label>Business Postal/Zip</label>
          <validator :value="form.BusinessPostalZip" reqs="required">
            <input type="text" v-model="form.BusinessPostalZip" maxlength="9" @keypress="allowLetterNumber($event, { numbers: true, allow: [45] })">
          </validator>
        </div>
        <div class="input-group">
          <label>Business Country</label>
          <validator :value="form.BusinessCountry" reqs="required">
            <select v-model="form.BusinessCountry">
              <option v-for="(type, key) in businessCountries" :key="key" :value="key">{{ type }}</option>
            </select>
          </validator>
        </div>
        <div class="input-group">
          <label>Business Phone</label>
          <validator :value="form.BusinessPhone" reqs="required">
            <input type="text" v-model="form.BusinessPhone" maxlength="10" @keypress="allowLetterNumber($event, { numbers: true, letters: false })">
          </validator>
        </div>
        <div class="input-group">
          <label>Business Email</label>
          <validator :value="form.BusinessEmail" reqs="email">
            <input type="text" v-model="form.BusinessEmail" maxlength="50">
          </validator>
        </div>
        <div class="input-group">
          <label>Business Webite</label>
          <validator :value="form.BusinessWebite" reqs="url">
            <input type="text" v-model="form.BusinessWebite" maxlength="50">
          </validator>
        </div>
        <div class="input-group">
          <label>Business Description</label>
          <validator :value="form.BusinessDescription" reqs="required">
            <input type="text" v-model="form.BusinessDescription" maxlength="100" @keypress="allowLetterNumber($event, { numbers: true })">
          </validator>
        </div>
        <div class="input-group">
          <label>Contact 1 First Name</label>
          <validator :value="form.Contact1FirstName" reqs="required">
            <input type="text" v-model="form.Contact1FirstName" maxlength="25" @keypress="allowLetterNumber($event, { numbers: true })">
          </validator>
        </div>
        <div class="input-group">
          <label>Contact 1 Last Name</label>
          <validator :value="form.Contact1LastName" reqs="required">
            <input type="text" v-model="form.Contact1LastName" maxlength="25" @keypress="allowLetterNumber($event, { numbers: true })">
          </validator>
        </div>
        <div class="input-group">
          <label>Contact 1 Email</label>
          <validator :value="form.Contact1Email" reqs="email">
            <input type="text" v-model="form.Contact1Email" maxlength="50">
          </validator>
        </div>
        <div class="input-group">
          <label>Contact 1 Phone</label>
          <input type="text" v-model="form.Contact1Phone" maxlength="10" @keypress="allowLetterNumber($event, { numbers: true, letters: false })">
        </div>
        <div class="input-group">
          <label>Contact 2 First Name</label>
          <input type="text" v-model="form.Contact2FirstName" maxlength="25" @keypress="allowLetterNumber($event, { numbers: true })">
        </div>
        <div class="input-group">
          <label>Contact 2 Last Name</label>
          <input type="text" v-model="form.Contact2LastName" maxlength="25" @keypress="allowLetterNumber($event, { numbers: true })">
        </div>
        <div class="input-group">
          <label>Contact 2 Email</label>
          <validator :value="form.Contact2Email" reqs="email">
            <input type="text" v-model="form.Contact2Email" maxlength="50">
          </validator>
        </div>
        <div class="input-group">
          <label>Contact 2 Phone</label>
          <input type="text" v-model="form.Contact2Phone" maxlength="10" @keypress="allowLetterNumber($event, { numbers: true, letters: false })">
        </div>
        <div class="input-group">
          <label>Vol Monthly</label>
          <input type="text" v-model="form.VolMonthly" maxlength="9" @keypress="allowLetterNumber($event, { numbers: true, letters: false })">
        </div>
        <div class="input-group">
          <label>Vol 12 Months</label>
          <input type="text" v-model="form.Vol12Months" maxlength="9" @keypress="allowLetterNumber($event, { numbers: true, letters: false })">
        </div>
        <div class="input-group">
          <label>High Ticket</label>
          <input type="text" v-model="form.HighTicket" maxlength="9" @keypress="allowLetterNumber($event, { numbers: true, letters: false })">
        </div>
        <div class="input-group">
          <label>AvgTicket</label>
          <input type="text" v-model="form.AvgTicket" maxlength="9" @keypress="allowLetterNumber($event, { numbers: true, letters: false })">
        </div>
        <div class="input-group">
          <label>Low Ticket</label>
          <input type="text" v-model="form.LowTicket" maxlength="9" @keypress="allowLetterNumber($event, { numbers: true, letters: false })">
        </div>
        <div class="input-group">
          <label>Currently Accept</label>
          <select v-model="form.CurrentlyAccept">
            <option v-for="(text, key) in yesNoOptions" :key="key" :value="key">{{ text }}</option>
          </select>
        </div>
        <div class="input-group">
          <label>Ever Accept</label>
          <select v-model="form.EverAccept">
            <option v-for="(text, key) in yesNoOptions" :key="key" :value="key">{{ text }}</option>
          </select>
        </div>
        <div class="input-group">
          <label>Trans Curr</label>
          <input type="text" v-model="form.TransCurr" maxlength="3" @keypress="allowLetterNumber($event)">
        </div>
      </div>
      <div class="buttons clear-after">
        <button class="btn btn-large btn-primary floatr no-margin" @click="save()">Save</button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  mixins: [ vueMixins.helpers, formValidation.mixin, vueMixins.amadelLeadMixin ],

   data () {
    return {
      form: null,
      currentPage: {
        text: 'New Lead'
      }
    }
  },

  created () {
    this.clearForm()
  },

  methods: {
    async save () {
      if (!this.validate()) return stopLoading('Validation has failed', 'error')
      startLoading()
      try {
        let latest = (await api.list('AmadelDataTransferFile', {}, ["leadIds:DESC"])).items
        latest = latest.length ? latest[0] : { leadIds: 99, records: 1 }
        
        const transferPayload = {
          userId: window.appData.currentUser.id,
          sourceId: this.form.SourceID,
          filename: 'Portal Submission',
          leadFileId: 0,
          leadIds: parseInt(latest.leadIds) + parseInt(latest.records),
          records: 1,
          status: 'Successful',
          created: 'CURRENT_TIMESTAMP'
        }
        const transfer = await api.create("AmadelDataTransferFile", transferPayload)
        
        const payload = {
          ...this.form,
          dataTransferFileId: transfer.id,
          createdBy: window.appData.currentUser.id,
          created: 'CURRENT_TIMESTAMP',
          status: 'AMA00'
        }
        const response = await api.create("AmadelLead", payload)

        const data = {
          message: 'Lead Created',
          created: "CURRENT_TIMESTAMP",
          author: window.appData.currentUser.id,
          system: 1,
          type: 'Lead',
          parentId: response.id
        }
        api.create("Note", data)

        this.clearForm()
        stopLoading('Lead successfully created.')
      } catch (e) {
        stopLoading(e, 'error')
      }
    },

    clearForm () {
      this.validateReset()
      this.form = {
        SourceID: null,
        LeadType: null,
        RecordId: null,
        MethodType: null,
        ContactDate: null,
        BusinessIndustry: null,
        BusinessName: null,
        BusinessDBA: null,
        BusinessType: null,
        BusinessAddress: null,
        BusinessCity: null,
        BusinessProvState: null,
        BusinessPostalZip: null,
        BusinessCountry: null,
        BusinessPhone: null,
        BusinessEmail: null,
        BusinessWebite: null,
        BusinessDescription: null,
        Contact1FirstName: null,
        Contact1LastName: null,
        Contact1Email: null,
        Contact1Phone: null,
        Contact2FirstName: null,
        Contact2LastName: null,
        Contact2Email: null,
        Contact2Phone: null,
        VolMonthly: null,
        Vol12Months: null,
        HighTicket: null,
        AvgTicket: null,
        LowTicket: null,
        CurrentlyAccept: null,
        EverAccept: null,
        TransCurr: null
      }
    }
  }
}
</script>
