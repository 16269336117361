<template>
  <div>
    <div>
      <div class="action-sub-buttons" v-if="!newCheck && !uwShown">
        <a class="btn" v-on:click="newCalc">New</a>
        <a class="btn" v-if="uwSelected" v-on:click.prevent="doShowItems(uwSelected)">View</a>
      </div>
      <div class="action-sub-buttons" v-else>
        <a class="btn" v-on:click="uwShown=null; newCheck=null">Back</a>
        <a class="btn" v-if="newCheck" v-on:click="saveNew">Save</a>
        <a class="btn" v-if="uwShown" :href="printUrl" target="_blank">Print</a>
      </div>
    </div>
    <table class="file-list content-table" v-if="!newCheck && !uwShown">
      <thead>
        <tr>
          <th style="width: 1%"></th>
          <th>ID</th>
          <th>Legal Name</th>
          <th>Date</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(uwItem, uwI) in uwList">
          <td><input type="radio" v-model="uwSelected" :value="uwItem.id"></td>
          <td>{{ uwList.length-uwI }}</td>
          <td>{{ item2.companyName }}</td>
          <td>{{ formatDate(uwItem.createdDate, "YYYY-MM-DD HH:mm") }}</td>
        </tr>
      </tbody>
    </table>
    <div class="risk-calculator" v-if="newCheck">
      <h3>Processing Exposure Calculator</h3>
      <p>DBA: {{ item2.companyNameTrade }}</p>
      <ul>
        <li>
          <h4>Credit and Chargeback Risk Exposure</h4>
          <ul>
            <li><label>Refund Ratio</label><formel-money-amount suffix="%" v-model="item.riskData.refundRatio" /></li>
            <li><label>Chargeback Ratio</label><formel-money-amount suffix="%" v-model="item.riskData.cbRatio" /></li>
            <li><label>Estimated average daily Visa/MasterCard Volume</label><formel-money-amount prefix="$" v-model="item.riskData.cardVolume" /></li>
            <li><label>Exposure Timeframe (days)</label><input v-model="item.riskData.expTime" /></li>
            <li><label>(Refund Ratio+CB Ratio)*(Avg Daily*Days)</label><input :value="calc1" readonly tabindex="-1" /></li>
          </ul>
        </li>
        <li>
          <h4>Delayed Delivery Risk Exposure</h4>
          <ul>
            <li><label>Number of Delayed Delivery Days</label><input v-model="item.riskData.delayDays" /></li>
            <li><label>Estimated average daily Visa/MasterCard Volume</label><formel-money-amount prefix="$" v-model="item.riskData.cardVolume2" /></li>
            <li><label>Delayed delivery percent of sales</label><formel-money-amount suffix="%" v-model="item.riskData.delayPercent" /></li>
            <li><label>(Delayed Delivery Days*Avg Daily*Delayed Percent)</label><input :value="calc2" readonly tabindex="-1" /></li>
          </ul>
        </li>
        <li>
          <h4>Discount and Fee Exposure</h4>
          <ul>
            <li><label>Estimated average daily Visa/MasterCard Volume</label><formel-money-amount prefix="$" v-model="item.riskData.cardVolume3" /></li>
            <li><label>N = Average Days Before Discount or Fees Collected</label><input v-model="item.riskData.discountDays" /></li>
            <li><label>Discount Rate</label><formel-money-amount suffix="%" v-model="item.riskData.discountRate" /></li>
            <li><label>Avg Daily*N*Discount Rate</label><input :value="calc3" readonly tabindex="-1" /></li>
          </ul>
        </li>
        <li>
          <h4>Total Exposure</h4>
          <ul>
            <li><label>(Credit exposure + Delayed Delivery Exposure + Fee Exposure)</label><input :value="calc4" readonly tabindex="-1" /></li>
          </ul>
        </li>
        <li>
          <ul>
            <li><label>Estimated Annual Volume</label><formel-money-amount prefix="$" v-model="item.riskData.annualVolume" /></li>
          </ul>
        </li>
      </ul>
<!--        <div class="actions">
        <button class="btn btn-large" v-on:click="saveNew">Save</button>
      </div>-->
    </div>
    <div class="risk-calculator" v-else-if="uwShown">
      <h3>Processing Exposure Calculator</h3>
      <p>DBA: {{ item2.companyNameTrade }}</p>
      <ul>
        <li>
          <h4>Credit and Chargeback Risk Exposure</h4>
          <ul>
            <li><label>Refund Ratio</label><formel-money-amount suffix="%" v-model="uwShown.riskData.refundRatio" readonly /></li>
            <li><label>Chargeback Ratio</label><formel-money-amount suffix="%" v-model="uwShown.riskData.cbRatio" readonly /></li>
            <li><label>Estimated average daily Visa/MasterCard Volume</label><formel-money-amount prefix="$" v-model="uwShown.riskData.cardVolume" readonly /></li>
            <li><label>Exposure Timeframe (days)</label><input v-model="uwShown.riskData.expTime" readonly /></li>
            <li><label>(Refund Ratio+CB Ratio)*(Avg Daily*Days)</label><input :value="calc1" readonly tabindex="-1" /></li>
          </ul>
        </li>
        <li>
          <h4>Delayed Delivery Risk Exposure</h4>
          <ul>
            <li><label>Number of Delayed Delivery Days</label><input v-model="uwShown.riskData.delayDays" readonly /></li>
            <li><label>Estimated average daily Visa/MasterCard Volume</label><formel-money-amount prefix="$" v-model="uwShown.riskData.cardVolume2" readonly /></li>
            <li><label>Delayed delivery percent of sales</label><formel-money-amount suffix="%" v-model="uwShown.riskData.delayPercent" readonly /></li>
            <li><label>(Delayed Delivery Days*Avg Daily*Delayed Percent)</label><input :value="calc2" readonly tabindex="-1" /></li>
          </ul>
        </li>
        <li>
          <h4>Discount and Fee Exposure</h4>
          <ul>
            <li><label>Estimated average daily Visa/MasterCard Volume</label><formel-money-amount prefix="$" v-model="uwShown.riskData.cardVolume3" readonly /></li>
            <li><label>N = Average Days Before Discount or Fees Collected</label><input v-model="uwShown.riskData.discountDays" readonly /></li>
            <li><label>Discount Rate</label><formel-money-amount suffix="%" v-model="uwShown.riskData.discountRate"  readonly /></li>
            <li><label>Avg Daily*N*Discount Rate</label><input :value="calc3" readonly tabindex="-1" /></li>
          </ul>
        </li>
        <li>
          <h4>Total Exposure</h4>
          <ul>
            <li><label>(Credit exposure + Delayed Delivery Exposure + Fee Exposure)</label><input :value="calc4" readonly tabindex="-1" /></li>
          </ul>
        </li>
        <li>
          <ul>
            <li><label>Estimated Annual Volume</label><formel-money-amount prefix="$" v-model="uwShown.riskData.annualVolume" readonly /></li>
          </ul>
        </li>
      </ul>
    </div>

    <portal to="actions">
      <ul>
        <template v-if="newCheck">
          <li>
            <a href="" v-on:click.prevent="newCheck=false">Back</a>
          </li>
          <li>
            <a href="" v-on:click.prevent="saveNew()">Save</a>
          </li>
        </template>
        <template v-else>
          <li>
            <a href="" v-on:click.prevent="newCalc()" v-if="!uwShown">New</a>
            <a href="" v-on:click.prevent="uwShown=null" v-else>Back</a>
          </li>
          <li v-if="!uwShown && uwSelected">
            <a href="" v-on:click.prevent="doShowItems(uwSelected)">View</a>
          </li>
          <li v-if="uwShown">
            <a :href="printUrl" target="_blank">Print</a>
          </li>
        </template>
      </ul>
    </portal>
  </div>
</template>

<script>
export default {
  props: ["item2"],
  data() { return {
    newCheck: false,
    uwShown: null,
    uwSelected: null,
    uwList: [],
    item: {
      riskData: {},
    },
  }},
  mounted() {
    this.listRiskCalc();
  },
  methods: {
    listRiskCalc: async function() {
      this.uwList=(await api.list("ApplicationRiskCalc", {applicationId: this.item2.id}, ["id:DESC"])).items;
    },
    newCalc() {
      this.newCheck=true;
      this.item={
        applicationId: this.item2.id,
        riskData: {
          
        },
      };
    },
    doShowItems(id) {
      this.uwShown=this.uwList.find((a)=>a.id==id);
    },
    saveNew: async function() {
//       if (!this.validate())
//         return;
      var id=(await api.create("ApplicationRiskCalc", this.item)).id;
      this.newCalc();
      this.newCheck=false;
      await this.listRiskCalc();
    },
    valueToDisplay(value) {
      var value=((value || "0")+"").replace(/[, ]/g, "")*1;
      value=value.toFixed(2);
      for(var i=value.length-6;i>0;i-=3)
        value=value.substring(0, i)+","+value.substring(i);
      return "$"+value;
    },
  },
  computed: {
    calc1() {
      var rd=this.uwShown?this.uwShown.riskData:this.item.riskData;
      var value=((rd.refundRatio*1+rd.cbRatio*1)/100*rd.cardVolume*rd.expTime);
      return value?this.valueToDisplay(value):"";
    },
    calc2() {
      var rd=this.uwShown?this.uwShown.riskData:this.item.riskData;
      var value=(rd.delayDays*rd.cardVolume2*rd.delayPercent/100);
      return value?this.valueToDisplay(value):"";
    },
    calc3() {
      var rd=this.uwShown?this.uwShown.riskData:this.item.riskData;
      var value=(rd.cardVolume3*rd.discountDays*rd.discountRate/100);
      return value?this.valueToDisplay(value):"";
    },
    calc4() {
      var rd=this.uwShown?this.uwShown.riskData:this.item.riskData;
      var value1=((rd.refundRatio*1+rd.cbRatio*1)/100*rd.cardVolume*rd.expTime);
      var value2=(rd.delayDays*rd.cardVolume2*rd.delayPercent/100);
      var value3=(rd.cardVolume3*rd.discountDays*rd.discountRate/100);
      var value=value1+value2+value3;
      return value?this.valueToDisplay(value):"";
    },
    printUrl() {
      if (!this.uwShown || !this.uwShown.id)
        return null;
      return `applications/exposure/${this.uwShownItem.id}/pdf`;
    },
  },
  mixins: [vueMixins.dateFormatter, formValidation.mixin],  
}
</script>
