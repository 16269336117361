<template>
  <div>
    <div class="preform-outer">
      <div class="appf preform ncontent">
        <div v-if="bankingSolution" class="wcontent">
          <h1>Treasury Enrollment</h1>
          <div class="cb-logo" :style="{'background-image': 'url(&quot;/files/company/'+bankingSolution.appHeadLogo+'&quot;)' }">CardBiller</div>
          <div class="form-info">
            <p>{{ bankingSolution.appHeadText }}</p>
            <p><strong>{{ bankingSolution.appHeadEmail }}</strong></p>
            <p><strong>{{ bankingSolution.appHeadPhone }}</strong></p>
          </div>
          <p class="form-intro">
            {{ bankingSolution.appHeadDesc }}
          </p>
        </div>
      </div>
    </div>
    <section class="form-box reg-form">
      <template v-if="true || system.registration_form">
        <template v-if="!submitted">
          <div class="content-form" v-show="!internal && (!accountCurrency || !accountType)">
            <h4>{{ $t('register.getting_started') }}</h4>
            <div class="form-row" :class="{'form-split': hasAccount===null, 'form-fullw': hasAccount!==null}">
              <form-field-select label="Language" :required="true" :options="{en: 'English', es: 'Español'}" v-model="$i18n.locale" no-null />
            </div>
          </div>
          <div class="content-form" v-show="hasAccount=='Yes'">
            <p>{{ $t('register.yes_description') }}</p>
            <div class="form-row form-center">
              <div class="">
              <button><a href="/login" class="login-link">{{ $t('register.login_button') }}</a></button>
              </div>
            </div>
          </div>
          <div class="content-form" v-show="hasAccount=='No'">
            <div class="form-row form-fullw">
              <form-field-select :label="$t('register.acc_type_selection')" :required="true" :options="businessPersonalOptions" v-model="accountType" />
            </div>
          </div>
          
          <template v-if="accountCurrency && accountType && form">
            <div class="content-form">
              <personal-form
                v-if="isPersonal"
                v-model="form"
                :solution="solution"
                :v="$v.form" />

              <business-form
                v-if="!isPersonal"
                v-model="form"
                :v="$v.form"
                :solution="solution"
                @add-contact="addContact()"
                @remove-contact="removeContact()" />

              <div class="form-row form-fullw">
                <div class="txtc">
                  <p>{{ $t('register.agree_terms') }}</p>
                </div>
              </div>

              <div class="form-row form-fullw">
                <div class="autoh">
                  <ul class="cmarks">
                    <li>
                      <label class="check" :class="{ error: errors.terms }">
                        <input v-model="terms" type="checkbox" />
                        <span></span>
                        <a @click.prevent="termsModal = true">{{ $t('register.terms') }}</a>
                      </label>
                    </li>
                    <li>
                      <label class="check" :class="{ error: errors.privacy_policy }">
                        <input v-model="privacy_policy" type="checkbox" />
                        <span></span>
                        <a @click.prevent="privacyModal = true">{{ $t('register.privacy') }}</a>
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="form-row form-center">
                <div class="">
                  <button v-if="!fileError" :disabled="saving" @click="submit()">{{ $t('register.submit_reg') }}</button>
                  <button v-else @click="retry()">{{ $t('register.retry_reg') }}</button>
                </div>
              </div>
            </div>
          </template>
        </template>

        <div v-else class="submitted-note">
          <div v-if="success" class="success">
            Registration Successfully Submitted!
            <div class="register-shade" v-if="internal"></div>
          </div>
          <div v-else class="denied">
            <span>{{ $t('register.unable_1') }} <b>{{ system.support_email }}</b> {{ $t('register.unable_2') }} <b>{{ system.support_phone }}</b> {{ $t('register.unable_3') }}.</span>
            <br><br>{{ $t('register.unable_4') }}, 
            <br><br><b>{{ system.dba_name }}</b>
          </div>
        </div>
      </template>

      <h4 v-else class="app-page-text">{{ system.disabled_form_text }}</h4>

      <popup :open.sync="termsModal">
        <div class="privacy-popup scrollable" v-on:scroll.passive="handleScroll">
          <terms-popup></terms-popup>
        </div>
        <div class="buttons" v-if="submitClicked">
          <button :class="reachTerms? 'main': 'second'" @click="acceptTerms()">{{ $t('register.accept_button') }}</button>
          <button class="second" @click="termsModal = false">{{ $t('register.cancel_button') }}</button>
          <template v-if="showTermsMessage">{{ $t('register.must_complete_terms') }}</template>
        </div>
      </popup>
      <popup :open.sync="privacyModal" >
        <div class="privacy-popup scrollable">
          <privacy-popup></privacy-popup>
        </div>
      </popup>
      <popup :open.sync="fileErrorPopup">
        <div class="scrollable" v-on:scroll.passive="handleScroll">
          <div class="">
            <h2><strong>{{ $t('register.document_upload_error') }}</strong></h2>
            <p>
              {{ $t('register.document_upload_error_text_1') }}
            </p>
            <p>
              {{ $t('register.document_upload_error_text_2') }}
            </p>
          </div>
        </div>
      </popup>
    </section>
  </div>
</template>

<script>
import helperMixin from '../components/common/helper-mixin'
import { mustBeTrue, dashNumbers, alphaNum, alphaVersionNoSpace, alphaOnly, alphaNumNoSpace, alphaVersion, phone, webSiteUrl, addressValidation, alpha } from '../lib/validators'
import { required, numeric, email, url, requiredIf, between, minLength } from 'vuelidate/lib/validators'

// import UAParser from 'ua-parser-js'
import Popup from '../components/popup'
import BusinessForm from './RegisterForm2Business'
import PersonalForm from './RegisterForm2Personal'
import PrivacyPopup from './PrivacyPopup.vue'
import TermsPopup from './TermsPopup.vue'

const contact = {
  conctactType: null,
  sex: null,
  firstName: null,
  lastName: null,
  dob: null,
  address: null,
  addressNumber: null,
  addressPostcode: null,
  addressCity: null,
  addressProvince: null,
  addressCountry: null,
  phone: null,
  mobile: null,
  email: null,
  ssn: null,
  id_types: [
    {
      id_type: null,
      id_number: null,
      document: null
    }
  ],
  id_number: null,
  ownership: null,
  doc_address_confirmation: null
}

const forms = {
  Personal: {
    sex: null,
    firstName: null,
    lastName: null,
    dob: null,
    address: null,
    addressNumber: null,
    addressPostcode: null,
    addressCity: null,
    addressProvince: null,
    addressCountry: null,
    phone: null,
    phone2: null,
    email: null,
    govDocType: null,
    govDocNum: null,
    ssn: null,
    id_types: [
      {
        id_type: null,
        id_number: null,
        document: null
      }
    ],
  },
  Business: {
    companyName: null,
    companyNameTrade: null,
    companyType: null,
    companyAddress: null,
    companyAddressNum: null,
    companyAddressPostcode: null,
    companyAddressCity: null,
    companyAddressState: null,
    companyFormationState: null,
    companyAddressCountry: null,
    companyPhone: null,
    companyEmail: null,
    companyWebsite: null,
    companyDate: null,
    companyTaxId: null,
    description: null,
    contacts: [{ ...contact }],
    questions: {
      purpose_of_account: null,
      association_with_other_accounts: null,
      source_of_assets_and_income: null,
      intended_use_of_account: null,
      anticipated_types_of_assets: null,
      anticipated_monthly_cash_volume: null,
      anticipated_trading_patterns: null,
      anticipated_monthly_transactions_incoming: null,
      anticipated_monthly_transactions_outgoing: null,
    },
    doc_incorporation: null,
    doc_address_confirmation: null,
  }
}

const fileSizeValidator=function(maxSize) {
  return function (value) {
    if (value==null || value==undefined)
      return true;
    if (!value instanceof File)
      return false;
    return value.size<=maxSize;
  };
}

var maxFileSize=10*1024*1024; // 10 MB

export default {
  mixins: [helperMixin],
  
  props: ["internal"],

  components: {
    Popup,
    BusinessForm,
    PersonalForm,
    PrivacyPopup,
    TermsPopup
  },

  data: ()=>({
    form: null,

    hasAccount: 'No',
    accountType: null,
    accountCurrency: 'USD',

    terms: false,
    privacy_policy: false,

    base: '/files/system/',

    submitClicked: false,

    termsModal: false,
    privacyModal: false,
    submitted: false,
    success: false,
    saving: false,
    reachTerms: true,
    showTermsMessage: false,
    fileError: false,
    fileErrorPopup: false,
    regId: null,
    token: null,
    bankingSolution: null,
    solutionId: appData.solution,
    solution: null,
    settings: appData.settings.settings,
  }),

  computed: {
    errors () {
      const keys = ['terms', 'privacy_policy']
      return keys.reduce((acc, key) => {
        acc[key] = this.$v[key].$dirty && this.$v[key].$invalid && !this.$v[key].$pending
        return acc
      }, {})
    },

    isPersonal () {
      return this.accountType == 'Personal'
    },

    system () {
      return window.appData.company || {};
    },

    ownershipError () {
      if (this.accountType !== 'Business') return false
      const contacts = this.form.contacts.filter(contact => contact.conctactType === 'Beneficial Owner')
      return contacts.length ? contacts.reduce((acc, contact) => acc + parseInt(contact.ownership), 0) !== 100 : false
    },

    noOwner () {
      if (this.accountType !== 'Business') return false
      return this.form.contacts.filter(contact => contact.conctactType === 'Beneficial Owner').length==0;
    },

    businessPersonalOptions () {
      let options = []
      if (this.settings?.enrollmentForms?.personal) {
        options.push({ 
          id: "Personal",
          text: this.$t("register.acc_type_personal")
        })
      }

      if (this.settings?.enrollmentForms?.business) {
        options.push({ 
          id: "Business",
          text: this.$t("register.acc_type_bussines")
        })
      } 

      if (this.settings?.enrollmentForms?.omnibus) {
        options.push({ 
          id: "Omnibus",
          text: this.$t("register.acc_type_omnibus")
        })
      } 

      return options
    }
  },

  watch: {
    termsModal (value) {
      if (!value) this.submitClicked = false
    },

    accountType (accountType) {
      if (accountType) {
        this.form = JSON.parse(JSON.stringify(forms[accountType]))
      }
    }
  },

  async created () {
    if (this.internal) {
      this.hasAccount="No";
    }
    this.solution = (await api.get("Solution", this.solutionId)).item
    var items=(await api.list("Solution", {solutionType: "Banking"})).items;
    this.bankingSolution = items[0]
  },

  methods: {
    acceptTerms () {
      if (this.reachTerms) {
        this.terms = true
        if (this.submitClicked) {
          this.save()
          this.submitClicked = false
        }
        this.termsModal = false
      } else {
        this.showTermsMessage = true
      }
    },

    async submit () { 
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$toast.error(this.$t('register.required1'))
        return
      }
      if (this.ownershipError) {
        this.$toast.error(this.$t('register.required2'))
        return
      }
      if (this.noOwner) {
        this.$toast.error(this.$t('register.required3'))
        return
      }

      this.submitClicked = true
      this.termsModal = true
    },

    async save (status) {
      startLoading()
      try {
        if(this.accountType == 'Personal') {
          var result = (await this.createPerosnalProfile())
          var personalProfile = result.id
          var status=result.item.status
          
          if(status == 'A06') {
            const payload = {
              personalProfile: personalProfile,
              accountType: this.accountType,
              createdAt: 'CURRENT_TIMESTAMP',
              source: appData.currentUser.id,
            }
            const accountId = (await api.create("TreasuryAccount", payload)).id

            const note = (await api.create("Note", {
              message: `System: New Registration Submitted - Duplicate`,
              parentId: accountId,
              type: 'Customer',
              created: "CURRENT_TIMESTAMP",
              author: appData.currentUser.id,
            }, true)).item


            this.submitted = true
            this.success = false
            stopLoading("")
            return
          }

          await this.uploadDocuments(personalProfile)
          
          if(!this.fileError) {
            const payload = {
              personalProfile: personalProfile,
              accountType: this.accountType,
              createdAt: 'CURRENT_TIMESTAMP',
              source: appData.currentUser.id,
            }
            const accountId = (await api.create("TreasuryAccount", payload)).id

            const note = (await api.create("Note", {
              message: `New Registration Submitted`,
              parentId: accountId,
              type: 'Customer',
              created: "CURRENT_TIMESTAMP",
              author: appData.currentUser.id,
            }, true)).item

            this.submitted = true
            this.success = true
            stopLoading("Saved")
          } else {
            this.fileErrorPopup = true
          }
        }

        if(this.accountType == 'Business') {
          var result = (await this.createBusinessProfile())
          var businessProfile = result.id
          var contacts=result.item.contacts;
          var status=result.item.status
          
          if(status == 'A06') {
            const payload = {
              businessProfile: businessProfile,
              accountType: this.accountType,
              createdAt: 'CURRENT_TIMESTAMP',
              source: appData.currentUser.id,
            }
            const accountId = (await api.create("TreasuryAccount", payload)).id

            const note = (await api.create("Note", {
              message: `System: New Registration Submitted - Duplicate`,
              parentId: accountId,
              type: 'Customer',
              created: "CURRENT_TIMESTAMP",
              author: appData.currentUser.id,
            }, true)).item
            
            this.submitted = true
            this.success = false
            stopLoading("")
            return
          }

          await this.uploadBusinessDocuments(businessProfile, contacts)
          if(!this.fileError) {
            const payload = {
              businessProfile: businessProfile,
              accountType: this.accountType,
              createdAt: 'CURRENT_TIMESTAMP',
              source: appData.currentUser.id,
            }

            const accountId = (await api.create("TreasuryAccount", payload)).id

            const note = (await api.create("Note", {
              message: `New Registration Submitted`,
              parentId: accountId,
              type: 'Customer',
              created: "CURRENT_TIMESTAMP",
              author: appData.currentUser.id,
            }, true)).item
            
            this.submitted = true
            this.success = true
            stopLoading("Saved")
          } else {
            this.fileErrorPopup = true
          }
        }
       
      } catch (e) {
        stopLoading(e, 'error')
      }
    },

    createPerosnalProfile () {
      const payload = {
        ...this.form,
        status: 'A02',
        solution: appData.solution,
        createdDate: 'CURRENT_TIMESTAMP'
      }
      return api.create("PersonalProfile", payload, true)
    },

    createBusinessProfile () {
      const payload = {
        ...this.form,
        status: 'A02',
        solution: appData.solution,
        createdDate: 'CURRENT_TIMESTAMP'
      }
      return api.create("BusinessProfile", payload, true)
    },

    async retry (status) {
    },

    async uploadDocuments (id) {
      const name = `${this.form.firstName} ${this.form.lastName}`
      var promises=[];
     
      const done = []
      this.form.id_types.forEach(type => {
        done.push(type.id_type)
        promises.push(this.documentPersonalPromise(id, `${name}-${type.id_type}`, type.id_type, type.id_number, type.document))
      })

      await Promise.all(promises).then(values => {
        console.log('then', values);
        values.forEach(value => {
          if(value.success == false) {
            this.fileError = true
          }
        })
      })
      .catch(err => {
          this.fileError = true
      });
    },

    async uploadBusinessDocuments (id, contacts) {
      const name = this.form.companyName
      var promises=[];

      if (this.accountType == 'Business') {
        promises.push(this.documentBusinessPromise(id, `${name}-Business Inc Documents`, 'Business Incorporation', this.form.companyTaxId, this.form.doc_incorporation))
        promises.push(this.documentBusinessPromise(id,`${name}-business Address Confirm`, 'Address', 'Address', this.form.doc_address_confirmation))

        this.form.contacts.forEach((contact, index) => {
          const prefix = `Contact ${index + 1} `
          const contactsName = `${contact.firstName} ${contact.lastName}`

          const done = []
          contact.id_types.forEach(type => {
            done.push(type.id_type)
            promises.push(this.documentBusinessContactPromise(contacts[index].id, `${contactsName}-${type.id_type}`, type.id_type, type.id_number, type.document))
          })
        })
      }

      await Promise.all(promises).then(values => {
        console.log('then', values);
        values.forEach(value => {
          if(value.success == false) {
            this.fileError = true
          }
        })
      })
        .catch(err => {
            this.fileError = true
        });
    },

    async documentPersonalPromise (personalId, filename,  type, number, file) {
      var id=(await api.create("ProfileDocument", {
        "personalId": personalId,
        "folder": null,
        "businessId": null,
        "businessContactId": null,
        "created": 'CURRENT_TIMESTAMP',
        "type": type,
        "title": filename,
        "number": number,
        "status": 'Pending',
      })).id;
      const action = (await api.upload("ProfileDocument", id, file));
      return action;
    },

    async documentBusinessPromise (businessId, filename,  type, number, file) {
      var id=(await api.create("ProfileDocument", {
        "personalId": null,
        "folder": null,
        "businessId": businessId,
        "businessContactId": null,
        "created": 'CURRENT_TIMESTAMP',
        "type": type,
        "title": filename,
        "number": number,
        "status": 'Pending',
      })).id;
      const action = (await api.upload("ProfileDocument", id, file));
      return action;
    },

    async documentBusinessContactPromise (businessContactId, filename,  type, number, file) {
      var id=(await api.create("ProfileDocument", {
        "personalId": null,
        "folder": null,
        "businessId": null,
        "businessContactId": businessContactId,
        "created": 'CURRENT_TIMESTAMP',
        "type": type,
        "title": filename,
        "number": number,
        "status": 'Pending',
      })).id;
      const action = (await api.upload("ProfileDocument", id, file));
      return action;
    },

    removeContact () {
      this.form.contacts.splice(this.form.contacts.length - 1, 1)
    },

    addContact () {
      this.form.contacts.push(JSON.parse(JSON.stringify(contact)))
    },

    handleScroll: function(e) {
      if ((e.target.scrollHeight-700) <= e.target.scrollTop) {
        this.reachTerms = true
      }
    }
  },

  validations () {
    const rules = {
      form: {},
      terms: { mustBeTrue },
      privacy_policy: { mustBeTrue },
    }

    if (this.isPersonal) {
      rules.form = {
        sex: { required },
        dob: { required },
        firstName: { required, alphaNum },
        lastName: { required, alphaNum },
        phone: { required, phone },
        phone2: { phone },
        email: { required, email },
        ssn: { required, dashNumbers, minLength: minLength(4) },
        addressCountry: { required,  },
        addressCity: { required, alpha },
        address: { required, addressValidation },
        addressNumber: { addressValidation },
        addressProvince: { required, alphaVersion },
        addressPostcode: { required, alphaVersionNoSpace },
        id_types: {
          $each: {
            id_type: { required },
            id_number: { required, alphaNumNoSpace },
            document: { required: requiredIf(() => !this.form.submit_later), fileSizeValidator: fileSizeValidator(maxFileSize) },
          }
        }
      }
    } else {
      rules.form = {
        companyName: { required, alphaNum },
        companyNameTrade: { alphaNum },
        companyDate: { required },
        companyTaxId: { required, numeric },
        companyAddressCountry: { required },
        companyAddressState: { alphaNumNoSpace },
        companyFormationState: { required, alphaNumNoSpace },
        companyType: { required },
        companyAddress: { required, addressValidation },
        companyAddressNum: { addressValidation },
        companyAddressCity: { required, alpha },
        companyPhone: { required, dashNumbers, phone },
        description: { required },
        companyEmail: { required, email },
        companyWebsite: { webSiteUrl },
        companyAddressPostcode: { required, alphaVersionNoSpace },
        doc_incorporation: { required: requiredIf(() => !this.form || !this.form.submit_later), fileSizeValidator: fileSizeValidator(maxFileSize) },
        doc_address_confirmation: { required: requiredIf(() => !this.form || !this.form.submit_later), fileSizeValidator: fileSizeValidator(maxFileSize) },
        contacts: {
          $each: {
            conctactType: { required },
            addressCountry: { required },
            addressCity: { required, alpha },
            addressProvince: { required, alphaVersion },
            addressPostcode: { required, alphaVersionNoSpace },
            address: { required, addressValidation },
            addressNumber: { addressValidation },
            sex: { required },
            dob: { required },
            firstName: { required, alphaNum },
            lastName: { required, alphaNum },
            phone: { required, phone },
            mobile: { phone },
            email: { required, email },
            ssn: { required, alphaNumNoSpace, minLength: minLength(4) },
            id_types: {
              $each: {
                id_type: { required },
                id_number: { required, alphaNumNoSpace },
                document: { required: requiredIf(() => !this.form.submit_later), fileSizeValidator: fileSizeValidator(maxFileSize) },
              }
            },
            doc_address_confirmation: { required, fileSizeValidator: fileSizeValidator(maxFileSize) },
            ownership: { required: requiredIf(nested => nested.conctactType === 'Beneficial Owner'), numeric, between: between(1, 100) },
          }
        },
        questions: {
          purpose_of_account: { required },
          association_with_other_accounts: { required },
          source_of_assets_and_income: { required },
          intended_use_of_account: { required },
          anticipated_types_of_assets: { required },
          anticipated_monthly_cash_volume: { required },
          anticipated_trading_patterns: { required },
          anticipated_monthly_transactions_incoming: { required },
          anticipated_monthly_transactions_outgoing: { required },
          business_industry: { required },
        }
      }

    }

    return rules
  }
}
</script>
<style lang="scss" scoped>
  .content-form .form-row div .check {
    position: unset;
    pointer-events: unset;
  }

  .preform-outer {
    padding-top: 0;
  }
  
  .ncontent {
    margin-top: 40px;
    margin-bottom: 40px;
    max-width: 700px;
  }

  .preform-outer .appf h1 {
    text-align: center;
  }
</style>
