<template>
  <li class="item">
    <template v-if="item.title && item.sub">
      <div class="item-dropdown" @click="menuOpen = !menuOpen">
        <span>{{ item.title }}</span>
        <button class="arrow-pointer unstyled" :class="{ open: menuOpen }">
          <img src="/assets/img/arrowd1.svg" alt="" />
        </button>
      </div>

      <ul class="submenu" :class="{ open: menuOpen }">
        <nav-item
          v-for="(subitem, key) in item.sub"
          :key="key"
          :name="key"
          :item="subitem"
        />
      </ul>
    </template>
    <template v-else>
      <a
        :href="'/' + item[1]"
        class="nav-link"
        :class="{ active: isActive }"
        @mouseenter="isHover = true"
        @mouseleave="isHover = false"
      >
        <div v-if="iconComponent" class="icon">
          <component
            :is="iconComponent"
            :size="18"
            :color="isActive || isHover ? '#51b13e' : '#292929'"
          />
        </div>
        {{ item[0] }}
      </a>
    </template>
  </li>
</template>

<script>
import DashboardIcon from "./navigation/DashboardIcon";

export default {
  props: {
    item: {
      type: [Object, Array],
      required: true,
    },

    name: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      menuOpen: false,
      isHover: false,

      icons: {
        dashboard: DashboardIcon,
      },
    };
  },

  computed: {
    iconComponent() {
      return this.icons[this.name];
    },

    isActive() {
      return (
        (this.name === "dashboard" &&
          !this.activeNav.item &&
          this.activeNav.section === "app") ||
        this.activeNav.item === this.name
      );
    },

    activeNav() {
      return window.appData.activeNav;
    },

    sectionHasActiveItem() {
      if (!this.activeNav.item) return false;
      return this.hasActiveItem(this.item, this.name);
    },
  },

  created() {
    if (this.sectionHasActiveItem) {
      this.menuOpen = true;
    }
  },

  methods: {
    hasActiveSection(item, name) {
      if (this.activeNav.section === name) return true;

      if (item.title && item.sub) {
        for (let key in item.sub) {
          const response = this.hasActiveSection(item.sub[key], key);
          if (response) {
            return true;
          }
        }
      }

      return false;
    },

    hasActiveItem(item, name) {
      if (this.activeNav.section === name && item.sub[this.activeNav.item])
        return true;

      if (item.title && item.sub) {
        for (let key in item.sub) {
          const response = this.hasActiveItem(item.sub[key], key);
          if (response) {
            return true;
          }
        }
      }

      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  display: block;
  .item-dropdown {
    padding: 8px 0;
    text-transform: uppercase;
    color: #8f979e;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    cursor: pointer;
    transition: all ease 0.2s;
    .arrow-pointer {
      transition: all ease 0.2s;
      cursor: pointer;
      img {
        width: 12px;
      }
      &.open {
        transform: rotate(-180deg);
      }
    }
    .flex {
      display: flex;
      .icon {
        margin-right: 5px;
      }
    }
    &:hover {
      color: #000;
    }
  }
  .submenu {
    padding-left: 20px;
    max-height: 0;
    overflow: hidden;
    transition: all ease-in-out 0.4s;
    display: block;
    margin: 0;
    &.open {
      transition: all ease-in-out 0.4s;
      max-height: 999px;
    }
  }

  .nav-link {
    display: flex;
    padding: 8px 0;
    color: #292929;
    font-size: 14px;
    font-weight: 600;
    transition: all ease-in-out 0.2s;
    .icon {
      margin-right: 8px;
    }
    &.active,
    &:hover {
      color: #51b13e;
    }
  }
}
</style>
