<template>
<div class="ncontent specpad custom-width">
  <div class="tabs">
    <a class="btn" v-on:click.prevent="currentTab='reports'">KYC Reports</a>
    <a class="btn" v-on:click.prevent="currentTab='item2'">G2 Monitoring</a>
    <a class="btn" v-on:click.prevent="currentTab='item3'">G2 Monitoring List</a>
  </div>
<scan-list type="g2" v-if="currentTab=='reports'"></scan-list>
<scan-g2mon type="g2" v-if="currentTab=='item2'"></scan-g2mon>
<scan-g2mon-server type="g2" v-if="currentTab=='item3'"></scan-g2mon-server>
</div>
</template>

<script>
export default {
  data: ()=>({
    currentTab: null,
  }),
}
</script>
