<template>
<div class="merchant-panel">
  <div class="data-content">
    <ul class="tabs">
      <li :class="{active: dataTab==0}" v-on:click="dataTab=0">General</li>
      <li :class="{active: dataTab==1}" v-on:click="dataTab=1">Company</li>
      <li :class="{active: dataTab==2}" v-on:click="dataTab=2">Banking</li>
<!--       <li :class="{active: dataTab==3}" v-on:click="dataTab=3">Website</li> -->
<!--       <li :class="{active: dataTab==4}" v-on:click="dataTab=4">Business details</li> -->
      <li :class="{active: dataTab==5}" v-on:click="dataTab=5">Additional Notes</li>
    </ul>
    <p class="tabs">Partner Settings</p>
    <ul class="tabs-smaller">
      <li :class="{active: dataTab==7}" v-on:click="dataTab=7">Profile</li>
      <li :class="{active: dataTab==8}" v-on:click="dataTab=8">Schedule A</li>
      <li :class="{active: dataTab==9}" v-on:click="dataTab=9">Access</li>
      <li :class="{active: dataTab==10}" v-on:click="dataTab=10">Sub-Agents</li>
    </ul>
    <div class="main">
      <formview-agent :item="item" :tab="dataTab" :notes="notes" :agents="agents" :editMode.sync="editMode" :setup="lastSetup" />
    </div>
  </div>
  <aside class="actions">
    <ul class="tabs">
      <li :class="{active: actionsTab==0}" v-on:click="showFinalApproval=false;actionsTab=0">General</li>
      <li :class="{active: actionsTab==2}" v-on:click="actionsTab=2">Application</li>
      <li :class="{active: actionsTab==1}" v-on:click="actionsTab=1">Documents</li>
      <li :class="{active: actionsTab==3}" v-on:click="actionsTab=3">Compliance</li>
      <li :class="{active: actionsTab==7}" v-on:click="actionsTab=7">Agreements</li>
      <li :class="{active: actionsTab==5}" v-on:click="actionsTab=5">Email</li>
      <li :class="{active: actionsTab==4}" v-on:click="actionsTab=4">Agent setup</li>
      <li :class="{active: actionsTab==6}" v-on:click="actionsTab=6">Accounting</li>
    </ul>
    <div class="main" v-if="actionsTab==0">
      <div class="action-buttons">
        <a href="" v-on:click.prevent="listChecklist">Setup Checklist</a>
        <a href="" v-on:click.prevent="editMode=true" :class="{disabled: editMode}">Edit</a>
        <a href="" v-for="(optLabel, optMethod) in allActions" v-on:click.prevent="!isDisabledAction(optMethod) && doAction(optMethod)" :class="{disabled: isDisabledAction(optMethod)}">{{ optLabel }}</a>
        <a href="" v-on:click.prevent="listPreChecks();showFinalApproval=false">Pre-Checks</a>
        <a href="" v-on:click.prevent="uwTab=2;showFinalApproval=false">Status Update</a>
      </div>
      <view-agent-finalcheck key="finalcheck" :uwList="approvalList" v-if="showFinalApproval" />
      <view-agent-precheck v-if="uwTab==1" key="precheck" :uwList="uwList" />
      <view-agent-status v-if="!showFinalApproval && uwTab==2" key="status" :item="item" :notes="notes" />
      <view-agent-setupcl v-if="!showFinalApproval && uwTab==3" key="setupcl" :item="item" :notes="notes" :prechecks="uwList" />
    </div>
    <div class="main" v-else-if="actionsTab==1">
      <div class="action-buttons">
        <a href="" v-on:click.prevent="newFile=true">New File</a>
      </div>
      <div class="content-table-container file-list-container">
      <table class="file-list content-table">
        <thead>
          <tr>
            <th></th>
            <th>Type</th>
            <th>Filename</th>
            <th>Uploaded</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="newFile">
            <td><a class="close" v-on:click.prevent="newFile=false">&times;</a></td>
            <td><input v-model="newFileType" style="width: 100%" /></td>
            <td>
              <input type="file" ref="newFileFile" style="width: 100%"/>
            </td>
            <td>
              <button v-on:click.prevent="uploadNew">Upload</button>
            </td>
          </tr>
          <template v-for="file in item.agentDb.documents" v-if="file.type.indexOf('signedApplication')==-1">
            <tr>
              <td><input type="radio" v-model="selectedFile" :value="'custom'+file.id"></td>
              <td>{{ file.type }}</td>
              <td>
                <a :href="fileLink(file.filename, file.id)" target="_blank">{{ file.filename || "(no file)" }}</a>
              </td>
              <td>{{ formatDate(file.created, "YYYY-MM-DD") }}</td>
            </tr>
          </template>
          <tr>
            <td></td>
            <td>Completed Agent Application</td>
            <td>
              <a :href="agentPdfUrl" target="_blank">{{ item.agentId }} - {{ item.agentDb.name }}</a>
            </td>
            <td>{{ formatDate(item.submitDate, "YYYY-MM-DD") }}</td>
          </tr>
        </tbody>
      </table>
      </div>
    </div>
    <div class="main" v-else-if="actionsTab==2">
      <div class="action-buttons">
        <a href="" v-on:click.prevent="viewApp=true" v-if="!viewApp">View</a>
        <a href="" v-on:click.prevent="uploadFile(signedApplicationField)" v-if="!viewApp">Upload signed</a>
        <a href="" v-on:click.prevent="viewApp=false" v-else>Back</a>
      </div>
      <div class="content-table-container file-list-container" v-if="!viewApp">
      <table class="file-list content-table">
        <thead>
          <tr>
            <th></th>
            <th>AgentID</th>
            <th>Submitted</th>
            <th>Status</th>
            <th>Legal Name</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><input type="radio" checked></td>
            <td>{{ item.agentId }}</td>
            <td>{{ formatDate(item.agentDb.createdAt, "YYYY-MM-DD") }}</td>
            <td>{{ item.agentDb.status }}</td>
            <td>{{ item.agentDb.name }}</td>
          </tr>
          <tr>
            <td colspan="3">
              <a target="_blank" :href="agentPdfUrl" style="display: block">Completed Agent Application</a>
            </td>
            <td colspan="3">
              <a target="_blank" :href="agentPdfUrl" style="display: block">{{ item.applicationId }} {{ item.agentDb.name }} {{ formatDate(item.agentDb.createdAt, "YYYY-MM-DD hh:mm") }}</a>
            </td>
          </tr>f
          <tr v-for="signedDoc in signedApplications">
            <td colspan="3">
              <a target="_blank" :href="fileLink(signedDoc.filename, signedDoc.id)" style="display: block">Signed Agent Application</a>
            </td>
            <td colspan="3">
              <a target="_blank" :href="fileLink(signedDoc.filename, signedDoc.id)" style="display: block">{{ item.applicationId }} {{ item.agentDb.name }} {{ formatDate(signedDoc.created, "YYYY-MM-DD hh:mm") }}</a>
            </td>
          </tr>
        </tbody>
      </table>
      </div>
      <div class="app-details" v-else>
        <p><label>Agent ID:</label> {{ item.agentId }}</p>
        <p><label>Submitted Date:</label> {{ formatDate(item.agentDb.createdAt, "YYYY-MM-DD") }}</p>
        <p><label>Status:</label> {{ item.agentDb.status }}</p>
        <p><label>Legal Name:</label> {{ item.agentDb.name }}</p>
      </div>
    </div>
    <div class="main" v-if="actionsTab==3">
      <div class="action-buttons">
        <a href="" v-on:click.prevent="listWebsiteChecks">Website Check</a>
<!--         <a href="" v-on:click.prevent="" class="disabled">Match/VMAS</a> -->
<!--         <a href="" v-on:click.prevent="" class="disabled">OFAC</a> -->
<!--         <a href="" v-on:click.prevent="" class="disabled">KYC</a> -->
<!--         <a href="" v-on:click.prevent="" class="disabled">AML</a> -->
        <a href="" v-on:click.prevent="" class="disabled">IDM</a>
        <a href="" v-on:click.prevent="" class="disabled">G2</a>
        <a href="" v-on:click.prevent="" class="disabled">KYC Site Scan</a>
      </div>
      <view-app-websitecheck v-if="uwTab==2" key="webcheck" :agent="true" :uwList="webList" />
    </div>
    <div class="main" v-if="actionsTab==4">
      <div class="action-buttons">
        <a v-if="false" href="" :class="{disabled: item.agentDb.status!='A04'}" v-on:click.prevent="item.agentDb.status=='A04' && startSetup()">Start New Setup</a>
        <a href="" v-if="false && currentSetup && currentSetup.status==null" v-on:click.prevent="startSetup(currentSetup)">Continue Setup</a>
        <a href="" v-if="currentSetup" v-on:click.prevent="viewSetup(currentSetup)">View Setup</a>
      </div>
      <table class="content-table file-list">
        <thead>
          <tr>
            <th style="width: 1%">#</th>
            <th>Build Date</th>
            <th>Build Id</th>
            <th>Build By</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(setup, ai) in setups">
            <td><input type="radio" checked v-model="currentSetupId" :value="setup.id"></td>
            <td>{{ formatDate(setup.created, "YYYY-MM-DD hh:mm") }}</td>
            <td>{{ setup.id }}</td>
            <td>{{ setup.id }}</td>
            <td>{{ setup.status }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="main" v-if="actionsTab==5">
      <div class="action-buttons">
<!--         <a href="" v-on:click.prevent="sendEmailUser('SignUp')">Sign-up Email</a> -->
<!--         <a href="" v-on:click.prevent="sendEmailUser('SignUpReminder')">Sign-Up Reminder</a> -->
<!--         <a href="" v-on:click.prevent="sendEmailUser('Approved')">AG Approved</a> -->
        <a href="" :class="{disabled: emailDisabled.Agreement}" v-on:click.prevent="!emailDisabled.Agreement && sendEmailUser('Agreement')">AG Agreement</a>
        <a href="" :class="{disabled: emailDisabled.Declined}" v-on:click.prevent="!emailDisabled.Declined && sendEmailUser('Declined')">AG Declined</a>
        <a href="" :class="{disabled: emailDisabled.Closed}" v-on:click.prevent="!emailDisabled.Closed && sendEmailUser('Closed')">AG Closed</a>
      </div>
      <table class="file-list content-table">
        <thead>
          <tr>
            <th>Type</th>
            <th>Created</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="email in emails">
            <td>{{ email.emailType }}</td>
            <td>{{ formatDate(email.created, "YYYY-MM-DD HH:mm:ss") }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="main" v-if="actionsTab==6">
    </div>
    <div class="main" v-if="actionsTab==7">
      <div class="action-buttons">
        <a href="" :class="{disabled: item.agentDb.status!='A02'}" v-on:click.prevent="item.agentDb.status=='A02' && startAgreement()">Start Agreement</a>
<!--         <a href="" v-on:click.prevent="">Upload Signed Agreement</a> -->
<!--         <a href="" v-on:click.prevent="">View Agreement</a> -->
      </div>
      <table class="content-table file-list">
        <thead>
          <tr>
            <th style="width: 1%">#</th>
            <th>Date Created</th>
            <th>Agreement</th>
            <th>Date Signed</th>
            <th>Signed Agreement</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(agreement, ai) in agreements">
            <td>{{ ai+1 }}</td>
            <td>{{ formatDate(agreement.created, "YYYY-MM-DD hh:mm") }}</td>
            <td><a target="_blank" :href="agreementPath(agreement)">{{ agreement.name }}</a></td>
            <td><a target="_blank" v-if="agreement.filename" :href="fileLink(agreement.filename)">{{ agreement.name+' signed' }}</a></td>
            <td>{{ formatDate(agreement.signed, "YYYY-MM-DD hh:mm") }}</td>
            <td><a href="" class="button" v-on:click.prevent="uploadSigned(agreement, ai)">Upload</a></td>
          </tr>
        </tbody>
      </table>
    </div>
  </aside>
</div>
</template>

<script>
export default {
  data() { return {
    dataTab: 0,
    actionsTab: 0,
    item: {agentDb: {}},
    negativeList: null,
    actions: {},
    notes: [],
    emails: [],
    sources: [],
    editMode: false,
    agentPdfUrl: appData.pdfUrl,
    newFile: false,
    newFileType: "",
    newFileDesc: "",
    selectedFile: null,
    uwTab: null,
    uwList: [],
    webList: [],
    agreements: [],
    setups: [],
    currentSetupId: null,
    viewApp: false,
    approvalList: [],
    agents: [],
    showFinalApproval: false,
    allActions: {
      "NEG": "Negative List",
      "RNE": "Restore Neg List",
//       "P04": "Denied",
//       "A02": "Restore",
//       "APP": "Approve",
      "FUP": "Follow-up",
      "NFU": "No Follow-up",
//       "P02": "Restrict",
//       "A08": "Un-Restrict",
//       "P03": "Close",
    },
  }},
  mounted() {
    if (window.appData.existingAgent)
      this.item=JSON.parse(JSON.stringify(window.appData.existingAgent));
    if (window.appData.actions)
      this.actions=JSON.parse(JSON.stringify(window.appData.actions));
    if (window.appData.allActions)
      this.allActions=JSON.parse(JSON.stringify(window.appData.allActions));
    if (window.appData.sources)
      this.sources=JSON.parse(JSON.stringify(window.appData.sources));
    if (window.appData.notes)
      this.notes=JSON.parse(JSON.stringify(window.appData.notes));
    if (window.appData.negativeList)
      this.negativeList=JSON.parse(JSON.stringify(window.appData.negativeList));
    this.agents=window.agents;
    this.listEmails();
    this.listAgreements();
    this.listSetups();
    window.viewAgent=this;
  },
  computed: {
    emailDisabled() {
      return {
        Agreement: this.agreements.length==0,
        Declined: false,
        Closed: this.item.agentDb.status!="A08",
      };
    },
    signedApplications() {
      var i=0;
      var result=[];
      var docs={};
      for(var j in this.item.agentDb.documents) {
        docs[this.item.agentDb.documents[j].type]=this.item.agentDb.documents[j];
      }
      while(docs['signedApplication'+(i?i:"")]) {
        result.push(docs['signedApplication'+(i?i:"")]);
        i++;
      }
      return result;
    },
    signedApplicationField() {
      var i=this.signedApplications.length;
      return "signedApplication"+(i?i:"");
    },
    currentSetup() {
      return this.setups.find((a)=>a.id==this.currentSetupId);
    },
    lastSetup() {
      return this.setups[0];
    },
  },
  methods: {
    agreementPath(agreement) {
      return `applications/agreement/${agreement.id}/pdf`;
    },
    listPreChecks: async function() {
      this.uwTab=1;
      this.uwList=(await api.list("PartnerPrecheck", {agentId: this.item.id}, ["id:DESC"])).items || [];
    },
    listWebsiteChecks: async function() {
      this.uwTab=2;
      this.webList=(await api.list("WebsiteCheck", {agentId: this.item.id}, ["id:DESC"])).items || [];
    },
    listEmails: async function() {
      this.emails=(await api.list("PartnerEmail", {agentId: this.item.agentDb.id}, ["id:DESC"])).items || [];
    },
    listFinalChecks: async function() {
      this.approvalList=(await api.list("PartnerApprovalCheck", {agentDbId: this.item.agentDb.id}, ["id:DESC"])).items || [];
    },
    listChecklist: async function() {
      this.uwTab=3;
      this.uwList=(await api.list("PartnerPrecheck", {agentId: this.item.id}, ["id:DESC"])).items || [];
    },
    fileLink(fileName, docId) {
      if (!fileName)
        return null;
      if (docId)
        return "download/AgentDocument/"+docId;
      return "files/agent/"+this.item.agentDb.id+"/"+fileName;
    },
    listAgreements: async function() {
      this.agreements=(await api.list("PartnerAgreement", {agentId: this.item.id}, ["id:DESC"])).items || [];
    },
    listSetups: async function() {
      this.setups=(await api.list("PartnerSetup", {agentId: this.item.id}, ["id:DESC"])).items || [];
    },
    uploadSigned(agreement, index) {
      var that=this;
      var input=$("<input />", {type: "file"});
      input.on("change", async function(e) {
        var file=this.files[0];
        startLoading();
        if (!window.validateFileExt(file))
          return stopLoading("Invalid file extension", "error");
        await api.update("PartnerAgreement", agreement.id, {signed: "CURRENT_TIMESTAMP"});
        await api.upload("PartnerAgreement", agreement.id, file);
        that.$set(that.agreements, index, (await api.get("PartnerAgreement", agreement.id)).item);
        stopLoading("File uploaded");
      });
      input.click();
    },
    uploadNew: async function() {
      var file=this.$refs.newFileFile.files[0];
      if (file) {
        startLoading();
        if (!window.validateFileExt(file))
          return stopLoading("Invalid file extension", "error");
        var id=(await api.create("PartnerDocument", {agentId: this.item.agentDb.id, type: this.newFileType, description: this.newFileDesc, created: "CURRENT_TIMESTAMP"})).id;
        if (id) {
          await api.upload("PartnerDocument", id, file);
          this.item.agentDb.documents=(await api.get("Partner", this.item.agentDb.id)).item.documents;
        }
        stopLoading("File uploaded");
        this.newFile=false;
        this.newFileType="";
        this.newFileDesc="";
      }
    },
    uploadFile(field=null) {
      if (field)
        this.selectedFile=field;
      var that=this;
      var input=$("<input />", {type: "file"});
      input.on("change", async function(e) {
        var file=this.files[0];
        startLoading();
        if (!window.validateFileExt(file))
          return stopLoading("Invalid file extension", "error");
        var id=(await api.create("PartnerDocument", {agentId: that.item.agentDb.id, type: that.selectedFile, created: "CURRENT_TIMESTAMP"})).id;
        if (id) {
          await api.upload("PartnerDocument", id, file);
          that.item.agentDb.documents=(await api.get("Partner", that.item.agentDb.id)).item.documents;
        }
        
        stopLoading("File uploaded");
        
      });
      input.click();
    },
    sendEmailUser: async function(type, resetPw) {
      if (!confirm("Send "+type+" email?"))
        return;
      await this.sendEmail(type, resetPw);
    },
    sendEmail: async function(type, resetPw) {
      if (type=='Welcome' && !confirm("Are you sure?"))
        return;
      startLoading();
      if (resetPw)
        var res=await api.create("PartnerEmail", {agentId: this.item.agentDb.id, emailType: type, created: "CURRENT_TIMESTAMP", resetPassword: true});
      else
        var res=await api.create("PartnerEmail", {agentId: this.item.agentDb.id, emailType: type, created: "CURRENT_TIMESTAMP"});
      stopLoading("Email sent");
      var newItem=await api.get("PartnerEmail", res.id);
      if (newItem.item)
        this.emails.unshift(newItem.item);
    },
    isDisabledAction(action) {
      var current=this.item.agentDb.status;
      if (action=="FUP")
        return this.item.agentDb.followup*1;
      if (action=="NFU")
        return !(this.item.agentDb.followup*1);
      if (action=="NEG")
        return !!this.negativeList;
      if (action=="RNE")
        return !this.negativeList;
      
      var enabledMatrix={
        "A02": ["P04", "APP"],
        "P04": ["A02"],
        "A08": ["P02", "P03"],
        "P02": ["A08", "P03"],
        "P03": ["A08"]
      };
      return (enabledMatrix[current] || []).indexOf(action)==-1;
    },
    doAction: async function(action) {
      if (action[0]=="P") {
        startLoading();
        this.item.agentDb.status=action;
        await api.update("Partner", this.item.agentDb.id, {status: action});
        stopLoading("Status updated");
        if (action=="P04")
          await this.sendEmail("Declined");
      } else if (action=="NEG") {
        startLoading();
        var id=(await api.create("NegativeList", {agent: this.item.id})).id;
        stopLoading("Status updated");
        this.negativeList=(await api.get("NegativeList", id)).item;
      } else if (action=="RNE") {
        startLoading();
        if (this.negativeList)
          await api.delete("NegativeList", this.negativeList.id);
        this.negativeList=null;
        stopLoading("Status updated");
      } else if (action=="FUP") {
        startLoading();
        this.item.agentDb.followup=1;
        await api.update("Partner", this.item.agentDb.id, {followup: 1});
        stopLoading("Status updated");
      } else if (action=="NFU") {
        startLoading();
        this.item.agentDb.followup=0;
        await api.update("Partner", this.item.agentDb.id, {followup: 0});
        stopLoading("Status updated");
      } else if (action=="APP") {
        //await api.update("Partner", this.item.agentDb.id, {pendingApproval: 1});
        this.listFinalChecks();
        this.showFinalApproval=true;
      }
    },
    startAgreement() {
      agreementPopup.show(this.item);
    },
    startSetup(current) {
      if (current)
        setupPopup.showExisting(current, this.item);
      else
        setupPopup.show(this.item);
    },
    viewSetup(setup) {
      window.open("/agents/setup/"+setup.id+"/pdf", "_blank");
    }
    
  },
  mixins: [vueMixins.dateFormatter],
}
</script>
