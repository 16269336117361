<template>
<div :class="{grbackground: tab>0}">
  <div class="status" v-if="tab==0">
    <em><i>Account Reviews</i>{{ accountReview }} {{ accountReview!=1?'Days':'Day' }}</em>
    <label>Current Status: <strong>{{ item.agentDb.status }} ({{ statusDesc(item.agentDb.status) }})</strong></label>
  </div>
  <form class="preq-form preq-view" v-if="tab==0">
    <section>
      <ul class="appview-list">
        <li>
          <label>Submit Date</label>
          <div class="validator">
            <span>{{ formatDate(item.agentDb.createdAt, "YYYY-MM-DD") }}</span>
          </div>
        </li>
        <li>
          <label>Approval Date</label>
          <div class="validator">
            <span>{{ formatDate(item.agentDb.approvalDate, "YYYY-MM-DD") }}</span>
          </div>
        </li>
        <li>
          <label>Partner Solution</label>
          <div class="validator">
            <span>{{ solutionForId(item.agentDb.solutionId) }}</span>
          </div>
        </li>
        <li v-if="false">
          <label>Partner Solution</label>
          <div class="validator">
            <dyn-select v-if="editMode" :options="solutions" v-model="item.agentDb.solutions"></dyn-select>
            <span v-else>{{ solution(item.agentDb.solutions) }}</span>
          </div>
        </li>
        <li>
          <label>Type</label>
          <div class="validator">
            <span>{{ groupsObj[item.groups] }}</span>
          </div>
        </li>
        <li>
          <label>Partner ID</label>
          <div class="validator">
            <span>{{ item.agentId }}</span>
          </div>
        </li>
        <li>
          <label>Tier</label>
          <div class="validator">
            <span>{{ item.agentDb.agentClass }}</span>
          </div>
        </li>
        <li>
          <label>Relationship Manager</label>
          <div class="validator">
            <dyn-select v-if="editMode" :options="sourcesToShow" v-model="item.manager"></dyn-select>
            <span v-else>{{ managerLabel(item.manager) }}</span>
          </div>
        </li>
        <li>
          <label>Referrer</label>
          <div class="validator">
            <dyn-select v-if="editMode" v-model="item.agentDb.referrer" :options="usersToShow" />
            <span v-else>{{ userById(item.agentDb.referrer).username }}</span>
          </div>
        </li>
        <li>
          <label>Language</label>
          <div class="validator">
            <dyn-select v-if="editMode" :options="languages" v-model="item.agentDb.language" />
            <span v-else>{{ item.agentDb.language }}</span>
          </div>
        </li>
        <li v-if="false">
          <label>Processors</label>
          <div class="validator">
            <input type="text" v-if="editMode" v-model="item.agentDb.processors" />
            <span v-else>{{ item.agentDb.processors }}</span>
          </div>
        </li>
        <li v-if="false">
          <label>Regions</label>
          <div class="validator">
            <input type="text" v-if="editMode" v-model="item.agentDb.regions" />
            <span v-else>{{ item.agentDb.regions }}</span>
          </div>
        </li>
        <li v-if="false">
          <label>Sales Brand</label>
          <div class="validator">
            <dyn-select v-if="editMode" :options="salesBrands" v-model="item.agentDb.salesBrand"></dyn-select>
            <span v-else>{{ salesBrandForId(item.agentDb.salesBrand).dba }}</span>
          </div>
        </li>
       
      </ul>
    </section>
    <div class="form-actions" v-if="editMode">
      <button class="btn btn-large" v-on:click.prevent="saveItem">Save</button>
    </div>
  </form>
  <form class="preq-form preq-view" v-else-if="tab==1">
    <section>
      <ul v-for="(ubo, ui) in item.contacts" class="appview-list">
        <li class="remove" v-if="ui*1 && editMode">
          <a href="" v-on:click.prevent="item.contacts.splice(ui, 1)">&times;</a>
        </li>
        <li>
          <label><strong>Contact {{ ui+1 }}</strong> First Name</label>
          <div class="validator">
            <span v-if="!editMode">{{ ubo.firstName }}</span>
            <input type="text" v-else v-model="ubo.firstName" />
          </div>
        </li>
        <li>
          <label><strong>Contact {{ ui+1 }}</strong> Last Name</label>
          <div class="validator">
            <span v-if="!editMode">{{ ubo.lastName }}</span>
            <input type="text" v-else v-model="ubo.lastName" />
          </div>
        </li>
        <li>
          <label><strong>Contact {{ ui+1 }}</strong> E-mail</label>
          <div class="validator">
            <span v-if="!editMode">{{ ubo.email }}</span>
            <input type="text" v-else v-model="ubo.email" />
          </div>
        </li>
        <li>
          <label><strong>Contact {{ ui+1 }}</strong> Phone</label>
          <div class="validator">
            <span v-if="!editMode">{{ ubo.phone }}</span>
            <input type="text" v-else v-model="ubo.phone" />
          </div>
        </li>
      </ul>
      <div class="new" v-if="editMode">
        <a href="" v-on:click.prevent="item.contacts.push({})">+</a>
      </div>
      <br />
      <ul class="appview-list">
        <li>
          <label>Company Name</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.agentDb.name }}</span>
            <input type="text" v-else v-model="item.agentDb.name" />
          </div>
        </li>
        <li>
          <label>Address</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.agentDb.address }}</span>
            <input type="text" v-else v-model="item.agentDb.address" />
          </div>
        </li>
        <li>
          <label>City</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.agentDb.city }}</span>
            <input type="text" v-else v-model="item.agentDb.city" />
          </div>
        </li>
        <li>
          <label>Zip/Postal Code</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.agentDb.zip }}</span>
            <input type="text" v-else v-model="item.agentDb.zip" />
          </div>
        </li>
        <li>
          <label>State/Province</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.agentDb.state }}</span>
            <input type="text" v-else v-model="item.agentDb.state" />
          </div>
        </li>
        <li>
          <label>Country</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.agentDb.country }}</span>
            <input type="text" v-else v-model="item.agentDb.country" />
          </div>
        </li>
        <li>
          <label>Phone Number</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.agentDb.phone }}</span>
            <input type="text" v-else v-model="item.agentDb.phone" />
          </div>
        </li>
        <li>
          <label>Company Email</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.agentDb.email || (item.contacts[0] && item.contacts[0].email) }}</span>
            <input type="text" v-else v-model="item.agentDb.email" />
          </div>
        </li>
        <li>
          <label>Company Type</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.agentDb.companyType }}</span>
            <combo-select-ext v-else :options="tobOptions" type="text" v-model="item.agentDb.companyType" />
          </div>
        </li>
      </ul>
      <br />
      <ul class="appview-list">
        <li>
          <label>Business Website</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.agentDb.website }}</span>
            <input type="text" v-else v-model="item.agentDb.website" />
          </div>
        </li>
        <li>
          <label>Estimated merchant applications monthly</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.agentDb.appMonthly }}</span>
            <div v-else class="combo-select">
            <dyn-select :options="validMapps" v-model="item.agentDb.appMonthly" />
            </div>
          </div>
        </li>
        <li>
          <label>Merchant Type</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.agentDb.merchantTypes && item.agentDb.merchantTypes.join(', ') }}</span>
            <multiselect v-else v-model="item.agentDb.merchantTypes" :hasOther="0" :labels="['POS', 'E-Commerce', 'Mobile', 'In-App']" :options="['POS/Card-Present', 'E-Commerce/Card-Not-Present', 'Mobile', 'In-App']" />
          </div>
        </li>
        <li>
          <label>Estimated Monthly Merchant Volume</label>
          <div class="validator">
            <span v-if="!editMode">{{ moneyAmount(item.agentDb.monthlyVolume) }}</span>
            <formel-money-amount v-else v-model="item.agentDb.monthlyVolume" />
          </div>
        </li>
      </ul>
    </section>
    <div class="form-actions" v-if="editMode">
      <button class="btn btn-large" v-on:click.prevent="saveItem">Save</button>
    </div>
  </form>
  <form class="preq-form preq-view" v-else-if="tab==100">
    <section>
      <ul class="appview-list">
        <li>
          <label>Company Name</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.agentDb.name }}</span>
            <input type="text" v-else v-model="item.agentDb.name" />
          </div>
        </li>
        <li>
          <label>Address</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.agentDb.address }}</span>
            <input type="text" v-else v-model="item.agentDb.address" />
          </div>
        </li>
        <li>
          <label>City</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.agentDb.city }}</span>
            <input type="text" v-else v-model="item.agentDb.city" />
          </div>
        </li>
        <li>
          <label>Zip/Postal Code</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.agentDb.zip }}</span>
            <input type="text" v-else v-model="item.agentDb.zip" />
          </div>
        </li>
        <li>
          <label>State/Province</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.agentDb.state }}</span>
            <input type="text" v-else v-model="item.agentDb.state" />
          </div>
        </li>
        <li>
          <label>Country</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.agentDb.country }}</span>
            <input type="text" v-else v-model="item.agentDb.country" />
          </div>
        </li>
        <li>
          <label>Phone Number</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.agentDb.phone }}</span>
            <input type="text" v-else v-model="item.agentDb.phone" />
          </div>
        </li>
        <li>
          <label>Company Email</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.agentDb.email || (item.contacts[0] && item.contacts[0].email) }}</span>
            <input type="text" v-else v-model="item.agentDb.email" />
          </div>
        </li>
        <li>
          <label>Company Type</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.agentDb.companyType }}</span>
            <combo-select-ext v-else :options="tobOptions" type="text" v-model="item.agentDb.companyType" />
          </div>
        </li>
      </ul>
    </section>
    <div class="form-actions" v-if="editMode">
      <button class="btn btn-large" v-on:click.prevent="saveItem">Save</button>
    </div>
  </form>
  <form class="preq-form preq-view" v-else-if="tab==101">
    <section>
      <ul v-for="(ubo, ui) in item.contacts" class="appview-list">
        <li class="remove" v-if="ui*1 && editMode">
          <a href="" v-on:click.prevent="item.contacts.splice(ui, 1)">&times;</a>
        </li>
        <li>
          <label><strong>Contact {{ ui+1 }}</strong> First Name</label>
          <div class="validator">
            <span v-if="!editMode">{{ ubo.firstName }}</span>
            <input type="text" v-else v-model="ubo.firstName" />
          </div>
        </li>
        <li>
          <label><strong>Contact {{ ui+1 }}</strong> Last Name</label>
          <div class="validator">
            <span v-if="!editMode">{{ ubo.lastName }}</span>
            <input type="text" v-else v-model="ubo.lastName" />
          </div>
        </li>
        <li>
          <label><strong>Contact {{ ui+1 }}</strong> E-mail</label>
          <div class="validator">
            <span v-if="!editMode">{{ ubo.email }}</span>
            <input type="text" v-else v-model="ubo.email" />
          </div>
        </li>
        <li>
          <label><strong>Contact {{ ui+1 }}</strong> Phone</label>
          <div class="validator">
            <span v-if="!editMode">{{ ubo.phone }}</span>
            <input type="text" v-else v-model="ubo.phone" />
          </div>
        </li>
      </ul>
      <div class="new" v-if="editMode">
        <a href="" v-on:click.prevent="item.contacts.push({})">+</a>
      </div>
    </section>
    <div class="form-actions" v-if="editMode">
      <button class="btn btn-large" v-on:click.prevent="saveItem">Save</button>
    </div>
  </form>
  <form class="preq-form preq-view" v-else-if="tab==102">
    <section>
      <ul class="appview-list">
        <li>
          <label>Business Website</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.agentDb.website }}</span>
            <input type="text" v-else v-model="item.agentDb.website" />
          </div>
        </li>
        <li style="width: 80%;">
          <label>Business Description</label>
          <div class="validator">
            <textarea :disabled="!editMode" class="more-details" v-model="item.agentDb.description" style="resize: none; heigth: 300px"></textarea>
          </div>
        </li>
      </ul>
      <ul class="appview-list">
        <li style="width: 50%;">
          <label>Services Requested</label>
          <div class="payment-checkbox">
            <input :disabled="!editMode" v-model="item.agentDb.payments" id="payment_checkbox" type="checkbox"/>
            <label for="payment_checkbox">&nbsp;&nbsp;PAYMENTS</label>
          </div>
          <validator value="item.agentDb.merchantTypes" reqs="required" class="payment-multi"  style="width: 23%;">
            <multiselect :readonly="!editMode"  :class="payment-multi" v-model="item.agentDb.merchantTypes" :hasOther="0" :options="['POS', 'E-Commerce', 'Unattended', 'EFT / ACH', 'Debit Only']" />
          </validator>
          <label>Estimated Merchant Activations Monthly?</label>
          <div v-if="!editMode">
            {{ item.agentDb.appMonthly }}
          </div>
          <validator v-else :value="item.agentDb.appMonthly" reqs="required" style="width: 100%">
            <div class="combo-select">
            <dyn-select :options="validMapps" v-model="item.agentDb.appMonthly" />
            </div>
          </validator>
          <label>Estimated Monthly Merchant Volume</label>
          <div class="validator">
            <span v-if="!editMode">{{ moneyAmount(item.agentDb.monthlyVolume) }}</span>
            <formel-money-amount v-else v-model="item.agentDb.monthlyVolume" />
          </div>
        </li>
        <li style="width: 50%;">
          <label>&nbsp;</label>
          <div class="payment-checkbox">
            <input :disabled="!editMode" v-model="item.agentDb.treasury" id="treasury_checkbox" type="checkbox"/>
            <label for="treasury_checkbox">&nbsp;&nbsp;TREASURY</label>
          </div>
          <validator value="item.agentDb.treasuryTypes" reqs="required" class="payment-multi" style="width: 30%;">
              <multiselect :readonly="!editMode" v-model="item.agentDb.treasuryTypes" :hasOther="0" :options="['Personal Accounts', 'Business Accounts', 'Debit Cards', 'Digital Assets', 'Assets Pay']" />
            </validator>
            <label>Estimated Accounts Monthly?</label>
          <div v-if="!editMode">
            {{ item.agentDb.treasuryMonthly }}
          </div>
          <validator v-else :value="item.agentDb.treasuryMonthly" reqs="required" style="width: 100%">
            <div class="combo-select">
            <dyn-select :options="validMapps" v-model="item.agentDb.treasuryMonthly" />
            </div>
          </validator>
          <label>Estimated Account Volume</label>
          <div class="validator">
            <span v-if="!editMode">{{ moneyAmount(item.agentDb.treasuryVolume) }}</span>
            <formel-money-amount v-else v-model="item.agentDb.treasuryVolume" />
          </div>
        </li>
      </ul>
    </section>
    <div class="form-actions" v-if="editMode">
      <button class="btn btn-large" v-on:click.prevent="saveItem">Save</button>
    </div>
  </form>
  <form class="preq-form" v-else-if="tab==2">
    <section>
      <div v-for="(account, i) in item.agentDb.accounts">
        <ul class="column-form appview-list">
          <li class="title">Account #{{ i+1 }} <a v-if="editMode" class="remove" v-on:click.prevent="item.agentDb.accounts.splice(i, 1)">&times;</a></li>
          <li>
            <label>currency</label>
            <validator :value="account.accountCurrency" reqs="required">
              <span v-if="!editMode">{{ currency(account.accountCurrency) }}</span>
              <dyn-select v-else v-model="account.accountCurrency" :options="currencies" />
            </validator>
          </li>
          <li>
            <label>Account Name</label>
            <validator :value="account.accountHolder" reqs="required legalName">
              <span v-if="!editMode">{{ account.accountHolder }}</span>
              <input v-else type="text" v-model="account.accountHolder" />
            </validator>
          </li>
          <li>
            <label>Account Number</label>
            <validator :value="account.accountNumber" reqs="required num20">
              <span v-if="!editMode">{{ account.accountNumber }}</span>
              <input v-else type="text" v-model="account.accountNumber" />
            </validator>
          </li>
          <li>
            <label>Institution Number</label>
            <validator :value="account.accountSwift" reqs="required num5">
              <span v-if="!editMode">{{ account.accountSwift }}</span>
              <input v-else type="text" v-model="account.accountSwift" />
            </validator>
          </li>
          <li>
            <label>Branch/Transit Number</label>
            <validator :value="account.accountIBAN" reqs="required num6">
              <span v-if="!editMode">{{ account.accountIBAN }}</span>
              <input v-else type="text" v-model="account.accountIBAN" />
            </validator>
          </li>
          <li>
            <label>Routing Number</label>
            <validator :value="account.accountRouting" reqs="num9e">
              <span v-if="!editMode">{{ account.accountRouting }}</span>
              <input v-else type="text" v-model="account.accountRouting" />
            </validator>
          </li>
          <li>
            <label>Bank name</label>
            <validator :value="account.bankName" reqs="required alnum30">
              <span v-if="!editMode">{{ account.bankName }}</span>
              <input v-else type="text" v-model="account.bankName" />
            </validator>
          </li>
          <li>
            <label>Bank address</label>
            <validator :value="account.bankAddress" reqs="required address">
              <span v-if="!editMode">{{ account.bankAddress }}</span>
              <input v-else type="text" v-model="account.bankAddress" />
            </validator>
          </li>
          <li>
            <label>Postal Code/Zip</label>
            <validator :value="account.bankAddressPostCode" reqs="required postcode">
              <span v-if="!editMode">{{ account.bankAddressPostCode }}</span>
              <input v-else type="text" v-model="account.bankAddressPostCode" />
            </validator>
          </li>
          <li>
            <label>City</label>
            <validator :value="account.bankAddressCity" reqs="required city">
              <span v-if="!editMode">{{ account.bankAddressCity }}</span>
              <input v-else type="text" v-model="account.bankAddressCity" />
            </validator>
          </li>
          <li>
            <label>Province/State</label>
            <validator :value="account.bankAddressState" reqs="required">
              <span v-if="!editMode">{{ account.bankAddressState }}</span>
              <province-select v-else v-model="account.bankAddressState" :country="account.bankAddressCountry"  />
            </validator>
          </li>
          <li>
            <label>Country</label>
            <validator :value="account.bankAddressCountry" reqs="required country">
              <span v-if="!editMode">{{ account.bankAddressCountry }}</span>
              <div v-else class="combo-select">
                <dyn-select :options="validCountries" v-model="account.bankAddressCountry" />
              </div>
            </validator>
          </li>
          <li>
            <label>Bank telephone number</label>
            <validator :value="account.bankPhone" reqs="required phone">
              <span v-if="!editMode">{{ account.bankPhone }}</span>
              <input v-else type="text" v-model="account.bankPhone" />
            </validator>
          </li>
        </ul>
      </div>
      <a v-if="editMode && item.agentDb.accounts.length<5" class="new" v-on:click.prevent="item.agentDb.accounts.length<5 && item.agentDb.accounts.push({})">+</a>
    </section>
    <div class="form-actions" v-if="editMode">
      <button class="btn btn-large" v-on:click.prevent="saveItem">Save</button>
    </div>
  </form>
  <form class="preq-form" v-else-if="tab==3">
    <section>
    </section>
    <div class="form-actions" v-if="editMode">
      <button class="btn btn-large" v-on:click.prevent="saveItem">Save</button>
    </div>
  </form>
  <form class="preq-form" v-else-if="tab==4">
    <section>
    </section>
    <div class="form-actions" v-if="editMode">
      <button class="btn btn-large" v-on:click.prevent="saveItem">Save</button>
    </div>
  </form>
  <form class="preq-form" v-else-if="tab==5">
    <notes class="appl-notes" :notes="notes" type="User" :id="item.id" />
  </form>
  <form class="preq-form" v-else-if="tab==7">
      <ul class="appview-list" v-if="setup || true">
        <li style="width: 33%">
          <label>Partner ID</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.agentId }}</span>
            <input type="text" v-else v-model="item.agentId" />
          </div>
          <label>Relationship Manager</label>
          <div class="validator">
            <dyn-select v-if="editMode" :options="sourcesToShow" v-model="item.manager"></dyn-select>
            <span v-else>{{ managerLabel(item.manager) }}</span>
          </div>
          <label>Company Name</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.agentDb.name }}</span>
            <input type="text" v-else v-model="item.agentDb.name" />
          </div>
          <label v-if="false">Sales Brand</label>
          <div v-if="false"  class="validator">
            <dyn-select v-if="editMode" :options="salesBrands" v-model="item.agentDb.salesBrand"></dyn-select>
            <span v-else>{{ salesBrandForId(item.agentDb.salesBrand).dba }}</span>
          </div>
        </li>
        <li style="width: 33%">
          <label>Partner Tier</label>
          <div class="validator">
            <dyn-select v-if="editMode" v-model="item.agentDb.agentClass" :options="accountClassesList" />
            <span v-else>{{ item.agentDb.agentClass }}</span>
          </div>
          <label>Partner Type</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.groups }}</span>
            <input type="text" v-else v-model="item.groups" />
          </div>
          <label>Partner Solution</label>
          <div class="validator">
            <span v-if="!editMode">{{ viewSolutions(item.agentDb.solutions) }}</span>
            <input type="text" v-else value="TODO" />
          </div>
        </li>
        <li style="width: 33%">
          <label>Language</label>
          <div class="validator">
            <input type="text" v-if="editMode" v-model="item.agentDb.language" />
            <span v-else>{{ item.agentDb.language }}</span>
          </div>
          <label v-if="setup">PIN</label>
          <div class="validator" v-if="setup">
            <span v-if="!editMode">{{ setup.pin }}</span>
            <input type="text" v-else v-model="setup.pin" />
          </div>
          <label>Merchant Solutions</label>
          <div class="validator">
            <span v-if="!editMode">{{ viewMSolutions(item.agentDb.msolutions) }}</span>
            <input type="text" v-else value="TODO" />
          </div>
        </li>

      </ul>  
    <section>
    </section>
    <div class="form-actions" v-if="editMode">
      <button class="btn btn-large" v-on:click.prevent="saveItem">Save</button>
    </div>
  </form>
  <form class="preq-form" v-else-if="tab==110">
      <ul class="appview-list">
        <li style="width: 33%">
          <label>Partner ID</label>
          <div class="validator">
            <span>{{ item.agentId }}</span>
          </div>
          <label>Relationship Manager</label>
          <div class="validator">
            <dyn-select v-if="editMode" :options="sourcesToShow" v-model="item.manager"></dyn-select>
            <span v-else>{{ managerLabel(item.manager) }}</span>
          </div>
          <label v-if="false">Sales Brand</label>
          <div v-if="false"  class="validator">
            <dyn-select v-if="editMode" :options="salesBrands" v-model="item.agentDb.salesBrand"></dyn-select>
            <span v-else>{{ salesBrandForId(item.agentDb.salesBrand).dba }}</span>
          </div>
        </li>
        <li style="width: 33%">
          <label>Partner Tier</label>
          <div class="validator">
            <span>{{ item.agentDb.agentClass }}</span>
          </div>
          <label>Partner Type</label>
          <div class="validator">
            <span>{{ groupsObj[item.groups] }}</span>
          </div>
          <label>Partner Solution</label>
          <div class="validator">
            <span>{{ viewSolutions(item.agentDb.solutions) }}</span>
          </div>
        </li>
        <li style="width: 33%">
          <label>Language</label>
          <div class="validator">
            <dyn-select v-if="editMode" :options="languages" v-model="item.agentDb.language"></dyn-select>
            <span v-else>{{ item.agentDb.language }}</span>
          </div>
          <label v-if="setup">PIN</label>
          <div class="validator" v-if="setup">
            <span v-if="!editMode">{{ setup.pin }}</span>
            <input type="text" v-else v-model="setup.pin" />
          </div>
        </li>
      </ul>  
    <section>
    </section>
    <div class="form-actions" v-if="editMode">
      <button class="btn btn-large" v-on:click.prevent="saveItem">Save</button>
    </div>
  </form>
  <form class="preq-form" v-else-if="tab==111">
      <h3>Solutions</h3>
      <ul class="appview-list">
        <li style="width: 33%">
          <div style="display: flex;" class="validator">
            <span class="solution-header" style="width: 30%;">Payments</span>
            <mnb-switch :disabled="!editMode || !modules.rPayments" v-model="item.agentDb.solutionsPayments" />
          </div>
          <div style="display: flex;" class="validator solution-list" v-for="msolution in msolutions">
            <span style="width: 30%;">{{ msolution.label }}</span>
            <mnb-switch v-if="item.agentDb.solutionsPayments" :disabled="!editMode || !item.agentDb.solutionsPayments" v-model="msolutionsSwitches[msolution.value]" />
            <mnb-switch v-else :disabled="!editMode || !item.agentDb.solutionsPayments" :set="msolutionsSwitches[msolution.value] = false" />
          </div>
        </li>
        <li style="width: 33%">
          <div style="display: flex;" class="validator">
            <span class="solution-header" style="width: 30%;">Treasury</span>
            <mnb-switch :disabled="!editMode || !modules.rBanking" v-model="item.agentDb.solutionsTreasury" />
          </div>
          <div style="display: flex;" class="validator solution-list" v-for="tsolution in tsolutions">
            <span style="width: 30%;">{{ tsolution.label }}</span>
            <mnb-switch v-if="item.agentDb.solutionsTreasury" :disabled="!editMode || !item.agentDb.solutionsTreasury" v-model="tsolutionsSwitches[tsolution.value]" />
            <mnb-switch v-else :disabled="!editMode || !item.agentDb.solutionsTreasury" :set="tsolutionsSwitches[tsolution.value] = false" />
          </div>
        </li>
      </ul>  
      <h3>Modules</h3>
      <ul class="appview-list">
        <li style="width: 33%">
          <div style="display: flex;" class="validator">
            <span style="width: 30%;">Amadel</span>
            <template v-if="settings && settings.partners" >
              <mnb-switch v-if="!settings.partners.amadel" :disabled="true" :value="false" />
              <template v-else>
                <mnb-switch :disabled="!editMode" v-model="item.agentDb.amadel" />
              </template>
            </template>
            <template v-else>
              <mnb-switch :disabled="true" :value="false" />
            </template>
          </div>
          <div style="display: flex;" class="validator">
            <span style="width: 30%;">Scheduler</span>
            <template v-if="settings && settings.partners" >
              <mnb-switch v-if="!settings.partners.scheduler" :disabled="true" :value="false" />
              <template v-else>
                <mnb-switch :disabled="!editMode" v-model="item.agentDb.scheduler" />
              </template>
            </template>
            <template v-else>
              <mnb-switch :disabled="true" :value="false" />
            </template>
          </div>
        </li>
      </ul>
    <section>
    </section>
    <div class="form-actions" v-if="editMode">
      <button class="btn btn-large" v-on:click.prevent="saveItem">Save</button>
    </div>
  </form>
  <form class="preq-form" v-else-if="tab==8">
      <ul class="appview-list">
        <li style="width: 33%">
          <label v-if="false">Sales Brand</label>
          <div v-if="false" class="validator">
            <dyn-select v-if="editMode" :options="salesBrands" v-model="item.agentDb.salesBrand"></dyn-select>
            <span v-else>{{ salesBrandForId(item.agentDb.salesBrand).dba }}</span>
          </div>
          <label>Partner Tier</label>
          <div class="validator">
            <dyn-select v-if="editMode" v-model="item.agentDb.agentClass" :options="accountClassesList" />
            <span v-else>{{ item.agentDb.agentClass }}</span>
          </div>
          <label>Partner Type</label>
          <div class="validator">
            <span v-if="!editMode">{{ groupsObj[item.groups] }}</span>
            <dyn-select v-else v-model="item.groups" :options="groups"/>
          </div>
        </li>
        <li style="width: 33%">
          <label>BIN/ICA Rental Fee</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.agentDb.bicRentalFee }}%</span>
            <formel-money-amount4 v-else v-model="item.agentDb.bicRentalFee" suffix="%" />
          </div>
          <label>Partner Monthly Fee</label>
          <div class="validator">
            <span v-if="!editMode">${{ item.agentDb.setupFee }}</span>
            <formel-money-amount4 v-else v-model="item.agentDb.setupFee" prefix="$" />
          </div>
          <label>Monthly Minimum</label>
          <div class="validator">
            <span v-if="!editMode">${{ item.agentDb.monthlyMinimum }}</span>
            <formel-money-amount4 type="text" v-else v-model="item.agentDb.monthlyMinimum" prefix="$" />
          </div>
        </li>
      </ul>
    <section>
    </section>
    <div class="form-actions" v-if="editMode">
      <button class="btn btn-large" v-on:click.prevent="saveItem">Save</button>
    </div>
  </form>
  <form class="preq-form" v-else-if="tab==9">
      <ul class="appview-list" v-if="setup">
        <li>
          <label>Platform</label>
          <div class="validator">
            <span>{{ "" }}</span>
          </div>
        </li>
        <li>
          <label>Platform ID</label>
          <div class="validator">
            <span>{{ "" }}</span>
          </div>
        </li>
        <li>
          <label>Portal Name</label>
          <div class="validator">
            <span>{{ "Trak" }}</span>
          </div>
        </li>
        <li>
          <label>Username</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.username }}</span>
            <input type="text" v-else v-model="item.username" />
          </div>
        </li>
        <li>
        </li>
        <li>
          <label>Reset Password</label>
          <div class="validator">
            <button v-on:click.prevent="resetPassword">Reset password</button>
          </div>
        </li>
        <li>
          <label>Welcome Email</label>
          <div class="validator">
            <button class="button" :disabled="item.agentDb.status!='A02' && item.agentDb.status!='A04' && item.agentDb.status!='A07'" v-on:click.prevent="$parent.sendEmail('Welcome', true)">Send</button>
          </div>
        </li>
        <li>
          <label>Block</label>
          <div class="validator">
            <button v-on:click.prevent="blockUser">{{ item.active=="Blocked"?'Unblock':'Block' }}</button>
          </div>
        </li>
        <li>
          <label>Amadel</label>
          <div class="validator">
            <span>{{ item.agentDb.amadel*1?"Enabled":"Disabled" }}</span>
            <button v-on:click.prevent="toggleAmadel">{{ item.agentDb.amadel*1?'Disable':'Enable' }}</button>
          </div>
        </li>

      </ul>
    <section>
    </section>
    <div class="form-actions" v-if="editMode">
      <button class="btn btn-large" v-on:click.prevent="saveItem">Save</button>
    </div>
  </form>
  <form class="preq-form" v-else-if="tab==10">
    <table class="content-table file-list">
      <thead>
      <tr>
        <th>Agent ID</th>
        <th>Status</th>
        <th>First Name</th>
        <th>Last Name</th>
        <th>Email</th>
        <th>Options</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item, index) in agents">
        <td class="text-left">
          <a :href="'/profile/sub/'+item.id">{{ item.subAgentID }}</a>
        </td>
        <td class="text-left">
          {{ item.subAgentStatus }}
        </td>
        <td class="text-left">
          {{ item.firstName }}
        </td>
        <td class="text-left">
          {{ item.lastName }}
        </td>
        <td class="text-left">
          {{ item.email }}
        </td>
        <td class="text-right">

        </td>
      </tr>
      </tbody>
    </table>
  </form>
  
  <portal to="actions">
    <ul>
      <li v-if="edit">
        <a href="" v-on:click.prevent="$emit('update:editMode', true)">Edit</a>
      </li>
      <template v-if="tab == 0">
          <li v-for="(optLabel, optMethod) in allActions" v-if="tab==0" :class="{disabled: $parent.$parent.isDisabledAction(optMethod)}">
          <a href="" v-on:click.prevent="!$parent.$parent.isDisabledAction(optMethod) && $parent.$parent.doAction(optMethod)">{{ optLabel }}</a>
        </li>
      </template>
      <template v-if="tab == 999">
          <li v-for="(optLabel, optMethod) in statusActions" :class="{disabled: activeStatuses.includes(item.agentDb.status)}">
            <a href="" v-on:click.prevent="$parent.$parent.doAction(optMethod)" :title="optLabel">{{ optMethod }}</a>
          </li>
      </template>
    </ul>
  </portal>
</div>
</template>

<script>
  import MnbSwitch from './switch.vue'
export default {
  components: {
      MnbSwitch,
    },
    
  props: ["item", "tab", "notes", "editMode", "setup", "agents", "edit"],
  data() { return {
    amadel: false,
    systemSettings: {},
    settings: appData.settings.settings,
    modules: appData.settings.modules,
    now: 0,
    sources: appData.sources || [],
    users: appData.users || [],
//     solutions: [
//       {value: "cardbiller-ca", label: "CardBiller - CA"},
//     ],
    validMapps: [
      {label: "0-30", value: "0-30"},
      {label: "31-50", value: "31-50"},
      {label: "51-75", value: "51-75"},
      {label: "76-99", value: "76-99"},
      {label: "100+", value: "100+"},
    ],
    currencies: [
      {value: "036", label: "AUD"},
      {value: "986", label: "BRL"},
      {value: "124", label: "CAD"},
      {value: "756", label: "CHF"},
      {value: "978", label: "EUR"},
      {value: "826", label: "GBP"},
      {value: "344", label: "HKD"},
      {value: "392", label: "JPY"},
      {value: "554", label: "NZD"},
      {value: "840", label: "USD"},
    ],
    accountClassesList: [
      {value: "10", label: "10"},
      {value: "20", label: "20"},
      {value: "30", label: "30"},
      {value: "40", label: "40"},
      {value: "50", label: "50"},
      {value: "CST", label: "CST"},
    ],
    tobOptions: [
      "Inc.",
      "Corp.",
      "LLC",
      "LLP",
      "Ltd.",
      "Sole Proprietor",
      "",
    ],
    salesBrands: [],
    solutions: [],
    msolutions: [],
    msolutionsSwitches: [],
    tsolutions: [],
    tsolutionsSwitches: [],
    groups: window.userGroupList.filter((a)=>a.value.indexOf("agent-")==0),
    allActions: {
      "NEG": "Negative List",
      "RNE": "Restore Neg List",
      "FUP": "Follow-up",
      "NFU": "No Follow-up",
    },
    statusActions: {
      "A08": "Active",
      "A09": "Review",
      "A10": "Restricted",
      "A11": "Dormant",
      "A12": "Closed",
    },
    activeStatuses: [
      "A02",
      "A03",
      "A04",
      "A05",
    ],
  }},

  mounted() {
    setInterval(()=>{ this.now++; }, 1000);
    this.solutions=appData.solutions;
    this.msolutions=appData.msolutions;
    this.tsolutions=appData.tsolutions;
    
    this.loadSalesBrands();
    if(this.item.agentDb.amadel == 1) {
      this.item.agentDb.amadel = true
    }else{
      this.item.agentDb.amadel = false
    }

    if(this.modules.rPayments == false)
      this.item.agentDb.payments = false
    if(this.modules.rBanking == false)  
      this.item.agentDb.treasury = false

    if(this.item.agentDb.payments) {
      var asolutions;
      asolutions = this.item.agentDb.msolutions
      for(var msolution of this.msolutions) {
        for(var solutionId of asolutions)
          if(msolution.value == solutionId){
            this.msolutionsSwitches[msolution.value] = true;
            break;
          }
          else
            this.msolutionsSwitches[msolution.value] = false;
      }
    }
    
    if(this.item.agentDb.treasury) {
      var rsolutions;
      rsolutions = this.item.agentDb.tsolutions
      for(var tsolution of this.tsolutions) {
        for(var solutionId of rsolutions)
          if(tsolution.value == solutionId){
            this.tsolutionsSwitches[tsolution.value] = true;
            break;
          }
          else
            this.tsolutionsSwitches[tsolution.value] = false;
      }
    }
  },
  watch: {
    item: {
      immediate: true,
      handler() {
        if (this.item.agentDb) {
          this.item.agentDb.solutionsTreasury=!!(this.item.agentDb.tsolutions || []).length;
          this.item.agentDb.solutionsPayments=!!(this.item.agentDb.msolutions || []).length;
        }
      },
    },

    'item.agentDb.payments' () {
      if(this.item.agentDb.payments == false) {
        this.msolutionsSwitches = []
      } 
    },

    'item.agentDb.treasury' () {
      if(this.item.agentDb.treasury == false) {
        this.tsolutionsSwitches = []
      } 
    },
  },
  methods: {
    loadSalesBrands: async function() {
      this.salesBrands=[];
      var items=(await api.list("SalesBrand", {}, ["brandId:ASC"])).items || [];
      for(var i in items) {
        var item=items[i];
        this.salesBrands.push({label: item.dba, value: item.id, item: item});
      }
    },
    resetPassword: async function() {
      if (!confirm("Are you sure?"))
        return;
      if (!this.item.id)
        return;
      startLoading();
//       await api.update("User", this.item.id, {
//         passChange: 1,
//       });
//       stopLoading("Password reset", "success");
      $.post("/login/forgotpw", {
        username: this.item.username,
        accountId: this.item.agentId,
        loginMode: "partners",
      }, (data)=>{
        stopLoading('Link to reset password is sent to partner email.')
      }, "json");
    },
    multilineText(text) {
      return (""+text).replace(/\</g, "&lt;")
                      .replace(/\>/g, "&gt;")
                      .replace(/\"/g, "&quot;")
                      .replace(/\&/g, "&amp;")
                      .replace(/\n/g, "<br />\n");
    },
    saveItem: async function() {
      if (!this.validate())
        return stopLoading("Please fill all required fields", "error");    
      startLoading();
      if(this.item.agentDb.amadel)
        this.item.agentDb.amadel = 1
      else
        this.item.agentDb.amadel = 0

      this.item.agentDb.msolutions = [];
      for(var i in this.msolutionsSwitches){
        if(this.msolutionsSwitches[i]) {
          this.item.agentDb.msolutions.push(i)
        }
      }

      this.item.agentDb.tsolutions = [];
      for(var i in this.tsolutionsSwitches){
        if(this.tsolutionsSwitches[i]) {
          this.item.agentDb.tsolutions.push(i)
        }
      }
      var updateItem=JSON.parse(JSON.stringify(this.item));
//       updateItem.source=updateItem.sourceId;
//       delete updateItem.sourceId;
      delete updateItem.passChange;
      await api.update("User", this.item.id, updateItem);
      stopLoading("Partner updated");
      this.$emit("update:editMode", false);
      var resItem=(await api.get("User", this.item.id)).item;
      this.$parent.item=resItem;
    },
    statusDesc(status) {
      for(var i in appData.statuses) {
        var xstatus=appData.statuses[i];
        if (xstatus.status==status)
          return xstatus.description;
      }
      return "";
    },
    managerLabel(managerId) {
      for(var i in this.sources)
        if (this.sources[i].id==managerId)
          return this.sources[i].fullName;
      return "";
    },
    userById(id) {
      return this.users.find((a)=>a.id==id) || {};
    },
    currency(code) {
      for(var currency of this.currencies)
        if (code*1==currency.value*1)
          return currency.label;
      return "";
    },
    solution(id) {
      for(var solution of this.solutions)
        if (id*1==solution.value*1)
          return solution.label;
      return "";
    },
    moneyAmount(value) {
      value=((value || "0")+"").replace(/[, ]/g, "")*1;
      value=value.toFixed(2);
      for(var i=value.length-6;i>0;i-=3)
        value=value.substring(0, i)+","+value.substring(i);
      return value;
    },
    blockUser: async function() {
      startLoading();
      var item=this.item;
      await api.update("User", item.id, {active: item.active=="Blocked"?"Yes":"Blocked"});
      stopLoading("Agent status updated");
      item.active=item.active=="Blocked"?"Yes":"Blocked";      
    },
    toggleAmadel: async function() {
      startLoading();
      var item=this.item;
      await api.update("User", item.id, {agentDb: {id: item.agentDb.id, amadel: item.agentDb.amadel*1?0:1}});
      stopLoading("Amadel status updated");
      item.agentDb.amadel=item.agentDb.amadel*1?0:1;      
    },
    salesBrandForId(id) {
      for(var i in this.salesBrands)
        if (this.salesBrands[i].value==id)
          return this.salesBrands[i].item;
      return {};
    },
    solutionForId(id) {
      for(var i in this.solutions)
        if (this.solutions[i].value==id)
          return this.solutions[i].label;
      return null;
    },
    showArray(value) {
      if (typeof(value)=="null" || typeof(value)=="undefined")
        return "";
      if (typeof(value)=="object" && value.length && value.join)
        return value.join(", ");
      return value;
    },
    viewSolutions(solutions) {
      if (!solutions)
        return "";
      if (!solutions.join) {
        solutions=[solutions];
      }
      var result=[];
      for(var solutionId of solutions)
        for(var solution of this.solutions)
          if (solution.value==solutionId)
            result.push(solution.label);
      return result.join(", ");
    },
    viewMSolutions(solutions) {
      if (!solutions || !solutions.join) {
        solutions=[solutions];
      }
      var result=[];
      for(var solutionId of solutions)
        for(var solution of this.msolutions)
          if (solution.value==solutionId)
            result.push(solution.label);
      return result.join(", ");
    },

    checkMSolutions(solutions, value) {
      if (!solutions || !solutions.join) {
        solutions=[solutions];
      }
      var result=[];
      for(var solutionId of solutions)
          if (value==solutionId)
            return true
      return false
    }
  },
  computed: {
    groupsObj() {
      var result={};
      for(var i in this.groups)
        result[this.groups[i].value]=this.groups[i].label;
      return result;
    },
    sourcesToShow() {
      return this.sources.map(function(a) {
        return {
          value: a.id,
          label: a.fullName || a.username,
        };
      });
    },
    usersToShow() {
      return this.users.map(function(a) {
        return {
          value: a.id,
          label: a.username,
        };
      });
    },
    accountReview() {
      if (this.item.agentDb.approvalDate) {
        var p=moment(this.item.agentDb.approvalDate);
        p.add(serverDiff, "seconds");
        p.add(this.item.agentDb.reviewDays, "days");
        var diff=moment.duration(p.diff(moment())).asDays();
        if (diff<0)
          return "0";
        return Math.floor(diff);
      }
      return "---";
    },

    languages() {
      var options=[{
        value: null, label: ""
      }];
      if(this.systemSettings.language) {
        for(var i = 0; i < this.systemSettings.language.length; i++){
          options.push(
            {
              value: this.systemSettings.language[i],
              label: this.systemSettings.language[i],
            }
          )
        };
      }
       
      return options;
    },

    validCountries() {
      var countries = [
        {
          label: 'Canada',
          value: 'CA'
        },
        {
          label: 'United States',
          value: 'US'
        }
      ];

      return countries
    },

  },

  async created () {
    this.systemSettings=(await api.get("Settings", 1)).item;
  },

  mixins: [vueMixins.dateFormatter, formValidation.mixin],
}
</script>
<style scoped>
.payment-checkbox {
  display: flex;
  align-items: center;
}
</style>
