<template>
<form class="form-step">
  <section>
    <h2>Ownership Profile</h2>
    <div class="pgf-main-cb center">
      <h3 class="intro">Directors <sup>1</sup></h3>
    </div>
    <ul class="column-form type2 bh" v-for="(director, i) in app.directors">
      <li class="head" :class="{collapsable: app.directors.length>1}" v-on:click="toggleCollapse('dirHead'+i)" :ref="'dirHead'+i">
        <!--<label>Director(s) <sup>1</sup></label>-->
        <div>
          Director {{ i+1 }} <a v-if="app.directors.length>1 && i" class="remove" href="" v-on:click.prevent.stop="app.directors.splice(i, 1)" title="Remove"><span>&times;</span>Remove director</a>
        </div>
      </li>
      <li>
        <label>First Name</label>
        <div class="field">
          <validator :value="director.firstName" reqs="required city" :key="'df1x'+i">
            <input type="text" v-model="director.firstName" />
          </validator>
        </div>
      </li>
      <li>
        <label>Last name</label>
        <div class="field">
          <validator :value="director.lastName" reqs="required city" :key="'df2x'+i">
            <input type="text" v-model="director.lastName" />
          </validator>
          <div v-if="app.directors.length<5" class="add-dir"></div>
        </div>
      </li>
      <li>
        <label>Date of birth</label>
        <div class="field">
          <validator :value="director.dob" reqs="required" :key="'df3x'+i">
            <datepicker type="text" v-model="director.dob" :future="'18+'" />
          </validator>
          <div v-if="app.directors.length<5" class="add-dir"></div>
        </div>
        <span class="pfx-info">(YYYY MM DD)</span>
      </li>
      <li>
        <label>Street address</label>
        <div class="field">
          <validator :value="director.address" reqs="required address" :key="'df4x'+i">
            <input type="text" v-model="director.address" />
          </validator>
          <div v-if="app.directors.length<5" class="add-dir"></div>
        </div>
        <span class="pfx-info">(home)</span>
      </li>
      <li>
        <label>Postal code / ZIP code</label>
        <div class="field">
          <validator :value="director.addressPostcode" reqs="required postcode" :key="'df6x'+i">
            <input type="text" v-model="director.addressPostcode" />
          </validator>
          <div v-if="app.directors.length<5" class="add-dir"></div>
        </div>
      </li>
      <li>
        <label>City</label>
        <div class="field">
          <validator :value="director.addressCity" reqs="required city" :key="'df7x'+i">
            <input type="text" v-model="director.addressCity" />
          </validator>
          <div v-if="app.directors.length<5" class="add-dir"></div>
        </div>
      </li>
      <li>
        <label>Country</label>
        <div class="field">
          <validator :value="director.addressCountry" reqs="required state" :key="'df8x'+i">
            <div class="combo-select">
            <dyn-select :options="validCountries" v-model="director.addressCountry" />
            </div>
          </validator>
          <div v-if="app.directors.length<5" class="add-dir"></div>
        </div>
      </li>
      <li>
        <label>Province / State</label>
        <div class="field">
          <validator v-if="director.addressCountry=='CA' || director.addressCountry=='US'" :value="director.addressNumber" reqs="required state" :key="'df5x'+i">
            <province-select v-model="director.addressNumber" :country="director.addressCountry"  />
          </validator>
          <validator v-else>
            <input type="text" v-model="director.addressNumber" />
          </validator>
          <div v-if="app.directors.length<5" class="add-dir"></div>
        </div>
      </li>
      <li>
        <label>Telephone number <sup>2</sup></label>
        <div class="field">
          <validator :value="director.phone" reqs="required phone" :key="'df9x'+i">
            <input type="text" v-model="director.phone" />
          </validator>
          <div v-if="app.directors.length<5" class="add-dir"></div>
        </div>
      </li>
      <li>
        <label>Email</label>
        <div class="field">
          <validator :value="director.email" reqs="required email" :key="'df12x'+i">
            <input type="text" v-model="director.email" />
          </validator>
          <div v-if="app.directors.length<5" class="add-dir"></div>
        </div>
      </li>
      <li>
        <label>Passport / ID number</label>
        <div class="field">
          <validator :value="director.passportNum" reqs="required postcode" :key="'df10x'+i">
            <input type="text" v-model="director.passportNum" />
          </validator>
          <div v-if="app.directors.length<5" class="add-dir"></div>
        </div>
      </li>
      <li>
        <label>S.I.N. / S.S.N.</label>
        <div class="field">
          <validator :value="director.ssn" reqs="required num10" :key="'df11x'+i">
            <input type="text" v-model="director.ssn" />
          </validator>
        </div>
      </li>
      <li>&nbsp;</li>
    </ul>
    <div v-if="app.directors.length<5" class="add-dir"><a v-on:click="addDirector">Add Additional Director<span>+</span></a></div>
    <ul class="footnotes">
      <li><sup>1</sup> All directors should be mentioned on the incorporation documents</li>
      <li><sup>2</sup> Including international country code (eg. +1 for USA, +852 for Hong Kong, etc)</li>
    </ul>
  </section>
  
  <section v-if="app.companyIsPublic!=='1' && app.companyIsNonProfit!=='1'">
    <h2>Ultimate Beneficial Owners <sup>3</sup></h2>
    <div class="pgf-main-cb">
      <p class="intro">Are there any UBOs <!--<sup>3</sup>--> owning 10% or more of the company?</p>
      <validator :value="app.largeUBO" reqs="required">
      <ul class="toggle-main">
        <li>
          <label :class="{checked: app.largeUBO==='1'}">
            <input type="radio" name="largeUBO" value="1" v-model="app.largeUBO">
            <i></i>
            <strong>Yes</strong>
            <span>(list all UBOs below: mention UBOs owning largest share % first)</span>
          </label>
        </li>
        <li>
          <label :class="{checked: app.largeUBO==='0'}">
            <input type="radio" name="largeUBO" value="0" v-model="app.largeUBO">
            <i></i>
            <strong>No</strong>
            <span>(list the largest 5 UBOs below: mention all additional UBOs in a separate document)</span>
          </label>
        </li>
      </ul>
      </validator>
    </div>
    <ul class="column-form type2 bh" v-for="(ubo, i) in app.ubos">
      <li class="head" :class="{collapsable: app.ubos.length>1}" v-on:click="toggleCollapse('uboHead'+i)" :ref="'uboHead'+i">
        <label></label>
        <div>
          UBO {{ i+1 }} <a v-if="app.ubos.length>1 && i" class="remove" href="" v-on:click.prevent.stop="app.ubos.splice(i, 1)" title="Remove"><span>&times;</span> Remove UBO</a>
            <label v-if="i<app.directors.length" class="copy" v-on:click.stop=""><input type="checkbox" v-on:change="copyFromDirector(i, $event)" /><span></span>Copy Director {{ i+1 }}</label>
        </div>
      </li>
      <li>
        <label>First Name</label>
        <div class="field">
          <validator :value="ubo.firstName" reqs="required city" :key="'df1x'+i">
            <input type="text" v-model="ubo.firstName" />
          </validator>
        </div>
      </li>
      <li>
        <label>Last name</label>
        <div class="field">
          <validator :value="ubo.lastName" reqs="required city" :key="'df2x'+i">
            <input type="text" v-model="ubo.lastName" />
          </validator>
          <div v-if="app.ubos.length<5" class="add-dir"></div>
        </div>
      </li>
      <li>
        <label>Date of birth</label>
        <div class="field">
          <validator :value="ubo.dob" reqs="required" :key="'df3x'+i">
            <datepicker type="text" v-model="ubo.dob" :future="'18+'" />
          </validator>
          <div v-if="app.ubos.length<5" class="add-dir"></div>
        </div>
        <span class="pfx-info">(YYYY MM DD)</span>
      </li>
      <li>
        <label>Street address</label>
        <div class="field">
          <validator :value="ubo.address" reqs="required address" :key="'df4x'+i">
            <input type="text" v-model="ubo.address" />
          </validator>
          <div v-if="app.ubos.length<5" class="add-dir"></div>
        </div>
        <span class="pfx-info">(home)</span>
      </li>
      <li>
        <label>Postal code / ZIP code</label>
        <div class="field">
          <validator :value="ubo.addressPostcode" reqs="required postcode" :key="'df6x'+i">
            <input type="text" v-model="ubo.addressPostcode" />
          </validator>
          <div v-if="app.ubos.length<5" class="add-dir"></div>
        </div>
      </li>
      <li>
        <label>City</label>
        <div class="field">
          <validator :value="ubo.addressCity" reqs="required city" :key="'df7x'+i">
            <input type="text" v-model="ubo.addressCity" />
          </validator>
          <div v-if="app.ubos.length<5" class="add-dir"></div>
        </div>
      </li>
      <li>
        <label>Country</label>
        <div class="field">
          <validator :value="ubo.addressCountry" reqs="required state" :key="'df8x'+i">
            <div class="combo-select">
            <dyn-select :options="validCountries" v-model="ubo.addressCountry" />
            </div>
          </validator>
          <div v-if="app.ubos.length<5" class="add-dir"></div>
        </div>
      </li>
      <li>
        <label>Province / State</label>
        <div class="field">
          <validator v-if="ubo.addressCountry=='CA' || ubo.addressCountry=='US'"  :value="ubo.addressNumber" reqs="required state" :key="'df5x'+i">
            <province-select v-model="ubo.addressNumber" :country="ubo.addressCountry" />
          </validator>
          <validator v-else>
            <input type="text" v-model="ubo.addressNumber" />
          </validator>
          <div v-if="app.ubos.length<5" class="add-dir"></div>
        </div>
      </li>
      <li>
        <label>Telephone number <sup>2</sup></label>
        <div class="field">
          <validator :value="ubo.phone" reqs="required phone" :key="'df9x'+i">
            <input type="text" v-model="ubo.phone" />
          </validator>
          <div v-if="app.ubos.length<5" class="add-dir"></div>
        </div>
      </li>
      <li>
        <label>Email</label>
        <div class="field">
          <validator :value="ubo.email" reqs="required email" :key="'df12x'+i">
            <input type="text" v-model="ubo.email" />
          </validator>
          <div v-if="app.directors.length<5" class="add-dir"></div>
        </div>
      </li>
      <li>
        <label>Passport / ID number</label>
        <div class="field">
          <validator :value="ubo.passportNum" reqs="required postcode" :key="'df10x'+i">
            <input type="text" v-model="ubo.passportNum" />
          </validator>
          <div v-if="app.ubos.length<5" class="add-dir"></div>
        </div>
      </li>
      <li>
        <label>S.I.N. / S.S.N.</label>
        <div class="field">
          <validator :value="ubo.ssn" reqs="required num10" :key="'df11x'+i">
            <input type="text" v-model="ubo.ssn" />
          </validator>
          <div v-if="app.ubos.length<5" class="add-dir"></div>
        </div>
      </li>
      <li>
        <label>Percentage of ownership</label>
        <div class="field">
          <validator :value="ubo.ownership" reqs="required numberPercent" :key="'df1x'+i">
            <input type="text" v-model="ubo.ownership" />
          </validator>
          <div v-if="app.ubos.length<5" class="add-dir"></div>
        </div>
      </li>
    </ul>
    <div v-if="app.ubos.length<5" class="add-dir"><a v-on:click="addUBO">Add Additional UBO<span>+</span></a></div>
    <ul class="footnotes">
      <li><sup>3</sup> UBO (Ultimate Beneficial Owner) is natural person owning (part of) the company. All UBOs should be listed and should be clearly identifiable from the incorporation documents. Mention UBO owning the largest share % first. If there are more than 5 UBOs, please disclose all additional UBOs in a separate document. In case of a publicly (stock) listed company, or a registered not-for-profit organization, UBOs do not have to be disclosed.</li>
    </ul>
  </section>
  
  <div class="buttons">
    <a class="btn btn-large btn-plain btn-back" v-on:click.prevent="$emit('prev')">Back</a>
    <a class="btn btn-large btn-warn" v-on:click.prevent="$emit('save')">Save</a>
    <a class="btn btn-large btn-success btn-next" v-on:click.prevent="nextStep">Next Step</a>
  </div>
</form>
</template>


<script>
export default {
  props: ["app", "solution"],

  computed: {
    validCountries() {
      var countries = [];
      var lists = [];
      if(this.solution && this.solution.contacts) {
        lists = window.fullCountries.filter(a => {
          return this.solution.contacts.includes(a.id)
        })
      }

      lists.forEach(item => {
        countries.push({
          label: item.text,
          value: item.id
        })
      })

      return countries
    }
  },

  methods: {
    nextStep() {
      if (!this.validate())
        return stopLoading("Please fill all required fields", "error");
      this.$emit("next");      
    },
    addUBO() {
      this.app.ubos.push({});
    },
    addDirector() {
      this.app.directors.push({});
    },
    copyFromDirector(index, event) {
      if (event.target.checked) {
        if (this.app.directors[index])
          this.$set(this.app.ubos, index, JSON.parse(JSON.stringify(this.app.directors[index])));
      } else {
        this.$set(this.app.ubos, index, {});
      }
    },
    toggleCollapse(ref) {
      if (ref.substring(0,3)=="ubo") {
        if (this.app.ubos.length<=1)
          return;
      } else {
        if (this.app.directors.length<=1)
          return;
      }
      var el=$(this.$refs[ref]);
      if (el.hasClass("collapsed")) {
        el.removeClass("collapsed");
        el.parent().find(">li").each(function(i) {
          if (i) $(this).slideDown(300);
        });
      } else {
        el.addClass("collapsed");
        el.parent().find(">li").each(function(i) {
          if (i) $(this).slideUp(300);
        });
      }
    },
  },
  mixins: [formValidation.mixin]
}
</script>
