<template>
  <div>
    <tabs>
      <tab title="Profile" :active="open === 0" @click="open = 0" />
      <tab title="Users" :active="open === 0" @click="open = 1" />
      <tab title="Login activity" :active="open === 1" @click="open = 2" />
    </tabs>

    <ul class="up-acc">
      <li v-if="open === 0" class="open">
        <div class="title">Merchant Profile</div>
        <div class="content">
          <div class="general">
            <div class="uinfo">
              <ul class="ui-prop">
                <li>
                  <label>Username</label>
                  <div>{{ profile.username }}</div>
                </li>
                <li>
                  <label>Services</label>
                  <div>-</div>
                </li>
                <li>
                  <label>Plan</label>
                  <div>-</div>
                </li>
              </ul>
              <a href="" class="tool-btn" v-on:click.prevent="resetPassword"
                >Reset password</a
              >
            </div>
            <aside>
              <div v-if="manager.email">
                <label>Account Manager:</label> {{ manager.fullName }}
              </div>
              <div v-if="manager.email">
                {{ manager.email }}, {{ manager.phone }},
                {{ manager.phoneExt ? "ext " + manager.phoneExt : "" }}
              </div>
              <div><label>AccountID #:</label> {{ profile.agentId }}</div>
              <div v-if="profile.supportPin">
                <label>PIN:</label> {{ profile.supportPin }}
              </div>
            </aside>
          </div>
          <ul class="mo-tabs">
            <li :class="{ active: tab == 0 }">
              <a href="" v-on:click.prevent="tab = 0">General</a>
            </li>
            <li :class="{ active: tab == 1 }">
              <a href="" v-on:click.prevent="tab = 1">Company</a>
            </li>
            <li :class="{ active: tab == 2 }">
              <a href="" v-on:click.prevent="tab = 2">Business</a>
            </li>
            <li :class="{ active: tab == 3 }">
              <a href="" v-on:click.prevent="tab = 3">Banking</a>
            </li>
            <li :class="{ active: tab == 4 }">
              <a href="" v-on:click.prevent="tab = 4">Contacts</a>
            </li>
            <li :class="{ active: tab == 5 }">
              <a href="" v-on:click.prevent="tab = 5">Terminal IDs</a>
            </li>
          </ul>
          <ul class="mo-info" v-if="tab == 0">
            <li>
              <ul>
                <li>
                  <label>Approval Date:</label>
                  <div>
                    {{
                      formatDate(
                        application.approveDate || application.submitDate,
                        "YYYY-MM-DD"
                      )
                    }}
                  </div>
                </li>
                <li>
                  <label>Customer Service Email:</label>
                  <div>{{ application.emailService }}</div>
                </li>
                <li>
                  <label>Account ID:</label>
                  <div>{{ application.accountId }}</div>
                </li>
                <li>
                  <label>Customer Service Phone:</label>
                  <div>{{ application.phoneService }}</div>
                </li>
                <li>
                  <label>Account Class:</label>
                  <div>{{ "" }}</div>
                </li>
                <li>
                  <label>Descriptor:</label>
                  <div>{{ application.descriptor1 }}</div>
                </li>
                <li>
                  <label>Account Manager:</label>
                  <div>
                    {{
                      application.manager &&
                      (application.manager.fullName ||
                        application.manager.companyName)
                    }}
                  </div>
                </li>
                <li>
                  <label>Industry:</label>
                  <div>{{ application.industry }}</div>
                </li>
                <li>
                  <label>Source / Agent:</label>
                  <div>
                    {{
                      application.source &&
                      (application.source.fullName ||
                        application.source.companyName)
                    }}
                  </div>
                </li>
                <li>
                  <label>Gateway:</label>
                  <div>{{ "" }}</div>
                </li>
                <li>
                  <label>MCC:</label>
                  <div>{{ (application.mcc || "").replace(/ .*/, "") }}</div>
                </li>
                <li>
                  <label>Gateway Merchant ID:</label>
                  <div>{{ "" }}</div>
                </li>
                <li>
                  <label>Merchant Type:</label>
                  <div>{{ "" }}</div>
                </li>
                <li>
                  <label>Gateway Status:</label>
                  <div>{{ "" }}</div>
                </li>
                <li>
                  <label>&nbsp;</label>
                  <div>{{ "" }}</div>
                </li>
                <li>
                  <label>Acquirer:</label>
                  <div>{{ "" }}</div>
                </li>
                <li>
                  <label>&nbsp;</label>
                  <div>{{ "" }}</div>
                </li>
                <li>
                  <label>Acquirer Merchant ID:</label>
                  <div>{{ "" }}</div>
                </li>
              </ul>
            </li>
          </ul>
          <ul class="mo-info" v-if="tab == 1">
            <li>
              <ul>
                <li>
                  <label>Company Name:</label>
                  <div>{{ application.companyName }}</div>
                </li>
                <li>
                  <label>City, State, Zip, Country:</label>
                  <div>
                    {{ application.companyAddressCity }},
                    {{ application.companyAddressState }},
                    {{ application.companyAddressPostcode }},
                    {{ application.companyAddressCountry }}
                  </div>
                </li>
                <li>
                  <label>Trade Name:</label>
                  <div>{{ application.companyNameTrade }}</div>
                </li>
                <li>
                  <label>Phone:</label>
                  <div>{{ application.companyPhone }}</div>
                </li>
                <li>
                  <label>Address:</label>
                  <div>{{ application.companyAddress }}</div>
                </li>
                <li>
                  <label>E-mail:</label>
                  <div>{{ application.companyEmail }}</div>
                </li>
              </ul>
            </li>
          </ul>
          <ul class="mo-info" v-if="tab == 2">
            <li>
              <ul>
                <li>
                  <label>Business:</label>
                  <div>{{ application.companyName }}</div>
                </li>
                <li>
                  <label>Descriptor:</label>
                  <div>{{ application.descriptor1 }}</div>
                </li>
                <li>
                  <label>Website:</label>
                  <div>{{ application.website }}</div>
                </li>
                <li>
                  <label>CS Email:</label>
                  <div>{{ application.emailService }}</div>
                </li>
                <li>
                  <label>Beta website:</label>
                  <div>{{ application.websiteBeta }}</div>
                </li>
                <li>
                  <label>CS Phone:</label>
                  <div>{{ application.phoneService }}</div>
                </li>
              </ul>
            </li>
          </ul>
          <ul class="mo-info" v-if="tab == 3">
            <li>
              <ul>
                <li>
                  <label>Settlement Currency:</label>
                  <div>{{ application.accounts[0].accountCurrency }}</div>
                </li>
                <li>
                  <label>SWIFT:</label>
                  <div>{{ application.accounts[0].accountSwift }}</div>
                </li>
                <li>
                  <label>Bank Name:</label>
                  <div>{{ application.accounts[0].bankName }}</div>
                </li>
                <li>
                  <label>IBAN:</label>
                  <div>{{ application.accounts[0].accountIBAN }}</div>
                </li>
                <li>
                  <label>Account Name:</label>
                  <div>{{ application.accounts[0].accountHolder }}</div>
                </li>
                <li>
                  <label>Bank Code:</label>
                  <div>{{ application.accounts[0].accountBankCode }}</div>
                </li>
                <li>
                  <label>Account Number:</label>
                  <div>{{ application.accounts[0].accountNumber }}</div>
                </li>
                <li>
                  <label>Routing:</label>
                  <div>{{ application.accounts[0].accountRouting }}</div>
                </li>
              </ul>
            </li>
          </ul>
          <ul class="mo-info" v-if="tab == 4">
            <li>
              <ul>
                <li>
                  <label>Primary:</label>
                  <div>
                    {{ application.contacts[0].firstName }}
                    {{ application.contacts[0].lastName }},
                    {{ application.contacts[0].phone }},
                    {{ application.contacts[0].email }}
                  </div>
                </li>
                <li>
                  <label>Financial:</label>
                  <div>
                    {{ application.contacts[1].firstName }}
                    {{ application.contacts[1].lastName }},
                    {{ application.contacts[1].phone }},
                    {{ application.contacts[1].email }}
                  </div>
                </li>
                <li>
                  <label>Technical:</label>
                  <div>
                    {{ application.contacts[2].firstName }}
                    {{ application.contacts[2].lastName }},
                    {{ application.contacts[2].phone }},
                    {{ application.contacts[2].email }}
                  </div>
                </li>
                <li>
                  <label>Risk:</label>
                  <div>
                    {{ application.contacts[2].firstName }}
                    {{ application.contacts[2].lastName }},
                    {{ application.contacts[2].phone }},
                    {{ application.contacts[2].email }}
                  </div>
                </li>
              </ul>
            </li>
          </ul>
          <ul class="" v-if="tab == 5">
            <li>
              <ul class="acc-results opt-lists linkcolor">
                <li class="">
                  <h4 class="osb-btns">
                    <span class="label">TIDS</span>
                    <select>
                      <option>Available</option>
                      <option>Assigned</option>
                      <option>Activated</option>
                      <option>Closed</option>
                    </select>
                    <button>Request TID</button>
                  </h4>
                  <div class="acc-list" style="display: block">
                    <table>
                      <thead>
                        <tr>
                          <th>Account ID</th>
                          <th>TID</th>
                          <th>Status</th>
                          <th>Date</th>
                          <th>Owner</th>
                          <th>Options</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>501038</td>
                          <td><a href="">AB1234</a></td>
                          <td>Active</td>
                          <td>September 4, 2019</td>
                          <td>John Smith</td>
                          <td class="osb-btns">
                            <a href="" class="">Eval</a
                            ><a href="" class="">Submit</a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </li>
              </ul>
              <ul class="acc-results opt-lists linkcolor">
                <li class="">
                  <h4 class="osb-btns">
                    <span class="label">KEYS</span>
                    <select>
                      <option>Available</option>
                      <option>Assigned</option>
                      <option>Activated</option>
                      <option>Closed</option>
                    </select>
                    <button>Request KEY</button>
                  </h4>
                  <div class="acc-list" style="display: block">
                    <table>
                      <thead>
                        <tr>
                          <th>Account ID</th>
                          <th>Key</th>
                          <th>Status</th>
                          <th>Date</th>
                          <th>IP</th>
                          <th>Options</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>501038</td>
                          <td><a href="">NmdtMk5FYndaVjNCSXQ1R3</a></td>
                          <td>Active</td>
                          <td>September 4, 2019</td>
                          <td>172.33.222.68</td>
                          <td class="osb-btns">
                            <a href="" class="">Eval</a
                            ><a href="" class="">Submit</a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </li>

      <li v-if="open === 1" class="open">
        <div class="title">Users</div>
        <div class="acc-list">
          <table class="">
            <thead>
              <tr>
                <th style="width: 40%">Username</th>
                <th style="width: 10%">ID</th>
                <th style="width: 20%">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in users" :key="item.id">
                <td class="text-left">
                  {{ item.username }}
                </td>
                <td class="">
                  {{ item.id }}
                </td>
                <td class="text-right">
                  <a
                    v-if="profile != item && mainUser"
                    class="block"
                    href=""
                    v-on:click.prevent="blockUser(index)"
                    >{{ item.active == "Blocked" ? "Unblock" : "Block" }}</a
                  >
                  <a
                    v-if="profile != item && mainUser"
                    class="edit"
                    href=""
                    v-on:click.prevent="editUser(index)"
                    >Edit</a
                  >
                  <a
                    v-if="profile != item && mainUser"
                    class="edit"
                    href=""
                    v-on:click.prevent="resetPwUser(index)"
                    >Reset Password</a
                  >
                  <a
                    v-if="profile != item && mainUser"
                    class="remove"
                    href=""
                    v-on:click.prevent="deleteSubuser(index)"
                    >Delete</a
                  >
                </td>
              </tr>
              <tr>
                <td colspan="3">
                  <a v-if="mainUser" href="" v-on:click.prevent="newUser"
                    >New user</a
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </li>
      <li v-if="open === 2" class="open">
        <div class="title">Login Activity</div>
        <div class="acc-list">
          <table class="">
            <thead>
              <tr>
                <th style="width: 33%">Username</th>
                <th style="width: 33%">Time</th>
                <th style="width: 34%">IP Address</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in logins">
                <td class="main-col">
                  {{ usersById[item.userId].username }}
                </td>
                <td class="">
                  {{ item.recorded }}
                </td>
                <td class="">
                  {{ item.ipAddress }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import Tab from "./components/tabs/Tab";
import Tabs from "./components/tabs/Tabs";

export default {
  components: {
    Tab,
    Tabs,
  },

  data() {
    return {
      open: 0,
      tab: 0,
      profile: {},
      manager: {},
      application: {},
      users: {},
      mainUser: false,
    };
  },
  computed: {
    usersById() {
      var result = {};
      for (var i in this.users) result[this.users[i].id] = this.users[i];
      result[this.profile.id] = this.profile;
      return result;
    },
  },
  mounted() {
    this.profile = window.userProfile;
    this.manager = window.managerProfile;
    this.application = window.applicationData;
    this.mainUser = window.mainUser;
    this.users = window.userProfiles;
    this.users.unshift(this.profile);
  },
  methods: {
    deleteSubuser: async function (index) {
      var that = this;
      if (confirm("Are you sure?")) {
        startLoading();
        await api.delete("User", that.users[index].id);
        that.users.splice(index, 1);
        stopLoading("User deleted");
      }
    },
    blockUser: async function (index) {
      startLoading();
      var item = this.users[index];
      await api.update("User", item.id, {
        active: item.active == "Blocked" ? "Yes" : "Blocked",
      });
      stopLoading("User status updated");
      item.active = item.active == "Blocked" ? "Yes" : "Blocked";
    },
    editUser(index) {
      userEditSub.showPopup(this.users[index]);
    },
    newUser() {
      userEditSub.showPopup();
    },
    resetPwUser: async function (index) {
      var user = this.users[index];
      if (!user.id) return;
      startLoading();
      await api.update("User", user.id, {
        passChange: 1,
      });
      stopLoading("Password reset", "success");
    },
  },
  mixins: [vueMixins.userProfile, vueMixins.dateFormatter],
};
</script>
