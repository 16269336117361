<template>
<div>
<!--   <view-app-extcheck type="idm" :uwList="itemList" v-on:update="listIDM" ref="extcheck" /> -->

      <div>
        <div class="action-sub-buttons" v-if="false">
        </div>
        <table class="file-list content-table">
          <thead>
            <tr>
              <th>Entity</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ item.agentDb.name }}</td>
              <td>ERROR</td>
            </tr>
            <tr v-for="(ubo, ui) in item.contacts">
              <td>{{ ubo.firstName }} {{ ubo.lastName }}</td>
              <td>ERROR</td>
            </tr>
          </tbody>
        </table>
        <br />
        <table class="file-list content-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Date</th>
              <th>Type</th>
              <th>Filename</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(uwItem, uwI) in itemList">
              <td>{{ itemList.length-uwI }}</td>
              <td>{{ formatDate(uwItem.createdDate, "YYYY-MM-DD HH:mm") }}</td>
              <td>Acuant</td>
              <td v-if="uwItem.type=='g2'"><a :href="printUrlForItem(uwItem)" target="_blank">{{ docNameForItem(uwItem) }}</a></td>
              <td v-else></td>
              <td v-if="uwItem.type=='g2'">{{ uwItem.response.complete?'Completed':'Pending' }}</td>
              <td v-else>
                {{ item.companyName+" "+item.companyType }} |
                {{ uwItem.response && uwItem.response.res || 'ERROR' }}
                <template v-for="person in uwItem.response.people || []">
                  <br />
                  {{ dbPerson(person.personId) }} |
                  {{ person.res || 'ERROR' }}
                </template>
              </td>
            </tr>
          </tbody>
        </table>
      </div>



  <portal to="actions" v-if="true">
    <ul>
      <li>
        <a href="" v-on:click.prevent="newCheck">Run New</a>
      </li>
      <li v-if="false && itemList && itemList.length">
        <a v-on:click.prevent="refresh(itemList[0])">Refresh</a>
      </li>
    </ul>
  </portal>
</div>
</template>
<script>
export default {
  props: ["item", "merchant"],
  data: ()=>({
    itemList: [],
    uwSelected: null,
  }),
  computed: {
  },
  methods: {
    dbPerson(id) {
      var person=this.item.ubos.find(ubo=>ubo.id==id);
      if (person)
        return person.firstName+' '+person.lastName;
      return "";
    },
    listIDM: async function() {
      this.itemList=(await api.list("ExtCheck", {applicationId: this.item.id, type: "idm"}, ["id:DESC"])).items || [];
    },
    refresh: async function() {
      startLoading();
      await api.update("ExtCheck", id, {type: "idm"});
      await this.listIDM();
      stopLoading();
    },
    newCheck: async function() {
      startLoading();
      if (this.preq)
        var id=(await api.create("ExtCheck", {
          type: 'idm',
          userId: appData.currentUser.id,
          createdDate: "CURRENT_TIMESTAMP",
          preqId: this.item.id,
        })).id;
      else
        var id=(await api.create("ExtCheck", {
          type: 'idm',
          userId: appData.currentUser.id,
          createdDate: "CURRENT_TIMESTAMP",
          applicationId: this.item.id,
        })).id;

      var newItem={}

      await this.listIDM();
//       this.doShowItems(this.uwList[this.uwList.length-1].id);
      this.$emit('update');
      stopLoading();
    },
    printUrlForItem(item) {
      if (!item)
        return null;
      if (!item.response || !item.response.pdf_url)
        return null;
      return item.response.pdf_url;
    },
    docNameForItem(item) {
      var date=moment(item.createdDate);
      return this.item.accountId+" "+date.format("D M YYYY")+".pdf";
    },

  },
  mounted() {
    this.listIDM();
  },
  mixins: [vueMixins.dateFormatter],
}
</script>
