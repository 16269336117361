<template>
<form class="edit-system search-form">
  <div class="">
    <div>
      <div class="field">
        <label>Agreement General:</label>
        <textarea v-model="item.agrGeneral"></textarea>
      </div>
      <div class="field">
        <label>Agreement Company Info:</label>
        <textarea v-model="item.argCompany"></textarea>
      </div>
      <div class="field">
        <label>Agreement Banking:</label>
        <textarea v-model="item.agrBanking"></textarea>
      </div>
      <div class="field">
        <label>Agreement Modules:</label>
        <textarea v-model="item.agrModules"></textarea>
      </div>
      <div class="field">
        <label>Agreement Equipment:</label>
        <textarea v-model="item.agrEquipment"></textarea>
      </div>
      <div class="field">
        <label>Agreement Options:</label>
        <textarea v-model="item.agrOptions"></textarea>
      </div>
      <div class="field">
        <label>Agreement Pricing:</label>
        <textarea v-model="item.agrPricing"></textarea>
      </div>
    </div>
  </div>
  <div class="buttons">
    <button class="btn btn-large btn-primary" v-on:click.prevent="updateSettings">Submit</button>
  </div>  
</form>
</template>

<script>
export default {
  data() { return {
    item: {},
  }},
  async created () {
    this.item = (await api.get("InternalNotes", 1)).item
  },
  methods: {
    refresh() {
      var that=this;
      api.get("InternalNotes", 1).then(function(data) {
        that.item=data.item;
      });
    },
    updateSettings: async function() {
      startLoading();
      var newItem=JSON.parse(JSON.stringify(this.item));
      await api.update("InternalNotes", 1, newItem);
      
      stopLoading("Internal notes updated");
      this.refresh();
    },
  },
}
</script>
