<template>
<div class="setupcl">
<ul>
  <li>
    <label>Run Pre-Check</label>
    <div class="done" v-if="isPrecheckDone"></div>
    <div class="actions" v-else>
      <a class="button" href="" v-on:click.prevent="runPrecheck()">Run</a>
    </div>
  </li>
  <li>
    <label>Website Check</label>
    <div class="done" v-if="isWebsiteCheckDone"></div>
    <div class="actions" v-else>
      <a class="button" :class="{disabled: !isPrecheckDone}" href="" v-on:click.prevent="isPrecheckDone && startWebsiteCheck()">Run</a>
    </div>
  </li>
  <li>
    <label>Compliance Scans</label>
    <div class="done" v-if="complianceDone2 || isApproved"></div>
    <div class="actions" v-else-if="complianceDone">
      <a class="button done" href="" v-on:click.prevent="isWebsiteCheckDone && !secondaryPrecheck && (complianceDone=false)">Skipped</a>
    </div>
    <div class="actions" v-else>
      <a class="button" :class="{disabled: !isWebsiteCheckDone}" href="" v-on:click.prevent="isWebsiteCheckDone && (complianceDone=true)">Skip</a>
      <a class="button" :class="{disabled: !isWebsiteCheckDone}" href="" v-on:click.prevent="isWebsiteCheckDone && (complianceDone=true) && (complianceDone2=true) && ($parent.actionsTab=3)">Scan</a>
    </div>
  </li>
  <li>
    <label>Pre-Check</label>
    <div class="done" v-if="secondaryPrecheck2 || isApproved"></div>
    <div class="actions" v-else-if="secondaryPrecheck">
      <a class="button done" href="" v-on:click.prevent="complianceDone && (secondaryPrecheck=false)">Skipped</a>
    </div>
    <div class="actions" v-else>
      <a class="button" :class="{disabled: !complianceDone}" href="" v-on:click.prevent="complianceDone && (secondaryPrecheck=true)">Skip</a>
      <a class="button" :class="{disabled: !complianceDone}" href="" v-on:click.prevent="complianceDone && (secondaryPrecheck=true) && (secondaryPrecheck2=true) && runPrecheck()">Run</a>
    </div>
  </li>
  <li>
    <label>Pre-Approval</label>
    <div class="done" v-if="isApproved"></div>
    <div class="actions" v-else>
      <a class="button" :class="{disabled: !secondaryPrecheck}" href="" v-on:click.prevent="secondaryPrecheck && doFinalApproval()">Run</a>
    </div>
  </li>

  
  
</ul>
</div>
</template>

<script>
export default {
  props: ["item", "notes", "prechecks", "weblist"],
  data: ()=>({
    complianceDone: false,
    complianceDone2: false,
    secondaryPrecheck: false,
    secondaryPrecheck2: false,
  }),
  computed: {
    isSignedApp() {
      return !!this.$parent.signedApplications.length
    },
    isPrecheckDone() {
      return !!(this.prechecks && this.prechecks.length)
    },
    isWebsiteCheckDone() {
      return !!(this.weblist && this.weblist.length)
    },
    isAgreementDone() {
      return !!this.$parent.agreements.length
    },
    isAgreementSigned() {
      for(var agreement of this.$parent.agreements) {
        if (agreement.signed)
          return true
        break
      }
    },
    isApproved() {
      return this.$parent.item.status=="A01";
    },
  },
  methods: {
    uploadSignedApplication() {
      this.$parent.uploadFile(this.$parent.signedApplicationField)
    },
    startWebsiteCheck() {
      this.$parent.actionsTab=3;
      this.$parent.listWebsiteChecks();
    },
    runPrecheck() {
      this.$parent.listPreChecks();
    },
    signAgreement() {
      for(var agreement of this.$parent.agreements) {
        this.$parent.uploadSigned(agreement, 0)
        break
      }
    },
    doFinalApproval() {
      this.$parent.doAction(this.$parent.item, "A01");
    },
    async goToBoarding() {
      var item=this.item;
      var id=(await api.create("PartnerSetup", {
        agentId: item.id,
        created: "CURRENT_TIMESTAMP",
        name: item.agentDb.companyName+" "+item.agentDb.agentId,
        accClass: "",
        managerId: item.agentDb.manager,
        filename: null,
        signed: null,
        salesBrand: null,
        solutions: [],
        regions: [],
        portal: 'trak',
        pin: null,
        username: null,
        buildId: "APP_ID_GEN_AGENTBUILD",
        buildBy: appData.currentUser.id,
      })).id;
      window.open("/implementation/agentsetup/"+id, "_self");
    },
  },
}
</script>


