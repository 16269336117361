<template>
<section>
  <ul class="appview-list">
    <li>
      <label>Setup Date</label>
      <div class="validator">
        <span>{{ formatDate(employee.created, 'MM/DD/YYYY') }}</span>
      </div>
    </li>
    <li>
      <label>Activation Date</label>
      <div class="validator">
        <span>{{ employee.userId.activationDate ? formatDate(employee.userId.activationDate, 'MM/DD/YYYY') : 'N/A' }}</span>
      </div>
    </li>
    <li>
      <label>Employee ID</label>
      <div class="validator">
        <span>{{ employee.employeeId }}</span>
      </div>
    </li>
    <li>
      <label>Employee Solution</label>
      <div class="validator">
        <validator v-if="editMode" :value="form.companyId" reqs="required">
          <select v-model="form.companyId">
            <option :value="company.id" v-for="company in companies">{{ company.nameSolution }}</option>
          </select>
        </validator>
        <span v-else>{{ employee.companyId.nameSolution }}</span>
      </div>
    </li>
  </ul>
  <portal to="actions">
    <ul>
      <li>
        <a href="" v-on:click.prevent="$emit('update:editMode', true)">Edit</a>
      </li>
    </ul>
  </portal>
</section>
</template>

<script>
export default {
  mixins: [ vueMixins.helpers, vueMixins.dateFormatter ],

  props: {
    employee: {
      type: Object,
      required: true
    },

    value: {
      type: Object,
      required: true
    },

    editMode: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      companies: [],
      groups: window.userGroups,
      titleGroups: window.employeeRoles
    }
  },

  computed: {
    form: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    async loadCompanies() {
      this.companies=(await api.list("Solution", {solutionType: "Employees"})).items;
    },
  },

  created () {
    this.loadCompanies();
  }
}
</script>
