<template>
<ul class="progress-bar">
  <li v-for="(cstep, index) in steps" :class="{completed: index<step, current: index==step}">
    <span v-html="cstep"></span>
  </li>
</ul>
</template>

<script>
export default {
  props: ["steps", "step"],
}
</script>
