<template>
<div class="popup usermgmt" :class="{shown: visible}">
  <div class="pop-med">
    <div class="pop-inner">
      <a href="" class="close" v-on:click.prevent="visible=false"></a>
      <h2>Provider Details &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {{ provider.status }}</h2>
      <form class="search-form">
        <h3 class="services-text">Information</h3>
        <div class="f2split">
          <div>
            <div class="field" style="justify-content: start;">
              <label>Type</label>
              <label>{{ provider.providerType }}</label>
            </div>
          </div>
          <div>
            <div class="field">
              <label>Date Added</label>
              {{ provider.dateAdded? provider.dateAdded.substr(0, 10) : '' }}
            </div>
          </div>
        </div>
        <div class="f2split">
          <div>
            <div class="field">
              <label>Name</label>
              <validator :value="provider.providerType" reqs="required">
                <input :disabled="!editing" type="text" v-model="provider.providerName" />
              </validator>
              </div>
          </div>
          <div>
            <div class="field">
              <label>Version</label>
              <validator :value="provider.version" reqs="required">
                <input :disabled="!editing" type="text" v-model="provider.version" />
              </validator>
            </div>
          </div>
        </div>
        <div class="f2split">
          <div>
            <div class="field">
              <label>Environment</label>
              <validator :value="provider.environment" reqs="required">
                <dyn-select :disabled="!editing"  v-model="provider.environment" :options="environments" />
              </validator>
            </div>
          </div>
          <div>
            <div class="field">
              <label>Service Level</label>
              <validator :value="provider.serviceLevel" reqs="required">
                <dyn-select :disabled="!editing"  v-model="provider.serviceLevel" :options="serviceLevels" />
              </validator>
            </div>
          </div>
        </div>
        <div class="f2split">
          <div>
            <div class="field">
              <label>Username</label>
              <input :disabled="!editing" v-model="provider.username" />
            </div>
          </div>
          <div>
            <div class="field">
              <label>Password</label>
              <input :disabled="!editing" v-model="provider.providerPassword" />
            </div>
          </div>
        </div>
        <div class="f2split">
          <div>
            <div class="field">
              <label>URL</label>
              <validator :value="provider.url" reqs="required">
                <input :disabled="!editing" v-model="provider.url" />
              </validator>
            </div>
          </div>
          <div>
            <div class="field">
              <label>Certification</label>
              <datepicker :disabled="!editing" v-model="provider.certifiedDate" />
            </div>
          </div>
        </div>
        <div class="f2split">
          <div>
            <div class="field">
              <label>ORG ID</label>
              <input :disabled="!editing" v-model="provider.orgId" />
            </div>
          </div>
          <div>
            <div class="field">
              <label>Vault ID</label>
              <input :disabled="!editing" v-model="provider.vaultId" />
            </div>
          </div>
        </div>
        <div class="f2split">
          <div>
            <div class="field">
              <label>Key Expiry</label>
              <datepicker :disabled="!editing" v-model="provider.providerKeyExpiry" />
            </div>
          </div>
          <div>
            <div class="field">
              <label>Profile</label>
              <input :disabled="!editing" v-model="provider.profile" />
            </div>
          </div>
        </div>
        <div class="f2split">
          <div>
            <div class="field">
              <label>Label</label>
              <input :disabled="!editing" v-model="provider.label" />
            </div>
          </div>
          <div>
            <div class="field">
              
            </div>
          </div>
        </div>
        <div class="field shortlab">
          <label>Key</label>
          <input :disabled="!editing" v-model="provider.providerKey" />
        </div>
        <div class="field shortlab">
          <label>Client ID</label>
          <input :disabled="!editing" v-model="provider.clientId" />
        </div>
        <div class="field shortlab">
          <label>Client Secret</label>
          <input :disabled="!editing" v-model="provider.clientSecret" />
        </div>
        <div class="field countries-section">
          <label>Transaction Countries</label>
          <div class="countries">
            <multiselect-dropdown :options="fullCountries.map(a=>({label: a.text, value: a.id}))" v-model="provider.transactionCountries" :isMulti="true" />
          </div>
        </div>
        <br/>
        <h3 class="services-text">Services</h3>
        <div v-if="provider.providerType == 'Payments'">
          <h5 class="services-subtitle">Billing</h5>
          <div class="services">
            <div class="input-group">
              <label>Daily</label>
              <mnb-switch :disabled="!editing" v-model="provider.service.payments.billing.daily" />
            </div>
            <div class="input-group">
              <label>Monthly</label>        
              <mnb-switch :disabled="!editing" v-model="provider.service.payments.billing.monthly" />
            </div>
            <div class="input-group">
              <label>Flat Rate</label>
              <mnb-switch :disabled="!editing" v-model="provider.service.payments.billing.flatRate" />
            </div>
            <div class="input-group">
              <label>Interchange Plus</label>
              <mnb-switch :disabled="!editing" v-model="provider.service.payments.billing.interchangePlus" />
            </div>
            <div class="input-group">
              <label>Tiered</label>
              <mnb-switch :disabled="!editing" v-model="provider.service.payments.billing.tiered" />
            </div>
          </div>

          <h5 class="services-subtitle">Methods</h5>
          <div class="services">
            <div class="input-group">
              <label>Visa</label>
              <mnb-switch :disabled="!editing" v-model="provider.service.payments.methods.visa" />
            </div>
            <div class="input-group">
              <label>MasterCard</label>
              <mnb-switch :disabled="!editing" v-model="provider.service.payments.methods.masterCard" />
            </div>
            <div class="input-group">
              <label>Discover</label>
              <mnb-switch :disabled="!editing" v-model="provider.service.payments.methods.discover" />
            </div>
            <div class="input-group">
              <label>AMEX</label>
              <mnb-switch :disabled="!editing" v-model="provider.service.payments.methods.amex" />
            </div>
            <div class="input-group">
              <label>JCB</label>
              <mnb-switch :disabled="!editing" v-model="provider.service.payments.methods.jcb" />
            </div>
            <div class="input-group">
              <label>Diners</label>
              <mnb-switch :disabled="!editing" v-model="provider.service.payments.methods.diners" />
            </div>
            <div class="input-group">
              <label>Debit</label>
              <mnb-switch :disabled="!editing" v-model="provider.service.payments.methods.debit" />
            </div>
            <div class="input-group">
              <label>Interac</label>
              <mnb-switch :disabled="!editing" v-model="provider.service.payments.methods.interac" />
            </div>
          </div>

          <h5 class="services-subtitle">Advanced Methods</h5>
          <div class="services">
            <div class="input-group">
              <label>Apple Pay</label>
              <mnb-switch :disabled="!editing" v-model="provider.service.payments.advancedMethods.applePay" />
            </div>
            <div class="input-group">
              <label>Google Pay</label>
              <mnb-switch :disabled="!editing" v-model="provider.service.payments.advancedMethods.googlePay" />
            </div>
            <div class="input-group">
              <label>Samsung Pay</label>
              <mnb-switch :disabled="!editing" v-model="provider.service.payments.advancedMethods.samsungPay" />
            </div>
            <div class="input-group">
              <label>Interac Online</label>
              <mnb-switch :disabled="!editing" v-model="provider.service.payments.advancedMethods.interacOnline" />
            </div>
            <div class="input-group">
              <label>Interac Transfer</label>
              <mnb-switch :disabled="!editing" v-model="provider.service.payments.advancedMethods.interacTransfer" />
            </div>
          </div>

          <h5 class="services-subtitle">Bank</h5>
          <div class="services">
            <div class="input-group">
              <label>EFT</label>
              <mnb-switch :disabled="!editing" v-model="provider.service.payments.bank.eft" />
            </div>
            <div class="input-group">
              <label>EFT Credits</label>
              <mnb-switch :disabled="!editing" v-model="provider.service.payments.bank.eftCredits" />
            </div>
            <div class="input-group">
              <label>ACH</label>
              <mnb-switch :disabled="!editing" v-model="provider.service.payments.bank.ach" />
            </div>
            <div class="input-group">
              <label>ACH Credits</label>
              <mnb-switch :disabled="!editing" v-model="provider.service.payments.bank.achCredits" />
            </div>
          </div>

          <h5 class="services-subtitle">Options</h5>
          <div class="services">
            <div class="input-group">
              <label>Multi-Currency</label>
              <mnb-switch :disabled="!editing" v-model="provider.service.payments.options.multiCurrency" />
            </div>
            <div class="input-group">
              <label>Subscription</label>
              <mnb-switch :disabled="!editing" v-model="provider.service.payments.options.subscription" />
            </div>
            <div class="input-group">
              <label>Account Updater</label>
              <mnb-switch :disabled="!editing" v-model="provider.service.payments.options.accountUpdater" />
            </div>
            <div class="input-group">
              <label>Tokenization</label>
              <mnb-switch :disabled="!editing" v-model="provider.service.payments.options.tokenization" />
            </div>
            <div class="input-group">
              <label>Level 2</label>
              <mnb-switch :disabled="!editing" v-model="provider.service.payments.options.level2" />
            </div>
            <div class="input-group">
              <label>Level 3</label>
              <mnb-switch :disabled="!editing" v-model="provider.service.payments.options.level3" />
            </div>
          </div>
        </div>

        <div v-if="provider.providerType == 'Banking'">
          <h5 class="services-subtitle">Accounts</h5>
          <div class="services">
            <div class="input-group">
              <label>KYC</label>
              <mnb-switch :disabled="!editing" v-model="provider.service.banking.accounts.kyc" />
            </div>
            <div class="input-group">
              <label>AML</label>        
              <mnb-switch :disabled="!editing" v-model="provider.service.banking.accounts.aml" />
            </div>
          </div>

          <h5 class="services-subtitle">Methods</h5>
          <div class="services">
            <div class="input-group">
              <label>ACH</label>
              <mnb-switch :disabled="!editing" v-model="provider.service.banking.methods.ach" />
            </div>
            <div class="input-group">
              <label>Account Transfer</label>
              <mnb-switch :disabled="!editing" v-model="provider.service.banking.methods.accountTransfer" />
            </div>
            <div class="input-group">
              <label>Checks</label>
              <mnb-switch :disabled="!editing" v-model="provider.service.banking.methods.checks" />
            </div>
            <div class="input-group">
              <label>Remote Deposit</label>
              <mnb-switch :disabled="!editing" v-model="provider.service.banking.methods.remoteDeposit" />
            </div>
            <div class="input-group">
              <label>Domestic Wires</label>
              <mnb-switch :disabled="!editing" v-model="provider.service.banking.methods.domesticWires" />
            </div>
            <div class="input-group">
              <label>International Wires</label>
              <mnb-switch :disabled="!editing" v-model="provider.service.banking.methods.internationalWires" />
            </div>
          </div>

          <h5 class="services-subtitle">Advanced Methods</h5>
          <div class="services">
            <div class="input-group">
              <label>Debit Cards</label>
              <mnb-switch :disabled="!editing" v-model="provider.service.banking.options.debitCards" />
            </div>
            <div class="input-group">
              <label>Digital Assets</label>
              <mnb-switch :disabled="!editing" v-model="provider.service.banking.advancedMethods.digitalAssets" />
            </div>
          </div>

          <h5 class="services-subtitle">Options</h5>
          <div class="services">
            <div class="input-group">
              <label>Multi-Currency</label>
              <mnb-switch :disabled="!editing" v-model="provider.service.banking.options.multiCurrency" />
            </div>
          </div>
        </div>

        <div v-if="provider.providerType == 'Compliance'">
          <h5 class="services-subtitle">Accounts</h5>
          <div class="services">
            <div class="input-group">
              <label>KYC</label>
              <mnb-switch :disabled="!editing" v-model="provider.service.compliance.accounts.kyc" />
            </div>
            <div class="input-group">
              <label>AML</label>        
              <mnb-switch :disabled="!editing" v-model="provider.service.compliance.accounts.aml" />
            </div>
            <div class="input-group">
              <label>Fraud</label>        
              <mnb-switch :disabled="!editing" v-model="provider.service.compliance.accounts.fraud" />
            </div>
            <div class="input-group">
              <label>Monitoring</label>        
              <mnb-switch :disabled="!editing" v-model="provider.service.compliance.accounts.monitoring" />
            </div>
            <div class="input-group">
              <label>Chargebacks</label>        
              <mnb-switch :disabled="!editing" v-model="provider.service.compliance.accounts.chargebacks" />
            </div>
          </div>
        </div>
        <br/>
        <h3 class="services-text">Notes</h3>
        <div class="field shortlab">
          <label>Notes</label>
          <input :disabled="!editing" v-model="provider.notes" />
        </div>

        <div class="buttons">
          <button v-if="!editing" class="btn btn-small btn-primary" v-on:click.prevent="editing = true">Update</button>
          <template v-else>
            <button class="btn btn-small mr-20 btn-primary" v-on:click.prevent="send">Save</button>
            <button class="btn btn-small btn-danger" v-on:click.prevent="editing = false">Cancel</button>
          </template>
        </div>
      </form>
    </div>
  </div>
</div>
</template>

<script>
  import MnbSwitch from './switch.vue'
export default {
  components: {
      MnbSwitch,
    },
  data() { return {
    readonly: false,
    visible: false,
    provider: {},
    statuses: [
        {value: "Review", label: "Review"},
        {value: "Testing", label: "Testing"},
        {value: "Active", label: "Active"},
        {value: "Disabled", label: "Disabled"},
      ],
  
      types: [
        {value: "", label: ""},
        {value: "Payments", label: "Payments"},
        {value: "Banking", label: "Banking"},
        {value: "Compliance", label: " Compliance"},
      ],
  
      environments: [
        {value: "Live", label: "Live"},
        {value: "Sandbox", label: "Sandbox"},
      ],
  
      serviceLevels: [
        {value: "Full", label: "Full"},
        {value: "Partial", label: "Partial"},
      ],
    editing: false,
  }},
  watch: {
    
  },
  computed: {
    fullCountries() {
      return window.fullCountries
    }
  },
  methods: {
    showPopup(provider, readonly) {
      this.editing=false
      this.readonly=readonly || false;
      this.validateReset();
      this.provider=provider;
      if(this.provider.service == null) {
        provider.service = {
            payments: {
              billing: {
                daily: false,
                monthly: false,
                flatRate: false,
                interchangePlus: false,
                tiered: false,
              },

              methods: {
                visa: false,
                masterCard: false,
                discover: false,
                amex: false,
                jcb: false,
                diners: false,
                debit: false,
                interac: false,
              },

              advancedMethods: {
                applePay: false,
                googlePay: false,
                samsungPay: false,
                interacOnline: false,
                interacTransfer: false,
              },

              bank: {
                eft: false,
                eftCredits: false,
                ach: false,
                achCredits: false,
              },

              options: {
                multiCurrency: false,
                subscription: false,
                accountUpdater: false,
                tokenization: false,
                level2: false,
                level3: false,
              },
            },

            banking: {
              accounts: {
                kyc: false,
                aml: false,
              },

              methods: {
                ach: false,
                accountTransfer: false,
                checks: false,
                remoteDeposit: false,
                domesticWires: false,
                internationalWires: false,
              },

              advancedMethods: {
                digitalAssets: false,
              },

              options: {
                multiCurrency: false,
                debitCards: false,
              },
            },

            compliance: {
              accounts: {
                kyc: false,
                aml: false,
                fraud: false,
                monitoring: false,
                chargebacks: false,
              },
            },
          }
      }
      this.visible=true;
    },
    send: async function() {
      if (!this.validate())
          return stopLoading("Please fill all required fields", "error");
        this.visible=false;
        startLoading();
        await api.update("Provider", this.provider.id, this.provider);
        stopLoading("Provider updated", "success");
    },
  },
  mounted: async function() {
    window.providerEdit=this;
  },
  mixins: [formValidation.mixin]
}
</script>
<style lang="scss" scoped>
.countries-section {
  display: flex;
  justify-content: normal;
}
</style>