<template>
<div class="preform-outer">
<div class="preformbg"></div>
<div class="ncontent appf appnat_ preform pfx pfx-simulation">
  <div class="cb-logo">CardBiller</div>
  <h1>Merchant Application</h1>
  <div class="form-info">
    <p>Application Assistance</p>
    <p><strong>{{ companydata.applicationEmail }}</strong></p>
    <p><strong>{{ companydata.servicesTel }}</strong></p>
  </div>
  <p class="form-intro">
    Please complete this form in full. Incomplete forms are not accepted.
  </p>
  <div class="temp-link" v-if="false">
    <label>Guest access to this application:</label>
    <span v-if="!link">
      <button v-on:click.prevent="save">Generate and Save Link</button>
    </span>
    <a v-else :href="link.url" target="_blank" v-on:click.prevent="copyLink">{{ link.url }}</a>
  </div>
  
  
  <form-apply-0 :app="app" />
  <form-apply-1 :app="app" />
  <form-apply-2 :app="app" :auxtype="auxtype" />
  <form-apply-3 :app="app" />
  <form-apply-4 :app="app" />
  <form-apply-5 :app="app" />
  <form-apply-6 :app="app" />
  <form-apply-7 :app="app" :basepath="basepath" :files="files" v-on:next="submit" :simulation="true" v-on:reset="reset" />

  <ul class="notes">
    <li v-if="errorMessage">{{ errorCode }} {{ errorMessage }}</li>
    <li v-for="field in errorFields">{{ field }}</li>
  </ul>


<!--   <div class="version">v1.0</div> -->
</div>
</div>
</template>


<script>
export default {
  props: ["auxtype"],
  data() { return {
    step: (window.existingApplication || {}).currentStep*1 || 0,
    app: window.existingApplication || {
      status: "A99",
      companyNew: false,
      hasWebsite: null,
      source: appData.currentUser.id,
      solution: appData.solution || null,
      requirements: {},
      ignore: {},
      directors: [{}],
      ubos: [{}],
      history: [{}, {}, {}, {}, {}, {}],
      accounts: [{accountCurrency: "124"}],
      contacts: [{type:'general'}, {type:'technical'}, {type:'financial'}, {type:'risk'}],
      documents: [],
      industry: null,
      apiMessaging: true,
      apiUpdates: true,
    },
    companydata: appData.company,
    link: window.existingLink || null,
    files: {},
    errorMessage: null,
    errorCode: null,
    errorFields: [],
    savedVal: null,
  }},
  computed: {
    basepath() {
      return "files/application/"+this.app.id;
    },
  },
  mounted() {
    this.loadSaved();
  },
  methods: {
    loadSaved: async function() {
      this.savedVal=(await api.list("SysValue", {propkey: "flysimulation"})).items[0];
      if (this.savedVal && this.savedVal.propvalue) {
        this.app=JSON.parse(this.savedVal.propvalue);
      }
    },
    save: async function() {
      if (!this.app.applicationId)
        this.app.applicationId="APP_ID_GEN_APPL";
      var data=JSON.parse(JSON.stringify(this.app));
      delete data.ignore;
      delete data.id;
      delete data.documents;

      data.currentStep=this.step;
      data.auxType=this.auxtype;
      
      data.documents=[];
      for(var fileField in this.files) {
        var file=this.files[fileField];
        if (file instanceof File)
          data.documents.push({
            type: fileField,
          });
      }
      
      var steps=1;
      var step=0;
      for(var fileField in this.files) {
        var file=this.files[fileField];
        if (file instanceof File)
          steps++;
      }
      startLoading(steps);
      
      var result=await extapi.createApplication({
        obj: data,
        mmid: data.mmid,
        username: data.mmidUsername,
        password: data.mmidPassword,
        ipAddress: data.ipAddress
      });
      if (result.errorCode) {
        this.errorCode=result.errorCode;
        this.errorMessage=result.errorMessage;
        this.errorFields=result.errorFields || [];
        return stopLoading(result.errorCode+" "+result.errorMessage, "error");
      } else {
        this.errorCode="Success!";
        this.errorMessage="";
        this.errorFields=[];
      }
      
      this.app.id=result.id;
      this.app.token=result.token;
      progressLoading(++step);

      for(var fileField in this.files) {
        var file=this.files[fileField];
        if (file instanceof File) {
          await extapi.createApplicationDoc(file, fileField, this.app.id, this.app.token, {
            mmid: data.mmid,
            username: data.mmidUsername,
            password: data.mmidPassword,
            ipAddress: data.ipAddress
          });
          progressLoading(++step);
        }
      }
      if (this.submitMessage)
        stopLoading("Application submitted", "success");
      else
        stopLoading("Application saved", "success");      
    },
    submit: async function() {
      api.update("SysValue", this.savedVal.id, {propvalue: JSON.stringify(this.app)});
      this.submitMessage=true;
      await this.save();
    },
    reset: async function() {
      startLoading();
      this.app={
        status: "A99",
        companyNew: false,
        hasWebsite: null,
        source: appData.currentUser.id,
        solution: appData.solution || null,
        requirements: {},
        ignore: {},
        directors: [{}],
        ubos: [{}],
        history: [{}, {}, {}, {}, {}, {}],
        accounts: [{accountCurrency: "124"}],
        contacts: [{type:'general'}, {type:'technical'}, {type:'financial'}, {type:'risk'}],
        documents: [],
        industry: null,
      };
      await api.update("SysValue", this.savedVal.id, {propvalue: JSON.stringify(this.app)});
      stopLoading("Data reset");
    }
  },
}
</script>
