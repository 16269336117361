<template>
  <div>
    <div v-if="selectedItem" class="tabs-sub">
      <ul>
        <li v-for="(tabData, tabId) in subTabs" :class="{active: subTab==tabId}">
          <a href="" v-on:click.prevent="subTab=tabId, editMode=false">{{ tabData.label }}</a>
        </li>
      </ul>
    </div>
    
    <div v-if="selectedItem" class="content-table-container file-list-container">
      <div v-if="subTab == 'details'">
        <form class="preq-form preq-view">
          <section>
            <ul class="appview-list">
              <li>
                <label>Account ID</label>
                <div class="validator">
                  <span>{{ selectedItem.accountId }}</span>
                </div>
              </li>
              <li>
                <label>Account Type</label>
                <div class="validator">
                  <span>{{ selectedItem.accountType }}</span>
                </div>
              </li>
              <li>
                <label>Account Class</label>
                <div class="validator">
                  <span>{{ selectedItem.class && selectedItem.class.name}}</span>
                </div>
              </li>
              <li>
                <label>Monthly Limit</label>
                <div class="validator">
                  <span></span>
                </div>
              </li>
              <li>
                <label>MCC</label>
                <div class="validator">
                  <span>{{ selectedItem.mcc }}</span>
                </div>
              </li>
            </ul>
          </section>
        </form>
      </div>
      <div v-if="subTab == 'business'">
        <form class="preq-form">
          <section>
            <ul class="column-form appview-list">
              <li>
                <label>Description of products</label>
                <div class="validator">
                  <span v-if="!editMode">{{ selectedItem.businessDescription}}</span>
                  <textarea v-else v-model="selectedItem.businessDescription"></textarea>
                </div>
              </li>
              <li>
                <label>Time in business</label>
                <div class="validator">
                  <span v-if="!editMode">{{ Math.floor(selectedItem.businessLength/12) }} years {{ selectedItem.businessLength%12 }} months</span>
                  <field-businesslength v-else v-model="selectedItem.businessLength" />
                </div>
              </li>
              <li>
                <label>Website(s)</label>
                <div class="validator">
                  <span v-if="!editMode">{{ selectedItem.hasWebsite && !selectedItem.website?"No website":selectedItem.website }}</span>
                  <input v-else type="text" v-model="selectedItem.website" />
                </div>
              </li>
              <li>
                <label>Login details</label>
                <div class="validator">
                  <span v-if="!editMode">{{ selectedItem.websiteUsername }} / {{ selectedItem.websitePassword }}</span>
                  <div v-else style="display: flex">
                    <input type="text" v-model="selectedItem.websiteUsername" />
                    <input type="text" v-model="selectedItem.websitePassword" />
                  </div>
                </div>
              </li>
              <li>
                <label>Beta website(s)</label>
                <div class="validator">
                  <span v-if="!editMode">{{ selectedItem.hasWebsite?"No website":selectedItem.websiteBeta }}</span>
                  <input v-else type="text" v-model="selectedItem.websiteBeta" />
                </div>
              </li>
              <li>
                <label>Descriptor</label>
                <div class="validator">
                  <span v-if="!editMode">{{ selectedItem.descriptor1 }}</span>
                  <input v-else type="text" v-model="selectedItem.descriptor1" />
                </div>
              </li>
              <li>
                <label>DBA/City</label>
                <div class="validator">
                  <span v-if="!editMode">{{ selectedItem.descriptor2 }}</span>
                  <input v-else type="text" v-model="selectedItem.descriptor2" />
                </div>
              </li>
              <li>
                <label>Dynamic descriptors</label>
                <div class="validator">
                  <span v-if="!editMode">{{ selectedItem.dynamicDescriptors==1?"Yes":"No" }}</span>
                  <formel-toggle v-else v-model="selectedItem.dynamicDescriptors" />
                </div>
              </li>
              <li>
                <label>Bankruptcy</label>
                <div class="validator">
                  <span v-if="!editMode">{{ selectedItem.bankruptcy==1?"Yes":"No" }}</span>
                  <formel-toggle v-else v-model="selectedItem.bankruptcy" />
                </div>
              </li>
              <li>
                <label>Bankruptcy date</label>
                <div class="validator">
                  <span v-if="!editMode">{{ selectedItem.bankruptcyDate }}</span>
                  <datepicker v-else v-model="selectedItem.bankruptcyDate" />
                </div>
              </li>
              <li>
                <label>Current/previous Payment Provider</label>
                <div class="validator">
                  <span v-if="!editMode">{{ selectedItem.lastProvider }}</span>
                  <input v-else type="text" v-model="selectedItem.lastProvider" />
                </div>
              </li>
              <li>
                <label>Reason for leaving</label>
                <div class="validator">
                  <span v-if="!editMode">{{ selectedItem.lastReason }}</span>
                  <textarea v-else v-model="selectedItem.lastReason"></textarea>
                </div>
              </li>
              <li>
                <label>Accepted cards</label>
                <div class="validator">
                  <span v-if="!editMode">{{ selectedItem.acceptedCards==1?"Yes":"No" }}</span>
                  <formel-toggle v-else v-model="selectedItem.acceptedCards" />
                </div>
              </li>
              <li>
                <label>Accepted cards</label>
                <div class="validator">
                  <span v-if="!editMode">{{ selectedItem.acceptedCardsMonths || 0 }} months</span>
                  <input v-else type="text" v-model="selectedItem.acceptedCardsMonths" />
                </div>
              </li>
              <li>
                <label>Violation</label>
                <div class="validator">
                  <span v-if="!editMode">{{ selectedItem.violation==1?"Yes":"No" }}</span>
                  <formel-toggle v-else v-model="selectedItem.violation" />
                </div>
              </li>
              <li>
                <label>Violation details</label>
                <div class="validator">
                  <span v-if="!editMode">{{ selectedItem.violationDetails }}</span>
                  <textarea v-else type="text" v-model="selectedItem.violationDetails"></textarea>
                </div>
              </li>
              <li>
                <label>History currency</label>
                <div class="validator">
                  <span v-if="!editMode">{{ currency(selectedItem.historyCurrency) }}</span>
                  <dyn-select v-else v-model="selectedItem.historyCurrency" :options="currencies" />
                </div>
              </li>
            </ul>
            <!--<h2 class="list-heading">History</h2>-->
            <ul class="column-form ext-cform">
              <li class="head">
                <label>History</label>
                <div>
                  <div>Last month</div>
                  <div>2 months ago</div>
                  <div>3 months ago</div>
                  <div>4 months ago</div>
                  <div>5 months ago</div>
                  <div>6 months ago</div>
                </div>
              </li>
            <li>
              <label>Number of transactions</label>
              <div class="field cols">
                <div class="validator" v-for="(ago, i) in selectedItem.history" :key="'ccph1x'+i">
                  <span v-if="!editMode">{{ ago.tsCount }}</span>
                  <input v-else type="text" v-model="ago.tsCount" />
                </div>
              </div>
            </li>
            <li>
              <label>Transaction volume</label>
              <div class="field cols">
                <div class="validator" v-for="(ago, i) in selectedItem.history" :key="'ccph1x'+i">
                  <span v-if="!editMode">{{ ago.tsVolume }}</span>
                  <input v-else type="text" v-model="ago.tsVolume" />
                </div>
              </div>
            </li>
            <li>
              <label>Number of chargebacks</label>
              <div class="field cols">
                <div class="validator" v-for="(ago, i) in selectedItem.history" :key="'ccph1x'+i">
                  <span v-if="!editMode">{{ ago.cbCount }}</span>
                  <input v-else type="text" v-model="ago.cbCount" />
                </div>
              </div>
            </li>
            <li>
              <label>Chargeback volume</label>
              <div class="field cols">
                <div class="validator" v-for="(ago, i) in selectedItem.history" :key="'ccph1x'+i">
                  <span v-if="!editMode">{{ ago.cbVolume }}</span>
                  <input v-else type="text" v-model="ago.cbVolume" />
                </div>
              </div>
            </li>
            <li>
              <label>Number of refunds</label>
              <div class="field cols">
                <div class="validator" v-for="(ago, i) in selectedItem.history" :key="'ccph1x'+i">
                  <span v-if="!editMode">{{ ago.refundCount }}</span>
                  <input v-else type="text" v-model="ago.refundCount" />
                </div>
              </div>
            </li>
            <li>
              <label>Refund volume</label>
              <div class="field cols">
                <div class="validator" v-for="(ago, i) in selectedItem.history" :key="'ccph1x'+i">
                  <span v-if="!editMode">{{ ago.refundVolume }}</span>
                  <input v-else type="text" v-model="ago.refundVolume" />
                </div>
              </div>
            </li>
            </ul>
            <h2 class="list-heading">Finance</h2>
            <ul class="column-form appview-list">
              <li>
                <label>Est monthly sales</label>
                <div class="validator">
                  <span v-if="!editMode">{{ selectedItem.volume }} {{ currency(selectedItem.volumeCurrency) }}</span>
                  <money v-else :amount.sync="selectedItem.volume" :currency.sync="selectedItem.volumeCurrency" />
                </div>
              </li>
              <li>
                <label>Avg transaction</label>
                <div class="validator">
                  <span v-if="!editMode">{{ selectedItem.avgTransaction }} {{ currency(selectedItem.avgTransactionCurrency) }}</span>
                  <money v-else :amount.sync="selectedItem.avgTransaction" :currency.sync="selectedItem.avgTransactionCurrency" />
                </div>
              </li>
              <li>
                <label>Max transaction</label>
                <div class="validator">
                  <span v-if="!editMode">{{ selectedItem.maxTransaction }} {{ currency(selectedItem.maxTransactionCurrency) }}</span>
                  <money v-else :amount.sync="selectedItem.maxTransaction" :currency.sync="selectedItem.maxTransactionCurrency" />
                </div>
              </li>
              <li>
                <label>Transaction currencies</label>
                <div class="validator">
                  <span v-if="!editMode">{{ selectedItem.transactionCurrencies }}</span>
                  <input v-else type="text" v-model="selectedItem.transactionCurrencies" />
                </div>
              </li>
              <li>
                <label>Payment Types:</label>
                <div class="validator">
                  <span v-if="!editMode">{{ (selectedItem.cardTypes || []).join(", ") }}</span>
                  <multiselect v-else v-model="selectedItem.cardTypes" :other.sync="selectedItem.cardTypesOther" hasOther="1" :options="['Visa', 'MasterCard', 'Discover', 'AMEX', 'China Union Pay', 'Apple Pay', 'Google Pay', 'Interac Online', 'ACH/EFT', 'Cryptocurrency']" />
                </div>
              </li>
              <li>
                <label>Origin: Domestic</label>
                <div class="validator">
                  <span v-if="!editMode">{{ selectedItem.originDomestic }} %</span>
                  <input v-else type="text" v-model="selectedItem.originDomestic" />
                </div>
              </li>
              <li>
                <label>Origin: Europe</label>
                <div class="validator">
                  <span v-if="!editMode">{{ selectedItem.originEurope }} %</span>
                  <input v-else type="text" v-model="selectedItem.originEurope" />
                </div>
              </li>
              <li>
                <label>Origin: USA</label>
                <div class="validator">
                  <span v-if="!editMode">{{ selectedItem.originUSA }} %</span>
                  <input v-else type="text" v-model="selectedItem.originUSA" />
                </div>
              </li>
              <li>
                <label>Origin: Asia</label>
                <div class="validator">
                  <span v-if="!editMode">{{ selectedItem.originAsia }} %</span>
                  <input v-else type="text" v-model="selectedItem.originAsia" />
                </div>
              </li>
              <li>
                <label>Origin: Rest of world</label>
                <div class="validator">
                  <span v-if="!editMode">{{ selectedItem.originOther }} %</span>
                  <input v-else type="text" v-model="selectedItem.originOther" />
                </div>
              </li>
              <li>
                <label>Method: E-Commerce</label>
                <div class="validator">
                  <span v-if="!editMode">{{ selectedItem.methodECommerce }} %</span>
                  <input v-else type="text" v-model="selectedItem.methodECommerce" />
                </div>
              </li>
              <li>
                <label>Method: POS</label>
                <div class="validator">
                  <span v-if="!editMode">{{ selectedItem.methodPOS }} %</span>
                  <input v-else type="text" v-model="selectedItem.methodPOS" />
                </div>
              </li>
              <li>
                <label>Method: M-POS</label>
                <div class="validator">
                  <span v-if="!editMode">{{ selectedItem.methodMPOS }} %</span>
                  <input v-else type="text" v-model="selectedItem.methodMPOS" />
                </div>
              </li>
              <li>
                <label>Method: M-Commerce</label>
                <div class="validator">
                  <span v-if="!editMode">{{ selectedItem.methodMCommerce }} %</span>
                  <input v-else type="text" v-model="selectedItem.methodMCommerce" />
                </div>
              </li>
              <li>
                <label>Method: MOTO</label>
                <div class="validator">
                  <span v-if="!editMode">{{ selectedItem.methodMOTO }} %</span>
                  <input v-else type="text" v-model="selectedItem.methodMOTO" />
                </div>
              </li>
              <li>
                <label>Method: In-App</label>
                <div class="validator">
                  <span v-if="!editMode">{{ selectedItem.methodApp }} %</span>
                  <input v-else type="text" v-model="selectedItem.methodApp" />
                </div>
              </li>
              <li>
                <label>Payment time</label>
                <div class="validator">
                  <span v-if="!editMode">{{ selectedItem.paymentMoment.join(", ") }}</span>
                  <multiselect v-else v-model="selectedItem.paymentMoment" :other.sync="selectedItem.paymentMomentOther" hasOther="1" :options="['Upon purchase', 'With download', 'On delivery']" />
                </div>
              </li>
              <li>
                <label>Payment frequency</label>
                <div class="validator">
                  <span v-if="!editMode">{{ selectedItem.frequency.join(", ") }}</span>
                  <multiselect v-else v-model="selectedItem.frequency" :hasOther="false" :options="['One-time payment', 'Recurring payment (subscription)']" />
                </div>
              </li>
              <li>
                <label>Delivery: Immediately</label>
                <div class="validator">
                  <span v-if="!editMode">{{ selectedItem.deliveryTime1 }} %</span>
                  <input v-else type="text" v-model="selectedItem.deliveryTime1" />
                </div>
              </li>
              <li>
                <label>Delivery: &lt;= 4 weeks</label>
                <div class="validator">
                  <span v-if="!editMode">{{ selectedItem.deliveryTime2 }} %</span>
                  <input v-else type="text" v-model="selectedItem.deliveryTime2" />
                </div>
              </li>
              <li>
                <label>Delivery: 5-14 weeks</label>
                <div class="validator">
                  <span v-if="!editMode">{{ selectedItem.deliveryTime3 }} %</span>
                  <input v-else type="text" v-model="selectedItem.deliveryTime3" />
                </div>
              </li>
              <li>
                <label>Delivery: &gt; 14 weeks</label>
                <div class="validator">
                  <span v-if="!editMode">{{ selectedItem.deliveryTime4 }} %</span>
                  <input v-else type="text" v-model="selectedItem.deliveryTime4" />
                </div>
              </li>
              <li>
                <label>Affiliate program</label>
                <div class="validator">
                  <span v-if="!editMode"> {{ selectedItem.affiliate==1?"Yes":"No" }}</span>
                  <formel-toggle v-else v-model="selectedItem.affiliate" />
                </div>
              </li>
              <li>
                <label>Affiliate details</label>
                <div class="validator">
                  <span v-if="!editMode"> {{ selectedItem.affiliateDetails }}</span>
                  <input v-else type="text" v-model="selectedItem.affiliateDetails" />
                </div>
              </li>
              <li>
                <label>Customer service email</label>
                <div class="validator">
                  <span v-if="!editMode">{{ selectedItem.emailService }}</span>
                  <input v-else type="text" v-model="selectedItem.emailService" />
                </div>
              </li>
              <li>
                <label>Customer service phone</label>
                <div class="validator">
                  <span v-if="!editMode">{{ selectedItem.phoneService }}</span>
                  <input v-else type="text" v-model="selectedItem.phoneService" />
                </div>
              </li>
              <li>
                <label>Chargeback notification email</label>
                <div class="validator">
                  <span v-if="!editMode">{{ selectedItem.emailChargeback }}</span>
                  <input v-else type="text" v-model="selectedItem.emailChargeback" />
                </div>
              </li>
            </ul>
            <h2 class="list-heading">Security measures</h2>
            <ul class="column-form appview-list">
              <li>
                <label>Security measures</label>
                <div class="validator">
                  <span v-if="!editMode">{{ selectedItem.securityMeasures }}</span>
                  <textarea v-else v-model="selectedItem.securityMeasures"></textarea>
                </div>
              </li>
              <li>
                <label>3D Secure</label>
                <div class="validator">
                  <span v-if="!editMode">{{ selectedItem.use3dSecure==1?"Yes":"No" }} {{ selectedItem.use3dSecureMPI?"MPI: "+selectedItem.use3dSecureMPI:"" }}</span>
                  <div v-else>
                    <formel-toggle v-model="selectedItem.use3dSecure" />
                    <input type="text" v-model="selectedItem.use3dSecureMPI" />
                  </div>
                </div>
              </li>
              <li>
                <label>CVC</label>
                <div class="validator">
                  <span v-if="!editMode"> {{ selectedItem.useCVC==1?"Yes":"No" }}</span>
                  <formel-toggle v-else v-model="selectedItem.useCVC" />
                </div>
              </li>
              <li>
                <label>AVS</label>
                <div class="validator">
                  <span v-if="!editMode"> {{ selectedItem.useAVS==1?"Yes":"No" }}</span>
                  <formel-toggle v-else v-model="selectedItem.useAVS" />
                </div>
              </li>
            </ul>
            
          </section>
          <div class="form-actions" v-if="editMode">
            <button class="btn btn-large" v-on:click.prevent="saveItem">Save</button>
          </div>
        </form>
      </div>
      <div v-if="subTab == 'scheduleA'">
        <form class="">
          <section>
            <pricing-details :currentTemplate="intake.formData" :solution="selectedItem.solution" />
          </section>
          <div class="form-actions" v-if="editMode">
            <button class="btn btn-large" v-on:click.prevent="saveItem">Save</button>
          </div>
        </form>
      </div>
    </div>

    <portal to="actions">
        <select v-model="selectedAccount">
          <option></option>
          <option v-for="account in accounts" :value="account.id">
            {{ account.accountId }}
          </option>
        </select>
      </portal>
  </div>
</template>

<script>
import Select from '../components/ui/forms/Select.vue';
export default {
  components: { Select },
  props: ["item"],
  data: ()=>({
    emails: [],
    accounts: [],
    subTab: 'details',
    subTabs: {
      details: {label: 'Account Details'},
      business: {label: 'Business'},
      scheduleA: {label: 'Schedule A'},
    },
    editMode: false,
    currencies: [
      {value: "AUD", label: "AUD"},
      {value: "BRL", label: "BRL"},
      {value: "CAD", label: "CAD"},
      {value: "CHF", label: "CHF"},
      {value: "EUR", label: "EUR"},
      {value: "GBP", label: "GBP"},
      {value: "HKD", label: "HKD"},
      {value: "JPY", label: "JPY"},
      {value: "NZD", label: "NZD"},
      {value: "USD", label: "USD"},
    ],
    intake: null,
    selectedAccount: null,
  }),

  watch: {
    "selectedAccount": {
      handler() {
        if (this.selectedAccount)
          this.loadIntake();
      },
      immediate: true,
    }
  },

  computed: {
    selectedItem() {
      if(this.selectedAccount) {
        return this.accounts.find(item =>  item.id == this.selectedAccount)
      }
      return null
    }
  },
  methods: {
    currency(code) {
      for(var currency of this.currencies)
        if (code==currency.value)
          return currency.label;
      return "";
    },

    async loadIntake() {
      var intake=(await api.list("Intake", {appId: this.item.id}, ["id:DESC"])).items[0];
      this.intake=intake;
    },
  },
  async mounted() {
    this.accounts=(await api.list("Application", {businessProfileId: this.item.businessProfileId}, ["id:DESC"])).items || [];
  },
  mixins: [vueMixins.dateFormatter],
}
</script>
<style scoped>
  .tabs-sub ul{
    display: flex;
    flex-wrap: wrap;
    padding: 15px 25px 10px;
    font-size: 14px;
  }

  .tabs-sub > ul > li.active {
    border-color: #76acff;
  }
  .tabs-sub > ul > li {
    box-sizing: border-box;
    border: 1px solid #fff;
    border-radius: 4px;
    margin-right: 12px;
  }

  .tabs-sub > ul > li a {
    padding: 10px 12px;
    display: block;
    transition: 0.3s;
  }
</style>