<template>
<div class="boarding-container">
  <aside class="boarding-steps">
    <ul>
      <li v-for="(stepLabel, si) in steps" :class="{current: step==si, available: si<=doneSteps}">
        <a href="" v-on:click.prevent="goToStep(si)">{{ stepLabel }}</a>
      </li>
    </ul>
  </aside>
  <section class="boarding-main">
    <div v-if="item.status=='A07'">
      Activation
    </div>
    <template v-else>
      <partner-boarding-profile v-if="step==0" v-on:next="goToNext" :item="item" />
      <partner-boarding-documents v-if="step==1" v-on:next="goToNext" v-on:back="goToStep(0)" :item="item"  v-on:reloadnotes="$emit('reloadnotes')" />
      <partner-boarding-compliance v-if="step==2" v-on:next="goToNext" v-on:back="goToStep(1)" :item="item" />
      <partner-boarding-final v-if="step==3" v-on:next="goToNext" v-on:back="goToStep(2)" :item="item" :approvalList="approvalList" />
      <partner-boarding-boarding v-if="step==4" :item="item" v-on:next="goToNext" v-on:back="goToStep(3)" />
      <div v-if="step==5">
        Boarding completed
      </div>
    </template>
  </section>
</div>
</template>

<script>
export default {
  props: ["item", "notes", "precheckList", "approvalList"],
  data: ()=>({
    steps: [
      "Profile Settings",
      "Documents",
      "Compliance",
      "Approval",
      "Onboarding"
    ],
    step: 0,
    done: [],
  }),
  computed: {
    doneSteps() {
      return this.done.length;
    },
    boardingProgress() {
      return this.item.agentDb.boardingprogress || {step: 0};
    },
  },
  methods: {
    goToStep(index) {
      if (this.doneSteps>=index) {
        this.step=index;
      }
    },
    async goToNext() {
      this.step++;
      if (this.step>this.done.length) {
        this.done.push("");
        this.item.agentDb.boardingprogress=this.item.agentDb.boardingprogress || {step: 0}
        this.item.agentDb.boardingprogress.step=this.step;
        startLoading();
        await api.update("Partner", this.item.agentDb.id, {boardingprogress: this.item.agentDb.boardingprogress});
        stopLoading();
      }
    },
  },
  mounted() {
    // if (location.hash=="#boarding/documents")
    //   this.goToNext();
    while(this.boardingProgress.step>this.step) {
      this.step++;
      if (this.step>this.done.length)
        this.done.push("");
    }
    if (this.item.agentDb.status=='A08' || this.item.agentDb.status=='A07') {
      if (this.step==4)
        this.step=5;
    }
  },
}
</script>
