<template>
<div>
  <amadel-menu :page="currentPage" />

  <div class="ncontent">
    <div class="header">
      <div class="content-table-container usermgmt">
        <table class="content-table usermgmt">
          <thead>
            <tr>
              <th>Souce Name</th>
              <th>Source ID</th>
              <th>Source Password</th>
              <th>Contact Email</th>
              <th>Contact Phone</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in data" :key="index">
              <td>{{ item.name }}</td>
              <td>{{ item.sourceId }}</td>
              <td>{{ item.password }}</td>
              <td>{{ item.email }}</td>
              <td>{{ item.phone }}</td>
              <td>
                <span class="text-danger" style="font-size: 17px; cursor: pointer" @click="remove(item)">x</span>
              </td>
            </tr>
            <tr v-if="showAdd">
              <td>
                <validator :value="form.name" reqs="required">
                  <input v-model="form.name" type="text" placeholder="Souce Name">
                </validator>
              </td>
              <td>
                <validator :value="form.sourceId" reqs="required">
                  <input v-model="form.sourceId" type="text" placeholder="Source ID">
                </validator>
              </td>
              <td>
                <validator :value="form.password" reqs="required">
                  <input v-model="form.password" type="text" placeholder="Source Password">
                </validator>
              </td>
              <td>
                <validator :value="form.email" reqs="required email">
                  <input v-model="form.email" type="text" placeholder="Contact Email">
                </validator>
              </td>
              <td>
                <validator :value="form.phone" reqs="required">
                  <input v-model="form.phone" type="text" placeholder="Contact Phone" @keypress="allowLetterNumber($event, { numbers: true, letters: false, allow: [43] })">
                </validator>
              </td>
              <td><button class="btn btn-xs" @click="save()">Save</button></td>
            </tr>
          </tbody>
        </table>
        <button class="table-add-button" @click="showAdd = !showAdd">
          {{ !showAdd ? '+' : '-' }}
        </button>
      </div>
    </div>  
  </div>  
</div>
</template>

<script>
export default {
  mixins: [ vueMixins.helpers, formValidation.mixin ],

  data () {
    return {
      showAdd: false,

      currentPage: {
        text: 'Data Sources',
        to: '/agents/data-sources'
      },

      form: {
        name: null,
        sourceId: null,
        password: null,
        email: null,
        phone: null
      },

      data: []
    }
  },

  created () {
    this.getSources()
  },

  methods: {
    async getSources () {
      startLoading()
      this.data = (await api.list("AmadelSources")).items
      if (!this.data.length) this.showAdd = true
      stopLoading()
    },

    async remove (source) {
      if(confirm(`Are you sure you want to delete source ${source.name}`)) {
        startLoading()
        const response = await api.list('AmadelDataTransferFile', { sourceId: source.id })
        if (!response.items.length) {
          await api.delete('AmadelSources', source.id)
          stopLoading('Source deleted successfully.')
          this.getSources()
        } else {
          stopLoading('Not Deleted. There are leads associated with this source.', 'error')
        }
      }
    },

    async save () {
      if (!this.validate()) return stopLoading('Please fill all required fields', 'error')
      startLoading()
      try {
        const payload = {
          created: 'CURRENT_TIMESTAMP',
          ...this.form
        }
        const response = await api.create("AmadelSources", payload, true)
        this.data.push(response.item)
        this.clear()
        stopLoading()
      } catch (e) {
        stopLoading(e, 'error')
      }
    },

    clear () {
      this.showAdd = false
      this.form = {
        source: null,
        sourceId: null,
        password: null,
        email: null,
        phone: null
      }
      this.validateReset()
    }
  }
}
</script>
