<template>
    <ul class="message-history">
      <li v-for="(message, mi) in messages">
        <p class="head">
          Message posted by <a>{{ message.author }}</a> on <time>{{ formatDate(message.date, "YYYY/MM/DD") }}</time>
        </p>
        <p class="message">
          {{ message.message }}
        </p>
        <div class="dashboard-flag">
          <label>DASHBOARD ONLY MESSAGE:</label>
          <span :class="{danger: message.dashboard==0, success: message.dashboard==1}">{{ message.dashboard==1?"YES":"NO" }}</span>
        </div>
        <button class="message-delete btn btn-danger" v-on:click.prevent="deleteMessage(mi)">Delete message</button>
      </li>
    </ul>
</template>

<script>
export default {
  data() { return {
    messages: [],
  }; },
  methods: {
    reload() {
      var that=this;
      api.list("Message", {}, ["`date`:DESC"]).then(function(data) {
        that.messages=data.items;
      });
    },
    deleteMessage(index) {
      if (confirm("Are you sure?")) {
        if (this.messages[index].id)
          api.delete("Message", this.messages[index].id);
        this.messages.splice(index, 1);
      }
    },
  },
  mounted() {
    window.messageList=this;
    this.reload();
  },
  mixins: [
    window.vueMixins.dateFormatter
  ],
}
</script>
