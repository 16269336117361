<template>
<div :class="{'form-popup': !inline}" v-show="inline || shown"><div>
  <div class="ncontent appf appnat">
    <a v-if="!inline" class="close" href="" v-on:click.prevent="dismiss">&times;</a>
    <h3 v-if="!inline">Set Merchant Settings</h3>

<form class="form-step">
  <section>
    <h2>Merchant Information</h2>
    <ul class="column-form column-form-compact">
      <li>
        <label>Solution</label>
        <div class="field">
        <validator :value="appl.solution" reqs="required">
          <dyn-select :options="solutionsToShow" v-model="appl.solution"></dyn-select>
        </validator>
        </div>
      </li>
      <li>
        <label>Account Class</label>
        <div class="field">
        <validator :value="appl.classId" reqs="required">
          <dyn-select v-model="appl.classId" :options="classesToShow" />
        </validator>
        </div>
      </li>
      <li>
        <label>Account Type</label>
        <div class="field">
        <validator :value="appl.accountType" reqs="required">
          <dyn-select v-model="appl.accountType" :options="accountTypes" />
        </validator>
        </div>
      </li>
      <li>
        <label>Account Manager</label>
        <div class="field">
        <validator :value="appl.managerId" reqs="">
          <dyn-select v-model="appl.managerId" :options="managersToShow" />
        </validator>
        </div>
      </li>
      <li>
        <label>Partner</label>
        <div class="field">
        <validator :value="appl.sourceId" reqs="required">
          <dyn-select :options="agentsToShow" v-model="appl.sourceId"></dyn-select>
        </validator>
        </div>
      </li>
      <li>
        <label>MCC</label>
        <div class="field">
        <validator :value="appl.mcc" reqs="required">
          <mcc-edit v-model="appl.mcc" class="in-form" />
        </validator>
        </div>
      </li>
      <li>
        <label>Merchant Type</label>
        <div class="field">
        <validator :value="appl.merchantType" reqs="required">
          <dyn-select v-model="appl.merchantType" :options="merchantTypes" />
        </validator>
        </div>
      </li>
      <li>
        <label>Industry</label>
        <div class="field">
        <validator :value="appl.industry" reqs="required">
          <dyn-select :options="industries" v-model="appl.industry"></dyn-select>
        </validator>
        </div>
      </li>
      <li>
        <label>Language</label>
        <div class="field">
        <validator :value="appl.language" reqs="required">
          <dyn-select :options="languages" v-model="appl.language"></dyn-select>
        </validator>
        </div>
      </li>
    </ul>
  </section>
  <div class="buttons">
    <a class="btn btn-large btn-success" v-on:click.prevent="save">Set</a>
  </div>
</form>
  </div>
  
  
</div></div>
</template>

<script>
export default {
  props: ["inline", "item"],
  data() { return {
    portals: [{value: appData.settings.partnerLoginURL, label: appData.settings.partnerLoginURL}],
    shown: false,
    appl: {},
    industries: [
      {value: "Hospitality", label: "Hospitality"},
      {value: "Retail", label: "Retail"},
      {value: "Spa/Salon/Gym", label: "Spa/Salon/Gym"},
      {value: "Gas & Oil", label: "Gas & Oil"},
      {value: "E-Commerce", label: "E-Commerce"},
      {value: "Professional Services", label: "Professional Services"},
      {value: "Convenience & Supermarket", label: "Convenience & Supermarket"},
      {value: "Tourism", label: "Tourism"},
      {value: "Community Groups & Orgs", label: "Community Groups & Orgs"},
      {value: "Business to Business", label: "Business to Business"},
      {value: "Taxi & Delivery", label: "Taxi & Delivery"},
      {value: "Utilities", label: "Utilities"},
      {value: "Other", label: "Other"},
    ],
    managers: [],
    internalNotes: {},
    salesBrands: [],
    toggleYesNo: [
      {label: "Yes", value: 1},
      {label: "No", value: 0},
    ],
    agents: appData.agents || [],
    classes: [],
    sources: [],
    users: appData.users || [],
    solutions: [],
    managers: [],
    accountTypes: [
      {value: "master", label: "Master Merchant"},
      {value: "merchant", label: "Merchant"},
      {value: "sub", label: "Sub-Account"},
    ],
    merchantTypes: [
      {value: "POS", label: "Card Present - POS"},
      {value: "E-Commerce", label: "Card not Present - E-Commerce"},
//       {value: "Hybrid", label: "Hybrid"},
    ],
    languages: [
      {value: "English", label: "English"},
    ],
  }},
  async mounted() {
    window.appProfilePopup=this;
    if (this.item)
      this.show(this.item);

    this.classes = (await api.list("AccountClass")).items || [];
    this.managers = (await api.list("User", {groups: 'manager-account', deleted :['<>', 'Yes']}, ['id:ASC'])).items || [];
    var appsolutions = (await api.list("Solution", {solutionType: 'Merchants'}, ['id:ASC'])).items || [];
    this.solutions=appsolutions.map(function(a) {
      return {value: a.id, label: a.nameSolution};
    });
    this.sources = (await api.list("User", {groups: 'manager-relationship', deleted :['<>', 'Yes']}, ['id:ASC'])).items || [];
  },
  computed: {
    sourcesToShow() {
      return this.sources.map(function(a) {
        return {
          value: a.id,
          label: a.fullName || a.username,
        };
      });
    },
    usersToShow() {
      return this.users.map(function(a) {
        return {
          value: a.id,
          label: a.username,
        };
      });
    },
    solutionsToShow() {
      return this.solutions;
    },
    classesToShow() {
      return this.classes.map(function(a) {
        return {
          value: a.id,
          label: a.name+" "+a.description,
        };
      });
    },
    managersToShow() {
      var items=this.managers.map(function(a) {
        return {
          value: a.id,
          label: a.username,
        };
      });
      items.unshift({
        value: null,
        label: "(no manager)",
      });
      return items;
    },
    agentsToShow() {
      return this.agents.map(function(a) {
        var name=a.fullName || "";
        if (!name && a.contacts && a.contacts[0]) {
          name=a.contacts[0].firstName+" "+a.contacts[0].lastName;
        }
        var cn=a.companyName || "";
        if (!cn) {
          
        }
        return {
          value: a.id,
          label: (a.agentId || "")+" - "+name+" - "+cn,
        };
      });
    },
  },
  watch: {
//     "item.source": {
//       handler() {
//         this.$set(this.appl, "sourceId", this.item.source && this.item.source.id);
//       },
//       immediate: true,
//     },
//     "item.class": {
//       handler() {
//         this.$set(this.appl, "classId", this.item.class && this.item.class.id);
//       },
//       immediate: true,
//     },
//     "item.manager": {
//       handler() {
//         this.$set(this.appl, "managerId", this.item.manager && this.item.manager.id);
//       },
//       immediate: true,
//     },
  },
  methods: {
    dismiss() {
      if (!this.inline) {
        this.shown=false;
        this.appl={};
      } else {
        this.$emit("next");
      }
    },
    show(item) {
      if (item) {
        this.shown=true;
        this.appl=item;
      }
    },
    async save() {
      if (!this.validate())
        return stopLoading("Please fill all required fields", "error");      
      
      startLoading();
      var item=(await api.update("Application", this.appl.id, {
        solution: this.appl.solution,
        class: this.appl.classId,
        accountType: this.appl.accountType,
        manager: this.appl.managerId,
        source: this.appl.sourceId,
        mcc: this.appl.mcc,
        industry: this.appl.industry,
        language: this.appl.language,
        merchantType: this.appl.merchantType,
      }, true)).item;
      
      stopLoading("Merchant updated");
      
      this.appl.class=item.class;
      this.appl.manager=item.manager;
      this.appl.source=item.source;
      
      this.dismiss();
    },
  },
  mixins: [formValidation.mixin, vueMixins.dateFormatter]
}
</script>
