<template>
  <dyn-select :options="validProvinces" :value="value" v-on:input="$emit('input', $event)" />
</template>

<script>
export default {
  props: ["value", "country"],
  computed: {
    validProvinces() {
      if (this.country=="CA")
        return [
          {label: "AB – Alberta", value: "AB – Alberta"},
          {label: "BC – British Columbia", value: "BC – British Columbia"},
          {label: "MB – Manitoba", value: "MB – Manitoba"},
          {label: "NB – New Brunswick", value: "NB – New Brunswick"},
          {label: "NL – Newfoundland", value: "NL – Newfoundland"},
          {label: "NT – Northwest Territories", value: "NT – Northwest Territories"},
          {label: "NS – Nova Scotia", value: "NS – Nova Scotia"},
          {label: "NU – Nunavut", value: "NU – Nunavut"},
          {label: "ON – Ontario", value: "ON – Ontario"},
          {label: "PE – Prince Edward Island", value: "PE – Prince Edward Island"},
          {label: "QC – Quebec", value: "QC – Quebec"},
          {label: "SK – Saskatchewan", value: "SK – Saskatchewan"},
          {label: "YT – Yukon Territories", value: "YT – Yukon Territories"},
        ];
      if (this.country=="US")
        return [
          {label: "AL – Alabama", value: "AL – Alabama"},
          {label: "AK – Alaska", value: "AK – Alaska"},
          {label: "AS – American Samoa", value: "AS – American Samoa"},
          {label: "AZ – Arizona", value: "AZ – Arizona"},
          {label: "AR – Arkansas", value: "AR – Arkansas"},
          {label: "CA – California", value: "CA – California"},
          {label: "CO – Colorado", value: "CO – Colorado"},
          {label: "CT – Connecticut", value: "CT – Connecticut"},
          {label: "DE – Delaware", value: "DE – Delaware"},
          {label: "DC – District of Columbia", value: "DC – District of Columbia"},
          {label: "FM – Federated States of Micronesia", value: "FM – Federated States of Micronesia"},
          {label: "FL – Florida", value: "FL – Florida"},
          {label: "GA – Georgia", value: "GA – Georgia"},
          {label: "GU – Guam", value: "GU – Guam"},
          {label: "HI – Hawaii", value: "HI – Hawaii"},
          {label: "ID – Idaho", value: "ID – Idaho"},
          {label: "IL – Illinois", value: "IL – Illinois"},
          {label: "IN – Indiana", value: "IN – Indiana"},
          {label: "IA – Iowa", value: "IA – Iowa"},
          {label: "KS – Kansas", value: "KS – Kansas"},
          {label: "KY – Kentucky", value: "KY – Kentucky"},
          {label: "LA – Louisiana", value: "LA – Louisiana"},
          {label: "ME – Maine", value: "ME – Maine"},
          {label: "MH – Marshall Islands", value: "MH – Marshall Islands"},
          {label: "MD – Maryland", value: "MD – Maryland"},
          {label: "MA – Massachusetts", value: "MA – Massachusetts"},
          {label: "MI – Michigan", value: "MI – Michigan"},
          {label: "MN – Minnesota", value: "MN – Minnesota"},
          {label: "MS – Mississippi", value: "MS – Mississippi"},
          {label: "MO – Missouri", value: "MO – Missouri"},
          {label: "MT – Montana", value: "MT – Montana"},
          {label: "NE – Nebraska", value: "NE – Nebraska"},
          {label: "NV – Nevada", value: "NV – Nevada"},
          {label: "NH – New Hampshire", value: "NH – New Hampshire"},
          {label: "NJ – New Jersey", value: "NJ – New Jersey"},
          {label: "NM – New Mexico", value: "NM – New Mexico"},
          {label: "NY – New York", value: "NY – New York"},
          {label: "NC – North Carolina", value: "NC – North Carolina"},
          {label: "ND – North Dakota", value: "ND – North Dakota"},
          {label: "MP – Northern Mariana Islands", value: "MP – Northern Mariana Islands"},
          {label: "OH – Ohio", value: "OH – Ohio"},
          {label: "OK – Oklahoma", value: "OK – Oklahoma"},
          {label: "OR – Oregon", value: "OR – Oregon"},
          {label: "PW – Palau", value: "PW – Palau"},
          {label: "PA – Pennsylvania", value: "PA – Pennsylvania"},
          {label: "PR – Puerto Rico ", value: "PR – Puerto Rico"},
          {label: "RI – Rhode Island", value: "RI – Rhode Island"},
          {label: "SC – South Carolina", value: "SC – South Carolina"},
          {label: "SD – South Dakota", value: "SD – South Dakota"},
          {label: "TN – Tennessee", value: "TN – Tennessee"},
          {label: "TX – Texas", value: "TX – Texas"},
          {label: "UT – Utah", value: "UT – Utah"},
          {label: "VT – Vermont", value: "VT – Vermont"},
          {label: "VI – Virgin Islands", value: "VI – Virgin Islands"},
          {label: "VA – Virginia", value: "VA – Virginia"},
          {label: "WA – Washington", value: "WA – Washington"},
          {label: "WV – West Virginia", value: "WV – West Virginia"},
          {label: "WI – Wisconsin", value: "WI – Wisconsin"},
          {label: "WY – Wyoming", value: "WY – Wyoming"},
        ];
      return [];
    },
  },
}
</script>
