<template>
<div class="preform-outer">
<div class="preformbg agent"></div>
<div class="appf preform ncontent pfx">
<div class="wcontent">
  <div class="cb-logo" :style="{'background-image': 'url(&quot;/files/company/'+companydata.agentHeadLogo+'&quot;)' }">CardBiller</div>
  <h1>Partner Sign-Up Form</h1>
  <div class="form-info">
    <p>{{ companydata.agentHeadText }}</p>
    <p><strong>{{ companydata.agentHeadEmail }}</strong></p>
    <p><strong>{{ companydata.agentHeadPhone }}</strong></p>
  </div>
  <p class="form-intro">
    {{ companydata.agentHeadDesc }}
  </p>
  <div class="temp-link">
    <label>Guest access to this application:</label>
    <span v-if="!link">
      <button v-on:click.prevent="generateLink">Generate Link</button>
    </span>
    <a v-else :href="link.url" target="_blank" v-on:click.prevent="copyLink">{{ link.url }}</a>
  </div>  
  <ul class="pqf-progress four">
    <li v-for="(stepInfo) in steps" :class="{past: step>stepInfo.index, current: step==stepInfo.index}">
      <span>{{ stepInfo.index }}. {{ stepInfo.title }}</span>
      <span class="stat ip">In Progress</span>
      <span class="stat co">Complete</span>
    </li>
  </ul>
  
  <form class="preq-form type2" v-if="step<=4">
    <section class="pqcat-select" v-if="step==0">
      <h3>To get started, tell us what type of sign-up:</h3>
      <validator :value="item.groups" reqs="required">
      <ul class="pqcat-list">
        <li v-for="ind in industries" :class="'plain-item '+(item.groups==ind.value?'selected':'')"><a href="" v-on:click.prevent="item.groups=ind.value">{{ ind.label }}</a></li>
      </ul>
      </validator>
    </section>
    
    
    <section v-if="step==1">
      <h2>
        <em>Contact information</em>
      </h2>
      <ul v-for="(ubo, ui) in item.contacts" class="preform-sp3">
        <li>
          <label><strong>Contact {{ ui+1 }}</strong> First Name</label>
          <validator :value="ubo.firstName" reqs="required city">
            <input type="text" v-model="ubo.firstName" />
          </validator>
        </li>
        <li>
          <label><strong>Contact {{ ui+1 }}</strong> Last Name</label>
          <validator :value="ubo.lastName" reqs="required city">
            <input type="text" v-model="ubo.lastName" />
          </validator>
        </li>
        <li>
          <label><strong>Contact {{ ui+1 }}</strong> E-mail</label>
          <validator :value="ubo.email" reqs="required email">
            <input type="text" v-model="ubo.email" />
          </validator>
        </li>
        <li>
          <label><strong>Contact {{ ui+1 }}</strong> Phone</label>
          <validator :value="ubo.phone" reqs="required phone">
            <input type="text" v-model="ubo.phone" />
          </validator>
        </li>
        <li class="remove" v-if="item.contacts.length>1 && ui">
          <a class="remove" href="" v-on:click.prevent="removeUbo(ui)">&times;</a>
        </li>
      </ul>
      <div class="new-ubo" v-if="item.contacts.length<5">
        <a href="" v-on:click.prevent="newUbo">Add Additional Contact</a>
      </div>
    </section>
    
    
    <section v-if="step==2 && item.groups!='agent-accex' && item.groups!='agent-sales'">
      <h2>
        <em>Company Information</em>
      </h2>
      <!--<label class="checkbox maincheck" :class="{checked: item.companyNew}">
        <input type="checkbox" v-model="item.companyNew" /><span></span>
        This is a <strong>New Business</strong>
      </label>-->
      <ul class="preform-sp3">
        <li>
          <label>Legal Business Name</label>
          <validator :value="item.agentDb.name" reqs="required legalName">
            <input type="text" v-model="item.agentDb.name" />
          </validator>
        </li>
        <li>
          <label>Trade Name/DBA</label>
          <validator :value="item.agentDb.nameTrade" reqs="legalName">
            <input type="text" v-model="item.agentDb.nameTrade" />
          </validator>
        </li>
        <li>
          <label>Type</label>
          <validator :value="item.agentDb.nameTrade" reqs="legalName">
            <combo-select-ext :options="tobOptions" type="text" v-model="item.agentDb.companyType" />
          </validator>
        </li>
      </ul>
      <ul class="preform-sp3">
        <li style="width: 31.3333333%">
          <label>Country</label>
          <validator :value="item.agentDb.country" reqs="required postcode">
            <div class="combo-select">
            <dyn-select :options="validCountries" v-model="item.agentDb.country" />
            </div>
<!--             <input type="text" v-model="item.agentDb.country" /> -->
          </validator>
        </li>
        <li style="width: 31.3333333%">
          <label>Address</label>
          <validator :value="item.agentDb.address" reqs="required address">
            <input type="text" v-model="item.agentDb.address" />
          </validator>
        </li>
        <li style="width: 13.6666666%">
          <label>City</label>
          <validator :value="item.agentDb.city" reqs="required city">
            <input type="text" v-model="item.agentDb.city" />
          </validator>
        </li>
        <li style="width: 13.6666666%">
          <label>Postal/ZIP Code</label>
          <validator :value="item.agentDb.zip" reqs="required postcode">
            <input type="text" v-model="item.agentDb.zip" />
          </validator>
        </li>
      </ul>
      <ul class="preform-sp3">
        <li>
          <label>State/Province</label>
          <validator :value="item.agentDb.state" reqs="required">
            <div class="combo-select" v-if="validProvinces[item.agentDb.country]">
            <dyn-select :options="validProvinces[item.agentDb.country]" v-model="item.agentDb.state" />
            </div>
            <input v-else type="text" v-model="item.agentDb.state" />
          </validator>
        </li>
        <li>
          <label>Phone Number</label>
          <validator :value="item.agentDb.phone" reqs="required phone">
            <input type="text" v-model="item.agentDb.phone" />
          </validator>
        </li>
        <li>
          <label>Company Email</label>
          <validator :value="item.agentDb.email" reqs="required email">
            <input type="text" v-model="item.agentDb.email" />
          </validator>
        </li>
      </ul>
      <ul class="preform-sp3">
        <li style="width: 100%">
          <label>Business Website</label>
          <validator :value="item.agentDb.website" reqs="url" style="width: 33.333%">
            <input type="text" v-model="item.agentDb.website" />
          </validator>
        </li>
      </ul>
    </section>
    <section v-else-if="step==2" class="shaded">
      <h2>
        <em>Company Information</em>
      </h2>
      <ul class="preform-sp3">
        <li>
          <label>Legal Business Name</label>
          <validator :value="item.agentDb.name" reqs="">
            <input disabled type="text" v-model="item.agentDb.name" />
          </validator>
        </li>
        <li>
          <label>Trade Name/DBA</label>
          <validator :value="item.agentDb.nameTrade" reqs="">
            <input disabled type="text" v-model="item.agentDb.nameTrade" />
          </validator>
        </li>
        <li>
          <label>Type</label>
          <validator :value="item.agentDb.nameTrade" reqs="legalName">
            <combo-select-ext :options="tobOptions" type="text" v-model="item.agentDb.companyType" />
          </validator>
        </li>
      </ul>
      <ul class="preform-sp3">
        <li style="width: 31.3333333%">
          <label>Address</label>
          <validator :value="item.agentDb.address" reqs="">
            <input disabled type="text" v-model="item.agentDb.address" />
          </validator>
        </li>
        <li style="width: 13.6666666%">
          <label>City</label>
          <validator :value="item.agentDb.city" reqs="">
            <input disabled type="text" v-model="item.agentDb.city" />
          </validator>
        </li>
        <li style="width: 13.6666666%">
          <label>Postal/ZIP Code</label>
          <validator :value="item.agentDb.zip" reqs="">
            <input disabled type="text" v-model="item.agentDb.zip" />
          </validator>
        </li>
        <li style="width: 31.3333333%">
          <label>State/Province</label>
          <validator :value="item.agentDb.state" reqs="">
            <input disabled type="text" v-model="item.agentDb.state" />
          </validator>
        </li>
      </ul>
      <ul class="preform-sp3">
        <li>
          <label>Country</label>
          <validator :value="item.agentDb.country" reqs="">
            <input disabled type="text" v-model="item.agentDb.country" />
          </validator>
        </li>
        <li>
          <label>Phone Number</label>
          <validator :value="item.agentDb.phone" reqs="">
            <input disabled type="text" v-model="item.agentDb.phone" />
          </validator>
        </li>
        <li>
          <label>Company Email</label>
          <validator :value="item.agentDb.email" reqs="">
            <input disabled type="text" v-model="item.agentDb.email" />
          </validator>
        </li>
      </ul>
      <ul class="preform-sp3">
        <li style="width: 100%">
          <label>Business Website</label>
          <validator :value="item.agentDb.website" reqs="url" style="width: 33.333%">
            <input type="text" v-model="item.agentDb.website" />
          </validator>
        </li>
      </ul>
      <div class="shade"></div>
      <div class="shade-message">
        <p>Company information is only completed for ISO, ISA, Authorized Agent, and Referral Partner Sign-Ups</p>
      </div>
    </section>
    
    <section v-if="step==2">
      <h2>
        <em>Business Information</em>
      </h2>
      <label class="forta"><b>Tell us more about your business</b></label>
      <validator :value="item.agentDb.description" reqs="char250">
        <div class="single-field">
          <textarea class="more-details" v-model="item.agentDb.description" style="resize: none; heigth: 300px"></textarea>
        </div>
      </validator>
      <br/>
      <ul class="preform-sp3">
        <li style="width: 100%">
          <label>Services Requested <span>(Check all that apply)</span></label>
        </li>
      </ul>
      <ul class="preform-sp3">
        <li style="width: 50%">
          <div class="payment-checkbox">
            <input v-model="item.agentDb.payments" id="payment_checkbox" type="checkbox"/>
            <label for="payment_checkbox">&nbsp;&nbsp;PAYMENTS</label>
          </div>
          <div class="field">
            <validator value="item.agentDb.merchantTypes" reqs="required" class="payment-multi" style="width: 23%;">
              <multiselect :readonly="!item.agentDb.payments"  :class="payment-multi" v-model="item.agentDb.merchantTypes" :hasOther="0" :options="['POS', 'E-Commerce', 'Unattended', 'EFT / ACH', 'Debit Only']" />
            </validator>
            <label>Estimated Merchant Activations Monthly?</label>
            <validator :value="item.agentDb.appMonthly" reqs="required" style="width: 100%">
              <div class="combo-select">
              <dyn-select :options="validMapps" v-model="item.agentDb.appMonthly" />
              </div>
            </validator>
            <label>Estimated Monthly Merchant Volume:</label>
            <validator :value="item.agentDb.monthlyVolume" reqs="required" style="width: 100%">
              <formel-money-amount v-model="item.agentDb.monthlyVolume" />
            </validator>
          </div>
        </li>
        <li style="width: 50%">
          <div class="payment-checkbox">
            <input v-model="item.agentDb.treasury" id="treasury_checkbox" type="checkbox"/>
            <label for="treasury_checkbox">&nbsp;&nbsp;TREASURY</label>
          </div>
          <div class="field">
            <validator value="item.agentDb.treasuryTypes" reqs="required" class="payment-multi" style="width: 30%;">
              <multiselect :readonly="!item.agentDb.treasury" v-model="item.agentDb.treasuryTypes" :hasOther="0" :options="['Personal Accounts', 'Business Accounts', 'Debit Cards', 'Digital Assets', 'Assets Pay']" />
            </validator>
            <label>Estimated Accounts Monthly?</label>
            <validator :value="item.agentDb.treasuryMonthly" reqs="required" style="width: 100%">
              <div class="combo-select">
              <dyn-select :options="validMapps" v-model="item.agentDb.treasuryMonthly" />
              </div>
            </validator>
            <label>Estimated Account Volume:</label>
            <validator :value="item.agentDb.treasuryVolume" reqs="required" style="width: 100%">
              <formel-money-amount v-model="item.agentDb.treasuryVolume" />
            </validator>
          </div>
        </li>
      </ul>
    </section>
    
    <section v-if="step==3">
      <h2>
        <em>Additional Notes</em>
      </h2>
      <label class="forta"><span>Use this section to include any additional notes or comments</span></label>
      <validator :value="item.agentDb.additionalComments" reqs="char250">
        <div class="single-field">
          <textarea class="more-details" v-model="item.agentDb.additionalComments" style="resize: none; heigth: 300px"></textarea>
        </div>
      </validator>
    </section>

    <div class="pre-done" v-else-if="step==4">
      <h1>Thank you for submitting your application!</h1>
      <p>One of our account Representatives will contact you shortly.</p>
      <a v-if="!guest" href="" class="btn btn-large btn-primary btn-black">Back to Dashboard</a>
    </div>
    <div class="buttons">
      <button class="btn btn-large btn-primary btn-back" v-on:click.prevent="prevStep" v-if="step>=1 && step<4">Previous Step</button>
      <button v-if="step<3" class="btn btn-large btn-primary" v-on:click.prevent="nextStep">Next Step</button>
      <button v-else-if="step==3" class="btn btn-large btn-primary" v-on:click.prevent="send" :disabled="sending">Submit</button>
    </div>

  </form>
  
</div>
</div>
</div>
</template>

<script>
export default {
  data() { return {
    step: 0,
    steps: [
      {index: 1, title: "Contact Info"},
      {index: 2, title: "Company Info"},
      {index: 3, title: "Notes"},
      {index: 4, title: "Finalize"},
    ],
    industries: [
      {class: "ac01", label: "ISO", value: "agent-iso"},
      {class: "ac02", label: "Independent Sales Agency", value: "agent-isa"},
      {class: "ac03", label: "Authorized Agent", value: "agent-auth"},
      {class: "ac04", label: "Referral", value: "agent-ref"},
      {class: "ac04", label: "ISV", value: "agent-isv"},
//     {class: "ac04", label: "Developers", value: "agent-dev"},
//       {class: "ac03", label: "Account Executives", value: "agent-accex"},
//       {class: "ac04", label: "Sales Associates", value: "agent-sales"},
          
    ],
    validCountries: window.allCountries,
    validProvinces: window.allProvinces,
    validMapps: [
      {label: "0-30", value: "0-30"},
      {label: "31-50", value: "31-50"},
      {label: "51-75", value: "51-75"},
      {label: "76-99", value: "76-99"},
      {label: "100+", value: "100+"},
    ],
    sending: false,
    item: window.existingItem || {
      agentDb: {
        status: "A02",
        createdAt: "CURRENT_TIMESTAMP",
        referrer: appData.currentUser.id,
        referrerSite: appData.currentUser.username,
//         solution: appData.solution || null,
        merchantTypes: [],
        solutionId: appData.company.id,
      },
      groups: "",
      contacts: [
        {}],
    },
    files: [ null ],
    companydata: appData.company,
    link: appData.tempLinkUrl?{url: appData.tempLinkUrl}:null,
    guest: appData.guest || false,
    tobOptions: [
      "Inc.",
      "Corp.",
      "LLC",
      "LLP",
      "Ltd.",
      "Sole Proprietor",
      "",
    ],
  }},
  methods: {
    newUbo() {
      this.item.contacts.push({
      });
    },
    removeUbo(index) {
      this.item.contacts.splice(index, 1);
    },
    nextStep() {
      if (!this.validate())
        return stopLoading("Please fill all required fields", "error");
      this.step++;
    },
    prevStep() {
      if (this.step)
        this.step--;
    },
    send: async function() {
      if (!this.validate())
        return stopLoading("Please fill all required fields", "error");
      startLoading(1);
      this.sending=true;
      this.item.agentId="APP_ID_GEN_AGENT";
      this.item.customNote="Partner Sign-up form created";
      var result=await api.create("User", this.item, true);
      this.sending=false;
      const note = (await api.create("Note", {
        message: "Partner Sign-up form created",
        parentId: result.item.agentDb.id,
        type: 'Partner',
        created: "CURRENT_TIMESTAMP",
        author: appData.currentUser.id,
      }, true)).item
      stopLoading();
      this.step=4;
    },
    generateLink: async function() {
      if (!this.link) {
        var result=await api.action("TempLink", "new", "generate", {type: "Agent", solution: appData.solution});
        this.link=result.item;
        this.link.url=result.action.link;
      }      
    },
    copyLink() {
      copyToClipboard.text=this.link.url;
      copyToClipboard.doCopy();
    },
  },
  mixins: [formValidation.mixin]
}
</script>
<style scoped>
.payment-checkbox {
  display: flex;
  align-items: center;
}

.payment-checkbox label{
  border: 0 !important;
}

label {
  border: 0 !important;
}
</style>
