<template>
<form class="search-form" @submit.prevent="save()">
  <h2 class="doc-title">INTERNAL UNUSUAL ACTIVITY REPORT (IUAR)</h2>
  <p>How to use this report form:</p>
  <p>An IUAR must be submitted to the <b>COMPLIANCE OFFICER</b>.</p>
  <p>The Internal Unusual Activity Report (IUAR) is used to document the information and facts regarding a potential unusual and/or suspicious behaviour, activity and/or transaction. An IUAR must be completed for all suspicious activity in conformity with the Company’s AML, ATF, Fraud and Risk Policy. This form must be submitted to the Compliance Officer promptly.</p>
  <p><b>To: Compliance Officer</b></p>
  <p><b>From: {{ user.fullName }}</b></p>
  <p><b>Company Employee ID</b> (if applicable): <b>{{ user.username }}</b>
    <span class="ml-20">Dept. Name: <b>{{ usersGroup }}</b></span>
  </p>
  <p>I, the undersigned  as {{ user.fullName }} part of Company’s compliance procedures, have identified the following transaction, and/or Merchant Customer Activity or known person affiliated with a specific Merchant Customer
  <input v-model="form.name" type="text" @keypress="allowLetterNumber($event, { numbers: false })" placeholder="Merchant Name">
  , as possibly being involved in money laundering, terrorist financing and/or fraud offences or otherwise in breach of the Company’s policies or applicable terms and conditions.</p>

  <b>Details of the transaction and/or transactions to be reported</b>
  <div class="preform">
    <ul class="inline-block">
      <li v-for="(transaction, index) in transactions" class="mr-5 inline-block" :key="index">
        <input v-model="transaction.value" type="text" placeholder="Transaction ID" maxlength="20" @keypress="allowLetterNumber($event, { numbers: true })">
        <span v-if="transactions.length >= 2" class="small-remove-item-button" @click="removeTransactionID(index)">×</span>
      </li>
    </ul>
    <div class="preq-form inline-block">
      <div class="new-ubo">
        <a @click="addTransactionID()">Add Additional Transaction ID</a>
      </div>
    </div>
  </div>
  <textarea v-model="form.transaction_details" rows="3" class="w100" />

  <p><b>What is the:</b></p>

  <div class="custom-doc-field">
    <div class="label">Average monthly trading volume (if applicable):</div>
    <div class="field-wrapper">
      <money :amount.sync="form.avg_monthly_trading_volume.value" :currency.sync="form.avg_monthly_trading_volume.currency" />
    </div>
  </div>
  <div class="custom-doc-field">
    <div class="label">Average monthly volume of Transactions (if applicable):</div>
    <div class="field-wrapper">
      <input v-model.number="form.avg_monthly_volume_transactions" type="text" class="w100" @keypress="allowLetterNumber($event, { letters: false, numbers: true })">
    </div>
  </div>
  <div class="custom-doc-field">
    <div class="label">Transaction activity in relation to any Company set limits:</div>
    <div class="field-wrapper">
      <money :amount.sync="form.transaction_activity_compnay_set_limits.value" :currency.sync="form.transaction_activity_compnay_set_limits.currency" />
    </div>
  </div>

  <p>Detail the circumstances giving rise to this suspicious activity. If you believe a series of transactions, or linked transactions, are involved, ensure to describe the entire series or linked transactions, and indicate why you believe they comprise a series or are linked. Please include the date and time stamp of the transaction(s) in question.</p>
  <textarea v-model="form.suspicious_activity_details" rows="5" class="w100" />

  <p>What indicators raised your suspicions about possible money laundering, terrorist financing, fraud or other suspicious activity or behaviour by this individual?</p>
  <textarea v-model="form.suspicious_activity_details_fraud" rows="5" class="w100" />

  <p>Please provide any other information that is relevant to this present report:</p>
  <textarea v-model="form.relavant_report_info" rows="3" class="w100" />

  <div class="field">
    <label>DATE IUAR WAS COMPLETED BY EMPLOYEE:</label>
    <datepicker v-model="form.completed_date" :future="false" :minDate="new Date()" disabled />
  </div>

  <p>EMPLOYEE NAME: <b>{{ user.fullName }}</b></p>
  <p class="signature-placeholder"><span>EMPLOYEE’S SIGNATURE:</span><span class="signature-line" /></p>
  <p class="signature-placeholder"><span>DATE SUBMITTED TO THE COMPLIANCE OFFICER:</span><span class="signature-line" /></p>
  <p class="signature-placeholder"><span>SIGNATURE OF COMPLIANCE OFFICER:</span><span class="signature-line" /></p>

  <div class="buttons">
    <button type="submit" class="btn btn-small" :disabled="!isValidForm">Submit</button>
    <button class="btn btn-small" @click="clear()">Clear</button>
  </div>
</form>
</template>

<script>
export default {
  mixins: [ vueMixins.helpers ],

  data () {
    return {
      transactions: [],
      form: {}
    }
  },

  computed: {
    user () {
      return window.appData.currentUser
    },

    usersGroup () {
      return this.userGroup(this.user.groups)
    },

    isValidForm () {
      return this.validateTransactionIDs && this.validateMoney && this.validateInputs
    },

    validateTransactionIDs () {
      return this.transactions.map(item => item.value).filter(value => !value).length === 0
    },

    validateInputs () {
      const empty = []
      const keys = ['name', 'transaction_details', 'suspicious_activity_details', 'suspicious_activity_details_fraud', 'relavant_report_info', 'completed_date']
      return keys.map(key => this.form[key]).filter(value => !value).length === 0
    },

    validateMoney () {
      const empty = []
      const keys = []
      keys.forEach(key => {
        let value = this.form[key].value
        if (value === null || isNaN(value)) empty.push(key)
      })
      return empty.length === 0
    }
  },

  created () {
    this.clear()
  },

  methods: {
    async save () {
      if (this.isValidForm) {
        let props = []
        startLoading();
        Object.keys(this.form).forEach(name => {
          let value = this.form[name] 
          if (name === 'transaction_ids')
            value = this.transactions.map(transaction => transaction.value)
          if (['transaction_ids', 'avg_monthly_trading_volume', 'transaction_activity_compnay_set_limits'].includes(name))
            value = JSON.stringify(value)
          props.push({ name, value })
        })
        try {
          const response = await api.create("ComplianceDocument", {
            userId: window.appData.currentUser.id,
            type: 'IUAR',
            docIndex: 'GEN_DOC_ID',
            status: 'PENDING',
            signed: null,
            created: 'CURRENT_TIMESTAMP',
            form: props
          })
          await this.createNote(response.id)
          this.clear()
          stopLoading("Saved")
        } catch (e) {
          stopLoading(e, 'error')
        }
      }
    },

    createNote (compDocId) {
      const data = {
        message: 'IUAR Form Created',
        created: "CURRENT_TIMESTAMP",
        author: window.appData.currentUser.id,
        system: 1,
        type: 'ComplianceDocument',
        parentId: compDocId
      }
      return api.create("Note", data)
    },

    removeTransactionID (index) {
      this.transactions.splice(index, 1)
    },

    addTransactionID () {
      this.transactions.push({ value: null })
    },

    clear () {
      this.form = {
        name: null,
        completed_date: moment().format("YYYY-MM-DD"),
        transaction_ids: null,
        transaction_details: null,
        suspicious_activity_details: null,
        suspicious_activity_details_fraud: null,
        relavant_report_info: null,
        avg_monthly_volume_transactions: null,
        avg_monthly_trading_volume: {
          value: null,
          currency: '124'
        },
        transaction_activity_compnay_set_limits: {
          value: null,
          currency: '124'
        }
      }
      this.transactions = [{ value: null }]
    }
  }
}
</script>
