<template>
<div>
  <amadel-menu :page="currentPage" />
 
  <task-list :type="type" />
</div>
</template>

<script>
export default {
  data () {
    return {
      type: 'AMADEL',
      currentPage: {
        text: 'Task List'
      }
    }
  }
}
</script>
