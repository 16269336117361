<template>
<div>
  <amadel-menu :page="currentPage" />

  <div class="wcontent dashboard">
    <div class="dash-row">
      <div class="col">
        <table class="breakdown-table">
          <thead>
            <tr>
              <th colspan="2">Prospects</th>
            </tr>
            <tr><th colspan="2" class="spacer"></th></tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, key) in list"
              :key="item.name">
              <td>
                <a :href="getProspectUrl(key)">{{ item.name }}</a>
              </td>
              <td>{{ item.data.length }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col" />
      <div class="col">
        <table class="breakdown-table">
          <thead>
            <tr>
              <th colspan="2">My Calendar</th>
            </tr>
            <tr><th colspan="2" class="spacer"></th></tr>
          </thead>
          <tbody>
            <tr>
              <td colspan="2">
                <div class="font-16 mb-10">Todays Events:</div>
                <div class="event-list">
                  <template v-if="events">
                    <ul v-if="events.length" class="list">
                      <li
                        v-for="event in events"
                        :key="event.id"
                        class="list-item">
                        <div
                          class="circle"
                          :class="event.color ? `event-${event.color.toLowerCase()}` : 'event-blue'" />
                        <b>{{ formatEventTime(event) }}</b>: {{ event.subject }}
                        <a :href="getUrl(event.id)">
                          <ch-button class="floatr" size="xs">View</ch-button>
                        </a>
                      </li>
                    </ul>
                    <span v-else>No Events Today.</span>
                  </template>
                  <div v-else>
                    Loading...
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  data () {
    return {
      leads: [],
      events: null,
      currentPage: {
        text: 'Amadel'
      }
    }
  },

  computed: {
    today () {
      return moment().format('YYYY-MM-DD')
    },

    newList () {
      return this.leads.filter(item => ['AMA10', 'AMA11', 'AMA12', 'AMA13'].includes(item.status))
    },

    assignedList () {
      return this.leads.filter(item => this.isAssigned(item))
    },

    completedList () {
      return this.leads.filter(item => ['AMA20', 'AMA21', 'AMA22', 'AMA23', 'AMA24'].includes(item.status))
    },

    incompletedList () {
      return this.leads.filter(item => ['AMA30', 'AMA31'].includes(item.status))
    },

    otherList () {
      return this.leads.filter(item => ['AMA40', 'AMA41', 'AMA42', 'AMA43', 'AMA44'].includes(item.status))
    },

    list () {
      return {
        NEW: {
          name: 'New',
          data: this.newList
        },
        ASSIGNED: {
          name: 'Assigned',
          data: this.assignedList
        },
        COMPLETED: {
          name: 'Completed',
          data: this.completedList
        },
        INCOMPLETED: {
          name: 'Incomplete',
          data: this.incompletedList
        },
        OTHER: {
          name: 'Other',
          data: this.otherList
        }
      }
    }
  },

  created() {
    this.getData()
    this.loadEvents()
  },

  methods: {
    getProspectUrl (key) {
      const list = {
        NEW: 'ASSIGNED',
        ASSIGNED: 'ASSIGNED',
        COMPLETED: 'COMPLETED',
        INCOMPLETED: 'INCOMPLETED',
        OTHER: 'OTHER',

      }
      return `/agents/leads-overview?open=${list[key]}`
    },

    getUrl (id) {
      let url = `/calendar/date-view/${this.today}`
      return id ? `${url}?event=${id}` : url
    },

    isAssigned (item) {
      return ['AMA11', 'AMA12', 'AMA13'].includes(item.status)
    },

    async getData () {
      const payload = (window.appData.currentUser.groups !== 'admin-super') ? { assignedTo: window.appData.currentUser.id } : {}
      this.leads = (await api.list('AmadelLead', payload, ["created:DESC"])).items
    },

    async loadEvents () {
      this.events = (await api.list("Event", { type: 'AMADEL', date_from: ["LIKE", `${this.today}%`]})).items
    },

    formatEventTime (event) {
      const from = moment(event.date_from, 'YYYY-MM-DD HH:mm:ss').format('h:mm A')
      const to = moment(event.date_to, 'YYYY-MM-DD HH:mm:ss').format('h:mm A')
      return `${from} - ${to}`
    }
  }
}
</script>
