<template>
  <div class="pricing-template">


    <h2>MONTHLY PLAN</h2>
    <ul class="pricing-list">
      <li><label><!--<input type="checkbox" v-model="currentTemplate.accessPlan" />--><em></em>Access Plan</label>
        <multiselect-dropdown :options="methodOptions.allMethods.map(a=>({label: a, value: a}))" v-model="methodOptions.enabledMethods" :isMulti="true" :isExclusive="true" />
      </li>
      <li v-if="methodOptions.enabledMethods.find(a=>methodOptions.allMethods.find(b=>a==b))"><label><!--<input type="checkbox" v-model="currentTemplate.accessPlan" />--><em></em>Price</label>
        <ul>
          <li>
<!--             <label></label> -->
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.liteAccessMonthly" />
          </li>
        </ul>
        <ul v-if="false">
          <li v-if="hasM('Monthly Simple')">
            <label><input type="checkbox" v-model="currentTemplate.liteAccessMonthlyToggle" v-on:input="currentTemplate.fullAccessMonthlyToggle=false" /><em></em>Simple</label> <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.liteAccessMonthly" :disabled="!currentTemplate.liteAccessMonthlyToggle" />
          </li>
          <li v-if="hasM('Monthly Prime')">
            <label><input type="checkbox" v-model="currentTemplate.fullAccessMonthlyToggle" v-on:input="currentTemplate.liteAccessMonthlyToggle=false" /><em></em>Prime</label> <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.fullAccessMonthly" :disabled="!currentTemplate.fullAccessMonthlyToggle" />
          </li>
<!--          <li>
            <label><input type="checkbox" v-model="currentTemplate.axisAccessMonthlyToggle" />Axis</label> <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.axisAccessMonthly" />
          </li>-->
        </ul>
      </li>
    </ul>

    <h2>PRICING MODEL</h2>
    <ul class="pricing-list">
      <li xv-if="hasM('Billing Daily') || hasM('Billing Monthly')">
        <label><!--<input type="checkbox" v-model="currentTemplate.billingOptions" />--><!--<em></em>-->Billing Option</label>
        <multiselect-dropdown :options="[{label:'Billing Daily', value: 'dailyPricing'}, {label: 'Billing Monthly', value: 'monthlyPricing'}]" v-model="ctCheckboxes" :isMulti="true" :isExclusive="true" />
        <ul v-if="false">
          <li v-if="hasM('Billing Daily')">
            <label><input type="checkbox" v-model="currentTemplate.dailyPricing" v-on:input="currentTemplate.monthlyPricing=false" /><em></em>Daily</label>
          </li>
          <li v-if="hasM('Billing Monthly')">
            <label><input type="checkbox" v-model="currentTemplate.monthlyPricing" v-on:input="currentTemplate.dailyPricing=false" /><em></em>Monthly</label>
          </li>
          <li>
          </li>
        </ul>
      </li>
      <li><label><!--<input type="checkbox" v-model="currentTemplate.billingModel" />-->Billing Model</label>
        <multiselect-dropdown :options="[{label:'Billing Flat Rate', value: 'flatRatePricing'}, {label: 'Billing Interchange Plus', value: 'interchangePricing'}, {label: 'Billing Tiered', value: 'tieredPricing'}]" v-model="ctCheckboxes" :isMulti="true" :isExclusive="true" />
        <ul v-if="false">
          <li v-if="hasM('Billing Flat Rate')">
            <label><input type="checkbox" v-model="currentTemplate.flatRatePricing" v-on:input="currentTemplate.interchangePricing=false; currentTemplate.tieredPricing=false" /><em></em>Flat Rate</label>
          </li>
          <li v-if="hasM('Billing Interchange Plus')">
            <label><input type="checkbox" v-model="currentTemplate.interchangePricing" v-on:input="currentTemplate.flatRatePricing=false; currentTemplate.tieredPricing=false" /><em></em>Interchange Plus Pricing</label>
          </li>
          <li v-if="hasM('Billing Tiered')">
            <label><input type="checkbox" v-model="currentTemplate.tieredPricing" v-on:input="currentTemplate.flatRatePricing=false; currentTemplate.interchangePricing=false" /><em></em>Tiered Pricing</label>
          </li>
        </ul>
        <div class="details details-tiered" v-if="hasM('Billing Tiered') && currentTemplate.tieredPricing">
          <ul>
            <li>
              <label>T1 - Qualified</label>
              <input :disabled="!currentTemplate.tieredPricing" type="text" v-model="currentTemplate.tier1">
              <span class="suffix">%</span>
            </li>
            <li>
              <label>T2 - Mid Qualified</label>
              <input :disabled="!currentTemplate.tieredPricing" type="text" v-model="currentTemplate.tier2">
              <span class="suffix">%</span>
            </li>
            <li>
              <label>T3 - Non Qualified</label>
              <input :disabled="!currentTemplate.tieredPricing" type="text" v-model="currentTemplate.tier3">
              <span class="suffix">%</span>
            </li>
          </ul>
        </div>
      </li>
    </ul>
    <h2>PAYMENT METHODS</h2>
    <ul class="pricing-list">
      <li v-if="hasM('Visa') || hasM('Mastercard') || hasM('Discover') || hasM('AMEX') || hasM('JCB') || hasM('Dinners') || hasM('Debit')">
        <label>Types</label>
        <multiselect-dropdown :options="cardOpts" v-model="currentTemplate.methods" :isMulti="true" />
        <ul v-if="false">
          <li v-if="hasM('Visa')">
            <label>
              <input type="checkbox" v-model="currentTemplate.methods" value="Visa" /><em></em>
              Visa
            </label>
          </li>
          <li v-if="hasM('Mastercard')">
            <label>
              <input type="checkbox" v-model="currentTemplate.methods" value="Mastercard" /><em></em>
              Mastercard
            </label>
          </li>
          <li v-if="hasM('Discover')">
            <label>
              <input type="checkbox" v-model="currentTemplate.methods" value="Discover" /><em></em>
              Discover
            </label>
          </li>
          <li v-if="hasM('AMEX')">
            <label>
              <input type="checkbox" v-model="currentTemplate.methods" value="AMEX" /><em></em>
              AMEX
            </label>
          </li>
          <li v-if="hasM('JCB')">
            <label>
              <input type="checkbox" v-model="currentTemplate.methods" value="JCB" /><em></em>
              JCB
            </label>
          </li>
          <li v-if="hasM('Dinners')">
            <label>
              <input type="checkbox" v-model="currentTemplate.methods" value="Dinners" /><em></em>
              Dinners
            </label>
          </li>
          <li v-if="hasM('Debit')">
            <label>
              <input type="checkbox" v-model="currentTemplate.methods" value="Debit" /><em></em>
              Debit
            </label>
          </li>
        </ul>
      </li>
      <li v-if="(hasM('Visa') || hasM('Mastercard') || hasM('Discover') || hasM('AMEX') || hasM('JCB') || hasM('Dinners') || hasM('Debit')) && cardSel.ccard">
        <label>Swiped/Tapped/Inserted</label>
        <ul>
          <li>
            <label>Rate</label>
            <number-edit step="0.01" v-model="currentTemplate.swipedTappedInserted" :disabled="!cardSel.ccard" />
            <span class="suffix">%</span>
          </li>
          <li>
            <label>Trans</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.swipedTappedInsertedTx" :disabled="!cardSel.ccard" />
          </li>
          <li>
            <label>Intl</label>
            <number-edit step="0.01" v-model="currentTemplate.swipedTappedInsertedInt" :disabled="!cardSel.ccard" />
            <span class="suffix">%</span>
          </li>
        </ul>
      </li>
      <li v-if="(hasM('Visa') || hasM('Mastercard') || hasM('Discover') || hasM('AMEX') || hasM('JCB') || hasM('Dinners') || hasM('Debit')) && cardSel.ccard">
        <label>E-Commerce/Keyed</label>
        <ul>
          <li>
            <label>Rate</label>
            <number-edit step="0.01" v-model="currentTemplate.eCommerceKeyed" :disabled="!cardSel.ccard" />
            <span class="suffix">%</span>
          </li>
          <li>
            <label>Trans</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.eCommerceKeyedTx" :disabled="!cardSel.ccard" />
          </li>
          <li>
            <label>Intl</label>
            <number-edit step="0.01" v-model="currentTemplate.eCommerceKeyedInt" :disabled="!cardSel.ccard" />
            <span class="suffix">%</span>
          </li>
        </ul>
      </li>
      <!--<li><label>AMEX</label>
        <ul>
          <li>
            <label>Monthly</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.amexMonthly" :disabled="!cardSel.amex" />
          </li>
          <li>
            <label>Rate</label>
            <number-edit step="0.01" v-model="currentTemplate.amex" :disabled="!cardSel.amex" />
            <span class="suffix">%</span>
          </li>
          <li>
            <label>Tx</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.amexTx" :disabled="!cardSel.amex" />
          </li>
          <li>
            <label>Intl</label>
            <number-edit step="0.01" v-model="currentTemplate.amexIntl" :disabled="!cardSel.amex" />
            <span class="suffix">%</span>
          </li>
        </ul>
      </li>-->
      <li v-if="(hasM('Visa') || hasM('Mastercard') || hasM('Discover') || hasM('AMEX') || hasM('JCB') || hasM('Dinners') || hasM('Debit')) && cardSel.ccard">
        <label>B2B</label>
        <ul>
          <li>
            <label>Level2 Trans</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.cbAsessment2" :disabled="!cardSel.ccard" />
          </li>
          <li>
            <label>Level3 Trans</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.cbAsessment3" :disabled="!cardSel.ccard" />
          </li>
        </ul>
      </li>
      <li v-if="hasM('Debit') && cardSel.debit">
        <label>Debit: Swiped/Inserted</label>
        <ul>
          <li>
            <label>Trans</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.debitTx" :disabled="!cardSel.debit" />
          </li>
          <li>
            <label>Tapped/Flash + Tx</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.debitTx1" :disabled="!cardSel.debit" />
            <span class="suffix-label">(t1)</span>
          </li>
          <li>
            <label>+ Tx</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.debitTx2" :disabled="!cardSel.debit" />
            <span class="suffix-label">(t2)</span>
          </li>
          <li>
            <label>+ Tx</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.debitTx3" :disabled="!cardSel.debit" />
            <span class="suffix-label">(t3)</span>
          </li>
        </ul>
      </li>
    </ul>
    <h2><label><input type="checkbox" v-model="currentTemplate.cbPlusToggle" /><em></em>ADVANCED PAYMENT METHODS</label></h2>
    <ul class="pricing-list">
      <li v-if="hasM('Apple Pay') || hasM('Google Pay') || hasM('Samsung Pay') || hasM('Interac Online')">
        <label>Types</label>
        <multiselect-dropdown :options="advPayOpts" v-model="currentTemplate.methods" :isMulti="true" :disabled="!currentTemplate.cbPlusToggle" />
        <ul v-if="false">
          <li v-if="hasM('Apple Pay')">
            <label>
              <input type="checkbox" v-model="currentTemplate.methods" value="Apple Pay" :disabled="!currentTemplate.cbPlusToggle" /><em></em>
              Apple Pay
            </label>
          </li>
          <li v-if="hasM('Google Pay')">
            <label>
              <input type="checkbox" v-model="currentTemplate.methods" value="Google Pay" :disabled="!currentTemplate.cbPlusToggle" /><em></em>
              Google Pay
            </label>
          </li>
          <li v-if="hasM('Samsung Pay')">
            <label>
              <input type="checkbox" v-model="currentTemplate.methods" value="Samsung Pay" :disabled="!currentTemplate.cbPlusToggle" /><em></em>
              Samsung Pay
            </label>
          </li>
          <li v-if="hasM('Interac Online')">
            <label>
              <input type="checkbox" v-model="currentTemplate.methods" value="Interac Online" :disabled="!currentTemplate.cbPlusToggle" /><em></em>
              Interac Online
            </label>
          </li>
        </ul>
      </li>
      <li v-if="(hasM('Apple Pay') || hasM('Google Pay') || hasM('Samsung Pay') || hasM('Interac Online')) && currentTemplate.cbPlusToggle">
        <label>All Types</label>
        <ul>
          <li>
            <label>Monthly</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.cbPlusMontly" :disabled="!currentTemplate.cbPlusToggle" />
          </li>
          <li>
            <label>Rate</label>
            <number-edit step="0.01" v-model="currentTemplate.cbPlus" :disabled="!currentTemplate.cbPlusToggle" />
            <span class="suffix">%</span>
          </li>
          <li>
            <label>Trans</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.cbPlusTx" :disabled="!currentTemplate.cbPlusToggle" />
          </li>
          <li>
            <label>Intl</label>
            <number-edit step="0.01" v-model="currentTemplate.cbPlusInt" :disabled="!currentTemplate.cbPlusToggle" />
            <span class="suffix">%</span>
          </li>
        </ul>
      </li>
    </ul>
    <h2><label><input type="checkbox" v-model="currentTemplate.bbToggle" /><em></em>BANK CLEARING</label></h2>
    <ul class="pricing-list">
      <li v-if="hasM('EFT') || hasM('EFT Credits') || hasM('ACH') || hasM('ACH Credits')">
        <label>Types</label>
        <multiselect-dropdown :options="bankClearingOpts" v-model="currentTemplate.methods" :isMulti="true" :disabled="!currentTemplate.bbToggle" />
        <ul v-if="false">
          <li v-if="hasM('EFT')">
            <label>
              <input type="checkbox" v-model="currentTemplate.methods" value="EFT" :disabled="!currentTemplate.bbToggle" /><em></em>
              EFT
            </label>
          </li>
          <li v-if="hasM('EFT Credits')">
            <label>
              <input type="checkbox" v-model="currentTemplate.methods" value="EFT Credits" :disabled="!currentTemplate.bbToggle" /><em></em>
              EFT Credits
            </label>
          </li>
          <li v-if="hasM('ACH')">
            <label>
              <input type="checkbox" v-model="currentTemplate.methods" value="ACH" :disabled="!currentTemplate.bbToggle" /><em></em>
              ACH
            </label>
          </li>
          <li v-if="hasM('ACH Credits')">
            <label>
              <input type="checkbox" v-model="currentTemplate.methods" value="ACH Credits" :disabled="!currentTemplate.bbToggle" /><em></em>
              ACH Credits
            </label>
          </li>
        </ul>
      </li>
      <li v-if="(hasM('EFT') || hasM('EFT Credits') || hasM('ACH') || hasM('ACH Credits')) && currentTemplate.bbToggle">
        <label>Initial</label>
        <ul>
          <li>
            <label>Setup</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.bbSetup" :disabled="!currentTemplate.bbToggle" />
          </li>
          <li>
            <label>Monthly</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.bbMontly" :disabled="!currentTemplate.bbToggle" />
          </li>
          <li>
            <label>Non-Compliance</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.bbNonComp" :disabled="!currentTemplate.bbToggle" />
          </li>
        </ul>
      </li>
      <li v-if="(hasM('EFT') || hasM('EFT Credits') || hasM('ACH') || hasM('ACH Credits')) && currentTemplate.bbToggle">
        <label>Processing</label>
        <ul>
          <li>
            <label>Rate</label>
            <number-edit step="0.01" v-model="currentTemplate.bbRate" :disabled="!currentTemplate.bbToggle" />
            <span class="suffix">%</span>
          </li>
          <li>
            <label>Trans</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.bbTx" :disabled="!currentTemplate.bbToggle" />
          </li>
          <li>
            <label>Batch Fee</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.bbBatch" :disabled="!currentTemplate.bbToggle" />
          </li>
          <li>
          </li>
        </ul>
      </li>
      <li v-if="(hasM('EFT') || hasM('EFT Credits') || hasM('ACH') || hasM('ACH Credits')) && currentTemplate.bbToggle">
        <label></label>
        <ul>
          <li>
            <label>Rejects</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.bbRejects" :disabled="!currentTemplate.bbToggle" />
          </li>
          <li>
            <label>Returns</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.bbReturns" :disabled="!currentTemplate.bbToggle" />
          </li>
          <li>
            <label>Unauthorized</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.bbUnauthorized" :disabled="!currentTemplate.bbToggle" />
          </li>
          <li>
            <label>Late Returns</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.bbLate" :disabled="!currentTemplate.bbToggle" />
          </li>
        </ul>
      </li>
<!--      <li><label><input type="checkbox" v-model="currentTemplate.bbAuthShield" />AuthShield</label>
        <ul>
          <li>
            <label>Monthly</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.bbAuthShieldMontly">
          </li>
          <li>
            <label>AuthVer Tx</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.bbAuthVerTx">
          </li>
          <li>
            <label>AuthScreen Tx</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.bbAuthScreenTx">
          </li>
        </ul>
      </li>-->
      <!--<li><label><input type="checkbox" v-model="currentTemplate.bbTransBox" />TransBox</label>
        <ul>
          <li>
            <label>Monthly</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.bbTransBoxMonthly">
          </li>
          <li>
            <label>Vault Tx</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.bbVaultTx">
          </li>
          <li>
            <label>Recurring Tx</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.bbRecurringTx">
          </li>
        </ul>
      </li>-->
    </ul>
    <h2>MODULES</h2>
    <ul class="pricing-list pl2 madon">
      <li style="width: 100%"><label style="min-width: 16.66%">Types</label>
        <multiselect-dropdown :options="methodOptions.allModules.map(a=>({label: a, value: a}))" v-model="ctCheckboxes" :isMulti="true" />
      </li>
      <li v-for="moduleName in methodOptions.allModules" v-if="ctCheckboxes.indexOf(moduleName)!=-1">
        <label><em></em>{{ moduleName }}</label>
        <ul>
          <li>
            <label>Setup</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.payAxisSetup" />
          </li>
          <li>
            <label>Monthly</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.payAxisMonthly" />
          </li>
        </ul>
      </li>
      <!--<li v-if="hasM('Gateway')"><label><input v-if="false" type="checkbox" v-model="currentTemplate.payAxis" /><em></em>Gateway</label>
        <ul>
          <li>
            <label>Setup</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.payAxisSetup" :disabled="!currentTemplate.payAxis" />
          </li>
          <li>
            <label>Monthly</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.payAxisMonthly" :disabled="!currentTemplate.payAxis" />
          </li>
        </ul>
      </li>
      <li v-if="hasM('Virtual Terminal')"><label><input v-if="false" type="checkbox" v-model="currentTemplate.vivpos" /><em></em>Virtual Terminal</label>
        <ul>
          <li>
            <label>Setup</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.vivposSetup" :disabled="!currentTemplate.vivpos" />
          </li>
          <li>
            <label>Monthly</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.vivposMonthly" :disabled="!currentTemplate.vivpos" />
          </li>
        </ul>
      </li>
      <li v-if="hasM('Gift')"><label><input v-if="false" type="checkbox" v-model="currentTemplate.chammpGift" /><em></em>Gift</label>
        <ul>
          <li>
            <label>Setup</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.chammpGiftSetup" :disabled="!currentTemplate.chammpGift" />
          </li>
          <li>
            <label>Monthly</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.chammpGiftMonthly" :disabled="!currentTemplate.chammpGift" />
          </li>
        </ul>
      </li>
      <li v-if="hasM('Gift & Loyality')"><label><input v-if="false" type="checkbox" v-model="currentTemplate.chammpRewards" /><em></em>Gift & Loyality</label>
        <ul>
          <li>
            <label>Setup</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.chammpRewardsSetup" :disabled="!currentTemplate.chammpRewards" />
          </li>
          <li>
            <label>Monthly</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.chammpRewardsMonthly" :disabled="!currentTemplate.chammpRewards" />
          </li>
        </ul>
      </li>-->
    </ul>
    <h2>ACCOUNT OPTIONS</h2>
    <ul class="pricing-list pl3  madon">
      <li style="width: 100%"><label style="min-width: 16.66%">Types</label>
        <multiselect-dropdown :options="accountOpts" v-model="ctCheckboxes" :isMulti="true" />
      </li>
      <li v-if="hasM('Multi-Currency') && currentTemplate.multiCurrencyToggle"><label><input v-if="false" type="checkbox" v-model="currentTemplate.multiCurrencyToggle" /><em></em>Multi-Currency</label>
        <ul>
          <li>
            <label>Monthly</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.multiCurrencyMonthly" :disabled="!currentTemplate.multiCurrencyToggle" />
          </li>
        </ul>
      </li>
      <li v-if="hasM('Subscription Billing') && currentTemplate.virtualTerminalToggle"><label><input v-if="false" type="checkbox" v-model="currentTemplate.virtualTerminalToggle" /><em></em>Subscription Billing</label>
        <ul>
          <li>
            <label>Monthly</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.virtualTerminalMonhly" :disabled="!currentTemplate.virtualTerminalToggle" />
          </li>
        </ul>
      </li>
      <li v-if="hasM('Mailed Statements') && currentTemplate.chammpCheckout"><label><input v-if="false" type="checkbox" v-model="currentTemplate.chammpCheckout" /><em></em>Mailed Statements</label>
        <ul>
          <li>
            <label>Monthly</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.chammpCheckoutMonthly" :disabled="!currentTemplate.chammpCheckout" />
          </li>
        </ul>
      </li>
      <li v-if="hasM('Auto Updater') && currentTemplate.chammpUpdater"><label><input v-if="false" type="checkbox" v-model="currentTemplate.chammpUpdater" /><em></em>Auto Account Updater</label>
        <ul>
          <li>
            <label>Monthly</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.chammpUpdaterMonthly" :disabled="!currentTemplate.chammpUpdater" />
          </li>
        </ul>
      </li>
      <li v-if="hasM('Titan') && currentTemplate.chammpTitan"><label><input v-if="false" type="checkbox" v-model="currentTemplate.chammpTitan" /><em></em>Titan</label>
        <ul>
          <li>
            <label>Monthly</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.chammpTitanMonthly" :disabled="!currentTemplate.chammpTitan" />
          </li>
        </ul>
      </li>
    </ul>
    <h2 v-if="false">CONNECTIVITY</h2>
    <ul class="pricing-list pl2 madon" v-if="false">
      <li><label><input type="checkbox" v-model="currentTemplate.tids" /><em></em>TIDs</label>
        <ul>
          <li>
            <label>Included</label>
            <span class="prefix">#</span>
            <number-edit step="0.01" v-model="currentTemplate.tidsIncluded" :disabled="!currentTemplate.tids" />
          </li>
          <li>
            <label>Additional</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.tidsFee" :disabled="!currentTemplate.tids" />
          </li>
        </ul>
      </li>
      <li><label><input type="checkbox" v-model="currentTemplate.keys" /><em></em>API Keys</label>
        <ul>
          <li>
            <label>Included</label>
            <span class="prefix">#</span>
            <number-edit step="0.01" v-model="currentTemplate.keysIncluded" :disabled="!currentTemplate.keys" />
          </li>
          <li>
            <label>Additional</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.keysFee" :disabled="!currentTemplate.keys" />
          </li>
        </ul>
      </li>
    </ul>
    <h2>SYSTEM FEES</h2>
    <div class="fee-types">
      <div>
        <h3>Per Occurrence</h3>
        <ul class="fee-list">
          <li><label>Chargebacks</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.chargebacks" />
          </li>
          <li><label>Retrievals</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.retrievals" />
          </li>
          <li><label>Reversals</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.reversals" />
          </li>
          <li><label>Alerts</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.chammpAlerts" />
          </li>
          <li><label>IVR Authorization</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.ivrAuthorizations" />
          </li>
          <li><label>Voice Authorization</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.voiceAuthorizations" />
          </li>
          <li><label>Referral Authorizations</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.referralAuthorization" />
          </li>
          <li><label>Wireless Activation</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.wirelessActivation" />
          </li>
          <li><label>Wireless Additional Data</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.wirelessData" />
            <span class="suffix">/MB</span>
          </li>
        </ul>
      </div>
      <div>
        <h3>Per Transaction</h3>
        <ul class="fee-list">
          <li><label>Titan</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.titan" />
          </li>
          <li><label>Titan Plus</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.titanPlus" />
          </li>
          <li><label>Batch Fee</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.batchFee" />
            <span class="suffix">/Batch</span>
          </li>
          <li><label>Tokenization</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.tokenization" />
            <span class="suffix">/Stored</span>
          </li>
          <li><label>Auto Account Updater</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.autoAccountUpdater" />
            <span class="suffix">/Update</span>
          </li>
          <li><label>AVS</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.avs" />
          </li>
          <li><label>Wireless Transaction Fee</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.wirelessFee" />
          </li>
        </ul>
      </div>
      <div>
        <h3>Monthly</h3>
        <ul class="fee-list">
          <li><label>Cellular Wireless Data Fee</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.cellularFee" />
          </li>
<!--          <li><label>Auto Account Updater</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.autoAccountUpdaterM" />
          </li>-->
<!--          <li><label>CHAMMP Alerts</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.chammpAlertsM" />
          </li>-->
          <li><label>PCI Non-Compliance</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.pciNonCompliance" />
          </li>
          <li><label>Data Security Fee</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.dataSecurityFee" />
          </li>
          <li><label>Wireless CDMA</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.wirelessCDMA" />
          </li>
          <li><label>Wireless GPRS</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.wirelessGPRS" />
          </li>
<!--          <li><label>Additional TID</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.tidsFee" />
          </li>
          <li><label>Additional Key</label>
            <span class="prefix">$</span>
            <number-edit step="0.01" v-model="currentTemplate.keysFee" />
          </li>-->
        </ul>
      </div>
    </div>
    <h2>OTHER FEES</h2>
    <div class="fee-types"><div><h3>Per Occurrence</h3></div></div>
    <ul class="fee-list fl3">
      <li><label>Application Fee</label>
        <span class="prefix">$</span>
        <number-edit step="0.01" v-model="currentTemplate.applicationFee" />
      </li>
      <li><label>Account Updates</label>
        <span class="prefix">$</span>
        <number-edit step="0.01" v-model="currentTemplate.accountChanges" />
      </li>
      <li><label>Terminal Support</label>
        <span class="prefix">$</span>
        <number-edit step="0.01" v-model="currentTemplate.terminalSupport" />
      </li>
      <li><label>Account Setup</label>
        <span class="prefix">$</span>
        <number-edit step="0.01" v-model="currentTemplate.accountSetup" />
      </li>
      <li><label>EFT/ACH Fee</label>
        <span class="prefix">$</span>
        <number-edit step="0.01" v-model="currentTemplate.eftAchFee" />
      </li>
      <li><label>Equipment Upgrade Fee</label>
        <span class="prefix">$</span>
        <number-edit step="0.01" v-model="currentTemplate.equipmentUpgradeFee" />
      </li>
      <li><label>Annual Fee</label>
        <span class="prefix">$</span>
        <number-edit step="0.01" v-model="currentTemplate.annualFee" />
      </li>
      <li><label>Wire Fee</label>
        <span class="prefix">$</span>
        <number-edit step="0.01" v-model="currentTemplate.wireFee" />
      </li>
      <li><label>Equipment Trade</label>
        <span class="prefix">$</span>
        <number-edit step="0.01" v-model="currentTemplate.equipmentTradeBuyBack" />
      </li>
<!--      <li><label>Mailed Statements</label>
        <span class="prefix">$</span>
        <number-edit step="0.01" v-model="currentTemplate.mailedStatements" />
      </li>-->
      <li></li>
      <li><label>International Wire Fee</label>
        <span class="prefix">$</span>
        <number-edit step="0.01" v-model="currentTemplate.internationalWireFee" />
      </li>
      <li><label>Finance Fee</label>
        <span class="prefix">$</span>
        <number-edit step="0.01" v-model="currentTemplate.financeFee" />
      </li>
      <li></li>
      <li><label>Returned Item/NSF</label>
        <span class="prefix">$</span>
        <number-edit step="0.01" v-model="currentTemplate.returnItemNsf" />
      </li>
      <li><label>Equipment Swap Fee</label>
        <span class="prefix">$</span>
        <number-edit step="0.01" v-model="currentTemplate.equipmentSwapFee" />
      </li>
      <li></li>
      <li></li>
      <li><label>Protection Plan</label>
        <span class="prefix">$</span>
        <number-edit step="0.01" v-model="currentTemplate.protectionPlan" />
      </li>
      <li></li>
      <li></li>
      <li><label>Platinum Protection Plan</label>
        <span class="prefix">$</span>
        <number-edit step="0.01" v-model="currentTemplate.protectionPlanPlatinum" />
      </li>
      <li></li>
      <li></li>
      <li><label>CB OnSite</label>
        <span class="prefix">$</span>
        <number-edit step="0.01" v-model="currentTemplate.cbOnSite" />
        <span class="suffix">/HR</span>
      </li>
    </ul>
    <h2>SPECIAL OFFERS</h2>
    <ul class="pricing-list">
      <li><label><input type="checkbox" v-model="currentTemplate.freeTerminal" /><em></em>Free Terminal Offer</label>
        <ul v-if="currentTemplate.freeTerminal">
          <li>
            <span class="prefix">#</span>
            <number-edit step="0.01" v-model="currentTemplate.freeTerminalIncluded" :disabled="!currentTemplate.freeTerminal" />
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import $ from 'jquery';
export default {
  props: ["currentTemplate", "selectedPlan", "solution"],
  data() { return {
    modules: [],
    options: [],
    methodOptions: {
      enabledMethods: [],
      allMethods: [],
      allModules: [],
    },
    ctCheckboxes: [],
  }},
  watch: {
    ctCheckboxes: {
      handler() {
        for(var opt of ["monthlyPricing", "dailyPricing", "flatRatePricing", "interchangePricing", "tieredPricing", "payAxis", "vivpos", "chammpGift", "chammpRewards", "multiCurrencyToggle", "virtualTerminalToggle", "chammpCheckout", "chammpUpdater", "chammpTitan"])
          this.$set(this.currentTemplate, opt, this.ctCheckboxes.find(a=>a==opt));
      }, deep: true,
    },
    currentTemplate: {
      immediate: true,
      handler() {
        if (this.currentTemplate && !this.currentTemplate.pricingModules)
          this.currentTemplate.pricingModules={};
        if (this.currentTemplate && !this.currentTemplate.pricingOptions)
          this.currentTemplate.pricingOptions={};
        if (this.currentTemplate && !this.currentTemplate.methods)
          this.currentTemplate.methods=[];
        for(var opt of ["monthlyPricing", "dailyPricing", "flatRatePricing", "interchangePricing", "tieredPricing", "payAxis", "vivpos", "chammpGift", "chammpRewards", "multiCurrencyToggle", "virtualTerminalToggle", "chammpCheckout", "chammpUpdater", "chammpTitan"])
          if (this.currentTemplate[opt])
            this.ctCheckboxes.push(opt);
        this.loadMethods();
      },
    },
  },
  computed: {
    cardSel() {
      var result={
        any: !!this.currentTemplate.methods.length,
        visa: this.currentTemplate.methods.indexOf('Visa')!=-1,
        mc: this.currentTemplate.methods.indexOf('Mastercard')!=-1,
        discover: this.currentTemplate.methods.indexOf('Discover')!=-1,
        amex: this.currentTemplate.methods.indexOf('AMEX')!=-1,
        unionpay: this.currentTemplate.methods.indexOf('China UnionPay')!=-1,
        debit: this.currentTemplate.methods.indexOf('Debit')!=-1,
      };
      result.ccard=result.visa || result.mc || result.discover || result.unionpay;
      return result;
    },
    cardOpts() {
      return ["Visa", "Mastercard", "Discover", "AMEX", "JCB", "Dinners", "Debit"].filter(card=>this.hasM(card)).map(a=>({label: a, value: a}));
    },
    advPayOpts() {
      return ["Apple Pay", "Google Pay", "Samsung Pay", "Interac Online"].filter(card=>this.hasM(card)).map(a=>({label: a, value: a}));
    },
    bankClearingOpts() {
      return ["EFT", "EFT Credits", "ACH", "ACH Credits"].filter(card=>this.hasM(card)).map(a=>({label: a, value: a}));
    },
    modulesOpts() {
      var result=[];
      if (this.hasM("Gateway")) result.push({value: 'payAxis', label: 'Gateway'});
      if (this.hasM("Virtual Terminal")) result.push({value: 'vivpos', label: 'Virtual Terminal'});
      if (this.hasM("Gift")) result.push({value: 'chammpGift', label: 'Gift'});
      if (this.hasM("Gift & Loyality")) result.push({value: 'chammpRewards', label: 'Gift & Loyality'});
      return result;
    },
    accountOpts() {
      var result=[];
      if (this.hasM('Multi-Currency')) result.push({value: 'multiCurrencyToggle', label: 'Multi-Currency'});
      if (this.hasM('Subscription Billing')) result.push({value: 'virtualTerminalToggle', label: 'Subscription Billing'});
      if (this.hasM('Mailed Statements')) result.push({value: 'chammpCheckout', label: 'Mailed Statements'});
      if (this.hasM('Auto Updater')) result.push({value: 'chammpUpdater', label: 'Auto Updater'});
      if (this.hasM('Titan')) result.push({value: 'chammpTitan', label: 'Titan'});
      return result;
    },
  },
  methods: {
    async loadMethods() {
      var data=await api.get("Solution", this.currentTemplate.solution || this.solution);
      if (data.item) {
        this.methodOptions={
          enabledMethods: data.item.enabledMethods || [],
          allMethods: data.item.allMethods || [],
          allModules: data.item.allModules || [],
        };
      }
    },
    hasM(method) {
      return this.methodOptions.enabledMethods.indexOf(method)!=-1;
    },
  },
  mounted: async function() {
    this.loadMethods();
    var modPromise=api.list("CompanyModule", {});
    var optPromise=api.list("CompanyOption", {});
    this.modules=(await modPromise).items;
    this.options=(await optPromise).items;
  },
}
</script>
