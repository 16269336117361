<template>
<div :class="{grbackground: tab > 0 && bg }">
  <form class="preq-form preq-view" v-if="tab === 0">
    <section>
      <ul class="appview-list column6">
        <li>
          <label>Created</label>
          <div class="validator">
            <span>{{ formatDate(customer.createdAt, 'YYYY-MM-DD') }}</span>
          </div>
        </li>
        <li>
          <label>Solution</label>
          <div class="validator">
            <span>{{ solution.nameSolution }}</span>
          </div>
        </li>
        <li>
          <label>Type</label>
          <div class="validator">
            <span>{{ customer.accountType }}</span>
          </div>
        </li>
        <li>
          <label>Profile ID</label>
          <div class="validator">
            <span v-if="isPersonal">{{ customer.personal.profileNumber  }}</span>
            <span v-else>{{ customer.business.profileNumber  }}</span>
          </div>
        </li>
        <li>
          <label>Partner</label>
          <div class="validator">
            <span v-if="partner && partner.agentDb">{{ partner.agentDb.partnerId }} - {{ partner.agentDb.name }}</span>
          </div>
        </li>
        <li>
          <label>Language</label>
          <div class="validator">
            <dyn-select v-if="editMode" :options="languages" v-model="customer.language" />
            <span v-else>{{ customer.language }}</span>
          </div>
        </li>
      </ul>
    </section>
    <div class="form-actions" v-if="editMode">
      <button class="btn btn-large" @click.prevent="saveItem">Save</button>
    </div>
  </form>
  <form class="preq-form preq-view" v-if="tab === 1">
    <section>
      <ul class="appview-list">
        <li>
          <label>Legal Name</label>
          <div class="validator">
            <input type="text" v-if="editMode" v-model="customer.business.companyName" />
            <span v-else>{{ customer.business.companyName }}</span>
          </div>
        </li>
        <li>
          <label>DBA</label>
          <div class="validator">
            <input type="text" v-if="editMode" v-model="customer.business.companyNameTrade" />
            <span v-else>{{ customer.business.companyNameTrade }}</span>
          </div>
        </li>
        <li>
          <label>Type</label>
          <div class="validator">
            <input type="text" v-if="editMode" v-model="customer.business.companyType" />
            <span v-else>{{ customer.business.companyType }}</span>
          </div>
        </li>
        <li>
          <label>Tax ID</label>
          <div class="validator">
            <input type="text" v-if="editMode" v-model="customer.business.companyTaxId" />
            <span v-else>{{ customer.business.companyTaxId }}</span>
          </div>
        </li>
        <li>
          <label>Incorporation Date</label>
          <div class="validator">
            <input type="text" v-if="editMode" v-model="customer.business.companyDate" />
            <span v-else>{{ customer.business.companyDate }}</span>
          </div>
        </li>
        <li>
          <label>Address</label>
          <div class="validator">
            <input type="text" v-if="editMode" v-model="customer.business.companyAddress" />
            <span v-else>{{ customer.business.companyAddress }}</span>
          </div>
        </li>
        <li>
          <label>City</label>
          <div class="validator">
            <input type="text" v-if="editMode" v-model="customer.business.companyAddressCity" />
            <span v-else>{{ customer.business.companyAddressCity }}</span>
          </div>
        </li>
        <li>
          <label v-if="simplified">Province/State</label>
          <label v-else>Province/State of Formation</label>
          <div class="validator">
            <template v-if="editMode">
              <province-select v-if="customer.business.companyAddressCountry=='CA' || customer.business.companyAddressCountry=='US'" :value="customer.business.companyAddressState" v-model="customer.business.companyAddressState" :country="customer.business.companyAddressCountry"  />
              <input type="text" v-else v-model="customer.business.companyAddressState" />
            </template>
            <span v-else>{{ customer.business.companyAddressState }}</span>
          </div>
        </li>
        <li>
          <label>Postal/Zip Code</label>
          <div class="validator">
            <input type="text" v-if="editMode" v-model="customer.business.companyAddressPostcode" />
            <span v-else>{{ customer.business.companyAddressPostcode }}</span>
          </div>
        </li>
        <li>
          <label v-if="simplified">Country</label>
          <label v-else>Country of Formation</label>
          <div class="validator">
            <dyn-select v-if="editMode" :options="validCountries" v-model="customer.business.companyAddressCountry" />
            <span v-else>{{ customer.business.companyAddressCountry }}</span>
          </div>
        </li>
        <li>
          <label>Email</label>
          <div class="validator">
            <input type="text" v-if="editMode" v-model="customer.business.companyEmail" />
            <span v-else>{{ customer.business.companyEmail }}</span>
          </div>
        </li>
        <li>
          <label>Phone</label>
          <div class="validator">
            <input type="text" v-if="editMode" v-model="customer.business.companyPhone" />
            <span v-else>{{ customer.business.companyPhone }}</span>
          </div>
        </li>
        <li>
          <label>Website</label>
          <div class="validator">
            <input type="text" v-if="editMode" v-model="customer.business.companyWebsite" />
            <span v-else>{{ customer.business.companyWebsite }}</span>
          </div>
        </li>
      </ul>
    </section>
    <div class="form-actions" v-if="editMode">
      <button class="btn btn-large" @click.prevent="saveItem">Save</button>
    </div>
  </form>
  <form v-if="tab === 2" class="preq-form preq-view">
    <template v-if="isPersonal">
      <section class="contact-form">
        <ul class="appview-list">
          <li>
            <label>First Name</label>
            <div class="validator">
              <input type="text" v-if="editMode" v-model="customer.personal.firstName" />
              <span v-else>{{ customer.personal.firstName }}</span>
            </div>
          </li>
          <li>
            <label>Last Name</label>
            <div class="validator">
              <input type="text" v-if="editMode" v-model="customer.personal.lastName" />
              <span v-else>{{ customer.personal.lastName }}</span>
            </div>
          </li>
          <li>
            <label>Sex</label>
            <div class="validator">
              <input type="text" v-if="editMode" v-model="customer.personal.sex" />
              <span v-else>{{ customer.personal.sex }}</span>
            </div>
          </li>
          <li>
            <label>Date of Birth</label>
            <div class="validator">
              <input type="text" v-if="editMode" v-model="customer.personal.dob" />
              <span v-else>{{ formatDate(customer.personal.dob, 'YYYY-MM-DD') }}</span>
            </div>
          </li>
          <li>
            <label>SIN/SSN</label>
            <div class="validator">
              <input type="text" v-if="editMode" v-model="customer.personal.ssn" />
              <span v-else>{{ customer.personal.ssn }}</span>
            </div>
          </li>
          <li>
            <label>Address</label>
            <div class="validator">
              <input type="text" v-if="editMode" v-model="customer.personal.address" />
              <span v-else>{{ customer.personal.address }}</span>
            </div>
          </li>
          <li>
            <label>City</label>
            <div class="validator">
              <input type="text" v-if="editMode" v-model="customer.personal.addressCity" />
              <span v-else>{{ customer.personal.addressCity }}</span>
            </div>
          </li>
          <li>
            <label>PROVINCE/STATE</label>
            <div class="validator">
              <template v-if="editMode">
                <province-select v-if="customer.personal.addressCountry=='CA' || customer.personal.addressCountry=='US'" :value="customer.personal.addressProvince" v-model="customer.personal.addressProvince" :country="customer.personal.addressCountry"  />
                <input type="text" v-else v-model="customer.personal.addressProvince" />
              </template>
              <span v-else>{{ customer.personal.addressProvince }}</span>
            </div>
          </li>
          <li>
            <label>POSTAL/ZIP CODE</label>
            <div class="validator">
              <input type="text" v-if="editMode" v-model="customer.personal.addressPostcode" />
              <span v-else>{{ customer.personal.addressPostcode }}</span>
            </div>
          </li>
          <li>
            <label>COUNTRY</label>
            <div class="validator">
              <dyn-select v-if="editMode" :options="validContactCountries" v-model="customer.personal.addressCountry" />
              <span v-else>{{ customer.personal.addressCountry }}</span>
            </div>
          </li>
          <li>
            <label>EMAIL</label>
            <div class="validator">
              <input type="text" v-if="editMode" v-model="customer.personal.email" />
              <span v-else>{{ customer.personal.email }}</span>
            </div>
          </li>
          <li>
            <label>PHONE</label>
            <div class="validator">
              <input type="text" v-if="editMode" v-model="customer.personal.phone" />
              <span v-else>{{ customer.personal.phone }}</span>
            </div>
          </li>
          <li>
            <label>MOBILE</label>
            <div class="validator">
              <input type="text" v-if="editMode" v-model="customer.personal.phone2" />
              <span v-else>{{ customer.personal.phone2 }}</span>
            </div>
          </li>
        </ul>
      </section>
    </template>
    <template v-else>
      <section v-for="(contact, index) in customer.business.contacts" :key="index" class="contact-form">
        <ul class="appview-list">
          <li>
            <label>First Name</label>
            <div class="validator">
              <input type="text" v-if="editMode" v-model="contact.firstName" />
              <span v-else>{{ contact.firstName }}</span>
            </div>
          </li>
          <li>
            <label>Last Name</label>
            <div class="validator">
              <input type="text" v-if="editMode" v-model="contact.lastName" />
              <span v-else>{{ contact.lastName }}</span>
            </div>
          </li>
          <li>
            <label>Sex</label>
            <div class="validator">
              <input type="text" v-if="editMode" v-model="contact.sex" />
              <span v-else>{{ contact.sex }}</span>
            </div>
          </li>
          <li>
            <label>Date of Birth</label>
            <div class="validator">
              <input type="text" v-if="editMode" v-model="contact.dob" />
              <span v-else>{{ contact.dob }}</span>
            </div>
          </li>
          <li>
            <label>SIN/SSN</label>
            <div class="validator">
              <input type="text" v-if="editMode" v-model="contact.ssn" />
              <span v-else>{{ contact.ssn }}</span>
            </div>
          </li>
          <li>
            <label>Address</label>
            <div class="validator">
              <input type="text" v-if="editMode" v-model="contact.address" />
              <span v-else>{{ contact.address }}</span>
            </div>
          </li>
          <li>
            <label>City</label>
            <div class="validator">
              <input type="text" v-if="editMode" v-model="contact.addressCity" />
              <span v-else>{{ contact.addressCity }}</span>
            </div>
          </li>
          <li>
            <label>PROVINCE/STATE</label>
            <div class="validator">
              <template v-if="editMode">
                <province-select v-if="contact.addressCountry=='CA' || contact.addressCountry=='US'" :value="contact.addressProvince" v-model="contact.addressProvince" :country="contact.addressCountry"  />
                <input type="text" v-else v-model="contact.addressProvince" />
              </template>
              <span v-else>{{ contact.addressProvince }}</span>
            </div>
          </li>
          <li>
            <label>POSTAL/ZIP CODE</label>
            <div class="validator">
              <input type="text" v-if="editMode" v-model="contact.addressPostcode" />
              <span v-else>{{ contact.addressPostcode }}</span>
            </div>
          </li>
          <li>
            <label>COUNTRY</label>
            <div class="validator">
              <dyn-select v-if="editMode" :options="validContactCountries" v-model="contact.addressCountry" />
              <span v-else>{{ contact.addressCountry }}</span>
            </div>
          </li>
          <li>
            <label>EMAIL</label>
            <div class="validator">
              <input type="text" v-if="editMode" v-model="contact.email" />
              <span v-else>{{ contact.email }}</span>
            </div>
          </li>
          <li>
            <label>PHONE</label>
            <div class="validator">
              <input type="text" v-if="editMode" v-model="contact.phone" />
              <span v-else>{{ contact.phone }}</span>
            </div>
          </li>
          <li>
            <label>MOBILE</label>
            <div class="validator">
              <input type="text" v-if="editMode" v-model="contact.mobile" />
              <span v-else>{{ contact.mobile }}</span>
            </div>
          </li>
          <li>
            <label>Contact Type</label>
            <div class="validator">
              <input type="text" v-if="editMode" v-model="contact.conctactType" />
              <span v-else>{{ contact.conctactType }}</span>
            </div>
          </li>
          <li>
            <label>Ownership %</label>
            <div class="validator">
              <input type="text" v-if="editMode" v-model="contact.ownership" />
              <span v-else>{{ contact.ownership }}</span>
            </div>
          </li>
        </ul>
      </section>
    </template>
   
    <div class="form-actions" v-if="editMode">
      <button class="btn btn-large" @click.prevent="saveItem()">Save</button>
    </div>
  </form>

  <portal to="actions">
    <ul>
      <template v-if="tab != 999">
        <li v-if="edit ">
          <a href="" @click.prevent="$emit('update:editMode', true)">Edit</a>
        </li>
      </template>
      <template v-if="tab === 999">
        <template v-if="customer.accountType == 'Personal'">
          <li v-for="(optLabel, optMethod) in statusActions" :class="{disabled: activeStatuses.includes(customer.personal.status)}">
            <a href="" v-on:click.prevent="$parent.$parent.doAction(optMethod)" :title="optLabel">{{ optMethod }}</a>
          </li>
        </template>
        <template v-if="customer.accountType == 'Business'">
          <li v-for="(optLabel, optMethod) in statusActions" :class="{disabled: activeStatuses.includes(customer.business.status)}">
            <a href="" v-on:click.prevent="$parent.$parent.doAction(optMethod)" :title="optLabel">{{ optMethod }}</a>
          </li>
        </template>
      </template>
    </ul>
  </portal>
</div>
</template>

<script>
export default {
  mixins: [vueMixins.dateFormatter],

  props: {
    customer: {
      type: Object,
      required: true
    },

    notes: {
      type: Array,
      required: true
    },

    tab: {
      type: Number,
      required: true
    },

    editMode: {
      type: Boolean,
      default: false
    },

    edit: {
      type: Object,
      default: null
    },

    bg: {
      type: Boolean,
      default: false
    },

    columns: {
      type: Number,
      default: 2
    },

    simplified: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      allActions: appData.allActions,
      solution: null,
      statusActions: {
        "A08": "Active",
        "A09": "Review",
        "A10": "Restricted",
        "A11": "Dormant",
        "A12": "Closed",
      },
      activeStatuses: [
        "A02",
        "A03",
        "A04",
        "A05",
      ],
      partner: null,
    }
  },

  computed: {
    isPersonal () {
      return this.customer.accountType == 'Personal'
    },

    languages() {
      var options=[{
        value: null, label: ""
      }];
      if(this.systemSettings.language) {
        for(var i = 0; i < this.systemSettings.language.length; i++){
          options.push(
            {
              value: this.systemSettings.language[i],
              label: this.systemSettings.language[i],
            }
          )
        };
      }
       
      return options;
    },

    validCountries() {
      var countries = [];
      var lists = [];
      if(this.solution.businessFormation) {
        lists = window.fullCountries.filter(a => {
          return this.solution.businessFormation.includes(a.id)
        })
      }

      lists.forEach(item => {
        countries.push({
          label: item.text,
          value: item.id
        })
      })

      return countries
    },

    validContactCountries() {
      var countries = [];
      var lists = [];
      if(this.solution.contacts) {
        lists = window.fullCountries.filter(a => {
          return this.solution.contacts.includes(a.id)
        })
      }

      lists.forEach(item => {
        countries.push({
          label: item.text,
          value: item.id
        })
      })

      return countries
    }
  },

  mounted() {
    this.loadSolution();
    this.loadPartners();
  },

  methods: {
    async loadSolution () {
      if(this.customer.accountType == 'Personal')
        this.solution = (await api.get("Solution", this.customer.personal.solution)).item
      if(this.customer.accountType == 'Business')
        this.solution = (await api.get("Solution", this.customer.business.solution)).item
    },
    async loadPartners () {
      this.partner=null;
      if (this.customer.source)
        this.partner=(await api.get("User", this.customer.source)).item;
    },

    saveItem: async function() {
      // if (!this.validate())
      //   return stopLoading("Please fill all required fields", "error");    
      startLoading();
      
      await api.update("TreasuryAccount", this.customer.id, this.customer);
      stopLoading("Customer updated");
      this.$emit("update:editMode", false);
      var resItem=(await api.get("TreasuryAccount", this.customer.id)).item;
      this.$parent.customer=resItem;
    },
  },

  async created () {
    this.systemSettings=(await api.get("Settings", 1)).item;
  },
}
</script>

<style lang="scss" scoped>
  .column6 li{
    width: 16%;
  }

  .contact-form + .contact-form {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid rgba(0,0,0,.1);
  }
</style>
