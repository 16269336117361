<template>
  <div class="popper">
    <Teleport v-if="open" to="body">
      <div class="popper-content" :style="positionStyle" @click.stop>
        <slot />
      </div>
    </Teleport>

    <span ref="reference" @click="onToggle()">
      <slot name="reference" />
    </span>
  </div>
</template>

<script>
import Teleport from "vue2-teleport";

export default {
  props: {
    position: {
      type: String,
      default: "bottom",
      validator: (value) =>
        [
          "top",
          "top-left",
          "top-right",
          "bottom",
          "bottom-left",
          "bottom-right",
          "left",
          "left-top",
          "left-bottom",
          "right",
          "right-top",
          "right-bottom",
        ].includes(value),
    },

    offset: {
      type: Object,
      default: null,
    },
  },

  components: {
    Teleport,
  },

  data() {
    return {
      skipClose: false,
      open: false,
      mainOffset: null,
    };
  },

  computed: {
    transformX() {
      if (["top", "bottom"].includes(this.position)) return "-50%";
      if (
        [
          "left",
          "top-right",
          "bottom-right",
          "left-top",
          "left-bottom",
        ].includes(this.position)
      )
        return "-100%";
      return "0";
    },

    transformY() {
      if (["left", "right"].includes(this.position)) return "-50%";
      if (["top", "left-bottom", "right-bottom"].includes(this.position))
        return "-100%";
      return "0";
    },

    posTop() {
      if (["left-bottom", "right-bottom"].includes(this.position)) {
        return (
          this.mainOffset.top + this.mainOffset.height - (this.offset?.y || 0)
        );
      }

      if (["left", "right"].includes(this.position)) {
        return this.mainOffset.top + this.mainOffset.height / 2;
      }

      if (["bottom", "bottom-left", "bottom-right"].includes(this.position)) {
        return (
          this.mainOffset.top + this.mainOffset.height + (this.offset?.y || 0)
        );
      }

      if (["top", "top-left", "top-right"].includes(this.position)) {
        return this.mainOffset.top - (this.offset?.y || 0);
      }

      return this.mainOffset.top + (this.offset?.y || 0);
    },

    posLeft() {
      if (["top-left", "bottom-left"].includes(this.position)) {
        return (
          this.mainOffset.left + this.mainOffset.width - (this.offset?.x || 0)
        );
      }

      if (["top", "bottom"].includes(this.position)) {
        return this.mainOffset.left + this.mainOffset.width / 2;
      }

      if (["left", "left-top", "left-bottom"].includes(this.position)) {
        return this.mainOffset.left - (this.offset?.x || 0);
      }

      if (["right", "right-top", "right-bottom"].includes(this.position)) {
        return (
          this.mainOffset.left + this.mainOffset.width + (this.offset?.x || 0)
        );
      }
      return this.mainOffset.left + (this.offset?.x || 0);
    },

    positionStyle() {
      const posTop = this.posTop < 0 ? 0 + (this.offset?.y || 0) : this.posTop;
      const posLeft =
        this.posLeft < 0 ? 0 + (this.offset?.x || 0) : this.posLeft;
      return {
        top: posTop + "px",
        left: posLeft + "px",
        transform: `translate(${this.transformX}, ${this.transformY})`,
      };
    },
  },

  mounted() {
    document.body.addEventListener("click", this.close);
    this.getMainOffset();
  },

  methods: {
    getMainOffset() {
      if (this.$refs.reference) {
        this.mainOffset = this.getOffset(this.$refs.reference);
      }

      return null;
    },

    onToggle() {
      this.getMainOffset();
      this.skipClose = true;
      this.open = !this.open;
    },

    close() {
      if (this.skipClose) {
        this.skipClose = false;
        return;
      }
      this.open = false;
    },

    getOffset(el) {
      const rect = el.getBoundingClientRect();

      return {
        width: el.offsetWidth,
        height: el.offsetHeight,
        left: rect.left + window.scrollX,
        top: rect.top + window.scrollY,
      };
    },
  },

  destroyed() {
    document.body.removeEventListener("click", this.close);
  },
};
</script>

<style lang="scss" scoped>
.popper {
}
</style>