<template>
<div>
  <popup v-model="show" @close="$emit('close')">
    <div style="padding: 10px 20px 20px 20px; box-sizing: border-box;">
      <div class="table-wrapper" >
        <table class="normal-table">
          <thead>
            <tr>
              <th v-for="(th, index) in headers" :key="index">{{ th }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in data" :key="index">
              <td>{{ row.SourceID.name }}</td>
              <td>{{ row.LeadType }}</td>
              <td>{{ row.MethodType }}</td>
              <td>{{ row.RecordId }}</td>
              <td>{{ row.BusinessIndustry }}</td>
              <td>{{ row.BusinessName }}</td>
              <td>{{ row.BusinessDBA }}</td>
              <td>{{ row.BusinessType }}</td>
              <td>{{ row.BusinessAddress }}</td>
              <td>{{ row.BusinessCity }}</td>
              <td>{{ row.BusinessProvState }}</td>
              <td>{{ row.BusinessPostalZip }}</td>
              <td>{{ row.BusinessCountry }}</td>
              <td>{{ row.BusinessPhone }}</td>
              <td>{{ row.BusinessEmail }}</td>
              <td>{{ row.BusinessWebite }}</td>
              <td>{{ row.BusinessDescription }}</td>
              <td>{{ row.ContactDate }}</td>
              <td>{{ row.Contact1FirstName }}</td>
              <td>{{ row.Contact1LastName }}</td>
              <td>{{ row.Contact1Email }}</td>
              <td>{{ row.Contact1Phone }}</td>
              <td>{{ row.Contact2FirstName }}</td>
              <td>{{ row.Contact2LastName }}</td>
              <td>{{ row.Contact2Email }}</td>
              <td>{{ row.Contact2Phone }}</td>
              <td>{{ row.VolMonthly }}</td>
              <td>{{ row.Vol12Months }}</td>
              <td>{{ row.HighTicket }}</td>
              <td>{{ row.AvgTicket }}</td>
              <td>{{ row.LowTicket }}</td>
              <td>{{ row.CurrentlyAccept }}</td>
              <td>{{ row.EverAccept }}</td>
              <td>{{ row.TransCurr }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </popup>
</div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      required: true
    }
  },

  data () {
    return {
      show: true,
      headers: ['SourceID', 'LeadType', 'Method Type', 'Industry', 'RecordID', 'BusinessName', 'BusinessDBA', 'BusinessType', 'BusinessAddress', 'BusinessCity', 'BusinessProvince', 'BusinessPostal', 'BusinessCountry', 'BusinessPhone', 'BusinessEmail', 'BusinessWeb', 'BusinessDesc', 'ContactDate', 'Contact1FName', 'Contact1LName', 'Contact1Email', 'Contact1Phone', 'Contact2FName', 'Contact2LName', 'Contact2Email', 'Contact2Phone', 'MthlyVol', 'Vol12Mth', 'HighTicket', 'AvgTicket', 'LowTicket', 'CurrentlyAcc', 'EverAcc', 'TransCurr']
    }
  }
}
</script>
