<template>
  <div>
    <p>Submission</p>
    <br/>
    <ul class="appview-list">
      <li>
        <label>Provider</label>
        <div>
          {{ selectedProvider? selectedProvider.providerName : '' }}
        </div>
      </li>
      <li>
        <label>Label</label>
        <div>
          {{ selectedProvider? selectedProvider.label : '' }}
        </div>
      </li>
      <li>
        <label>IDV</label>
        <validator>
          <dyn-select :options="yesnoOptions"></dyn-select>
        </validator>
      </li>
      <li>
        <label>Date/Time</label>
        <div></div>
      </li>
      <li>
        <label>Account Number</label>
        <div></div>
      </li>
      <li>
        <label>Result</label>
        <div></div>
      </li>
    </ul>

    <p>Options</p>
    <br/>
    <div style="display: flex;">
      <button>Submit</button>
      <button>Documents</button>
    </div>
        
    <p>Messages</p>
    <br/>
    <div class="content-table-container file-list-container">
      <table class="file-list content-table">
        <thead>
          <tr>
            <td>Name</td>
            <td>AML</td>
            <td>CIP</td>
            <td>KYC</td>
            <td>Messages</td>
          </tr>
        </thead>
      </table>
    </div>
    <div class="actions">
      <button v-on:click.prevent="goBack">Back</button>
      <button disabled v-on:click.prevent="doNext">Save & Finalize</button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["item"],
  data: ()=>({
    providers: [],
  }),

  computed: {
    selectedProvider() {
      return this.providers.find(item => item.id == this.item.provider)
    },

    yesnoOptions() {
      var options = [
        {
          value: '',
          label: ''
        },
        {
          value: 1,
          label: 'Yes'
        },
        {
          value: 0,
          label: 'No'
        }
      ]
      return options;
    },
  },

  methods: {
    goBack() {
      this.$emit("back");
    },
    doNext() {
      this.$emit("next");
    },
  },

  async created() {
    var connexPromise=api.list("Provider", {providerType: 'Banking', status: ['!=', 'Archived']});
    this.providers=(await connexPromise).items;
  },
}
</script>
<style scoped>
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  margin-right: 8px;
  color: black;
  font-size: 16px;
  display: block;
  padding: 6px 20px;
  box-sizing: border-box;
  border: 1px solid #b3b3b3;
  border-radius: 6px;
  background: #e4e4e4;
  background: linear-gradient(to bottom, #e4e4e4 0%, #cfcfcf 100%);
}
button:hover, button:focus {
  background: #e9f2ff;
  background: linear-gradient(to bottom, #e9f2ff 0%, #deebff 100%);
  border-color: #6192fc;
  color: #6192fc;
}
.appview-list li {
  width :15%;
}
</style>
