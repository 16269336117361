<template>
<nav class="submenu">
  <!--<a v-if="back" href="/">
    <button class="back"><i /> Back to Main</button>
  </a>-->
  <a v-if="dashboard" :href="dashboard.to">
    <button class="back"><i /> {{ dashboard.text }}</button>
  </a>
  <ul class="menu">
    <li v-for="item in menu">
      <template v-if="item.list">
        <span> {{ item.text }} <i /></span>
        <i class="arrow-up" />
        <ul>
          <li v-for="subitem in item.list">
            <template v-if="subitem.settings">
              <template v-if="checkSettings(subitem.settings)">
                <a :href="subitem.to">{{ subitem.text }}</a>
              </template>
            </template>
            <a v-else :href="subitem.to">{{ subitem.text }}</a>
          </li>
        </ul>
      </template>
      <a v-else :href="item.to">{{ item.text }}</a>
    </li>
  </ul>
</nav>
</template>

<script>
export default {
  props: {
    back: {
      type: Boolean,
      default: true
    },

    dashboard: {
      type: Object,
      default: null
    },

    menu: {
      type: Array,
      required: true
    }
  },

  data: ()=>({
    settings: appData.settings,
  }),

  methods: {
    checkSettings(key) {
     if(this.settings.settings.general[key]) {
       return true
     }else{
       return false
     }
    },
  },
}
</script>
