<template>
<div>
  <scheduler-menu />

  <scheduler-dashboard />
</div>
</template>

<script>
export default {
  data () {
    return {
      currentPage: {
        text: 'Chammp Scheduler Dashboard'
      }
    }
  }
}
</script>
