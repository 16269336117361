<template>
  <div>
    <p>Approval</p>
    <br/>
    <view-agent-finalcheck :uwList="approvalList" :acParent="$parent.$parent.$parent" ref="finalCheck" v-on:final="$emit('next')" :skipAuto="true" />
    <div class="actions">
      <button v-on:click.prevent="goBack">Back</button>
      <button v-if="item.agentDb.status == 'A03'" v-on:click.prevent="doNext" :disabled="!$refs.finalCheck || !$refs.finalCheck.passed">Final Approval</button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["approvalList", "item"],
  data: ()=>({
  }),
  methods: {
    goBack() {
      this.$emit("back");
    },
    doNext() {
//       if (!this.approvalList || !this.approvalList.length)
//         return;
//       this.$emit("next");
      return this.$refs.finalCheck.passed && this.$refs.finalCheck.doFinal();
    },
  },
  mounted() {
  },
}
</script>
