<template>
  <form class="search-form">
    <h3>Search Options</h3>
    <div class="f2split">
      <div>
        <div class="field">
          <label>Date Range</label>
          <div class="field-group">
            <datepicker type="text" v-model="search.dateStart" />
            <datepicker type="text" v-model="search.dateEnd" />
          </div>
        </div>
        <div class="field">
          <label>Partners</label>
          <dyn-select v-model="search.source" :options="sourcesOpt" />
        </div>
        <div class="field" v-if="!followup">
          <label>Status</label>
          <dyn-select v-model="search.status" :options="statusesOpt" />
        </div>
      </div>
      <div>
        <div class="field">
          <label>Account ID</label>
          <input type="text" v-model="search.accountId" />
        </div>
        <div class="field">
          <label>Company Name</label>
          <input type="text" v-model="search.companyName" />
        </div>
        <div class="field">
          <label>Email</label>
          <input type="text" v-model="search.email" />
        </div>
        <div class="field">
          <label>Type</label>
          <dyn-select v-model="search.type" :options="types" />
        </div>
      </div>
    </div>
    <div class="f2split">
      <div class="buttons">
        <button
          class="btn btn-small btn-primary"
          v-on:click.prevent="updateResults"
        >
          Search
        </button>
        <button
          class="btn btn-small btn-default"
          v-on:click.prevent="resetResults"
        >
          Reset
        </button>
      </div>
      <div>&nbsp;</div>
    </div>
  </form>
</template>

<script>
export default {
  props: ["saved", "followup", "auxtype", "statuses"],
  data() {
    return {
      search: {
        dateStart: moment().subtract(7, "day").format("YYYY-MM-DD"),
        dateEnd: moment().format("YYYY-MM-DD"),
        source: "",
        status: "",
        accountId: "",
        companyName: "",
        email: "",
      },
      sources: [],
      types: [
        { value: null, label: "" },
        { value: "mastermerchants", label: "Payment Facilitator" },
        { value: "merchants", label: "Merchant" },
        { value: "submerchants", label: "Sub-Merchant" },
      ],
    };
  },
  computed: {
    statusesOpt() {
      var result = [{ value: "", label: "" }];
      for (var i in this.statuses)
        result.push({
          value: this.statuses[i].status,
          label: this.statuses[i].status + " - " + this.statuses[i].description,
        });
      return result;
    },
    sourcesOpt() {
      var result = [{ value: "", label: "" }];
      for (var i in this.sources)
        result.push({
          value: this.sources[i].id,
          label:
            this.sources[i].agentId +
            " - " +
            (this.sources[i].agentDb
              ? this.sources[i].agentDb.name
              : this.sources[i].fullName + " " + this.sources[i].lastName),
        });
      return result;
    },
  },
  methods: {
    async updateResults() {
      var q = {};
      q.status = ["LIKE", "A%"];
      //q.auxType=this.auxtype || "null";
      //if (this.followup) {
      //  q.followup=["IS NOT NULL"];
      //}
      if (this.search.accountId) {
        q.accountId = ["LIKE", "%" + this.search.accountId + "%"];
      }
      if (appData.currentUser.agentId) q.source = appData.currentUser.id;
      if (this.search.dateStart)
        q.dateStart = [
          ">=",
          this.search.dateStart,
          this.saved ? "createdDate" : "submitDate",
        ];
      if (this.search.dateEnd)
        q.dateEnd = [
          "<=",
          this.search.dateEnd + " 23:59:59",
          this.saved ? "createdDate" : "submitDate",
        ];
      if (this.search.source) q.source = this.search.source;
      if (this.search.companyName)
        q.companyName = ["LIKE", "%" + this.search.companyName + "%"];
      if (this.search.email)
        q.companyEmail = ["LIKE", "%" + this.search.email + "%"];
      if (this.search.status) q.status = this.search.status;
      if (this.search.type == "facilitators") q.auxType = "Facilitator";
      if (this.search.type == "merchants") q.auxType = "null";
      if (this.search.type == "submerchants") q.auxType = "Sub";

      var that = this;
      var appList = api.list("Application", q, ["id:ASC"]);
      var preqList = [];
      if (q.auxType == "null" || !q.auxType) {
        delete q.accountId;
        delete q.auxType;
        if (this.search.accountId) {
          q.id = ["LIKE", "%" + this.search.accountId + "%"];
        }
        if (this.saved && !this.followup)
          preqList = api.list("Prequalification", q, ["id:ASC"]);
      } else {
        preqList = { items: [] };
      }

      var data = await appList;
      var data2 = await preqList;
      var items = [];
      for (var i in data.items) {
        var item = data.items[i];
        item.type = "Application";
        items.push(item);
      }
      for (var i in data2.items) {
        var item = data2.items[i];
        item.type = "Prequalification";
        items.push(item);
      }
      accordeonResults.items = items;
      accordeonResults.search = this.search;
      breakdownTable.items = items;
      breakdownTable.search = this.search;
    },

    async resetResults() {
      var q = {};
      q.status = ["LIKE", "A%"];
      this.search = {
        dateStart: "",
        dateEnd: "",
        source: "",
        status: "",
        accountId: "",
        companyName: "",
        email: "",
      };

      var that = this;
      var appList = api.list("Application", q, ["id:ASC"]);
      var preqList = [];
      if (q.auxType == "null" || !q.auxType) {
        delete q.auxType;
        if (this.saved && !this.followup)
          preqList = api.list("Prequalification", q, ["id:ASC"]);
      } else {
        preqList = { items: [] };
      }

      var data = await appList;
      var data2 = await preqList;
      var items = [];
      for (var i in data.items) {
        var item = data.items[i];
        item.type = "Application";
        items.push(item);
      }
      for (var i in data2.items) {
        var item = data2.items[i];
        item.type = "Prequalification";
        items.push(item);
      }
      accordeonResults.items = items;
      accordeonResults.search = this.search;
      breakdownTable.items = items;
      breakdownTable.search = this.search;
    },
  },
  mounted() {
    this.sources = appData.sources
      ? JSON.parse(JSON.stringify(appData.sources))
      : [];
    if (this.followup || true) {
      this.search.dateStart = null;
      this.search.dateEnd = null;
    }
    this.updateResults();
  },
};
</script>
