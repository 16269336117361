<template>
<div>
  <page-header title="Employees" :breadcrumbs="breadcrumbs" />

  <sub-menu :back="true" :menu="menu" />
</div>
</template>

<script>
export default {
  data () {
    return {
      breadcrumbs: [
        {
          text: 'System',
        },
        {
          text: 'Employees'
        }
      ],

      menu: [
//         {
//           text: 'Overview',
//           list: [
//             {
//               text: 'Employee Overview',
//               to: '/employees'
//             }
//           ]
//         },
//         {
//           text: 'Manage',
//           list: [
//             {
//               text: 'Add New',
//               to: '/employees/add-new'
//             }
//           ]
//         },
//         {
//           text: 'Menu Nav 1',
//           list: [
//             {
//               text: 'Item 1',
//               to: '/employees'
//             },
//             {
//               text: 'Item 2',
//               to: '/employees'
//             }
//           ]
//         },
//         {
//           text: 'Menu Nav 2',
//           list: [
//             {
//               text: 'Item 1',
//               to: '/employees'
//             },
//             {
//               text: 'Item 2',
//               to: '/employees'
//             }
//           ]
//         }
      ]
    }
  }
}
</script>
