<template>
<section>
  <ul class="appview-list">
    <li>
      <label>Portal</label>
      <div class="validator">
        <validator v-if="editMode" :value="form.portal" reqs="required" class="inline-block">
          <select v-model="form.portal">
            <option v-for="(opt, key) in portalOptions" :key="key" :value="key">{{ opt }}</option>
          </select>
        </validator>
        <span v-else>{{ portal }}</span>
      </div>
    </li>
    <li>
      <label>Username</label>
      <div class="validator">
        <span>{{ employee.username }}</span>
      </div>
    </li>
  </ul>

  <portal to="actions">
    <ul>
      <li>
        <a href="" v-on:click.prevent="$emit('update:editMode', true)">Edit</a>
      </li>
      <li :class="{disabled: !isPending}">
        <a href="" v-on:click.prevent="isPending && sendWelcomeEmail()">Welcome Email</a>
      </li>
      <li :class="{disabled: isPending}">
        <a href="" v-on:click.prevent="!isPending && resetPass()">Reset Pass</a>
      </li>
      <li v-if="employee.userId.active !== 'Blocked'">
        <a href="" v-on:click.prevent="block()">Block</a>
      </li>
      <li v-else>
        <a href="" v-on:click.prevent="unblock()">Unblock</a>
      </li>
    </ul>
  </portal>
  <div v-if="false">
    <button type="button" :disabled="!isPending" class="btn btn-xs" @click="sendWelcomeEmail()">Welcome Email</button>
    <button type="button" :disabled="isPending" class="btn btn-xs" @click="resetPass()">Reset Pass</button>
    <button type="button" v-if="employee.userId.active !== 'Blocked'" class="btn btn-xs" @click="block()">Block</button>
    <button type="button" v-else class="btn btn-xs" @click="unblock()">Unblock</button>
  </div>
</section>
</template>

<script>
export default {
  props: {
    employee: {
      type: Object,
      required: true
    },

    value: {
      type: Object,
      required: true
    },

    editMode: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    form: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },

    portalOptions () {
      return window.employeePortals
    },

    userId () {
      return this.employee.userId.id
    },

    isPending () {
      return this.employee.status === 'PENDING'
    },

    portal () {
      return window.employeePortals[this.employee.portal]
    }
  },

  methods: {
    async resetPass () {
      startLoading()
//       await api.update("User", this.userId, { passChange: 1 })
      $.post("/login/forgotpw", {
        username: this.employee.userId.username,
        accountId: this.employee.employeeId
      }, (data)=>{
        stopLoading('Link to reset password is sent to employees email.')
      }, "json");

    },

    async sendWelcomeEmail () {
      startLoading()
      await api.action("User", this.userId, "WelcomeEmailPassword")
      stopLoading('Welcome Email sent successfully.')
    },

    async block () {
      startLoading()
      await api.update('User', this.userId, { active: 'Blocked' })
      this.$emit('active', 'Blocked')
      stopLoading('User Blocked successfully.')
    },
    
    async unblock () {
      startLoading()
      await api.update('User', this.userId, { active: 'Yes' })
      this.$emit('active', 'Yes')
      stopLoading('User Blocked successfully.')
    }
  },
}
</script>
