<template>
  <div class="content-table-container file-list-container">
    <table class="file-list content-table">
      <thead>
        <tr>
          <td>Created</td>
          <td>Description</td>
          <td>Number</td>
          <td>Filename</td>
          <td>Status</td>
          <td>Options</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="document in item.agentDb.documents" :key="document.number">
            <td>{{ formatDate(document.created, "YYYY-MM-DD") }}</td>
            <td>{{ document.description }}</td>
            <td>{{ document.number }}</td>
            <td>
              <a :href="fileLink(document.filename, document.id)" target="_blank">{{ document.filename || "(no file)" }}</a>
            </td>
            <td>{{ document.status }}</td>
            <td>
              <template v-if="document.status == 'Pending'">
                <span @click="updateOk(document)" class="option-btn">
                  <img src="/assets/img/table-approve.png" />
                </span>
                <span @click="update(document)" class="option-btn">
                  <img src="/assets/img/table-deny.png" />
                </span>
                <span @click="updateArchived(document)" class="option-btn">
                  DEL
                </span> 
              </template>
            </td>
          </tr>
      </tbody>
    </table>

    <portal to="actions">
      <ul>
        <li>
          <a href="" v-on:click.prevent="newFile=true">New File</a>
        </li>
      </ul>
    </portal>

    <popup :open.sync="newFile">
      <partner-document-popup v-if="newFile" v-model="item" :newFile.sync="newFile" v-on:reloadnotes="$emit('reloadnotes')" />
    </popup>

    <popup :open.sync="statusPopup">
      <div class="static-page privacy-popup">
        <h2><strong>Update Document Status</strong></h2>
        <br/>
        <div class="content-form">
          <div class="form-row form-fullw">
            <form-field-select :validator="$v.selectedStatus" v-model="selectedStatus" :label="`Select Document Status`" required :options="statusOptions" />
            <p>{{ selectedText }}</p>
          </div>
          <br/>
          <br/>
          <div class="form-row form-split">
            <div>
              <button :disabled="!selectedStatus" @click="submit()">Submit</button>
            </div>
            <div>
              <button @click="statusPopup = false, selectedStatus = null">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </popup>
  </div>

</template>

<script>
import { required } from 'vuelidate/lib/validators'
export default {
  props: ["item"],
  data: ()=>({
    newFile: false,
    statusPopup: false,
    statuses: [],
    selectedStatus: null,
  }),
  computed: {
    statusOptions() {
      var options = [];
      for(const status of this.statuses)
        options.push(
          {
            id: `${status.status}`,
            text: `${status.status} - ${status.description.substr(0, 50)}`
          }
        )
      return options
    },

    selectedText() {
      return this.statuses.find(item =>  item.status == this.selectedStatus)?.description
    }
  },

  async created() {
    this.statuses = (await api.list('Status', {category: 'Compliance'})).items
  },

  methods: {
    fileLink(fileName, docId) {
      if (!fileName)
        return null;
      if (docId)
        return "download/PartnerDocument/"+docId;
      return "files/agent/"+this.item.agentDb.id+"/"+fileName;
    },

    async updateOk(document) {
      await api.update("PartnerDocument", document.id, {
            "status": 'Ok'
          });
      document.status = 'Ok'
      await this.createNote(document)
    },

    async updateArchived(document) {
      await api.update("PartnerDocument", document.id, {
            "status": 'Archived'
          });
      document.status = 'Archived'
      await this.createNote(document)
    },

    async update(document) {
     this.selectedDocument = document
     this.statusPopup = true
    },

    async submit () { 
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$toast.error("You need to fill all required fields. We marked them with red color so that you know what fields you have missed.")
        return
      }
      startLoading()
      try {
        await api.update("PartnerDocument", this.selectedDocument.id, {
            "status": this.selectedStatus
          });
        this.selectedDocument.status = this.selectedStatus
        this.statusPopup = false
        this.selectedStatus = null
        await this.createNote(this.selectedDocument)
        stopLoading("")
      } catch (e) {
        stopLoading(e, 'error')
      }
    },

    async createNote(document) {
      const note = (await api.create("Note", {
        message: `Updated document ${document.description}, Pending to ${document.status}`,
        parentId: this.item.agentDb.id,
        type: 'Partner',
        created: "CURRENT_TIMESTAMP",
        author: appData.currentUser.id,
      }, true)).item
      await this.$emit("reloadnotes")
    }
  },

  validations () {
    const rules = {
      selectedStatus: { required },
    }

    return rules
  },

  mixins: [vueMixins.dateFormatter],
}
</script>
<style scoped>
.option-btn {
  background: #ffffff;
    display: inline-block;
    width: 30px;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
}
</style>