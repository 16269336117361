<template>
<div class="autocomplete" style="display: inline-block">
  <input type="text" :class="vclass" :value="shownValue" v-on:input="update" v-on:keypress="handleKeypress" />
  <div class="options" v-if="value && shownList">
    <div v-for="(option, optindex) in optionValues" v-if="option.toLowerCase().indexOf((value || '').toLowerCase())!=-1" class="option" :class="{current: index===optindex}" v-on:mouseover="hoverItem(optindex)" v-on:click.prevent="selectItem(optindex)">
      {{ option }}
    </div>
  </div>
</div>
</template>

<script>
export default {
  props: ["vclass", "value", "options", "short"],
  data() {
    return {
      index: null,
      shownList: false,
    };
  },
  computed: {
    optionValues() {
      var values=[];
      for(var i in this.options)
        values.push(this.options[i]);
      return values;
    },
    shownValue() {
      if (this.short)
        return (this.value || "").replace(/ .*$/, "");
      else
        return this.value;
    },
  },
  methods: {
    handleKeypress($e) {
      if ($e.key=="Enter") {
        $e.preventDefault();
        var that=this;
        setTimeout(function() {
          that.shownList=false;
        }, 50);
        var value=$e.target.value;
        if (this.index!==null)
          value=this.optionValues[this.index];
        this.index=null;
        this.$emit("input", value);
        this.$emit("select");
      }
      if ($e.key=="ArrowUp") {
        $e.preventDefault();
        if (this.index===null)
          this.index=this.optionValues.length-1;
        else {
          if (this.index!==0)
            this.index--;
          else
            this.index=null;
        }
      }
      if ($e.key=="ArrowDown") {
        $e.preventDefault();
        if (this.index===null)
          this.index=0;
        else {
          if (this.index<=this.optionValues.length-1)
            this.index++;
          else
            this.index=null;
        }
      }
    },
    hoverItem(index) {
      this.index=index;
    },
    selectItem(index) {
      this.index=index;
      var value=this.optionValues[this.index];
      this.index=null;
      this.shownList=false;
      this.$emit("input", value);
      this.$emit("select");
    },
    update($e) {
      this.shownList=true;
      this.$emit("input", $e.target.value);
    }
  }
}
</script>
