<template>
<div>
  <div class="content-table-container">
    <table class="content-table">
      <thead>
        <tr>
          <th style="width: 45%">Description</th>
          <th style="width: 15%">Options</th>
          <th style="width: 20%">File</th>
          <th style="width: 10%">Type</th>
          <th style="width: 10%">&nbsp;</th>
        </tr>
        <tr>
          <th class="spacer" colspan="3"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items">
          <td class="text-left" style="width: 45%">
            <input type="text" v-model="item.name" style="text-align: left" />
          </td>
          <td>
            <multiselect :options="['Partners','Merchants']" v-model="item.options" />
          </td>
          <td class="" style="width: 20%">
            <file-obj :basepath="basepath" v-model="item.filename" />
          </td>
          <td class="" style="width: 20%">
            <dyn-select v-model="item.type" :options="filetypes" />
          </td>
          <td>
            <a class="remove" href="" v-on:click.prevent="deleteItem(index)">&times;</a>
          </td>
        </tr>
        <tr class="new-row">
          <td colspan="5">
            <a class="add" href="" v-on:click.prevent="newItem">+</a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="buttons">
    <button class="btn btn-large btn-primary" v-on:click.prevent="updateResources">Submit</button>
  </div>
</div>
</template>

<script>
export default {
  data() { return {
    items: [],
    origItems: [],
    filetypes: [
      {label: "Sales", value: "Sales"},
      {label: "Technical", value: "Technical"},
      {label: "Compliance", value: "Compliance"},
      {label: "Risk", value: "Risk"},
      {label: "Finance", value: "Finance"},
      {label: "Legal", value: "Legal"},
    ],
    basepath: "",
  }},
  methods: {
    reload() {
      var that=this;
      api.list("Resources", {}, ["id:ASC"]).then(function(data) {
        that.basepath=data.desc.filename.basepath;
        that.origItems=JSON.parse(JSON.stringify(data.items));
        that.items=JSON.parse(JSON.stringify(data.items));
      });
    },
    deleteItem(index) {
      var that=this;
      if (confirm("Are you sure?")) {
        that.items.splice(index, 1);
      }
    },
    newItem() {
      this.items.push({
        name: "",
        options: [],
        filename: null,
      });
    },
    updateResources() {
      var itemsToDelete=[];
      for(var index in this.origItems) {
        var item=this.origItems[index];
        if (!this.items.find(function(e) { return e.id==item.id; }))
          itemsToDelete.push(item);
      }
      var itemsToAdd=[];
      var itemsToUpdate=[];
      for(var index in this.items) {
        var item=this.items[index];
        var foundItem=this.origItems.find(function(e) { return e.id==item.id; });
        if (!foundItem)
          itemsToAdd.push(item);
        else if (JSON.stringify(foundItem)!=JSON.stringify(item))
          itemsToUpdate.push(item);
      }
      this.updateResourcesApi(itemsToUpdate, itemsToAdd, itemsToDelete);
      this.origItems=JSON.parse(JSON.stringify(this.items));
    },
    updateResourcesApi: async function(update, add, remove) {
      startLoading();
      for(var i in update) {
        var item=update[i];
        await api.update("Resources", item.id, item);
      }
      for(var i in add) {
        var item=add[i];
        await api.create("Resources", item);
      }
      for(var i in remove) {
        var item=remove[i];
        await api.delete("Resources", item.id);
      }
      stopLoading("Updated resources");
    },
  },
  mounted() {
    this.reload();
  },
}
</script>

