<template>
<div v-if="employees">
  <div class="form-group">
    <label>Tag Employees:</label>
    <select v-if="availableEmployees.length && !readonly" v-model="selectEmployee">
      <option v-for="employee in availableEmployees" :key="employee.id" :value="employee.id">{{ employee.first_name }} {{ employee.last_name }}</option>
    </select>
    <span v-if="!employees.length">There are no Active employees</span>
  </div>
  <ul class="tags">
    <li v-for="id in selectedEmployees">{{ getEmployee(id).first_name }} {{getEmployee(id).last_name }}
      <span v-if="!readonly || (selfRemove && getEmployee(id).userId.id === userId)" class="close" @click="removeEmployee(id)">x</span>
    </li>
  </ul>
</div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      required: true
    },

    readonly: {
      type: Boolean,
      default: false
    },

    selfRemove: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      selectEmployee: null,
      employees: null
    }
  },

  computed: {
    userId () {
      return window.appData.currentUser.id
    },

    selectedEmployees: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },

    availableEmployees () {
      return this.employees ? this.employees.filter(employee => employee.status === 'ACTIVE' && !this.selectedEmployees.includes(employee.id)) : []
    }
  },

  watch: {
    selectEmployee (value) {
      if (value) {
        this.selectedEmployees.push(value)
        this.selectEmployee = null
      }
    }
  },

  async created () {
    this.employees = (await api.list('Employee')).items
  },

  methods: {
    removeEmployee (id) {
      if (this.readonly) {
        this.$emit('remove', this.getEmployee(id))
      } else {
        const idx = this.selectedEmployees.findIndex(employeeId => employeeId === id)
        this.selectedEmployees.splice(idx, 1)
      }
    },

    getEmployee (id) {
      return this.employees ? this.employees.find(employee => employee.id === id) : null
    }
  },
}
</script>
