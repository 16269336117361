<template>
  <div>
    <div class="content-filters">
        <basic-form>
          <h4>Terms and Conditions</h4>
          <mnb-input v-model="legalDisclaimers.termsAndConditionsHeader" label="Terms and Conditions Header" :disabled="!updateInformations" :maxlength="100"/>
          <br/>
          <label>Terms and Conditions Updated:</label>
          <date-time-picker
            v-model="legalDisclaimers.termsAndConditionsDate"
            value-type="String"
            value-format="yyyy-LL-dd"
            format="yyyy-LL-dd"
            :time-picker="false"
            :auto-close="true"
            min-date="2020-01-01"
            :max-date="today"
            :disabled="!updateInformations" />
          <br/>
          <label>Terms and Conditions Text:</label>
          <block-edit rows="10" v-model="legalDisclaimers.termsAndConditionsText" placeholder="" :editable="updateInformations" />
          <br/>
          <br/>
          <h4>Privacy Policy</h4>
          <mnb-input v-model="legalDisclaimers.privacyPolicyHeader" label="Privacy Policy Header" :disabled="!updateInformations" :maxlength="100"/>
          <br/>
          <label>Privacy Policy Updated:</label>
          <date-time-picker
            v-model="legalDisclaimers.privacyPolicyDate"
            value-type="String"
            value-format="yyyy-LL-dd"
            format="yyyy-LL-dd"
            :time-picker="false"
            :auto-close="true"
            min-date="2020-01-01"
            :max-date="today"
            :disabled="!updateInformations" />
          <br/>
          <label>Privacy Policy Text:</label>
          <block-edit rows="10" v-model="legalDisclaimers.privacyPolicyText" placeholder="" :editable="updateInformations" />
          <div class="buttons">
            <template v-if="updateInformations">
              <button class="btn btn-large btn-primary" @click="save()">Submit</button>
            </template>
            <template v-else class="buttons">
              <button class="btn btn-large btn-primary" @click="updateInformations = true">Update</button>
            </template>
              <button class="btn btn-large btn-primary">Reset Fields</button>
          </div>
        </basic-form>
      </div>
  </div>
  </template>
  
  <script>
  import BasicForm from './components/ui/forms/Form'
  import MnbInput from './components/ui/forms/Input'
  import MnbSelect from './components/ui/forms/Select'
  import BlockEdit from './components/ui/forms/BlockEdit'
  import MnbUploadInput from './components/ui/forms/UploadInput'

  import mixinAutoResize from "./components/ui/forms/autoresize.js"
  import inputMixin from "./components/ui/forms/input-mixin.js"
  import DateTimePicker from 'vue-vanilla-datetime-picker';

  export default {
    mixins: [mixinAutoResize],

    components: {
      MnbInput,
      BasicForm,
      MnbSelect,
      BlockEdit,
      MnbUploadInput,
      DateTimePicker,
    },
    
    data() { return {
      legalDisclaimers: {},
      updateInformations: false,
      today: moment("00:00:00", "HH:mm:ss").format("YYYY-MM-DD"),
    }},
    
    async created () {
      var items=(await api.list("Disclaimers", {type: "general"})).items;
      this.legalDisclaimers = items[0]
    },

    methods: {
      async save() {
        startLoading();
        await api.update('Disclaimers', this.legalDisclaimers.id, {...this.legalDisclaimers});
        this.updateInformations = false
        stopLoading("Updated.");
      }
    },
    mixins: [vueMixins.dateFormatter, formValidation.mixin],
  }
  </script>
<style lang="scss" scoped>
  textarea {
    width: 100%;
  }
</style>