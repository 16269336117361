<template>

</template>

<script>
export default {
  props: ["item", "merchant"],
  async doNext() {
    this.$emit("next");
  },
}
</script>
