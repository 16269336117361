<template>
<div class="element-toggle">
  <label :class="{checked: actualValue==customVal}">
    <span></span>
    <input type="radio" :value="customVal" v-model="actualValue" v-on:change="updateVal">
    {{ customLabel || "No" }}
    <datepicker v-model="customValue" v-on:input="updateVal" :disabled="actualValue==defaultVal" :future="future" placeholder="Live Date" />
  </label>
  <label :class="{checked: actualValue==defaultVal}">
    <span></span>
    <input type="radio" :value="defaultVal" v-model="actualValue" v-on:change="updateVal">
    {{ defaultLabel || "Yes" }}
  </label>
</div>
</template>

<script>
export default {
  data() { return {
    actualValue: false,
    customValue: "",
  }},
  props: ["value", "custom", "default", "customLabel", "defaultLabel", "future"],
  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        if (newVal!=this.defaultVal) {
          this.actualValue=this.customVal;
          this.customValue=newVal;
        } else {
          this.actualValue=this.defaultVal;
          this.customValue="";
        }
      },
    },
  },
  computed: {
    defaultVal() {
      return this.default===undefined?'1':this.default;
    },
    customVal() {
      return this.custom===undefined?'0':this.custom;
    },
  },
  methods: {
    updateVal() {
      if (this.actualValue==this.customVal)
        this.$emit("input", this.customValue);
      else
        this.$emit("input", this.actualValue);
      
    },
  },
}
</script>
