<template>
<div class="element-toggle slin">
  <label :class="{checked: value, disabled: !!disabled}">
    <input :disabled="disabled" type="checkbox" v-model="actualValue" v-on:change="update" />
    <span></span>
    <slot />
  </label>
</div>
</template>

<script>
export default {
  props: ["value", "disabled"],
  data() { return {
    actualValue: false,
    more: false,
  }},
  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        this.actualValue=newVal==1?true:false;
      },
    },
  },
  methods: {
    update($event) {
      this.$emit('input', this.actualValue?1:0);
    },
  },
}
</script>
