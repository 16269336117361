<template>
<div class="element-toggle">
  <div class="opts">
  <label :class="{checked: actualValue===customVal}">
    <span></span>
    <input type="radio" :value="customVal" v-model="actualValue" v-on:change="updateVal">
    {{ customLabel || "Yes" }}
  </label>
  <label :class="{checked: actualValue===defaultVal}">
    <span></span>
    <input type="radio" :value="defaultVal" v-model="actualValue" v-on:change="updateVal">
    {{ defaultLabel || "No" }}
  </label>
  </div>
  <slot v-if="hideslot==='inverse'?(value==defaultVal):((!hideslot) || value==customVal)" />
  <div v-else></div>
</div>
</template>

<script>
export default {
  data() { return {
    actualValue: false,
  }},
  props: ["value", "custom", "default", "customLabel", "defaultLabel", "hideslot"],
  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        if (newVal===undefined || newVal===null)
          this.actualValue=null;
        else if (newVal!=this.defaultVal)
          this.actualValue=this.customVal;
        else
          this.actualValue=this.defaultVal;
      },
    },
  },
  computed: {
    defaultVal() {
      return this.default===undefined?'0':this.default;
    },
    customVal() {
      return this.custom===undefined?'1':this.custom;
    },
  },
  methods: {
    updateVal() {
      this.$emit("input", this.actualValue);
      this.$emit("input", this.actualValue);
    },
  },
}
</script>
