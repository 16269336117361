<template>
  <div class="content-table-container file-list-container">
    <table class="file-list content-table">
      <thead>
        <tr>
          <th>Type</th>
          <th>Created</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="email in emails">
          <td>{{ email.emailType }}</td>
          <td>{{ formatDate(email.created, "YYYY-MM-DD HH:mm:ss") }}</td>
        </tr>
      </tbody>
    </table>

    <portal to="actions">
      <ul>
        <template v-if="item.emailConfirmation === false">
          <template v-if="settings.settings.emailNotifications.emailConfirmation">
            <template v-if="item.accountType == 'Personal'">
              <template v-if="item.personal.status == 'A02'">
                <li>
                  <a href="" v-on:click.prevent="sendConfirmationEmail1()">Email Confirmation</a>
                </li>
              </template>
            </template>
            <template v-if="item.accountType == 'Business'">
              <template v-if="item.business.status == 'A02'">
                <li>
                  <a href="" v-on:click.prevent="sendConfirmationEmail1()">Email Confirmation</a>
                </li>
              </template>
            </template>
          </template>
        </template>
      </ul>
    </portal>
  </div>
</template>

<script>
export default {
  props: ["item"],
  data: ()=>({
    emails: [],
    settings: [],
  }),
  computed: {
  },
  methods: {
    listEmails: async function() {
      this.emails=(await api.list("ApplicationEmail", {appId: this.item.id}, ["id:DESC"])).items || [];
    },
    sendEmailUser: async function(type, resetPw) {
      if (!confirm("Send "+type+" email?"))
        return;
      await this.sendEmail(type, resetPw);
    },
    sendEmail: async function(type) {
      startLoading();
      var res=await api.create("ApplicationEmail", {appId: this.item.id, emailType: type, created: "CURRENT_TIMESTAMP"});
      stopLoading("Email sent");
      var newItem=await api.get("ApplicationEmail", res.id);
      if (newItem.item)
        this.emails.unshift(newItem.item);
    },

    sendConfirmationEmail1: async function(type) {
      startLoading();
      var result=await api.action("TreasuryAccount", this.item.id, "emailConfirmation");
      stopLoading("Email sent");
    },
  },
  async created() {
    this.listEmails();
    this.settings = (await api.get("Settings", 1)).item;
  },
  mixins: [vueMixins.dateFormatter],
}
</script>
