<template>
    <table class="breakdown-table">
      <thead>
        <tr>
          <th>Status</th>
          <th>Amount</th>
        </tr>
        <tr>
          <th colspan="2" class="spacer"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="status in perStatus">
          <td>{{ status.description }}</td>
          <td>{{ status.items.length }}</td>
        </tr>
      </tbody>
    </table>
</template>

<script>
export default {
  props: ["statuses"],
  data() { return {
    items: [],
    search: {},
  }; },
  computed: {
    perStatus() {
      return this.statuses.map((a)=>({
        description: a.description,
        items: this.items.filter((i)=>{
          if(i.accountType == 'Personal'){
            return i.personal?.status==a.status;
          }else{
            return i.business?.status==a.status;
          }
        }),
      }));
    },
  },
  methods: {
  },
  mounted() {
    window.breakdownTable=this;
  },
}
</script>
