<template>
<div class="form-popup" v-show="shown"><div>
  <div class="ncontent appf appnat">
    <a class="close" href="" v-on:click.prevent="dismiss">&times;</a>
    
    <form-progress :step="step" :steps="['General', 'Company', 'Business', 'Banking', 'Schedule A']" />
    <h3>New Agreement</h3>

  
<form class="form-step" v-if="step==0" key="step1">
  <section>
    <h2>General</h2>
    <ul class="column-form">
      <li>
        <label>Signup Form ID</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly v-model="appl.id" />
          </validator>
        </div>
      </li>
      <li>
        <label>Partner ID</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly v-model="appl.agentId" />
          </validator>
        </div>
      </li>
      <li>
        <label>Partner Type</label>
        <div class="field">
          <validator :value="0" reqs="">
            <dyn-select v-model="appl.groups" :options="groups" />
          </validator>
        </div>
      </li>
      <li v-if="false && (appl.groups=='agent-inside' || appl.groups=='agent-outside')">
        <label>Sales Brand</label>
        <div class="field">
          <validator :value="item.salesBrand" reqs="required">
            <dyn-select v-model="item.salesBrand" :options="salesBrands" />
          </validator>
        </div>
      </li>
      <li>
        <label>Partner Solution</label>
        <div class="field">
          <validator :value="item.solutions" reqs="required">
            <label v-for="solution in solutions" class="" :class="{checked: item.solutions==solution.value}">
              <input type="checkbox" name="plan" v-model="item.solutions" :value="solution.value" />
              <span></span>{{ solution.label }}
            </label>
          </validator>
        </div>
      </li>
      <li v-if="false">
        <label>Regions</label>
        <div class="field regions-list" v-if="false">
          <validator :value="item.regions" reqs="required">
            <label>CA - </label>
            <label class="" :class="{checked: item.regions.indexOf('0101')!=-1}">
              <input type="checkbox" name="plan" v-model="item.regions" value="0101" />
              <span></span>01
            </label>
            <label class="" :class="{checked: item.regions.indexOf('0102')!=-1}">
              <input type="checkbox" name="plan" v-model="item.regions" value="0102" />
              <span></span>02
            </label>
            <label class="" :class="{checked: item.regions.indexOf('0103')!=-1}">
              <input type="checkbox" name="plan" v-model="item.regions" value="0103" />
              <span></span>03
            </label>
            <label class="" :class="{checked: item.regions.indexOf('0104')!=-1}">
              <input type="checkbox" name="plan" v-model="item.regions" value="0104" />
              <span></span>04
            </label>
            <label class="" :class="{checked: item.regions.indexOf('0105')!=-1}">
              <input type="checkbox" name="plan" v-model="item.regions" value="0105" />
              <span></span>05
            </label>
          </validator>
        </div>
        <div class="field" v-else>
          <validator :value="item.regions" reqs="">
            <input type="text" v-model="item.regions" />
          </validator>
        </div>
      </li>

    </ul>
    <div class="notes important" v-if="internalNotes.agentAgrGeneral">Note: {{ internalNotes.agentAgrGeneral }} </div>
  </section>
  <div class="buttons">
    <a class="btn btn-large btn-plain btn-back" v-on:click.prevent="">Back</a>
    <a class="btn btn-large btn-success btn-next" v-on:click.prevent="nextStep">Next</a>
  </div>
</form>
<form class="form-step" v-if="step==1" key="step2">
  <section>
    <h2>Company Info</h2>
    <ul class="column-form">
      <template v-for="(ubo, ui) in appl.contacts">
        <li>
          <label><strong>Contact {{ ui+1 }}</strong> First Name</label>
          <div class="field">
          <div class="validator">
            <input type="text" readonly v-model="ubo.firstName" />
          </div>
          </div>
        </li>
        <li>
          <label><strong>Contact {{ ui+1 }}</strong> Last Name</label>
          <div class="field">
          <div class="validator">
            <input type="text" readonly v-model="ubo.lastName" />
          </div>
          </div>
        </li>
        <li>
          <label><strong>Contact {{ ui+1 }}</strong> E-mail</label>
          <div class="field">
          <div class="validator">
            <input type="text" readonly v-model="ubo.email" />
          </div>
          </div>
        </li>
        <li>
          <label><strong>Contact {{ ui+1 }}</strong> Phone</label>
          <div class="field">
          <div class="validator">
            <input type="text" readonly v-model="ubo.phone" />
          </div>
          </div>
        </li>
      </template>
        <li v-if="['agent-iso', 'agent-isa', 'agent-ref'].indexOf(appl.groups)!=-1">
          <label>Company Name</label>
          <div class="field">
          <div class="validator">
            <input type="text" readonly v-model="appl.agentDb.name" />
          </div>
          </div>
        </li>
      <template v-if="['agent-iso', 'agent-isa', 'agent-auth', 'agent-ref'].indexOf(appl.groups)!=-1">
        <li>
          <label>Company Type</label>
          <div class="field">
          <div class="validator">
            <input type="text" readonly v-model="appl.agentDb.companyType" />
          </div>
          </div>
        </li>
        <li>
          <label>Address</label>
          <div class="field">
          <div class="validator">
            <input type="text" readonly v-model="appl.agentDb.address" />
          </div>
          </div>
        </li>
        <li>
          <label>City</label>
          <div class="field">
          <div class="validator">
            <input type="text" readonly v-model="appl.agentDb.city" />
          </div>
          </div>
        </li>
        <li>
          <label>Zip/Postal Code</label>
          <div class="field">
          <div class="validator">
            <input type="text" readonly v-model="appl.agentDb.zip" />
          </div>
          </div>
        </li>
        <li>
          <label>State/Province</label>
          <div class="field">
          <div class="validator">
            <input type="text" readonly v-model="appl.agentDb.state" />
          </div>
          </div>
        </li>
        <li>
          <label>Country</label>
          <div class="field">
          <div class="validator">
            <input type="text" readonly v-model="appl.agentDb.country" />
          </div>
          </div>
        </li>
        <li>
          <label>Phone Number</label>
          <div class="field">
          <div class="validator">
            <input type="text" readonly v-model="appl.agentDb.phone" />
          </div>
          </div>
        </li>
        <li>
          <label>Company Email</label>
          <div class="field">
          <div class="validator">
            <input type="text" readonly v-model="appl.agentDb.email" />
          </div>
          </div>
        </li>
      </template>
    </ul>
    <div class="notes important" v-if="internalNotes.agentAgrCompany">Note: {{ internalNotes.agentAgrCompany }} </div>
  </section>
  <div class="buttons">
    <a class="btn btn-large btn-plain btn-back" v-on:click.prevent="step--">Back</a>
    <a class="btn btn-large btn-success btn-next" v-on:click.prevent="nextStep">Next</a>
  </div>
</form>
<form class="form-step" v-if="step==2" key="step3">
  <section>
    <h2>Business Info</h2>
    <ul class="column-form">
        <li>
          <label>Business Website</label>
          <div class="field">
          <div class="validator">
            <input type="text" readonly v-model="appl.agentDb.website" />
          </div>
          </div>
        </li>
        <li>
          <label>Sevices</label>
          <div class="field">
          <div class="validator business-section">
            <div class="payment-checkbox">
              <input v-model="appl.agentDb.payments" id="payment_checkbox" type="checkbox"/>
              <label for="payment_checkbox">PAYMENTS</label>
            </div>
            <div class="payment-checkbox">
              <input v-model="appl.agentDb.treasury" id="treasury_checkbox" type="checkbox"/>
              <label for="treasury_checkbox">TREASURY</label>
            </div>
          </div>
          </div>
        </li>
        <li>
          <label>Types</label>
          <div class="field">
          <div class="validator business-section">
            <div class="payment-checkbox">
              <multiselect :readonly="!appl.agentDb.payments" :class="payment-multi" v-model="appl.agentDb.merchantTypes" :hasOther="0" :options="['POS', 'E-Commerce', 'Unattended', 'EFT / ACH', 'Debit Only']" />
            </div>
            <div class="payment-checkbox">
              <multiselect :readonly="!appl.agentDb.treasury" v-model="appl.agentDb.treasuryTypes" :hasOther="0" :options="['Personal Accounts', 'Business Accounts', 'Debit Cards', 'Digital Assets', 'Assets Pay']" />
            </div>
          </div>
          </div>
        </li>
        <li>
          <label>Estimated Applications Monthly</label>
          <div class="field">
          <div class="validator business-section">
            <input type="text" readonly v-model="appl.agentDb.appMonthly" />
            <input type="text" readonly v-model="appl.agentDb.treasuryMonthly" />
          </div>
          </div>
        </li>
        <li>
          <label>Estimated Monthly Volume</label>
          <div class="field">
          <div class="validator business-section">
            <formel-money-amount readonly v-model="appl.agentDb.monthlyVolume" />
            <formel-money-amount readonly v-model="appl.agentDb.treasuryVolume" />
          </div>
          </div>
        </li>
    </ul>
    <div class="notes important" v-if="internalNotes.agentAgrBusiness">Note: {{ internalNotes.agentAgrBusiness }} </div>
  </section>
  <div class="buttons">
    <a class="btn btn-large btn-plain btn-back" v-on:click.prevent="step--">Back</a>
    <a class="btn btn-large btn-success btn-next" v-on:click.prevent="nextStep">Next</a>
  </div>

</form>
<form class="form-step" v-if="step==3" key="step4">
  <section>
    <h2>Banking</h2>
    <ul class="column-form" v-if="appl.agentDb.accounts.length">
      <li>
        <label>Bank Country</label>
        <div class="field">
          <validator :value="appl.agentDb.accounts[0].bankAddressCountry" reqs="required">
            <dyn-select v-model="appl.agentDb.accounts[0].bankAddressCountry" :options="[{value:'United States', label: 'United States'}, {value: 'Canada', label: 'Canada'}]" />
          </validator>
        </div>
      </li>
      <li>
        <label>Bank State</label>
        <div class="field">
          <validator :value="appl.agentDb.accounts[0].bankAddressState" reqs="required">
            <dyn-select v-if="states.length" v-model="appl.agentDb.accounts[0].bankAddressState" :options="states" />
            <input type="text" v-else v-model="appl.agentDb.accounts[0].bankAddressState" :options="states" />
          </validator>
        </div>
      </li>
      <li>
        <label>Bank Name</label>
        <div class="field">
          <validator :value="appl.agentDb.accounts[0].bankName" reqs="required">
            <input type="text" v-model="appl.agentDb.accounts[0].bankName" />
          </validator>
        </div>
      </li>
      <li>
        <label>Currency</label>
        <div class="field">
          <validator :value="appl.agentDb.accounts[0].accountCurrency" reqs="required">
            <dyn-select v-model="appl.agentDb.accounts[0].accountCurrency" :options="currencies2" />
          </validator>
        </div>
      </li>
      <li>
        <label>Account Name</label>
        <div class="field">
          <validator :value="appl.agentDb.accounts[0].accountHolder" reqs="required">
            <input type="text" v-model="appl.agentDb.accounts[0].accountHolder" />
          </validator>
        </div>
      </li>
      <li>
        <label>Account Number</label>
        <div class="field">
          <validator :value="appl.agentDb.accounts[0].accountNumber" reqs="required">
            <input type="text" v-model="appl.agentDb.accounts[0].accountNumber" />
          </validator>
        </div>
      </li>
      <li>
        <label>Institution Number</label>
        <div class="field">
          <validator :value="appl.agentDb.accounts[0].accountSwift" reqs="required">
            <input type="text" v-model="appl.agentDb.accounts[0].accountSwift" />
          </validator>
        </div>
      </li>
      <li>
        <label>Transit/Branch Number</label>
        <div class="field">
          <validator :value="appl.agentDb.accounts[0].accountIBAN" reqs="required">
            <input type="text" v-model="appl.agentDb.accounts[0].accountIBAN" />
          </validator>
        </div>
      </li>
      <li>
        <label>ABA / routing number</label>
        <div class="field">
          <validator :value="appl.agentDb.accounts[0].accountRouting" reqs="required">
            <input type="text" v-model="appl.agentDb.accounts[0].accountRouting" />
          </validator>
        </div>
      </li>
    </ul>
    <div class="notes important" v-if="internalNotes.agentAgrBanking">Note: {{ internalNotes.agentAgrBanking }} </div>
  </section>
  <div class="buttons">
    <a class="btn btn-large btn-plain btn-back" v-on:click.prevent="step--">Back</a>
    <a class="btn btn-large btn-success btn-next" v-on:click.prevent="nextStep">Next</a>
  </div>
</form>
<form class="form-step" v-if="step==4" key="step5">
  <section>
    <h2>Schedule A</h2>
    <ul class="column-form">
      <li v-if="false">
        <label>Sales Brand</label>
        <div class="field">
          <validator :value="item.salesBrand" reqs="required">
            <dyn-select v-model="item.salesBrand" :options="salesBrands" />
          </validator>
        </div>
      </li>
      <li>
        <label>Partner Tier</label>
        <div class="field">
          <validator :value="item.accClass" reqs="required">
            <dyn-select :options="accountClassesList" v-model="item.accClass" />
          </validator>
        </div>
      </li>
      <li>
        <label>Compliance review days</label>
        <div class="field">
          <validator :value="item.reviewDays" reqs="required">
            <dyn-select :options="reviewDaysOpts" v-model="item.reviewDays" />
          </validator>
        </div>
      </li>
      <li>
        <label>BIN/ICA Rental Fee:</label>
        <div class="field">
          <validator :value="item.bicRentalFee" reqs="required">
            <formel-money-amount4 v-model="item.bicRentalFee" suffix="%" />
          </validator>
        </div>
      </li>
      <li>
        <label>Partner Setup Fee:</label>
        <div class="field">
          <validator :value="item.setupFee" reqs="required">
            <formel-money-amount4 v-model="item.setupFee" prefix="$" />
          </validator>
        </div>
      </li>
      <li>
        <label>Monthly Minimum:</label>
        <div class="field">
          <validator :value="item.monthlyMinimum" reqs="required">
            <formel-money-amount4 v-model="item.monthlyMinimum" prefix="$" />
          </validator>
        </div>
      </li>
    </ul>
    <div class="notes important" v-if="internalNotes.agentAgrBanking">Note: {{ internalNotes.agentAgrBanking }} </div>
  </section>
  <div class="buttons">
    <a class="btn btn-large btn-plain btn-back" v-on:click.prevent="step--">Back</a>
    <a class="btn btn-large btn-success" v-on:click.prevent="save">Create Agreement</a>
  </div>
</form>
  </div>
  
  
</div></div>
</template>

<script>
export default {
  data() { return {
    groups: window.userGroupList.filter((a)=>a.value.indexOf("agent-")==0),
    optsCount: [{value: '0', label: '0'}, {value: '1', label: '1'},
                {value: '2', label: '2'}, {value: '3', label: '3'},
                {value: '4', label: '4'}, {value: '5', label: '5'},
                {value: '6', label: '6'}, {value: '7', label: '7'},
                {value: '8', label: '8'}, {value: '9', label: '9'},
                {value: '10', label: '10'}, {value: '11', label: '11'},
                {value: '12', label: '12'}, {value: '13', label: '13'},
                {value: '14', label: '14'}, {value: '15', label: '15'},
                {value: '16', label: '16'}, {value: '17', label: '17'},
                {value: '18', label: '18'}, {value: '19', label: '19'},
                {value: '20', label: '20'}],
    shown: false,
    step: 0,
    item: {solutions: [], regions: [], msolutions: []},
    appl: {},
    managers: [],
    internalNotes: {},
    validMapps: [
      {label: "0-30", value: "0-30"},
      {label: "31-50", value: "31-50"},
      {label: "51-75", value: "51-75"},
      {label: "76-99", value: "76-99"},
      {label: "100+", value: "100+"},
    ],
    languages: [
      {value: "English", label: "English"},
    ],
    accountClassesList: [
      {value: "10", label: "10"},
      {value: "20", label: "20"},
      {value: "30", label: "30"},
      {value: "40", label: "40"},
      {value: "50", label: "50"},
    ],
    reviewDaysOpts: [
      {value: "30", label: "30"},
      {value: "60", label: "60"},
      {value: "90", label: "90"},
      {value: "180", label: "180"},
      {value: "365", label: "365"},
    ],
    salesBrands: [],
    currencies: {"840": "USD", "124": "CAD"},
    currencies2: [
      {value: "124", label: "CAD"},
      {value: "840", label: "USD"},
    ],
    availQuantities: [
      {label: "0", value: 0}, {label: "1", value: 1}, {label: "2", value: 2},
      {label: "3", value: 3}, {label: "4", value: 4}, {label: "5", value: 5},
      {label: "6", value: 6}, {label: "7", value: 7}, {label: "8", value: 8},
      {label: "9", value: 9}, {label: "10", value: 10},
    ],
    equipment: [],
    quantities: [],
    rental: [],
    options: [],
    modules: [],
    availOptEnabled: [{label: "No", value: false}, {label: "Yes", value: true}],
    optionsEnabled: [],
    modulesEnabled: [],
    pricingTemplates: [],
    templateId: null,
    accountClasses: [],
    optionTemplates: [],
    optionTemplateId: "0",
    moduleTemplates: [],
    moduleTemplateId: "0",
    enabledPayOpts: [],
    methodTemplateId: "0",
    methodTemplates: [],
    solutions: [],
    msolutions: [],
    returnLink: null,
  }},
  watch: {
  },
  mounted() {
    this.solutions=appData.solutions;
    this.msolutions=appData.msolutions;
    window.agreementPopup=this;
    this.managers=appData.sources.map(function(a) {
      return {value: a.id, label: a.fullName};
    });
    if (appData.internalNotes)
      this.internalNotes=appData.internalNotes;
    this.loadSalesBrands();
  },
  computed: {
    states() {
      return window.allProvinces[this.appl.agentDb.accounts[0].bankAddressCountry] || [];
    },
    agentFormattedEmail() {
      var email=this.salesBrandForId(this.item.salesBrand).email || "";
      if (!email)
        return "";
      var contact=this.appl.contacts[0] || {};
      var emailFN=contact.firstName.toLowerCase().replace(/[^a-z]/g, "");
      var emailLN=contact.lastName.toLowerCase().replace(/[^a-z]/g, "");
      return emailFN+"."+emailLN+(email.replace(/^[^@]*/, ""));
    },
//     currentOptTemplates() {
//       var result=this.optionTemplates.map(function(a) {
//         return {value: a.id, label: a.name};
//       });
//       result.unshift({value: 0, label: "(custom)"});
//       return result;
//     },
  },
  methods: {
    loadSalesBrands: async function() {
      this.salesBrands=[];
      var items=(await api.list("SalesBrand", {}, ["brandId:ASC"])).items || [];
      for(var i in items) {
        var item=items[i];
        this.salesBrands.push({label: item.dba, value: item.id, item: item});
      }
    },
    loadModules: async function() {
      this.modules=(await api.list("CompanyModule", [], ["id:ASC"])).items || [];
      for(var i in this.modules)
        this.modulesEnabled[i]=false;
    },
    loadEquipment: async function() {
      this.equipment=(await api.list("Equipment", {enabled: 1})).items || [];
      for(var i in this.equipment)
        this.quantities[i]=0;
    },
    loadSysOptions: async function() {
      var item=(await api.get("Options", 1)).item || {};
      this.enabledPayOpts=item.enabledMethods || [];
    },
    dismiss() {
      this.shown=false;
      this.step=0;
      this.appl={};
      this.item={solutions: [], msolutions: [], regions: []};
    },
    show(agent) {
      this.shown=true;
      this.step=0;
      this.appl=agent;
      this.item={
        agentId: agent.id,
        created: "CURRENT_TIMESTAMP",
        name: agent.agentDb.name+" "+(agent.agentId || agent.id),
        accClass: agent.agentDb.agentClass,
        managerId: agent.manager,
        filename: null,
        signed: null,
        salesBrand: null,
        solutions: [],
        msolutions: [],
        regions: [],
        language: agent.agentDb.language,
      };
      if (this.appl.agentDb.accounts.length==0) {
        this.appl.agentDb.accounts.push({
          accountCurrency:"124",
          accountCurrencies:"",
          accountHolder:"",
          accountNumber:"",
          accountSwift:"",
          accountIBAN:"",
          accountBankCode:"",
          accountRouting:"",
          bankName:"",
          bankAddress:"",
          bankAddressPostCode:"",
          bankAddressCity:"",
          bankAddressState:"",
          bankAddressCountry:"",
          bankPhone:""
        });
      }
    },
    nextStep() {
      if (!this.validate())
        return stopLoading("Please fill all required fields", "error");
      this.step++;
    },
    salesBrandForId(id) {
      for(var i in this.salesBrands)
        if (this.salesBrands[i].value==id)
          return this.salesBrands[i].item;
      return {};
    },
    save: async function() {
      if (!this.validate())
        return stopLoading("Please fill all required fields", "error");
      startLoading();
      var salesBrand=this.salesBrandForId(this.item.salesBrand);
      var result=JSON.parse(JSON.stringify(this.item));
      await api.create("PartnerAgreement", result);
      var userData={
        groups: this.appl.groups,
        manager: this.item.managerId,
        agentDb: {
          id: this.appl.agentDb.id,
          accounts: this.appl.agentDb.accounts,
          agentClass: this.item.accClass,
          language: this.item.language,
          solutions: this.item.solutions,
          msolutions: this.item.msolutions,
          regions: this.item.regions.join?this.item.regions.join(", "):this.item.regions,
          salesBrand: this.item.salesBrand,
          bicRentalFee: this.item.bicRentalFee,
          setupFee: this.item.setupFee,
          monthlyMinimum: this.item.monthlyMinimum,
          reviewDays: this.item.reviewDays,
          payments: this.appl.agentDb.payments,
          treasury: this.appl.agentDb.treasury,
          merchantTypes: this.appl.agentDb.merchantTypes,
          treasuryTypes: this.appl.agentDb.treasuryTypes,
        },
        skipNote: true,
//         companyName: this.appl.agentDb.name || salesBrand.dba,
//         companyPhone: this.appl.companyPhone || salesBrand.phone,
      };
//       if (this.agentFormattedEmail)
//         userData.companyEmail=this.agentFormattedEmail;

      await api.update("User", this.appl.id, userData);
      viewAgent.sendEmailUser("Agreement");
      setTimeout(()=>{
        stopLoading("Agreement created");
        this.dismiss();
        viewAgent.listAgreements();
        if (!this.returnLink)
          location.reload();
        else {
          location.replace(this.returnLink);
          location.reload();
        }
      }, 500);
      const note = (await api.create("Note", {
          message: `${this.item.agentDb.name} New Agreement Created`,
          parentId: this.item.agentDb.id,
          type: 'Partner',
          created: "CURRENT_TIMESTAMP",
          author: appData.currentUser.id,
        }, true)).item
    },
  },
  mixins: [formValidation.mixin]
}
</script>
<style scoped>
.payment-checkbox {
  display: flex;
  align-items: center;
  width: 50%;
}

.payment-checkbox label {
  margin-left: 0 !important;
  flex-grow: unset !important;
}

.payment-checkbox .multiselect {
  display: block !important;
}

.business-section{
  display: flex;
}

</style>
