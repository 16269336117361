<template>
<div>
  <view-agent-precheck :uwList="precheckList" />



  <div class="actions">
    <button v-on:click.prevent="goBack">Back</button>
    <button v-on:click.prevent="doNext">Next</button>
  </div>
</div>
</template>

<script>
export default {
  props: ["precheckList"],
  data: ()=>({
  }),
  methods: {
    goBack() {
      this.$emit("back");
    },
    doNext() {
      if (!this.precheckList || !this.precheckList.length)
        return;
      this.$emit("next");
    },
  },
  mounted() {
  },
}
</script>
