<template>
<div class="combo-select">
  <select :value="value" v-on:input="updateValue">
    <option v-for="opt in options" :value="opt">{{ opt || "other" }}</option>
  </select>
  <input type="text" :value="value" v-on:input="updateValue" v-if="!value || options.indexOf(value)==-1" />
</div>
</template>

<script>
export default {
  props: ["value", "options"],
  methods: {
    updateValue($e) {
      this.$emit('input', $e.target.value)
    },
  },
}
</script>
