<template>
<form class="search-form"  @submit.prevent="save()">
  <h2 class="doc-title">AML TRAINING CERTIFICATION</h2>
  <p>If you have successfully received your AML training please complete this form, sign it, and deliver it to the chief compliance officer.</p>
  <p><b>PLEASE SELECT ONE OF THE FOLLOWING:</b></p>

  <p><b>THIS TRAINING SESSION WAS MY:</b><br>
    <validator :value="form.traning_session" reqs="required">
      <div class="doc-field">
        <input v-model="traning_session.initial" type="checkbox" id="rad1" @input="checkboxAsRadio('traning_session', 'initial')">
        <label for="rad1">INITIAL TRAINING</label>
      </div>
      <div class="doc-field">
        <input v-model="traning_session.yearly" type="checkbox" id="rad2" @input="checkboxAsRadio('traning_session', 'yearly')">
        <label for="rad2">YEARLY TRAINING</label>
      </div>
    </validator>
  </p>
  
  <div class="field">
    <label>DATE(S) OF TRAINING:</label>
    <validator :value="form.dates_of_traning" reqs="required" class="w100">
      <datepicker v-model="form.dates_of_traning" type="text" :future="false" :minDate="todaySubstractDate(7)" class="w100" />
    </validator>
  </div>

  <div class="field">
    <label>TITLE OF TRAINING PROVIDED:</label>
    <validator :value="form.title_of_traning_provided" reqs="required legalName" class="w100">
      <input v-model="form.title_of_traning_provided" type="text" maxlength="100" @keypress="allowLetterNumber($event, { numbers: true })" class="w100">
    </validator>
  </div>

  <p>HOW WAS TRAINING TAKEN PLEASE SELECT ONE?
    <validator :value="form.traning_taken" reqs="required">
      <div class="doc-field">
        <input v-model="traning_taken.instructor" type="checkbox" id="rad54785434" @input="checkboxAsRadio('traning_taken', 'instructor')">
        <label for="rad54785434">INSTRUCTOR</label>
      </div>
      <div class="doc-field">
        <input v-model="traning_taken.webinar" type="checkbox" id="rad54123434" @input="checkboxAsRadio('traning_taken', 'webinar')">
        <label for="rad54123434">WEBINAR</label>
      </div>
      <div class="doc-field">
        <input v-model="traning_taken.online" type="checkbox" id="rad57335434" @input="checkboxAsRadio('traning_taken', 'online')">
        <label for="rad57335434">ONLINE E-LEARNING</label>
      </div>
      <div class="doc-field">
        <input v-model="traning_taken.all" type="checkbox" id="rad547234534" @input="checkboxAsRadio('traning_taken', 'all')">
        <label for="rad547234534">COMBINATION OF ALL</label>
      </div>
    </validator>
  </p>

  <p>I, the undersigned
    <validator :value="form.first_name" reqs="required letters" class="inline-block">
      <input v-model="form.first_name" type="text" maxlength="30" @keypress="allowLetterNumber($event)" placeholder="First Name">
    </validator>
    <validator :value="form.last_name" reqs="required letters" class="inline-block">
      <input  v-model="form.last_name" type="text" maxlength="30" @keypress="allowLetterNumber($event)" placeholder="Last Name">
    </validator>
    <validator :value="form.email" reqs="required email" class="inline-block">
      <input  v-model="form.email" type="email" placeholder="Email">
    </validator>
    <validator :value="form.department" reqs="required" class="inline-block">
      <select v-model="form.department">
        <option disabled value="">Department</option>
        <option v-for="(opt, key) in userGroups" :key="key" :value="key">{{ opt }}</option>
      </select>
    </validator>
    certify that I attended the above-noted training either in person or remotely via simultaneous audio-visual means, and that I received the above-noted training on the above-noted date(s) concerning the Company’s AML Program outlining all the policies and procedures to follow so as to detect, deter, and protect the Company against money launderers, terrorist financiers or fraudsters.</p>

  <p>SIGNED by {{ form.first_name }} {{ form.last_name }}</p>
  <p>
    SIGNATURE: <span class="signature-line"></span>
  </p>

  <p class="text-bg-black">TO BE COMPLETED BY THE CHIEF COMPLIANCE OFFICER</p>
  
  <div class="custom-doc-field">
    <div class="label">The Employee Completed the Training Described Above:</div>
    <div class="field-wrapper">
      <validator :value="form.completed_traning" reqs="required">
        <div class="yes-or-no">
          <div>
            <input v-model="completed_traning.yes" type="checkbox" id="cbx43123768421" @input="checkboxAsRadio('completed_traning', 'yes')">
            <label for="cbx43123768421">YES</label>
          </div>
          <div>
            <input v-model="completed_traning.no" type="checkbox" id="cbx437612338421" @input="checkboxAsRadio('completed_traning', 'no')">
            <label for="cbx437612338421">NO</label>
          </div>
        </div>
      </validator>
    </div>
  </div>
  
  <div class="custom-doc-field">
    <div class="label">A Test Was Administered:</div>
    <div class="field-wrapper">
      <validator :value="form.administered" reqs="required">
        <div class="yes-or-no">
          <div>
            <input v-model="administered.yes" type="checkbox" id="cbx666448421" @input="checkboxAsRadio('administered', 'yes')">
            <label for="cbx666448421">YES</label>
          </div>
          <div>
            <input v-model="administered.no" type="checkbox" id="cbx4367998421" @input="checkboxAsRadio('administered', 'no')">
            <label for="cbx4367998421">NO</label>
          </div>
        </div>
      </validator>
    </div>
  </div>

  <div class="custom-doc-field">
    <div class="label">Test Was Passed with a Grade Of:</div>
    <validator :value="form.passed_grade" reqs="required number" class="inline-block">
      <div class="field-wrapper yes-or-no">
        <input v-model.number="form.passed_grade" type="text" min="0" max="100" maxlength="3" class="w100" @input="limitMinMaxValue()" @keypress="allowLetterNumber($event, { numbers: true, letters: false })">
      </div>
    </validator>
  </div>

  
  <div class="custom-doc-field">
    <div class="label">Training and Test Must Be Retaken:</div>
    <div class="field-wrapper">
      <validator :value="form.must_be_retaken" reqs="required">
        <div class="yes-or-no">
          <div>
            <input v-model="must_be_retaken.yes" type="checkbox" id="cbx4311241118421" @input="checkboxAsRadio('must_be_retaken', 'yes')">
            <label for="cbx4311241118421">YES</label>
          </div>
          <div>
            <input v-model="must_be_retaken.no" type="checkbox" id="cbx43662238421" @input="checkboxAsRadio('must_be_retaken', 'no')">
            <label for="cbx43662238421">NO</label>
          </div>
        </div>
      </validator>
    </div>
  </div>
  
  
  <div class="custom-doc-field">
    <div class="label">(In the event of a failed grade) on the following date:</div>
    <validator :value="form.failed_grade_date" :reqs="form.must_be_retaken ? 'required' : null" class="inline-block">
      <div class="field-wrapper yes-or-no">
        <datepicker v-model="form.failed_grade_date" type="text" :future="true" :maxDate="todaySubstractDate(-14)" :disabled="!form.must_be_retaken" class="w100" />
      </div>
    </validator>
  </div>

  <p>Chief Compliance Officer Signature: _________________________________________________ Date: ______________________________</p>

  <div class="buttons">
    <button type="submit" class="btn btn-small">Submit</button>
    <button type="button" class="btn btn-small" @click="clear()">Clear</button>
  </div>
</form>
</template>

<script>
export default {
  mixins: [ vueMixins.helpers, formValidation.mixin ],

  data () {
    return {
      traning_session: {
        initial: false,
        yearly: false
      },
      completed_traning: {
        yes: false,
        no: false
      },
      must_be_retaken: {
        yes: false,
        no: false
      },
      administered: {
        yes: false,
        no: false
      },
      traning_taken: {
        instructor: false,
        webinar: false,
        online: false,
        all: false,
      },
      userGroups: window.userGroups,
      form: {}
    }
  },
  
  created () {
    this.clear()
  },

  methods: {
    async save () {
      if (!this.validate()) return stopLoading("Please fill all required fields", "error");
      let props = []
      startLoading();
      Object.keys(this.form).forEach(name => props.push({ name, value: this.form[name] }))
      try {
        const response = await api.create("ComplianceDocument", {
          userId: window.appData.currentUser.id,
          type: 'AMLTC',
          docIndex: 'GEN_DOC_ID',
          status: 'PENDING',
          signed: null,
          created: 'CURRENT_TIMESTAMP',
          form: props
        })
        await this.createNote(response.id)
        this.clear()
        this.validateReset()
        stopLoading("Saved")
      } catch (e) {
        stopLoading(e, 'error')
      }
    },

    createNote (compDocId) {
      const data = {
        message: 'AMLTC Form Created',
        created: "CURRENT_TIMESTAMP",
        author: window.appData.currentUser.id,
        system: 1,
        type: 'ComplianceDocument',
        parentId: compDocId
      }
      return api.create("Note", data)
    },

    limitMinMaxValue () {
      if (this.form.passed_grade > 100) this.form.passed_grade = 100
      if (this.form.passed_grade < 0) this.form.passed_grade = 0
    },
    todaySubstractDate (days) {
      let date = new Date()
      date.setDate(date.getDate() - days)
      return date
    },

    checkboxAsRadio (key, value) {
      if (!this[key][value]) {
        Object.keys(this[key]).forEach(val => {
          if (val !== value) this[key][val] = false
        })
        if (value === 'yes') this.form[key] = true
        else if (value === 'no') this.form[key] = false
        else this.form[key] = value
      } else {
        this.form[key] = null
      }
    },

    clear () {
      this.form = {
        first_name: null,
        last_name: null,
        email: null,
        department: '',
        failed_grade_date: null,
        passed_grade: null,
        title_of_traning_provided: null,
        dates_of_traning: null,
        traning_session: null,
        completed_traning: null,
        must_be_retaken: null,
        administered: null,
        traning_taken: null
      }
      // Sets all checkboxes values to false
      const checkboxes = ['traning_session', 'completed_traning', 'must_be_retaken', 'administered', 'traning_taken']
      checkboxes.forEach(key => {
        Object.keys(this[key]).forEach(value => {
          this[key][value] = false
        })
      })
      
      this.validateReset()
    }
  }
}
</script>
