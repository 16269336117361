<template>
<div class="ncontent newappsp">
  <div class="new-app-title">
    <h1>New Partner Sign-Up</h1>
    
  </div>
  <div class="partner-form">
    <div class="partner-item">
      <label>Solution</label>
      <dyn-select v-model="solution" :options="solutions" />
    </div>
    <div class="partner-item">
      <label>
        Form
      </label>
      <dyn-select v-model="form" :options="forms" />
    </div>
    <template v-if="solution && form">
      <div>
        <div style="display: flex;">
          <div class="partner-item">
            <a class="btn btn-small btn-green" :href="link+solution">Start Form</a>
            <a :href="pubLink" target="_blank">{{ pubLink }}</a>
          </div>
          <div class="partner-item">
            <button class="btn btn-small btn-primary" v-if="!tempLink" v-on:click.prevent="generateTempLink">
              Get URL
            </button>
            <button class="btn btn-small btn-primary" v-else v-on:click.prevent="doCopy(tempLink)">Copy URL</button>
            
          </div>
          <div class="partner-item">
            <label>
              Send Email
            </label>
            <dyn-select v-model="emailType" :options="emailTypes" />
          </div>
        </div>

        <div class="partner-input">
          <div v-if="tempLink">
            <input type="text" reaonly :value="tempLink" />
          </div>

          <div v-if="emailType == 'New Signup'" class="new-setup-email">
            <agent-signup type="SignUp" :solution="solution"></agent-signup>
          </div>
          <div v-if="emailType == 'Signup Reminder'" class="new-setup-email">
            <agent-signup type="SignUpReminder" :solution="solution"></agent-signup>
          </div>
        </div>
      </div>
    </template>
  </div>
  <div class="new-app-title">
    <p>For assistance or more information, please contact support.</p>
  </div>
  <div style="display: none; ">
    <div style="width: 50%; background: #f0f0f0">
      <div class="header new-app-header">
        <div class="toolbar">
          <div class="form-link-btn link-gen-agent" style="width: 100%" v-on:click.prevent="generateTempLink" :class="{generated: tempLink}">
            <strong>Partner Sign-Up Form</strong>
            <em>Generate URL</em>
            <div v-on:click.stop="">
              <input type="text" reaonly :value="tempLink" />
              <button v-on:click.prevent="doCopy(tempLink)">Copy</button>
            </div>
          </div>
        </div>
      </div>
      <div class="new-app-split">
        <div class="new-app-apply" style="width: 100%">
          <div>
            <h2>Partner Sign-Up</h2>
          </div>
          <a v-if="solution" class="btn btn-large btn-green" :href="link+solution">Start</a>
          <button v-else class="btn btn-large btn-green" disabled>Start</button>
        </div>
      </div>

    </div>
    <div style="width: 50%; box-sizing: border-box; background: #f0f0f0; display: flex; flex-direction: column; padding: 30px 4.32% 0 0">
      <div class="new-setup-email">
        <h2>New Partner Sign-Up</h2>
        <template v-if="solution">
          <agent-signup type="SignUp" :solution="solution"></agent-signup>
        </template>
        <template v-else>
            <form>
              <input required type="email" disabled placeholder="email">
              <button class="disabled-send" disabled>Send</button>
            </form>
        </template>
      </div>
      <div class="new-setup-email">
        <h2>Partner Reminder</h2>
        <template v-if="solution">
          <agent-signup type="SignUpReminder" :solution="solution"></agent-signup>
        </template>
        <template v-else>
          <form>
            <input required type="email" disabled placeholder="email">
            <button class="disabled-send" disabled>Send</button>
          </form>
        </template>
      </div>

    </div>
  </div>
  
</div>

</template>

<script>
export default {
  props: ["link"],
  data: ()=>({
    solution: null,
    tempLink: null,
    form: null,
    emailType: null,
    emailTypes: [
      {'value': null, label: ''},
      {'value': 'New Signup', label: 'New Signup'},
      {'value': 'Signup Reminder', label: 'Signup Reminder'},
    ],
    settings: appData.settings.settings ,
    solutions: [
      {
        "label" : "",
        "value" : null
      }
    ]
  }),

  async created() {
    var items=(await api.list("Solution", {solutionType: "Partners"})).items;
    for(var i in items)
      this.solutions.push(
        {
          "label" : items[i].nameSolution,
          "value" : items[i].id
        }
      )
  },

  computed: {
    pubLink() {
      var solModId=(this.solution%4096)^2650;
      var solVal=97-solModId%97;
      var solCode=solVal.toString(16).padStart(2, "0")+
                  solModId.toString(16).padStart(3, "0");
      return location.origin+"/register/"+solCode+"-p";
    },
    forms() {
      var forms = [
        {'value': null, label: ''}
      ]
      if(this.settings?.enrollmentForms?.partners) {
        forms.push(
          {'value': 'Partner Signup', label: 'Partner Signup'}
        )
      }
      return forms
    }
  },

  methods: {
    async generateTempLink() {
      if (!this.solution)
        return;
      var result=await api.action("TempLink", "new", "generate", {type: 'Agent', solution: this.solution});
      this.tempLink=result.action.link;

    },
    doCopy(text) {
      copyToClipboard.doCopyText(text);
    }
  },

  watch: {
    emailType: {
      handler() {
        if(this.emailType) {
          this.tempLink = null
        }
      }
    },

    tempLink: {
      handler() {
        if(this.tempLink) {
          this.emailType = null
        }
      }
    }
  }
}
</script>
