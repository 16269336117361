<template>
<form class="edit-system">
  <div class="field">
    <label>System Logo:</label>
    <photo-obj :basepath="basePath" v-model="logoFile" />
  </div>
  <div class="field">
    <label>Footer Logo:</label>
    <photo-obj :basepath="basePath" v-model="footerLogoFile" />
  </div>
  <div class="field">
    <label>Footer Text:</label>
    <input type="text" v-model="footerText" style="background: white; border: 1px solid #ccc; width: 60%" />
  </div>
  <br />
  <div class="buttons">
    <button class="btn btn-large btn-primary" v-on:click.prevent="updateSettings">Submit</button>
  </div>  
</form>
</template>

<script>
export default {
  data() { return {
    logoFile: null,
    footerLogoFile: null,
    basePath: "",
    footerText: "",
  }},
  mounted() {
    this.refresh();
  },
  methods: {
    refresh() {
      var that=this;
      api.get("Settings", 1).then(function(data) {
        that.logoFile=data.item.logo;
        that.footerLogoFile=data.item.footerLogo;
        that.footerText=data.item.footerText;
        that.basePath=data.desc.logo.basepath;
      });
    },
    updateSettings: async function() {
      startLoading();
      if (this.logoFile instanceof File) {
        if (!window.validateFileExt(this.logoFile))
          return stopLoading("Invalid file extension", "error");
        await api.upload("Settings", 1, this.logoFile);
      }
      if (this.logoFile===null)
        await api.update("Settings", 1, {logo: null});
      if (this.footerLogoFile instanceof File) {
        if (!window.validateFileExt(this.footerLogoFile))
          return stopLoading("Invalid file extension", "error");
        await api.upload("Settings", 1, this.footerLogoFile, {field: "footerLogo"});
      }
      if (this.footerLogoFile===null)
        await api.update("Settings", 1, {footerLogoFile: null});
      await api.update("Settings", 1, {footerText: this.footerText});
      stopLoading("Logo updated");
    },
  },
}
</script>
