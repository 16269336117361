<template>
<div>
<form class="scan-filter" v-on:submit.prevent="activateFilter">
  <div class="actions">
    <button class="btn" v-on:click.prevent="refresh">Get list</button>
  </div>
  <ul class="filter">

  </ul>
</form>
<ul class="acc-results">
  <li class="active">
    <h4><span class="label">G2 Monitoring List</span></h4>
    <div class="acc-list" style="display: block;">
        <table>
            <thead>
                <tr>
                    <th>G2 MDM ID</th>
                    <th>Company Name</th>
                    <th>Date</th>
                    <th>URL</th>
                    <th>Active</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in itemsToShow">
                  <td>{{ item.attributes && item.attributes.id_1 }}</td>
                  <td>{{ item.attributes && item.attributes.merchant_name }}</td>
                  <td>{{ item.attributes && formatDate(item.attributes.created_at, "YYYY-MM-DD") }}</td>
                  <td>{{ item.attributes && item.attributes.url }}</td>
                  <td>{{ 'Yes' }}</td>
                  <td>
                     <a href="" v-on:click.prevent="doDelete(item)" class="tb-btn">Delete</a> 
                  </td>
                </tr>
            </tbody>
        </table>
    </div>
</li>
</ul>
</div>
</template>
<script>
export default {
  data: ()=>({
    filter: {
      dateFrom: null,
      dateTo: null,
      account: null,
      accountNo: null,
      complete: null,
    },
    activeFilter: {},
    items: [],
    yesNoNull: [
      {value: null, label: "-"},
      {value: "false", label: "No"},
      {value: "true", label: "Yes"},
    ],
  }),
  created() {
  },
  computed: {
    itemsToShow() {
      return this.items.filter(a=>{
        return true;
      });
    },
  },
  methods: {
    async refresh() {
      startLoading();
      await this.listItems();
      stopLoading();
    },
    async listItems() {
      this.items=(await api.action("Merchant", "new", "getG2Mdm")).action.list;
    },
    async doDelete(item) {
      startLoading();
      await api.action("Merchant", "new", "deleteG2mdmRaw", {id: item.attributes.id_1});
      await this.listItems();
      stopLoading();
    },
  },
  mixins: [vueMixins.dateFormatter],
}
</script>
<style lang="scss">
.scan-filter {
  display: flex;
  margin-bottom: 20px;
  .actions button {
    text-decoration: none;
    padding: 4px 20px;
    border: 1px solid black;
    border-radius: 3px;
    color: white;
    background: rgb(50,50,50);
    background: linear-gradient(to bottom, rgba(50,50,50,1) 0%,rgba(5,5,5,1) 100%);
  }
  .filter {
    margin-left: 20px;
    display: flex;
    li {
      margin-right: 20px;
      display: flex;
      align-items: middle;
      label {
        margin-right: 6px;
        line-height: 26px;
      }
      .hasDatepicker {
        width: 120px;
      }
    }
  }
}
</style>
