<template>
<div class="preform-outer">
<div class="preformbg"></div>
<div class="ncontent appf appnat_ preform pfx">
  <div class="cb-logo" :style="{'background-image': 'url(&quot;'+companydata.appHeadLogo+'&quot;)' }">CardBiller</div>
  <h1 v-if="step<7">Merchant Application</h1>
  <div class="form-info">
    <p>{{ companydata.appHeadText }}</p>
    <p><strong>{{ companydata.appHeadEmail }}</strong></p>
    <p><strong>{{ companydata.appHeadPhone }}</strong></p>
  </div>
  <p class="form-intro">
    {{ companydata.appHeadDesc }}
  </p>
  <form-progress :step="step" :steps="['Type', 'Checklist', 'Company Profile', 'Ownership Profile', 'Business Profile', 'Banking Profile', 'Contact', 'Questionnaire', 'Notes & Information']" />
  
  
  <form-apply-0 :app="app" v-if="step==0" v-on:next="nextStep" :mode="mode" />
  <form-apply-1 :app="app" v-if="step==1" v-on:next="nextStep" />
  <form-apply-2 :app="app" v-else-if="step==2" v-on:next="nextStep" v-on:save="save" v-on:prev="prevStep" />
  <form-apply-3 :app="app" v-else-if="step==3" v-on:next="nextStep" v-on:save="save" v-on:prev="prevStep" />
  <form-apply-4 :app="app" v-else-if="step==4" v-on:next="nextStep" v-on:save="save" v-on:prev="prevStep" />
  <form-apply-5 :app="app" v-else-if="step==5" v-on:next="nextStep" v-on:save="save" v-on:prev="prevStep" />
  <form-apply-6 :app="app" v-else-if="step==6" v-on:next="nextStep" v-on:save="save" v-on:prev="prevStep" />
  <form-apply-6a :app="app" v-else-if="step==7" v-on:next="nextStep" v-on:save="save" v-on:prev="prevStep" />
  <form-apply-7 :app="app" :basepath="basepath" :files="files" v-else-if="step==8" v-on:save="save" v-on:prev="prevStep"  v-on:next="submit" />
  <div class="pre-done" v-else-if="step==9">
    <h1>Thank you for your application</h1>
    <p>One of our relationship managers will contact you shortly!</p>
  </div>
<!--   <div class="version">v1.0</div> -->
</div>
</div>
</template>


<script>
export default {
  props: ["auxtype"],
  data() { return {
    step: (window.existingApplication || {}).currentStep*1 || 0,
    app: window.existingApplication || {
      status: "A99",
      companyNew: false,
      hasWebsite: null,
      source: appData.currentUser.id,
      solution: appData.solution || null,
      requirements: {},
      ignore: {},
      directors: [{}],
      ubos: [{}],
      history: [{}, {}, {}, {}, {}, {}],
      accounts: [{accountCurrency: "124"}],
      contacts: [{type:'general'}, {type:'technical'}, {type:'financial'}, {type:'risk'}],
      documents: [],
      industry: null,
      apiMessaging: true,
      apiUpdates: true,
      questionnaire: {
        date: moment().format("YYYY-MM-DD"),
        
      },
    },
    companydata: appData.company,
    link: window.existingLink || null,
    files: {},
    errorMessage: null,
    errorCode: null,
    errorFields: [],
    savedVal: null,
  }},
  computed: {
    basepath() {
      return "files/application/"+this.app.id;
    },
  },
  mounted() {
    this.loadSaved();
  },
  methods: {
    nextStep() {
      this.step++;
      $("html, body").animate({scrollTop: 0}, 300);
    },
    prevStep() {
      this.step--;
      $("html, body").animate({scrollTop: 0}, 300);
    },
    loadSaved: async function() {
    },
    save: async function() {
      if (!this.app.applicationId)
        this.app.applicationId="APP_ID_GEN_APPL";
      var data=JSON.parse(JSON.stringify(this.app));
      delete data.ignore;
      delete data.id;
      delete data.documents;

      data.currentStep=this.step;
      data.auxType=this.auxtype;
      
      data.documents=[];
      for(var fileField in this.files) {
        var file=this.files[fileField];
        if (file instanceof File)
          data.documents.push({
            type: fileField,
          });
      }
      
      var steps=1;
      var step=0;
      for(var fileField in this.files) {
        var file=this.files[fileField];
        if (file instanceof File)
          steps++;
      }
      startLoading(steps);
      
      var result=await extapi.createApplication({
        obj: data,
//         mmid: window.appData.mmid,
//         username: window.appData.username,
//         password: window.appData.password,
//         ipAddress: window.appData.ipAddress
      });
      if (result.errorCode) {
        this.errorCode=result.errorCode;
        this.errorMessage=result.errorMessage;
        this.errorFields=result.errorFields || [];
        return stopLoading(result.errorCode+" "+result.errorMessage, "error");
      } else {
        this.errorCode="Success!";
        this.errorMessage="";
        this.errorFields=[];
      }
      
      this.app.id=result.id;
      this.app.token=result.token;
      progressLoading(++step);

      for(var fileField in this.files) {
        var file=this.files[fileField];
        if (file instanceof File) {
          await extapi.createApplicationDoc(file, fileField, this.app.id, this.app.token, {
//             mmid: window.appData.mmid,
//             username: window.appData.username,
//             password: window.appData.password,
//             ipAddress: window.appData.ipAddress
          });
          progressLoading(++step);
        }
      }
      stopLoading("Application submitted", "success");
      this.step=9;  
    },
    submit: async function() {
      await this.save();
    },
    reset: async function() {
      this.app={
        status: "A99",
        companyNew: false,
        hasWebsite: null,
        source: appData.currentUser.id,
        solution: appData.solution || null,
        requirements: {},
        ignore: {},
        directors: [{}],
        ubos: [{}],
        history: [{}, {}, {}, {}, {}, {}],
        accounts: [{accountCurrency: "124"}],
        contacts: [{type:'general'}, {type:'technical'}, {type:'financial'}, {type:'risk'}],
        documents: [],
        industry: null,
      };
    }
  },
}
</script>
