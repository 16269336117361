<template>
  <div>
    <div class="content-table-container file-list-container">
      <table class="file-list content-table">
        <thead>
          <tr>
            <td>Created</td>
            <td>Description</td>
            <td>Number</td>
            <td>Filename</td>
            <td>Status</td>
            <td>Options</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="document in item.documents" :key="document.number">
            <td>{{ formatDate(document.created, "YYYY-MM-DD") }}</td>
            <td>{{ document.description }}</td>
            <td>{{ document.number }}</td>
            <td>
              <a :href="fileLink(document.filename, document.id)" target="_blank">{{ document.filename || "(no file)" }}</a>
            </td>
            <td>{{ document.status }}</td>
            <td>
              <template v-if="document.status == 'Pending'">
                <span @click="updateDocumentOk(document)" class="option-btn">
                  <img src="/assets/img/table-approve.png" />
                </span>
                <span @click="updateDocument(document)" class="option-btn">
                  <img src="/assets/img/table-deny.png" />
                </span>
                <span @click="updateDocumentArchived(document)" class="option-btn">
                  DEL
                </span> 
              </template>
            </td>
          </tr>

          <tr v-for="document in item.customDocuments" :key="document.number">
            <td>{{ formatDate(document.created, "YYYY-MM-DD") }}</td>
            <td>{{ document.description }}</td>
            <td>{{ document.number }}</td>
            <td>
              <a :href="fileLinkCustom(document.filename, document.id)" target="_blank">{{ document.filename || "(no file)" }}</a>
            </td>
            <td>{{ document.status }}</td>
            <td>
              <template v-if="document.status == 'Pending'">
                <span @click="updateOk(document)" class="option-btn">
                  <img src="/assets/img/table-approve.png" />
                </span>
                <span @click="update(document)" class="option-btn">
                  <img src="/assets/img/table-deny.png" />
                </span>
                <span @click="updateArchived(document)" class="option-btn">
                  DEL
                </span> 
              </template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <portal to="actions">
      <ul>
        <li>
          <a href="" v-on:click.prevent="newFile=true">New File</a>
        </li>
      </ul>
    </portal>

    <popup :open.sync="newFile">
      <merchant-document-popup v-if="newFile" v-model="item" :newFile.sync="newFile" v-on:reloadnotes="$emit('reloadnotes')" />
    </popup>

    <popup :open.sync="statusPopup">
      <div class="static-page privacy-popup">
        <h2><strong>Update Document Status</strong></h2>
        <br/>
        <div class="content-form">
          <div class="form-row form-fullw">
            <form-field-select :validator="$v.selectedStatus" v-model="selectedStatus" :label="`Select Document Status`" required :options="statusOptions" />
            <p>{{ selectedText }}</p>
          </div>
          <br/>
          <br/>
          <div class="form-row form-split">
            <div>
              <button :disabled="!selectedStatus" @click="submit()">Submit</button>
            </div>
            <div>
              <button @click="statusPopup = false, selectedStatus = null">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </popup>

    <popup :open.sync="statusDocumentPopup">
      <div class="static-page privacy-popup">
        <h2><strong>Update Document Status</strong></h2>
        <br/>
        <div class="content-form">
          <div class="form-row form-fullw">
            <form-field-select :validator="$v.selectedStatus" v-model="selectedStatus" :label="`Select Document Status`" required :options="statusOptions" />
            <p>{{ selectedText }}</p>
          </div>
          <br/>
          <br/>
          <div class="form-row form-split">
            <div>
              <button :disabled="!selectedStatus" @click="submitDocument()">Submit</button>
            </div>
            <div>
              <button @click="statusDocumentPopup = false, selectedStatus = null">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </popup>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
export default {
  props: ["item"],
  data: ()=>({
    newFile: false,
    statusPopup: false,
    statusDocumentPopup: false,
    statuses: [],
    selectedStatus: null,
  }),
  computed: {
    statusOptions() {
      var options = [];
      for(const status of this.statuses)
        options.push(
          {
            id: `${status.status}`,
            text: `${status.status} - ${status.description.substr(0, 50)}`
          }
        )
      return options
    },

    selectedText() {
      return this.statuses.find(item =>  item.status == this.selectedStatus)?.description
    }
  },

  async created() {
    this.statuses = (await api.list('Status', {category: 'Compliance'})).items
  },

  methods: {
    fileLinkCustom(fileName, docId) {
      if (!fileName)
        return null;
      if (docId)
        return "download/ApplicationCustomDocument/"+docId;
      return "files/application/"+this.item.id+"/"+fileName;
    },
    
    fileLink(fileName, docId) {
      if (!fileName)
        return null;
      if (docId)
        return "download/ApplicationDocument/"+docId;
    },
    
    async updateOk(document) {
      await api.update("ApplicationCustomDocument", document.id, {
            "status": 'Ok'
          });
      document.status = 'Ok'
      await this.createNote(document)
    },

    async updateArchived(document) {
      await api.update("ApplicationCustomDocument", document.id, {
            "status": 'Archived'
          });
      document.status = 'Archived'
      await this.createNote(document)
    },

    async update(document) {
     this.selectedDocument = document
     this.statusPopup = true
    },

    async updateDocumentOk(document) {
      await api.update("ApplicationDocument", document.id, {
            "status": 'Ok'
          });
      document.status = 'Ok'
      await this.createNote(document)
    },

    async updateDocumentArchived(document) {
      await api.update("ApplicationDocument", document.id, {
            "status": 'Archived'
          });
      document.status = 'Archived'
      await this.createNote(document)
    },

    async updateDocument(document) {
     this.selectedDocument = document
     this.statusPopup = true
    },

    async submit () { 
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$toast.error("You need to fill all required fields. We marked them with red color so that you know what fields you have missed.")
        return
      }
      startLoading()
      try {
        await api.update("ApplicationCustomDocument", this.selectedDocument.id, {
            "status": this.selectedStatus
          });
        this.selectedDocument.status = this.selectedStatus
        this.statusPopup = false
        this.selectedStatus = null
        await this.createNote(this.selectedDocument)
        stopLoading("")
      } catch (e) {
        stopLoading(e, 'error')
      }
    },

    async submitDocument () { 
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$toast.error("You need to fill all required fields. We marked them with red color so that you know what fields you have missed.")
        return
      }
      startLoading()
      try {
        await api.update("ApplicationDocument", this.selectedDocument.id, {
            "status": this.selectedStatus
          });
        this.selectedDocument.status = this.selectedStatus
        this.statusPopup = false
        this.selectedStatus = null
        await this.createNote(this.selectedDocument)
        stopLoading("")
      } catch (e) {
        stopLoading(e, 'error')
      }
    },

    async createNote(document) {
      const note = (await api.create("Note", {
        message: `Updated document ${document.description}, Pending to ${document.status}`,
        parentId: this.item.id,
        type: 'Application',
        created: "CURRENT_TIMESTAMP",
        author: appData.currentUser.id,
      }, true)).item
      await this.$emit("reloadnotes")
    }
  },
  
  validations () {
    const rules = {
      selectedStatus: { required },
    }

    return rules
  },

  mixins: [vueMixins.dateFormatter],
}
</script>
<style scoped>
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  margin-right: 8px;
  color: black;
  font-size: 16px;
  display: block;
  padding: 6px 20px;
  box-sizing: border-box;
  border: 1px solid #b3b3b3;
  border-radius: 6px;
  background: #e4e4e4;
  background: linear-gradient(to bottom, #e4e4e4 0%, #cfcfcf 100%);
}
button:hover, button:focus {
  background: #e9f2ff;
  background: linear-gradient(to bottom, #e9f2ff 0%, #deebff 100%);
  border-color: #6192fc;
  color: #6192fc;
}
</style>
