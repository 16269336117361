<template>
<div>
  <page-header :title="title" :breadcrumbs="breadcrumbs" />

  <sub-menu
    :dashboard="{ to: '/tools/chammp-scheduler', text:'Scheduler Dashboard' }"
    :menu="menu" />
</div>
</template>

<script>
export default {
  props: {
    page: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      default: 'Chammp Scheduler Dashboard',
      menu: [
        {
          text: 'Calendar',
          list: [
            {
              text: 'View',
              to: '/tools/chammp-scheduler/calendar'
            },
            {
              text: 'Add New Event',
              to: '/tools/chammp-scheduler/add-event'
            }
          ]
        },
        {
          text: 'Tasks',
          list: [
            {
              text: 'Task List',
              to: '/tools/chammp-scheduler/task-list'
            }
          ]
        }
      ]
    }
  },

  computed: {
    title () {
      return this.page ? this.page.text : this.default
    },

    breadcrumbs () {
      const breadcrumbs =  [{
        text: 'Chammp Scheduler Dashboard',
        to: '/tools/chammp-scheduler/'
      }]

      
      if (this.page) {
        if (this.page.breadcrumbs) {
          breadcrumbs.push(...this.page.breadcrumbs)
        }

        breadcrumbs.push({
          text: this.page.text
        })
      }
      
      return breadcrumbs
    }
  }
}
</script>
