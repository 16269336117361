<template>
<popup v-model="open" @close="close()" auto-width>
  <div style="padding: 10px 50px 30px 50px" class="text-center">
    <template v-if="agents.length && leads">
      Assign Selected Lead(s) To:
      <div class="mt-20 mb-20">
        <validator :value="form.assignedTo" reqs="required">
          <select placeholder="Select Agent" v-model="form.assignedTo">
            <option
              v-for="user in agents"
              :key="user.id"
              :value="user.id">
              {{ user.agentId }} - {{ item.agentDb.name }}
            </option>
          </select>
        </validator>
      </div>
      <button class="btn btn-small align-center" @click="save()">Assign</button>
    </template>
    <div v-else>
      <div class="mb-20">Lead can't be assigned because there are no available agents to assign it to.</div class="mb-20">
      <button class="btn btn-small align-center" @click="close()">Okay</button>
    </div>
  </div>
</popup>
</template>

<script>
export default {
  mixins: [ formValidation.mixin, vueMixins.helpers ],

  props: {
    leads: {
      type: Array,
      default: null
    }
  },

  data () {
    return {
      agents: [],
      form: {
        assignedTo: null,
        status: 'AMA10'
      }
    }
  },

  computed: {
    open () {
      return !!(this.leads && this.leads.length)
    },

    showIds () {
      return this.leads.map(lead => this.formatLeadId(lead.id)).join(', ')
    }
  },

  async created () {
    this.agents = (await api.list("User", { agentDbId: ["IS NOT NULL"] })).items.filter(user => user.agentDb.status === 'A08')
  },

  methods: {
    async save () {
      if (!this.validate() || !this.form.assignedTo) return stopLoading('Please select agent.', 'error')
      startLoading()
      const leads = []
      const agent = this.agents.find(user => user.id === this.form.assignedTo)
      for(let lead of this.leads) {
        leads.push((await api.update('AmadelLead', lead.id, this.form, true)).item)
        const data = {
          message: `Lead Assigned to ${agent.agentId}`,
          created: "CURRENT_TIMESTAMP",
          author: window.appData.currentUser.id,
          system: 1,
          type: 'Lead',
          parentId: lead.id
        }
        await api.create("Note", data)
      }
      this.form.assignedTo = null
      this.$emit('assign', leads)
      stopLoading('Lead assigned successfully.')
    },

    close () {
      this.form.assignedTo = null
      this.$emit('close')
    }
  },
}
</script>
