<template>
<div class="preform-outer">
<div class="preformbg"></div>
<div class="ncontent appf appnat_ preform pfx">
  <div class="cb-logo" :style="{'background-image': 'url(&quot;/files/company/'+companydata.appHeadLogo+'&quot;)' }">CardBiller</div>
  <h1 v-if="step<7">Merchant Application</h1>
  <div class="form-info">
    <p>{{ companydata.appHeadText }}</p>
    <p><strong>{{ companydata.appHeadEmail }}</strong></p>
    <p><strong>{{ companydata.appHeadPhone }}</strong></p>
  </div>
  <p class="form-intro">
    {{ companydata.appHeadDesc }}
  </p>
  <div class="temp-link">
    <label>Guest access to this application:</label>
    <span v-if="!link">
      <button v-on:click.prevent="save">Generate and Save Link</button>
    </span>
    <a v-else :href="link.url" target="_blank" v-on:click.prevent="copyLink">{{ link.url }}</a>
  </div>
  <form-progress :step="step" :steps="['Type', 'Checklist', 'Company Profile', 'Ownership Profile', 'Business Profile', 'Banking Profile', 'Contact', 'Questionnaire', 'Notes & Information']" />
  
  
  <form-apply-0 :app="app" v-if="step==0" v-on:next="nextStep" :mode="mode" />
  <form-apply-1 :app="app" v-if="step==1" v-on:next="nextStep" />
  <form-apply-2 :app="app" :solution="solution" v-else-if="solution && step==2" v-on:next="nextStep" v-on:save="save" v-on:prev="prevStep" />
  <form-apply-3 :app="app" :solution="solution" v-else-if="solution && step==3" v-on:next="nextStep" v-on:save="save" v-on:prev="prevStep" />
  <form-apply-4 :app="app" v-else-if="step==4" v-on:next="nextStep" v-on:save="save" v-on:prev="prevStep" />
  <form-apply-5 :app="app" v-else-if="step==5" v-on:next="nextStep" v-on:save="save" v-on:prev="prevStep" />
  <form-apply-6 :app="app" v-else-if="step==6" v-on:next="nextStep" v-on:save="save" v-on:prev="prevStep" />
  <form-apply-6a :app="app" v-else-if="step==7" v-on:next="nextStep" v-on:save="save" v-on:prev="prevStep" />
  <form-apply-7 :app="app" :basepath="basepath" :files="files" v-else-if="step==8" v-on:save="save" v-on:prev="prevStep"  v-on:next="submit" />
  <div class="pre-done" v-else-if="step==9">
    <h1>Thank you for your application</h1>
    <p>You will be contacted shortly!</p>
  </div>
<!--   <div class="version">v1.0</div> -->
</div>
</div>
</template>


<script>
export default {
  props: ["auxtype", "solutionId"],
  data() { return {
    solution: null,
    step: (window.existingApplication || {}).currentStep*1 || 0,
    app: window.existingApplication || {
      mainType: null,
      status: "A99",
      companyNew: false,
      hasWebsite: null,
      createdDate: "CURRENT_TIMESTAMP",
      os: "CURRENT_OS",
      useragent: "CURRENT_BROWSER",
      ip: "CURRENT_IP",
      source: appData.currentUser.id,
      solution: null,
      requirements: {},
      ignore: {},
      directors: [{}],
      ubos: [{}],
      history: [{}, {}, {}, {}, {}, {}],
      accounts: [{accountCurrency: "124"}],
      contacts: [{type:'general'}, {type:'technical'}, {type:'financial'}, {type:'risk'}],
      documents: [],
      industry: null,
      questionnaire: {
        date: moment().format("YYYY-MM-DD"),
        
      },
      companyType: "Inc.",
      parentType: "Inc.",
      accountType: window.appMode=="merchant"?"merchant":"master",
      extraData: {},
    },
    companydata: appData.company,
    link: window.existingLink || null,
    files: {},
    mode: window.appMode,
  }},
  computed: {
    basepath() {
      return "files/application/"+this.app.id;
    },
  },
  async mounted() {
    if (!this.app.questionnaire)
      this.$set(this.app, "questionnaire", {});
    this.$set(this.app, "hasWebsite", this.app.hasWebsite*1?'1':'0');
    this.$set(this.app, "affiliate", this.app.affiliate*1?'1':'0');
    this.$set(this.app, "useAVS", this.app.useAVS*1?'1':'0');
    this.$set(this.app, "useCVC", this.app.useCVC*1?'1':'0');
    this.$set(this.app, "use3dSecure", this.app.use3dSecure*1?'1':'0');
    this.updateFiles();
    this.solution = (await api.get('Solution', this.solutionId)).item
    if (this.solutionId)
      this.app.solution=this.solutionId;
  },
  methods: {
    updateFiles() {
      for(var document of this.app.documents) {
        this.$set(this.files, document.type, document.filename);
      }
    },
    nextStep() {
      this.step++;
      if (this.step==1) {
        if (this.app.mainType=='cp') {
          this.$set(this.app, 'hasWebsite', '0');
          this.$set(this.app, 'affiliate', '0');
          this.$set(this.app, 'useAVS', '0');
          this.$set(this.app, 'useCVC', '0');
          this.$set(this.app, 'use3dSecure', '0');
        } else if (this.app.mainType=='cnp') {
          this.$set(this.app, 'hasWebsite', '1');
        }
      }
      $("html, body").animate({scrollTop: 0}, 300);
    },
    prevStep() {
      this.step--;
      $("html, body").animate({scrollTop: 0}, 300);
    },
    save: async function() {
      if (!this.app.applicationId)
        this.app.applicationId="APP_ID_GEN_APPL";
      var data=JSON.parse(JSON.stringify(this.app));
      delete data.ignore;
      delete data.id;
      delete data.documents;

      data.currentStep=this.step;
      data.createdDate="CURRENT_TIMESTAMP";
      data.auxType=this.auxtype;
      if (data.industry=='Facilitator') {
        data.industry='';
        data.auxType='Facilitator';
      }
      
      var steps=1;
      var step=0;
      for(var fileField in this.files) {
        var file=this.files[fileField];
        var existingId=null;
        for(var document of this.app.documents)
          if (document.type==fileField)
            existingId=document.id;
        if (file instanceof File)
          steps++;
        else if (file===null && existingId)
          steps++;
      }
      startLoading(steps);
      
      if (this.app.id)
        var result=await api.update("Application", this.app.id, data);
      else {
        var result=await api.create("Application", data);
        this.app.id=result.id;
      }
      progressLoading(++step);
      if (!this.link) {
        var result=await api.action("TempLink", "new", "generate", {type: "Application", id: this.app.id, solution: this.app.solution});
        this.link=result.item;
        this.link.url=result.action.link;
      }
      for(var fileField in this.files) {
        var file=this.files[fileField];
        var existingId=null;
        for(var document of this.app.documents)
          if (document.type==fileField)
            existingId=document.id;
        if (file instanceof File) {
          if (!existingId) {
            var description = this.app.extraData[fileField]
            var res=await api.create("ApplicationDocument", {appId: this.app.id, type: fileField, description: description, created: "CURRENT_TIMESTAMP"});
            existingId=res.id;
          } else {
            await api.update("ApplicationDocument", existingId, {created: "CURRENT_TIMESTAMP"});
          }
          await api.upload("ApplicationDocument", existingId, file);
          progressLoading(++step);
        }
        if (file===null && existingId) {
          await api.delete("ApplicationDocument", existingId);
          progressLoading(++step);
        }
      }
      if (result.success)
        await api.action("Application", this.app.id, "negative");
      if (this.submitMessage)
        stopLoading("Application submitted", "success");
      else
        stopLoading("Application saved", "success");      
      var res=await api.get("Application", this.app.id);
      this.app=res.item;
      this.updateFiles();
    },
    submit: async function() {
      this.app.status="A02";
      this.app.submitDate="CURRENT_TIMESTAMP";
      this.app.accountId="APP_ID_GEN_ACCT";
//       this.app.mmid="APP_ID_GEN_MMID";
      if (appData.currentUser && appData.currentUser.agentId)
        this.app.submitSource=appData.currentUser.id;
      this.submitMessage=true;
      await this.save();
      this.nextStep();
    },
    copyLink() {
      copyToClipboard.text=this.link.url;
      copyToClipboard.doCopy();
    },
  },
}
</script>
