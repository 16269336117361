<template>
  <div class="ncontent nc-flat">
    <tabs class="tabs">
      <tab title="New Enrollment" @click="openLink('/applications/new')" />
      <tab title="Merchants Overview" active />
    </tabs>

    <div class="ncontent marginless">
      <a class="back-button" href="/">Dashboard</a>
      <div class="header split">
        <div>
          <ul class="breadcrumbs">
            <li><a>Application Overview</a></li>
          </ul>
          <div class="toolbar"></div>
          <app-search-form :statuses="statuses" :saved="parsedSaved" />
        </div>
        <aside>
          <h3>Breakdown</h3>
          <breakdown-table :statuses="statuses" />
        </aside>
      </div>
      <div class="hr" />

      <div class="content">
        <accordeon-results-application />
      </div>
    </div>
  </div>
</template>

<script>
import Tab from "./components/tabs/Tab";
import Tabs from "./components/tabs/Tabs";

export default {
  components: {
    Tab,
    Tabs,
  },

  props: ["saved", "statuses"],

  computed: {
    parsedSaved() {
      return this.saved === "true";
    },
  },

  methods: {
    openLink(link) {
      window.location.href = link;
    },
  },
};
</script>