<template>
  <div class="account-dropdown">
    <div class="text">
      <span class="welcome-user">Welcome back, {{ user.username }}</span>
      <span class="label">{{ label }}</span>
      <span class="label"
        >Last login: {{ formatDate(user2.lastLogin, "LLL") }}</span
      >
    </div>
    <ul>
      <li>
        <a :href="`/your-profile/${user.username}`"> My Profile </a>
      </li>
      <li>
        <a href="/sys-options"> Options </a>
      </li>
      <li>
        <a href="/login?logout=1"> Logout </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  mixins: [vueMixins.dateFormatter],

  computed: {
    lastLogin() {},

    merchant() {
      return window.appData.merchant;
    },

    agentId() {
      return window.appData.agentId;
    },

    user() {
      return window.appData.currentUser;
    },

    user2() {
      return window.appData.currentUser2;
    },

    label() {
      if (this.agentId && this.user2.applicationId) {
        return `Account ID: ${this.agentId}`;
      }

      if (this.merchant) {
        return `Account mode: ${this.merchant.accountMode}`;
      }

      if (this.agentId) {
        return `Partner ID: ${this.agentId}`;
      }

      return "Administration";
    },
  },
};
</script>

<style lang="scss" scoped>
.account-dropdown {
  font-size: 14px;
  font-weight: 600;
  color: #292929;
  .text {
    .welcome-user {
      font-weight: bold;
    }
    .label {
      display: block;
      font-size: 12px;
      color: #8f979e;
      margin-top: 5px;
    }
  }

  ul li {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    a {
      &:hover {
        color: #51b13e;
      }
    }
  }

  .text,
  ul li a {
    display: block;
    padding: 10px 15px;
    white-space: nowrap;
  }
}
</style>