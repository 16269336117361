import { render, staticRenderFns } from "./formel-money-amount4.vue?vue&type=template&id=5d2e4706&"
import script from "./formel-money-amount4.vue?vue&type=script&lang=js&"
export * from "./formel-money-amount4.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports