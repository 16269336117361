<template>
  <div>
    <!-- <div class="new-btn">
      <button class="btn btn-small btn-primary mb-20" v-on:click.prevent="newItem = true">New</button>
    </div>
    <br/> -->
    <template>
      <div class="provider-form pad20" v-for="item in languages">
        <div class="input-group">
          <label>Name</label>
            {{ item.name }}
        </div>
        <div class="input-group">
          <label>Short Name</label>
            {{ item.shortName }}
        </div>
        <div class="input-group">
          <label>Enabled</label>
          <mnb-switch :disabled="true" v-model="item.enabled" />
        </div>
      </div>
    </template>
  </div>
  </template>
  
  <script>
    import MnbSwitch from './switch.vue'

  export default {
    components: {
      MnbSwitch,
    },
    
    data() { return {
      reviewDays: [
        {value: 30, label: 30},
        {value: 60, label: 60},
        {value: 90, label: 90},
        {value: 180, label: 180},
        {value: 365, label: 365},
      ],
  
      form : {
        partnerTier: null,
        description: null,
        reviewDays: null,
        createdAt: 'CURRENT_TIMESTAMP',
      },
      
      newItem: false,
      languages: [],
    }},
    mounted() {
      this.loadData();
    },
    methods: {
      doDelete: async function(item) {
        item.status = 'Archived'
        await api.update("Languages", item.id, item);
        this.providers = []
        this.loadData();
      },

      loadData: async function() {
        var connexPromise=api.list("Languages");
        var languages=(await connexPromise).items;
        for(var language of languages) {
          language.editing = false
            this.languages.push(language);
        }
      },
  
      newProvider() {
        this.providers.push({
          id: null,
          providerType: "",
          providerName: "",
          version: "",
          username: "",
          providerPassword: "",
          providerKey: "",
          status: "",
          notes: "",
          certifiedDate: null,
          providerKeyExpiry: null,
          updatedAt: 'CURRENT_TIMESTAMP',
          editing: true,
        });
      },
      saveItem: async function(item) {
        if (item.id)
          await api.update("Provider", item.id, item);
        else
          await api.create("Provider", item)
  //      item.editing=false;
        this.providers = []
        this.loadData()
      },

      saveNewItem: async function() {
        if (!this.validate())
          return stopLoading("Please fill all required fields", "error");
        await api.create("PartnerTier", this.form)
        this.partners = []
        this.form = {
          partnerTier: null,
          description: null,
          reviewDays: null,
          createdAt: 'CURRENT_TIMESTAMP',
        }
        this.loadData()
        return stopLoading("Partner Tier Added.", "success");
      },
      
      resetItem: async function() {
        this.form = {
          partnerTier: null,
          description: null,
          reviewDays: null,
          createdAt: 'CURRENT_TIMESTAMP',
        }
      },

      showItem(item) {
        providerEdit.showPopup(item);
      },
    },
    mixins: [vueMixins.dateFormatter, formValidation.mixin],
  }
  </script>
  