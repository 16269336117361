<template>
<div>
  <ul class="appview-list" v-if="!loading">
    <li v-if="!uwList.length">
      Pending
    </li>
    <li v-else>
      Profile set
    </li>

  </ul>
  <div class="actions" v-if="!loading && !uwList.length">
    <button v-on:click.prevent="doSet">Set</button>
  </div>
  <div class="actions" v-if="uwList.length">
    <button v-on:click.prevent="doView">View</button>
    <button v-on:click.prevent="doSet">Reset</button>
    <button v-on:click.prevent="doNext">Next</button>
  </div>
</div>
</template>

<script>
export default {
  props: ["item"],
  data: ()=>({
    loading: true,
    uwList: [],
  }),
  methods: {
    listIntake: async function() {
      this.uwList=(await api.list("Intake", {appId: this.item.id}, ["id:DESC"])).items;
      this.loading=false;
    },
    async doView() {
      window.open("/applications/"+this.item.id+"/intakepdf/"+this.uwList[0].id, "_blank");
    },
    async doSet() {
      window.open("/applications/"+this.item.id+"/profile", "_self");
    },
    async doNext() {
//       await this.save();
      this.$emit("next");
    },
    async save() {
//       startLoading();
//       await api.update("User", this.item.id, updateItem);
//       stopLoading("Agent updated");
    },
  },
  computed: {

  },
  mounted() {
    this.listIntake();
  },
}
</script>
