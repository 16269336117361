<template>
<multiselect :options="dynOptions" v-model="dynValue" :hasOther="0" :readonly="readonly">

</multiselect>
</template>

<script>
export default {
  props: ["value", "readonly"],
  data() { return {
    dynValue: [],
    dynOptions: ["CAD", "USD"],
  }},
  watch: {
    dynValue: {
      handler() {
        this.$emit("input", this.dynValue.join(","));
      },
      immediate: true,
    },
    value: {
      handler() {
        this.dynValue=(this.value || "").split(",").filter(function(a) { return a; });
      },
      immediate: true,
    },
  },
}
</script>
