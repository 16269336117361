<template>
<div class="setup-box" v-show="shown"><div>
  <div class="ncontent appf appnat">
<!--     <a class="close" href="" v-on:click.prevent="dismiss">&times;</a> -->
    <h3 v-if="step<5" style="text-align: center">Merchant Setup</h3>
    <h3 v-else style="text-align: center">Finalize</h3>
    
    <form-progress :step="step" :steps="['Merchant&lt;br&gt;Profile', 'Company&lt;br&gt;Profile', 'Pricing&lt;br&gt;Profile', 'Payments&lt;br&gt;Profile', 'Equipment&lt;br&gt;Profile', 'Access&lt;br&gt;Profile', 'Advanced', 'Summary']" />
    
    <div class="setup-details">
      <div style="width: 30%">
        <label>Build ID</label>
        <span>{{ item.buildId }}</span>
        <label style="margin-top: 15px">Solution</label>
        <span>{{ viewSolutions(item.app.solution) }}</span>
        <label style="margin-top: 15px">Account Manager</label>
        <span>{{ item.app.manager?item.app.manager.fullName/*+" "+item.app.manager.lastName*/:"" }}</span>
        <label>Partner</label>
        <span>{{ item.app.source?item.app.source.agentDb.name:"" }}</span>
      </div>
      <div style="width: 30%">
        <label>Build By</label>
        <span>{{ item.buildBy && (item.buildBy.fullName+" "+item.buildBy.lastName) }}</span>
        <label style="margin-top: 15px">Account Number</label>
        <span>{{ item.app.accountId }}</span>
        <label style="margin-top: 15px">Company Name</label>
        <span>{{ item.app.companyName }}</span>
        <label>Merchant Type</label>
        <span>{{ item.app.industry }}</span>
      </div>
      <div style="width: 30%">
        <label>Build Start Date</label>
        <span>{{ formatDate(item.created, "YYYY-MM-DD") }}</span>
        <label style="margin-top: 15px">Account Type</label>
        <span>{{ accountTypes[item.app.accountType] || "" }}</span>
        <label style="margin-top: 15px">Company DBA</label>
        <span>{{ item.app.companyNameTrade }}</span>
        <label>MCC</label>
        <span style="text-overflow: ellipsis; overflow: hidden">{{ item.app.mcc }}</span>
      </div>
    </div>
    
    <div class="implementation-note">
      <p>{{ item.merchant.implMessage }}</p>
    </div>

<template v-for="sdata in [item.setupData || {}]">
<form class="form-step" v-if="step==0" key="step1">
  <section>
    <h2>General Information</h2>
    <ul class="column-form column-form-compact">
      <li>
        <label>Approval Date</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly :value="formatDate(item.app.approveDate, 'YYYY-MM-DD')" />
          </validator>
        </div>
      </li>
      <li>
        <label>Language</label>
        <div class="field">
          <validator :value="item.app.language" reqs="required">
            <dyn-select v-model="item.app.language" :options="languages" />
          </validator>
        </div>
      </li>
    </ul>
    
    <h2>Business Information</h2>
    <ul class="column-form column-form-compact">
      <li>
        <label>Merchant Type</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly :value="accountTypes[item.app.accountType]" />
          </validator>
        </div>
      </li>
      <li>
        <label>Merchant Industry</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly :value="item.app.industry" />
          </validator>
        </div>
      </li>
      <li>
        <label>Website</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly :value="item.app.website" />
          </validator>
        </div>
      </li>
      <li>
        <label>Website User/Pass</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly :value="item.app.websiteUsername+' '+item.app.websitePassword" />
          </validator>
        </div>
      </li>
      <li>
        <label>Customer Service Email</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly :value="item.app.emailService" />
          </validator>
        </div>
      </li>
      <li>
        <label>Customer Service Phone</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly :value="item.app.phoneService" />
          </validator>
        </div>
      </li>
      <li>
        <label>Billing Descriptor</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly :value="item.app.descriptor1" />
          </validator>
        </div>
      </li>
      <li>
        <label>Customers Located</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly :value="'TODO'" />
          </validator>
        </div>
      </li>
      <li>
        <label>Customer Type</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly :value="'TODO'" />
          </validator>
        </div>
      </li>
    </ul>

  </section>
  <div class="buttons">
    <a class="btn btn-large btn-plain btn-back btn-disabled" v-on:click.prevent="">Back</a>
<!--     <a class="btn btn-large btn-plain btn-save" v-on:click.prevent="nextStep">Save</a> -->
<!--     <a class="btn btn-large btn-plain btn-update" v-on:click.prevent="nextStep">Update</a> -->
<!--     <a class="btn btn-large btn-plain btn-complete" v-on:click.prevent="nextStep">Complete</a> -->
    <a class="btn btn-large btn-success btn-next" v-on:click.prevent="nextStep">Next</a>
  </div>
</form>
<form class="form-step" v-if="step==1" key="step2">

<!--

Locations Information - Sub-Header

Location 1 ID: *For each merchant, location IDs start at 001 etc.
Location 1 Country:
Location 1 Address:
Location 1 City:
Location 1 Prov/State:
Location 1 Postal/Zip:

*List all additional Locations if there are some.


-->


  <section>
    <h2>Company Information</h2>
    <ul class="column-form column-form-compact">
      <li>
        <label>Parent Company</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly :value="item.app.hasParent==1?'Yes':'No'" />
          </validator>
        </div>
      </li>
      <li>
        <label>New Business</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly :value="item.app.companyNew?'Yes':'No'" />
          </validator>
        </div>
      </li>
      <li>
        <label>Registration ID</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly :value="item.app.companyRegnum" />
          </validator>
        </div>
      </li>
      <li>
        <label>Tax ID</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly :value="item.app.companyTaxId" />
          </validator>
        </div>
      </li>
      <li>
        <label>Company Country</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly :value="item.app.companyAddressCountry" />
          </validator>
        </div>
      </li>
      <li>
        <label>Legal Business Name</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly :value="item.app.companyName" />
          </validator>
        </div>
      </li>
      <li>
        <label>Company DBA Name</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly :value="item.app.companyNameTrade" />
          </validator>
        </div>
      </li>
      <li>
        <label>Company Website</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly :value="item.app.website" />
          </validator>
        </div>
      </li>
      <li>
        <label>Company Email</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly :value="item.app.companyEmail" />
          </validator>
        </div>
      </li>
      <li>
        <label>Company Phone</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly :value="item.app.companyPhone" />
          </validator>
        </div>
      </li>
      <li>
        <label>Company Address</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly :value="item.app.companyAddress" />
          </validator>
        </div>
      </li>
      <li>
        <label>Company City</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly :value="item.app.companyAddressCity" />
          </validator>
        </div>
      </li>
      <li>
        <label>Company Prov/State</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly :value="item.app.companyAddressState" />
          </validator>
        </div>
      </li>
      <li>
        <label>Company Postal/Zip</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly :value="item.app.companyAddressPostcode" />
          </validator>
        </div>
      </li>
      <li v-if="false">
        <label></label>
        <div class="field">
          <validator :value="item.app.language" reqs="required">
            <dyn-select v-model="item.app.language" :options="languages" />
          </validator>
        </div>
      </li>
    </ul>
    <h2>UBOs Information</h2>
    <ul class="column-form column-form-compact" v-for="(ubo, ui) in item.app.ubos || []">
      <li>
        <label>UBO {{ ui+1 }} Title</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly :value="''" />
          </validator>
        </div>
      </li>
      <li>
        <label>UBO {{ ui+1 }} First Name</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly :value="ubo.firstName" />
          </validator>
        </div>
      </li>
      <li>
        <label>UBO {{ ui+1 }} Last Name</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly :value="ubo.lastName" />
          </validator>
        </div>
      </li>
      <li>
        <label>UBO {{ ui+1 }} Ownership %</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly :value="ubo.ownership" />
          </validator>
        </div>
      </li>
    </ul>
    <h2>Directors Information</h2>
    <ul class="column-form column-form-compact" v-for="(ubo, ui) in item.app.directors || []">
      <li>
        <label>Director {{ ui+1 }} Title</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly :value="''" />
          </validator>
        </div>
      </li>
      <li>
        <label>Director {{ ui+1 }} First Name</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly :value="ubo.firstName" />
          </validator>
        </div>
      </li>
      <li>
        <label>Director {{ ui+1 }} Last Name</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly :value="ubo.lastName" />
          </validator>
        </div>
      </li>
    </ul>
    <h2>Contacts Information</h2>
    <ul class="column-form column-form-compact">
      <li>
        <label>Company Email</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly :value="item.app.companyEmail" />
          </validator>
        </div>
      </li>
      <li>
        <label>Company Phone</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly :value="item.app.companyPhone" />
          </validator>
        </div>
      </li>
    </ul>
    <ul class="column-form column-form-compact" v-for="contact in item.app.contacts || []">
      <li>
        <label>{{ capitalize(contact.type) }} Contact First Name</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly :value="contact.firstName" />
          </validator>
        </div>
      </li>
      <li>
        <label>{{ capitalize(contact.type) }} Contact Last Name</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly :value="contact.lastName" />
          </validator>
        </div>
      </li>
      <li>
        <label>{{ capitalize(contact.type) }} Contact Email</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly :value="contact.email" />
          </validator>
        </div>
      </li>
      <li>
        <label>{{ capitalize(contact.type) }} Contact Phone</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly :value="contact.phone" />
          </validator>
        </div>
      </li>
    </ul>
    <h2>Locations Information</h2>
    <!-- TODO -->
    <h2>Banking Information</h2>
    <ul class="column-form column-form-compact" v-for="(account, ai) in item.app.accounts || []">
      <li>
        <label>Bank {{ ai+1 }} Account Currency</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly :value="currency(account.accountCurrency)" />
          </validator>
        </div>
      </li>
      <li>
        <label>Bank {{ ai+1 }} Name on Account</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly :value="account.accountHolder" />
          </validator>
        </div>
      </li>
      <li>
        <label>Bank {{ ai+1 }} Country</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly :value="account.bankAddressCountry" />
          </validator>
        </div>
      </li>
      <li>
        <label>Bank {{ ai+1 }} Name</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly :value="account.bankName" />
          </validator>
        </div>
      </li>
      <li>
        <label>Bank {{ ai+1 }} Account Type</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly :value="''" />
          </validator>
        </div>
      </li>
      <li>
        <label>Bank {{ ai+1 }} Account Number</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly :value="account.accountNumber" />
          </validator>
        </div>
      </li>
      <li>
        <label>Bank {{ ai+1 }} Institution Number</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly :value="account.accountSwift" />
          </validator>
        </div>
      </li>
      <li>
        <label>Bank {{ ai+1 }} Transit Number</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly :value="account.accountIBAN" />
          </validator>
        </div>
      </li>
      <li>
        <label>Bank {{ ai+1 }} Routing Number</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly :value="account.accountRouting" />
          </validator>
        </div>
      </li>
    </ul>
    
<!--     <div class="notes important" v-if="internalNotes.agentAgrCompany">Note: {{ internalNotes.agentAgrCompany }} </div> -->
  </section>
  <div class="buttons">
    <a class="btn btn-large btn-plain btn-back" v-on:click.prevent="prevStep">Back</a>
    <a class="btn btn-large btn-success btn-next" v-on:click.prevent="nextStep">Next</a>
  </div>
</form>
<form class="form-step" v-if="step==2" key="step3">
  <section>
    <h2>Pricing Info</h2>
    <pricing-details :currentTemplate="intake.formData" :solution="item.app.solution" />
<!--     <div class="notes important" v-if="internalNotes.agentAgrBusiness">Note: {{ internalNotes.agentAgrBusiness }} </div> -->
  </section>
  <div class="buttons">
    <a class="btn btn-large btn-plain btn-back" v-on:click.prevent="prevStep">Back</a>
    <a class="btn btn-large btn-success btn-next" v-on:click.prevent="nextStep">Next</a>
  </div>

</form>
<form class="form-step" v-if="step==3" key="step5">
  <section>
    <h2>Connectivity</h2>
    <ul class="column-form column-form-compact">
      <li>
        <label>Gateway</label>
        <div class="field">
          <validator :value="0" reqs="">
            <dyn-select v-model="item.app.gateway" :options="gatewaysList" />
          </validator>
        </div>
      </li>
      <li>
        <label>Processor</label>
        <div class="field">
          <validator :value="0" reqs="">
            <dyn-select v-model="item.app.processor" :options="processorsList" />
          </validator>
        </div>
      </li>
    </ul>
    <h2>Payment Methods</h2>
    <ul class="column-form column-form-compact">
      <li>
        <label>
        <input type="checkbox" v-model="item.setupData.cardPresent" style="margin-right: 4px; vertical-align: middle" />
        Card Present / POS</label>
<!--        <div class="field">
          <validator :value="0" reqs="" style="display: flex">
            <input readonly type="text" :value="item.app.methodPOS" :disabled="!item.setupData.cardPresent" />
          </validator>
        </div>-->
      </li>
      <li style="width: 25%">
        <label>
        <input type="checkbox" v-model="item.setupData.cardNotPresent" style="margin-right: 4px; vertical-align: middle" />
        Card not Present / E-Commerce</label>
<!--        <div class="field">
          <validator :value="0" reqs="" style="display: flex">
            <input readonly type="text" :value="item.app.methodECommerce" :disabled="!item.setupData.cardNotPresent" />
          </validator>
        </div>-->
      </li>
    </ul>
    <h2>Payment Settings</h2>
    <ul class="column-form column-form-compact column-form-compact-3">
      <li>
        <label>Transaction Currencies</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input readonly type="text" :value="item.app.transactionCurrencies" />
          </validator>
        </div>
      </li>
      <li>
        <label>Monthly Transaction Volume Limit</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly :value="item.merchant.monthlyLimit" />
          </validator>
        </div>
      </li>
      <li>
        <label>Annual Transaction Volume Limit</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" :value="item.merchant.annualLimit" />
          </validator>
        </div>
      </li>
    </ul>
    <ul class="column-form column-form-compact column-form-compact-3">
      <li>
        <label>Minimum Transaction Amount</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" :value="item.setupData.minTransaction" />
          </validator>
        </div>
      </li>
      <li>
        <label>Average Transaction Amount</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input readonly type="text" :value="item.app.avgTransaction" />
          </validator>
        </div>
      </li>
      <li>
        <label>Maximum Transaction Amount</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input readonly type="text" :value="item.app.maxTransaction" />
          </validator>
        </div>
      </li>
    </ul>
    <h2 style="font-size: 1.3em">EFT / ACH</h2>
    <ul class="column-form column-form-compact column-form-compact-3">
      <li>
        <label>Monthly Transaction Volume Limit</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input readonly type="text" :value="item.setupData.eftMonthlyLimit" />
          </validator>
        </div>
      </li>
      <li>
        <label>Annual Transaction Volume Limit</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input readonly type="text" :value="item.setupData.eftAnnualLimit" />
          </validator>
        </div>
      </li>
    </ul>
    <ul class="column-form column-form-compact column-form-compact-3">
      <li>
        <label>Minimum Transaction Amount</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input readonly type="text" :value="item.setupData.eftMinTransaction" />
          </validator>
        </div>
      </li>
      <li>
        <label>Average Transaction Amount</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input readonly type="text" :value="item.setupData.eftAvgTransaction" />
          </validator>
        </div>
      </li>
      <li>
        <label>Maximum Transaction Amount</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input readonly type="text" :value="item.setupData.eftMaxTransaction" />
          </validator>
        </div>
      </li>
    </ul>
    <h2>TIDS &amp; KEYS Settings</h2>
    <ul class="column-form column-form-compact">
<!--      <li>
        <label></label>
        <div class="field">
          <validator :value="0" reqs="">
            <input readonly type="text" :value="item.a" />
          </validator>
        </div>
      </li>-->
    </ul>
    <h2>Risk &amp; Compliance Settings</h2>
    <ul class="column-form column-form-compact">
      <li>
        <label>Compliance Review Days</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly v-model="item.merchant.reviewDays" />
          </validator>
        </div>
      </li>
      <li>
        <label>Account Reserve (%)</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly v-model="item.merchant.reserve" />
          </validator>
        </div>
      </li>
      <li>
        <label>Account Reserve (Days)</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly v-model="item.merchant.reserveHold" />
          </validator>
        </div>
      </li>
      <li>
        <label>Settlement Delay (Days)</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly v-model="item.merchant.settlementDays" />
          </validator>
        </div>
      </li>
      <li>
        <label>Fraud Prevention</label>
        <div class="field">
          <validator :value="0" reqs="">
            <dyn-select v-model="item.setupData.fraudPrevention" :options="[]" />
          </validator>
        </div>
      </li>
      <li class="double">
        <label>Billing Descriptor</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input readonly type="text" :value="item.app.descriptor1" />
          </validator>
        </div>
      </li>
      <li class="triple">
        <label>Account Class</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input readonly type="text" :value="item.app.class.name+' '+item.app.class.description" />
          </validator>
        </div>
      </li>
      <li class="quintuple">
        <label>MCC</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input readonly type="text" :value="item.app.mcc" />
          </validator>
        </div>
      </li>
    </ul>
<!--     <div class="notes important" v-if="internalNotes.agentAgrBanking">Note: {{ internalNotes.agentAgrBanking }} </div> -->
  </section>
  <div class="buttons">
    <a class="btn btn-large btn-plain btn-back" v-on:click.prevent="prevStep">Back</a>
    <a class="btn btn-large btn-success btn-next" v-on:click.prevent="nextStep">Next</a>
  </div>
</form>
<form class="form-step" v-if="step==4" key="step5">
  <section v-for="q in [intake.formData || {}]">
    <h2>EQUIPMENT</h2>
    
    <ul class="column-form">
      <li class="fwidth fwx" v-if="false">
        <label>Special Offers</label>
        <div class="field">
          <validator :value="q.specialOffer&&q.specialOffer.length" reqs="">
            <multiselect-desc v-model="q.specialOffer" :options="['Free Terminal Program']">
              Must ensure that merchant meets program criteria.
            </multiselect-desc>
          </validator>
        </div>
      </li>
      
      <template v-for="ti in terminals">
      <h2 style="font-size: 1.2em">Terminals Type #{{ti}}</h2>
      <li class="">
        <label>Model</label>
        <div class="field complex">
          <validator :value="q['terminal'+ti+'Type']" reqs="">
            <dyn-select  v-model="q['terminal'+ti+'Type']" :options="equipmentOpt" /> <br />
            ${{ (equi(ti)[q['terminal'+ti+'PurchaseOpt']?'purchase':(q['terminal'+ti+'RentalOpt']?'rental':'reprogram')] || 0).toFixed(2) }}
<!--             <input type="text" v-model="q['terminal'+ti+'Type']" /> -->
          </validator>
        </div>
      </li>
      <li class="">
        <label>QTY</label>
        <div class="field complex">
          <validator :value="q['terminal'+ti+'Qty']" reqs="">
            <input type="text" v-model="q['terminal'+ti+'Qty']" />
          </validator>
        </div>
      </li>
      <li class="">
        <label>Purchase</label>
        <div class="field conditional">
          <checkbox-plain2 v-model="q['terminal'+ti+'PurchaseOpt']" v-on:input="$set(q, 'terminal'+ti+'RentalOpt', false); $set(q, 'terminal'+ti+'LeasingOpt', false); $set(q, 'terminal'+ti+'ReprogramOpt', false)" :disabled="!equi(ti).purchase" />
<!--           <span>Qty:</span> -->
<!--           <input type="text" v-model="q['terminal'+ti+'Purchase']" :disabled="!q['terminal'+ti+'PurchaseOpt']" /> -->
        </div>
      </li>
      <li class="">
        <label>Rental</label>
        <div class="field conditional">
          <checkbox-plain2 v-model="q['terminal'+ti+'RentalOpt']" v-on:input="$set(q, 'terminal'+ti+'PurchaseOpt', false); $set(q, 'terminal'+ti+'LeasingOpt', false); $set(q, 'terminal'+ti+'ReprogramOpt', false)" :disabled="!equi(ti).rental" />
<!--           <span>Qty:</span> -->
<!--           <input type="text" v-model="q['terminal'+ti+'Rental']" :disabled="!q['terminal'+ti+'RentalOpt']" /> -->
        </div>
      </li>
      <li class="">
        <label>Reprogram</label>
        <div class="field conditional">
          <checkbox-plain2 v-model="q['terminal'+ti+'ReprogramOpt']" v-on:input="$set(q, 'terminal'+ti+'PurchaseOpt', false); $set(q, 'terminal'+ti+'RentalOpt', false); $set(q, 'terminal'+ti+'LeasingOpt', false)" />
<!--           <span>Qty:</span> -->
<!--           <input type="text" v-model="q['terminal'+ti+'Reprogram']" :disabled="!q['terminal'+ti+'ReprogramOpt']" /> -->
        </div>
      </li>
      <li class="">
        <label>Leasing</label>
        <div class="field conditional">
          <checkbox-plain2 disabled v-model="q['terminal'+ti+'LeasingOpt']" v-on:input="$set(q, 'terminal'+ti+'PurchaseOpt', false); $set(q, 'terminal'+ti+'RentalOpt', false); $set(q, 'terminal'+ti+'ReprogramOpt', false)" />
<!--           <span>Qty:</span> -->
<!--           <input type="text" v-model="q['terminal'+ti+'Leasing']" :disabled="!q['terminal'+ti+'LeasingOpt']" /> -->
        </div>
      </li>
      <li v-if="false" class="" style="width: calc(12% - 5px)">
        <label>Merchant Own</label>
        <div class="field conditional">
          <checkbox-plain2 v-model="q['terminal'+ti+'OwnOpt']" />
          <span>Qty:</span>
          <input type="text" v-model="q['terminal'+ti+'Own']" :disabled="!q['terminal'+ti+'OwnOpt']" />
        </div>
      </li>
      <li v-if="false" class="" style="width: calc(12% - 5px)">
        <label>Financing</label>
        <div class="field conditional">
          <checkbox-plain2 v-model="q['terminal'+ti+'FinancingOpt']" />
          <span>Qty:</span>
          <input type="text" v-model="q['terminal'+ti+'Financing']" :disabled="!q['terminal'+ti+'FinancingOpt']" />
        </div>
      </li>
      <li v-if="false" class="" style="width: calc(12% - 5px)">
        <label>Insurance</label>
        <div class="field conditional">
          <checkbox-plain2 v-model="q['terminal'+ti+'InsuranceOpt']" />
          <span>Qty:</span>
          <input type="text" v-model="q['terminal'+ti+'Insurance']" :disabled="!q['terminal'+ti+'InsuranceOpt']" />
        </div>
      </li>
      </template>
      <li style="width: 100%" v-if="false">
        <a class="terminals-del" href="" v-on:click.prevent="terminalCount--" v-if="terminalCount>1">‒</a>
        <a class="terminals-add" href="" v-on:click.prevent="terminalCount++">+</a>
      </li>
      
      
      <li class="fwidth fwx">
        <label>Communication</label>
        <div class="field">
          <validator :value="q.communicationMethod&&q.communicationMethod.length" reqs="">
            <multiselect v-model="q.communicationMethod" :options="['IP', 'Wireless', 'SIM Card Required', 'Batch Auto Close Time!time']" :time.sync="q.communicationTime">
            </multiselect>
          </validator>
        </div>
      </li>
      
      <h2 style="font-size: 1.2em">Shipping</h2>
      <li class="fwidth fwx">
        <label>Ship to</label>
        <div class="field">
          <validator :value="q.shipTo&&q.shipTo.length?q.shipTo:q.shipToOther" reqs="">
            <multiselect v-model="q.shipTo" :other.sync="q.shipToOther" :options="['Company', 'Location', 'Other']" :onlyone="true" />
          </validator>
        </div>
      </li>
      <li class="fwidth fwx">
        <label>Shipping Method</label>
        <div class="field">
          <validator :value="q.shippingMethod&&q.shippingMethod.length" reqs="">
            <multiselect v-model="q.shippingMethod" :options="['Regular', '2 Day', 'Next Day', 'Overnight']" :onlyone="true" />
          </validator>
        </div>
      </li>
      <li class="">
        <label>Ship to Other Address</label>
        <div class="field complex">
          <validator :value="q.shipOtherAddress" :reqs="q.shipTo && q.shipTo.indexOf('Other')!=-1?'required':''">
            <input type="text" v-model="q.shipOtherAddress" :disabled="!q.shipTo || q.shipTo.indexOf('Other')==-1" />
          </validator>
        </div>
      </li>
      <li class="">
        <label>City</label>
        <div class="field complex">
          <validator :value="q.shipOtherCity" :reqs="q.shipTo && q.shipTo.indexOf('Other')!=-1?'required':''">
            <input type="text" v-model="q.shipOtherCity" :disabled="!q.shipTo || q.shipTo.indexOf('Other')==-1" />
          </validator>
        </div>
      </li>
      <li class="">
        <label>Province/State</label>
        <div class="field complex">
          <validator :value="q.shipOtherState" :reqs="q.shipTo && q.shipTo.indexOf('Other')!=-1?'required':''">
            <input type="text" v-model="q.shipOtherState" :disabled="!q.shipTo || q.shipTo.indexOf('Other')==-1" />
          </validator>
        </div>
      </li>
      <li class="">
        <label>Postal Code/Zip</label>
        <div class="field complex">
          <validator :value="q.shipOtherPostal" :reqs="q.shipTo && q.shipTo.indexOf('Other')!=-1?'required':''">
            <input type="text" v-model="q.shipOtherPostal" :disabled="!q.shipTo || q.shipTo.indexOf('Other')==-1" />
          </validator>
        </div>
      </li>
      <li class="" style="width: 100%">
        <label>Instructions</label>
        <div class="field complex">
          <validator :value="q.shipOtherInstructions" :reqs="''">
            <input type="text" v-model="q.shipOtherInstructions" />
          </validator>
        </div>
      </li>
    </ul>
  </section>
  <div class="buttons">
    <a class="btn btn-large btn-plain btn-back" v-on:click.prevent="prevStep">Back</a>
    <a class="btn btn-large btn-success btn-next" v-on:click.prevent="nextStep">Next</a>
  </div>
</form>
<form class="form-step" v-if="step==5" key="step6">
  <section>
    <h2>Access Information</h2>
    <ul class="column-form column-form-compact">
      <li>
        <label>Portal</label>
        <div class="field">
          <validator :value="0" reqs="">
            <dyn-select v-model="item.setupData.portal" :options="portals" />
          </validator>
        </div>
      </li>
      <li>
        <label>Username</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" v-model="item.setupData.username" />
          </validator>
        </div>
      </li>
      <li>
        <label>Send welcome email</label>
        <div class="field">
          <validator :value="item.setupData.sendEmail" reqs="required">
            <dyn-select v-model="item.setupData.sendEmail" :options="toggleYesNo" />
          </validator>
        </div>
      </li>
      <li>
        <label>PIN</label>
        <div class="field">
          <validator :value="item.setupData.pin" reqs="required">
            <input type="text" readonly v-model="item.setupData.pin" />
            <button v-on:click.prevent="generatePin">Generate PIN</button>
          </validator>
        </div>
      </li>
    </ul>
    <div class="notes important" v-if="internalNotes.agentAgrBanking">Note: {{ internalNotes.agentAgrBanking }} </div>
  </section>
  <div class="buttons">
    <a class="btn btn-large btn-plain btn-back" v-on:click.prevent="prevStep">Back</a>
    <a class="btn btn-large btn-success btn-next" v-on:click.prevent="nextStep">Next</a>
  </div>
</form>
<form class="form-step" v-if="step==6" key="step7">
  <section>
    <h2>Advanced Information</h2>
    <ul class="column-form column-form-compact">
      <li>
        <label>Plugg & Co. Registration</label>
        <div class="field">
          <validator :value="item.setupData.pluggReg" reqs="required">
            <dyn-select v-model="item.setupData.pluggReg" :options="toggleYesNo" />
          </validator>
        </div>
      </li>
      <li>
        <label>Alerts Enroll</label>
        <div class="field">
          <validator :value="item.setupData.alerts" reqs="required">
            <dyn-select v-model="item.setupData.alerts" :options="toggleYesNo" />
          </validator>
        </div>
      </li>
      <li>
        <label>OnSite Service Requested</label>
        <div class="field">
          <validator :value="item.setupData.onsite" reqs="required">
            <dyn-select v-model="item.setupData.onsite" :options="toggleYesNo" />
          </validator>
        </div>
      </li>
      <li>
        <label>G2 Monitoring</label>
        <div class="field">
          <validator :value="item.setupData.g2mon" reqs="required">
            <dyn-select v-model="item.setupData.g2mon" :options="toggleYesNo" />
          </validator>
        </div>
      </li>
    </ul>
<!--     <div class="notes important" v-if="internalNotes.agentAgrBanking">Note: {{ internalNotes.agentAgrBanking }} </div> -->
  </section>
  <div class="buttons">
    <a class="btn btn-large btn-plain btn-back" v-on:click.prevent="prevStep">Back</a>
    <a class="btn btn-large btn-success btn-next" v-on:click.prevent="nextStep">Next</a>
  </div>
</form>
<form class="form-step" v-if="step==7" key="step8">
  <section>
    <h2>New Merchant build</h2>
    <ul class="setup-steps">
      <li v-if="item.setupData.pluggReg=='1'">
        <h5>Plugg and Co. Registration</h5>
        <p>Result{{ buildSteps.pluggReg?': '+pluggResult.Result:': Pending' }}</p>
        <p v-if="pluggResult['Registration ID']">Plugg ID: {{ pluggResult['Registration ID'] }}</p>
        <button v-on:click.prevent="setBuildStep('pluggReg')" v-if="!buildSteps.pluggReg">Start</button>
      </li>
      <li v-if="item.setupData.g2mon=='1'">
        <h5>G2 Monitoring</h5>
        <p>Result{{ buildSteps.g2mon?(g2mdmResult.id?': Done':(item.app.website?': Fail':': Fail (no website specified)')):': Pending' }}</p>
        <p v-if="g2mdmResult.id">Merchant ID: {{ g2mdmResult.id }}</p>
        <p v-if="g2mdmResult.attributes">Merchant Name: {{ g2mdmResult.attributes.merchant_name }}</p>
        <p v-if="g2mdmResult.attributes">Merchant URL: {{ g2mdmResult.attributes.url }}</p>
        <button v-on:click.prevent="setBuildStep('g2mon')" v-if="!buildSteps.g2mon">Start</button>
      </li>
    </ul>
  </section>
  <div class="buttons">
    <a class="btn btn-large btn-plain btn-back" v-on:click.prevent="prevStep">Back</a>
<!--     <a class="btn btn-large btn-success btn-next" v-on:click.prevent="startBuild">Start Build</a> -->
  </div>
</form>
<form class="form-step" v-if="step==8" key="step9">
  <section>
    <h2>New Merchant build</h2>
    <ul class="setup-steps">
      <li v-if="item.setupData.pluggReg=='1'">
        <h5>Plugg and Co. Registration</h5>
        <p>Result{{ buildSteps.pluggReg?': '+pluggResult.Result:': Pending' }}</p>
        <p v-if="pluggResult['Registration ID']">Plugg ID: {{ pluggResult['Registration ID'] }}</p>
      </li>
      <li v-if="item.setupData.g2mon=='1'">
        <h5>G2 Monitoring</h5>
        <p>Result{{ buildSteps.g2mon?(g2mdmResult.id?': Done':(item.app.website?': Fail':': Fail (no website specified)')):': Pending' }}</p>
        <p v-if="g2mdmResult.id">Merchant ID: {{ g2mdmResult.id }}</p>
        <p v-if="g2mdmResult.attributes">Merchant Name: {{ g2mdmResult.attributes.merchant_name }}</p>
        <p v-if="g2mdmResult.attributes">Merchant URL: {{ g2mdmResult.attributes.url }}</p>
      </li>
    </ul>
  </section>
  <div class="buttons">
    <a class="btn btn-large btn-success btn-next" v-on:click.prevent="save">Finalize</a>
  </div>
</form>
<form class="form-step" v-if="false && step==7" key="step8">
  <section>
    <h2>Access Information</h2>
    <ul class="column-form column-form-compact">
      <li>
        <label>Additional Build Notes</label>
        <div class="field">
          <validator :value="0" reqs="">
            <textarea v-model="item.notes"></textarea>
          </validator>
        </div>
      </li>
    </ul>
<!--     <div class="notes important" v-if="internalNotes.agentAgrBanking">Note: {{ internalNotes.agentAgrBanking }} </div> -->
  </section>
  <div class="buttons">
    <a class="btn btn-large btn-plain btn-back" v-on:click.prevent="prevStep">Back</a>
    <a class="btn btn-large btn-success btn-next" v-on:click.prevent="nextStep">Next</a>
  </div>
</form>
<form class="form-step" v-if="false && step==8" key="step9">
  <section>
    <h2>Merchant Information</h2>
    <ul class="column-form readonly">
      <li>
        <label style="border: none">Merchant ID:</label>
        <div class="field">
          <validator :value="0" reqs="" style="border: none">
            <input type="text" readonly v-model="sdata.agentId" />
          </validator>
        </div>
      </li>
    </ul>
    <h2>Access Information</h2>
    <ul class="column-form readonly">
      <li>
        <label style="border: none">Platform:</label>
        <div class="field">
          <validator :value="0" reqs="" style="border: none; background: none;">
            <input type="text" :value="item.platform" readonly />
          </validator>
        </div>
      </li>
      <li>
        <label style="border: none; background: none;">Platform ID:</label>
        <div class="field">
          <validator :value="0" reqs="" style="border: none; background: none;">
            <input type="text" :value="''" readonly />
          </validator>
        </div>
      </li>
      <li>
        <label style="border: none; background: none;">Portal:</label>
        <div class="field">
          <validator :value="0" reqs="" style="border: none; background: none;">
            <input type="text" :value="item.portal" readonly />
          </validator>
        </div>
      </li>
      <li>
        <label style="border: none; background: none;">Username:</label>
        <div class="field">
          <validator :value="0" reqs="" style="border: none; background: none;">
            <input type="text" v-model="item.username" />
          </validator>
        </div>
      </li>
      <li>
        <label style="border: none; background: none;">Portal Activation Code:</label>
        <div class="field">
          <validator :value="0" reqs="" style="border: none; background: none;">
            <input type="text" v-model="item.password" />
          </validator>
        </div>
      </li>
      <li>
        <label style="border: none; background: none;">Send welcome email:</label>
        <div class="field">
          <validator :value="item.sendEmail" reqs="required" style="border: none; background: none;">
            <input type="text" :value="item.sendEmail==1?'Yes':'No'" readonly />
          </validator>
        </div>
      </li>
    </ul>
    <h2>Advanced Information</h2>
    <ul class="column-form readonly">
      <li>
        <label style="border: none">Plugg & Co. Registration:</label>
        <div class="field">
          <validator :value="0" reqs="" style="border: none">
            <input type="text" readonly :value="item.pluggReg==1?'Yes':'No'" />
          </validator>
        </div>
      </li>
      <li>
        <label style="border: none; background: none;">Plugg & Co. Username:</label>
        <div class="field">
          <validator :value="0" reqs="" style="border: none; background: none;">
            <input type="text" readonly :value="item.pluggUsername" />
          </validator>
        </div>
      </li>
      <li>
        <label style="border: none">Plugg & Co. Activation Code:</label>
        <div class="field">
          <validator :value="0" reqs="" style="border: none">
            <input type="text" readonly :value="item.pluggActCode" />
          </validator>
        </div>
      </li>
      <li>
        <label style="border: none; background: none;">Plugg & Co. Registration ID:</label>
        <div class="field">
          <validator :value="0" reqs="" style="border: none; background: none;">
            <input type="text" readonly :value="item.pluggRegId" />
          </validator>
        </div>
      </li>
    </ul>
    <h2>Account Security Information</h2>
    <ul class="column-form readonly">
      <li>
        <label style="border: none">PIN:</label>
        <div class="field">
          <validator :value="item.pin" reqs="required" style="border: none">
            <input type="text" readonly v-model="item.pin" />
          </validator>
        </div>
      </li>
    </ul>
    <h2>Additional Build Notes</h2>
    <ul class="column-form readonly">
      <li>
        <label style="border: none">Build Notes:</label>
        <div class="field">
          <validator :value="0" reqs="" style="border: none">
            <textarea readonly v-model="item.notes" style="resize: none"></textarea>
          </validator>
        </div>
      </li>
    </ul>

  </section>
  <div class="buttons" v-if="!buildStarted">
    <a class="btn btn-large btn-success" v-on:click.prevent="startBuild">Start Build</a>
  </div>
  <div class="start-build" v-if="buildStarted" :class="{visible: buildStarted && !buildFinalized }"><div></div>
    <ul>
      <li :class="{visible: buildStep>=1}">
        <label>Generating MerchantID... {{ progress(1,2) }}</label>
        <span :class="{done: buildStep>=2}">✔</span>
      </li>
      <li :class="{visible: buildStep>=2}">
        <label>Portal... {{ progress(2,3) }}</label>
        <span :class="{done: buildStep>=3}">✔</span>
      </li>
      <li :class="{visible: buildStep>=3}">
        <label>Username... {{ progress(3,4) }}</label>
        <span :class="{done: buildStep>=4}">✔</span>
      </li>
      <li :class="{visible: buildStep>=4}">
        <label>Generating Activation Code.... {{ progress(4,5) }}</label>
        <span :class="{done: buildStep>=5}">✔</span>
      </li>
      <li :class="{visible: buildStep>=5}">
        <label>Account Security PIN... {{ progress(5,6) }}</label>
        <span :class="{done: buildStep>=6}">✔</span>
      </li>
      <li :class="{visible: buildStep>=6}">
        <label>Creating Plugg & Co registration... {{ progress(6,7) }}</label>
        <span :class="{done: buildStep>=7}">✔</span>
      </li>
      <li :class="{visible: buildStep>=7}">
        <label>Sending data... {{ progress(7,9) }}</label>
        <span :class="{done: buildStep>=9}">✔</span>
      </li>
      <li :class="{visible: buildStep>=9}">
        <label><strong>Receiving data (Registration ID, username, Activation Code)... {{ progress(9,11) }}</strong></label>
        <span :class="{done: buildStep>=11}">✔</span>
      </li>
      <li :class="{visible: buildStep>=11}">
        <label>Note... {{ progress(11,12) }}</label>
        <span :class="{done: buildStep>=12}">✔</span>
      </li>
    </ul>
  </div>
  <div class="buttons">
    <a class="btn btn-large btn-plain btn-back" v-on:click.prevent="prevStep">Back</a>
    <a class="btn btn-large btn-success" :class="{'btn-disabled': !buildFinalized}" v-on:click.prevent="save">Finalize Build</a>
<!--     <a class="btn btn-large btn-success" v-on:click.prevent="save">Create Agreement</a> -->
  </div>
</form>
</template>
  </div>
  
  
</div></div>
</template>

<script>
export default {
  props: ["inline"],
  data() { return {
    groups: [
      {value: "agent-iso", label: "Independent Sales Organization - ISO"},
      {value: "agent-isa", label: "Independent Sales Agent - ISA"},
      {value: "agent-auth", label: "Authorized Agent"},
      {value: "agent-ref", label: "Referral Merchant/Intergrated Software Vendor - ISV"},
      {value: "agent-isv", label: "Integrated Software Vendor"},
    ],
    portals: [{value: appData.settings.merchantLoginURL, label: appData.settings.merchantLoginURL}],
    optsCount: [{value: '0', label: '0'}, {value: '1', label: '1'},
                {value: '2', label: '2'}, {value: '3', label: '3'},
                {value: '4', label: '4'}, {value: '5', label: '5'},
                {value: '6', label: '6'}, {value: '7', label: '7'},
                {value: '8', label: '8'}, {value: '9', label: '9'},
                {value: '10', label: '10'}, {value: '11', label: '11'},
                {value: '12', label: '12'}, {value: '13', label: '13'},
                {value: '14', label: '14'}, {value: '15', label: '15'},
                {value: '16', label: '16'}, {value: '17', label: '17'},
                {value: '18', label: '18'}, {value: '19', label: '19'},
                {value: '20', label: '20'}],
    shown: false,
    stepMode: 'view',
    step: 0,
    item: {app: {}, merchant: {}, setupData: {}},
    intake: {formData: {}},
    managers: [],
    internalNotes: {},
    validMapps: [
      {label: "0-30", value: "0-30"},
      {label: "31-50", value: "31-50"},
      {label: "51-75", value: "51-75"},
      {label: "76-99", value: "76-99"},
      {label: "100+", value: "100+"},
    ],
    languages: [
      {value: "English", label: "English"},
    ],
    accountClassesList: [
      {value: "Class 100", label: "Class 100"},
      {value: "Class 150", label: "Class 150"},
      {value: "Class 200", label: "Class 200"},
      {value: "Class 250", label: "Class 250"},
      {value: "Class 300", label: "Class 300"},
      {value: "Class 350", label: "Class 350"},
      {value: "Class 400", label: "Class 400"},
      {value: "Class 450", label: "Class 450"},
      {value: "Class 500", label: "Class 500"},
      {value: "Class 550", label: "Class 550"},
      {value: "Class 600", label: "Class 600"},
      {value: "Class 650", label: "Class 650"},
      {value: "Class 700", label: "Class 700"},
      {value: "Class 750", label: "Class 750"},
      {value: "Class 800", label: "Class 800"},
      {value: "Class 850", label: "Class 850"},
      {value: "Class 900", label: "Class 900"},
    ],
    accountTiers: [
      {value: "10", label: "10"},
      {value: "20", label: "20"},
      {value: "30", label: "30"},
      {value: "40", label: "40"},
      {value: "50", label: "50"},
    ],
    salesBrands: [],
    currencies: {"840": "USD", "124": "CAD"},
    currencies2: [
      {value: "124", label: "CAD"},
      {value: "840", label: "USD"},
    ],
    availQuantities: [
      {label: "0", value: 0}, {label: "1", value: 1}, {label: "2", value: 2},
      {label: "3", value: 3}, {label: "4", value: 4}, {label: "5", value: 5},
      {label: "6", value: 6}, {label: "7", value: 7}, {label: "8", value: 8},
      {label: "9", value: 9}, {label: "10", value: 10},
    ],
    reviewDaysOpts: [
      {value: "30", label: "30"},
      {value: "60", label: "60"},
      {value: "90", label: "90"},
      {value: "180", label: "180"},
      {value: "365", label: "365"},
    ],
    equipment: [],
    quantities: [],
    rental: [],
    options: [],
    modules: [],
    availOptEnabled: [{label: "No", value: false}, {label: "Yes", value: true}],
    optionsEnabled: [],
    modulesEnabled: [],
    pricingTemplates: [],
    templateId: null,
    accountClasses: [],
    optionTemplates: [],
    optionTemplateId: "0",
    moduleTemplates: [],
    moduleTemplateId: "0",
    enabledPayOpts: [],
    methodTemplateId: "0",
    methodTemplates: [],
    solutions: [],
    msolutions: [],
    editing: false,
    toggleYesNo: [
      {label: "Yes", value: 1},
      {label: "No", value: 0},
    ],
    buildStarted: false,
    buildStep: 0,
    buildFinalized: false,
    countries: [
      {label: "United States", value: "United States"},
      {label: "Canada", value: "Canada"},
    ],
    tobOptions: [
      "Inc.",
      "Corp.",
      "LLC",
      "LLP",
      "Ltd.",
      "Sole Proprietor",
      "",
    ],
    accountTypes: {
      master: "Payfac",
      merchant: "Merchant",
      sub: "Sub-Account",
    },
    buildSteps: {
      g2mon: false,
      pluggReg: false,
    },
    g2mdmResult: {
      id: null,
      attributes: null,
    },
    pluggResult: {
      'Registration ID': null,
    },
    terminalCount: 1,
    gatewaysList: [],
    processorsList: [],
  }},
  mounted() {
    this.solutions=appData.solutions;
    this.msolutions=appData.msolutions;
    window.merchantSetupPopup=this;
    this.managers=appData.sources.map(function(a) {
      return {value: a.id, label: a.fullName};
    });
    if (appData.internalNotes)
      this.internalNotes=appData.internalNotes;
    this.loadSalesBrands();
    this.generatePassword();
    this.loadEquipment();
    this.loadProviders();
  },
  computed: {
    equipmentOpt() {
      return this.equipment.map(a=>({
        value: a.id,
        label: a.name,
        purchase: a.purchase*1 || 0,
        rental: a.rental*1 || 0,
        reprogram: a.reprogram*1 || 0,
      }));
    },
    terminals() {
      var result=[];
      for(var i=0;i<this.terminalCount;i++)
        result.push(i+1);
      return result;
    },
  },
  methods: {
    equi(index) {
      return this.equipmentOpt.find((a)=>a.value==this.intake.formData['terminal'+index+'Type']) || {};
    },
    currency(code) {
      return this.currencies[code] || "";
    },
    capitalize(val) {
      val=(val || "")+"";
      return val.substring(0,1).toUpperCase()+val.substring(1);
    },
    async generatePassword() {
      this.item.password=(await api.password()).password;
    },
    async loadProviders() {
      var providers=(await api.list("Provider")).items;
      this.gatewaysList=providers.filter(a=>a.providerType=='Gateway').map(a=>({
        label: a.providerName,
        value: a.id
      }));
      this.processorsList=providers.filter(a=>a.providerType=='Processor').map(a=>({
        label: a.providerName,
        value: a.id
      }));
    },
    progress(min, max) {
      if (this.buildStep<min)
        return "";
      if (this.buildStep>=max)
        return "";
      var percent=Math.round(((this.buildStep-min)/(max-min))*100);
      return "("+percent+" of 100%)";
    },
    startBuild() {
      this.buildStarted=true;
//       var buildInterval=setInterval(()=>{
//         this.buildStep+=0.02;
//         if (this.buildStep>=12) {
//           this.buildFinalized=true;
//           this.step++;
//           clearInterval(buildInterval);
//         }
//       }, 18);
      // this.doBuild();
//       this.buildFinalized=true;
      if (this.item.setupData.g2mon=='0')
        this.setBuildStep('g2mon');
      if (this.item.setupData.pluggReg=='0')
        this.setBuildStep('pluggReg');
    },
    groupLabel(id) {
      for(var item of this.groups)
        if (item.value==id)
          return item.label;
      return id;
    },
    viewSolutions(solutions) {
      if (!solutions)
        solutions=[];
      else if (!solutions.join)
        solutions=[solutions];
      var result=[];
      for(var solutionId of solutions)
        for(var solution of this.msolutions || [])
          if (solution.value==solutionId)
            result.push(solution.label);
      
      return result.join(", ");
    },
    loadSalesBrands: async function() {
      this.salesBrands=[{label: '(none)', value: null}];
      var items=(await api.list("SalesBrand", {}, ["brandId:ASC"])).items || [];
      for(var i in items) {
        var item=items[i];
        this.salesBrands.push({label: item.brandId, value: item.id, item: item});
      }
    },
    salesBrandForId(id) {
      for(var i in this.salesBrands)
        if (this.salesBrands[i].value==id)
          return this.salesBrands[i].item;
      return {};
    },
    loadModules: async function() {
      this.modules=(await api.list("CompanyModule", [], ["id:ASC"])).items || [];
      for(var i in this.modules)
        this.modulesEnabled[i]=false;
    },
    loadEquipment: async function() {
      this.equipment=(await api.list("Equipment", {enabled: 1})).items || [];
      for(var i in this.equipment)
        this.quantities[i]=0;
    },
    loadSysOptions: async function() {
      var item=(await api.get("Options", 1)).item || {};
      this.enabledPayOpts=item.enabledMethods || [];
    },
    dismiss() {
      this.shown=false;
      this.step=0;
      this.appl={};
      this.item={solutions: [], regions: []};
    },
    showExisting(setup) {
      this.shown=true;
      this.item=setup;
      this.step=this.item.currentStep || 0;
      this.loadIntake();
    },
    async loadIntake() {
      var intake=(await api.list("Intake", {appId: this.item.app.id}, ["id:DESC"])).items[0];
      this.intake=intake;
    },
    nextStep() {
      if (!this.validate())
        return stopLoading("Please fill all required fields", "error");
      this.step++;
      if (this.step==5) {
        this.item.setupData.username=this.generateUsername();
      }
      if (this.step==7)
        this.startBuild()
    },
    generatePin: async function() {
      this.$set(this.item.setupData, "pin", (await api.pin()).pin);
    },
    generateUsername() {
      var value=this.item.app.companyName;
//       if (["agent-inside", "agent-outside"].indexOf(this.appl.groups)!=-1) {
//         value=(this.appl.fullName || this.appl.contacts[0].firstName)+" "+
//               (this.appl.lastName || this.appl.contacts[0].lastName);
//       } else {
//         value=this.appl.agentDb.name;
//       }
      return (value||"").toLowerCase().replace(/[^a-zA-Z0-9]+/g, "-").replace(/^-|-$/, "");
    },
    doBuild: async function() {
      var salesBrand=this.salesBrandForId(this.item.salesBrand);
      var result=JSON.parse(JSON.stringify(this.item));
      result.status="Completed";
      delete result.app;
      delete result.merchant;
      if (result.id)
        await api.update("MerchantSetup", result.id, result);
      else
        result.id=(await api.create("MerchantSetup", result)).id;
      var user=(await api.create("User", {
        applicationId: this.item.app.id,
        manager: this.item.app.manager.id,
        username: this.item.setupData.username,
        active: "Yes",
        skipNote: true,
        email: this.item.app.companyEmail,
        passChange: true,
      }, true)).item;
      
      
      this.buildStep=11.8;
    },
    save: async function() {
      startLoading();
      this.item.merchant.status="M99";
      this.item.merchant.accountMode="Test";
      var result=JSON.parse(JSON.stringify(this.item));
      
      if (result.id)
        await api.update("MerchantSetup", result.id, result);
      else
        await api.create("MerchantSetup", result);
      
      stopLoading("Setup completed");
      window.open("/implementation/onboarding", "_self");
    },
    saveDraft: async function() {
      this.editing=false;
      startLoading();
      var salesBrand=this.salesBrandForId(this.item.salesBrand);
      var result=JSON.parse(JSON.stringify(this.item));
      delete result.merchant.app;
      result.currentStep=this.step;
      if (result.id)
        await api.update("MerchantSetup", this.item.id, result);
      else
        await api.create("MerchantSetup", result);
//       await api.update("User", this.appl.id, {
//         groups: this.appl.groups,
//         manager: this.item.managerId,
//         agentDb: {
//           id: this.appl.agentDb.id,
//           accounts: this.appl.agentDb.accounts,
//           agentClass: this.item.accClass,
//           language: this.item.language,
//           pin: this.item.pin,
//           solutions: this.item.solutions,
//           msolutions: this.item.msolutions,
//           regions: this.item.regions,
//         },
//         companyName: this.appl.agentDb.name || salesBrand.dba,
//         companyPhone: this.appl.agentDb.phone || salesBrand.phone,
//         companyEmail: this.appl.agentDb.email || this.agentFormattedEmail,
//       });
      
      stopLoading("Setup saved");
//       viewAgent.listSetups();
//       this.dismiss();
    },
    validateStep: function($event) {
//       if (this.editing)
//         return;
      this.editing=false;
      if (!this.validate())
        return stopLoading("Please fill all required fields", "error");      
    },
    prevStep: function() {
      this.step--;
      this.$nextTick(()=>{ this.validateStep("noanim"); });
    },
    async setBuildStep(type) {
      if (type=="g2mon" && this.item.setupData.g2mon!='0') {
        var result=(await api.action("Merchant", this.item.merchant.id, "registerG2mdm", {})).item;
        this.g2mdmResult=result.g2mgmData || {};
        this.item.g2mgmData=result.g2mgmData;
      }
      if (type=="pluggReg" && this.item.setupData.pluggReg!='0') {
        var result=(await api.action("MerchantSetup", this.item.id, "pluggReg", {})).action;
        this.pluggResult=result || {};
      }

    
      this.buildSteps[type]=true;
      var done=true;
      for(var t in this.buildSteps) {
        console.log(t, this.buildSteps[t], this.buildSteps);
        if (!this.buildSteps[t])
          done=false;
      }
      if (done) {
        this.doBuild();
        this.step++;
      }
    },
  },
  mixins: [formValidation.mixin, vueMixins.dateFormatter]
}
</script>
<style lang="scss">
.setup-steps {
  li {
    position: relative;
    border: 1px solid #999;
    margin-bottom: 10px;
    padding: 10px;
    button {
      position: absolute;
      top: 10px;
      right: 10px;
      text-decoration: none;
      padding: 4px 20px;
      border: 1px solid black;
      border-radius: 3px;
      color: white;
      background: rgb(50,50,50);
      background: linear-gradient(to bottom, rgba(50,50,50,1) 0%,rgba(5,5,5,1) 100%);
    }
    p {
      margin-bottom: 0;
    }
  }
}
</style>
