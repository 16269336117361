<template>
<div class="ncontent">
  <div class="header">
    <div class="toolbar">
    </div>
    <ul class="breadcrumbs">
      <li><a href="/manage">System management</a></li>
      <li><a href="">Webhook Notifications Log</a></li>
    </ul>
    <div>
      <ul class="acc-results">
        <li>
          <div class="acc-list" style="display: block;">
            <table>
              <thead>
                <tr>
                  <th style="text-align: left;">Date/Time</th>
                  <th style="text-align: left;">API id</th>
                  <th style="text-align: left;">Method / URL</th>
                  <th style="text-align: left;">Provider</th>
                  <th style="text-align: left;">Version</th>
                  <th style="text-align: left;">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in apilog" style="cursor: pointer" v-on:click="selectedItem=item">
                  <td style="text-align: left;">
                    <span>{{ formatDate(item.created, "MMM Do YYYY, h:mm:ssa") }}</span>
                  </td>
                  <td style="text-align: left;">
                    <span>{{ item.id }}</span>
                  </td>
                  <td style="text-align: left" width="50%">
                    <span>{{ item.method }} {{ item.url }}</span>
                  </td>
                  <td style="text-align: left;">
                    <span>{{ item.provider }}</span>
                  </td>
                  <td style="text-align: left;">
                    <span>{{ item.version }}</span>
                  </td>
                  <td style="text-align: left;">
                    <span>{{ item.status }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="popup shown api-log-pop" v-if="selectedItem">
    <div><div class="pop-inner">
      <a class="close" href="" v-on:click.prevent="selectedItem=null"></a>
      <div class="log-body">
        <div class="head-details">
          <div>
            <label>URL:</label>
            <span>{{ selectedItem.url }}</span>
          </div>
          <div>
            <label>Response Code:</label>
            <span>{{ selectedItem.status }}</span>
          </div>
          <div>
            <label>Created date:</label>
            <span>{{ formatDate(selectedItem.created, "MMM Do YYYY, h:mm:ssa") }}</span>
          </div>
        </div>
        <div class="request-data">
          <label>Request:</label>
          <json-viewer :value="selectedItem.request" />
        </div>
        <div class="response-data">
          <label>Response:</label>
          <json-viewer :value="selectedItem.response" />
        </div>
      </div>
    </div></div>
  </div>
</div>
</template>

<script>
import JsonViewer from 'vue-json-viewer'
Vue.use(JsonViewer);
export default {
  data: ()=>({
    selectedItem: null,
    providers: [],
    apilog: [
//       {created: "2022-04-04 14:30:11", id: 1, provider: "G2", version: "1.0", status: "200",
//        url: "https://api.example.com/v2/group/a2e464ec-2f5b-4911-85be-b4645872ff68/persons",
//        request: '{"q":{"from":"2021-12-31"},"limit":5,"fields":["photo","firstName","email"]}',
//        response: '{"count":30,"items":[{"photo":null,"firstName":"Danilo","email":"dzukis87@gmail.com"},{"photo":null,"firstName":"Filip","email":"filip@oneit.rs"}]}'},
//       {created: "2022-04-04 14:41:53", id: 2, provider: "G2", version: "1.0", status: "500",
//        url: "https://api.example.com/v2/group/a2e464ec-2f5b-4911-85be-b4645872ff68/documents",
//        request: '{"q":{"type":"pdf"},"limit":5,"fields":["created","fileName"]}',
//        response: '{"error":"unauthorized"}'},
    ],
  }),
  methods: {
    async loadData() {
      this.providers=(await api.list("Provider")).items;
      this.apilog=[];
      for(var log of (await api.list("WebhookLog")).items) {
        var provider=this.providers.find(a=>a.id==log.providerId) || {};
        this.apilog.push({
          ...log,
          provider: provider.providerName || "",
          version: provider.version || "",
        });
      }
    }
  },
  mounted() {
    this.loadData();
  },
  mixins: [vueMixins.dateFormatter],
}
</script>
