<template>
<form class="form-step">
  <!--<p  class="form-intro">
    Please complete this form in full.  Incomplete forms are not accepted.
  </p>-->
  <section class="pqcat-select">
    <h3>To get started, tell us what type of merchant you are:</h3>
    <validator :value="app.industry" reqs="required">
    <ul class="pqcat-list">
      <li class="card-select" :class="{selected: app.mainType=='cp'}"><a href="" v-on:click.prevent="app.mainType='cp'">Card Present (Point of Sale)</a></li>
      <li class="card-select" :class="{selected: app.mainType=='cnp'}"><a href="" v-on:click.prevent="app.mainType='cnp'">Card not Present (E-Commerce)</a></li>
      <li v-for="ind in industries" :class="ind.class+' '+(app.industry==ind.label?'selected':'')+' '+(indAvail(ind)?'':'disabled')"><a href="" v-on:click.prevent="indAvail(ind) && (app.industry=ind.label)">{{ ind.label }}</a></li>
    </ul>
    </validator>
  </section>
  <div class="buttons">
    <button class="btn btn-large btn-success" v-on:click.prevent="nextStep">Next Step</button>
  </div>
 
</form>
</template>


<script>
export default {
  props: ["app", "mode"],
  computed: {
    webAll() {
      var all=true;
      var fields=["webContact", "webCustService", "webBilling", "webShipping", 
          "webReturn", "webTerms", "webPrivacy", "webDescription", 
          "webCurrency", "webSsl", "webUrlList", "webUser"];
      for(var i in fields) {
        if (!(this.app.requirements[fields[i]]*1))
          all=false;
      }
      return all;
    },
    industries() {
      if (this.mode=="facilitator")
        return [
          {class: "facilitator", label: "Facilitator"},
        ];
      else
        return [
          {class: "pc01", label: "Hospitality", type: 'cp'},
          {class: "pc02", label: "Retail", type: 'cp'},
          {class: "pc03", label: "Spa/Salon/Gym", type: 'cp'},
          {class: "pc04", label: "Taxi & Delivery", type: 'cp'},
          {class: "pc05", label: "Convenience & Supermarket", type: 'cp'},
          {class: "pc06", label: "Gas & Oil", type: 'cp'},
          {class: "pc07", label: "E-Commerce", type: 'cnp'},
          {class: "pc08", label: "Professional Services"},
          {class: "pc09", label: "Tourism"},
          {class: "pc10", label: "Business to Business"},
          {class: "pc11", label: "Community Groups & Orgs", type: 'cp'},
          {class: "pc12", label: "Utilities", type: 'cp'},
          {class: "other", label: "Other"},
        ];
    },
  },
  watch: {
    'app.hasWebsite'() {
      if (this.app.hasWebsite) {
        var fields=["webContact", "webCustService", "webBilling", "webShipping", 
            "webReturn", "webTerms", "webPrivacy", "webDescription", 
            "webCurrency", "webSsl", "webUrlList", "webUser"];
        for(var i in fields) {
          this.$set(this.app.requirements, fields[i], false);
        }
      } 
    },
  },
  data() { return {
    companydata: appData.company,
  }},
  methods: {
    indAvail(ind) {
      if (!this.app.mainType)
        return false;
      if (!ind.type)
        return true;
      return ind.type==this.app.mainType;
    },
    nextStep() {
      if (!this.validate())
        return stopLoading("Please fill all required fields", "error");
      this.$emit("next");      
    },
    toggleWebAll() {
      var fields=["webContact", "webCustService", "webBilling", "webShipping", 
          "webReturn", "webTerms", "webPrivacy", "webDescription", 
          "webCurrency", "webSsl", "webUrlList", "webUser"];
      var wasSelected=this.webAll;
      if (this.app.hasWebsite)
        wasSelected=true;
      for(var i in fields) {
        this.$set(this.app.requirements, fields[i], !wasSelected);
      }
    },
  },
  mixins: [formValidation.mixin]
}
</script>
