<template>

<form class="edit-system search-form company-branding">
  <div class="twocol">
    <div>
      <h1>Partner Portal</h1>
      <div class="field">
        <label>Login Logo:</label>
        <photo-obj :basepath="basePath" v-model="files.partnerLoginLogo" />
      </div>
      <div class="field">
        <label>Login Text:</label>
        <input v-model="form.partnerLoginText" />
      </div>
      <div class="field">
        <label>Main Logo:</label>
        <photo-obj :basepath="basePath" v-model="files.partnerLogo" />
      </div>
    </div>
  </div>
  <div class="buttons">
    <button class="btn btn-large btn-primary" v-on:click.prevent="updateSettings">Submit</button>
  </div>  
</form>


</template>

<script>
export default {
  data: ()=>({
    files: {
      loginLogo: null,
      logo: null,
      footerLogo: null,
      partnerLoginLogo: null,
      partnerLogo: null,
      merchantLoginLogo: null,
      merchantLogo: null,
    },
    form: {
      loginLogo: null,
      loginText: '',
      logo: null,
      footerLogo: null,
      footerText: '',
      partnerLoginLogo: null,
      partnerLoginText: '',
      partnerLogo: null,
      merchantLoginLogo: null,
      merchantLoginText: '',
      merchantLogo: null,
    },
    opts: {
      pin: '',
    },
    basePath: '',
  }),
  mounted() {
    this.refresh2();
  },
  methods: {
    async refresh2() {
      var data=await api.get("Settings", 1);
      this.form=data.item;
      this.basePath=data.desc.logo.basepath;
      for(var field in this.files)
        this.files[field]=this.form[field];
    },
    async updateSettings() {
      startLoading();
      var toUpdate={
        loginText: this.form.loginText,
        footerText: this.form.footerText,
        partnerLoginText: this.form.partnerLoginText,
        merchantLoginText: this.form.merchantLoginText,
      };
      for(var field in this.files) {
        if (this.files[field] instanceof File) {
          if (!window.validateFileExt(this.files[field]))
            return stopLoading("Invalid file extension", "error");
          await api.upload("Settings", 1, this.files[field], {field});
        }
      }
      for(var field in this.files)
        if (this.files[field]===null)
          toUpdate[field]=null;
      await api.update("Settings", 1, toUpdate);
      stopLoading("Settings updated");
      await this.refresh2();
    },
  },
}
</script>
