<template>
<span>
  <button
    class="btn btn-xs"
    style="background-color: #357aba;"
    @click.stop="val = !val">
    {{ text }}
  </button>
  <template v-if="val">
    <button
      class="btn btn-xs"
      style="background-color: #357aba;"
      @click.stop="$emit('toggleSelectAll')">
      {{ allSelected ? 'Unselect all' : 'Select all' }}
    </button>
    <button
      v-if="anySelected"
      class="btn btn-xs"
      style="background-color: #357aba;"
      @click.stop="$emit('assignSelected')">
      {{ word }} Leads
    </button>
  </template>
</span>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: true
    },

    assign: {
      type: Boolean,
      default: true
    },

    anySelected: {
      type: Boolean,
      default: false
    },

    allSelected: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    word () {
      return this.assign ? 'Assign' : 'Unassign'
    },

    text () {
      return this.val ? `Cancel Bulk Leads ${this.word}` : `Bulk Leads ${this.word}`
    },

    val: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
}
</script>
