<template>
  <div class="messages">
    <h2>System Messages</h2>
    <ul>
      <li v-for="message in messages" :key="message.id">
        <div class="date">
          <template v-if="formatDate(message.date, 'X') < dateNow('X') - 86400">
            <span class="new">New</span>
          </template>
          {{ formatDate(message.date, "MMMM D, YYYY [at] h:mm A z") }}
        </div>
        <div class="message">{{ message.message }}</div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  computed: {
    messages() {
      return window.appData.messages;
    },
  },

  mixins: [window.vueMixins.dateFormatter],
};
</script>
