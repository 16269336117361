<template>
  <view-profile :header="['Customer Detail']" v-if="customer">
    <template v-slot:tabsheader>
      <p v-if="customer.emailConfirmation">Email confirmed</p>
      <p v-else>Email is not confirmed. Boarding is disabled</p>
    </template>
    <template v-slot:tabsmain>
      <ul>
        <template v-for="(tabData, tabId) in tabs" :class="{active: tab==tabId}">
          <template v-if="tabData.disabled">
            <template v-if="customer.emailConfirmation">
              <li :class="{active: tab==tabId}">
                <a href="" v-on:click.prevent="tab=tabId">{{ tabData.label }}</a>
              </li>
            </template>
            <template v-else>
              <li class="disabled">
                <a href="" v-on:click.prevent="">{{ tabData.label }}</a>
              </li>
            </template>
          </template>
          <template v-else>
            <li :class="{active: tab==tabId}">
              <a href="" v-on:click.prevent="tab=tabId">{{ tabData.label }}</a>
            </li>
          </template>
        </template>
      </ul>
      <div class="status" v-if="customer">
        <template v-if="customer.accountType == 'Personal'">
          <label><strong>{{ customer.personal.profileNumber }}</strong></label>
          <label><strong>{{ customer.personal.firstName }} {{ customer.personal.lastName }}</strong></label>
          <label><strong>{{ customer.personal.status }} - {{ statusDescription }}</strong></label>
        </template>
        <template v-if="customer.accountType == 'Business'">
          <label><strong>{{ customer.business.profileNumber }}</strong></label>
          <label><strong>{{ customer.business.companyName }}</strong></label>
          <label><strong>{{ customer.business.status }} - {{ statusDescription }}</strong></label>
        </template>
      </div>
    </template>
    <template v-slot:tabssub v-for="subTabs in [sTabs[tab]]" v-if="customer">
      <ul v-if="customer.accountType == 'Personal'">
        <li v-for="(tabData, tabId) in subTabs" :class="{active: subTab==tabId}" v-if="tabId != 'company'">
          <a href="" v-on:click.prevent="subTab=tabId, editMode=false">{{ tabData.label }}</a>
        </li>
      </ul>
      <ul v-if="customer.accountType == 'Business'">
        <li v-for="(tabData, tabId) in subTabs" :class="{active: subTab==tabId}">
          <a href="" v-on:click.prevent="subTab=tabId, editMode=false">{{ tabData.label }}</a>
        </li>
      </ul>
    </template>
    
    <template v-slot:actions>
      <PortalTarget name="actions"></PortalTarget>
    </template>
    
    <div class="merchant-panel">
      <div class="data-content" v-if="customer">
        <treasury-notes class="" :notes="notes" type="Customer" :id="id" ref="notes" v-if="subTab === 'notes'" />
        
        <formview-treasury-main :customer="customer" :tab="formTabs[subTab]" :notes="notes" :editMode.sync="editMode" v-if="formTabs[subTab]!==undefined" :edit="sTabs[tab][subTab].edit" />

        <treasury-documents :item="customer" :merchant="customer" v-if="subTab=='documents'" ref="documents" v-on:reloadnotes="loadNotes" />

        <treasury-emails :item="customer" v-if="subTab=='emails'" ref="emails" />

        <treasury-scans :item="customer" v-if="subTab=='scans'" ref="scans" />

        <treasury-boarding :item="customer" v-if="subTab=='boarding'" v-on:reloadnotes="loadNotes" />

      </div>
    </div>
  </view-profile>
  </template>
  
  <script>
  export default {
    props: ["id"],
    data: ()=>({
      tab: "general",
      subTab: "general",
      tabs: {
        boarding: {label: "Boarding", def: "boarding", disabled: true},
        general: {label: "General", def: "general"},
        documents: {label: "Documents", def: "documents"},
        compliance: {label: "Compliance", def: "status"},
        accounts: {label: "Accounts", def: "accounts"},
        notes: {label: "Notes", def: "notes"},
        emails: {label: "Emails", def: "emails"},
        access: {label: "Access", def: "users", act: true},
        settings: {label: "Settings", def: "settings", act: true},
      },
      sTabs: {
        boarding: {
          boarding: {label: "Onboarding"},
        },
        general: {
          general: {label: 'Profile', edit: true},
          company: {label: 'Company', edit: true},
          contacts: {label: 'Contacts', edit: true},
        },
        documents: {
          documents: {label: 'Documents'},
        },
        compliance: {
          status: {label: 'Status'},
          scans: {label: 'Scans'},
        },
        accounts: {
          accounts: {label: "Accounts"/*, edit: true*/},
        },
        emails: {
          emails: {label: 'Emails'},
        },
        notes: {
          notes: {label: 'Notes'},
        },
        access: {
          users: {label: "Users"/*, edit: true*/},
          activity: {label: "Activity"},
        },
        settings: {
          settings: {label: "Settings"/*, edit: true*/},
        },
        
      },
      editMode: false,
      customer: null,
      notes: [],
      form: {},
      formTabs: {
        general: 0,
        company: 1,
        contacts: 2,
        status: 999,
      },
      statusDescription: null
    }),
    watch: {
      tab() {
        this.subTab=this.tabs[this.tab].def;
      },
    },
    methods: {
  
      async loadNotes () {
        const filter = {
          type: 'Customer',
          parentId: this.id
        }
        this.notes = (await api.list("Note", filter, ['id:ASC'])).items || [];
      },
      async loadCustomer () {
        const response = await api.get('TreasuryAccount', this.id)
        this.customer = response.item
        //this.clearForm()
        if(this.customer.accountType == 'Personal') {
          var status = (await api.list('Status', { status: this.customer.personal.status})).items
        }else{
          var status = (await api.list('Status', { status: this.customer.business.status})).items
        }
        this.statusDescription = status[0].description
      },
  
      clearForm () {
        const values = ['first_name', 'last_name', 'title', 'role', 'address', 'city', 'province', 'postal', 'country', 'ec_first_name', 'ec_last_name', 'ec_email', 'ec_phone', 'portal', 'phone', 'phone_ext']
        values.forEach(key => {
          this.$set(this.form, key, this.employee[key])
        })
        this.$set(this.form, 'companyId', this.employee?.companyId?.id)
        this.$set(this.form, 'salesBrandId', this.employee?.salesBrandId?.id)
      },
    },
    mounted() {
      this.loadNotes();
      this.loadCustomer();
    },
  }
  </script>
  