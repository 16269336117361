<template>
<div class="merchant-panel">
  <div class="data-content">
    <ul class="tabs">
      <li :class="{active: dataTab==0}" v-on:click="dataTab=0">General</li>
      <li :class="{active: dataTab==1}" v-on:click="dataTab=1">Business</li>
      <li :class="{active: dataTab==2}" v-on:click="dataTab=2">Ownership</li>
      <li :class="{active: dataTab==3}" v-on:click="dataTab=3">Website</li>
      <li :class="{active: dataTab==4}" v-on:click="dataTab=4">Business details</li>
      <li :class="{active: dataTab==5}" v-on:click="dataTab=5">Additional Notes</li>
    </ul>
    <div class="main">
      <formview-pre-main :item="item" :tab="dataTab" :notes="notes" :editMode.sync="editMode" />
    </div>
  </div>
  <aside class="actions">
    <ul class="tabs">
      <li :class="{active: actionsTab==0}" v-on:click="actionsTab=0">General</li>
      <li :class="{active: actionsTab==1}" v-on:click="actionsTab=1">Application</li>
      <li :class="{active: actionsTab==2}" v-on:click="actionsTab=2">Documents</li>
      <li :class="{active: actionsTab==3}" v-on:click="actionsTab=3">Compliance</li>
<!--       <li :class="{active: actionsTab==7}" v-on:click="actionsTab=7">Agreements</li> -->
      <li :class="{active: actionsTab==5}" v-on:click="actionsTab=5">Email</li>
<!--       <li :class="{active: actionsTab==4}" v-on:click="actionsTab=4">Merchant setup</li> -->
<!--       <li :class="{active: actionsTab==6}" v-on:click="actionsTab=6">Accounting</li> -->
    </ul>
    <div class="main" v-if="actionsTab==0">
      <div class="action-buttons">
        <a href="" v-on:click.prevent="listChecklist();showFinalApproval=false">Setup Checklist</a>
        <a href="" v-on:click.prevent="uwTab=2;showFinalApproval=false">Status Update</a>
        <a href="" v-on:click.prevent="editMode=true" :class="{disabled: editMode}">Edit</a>
        <a href="" v-on:click.prevent="listPreChecks">Pre-Checks</a>
<!--         <a href="" v-for="(optLabel, optMethod) in actions[item.status]" v-on:click.prevent="doAction(item, optMethod)" v-if="optMethod!='A02'">{{ optLabel }}</a> -->
        <a href="" v-on:click.prevent="" class="">Negative List</a>
<!--         <a href="" v-on:click.prevent="" class="disabled">Remove Neg List</a> -->
        <a href="" v-on:click.prevent="" class="">Follow-up</a>
<!--         <a href="" v-on:click.prevent="" class="disabled">No follow-up</a> -->
      </div>
      <div v-if="uwTab==2">
        <div class="action-sub-buttons">
          <a href="" v-for="(optLabel, optMethod) in allActions" v-on:click.prevent="!isDisabledAction(optMethod) && doAction(item, optMethod)" :class="{disabled: isDisabledAction(optMethod)}">{{ optLabel }}</a>
          <a href="" v-on:click.prevent="" class="disabled">Hold Account</a>
          <a href="" v-on:click.prevent="" class="disabled">Close Account</a>
        </div>

        <table class="file-list content-table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Status Update</th>              
              <th>Updated By</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="uwItem in notes" v-if="uwItem.system && uwItem.message.indexOf('Updated to ')==0">
              <td>{{ formatDate(uwItem.created, "YYYY-MM-DD h:mm a") }}</td>
              <td>{{ uwItem.message.substring(11) }}</td>              
              <td>{{ uwItem.author.username }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <view-preq-setupcl v-if="uwTab==3" key="setupcl" :prechecks="uwList" :weblist="webList" />
      <view-preq-precheck v-if="uwTab==1" key="precheck" :uwList="uwList" :preq="true" />
    </div>
    <div class="main" v-else-if="actionsTab==1">
<!--       <div class="action-buttons"> -->
<!--       </div> -->
      <div class="file-field" style="margin-top: 8px">
        <label>Pre-Qualification form:</label>
        <a :href="pdfUrl" target="_blank">{{ item.applicationId+"-"+item.companyName }}</a>
      </div>
    </div>
    <div class="main" v-else-if="actionsTab==2">
      <div class="file-field">
        <label>Processing statement:</label>
        <a :href="fileLink(item.fileStatement)" target="_blank">{{ item.fileStatement || "(no file)" }}</a>
      </div>
    </div>
    <div class="main" v-if="actionsTab==3">
      <div class="action-buttons">
        <a href="" v-on:click.prevent="listWebsiteChecks">Website Check</a>
        <a href="" v-on:click.prevent="" class="disabled">Doc-Checks</a>
        <a href="" v-on:click.prevent="" class="disabled">Match/VMAS</a>
        <a href="" v-on:click.prevent="" class="disabled">OFAC</a>
        <a href="" v-on:click.prevent="" class="disabled">KYC</a>
        <a href="" v-on:click.prevent="" class="disabled">AML</a>
        <a href="" v-on:click.prevent="" :class="{disabled: item.status!='A00'}">IDM</a>
        <a href="" v-on:click.prevent="listG2" :class="{disabled: item.status!='A00'}">G2</a>
      </div>
      <view-app-websitecheck v-if="uwTab==2" key="websitecheck" :uwList="webList" :preq="true" />
      <view-app-extcheck type="g2" v-if="uwTab==10" key="extcheckg2" :uwList="checkList" :preq="true" />
    </div>
    <div class="main" v-if="actionsTab==4">
      <div class="action-buttons">
        <a href="" v-on:click.prevent="">Modules</a>
        <a href="" v-on:click.prevent="">Gateway</a>
        <a href="" v-on:click.prevent="">Financial Setup</a>
      </div>
    </div>
    <div class="main" v-if="actionsTab==5">
      <div class="action-buttons">
        <a href="" :class="{disabled: item.status!='A01'}" v-on:click.prevent="item.status=='A01' && sendEmail('PreApproved')">Pre-Approval</a>
        <a href="" v-on:click.prevent="appReminderAvailable && sendEmail('ApplicationReminder')" :class="{disabled: !appReminderAvailable}">Application Reminder</a>
        <a href="" v-on:click.prevent="" class="disabled">Applications</a>
        <a href="" v-on:click.prevent="" class="disabled">Declined</a>
        <a href="" v-on:click.prevent="" class="disabled">Agreements</a>
        <a href="" v-on:click.prevent="" class="disabled">Approved</a>
        <a href="" v-on:click.prevent="" class="disabled">Merchant Welcome</a>
        <a href="" v-on:click.prevent="" class="disabled">Gateway Info</a>
        <a href="" v-on:click.prevent="" class="disabled">Config Setup</a>
      </div>
      <table class="file-list content-table">
        <thead>
          <tr>
            <th>Type</th>
            <th>Created</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="email in emails">
            <td>{{ email.emailType }}</td>
            <td>{{ formatDate(email.created, "YYYY-MM-DD hh:mm:ss") }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="main" v-if="actionsTab==6">
      <div class="action-buttons">
        <a href="" v-on:click.prevent="">Generate New</a>
      </div>
    </div>
  </aside>
</div>
</template>

<script>
export default {
  data() { return {
    dataTab: 0,
    actionsTab: 0,
    uwTab: 0,
    uwList: [],
    checkList: [],
    webList: [],
    item: {},
    actions: {},
    allActions: {},
    notes: [],
    emails: [],
    editMode: false,
    pdfUrl: appData.pdfUrl,
  }},
  mounted() {
    if (window.appData.existingPreq)
      this.item=JSON.parse(JSON.stringify(window.appData.existingPreq));
    if (window.appData.actions)
      this.actions=JSON.parse(JSON.stringify(window.appData.actions));
    if (window.appData.allActions)
      this.allActions=JSON.parse(JSON.stringify(window.appData.allActions));
    if (window.appData.notes)
      this.notes=JSON.parse(JSON.stringify(window.appData.notes));
    this.listEmails();
  },
  methods: {
    isDisabledAction(method) {
      if (method=="A02" || method=="A03" || method=="A04")
        return true;
      for(var i in this.actions[this.item.status])
        if (i==method)
          return false;
      return true;
    },
    listEmails: async function() {
      this.emails=(await api.list("ApplicationEmail", {preqId: this.item.id}, ["id:DESC"])).items || [];
    },
    doAction: async function(item, targetMethod) {
      startLoading();
      await api.update("Prequalification", item.id, {status: targetMethod});
      if (targetMethod=="A01") {
        this.sendEmail("PreApproved");
      }
      stopLoading("Status updated");
      item.status=targetMethod;
    },
    fileLink(fileName) {
      if (!fileName)
        return null;
      return "download/Prequalification/"+this.item.id;
      return "files/prequalification/"+fileName;
    },
    listChecklist: async function() {
      this.uwTab=3;
      this.uwList=(await api.list("PreCheck", {prequalId: this.item.id}, ["id:DESC"])).items || [];
      this.webList=(await api.list("WebsiteCheck", {prequalId: this.item.id}, ["id:DESC"])).items || [];
    },
    listPreChecks: async function() {
      this.uwTab=1;
      this.uwList=(await api.list("PreCheck", {prequalId: this.item.id}, ["id:DESC"])).items || [];
    },
    listWebsiteChecks: async function() {
      this.uwTab=2;
      this.webList=(await api.list("WebsiteCheck", {prequalId: this.item.id}, ["id:DESC"])).items || [];
    },
    sendEmail: async function(type) {
      startLoading();
      var res=await api.create("ApplicationEmail", {preqId: this.item.id, emailType: type, created: "CURRENT_TIMESTAMP"});
      stopLoading("Email sent");
      var newItem=await api.get("ApplicationEmail", res.id);
      if (newItem.item)
        this.emails.unshift(newItem.item);
    },
    listG2: async function() {
      this.uwTab=10;
      this.checkList=[];
      this.checkList=(await api.list("ExtCheck", {preqId: this.item.id, type: "g2"}, ["id:DESC"])).items || [];
    },
  },
  computed: {
    appReminderAvailable() {
      if (this.item && this.item.status!="A01")
        return false;
      var emailSent=false;
      for(var i in this.emails)
        if (this.emails[i].emailType=="PreApproved")
          emailSent=true;
      if (!emailSent)
        return false;
      return true;
    },
  },
  mixins: [vueMixins.dateFormatter],
}
</script>
