<template>
<div class="element-toggle slin">
  <label :class="{checked: value==yesVal}">
    <span></span>
    <input type="radio" :value="yesVal" v-model="actualValue" v-on:change="updateVal">
    Yes
  </label>
  <label :class="{checked: value==noVal}">
    <span></span>
    <input type="radio" :value="noVal" v-model="actualValue" v-on:change="updateVal">
    No
  </label>
</div>
</template>

<script>
export default {
  data() { return {
    actualValue: false,
  }},
  props: ["value", "yes", "no"],
  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        this.actualValue=newVal;
      },
    },
  },
  computed: {
    yesVal() {
      return this.yes===undefined?'1':this.yes;
    },
    noVal() {
      return this.no===undefined?'0':this.no;
    },
  },
  methods: {
    updateVal() {
      this.$emit("input", this.actualValue);
    },
  },
}
</script>
