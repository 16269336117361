<template>
      <div>
        <div class="action-sub-buttons">
          <a href="" v-if="!isDisabledAction(optMethod)" v-for="(optLabel, optMethod) in allActions" v-on:click.prevent="!isDisabledAction(optMethod) && doAction(optMethod)" :class="{disabled: isDisabledAction(optMethod)}">{{ optLabel }}</a>
        </div>

        <table class="file-list content-table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Status Update</th>              
              <th>Updated By</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="uwItem in notes" v-if="uwItem.system && uwItem.message.indexOf('Updated to ')==0">
              <td>{{ formatDate(uwItem.created, "YYYY-MM-DD h:mm a") }}</td>
              <td>{{ uwItem.message.substring(11) }}</td>              
              <td>{{ uwItem.author.username }}</td>
            </tr>
          </tbody>
        </table>
      </div>
</template>


<script>
export default {
  props: ["item", "notes"],
  data() { return {
    allActions: {
      "P04": "Denied",
      "A02": "Pending",
      "APP": "Approve",
      "P02": "Restrict",
      "A08": "Active",
      "P03": "Close",
    },
  }},
  methods: {
    isDisabledAction(method) {
      return this.$parent.isDisabledAction(method);
    },
    doAction(method) {
      if (method[0]=="P")
        this.notes.push({
          message: "Updated to "+method+" - "+this.allActions[method],
          created: moment().format("YYYY-MM-DD HH:mm:ss"),
          system: 1,
          author: {
            username: appData.currentUser.username,
          },
        });
      return this.$parent.doAction(method);
    },
  },
  mixins: [vueMixins.dateFormatter],  
}
</script>
