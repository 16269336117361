<template>
<div>
  <div class="content-table-container">
    <table class="content-table">
      <tbody>
        <tr>
          <th><label>
            <input type="checkbox" v-model="options.enabledMethods" value="CardBiller" />
            CardBiller
          </label></th>
        </tr>
        <tr>
          <td style="text-align: left">
            <label>
              <input type="checkbox" v-model="options.enabledMethods" value="Visa" :disabled="options.enabledMethods.indexOf('CardBiller')==-1" />
              Visa
            </label>
            <label>
              <input type="checkbox" v-model="options.enabledMethods" value="Mastercard" :disabled="options.enabledMethods.indexOf('CardBiller')==-1" />
              Mastercard
            </label>
            <label>
              <input type="checkbox" v-model="options.enabledMethods" value="Discover" :disabled="options.enabledMethods.indexOf('CardBiller')==-1" />
              Discover
            </label>
            <label>
              <input type="checkbox" v-model="options.enabledMethods" value="AMEX" :disabled="options.enabledMethods.indexOf('CardBiller')==-1" />
              AMEX
            </label>
            <label>
              <input type="checkbox" v-model="options.enabledMethods" value="China UnionPay" :disabled="options.enabledMethods.indexOf('CardBiller')==-1" />
              China UnionPay
            </label>
          </td>
        </tr>
        <tr>
          <th><label>
            <input type="checkbox" v-model="options.enabledMethods" value="CardBiller Plus" />
            CardBiller Plus
          </label></th>
        </tr>
        <tr>
          <td style="text-align: left">
            <label>
              <input type="checkbox" v-model="options.enabledMethods" value="Apple Pay" :disabled="options.enabledMethods.indexOf('CardBiller Plus')==-1" />
              Apple Pay
            </label>
            <label>
              <input type="checkbox" v-model="options.enabledMethods" value="Google Pay" :disabled="options.enabledMethods.indexOf('CardBiller Plus')==-1" />
              Google Pay
            </label>
            <label>
              <input type="checkbox" v-model="options.enabledMethods" value="Interac Online" :disabled="options.enabledMethods.indexOf('CardBiller Plus')==-1" />
              Interac Online
            </label>
          </td>
        </tr>
        <tr>
          <th><label>
            <input type="checkbox" v-model="options.enabledMethods" value="BankBiller" />
            BankBiller
          </label></th>
        </tr>
        <tr>
          <td style="text-align: left">
            <label>
              <input type="checkbox" v-model="options.enabledMethods" value="EFT" :disabled="options.enabledMethods.indexOf('BankBiller')==-1" />
              EFT
            </label>
            <label>
              <input type="checkbox" v-model="options.enabledMethods" value="ACH" :disabled="options.enabledMethods.indexOf('BankBiller')==-1" />
              ACH
            </label>
          </td>
        </tr>
        <tr>
          <th><label>
            <input type="checkbox" v-model="options.enabledMethods" value="CoinBiller" />
            CoinBiller
          </label></th>
        </tr>
        <tr>
          <td style="text-align: left">
            <label>
              <input type="checkbox" v-model="options.enabledMethods" value="Bitcoin" :disabled="options.enabledMethods.indexOf('CoinBiller')==-1" />
              Bitcoin
            </label>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="buttons">
    <button class="btn btn-large btn-primary" v-on:click.prevent="updateSettings">Submit</button>
  </div>  
</div>
</template>

<script>
export default {
  data() { return {
    options: {
      enabledMethods: [],
    },
  }},
  mounted() {
    this.refresh();
  },
  methods: {
    refresh() {
      var that=this;
      api.get("Options", 1).then(function(data) {
        that.options=data.item;
        that.options.enabledMethods=that.options.enabledMethods || [];
      });
    },
    updateSettings: async function() {
      startLoading();
      await api.update("Options", 1, this.options);
      stopLoading("Payment methods updated");
    },
  },
}

</script>

