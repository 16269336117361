<template>
  <div>
    <div class="action-sub-buttons" v-if="!newCheck && !uwShown">
      <a class="btn" v-on:click="newWebCheck">New</a>
      <a class="btn" v-if="uwSelected" v-on:click.prevent="doShowItems(uwSelected)">View</a>
    </div>
    <div class="action-sub-buttons" v-else>
      <a class="btn" v-on:click="uwShown=null; newCheck=null">Back</a>
      <a class="btn" v-if="uwShown" :href="printUrl" target="_blank">Print</a>
    </div>
    <table class="file-list content-table" v-if="!newCheck && !uwShown">
      <thead>
        <tr>
          <th style="width: 1%"></th>
          <th>ID</th>
          <th>Legal Name</th>
          <th>Date</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(uwItem, uwI) in uwList">
          <td><input type="radio" v-model="uwSelected" :value="uwItem.id"></td>
          <td>{{ uwList.length-uwI }}</td>
          <td>{{ uwItem.companyName }}</td>
          <td>{{ formatDate(uwItem.createdDate, "YYYY-MM-DD HH:mm") }}</td>
        </tr>
      </tbody>
    </table>
    <div class="check-form" v-if="newCheck">
      <template v-for="field in agent?agentFields:fields">
        <validator :value="newCheck[field[0]]" reqs="required" class="check-field">
          <label>{{ field[1] }} - <span>{{ field[2] }}</span></label>
          <formel-toggle v-model="newCheck[field[0]]" />
        </validator>
        <validator :value="newCheck[field[0]+'Desc']" reqs="required" class="check-desc" v-if="newCheck[field[0]]==='0'">
          <textarea v-model="newCheck[field[0]+'Desc']" />
        </validator>
      </template>
      <div class="actions">
        <button class="btn btn-large" v-on:click="saveNewCheck">Save</button>
      </div>
    </div>
    <div class="web-check-details" v-else-if="uwShown">
      <div class="check-result" v-for="field in agent?agentFields:fields">
        <label>{{ field[1] }} - <span>{{ field[2] }}</span></label>
        <span class="val">{{ uwShownItem[field[0]]==0?'No':'Yes' }}</span>
        <span class="note" v-if="uwShownItem[field[0]]==0">{{ uwShownItem[field[0]+'Desc'] }}</span>
      </div>
    </div>
    <portal to="actions">
      <ul>
        <li>
          <a href="" v-on:click.prevent="newWebCheck()" v-if="!newCheck && !uwShown">New</a>
          <a href="" v-on:click.prevent="uwShown=null; newCheck=null" v-else>Back</a>
        </li>
        <li v-if="uwShown">
          <a :href="printUrl" target="_blank">Print</a>
        </li>
      </ul>
    </portal>
  </div>
</template>

<script>
export default {
  props: ["uwList", "preq", "agent"],
  data() { return {
    uwSelected: null,
    uwShown: null,
    shownItem: 0,
    newCheck: null,
    fields: [
      ["phoneNumber",       "Customer Service Phone number", "ensure customer service/contact phone number is listed on the site and matches the application."],
      ["businessName",      "Business Name", "compare the website name to that of the merchant application and ensure they match."],
      ["contactInfo",       "Contact Information/location", "confirm physical and email address match application, and verify that address is in the U.S."],
      ["shippingInfo",      "Shipping/Delivery Information", "confirm the delayed delivery timeframe matches the application to ensure proper risk calculation. Policies are also on website and are clear"],
      ["returnPolicy",      "Refund/Return Policy", "review the refund/cancellation policy."],
      ["termsPrivacy",      "Terms & Conditions, Privacy Statement", "review terms & privacy policy"],
      ["goodsDescription",  "Complete description of goods or services offered", "confirm the product or service sold is consistent with the product/service listed on the application and that the average ticket/volume appears to be appropriate/reasonable for the business."],
      ["currency",          "Transaction currency", "review site to validate transaction is in appropriate currency (dollars)."],
      ["links",             "Links within the site", "When disclosed, examine links to other sites to evaluate products/services are consistent with application."],
      ["legalRestrictions", "Export or Legal restrictions (if applicable)", "review any legal restrictions listed on the site"],
      ["billingDescriptor", "Billing Descriptor", "Billing Descriptor is clearly displayed"],
      ["secure",            "Secure Site for transmission", "does the website have a secure method of accepting payments (SSL or SQL) and this is disclosed to consumers. * New applications using Authorize.net are considered secure as this is recognized as a secure method of transmission."],
      ["screenshots",       "Screenshot of All Website Pages", "Obtained screenshot off all pages of the website"],
    ],
    agentFields: [
      ["phoneNumber",       "Customer Service Phone number", "ensure customer service/contact phone number is listed on the site and matches the application."],
      ["businessName",      "Business Name", "compare the website name to ensure they match."],
      ["termsPrivacy",      "Terms & Conditions, Privacy Statement", "review terms & privacy policy"],
      ["links",             "Links within the site", "When disclosed, examine links to other sites."],
      ["legalRestrictions", "Export or Legal restrictions (if applicable)", "review any legal restrictions listed on the site"],
      ["secure",            "Secure Site for transmission", "does the website have a secure method of accepting new applications"],
      ["screenshots",       "Screenshot of All Website Pages", "Obtained screenshot off all pages of the website"],      
    ],
  }},
  methods: {
    doShowItems(id, animate) {
      this.uwShown=id;
    },
    listPreChecks: async function() {
      await this.$parent.listWebsiteChecks();
    },
    newWebCheck: async function() {
      if (this.preq)
        this.newCheck={
          prequalId: this.parentItem.id,
          userId: appData.currentUser.id,
          createdDate: "CURRENT_TIMESTAMP",
        };
      else if (this.agent)
        this.newCheck={
          agentId: this.parentItem.id,
          userId: appData.currentUser.id,
          createdDate: "CURRENT_TIMESTAMP",
        };
      else
        this.newCheck={
          appId: this.parentItem.id,
          userId: appData.currentUser.id,
          createdDate: "CURRENT_TIMESTAMP",
        };
    },
    saveNewCheck: async function() {
      if (!this.validate())
        return;
      var id=(await api.create("WebsiteCheck", this.newCheck)).id;
      await this.listPreChecks();
      this.uwShown=id;
    },
  },
  computed: {
    parentItem() {
      return this.$parent.item || this.$parent.$parent.item;
    },
    uwShownItem() {
      if (this.uwShown)
        return this.uwList.find((a)=>a.id==this.uwShown);
      return {};
    },
    printUrl() {
      if (!this.uwShownItem)
        return null;
      return `applications/webcheck/${this.uwShownItem.id}/pdf`;
    },
  },
  mounted() {
    this.$parent.$parent.isWsCheck=true;
  },
  mixins: [vueMixins.dateFormatter, formValidation.mixin],  
}
</script>
