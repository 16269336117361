<template>
  <input type="text" :value="value" v-on:input="update" v-on:blur="formatData" />
</template>

<script>
export default {
  props: ["value", "step"],
  methods: {
    update($e) {
      var newVal=$e.target.value || "";
      newVal=newVal.replace(/[^0-9.]/g, "");
      $e.target.value=newVal;
      this.$emit('input', newVal);
    },
    formatData() {
      var digits=0;
      var step=this.step;
      while(step && step<1) {
        step*=10;
        digits++;
      }
      if (this.value)
       this.$emit("input", ((this.value*1) || 0).toFixed(digits));
    },
  },
}
</script>
