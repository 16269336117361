<template>
  <div class="login-content">
    <img :src="logo" alt="" class="logo" />
    <p v-if="!submitted">Please enter your Account ID and username below to reset your password</p>
    <form class="form-login" v-on:submit.prevent="doSubmit" v-if="!submitted">
      <input type="text" name="agentId" placeholder="Account ID#" v-model="accountId" required />
      <input type="text" name="username" placeholder="Username" v-model="username" required />
      <button>Submit</button>
    </form>
    <div class="login-msg" v-else-if="!success">
      <h1>Username Not Found!</h1>
      <p>Your username was not found. Please check your information and try again. If the problem persists or for immediate assistance, contact support.</p>
      <form><button type="button" v-on:click.prevent="submitted=false">Back</button></form>
    </div>
    <div class="login-msg" v-else>
      <h1>Reset Instructions Sent!</h1>
      <p>Please follow the instructions found in the email to reset your password. If you do not receive the email or for immediate assistance, contact support.</p>
    </div>
  </div>

</template>
<script>
export default {
  props: ["logo"],
  data: ()=>({
    submitted: false,
    failed: false,
    success: false,
    username: "",
    accountId: "",
  }),
  methods: {
    doSubmit() {
      $.post(location.href, {username: this.username, accountId: this.accountId}, (data)=>{
        this.submitted=true;
        this.success=!!data.success;
      }, "json");
    }
  },
  mounted() {
    if (window.resetStatus=="valid") {
      setTimeout(()=>{
        userResetPw.resetCode=window.resetCode;
        userResetPw.showPopup()
      }, 30);
    }
  },
}
</script>
