<template>
<div>
<form class="scan-filter" v-on:submit.prevent="activateFilter">
  <div class="actions">
    <button class="btn" disabled>+ New</button>
  </div>
  <ul class="filter">
    <li>
      <label>Date search</label>
      <datepicker v-model="filter.dateFrom" />
      <datepicker v-model="filter.dateTo" />
    </li>
    <li>
      <label>Account</label>
<!--       <dyn-select :options="applicationsToShow" v-model="filter.account" /> -->
      <input type="text" v-model="filter.accountNo" />
    </li>
    <li>
      <label>Active</label>
      <dyn-select :options="yesNoNull" v-model="filter.complete" />
    </li>
    <li class="actions">
      <button class="btn">Search</button>
    </li>
  </ul>
</form>
<ul class="acc-results">
  <li class="active">
    <h4><span class="label">G2 Monitoring</span></h4>
    <div class="acc-list" style="display: block;">
        <table>
            <thead>
                <tr>
                    <th>Account ID</th>
                    <th>Company Name</th>
                    <th>Date</th>
                    <th>URL</th>
                    <th>Active</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in itemsToShow">
                  <td><a :href="'/applications/'+item.app.id+'#compliance-g2'">{{ item.app && item.app.accountId }}</a></td>
                  <td>{{ item.app && item.app.companyName }}</td>
                  <td>{{ item.createdDate }}</td>
                  <td>{{ item.app && item.app.website }}</td>
                  <td>{{ 'Yes' }}</td>
                  <td>
                     <a href="" v-on:click.prevent="doDelete(item)" class="tb-btn">Delete</a> 
                     <a href="" v-on:click.prevent="doUpdate(item)" class="tb-btn">Update</a> 
                  </td>
                </tr>
            </tbody>
        </table>
    </div>
</li>
</ul>
</div>
</template>
<script>
export default {
  data: ()=>({
    filter: {
      dateFrom: null,
      dateTo: null,
      account: null,
      accountNo: null,
      complete: null,
    },
    activeFilter: {},
    items: [],
    applications: {},
    yesNoNull: [
      {value: null, label: "-"},
      {value: "false", label: "No"},
      {value: "true", label: "Yes"},
    ],
  }),
  created() {
    this.listItems();
  },
  computed: {
    applicationsToShow() {
      var result=[{value: null, label: "-"}];
      for(var id in this.applications)
        result.push({value: id, label: this.applications[id].accountId});
      return result;
    },
    itemsToShow() {
      return this.items.filter(a=>{
        if (this.activeFilter.complete=="false" && a.g2mgmData) return false;
        if (this.activeFilter.complete=="true" && !a.g2mgmData) return false;
        if (this.activeFilter.account && a.app.id!=this.activeFilter.account) return false;
        if (this.activeFilter.dateFrom && a.createdDate<this.activeFilter.dateFrom) return false;
        if (this.activeFilter.dateTo && a.createdDate>this.activeFilter.dateTo+" 24") return false;
        if (this.activeFilter.accountNo) {
          var application=a.app;
          if (!application || !application.accountId || application.accountId.indexOf(this.filter.accountNo)==-1)
            return false;
        }
        return true;
      });
    },
  },
  methods: {
    activateFilter() {
      this.activeFilter=JSON.parse(JSON.stringify(this.filter));
    },
    printUrl(item) {
      if (!item)
        return null;
      if (!item.response || !item.response.pdf_url)
        return null;
      return item.response.pdf_url;
    },
    async refresh(id) {
      startLoading();
      await this.listItems();
      stopLoading();
    },
    async listItems() {
      this.items=(await api.list("Merchant", {g2mgmData: ["IS NOT NULL"]}, ["id:DESC"])).items;
      this.items=this.items.filter(a=>a.g2mgmData);
//       var appIds=this.items.filter((a)=>a.applicationId*1).map((a)=>a.applicationId*1);
//       var apps=await api.list("Application", {id: ["IN ("+appIds.join(",")+")"]});
      for(var item of this.items)
        this.$set(this.applications, item.app.id, item.app);
    },
    async doDelete(item) {
      startLoading();
      await api.action("Merchant", item.id, "deleteG2mdm");
      await this.listItems();
      stopLoading();
    },
    async doUpdate(item) {
      startLoading();
      await api.action("Merchant", item.id, "registerG2mdm");
      await this.listItems();
      stopLoading();
    },
  },
}
</script>
<style lang="scss">
.scan-filter {
  display: flex;
  margin-bottom: 20px;
  .actions button {
    text-decoration: none;
    padding: 4px 20px;
    border: 1px solid black;
    border-radius: 3px;
    color: white;
    background: rgb(50,50,50);
    background: linear-gradient(to bottom, rgba(50,50,50,1) 0%,rgba(5,5,5,1) 100%);
  }
  .filter {
    margin-left: 20px;
    display: flex;
    li {
      margin-right: 20px;
      display: flex;
      align-items: middle;
      label {
        margin-right: 6px;
        line-height: 26px;
      }
      .hasDatepicker {
        width: 120px;
      }
    }
  }
}
</style>
