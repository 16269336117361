<template>
<div class="form-popup" v-show="shown"><div>
  <div class="ncontent appf appnat">
    <a class="close" href="" v-on:click.prevent="dismiss">&times;</a>
    <h3>Final Approval</h3>

<form class="form-step" v-if="step==0">
  <section>
    <h2>Implementation Settings</h2>
    <ul class="column-form column-form-compact">
      <li>
        <label>Compliance Review Days</label>
        <div class="field">
        <validator :value="form.reviewDays" reqs="required number">
          <dyn-select :options="reviewDaysOpts" v-model="form.reviewDays" />
<!--           <input type="text" v-model="form.reviewDays" /> -->
        </validator>
        </div>
      </li>
      <li>
        <label>Settlement Delay (Days)</label>
        <div class="field">
        <validator :value="form.settlementDays" reqs="required number">
          <dyn-select :options="settlementDaysOpts" v-model="form.settlementDays" />
<!--           <input type="text" v-model="form.settlementDays" /> -->
        </validator>
        </div>
      </li>
      <li>
        <label>Monthly Volume Limit</label>
        <div class="field">
        <validator :value="form.monthlyLimit" reqs="required number">
          <formel-money-amount prefix="$" v-model="form.monthlyLimit" />
        </validator>
        </div>
      </li>
      <li>
        <label>Annual Volume Limit</label>
        <div class="field">
        <validator :value="form.annualLimit" reqs="required number">
          <formel-money-amount prefix="$" v-model="form.annualLimit" />
        </validator>
        </div>
      </li>
      <li>
        <label>Account Reserve</label>
        <div class="field">
        <validator :value="form.reserve" reqs="required number">
          <formel-money-amount suffix="%" v-model="form.reserve" />
        </validator>
        </div>
      </li>
      <li>
        <label>Account Reserve Hold (Days)</label>
        <div class="field">
        <validator :value="form.reserveHold" reqs="required number">
          <dyn-select :options="reserveHoldOpts" v-model="form.reserveHold" />
<!--           <input type="text" v-model="form.reserveHold" /> -->
        </validator>
        </div>
      </li>
    </ul>
  </section>
  <div class="buttons">
    <a class="btn btn-large btn-success" v-on:click.prevent="nextStep">Next</a>
  </div>
</form>
<form class="form-step" v-else-if="step==1">
  <section>
    <h2>Implementation Message</h2>
    <ul class="column-form column-form-compact">
      <li>
        <label>Implementation Message</label>
        <div class="field">
        <validator :value="form.implMessage" reqs="char250">
          <textarea type="text" v-model="form.implMessage" />
        </validator>
        </div>
      </li>
    </ul>
  </section>
  <div class="buttons">
    <a class="btn btn-large btn-success" v-on:click.prevent="save">Final Approval</a>
  </div>
</form>
  </div>
  
  
</div></div>
</template>

<script>
var vlMap=function(v) { return {value: v, label: v}; }
export default {
  data() { return {
    step: 0,
    portals: [{value: appData.settings.partnerLoginURL, label: appData.settings.partnerLoginURL}],
    shown: false,
    form: {},
    industries: [
      {value: "Hospitality", label: "Hospitality"},
      {value: "Retail", label: "Retail"},
      {value: "Spa/Salon/Gym", label: "Spa/Salon/Gym"},
      {value: "Gas & Oil", label: "Gas & Oil"},
      {value: "E-Commerce", label: "E-Commerce"},
      {value: "Professional Services", label: "Professional Services"},
      {value: "Convenience & Supermarket", label: "Convenience & Supermarket"},
      {value: "Tourism", label: "Tourism"},
      {value: "Community Groups & Orgs", label: "Community Groups & Orgs"},
      {value: "Business to Business", label: "Business to Business"},
      {value: "Taxi & Delivery", label: "Taxi & Delivery"},
      {value: "Utilities", label: "Utilities"},
      {value: "Other", label: "Other"},
    ],
    internalNotes: {},
    salesBrands: [],
    toggleYesNo: [
      {label: "Yes", value: 1},
      {label: "No", value: 0},
    ],
    agents: appData.agents || [],
    classes: [],
    sources: [],
    users: appData.users || [],
    solutions: [],
    managers: [],
    accountTypes: [
      {value: "master", label: "Master Merchant"},
      {value: "merchant", label: "Merchant"},
      {value: "sub", label: "Sub-Account"},
    ],
    merchantTypes: [
      {value: "POS", label: "Card Present - POS"},
      {value: "E-Commerce", label: "Card not Present - E-Commerce"},
//       {value: "Hybrid", label: "Hybrid"},
    ],
    languages: [
      {value: "English", label: "English"},
    ],
    reviewDaysOpts: ["30", "60", "90", "180", "365"].map(vlMap),
    settlementDaysOpts: ["1", "2", "3", "4", "5",
                         "6", "7", "14", "21", "30"].map(vlMap),
    reserveHoldOpts: ["180", "365"].map(vlMap),
  }},
  async mounted() {
    window.appFinalapprovalPopup=this;
    this.classes = (await api.list("AccountClass")).items || [];
    this.managers = (await api.list("User", {groups: 'manager-account', deleted :['<>', 'Yes']}, ['id:ASC'])).items || [];
    this.sources = (await api.list("User", {groups: 'manager-relationship', deleted :['<>', 'Yes']}, ['id:ASC'])).items || [];
    var appsolutions = (await api.list("Solution", {solutionType: 'Merchants'}, ['id:ASC'])).items || [];
    this.solutions=appsolutions.map(function(a) {
      return {value: a.id, label: a.nameSolution};
    });
  },
  computed: {
    sourcesToShow() {
      return this.sources.map(function(a) {
        return {
          value: a.id,
          label: a.fullName || a.username,
        };
      });
    },
    usersToShow() {
      return this.users.map(function(a) {
        return {
          value: a.id,
          label: a.username,
        };
      });
    },
    solutionsToShow() {
      return this.solutions;
    },
    classesToShow() {
      return this.classes.map(function(a) {
        return {
          value: a.id,
          label: a.name+" "+a.description,
        };
      });
    },
    managersToShow() {
      var items=this.managers.map(function(a) {
        return {
          value: a.id,
          label: a.username,
        };
      });
      items.unshift({
        value: null,
        label: "(no manager)",
      });
      return items;
    },
    agentsToShow() {
      return this.agents.map(function(a) {
        var name=a.fullName || "";
        if (!name && a.contacts && a.contacts[0]) {
          name=a.contacts[0].firstName+" "+a.contacts[0].lastName;
        }
        var cn=a.companyName || "";
        if (!cn) {
          
        }
        return {
          value: a.id,
          label: (a.agentId || "")+" - "+name+" - "+cn,
        };
      });
    },
  },
  watch: {
  },
  methods: {
    dismiss() {
      this.shown=false;
      this.form={};
    },
    show(item) {
      this.finalCheck=item;
      this.shown=true;
    },
    nextStep() {
      if (!this.validate())
        return stopLoading("Please fill all required fields", "error");
      this.step++;
    },
    async save() {
      if (!this.validate())
        return stopLoading("Please fill all required fields", "error");
      await this.finalCheck.doFinal2(this.form);
      this.dismiss();
    },
  },
  mixins: [formValidation.mixin, vueMixins.dateFormatter]
}
</script>
