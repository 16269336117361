<template>
    <table class="breakdown-table">
      <thead>
        <tr>
          <th>Status</th>
          <th>Amount</th>
        </tr>
        <tr>
          <th colspan="2" class="spacer"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="status in perStatus">
          <td>{{ status.description }}</td>
          <td>{{ status.items.length }}</td>
        </tr>
      </tbody>
    </table>
</template>

<script>
export default {
  props: ["statuses"],
  data() { return {
    items: [],
    search: {},
  }; },
  computed: {
    perStatus() {
      return this.statuses.map((a)=>({
        description: a.description,
        items: this.items.filter((i)=>{
          if ((!this.search || !this.search.dateStart) && i.status=="A04" && a.status=="A04") {
            var m=moment(i.submitDate);
            var n=moment();
            return n.diff(m, "days")<=14;
          }
          if (a.status=="FOLLOWUP")
            return !!i.followup;
          if (a.status=="PENDINGAPPROVAL")
            return !!i.pendingApproval;
          if (a.status=="REVIEW")
            return moment(i.reviewDate).add(serverDiff, "seconds").diff(moment())<0;
          return i.status==a.status;
        }),
      }));
    },
  },
  methods: {
  },
  mounted() {
    window.breakdownTable=this;
  },
}
</script>
