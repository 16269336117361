<template>
<div>
  <div class="content-table-container usermgmt">
    <table class="content-table usermgmt">
      <thead>
        <tr>
          <th style="width: 30%">Username</th>
          <th style="width: 10%">ID</th>
          <th style="width: 30%">Role</th>
          <th style="width: 30%">Actions</th>
        </tr>
        <tr>
          <th class="spacer" colspan="3"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items">
          <td class="text-left">
            <a href="" v-on:click.prevent="showItem(index)">{{ item.username }}</a>
          </td>
          <td class="">
            {{ item.agentId || 0 }}
          </td>
          <td class="">
            {{ groups[item.groups] }}
          </td>
          <td class="text-right">
<!--             <a class="edit" href="" v-on:click.prevent="viewLog(index)">Activity</a> -->
            <a class="block" href="" v-on:click.prevent="blockItem(index)">{{ item.active=="Blocked"?"Unblock":"Block" }}</a>
            <a v-if="item.groups && item.groups.indexOf('admin-')==0" class="edit" href="" v-on:click.prevent="editItem(index)">Edit</a>
            <a v-if="item.groups && item.groups.indexOf('admin-')==0" class="remove" href="" v-on:click.prevent="deleteItem(index)">Delete</a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
</template>

<script>
export default {
  data() { return {
    items: [],
    groups: window.userGroups,
  }},
  methods: {
    reload: async function() {
      var that=this;
      var data=await api.list("User", {parentId: "null", deleted: "No"}, ["id:ASC"]);
      that.items=JSON.parse(JSON.stringify(data.items));
      that.items=that.items.sort(function(a, b) {
        var gindexa=window.userGroupList.findIndex(function(x) { return x.value==a.groups; });
        var gindexb=window.userGroupList.findIndex(function(x) { return x.value==b.groups; });
        if (gindexa<gindexb) return -1;
        if (gindexa>gindexb) return 1;
        return 0;
      });
      userEdit.users=JSON.parse(JSON.stringify(data.items));
    },
    deleteItem: async function(index) {
      var that=this;
      if (confirm("Are you sure?")) {
        startLoading();
        await api.update("User", that.items[index].id, {deleted: "Yes"});
        that.items.splice(index, 1);
        stopLoading("User deleted");
      }
    },
    blockItem: async function(index) {
      startLoading();
      var item=this.items[index];
      await api.update("User", item.id, {active: item.active=="Blocked"?"Yes":"Blocked"});
      stopLoading("User status updated");
      item.active=item.active=="Blocked"?"Yes":"Blocked";
    },
    editItem(index) {
      userEdit.showPopup(this.items[index]);
    },
    showItem(index) {
      userEdit.showPopup(this.items[index], true);
    },
    viewLog(index) {
      userActivity.showPopup(this.items[index]);
    },
  },
  mounted() {
    window.usersList=this;
    this.reload();
  },
}
</script>

