<template>
<div class="popup usermgmt" :class="{shown: visible}">
  <div class="pop-med">
    <div class="pop-inner">
      <a href="" class="close" v-on:click.prevent="visible=false"></a>
      <h2>{{ user.id?'Editing user: '+user.username+" "+(user.agentId?'('+user.agentId+')':''):'New user' }}</h2>
      <form class="search-form">
        <validator class="field shortlab" :value="user.services" reqs="">
          <label>Services</label>
          <dyn-multiselect :readonly="readonly" v-model="user.services" :options="services" />
        </validator>
        <validator class="field shortlab" :value="user.groups" reqs="required">
          <label>Role</label>
          <dyn-select :disabled="readonly" v-model="user.groups" :options="groups" />
        </validator>
        <div class="f2split">
          <div v-if="!user.id && user.groups=='merchant'">
            <validator class="field" :value="user.username" reqs="required">
              <label>Username</label>
              <select :disabled="readonly" tabindex="1" v-model="user.applicationId">
                <option v-for="merchant in merchantList" :value="merchant.id">{{ merchant.username }}</option>
              </select>
            </validator>
          </div>
          <div v-else>
            <validator class="field" :value="user.username" reqs="required">
              <label>Username</label>
              <input tabindex="1" type="text" v-model="user.username" :readonly="(!user.id && user.groups!='admin-super') || readonly" />
            </validator>
          </div>
          <div v-if="false">
            <validator class="field" :value="user.password" reqs="password">
              <label>Password</label>
              <input :readonly="readonly" tabindex="2" type="password" v-model="user.password" />
            </validator>
          </div>
          <div v-else-if="isAgent">
            <validator class="field" :value="user.manager" reqs="">
              <label>Relationship Manager</label>
              <dyn-select :disabled="readonly" tabindex="2" v-model="user.manager" :options="managers" />
            </validator>
          </div>
          <div v-else-if="user.groups=='merchant'">
            <validator class="field" :value="user.manager" reqs="">
              <label>Account Manager</label>
              <dyn-select :disabled="readonly" tabindex="2" v-model="user.manager" :options="amanagers" />
            </validator>
          </div>
        </div>
        <div class="f2split">
          <div>
            <validator class="field" :value="user.fullName" reqs="required">
              <label>First Name</label>
              <input :readonly="readonly" tabindex="3" type="text" v-model="user.fullName" />
            </validator>
          </div>
          <div>
            <validator class="field" :value="user.lastName" reqs="required">
              <label>Last Name</label>
              <input :readonly="readonly" tabindex="3" type="text" v-model="user.lastName" />
            </validator>
          </div>
        </div>
        <div class="f2split" v-if="!isAgent">
          <div>
            <validator class="field" :value="user.title" reqs="">
              <label>Title</label>
              <input :readonly="readonly" tabindex="3" type="text" v-model="user.title" />
            </validator>
            <validator v-if="hasPhone" class="field" :value="user.phone" reqs="required">
              <label>Phone</label>
              <input :readonly="readonly" tabindex="5" type="text" v-model="user.phone" />
            </validator>
          </div>
          <div>
            <validator class="field" :value="user.email" reqs="required email">
              <label>E-mail</label>
              <input :readonly="readonly" tabindex="4" type="text" v-model="user.email" />
            </validator>
            <validator v-if="hasPhone" class="field" :value="user.phoneExt" reqs="">
              <label>Phone Ext</label>
              <input :readonly="readonly" tabindex="5" type="text" v-model="user.phoneExt" />
            </validator>
          </div>
        </div>
        <div class="f2split" v-else-if="user.groups=='merchant'">
          <div>
            <validator class="field" :value="user.supportPin" reqs="">
              <label>Support Pin</label>
              <input readonly tabindex="4" type="text" v-model="user.supportPin" />
            </validator>
          </div>
        </div>
        <div class="f2split" v-else>
          <div>
            <validator class="field" :value="user.companyName" reqs="required">
              <label>Company Name</label>
              <input :readonly="readonly" tabindex="5" type="text" v-model="user.companyName" />
            </validator>
            <validator class="field" :value="user.companyCity" reqs="required">
              <label>Company City</label>
              <input :readonly="readonly" tabindex="7" type="text" v-model="user.companyCity" />
            </validator>
            <validator class="field" :value="user.companyState" reqs="required">
              <label>Company State</label>
              <input :readonly="readonly" tabindex="9" type="text" v-model="user.companyState" />
            </validator>
            <validator class="field" :value="user.companyEmail" reqs="required email">
              <label>Company Email</label>
              <input :readonly="readonly" tabindex="11" type="text" v-model="user.companyEmail" />
            </validator>
          </div>
          <div>
            <validator class="field" :value="user.companyAddress" reqs="required">
              <label>Company Address</label>
              <input :readonly="readonly" tabindex="6" type="text" v-model="user.companyAddress" />
            </validator>
            <validator class="field" :value="user.companyZip" reqs="required">
              <label>Company Zip</label>
              <input :readonly="readonly" tabindex="8" type="text" v-model="user.companyZip" />
            </validator>
            <validator class="field" :value="user.companyCountry" reqs="required">
              <label>Company Country</label>
              <input :readonly="readonly" tabindex="10" type="text" v-model="user.companyCountry" />
            </validator>
            <validator class="field" :value="user.companyPhone" reqs="required">
              <label>Company Phone</label>
              <input :readonly="readonly" tabindex="12" type="text" v-model="user.companyPhone" />
            </validator>
          </div>
        </div>
        <ul class="user-contacts" v-if="isAgent">
          <li v-for="(contact, ci) in user.contacts">
            <a v-if="user.contacts.length>1" href="" class="remcon" v-on:click.prevent="removeContact(ci)">Remove Contact</a>
            <div class="f2split">
              <div>
                <validator class="field" :value="contact.firstName" reqs="required">
                  <label>Contact First Name</label>
                  <input :readonly="readonly" :tabindex="13+ci*4" type="text" v-model="contact.firstName" />
                </validator>
                <validator class="field" :value="contact.phone" reqs="required">
                  <label>Contact Phone</label>
                  <input :readonly="readonly" :tabindex="15+ci*4" type="text" v-model="contact.phone" />
                </validator>
              </div>
              <div>
                <validator class="field" :value="contact.lastName" reqs="required">
                  <label>Contact Last Name</label>
                  <input :readonly="readonly" :tabindex="14+ci*4" type="text" v-model="contact.lastName" />
                </validator>
                <validator class="field" :value="contact.email" reqs="required email">
                  <label>Contact Email</label>
                  <input :readonly="readonly" :tabindex="16+ci*4" type="text" v-model="contact.email" />
                </validator>
              </div>
            </div>
          </li>
          <li v-if="!readonly">
            <a href="" class="newcon" v-on:click.prevent="addContact">Add additional contact</a>
            <a href="" class="newcon" v-on:click.prevent="resetPassword">Reset Password</a>
            <a href="" class="newcon" v-on:click.prevent="welcomeEmail">Welcome Email</a>
          </li>
        </ul>
        <a v-if="!isAgent && !readonly" href="" class="newcon" v-on:click.prevent="resetPassword">Reset Password</a>
        <div class="buttons" v-if="!readonly">
          <button class="btn btn-large btn-primary" v-on:click.prevent="send">{{ user.id?'Update':'Create' }}</button>
        </div>
      </form>
    </div>
  </div>
</div>
</template>

<script>
export default {
  data() { return {
    readonly: false,
    groups: window.userGroupList.filter(a=>a.value.indexOf('admin-')==0),
    services: [
      {label: "Globobil", value: "globobil"},
      {label: "CardBiller", value: "cardbiller"},
    ],
    visible: false,
    user: {},
    users: [],
    merchants: [],
  }},
  watch: {
    "user.applicationId"() {
      if (this.user.id)
        return;
      if (this.user.applicationId) {
        var that=this;
        var merchant=this.merchantList.find(function(a) { return a.id==that.user.applicationId; });
        if (merchant) {
          this.user.username=merchant.username;
          this.user.email=merchant.companyEmail;
          this.user.fullName=merchant.companyName;
        }
      }
    },
    "user.companyName"() {
      if (this.user.id)
        return;
      this.user.username=(this.user.companyName||"").toLowerCase().replace(/[^a-zA-Z0-9]+/g, "-").replace(/^-|-$/, "");
    },
    "user.fullName"() {
      if (this.user.id || this.user.groups=='admin-super')
        return;
      this.user.username=(this.user.fullName||"").toLowerCase().replace(/[^a-zA-Z0-9]+/g, "-").replace(/^-|-$/, "");
    },
    "user.groups"() {
      if (!this.readonly && this.user.groups=="merchant") {
        if (!this.user.supportPin)
          this.generatePin();
      }      
    },
    "user"() {
      if (!this.readonly && this.user.groups=="merchant") {
        if (!this.user.supportPin)
          this.generatePin();
      }      
    },
  },
  computed: {
    managers() {
      var result=[];
      for(var i in this.users) {
        var user=this.users[i];
        if (user.groups!="manager-relationship")
          continue;
        result.push({value: user.id, label: user.username});
      }
      return result;
    },
    amanagers() {
      var result=[];
      for(var i in this.users) {
        var user=this.users[i];
        if (user.groups!="manager-account")
          continue;
        result.push({value: user.id, label: user.username});
      }
      return result;
    },
    isAgent() {
      return this.user.groups=="agent-isa" ||
             this.user.groups=="agent-iso";
    },
    hasPhone() {
      return this.user.groups=="agent-inside" || 
             this.user.groups=="support-tech" || 
             this.user.groups=="analyst-admin" || 
             this.user.groups=="analyst-risk" || 
             this.user.groups=="manager-account" ||
             this.user.groups=="manager-compliance" ||
             this.user.groups=="analyst-compliance" ||
             this.user.groups=="manager-relationship";
    },
    merchantList() {
      return this.merchants.filter(function(a) {
        return !window.usersList.items.find(function(b) {
          return b.applicationId==a.id;
        });
      }).map(function(a) {
        return {...a, username: a.companyName.toLowerCase().replace(/[^a-z0-9]+/g, "-")};
      });
    },
  },
  methods: {
    generatePin: async function() {
      var result=await api.action("User", this.user.id || "new", "supportPin", {});
      this.$set(this.user, "supportPin", result.action.pin);
    },
    updateMerchants: async function() {
      var merchants=await api.list("Application", {"status": ["='A03' OR base.status='A04' OR base.status=", "A02"]});
      this.merchants=merchants.items;
    },
    showPopup(user, readonly) {
      this.readonly=readonly || false;
      this.validateReset();
      this.user=user || {
        active: "Yes",
        agentId: "",
        username: "",
        fullName: "",
        lastName: "",
        email: "",
        password: "",
        groups: "admin",
        companyName: null,
        companyAddress: null,
        companyCity: null,
        companyZip: null,
        companyState: null,
        companyCountry: null,
        companyPhone: null,
        companyEmail: null,
        contacts: [],
        applicationId: null,
        passChange: true,
      };
      if (!this.user.contacts.length)
        this.addContact();
      this.visible=true;
    },
    send: async function() {
      var isNew=!this.user.id;
      //if (!this.user.agentId)
      //  this.user.agentId="APP_ID_GEN_AGENT";
      if (!this.validate())
        appNotifications.show("Please fill in all required fields", "error");
      else {
        this.visible=false;
        startLoading();
        if (isNew)
          await api.create("User", this.user);
        else
          await api.update("User", this.user.id, this.user);
        stopLoading(isNew?"Profile created":"Profile updated", "success");
        usersList.reload();
      }
    },
    addContact() {
      this.user.contacts.push({
        firstName: "",
        lastName: "",
        phone: "",
        email: ""
      });
    },
    removeContact(index) {
      this.user.contacts.splice(index, 1);
    },
    resetPassword: async function() {
      if (!this.user.id)
        return;
      startLoading();
      await api.update("User", this.user.id, {
        password: this.user.username+"123",
        passChange: 1,
      });
      stopLoading("Password reset", "success");
    },
    welcomeEmail: async function() {
      if (!this.user.id)
        return;
      startLoading();
      await api.action("User", this.user.id, "welcomeEmail");
      stopLoading("Welcome email sent", "success");
    },
  },
  mounted: async function() {
    window.userEdit=this;
    this.updateMerchants();
  },
  mixins: [formValidation.mixin]
}
</script>
