<template>
  <div class="static-page privacy-popup">
    <h2><strong>{{ legal.privacyPolicyHeader }}</strong></h2>
    <p>
      Last updated: {{ legal.privacyPolicyDate }}
    </p>
    <div v-html="legal.privacyPolicyText"></div>
  </div>
</template>

<script>

export default {
  data: ()=>({
    legal: appData.legal || {},
  }),
}
</script>
<style lang="scss" scoped>
  .privacy-popup {
    line-height: 22px;
    color: #21364c;
  }

  .privacy-popup h2{
    border-bottom: none;
  }

  .privacy-popup p {
    margin-bottom: 18px;
    line-height: 22px;
    color: #21364c;
  }

  .privacy-popup a {
    color: #00f;
    text-decoration: underline;
  }
</style>
