<template>
<div>
  <ul class="acc-results">
    <li class="">
      <div class="acc-list" style="display: block">
        <table>
          <thead>
            <tr>
              <th style="text-align: left">Version</th>
              <th style="text-align: left">Username</th>
              <th style="text-align: left">Key</th>
              <th style="text-align: left">Status</th>
              <th style="text-align: left">Notes</th>
              <th>Options</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in connexes">
              <td style="text-align: left">
                <span v-if="!item.editing">{{ item.version }}</span>
                <input v-else v-model="item.version" />
              </td>
              <td style="text-align: left">
                <span v-if="!item.editing">{{ item.username }}</span>
                <input v-else v-model="item.username" />
              </td>
              <td style="text-align: left">
                <span v-if="!item.editing">{{ item.connexKey }}</span>
                <input v-else v-model="item.connexKey" />
              </td>
              <td style="text-align: left">
                <span v-if="!item.editing">{{ item.status }}</span>
                <dyn-select v-else v-model="item.status" :options="statuses" />
              </td>
              <td style="text-align: left">
                <span v-if="!item.editing">{{ item.notes }}</span>
                <input v-else v-model="item.notes" />
              </td>
              <td>
                <a href="" v-if="!item.editing" v-on:click.prevent="item.editing=true">Edit</a>
                <a href="" v-else v-on:click.prevent="saveItem(item)">Save</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </li>
  </ul>
</div>
</template>

<script>
export default {
  data() { return {
    statuses: [
      {value: "Review", label: "Review"},
      {value: "Testing", label: "Testing"},
      {value: "Active", label: "Active"},
      {value: "Disabled", label: "Disabled"},
    ],

    connexes: [],
  }},
  mounted() {
    this.loadData();
  },
  methods: {
    loadData: async function() {
      var connexPromise=api.list("ConnexManager", {});
      var connexes=(await connexPromise).items;
      for(var connex of connexes) {
          this.connexes.push({
            id: connex.id,
            version: connex.version,
            username: connex.username,
            connexKey: connex.connexKey,
            status: connex.status,
            notes: connex.notes,
            updatedAt: 'CURRENT_TIMESTAMP',
            editing: false,
          });
      }
    },

    newOther() {
      this.others.push({
        id: null,
        apiId: "",
        name: "",
        dbaName: "",
        username: "",
        password: "",
        ipaddress: "",
        lastConnection: null,
        status: "Disabled",
        editing: true,
      });
    },
    saveItem: async function(item) {
      if (item.id)
        await api.update("ConnexManager", item.id, item);
      else
        item.id=(await api.create("ConnexManager", item)).id;
      item.editing=false;
    },
  },
  mixins: [vueMixins.dateFormatter],
}
</script>
