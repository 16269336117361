<template>
<form class="form-step">
  <section>
    <h2>Business Profile</h2>
    <div class="pgf-main-cb type2">
      <p class="intro">Does this business have a website that accepts payments?</p>
      <validator :value="app.hasWebsite" reqs="required">
      <ul class="toggle-main">
        <li class="disabled">
          <label :class="{checked: app.hasWebsite==='1'}">
            <input type="radio" name="hasParent" value="1" v-model="app.hasWebsite" disabled>
            <i></i>
            <strong>Yes</strong>
            <!--<span>(provide details of applying company and parent company below)</span>-->
          </label>
        </li>
        <li class="disabled">
          <label :class="{checked: app.hasWebsite==='0'}">
            <input type="radio" name="hasParent" value="0" v-model="app.hasWebsite" disabled>
            <i></i>
            <strong>No</strong>
            <!--<span>(only provide details of applying company company below)</span>-->
          </label>
        </li>
      </ul>
      </validator>
    </div>
    <ul class="column-form type2 grayed" v-if="app.hasWebsite*1">
      <li class="large-title">
        <label>Is website live?<span></span></label>
        <div class="field ve">
          <validator :value="app.websiteLive" reqs="required">
            <formel-toggle-slot v-model="app.websiteLive">
              <validator :value="app.websiteDate" :reqs="app.websiteLive==='0'?'required':''">
                <datepicker v-model="app.websiteDate" :disabled="app.websiteLive!=='0'" placeholder="If not, when?" />
              </validator>
            </formel-toggle-slot>
          </validator>
        </div>
      </li>
      <li>
        <label>Website(s)</label>
        <div class="field">
          <validator :value="app.website" reqs="required char1000">
            <input type="text" v-model="app.website" />
          </validator>
        </div>
        <span class="pfx-info">(please separate with commas)</span>
      </li>
      <li>
        <label>Login details:</label>
        <div class="field">
          <validator :value="app.websiteUsername" reqs="char1000">
            <input type="text" v-model="app.websiteUsername" />
          </validator>
        </div>
        <span class="pfx-info">Username</span>
      </li>
      <li>
        <label>&nbsp;&nbsp;</label>
        <div class="field">
          <validator :value="app.websitePassword" reqs="char1000">
            <input type="text" v-model="app.websitePassword" />
          </validator>
        </div>
        <span class="pfx-info">Password</span>
      </li>
    </ul>
    <ul class="column-form type2">
      <li class="fwidth">
        <label v-if="app.industry!='Facilitator'">Detailed description of products / services sold <span>(explain business model)</span></label>
        <label v-else>Detailed description of products / services<br />offered to Sub-Merchants <span>(explain business model)</span></label>
        <div class="field">
          <validator :value="app.businessDescription" reqs="required char1000">
            <textarea type="text" v-model="app.businessDescription"></textarea>
          </validator>
        </div>
      </li>
      <li>
        <label>Length of time in business <span></span></label>
        <div class="field">
          <validator :value="app.businessLength" reqs="required">
            <field-businesslength v-model="app.businessLength" />
          </validator>
        </div>
      </li>
      <!--<li>
        <label>Website(s) <span>(please separate by comma)</span></label>
        <div class="field">
          <validator :value="app.website" :reqs="app.hasWebsite?'':''">
            <input type="text" v-model="app.website" />
          </validator>
        </div>
      </li>
      <li>
        <label>Login details <span></span></label>
        <div class="field special">
          <validator :value="app.websiteUsername" :reqs="app.hasWebsite?'':''">
            <input type="text" v-model="app.websiteUsername" placeholder="Username" />
          </validator>
          <validator :value="app.websitePassword" :reqs="app.hasWebsite?'':''">
            <input type="text" v-model="app.websitePassword" placeholder="Password" />
          </validator>
        </div>
      </li>
      <li v-if="false">
        <label>Beta website(s) <span></span></label>
        <div class="field">
          <validator :value="app.websiteBeta" :reqs="app.hasWebsite?'':''">
            <input type="text" v-model="app.websiteBeta" />
          </validator>
        </div>
      </li>-->
      <li>
        <label v-if="app.industry!='Facilitator'">Descriptor</label>
        <label v-else>Desired Descriptor Prefix</label>
        <div class="field">
          <validator :value="app.descriptor1" reqs="required descriptor">
            <input type="text" v-model="app.descriptor1" />
          </validator>
        </div>
        <span v-if="app.industry!='Facilitator'" class="pfx-info">(to appear on customers' credit card statement; max 22 characters, for example: website or company name)</span>
        <span v-else class="pfx-info">(to appear on customers' statement before sub-merchant descriptor. max 3 characters)</span>
      </li>
      <li v-if="false">
        <label>DBA/City Field <span>(second line of descriptor, max 12 characters, for example: city of customer service phone number)</span></label>
        <div class="field">
          <validator :value="app.descriptor2" reqs="required descriptor">
            <input type="text" v-model="app.descriptor2" />
          </validator>
        </div>
      </li>
      <li v-if="false">
        <label>Will you use "Dynamic Descriptors"? <span></span></label>
        <div class="field ve">
          <validator :value="app.dynamicDescriptors" reqs="required">
            <formel-toggle v-model="app.dynamicDescriptors" />
          </validator>
        </div>
      </li>
      <li>
        <label>Have you ever filed for bankruptcy? <span></span></label>
        <div class="field ve">
          <validator :value="app.bankruptcy" reqs="required">
            <formel-toggle-slot v-model="app.bankruptcy">
              <validator :value="app.bankruptcyDate" :reqs="app.bankruptcy==1?'required':''">
                <!--<input type="text" v-model="app.bankruptcyDate" :disabled="app.bankruptcy!=1" />
                <span>YYY MM DD</span>-->
              </validator>
            </formel-toggle-slot>
          </validator>
        </div>
      </li>
      
      <li class="fwidth">
        <label>{{ app.industry!='Facilitator'?'Have you ever flagged or been in violation of any card scheme program?':'Have you ever experienced a breach, been flagged or in any violation of any card scheme program?' }}
          <validator :value="app.violation" reqs="required">
            <formel-toggle v-model="app.violation" />
          </validator>
          <span class="pfx-info">(e.g. Excessive Chargebacks, BRAM violation, Account Data Compromise)</span>
        </label>
        <div class="field ve">
          
          <validator :value="app.violationDetails" :reqs="app.violation==1?'required char250':''">
            <textarea v-model="app.violationDetails" placeholder="Description goes here" ></textarea>
          </validator>
        </div>
      </li>
      <li style="width: calc(50% - 15px)">
        <label>Current/previous Payment Provider<span></span></label>
        <div class="field ve">
          <validator :value="app.lastProvider" reqs="">
            <input type="text" v-model="app.lastProvider" />
          </validator>
        </div>
      </li>
      <li style="width: calc(50% - 15px)">
        <div class="field ve">
          <validator :value="app.lastReason" reqs="">
            <textarea type="text" v-model="app.lastReason" placeholder="Reason for leaving current Provider"></textarea>
          </validator>
        </div>
      </li>
      <li>
        <label>Do you currently or have you previously accepted credit cards? <span></span></label>
        <div class="field ve">
          <validator :value="app.acceptedCards" reqs="required">
            <formel-toggle-slot v-model="app.acceptedCards">
              <!--<validator :value="app.acceptedCardsMonths" :reqs="app.acceptedCards==1?'required':''">
                <input type="text" v-model="app.acceptedCardsMonths" :disabled="app.acceptedCards!=1" />
                <span>(months)</span>
              </validator>-->
            </formel-toggle-slot>
          </validator>
        </div>
      </li>
      <li>
        <label class="dh">Estimated monthly sales volume</label>
        <div class="field money">
          <validator :value="app.volume+' '+app.volumeCurrency" reqs="required money numval10">
            <money :amount.sync="app.volume" :currency.sync="app.volumeCurrency" />
          </validator>
        </div>
      </li>
      <li>
        <label class="dh">Average transaction value</label>
        <div class="field">
          <validator :value="app.avgTransaction+' '+app.avgTransactionCurrency" reqs="required money numval10">
            <money :amount.sync="app.avgTransaction" :currency.sync="app.avgTransactionCurrency" />
          </validator>
        </div>
      </li>
      <li>
        <label class="dh">Highest transaction value</label>
        <div class="field">
          <validator :value="app.maxTransaction+' '+app.maxTransactionCurrency" reqs="required money numval10">
            <money :amount.sync="app.maxTransaction" :currency.sync="app.maxTransactionCurrency" />
          </validator>
        </div>
      </li>
      <!--<li class="fwidth">
        <label>Transaction currencies</label>
        <div class="field">
          <validator :value="app.transactionCurrencies" reqs="required letters">
            <field-currencies v-model="app.transactionCurrencies" />
          </validator>
        </div>
      </li>-->
    </ul>
  </section>

  <section>
    <div class="ext-h4" v-if="app.acceptedCards=='1'">
      <div class="toolbar currency-select">
        <validator :value="app.historyCurrency" reqs="required">
          <dyn-select v-model="app.historyCurrency" :options="currencies" />
          <label>Applicable currency for the
volumes specified below</label>
        </validator>
      </div>
      <h4 v-if="app.acceptedCards=='1'">Please provide 6 months of most recent processing history below</h4>
      <div class="subtitle" v-if="app.acceptedCards=='1'">(Clearly showing transactions, chargebacks and refunds per month)</div>
    </div>
    <ul class="column-form type3">
      <li class="head" v-if="app.acceptedCards=='1'">
        <label>Credit Card Processing History</label>
        <div>
          <div v-for="ago in [1,2,3,4,5,6]">{{ ago==1?'Last Month':ago+' Months Ago' }}</div>
        </div>
      </li>
      <li v-if="app.acceptedCards=='1'">
        <label>Number of transactions</label>
        <div class="field">
          <validator :value="ago.tsCount" reqs="number" v-for="(ago, i) in app.history" :key="'ccph1x'+i">
            <input type="text" v-model="ago.tsCount" />
          </validator>
        </div>
      </li>
      <li v-if="app.acceptedCards=='1'">
        <label>Transaction volume</label>
        <div class="field">
          <validator :value="ago.tsVolume" reqs="number" v-for="(ago, i) in app.history" :key="'ccph2x'+i">
            <input type="text" v-model="ago.tsVolume" />
          </validator>
        </div>
      </li>
      <li v-if="app.acceptedCards=='1'">
        <label>Number of chargebacks</label>
        <div class="field">
          <validator :value="ago.cbCount" reqs="number" v-for="(ago, i) in app.history" :key="'ccph3x'+i">
            <input type="text" v-model="ago.cbCount" />
          </validator>
        </div>
      </li>
      <li v-if="app.acceptedCards=='1'">
        <label>Chargeback volume</label>
        <div class="field">
          <validator :value="ago.cbVolume" reqs="number" v-for="(ago, i) in app.history" :key="'ccph4x'+i">
            <input type="text" v-model="ago.cbVolume" />
          </validator>
        </div>
      </li>
      <li v-if="app.acceptedCards=='1'">
        <label>Number of refunds</label>
        <div class="field">
          <validator :value="ago.refundCount" reqs="number" v-for="(ago, i) in app.history" :key="'ccph5x'+i">
            <input type="text" v-model="ago.refundCount" />
          </validator>
        </div>
      </li>
      <li v-if="app.acceptedCards=='1'">
        <label>Refund volume</label>
        <div class="field">
          <validator :value="ago.refundVolume" reqs="number" v-for="(ago, i) in app.history" :key="'ccph6x'+i">
            <input type="text" v-model="ago.refundVolume" />
          </validator>
        </div>
      </li>
    </ul>
    <ul class="column-form type2">
      <!--<li>
        <label>Estimated monthly sales volume</label>
        <div class="field money">
          <validator :value="app.volume+' '+app.volumeCurrency" reqs="required money">
            <money :amount.sync="app.volume" :currency.sync="app.volumeCurrency" />
          </validator>
        </div>
      </li>
      <li>
        <label>Average transaction value</label>
        <div class="field">
          <validator :value="app.avgTransaction+' '+app.avgTransactionCurrency" reqs="required money">
            <money :amount.sync="app.avgTransaction" :currency.sync="app.avgTransactionCurrency" />
          </validator>
        </div>
      </li>
      <li>
        <label>Highest transaction value</label>
        <div class="field">
          <validator :value="app.maxTransaction+' '+app.maxTransactionCurrency" reqs="required money">
            <money :amount.sync="app.maxTransaction" :currency.sync="app.maxTransactionCurrency" />
          </validator>
        </div>
      </li>
      <li>
        <label>Transaction currencies</label>
        <div class="field">
          <validator :value="app.transactionCurrencies" reqs="required letters">
            <field-currencies v-model="app.transactionCurrencies" />
          </validator>
        </div>
      </li>-->
      <li class="qw">
        <label v-if="app.industry!='Facilitator'">Transaction currencies applying for</label>
        <label v-else>Sub-Merchants<br />Transaction currencies</label>
        <div class="field">
          <validator :value="app.transactionCurrencies" reqs="required letters">
            <field-currencies v-model="app.transactionCurrencies" />
          </validator>
        </div>
      </li>
      <li class="halfw split-tri">
        <label v-if="app.industry!='Facilitator'">Payment types applying for:</label>
        <label v-else>Payment types for Sub-Merchants:</label>
        <div class="field">
          <validator :value="app.cardTypes&&app.cardTypes.length?app.cardTypes:app.cardTypesOther" reqs="required">
            <multiselect v-if="app.mainType=='cp'" v-model="app.cardTypes" :other.sync="app.cardTypesOther" hasOther="1" :options="['Visa', 'MasterCard', 'Discover', 'AMEX', 'China UnionPay', 'Apple Pay', 'Google Pay', 'Interac Online!disabled', 'Interac Debit', 'ACH/EFT']" />
            <multiselect v-if="app.mainType=='cnp'" v-model="app.cardTypes" :other.sync="app.cardTypesOther" hasOther="1" :options="['Visa', 'MasterCard', 'Discover', 'AMEX', 'China UnionPay', 'Apple Pay', 'Google Pay', 'Interac Online', 'Interac Debit!disabled', 'ACH/EFT']" />
          </validator>
        </div>
      </li>
      <li class="qw half-split">
        <label>When does payment take place?</label>
        <div class="field">
          <validator :value="app.paymentMoment&&app.paymentMoment.length?app.paymentMoment:app.paymentMomentOther" reqs="required">
            <multiselect v-model="app.paymentMoment" :other.sync="app.paymentMomentOther" hasOther="1" :options="['Upon purchase', 'With download', 'On delivery']" />
          </validator>
        </div>
      </li>
      <li class="fwidth fwx">
        <label><em>{{ (app.originDomestic*1 || 0) + (app.originEurope*1 || 0) + (app.originUSA*1 || 0) + (app.originAsia*1 || 0) + (app.originOther*1 || 0) }}%</em> Origin of transactions <span>(total should equal 100%)</span></label>
        <div class="field">
          <validator :value="[app.originDomestic, app.originEurope, app.originUSA, app.originAsia, app.originOther]" reqs="sum100" class="multipercent">
            <div>
              <label>Domestic</label>
              <input type="text" v-model="app.originDomestic" /><span>%</span>
            </div>
            <div :class="{disabled: app.mainType=='cp'}">
              <label>USA</label>
              <input type="text" v-model="app.originUSA" :disabled="app.mainType=='cp'" /><span>%</span>
<!--               <span class="pfx-info"></span> -->
            </div>
            <div :class="{disabled: app.mainType=='cp'}">
              <label>Europe</label>
              <input type="text" v-model="app.originEurope" :disabled="app.mainType=='cp'" /><span>%</span>
<!--               <span class="pfx-info"></span> -->
            </div>
            <div :class="{disabled: app.mainType=='cp'}">
              <label>Asia</label>
              <input type="text" v-model="app.originAsia" :disabled="app.mainType=='cp'" /><span>%</span>
<!--               <span class="pfx-info"></span> -->
            </div>
            <div :class="{disabled: app.mainType=='cp'}">
              <label>Rest</label>
              <input type="text" v-model="app.originOther" :disabled="app.mainType=='cp'" /><span>%</span>
              <span class="pfx-info">(Rest of the world)</span>
            </div>
          </validator>
        </div>
      </li>
      <li class="fwidth fwx">
        <label><em>{{ (app.methodECommerce*1 || 0) + (app.methodPOS*1 || 0) + (app.methodMPOS*1 || 0) + (app.methodMCommerce*1 || 0) + (app.methodMOTO*1 || 0) + (app.methodApp*1 || 0) }}%</em> Method of acceptance <span>(total should equal 100%)</span></label>
        <div class="field">
          <validator :value="[app.methodECommerce, app.methodPOS, app.methodMPOS, app.methodMCommerce, app.methodMOTO, app.methodApp]" reqs="sum100" class="multipercent">
            <div :class="{disabled: app.mainType=='cp'}">
              <label>E-Commerce</label>
              <input type="text" v-model="app.methodECommerce" :disabled="app.mainType=='cp'" /><span>%</span>
            </div>
            <div :class="{disabled: app.mainType=='cnp'}">
              <label>Card-Present</label>
              <input type="text" v-model="app.methodPOS" :disabled="app.mainType=='cnp'" /><span>%</span>
              <span class="pfx-info">(Point of sale)</span>
            </div>
            <div :class="{disabled: app.mainType=='cnp'}">
              <label>Mobile POS</label>
              <input type="text" v-model="app.methodMPOS" :disabled="app.mainType=='cnp'" /><span>%</span>
              <span class="pfx-info">(Mobile POS)</span>
            </div>
            <div v-if="false">
              <label>M-Commerce</label>
              <input type="text" v-model="app.methodMCommerce" /><span>%</span>
              <span class="pfx-info">(Mobile payments)</span>
            </div>
            <div :class="{disabled: app.mainType=='cp'}">
              <label>MOTO</label>
              <input type="text" v-model="app.methodMOTO" :disabled="app.mainType=='cp'" /><span>%</span>
              <span class="pfx-info">(Mail-order / Telephone-order)</span>
            </div>
            <div :class="{disabled: app.mainType=='cp'}">
              <label>In-App</label>
              <input type="text" v-model="app.methodApp" :disabled="app.mainType=='cp'" /><span>%</span>
              <span class="pfx-info">(In-App Commerce)</span>
            </div>
          </validator>
        </div>
      </li>
      
      <li>
        <label>Payment frequency</label>
        <div class="field multirow">
          <validator :value="app.frequency" reqs="required">
            <multiselect v-model="app.frequency" :hasOther="false" :options="['One-time payment', 'Recurring payment <span class=\'pfx-info\'>(subscription)</span>']" />
          </validator>
        </div>
      </li>
      <li class="tqw singlerow">
        <label> Delivery time for goods/services <span>(total should equal 100%)</span><em>{{ (app.deliveryTime1*1 || 0) + (app.deliveryTime2*1 || 0) + (app.deliveryTime3*1 || 0) + (app.deliveryTime4*1 || 0) }}%</em></label>
        <div class="field">
          <validator :value="[app.deliveryTime1, app.deliveryTime2, app.deliveryTime3, app.deliveryTime4]" reqs="sum100" class="multipercent">
            <div>
              <input type="text" v-model="app.deliveryTime1" /><span>%</span>
              <label>Immediately</label>
            </div>
            <div>
              <input type="text" v-model="app.deliveryTime2" /><span>%</span>
              <label>Within 4 weeks</label>
            </div>
            <div>
              <input type="text" v-model="app.deliveryTime3" /><span>%</span>
              <label>Within 5-14 weeks</label>
            </div>
            <div>
              <input type="text" v-model="app.deliveryTime4" /><span>%</span>
              <label>More than 14 weeks</label>
            </div>
          </validator>
        </div>
      </li>
      <li class="fwidth">
        <div class="complex-label">
          <label v-if="app.industry!='Facilitator'">Do you offer / make use of affiliate programs?</label>
          <label v-else>Do your sub-merchants offer / make use of affiliate programs?</label>
          <validator :value="app.affiliate" reqs="required">
            <formel-toggle v-model="app.affiliate" :class="{disabled: app.mainType=='cp'}" :style="app.mainType=='cp'?'pointer-events: none':''"  />
          </validator>
        </div>
        <div class="field ve ve2">
          <validator :value="app.affiliateDetails" :reqs="app.affiliate==1?'required':''">
            <textarea v-model="app.affiliateDetails" :disabled="app.affiliate!=1" ></textarea>
          </validator>

        </div>
      </li>
      <li :class="{split5: app.industry=='Facilitator'}">
        <label v-if="app.industry!='Facilitator'">Customer service email</label>
        <label v-else>Merchant service email</label>
        <div class="field">
          <validator :value="app.emailService" reqs="required email">
            <input type="text" v-model="app.emailService" />
          </validator>
        </div>
      </li>
      <li :class="{split5: app.industry=='Facilitator'}">
        <label v-if="app.industry!='Facilitator'">Customer service telephone</label>
        <label v-else>Merchant service telephone</label>
        <div class="field">
          <validator :value="app.phoneService" reqs="required phone">
            <input type="text" v-model="app.phoneService" />
          </validator>
        </div>
      </li>
      <li :class="{split5: app.industry=='Facilitator'}">
        <label>Chargeback notification email</label>
        <div class="field">
          <validator :value="app.emailChargeback" reqs="required email">
            <input type="text" v-model="app.emailChargeback" />
          </validator>
        </div>
      </li>
      <li v-if="app.industry!='Facilitator'">&nbsp;</li>
      <li class="split5" v-if="app.industry=='Facilitator'">
        <label>Support email</label>
        <div class="field">
          <validator :value="app.supportEmail" reqs="required email">
            <input type="text" v-model="app.supportEmail" />
          </validator>
        </div>
      </li>
      <li class="split5" v-if="app.industry=='Facilitator'">
        <label>Support phone</label>
        <div class="field">
          <validator :value="app.supportPhone" reqs="required phone">
            <input type="text" v-model="app.supportPhone" />
          </validator>
        </div>
      </li>
    </ul>
  </section>
  <section>
    <h2 class="bline">Security measures</h2>
    <ul class="column-form type2 cf-3sp">
      <li class="fwidth">
        <label>Describe ALL security measures AND fraud/chargeback handling<span class="pfx-info">(in detail)</span></label>
        <div class="field">
          <validator :value="app.securityMeasures" reqs="required char500">
            <textarea v-model="app.securityMeasures"></textarea>
          </validator>
        </div>
      </li>
      <li>
        <label>Would you like to apply for 3D Secure?</label>
        <div class="field ve">
          <validator :value="app.use3dSecure" reqs="required">
            <formel-toggle-slot v-model="app.use3dSecure" :class="{disabled: app.mainType=='cp'}" :style="app.mainType=='cp'?'pointer-events: none':''">
              <validator :value="app.use3dSecureMPI" reqs="">
                <!--<input type="text" v-model="app.use3dSecureMPI" :disabled="app.use3dSecure!=1" placeholder="" />-->
                <!--<span>(leave empty if unknown)</span>-->
              </validator>
            </formel-toggle-slot>
          </validator>
        </div>
        <span class="pfx-info">(MasterCard SecureCode, VerifiedByVisa)</span>
      </li>
      <li>
        <label v-if="app.industry!='Facilitator'">Will you use CVC</label>
        <label v-else>Will you require CVC</label>
        <div class="field ve">
          <validator :value="app.useCVC" reqs="required" style="overflow: hidden;">
            <formel-toggle v-model="app.useCVC" :class="{disabled: app.mainType=='cp'}" :style="app.mainType=='cp'?'pointer-events: none':''" />
          </validator>
        </div>
        <span class="pfx-info">(Card Verification Code printed on credit card)</span>
      </li>
      <li>
        <label v-if="app.industry!='Facilitator'">Would you like to apply for AVS</label>
        <label v-else>Will you require AVS</label>
        <div class="field ve">
          <validator :value="app.useAVS" reqs="required" style="overflow: hidden;">
            <formel-toggle v-model="app.useAVS" :class="{disabled: app.mainType=='cp'}" :style="app.mainType=='cp'?'pointer-events: none':''" />
          </validator>
        </div>
        <span class="pfx-info">(Address Verification)</span>
      </li>
      <li>&nbsp;</li>
    </ul>
    <ul class="footnotes">
      <li><sup>1</sup> MPI = Merchant Plug In (sofware required for processing 3D Secure transactions).</li>
    </ul>
  </section>

  <div class="buttons">
    <a class="btn btn-large btn-plain btn-back" v-on:click.prevent="$emit('prev')">Back</a>
    <a class="btn btn-large btn-warn" v-on:click.prevent="$emit('save')">Save</a>
    <a class="btn btn-large btn-success btn-next" v-on:click.prevent="nextStep">Next Step</a>
  </div>
</form>
</template>


<script>
export default {
  props: ["app"],
  data() { return {
    currencies: [
      {value: "AUD", label: "AUD"},
      {value: "BRL", label: "BRL"},
      {value: "CAD", label: "CAD"},
      {value: "CHF", label: "CHF"},
      {value: "EUR", label: "EUR"},
      {value: "GBP", label: "GBP"},
      {value: "HKD", label: "HKD"},
      {value: "JPY", label: "JPY"},
      {value: "NZD", label: "NZD"},
      {value: "USD", label: "USD"},
    ],
  }},
  methods: {
    nextStep() {
      if (!this.validate())
        return stopLoading("Please fill all required fields", "error");
      this.$emit("next");      
    },
  },
  mixins: [formValidation.mixin]
}
</script>
