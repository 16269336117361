export default {
  register: {
    title: 'NEW ACCOUNT REGISTRATION',
    subtitle_q: 'Do you already have an account with us?*(Required)',
    language: 'Language',
    getting_started: 'Getting Started',
    have_acc_yes: 'YES',
    have_acc_no: 'NO',
    have_acc_label: 'Have an Existing Account?',
    yes_description: 'If you are an existing customer, adding a new account is fast and hassle free! Just click on "New Account" on the Dashboard of the Client Area. You can also add a New Account and manage your account settings in My Profile. Contact us for more information.',
    login_button: 'Login',
    acc_info: 'Account Information',
    acc_info_rq: 'Required',
    acc_type_selection: 'Account Type',
    acc_curr_selection: 'Account Currency',
    acc_type_bussines: 'Business',
    acc_type_personal: 'Personal',
    acc_type_omnibus: 'Omnibus',
    agree_terms: 'Do you agree to our:',
    terms: 'Terms & Conditions',
    privacy: 'Privacy Policy',
    submit_reg: 'Submit Registration',
    retry_reg: 'Retry Registration',
    accept_button: 'Accept',
    cancel_button: 'Cancel',
    must_complete_terms: 'You must complete to the bottom of the Terms in order to accept.',
    document_upload_error: 'Document upload error',
    document_upload_error_text_1: 'There was an issue with your registration documents. Please select a new file to upload and click below to retry your registration.',
    document_upload_error_text_2: 'Please contact support if you continue to experience issues.',
    business_acc_info_header: 'Business Information',
    buss_legal_name: 'Business Legal Name',
    buss_dba_name: 'Business DBA Name',
    buss_inc_date: 'Business Incorporation Date',
    buss_tax_name: 'Business Tax ID',
    country_formation: 'Country of Formation',
    prohibited_c: 'Prohibited Countries',
    state_formation: 'State/Province of Formation',
    business_types: 'Business Type',
    bus_type_sole: 'Sole Proprietor',
    bus_type_smllc: 'Single Member LLC',
    bus_type_llc: 'LLC',
    bus_type_gen_par: 'General Partnership',
    bus_type_corp: 'Corporation',
    bus_type_ptc: 'Publicly Traded Corporation',
    bus_type_association: 'Association',
    bus_type_n_profit: 'Non Profit',
    bus_type_gov_org: 'Government Organization',
    bus_type_rev_trust: 'Revocable Trust',
    bus_type_irr_trust: 'Irrevocable Trust',
    bus_type_estate: 'Estate',
    business_address: 'Business Address',
    business_address_2: 'Business Address 2',
    business_city: 'Business City',
    business_state: 'Business State/Province',
    business_zip: 'Business ZIP / Postal Code',
    business_phone: 'Business Phone',
    business_email: 'Business Email',
    business_website: 'Business Website',
    account_contacts: 'Contacts Information',
    add_ID: 'Add Another ID',
    remove_ID: 'Remove ID',
    add_another_contact: 'Add Another Contact',
    remove_contact: 'Remove Contact',
    contact_type: 'Contact Type',
    ben_owner: 'Beneficial Owner',
    auth_person: 'Authorized person',
    contact_gender: 'Sex',
    gender_m: 'Male',
    gender_f: 'Female',
    contact_birth: 'Date of Birth',
    contact_FN: 'First Name',
    contact_LN: 'Last Name',
    contact_country: 'Country',
    contact_city: 'City',
    contact_address: 'Address',
    contact_address_2: 'Address 2',
    contact_state: 'State/Province',
    contact_zip: 'ZIP / Postal Code',
    contact_phone: 'Phone Number',
    contact_mobile: 'Mobile Number',
    contact_email: 'Email Address',
    contact_ssn: 'SSN',
    whats_this: 'What\'s this?',
    gov_issued_id_type: 'Government Issued ID Type',
    gov_issued_id_num: 'Government Issued ID Number',
    gov_doc_passport: 'Passport',
    gov_doc_drivers_license: 'Drivers License',
    ownership_percent: 'Ownership Percent',
    modal_ssn: 'SSN',
    modal_ssn_line1: 'US Citizens: Enter your social security number',
    modal_ssn_line2: 'For Citizens outside of the US: Enter your government tax identification number',
    year_format: 'YR',
    business_desc_questions: 'Business Description Questions',
    business_purpose: 'Purpose of Account',
    business_purpose_select_1: 'Custody of Funds',
    business_purpose_select_2: 'Custody of Digital Assets',
    business_purpose_select_3: 'Custody of Funds & Digital Assets',
    business_associations_other_acc: 'Association with Other Accounts',
    business_associations_other_acc_select_1: 'None',
    business_associations_other_acc_select_2: 'I also have a Personal Account',
    business_associations_other_acc_select_3: 'I also have a Business Account',
    business_source_assets: 'Source of Assets and Income',
    business_source_assets_personal: 'Personal',
    business_source_assets_business: 'Business',
    business_intended_use_of_account: 'Intended Use of Account',
    business_intended_use_of_account_select_1: 'Custody of Funds for Personal Use',
    business_intended_use_of_account_select_2: 'Custody of Digital Assets for Personal Use',
    business_intended_use_of_account_select_3: 'Custody of Funds & Digital Assets for Personal Use',
    business_intended_use_of_account_select_4: 'Custody of Funds for Business Use',
    business_intended_use_of_account_select_5: 'Custody of Digital Assets for Business Use',
    business_intended_use_of_account_select_6: 'Custody of Funds & Digital Assets for Business Use',
    business_anticipated_types_of_assets: 'Anticipated Types of Assets',
    business_anticipated_types_of_assets_select_1: 'Cash',
    business_anticipated_types_of_assets_select_2: 'Digital Assets',
    business_anticipated_types_of_assets_select_3: 'Cash & Digital Assets',
    business_anticipated_monthly_cash_volume: 'Anticipated Monthly Cash Volume',
    business_anticipated_trading_patterns: 'Anticipated Trading Patterns',
    business_anticipated_trading_patterns_select_1: 'None',
    business_anticipated_trading_patterns_select_2: 'Regular',
    business_anticipated_trading_patterns_select_3: 'Rare',
    business_anticipated_monthly_transactions_incoming: 'Anticipated Monthly Transactions Incoming',
    business_anticipated_monthly_transactions_outgoing: 'Anticipated Monthly Transactions Outgoing',
    business_industry: 'Business Industry',
    administrative_services: 'Administrative Services',
	advertising: 'Advertising',
	animal_farming_production: 'Animal Farming Production',
	art_photography: 'Art Photography',
	auto_dealers: 'Auto Dealers',
	automotive: 'Automotive',
	bank: 'Bank',
	beauty_or_barber_shops: 'Beauty Or Barber Shops',
	biotechnology: 'Biotechnology',
	building_materials_hardware: 'Building Materials Hardware',
	car_wash: 'Car Wash',
	clothing_apparel: 'Clothing Apparel',
	college_university_schools: 'College University Schools',
	computer_service_repair: 'Computer Service Repair',
	construction: 'Construction',
	consumer_goods: 'Consumer Goods',
	crop_farming: 'Crop Farming',
	data_analytics: 'Data Analytics',
	design: 'Design',
	ecommerce: 'Ecommerce',
	electronics: 'Electronics',
	employment_services: 'Employment Services',
	energy: 'Energy',
	engineering: 'Engineering',
	events: 'Events',
	financial_investments: 'Financial Investments',
	financial_services: 'Financial Services',
	financial_technology: 'Financial Technology',
	fishing_hunting: 'Fishing/Hunting',
	fitness_sports_centers: 'Fitness Sports Centers',
	food: 'Food',
	forestry: 'Forestry',
	freelance_professional: 'Freelance Professional',
	funds_trusts_other: 'Funds Trusts Other',
	gaming: 'Gaming',
	gasoline_service_station: 'Gasoline Service Station',
	government_agency: 'Government Agency',
	hardware: 'Hardware',
	health_services: 'Health Services',
	home_furnishing: 'Home Furnishing',
	hospitals: 'Hospitals',
	hotel_motel: 'Hotel/Motel',
	industrial_machinery: 'Industrial Machinery',
	information_technology: 'Information Technology',
	insurance: 'Insurance',
	landscape_services: 'Landscape Services',
	legal_services: 'Legal Services',
	lifestyle: 'Lifestyle',
	massage_tanning_services: 'Massage Tanning Services',
	mobile: 'Mobile',
	money_transfer_remittance: 'Money Transfer Remittance',
	museums: 'Museums',
	music: 'Music',
	natural_resources: 'Natural Resources',
	non_government: 'Non Government',
	online_retailer: 'Online Retailer',
	other_accommodation: 'Other Accommodation',
	other_arts_entertainment: 'Other Arts Entertainment',
	other_education_services: 'Other Education Services',
	other_farming_hunting: 'Other Farming/Hunting',
	other_food_services: 'Other Food Services',
	other_health_fitness: 'Other Health Fitness',
	other_manufacturing: 'Other Manufacturing',
	other_professional_services: 'Other Professional Services',
	other_trade_contractor: 'Other Trade Contractor',
	other_transport_services: 'Other Transport Services',
	other_travel_services: 'Other Travel Services',
	parking_garages: 'Parking Garages',
	payments: 'Payments',
	plumbing_hvac: 'Plumbing HVAC',
	private_investment: 'Private Investment',
	publishing: 'Publishing',
	real_estate: 'Real Estate',
	religious_organization: 'Religious Organization',
	restaurants_with_cash: 'Restaurants With Cash',
	restaurants: 'Restaurants',
	retail_jeweler: 'Retail Jeweler',
	retail_with_cash: 'Retail With Cash',
	retail: 'Retail',
	sales_marketing: 'Sales Marketing',
	security: 'Security',
	software_publisher: 'Software Publisher',
	sports_teams_clubs: 'Sports Teams Clubs',
	sports: 'Sports',
	sustainability: 'Sustainability',
	taxi: 'Taxi',
	telecommunication: 'Telecommunication',
	tour_operator: 'Tour Operator',
	transportation: 'Transportation',
	travel_agency: 'Travel Agency',
	trucking_shipping: 'Trucking/Shipping',
	unions: 'Unions',
	used_clothes_dealer: 'Used Clothes Dealer',
	video: 'Video',
	warehouse_distribution: 'Warehouse Distribution',
	wellness_services: 'Wellness Services',
	wholesale_jeweler: 'Wholesale Jeweler',
	wholesale: 'Wholesale',

    business_desc: 'Business Description',
    business_doc_submission: 'Document Submission',
    business_nature: 'Nature of Business of the Company',
    business_inc_doc: 'Incorporation Documents (10MB max)',
    business_address_confirmation: 'Address Confirmation (10MB max)',
    contact_increment_ID: 'Contact',
    contact_increment_ID_text: 'Government Issued ID Type',
    contact_increment_address_text: 'Address Confirmation',
    bus_inc_doc_header: 'Business Incorporation Documents',
    bus_inc_doc_text: 'Please submit your complete business incorporation documents which can include the company charter, articles of incorporation, bylaws, operating agreement, ownership share registry, etc.',
    bus_address_conf_header: 'Business Address Confirmation',
    bus_address_conf_text: 'Please provide proof of address (mail item) dated within the last 90 days (e.g., Monthly Bill Statement, Bank/Credit Card Account Statement, Rental Lease Agreement, Vehicle Registration, Copy of Real Estate Property Title or Property Tax Bill).',
    bus_gov_issues_ID_popup_header: 'Government Issued ID',
    bus_gov_issues_ID_popup_line1: 'Please follow these instructions when submitting your government issued ID.  Submitted ID documents that do not meet the below criteria may cause approval delays.',
    bus_gov_issues_ID_popup_line2: 'Scan of the passport should be of the full photograph page and showing the full opposite page as well.  Both pages must show their complete margin.  Scan must be in color and have the least amount of reflections and glare as possible.',
    bus_gov_issues_ID_popup_line3: 'Drivers License:  Scan of the drivers license should be in full and showing all margins.  Scan must be in color and have the least amount of reflections and glare as possible.',
    choose_file: 'Choose File',
    personal_acc_heading: 'Contact Information',
    personal_address_conf_header: 'Address Information',
    personal_doc_submission: 'Document Submission',
    unable_1: 'We are unable to complete your request at this time. Please contact support at',
    unable_2: 'or by phone at',
    unable_3: 'to complete your request',
    unable_4: 'Thank you',
    final_acc_activation: 'Final Account Activation will only occur once all documents have been received.',
    required1: 'You need to fill all required fields. We marked them with red color so that you know what fields you have missed.',
    required2: 'Ownership Percentage of all Beneficial Owners needs to be a total of 100%',
    required3: 'At least one Beneficial Owner must be selected',
    form_error_required: 'This field is required.',
    form_error_email: 'This should be a valid email.',
    form_error_sameAs: 'Passwords must match.',
    form_error_integer: 'The value must be integer.',
    form_error_numeric: 'This field must be numeric.',
    form_error_decimal: 'This field must be a number.',
    form_error_between: 'The value is out of range.',
    form_error_alpha: 'This field must be letters only.',
    form_error_alphaNum: 'This field does not allow special characters.',
    form_error_alphaNumNoSpace: 'This field must be alphanumeric.',
    form_error_alphaOnly: 'This field must be letters only.',
    form_error_alphaVersionNoSpace: 'This field does not allow special characters.',
    form_error_addressValidation: 'This field does not allow special characters.',
    form_error_dashNumbers: 'This field does not allow special characters.',
    form_error_ipAddress: 'The value must be a valid IPv4 address.',
    form_error_url: 'The value must be a valid URL.',
    form_error_minLength: 'More characters required.',
    form_error_maxLength: 'Maximum character limit exceeded.',
    form_error_minValue: 'The value can not be less than zero.',
    form_error_limit: 'Amount Entered Exceeds Limit',
    form_error_routingNumber: 'Routing number can only start with 0, 1, 2 or 3',
    form_error_monbiAccountNumber: 'This is not a valid Account number. Please check information and try again.',
    form_error_swift: 'Enter a valid SWIFT code.',
    form_error_minLimit: 'The Amount must be greater than the Fee.',
    form_error_webSiteUrl: 'This field must be url format.',
  },
  months: {
  	1: 'January',
  	2: 'February',
  	3: 'March',
  	4: 'April',
  	5: 'May',
  	6: 'June',
  	7: 'July',
  	8: 'August',
  	9: 'September',
  	10: 'October',
  	11: 'November',
  	12: 'December',
  },
};
