import $ from 'jquery';
import Vue from 'vue';

import i18n from '../lang'

$(function($) {
  window.vueMixins=window.vueMixins || {}
  $("[vue-dyn]").each(function() {
    var $this=$(this);
    var mixins=$this.attr("vue-dyn").split(",").filter(function(a) {
      return a;
    }).map(function(a) {
      return window.vueMixins[a];
    });
    $this.removeAttr("vue-dyn");
    (new Vue({
      el: this,
      mixins: mixins,
      i18n,
    }));
  });
});
