<template>
<div
  v-if="employee"
  class="ncontent wide">
  <a class="back-button">Dashboard</a>
  <div class="header">
    <div>
      <ul class="breadcrumbs">
        <li><a href="">Employee Overview</a></li>
        <li><a href="">Employee</a></li>
      </ul>
    </div>
  </div>
  <div class="merchant-panel">
    <div class="data-content">
      <ul class="tabs">
        <li :class="{ active: activeTab === 'general' }" @click="activeTab = 'general'">General</li>
        <li :class="{ active: activeTab === 'personal' }" @click="activeTab = 'personal'">Personal</li>
        <li :class="{ active: activeTab === 'notes' }" @click="activeTab = 'notes'">Notes</li>
      </ul>
      <p class="tabs">Employee Settings</p>
      <ul class="tabs-smaller">
        <li :class="{ active: activeTab === 'profile' }" @click="activeTab = 'profile'">Profile</li>
        <li :class="{ active: activeTab === 'access' }" @click="activeTab = 'access'">Access</li>
        <li :class="{ active: activeTab === 'activity' }" @click="activeTab = 'activity'">Activity</li>
      </ul>
      <div class="main">
        <div class="status">
          <label>Current Status: <strong>{{ employee.status }}</strong></label>
        </div>

        <employee-data-activity
          v-show="activeTab === 'activity'"
          :employee="employee" />
        
        <form class="preq-form preq-view">
          <notes v-if="activeTab === 'notes'" class="appl-notes" :notes="notes" type="Employee" :id="id" />

          <employee-data-general
            v-show="activeTab === 'general'"
            :employee="employee"
            :value="form"
            :editMode="editMode" />

          <employee-data-personal
            v-show="activeTab === 'personal'"
            :employee="employee"
            :value="form"
            :editMode="editMode" />

          <employee-data-access
            v-show="activeTab === 'access'"
            :employee="employee"
            :value="form"
            :editMode="editMode"
            @active="employee.userId.active = $event" />
            
          <employee-data-profile
            v-show="activeTab === 'profile'"
            :employee="employee"
            :value="form"
            :editMode="editMode" />
        </form>

        <template v-if="editMode">
          <button type="button" class="btn btn-small" @click="edit()">Save</button>
          <button type="button" class="btn btn-small" @click="editMode = false">Cancel</button>
        </template>
      </div>
    </div>

    <aside class="actions">
      <ul class="tabs">
        <li>General</li>
      </ul>
      
      <div class="main">
        <div class="action-buttons">
          <a href="#" @click.prevent="editMode = true">Edit</a>
        </div>
      </div>
    </aside>
  </div>
</div>
</template>

<script>
export default {
  mixins: [ vueMixins.helpers, vueMixins.dateFormatter, formValidation.mixin ],

  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },

  data () {
    return {
      activeTab: 'general',
      employee: null,
      notes: [],
      form: {},
      editMode: false
    }
  },

  created() {
    startLoading()
    this.loadEmployee()
    this.loadNotes()
    stopLoading()
  },

  methods: {
    async edit () {
      if (!this.validate()) return stopLoading('Please fill all required fields', 'error')
      startLoading()
      try {
        const response = await api.update("Employee", this.id, this.form, true)
        const edited = this.getEditedFields()
        const note = (await api.create("Note", {
          message: `Employee edited - ${edited}`,
          parentId: this.id,
          type: 'Employee',
          created: "CURRENT_TIMESTAMP",
          author: appData.currentUser.id,
          system: 1
        }, true)).item
        this.notes.push(note)
        this.employee = response.item
        this.validateReset()
//         this.clearForm()
        this.editMode = false
        stopLoading()
      } catch (e) {
        stopLoading(e, 'error')
      }
    },

    getEditedFields () {
      const labels = {
        first_name: 'First Name',
        last_name: 'Last Name',
        title: 'Title',
        role: 'Role',
        address: 'Address',
        city: 'City',
        province: 'Province',
        postal: 'Postal',
        country: 'Country',
        ec_first_name: 'Emergency Contact First Name',
        ec_last_name: 'Emergency Contact Last Name',
        ec_email: 'Emergency Contact Email',
        ec_phone: 'Emergency Contact Phone',
        portal: 'Portal',
        phone: 'Phone',
        phone_ext: 'Phone Ext',
        companyId: 'Company',
        salesBrandId: 'Salses Brand'
      }
      const fields = []
      Object.keys(this.form).forEach(key => {
        if ((['companyId', 'salesBrandId'].includes(key) && this.form[key] !== this.employee[key].id) || (!['companyId', 'salesBrandId'].includes(key) && this.form[key] !== this.employee[key]))
          fields.push(labels[key])
      })
      return fields.join(', ')
    },

    async loadNotes () {
      const filter = {
        type: 'Employee',
        parentId: this.id
      }
      this.notes = (await api.list("Note", filter, ['id:ASC'])).items || [];
    },

    async loadEmployee () {
      const response = await api.get('Employee', this.id)
      this.employee = response.item
      this.clearForm()
    },

    clearForm () {
      const values = ['first_name', 'last_name', 'title', 'role', 'address', 'city', 'province', 'postal', 'country', 'ec_first_name', 'ec_last_name', 'ec_email', 'ec_phone', 'portal', 'phone', 'phone_ext']
      values.forEach(key => {
        this.$set(this.form, key, this.employee[key])
      })
      this.$set(this.form, 'companyId', this.employee.companyId.id)
      this.$set(this.form, 'salesBrandId', this.employee.salesBrandId.id)
    }
  },
}
</script>
