<template>
<div>
  <div>
    <merchant-setup-popup ref="setup" :inline="true" />
  </div>
  <div class="actions">
    <button v-on:click.prevent="$emit('back')">Back</button>
<!--     <button v-on:click.prevent="doNext">Next</button> -->
  </div>
</div>
</template>

<script>
export default {
  props: ["item", "merchant"],
  data: ()=>({
  }),
  methods: {
    async startSetup() {
      if (!this.merchant || !this.item)
        return;
      var setup=(await api.list("MerchantSetup", {
        merchantId: this.merchant.id,
        appId: this.item.id,
      }, ["id:DESC"])).items[0];
      if (!setup) {
        var id=(await api.create("MerchantSetup", {
          merchant: this.merchant.id,
          app: this.item.id,
          created: "CURRENT_TIMESTAMP",
          buildId: "APP_ID_GEN_MERCHANTBUILD",
          setupData: {accountId: this.item.accountId, cardPresent: this.item.merchantType=='POS', cardNotPresent: this.item.merchantType=='E-Commerce'},
        })).id;
        setup=(await api.get("MerchantSetup", id)).item;
      }
      if (setup)
        this.$refs.setup.showExisting(setup);
    },
  },
  mounted() {
    this.startSetup();
  },
}
</script>
<style scoped>
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  margin-right: 8px;
  color: black;
  font-size: 16px;
  display: block;
  padding: 6px 20px;
  box-sizing: border-box;
  border: 1px solid #b3b3b3;
  border-radius: 6px;
  background: #e4e4e4;
  background: linear-gradient(to bottom, #e4e4e4 0%, #cfcfcf 100%);
}
button:hover, button:focus {
  background: #e9f2ff;
  background: linear-gradient(to bottom, #e9f2ff 0%, #deebff 100%);
  border-color: #6192fc;
  color: #6192fc;
}
</style>
