var api=window.api;

window.vueMixins=window.vueMixins || {}
window.vueMixins.userProfile={
  data: function() { return {
    logins: [],
  }},
  mounted: function() {
    var that=this;
    setTimeout(async function() {
      if (mainUser!==false)
        that.logins=(await api.list("UserLogin", {userMainId: that.profile.id}, ["recorded:DESC"], 10)).items;
      else
        that.logins=(await api.list("UserLogin", {userId: appData.currentUser.id}, ["recorded:DESC"], 10)).items;
    }, 1);
  },
  methods: {
    resetPassword: async function() {
      if (!this.profile.id)
        return;
      startLoading();
      await api.update("User", this.profile.id, {
        password: this.profile.username+"123",
        passChange: 1,
      });
      stopLoading("Password reset instructions have been sent via email", "success");
      setTimeout(function() {
        location.href="/login";
      }, 5000);
    },
  },
}
