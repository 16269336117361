<template>
  <div class="acc-list" style="display: block">
    <table class="">
      <thead>
        <tr>
          <th style=""></th>
          <th style="">Username</th>
          <th style="">Email</th>
          <th style="">Activation Code</th>
          <th style="">Activation Code Expires</th>
          <th style="">Activation Date</th>
          <th style="">Reset Pass Request</th>
          <th style="">Reset Pass Complete</th>
          <th style="">Blocked</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><input type="radio" v-model="selectedUser" :value="item.id" style="height: auto"></td>
          <td>{{ item.username }}</td>
          <td>{{ item.email || (item.agentDb && item.agentDb.email) }}</td>
          <td>{{ item.agentDb.status=='A07' && item.activationPassword?item.activationPassword:'' }}</td>
          <td>{{ item.agentDb.status=='A07' && item.passChange?formatDate(item.passChange*1000, 'YYYY-MM-DD HH:mm:ss'):'' }}</td>
          <td>{{ item.activationDate }}</td>
          <td>{{ item.agentDb.status!='A07' && item.hasNewPassword?'(generated)':'' }}</td>
          <td>{{ item.agentDb.status!='A07' && item.passChange?formatDate(item.passChange*1000, 'YYYY-MM-DD HH:mm:ss'):'' }}</td>
          <td>{{ item.active=='Yes'?'No':'Blocked' }}</td>
        </tr>
        <tr v-for="subuser in subusers">
          <td><input type="radio" v-model="selectedUser" :value="subuser.id" style="height: auto"></td>
          <td>{{ subuser.username }}</td>
          <td>{{ subuser.email }}</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>{{ subuser.activationDate }}</td>
          <td>{{ subuser.activationPassword?subuser.activationPassword:'' }}</td>
          <td>{{ subuser.passChange?formatDate(subuser.passChange*1000, 'YYYY-MM-DD HH:mm:ss'):'' }}</td>
          <td>{{ subuser.active=='Yes'?'No':'Blocked' }}</td>
        </tr>
      </tbody>
    </table>

    <portal to="actions">
      <ul>
        <template>
            <li v-for="(optLabel, optMethod) in actions" :class="{disabled: isDisabled(optMethod)}">
              <a href="" v-on:click.prevent="doAction(optMethod)">{{ optMethod }}</a>
            </li>
        </template>
      </ul>
    </portal>

    <popup :open="newUserOpen" @close="newUserOpen=false">
      <user-sub-new-sub v-if="newUserOpen" />
    </popup>
  </div>
</template>

<script>
export default {
  props: ["item"],
  mixins: [vueMixins.dateFormatter],
  data: ()=>({
    selectedUser: null,
    newUserOpen: false,
    subusers: [],
    actions: {
      "Reset Activation": "Reset Activation",
      "Reset Password": "Reset Password",
      "Add Sub-user": "Add Sub-user",
      "Remove Sub-user": "Remove Sub-user",
    },
  }),
  computed: {
    usersById() {
      var result={};
      for(var i in this.users)
        result[this.users[i].id]=this.users[i];
      result[this.item.id]=this.item;
      return result;
    }
  },
  methods: {
    isDisabled(action) {
      if (action=='Reset Activation' && this.selectedUser!=this.item.id)
        return true;
      if (action=='Reset Activation' && this.item.agentDb.status!='A07')
        return true;
      if (action=='Reset Password' && this.item.agentDb.status!='A08')
        return true;
      if (action=='Reset Password' && !this.selectedUser)
        return true;
      if (action=='Remove Sub-user' && (!this.selectedUser || this.selectedUser==this.item.id))
        return true;
      return false;
    },
    async doAction(action) {
      if (action=='Reset Activation') {
        this.sendEmail('Welcome', true);
      }
      if (action=='Reset Password') {
        this.sendEmailUser('ResetPw');
      }
      if (action=='Add Sub-user') {
        this.newUserOpen=true;
      }
      if (action=='Remove Sub-user') {
        var subUserIndex=this.subusers.findIndex(a=>a.id==this.selectedUser);
        if (subUserIndex!=-1)
          this.deleteSubuser(subUserIndex);
      }
    },
    async sendEmail(type, resetPw) {
      if (type=='Welcome' && !confirm("Are you sure?"))
        return;
      startLoading();
      if (resetPw)
        var res=await api.create("PartnerEmail", {agentId: this.item.agentDb.id, emailType: type, created: "CURRENT_TIMESTAMP", resetPassword: true});
      else
        var res=await api.create("PartnerEmail", {agentId: this.item.agentDb.id, emailType: type, created: "CURRENT_TIMESTAMP"});
      stopLoading("Activation code reset");
    },
    async sendEmailUser(type) {
      if (type=='ResetPw' && confirm("Are you sure?")) {
        startLoading();
        var res=await api.update("User", this.item.id, {passChange: true});
        stopLoading("Password reset");
      }
    },
    async deleteSubuser(index) {
      var that=this;
      if (confirm("Are you sure?")) {
        startLoading();
        await api.delete("User", that.subusers[index].id);
        that.users.splice(index, 1);
        stopLoading("User deleted");
      }
    },
  },
  async mounted() {
    this.subusers = (await api.list("User", {parent: this.item.id})).items
  },
}
</script>
