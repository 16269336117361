<template>
<div>
  <view-app-extcheck type="g2" :uwList="itemList" ref="extcheck" />
  <portal to="actions">
    <ul>
      <li>
        <a href="" v-on:click.prevent="$refs.extcheck.newCheck()">Run New</a>
      </li>
    </ul>
  </portal>
</div>
</template>
<script>
export default {
  props: ["item", "merchant"],
  data: ()=>({
    itemList: [],
  }),
  computed: {
  },
  methods: {
    listG2: async function() {
      this.itemList=(await api.list("ExtCheck", {applicationId: this.item.id, type: "g2"}, ["id:DESC"])).items || [];
    },

  },
  mounted() {
    this.listG2();
  },
}
</script>
