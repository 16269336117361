<template>
<div class="preform-outer">
<div class="preformbg"></div>
<div class="appf preform ncontent">
<div class="wcontent mc-setup">
  <div class="cb-logo" :style="{'background-image': 'url(&quot;/files/company/'+companydata.appHeadLogo+'&quot;)' }">CardBiller</div>
  <h1>Account Confirmation</h1>
  <div class="fi-split">
    <div class="form-info">
      <p>Application Assistance</p>
      <p><strong>{{ companydata.applicationEmail }}</strong></p>
      <p><strong>{{ companydata.servicesTel }}</strong></p>
    </div>
    <div class="form-info" v-if="item.manager">
      <p>Account Manager:</p>
      <p><strong>{{ item.manager.fullName }}</strong></p>
      <p><strong>{{ item.manager.email }}</strong></p>
      <p><strong>{{ item.manager.phone }}</strong></p>
    </div>
  </div>
  <p class="form-intro">
    <strong>Dear Merchant:</strong><br /><br />
    We are pleased to inform you that we have opened your new payment processing account! Below is important information that will be used to correctly configure your account to maximize your processing experience. Review and complete the required fields below and submit when completed in order to finalize setting up your account. If there is information that needs to be updated, do not submit and contact your Account Manager or Account Setup Assistance.
  </p>
  <!--<div class="temp-link">
    <label>Guest access to this form:</label>
    <a :href="link.url" target="_blank" v-on:click.prevent="copyLink">{{ link.url }}</a>
  </div>-->  
  
  <form class="preq-form" v-if="step==0">
    <section>
      <h2>
        <em>Merchant Information</em>
      </h2>
      <ul class="preform-sp3">
        <li>
          <label>Merchant Account ID</label>
          <div>
            {{ item.accountId }}
          </div>
        </li>
        <li>
          <label>Business Type:</label>
          <div>
            {{ item.industry }}
          </div>
        </li>
        <li>
          <label>Merchant Type</label>
          <div>
            {{ item.merchantType }}
          </div>
        </li>
        <li>
          <label>MCC:</label>
          <div>
            {{ (item.mcc || "") }}
          </div>
        </li>
      </ul>
      <ul class="preform-sp3">
        <li>
          <label>Legal Business Name:</label>
          <div>
            {{ item.companyName }}
          </div>
        </li>
        <li>
          <label>Company Email:</label>
          <div>
            {{ item.companyEmail }}
          </div>
        </li>
        <li>
          <label>Company Phone:</label>
          <div>
            {{ item.companyPhone }}
          </div>
        </li>
        <li>
          <label>Billing Descriptor:</label>
          <div>
            {{ item.descriptor1 }}
          </div>
        </li>
      </ul>      
      <h2>
        <em>Contact Information</em>
      </h2>
      <ul class="preform-sp3" v-for="contact in item.contacts">
        <li>
          <label>Type:</label>
          <div>
            {{ (contact.type || "")[0].toUpperCase()+(contact.type || "").substring(1).toLowerCase()+" contact" }}
          </div>
        </li>
        <li>
          <label>Full Name:</label>
          <div>
            {{ contact.firstName+" "+contact.lastName }}
          </div>
        </li>
        <li>
          <label>Email:</label>
          <div>
            {{ contact.email }}
          </div>
        </li>
        <li>
          <label>Phone:</label>
          <div>
            {{ contact.phone }}
          </div>
        </li>
      </ul>      
      <h2>
        <em>Account Setup</em>
      </h2>
      <p class="intro">
        How will you process your transactions with {{ companydata.nameDba }}?  Check all that apply
      </p>
      <validator :value="(item.methodPos || item.methodMobile || item.methodApi || item.methodCheckout || item.methodVirtual || item.methodGateway)" reqs="boolean">
      <ul class="preform-sp3 preform-nogrow preform-line">
        <li>
          <label>
            <input type="checkbox" v-model="item.methodPos" :disabled="readonly" /><span></span>
            POS <!-- <input placeholder="#" type="text" style="display: inline-block; width: 40px" v-model="merchant.posAmount" :disabled="!merchant.methodPos" > Terminals -->
          </label>
        </li>
        <li>
          <label>
            <input type="checkbox" v-model="item.methodMobile" :disabled="readonly" /><span></span>
            Mobile <!-- <input placeholder="#" type="text" style="display: inline-block; width: 40px" v-model="merchant.mobileAmount" :disabled="!merchant.methodMobile" > Devices -->
          </label>
        </li>
        <!--
        <li>
          <label>
            <input type="checkbox" v-model="merchant.methodGateway" /><span></span>
            Payment Gateway
          </label>
        </li> -->
        <li>
          <label>
            <input type="checkbox" v-model="item.methodCheckout" :disabled="readonly" /><span></span>
            CardBiller Checkout
          </label>
        </li>
        <li>
          <label>
            <input type="checkbox" v-model="item.methodVirtual" :disabled="readonly" /><span></span>
            Virtual Terminal
          </label>
        </li>
        <li>
          <label>
            <input type="checkbox" v-model="item.methodApi" :disabled="readonly" /><span></span>
            API  <!-- <input placeholder="#" type="text" style="display: inline-block; width: 40px" v-model="merchant.ipAmount" :disabled="!merchant.methodApi" > IPs -->
          </label>
        </li>
      </ul>
      <!--
      <ul class="preform-sp3 preform-nogrow preform-line">        
        <li class="ipinput">
          <label>List IP Addresses to be whitelisted, separate them by commas</label>
          <validator :value="merchant.apiIps" :reqs="merchant.methodApi?'required':''">
            <input type="text" v-model="merchant.apiIps" />
          </validator>
        </li>
      </ul> -->
      </validator>
      <p class="intro">
        Your account is setup to accept the following payment types:
      </p>
      <ul class="preform-auto preform-nogrow">
        <li v-if="item.cardTypes.indexOf('Visa')!=-1">
          <label>
<!--             <input type="checkbox" v-model="item.cardTypes" value="Visa" :disabled="true" /><span></span> -->
            Visa
          </label>
        </li>
        <li v-if="item.cardTypes.indexOf('MasterCard')!=-1">
          <label>
<!--             <input type="checkbox" v-model="item.cardTypes" value="MasterCard" :disabled="true" /><span></span> -->
            MasterCard
          </label>
        </li>
        <li v-if="item.cardTypes.indexOf('Discover')!=-1">
          <label>
<!--             <input type="checkbox" v-model="item.cardTypes" value="Discover" :disabled="true" /><span></span> -->
            Discover
          </label>
        </li>
        <li v-if="item.cardTypes.indexOf('AMEX')!=-1">
          <label>
<!--             <input type="checkbox" v-model="item.cardTypes" value="AMEX" :disabled="true" /><span></span> -->
            AMEX
          </label>
        </li>
        <li v-if="item.cardTypes.indexOf('China UnionPay')!=-1">
          <label>
<!--             <input type="checkbox" v-model="item.cardTypes" value="China UnionPay" :disabled="true" /><span></span> -->
            China UnionPay
          </label>
        </li>
        <li v-if="item.cardTypes.indexOf('ApplePay')!=-1">
          <label>
<!--             <input type="checkbox" v-model="item.cardTypes" value="ApplePay" :disabled="true" /><span></span> -->
            ApplePay
          </label>
        </li>
        <li v-if="item.cardTypes.indexOf('GooglePay')!=-1">
          <label>
<!--             <input type="checkbox" v-model="item.cardTypes" value="GooglePay" :disabled="true" /><span></span> -->
            GooglePay
          </label>
        </li>
        <li v-if="item.cardTypes.indexOf('SamsungPay')!=-1">
          <label>
<!--             <input type="checkbox" v-model="item.cardTypes" value="SamsungPay" :disabled="true" /><span></span> -->
            SamsungPay
          </label>
        </li>
        <li v-if="item.cardTypes.indexOf('Interac Online')!=-1">
          <label>
<!--             <input type="checkbox" v-model="item.cardTypes" value="Interac Online" :disabled="true" /><span></span> -->
            Interac Online
          </label>
        </li>
        <li v-if="item.cardTypes.indexOf('Interac Debit')!=-1">
          <label>
            Interac Debit <!--TODO NEEDED FILIP-->
          </label>
        </li>
        <li v-if="item.cardTypes.indexOf('EFT')!=-1">
          <label>
            ACH/EFT
          </label>
        </li>
      </ul>     
      <ul class="preform-sp3">
        <li class="sub-select compact">
          <label>You can accept the following currencies:</label>
          <validator :value="merchant.currencies" reqs="required city">
            <span>{{ merchant.currencies }}</span>
          </validator>
        </li>
      </ul>
      <h2><strong>Equipment</strong></h2>
      <p class="intro" v-if="false">
        All equipment will be shipped to the business address listed above.
      </p>
      <table class="preform-sp3 preform-nogrow">
        <thead>
          <tr>
            <th>Item</th>
            <th>Qty</th>
            <th>Contract Type</th>
            <th>Unit Price</th>
            <th>Total Price</th>
            <th>Note</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="equItem in equipmentList">
            <td>{{ equItem.name }}</td>
            <td>{{ equItem.quantity }}</td>
            <th>{{ equItem.rental==1?"Rental":"Purchase" }}</th>
            <th>{{ equItem.rental==1?equItem.rentalPrice:equItem.price }}</th>
            <th>{{ ((equItem.rental==1?equItem.rentalPrice:equItem.price)*equItem.quantity).toFixed(2) }}</th>
            <th>&nbsp;</th>
          </tr>
        </tbody>
      </table>
      <p class="intro">
         If there is information that needs to be updated, <em>do not submit this form</em> and contact your Account Manager or Account Setup Assistance.
      </p>
      <p class="intro">
        <strong>Account Manager:</strong><br />
        {{ item.manager && item.manager.fullName }}, {{ item.manager && item.manager.email }}, {{ item.manager && item.manager.phone }} {{ item.manager && item.manager.phoneExt }}
      </p>
      <p class="intro">
        <strong>Account Setup Assistance:</strong><br />
        {{ companydata.supportEmail }}<br />
        {{ companydata.supportTel }}<br />
      </p>
    </section>

    <div class="buttons" v-if="!readonly">
      <button class="btn btn-large btn-primary" v-on:click.prevent="send" :disabled="sending">Submit</button>
    </div>

  </form>
  <div class="pre-done" v-else-if="step==1">
    <h1>Thank you for submitting your setup configuration!</h1>
    <p>One of our account Representatives will contact you shortly.</p>
    <a href="" class="btn btn-large btn-primary btn-black">Back to Dashboard</a>
  </div>
</div>
</div>
</div>
</template>

<script>
export default {
  props: ["readonly"],
  data() { return {
    step: 0,
    sending: false,
    item: window.existingApplication,
    merchant: window.existingMerchant,
    agreement: window.existingAgreement,
    equipment: window.existingEquipment,
    files: [ null ],
    companydata: appData.company || {},
    link: existingLink.url?{url: existingLink.url}:null,
    enabledPayOpts: [],
  }},
  mounted() {
    this.loadOptions();
    var that=this;
    if (this.merchant.cardTypes.length==0 && this.agreement)
      this.merchant.cardTypes=JSON.parse(JSON.stringify(this.agreement.payoptions));
    if (!this.merchant.currencies)
      setTimeout(function() {
        that.merchant.currencies=that.item.transactionCurrencies || "";
      }, 30);
    for(var field of ["methodPos", "methodMobile", "methodApi", "methodCheckout", "methodVirtual", "methodGateway"])
      this.$set(this.item, field, this.merchant[field] || this.item[field]);
  },
  computed: {
    equipmentList() {
      if (!this.agreement)
        return [];
      if (this.agreement.equipment.length)
        return this.agreement.equipment;
      else {
        var equipmentList=[];
        var index=1;
        var equ=this.agreement.equipment;
        while(equ["terminal"+index+"Type"]) {
          var equItem=this.equipment.find((a)=>a.id==equ["terminal"+index+"Type"]);
          if (!equItem) { index++; continue; }
          equipmentList.push({
            name: equItem.name,
            quantity: equ["terminal"+index+"Qty"]*1 || 0,
            mode: equ["terminal"+index+"PurchaseOpt"]?"Purchase":
                 (equ["terminal"+index+"RentalOpt"]?"Rental":"Reprogram"),
            price: equ["terminal"+index+"PurchaseOpt"]?equItem.purchase*1:
                  (equ["terminal"+index+"RentalOpt"]?equItem.rental*1:0),
          });
          index++;
        }
        return equipmentList;
      }
    },
  },
  methods: {
    loadOptions: async function() {
      var item=(await api.get("Options", 1)).item || {};
      this.enabledPayOpts=item.enabledMethods || [];
    },
    send: async function() {
      if (!this.validate())
        return stopLoading("Please fill all required fields", "error");
      startLoading();
      this.sending=true;
      for(var field of ["methodPos", "methodMobile", "methodApi", "methodCheckout", "methodVirtual", "methodGateway"])
        this.$set(this.merchant, field, this.item[field]);
      var result=await api.update("Merchant", this.merchant.id, this.merchant);
      stopLoading();
      this.step=1;
    },
    copyLink() {
      copyToClipboard.text=this.link.url;
      copyToClipboard.doCopy();
    },
  },
  mixins: [formValidation.mixin]
}
</script>
