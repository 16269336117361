<template>
<div class="popup" :class="{shown: visible}">
  <div class="pop-med">
    <div class="pop-inner">
      <a href="" class="close" v-on:click.prevent="visible=false"></a>
      <div class="text-to-copy">
        <span v-on:click="doCopy">{{ text }}</span>
        <button v-on:click="doCopy">Copy</button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  data() { return {
    visible: false,
    text: "",
  }},
  methods: {
    show(text) {
      this.visible=true;
      this.text=text;
    },
    hide() {
      this.visible=false;
      this.text="";
    },
    doCopyText(text) {
      this.text=text;
      this.doCopy();
    },
    doCopy() {
      var textArea=document.createElement("textarea");
      textArea.style.position="fixed";
      textArea.style.left="-1000px";
      
      textArea.value=this.text;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      var successful=false;
      try {
        successful=document.execCommand('copy');
      } catch (err) {
      }
      document.body.removeChild(textArea);
      if (successful) {
        stopLoading("Copied to clipboard", "success");
        this.hide();
      } else
        stopLoading("Failed to copy to clipboard", "error");
    },
  },
  mounted() {
    window.copyToClipboard=this;
  }
}

</script>
