<template>
<div class="setupcl">
<ul>

  <li>
    <label>Merchant Profile</label>
    <div class="done" v-if="doneStep>=0"></div>
    <div class="actions" v-else>
      <a class="button" :class="{disabled: doneStep!=-1}" href="" v-on:click.prevent="doneStep==-1 && setIntake()">Set</a>
    </div>
  </li>
  <li>
    <label>Merchant Settings</label>
    <div class="done" v-if="doneStep>=1"></div>
    <div class="actions" v-else>
      <a class="button" :class="{disabled: doneStep!=0}" href="" v-on:click.prevent="doneStep==0 && setSettings()">Set</a>
    </div>
  </li>
  <li>
    <label>Merchant Underwriting</label>
    <div class="done" v-if="doneStep>=2 || item.underwriting" style="position: relative"><a href="" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%" target="_blank" :href="'/applications/'+item.id+'/underwriting'"></a></div>
    <div class="actions" v-else>
      <a class="button" :class="{disabled: doneStep!=1}" href="" v-on:click.prevent="doneStep==1 && startUnderwriting()">Set</a>
    </div>
  </li>
  <li>
    <label>Status Update - In Process</label>
    <div class="done" v-if="doneStep>=2"></div>
    <div class="actions" v-else>
      <a class="button" :class="{disabled: doneStep!=1 || !item.underwriting}" href="" v-on:click.prevent="doneStep==1 && setStatus()">Set</a>
    </div>
  </li>
  <li>
    <label>Sign Application</label>
    <div class="done" v-if="doneStep>=3"></div>
    <div class="actions" v-else>
      <a class="button" :class="{disabled: doneStep!=2}" href="" v-on:click.prevent="doneStep==2 && uploadSignedApplication()">Upload</a>
    </div>
  </li>
  <li>
    <label>Website Check</label>
    <div class="done" v-if="doneStep>=4"></div>
    <div class="actions" v-else>
      <a class="button" :class="{disabled: doneStep!=3}" href="" v-on:click.prevent="doneStep==3 && startWebsiteCheck()">Run</a>
    </div>
  </li>
  <li>
    <label>Run Pre-Check</label>
    <div class="done" v-if="doneStep>=5"></div>
    <div class="actions" v-else>
      <a class="button" :class="{disabled: doneStep!=4}" href="" v-on:click.prevent="doneStep==4 && runPrecheck()">Run</a>
    </div>
  </li>
  <li>
    <label>Compliance Scans</label>
    <div class="done" v-if="doneStep>=7 || complianceDone2"></div>
    <div class="actions" v-else-if="doneStep>=6">
      <a class="button done" href="" v-on:click.prevent="doneStep==6 && (complianceDone=false)">Skipped</a>
    </div>
    <div class="actions" v-else>
      <a class="button" :class="{disabled: doneStep!=5}" href="" v-on:click.prevent="doneStep==5 && (complianceDone=true)">Skip</a>
      <a class="button" :class="{disabled: doneStep!=5}" href="" v-on:click.prevent="doneStep==5 && (complianceDone=true) && (complianceDone2=true) && ($parent.actionsTab=3)">Scan</a>
    </div>
  </li>
  <li>
    <label>Risk Exposure Calculator</label>
    <div class="done" v-if="doneStep>=7"></div>
    <div class="actions" v-else>
      <a class="button" :class="{disabled: doneStep!=6}" href="" v-on:click.prevent="doneStep==6 && runRisk()">Run</a>
    </div>
  </li>
  <li>
    <label>Create Agreement</label>
    <div class="done" v-if="doneStep>=8"></div>
    <div class="actions" v-else>
      <a class="button" :class="{disabled: doneStep!=7}" href="" v-on:click.prevent="doneStep==7 && createAgreement()">Start</a>
    </div>
  </li>
  <li>
    <label>Sign Agreement</label>
    <div class="done" v-if="doneStep>=9"></div>
    <div class="actions" v-else>
      <a class="button" :class="{disabled: doneStep!=8}" href="" v-on:click.prevent="doneStep==8 && signAgreement()">Start</a>
    </div>
  </li>
  <li>
    <label>Pre-Check</label>
    <div class="done" v-if="doneStep>=11"></div>
    <div class="actions" v-else-if="doneStep>=10">
      <a class="button done" href="" v-on:click.prevent="doneStep==10 && (secondaryPrecheck==false)">Skipped</a>
    </div>
    <div class="actions" v-else>
      <a class="button" :class="{disabled: doneStep!=9}" href="" v-on:click.prevent="doneStep==9 && setSecondaryPrecheck('skip')">Skip</a>
      <a class="button" :class="{disabled: doneStep!=9}" href="" v-on:click.prevent="doneStep==9 && setSecondaryPrecheck('run')">Run</a>
    </div>
  </li>
  <li>
    <label>Final Approval</label>
    <div class="done" v-if="doneStep>=11"></div>
    <div class="actions" v-else>
      <a class="button" :class="{disabled: doneStep!=10}" href="" v-on:click.prevent="doneStep==10 && doFinalApproval()">Start</a>
    </div>
  </li>
  <li>
    <label>Boarding</label>
    <div class="done" v-if="doneStep>=12"></div>
    <div class="actions" v-else>
      <a class="button" :class="{disabled: doneStep!=11}" href="" v-on:click.prevent="doneStep==11 && doBoarding()">Confirm</a>
    </div>
  </li>

  
  
</ul>
</div>
</template>

<script>
export default {
  props: ["item", "merchant", "notes", "prechecks", "weblist"],
  data: ()=>({
    complianceDone: false,
    complianceDone2: false,
    secondaryPrecheck: false,
    secondaryPrecheck2: false,
    riskList: [],
    extChecks: [],
  }),
  computed: {
    doneStep() {
      if (!this.isMerchantProfile)
        return -1;
      if (!this.isMerchantSettings)
        return 0;
      if (!this.isStatusSet)
        return 1;
      if (!this.isSignedApp)
        return 2;
      if (!this.isWebsiteCheckDone)
        return 3;
      if (!this.isPrecheckDone)
        return 4;
      if (!this.isComplianceScanDone && !this.complianceDone/* && !this.isRiskDone*/)
        return 5;
      if (!this.isRiskDone)
        return 6;
      if (!this.isAgreementDone)
        return 7;
      if (!this.isAgreementSigned)
        return 8;
      if (!this.secondaryPrecheck && !this.secondaryPrecheck2)
        return 9;
      if (!this.isApproved)
        return 10;
      if (!this.isBoarding)
        return 11;
      return 12;
    },
    isMerchantProfile() {
      return this.item.hasIntake;
    },
    isStatusSet() {
      return this.item.status>="A03";
    },
    isMerchantSettings() {
      for(var field of ["solution", "class", "accountType", /*"manager",*/ "source", "mcc", "industry", "language"]) {
        if (!this.item[field])
          return false;
      }
      return true;
    },
    isComplianceScanDone() {
      var isG2Done=false;
      var isIDMDone=false;
      for(var extcheck of this.extChecks) {
        if (extcheck.type=='g2') {
          if (extcheck.response.complete)
            isG2Done=true;
        }
        if (extcheck.type=='idm') {
          if (extcheck.response.res=='ACCEPT')
            isIDMDone=true;
        }
      }
      return isG2Done && isIDMDone;
    },
    isRiskDone() {
      return !!this.riskList.length;
    },
    isBoarding() {
      return false;
    },
    isSignedApp() {
      return !!this.$parent.signedApplications.length
    },
    isPrecheckDone() {
      return !!(this.prechecks && this.prechecks.length)
    },
    isWebsiteCheckDone() {
      return !!(this.weblist && this.weblist.length)
    },
    isAgreementDone() {
      return !!this.$parent.agreements.length
    },
    isAgreementSigned() {
      for(var agreement of this.$parent.agreements) {
        if (agreement.signed)
          return true
        break
      }
    },
    isApproved() {
      return this.$parent.item.status=="A04";
    },
  },
  methods: {
    async setSecondaryPrecheck(mode) {
      var newRequirements=JSON.parse(JSON.stringify(this.item.requirements));
      newRequirements.secondaryPrecheckMode=mode;
      await api.update("Application", this.item.id, {requirements: newRequirements});
      this.item.requirements && (this.item.requirements.secondaryPrecheckMode=mode);
      if (mode=='skip') {
        this.secondaryPrecheck=true;
      } else if (mode=='run') {
        this.secondaryPrecheck=true;
        this.secondaryPrecheck2=true;
        this.runPrecheck();
      }
    },
    setSettings() {
      appProfilePopup.show(this.item);
    },
    startUnderwriting() {
      appUnderwritingPopup.show(this.item);
    },
    createAgreement() {
      this.$parent.startAgreement();
    },
    async doBoarding() {
      if (!this.merchant)
        return;
      var id=(await api.create("MerchantSetup", {
        merchant: this.merchant.id,
        app: this.item.id,
        created: "CURRENT_TIMESTAMP",
        buildId: "APP_ID_GEN_MERCHANTBUILD",
        setupData: {accountId: this.item.accountId, cardPresent: this.item.merchantType=='POS', cardNotPresent: this.item.merchantType=='E-Commerce'},
      })).id;
      window.open("implementation/merchantsetup/"+id, "_self");
    },
    uploadSignedApplication() {
      this.$parent.uploadFile(this.$parent.signedApplicationField)
    },
    startWebsiteCheck() {
      this.$parent.actionsTab=3;
      this.$parent.listWebsiteChecks();
    },
    runPrecheck() {
      this.$parent.actionsTab=3;
      this.$parent.listPreChecks();
    },
    signAgreement() {
      for(var agreement of this.$parent.agreements) {
        this.$parent.uploadSigned(agreement, 0)
        break
      }
    },
    doFinalApproval() {
      this.$parent.doAction(this.$parent.item, "A04");
    },
    runRisk() {
      this.$parent.actionsTab=3;
      this.$parent.uwTab=4;
    },
    async setStatus() {
      this.$parent.doAction(this.$parent.item, "A03");
    },
    setIntake() {
      location.href=location.href+"/profile";
    },
    async loadRisk() {
      this.riskList=(await api.list("ApplicationRiskCalc", {applicationId: this.item.id}, ["id:DESC"])).items;
    },
    async loadExtchecks() {
      this.extChecks=(await api.list("ExtCheck", {applicationId: this.item.id}, ["id:DESC"])).items || [];

    },
  },
  mounted() {
    this.secondaryPrecheck=this.item.requirements.secondaryPrecheckMode=='skip' ||
                           this.item.requirements.secondaryPrecheckMode=='run';
    this.secondaryPrecheck2=this.item.requirements.secondaryPrecheckMode=='run';
    this.loadRisk();
    this.loadExtchecks();
  },
  
}
</script>


