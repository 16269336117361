<template>
<form class="form-step">
  <section class="last-step">
    <figure class="last-step-head">
      <h2>Uploads</h2>
      <div class="note">Please use the section below to upload documents:</div>
    </figure>
    <div class="page-7-header">
    <p class="page-7-right-text">Only the following file types are accepted:<br>jpg, jpeg, gif, tif, tiff, pdf</p>
    <h2>Required Documents</h2>
    </div>
    <div class="clearfx"></div>
    <validator v-if="false" :value="files.application" :reqs="app.requirements.merchantApplication?'required':''">
      <checkbox-file :basepath="basepath" v-model="files.application">
        Completed & Signed Merchant Application
        <template v-slot:more>Incomplete, handwritten or unsigned applications will not be accepted</template>
      </checkbox-file>
    </validator>
    <validator :value="files.pParent" reqs="required" v-if="app.hasParent==='1'">
      <checkbox-file :basepath="basepath" v-model="files.pParent">
        Parent company incorporation documents 
        <template v-slot:more>Please combine into 1 file</template>
      </checkbox-file>
    </validator>
    <validator :value="files.incor" :reqs="app.requirements.incorporationDocuments?'required':''">
      <checkbox-file :basepath="basepath" v-model="files.incor">
        {{ app.companyName }} Incorporation Documents 
        <template v-slot:more>Please combine into 1 file, must include certificate of incorporation, memorandum and articles of association, certificates listing all current beneficial owners, directors and shareholders</template>
      </checkbox-file>
    </validator>
    <template v-if="app.ubos.length>1">
      <template v-for="(ubo, i) in app.ubos">
        <validator :value="`files.passport${i+1}`" :reqs="app.requirements.passportCopy?'required':''">
          <checkbox-file :basepath="basepath" v-model="files[`passport${i+1}`]">
            <span>{{ app.ubos[i].firstName }} {{ app.ubos[i].lastName }}&nbsp;
            <select type="plain" v-on:click.stop="" v-model="app.extraData['passport'+(i+1)]">
              <option value="Passport">Passport</option>
              <option value="Driver's license">Driver's license</option>
              <option value="Personal ID">Personal ID</option>
            </select>
            &nbsp;Copy</span>
            <template v-slot:more>Passport copy for all beneficial owners, directors and authorized signatories</template>
          </checkbox-file>
        </validator>
      </template>
    </template>
    <template v-else>
      <validator :value="files.passport" :reqs="app.requirements.passportCopy?'required':''">
        <checkbox-file :basepath="basepath" v-model="files.passport">
          <span>{{ app.ubos[0].firstName }} {{ app.ubos[0].lastName }}&nbsp;
          <select type="plain" v-on:click.stop="" v-model="app.extraData['passport']">
            <option value="Passport">Passport</option>
            <option value="Driver's license">Driver's license</option>
            <option value="Personal ID">Personal ID</option>
          </select>
          &nbsp;Copy</span>
          <template v-slot:more>Passport copy for all beneficial owners, directors and authorized signatories</template>
        </checkbox-file>
      </validator>
    </template>
   
    <validator :value="files.utility" :reqs="app.requirements.utilityBill?'required':''">
      <checkbox-file :basepath="basepath" v-model="files.utility">
        {{ app.companyName }} Recent Utility Bill
        <template v-slot:more>Utility bill for all beneficial owners, directors and authorized signatories.  No older than 60 days.</template>
      </checkbox-file>
    </validator>
    <validator :value="files.statement" :reqs="app.requirements.bankStatement?'required':''">
      <checkbox-file :basepath="basepath" v-model="files.statement">
        {{ app.companyName }} 3 Months Company Bank Statement
        <template v-slot:more>3 Months company bank statement clearly showing company name and address</template>
      </checkbox-file>
    </validator>
    <validator :value="files.voidCheck" :reqs="app.requirements.voidCheck?'required':''">
      <checkbox-file :basepath="basepath" v-model="files.voidCheck">
        {{ app.companyName }} Void Check
        <template v-slot:more>Must be in company name</template>
      </checkbox-file>
    </validator>
  </section>
  <section class="last-step">
    <h2>Conditional Documents</h2>
    <validator :value="files.processing" :reqs="app.requirements.processingStatement?'required':''">
      <checkbox-file :basepath="basepath" v-model="files.processing">
        6 Months Processing Statements
        <template v-slot:more>6 most recent processing statements for company</template>
      </checkbox-file>
    </validator>
    <validator :value="files.govId" :reqs="app.requirements.govId?'required':''">
      <checkbox-file :basepath="basepath" v-model="files.govId">
        Government Issued ID
        <template v-slot:more></template>
      </checkbox-file>
    </validator>
    <validator :value="files.structure" :reqs="app.requirements.corporateStructure?'required':''">
      <checkbox-file :basepath="basepath" v-model="files.structure">
        Corporate Structure Diagram
        <template v-slot:more>Show parent and related companies and beneficial owners, directors and shareholders</template>
      </checkbox-file>
    </validator>
    <validator :value="files.financial" :reqs="app.requirements.financialStatements?'required':''">
      <checkbox-file :basepath="basepath" v-model="files.financial">
        Financial Statements
        <template v-slot:more>Most recent financial statements for company</template>
      </checkbox-file>
    </validator>
    <validator :value="files.licenses" :reqs="app.requirements.copyOfLicense?'required':''">
      <checkbox-file :basepath="basepath" v-model="files.licenses">
        Copy of License(s)
        <template v-slot:more>Provide if is a regulated business type</template>
      </checkbox-file>
    </validator>
    <validator :value="files.pciDss" :reqs="app.requirements.pciDssCert?'required':''">
      <checkbox-file :basepath="basepath" v-model="files.pciDss">
        PCI DSS Certificate or Adherence
        <template v-slot:more>PCI DSS compliance certification</template>
      </checkbox-file>
    </validator>
    <validator :value="files.padAuth" :reqs="app.requirements.padAuth?'required':''">
      <checkbox-file :basepath="basepath" v-model="files.padAuth">
        Sample PAD Authorization
        <template v-slot:more></template>
      </checkbox-file>
    </validator>
    <!--<validator :value="files.motoQuestionnaire" :reqs="app.requirements.motoQuestionnaire?'required':''">
      <checkbox-file :basepath="basepath" v-model="files.motoQuestionnaire">
        Completed MOTO/Internet Questionnaire
        <template v-slot:more></template>
      </checkbox-file>
    </validator>-->
    <validator :value="files.marketingMaterials" :reqs="app.requirements.marketingMaterials?'required':''">
      <checkbox-file :basepath="basepath" v-model="files.marketingMaterials">
        Marketing Materials if no Website
        <template v-slot:more></template>
      </checkbox-file>
    </validator>
    <validator :value="files.businessSummary" :reqs="app.requirements.businessSummary?'required':''">
      <checkbox-file :basepath="basepath" v-model="files.businessSummary">
        Complete Business Summary
        <template v-slot:more></template>
      </checkbox-file>
    </validator>
    <validator :value="files.personalGuarantee" :reqs="app.requirements.personalGuarantee?'required':''">
      <checkbox-file :basepath="basepath" v-model="files.personalGuarantee">
        Personal Guarantee
        <template v-slot:more></template>
      </checkbox-file>
    </validator>
    <validator reqs="">
      <div class="element-checkbox-line">
        <label :class="{checked: questionnaireCompleted}"><span></span>
        </label>
        <div class="title" v-on:click.prevent="more=!more">
          MOTO/Internet/Future Services Questionnaire
          <div class="file-obj">
            <a>{{ questionnaireCompleted?"Questionnaire.pdf":"(no file)" }}</a>
          </div>
          <div class="more" v-show="more">
          </div>
        </div>
        <div class="toggleMore" v-on:click.prevent="">
          <a href=""></a>
        </div>
      </div>
    </validator>
  </section>
  <section class="last-step" v-if="app.companyNew">
    <h2>New Business</h2>
    <validator :value="files.plan" :reqs="app.requirements.businessPlan?'required':''">
      <checkbox-file :basepath="basepath" v-model="files.plan">
        Business Plan with Projections
        <template v-slot:more>Provide business plan with projections </template>
      </checkbox-file>
    </validator>
    <validator :value="files.bankStatement" :reqs="app.requirements.bankStatement2?'required':''">
      <checkbox-file :basepath="basepath" v-model="files.bankStatement">
        Bank Statement or Signed Bank Letter
        <template v-slot:more>Provide bank statement for all beneficial owners, directors and shareholders</template>
      </checkbox-file>
    </validator>
    <validator :value="files.businessSummary2" :reqs="app.requirements.businessSummary2?'required':''">
      <checkbox-file :basepath="basepath" v-model="files.businessSummary2">
        Complete Business Summary
        <template v-slot:more></template>
      </checkbox-file>
    </validator>
  </section>
  
  
  
  <section class="note-title"><h2>Notes</h2></section>
  <ul class="notes">
    <li>For assistance, please contact {{companydata.applicationEmail}} or call {{companydata.servicesTel}}</li>
    <li>Additional requirements may apply</li>
    <li>Ensure that all above requested items are complete when submitted</li>
    <li>Please submit/upload all requested items on the last page of application.</li>
    <li>By completing this form, you agree that your personal data may be shared with third parties for on boarding and Know-Your-Customer purposes.</li>
    <li>By submitting this form you confirm that all information provided is accurate, complete and truthful and you consent to credit and information verification checks being performed.</li>
  </ul>
  <div class="buttons">
    <a v-if="!simulation" class="btn btn-large btn-plain btn-back" v-on:click.prevent="$emit('prev')">Back</a>
    <a v-else class="btn btn-large btn-plain btn-back" v-on:click.prevent="$emit('reset')">Reset</a>
    <a v-if="!simulation" class="btn btn-large btn-warn" v-on:click.prevent="$emit('save')">Save</a>
    <div v-if="simulation"></div>
    <a v-if="!simulation" class="btn btn-large btn-success" v-on:click.prevent="nextStep">Submit</a>
    <a v-else class="btn btn-large btn-success" v-on:click.prevent="$emit('next')">Submit</a>
  </div>  
</form>
</template>


<script>
export default {
  props: ["app", "files", "basepath", "simulation"],
  data() { return {
    companydata: appData.company,
  }},
  computed: {
    questionnaireCompleted() {
      if (this.app.hasWebsite)
        return true;
      if (this.app.methodECommerce || this.app.methodMOTO)
        return true;
      if (this.app.deliveryTime2 || this.app.deliveryTime3 || this.app.deliveryTime4)
        return true;
      return false;
    },
  },
  methods: {
    nextStep() {
      if (!this.validate())
        return stopLoading("Please fill all required fields", "error");
      this.$emit("next");      
    },
  },
  watch: {
    "app.extraData": {
      handler() {
        this.app.extraData=this.app.extraData || {};
      }, immediate: true
    },
  },
  mixins: [formValidation.mixin]
}
</script>
