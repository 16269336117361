<template>
<div>
<!--   <employees-menu /> -->
  
  <div class="ncontent nc-flat custom-width">
    <div class="ncontent touch-it">
      <div class="merchant-panel">
        <div class="data-content">
          <ul class="tabs">
            <li v-for="(tab, idx) in tabs" :class="{ active: activeTab == idx }">{{ tab }}</li>
          </ul>
        </div>
      </div>

      <div v-if="activeTab === 0" class="custom-form pad20">
        <div class="input-group">
          <label>First Name</label>
          <validator :value="form.first_name" reqs="required">
            <input type="text" v-model="form.first_name" @keypress="allowLetterNumber($event, { numbers: false })">
          </validator>
        </div>

        <div class="input-group">
          <label>Last Name</label>
          <validator :value="form.last_name" reqs="required">
            <input type="text" v-model="form.last_name" @keypress="allowLetterNumber($event, { numbers: false })">
          </validator>
        </div>
        <div class="input-group">
          <label>Email</label>
          <validator :value="form.email" reqs="required email">
            <input type="text" v-model="form.email">
          </validator>
        </div>
        <div class="input-group">
          <label>Phone</label>
          <validator :value="form.phone" reqs="required">
            <input type="text" v-model="form.phone" class="">
          </validator>
        </div>
        <div class="input-group">
          <label>Username</label>
          <validator :value="form.username" reqs="required">
            <input type="text" v-model="form.username" @keypress="allowLetterNumber($event, { numbers: true })">
          </validator>
        </div>
        <div class="input-group">
          &nbsp;
        </div>
      </div>
      <div v-if="activeTab === 0" class="custom-form pad20">
      </div>
      <div class="buttons clear-after">
        <button v-if="activeTab === 0" class="btn btn-large btn-primary floatr no-margin" @click="save()">FINALIZE</button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  mixins: [ vueMixins.helpers, formValidation.mixin ],

  data () {
    return {
      activeTab: 0,
      tabs: ['Profile'],
      salesBrand: [],
      companies: null,
      groups: window.userGroups,
      titleGroups: window.employeeRoles,
      form: {},
      uniqueEmail: null,
      postalRule: {
        numbers: true,
        allow: [45]
      }
    }
  },

  computed: {
    portalOptions () {
      return window.employeePortals
    },
  },

  created () {
    this.initForm()
    api.list("SalesBrand").then((data) => {
      this.salesBrand = data.items
    })
    api.get("Solution", 1).then((data) => {
      this.companies = data.item
    })
  },

  methods: {
    async save () {
      if (!this.validate()) return stopLoading('Please fill all required fields', 'error')
      startLoading()
      const userId = (await this.createUser()).id
      
      await api.action("User", userId, "WelcomeEmailPassword")
      await this.addNote('Welcome email sent', userId)

      
      
      this.initForm()
      this.validateReset()
      stopLoading("Saved")
      setTimeout(()=>location.href="/your-profile/"+appData.currentUser.username, 3000);
    },

    async createUser () {
      let payload = {
        active: "Yes",
        parent: appData.currentUser.id,
        agentId: appData.currentUser.agentId,
        generateSubUserId: true,
        username: this.form.username,
        fullName: this.form.first_name,
        lastName: this.form.last_name,
        email: this.form.email,
        title: this.form.title,
        groups: this.form.role,
        phone: this.form.phone,
        phoneExt: this.form.phone_ext,
        passChange: true,
        skipPasswordEmail: true,
      }
      var user = (await api.create("User", payload) );

      return user
    },

    addNote (message, userId) {
      return api.create("Note", {
        message,
        parentId: userId,
        type: 'User',
        created: "CURRENT_TIMESTAMP",
        author: appData.currentUser.id,
        system: 1
      })
    },

    formatName (name) {
      return name.replace(/\s/g, '.').toLowerCase()
    },

    async prevNext (next = true) {
      startLoading()
      if (this.activeTab === 0 && next && this.autogeneratedEmail) {
        const response = await api.list('Employee', { email: this.autogeneratedEmail })
        this.uniqueEmail = response.items.length ? false : this.autogeneratedEmail
      }
      this.$nextTick(() => {
        if (next && !!this.validate()) {
          this.validateReset()
          this.activeTab++
        }
        if (!next) this.activeTab--
        if (!this.uniqueEmail && this.autogeneratedEmail) stopLoading('This email already exists.', 'error')
        else stopLoading()
      })
    },

    initForm () {
      this.activeTab = 0
      this.$set(this, 'form', {
        username: null,
        first_name: null,
        email_first_name: null,
        last_name: null,
        title: null,
        companyId: 1,
        salesBrandId: null,
        email: null,
        phone: null,
        phone_ext: null,
        role: null,
        address: null,
        city: null,
        province: null,
        postal: null,
        country: null,
        ec_first_name: null,
        ec_last_name: null,
        ec_phone: null,
        ec_email: null,
        portal: null
      })
    }
  },
}
</script>
