<template>
  <div>
    <p class="result-count">
      Your search returned <span>{{ results }}</span> result(s).
    </p>
    <ul class="acc-results">
      <li
        v-for="(status, si) in perStatus"
        :class="{ active: currentIndex == si }"
      >
        <h4 v-on:click="selectIndex(si)">
          <span class="label">{{ status.description }}</span>
          <span class="count">{{ status.items.length }} Application(s)</span>
        </h4>
        <div class="acc-list">
          <table>
            <thead>
              <tr>
                <th
                  v-for="(columnLabel, columnName) in columnsToShow[
                    status.status
                  ]"
                >
                  {{ columnLabel }}
                </th>
                <th
                  v-if="
                    !noActions &&
                    !followup &&
                    !negative &&
                    (status.status == 'A02' ||
                      status.status == 'A03' ||
                      status.status == 'A04' ||
                      status.status == 'M00')
                  "
                >
                  Timer
                </th>
                <th
                  v-else-if="
                    !followup && !negative && status.status.indexOf('M') == 0
                  "
                >
                  Services
                </th>
                <th v-if="!noActions">Options</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in status.items">
                <td
                  v-for="(columnLabel, columnName) in columnsToShow[
                    status.status
                  ]"
                >
                  <a
                    v-if="fieldHref(item, columnName)"
                    :href="fieldHref(item, columnName)"
                    :title="showTitle(item, columnName)"
                  >
                    {{ showField(item, columnName) }}
                  </a>
                  <template v-else>
                    {{ showField(item, columnName) }}
                  </template>
                </td>
                <td
                  v-if="
                    !followup &&
                    !negative &&
                    (status.status == 'A02' || status.status == 'A03')
                  "
                  :style="{
                    'background-color': agoColor(item.submitDate, now),
                  }"
                >
                  {{ agoTimer(item.submitDate, now) }}
                </td>
                <td
                  v-else-if="
                    !noActions &&
                    !followup &&
                    !negative &&
                    (status.status == 'A04' || status.status == 'M00')
                  "
                  :style="{
                    'background-color': agoColor(
                      item.approveDate || item.submitDate,
                      now
                    ),
                  }"
                >
                  {{ agoTimer(item.approveDate || item.submitDate, now) }}
                </td>
                <td
                  v-else-if="
                    !followup && !negative && status.status.indexOf('M') == 0
                  "
                >
                  &nbsp;
                </td>
                <td v-if="!noActions">
                  <template v-if="mode != 'intake'">
                    <a
                      href=""
                      v-if="followup && item.followup"
                      v-on:click.prevent="doAction(item, 'removeFup')"
                      >Remove</a
                    >
                    <a
                      href=""
                      v-else-if="!negative && !item.followup"
                      v-on:click.prevent="doAction(item, 'followUp')"
                      >Follow-up</a
                    >
                    <a
                      href=""
                      v-else-if="negative"
                      v-on:click.prevent="doAction(item, 'removeNeg')"
                      >Remove</a
                    >
                  </template>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ["followup", "negative", "mode"],
  data() {
    return {
      items: [],
      statuses: [
        {
          id: "3",
          status: "A02",
          category: "Merchant Application",
          description: "Merchant Applications",
        },
        {
          id: "4",
          status: "A99",
          category: "Merchant Application",
          description: "Saved Applications",
        },
      ],
      columns: {},
      options: {},
      actions: {},
      currentIndex: null,
      search: {},
      now: 0,
      noLinks: appData.noLinks || false,
      noActions: appData.noActions || false,
    };
  },
  computed: {
    results() {
      var total = 0;
      for (var st in this.perStatus) total += this.perStatus[st].items.length;
      return total;
    },
    perStatus() {
      return this.statuses.map((a) => ({
        description: a.description,
        status: a.status,
        items: this.sortItems.filter((i) => {
          if (this.negative) return true;
          if (a.status == "A99") return i.status == "A99";
          else return i.status != "A99";
        }),
        actions:
          this.negative || this.followup ? {} : this.actions[a.status] || {},
      }));
    },
    columnsToShow() {
      var r = {};
      for (var status of this.statuses) {
        var result = {};
        for (var columnName in this.columns) {
          var val = this.columns[columnName];
          if (typeof val == "object") {
            if (val[1].indexOf(status.status) == -1) continue;
            val = val[0];
          }
          if (status.status == "A99") {
            if (
              columnName == "submitDate" ||
              columnName == "accountId" ||
              columnName == "followup"
            )
              continue;
          } else if (status.status == "PENDING") {
            if (
              columnName == "submitDate" ||
              columnName == "createdDate" ||
              columnName == "applicationId"
            )
              continue;
          } else if (status.status == "FOLLOWUP") {
            if (
              columnName == "submitDate" ||
              columnName == "createdDate" ||
              columnName == "applicationId"
            )
              continue;
          } else {
            if (
              columnName == "createdDate" ||
              columnName == "applicationId" ||
              columnName == "followup"
            )
              continue;
          }
          result[columnName] = val;
        }
        r[status.status] = result;
      }
      return r;
    },

    sortItems() {
      const data = this.items || [];
      return data.sort((a, b) => {
        if (a.status > b.status) return 1;
        if (a.status < b.status) return -1;
        return 0;
      });
    },
  },
  methods: {
    isMerchant(item) {
      return (item.status || "").startsWith("M");
    },
    fieldHref(item, columnName) {
      if (columnName == "applicationId") {
        if (item.type == "Application") {
          if (this.noLinks) {
            return `applications/${item.id}`;
          } else {
            return `applications/saved/${item.id}`;
          }
        }
        if (item.type == "Prequalification") {
          return `applications/preq/${item.id}`;
        }
      }
      if (
        columnName == "accountId" ||
        columnName == "businessProfile.profileNumber"
      ) {
        if (item.type == "Application") {
          return `applications/${item.id}`;
        }
        if (item.type == "Prequalification") {
          return `applications/preq/${item.id}`;
        }
      }
      return null;
    },
    showField(item, columnName, origItem) {
      origItem = origItem || item;
      columnName = columnName.split(".");
      var firstPart = columnName.shift();
      var value = item[firstPart];
      if (!value || !columnName.length) {
        if (
          (value + "").match(
            /[0-9]{4}-[0-9]{2}-[0-9]{2} [0-9]{2}:[0-9]{2}:[0-9]{2}/
          )
        ) {
          return this.formatDate(value, "YYYY-MM-DD");
        }
        if (firstPart == "accountId" && !value) value = item.id;
        if (firstPart == "profileNumber" && !value) value = origItem.accountId;
        return value;
      }
      return this.showField(value, columnName.join("."), origItem);
    },
    showTitle(item, columnName) {
      columnName = columnName.split(".");
      var firstPart = columnName.shift();
      var value = item[firstPart];
      if (!value || !columnName.length) {
        if (firstPart == "status" && window.appData.allStatuses) {
          var status = window.appData.allStatuses.find(
            (a) => a.status == value
          );
          if (status) return status.description;
        }
        return null;
      }
      return this.showTitle(value, columnName.join("."));
    },
    selectIndex(index) {
      if (this.currentIndex == index) {
        this.currentIndex = null;
        $(this.$el).find("li.active .acc-list").slideUp(300);
      } else {
        this.currentIndex = index;
        $(this.$el).find("li.active .acc-list").slideUp(300);
        $(this.$el).find("li").eq(index).find(".acc-list").slideDown(300);
      }
    },
    doAction: async function (item, targetMethod) {
      startLoading();
      if (targetMethod == "intake") {
        stopLoading();
        location.href = "/applications/" + item.id + "/intake";
      } else if (targetMethod == "intakeview") {
        stopLoading();
        window.open("/applications/" + item.id + "/intakepdf", "_blank");
      } else if (targetMethod == "negative") {
        await api.create("NegativeList", { application: item.id });
        stopLoading("Added to negative list");
      } else if (targetMethod == "removeNeg") {
        await api.delete("NegativeList", item.id);
        var index = this.sortItems.indexOf(item);
        if (index > -1) this.sortItems.splice(index, 1);
        stopLoading("Removed from negative list");
      } else if (targetMethod == "followUp") {
        await api.update(item.type, item.id, { followup: "CURRENT_TIMESTAMP" });
        item.followup = true;
        stopLoading("Added to follow-up");
      } else if (targetMethod == "removeFup") {
        if (item.type == "Agent") item.id = item.agentDb.id;
        await api.update(item.type, item.id, { followup: null });
        item.followup = null;
        if (this.followup) {
          var index = this.sortItems.indexOf(item);
          if (index > -1) this.sortItems.splice(index, 1);
        }
        stopLoading("Removed from follow-up");
      } else {
        var update = { status: targetMethod };
        if (targetMethod == "A05" && item.status == "A04") {
          if (
            !confirm(
              "Are you sure you want to delete all related merchant data?"
            )
          ) {
            stopLoading();
            return;
          }
          this.deleteRelatedMerchant(item);
        }
        if (targetMethod == "A04" || targetMethod == "M00")
          update.approveDate = "CURRENT_TIMESTAMP";
        await api.update(item.type, item.id, update);
        item.status = targetMethod;
        stopLoading("Status updated");
      }
    },
    deleteRelatedMerchant: async function (item) {
      var merchants = (await api.list("Merchant", { app: item.id })).items;
      for (var i in merchants) api.delete("Merchant", merchants[i].id);
    },
  },
  mounted() {
    window.accordeonResults = this;
    //    this.statuses=JSON.parse(JSON.stringify(appData.statuses));
    this.columns = appData.columns
      ? JSON.parse(JSON.stringify(appData.columns))
      : [];
    //this.options=JSON.parse(JSON.stringify(appData.options));
    this.actions = appData.actions
      ? JSON.parse(JSON.stringify(appData.actions))
      : [];
    if (this.followup || this.negative)
      setTimeout(() => {
        this.selectIndex(0);
      }, 500);
    setInterval(() => {
      this.now++;
    }, 1000);
  },
  mixins: [vueMixins.dateFormatter],
};
</script>
