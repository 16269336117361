<template>
<div>
  <div class="amadel-logo-wrapper">
    <page-header :title="page.title" :breadcrumbs="breadcrumbs" />
  </div>

  <sub-menu
    :dashboard="{ to: '/agents/amadel', text:'Amadel Dashboard' }"
    :menu="menus" />
</div>
</template>

<script>
export default {
  props: {
    page: {
      type: Object,
      default: null
    }
  },

  data: ()=>({
      menu: [
        {
          text: 'Prospects',
          list: [
            {
              text: 'Leads',
              to: '/agents/leads-overview'
            }
          ]
        },
        {
          text: 'Calendar',
          list: [
            {
              text: 'View',
              to: '/calendar'
            },
            {
              text: 'Add New Event',
              to: '/calendar/add-event'
            },
            {
              text: 'Task List',
              to: '/amadel/task-list'
            }
          ]
        },
        {
          text: 'Settings',
          list: [
            {
              text: 'Filters',
              to: '/'
            },
            {
              text: 'Setting 2',
              to: '/'
            },
            {
              text: 'Setting 3',
              to: '/'
            }
          ]
        },
        {
          text: 'Tools',
          list: [
            {
              text: 'Tool 1',
              to: '/'
            },
            {
              text: 'Tool 2',
              to: '/'
            },
            {
              text: 'Tool 3',
              to: '/'
            }
          ]
        },

      ]
  }),

  computed: {
    breadcrumbs () {
      const breadcrumbs =  [{
        text: 'Amadel',
        to: '/agents/amadel'
      }]

      if (this.page.breadcrumbs) {
        breadcrumbs.push(...this.page.breadcrumbs)
      }

      breadcrumbs.push({
        text: this.page.text
      })
      
      return breadcrumbs
    },

    menus () {
      if(window.appData.loginMode == 'partners') {
        return this.menu
      }else{
        this.menu.push({
          text: 'Management',
          list: [
            {
              text: 'Lead Manager',
              to: '/agents/lead-manager'
            },
            {
              text: 'Calendar Manager',
              to: '/'
            },
            {
              text: 'Appointment Manager',
              to: '/'
            },
            {
              text: 'Billing Manager',
              to: '/'
            },
            {
              text: 'New Lead',
              to: '/agents/new-lead'
            },
            {
              text: 'Data Transfer',
              to: '/agents/data-transfer'
            },
            {
              text: 'Data Sources',
              to: '/agents/data-sources'
            }
          ]
        })
        return this.menu
      }
    }
  }
}
</script>
