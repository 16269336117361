<template>
<div v-if="lead">
  <popup v-model="showPreview" @close="$emit('close')">
    <div class="doc-title">LEAD DETAILS</div>
    <div class="title">
      Lead ID: {{ formatLeadId(lead.id) }}<br>
      <small>Submitted by: {{ lead.SourceID.name }}, {{ lead.SourceID.sourceId }}, {{ formatDate(lead.created, 'LLLL') }}</small><br><br>
      
      <button v-if="editMode" class="btn btn-xs" @click="edit()">Save</button>
      <button class="btn btn-xs" @click="editMode = !editMode">{{ !editMode ? 'Edit' : 'Cancel' }}</button>
    </div>
    <div class="tables">
      Date Received: {{ formatDate(lead.created, 'MM/DD/YYYY') }}
      <div
        v-for="(table, idx) in tables"
        :key="idx"
        class="table-wrapper">
        <table class="mt-20">
          <thead>
            <tr>
              <th v-for="(item, key) in table" :key="key">{{ key }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td v-for="(value, key) in table" :key="key">
                <span v-if="!showInputs(key)">
                  {{ value || 'N/A' }}
                </span>
                
                <template v-else>
                  <validator
                    v-if="hasValidation(key)"
                    :value="form[key]"
                    :reqs="hasValidation(key)">
                    <select
                      v-if="slecectOptions(key)"
                      v-model="form[key]">
                      <option v-for="(text, key) in slecectOptions(key)" :key="key" :value="key">{{ text }}</option>
                    </select>
                    <input
                      v-else
                      v-model="form[key]"
                      type="text"
                      :maxlength="getMaxLength(key)"
                      @keypress="onInput($event, key)">
                  </validator>
                  <template v-else>
                    <select
                      v-if="slecectOptions(key)"
                      v-model="form[key]">
                      <option v-for="(text, key) in slecectOptions(key)" :key="key" :value="key">{{ text }}</option>
                    </select>
                    <input
                      v-else
                      v-model="form[key]"
                      :maxlength="getMaxLength(key)"
                      @keypress="onInput($event, key)"
                      type="text">
                  </template>
                </template>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="table-actions">
        <div class="mb-5">
          <span>Options:</span>
          <template v-if="!hideAssignUnassign">
            <a
              v-if="isUnassigned()"
              class="ta-btn"
              @click="$emit('assign')">
              Assign
            </a>
            <a
              v-if="isAssigned()"
              class="ta-btn"
              @click="$emit('unassign')">
              Unassign
            </a>
          </template>
        </div>
        <template v-if="lead.assignedTo">
          <div v-for="(statuses, statusName) in availableStatuses" :key="statusName">
            <span>{{ statusName }}:</span>
            <button
              v-for="status in statuses"
              :key="status"
              class="btn btn-xxs mr-5"
              @click="setStatus(status)">
              {{ getLeadStatus(status) }}
            </button>
          </div>
        </template>
      </div>
    </div>

    <form class="notes preq-form">
      <notes class="appl-notes" :notes="notes" type="Lead" :id="lead.id" />
    </form>
  </popup>
</div>
</template>

<script>
export default {
  mixins: [ vueMixins.dateFormatter, formValidation.mixin, vueMixins.helpers, vueMixins.amadelLeadMixin ],

  props: {
    lead: {
      type: Object,
      default: null
    },

    hideAssignUnassign: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      notes: [],
      editMode: false,
      form: {}
    }
  },

  computed: {
    tables () {
      return [
        {
          SourceID: this.lead.SourceID.sourceId,
          LeadType: this.lead.LeadType,
          RecordId: this.lead.RecordId,
          MethodType: this.lead.MethodType,
          LeadQualDate: this.formatDate(this.lead.ContactDate, 'MM/DD/YYYY'),
          BusinessIndustry: this.lead.BusinessIndustry
        },
        {
          BusinessName: this.lead.BusinessName,
          BusinessDBA: this.lead.BusinessDBA,
          BusinessType: this.lead.BusinessType,
          BusinessPhone: this.lead.BusinessPhone,
          BusinessEmail: this.lead.BusinessEmail,
          BusinessWebite: this.lead.BusinessWebite,
          BusinessDescription: this.lead.BusinessDescription
        },
        {
          BusinessAddress: this.lead.BusinessAddress,
          BusinessCity: this.lead.BusinessCity,
          BusinessProvState: this.lead.BusinessProvState,
          BusinessPostalZip: this.lead.BusinessPostalZip,
          BusinessCountry: this.lead.BusinessCountry
        },
        {
          Contact1FirstName: this.lead.Contact1FirstName,
          Contact1LastName: this.lead.Contact1LastName,
          Contact1Email: this.lead.Contact1Email,
          Contact1Phone: this.lead.Contact1Phone,
          Contact2FirstName: this.lead.Contact2FirstName,
          Contact2LastName: this.lead.Contact2LastName,
          Contact2Email: this.lead.Contact2Email,
          Contact2Phone: this.lead.Contact2Phone
        },
        {
          VolMonthly: this.lead.VolMonthly,
          Vol12Months: this.lead.Vol12Months,
          HighTicket: this.lead.HighTicket,
          AvgTicket: this.lead.AvgTicket,
          LowTicket: this.lead.LowTicket,
          CurrentlyAccept: this.lead.CurrentlyAccept,
          EverAccept: this.lead.EverAccept,
          TransCurr: this.lead.TransCurr
        }
      ]
    },

    availableStatuses () {
      return {
        Assigned: ['AMA11', 'AMA12', 'AMA13'],
        Completed: ['AMA20', 'AMA21', 'AMA22', 'AMA23', 'AMA24'],
        Incomplete: ['AMA30', 'AMA31'],
        Other: ['AMA40', 'AMA41', 'AMA42', 'AMA43', 'AMA44'],
      }
    },

    showPreview () {
      return !!this.lead
    }
  },

  watch: {
    lead (lead) {
      if (lead)  {
        this.loadNotes()

        const fields = ['MethodType', 'BusinessIndustry', 'BusinessName', 'BusinessDBA', 'BusinessType', 'BusinessAddress', 'BusinessCity', 'BusinessProvState', 'BusinessPostalZip', 'BusinessCountry', 'BusinessPhone', 'BusinessEmail', 'BusinessWebite', 'BusinessDescription', 'Contact1FirstName', 'Contact1LastName', 'Contact1Email', 'Contact1Phone', 'Contact2FirstName', 'Contact2LastName', 'Contact2Email', 'Contact2Phone', 'VolMonthly', 'Vol12Months', 'HighTicket', 'AvgTicket', 'LowTicket', 'CurrentlyAccept', 'EverAccept', 'TransCurr']
        fields.forEach(field => {
          this.$set(this.form, field, this.lead[field])
        })
      }
    }
  },

  methods: {
    getLeadStatus (status) {
      return window.leadStatuses[status]
    },

    async setStatus (status) {
      if (confirm(`Are you sure you want to change status to ${status}?`)) {
        startLoading()
        const payload = { status }
        const lead = (await api.update("AmadelLead", this.lead.id, { status }, true)).item
        await this.$parent.createNote(this.lead.id, `Lead Status Changed to ${status} - ${window.leadStatuses[status]}`)
        this.$emit('edit', lead)
        stopLoading()
      }
    },

    async edit () {
      if (!this.validate()) return stopLoading('Validation has failed', 'error')
      startLoading()
      const lead = (await api.update("AmadelLead", this.lead.id, this.form, true)).item
      this.editMode = false
      this.$emit('edit', lead)
      stopLoading()
    },

    showInputs (key) {
      return this.editMode && !['SourceID', 'LeadType', 'RecordId', 'LeadQualDate'].includes(key)
    },

    isUnassigned () {
      return ['AMA00'].includes(this.lead.status)
    },

    isAssigned () {
      return ['AMA10', 'AMA11', 'AMA12', 'AMA13'].includes(this.lead.status)
    },

    async loadNotes () {
      const filter = {
        type: 'Lead',
        parentId: this.lead.id
      }
      this.notes = (await api.list("Note", filter, ['id:ASC'])).items || [];
    }
  },
}
</script>
