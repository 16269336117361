<template>
<div class="sup-cont">
  <ul class="sup-tabs">
    <li :class="{active: tab=='Pending'}">
      <a href="" v-on:click.prevent="changeTab('Pending')">Pending</a>
    </li>
    <li :class="{active: tab=='Open'}">
      <a href="" v-on:click.prevent="changeTab('Open')">Open</a>
    </li>
    <li :class="{active: tab=='Closed'}">
      <a href="" v-on:click.prevent="changeTab('Closed')">Closed</a>
    </li>
    <li :class="{active: tab=='Resolved'}">
      <a href="" v-on:click.prevent="changeTab('Resolved')">Resolved</a>
    </li>
    <li :class="{active: tab === null}">
      <a href="" v-on:click.prevent="changeTab(null)">All</a>
    </li>
    <li :class="{ filters: true, applied: hasAppliedFilters }" @click="openFilters = true">
      <img src="/assets/img/filters.png" />
    </li>
  </ul>
  <ul class="sup-tabbedc">
    <li class="active">
      <div class="sup-table">
        <table>
          <tr>
            <th>Ticket #</th>
            <th>Type</th>
            <th>Account id</th>
            <th>Name</th>
            <th>E-Mail</th>
            <th>Subject</th>
            <th>Status</th>
            <th>&nbsp;</th>
          </tr>
          <template v-for="ticket in filteredTickets">
            <tr v-if="currentTicket !== ticket.id" :key="ticket.id">
              <td><span>{{ ticket.id }}</span></td>
              <td><span>{{ ticket.type }}</span></td>
              <td><span>{{ ticket.user && ticket.user.agentId }}</span></td>
              <td><span>{{ ticket.name }}</span></td>
              <td><span>{{ ticket.email }}</span></td>
              <td><span>{{ subjOptions[ticket.subject] || "Other" }}</span></td>
              <td><span>{{ ticket.status }}</span></td>
              <td><span><a class="table-btn" href="" v-on:click.prevent="showTicket(ticket.id)">View<em v-if="unreadCount(ticket)">{{ unreadCount(ticket) }}</em></a></span></td>
            </tr>
            <support-chat v-else :admin="admin" :ticket="ticket" :key="'ticketChat'+tab+ticket.id"></support-chat>
          </template>
        </table>
      </div>
    </li>
  </ul>

  <support-filters
    v-if="openFilters"
    :filters="filters"
    @close="openFilters = false"
    @filter="updateFilters($event)" />
</div>
</template>

<script>
import SupportFilters from './support-filters'

export default {
  props: ["admin"],

  components: {
    SupportFilters
  },

  data() { return {
    tab: "Pending",
    tickets: [],
    page: 0,
    count: 0,
    currentTicket: null,
    subjOptions: {
      "1": "Subject 1",
      "2": "Subject 2",
      "3": "Subject 3",
      "4": "Subject 4",
      "5": "Subject 5",
      "6": "Other",
    },
    openFilters: false,
    filters: null
  }},

  computed: {
    hasAppliedFilters () {
      return this.filters ? Object.values(this.filters).some(val => !!val) : false
    },

    filteredTickets () {
      let tickets = this.tickets

      // Name and Email are filtered trough frontend since they have multiple sources of data
      // And I don't know how to do WHERE ticker.user.email = x OR ticker.user.companyEmail = x OR ...

      if (this.filters?.name) {
        tickets = tickets.filter(t => t.name?.toLowerCase().includes(this.filters.name.toLowerCase()))
      }

      if (this.filters?.email) {
        tickets = tickets.filter(t => t.email?.toLowerCase().includes(this.filters.email.toLowerCase()))
      }

      return tickets
    }
  },

  mounted() {
    this.update();
    window.supportUserList=this;
  },

  methods: {
    updateFilters (filters) {
      if (filters.status) {
        this.changeTab(filters.status, filters)
      } else {
        this.reset(filters)
        this.update()
      }
    },

    unreadCount (ticket) {
      let lastOpen = this.admin?ticket.adminRead:ticket.userRead || ""
      return ticket.updates.filter(a => a.created > lastOpen).length
    },

    changeTab(tab, filters) {
      this.tab = tab
      this.reset(filters)
      this.update()
    },

    reset (filters) {
      this.filters = filters ? { ...filters } : null
      this.page = 0
      this.count = 0
      this.tickets = []
    },

    showTicket(id) {
      this.currentTicket = id
      const ticket = this.tickets.find(a => a.id == id)
      if (ticket) ticket.adminRead = ticket.userRead = "f"
    },

    setTickets (tickets) {
      this.tickets = tickets.map(ticket => ({
        ...ticket,
        name: ticket.user ? (ticket.user.fullName || ticket.user.companyName || (ticket.user.contacts?.[0] && `${ticket.user.contacts[0].firstName} ${ticket.user.contacts[0].lastName}`)) : null,
        email: ticket.user?.email || ticket.user?.companyEmail || (ticket.user?.agentDb && ticket.user?.agentDb?.email) || null
      }))
      
    },

    async update () {
      if (this.count < this.page * 10) return

      const where = this.tab || this.filters?.status ? { status: this.tab || this.filters?.status } : {}
      
      if (this.hasAppliedFilters) {
        if (this.filters.dateFrom)
          where.createdAtAfter = [">=", this.filters.dateFrom + " 00:00:00", "createdAt"];

        if (this.filters.dateTo)
          where.createdAtBefore = ["<=", this.filters.dateTo + " 23:59:59", "createdAt"];

        if (this.filters.type)
          where.type = this.filters.type

        if (this.filters.accountId)
          where['user.agentId'] = this.filters.accountId
      }

      if (!this.admin) where.userId = window.currentUser.id

      const data = await api.list("SupportTicket", where, ["id:DESC"], 10, this.page*10)
      this.setTickets(data.items)
      this.count = data.count
      this.page++
    },
  },
}
</script>

<style lang="scss" scoped>
  .filters {
    margin-left: auto;
    padding: 0 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &.applied {
      background-color: #3d9645;
    }
  }
</style>