<template>
<div>
  <form ref="form" v-on:submit.prevent="sendSignupEmail">
  <dyn-select required v-model="solution" :options="solutions" v-show="false" />
  <input :disabled="!this.solution" required type="email" v-model="email" placeholder="email">
  <button :disabled="!this.solution">Send</button>
  </form>
</div>
</template>

<script>
export default {
  props: ["type", "solutions", "solution"],
  data() { return {
    email: "",
  }},
  mounted() {
  },
  methods: {
    sendSignupEmail: async function() {
      if (!this.solution)
        return;
      startLoading();
      
      var result=await api.action("TempLink", "new", "email", {type: this.type, email: this.email, solution: this.solution});

      //await api.create("ApplicationEmail", {genLink: 1, emailType: this.type, created: "CURRENT_TIMESTAMP", emailTo: this.email});
      stopLoading("Email sent");
      this.email="";
      this.$refs.form.reset();
    },
  },
}
</script>
