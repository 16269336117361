window.vueMixins = window.vueMixins || {}

window.vueMixins.helpers = {
  methods: {
    getUrl (name) {
      let url = location.href
      name = name.replace(/[\[]/,"\\\[").replace(/[\]]/,"\\\]")
      var regexS = "[\\?&]"+name+"=([^&#]*)"
      var regex = new RegExp( regexS )
      var results = regex.exec( url )
      return results == null ? null : results[1]
    },

    allowLetterNumber (event, { numbers = false, letters = true, allow = [] } = { numbers: false, letters: true, allow: [] }) {
      let key = event.keyCode;
      if ((allow.length && allow.includes(key))
        || (key >= 48 && key <= 57 && numbers)
        || (letters && (key >= 65 && key <= 90 || key >= 96 && key <= 122 || key == 32))
      ) return true
      event.preventDefault();
    },

    changeFileName (file, name) {
      const ext = file.name.substr(file.name.lastIndexOf('.') + 1)
      const blob = file.slice(0, file.size, file.type);
      const fileName = name + '.' + ext
      return new File([blob], fileName, { type: file.type })
    },

    userGroup (group) {
      return window.userGroups[group]
    },

    formatID (type, id) {
      let value = id + ''
      return type + (value.length >= 3 ? value : new Array(3 - value.length + 1).join('0') + value)
    },

    formatLeadId (id) {
      return `100${parseInt(id) + 99}`
    }
  }
}
