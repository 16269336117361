<template>
  <div>
    <p>Documents</p>
    <br/>
    <div class="content-table-container file-list-container">
      <div class="document-actions">
        <button :disabled="item.agentDb.status == 'A04'" v-on:click.prevent="startAgreement">Create Agreement</button>
        <span class="agreement" v-if="agreements.length">
          <a :href="agreementPath(agreements[0])" target="_blank">{{ item.agentDb.name }} agreement</a>
        </span>
        <span v-else class="agreement">
          No agreement created
        </span>
        <button :disabled="item.agentDb.status == 'A04'" v-on:click.prevent="uploadAgreement">Upload Signed Agreement</button>
      </div>
    </div>

    <div class="content-table-container file-list-container">
      <table class="file-list content-table">
        <thead>
          <tr>
            <td>Created</td>
            <td>Description</td>
            <td>Number</td>
            <td>Filename</td>
            <td>Status</td>
            <td>Options</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="document in item.agentDb.documents" v-if="document.status != 'Archived'" :key="document.number">
              <td>{{ formatDate(document.created, "YYYY-MM-DD") }}</td>
              <td>{{ document.description }}</td>
              <td>{{ document.number }}</td>
              <td>
                <a :href="fileLink(document.filename, document.id)" target="_blank">{{ document.filename || "(no file)" }}</a>
              </td>
              <td>{{ document.status }}</td>
              <td>
                <template v-if="document.status == 'Pending'">
                  <span @click="updateOk(document)" class="option-btn">
                    <img src="/assets/img/table-approve.png" />
                  </span>
                  <span @click="update(document)" class="option-btn">
                    <img src="/assets/img/table-deny.png" />
                  </span>
                  <span @click="updateArchived(document)" class="option-btn">
                    DEL
                  </span> 
                </template>
              </td>
            </tr>
        </tbody>
      </table>
    </div>

    <div class="actions">
      <button v-on:click.prevent="goBack">Back</button>
      <button :disabled="disabledStatus" v-on:click.prevent="doNext">Save & Continue</button>
    </div>

    <popup :open.sync="statusPopup">
      <div class="static-page privacy-popup">
        <h2><strong>Update Document Status</strong></h2>
        <br/>
        <div class="content-form">
          <div class="form-row form-fullw">
            <form-field-select :validator="$v.selectedStatus" v-model="selectedStatus" :label="`Select Document Status`" required :options="statusOptions" />
            <p>{{ selectedText }}</p>
          </div>
          <br/>
          <br/>
          <div class="form-row form-split">
            <div>
              <button :disabled="!selectedStatus" @click="submit()">Submit</button>
            </div>
            <div>
              <button @click="statusPopup = false, selectedStatus = null">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </popup>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
export default {
  props: ["item"],
  data: ()=>({
    agentPdfUrl: appData.pdfUrl,
    agreements: [],
    selectedStatus: null,
    statuses: [],
    statusPopup: false,
  }),

  computed: {
    disabledStatus() {
      if (!this.agreements.length)
        return true
      if (!this.agreements[0].signed)
        return true
      for(var i in this.item.agentDb.documents) {
        if(this.item.agentDb.documents[i].status != 'Ok') {
          return true
        }
      }
      return false
    },

    statusOptions() {
      var options = [];
      for(const status of this.statuses)
        options.push(
          {
            id: `${status.status}`,
            text: `${status.status} - ${status.description.substr(0, 50)}`
          }
        )
      return options
    },

    selectedText() {
      return this.statuses.find(item =>  item.status == this.selectedStatus)?.description
    },

    signedApplications() {
      var i=0;
      var result=[];
      var docs={};
      for(var j in this.item.agentDb.documents) {
        docs[this.item.agentDb.documents[j].type]=this.item.agentDb.documents[j];
      }
      while(docs['signedApplication'+(i?i:"")]) {
        result.push(docs['signedApplication'+(i?i:"")]);
        i++;
      }
      return result;
    },
    signedApplicationField() {
      var i=this.signedApplications.length;
      return "signedApplication"+(i?i:"");
    },
  },

  methods: {
    fileLink(fileName, docId) {
      if (!fileName)
        return null;
      if (docId)
        return "download/PartnerDocument/"+docId;
      return "files/agent/"+this.item.agentDb.id+"/"+fileName;
    },
    uploadFile(field=null) {
      if (field)
        this.selectedFile=field;
      var that=this;
      var input=$("<input />", {type: "file"});
      input.on("change", async function(e) {
        var file=this.files[0];
        startLoading();
        if (!window.validateFileExt(file))
          return stopLoading("Invalid file extension", "error");
        var id=(await api.create("PartnerDocument", {agentId: that.item.agentDb.id, type: that.selectedFile, created: "CURRENT_TIMESTAMP"})).id;
        if (id) {
          await api.upload("PartnerDocument", id, file);
          that.item.agentDb.documents=(await api.get("Partner", that.item.agentDb.id)).item.documents;
        }

        stopLoading("File uploaded");

      });
      input.click();
    },
    goBack() {
      this.$emit("back");
    },
    doNext() {
      this.$emit("next");
    },
    async listAgreements() {
      this.agreements=(await api.list("PartnerAgreement", {agentId: this.item.id}, ["id:DESC"])).items || [];
    },
    agreementPath(agreement) {
      return window.appData.agreementPdf.replace("%d", agreement.id);
    },
    startAgreement() {
      agreementPopup.show(this.item);
      agreementPopup.returnLink=location.href.replace(/#.*/, "")+"#boarding/documents";
    },
    uploadSignup() {
      var f=$("<input type=\"file\">");
      f.on("change", async ($event)=>{
        var file=$event.target.files[0];
        startLoading();
        if (!window.validateFileExt(file))
          return stopLoading("Invalid file extension", "error");
        var id=(await api.create("PartnerDocument", {agentId: this.item.agentDb.id, type: this.signedApplicationField, created: "CURRENT_TIMESTAMP"})).id;
        if (id) {
          await api.upload("PartnerDocument", id, file);
          this.item.agentDb.documents=(await api.get("Partner", this.item.agentDb.id)).item.documents;
        }
        stopLoading("File uploaded");
      });
      f.click();
    },
    uploadAgreement($event) {
      var f=$("<input type=\"file\">");
      f.on("change", async ($event)=>{
        var agreement=this.agreements[0];
        var file=$event.target.files[0];
        startLoading();
        if (!window.validateFileExt(file))
          return stopLoading("Invalid file extension", "error");
        
        var id=(await api.create("PartnerDocument", {
          "agentId": this.item.agentDb.id,
          "created": 'CURRENT_TIMESTAMP',
          "type": 'Signed Agreement',
          "description": `${this.item.agentDb.name} Signed Agreement`,
          "status": 'Pending',
        })).id;
        const action = (await api.upload("PartnerDocument", id, file));
        const response = await api.get('PartnerDocument', id)
        this.item.agentDb.documents.push(
          response.item
        )

        const note = (await api.create("Note", {
            message: `${this.item.agentDb.name} Signed Agreement added`,
            parentId: this.item.agentDb.id,
            type: 'Partner',
            created: "CURRENT_TIMESTAMP",
            author: appData.currentUser.id,
          }, true)).item
        await this.$emit("reloadnotes")


        await api.update("PartnerAgreement", agreement.id, {signed: "CURRENT_TIMESTAMP"});
        await api.upload("PartnerAgreement", agreement.id, file);
        this.$set(this.agreements, 0, (await api.get("PartnerAgreement", agreement.id)).item);

        stopLoading("File uploaded");
      });
      f.click();
    },
    
    async updateOk(document) {
      await api.update("PartnerDocument", document.id, {
            "status": 'Ok'
          });
      document.status = 'Ok'
      await this.createNote(document)
    },

    async updateArchived(document) {
      await api.update("PartnerDocument", document.id, {
            "status": 'Archived'
          });
      document.status = 'Archived'
      await this.createNote(document)
    },

    async update(document) {
     this.selectedDocument = document
     this.statusPopup = true
    },

    async submit () { 
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$toast.error("You need to fill all required fields. We marked them with red color so that you know what fields you have missed.")
        return
      }
      startLoading()
      try {
        await api.update("PartnerDocument", this.selectedDocument.id, {
            "status": this.selectedStatus
          });
        this.selectedDocument.status = this.selectedStatus
        this.statusPopup = false
        this.selectedStatus = null
        await this.createNote(this.selectedDocument)
        stopLoading("")
      } catch (e) {
        stopLoading(e, 'error')
      }
    },

    async createNote(document) {
      const note = (await api.create("Note", {
        message: `Updated document ${document.description}, Pending to ${document.status}`,
        parentId: this.item.agentDb.id,
        type: 'Partner',
        created: "CURRENT_TIMESTAMP",
        author: appData.currentUser.id,
      }, true)).item
      await this.$emit("reloadnotes")
    }
  },

  mounted() {
    this.listAgreements();
  },

  async created() {
    this.statuses = (await api.list('Status', {category: 'Compliance'})).items
  },

  validations () {
    const rules = {
      selectedStatus: { required },
    }

    return rules
  },

  mixins: [vueMixins.dateFormatter],
}
</script>
<style scoped>
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  margin-right: 8px;
  color: black;
  font-size: 16px;
  display: block;
  padding: 6px 20px;
  box-sizing: border-box;
  border: 1px solid #b3b3b3;
  border-radius: 6px;
  background: #e4e4e4;
  background: linear-gradient(to bottom, #e4e4e4 0%, #cfcfcf 100%);
}
button:no-di:hover:not([disabled]), button:focus:not([disabled]) {
  background: #e9f2ff;
  background: linear-gradient(to bottom, #e9f2ff 0%, #deebff 100%);
  border-color: #6192fc;
  color: #6192fc;
}
button:disabled {
  opacity: 0.5;
}
.document-actions {
  display: flex;
  align-items: center;
  padding: 10px;
}
.document-actions span {
  display: block;
  margin: 0 10px;
  flex-grow: 1;
}
</style>
