<template>
<div class="panel">
  <div
    v-if="title || $slots.title"
    class="panel-title">
    <slot name="title">
      <h2>{{ title }}</h2>
    </slot>
    <div
      v-if="$slots.actions"
      class="panel-actions">
      <slot name="actions" />
    </div>
  </div>
  <div class="body">
    <slot />
  </div>
  <div
    v-if="$slots.footer"
    class="panel-footer">
      <slot name="footer" />
  </div>
</div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null
    }
  }
}
</script>
