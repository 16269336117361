<template>
<div>
  <div class="contdit-table-container">
    <table class="content-table">
      <thead>
        <tr>
          <th style="width: 15%">Date</th>
          <th style="width: 15%">Version</th>
          <th style="width: 60%">Description</th>
          <th style="width: 10%">&nbsp;</th>
        </tr>
        <tr>
          <th class="spacer" colspan="3"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items">
          <td class="text-left">
            <datepicker v-model="item.created" />
          </td>
          <td>
            <input type="text" v-model="item.version" style="text-align: left" />
          </td>
          <td class="">
            <textarea v-model="item.description" rows="20" cols="60">
            </textarea>
          </td>
          <td>
            <a class="remove" href="" v-on:click.prevent="deleteItem(index)">&times;</a>
          </td>
        </tr>
        <tr class="new-row">
          <td colspan="4">
            <a class="add" href="" v-on:click.prevent="newItem">+</a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="buttons">
    <button class="btn btn-large btn-primary" v-on:click.prevent="updateResources">Submit</button>
  </div>
</div>
</template>

<script>
export default {
  data() { return {
    items: [],
    origItems: [],
  }},
  methods: {
    reload() {
      var that=this;
      api.list("Changelog", {}, ["id:DESC"]).then(function(data) {
        that.origItems=JSON.parse(JSON.stringify(data.items));
        that.items=JSON.parse(JSON.stringify(data.items));
      });
    },
    deleteItem(index) {
      var that=this;
      if (confirm("Are you sure?")) {
        that.items.splice(index, 1);
      }
    },
    newItem() {
      this.items.push({
        created: null,
        version: "",
        description: "",
      });
    },
    updateResources() {
      var itemsToDelete=[];
      for(var index in this.origItems) {
        var item=this.origItems[index];
        if (!this.items.find(function(e) { return e.id==item.id; }))
          itemsToDelete.push(item);
      }
      var itemsToAdd=[];
      var itemsToUpdate=[];
      for(var index in this.items) {
        var item=this.items[index];
        var foundItem=this.origItems.find(function(e) { return e.id==item.id; });
        if (!foundItem)
          itemsToAdd.push(item);
        else if (JSON.stringify(foundItem)!=JSON.stringify(item))
          itemsToUpdate.push(item);
      }
      this.updateResourcesApi(itemsToUpdate, itemsToAdd, itemsToDelete);
      this.origItems=JSON.parse(JSON.stringify(this.items));
    },
    updateResourcesApi: async function(update, add, remove) {
      startLoading();
      for(var i in update) {
        var item=update[i];
        await api.update("Changelog", item.id, item);
      }
      for(var i in add) {
        var item=add[i];
        await api.create("Changelog", item);
      }
      for(var i in remove) {
        var item=remove[i];
        await api.delete("Changelog", item.id);
      }
      stopLoading("Updated Changelog");
    },
  },
  mounted() {
    this.reload();
  },
}

</script>

