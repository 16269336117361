<template>
<div>
  <div class="content-table-container">
    <table class="content-table">
      <thead>
        <tr>
          <th style="width: 15%">Date</th>
          <th style="width: 15%">Version</th>
          <th style="width: 60%">Description</th>
        </tr>
        <tr>
          <th class="spacer" colspan="3"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items">
          <td class="text-left">
            {{ item.created }}
          </td>
          <td>
            {{ item.version }}
          </td>
          <td class="">
            <textarea disabled cols="60" rows="20">
              {{ item.description }}
            </textarea>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
</template>

<script>
export default {
  data() { return {
    items: [],
    origItems: [],
  }},
  methods: {
    reload() {
      var that=this;
      api.list("Changelog", {}, ["id:DESC"]).then(function(data) {
        that.origItems=JSON.parse(JSON.stringify(data.items));
        that.items=JSON.parse(JSON.stringify(data.items));
      });
    },
    deleteItem(index) {
      var that=this;
      if (confirm("Are you sure?")) {
        that.items.splice(index, 1);
      }
    },
    newItem() {
      this.items.push({
        created: null,
        version: "",
        description: "",
      });
    },
    updateResources() {
      var itemsToDelete=[];
      for(var index in this.origItems) {
        var item=this.origItems[index];
        if (!this.items.find(function(e) { return e.id==item.id; }))
          itemsToDelete.push(item);
      }
      var itemsToAdd=[];
      var itemsToUpdate=[];
      for(var index in this.items) {
        var item=this.items[index];
        var foundItem=this.origItems.find(function(e) { return e.id==item.id; });
        if (!foundItem)
          itemsToAdd.push(item);
        else if (JSON.stringify(foundItem)!=JSON.stringify(item))
          itemsToUpdate.push(item);
      }
      this.updateResourcesApi(itemsToUpdate, itemsToAdd, itemsToDelete);
      this.origItems=JSON.parse(JSON.stringify(this.items));
    },
    updateResourcesApi: async function(update, add, remove) {
      startLoading();
      for(var i in update) {
        var item=update[i];
        await api.update("Changelog", item.id, item);
      }
      for(var i in add) {
        var item=add[i];
        await api.create("Changelog", item);
      }
      for(var i in remove) {
        var item=remove[i];
        await api.delete("Changelog", item.id);
      }
      stopLoading("Updated Changelog");
    },
  },
  mounted() {
    this.reload();
  },
}

</script>

