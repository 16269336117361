<template>
<div class="popup usermgmt" :class="{shown: visible}">
  <div class="pop-med">
    <div class="pop-inner">
      <a href="" class="close" v-on:click.prevent="visible=false"></a>
      <h2>Set new password for: {{ user.username }}</h2>
      <form class="search-form">
        <div v-if="false">
          <validator class="field" :value="agentId" reqs="">
            <label>Partner ID</label>
            <input tabindex="2" type="text" v-model="agentId" />
          </validator>
        </div>
        <div>
          <validator class="field" :value="username" reqs="">
            <label>Username</label>
            <input tabindex="2" type="text" v-model="username" />
          </validator>
        </div>
        <div>
          <validator class="field" :value="password" reqs="password">
            <label>New Password</label>
            <input tabindex="2" type="password" v-model="password" />
          </validator>
        </div>
        <div>
          <validator class="field" :value="password2" reqs="password">
            <label>Confirm Password</label>
            <input tabindex="2" type="password" v-model="password2" />
          </validator>
        </div>
        <div class="consent" v-if="user.initial">
          <p>By accessing the system, you accept and agree to our:</p>
          <ul>
            <li><label>
                <input type="checkbox" v-model="consent.accessControl" />
                <a href="" target="_blank">Access Control Policy</a>
            </label></li>
            <li><label>
                <input type="checkbox" v-model="consent.cyberSecurity" />
                <a href="" target="_blank">Cyber Security policy</a>
            </label></li>
            <li><label>
                <input type="checkbox" v-model="consent.acceptableUse" />
                <a href="" target="_blank">Acceptable Use Policy</a>
            </label></li>
            <li><label>
                <input type="checkbox" v-model="consent.terms" />
                <a href="" target="_blank">Terms of Use</a>
            </label></li>
            <li><label>
                <input type="checkbox" v-model="consent.privacy" />
                <a href="" target="_blank">Privacy Policy</a>
            </label></li>
          </ul>
        </div>
        <div class="buttons">
          <button class="btn btn-large btn-primary" v-on:click.prevent="send">Update</button>
        </div>
      </form>
    </div>
  </div>
</div>
</template>

<script>
export default {
  data() { return {
    visible: false,
    user: {},
    agentId: "",
    username: "",
    password: "",
    password2: "",
    consent: {
      accessControl: false,
      cyberSecurity: false,
      acceptableUse: false,
      terms: false,
      privacy: false,
    },
  }},
  computed: {
  },
  methods: {
    showPopup(user) {
      this.validateReset();
      this.user=user || {};
      this.visible=true;
      this.username=this.user.username || "";
      this.agentId=this.user.agentId || "";
    },
    send: async function() {
      if (this.user.initial) {
        if (!this.consent.accessControl)
          return stopLoading("You must agree to our Access Control Policy", "error");
        if (!this.consent.cyberSecurity)
          return stopLoading("You must agree to our Cyber Security Policy", "error");
        if (!this.consent.acceptableUse)
          return stopLoading("You must agree to our Acceptable Use Policy", "error");
        if (!this.consent.terms)
          return stopLoading("You must agree to our Terms of use", "error");
        if (!this.consent.privacy)
          return stopLoading("You must agree to our Privacy Policy", "error");
      }
    
//       if (this.agentId!=this.user.agentId)
//         return stopLoading("Account # does not match", "error");
      if (this.username!=this.user.username)
        return stopLoading("Username does not match", "error");
      if (!this.password)
        return stopLoading("Please enter a password", "error");
      if (this.password!=this.password2)
        return stopLoading("Passwords do not match", "error");
      var that=this;
      startLoading();
      $.post(resetPwUrl, {
        username: this.user.username,
        password: this.user.oldPassword,
        newPassword: this.password,
      }, function(data) {
        if (data.success) {
          stopLoading("Password updated", "success");
          $("[name=username]").val(that.user.username);
          $("[name=agentId]").val(that.user.agentId);
          $("[name=password]").val(that.password);
          /*$.post($("form.form-login").attr("action"), $("form.form-login").serialize(), function(res) {
            if (res.success==false) {
              $('.error-msg').html(res.msg).show();
            } else
              window.location.href=$("base").attr("href");
          });*/
          that.visible=false;
        } else {
          stopLoading("Error updating your password", "error");
        }
      }, "json");
    },
  },
  mounted: async function() {
    window.userResetPw=this;
  },
  mixins: [formValidation.mixin]
}
</script>
