<template>
<div>
  <div class="content-table-container file-list-container">
    <table class="file-list content-table">
        <tbody>
          <tr>
            <td>Created</td>
            <td>Description</td>
            <td>Number</td>
            <td>Filename</td>
            <td>Status</td>
            <td v-if="!simplified">Options</td>
          </tr>
          <template v-if="item.accountType == 'Personal'">
            <template v-if="onboarding">
              <tr v-for="document in item.personal.documents" v-if="document.status != 'Archived'" :key="document.number">
                <td>{{ formatDate(document.created, "YYYY-MM-DD") }}</td>
                <td>{{ document.title }}</td>
                <td>{{ document.number }}</td>
                <td>
                  <a :href="fileLink(document.filename, document.id)" target="_blank">{{ document.filename || "(no file)" }}</a>
                </td>
                <td>{{ document.status }}</td>
                <td v-if="!simplified">
                  <template v-if="document.status == 'Pending'">
                    <span @click="updateOk(document)" class="option-btn">
                      <img src="/assets/img/table-approve.png" />
                    </span>
                    <span @click="update(document)" class="option-btn">
                      <img src="/assets/img/table-deny.png" />
                    </span>
                    <span @click="updateArchived(document)" class="option-btn">
                      DEL
                    </span> 
                  </template>
                </td>
              </tr>
            </template>
            <template v-else>
              <tr v-for="document in item.personal.documents" :key="document.number">
                <td>{{ formatDate(document.created, "YYYY-MM-DD") }}</td>
                <td>{{ document.title }}</td>
                <td>{{ document.number }}</td>
                <td>
                  <a :href="fileLink(document.filename, document.id)" target="_blank">{{ document.filename || "(no file)" }}</a>
                </td>
                <td>{{ document.status }}</td>
                <td v-if="!simplified">
                  <template v-if="document.status == 'Pending'">
                    <span @click="updateOk(document)" class="option-btn">
                      <img src="/assets/img/table-approve.png" />
                    </span>
                    <span @click="update(document)" class="option-btn">
                      <img src="/assets/img/table-deny.png" />
                    </span>
                    <span @click="updateArchived(document)" class="option-btn">
                      DEL
                    </span> 
                  </template>
                </td>
              </tr>
            </template>
          </template>
          <template v-if="item.accountType == 'Business'">
            <template v-if="onboarding">
              <tr v-for="document in item.business.documents" v-if="document.status != 'Archived'" :key="document.number">
                <td>{{ formatDate(document.created, "YYYY-MM-DD") }}</td> 
                <td>{{ document.title }}</td>
                <td>{{ document.number }}</td>
                <td>
                  <a :href="fileLink(document.filename, document.id)" target="_blank">{{ document.filename || "(no file)" }}</a>
                </td>
                <td>{{ document.status }}</td>
                <td v-if="!simplified">
                  <template v-if="document.status == 'Pending'">
                    <span @click="updateOk(document)" class="option-btn">
                      <img src="/assets/img/table-approve.png" />
                    </span>
                    <span @click="update(document)" class="option-btn">
                      <img src="/assets/img/table-deny.png" />
                    </span>
                    <span @click="updateArchived(document)" class="option-btn">
                      DEL
                    </span> 
                  </template>
                </td>
              </tr>
            </template>
            <template v-else>
              <tr v-for="document in item.business.documents" :key="document.number">
                <td>{{ formatDate(document.created, "YYYY-MM-DD") }}</td> 
                <td>{{ document.title }}</td>
                <td>{{ document.number }}</td>
                <td>
                  <a :href="fileLink(document.filename, document.id)" target="_blank">{{ document.filename || "(no file)" }}</a>
                </td>
                <td>{{ document.status }}</td>
                <td v-if="!simplified">
                  <template v-if="document.status == 'Pending'">
                    <span @click="updateOk(document)" class="option-btn">
                      <img src="/assets/img/table-approve.png" />
                    </span>
                    <span @click="update(document)" class="option-btn">
                      <img src="/assets/img/table-deny.png" />
                    </span>
                    <span @click="updateArchived(document)" class="option-btn">
                      DEL
                    </span> 
                  </template>
                </td>
              </tr>
            </template>
            <template v-if="onboarding">
              <template v-for="contact in item.business.contacts">
                <tr v-for="document in contact.documents" v-if="document.status != 'Archived'" :key="document.number">
                  <td>{{ formatDate(document.created, "YYYY-MM-DD") }}</td> 
                  <td>{{ document.title }}</td>
                  <td>{{ document.number }}</td>
                  <td>
                    <a :href="fileLink(document.filename, document.id)" target="_blank">{{ document.filename || "(no file)" }}</a>
                  </td>
                  <td>{{ document.status }}</td>
                  <td v-if="!simplified">
                    <template v-if="document.status == 'Pending'">
                    <span @click="updateOk(document)" class="option-btn">
                      <img src="/assets/img/table-approve.png" />
                    </span>
                    <span @click="update(document)" class="option-btn">
                      <img src="/assets/img/table-deny.png" />
                    </span>
                    <span @click="updateArchived(document)" class="option-btn">
                      DEL
                    </span> 
                  </template>
                  </td>
                </tr>
              </template>
            </template>
            <template v-else>
              <template v-for="contact in item.business.contacts">
                <tr v-for="document in contact.documents" :key="document.number">
                  <td>{{ formatDate(document.created, "YYYY-MM-DD") }}</td> 
                  <td>{{ document.title }}</td>
                  <td>{{ document.number }}</td>
                  <td>
                    <a :href="fileLink(document.filename, document.id)" target="_blank">{{ document.filename || "(no file)" }}</a>
                  </td>
                  <td>{{ document.status }}</td>
                  <td v-if="!simplified">
                    <template v-if="document.status == 'Pending'">
                    <span @click="updateOk(document)" class="option-btn">
                      <img src="/assets/img/table-approve.png" />
                    </span>
                    <span @click="update(document)" class="option-btn">
                      <img src="/assets/img/table-deny.png" />
                    </span>
                    <span @click="updateArchived(document)" class="option-btn">
                      DEL
                    </span> 
                  </template>
                  </td>
                </tr>
              </template>
            </template>
          </template>
        </tbody>
    </table>
  </div>

  <div v-if="onboarding" class="actions">
    <button v-on:click.prevent="goBack">Back</button>
    <button :disabled="disabledStatus" v-on:click.prevent="doNext">Save & Continue</button>
  </div>

  <portal v-if="portalButtons && !onboarding" to="actions">
    <ul>
      <li>
        <a href="" v-on:click.prevent="newFile=true">New File</a>
      </li>
    </ul>
  </portal>

  <popup :open.sync="newFile">
    <treasury-document-popup v-if="newFile" v-model="item" :newFile.sync="newFile" v-on:reloadnotes="$emit('reloadnotes')" />
  </popup>

  <popup :open.sync="statusPopup">
      <div class="static-page privacy-popup">
        <h2><strong>Update Document Status</strong></h2>
        <br/>
        <div class="content-form">
          <div class="form-row form-fullw">
            <form-field-select :validator="$v.selectedStatus" v-model="selectedStatus" :label="`Select Document Status`" required :options="statusOptions" />
            <p>{{ selectedText }}</p>
          </div>
          <br/>
          <br/>
          <div class="form-row form-split">
            <div>
              <button :disabled="!selectedStatus" @click="submit()">Submit</button>
            </div>
            <div>
              <button @click="statusPopup = false, selectedStatus = null">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </popup>
</div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
export default {
  props: {
    item: {
      type: Object,
      required: true
    },

    portalButtons: {
      type: Boolean,
      default: true
    },

    onboarding: {
      type: Boolean,
      default: false
    },

    simplified: {
      type: Boolean,
      default: false
    }
  },

  data: ()=>({
    newFile: false,
    statusPopup: false,
    statuses: [],
    selectedStatus: null,
  }),
  computed: {
    statusOptions() {
      var options = [];
      for(const status of this.statuses)
        options.push(
          {
            id: `${status.status}`,
            text: `${status.status} - ${status.description.substr(0, 50)}`
          }
        )
      return options
    },

    selectedText() {
      return this.statuses.find(item =>  item.status == this.selectedStatus)?.description
    },

    disabledStatus() {
      if(this.item.accountType == 'Personal') {
        for(var i in this.item.personal.documents) {
          if(this.item.personal.documents[i].status != 'Ok' && this.item.personal.documents[i].status != 'Archived') {
            return true
          }
        }
      }
      if(this.item.accountType == 'Business') {
        for(var i in this.item.business.documents) {
          if(this.item.business.documents[i].status != 'Ok' && this.item.business.documents[i].status != 'Archived') {
            return true
          }
        }
        for(var i in this.item.business.contacts) {
          for(var j in this.item.business.contacts[i].documents) {
            if(this.item.business.contacts[i].documents[j].status != 'Ok' && this.item.business.contacts[i].documents[j].status != 'Archived') {
              return true
            }
          }
        }
      }
      
      return false
    },
  },

  async created() {
    this.statuses = (await api.list('Status', {category: 'Compliance'})).items
  },

  watch: {
   
  },
  methods: {
    fileLink(fileName, docId) {
      if (!fileName)
        return null;
      if (docId)
        return "download/ProfileDocument/"+docId;
      return null;
    },

    async updateOk(document) {
      await api.update("ProfileDocument", document.id, {
            "status": 'Ok'
          });
      document.status = 'Ok'
      await this.createNote(document)
    },

    async updateArchived(document) {
      await api.update("ProfileDocument", document.id, {
            "status": 'Archived'
          });
      document.status = 'Archived'
      await this.createNote(document)
    },

    async update(document) {
     this.selectedDocument = document
     this.statusPopup = true
    },

    async submit () { 
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$toast.error("You need to fill all required fields. We marked them with red color so that you know what fields you have missed.")
        return
      }
      startLoading()
      try {
        await api.update("ProfileDocument", this.selectedDocument.id, {
            "status": this.selectedStatus
          });
        this.selectedDocument.status = this.selectedStatus
        this.statusPopup = false
        this.selectedStatus = null
        await this.createNote(this.selectedDocument)
        stopLoading("")
      } catch (e) {
        stopLoading(e, 'error')
      }
    },

    async createNote(document) {
      const note = (await api.create("Note", {
        message: `Updated document ${document.title}, Pending to ${document.status}`,
        parentId: this.item.id,
        type: 'Customer',
        created: "CURRENT_TIMESTAMP",
        author: appData.currentUser.id,
      }, true)).item
      await this.$emit("reloadnotes")
    },

    goBack() {
      this.$emit("back");
    },
    doNext() {
      this.$emit("next");
    },
  },

  mounted() {
   
  },

  validations () {
    const rules = {
      selectedStatus: { required },
    }

    return rules
  },

  mixins: [vueMixins.dateFormatter],
}
</script>

<style lang="scss" scoped>
  .buttons {
    display: flex;
    margin-bottom: 20px;
  }

  .mr-10 {
    margin-right: 10px;
  }

  .option-btn {
    background: #ffffff;
    display: inline-block;
    width: 30px;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
  }
</style>
