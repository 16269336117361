<template>
<div>
  <onboarding-menu />

  <div class="ncontent touch-it">
    <div class="header split">
      <div>
        <ul class="breadcrumbs">
          <li><a>Onboarding Overview</a></li>
        </ul>
        <div class="toolbar" />
<!--         <onboarding-search-form /> -->
        <form class="search-form">
          <h3>Search Options</h3>
          <div class="f2split">
            <div>
              <div class="field">
                <label>Date Range</label>
                <div class="field-group">
                  <input type="text" id="dp1569254393711" class="hasDatepicker">
                  <input type="text" id="dp1569254393712" class="hasDatepicker">
                </div>
              </div>
              <div class="field">
                <label>Trans ID</label>
                <input type="text">
              </div>
              <div class="field">
                <label>TID</label>
                <select>
                  <option value="">(all)</option>
                  <option value="2">Allium</option>
                  <option value="3">Acme Inc.</option>
                  <option value="4">Internal Agent</option>
                  <option value="5">David Collins Jr</option>
                  <option value="7">GBWEB</option>
                  <option value="14"></option>
                  <option value="15"></option>
                  <option value="20"></option>
                </select>
              </div>
              <div class="field">
                <label>Trans Type</label>
                <select>
                  <option value="">(all)</option>
                  <option value="A00">A00 - To be Assessed</option>
                  <option value="A01">A01 - Pre-Qualification Complete</option>
                  <option value="A02">A02 - New Application</option>
                  <option value="A03">A03 - In Process</option>
                  <option value="A04">A04 - Approved</option>
                  <option value="A05">A05 - Denied</option>
                  <option value="A06">A06 - Duplicate</option>
                </select>
              </div>
              <div class="field">
                <label>Entry Type</label>
                <select>
                  <option value="">(all)</option>
                  <option value="A00">A00 - To be Assessed</option>
                  <option value="A01">A01 - Pre-Qualification Complete</option>
                  <option value="A02">A02 - New Application</option>
                  <option value="A03">A03 - In Process</option>
                  <option value="A04">A04 - Approved</option>
                  <option value="A05">A05 - Denied</option>
                  <option value="A06">A06 - Duplicate</option>
                </select>
              </div>
            </div>
            <div>
              <div class="field">
                <label>Scheme</label>
                <select>
                  <option value="">(all)</option>
                  <option value="A00">A00 - To be Assessed</option>
                  <option value="A01">A01 - Pre-Qualification Complete</option>
                  <option value="A02">A02 - New Application</option>
                  <option value="A03">A03 - In Process</option>
                  <option value="A04">A04 - Approved</option>
                  <option value="A05">A05 - Denied</option>
                  <option value="A06">A06 - Duplicate</option>
                </select>
              </div>
              <div class="field">
                <label>Card</label>
                <input type="text">
              </div>
              <div class="field">
                <label>Ammount</label>
                <input type="text">
              </div>
              <div class="field">
                <label>Batch</label>
                <input type="text">
              </div>
              <div class="field">
                <label>Status</label>
                <select>
                  <option value="">(all)</option>
                  <option value="Pending">Pending</option>
                  <option value="Complete">Complete</option>
                  <option value="Expired">Expired</option>
                  <option value="Cancel">Cancel</option>
                  <option value="Resolved">Resolved</option>                
                </select>
              </div>
            </div>
          </div>
          <div class="buttons"><button class="btn btn-large btn-primary">Search</button></div>
        </form>
      </div>
      <aside>
        <h3>Summary Report</h3>
        <table class="breakdown-table">
          <thead>
            <tr>
              <th>Status</th>
              <th>Count</th>              
            </tr>
            <tr>
              <th colspan="2" class="spacer"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in list">
              <td>{{ item.name }}</td>
              <td>{{ item.data.length }}</td>             
            </tr>
          </tbody>
        </table>
      </aside>
    </div>
    <div class="hr"></div>
    <div class="content">
      <div>
        <p class="result-count">Your search returned <span>0</span> result(s).</p>
        <ul class="acc-results">
          <li v-for="(item, type) in list" :key="type" :class="{ active: show[type] }">
            <h4 @click="show[type] = !show[type]"><span class="label">{{ item.name }}</span></h4>
            <div v-show="show[type]" class="acc-list" style="display: block;">
              <table>
                <thead>
                  <tr>
                    <th>Build ID</th>
                    <th>Relationship Manager</th>
                    <th>Approval Date</th>
                    <th>Company Name</th>
                    <th>Company Email</th>
                    <th>Status</th>
                    <th>Options</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="emp in item.data" :key="data.id">
                    <template v-if="emp.itemType=='Partner'">
                    <td>{{ (emp.setups && emp.setups[0] && emp.setups[0].buildId) || emp.user.id }}</td>
                    <td>{{ emp.manager && emp.manager.fullName }}</td>
                    <td>{{ formatDate(emp.createdAt, "YYYY-MM-DD") }}</td>
                    <td>{{ emp.name }}</td>
                    <td>{{ emp.email }}</td>
                    <td>{{ emp.status }}</td>
                    <td>
                      <a href="" v-if="!emp.setups || !emp.setups.length" v-on:click.prevent="startSetup(emp)">Start</a>
                      <a href="" v-if="emp.setups && emp.setups.length" v-on:click.prevent="resumeSetup(emp)">Resume</a>
                      <a href="" v-if="emp.setups && emp.setups.length" v-on:click.prevent="resetSetup(emp)">Reset</a>
                    </td>
                    </template>
                    <template v-else-if="emp.itemType=='Merchant'">
                    <td>{{ (emp.setups && emp.setups[0] && emp.setups[0].buildId) || emp.id }}</td>
                    <td>{{ emp.app.manager && emp.app.manager.fullName }}</td>
                    <td>{{ formatDate(emp.app.approveDate, "YYYY-MM-DD") }}</td>
                    <td>{{ emp.app.companyName }}</td>
                    <td>{{ emp.app.companyEmail }}</td>
                    <td>{{ emp.status }}</td>
                    <td>
                      <a href="" v-if="!emp.setups || !emp.setups.length" v-on:click.prevent="startSetupMerchant(emp)">Start</a>
                      <a href="" v-if="emp.setups && emp.setups.length" v-on:click.prevent="resumeSetupMerchant(emp)">Resume</a>
                      <a href="" v-if="emp.setups && emp.setups.length" v-on:click.prevent="resetSetupMerchant(emp)">Reset</a>
                    </td>
                    </template>
                  </tr>
                </tbody>
              </table>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  mixins: [ vueMixins.helpers, vueMixins.dateFormatter ],

  data () {
    return {
      show: {
        MERCHANTS: false,
        FACILITATORS: false,
        SUBMERCHANTS: false,
        AGENTS: false
      },

      data: [],
      list: {
        MERCHANTS: {
          name: "Merchants Pending Setup",
          data: [],
        },
//         FACILITATORS: {
//           name: "Payfac Pending Setup",
//           data: [],
//         },
//         SUBMERCHANTS: {
//           name: "Sub-Accounts Pending Setup",
//           data: [],
//         },
        AGENTS: {
          name: "Partners Pending Setup",
          data: [],
        },
      },
    }
  },
  computed: {
  },
  created () {
    this.loadAgents()
    this.loadMerchants()
  },
  methods: {
    openPreview (emp) {
      console.log('preview', emp)
    },
    async loadMerchants() {
      var items=(await api.list("Merchant", {status: "M00"})).items;
      var waits=[];
      for(var item of items)
        waits.push(api.list("MerchantSetup", {merchantId: item.id}));
      var results=await Promise.all(waits);
      for(var i in results) {
        items[i].setups=results[i].items || [];
        items[i].itemType="Merchant";
      }
      
      this.list.MERCHANTS.data=items.filter(a=>a.app && a.app.accountType=="merchant");
      this.list.FACILITATORS.data=items.filter(a=>a.app && a.app.accountType=="master");
      this.list.SUBMERCHANTS.data=items.filter(a=>a.app && a.app.accountType=="sub");
    },

    async loadAgents() {
      var items=(await api.list("Partner", {status: "A04"})).items;
      var waits=[];
      var waits3=[];
      var waits2=[];
      for(var item of items) {
        item.itemType="Partner";
        waits.push(api.list("User", {agentDbId: item.id}));
      }
      for(var i in items) {
        var item=items[i];
        item.user=(await waits[i]).items[0] || {};
        waits2.push(api.list("PartnerSetup", {agentId: item.user.id}));
        waits3.push(api.get("User", item.user.manager));
      }
      for(var i in items) {
        var item=items[i];
        item.setups=(await waits2[i]).items || [];
      }
      for(var i in items) {
        var item=items[i];
        item.manager=(await waits3[i]).item;
      }
      this.list.AGENTS.data=items;
    },
    async startSetup(item) {
      var id=(await api.create("PartnerSetup", {
        agentId: item.user.id,
        created: "CURRENT_TIMESTAMP",
        name: item.companyName+" "+item.agentId,
        accClass: "",
        managerId: item.manager,
        filename: null,
        signed: null,
        salesBrand: null,
        solutions: [],
        regions: [],
        portal: 'trak',
        pin: null,
        username: null,
        buildId: "APP_ID_GEN_MERCHANTBUILD",
        buildBy: appData.currentUser.id,
      })).id;
      window.open("implementation/agentsetup/"+id, "_self");
    },
    async resumeSetup(item) {
      var setup=(await api.list("PartnerSetup", {agentId: item.user.id}, ["id:DESC"])).items[0];
      if (setup)
        window.open("implementation/agentsetup/"+setup.id, "_self");
    },
    async resetSetup(item) {
      var setup=(await api.list("AgentSetup", {agentId: item.user.id}, ["id:DESC"])).items[0];
      if (setup && setup.id) {
        await api.delete("PartnerSetup", setup.id);
        item.setups=[];
      }
      appNotifications.show("Partner setup reset", "success")
    },
    async startSetupMerchant(item) {
      var id=(await api.create("MerchantSetup", {
        merchant: item.id,
        app: item.app.id,
        created: "CURRENT_TIMESTAMP",
        buildId: "APP_ID_GEN_MERCHANTBUILD",
        setupData: {accountId: item.app.accountId, cardPresent: item.app.merchantType=='POS', cardNotPresent: item.app.merchantType=='E-Commerce'},
      })).id;
      window.open("implementation/merchantsetup/"+id, "_self");
    },
    async resumeSetupMerchant(item) {
      var setup=(await api.list("MerchantSetup", {merchantId: item.id}, ["id:DESC"])).items[0];
      if (setup)
        window.open("implementation/merchantsetup/"+setup.id, "_self");
    },
    async resetSetupMerchant(item) {
      var setup=(await api.list("MerchantSetup", {merchantId: item.id}, ["id:DESC"])).items[0];
      if (setup && setup.id) {
        await api.delete("MerchantSetup", setup.id);
        item.setups=[];
      }
      appNotifications.show("Partner setup reset", "success")
    },
  }
}
</script>
