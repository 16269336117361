<template>
  <div class="static-page privacy-popup">
    <h2><strong>Add Document</strong></h2>
    <br/>
    <div class="content-form">
      <div class="form-row form-split">
        <form-field-select v-model="category" :label="`Document Category`" required :options="{Business: 'Business', Contact: 'Contact'}" />
        <form-field-select :validator="$v.name" :disabled="!category" v-model="name" :label="`Name`" required :options="nameOptions" />
      </div>
      <div class="form-row form-split">
        <form-field-select  :validator="$v.type"  :disabled="!category" v-model="type" :label="`File type`" required :options="typeOptions" />
        <form-field-text :disabled="!category" v-if="type=='Other'" v-model="otherType" :label="`Other`" :maxlength="25" required  />
      </div>
      <div class="form-row form-fullw">
        <form-field-text :disabled="!category" v-model="number" :label="`Document Number`" :maxlength="15" required  />
      </div>
      <div class="form-row form-fullw">
        <form-field-upload :validator="$v.file" :disabled="!category" v-model="file" :label="`File Upload`" required />
      </div>
      <div class="form-row form-center">
        <div class="">
          <button :disabled="!category" @click="submit()">Submit</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import documentUpload from '../common/document-upload'
import { required } from 'vuelidate/lib/validators'
export default {
  mixins: [documentUpload],

  props: {
    value: {
      type: Object,
      required: true
    },
    newFile: {
      type: Boolean,
      required: true
    },
  },

  data () {
    return {
      form: this.value,
      category: null,
      name: null,
      type: null,
      otherType: null,
      number: null,
      file: null,
    }
  },

  computed: {
    nameOptions() {
      var options = [];
      if(this.category == 'Business') {
        options.push(
          {
            id: this.form.businessProfile.companyName,
            text: this.form.businessProfile.companyName  
          }
        )
      }

      if(this.category == 'Contact') {
        if(this.form.contacts) {
          for(const contact of this.form.contacts)
          options.push(
            {
              id: `${contact.id}`,
              text: `${contact.firstName} ${contact.lastName}`
            }
          )
        }
      }

      return options
    },
  },

  methods: {
    async submit () { 
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$toast.error("You need to fill all required fields. We marked them with red color so that you know what fields you have missed.")
        return
      }
      startLoading()
      try {
        var doctype = this.type == 'Other' ? this.otherType :  this.type

        if(this.category == 'Business') {
          var id=(await api.create("ApplicationCustomDocument", {
            "appId": this.form.id,
            "created": 'CURRENT_TIMESTAMP',
            "type": doctype,
            "description": `${this.name} ${doctype}`,
            "number": this.number,
            "status": 'Pending',
          })).id;
          const action = (await api.upload("ApplicationCustomDocument", id, this.file));
          const response = await api.get('ApplicationCustomDocument', id)
          this.form.customDocuments.push(
            response.item
          )
          const note = (await api.create("Note", {
            message: `${this.name} ${doctype} added`,
            parentId: this.form.id,
            type: 'Application',
            created: "CURRENT_TIMESTAMP",
            author: appData.currentUser.id,
          }, true)).item
          this.$emit("reloadnotes")
        }

        if(this.category == 'Contact') {
          const contact = this.form.contacts.find(item=>item.id == this.name)
          var id=(await api.create("ApplicationCustomDocument", {
            "appId": this.form.id,
            "created": 'CURRENT_TIMESTAMP',
            "type": doctype,
            "description": `${contact.firstName} ${contact.lastName} ${doctype}`,
            "number": this.number,
            "status": 'Pending',
          })).id;
          const action = (await api.upload("ApplicationCustomDocument", id, this.file));
          const response = await api.get('ApplicationCustomDocument', id)
          this.form.customDocuments.push(
            response.item
          )
          const note = (await api.create("Note", {
            message: `${contact.firstName} ${contact.lastName} ${doctype} added`,
            parentId: this.form.id,
            type: 'Application',
            created: "CURRENT_TIMESTAMP",
            author: appData.currentUser.id,
          }, true)).item
          this.$emit("reloadnotes")
        }

        this.$emit('update:newFile', false)
        stopLoading("")
      } catch (e) {
        stopLoading(e, 'error')
      }
    }
  },

  validations () {
    const rules = {
      name: { required },
      type: { required },
      file: { required },
    }

    return rules
  }
}
</script>
<style lang="scss" scoped>
  .privacy-popup {
    line-height: 22px;
    color: #21364c;
  }

  .privacy-popup h2{
    border-bottom: none;
  }

  .privacy-popup p {
    margin-bottom: 18px;
    line-height: 22px;
    color: #21364c;
  }

  .privacy-popup a {
    color: #00f;
    text-decoration: underline;
  }

  .privacy-popup strong {
    font-weight: 700;
  }
</style>
