<template>
  <div class="content-table-container file-list-container">
    <table class="file-list content-table">
      <thead>
        <tr>
          <th>Type</th>
          <th>Created</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="email in emails">
          <td>{{ email.emailType }}</td>
          <td>{{ formatDate(email.created, "YYYY-MM-DD HH:mm:ss") }}</td>
        </tr>
      </tbody>
    </table>

    <portal to="actions">
      <ul>
        <li>
          <a href="" v-on:click.prevent="sendEmailUser('ApplicationReminder')">Application Reminder</a>
        </li>
        <li>
          <a href="" v-on:click.prevent="sendEmailUser('ApplicationAtt')">Applications</a>
        </li>
        <li>
          <a href="" v-on:click.prevent="sendEmailUser('Declined')">Declined</a>
        </li>
        <li>
          <a href="" v-on:click.prevent="sendEmailUser('AgreementAtt')">Agreements</a>
        </li>
        <li>
          <a href="" v-on:click.prevent="sendEmailUser('Approved')">Approved</a>
        </li>
      </ul>
    </portal>
  </div>
</template>

<script>
export default {
  props: ["item"],
  data: ()=>({
    emails: [],
  }),
  computed: {
  },
  methods: {
    listEmails: async function() {
      this.emails=(await api.list("ApplicationEmail", {appId: this.item.id}, ["id:DESC"])).items || [];
    },
    sendEmailUser: async function(type, resetPw) {
      if (!confirm("Send "+type+" email?"))
        return;
      await this.sendEmail(type, resetPw);
    },
    sendEmail: async function(type) {
      startLoading();
      var res=await api.create("ApplicationEmail", {appId: this.item.id, emailType: type, created: "CURRENT_TIMESTAMP"});
      stopLoading("Email sent");
      var newItem=await api.get("ApplicationEmail", res.id);
      if (newItem.item)
        this.emails.unshift(newItem.item);
    },
  },
  mounted() {
    this.listEmails();
  },
  mixins: [vueMixins.dateFormatter],
}
</script>
