window.vueMixins=window.vueMixins || {}

window.vueMixins.newAppSelector={
  data: function() { return {
    type: "cardbiller-ca",
    typePre: "cardbiller-ca",
  }},
  mounted: function() {
    window.solutionListeners=window.solutionListeners || [];
    window.solutionListeners.push(this);
    try {
      this.type=this.typePre=this.$children[0].options[0].value;
    } catch(e) { }
  },
}
