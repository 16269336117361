<template>
      <div>
        <div class="action-sub-buttons" v-if="!uwShown">
          <a class="btn" v-on:click="newDocCheck">Run new</a>
          <a class="btn" v-if="uwSelected" v-on:click.prevent="doShowItems(uwSelected)">View</a>
        </div>
        <div class="action-sub-buttons" v-else>
          <a class="btn" v-on:click="uwShown=null">Back</a>
          <a class="btn" :href="printUrl" target="_blank">Print</a>
        </div>
        <table class="file-list content-table" v-if="!uwShown">
          <thead>
            <tr>
              <th style="width: 1%"></th>
              <th>ID</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(uwItem, uwI) in uwList">
              <td><input type="radio" v-model="uwSelected" :value="uwItem.id"></td>
              <td>{{ uwList.length-uwI }}</td>
              <td>{{ formatDate(uwItem.createdDate, "YYYY-MM-DD HH:mm") }}</td>
            </tr>
          </tbody>
        </table>
        <div class="pre-check-details" v-else-if="uwShown">
          <div class="t2col toAnimate">
            <div style="width: 100%">
              <div v-for="(field, index) in fieldIds" class="field" :class="{visible: shownItem>index}">
                <label>{{ fields[field] }}:</label>
                <span v-if="uwShownItem.documents[field] && field=='agreement'">
                  <a target="_blank" :href="$parent.agreementPath(uwShownItem.documents[field])">{{ uwShownItem.documents[field].filename }}</a>
                </span>
                <span v-else-if="uwShownItem.documents[field]">
                  <a target="_blank" :href="$parent.fileLink(uwShownItem.documents[field].filename)">{{ uwShownItem.documents[field].filename }}</a>
                </span>
                <span class="empty" v-else>NO</span>
              </div>
            </div>
          </div>

        </div>
      </div>
</template>


<script>
export default {
  props: ["uwList", "preq"],
  data() { return {
    uwSelected: null,
    uwShown: null,    
    shownItem: 0,
    fields: {
      pParent: "Parent company incorporated documents",
      incor: "Incorporated documents",
      passport: "Passport copy",
      utility: "Recent Utility Bill",
      statement: "3 Months Company Bank Statement",
      voidCheck: "Void Check",
      processing: "5 Months Processing Statement",
      structure: "Corporate Structure Diagram",
      financial: "Financial Statements",
      licenses: "Copy of License(s)",
      pciDss: "PCI DSS Certificate",
      plan: "Business Plan with Projections",
      bankStatement: "Bank Statement",
      signedApplication: "Signed Merchant Application",
      agreement: "Agreement",
      signedAgreement: "Signed Agreement",
    },
  }},
  methods: {
    doShowItems(id, animate) {
      this.uwShown=id;
      var that=this;
      if (animate) {
        this.shownItem=0;
        clearInterval(this.shownItemInterval);
        this.shownItemInterval=setInterval(function() {
          that.shownItem++;
          if (that.shownItem>100)
            clearInterval(that.shownItemInterval);
        }, 300);
      } else {
        this.shownItem=100;
      }
    },
    listDocChecks: async function() {
      await this.$parent.listDocChecks();
    },
    newDocCheck: async function() {
      startLoading();
      var id=(await api.create("DocCheck", {})).id;
      
      var newItem={}
      
      newItem.id=null;
      newItem.applicationId=this.$parent.item.id;
      newItem.documents={};
      for(var i in this.$parent.documents) {
        var doc=this.$parent.documents[i];
        newItem.documents[i]={
          id: doc.id,
          created: doc.created,
          filename: doc.filename,
        };
      }
      if (this.$parent.agreements.length) {
        newItem.documents["agreement"]={
          id: this.$parent.agreements[0].id,
          filename: this.$parent.agreements[0].name,
        };
        if (this.$parent.agreements[0].filename) {
          newItem.documents["signedAgreement"]={
            id: this.$parent.agreements[0].id,
            filename: this.$parent.agreements[0].filename,
          };
        }
      }
      newItem.userId=appData.currentUser.id;
      newItem.createdDate="CURRENT_TIMESTAMP";
      
      await api.update("DocCheck", id, newItem);
      await this.listDocChecks();
      this.doShowItems(this.uwList[this.uwList.length-1].id, true);
      stopLoading();
    },
  },
  computed: {
    fieldIds() {
      var result=[];
      for(var i in this.fields)
        result.push(i);
      return result;
    },
    uwShownItem() {
      var result={};
      if (this.uwShown) {
        var item=this.uwList.find((a)=>a.id==this.uwShown);
        for(var i in item)
          result[i]=item[i];
      }
      return result;
    },
    printUrl() {
      if (!this.uwShownItem)
        return null;
      if (!appData.docCheckPrintUrl)
        return null;
      return appData.docCheckPrintUrl.replace("%d", this.uwShownItem.id);
    },
  },
  mixins: [vueMixins.dateFormatter],  
}
</script>
