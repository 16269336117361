<template>
  <div>
    <div class="action-sub-buttons" v-if="!uwShown">
      <a class="btn" v-on:click="newPreCheck">Run new</a>
      <a class="btn" v-if="uwSelected" v-on:click.prevent="doShowItems(uwSelected)">View</a>
    </div>
    <div class="action-sub-buttons" v-else>
      <a class="btn" v-on:click="uwShown=null">Back</a>
      <a class="btn" :href="printUrl" target="_blank">Print</a>
    </div>
    <table class="file-list content-table" v-if="!uwShown">
      <thead>
        <tr>
          <th style="width: 1%"></th>
          <th>ID</th>
          <th>Legal Name</th>
          <th>Date</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(uwItem, uwI) in uwList">
          <td><input type="radio" v-model="uwSelected" :value="uwItem.id"></td>
          <td>{{ uwList.length-uwI }}</td>
          <td>{{ uwItem.companyName }}</td>
          <td>{{ formatDate(uwItem.createdDate, "YYYY-MM-DD HH:mm") }}</td>
        </tr>
      </tbody>
    </table>
    <div class="pre-check-details" v-if="uwShown" ref="detailsBox">
      <div class="t2precheck">
        <div class="t2sect">
          <div class="t2box">
            <h3>General Information</h3>
            <ul>
              <li>
                <div class="field" :class="{visible: shownItem>4}">
                  <label>Solution:</label>
                  <span v-if="uwShownItem.solutions">{{ solutionName }}</span>
                  <span class="empty" v-else>NO</span>
                </div>
              </li>
              <li>
                <label>Status:</label>
                <span>{{ uwShownItem.status }}</span>
              </li>
              <li>
                <label>App ID:</label>
                <span v-if="uwShownItem.agentIdNum">{{ uwShownItem.agentIdNum }}</span>
                <span class="empty" v-else>NO</span>
              </li>
              <li>
                <label>Agent Type:</label>
                <span v-if="uwShownItem.groups">{{ groups[uwShownItem.groups] }}</span>
                <span class="empty" v-else>NO</span>
              </li>
              <li>
                <label>Agent Tier:</label>
                <span v-if="uwShownItem.agentClass">{{ uwShownItem.agentClass }}</span>
                <span class="empty" v-else>NO</span>
              </li>
              <li>
                <label>Relationship Manager:</label>
                <span v-if="uwShownItem.manager">{{ uwManager }}</span>
                <span class="empty" v-else>NO</span>
              </li>
              <li>
                <label>Referral Source:</label>
                <span v-if="uwShownItem.referrerSite">{{ uwShownItem.referrerSite }}</span>
                <span class="empty" v-else>NO</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="t2sect">
          <div class="t2box">
            <h3>Company Information</h3>
            <ul>
              <li>
                <label>Business Legal Name:</label>
                <span :class="{duplicate: uwShownItem.dupCompanyName}" v-if="uwShownItem.companyName">{{ uwShownItem.companyName }} {{ uwShownItem.companyType }}</span>
                <span class="empty" v-else>NO</span>
              </li>
              <li>
                <label>Business DBA:</label>
                <span :class="{duplicate: uwShownItem.dupCompanyNameTrade}" v-if="uwShownItem.companyNameTrade">{{ uwShownItem.companyNameTrade }}</span>
                <span class="empty" v-else>NO</span>
              </li>
              <li>
                <label>Business Address:</label>
                <span :class="{duplicate: uwShownItem.dupAddress}" v-if="uwShownItem.companyAddress">{{ uwShownItem.companyAddress }}</span>
                <span class="empty" v-else>NO</span>
              </li>
              <li>
                <label>Business Country:</label>
                <span :class="{duplicate: uwShownItem.dupAddress}" v-if="uwShownItem.companyCountry">{{ uwShownItem.companyCountry }}</span>
                <span class="empty" v-else>NO</span>
              </li>
              <li>
                <label>Business Email:</label>
                <span :class="{duplicate: uwShownItem.dupCompanyEmail}" v-if="uwShownItem.companyEmail">{{ uwShownItem.companyEmail }}</span>
                <span class="empty" v-else>NO</span>
              </li>
              <li>
                <label>Business Phone:</label>
                <span v-if="uwShownItem.companyPhone">{{ uwShownItem.companyPhone }}</span>
                <span class="empty" v-else>NO</span>
              </li>
              <li>
                <label>Business Website:</label>
                <span :class="{duplicate: uwShownItem.dupWebsite}" v-if="uwShownItem.website">{{ uwShownItem.website }}</span>
                <span class="empty" v-else>NO</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="t2sect">
          <div class="t2box">
            <h3>Principals Information</h3>
            <ul>
              <li>
                <label>Number of contacts:</label>
                <span :class="{duplicate: uwShownItem.dupContacts}" v-if="uwShownItem.contacts">{{ uwShownItem.contacts.length }}</span>
                <span class="empty" v-else>NO</span>
              </li>
              <template v-for="(contact, ci) in uwShownItem.contacts">
                <li>
                  <label>Contact {{ ci+1 }} First Name</label>
                  <span :class="{duplicate: uwShownItem.dupContacts}" v-if="contact.firstName">{{ contact.firstName }}</span>
                  <span class="empty" v-else>NO</span>
                </li>
                <li>
                  <label>Contact {{ ci+1 }} Last Name</label>
                  <span :class="{duplicate: uwShownItem.dupContacts}" v-if="contact.lastName">{{ contact.lastName }}</span>
                  <span class="empty" v-else>NO</span>
                </li>
                <li>
                  <label>Contact {{ ci+1 }} Email</label>
                  <span :class="{duplicate: uwShownItem.dupContacts}" v-if="contact.email">{{ contact.email }}</span>
                  <span class="empty" v-else>NO</span>
                </li>
                <li>
                  <label>Contact {{ ci+1 }} Phone</label>
                  <span :class="{duplicate: uwShownItem.dupContacts}" v-if="contact.phone">{{ contact.phone }}</span>
                  <span class="empty" v-else>NO</span>
                </li>
              </template>
            </ul>
          </div>
        </div>
        <div class="t2sect">
          <div class="t2box">
            <h3>Business Information</h3>
            <ul>
              <li>
                <label>Merchant Types:</label>
                <span v-if="uwShownItem.merchantTypes">{{ uwShownItem.merchantTypes }}</span>
                <span class="empty" v-else>NO</span>
              </li>
              <li>
                <label>Monthly Applications:</label>
                <span v-if="uwShownItem.monthlyApplications">{{ uwShownItem.monthlyApplications }}</span>
                <span class="empty" v-else>NO</span>
              </li>
              <li>
                <label>Monthly Volume:</label>
                <span v-if="uwShownItem.monthlyVolume">{{ uwShownItem.monthlyVolume+" "+(currencies[uwShownItem.monthlyVolumeCurrency] || "") }}</span>
                <span class="empty" v-else>NO</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="t2sect">
          <div class="t2box">
            <h3>Banking Information</h3>
            <ul>
              <template v-for="(subi, si) in uwShownItem.accounts">
                <li>
                  <label>Account Name:</label>
                  <span :class="{duplicate: uwShownItem.dupAccounts}" v-if="subi.accountHolder">{{ subi.accountHolder }}</span>
                  <span class="empty" v-else>NO</span>
                </li>
                <li>
                  <label>Account Nbr:</label>
                  <span v-if="subi.accountNumber">{{ subi.accountNumber }}</span>
                  <span class="empty" v-else>NO</span>
                </li>
                <li>
                  <label>Bank Name:</label>
                  <span v-if="subi.bankName">{{ subi.bankName }}</span>
                  <span class="empty" v-else>NO</span>
                </li>
                <li>
                  <label>Institution Nbr:</label>
                  <span v-if="subi.accountSwift">{{ subi.accountSwift }}</span>
                  <span class="empty" v-else>NO</span>
                </li>
                <li>
                  <label>Transit/Branch Nbr:</label>
                  <span v-if="subi.accountIBAN">{{ subi.accountIBAN }}</span>
                  <span class="empty" v-else>NO</span>
                </li>
                <li>
                  <label>ABA/Routing Nbr:</label>
                  <span v-if="subi.accountRouting">{{ subi.accountRouting }}</span>
                  <span class="empty" v-else>NO</span>
                </li>
              </template>
            </ul>
          </div>
        </div>
        <div class="t2sect">
          <div class="t2box">
            <h3>Documents Information</h3>
            <ul>
              <li>
                <label>Signed NDA:</label>
                <span>NO</span> <!-- TODO -->
              </li>
              <li>
                <label>Completed Sign-up Form:</label>
                <span v-if="uwShownItem.completedForm">YES</span>
                <span class="empty" v-else>NO</span>
              </li>
              <li>
                <label>Signed Sign-up Form:</label>
                <span v-if="uwShownItem.signedFrom">YES</span>
                <span class="empty" v-else>NO</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="t2sect">
          <div class="t2box">
            <h3>System</h3>
            <ul>
              <li>
                <label>CRISTINE Score:</label>
                <span>YES</span> <!-- TODO -->
              </li>
              <li>
                <label>Website Check Complete:</label>
                <span>NO</span> <!-- TODO -->
              </li>
              <li>
                <label>Compliance Review Days:</label>
                <span>365</span> <!-- TODO -->
              </li>
              <li>
                <label>Duplicates:</label>
              </li>
              <li v-if="uwShownItem.dupCompanyName">
                <label>Company Name: </label>
                <template v-for="dupId in uwShownItem.dupCompanyName.split(',')">
                  <a v-if="dupId[0]=='a'" _target="blank" :href="'/applications/'+dupId">{{ appIds[dupId.substring(1)] || dupId.substring(1) }}</a><a v-else _target="blank" :href="'/agents/'+dupId.substring(1)">{{ userIds[dupId.substring(1)] || dupId.substring(1) }}</a>&nbsp;
                </template>
              </li>
              <li v-if="uwShownItem.dupCompanyNameTrade">
                <label>Company Trade Name: </label>
                <template v-for="dupId in uwShownItem.dupCompanyNameTrade.split(',')">
                  <a v-if="dupId[0]=='a'" _target="blank" :href="'/applications/'+dupId.substring(1)">{{ appIds[dupId.substring(1)] || dupId.substring(1) }}</a><a v-else _target="blank" :href="'/agents/'+dupId.substring(1)">{{ userIds[dupId.substring(1)] || dupId.substring(1) }}</a>&nbsp;
                </template>
              </li>
              <li v-if="uwShownItem.dupCompanyEmail">
                <label>Company Email: </label>
                <template v-for="dupId in uwShownItem.dupCompanyEmail.split(',')">
                  <a v-if="dupId[0]=='a'" _target="blank" :href="'/applications/'+dupId">{{ appIds[dupId.substring(1)] || dupId.substring(1) }}</a><a v-else _target="blank" :href="'/agents/'+dupId.substring(1)">{{ userIds[dupId.substring(1)] || dupId.substring(1) }}</a>&nbsp;
                </template>
              </li>
              <li v-if="uwShownItem.dupWebsite">
                <label>Website: </label>
                <template v-for="dupId in uwShownItem.dupWebsite.split(',')">
                  <a v-if="dupId[0]=='a'" _target="blank" :href="'/applications/'+dupId.substring(1)">{{ appIds[dupId.substring(1)] || dupId.substring(1) }}</a><a v-else _target="blank" :href="'/agents/'+dupId.substring(1)">{{ userIds[dupId.substring(1)] || dupId.substring(1) }}</a>&nbsp;
                </template>
              </li>
              <li v-if="uwShownItem.dupAddress">
                <label>Address: </label>
                <template v-for="dupId in uwShownItem.dupAddress.split(',')">
                  <a v-if="dupId[0]=='a'" _target="blank" :href="'/applications/'+dupId.substring(1)">{{ appIds[dupId.substring(1)] || dupId.substring(1) }}</a><a v-else _target="blank" :href="'/agents/'+dupId.substring(1)">{{ userIds[dupId.substring(1)] || dupId.substring(1) }}</a>&nbsp;
                </template>
              </li>
              <li v-if="uwShownItem.dupAccounts">
                <label>Bank Account:</label>
                <template v-for="dupId in uwShownItem.dupAccounts">
                  <a v-if="dupId[2]=='a'" _target="blank" :href="'/applications/'+dupId[1]">{{ appIds[dupId[1]] || dupId[1] }}</a><a v-else _target="blank" :href="'/agents/'+dupId[1]">{{ appIds[dupId[1]] || dupId[1] }}</a>&nbsp;
                </template>
              </li>
              <li v-if="uwShownItem.dupContacts">
                Contacts:
                <template v-for="dupId in uwShownItem.dupContacts">
                  <a v-if="dupId[2]=='a'" _target="blank" :href="'/applications/'+dupId[1]">{{ appIds[dupId[1]] || dupId[1] }}</a><a v-else _target="blank" :href="'/agents/'+dupId[1]">{{ appIds[dupId[1]] || dupId[1] }}</a>&nbsp;
                </template>
              </li>
              <li>
              </li>
              <li>
              </li>
            </ul>
          </div>
        </div>
        <div class="t2sect">
          <div class="t2box">
            <h3>Pre-Check Score Report</h3>
            <ul>
              <li>
                <label>=</label> {{ validItems }} / 35
              </li>
              <li>
                <label>Negative List Report:</label>
                <span>{{ 0 }}</span>
              </li>
              <li>
                <label>Duplicate List Report:</label>
                <span>{{ dupCount }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <portal to="actions">
      <ul>
        <li>
          <a href="" v-on:click.prevent="newPreCheck()" v-if="!uwShown">New</a>
          <a href="" v-on:click.prevent="uwShown=null" v-else>Back</a>
        </li>
        <li v-if="!uwShown && uwSelected">
          <a href="" v-on:click.prevent="doShowItems(uwSelected)">View</a>
        </li>
        <li v-if="uwShown">
          <a :href="printUrl" target="_blank">Print</a>
        </li>
      </ul>
    </portal>
  </div>
</template>


<script>
export default {
  props: ["uwList", "preq"],
  data() { return {
    appIds: {},
    userIds: {},
    uwSelected: null,
    uwShown: null,    
    currencies: {
      "036": "AUD",
      "986": "BRL",
      "124": "CAD",
      "756": "CHF",
      "978": "EUR",
      "826": "GBP",
      "344": "HKD",
      "392": "JPY",
      "554": "NZD",
      "840": "USD",
    },
    shownItem: 0,
    uwManager: null,
    uwSource: null,
    groups: window.userGroups,
  }},
  methods: {
    doShowItems(id, animate) {
      this.uwShown=id;
      var that=this;
      if (animate) {
        this.$nextTick(()=>{
          var itemsToShow=$(this.$refs.detailsBox).find(".t2box, .t2box li, .t2box h4");
          var itemIndex=0;
          this.shownItemInterval=setInterval(()=>{
            itemsToShow.eq(itemIndex).addClass("visible");
            itemIndex++;
            if (itemsToShow.length==itemIndex)
              clearInterval(this.shownItemInterval);
          }, 150);
        });
      } else {
        this.$nextTick(()=>{
          $(this.$refs.detailsBox).find(".t2box, .t2box li, .t2box h4").addClass("visible");
        });
      }
      
      if (this.uwShownItem) {
        this.appIds={};
        this.userIds={};
        var duplicates={};
        for(var id of (this.uwShownItem.dupCompanyName || "").split(","))
          if (id) duplicates[id]=true;
        for(var id of (this.uwShownItem.dupCompanyEmail || "").split(","))
          if (id) duplicates[id]=true;
        for(var id of (this.uwShownItem.dupCompanyNameTrade || "").split(","))
          if (id) duplicates[id]=true;
        for(var id of (this.uwShownItem.dupWebsite || "").split(","))
          if (id) duplicates[id]=true;
        for(var id of (this.uwShownItem.dupAddress || "").split(","))
          if (id) duplicates[id]=true;
        for(var id of (this.uwShownItem.dupAccounts || []))
          duplicates[id[1]]=true;
        for(var id of (this.uwShownItem.dupContacts || []))
          duplicates[id[1]]=true;
        
        var dups=[];
        for(var i in duplicates)
          dups.push(i);
        
        this.dupCount=dups.length;
        if (dups.length && dups.join(","))
          api.list("Application", {
            id: ["IN ("+dups.filter((a)=>a[0]=="a").map((a)=>a.substring(1)).join(",")+")"]
          }).then(function(data) {
            for(var i in data.items)
              that.$set(that.appIds, data.items[i].id, data.items[i].applicationId);
          });
          api.list("User", {
            id: ["IN ("+dups.filter((a)=>a[0]=="u").map((a)=>a.substring(1)).join(",")+")"]
          }).then(function(data) {
            for(var i in data.items)
              that.$set(that.userIds, data.items[i].id, data.items[i].agentId);
          });
        
        
      }
      
    },
    listPreChecks: async function() {
      if (this.$parent.listPreChecks)
        await this.$parent.listPreChecks();
      else if (this.$parent.$parent.listPreChecks)
        await this.$parent.$parent.listPreChecks();
      else if (this.$parent?.$parent?.$parent?.$parent?.listPreChecks())
        await this.$parent?.$parent?.$parent?.$parent?.listPreChecks();
    },
    newPreCheck: async function() {
      startLoading();
      var id=(await api.create("PartnerPrecheck", {})).id;
      

      var newItem=JSON.parse(JSON.stringify(this.parentItem));
      delete newItem.agentDb;
      var fieldMap={
        "name": "companyName",
        "nameTrade": "companyNameTrade",
        "address": "companyAddress",
        "country": "companyCountry",
        "email": "companyEmail",
        "phone": "companyPhone",
      };
      for(var i in this.parentItem.agentDb) {
        var field=fieldMap[i] || i;
        newItem[field]=this.parentItem.agentDb[i];
      }
      newItem.agentId=this.parentItem.id;
      newItem.solutions=this.parentItem.agentDb.solutionId;

      newItem.id=null;
      newItem.userId=appData.currentUser.id;
      newItem.createdDate="CURRENT_TIMESTAMP";
      newItem.monthlyApplications=newItem.appMonthly;
      newItem.merchantTypes=(newItem.merchantTypes || []).join(", ");
      newItem.completedForm=true;
      if (this.$parent.signedApplications)
        newItem.signedFrom=!!this.$parent.signedApplications.length;
      if (this.$parent.$parent.signedApplications)
        newItem.signedFrom=!!this.$parent.$parent.signedApplications.length;
      
      await api.update("PartnerPrecheck", id, newItem);
      await this.listPreChecks();
      //this.doShowItems(this.uwList[this.uwList.length-1].id, true);
      if (this.uwList[0])
        this.doShowItems(this.uwList[0].id, true);
      stopLoading();
    },
  },
  computed: {
    parentItem() {
      return this.$parent.item || this.$parent.$parent.item;
    },
    validItems() {
      if (!this.uwShownItem)
        return 0;
      var i=this.uwShownItem;
      var invalid=0;
      
      invalid++; // Added for Intake form TODO
      
      if (!i.solutions) invalid++;
      if (!i.agentIdNum) invalid++;
      if (!i.groups) invalid++;
      if (!i.agentClass) invalid++;
      if (!i.manager) invalid++;
      if (!i.referrerSite) invalid++;
      if (!i.companyName) invalid++;
      if (!i.companyNameTrade) invalid++;
      if (!i.companyAddress) invalid++;
      if (!i.companyAddressCountry) invalid++;
      if (!i.companyEmail) invalid++;
      if (!i.website) invalid++;
      if (!i.contacts) invalid++;
      if (!i.merchantTypes) invalid++;
      if (!i.monthlyApplications) invalid++;
      if (!i.monthlyVolume) invalid++;
      if (!i.completedForm) invalid++;
      if (!i.signedFrom) invalid++;
      
      for(var subi of i.contacts) {
        if (!subi.firstName) invalid++;
      }
      
      for(var subi of i.accounts) {
        if (!subi.accountHolder) invalid++;
        if (!subi.accountNumber) invalid++;
        if (!subi.bankName) invalid++;
        if (!subi.accountIBAN) invalid++;
        if (!subi.accountBankCode) invalid++;
        if (!subi.accountRouting) invalid++;
      }


      
      return 35-invalid;
    },
    uwShownItem() {
      var result={};
      if (this.uwShown) {
        var item=this.uwList.find((a)=>a.id==this.uwShown);
        for(var i in item)
          result[i]=item[i];
        result.numberAccounts=result.accounts && result.accounts.length;
        result.accounts=(result.accounts && result.accounts.length)?result.accounts:[{}];
      }
      return result;
    },
    printUrl() {
      if (!this.uwShownItem)
        return null;
      if (!appData.preCheckPrintUrl)
        return null;
      return appData.preCheckPrintUrl.replace("%d", this.uwShownItem.id);
    },
    solutionName() {
      if (!this.uwShownItem)
        return null;
      for(var solution of appData.solutions) {
        if (solution.value==this.uwShownItem.solutions)
          return solution.label;
      }
      return null;
    },
  },
  mounted() {
    this.$parent.$parent.isPreCheck=true;
  },
  watch: {
    uwShown: async function() {
      this.uwManager=null;
      var item=(await api.get("User", this.uwShownItem.manager)).item;
      if (item)
        this.uwManager=item.fullName/*+" "+(item.lastName || "")*/;
    },
  },
  mixins: [vueMixins.dateFormatter],  
}
</script>
