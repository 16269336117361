<template>
<form class="edit-system">
  <div class="field">
    <label>PIN:</label>
    <input type="text" v-model="options.pin" />
  </div>
  <br />
  <div class="buttons">
    <button class="btn btn-large btn-primary" v-on:click.prevent="updateSettings">Submit</button>
  </div>  
</form>
</template>

<script>
export default {
  data() { return {
    options: {},
  }},
  mounted() {
    this.refresh();
  },
  methods: {
    refresh() {
      var that=this;
      api.get("Options", 1).then(function(data) {
        that.options=data.item;
      });
    },
    updateSettings: async function() {
      startLoading();
      await api.update("Options", 1, this.options);
      stopLoading("Options updated");
    },
  },
}
</script>
