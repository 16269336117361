<template>
  <div class="ncontent nc-flat">
    <tabs class="tabs">
      <tab
        title="MERCHANT CASH - ADVANCE"
        :active="open === 0"
        @click="open = 0"
      />
      <tab title="Language" :active="open === 1" @click="open = 1" />
      <tab title="Time Zone" :active="open === 2" @click="open = 2" />
    </tabs>

    <div class="ncontent opt-cont">
      <ul class="opt-setboxes" v-if="false">
        <li v-if="open === 0">
          <div class="osb-head">
            <span>MERCHANT CASH <br />ADVANCE</span>
          </div>
          <div class="osb-body">
            <label class="current"
              ><strong>You are eligible</strong> to <br />receive up to</label
            >
            <span class="value emph"><strong>$2373.95</strong></span>
            <label class="current"
              >in a <strong>Merchant<br />Cash Advance</strong></label
            >
            <form class="opts-f2">
              <div class="osb-btns">
                <a href="" class="osb-btn">Claim</a>
                <a href="" class="osb-btn">Free Eval</a>
              </div>
              <label>Last Eval: <strong>September 1, 2019</strong></label>
            </form>
          </div>
        </li>
        <li v-if="false">
          <div class="osb-head"><!--Invobiller--></div>
          <div class="osb-body">
            <!--<div class="osb-activate"><span>Activate your<br />Tax Plug-in</span></div>
          <form class="opts-f2">
            <div class="osb-btns">
              <a href="" class="osb-btn">Activate</a>
            </div>
          </form>-->
          </div>
        </li>
        <li v-if="open === 1">
          <div class="osb-head">Language</div>
          <div class="osb-body">
            <label class="current">Current Language:</label>
            <span class="value"><strong>English</strong></span>
            <form>
              <label>Select New Language:</label>
              <div class="select">
                <span>&nbsp;</span>
                <select>
                  <option>English</option>
                  <option>Français</option>
                </select>
              </div>
              <div class="osb-btns">
                <button>Update</button>
                <a href="" class="osb-btn">Cancel</a>
              </div>
            </form>
          </div>
        </li>
        <li v-if="open === 2">
          <div class="osb-head">Time Zone</div>
          <div class="osb-body">
            <label class="current">Current Time Zone:</label>
            <span class="value"><strong>GMT -5 (EST)</strong></span>
            <form>
              <label>Select New Time Zone:</label>
              <div class="select">
                <span>&nbsp;</span>
                <select>
                  <option>GMT -5</option>
                  <option>GMT -6</option>
                </select>
              </div>
              <div class="osb-btns">
                <button>Update</button>
                <a href="" class="osb-btn">Cancel</a>
              </div>
            </form>
          </div>
        </li>
      </ul>
      <div class="account-settings">
        <h2>Merchant Options</h2>
        <div class="module osb-module">
          <div class="mod-head">MERCHANT CASH<br />ADVANCE</div>
          <div class="mod-body osb-body cash-advance">
            <label class="current">
              <strong>You are eligible</strong> to receive up to
              <span class="emph"><strong>$2373.95</strong></span
              ><br />
              in a <strong>Merchant Cash Advance</strong>
            </label>

            <form class="opts-f2">
              <div class="osb-btns">
                <a href="" class="osb-btn">Claim</a>
                <a href="" class="osb-btn">Free Eval</a>
              </div>
              <label>Last Eval: <strong>September 1, 2019</strong></label>
            </form>
          </div>
        </div>
        <h2>Chammp Settings</h2>
        <div class="module osb-module">
          <div class="mod-head">Language</div>
          <div class="mod-body osb-body">
            <div>
              <label class="current">Current Language:</label>
              <span class="value"><strong>English</strong></span>
            </div>
            <form>
              <label>Select New Language:</label>
              <div class="select">
                <span>&nbsp;</span>
                <select>
                  <option>English</option>
                  <option>Français</option>
                </select>
              </div>
              <div class="osb-btns">
                <button>Update</button>
                <a href="" class="osb-btn">Cancel</a>
              </div>
            </form>
          </div>
        </div>
        <div class="module osb-module">
          <div class="mod-head">Time zone</div>
          <div class="mod-body osb-body">
            <div>
              <label class="current">Current Time Zone:</label>
              <span class="value"><strong>GMT -5 (EST)</strong></span>
            </div>
            <form>
              <label>Select New Time Zone:</label>
              <div class="select">
                <span>&nbsp;</span>
                <select>
                  <option>GMT -5</option>
                  <option>GMT -6</option>
                </select>
              </div>
              <div class="osb-btns">
                <button>Update</button>
                <a href="" class="osb-btn">Cancel</a>
              </div>
            </form>
          </div>
        </div>
        <h2>Payment Types</h2>
        <div class="module">
          <div class="mod-head">CardBiller</div>
          <div class="mod-body">
            <label
              class="active-toggle"
              :class="{ checked: user.configuration.cardBiller }"
              v-if="true"
            >
              <input type="checkbox" v-model="user.configuration.cardBiller" />
              <span>Active</span>
            </label>
            <label class="enroll-button" v-else>
              <a href="/support" class="misc">Enroll</a>
            </label>
          </div>
        </div>
        <div class="module">
          <div class="mod-head">CardBiller Plus</div>
          <div class="mod-body">
            <label
              class="active-toggle"
              :class="{ checked: user.configuration.cardBillerPlus }"
              v-if="true"
            >
              <input
                type="checkbox"
                v-model="user.configuration.cardBillerPlus"
              />
              <span>Active</span>
            </label>
            <label class="enroll-button" v-else>
              <a href="/support" class="misc">Enroll</a>
            </label>
          </div>
        </div>
        <div class="module">
          <div class="mod-head">BankBiller</div>
          <div class="mod-body">
            <label
              class="active-toggle"
              :class="{ checked: user.configuration.bankBiller }"
              v-if="true"
            >
              <input type="checkbox" v-model="user.configuration.bankBiller" />
              <span>Active</span>
            </label>
            <label class="enroll-button" v-else>
              <a href="/support" class="misc">Enroll</a>
            </label>
          </div>
        </div>
        <div class="module">
          <div class="mod-head">CoinBiller</div>
          <div class="mod-body">
            <label
              class="active-toggle"
              :class="{ checked: user.configuration.coinBiller }"
              v-if="true"
            >
              <input type="checkbox" v-model="user.configuration.coinBiller" />
              <span>Active</span>
            </label>
            <label class="enroll-button" v-else>
              <a href="/support" class="misc">Enroll</a>
            </label>
          </div>
        </div>
        <h2>Modules</h2>
        <div class="module" v-for="module in modules" :key="module.id">
          <div class="mod-head">{{ module.name }}</div>
          <div class="mod-body">
            <label
              class="active-toggle"
              :class="{ checked: user.configuration['mod' + module.id] }"
              v-if="moduleFound(module)"
            >
              <input
                type="checkbox"
                v-model="user.configuration['mod' + module.id]"
              />
              <span>Active</span>
            </label>
            <label class="enroll-button" v-else>
              <a href="/support" class="misc">Enroll</a>
            </label>
            <div class="addons">
              <a class="button">Activate Tax</a>
            </div>
          </div>
        </div>
        <h2>Account options</h2>
        <div
          class="module"
          v-for="option in options.filter(
            (option) => !(option.parent * 1) && option.mode == 'Toggle'
          )"
          :key="option.id"
        >
          <div class="mod-head">{{ option.name }}</div>
          <div class="mod-body">
            <label
              class="active-toggle"
              :class="{ checked: user.configuration['opt' + option.id] }"
              v-if="optionFound(option)"
            >
              <input
                type="checkbox"
                v-model="user.configuration['opt' + option.id]"
              />
              <span>Active</span>
            </label>
            <label class="enroll-button" v-else>
              <a href="/support" class="misc">Enroll</a>
            </label>
            <div class="addons">
              <a
                class="button"
                v-for="(subopt, index) in options.filter(
                  (option) => option.id == subopt.parent
                )"
                :key="index"
                >Activate {{ subopt.name.replace("CHAMMP", "") }}</a
              >
            </div>
          </div>
        </div>
        <h2>Plug-Ins</h2>
        <div class="module">
          <div class="mod-head">Tax Cloud</div>
          <div class="mod-body">
            <label
              class="active-toggle"
              :class="{ checked: user.configuration.taxCloud }"
              v-if="true"
            >
              <input type="checkbox" v-model="user.configuration.taxCloud" />
              <span>Active</span>
            </label>
            <label class="enroll-button" v-else>
              <a href="/support" class="misc">Enroll</a>
            </label>
          </div>
        </div>
        <div class="buttons" style="text-align: center; margin-top: 30px">
          <button
            class="btn btn-large btn-primary"
            v-on:click.prevent="updateSettings"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tab from "./components/tabs/Tab";
import Tabs from "./components/tabs/Tabs";

export default {
  components: {
    Tab,
    Tabs,
  },

  data() {
    return {
      open: 0,
      user: appData.currentUser,
      options: [],
      modules: [],
      agreement: {
        options: [],
        modules: [],
      },
    };
  },
  mounted() {
    this.$set(
      this.user,
      "configuration",
      this.user.configuration ? JSON.parse(this.user.configuration) : {}
    );
    this.loadOptions();
    this.loadModules();
    this.loadAgreement();
  },
  methods: {
    moduleFound(module) {
      for (var i in this.agreement.modules)
        if (this.agreement.modules[i].id == module.id) return true;
      return false;
    },
    optionFound(option) {
      for (var i in this.agreement.options)
        if (this.agreement.options[i].id == option.id) return true;
      return false;
    },
    loadOptions: async function () {
      this.options = (await api.list("CompanyOption")).items;
    },
    loadModules: async function () {
      this.modules = (await api.list("CompanyModule")).items;
    },
    loadAgreement: async function () {
      var result = (
        await api.list(
          "Agreement",
          { applicationId: appData.currentUser.applicationId },
          ["id:DESC"],
          1
        )
      ).items;
      if (result.length) this.agreement = result[0];
    },
    updateSettings: async function () {
      startLoading();
      await api.update("User", this.user.id, {
        configuration: this.user.configuration,
      });
      stopLoading();
    },
  },
};
</script>

<style lang="scss" scoped>
.ncontent.opt-cont {
  margin: 20px 0 50px 0;
}
</style>
