<template>
<div class="setupcl">
<ul>
  <li>
    <label>Profile Settings</label>
    <div class="done" v-if="isProfileDone"></div>
    <div class="actions" v-else>
      <a class="button" :class="" href="" v-on:click.prevent="setProfileFields">Set</a>
    </div>
  </li>
  <li>
    <label>Signed Sign-up Form</label>
    <div class="done" v-if="isSignedApp"></div>
    <div class="actions" v-else>
      <a class="button" :class="{disabled: !isProfileDone}" href="" v-on:click.prevent="isProfileDone && uploadSignedApplication()">Upload</a>
    </div>
  </li>
  <li>
    <label>Run Pre-Check</label>
    <div class="done" v-if="isPrecheckDone"></div>
    <div class="actions" v-else>
      <a class="button" :class="{disabled: !isSignedApp}" href="" v-on:click.prevent="isSignedApp && runPrecheck()">Run</a>
    </div>
  </li>
  <li>
    <label>Create Agreement</label>
    <div class="done" v-if="isAgreementDone"></div>
    <div class="actions" v-else>
      <a class="button" :class="{disabled: !isPrecheckDone}" href="" v-on:click.prevent="isPrecheckDone && $parent.startAgreement()">Start</a>
    </div>
  </li>
  <li>
    <label>Signed Agreement</label>
    <div class="done" v-if="isAgreementSigned"></div>
    <div class="actions" v-else>
      <a class="button" :class="{disabled: !isAgreementDone}" href="" v-on:click.prevent="isAgreementDone && signAgreement()">Upload</a>
    </div>
  </li>
  <li>
    <label>Compliance Scans</label>
    <div class="done" v-if="complianceDone2 || isApproved"></div>
    <div class="actions" v-else-if="complianceDone">
      <a class="button done" href="" v-on:click.prevent="isAgreementSigned && !secondaryPrecheck && markUnskipped('complianceDone', complianceDone=false)">Skipped</a>
    </div>
    <div class="actions" v-else>
      <a class="button" :class="{disabled: !isAgreementSigned}" href="" v-on:click.prevent="isAgreementSigned && (complianceDone=true) && markSkipped('complianceDone')">Skip</a>
      <a class="button" :class="{disabled: !isAgreementSigned}" href="" v-on:click.prevent="isAgreementSigned && (complianceDone=true) && (complianceDone2=true) && ($parent.actionsTab=3)">Scan</a>
    </div>
  </li>
  <li>
    <label>Pre-Check</label>
    <div class="done" v-if="secondaryPrecheck2 || isApproved"></div>
    <div class="actions" v-else-if="secondaryPrecheck">
      <a class="button done" href="" v-on:click.prevent="complianceDone &&  markUnskipped('secondaryPrecheck', secondaryPrecheck=false)">Skipped</a>
    </div>
    <div class="actions" v-else>
      <a class="button" :class="{disabled: !complianceDone}" href="" v-on:click.prevent="complianceDone && (secondaryPrecheck=true) && markSkipped('secondaryPrecheck')">Skip</a>
      <a class="button" :class="{disabled: !complianceDone}" href="" v-on:click.prevent="complianceDone && (secondaryPrecheck=true) && (secondaryPrecheck2=true) && runPrecheck()">Run</a>
    </div>
  </li>
  <li>
    <label>Final Approval</label>
    <div class="done" v-if="isApproved"></div>
    <div class="actions" v-else>
      <a class="button" :class="{disabled: !secondaryPrecheck}" href="" v-on:click.prevent="secondaryPrecheck && doFinalApproval()">Start</a>
    </div>
  </li>
  <li>
    <label>Boarding</label>
    <div class="actions">
      <a class="button" :class="{disabled: !isApproved}" href="" v-on:click.prevent="isApproved && goToBoarding()">Confirm</a>
    </div>
  </li>
  
  
</ul>
</div>
</template>

<script>
export default {
  props: ["item", "notes", "prechecks"],
  data: ()=>({
    complianceDone: false,
    complianceDone2: false,
    secondaryPrecheck: false,
    secondaryPrecheck2: false,
  }),
  computed: {
    actualParent() {
      return this.$parent.signedApplications?this.$parent:this.$parent.$parent;
    },
    isProfileDone() {
      if (!this.item.manager)
        return false;
      if (!this.item.agentDb.agentClass)
        return false;
      if (!this.item.agentDb.referrer)
        return false;
      if (!this.item.agentDb.language)
        return false;
      return true;
    },
    isSignedApp() {
      return !!this.actualParent.signedApplications.length
    },
    isPrecheckDone() {
      return !!(this.prechecks && this.prechecks.length)
    },
    isAgreementDone() {
      return !!this.actualParent.agreements.length
    },
    isAgreementSigned() {
      for(var agreement of this.actualParent.agreements) {
        if (agreement.signed)
          return true
        break
      }
    },
    isApproved() {
      return this.actualParent.item.agentDb.status=='A08' || this.actualParent.item.agentDb.status=='A04'
    },
  },
  methods: {
    setProfileFields() {
      agentProfilePopup.show(this.item);
    },
    uploadSignedApplication() {
      this.actualParent.uploadFile(this.actualParent.signedApplicationField)
    },
    runPrecheck() {
      this.actualParent.listPreChecks();
    },
    signAgreement() {
      for(var agreement of this.actualParent.agreements) {
        this.actualParent.uploadSigned(agreement, 0)
        break
      }
    },
    doFinalApproval() {
      this.actualParent.listFinalChecks();
      this.actualParent.showFinalApproval=true;
    },
    async goToBoarding() {
      var item=this.item;
      var id=(await api.create("PartnerSetup", {
        agentId: item.id,
        created: "CURRENT_TIMESTAMP",
        name: item.agentDb.companyName+" "+item.agentDb.agentId,
        accClass: "",
        managerId: item.agentDb.manager,
        filename: null,
        signed: null,
        salesBrand: null,
        solutions: [],
        regions: [],
        portal: 'trak',
        pin: null,
        username: null,
        buildId: "APP_ID_GEN_AGENTBUILD",
        buildBy: appData.currentUser.id,
      })).id;
      window.open("/implementation/agentsetup/"+id, "_self");
    },
    async markSkipped(field) {
      var update=this.item.agentDb.extradata || {};
      update[field]=true;
      await api.update("Partner", this.item.agentDb.id, {extradata: update});
      this.$set(this.item.agentDb, "extradata", update);
    },
    async markUnskipped(field) {
      var update=this.item.agentDb.extradata || {};
      update[field]=false;
      await api.update("Partner", this.item.agentDb.id, {extradata: update});
      this.$set(this.item.agentDb, "extradata", update);
    },
  },
  mounted() {
    var ed=this.item.agentDb.extradata || {};
    this.complianceDone=ed.complianceDone || false;
    this.secondaryPrecheck=ed.secondaryPrecheck || false;
  },
}
</script>
