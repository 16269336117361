<template>
<div>
  <scheduler-menu :page="currentPage" />

  <panel title="Add new Event">
    <template v-slot:actions>
      <ch-button color="gray" @click="clear()">Clear Event</ch-button>
      <ch-button @click="save()">Save Event</ch-button>
    </template>

    <create-event-form type="SCHEDULER" ref="addEvent" />
  </panel>
</div>
</template>

<script>
export default {
  data () {
    return {
      currentPage: {
        text: 'Add New Event',
        breadcrumbs: [{
          text: 'Calendar',
          to: '/tools/chammp-scheduler/calendar'
        }]
      }
    }
  },

  methods: {
    save () {
      this.$refs.addEvent.save()
    },

    clear () {
      this.$refs.addEvent.clear()
    }
  }
}
</script>
