<template>
  <div class="status-page">
    <div class="ss-head">
      <div class="publish-content">
        <h1>Change Log</h1>
      </div>
    </div>
    <div class="status-list">
      <ul>
        <li v-for="(item, index) in items" :class="{opened: opened[index]}">
          <div v-on:click.prevent="toggleOpened(index)">
            <a class="toggle" href="">
              <!--{{ opened[index]?'-':'+' }}-->
              {{ item.created }} - {{ item.version }}
            </a>
          </div>
          <ul v-if="opened[index]">
            <textarea disabled cols="100" rows="20">
              {{ item.description }}
            </textarea>
          </ul>
        </li>
      </ul>
    </div>
  </div>
  </template>
  
  <script>
  export default {
    data() { return {
      items: [],
      opened: [],
    }},
    methods: {
      toggleOpened(index) {
        this.$set(this.opened, index, !this.opened[index]);
      },
      async loadItems() {
        this.items=(await api.list("Changelog", {}, ["id:DESC"])).items;
        this.opened=this.items.map(a=>false);
      },
    },

    mounted() {
      this.loadItems();
    },
  }
  </script>
  <style lang="scss" scoped>
    textarea {
      border: none;
    }
  </style>