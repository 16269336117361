<template>
<div>
  <amadel-menu :page="currentPage" />
  
  <button class="btn btn-large align-center" @click="$refs.upload.click()">Select to Upload</button>
  <input v-show="false" ref="upload" type="file" @change="uploadFile" />

  <div v-if="errors" class="ncontent wide data-transfer-validation">
    <h3 class="error">File not accepted. Validation has failed.</h3>
    <p class="info-text text-center">Fields marked red are the ones that did not pass our validation.</p>
    <div class="table-wrapper">
      <table class="normal-table">
        <thead>
          <tr>
            <td>Row</td>
            <td v-for="column in errors.columns" :key="column">
              {{ column }}
            </td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(err, idx) in errors.errors" :key="idx">
            <td>{{ idx }}</td>
            <td v-for="(val, i) in errors.data[idx]" :key="`td${i}`"
              :class="{ error: err.includes(i) }">
              {{ val }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <p class="info-text">Validation Rules:</p>
    <ul>
      <li v-for="rule in displayErrors" :key="rule.name">- {{ rule.value }}</li>
    </ul>

    <button @click="errors = null" class="btn btn-xs">I understand</button>
  </div>

  <div class="ncontent">
    <div class="header">
      <data-transfer-history :data="data" />
    </div>
  </div>
</div>
</template>

<script>
export default {
  data () {
    return {
      currentPage: {
        text: 'Data Transfer',
        to: '/agents/data-transfer'
      },
      data: [],
      rules: {
        SourceID: 'Invalid Source ID',
        LeadType: 'Invalid Lead Type, only PA-RQ-TP',
        RecordID: 'Invalid RecordID',
        MethodType:	'Invalid Method Type, only POS-ECOM-MOB-Multi-other',
        BusinessIndustry: 'Invalid Business Industry',
        BusinessName: 'Invalid Business Name, numbers and letters, max 50, required',
        BusinessDBA: 'Invalid Business DBA numbers and letters, max 50, not required',
        BusinessType:	'Invalid Business Type, only Inc.-Corp-LLC-LLP-SoleP-Other',
        BusinessAddress: 'Invalid Business Address',
        BusinessCity: 'Invalid Business City',
        'BusinessProvince/State': 'Invalid Business Province/State two letters only',
        'BusinessPostal/Zip': 'Invalid Business Postal/Zip',
        BusinessCountry: 'Invalid Business Country, only CA, US',
        BusinessPhone: 'Invalid Business Phone',
        BusinessEmail: 'Invalid Business Email',
        BusinessWeb: 'Invalid Business Web',
        BusinessDesc: 'Invalid Business Desc',
        ContactDate: 'Invalid Contact Date, Format should be MM/DD/YYYY',
        Contact1FName: 'Invalid Contact 1 First Name',
        Contact1LName: 'Invalid Contact 1 Last Name',
        Contact1Email: 'Invalid Contact 1 Email',
        Contact1Phone: 'Invalid Contact 1 Phone',
        Contact2FName: 'Invalid Contact 2 First Name',
        Contact2LName: 'Invalid Contact 2 Last Name',
        Contact2Email: 'Invalid Contact 2 Email',
        Contact2Phone: 'Invalid Contact 2 Phone',
        MthlyVol: 'Invalid MthlyVol',
        Vol12Mth: 'Invalid Vol12Mth',
        HighTicket: 'Invalid HighTicket',
        AvgTicket: 'Invalid Avg Ticket',
        LowTicket: 'Invalid Low Ticket',
        CurrentlyAcc: 'Invalid Currently Acc only Y or N',
        EverAccept: 'Invalid Ever Accept only Y or N',
        TransCurr: 'Invalid Trans Curr'
      },
      errors: null
    }
  },

  computed: {
    displayErrors () {
      const errors = []
      Object.values(this.errors.errors).forEach(err => {
        err.forEach(idx => {
          if (errors.indexOf(idx) === -1) errors.push(idx)
        })
      })
      return errors.map(idx => {
        return {
          name: Object.keys(this.rules)[idx],
          value: Object.values(this.rules)[idx],
        }
      })
    }
  },

  created () {
    this.getData()
  },

  methods: {
    async getData () {
      this.data = (await api.list('AmadelDataTransferFile', {}, ["created:DESC"])).items
    },

    async uploadFile ($e) {
      startLoading()
      const file = $e.target.files[0]
      if (!this.isFileXlsx(file)) return stopLoading('This file type is not allowed.', 'error')
      
      const payload = {
        created: "CURRENT_TIMESTAMP",
        userId: window.appData.currentUser.id
      }
      const id = (await api.create("AmadelDataTransferFile", payload)).id
      await api.upload("AmadelDataTransferFile", id, file)
      const response = await api.action("AmadelDataTransferFile", id, "ValidateFile")

      this.errors = (response.action.errors) ? response.action : null

      if (response.action.error) stopLoading(response.action.error, 'error')
      else if (response.action.errors && Object.keys(response.action.errors).length) stopLoading('Validation has failed.', 'error')
      else stopLoading('File uploaded successfully.')
      
      this.getData()
    },

    isFileXlsx (file) {
      const ext = file.name.substr(file.name.lastIndexOf('.') + 1)
      return ['xls', 'xlsx'].includes(ext)
    }
  },
}
</script>
