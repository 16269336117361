<template>
  <view-profile :header="['Profile Detail']">
    
    <div v-if="customer" class="merchant-panel">
      <div class="content-wrapper">
        <panel title="Profile">
          <template v-slot:actions>
            <span class="status">{{ customer.accountType == 'Personal' ? customer.personal.status : customer.business.status }} - {{ statusDescription }}</span>
          </template>

          <formview-treasury-main :customer="customer" :tab="0" :notes="notes" :columns="5" />
        </panel>
        <panel v-if="!!customer.business" title="Company" class="mt-20">
          <formview-treasury-main :customer="customer" :tab="1" :notes="notes" :bg="false" :columns="5" :simplified="true" />
        </panel>
        <panel title="Contacts" class="mt-20">
          <formview-treasury-main :customer="customer" :tab="2" :notes="notes" :bg="false" :columns="5" />
        </panel>
        <panel title="Documents" class="mt-20">
          <treasury-documents :item="customer" :portal-buttons="false" :simplified="true" />
        </panel>

        <panel title="Accounts" class="mt-20" />
        <panel title="Settings" class="mt-20" />
      </div>
    </div>
  </view-profile>
</template>

<script>
import Panel from '../common/Panel'

export default {
  props: {
    id: {
      type: String,
      required: true
    }
  },

  comments: {
    Panel
  },

  data () {
    return {
      customer: null,
      notes: [],
      statusDescription: null
    }
  },
  
  created () {
    this.loadNotes();
    this.loadCustomer();
  },

  methods: {
    async loadNotes () {
      const filter = {
        type: 'Customer',
        parentId: this.id
      }
      this.notes = (await api.list("Note", filter, ['id:ASC'])).items || [];
    },

    async loadCustomer () {
      const response = await api.get('TreasuryAccount', this.id)
      this.customer = response.item
      if(this.customer.accountType == 'Personal') {
        var status = (await api.list('Status', { status: this.customer.personal.status})).items
      }else{
        var status = (await api.list('Status', { status: this.customer.business.status})).items
      }
      this.statusDescription = status[0].description
    }
  }
}
</script>

<style lang="scss" scoped>
  .content-wrapper {
    width: 100%;
    ::v-deep .panel {
      max-width: 100%;
    }
  }
  .mt-20 {
    margin-top: 20px;
  }
</style>