var regexValidator=function(regex) {
  return function(value) {
    return !!(""+value).match(regex);
  }
}
var regexValidatorWithNull=function(regex) {
  return function(value) {
    if (value===null || value===undefined || value==="")
      return true;
    return !!(""+value).match(regex);
  }
}


formValidation={
  mixin: {
    methods: {
      validateReset: function(element) {
        element=element || this;
        for(var i in element.$children) {
          var child=element.$children[i];
          if (child.$options.name=="validator") {
            child.submitted=false;
          }
          this.validateReset(child);
        }
      },
      validate: function(element) {
        element=element || this;
        var isValid=true;
        for(var i in element.$children) {
          var child=element.$children[i];
          if (child.$options.name=="validator") {
            child.submitted=true;
            if (!child.valid)
              isValid=false;
          }
          if (!this.validate(child))
            isValid=false;
        }
        return isValid;
      },
    },
    data: function() { return {
      submitted: false,
    } },
  },
  boolean: function(value) { return value*1; },
  required: function(value) { return value!==null && value!==undefined && !!(""+value).length; },
  email: regexValidatorWithNull(/.+@.+\..+/),
  phone: regexValidatorWithNull(/^[0-9 +-]{1,20}$/),
  password: function(value) { return true; }, // TODO
  url: regexValidatorWithNull(/^(https?\:\/\/)?.+\..+\/?.*$/),
  number: regexValidatorWithNull(/^[0-9]+\.?[0-9]*$/),
  numberPercent: regexValidatorWithNull(/^[0-9%]+\.?[0-9]*$/),
  numberPercentWhole: regexValidatorWithNull(/^(100|[0-9]{1,2})$/),
  sum100: function(value) {
    var sum=0;
    for(var i in value)
      sum+=value[i]*1 || 0;
    return sum==100;
  },
  letters: regexValidatorWithNull(/^[, a-zA-ZÀàÂâÆæÇçÉéÈèÊêËëÎîÏïÔôŒœÙùÛûÜüŸÿ]+$/),
  legalName: regexValidatorWithNull(/^[-, a-zA-Z0-9ÀàÂâÆæÇçÉéÈèÊêËëÎîÏïÔôŒœÙùÛûÜüŸÿ]{0,50}$/),
  regnum: regexValidatorWithNull(/^[a-zA-Z0-9ÀàÂâÆæÇçÉéÈèÊêËëÎîÏïÔôŒœÙùÛûÜüŸÿ]{1,20}$/),
  descriptor: regexValidatorWithNull(/^[ 0-9a-zA-Z]{0,22}$/),
  address: regexValidatorWithNull(/^[/#, 0-9a-zA-ZÀàÂâÆæÇçÉéÈèÊêËëÎîÏïÔôŒœÙùÛûÜüŸÿ]{0,50}$/),
  city: regexValidatorWithNull(/^[, a-zA-ZÀàÂâÆæÇçÉéÈèÊêËëÎîÏïÔôŒœÙùÛûÜüŸÿ]{0,30}$/),
  postcode: regexValidatorWithNull(/^[-, 0-9a-zA-ZÀàÂâÆæÇçÉéÈèÊêËëÎîÏïÔôŒœÙùÛûÜüŸÿ]{0,20}$/),
  postcode2: regexValidatorWithNull(/^[0-9a-zA-Z]{3,3}-[0-9a-zA-Z]{3,3}$/),
  state: regexValidatorWithNull(/^[-–, a-zA-ZÀàÂâÆæÇçÉéÈèÊêËëÎîÏïÔôŒœÙùÛûÜüŸÿ]{0,30}$/),
  country: regexValidatorWithNull(/^[-, a-zA-ZÀàÂâÆæÇçÉéÈèÊêËëÎîÏïÔôŒœÙùÛûÜüŸÿ]{0,30}$/),
  charmin5: function(value) { return (value || "").length>=5; },
  char10: function(value) { return (value || "").length<10; },
  char100: function(value) { return (value || "").length<100; },
  char250: function(value) { return (value || "").length<250; },
  char500: function(value) { return (value || "").length<500; },
  char1000: function(value) { return (value || "").length<1000; },
  numval10: function(value) { return ((value+"").split(" ")[0]*1 || 0)<10000000000; },
  num5: regexValidatorWithNull(/^[0-9.]{0,5}$/),
  num6: regexValidatorWithNull(/^[0-9.]{0,6}$/),
  num10: regexValidatorWithNull(/^[0-9.]{0,10}$/),
  num3e: regexValidatorWithNull(/^[0-9.]{3,3}$/),
  num5e: regexValidatorWithNull(/^[0-9.]{5,5}$/),
  num9e: regexValidatorWithNull(/^[0-9.]{9,9}$/),
  num20: regexValidatorWithNull(/^[0-9.]{0,20}$/),
  alnum15: regexValidatorWithNull(/^[ a-zA-ZÀàÂâÆæÇçÉéÈèÊêËëÎîÏïÔôŒœÙùÛûÜüŸÿ0-9.]{0,15}$/),
  alnum20: regexValidatorWithNull(/^[ a-zA-ZÀàÂâÆæÇçÉéÈèÊêËëÎîÏïÔôŒœÙùÛûÜüŸÿ0-9.]{0,20}$/),
  alnum30: regexValidatorWithNull(/^[ a-zA-ZÀàÂâÆæÇçÉéÈèÊêËëÎîÏïÔôŒœÙùÛûÜüŸÿ0-9.]{0,30}$/),
  businessType: regexValidatorWithNull(/^[ .A-Za-zÀàÂâÆæÇçÉéÈèÊêËëÎîÏïÔôŒœÙùÛûÜüŸÿ]{0,50}$/),
  money: regexValidatorWithNull(/^[0-9.]+ [0-9]{3,3}$/),
}


formValidation.boolean.message="Choose at least one option";
formValidation.required.message="This field is requred";
formValidation.email.message="Invalid email";
formValidation.phone.message="Invalid phone number";
formValidation.password.message="Invalid password";
formValidation.number.message="Invalid number";
formValidation.numberPercent.message="Invalid number";
formValidation.numberPercentWhole.message="Invalid number";
formValidation.url.message="Invalid URL";
formValidation.sum100.message="Fields have to total 100";
formValidation.regnum.message="Letters and numbers only, max 20 characters";
formValidation.legalName.message="Letters and numbers only, max 50 characters";
formValidation.descriptor.message="Letters and numbers only, max 22 characters";
formValidation.address.message="Letters, numbers and # only, max 50 characters";
formValidation.city.message="Letters only, max 30 characters";
formValidation.state.message="Letters only, max 30 characters";
formValidation.country.message="Letters only, max 30 characters";
formValidation.letters.message="Letters only";
formValidation.businessType.message="Letters only, max 50 characters";
formValidation.postcode.message="Letters and numbers only, max 20 characters";
formValidation.postcode2.message="Invalid postcode";
formValidation.charmin5.message="Min 5 characters";
formValidation.char10.message="Max 10 characters";
formValidation.char100.message="Max 100 characters";
formValidation.char250.message="Max 250 characters";
formValidation.char500.message="Max 500 characters";
formValidation.char1000.message="Max 1000 characters";
formValidation.num5.message="Numbers only, Max 5 characters";
formValidation.num6.message="Numbers only, Max 6 characters";
formValidation.num10.message="Numbers only, Max 10 characters";
formValidation.numval10.message="Max 10 numbers";
formValidation.num20.message="Numbers only, Max 20 characters";
formValidation.num9e.message="Numbers only, exactly 9 characters";
formValidation.num3e.message="Numbers only, exactly 3 characters";
formValidation.num5e.message="Numbers only, exactly 5 characters";
formValidation.alnum15.message="Letters and numbers only, Max 15 characters";
formValidation.alnum20.message="Letters and numbers only, Max 20 characters";
formValidation.alnum30.message="Letters and numbers only, Max 30 characters";
formValidation.money.message="Both amount and currency are required";


window.formValidation=formValidation;
