<template>
<form class="form">
  <div class="inline-form-group">
    <div class="form-group">
      <label>Date:</label>
      <validator :value="form.date" reqs="required">
        <datepicker v-model="form.date" type="text" :future="true" />
      </validator>
    </div>
    <div class="form-group">
      <label>Time:</label>
      <validator class="inline-block" :value="form.hours" reqs="required">
        <select v-model="form.hours">
          <option v-for="i in 12" :key="i">{{ i }}</option>
        </select>
      </validator>
      
      <validator class="inline-block" :value="form.minutes" reqs="required">
        <select v-model="form.minutes">
          <option v-for="t in timeOptions" :key="t" :value="t">{{ t }}</option>
        </select>
      </validator>
      
      <validator class="inline-block" :value="form.am_pm" reqs="required">
        <select v-model="form.am_pm">
          <option v-for="type in amPm" :key="type" :value="type">{{ type }}</option>
        </select>
      </validator>
    </div>
  </div>
  
  <tag-employees ref="tagEmployees" v-if="isScheduler" v-model="selectedEmployees" />

  <div class="form-group">
    <label>Task:</label>
    <validator :value="form.text" reqs="required">
      <textarea v-model="form.text" maxlength="300" class="w100" />
    </validator>
  </div>
</form>
</template>

<script>
export default {
  mixins: [ formValidation.mixin ],

  props: {
    type: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      form: {},
      timeOptions: ['00', 15, 30, 45],
      amPm: ['AM', 'PM'],
      selectedEmployees: []
    }
  },

  computed: {
    isScheduler () {
      return this.type === 'SCHEDULER'
    }
  },

  created () {
    this.clear()
  },

  methods: {
    async save () {
      if (!this.validate()) return stopLoading('Please fill all required fields', 'error')
      startLoading()
      try {
        const payload = {
          text: this.form.text,
          date: moment(`${this.form.date} ${this.form.hours}:${this.form.minutes} ${this.form.am_pm}`, 'YYYY-MM-DD h:mm A').format('YYYY-MM-DD HH:mm:ss'),
          created: 'CURRENT_TIMESTAMP',
          createdBy: appData.currentUser.id,
          type: this.type
        }
        const task = (await api.create("TaskList", payload, true)).item

        if (this.selectedEmployees.length) {
          const allEmployees = this.$refs.tagEmployees.employees
          const props = this.selectedEmployees.map(id => {
            return { employee_id: id, user_id: allEmployees.find(emp => emp.id === id).userId.id }
          })
          await api.update("TaskList", task.id, { employees: props })
        }

        this.$emit('save', task)
        this.clear()
        this.validateReset()
        stopLoading('Task added successfully.')
      } catch (e) {
        stopLoading(e, 'error')
      }
    },

    clear () {
      this.form = {
        text: null,
        date: null,
        hours: null,
        minutes: null,
        am_pm: null
      }
      this.selectedEmployees = []
    }
  }
}
</script>
