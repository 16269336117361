<template>
<div>
  <p>Account Settings</p>
  <br/>
  <ul class="appview-list">
    <li>
      <label>Provider</label>
      <validator :value="item.provider" reqs="required">
        <dyn-select :options="providerOptions" v-model="item.provider"></dyn-select>
      </validator>
    </li>
    <li>
      <label>Label</label>
      <div>
        {{ selectedProvider? selectedProvider.label : '' }}
      </div>
    </li>
    <li>
      <label>Account Type</label>
      <div>
        {{ item.accountType }}
      </div>
    </li>
    <li>
      <label>Template</label>
      <validator :value="item.pricingId" reqs="required">
        <dyn-select :disabled="!item.provider" :options="templateOptions" v-model="item.pricingId"></dyn-select>
      </validator>
    </li>
  </ul>
  <ul class="appview-list">
    <li>
      <label><b>Verifications</b></label>
    </li>
    <li>
      <label>Administrative</label>
      <div>
        <mnb-switch :disabled="!item.provider"  v-model="item.administrative"/>
      </div>
    </li>
    <li>
      <label>Remote Deposit Capture</label>
      <div>
        <mnb-switch :disabled="!item.provider" v-model="item.remoteDepositCapture"/>
      </div>
    </li>
    <li>
      <label>Double Verification</label>
      <div :value="item.provider" reqs="required">
        <mnb-switch :disabled="!item.provider" v-model="item.doubleVerification"/>
      </div>
    </li>
  </ul>

  <p>Treasury Pricing Template</p>
  <br/>
  <pricing-details-treasury :currentTemplate="item.pricing" :solution="solutionId" v-if="solutionId" :parentTemplate="currentTemplate" :boarding="true" />

  <p>Profile Settings</p>
  <br/>
  <ul class="appview-list">
    <li>
      <label><b>Permissions</b></label>
    </li>
    <li>
      <label>Create Sub-users</label>
      <div>
        <mnb-switch :disabled="!item.provider" v-model="item.createSubUsers"/>
      </div>
    </li>
  </ul>

  <ul class="appview-list">
    <li>
      <label><b>Options</b></label>
    </li>
    <li>
      <label>Welcome Email</label>
      <validator :value="item.welcomeEmail" reqs="required">
        <dyn-select :disabled="!item.provider" :options="welcomeOptions" v-model="item.welcomeEmail"></dyn-select>
      </validator>
    </li>
    <li>
      <label>Profile Review Days</label>
      <validator :value="item.profileReviewDays" reqs="required">
        <dyn-select :disabled="!item.provider" :options="daysOptions" v-model="item.profileReviewDays"></dyn-select>
      </validator>
    </li>
    <li>
      <label>Language</label>
      <validator :value="item.language" reqs="required">
        <dyn-select :disabled="!item.provider" :options="lanuageOptions" v-model="item.language"></dyn-select>
      </validator>
    </li>
  </ul>


  <div class="actions">
    <button :disabled="!validate()" v-on:click.prevent="doNext">Save & Continue</button>
  </div>
</div>
</template>

<script>
import MnbSwitch from './../switch.vue'
export default {
  components: {
    MnbSwitch,
  },
  
  props: ["item"],

  data: ()=>({
    providers: [],
    templates: [],
    settings: {},
    currentTemplate: null,
  }),

  async created() {
    if(this.item.accountType == 'Personal') {
      var solution = (await api.get('Solution', this.item.personal.solution)).item
    }else{
      var solution = (await api.get('Solution', this.item.business.solution)).item
    }
    
    var connexPromise=api.list("Provider", {providerType: 'Banking', status: ['!=', 'Archived']});
    var providers=(await connexPromise).items;
    for(var provider of providers) {
      if(solution.provider.length > 0) {
        for(var serviceProvider of solution.provider) {
          if(provider.id == serviceProvider) {
            this.providers.push(provider);
          }
        }
      }
    }
    this.templates = (await api.list('PricingTemplate', {'solution': solution.id})).items
    this.settings=(await api.get("Settings", 1)).item;
  },

  computed: {
    solutionId() {
      if(this.item.accountType == 'Personal') {
        return this.item.personal.solution;
      }
      if(this.item.accountType == 'Business') {
        return this.item.business.solution;
      }
      return null
    },

    providerOptions() {
      var options = [{
        value: '',
        label: ''
      }]
      this.providers.map(function(a) {
        options.push(
          {
            value: a.id,
            label: a.providerName,
          }
        )
      });
      return options;
    },

    selectedProvider() {
      return this.providers.find(item => item.id == this.item.provider)
    },

    templateOptions() {
      var options = [{
        value: '',
        label: ''
      }]
      var that = this
      this.templates.map(function(a) {
        if(a.accountType.includes(that.item.accountType)) {
          options.push(
            {
              value: a.id,
              label: a.name,
            }
          )
        }else{
          options.push(
            {
              value: a.id,
              label: a.name,
              disabled: true
            }
          )
        }
      });
      
      options.push(
        {
          value: 0,
          label: 'Custom'
        }
      )
      return options;
    },

    welcomeOptions() {
      var options = [
        {
          value: '',
          label: ''
        },
        {
          value: 1,
          label: 'Yes'
        },
        {
          value: 0,
          label: 'No'
        }
      ]
      return options;
    },

    daysOptions() {
      var options = [
        {
          value: '',
          label: ''
        },
        {
          value: '30',
          label: '30'
        },
        {
          value: '60',
          label: '60'
        },
        {
          value: '90',
          label: '90'
        },
        {
          value: '180',
          label: '180'
        },
        {
          value: '365',
          label: '365'
        }
      ]
      return options;
    },
    
    lanuageOptions() {
      var options = [{
        value: '',
        label: ''
      }]
      if(this.settings.language) {
        for(var i = 0; i < this.settings.language.length; i++){
          options.push(
            {
              value: this.settings.language[i],
              label: this.settings.language[i],
            }
          )
        };
      }
      
      return options;
    },
  },

  watch: {
    "item.provider": {
      handler() {
        this.item.pricingId = null
        this.item.administrative = false
        this.item.remoteDepositCapture = false
        this.item.doubleVerification = false
        this.item.createSubUsers = false
        this.item.welcomeEmail = null
        this.item.profileReviewDays = null
        this.item.language = null
      }
    },

    "item.pricingId" : {
      handler() {
        var that=this;
        if (this.item.pricingId*1) {
          this.item.pricing=JSON.parse(JSON.stringify(this.templates.find(function(a) {
            return a.id==that.item.pricingId;
          }) || {}));
          this.currentTemplate=JSON.parse(JSON.stringify(this.item.pricing));
        } else {
          this.currentTemplate=null;
          this.item.pricing={
            name: "",
            flatRatePricing:"", interchangePricing:"", tieredPricing:"",
            tier1:"", tier2:"", tier3:"",
            liteAccessMonthly:"", treasuryAccessMonthly:"", fullAccessMonthly:"",
            swipedTappedInserted:"", swipedTappedInsertedTx:"",
            swipedTappedInsertedInt:"", eCommerceKeyed:"",
            eCommerceKeyedTx:"", eCommerceKeyedInt:"",
            customPay:"", customPayTx:"",
            interacInsertedSwiped:"", interacInsertedSwipedTx:"",
            interacTappedFlash:"",interacTappedFlashTx:"",
            interacOnline:"",
            bbTransactionRate:"",
            bbTransactionFee:"",
            bbRejectsFee:"",
            bbReturnsFee:"",
            bbLateReturnsFee:"",
            bbUnauthorizedReturns:"",
            bbAuthorizationNonCompliance:"",
            multiCurrency:"",
            additionalTid:"",
            chargebacks:"",
            chammpGuard:"",
            chammpGuardPlus:"",
            chammpAlerts:"",
            pciNonCompliance:"",
            systemRiskFee:"",
            accountChanges:"",
            eftAchFee:"",
            wireFee:"",
            internationalWireFee:"",
            equipmentUpgradeFee:"",
            equipmentSwapFee:"",
            equipmentTradeBuyBack:"",
            financeFee:"",
            tidActivations:"",
            cardBiller:"",
            cardBillerPlus:"",
            coinBiller:"",
            bankBiller:"",
            invoBiller:"",
            virtualTerminal:"",
            chammpCheckout:"",
            chammpApiApiKey:"",
            chammpGuardOpt:"",
            chammpGuardPlusOpt:"",
            chammpNotifications:"",
            coinSwipedTappedInserted:"",
            coinSwipedTappedInsertedTx:"",
            coineCommerceKeyed:"",
            coineCommerceKeyedTx:"",
            pricingModules:{},
            pricingOptions:{},
            retrievals:"",
            reversals:"",
            ivrAuthorizations:"",
            voiceAuthorizations:"",
            tokenization:"",
            level2Processing:"",
            level3Processing:"",
            terminalSupport:"",
            dailyPricing:"",
            monthlyPricing:"",
            liteAccessMonthlyToggle:"",
            fullAccessMonthlyToggle:"",
            amexMonthly:null,
            amex:null,
            amexTx:null,
            amexIntl:null,
            debitTx:null,
            debitTx1:null,
            debitTx2:null,
            debitTx3:null,
            cbAsessment:null,
            cbAsessment2:null,
            cbAsessment3:null,
            cbPlusMontly:null,
            cbPlus:null,
            cbPlusTx:null,
            cbPlusInt:null,
            bbSetup:null,
            bbMontly:null,
            bbNonComp:null,
            bbRate:null,
            bbTx:null,
            bbBatch:null,
            bbRejects:null,
            bbReturns:null,
            bbUnauthorized:null,
            bbLate:null,
            bbAuthShield:"",
            bbAuthShieldMontly:null,
            bbAuthVerTx:null,
            bbAuthScreenTx:null,
            bbTransBox:"",
            bbTransBoxMonthly:null,
            bbVaultTx:null,
            bbRecurringTx:null,
            chammpBilling:"",
            chammpBillingSetup:null,
            chammpBillingMontly:null,
            vivpos:"",
            vivposSetup:null,
            vivposMonthly:null,
            payAxis:"",
            payAxisSetup:null,
            payAxisMonthly:null,
            chammpMobile:"",
            chammpMobileSetup:null,
            chammpMobileMonthly:null,
            chammpGift:"",
            chammpGiftSetup:null,
            chammpGiftMonthly:null,
            chammpRewards:"",
            chammpRewardsSetup:null,
            chammpRewardsMonthly:null,
            tids:"",
            tidsIncluded:null,
            tidsFee:null,
            keys:"",
            keysIncluded:null,
            keysFee:null,
            multiCurrencyToggle:"",
            multiCurrencyMonthly:null,
            virtualTerminalToggle:"",
            virtualTerminalMonhly:null,
            chammpCheckoutMonthly:null,
            wirelessActivation:null,
            wirelessData:null,
            titan:null,
            titanPlus:null,
            batchFee:null,
            autoAccountUpdater:null,
            avs:null,
            cellularFee:null,
            autoAccountUpdaterM:null,
            chammpAlertsM:null,
            dataSecurityFee:null,
            freeTerminal:"",
            freeTerminalIncluded:null,
            referralAuthorization:null,
            wirelessFee:"",
            wirelessCDMA:"",
            wirelessGPRS:"",
            applicationFee:"",
            accountSetup:"",
            annualFee:"",
            mailedStatements:"",
            returnItemNsf:"",
            protectionPlan:"",
            protectionPlanPlatinum:"",
            cbOnSite:"",
            methods:[],
            cbPlusToggle:"",
            bbToggle:"",
            accessPlan:null,
            billingModel:null,
            billingOptions:null,
            solution: this.solutionId,
            feeSchedule : {
              volumeFee : {
                text: 'Volume Fee',
                fee: null 
              },
              monthlyAccessFee: { 
                text: 'Monthly Access Fee',
                fee: null 
              },
              domesticWire: { 
                text: 'Domestic Wire',
                per_deposit: null, 
                per_withdrawal: null, 
              },
              internationalWire: { 
                text: 'International Wire',
                per_deposit: null, 
                per_withdrawal: null, 
              },
              ach: { 
                text: 'ACH',
                per_deposit: null, 
                per_withdrawal: null, 
              },
              accountTransfer: { 
                text: 'Account Transfer',
                per_withdrawal: null, 
              },
              checks: { 
                text: 'Checks',
                per_deposit: null, 
                per_withdrawal: null, 
              },
              exchange: { 
                text: 'Exchange',
                fee: null, 
              },
              assets: { 
                text: 'Assets',
                fee: null, 
                per_deposit: null, 
                per_withdrawal: null, 
              },
              dormantAccounts: { 
                text: 'Dormant Accounts',
                fee: null, 
              },
              debitCard: { 
                text: 'Debit Card',
                fee: null, 
                per_withdrawal: null, 
              },
              modules: [],
              exchangeDesksetup: null,
              exchangeDeskmonthly: null,
              tradeDesksetup: null,
              tradeDeskmonthly: null,
              options: null,
            },
            accountType:null,
            accountLimitsDaily:null,
            accountLimitsMonthly:null,
          };
        }
      },
    }
  },
  
  methods: {
    async doNext() {
      if (!this.validate())
        return stopLoading("Please fill all required fields", "error");
      await this.save();
      this.$emit("next");
    },

    async save() {
      startLoading();
      var updateItem = JSON.parse(JSON.stringify(this.item));
      await api.update("TreasuryAccount", this.item.id, updateItem);
      stopLoading("Updated");
    },
  },
  

  mixins: [formValidation.mixin]
}
</script>
<style scoped>
  .appview-list li label {
    font-weight: 500;
  }
</style>
