<template>
<div>
  <scheduler-menu :page="currentPage" />
 
  <task-list :type="type" />
</div>
</template>

<script>
export default {
  data () {
    return {
      type: 'SCHEDULER',
      currentPage: {
        text: 'Task List',
        breadcrumbs: [{
          text: 'Calendar',
          to: '/tools/chammp-scheduler/calendar'
        }]
      }
    }
  }
}
</script>
