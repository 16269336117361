<template>
  <div>
    <template>
      <template>
        <div class="content-tlist" style="padding: 30px;">
          <basic-form>
            <label>Maintenance Message</label>
            <textarea rows="10" class="fullw" v-model="systemStatus.maintenanceMessage" :disabled="!updateInformations"></textarea>
            <br/>
            <br/>
            <h4>Section 1 </h4>
            <mnb-input v-model="systemStatus.section1Name" label="Category" :maxlength="150" :disabled="!updateInformations" />
          </basic-form>
          <div class="content-tlist">
            <table class="content-table">
              <tr>
                <th style="width: 20%;">Name</th>
                <th style="width: 20%;">Item Message</th>
                <th style="width: 20%;">Color</th>
                <th style="width: 20%;">Downtime</th>
                <th style="width: 20%;">Options</th>
              </tr>
              <tr v-for="(item, i) in systemStatus.section1Items">
                <td>
                  <template v-if="updateInformations">
                    <input type="text" class="split" v-model="item.name" />
                  </template>
                  <template v-else>
                    {{ item.name }}
                  </template>
                </td>
                <td>
                  <template v-if="updateInformations">
                    <input type="text" class="split" v-model="item.message" />
                  </template>
                  <template v-else>
                    {{ item.message }}
                  </template>
                </td>
                <td>
                  <template v-if="updateInformations">
                    <select v-model="item.color">
                      <option value="green">Green</option>
                      <option value="yellow">Yellow</option>
                      <option value="red">Red</option>
                      <option value="black">Black</option>
                    </select>
                  </template>
                  <template v-else>
                    <div class="color" :class="item.color"></div>
                  </template>
                </td>
                <td>
                  <template v-if="updateInformations">
                    <select v-model="item.downtime">
                      <option value="Operational">Operational</option>
                      <option value="Maintenance">Maintenance</option>
                      <option value="Down">Down</option>
                      <option value="Offline">Offline</option>
                    </select>
                  </template>
                  <template v-else>
                    {{ item.downtime }}
                  </template>
                </td>
                <td>
                  <button v-if="updateInformations" @click="removeStatusItem1(i)" class="btn btn-xs btn-danger">Remove</button>
                </td>
              </tr>
              <tr v-for="(item, i) in itemList1" :key="i">
                <td>
                  <input type="text" class="split" v-model="item.name" />
                </td>
                <td>
                  <input type="text" class="split" v-model="item.message" />
                </td>
                <td>
                  <select v-model="item.color">
                    <option value="green">Green</option>
                    <option value="yellow">Yellow</option>
                    <option value="red">Red</option>
                    <option value="black">Black</option>
                  </select>
                </td>
                <td>
                  <select v-model="item.downtime">
                    <option value="Operational">Operational</option>
                    <option value="Maintenance">Maintenance</option>
                    <option value="Down">Down</option>
                    <option value="Offline">Offline</option>
                  </select>
                </td>
                <td>
                  <button @click="removeItem1(i)" class="btn btn-xs btn-danger">Remove</button>
                </td>
              </tr>
            </table>
          </div>
          <basic-form>
            <button class="btn btn-xs btn-success" style="margin-left: 30px;" :disabled="!updateInformations" @click="addItem1()">+ Add Item</button>
          </basic-form>
          
          <basic-form>
            <br/>
            <br/>
            <h4>Section 2 </h4>
            <mnb-input v-model="systemStatus.section2Name" label="Category" :maxlength="150" :disabled="!updateInformations"/>
          </basic-form>
          <div class="content-tlist">
            <table class="content-table">
              <tr>
                <th style="width: 20%;">Name</th>
                <th style="width: 20%;">Item Message</th>
                <th style="width: 20%;">Color</th>
                <th style="width: 20%;">Downtime</th>
                <th style="width: 20%;">Options</th>
              </tr>
              <tr v-for="(item, i) in systemStatus.section2Items">
                <td>
                  <template v-if="updateInformations">
                    <input type="text" class="split" v-model="item.name" />
                  </template>
                  <template v-else>
                    {{ item.name }}
                  </template>
                </td>
                <td>
                  <template v-if="updateInformations">
                    <input type="text" class="split" v-model="item.message" />
                  </template>
                  <template v-else>
                    {{ item.message }}
                  </template>
                </td>
                <td>
                  <template v-if="updateInformations">
                    <select v-model="item.color">
                      <option value="green">Green</option>
                      <option value="yellow">Yellow</option>
                      <option value="red">Red</option>
                      <option value="black">Black</option>
                    </select>
                  </template>
                  <template v-else>
                    <div class="color" :class="item.color"></div>
                  </template>
                </td>
                <td>
                  <template v-if="updateInformations">
                    <select v-model="item.downtime">
                      <option value="Operational">Operational</option>
                      <option value="Maintenance">Maintenance</option>
                      <option value="Down">Down</option>
                      <option value="Offline">Offline</option>
                    </select>
                  </template>
                  <template v-else>
                    {{ item.downtime }}
                  </template>
                </td>
                <td>
                  <button v-if="updateInformations" @click="removeStatusItem2(i)" class="btn btn-xs btn-danger">Remove</button>
                </td>
              </tr>
              <tr v-for="(item, i) in itemList2" :key="i">
                <td>
                  <input type="text" class="split" v-model="item.name" />
                </td>
                <td>
                  <input type="text" class="split" v-model="item.message" />
                </td>
                <td>
                  <select v-model="item.color">
                    <option value="green">Green</option>
                    <option value="yellow">Yellow</option>
                    <option value="red">Red</option>
                    <option value="black">Black</option>
                  </select>
                </td>
                <td>
                  <select v-model="item.downtime">
                    <option value="Operational">Operational</option>
                    <option value="Maintenance">Maintenance</option>
                    <option value="Down">Down</option>
                    <option value="Offline">Offline</option>
                  </select>
                </td>
                <td>
                  <button @click="removeItem2(i)" class="btn btn-xs btn-danger">Remove</button>
                </td>
              </tr>
            </table>
          </div>
          <basic-form>
            <button class="btn btn-xs btn-success" style="margin-left: 30px;" :disabled="!updateInformations" @click="addItem2()">+ Add Item</button>
          </basic-form>

          <basic-form>
            <br/>
            <br/>
            <h4>Section 3 </h4>
            <mnb-input v-model="systemStatus.section3Name" label="Category" :maxlength="150" :disabled="!updateInformations"/>
          </basic-form>
          <div class="content-tlist">
            <table class="content-table">
              <tr>
                <th style="width: 20%;">Name</th>
                <th style="width: 20%;">Item Message</th>
                <th style="width: 20%;">Color</th>
                <th style="width: 20%;">Downtime</th>
                <th style="width: 20%;">Options</th>
              </tr>
              <tr v-for="(item, i) in systemStatus.section3Items">
                <td>
                  <template v-if="updateInformations">
                    <input type="text" class="split" v-model="item.name" />
                  </template>
                  <template v-else>
                    {{ item.name }}
                  </template>
                </td>
                <td>
                  <template v-if="updateInformations">
                    <input type="text" class="split" v-model="item.message" />
                  </template>
                  <template v-else>
                    {{ item.message }}
                  </template>
                </td>
                <td>
                  <template v-if="updateInformations">
                    <select v-model="item.color">
                      <option value="green">Green</option>
                      <option value="yellow">Yellow</option>
                      <option value="red">Red</option>
                      <option value="black">Black</option>
                    </select>
                  </template>
                  <template v-else>
                    <div class="color" :class="item.color"></div>
                  </template>
                </td>
                <td>
                  <template v-if="updateInformations">
                    <select v-model="item.downtime">
                      <option value="Operational">Operational</option>
                      <option value="Maintenance">Maintenance</option>
                      <option value="Down">Down</option>
                      <option value="Offline">Offline</option>
                    </select>
                  </template>
                  <template v-else>
                    {{ item.downtime }}
                  </template>
                </td>
                <td>
                  <button v-if="updateInformations" @click="removeStatusItem3(i)" class="btn btn-xs btn-danger">Remove</button>
                </td>
              </tr>
              <tr v-for="(item, i) in itemList3" :key="i">
                <td>
                  <input type="text" class="split" v-model="item.name" />
                </td>
                <td>
                  <input type="text" class="split" v-model="item.message" />
                </td>
                <td>
                  <select v-model="item.color">
                    <option value="green">Green</option>
                    <option value="yellow">Yellow</option>
                    <option value="red">Red</option>
                    <option value="black">Black</option>
                  </select>
                </td>
                <td>
                  <select v-model="item.downtime">
                    <option value="Operational">Operational</option>
                    <option value="Maintenance">Maintenance</option>
                    <option value="Down">Down</option>
                    <option value="Offline">Offline</option>
                  </select>
                </td>
                <td>
                  <button @click="removeItem3(i)" class="btn btn-xs btn-danger">Remove</button>
                </td>
              </tr>
            </table>
          </div>
          <basic-form>
            <button class="btn btn-xs btn-success" style="margin-left: 30px;" :disabled="!updateInformations" @click="addItem3()">+ Add Item</button>
          </basic-form>

          <basic-form>
            <br/>
            <br/>
            <h4>Section 4 </h4>
            <mnb-input v-model="systemStatus.section4Name" label="Category" :maxlength="150" :disabled="!updateInformations"/>
          </basic-form>
          <div class="content-tlist">
            <table class="content-table">
              <tr>
                <th style="width: 20%;">Name</th>
                <th style="width: 20%;">Item Message</th>
                <th style="width: 20%;">Color</th>
                <th style="width: 20%;">Downtime</th>
                <th style="width: 20%;">Options</th>
              </tr>
              <tr v-for="(item, i) in systemStatus.section4Items">
                <td>
                  <template v-if="updateInformations">
                    <input type="text" class="split" v-model="item.name" />
                  </template>
                  <template v-else>
                    {{ item.name }}
                  </template>
                </td>
                <td>
                  <template v-if="updateInformations">
                    <input type="text" class="split" v-model="item.message" />
                  </template>
                  <template v-else>
                    {{ item.message }}
                  </template>
                </td>
                <td>
                  <template v-if="updateInformations">
                    <select v-model="item.color">
                      <option value="green">Green</option>
                      <option value="yellow">Yellow</option>
                      <option value="red">Red</option>
                      <option value="black">Black</option>
                    </select>
                  </template>
                  <template v-else>
                    <div class="color" :class="item.color"></div>
                  </template>
                </td>
                <td>
                  <template v-if="updateInformations">
                    <select v-model="item.downtime">
                      <option value="Operational">Operational</option>
                      <option value="Maintenance">Maintenance</option>
                      <option value="Down">Down</option>
                      <option value="Offline">Offline</option>
                    </select>
                  </template>
                  <template v-else>
                    {{ item.downtime }}
                  </template>
                </td>
                <td>
                  <button v-if="updateInformations" @click="removeStatusItem4(i)" class="btn btn-xs btn-danger">Remove</button>
                </td>
              </tr>
              <tr v-for="(item, i) in itemList4" :key="i">
                <td>
                  <input type="text" class="split" v-model="item.name" />
                </td>
                <td>
                  <input type="text" class="split" v-model="item.message" />
                </td>
                <td>
                  <select v-model="item.color">
                    <option value="green">Green</option>
                    <option value="yellow">Yellow</option>
                    <option value="red">Red</option>
                    <option value="black">Black</option>
                  </select>
                </td>
                <td>
                  <select v-model="item.downtime">
                    <option value="Operational">Operational</option>
                    <option value="Maintenance">Maintenance</option>
                    <option value="Down">Down</option>
                    <option value="Offline">Offline</option>
                  </select>
                </td>
                <td>
                  <button @click="removeItem4(i)" class="btn btn-xs btn-danger">Remove</button>
                </td>
              </tr>
            </table>
          </div>
          <basic-form>
            <button class="btn btn-xs btn-success" style="margin-left: 30px;" :disabled="!updateInformations" @click="addItem4()">+ Add Item</button>
          </basic-form>
          <br/>
          <br/>
          <br/>
          <br/>
          <basic-form style="text-align: center;">
            <template v-if="updateInformations">
              <button class="btn btn-xs btn-success" @click="save()">Submit</button>
              <button class="btn btn-xs btn-danger" @click="cancel()">Cancel</button>
            </template>
            <template v-else>
              <button class="btn btn-xs btn-success" @click="updateInformations = true">Update</button>
            </template>
          </basic-form>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
  import { required, maxLength } from 'vuelidate/lib/validators'
  import BasicForm from './components/ui/forms/Form'
  import MnbInput from './components/ui/forms/Input'
  import MnbSelect from './components/ui/forms/Select'

  import mixinAutoResize from "./components/ui/forms/autoresize.js"
  import DateTimePicker from 'vue-vanilla-datetime-picker';

  export default {
    mixins: [mixinAutoResize],

    components: {
      MnbInput,
      BasicForm,
      MnbSelect,
      DateTimePicker,
    },

    data () {
      return {
        form: {
          maintenanceMessage: null,
          section1Name: null,
          section1Items: null,
          section2Name: null,
          section2Items: null,
          section3Name: null,
          section3Items: null,
          section4Name: null,
          section4Items: null,
        },
        updateInformations: false,
        today: moment("00:00:00", "HH:mm:ss").format("YYYY-MM-DD"),
        itemList1: [],
        itemList2: [],
        itemList3: [],
        itemList4: [],
        systemStatus: null
      }
    },

    computed: {
      statusColorClasses () {
        return {
          Sent: 'c1',
          Resent: 'c2',
          Archived: 'c2',
          Error: 'c3'
        }
      },
    },

    watch: {

    },

    async created () {
      this.systemStatus =((await api.get("SystemStatus", 1)).item || this.form);
    },

    methods: {
      addItem1() {
        this.itemList1.push(
          {
            name: null,
            message: null,
            color: null,
            downtime: null
          }
        )
      },

      addItem2() {
        this.itemList2.push(
          {
            name: null,
            message: null,
            color: null,
            downtime: null
          }
        )
      },

      addItem3() {
        this.itemList3.push(
          {
            name: null,
            message: null,
            color: null,
            downtime: null
          }
        )
      },

      addItem4() {
        this.itemList4.push(
          {
            name: null,
            message: null,
            color: null,
            downtime: null
          }
        )
      },

      removeItem1(i) {
        this.itemList1.splice(i,1)
      },

      removeItem2(i) {
        this.itemList2.splice(i,1)
      },

      removeItem3(i) {
        this.itemList3.splice(i,1)
      },

      removeItem4(i) {
        this.itemList4.splice(i,1)
      },

      removeStatusItem1(i) {
        this.systemStatus.section1Items.splice(i,1)
      },

      removeStatusItem2(i) {
        this.systemStatus.section2Items.splice(i,1)
      },

      removeStatusItem3(i) {
        this.systemStatus.section3Items.splice(i,1)
      },

      removeStatusItem4(i) {
        this.systemStatus.section4Items.splice(i,1)
      },

      cancel() {
        this.itemList1 = []
        this.itemList2 = []
        this.itemList3 = []
        this.itemList4 = []
        this.updateInformations = false
      },

      async save () {
        this.$v.$touch()
        if (!this.$v.$invalid) {
          startLoading();
          try {
            this.systemStatus.updatedAt = 'CURRENT_TIMESTAMP'
            if(this.systemStatus.section1Items == null)
              this.systemStatus.section1Items = []
            for(var i = 0; i < this.itemList1.length; i++){
              this.systemStatus.section1Items.push(
                this.itemList1[i]
              )
            }

            if(this.systemStatus.section2Items == null)
              this.systemStatus.section2Items = []
            for(var i = 0; i < this.itemList2.length; i++){
              this.systemStatus.section2Items.push(
                this.itemList2[i]
              )
            }

            if(this.systemStatus.section3Items == null)
              this.systemStatus.section3Items = []
            for(var i = 0; i < this.itemList3.length; i++){
              this.systemStatus.section3Items.push(
                this.itemList3[i]
              )
            }

            if(this.systemStatus.section4Items == null)
              this.systemStatus.section4Items = []
            for(var i = 0; i < this.itemList4.length; i++){
              this.systemStatus.section4Items.push(
                this.itemList4[i]
              )
            }

            api.update("SystemStatus", 1, this.systemStatus);
            
          } catch (e) {
            this.$toast.error(window.errorMessage)
          } finally {
            stopLoading("Status updated");
            this.itemList1 = []
            this.itemList2 = []
            this.itemList3 = []
            this.itemList4 = []
            this.updateInformations = false
         }
        }
      },
    },

    validations() {
      const rules = {
        form: {

        }
      }
      return rules
    }
  }
</script>

<style lang="scss" scoped>
  .fullw {
    width: 100%!important;
  }
  .color {
    height: 22px;
    border-radius: 4px;
    width: 50px;
  &.green {
     background: #7EEECE;
   }
  &.yellow {
     background: #fbecbe;
   }
  &.red {
     background: #f9a0a0;
   }
  &.black {
     background: #ccd5dd;
   }
  }
</style>
