<template>
  <div>
    <tabs>
      <tab title="Profile" :active="open === 0" @click="open = 0" />
      <template v-if="hasSubUsers && mainUser">
        <tab title="Sub-users" :active="open === 2" @click="open = 1" />
        <tab title="Agents" :active="open === 3" @click="open = 1" />
      </template>
      <tab title="Login Activity" :active="open === 4" @click="open = 1" />
    </tabs>

    <ul class="up-acc">
      <li v-if="open === 0" class="open">
        <div class="title">Partner Profile</div>
        <div class="content">
          <div class="general">
            <div class="uinfo">
              <ul class="ui-prop">
                <li>
                  <label>Username</label>
                  <div>
                    {{ mainUser ? profile.username : currentUser.username }}
                  </div>
                </li>
                <li>
                  <label>Partner Type</label>
                  <div>{{ groups[profile.groups] }}</div>
                </li>
              </ul>
              <a
                href=""
                class="tool-btn"
                v-on:click.prevent="resetPassword"
                v-if="mainUser"
                >Reset password</a
              >
            </div>
            <aside v-if="manager">
              <div>
                <label>Relationship Manager:</label> {{ manager.fullName }}
              </div>
              <div>
                {{ manager.email }}, {{ manager.phone }},
                {{ manager.phoneExt ? "ext " + manager.phoneExt : "" }}
              </div>
              <div>
                <label>Partner ID #:</label>
                {{ mainUser ? profile.agentId : currentUser.agentId }}
              </div>
              <div><label>Support PIN #:</label> {{ profile.agentDb.pin }}</div>
            </aside>
          </div>
          <ul class="mo-info">
            <li>
              <ul>
                <li>
                  <label>Name:</label>
                  <div>{{ profile.agentDb.name }}</div>
                </li>
                <li>
                  <label>Address:</label>
                  <div>{{ profile.agentDb.address }}</div>
                </li>
                <li>
                  <label>City:</label>
                  <div>{{ profile.agentDb.city }}</div>
                </li>
                <li>
                  <label>Postal/Zip Code:</label>
                  <div>{{ profile.agentDb.zip }}</div>
                </li>
                <li>
                  <label>Province/State:</label>
                  <div>{{ profile.agentDb.state }}</div>
                </li>
                <li>
                  <label>Country:</label>
                  <div>{{ profile.agentDb.country }}</div>
                </li>
                <li>
                  <label>E-mail:</label>
                  <div>{{ profile.agentDb.email }}</div>
                </li>
                <li>
                  <label>Phone:</label>
                  <div>{{ profile.agentDb.phone }}</div>
                </li>
              </ul>
            </li>
            <li v-for="contact in profile.contacts" :key="contact.id">
              <ul>
                <li>
                  <label>Contact First Name:</label>
                  <div>
                    {{ mainUser ? contact.firstName : currentUser.fullName }}
                  </div>
                </li>
                <li>
                  <label>Contact Last Name:</label>
                  <div>{{ (mainUser ? contact : currentUser).lastName }}</div>
                </li>
                <li>
                  <label>Contact Phone:</label>
                  <div>{{ (mainUser ? contact : currentUser).phone }}</div>
                </li>
                <li>
                  <label>Contact E-mail:</label>
                  <div>{{ (mainUser ? contact : currentUser).email }}</div>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </li>

      <template v-if="hasSubUsers && mainUser">
        <li v-if="open === 2" class="open">
          <div class="title">Sub-users</div>
          <div class="acc-list">
            <table>
              <thead>
                <tr>
                  <th style="width: 10%">ID</th>
                  <th style="width: 15%">First name</th>
                  <th style="width: 15%">Last name</th>
                  <th style="width: 15%">Email</th>
                  <th style="width: 30%">Options</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in subusers" :key="item.id">
                  <td class="text-left">
                    <a :href="'/profile/subuser/' + item.id">{{
                      item.agentId
                    }}</a>
                  </td>
                  <td class="text-left">
                    {{ item.fullName }}
                  </td>
                  <td class="text-left">
                    {{ item.lastName }}
                  </td>
                  <td class="text-left">
                    {{ item.email }}
                  </td>
                  <td class="text-right">
                    <a
                      v-if="profile != item && mainUser"
                      class="remove"
                      href=""
                      v-on:click.prevent="deleteSubuser(index)"
                      >Delete</a
                    >
                  </td>
                </tr>
                <tr v-if="mainUser">
                  <td colspan="6">
                    <a href="/your-profile/newsub">New sub-user</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </li>

        <li v-if="open === 3" class="open">
          <div class="title">Agents</div>
          <div class="acc-list">
            <table>
              <thead>
                <tr>
                  <th style="width: 10%">ID</th>
                  <th style="width: 15%">First name</th>
                  <th style="width: 15%">Last name</th>
                  <th style="width: 15%">Email</th>
                  <th style="width: 30%">Options</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in agents" :key="item.id">
                  <td class="text-left">
                    <a :href="'/profile/sub/' + item.id">{{
                      item.subAgentID
                    }}</a>
                  </td>
                  <td class="text-left">
                    {{ item.firstName }}
                  </td>
                  <td class="text-left">
                    {{ item.lastName }}
                  </td>
                  <td class="text-left">
                    {{ item.email }}
                  </td>
                  <td class="text-right">
                    <a
                      v-if="profile != item && mainUser"
                      class="remove"
                      href=""
                      v-on:click.prevent="deleteUser(index)"
                      >Delete</a
                    >
                  </td>
                </tr>
                <tr v-if="mainUser">
                  <td colspan="6">
                    <a href="/your-profile/new">New agent</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </li>
      </template>

      <li v-if="open === 4" class="open">
        <div class="title">Login Activity</div>
        <div class="acc-list">
          <table>
            <thead>
              <tr>
                <th style="width: 33%">Username</th>
                <th style="width: 33%">Time</th>
                <th style="width: 34%">IP Address</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in logins" :key="index">
                <td class="main-col">
                  {{
                    usersById[item.userId] && usersById[item.userId].username
                  }}
                </td>
                <td>
                  {{ item.recorded }}
                </td>
                <td>
                  {{ item.ipAddress }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import Tab from "./components/tabs/Tab";
import Tabs from "./components/tabs/Tabs";

export default {
  components: {
    Tab,
    Tabs,
  },

  data() {
    return {
      profile: { agentDb: {} },
      manager: {},
      applications: [],
      users: [],
      agents: [],
      subusers: [],
      mainUser: false,
      open: 0,
      groups: window.userGroups,
      currentUser: appData.currentUser,
    };
  },
  computed: {
    hasSubUsers() {
      return ["agent-accex", "agent-sales"].indexOf(this.profile.groups) == -1;
    },
    usersById() {
      var result = {};
      for (var i in this.users) result[this.users[i].id] = this.users[i];
      result[this.profile.id] = this.profile;
      return result;
    },
  },
  mounted() {
    this.profile = window.userProfile;
    this.manager = window.managerProfile;
    this.applications = window.applications;
    this.users = window.userProfiles;
    this.users.unshift(this.profile);
    this.mainUser = window.mainUser;
    this.agents = window.agents;
    this.subusers = window.subusers;
  },
  mixins: [vueMixins.userProfile],
  methods: {
    deleteUser: async function (index) {
      var that = this;
      if (confirm("Are you sure?")) {
        startLoading();
        await api.delete("User", that.users[index].id);
        that.users.splice(index, 1);
        stopLoading("User deleted");
      }
    },
    deleteSubuser: async function (index) {
      var that = this;
      if (confirm("Are you sure?")) {
        startLoading();
        await api.delete("User", that.subusers[index].id);
        that.users.splice(index, 1);
        stopLoading("User deleted");
      }
    },
    blockUser: async function (index) {
      startLoading();
      var item = this.users[index];
      await api.update("User", item.id, {
        active: item.active == "Blocked" ? "Yes" : "Blocked",
      });
      stopLoading("User status updated");
      item.active = item.active == "Blocked" ? "Yes" : "Blocked";
    },
    editUser(index) {
      userEditSub.showPopup(this.users[index]);
    },
    newUser() {
      userEditSub.showPopup();
    },
    resetPwUser: async function (index) {
      var user = this.users[index];
      if (!user.id) return;
      startLoading();
      await api.update("User", user.id, {
        passChange: 1,
      });
      stopLoading("Password reset", "success");
    },
  },
};
</script>
