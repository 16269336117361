<template>
<div class="solution-select">
  <label v-if="!partner">Solution:</label>
  <label v-else>Solution:</label>
  <dyn-select required v-model="solution" :options="solutions" v-on:input="handleChange" />
</div>
</template>

<script>
export default {
  props: ["type", "partner"],
  data() { return {
    solution: "",
    solutions: [
      {
        "label" : "",
        "value" : null
      }
    ]
  }},
  async mounted() {
    var items=(await api.list("Solution", {solutionType: "Partners"})).items;
    for(var i in items)
      this.solutions.push(
        {
          "label" : items[i].nameSolution,
          "value" : items[i].id
        }
      )
  },
  methods: {
    handleChange($e) {
      this.$emit("input", $e);
    },
  },
}
</script>
