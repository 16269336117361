<template>
<div class="preform-outer">
<div class="preformbg"></div>
<div class="ncontent appf appnat_ preform pfx">
  <div class="cb-logo" :style="{'background-image': 'url(&quot;/files/company/'+companydata.appHeadLogo+'&quot;)' }">CardBiller</div>
  <h1 v-if="step<7">Merchant Application</h1>
  <div class="form-info">
    <p>{{ companydata.appHeadText }}</p>
    <p><strong>{{ companydata.appHeadEmail }}</strong></p>
    <p><strong>{{ companydata.appHeadPhone }}</strong></p>
  </div>
  <p class="form-intro">
    {{ companydata.appHeadDesc }}
  </p>
  <div class="temp-link">
    <label>Guest access to this application:</label>
    <a :href="link.url" target="_blank" v-on:click.prevent="copyLink">{{ link.url }}</a>
  </div>
  <form-progress :step="step" :steps="['Questionnaire']" />
  
  
  <form-apply-6a :app="app" v-if="step==0" v-on:generate="generate" :onlyStep="true" />
  <div class="pre-done" v-else-if="step==1">
    <h1>Thank you for your application</h1>
    <p>One of our relationship managers will contact you shortly!</p>
  </div>
<!--   <div class="version">v1.0</div> -->
</div>
</div>
</template>


<script>
export default {
  props: ["auxtype"],
  data() { return {
    step: 0,
    app: window.existingApplication,
    companydata: appData.company,
    link: window.existingLink || null,
    files: {},
  }},
  computed: {
    basepath() {
      return "files/application/"+this.app.id;
    },
  },
  mounted() {
    this.updateFiles();
  },
  methods: {
    updateFiles() {
      for(var document of this.app.documents) {
        this.$set(this.files, document.type, document.filename);
      }
    },
    nextStep() {
      this.step++;
      $("html, body").animate({scrollTop: 0}, 300);
    },
    prevStep() {
      this.step--;
      $("html, body").animate({scrollTop: 0}, 300);
    },
    generate: async function() {
      startLoading();
      var data={
        generate: "NEW_QUESTIONNAIRE",
        questionnaire: this.app.questionnaire,
      };
      var result=await api.update("Application", this.app.id, data);
      stopLoading("Questionnaire updated");
      this.step++;
    },
    copyLink() {
      copyToClipboard.text=this.link.url;
      copyToClipboard.doCopy();
    },
  },
}
</script>
