<template>
<div v-if="document">
  <popup v-model="showPreview" @close="$emit('close')">
    <div class="doc-title">{{ title }}</div>
    <div class="title">
      ID: {{ documentId }}<br>
      <small>Submitted by: {{ user.fullName }}, {{ user.email }}, {{ userGroup(user.groups) }}, {{ formatDate(document.created, 'LLLL') }}</small><br><br>
      <a :href="UrlPDF" target="_blank">
        <button class="btn btn-xs">View</button>
      </a>
      <template v-if="document.signed && document.documents.length">
        <a
          v-for="(file, index) in document.documents"
          :key="file.id"
          :href="formSignedDocUrl(file.filename)"
          target="_blank">
          <button class="btn btn-xs">View Signed <span v-if="index > 0">{{ index + 1 }}</span></button>
        </a>
      </template>
    </div>
    <div class="tables table-wrapper">
      Date Received: {{ formatDate(dateReceived, 'MM/DD/YYYY') }}
      <table
        v-if="document.type === 'IUAR'"
        class="mt-20">
        <thead>
          <tr>
            <th>Employee FIrst Name</th>
            <th>Employee Last Name</th>
            <th>Email</th>
            <th>Department</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>First Name</td>
            <td>Last Name</td>
            <td>{{ document.userId.email }}</td>
            <td>{{ userGroup(document.userId.groups) }}</td>
          </tr>
        </tbody>
      </table>
      <table
        v-for="(table, idx) in tables[document.type]"
        :key="idx"
        class="mt-20">
        <thead>
          <tr>
            <th v-for="item in table" :key="item.id">{{ trans[document.type][item] }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td v-for="item in table" :key="item.id">{{ displayValue(item) }}</td>
          </tr>
        </tbody>
      </table>

      <div v-if="!['EXPIRED', 'CXL', 'RESOLVED'].includes(document.status)" class="table-actions">
        <span>Options:</span>
        <a v-if="document.status !== 'COMPLETE'" class="ta-btn" @click="$emit('cxl', document.id)">CXL</a>
        <a v-if="['COMPLETE', 'PENDING'].includes(document.status)" class="ta-btn" @click="$emit('upl', document.id)">UPL</a>
        <a v-if="['COMPLETE'].includes(document.status)" class="ta-btn" @click="$emit('res', document.id)">RES</a>
      </div>
    </div>

    <form class="notes preq-form">
      <notes class="appl-notes" :notes="notes" type="ComplianceDocument" :id="document.id" />
    </form>
  </popup>
</div>
</template>

<script>
export default {
  mixins: [vueMixins.dateFormatter, vueMixins.currency, vueMixins.helpers],

  props: {
    documentId: {
      type: String,
      default: ''
    },
    document: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      notes: [],
      files: {
        FRPR: 'fraud-risk-policy-form',
        AMLTC: 'aml-traning-certification-form',
        IUAR: 'internal-usual-activity-report-form'
      },
      traning_session: {
        initial: 'Initial training',
        yearly: 'Yearly training'
      },
      traning_taken: {
        instructor: 'Instructor',
        webinar: 'Webinar',
        online: 'Online E-learning',
        all: 'Combination of all',
      },
      trans: {
        FRPR: {
          first_name: 'Employee FIrst Name',
          last_name: 'Employee Last Name',
          email: 'Email',
          department: 'Department',
          date: 'Date received'
        },
        AMLTC: {
          first_name: 'Employee First Name',
          last_name: 'Employee Last Name',
          email: 'Email',
          department: 'Department',

          traning_session: 'Training Session',
          dates_of_traning: 'Training Date',
          title_of_traning_provided: 'Training Title',
          traning_taken: 'How Was Training Taken',

          completed_traning: 'Training Completed',
          administered: 'Test Administered',
          passed_grade: 'Grade',
          must_be_retaken: 'Must Retake',
          failed_grade_date: 'Retake Date',
        },
        IUAR: {
          name: 'Merchant Name',
          transaction_ids: 'Transaction IDs',
          avg_monthly_trading_volume: 'Average monthly trading volume',
          avg_monthly_volume_transactions: 'Average monthly volume of Transactions',
          transaction_activity_compnay_set_limits: 'Transaction activity in relation to any Company set limits',

          transaction_details: 'Transaction Details',

          suspicious_activity_details: 'Detail the circumstances giving rise to this suspicious activity. If you believe a series of transactions, or linked transactions, are involved, ensure to describe the entire series or linked transactions, and indicate why you believe they comprise a series or are linked. Please include the date and time stamp of the transaction(s) in question.',
          suspicious_activity_details_fraud: 'What indicators raised your suspicions about possible money laundering, terrorist financing, fraud or other suspicious activity or behaviour by this individual?',
          relavant_report_info: 'Please provide any other information that is relevant to this present report',

          completed_date: 'Date IUAR was completed by Employee',
        }
      },
      tables: {
        FRPR: [
          ['first_name', 'last_name', 'email', 'department']
        ],
        AMLTC: [
          ['first_name', 'last_name', 'email', 'department'],
          ['traning_session', 'dates_of_traning', 'title_of_traning_provided', 'traning_taken'],
          ['completed_traning', 'administered', 'passed_grade', 'must_be_retaken', 'failed_grade_date']
        ],
        IUAR: [
          ['name', 'transaction_ids', 'avg_monthly_trading_volume', 'avg_monthly_volume_transactions', 'transaction_activity_compnay_set_limits'],
          ['transaction_details'],
          ['suspicious_activity_details'],
          ['suspicious_activity_details_fraud'],
          ['relavant_report_info'],
          ['completed_date']
        ]
      }
    }
  },

  computed: {
    user () {
      return this.document.userId
    },

    UrlPDF () {
      return `/compliance/recmatrix/${this.document.type.toLowerCase()}/${this.document.id}/pdf`
    },

    title () {
      return {
        AMLTC: 'AML ATF FRAUD RISK POLICY RECEIPT',
        FRPR: 'FRAUD RISK POLICY RECEIPT',
        IUAR: 'INTERNAL UNUSUAL ACTIVITY REPORT (IUAR)'
      }[this.document.type] + ' DETAILS'
    },

    showPreview () {
      return !!this.document
    },

    values () {
      const obj = {}
      this.document.form.forEach(item => {
        obj[item.name] = item.value
      })
      return obj
    },

    dateReceived () {
      return this.document.type === 'FRPR' ? this.values['date'] : this.document.created
    }
  },

  created () {
    this.loadNotes()
  },

  methods: {
    formSignedDocUrl (name) {
      return `/files/compliance/${this.document.id}/${name}`
    },

    displayValue (name) {
      let value = this.values[name]
      if (['avg_monthly_trading_volume', 'transaction_activity_compnay_set_limits'].includes(name)) return this.formatMoney(value)
      if (['completed_traning', 'administered', 'must_be_retaken'].includes(name)) return value == '1' ? 'Yes' : 'No'
      if (this.isDate(name) && value) return this.formatDate(value, 'MM/DD/YYYY')
      if (this.isDepartment(name)) return this.userGroup(value)
      if (name === 'traning_taken') return this.traning_taken[value]
      if (name === 'traning_session') return this.traning_session[value]
      if (name === 'transaction_ids') return JSON.parse(value).join(', ')
      return !value ? 'N/A' : value
    },

    formatMoney (data) {
      data = JSON.parse(data)
      let currency = this.currencies.find(item => item.value === data.currency)
      return data.value + ' ' + currency.label
    },

    isDepartment (name) {
      return ['department'].includes(name)
    },

    isDate (name) {
      return ['dates_of_traning', 'failed_grade_date', 'completed_date'].includes(name)
    },

    async loadNotes () {
      const filter = {
        type: 'ComplianceDocument',
        parentId: this.document.id
      }
      this.notes = (await api.list("Note", filter, ['id:ASC'])).items || [];
    },
  },
}
</script>
