<template>
<div>
  <amadel-menu :page="currentPage" />
  
  <div class="ncontent touch-it">
    <div class="header split">
      <div>
        <ul class="breadcrumbs">
          <li><a>Leads Overview</a></li>
        </ul>
        <div class="toolbar" />
        <form class="search-form">
          <h3>Search Options</h3>
          <div class="f2split">
            <div>
              <div class="field">
                <label>Date Range</label>
                <div class="field-group">
                  <input type="text" id="dp1569254393711" class="hasDatepicker">
                  <input type="text" id="dp1569254393712" class="hasDatepicker">
                </div>
              </div>
              <div class="field">
                <label>Trans ID</label>
                <input type="text">
              </div>
              <div class="field">
                <label>TID</label>
                <select>
                  <option value="">(all)</option>
                  <option value="2">Allium</option>
                  <option value="3">Acme Inc.</option>
                  <option value="4">Internal Agent</option>
                  <option value="5">David Collins Jr</option>
                  <option value="7">GBWEB</option>
                  <option value="14"></option>
                  <option value="15"></option>
                  <option value="20"></option>
                </select>
              </div>
              <div class="field">
                <label>Trans Type</label>
                <select>
                  <option value="">(all)</option>
                  <option value="A00">A00 - To be Assessed</option>
                  <option value="A01">A01 - Pre-Qualification Complete</option>
                  <option value="A02">A02 - New Application</option>
                  <option value="A03">A03 - In Process</option>
                  <option value="A04">A04 - Approved</option>
                  <option value="A05">A05 - Denied</option>
                  <option value="A06">A06 - Duplicate</option>
                </select>
              </div>
              <div class="field">
                <label>Entry Type</label>
                <select>
                  <option value="">(all)</option>
                  <option value="A00">A00 - To be Assessed</option>
                  <option value="A01">A01 - Pre-Qualification Complete</option>
                  <option value="A02">A02 - New Application</option>
                  <option value="A03">A03 - In Process</option>
                  <option value="A04">A04 - Approved</option>
                  <option value="A05">A05 - Denied</option>
                  <option value="A06">A06 - Duplicate</option>
                </select>
              </div>
            </div>
            <div>
              <div class="field">
                <label>Scheme</label>
                <select>
                  <option value="">(all)</option>
                  <option value="A00">A00 - To be Assessed</option>
                  <option value="A01">A01 - Pre-Qualification Complete</option>
                  <option value="A02">A02 - New Application</option>
                  <option value="A03">A03 - In Process</option>
                  <option value="A04">A04 - Approved</option>
                  <option value="A05">A05 - Denied</option>
                  <option value="A06">A06 - Duplicate</option>
                </select>
              </div>
              <div class="field">
                <label>Card</label>
                <input type="text">
              </div>
              <div class="field">
                <label>Ammount</label>
                <input type="text">
              </div>
              <div class="field">
                <label>Batch</label>
                <input type="text">
              </div>
              <div class="field">
                <label>Status</label>
                <select>
                  <option value="">(all)</option>
                  <option value="Pending">Pending</option>
                  <option value="Complete">Complete</option>
                  <option value="Expired">Expired</option>
                  <option value="Cancel">Cancel</option>
                  <option value="Resolved">Resolved</option>                
                </select>
              </div>
            </div>
          </div>
          <div class="buttons"><button class="btn btn-large btn-primary">Search</button></div>
        </form>
      </div>
      <aside>
        <h3>Summary Report</h3>
        <table class="breakdown-table">
          <thead>
            <tr>
              <th>Status</th>
              <th>Count</th>              
            </tr>
            <tr>
              <th colspan="2" class="spacer"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in list">
              <td>{{ item.name }}</td>
              <td>{{ item.data.length }}</td>             
            </tr>
          </tbody>
        </table>
      </aside>
    </div>
    <div class="hr"></div>
    <div class="content">
      <div>
        <p class="result-count">Your search returned <span>{{ count }}</span> result(s).</p>
        <ul class="acc-results">
          <li v-for="(item, type) in list" :key="type" :class="{ active: show[type] }">
            <h4 @click="show[type] = !show[type]">
              <span class="label">{{ item.name }}</span>
            </h4>
            <div v-show="show[type]" class="acc-list" style="display: block;">
              <table>
                <thead>
                  <tr>
                    <th>Source ID</th>
                    <th>Assigned</th>
                    <th>Lead Type</th>
                    <th>Lead ID</th>
                    <th>Date/Time</th>
                    <th>Method Type</th>
                    <th>Business Name</th>
                    <th>Business DBA</th>
                    <th>Prov/State</th>
                    <th>Country</th>
                    <th>Status</th>
                    <th>Options</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="lead in item.data" :key="data.id">
                    <td>{{ lead.SourceID.sourceId }}</td>
                    <td>{{ lead.assignedTo ? lead.assignedTo.agentId : 'Unassigned' }}</td>
                    <td>{{ lead.LeadType }}</td>
                    <td><a class="link" @click="previewLead = lead">{{ formatLeadId(lead.id) }}</a></td>
                    <td>{{ lead.created }}</td>
                    <td>{{ lead.MethodType }}</td>
                    <td>{{ lead.BusinessName }}</td>
                    <td>{{ lead.BusinessDBA || 'N/A' }}</td>
                    <td>{{ lead.BusinessProvState || 'N/A' }}</td>
                    <td>{{ lead.BusinessCountry || 'N/A' }}</td>
                    <td>{{ lead.status }} - {{ getLeadStatus(lead.status) }}</td>
                    <td>-</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <lead-preview
    :lead="previewLead"
    hide-assign-unassign
    @close="previewLead = null"
    @edit="editLead($event)" />
</div>
</template>

<script>
export default {
  mixins: [vueMixins.helpers],

   data () {
    return {
      data: [],
      previewLead: null,
      show: {
        ASSIGNED: false,
        COMPLETED: false,
        INCOMPLETED: false,
        OTHER: false
      },
      currentPage: {
        text: 'Leads Overview'
      }
    }
  },

  computed: {
    assignedList () {
      return this.data.filter(item => this.isAssigned(item))
    },

    completedList () {
      return this.data.filter(item => ['AMA20', 'AMA21', 'AMA22', 'AMA23', 'AMA24'].includes(item.status))
    },

    incompletedList () {
      return this.data.filter(item => ['AMA30', 'AMA31'].includes(item.status))
    },

    otherList () {
      return this.data.filter(item => ['AMA40', 'AMA41', 'AMA42', 'AMA43', 'AMA44'].includes(item.status))
    },

    count () {
      return this.assignedList.length + this.completedList.length + this.incompletedList.length + this.otherList.length
    },
    
    list () {
      return {
        ASSIGNED: {
          name: 'Assigned Leads',
          data: this.assignedList
        },
        COMPLETED: {
          name: 'Completed Leads',
          data: this.completedList
        },
        INCOMPLETED: {
          name: 'Incompleted Leads',
          data: this.incompletedList
        },
        OTHER: {
          name: 'Other',
          data: this.otherList
        }
      }
    }
  },

  created() {
    this.getData()
    const open = this.getUrl('open')

    if (open && Object.keys(this.show).includes(open))
      this.show[open] = true

  },

  methods: {
    createNote (id, message) {
      const payload = {
        message,
        created: "CURRENT_TIMESTAMP",
        author: window.appData.currentUser.id,
        system: 1,
        type: 'Lead',
        parentId: id
      }

      return api.create("Note", payload)
    },

    editLead (lead) {
      const index = this.data.findIndex(l => l.id === lead.id)
      this.$set(this.data, index, lead)

      if (this.previewLead) this.previewLead = this.data[index]
    },

    isUnassigned (item) {
      return ['AMA00'].includes(item.status)
    },

    isAssigned (item) {
      return ['AMA10', 'AMA11', 'AMA12', 'AMA13'].includes(item.status)
    },

    
    getLeadStatus (status) {
      return window.leadStatuses[status]
    },

    async getData () {
      startLoading()
      const payload = (window.appData.currentUser.groups !== 'admin-super') ? { assignedTo: window.appData.currentUser.id } : {}
      this.data = (await api.list('AmadelLead', payload, ["created:DESC"])).items
      stopLoading()
    }
  },
}
</script>
