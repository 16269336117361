<template>
<div>
  <amadel-menu :page="currentPage" />

  <events-by-date :date="date" :type="type" />
</div>
</template>

<script>
export default {
  mixins: [vueMixins.helpers],

  props: {
    date: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      currentPage: {
        text: 'Calendar'
      },
      type: 'AMADEL'
    }
  }
}
</script>
