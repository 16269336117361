<template>
  <div class="idv-widget">
    <h1>Identity Verification</h1>
    <div style="">
      <div v-if="step==0">
        <p>Lorem Ipsum Text</p>
        <div>
          <button class="ch-button btn-success" v-on:click.prevent="step++">Start</button>
        </div>
      </div>
      <div v-if="step==1">
        <label>Select your country</label>
        <div class="field">
          <validator reqs="required">
            <div class="combo-select">
              <dyn-select :options="validCountries" v-model="country" />
            </div>
          </validator>
        </div>
        <div v-if="country">
          <label>Select Document Type</label>
          <div class="field">
            <validator reqs="required">
              <div class="combo-select">
                <dyn-select :options="documentTypeOptions" v-model="docType" />
              </div>
            </validator>
          </div>
        </div>
        <div v-if="docType" style="margin-bottom: 10px;">
          <button class="ch-button btn-success" @click="startCamera">Capture Photo</button>
          <button class="ch-button btn-success" @click="uploadDocumentFile">File Upload</button>
        </div>

        <div>
          <button class="ch-button btn-primary" v-on:click.prevent="step--">Back</button>
          <button class="ch-button btn-success" v-on:click.prevent="saveImage">Next</button>
        </div>
      </div>
      <div v-if="step==2">
        <button class="ch-button btn-primary" @click="openFaceCapture">Capture Liveness Photo</button>
        <div style="margin-top: 10px;">
          <button class="ch-button btn-primary" v-on:click.prevent="step--">Next</button>
          <button class="ch-button btn-success" v-on:click.prevent="saveFace">Finish</button>
        </div>
      </div>
      <div v-if="step==3">
        <p>Your Identity Verification has been submitted for verification</p>
        <div>
<!--           <button class="ch-button btn-success" v-on:click.prevent="step = 0">Close</button> -->
        </div>
      </div>
    </div>

    <div class="camera simple-camera-container" v-if="simpleVideoShown">
      <div class="simple-camera">
        <video ref="videoout">Video stream not available.</video>

      </div>
      <a class="capture" href="" v-on:click.prevent="capturePhoto">Capture</a>
      <a class="cancel" href="" v-on:click.prevent="cancelSimpleCamera">Cancel</a>
    </div>

    <div class="camera">
      <div id="acuant-camera"></div>
      <a class="cancel" href="" v-on:click.prevent="cancelCapture">Cancel</a>
    </div>



  <div id="acuant-loader" class="" style="display:none;">
    <div class="">
      <div class="loader" style="margin: 20% auto 0 auto"></div>
    </div>
  </div>
  <div id="acuant-face-capture-container" class="face-camera-container">
    <div id="face-detection-text" class="detection-state"></div>
  </div>

  <div id="result-id" style="display:none">
    <p id="result-error" style="display:none">An error has occurred while cropping the image. Please try again.</p>
    <img id="result-id-img" class="fit-screen" style="display:block"></img>
    <div class="" style="margin-top:1%" v-show="false">
      <div>
        <button class="btn btn-success" @click="saveImage">Save</button>
      </div>
    </div>
  </div>
  <!-- Modal content -->
  <div id="acuant-modal" class="modal-content" style="display: none; margin-top:30%">
    <span class="close">&times;</span>
    <div class="row" style="margin:3%">
      <h3 id="acuant-modal-text"></h3>
    </div>
    <div class="row text-right" style="margin:3%">
      <button class="btn btn-success mr-2" onClick="startManualCapture()">Start Manual Capture</button>
    </div>
  </div>

    </div>
</template>
    
<script>


var currentResult = null;
var currentFace = null;

var acuantFront=function() {

  const camera = document.getElementById('acuant-camera');
  const camera2 = document.querySelector(".idv-widget .camera");
  const result = document.getElementById('result-id');

  const faceCaptureContainer  = document.getElementById('acuant-face-capture-container');
  const faceDetectionTextDiv = document.getElementById('face-detection-text');

  const resultId = document.getElementById('result-id-img');
  const resultError = document.getElementById('result-error');

  const loader = document.getElementById('acuant-loader');

  var liveCaptureFailed = false
  currentResult = null;


  window.onbeforeunload = function(event) {
    AcuantJavascriptWebSdk.endWorkers();
  };

  window.closeCamera=function closeCamera() {
    camera.style.display = "none";
    camera2.style.display = "none";
    result.style.display = "block";
  }

  const options = {
    text: {
      NONE: "Align",
      SMALL_DOCUMENT: "Move closer",
      GOOD_DOCUMENT: null,//null countdown
      BIG_DOCUMENT: "Too close",
      CAPTURING: "Capturing",
      TAP_TO_CAPTURE: "Tap to capture"
    }
  }

  var cameraCallback = {
    onCaptured: onCaptured,
    onCropped: onCropped,
    onFrameAvailable: function (response) {
      //get each frame if needed
      //console.log(response)
    }
  }

  function startCamera() {
    currentResult = null;
    if (AcuantCamera.isCameraSupported && !AcuantCamera.isIOSWebview && !liveCaptureFailed) {
      camera.style.display = "block";
      camera2.style.display = "flex";

      AcuantCameraUI.start(cameraCallback, onError, options);
    }
    else {
      startManualCapture()
    }
  }
  window.startCamera=startCamera;

  window.cancelCapture=function cancelCapture() {
    AcuantCameraUI.end();
    camera.style.display = "none";
    camera2.style.display = "none";

  }

  function onError(error, code) {
    camera.style.display = "none";
    camera2.style.display = "none";
    faceCaptureContainer.style.display = "none";
    console.error(error, code);
    liveCaptureFailed = true;

    if (code === AcuantJavascriptWebSdk.REPEAT_FAIL_CODE) { //live capture was started after previous failure leading to manual capture, show any elements you may have hidden as the user can cancel the prompt and could otherwise end up on a blank page.
    } else {
      modal.style.display = "block";
      switch(code) {
        case AcuantJavascriptWebSdk.SEQUENCE_BREAK_CODE: //camera froze/crashed; usually happens due to an ios 15 bug
          modalText.innerHTML = "Live Camera failed. Start manual capture.";
          break;
        case AcuantJavascriptWebSdk.START_FAIL_CODE: //camera not supported or permission not granted
          modalText.innerHTML = "Live Camera failed to open. Start manual capture.";
          break;
        default: //unknown error (shouldn't happen)
          modalText.innerHTML = "Unknown camera failure. Start manual capture.";
          break;
      }
    }
  }

  function onCaptured(response) {
    modal.style.display = "none";
    loader.style.display = "block";
  }

  function onCropped(response) {
    resultId.style.display = "block";
    resultError.style.display = "none";
    if (response && response.image) {
      drawImageOnResult(response);
    } else {
      resultId.style.display = "none";
      resultError.style.display = "block";
    }
    closeCamera();
    loader.style.display = "none";
  }

  window.startManualCapture=function startManualCapture() {
    AcuantCamera.startManualCapture(cameraCallback);
  }

  window.startFileCapture=function startFileCapture(file) {


    var iOSversion=function() {
      if (/iP(hone|od|ad)/.test(navigator.platform)) {
        // supports iOS 2.0 and later: <http://bit.ly/TJjs1V>
        try {
          const v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
          return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
        } catch (_) {
          return -1;
        }
      }
      return -1;
    }

    var isiOS144Plus=function() {
      let ver = iOSversion();
      return ver && ver != -1 && ver.length >= 2 && ver[0] == 14 && ver[1] >= 4;
    }

    var isiOS15=function() {
      let ver = iOSversion();
      return ver && ver != -1 && ver.length >= 1 && ver[0] == 15;
    }

    var isiOS16=function() {
      let ver = iOSversion();
      return ver && ver != -1 && ver.length >= 1 && ver[0] == 16;
    }
    var arrayBufferToBase64=function(buffer) {
      let binary = '';
      const bytes = new Uint8Array(buffer);
      const len = bytes.byteLength;
      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return window.btoa(binary);
    }

    var getOrientation=function(e) {
      const view = new DataView(e.target.result);
      if (view.getUint16(0, false) != 0xFFD8) {
        return -2;
      }
      const length = view.byteLength;
      let offset = 2;
      while (offset < length) {
        if (view.getUint16(offset + 2, false) <= 8) return -1;
        const marker = view.getUint16(offset, false);
        offset += 2;
        if (marker == 0xFFE1) {
          if (view.getUint32(offset += 2, false) != 0x45786966) {
            return -1;
          }

          const little = view.getUint16(offset += 6, false) == 0x4949;
          offset += view.getUint32(offset + 4, little);
          const tags = view.getUint16(offset, little);
          offset += 2;
          for (let i = 0; i < tags; i++) {
            if (view.getUint16(offset + (i * 12), little) == 0x0112) {
              return view.getUint16(offset + (i * 12) + 8, little);
            }
          }
        }
        else if ((marker & 0xFF00) != 0xFF00) {
          break;
        }
        else {
          offset += view.getUint16(offset, false);
        }
      }
      return -1;
    }


    var manualCaptureInput = document.createElement('input');
    manualCaptureInput.type = 'file';
    manualCaptureInput.accept = 'image/*';
    var onSelectFile = manualCaptureInput.onchange = function(event) {
      var file = event.target, reader = new FileReader();
      loader.style.display = "block";

      var hiddenCanvas = document.createElement('canvas');
      var hiddenContext = hiddenCanvas.getContext('2d');

      reader.onload = (e) => {
        var captureOrientation = getOrientation(e);
        var  image = document.createElement('img');
        image.src = 'data:image/jpeg;base64,' + arrayBufferToBase64(e.target.result);
        image.onload = () => {

          let MAX_WIDTH = 2560,
            MAX_HEIGHT = 1920,
            width = image.width,
            height = image.height;

          if (isiOS15()) {
            MAX_WIDTH = MAX_VIDEO_WIDTH_IOS;
            MAX_HEIGHT = Math.floor(MAX_VIDEO_WIDTH_IOS * 3 / 4);
          }

          const largerDimension = width > height ? width : height;

          if (largerDimension > MAX_WIDTH) {
            if (width < height) {
              const aspectRatio = height / width;
              MAX_HEIGHT = MAX_WIDTH;
              MAX_WIDTH = MAX_HEIGHT / aspectRatio;
            }
            else {
              const aspectRatio = width / height;
              MAX_HEIGHT = MAX_WIDTH / aspectRatio;
            }
          } else {
            MAX_WIDTH = image.width;
            MAX_HEIGHT = image.height;
          }

          hiddenCanvas.width = MAX_WIDTH;
          hiddenCanvas.height = MAX_HEIGHT;

          hiddenContext.mozImageSmoothingEnabled = false;
          hiddenContext.webkitImageSmoothingEnabled = false;
          hiddenContext.msImageSmoothingEnabled = false;
          hiddenContext.imageSmoothingEnabled = false;

          hiddenContext.drawImage(image, 0, 0, MAX_WIDTH, MAX_HEIGHT);

          width = MAX_WIDTH;
          height = MAX_HEIGHT;

          const imgData = hiddenContext.getImageData(0, 0, width, height);

          hiddenContext.clearRect(0, 0, MAX_WIDTH, MAX_HEIGHT);
          image.remove();

          onCaptured({
            data:imgData,
            width: width,
            height: height
          });

          AcuantCamera.evaluateImage(imgData, width, height, null, 'MANUAL', onCropped);
        };
      };

      if(file && file.files[0]){
        reader.readAsArrayBuffer(file.files[0]);
      }
    };
    if (file)
      onSelectFile({target: {files: [file]}});
    else
      manualCaptureInput.click();


  }

  function drawImageOnResult(result) {
    currentResult = result.image.data;

    resultId.width = result.image.width;
    resultId.height = result.image.height;
    resultId.src = result.image.data;

  }

  window.saveImage=async function saveImage() {
    var checkId = window.extCheckId;
    const imagedata = currentResult; // Replace with your image data URL
    const mime = 'image/jpeg'; // Replace with the appropriate MIME type for your image data
    const b64Data = imagedata.split(',')[1]; // Extract the base64-encoded binary data
    const decodedData = atob(b64Data); // Decode the base64-encoded binary data
    const array = new Uint8Array(decodedData.length); // Create a Uint8Array from the decoded binary data
    for (let i = 0; i < decodedData.length; i++) {
      array[i] = decodedData.charCodeAt(i); // Populate the Uint8Array with the binary data
    }
    const blob = new Blob([array], { type: mime }); // Create a Blob object from the Uint8Array with the appropriate MIME type
    var file = new File([blob], "frontside.jpg", {type: "image/jpeg"});
    var frontSide="frontSide"+(window.personIndex?(personIndex+1):"");
    await api.upload("ExtCheck", checkId, file, {field: frontSide});

    resultId.style.display = "none";

  }




  //**************** Passive Liveness==========================

  function setUIValues(id, value) {
    let el = document.getElementById(id);
    el.value = value;
  }

  const faceDetectionState = {
    FACE_NOT_FOUND: "Face not found",
    TOO_MANY_FACES: "Too many faces",
    FACE_ANGLE_TOO_LARGE: "Face angle too large",
    PROBABILITY_TOO_SMALL: "Probabilty too small",
    FACE_TOO_SMALL: "Face too small",
    FACE_CLOSE_TO_BORDER: "Too close to the frame"
  }


  const onFaceCaptured = window.onFaceCaptured = async (image) => {
//     loader.style.display = "block";
//     faceCaptureContainer.style.display = 'none';
    resultId.style.display = "block";
//     console.log(image);
    var dataUrl="data:image/png;base64,"+image;
    var file=dataURLtoBlob(dataUrl);
    currentFace=file;
//     await api.upload("ExtCheck", checkId, file, {field: "backSide"});
    resultId.src=dataUrl;

  }

  window.saveFace = async() => {
    var checkId = window.extCheckId;
    if (currentFace) {
      var backSide="backSide"+(window.personIndex?(personIndex+1):"");
      await api.upload("ExtCheck", checkId, currentFace, {field: backSide});
    }
    await api.action("ExtCheck", checkId, "evaluatePerson", window.personIndex);
    resultId.style.display = "none";
  }

  const openFaceCaptureCamera = () => {
    result.style.display = 'none';
    faceDetectionTextDiv.innerHTML = "LOADING DETECTOR";
    faceCaptureContainer.style.display = 'block';


    var handler = {
      onDetectorInitialized: () => {
        faceDetectionTextDiv.innerHTML = "";
      },
      onDetection: (text) => {
        faceDetectionTextDiv.innerHTML = text;
      },
      onOpened: () => {
        faceDetectionTextDiv.style.display = 'flex';
      },
      onClosed: () => {
        faceCaptureContainer.style.display = 'none';
        faceDetectionTextDiv.style.display = 'none';
      },
      onError: (error) => {
        if (error.code == 1) { //camera permission not granted
          faceDetectionTextDiv.style.display = 'none';
        }
        console.log(error.message)
      },
      onPhotoTaken: () => {
        faceDetectionTextDiv.style.display = 'none';
      },
      onPhotoRetake: () => {
        faceDetectionTextDiv.style.display = 'flex';
      },
      onCaptured: onFaceCaptured
    }
    AcuantPassiveLiveness.start(handler, faceDetectionState);

  }
  window.openFaceCaptureCamera=openFaceCaptureCamera;

  //**************** Passive Liveness==========================

  //**************** Custom Modal===========================
  // Get the modal
  const modal = document.getElementById("acuant-modal");
  const modalText = document.getElementById('acuant-modal-text');

  // Get the <span> element that closes the modal
  var span = document.getElementsByClassName("close")[0];

  // When the user clicks on <span> (x), close the modal
  span.onclick = function () {
    closeCamera();
    modal.style.display = "none";
  }

  // When the user clicks anywhere outside of the modal, close it
  window.onclick = function (event) {
    if (event.target == modal) {
      closeCamera();
      modal.style.display = "none";
    }
  }
  //**************** Custom Modal===========================
}


function dataURLtoBlob(dataurl) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], "captured.png", {type:mime});
}

export default {
  data: ()=>({
    step: 0,
    country: 'RS',
    docType: 'Passport',
    idvType: null,
    simpleVideoShown: false,
    isSimpleVideoSupported: true,
  }),
  computed: {
    validCountries() {
      var countries = [];
      var lists = [];
      lists = window.fullCountries
      lists.forEach(item => {
        countries.push({
        label: item.text,
        value: item.id
        })
      })

      return countries
    },

    documentTypeOptions() {
      var types = [
        {label: 'Passport', value: 'Passport'},
        {label: 'Drivers Licence', value: 'Drivers Licence'},
        {label: 'Government ID', value: 'Government ID'},
      ];

      return types
    },
  },
  watch: {

  },
  methods: {
    goToNext() {
      this.step++;
    },

    startCamera() {
      if (AcuantCamera.isCameraSupported || !this.isSimpleVideoSupported)
        startCamera();
      else
        this.startSimpleCamera();
    },

    cancelCapture() {
      if (AcuantCamera.isCameraSupported || !this.isSimpleVideoSupported)
        cancelCapture();
      else
        this.cancelSimpleCamera();
    },

    openFaceCapture() {
      if (AcuantCamera.isCameraSupported || !this.isSimpleVideoSupported)
        openFaceCaptureCamera();
      else
        this.startSimpleCamera();
    },

    openRun() {
      openRunFaceMatch();
    },

    saveImage() {
      if (currentResult) {
        saveImage();
        this.step++;
      } else {
        appNotifications.show("Document not captured", "error");
      }
    },
    saveFace() {
      if (currentFace) {
        saveFace();
        this.step++;
      } else {
        appNotifications.show("Face not captured", "error");
      }
    },

    uploadDocumentFile() {
      startFileCapture();
    },

    startSimpleCamera() {
      this.simpleVideoShown=true;
      navigator.mediaDevices.getUserMedia({ video: { width: 1280, height: 720 }, audio: false })
        .then((stream) => {
          var video=this.$refs.videoout;
          video.srcObject = stream;
          video.play();
        })
        .catch((err) => {
          console.error(`An error occurred: ${err}`);
        });

    },

    cancelSimpleCamera() {
      if (this.$refs.videoout) {
        this.$refs.videoout.srcObject.getTracks().forEach((track)=>{
          track.stop();
        });
        this.$refs.videoout.src="";
      }
      this.simpleVideoShown=false;
    },

    capturePhoto() {
      var video=this.$refs.videoout;
      if (video) {
        var canvas=document.createElement("canvas");
        canvas.width=video.videoWidth;
        canvas.height=video.videoHeight;
        var context=canvas.getContext("2d");
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        var data=canvas.toDataURL("image/png");
        if (this.step==1)
          startFileCapture(dataURLtoBlob(data));
        else if (this.step==2)
          onFaceCaptured(data.split(",")[1]??"");
        this.cancelSimpleCamera();
      }
    },

  },
  mounted() {
    acuantFront();
    this.isSimpleVideoSupported=!!navigator.mediaDevices?.getUserMedia
  },

}
</script>
    
<style scoped>

</style>
