<template>
<div>
  <p class="result-count">Your search returned <span>{{ results }}</span> result(s).</p>
  <ul class="acc-results">
    <li>
      <h4 v-on:click="show = !show">
        <span class="label">Profiles</span>
        <span class="count">{{ sortItems.length }} Application(s)</span>
      </h4>
      <div ref="" v-show="show" class="acc-list" style="display: block;">
        <table>
          <thead>
            <tr>
              <th v-for="(columnLabel, columnName) in columns">
                {{ columnLabel }}
              </th>

              <th v-if="!noActions">Options</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in sortItems">
              <td v-for="(columnLabel, columnName) in columns">
                <a v-if="fieldHref(item, columnName)" :href="fieldHref(item, columnName)">
                  {{ showField(item, columnName) }}
                </a>
                <template v-else>
                  {{ showField(item, columnName) }}
                </template>
              </td>
              <td v-if="!noActions">
                
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </li>
  </ul>
</div>
</template>

<script>
export default {
  props: ["statuses", "isPartners"],
  data() { return {
    show: false,
    items: [],
    columns: {
      "profileNumber": "Profile",
      "createdAt": "Created",
      "partner": "Partner",
      "accountType": "Type",
      "name": "Name",
      "status": "Status",
    }, 
    options: {},
    actions: {},
    currentIndex: null,
    search: {},
    now: 0,
    noLinks: appData.noLinks || false,
    noActions: appData.noActions || false,
  }; },
  computed: {
    results() {
      return this.sortItems.length;
    },
    perStatus() {
      return this.statuses.map((a)=>({
          description: a.description,
          status: a.status,
          items: this.sortItems.filter((i)=>{
            return true; 
          }),
          actions: this.actions[a.status] || {},
        }));
    },
    columnsToShow() {
      var r={};
      for(var status of this.statuses) {
        var result={};
        for(var columnName in this.columns) {
          result[columnName]=this.columns[columnName];
        }
        r[status.status]=result;
      }
      return r;
    },

    sortItems() {
      const data = this.items || []
      return data.sort((a, b) => {
        if (a.status > b.status) return 1
        if (a.status < b.status) return -1
        return 0
      })
    }
  },
  methods: {
    fieldHref(item, columnName) {
      if(columnName =="profileNumber")
        if(this.isPartners == 1) {
          return `simplified-view/${item.id}`
        }else{
          return `treasury/${item.id}`
        }
      return null;
    },
    showField(item, columnName) {
      if(columnName == 'name') {
        if(item.accountType == 'Personal') {
          return item['personal']['firstName'] + ' ' + item['personal']['lastName']
        }else{
          return item?.business?.companyName
        }
      }else if(columnName == 'profileNumber') {
        if(item.accountType == 'Personal') {
          return item?.personal?.profileNumber
        }else{
          return item?.business?.profileNumber
        }
      }else if(columnName == 'status') {
        if(item.accountType == 'Personal') {
          return item?.personal?.status
        }else{
          return item?.business?.status
        }
      }else if(columnName == 'partner') {
        return item?.partner?.agentDb?.partnerId
      }else{
        columnName=columnName.split(".");
        var firstPart=columnName.shift();
        var value=item[firstPart];
        if (!value || !columnName.length) {
          if ((value+"").match(/[0-9]{4}-[0-9]{2}-[0-9]{2} [0-9]{2}:[0-9]{2}:[0-9]{2}/)) {
            return this.formatDate(value, "YYYY-MM-DD");
          }
          return value;
        }
        return this.showField(value, columnName.join("."));
      }
      
    },
    selectIndex(index) {
      if (this.currentIndex==index) {
        this.currentIndex=null;
        $(this.$el).find("li.active .acc-list").slideUp(300);
      } else {
        this.currentIndex=index;
        $(this.$el).find("li.active .acc-list").slideUp(300);
        $(this.$el).find("li").eq(index).find(".acc-list").slideDown(300);
      }
    },
    doAction: async function(item, targetMethod) {
    },
  },
  mounted() {
    window.accordeonResults=this;
  },
  mixins: [vueMixins.dateFormatter],
}
</script>
