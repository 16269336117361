<template>
  <div :class="{filled: focused || actualValue, disabled: disabled}">
    <label>{{ label }} <em v-if="required">*</em></label>
    <textarea
      ref="textarea"
      v-model="actualValue"
      :type="type"
      :required="required"
      :disabled="disabled"
      :maxlength="maxlength || null"
      :class="{ error: hasErrors }"
      @focus="focused = true"
      @blur="focused = false"
      @input="$emit('input', $event.target.value)" />
  </div>
</template>

<script>
import autosize from 'autosize'

export default {
  props: ["label", "options", "value", "type", "required", "disabled", "maxlength", "validator"],
  
  data: ()=>({
    actualValue: null,
    focused: false
  }),

  computed: {
    hasErrors () {
      return !!(this.validator && this.validator.$invalid && this.validator.$dirty && !this.validator.$pending)
    }
  },

  watch: {
    value: {
      handler() {
        this.actualValue = this.value;
      },
      immediate: true
    },
  },

  mounted () {
    autosize(this.$refs.textarea);
  }
}
</script>
