<template>
  <div>
    <div class="content-filters">
        <basic-form>
          <h4>Languages</h4>
          <multiselect-dropdown :options="languageOptions.map(a=>({label: a, value: a}))" v-model="settings.language" :isMulti="true" :disabled="!updateInformations" />
          <br/>
          <h4>Timezones</h4>
          <multiselect-dropdown :options="timezoneOptions.map(a=>({label: a, value: a}))" v-model="settings.timezone" :isMulti="true" :disabled="!updateInformations" />
          <br/>
          <br/>
          <div class="buttons">
            <template v-if="updateInformations">
              <button class="btn btn-large btn-primary" @click="save()">Submit</button>
            </template>
            <template v-else class="buttons">
              <button class="btn btn-large btn-primary" @click="updateInformations = true">Update</button>
            </template>
          </div>
        </basic-form>
      </div>
  </div>
  </template>
  
  <script>
  import BasicForm from './components/ui/forms/Form'

  export default {
    components: {
      BasicForm,
    },
    
    data() { return {
      settings: {},
      updateInformations: false,
      today: moment("00:00:00", "HH:mm:ss").format("YYYY-MM-DD"),
    }},
    
    async created () {
      this.settings=(await api.get("Settings", 1)).item;
    },

    computed: {
      languageOptions () {
        return [
          "English",
          "Français",
          "Español",
        ]
      },

      timezoneOptions () {
        return [
          "AST (GMT -4)",
          "EST (GMT -5)",
          "CST (GMT -6)",
          "MST (GMT -7)",
          "PST (GMT -8)",
        ]
      },
    },

    methods: {
      async save() {
        startLoading();
        await api.update('Settings', 1, {...this.settings});
        this.updateInformations = false
        stopLoading("Updated.");
      }
    },
    mixins: [vueMixins.dateFormatter, formValidation.mixin],
  }
  </script>
<style lang="scss" scoped>
</style>