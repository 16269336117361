<template>
<div>
  <view-app-websitecheck :uwList="itemList" ref="wscheck" />
</div>
</template>
<script>
export default {
  props: ["item", "merchant"],
  data: ()=>({
    itemList: [],
  }),
  computed: {
  },
  methods: {
    listWebsiteChecks: async function() {
      this.uwTab=2;
      this.itemList=(await api.list("WebsiteCheck", {appId: this.item.id}, ["id:DESC"])).items || [];
    },
  },
  mounted() {
    this.listWebsiteChecks();
  },
}
</script>
