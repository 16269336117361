<template>
  <div class="status-page">
    <div class="ss-head">
      <div class="publish-content">
        <h1>General Privacy Policy</h1>
      </div>
    </div>
    <div class="status-list">
      <ul>
        <li>
          <div class="terms-header">
            <a class="toggle" href="">
              Last Updated - {{ items.privacyPolicyDate }}
            </a>
          </div>
          <ul>
            <div class="terms-content" v-html="items.privacyPolicyText">
            </div>
          </ul>
        </li>
      </ul>
    </div>
  </div>
  </template>
  
  <script>
  export default {
    data() { return {
      items: [],
      opened: true,
    }},
    methods: {
      toggleOpened(index) {
        this.$set(this.opened, index, !this.opened[index]);
      },
      async loadItems() {
        var results=(await api.list("Disclaimers", {type: "general"})).items;
        this.items = results[0]
        this.opened=this.items.map(a=>false);
      },
    },
    
    async created() {
      this.loadItems();
    },
  }
  </script>
  <style lang="scss" scoped>
    .terms-content {
      padding: 8px 12px;
    }

    .terms-header::before, .terms-header::after {
      content: none;
    }
  </style>