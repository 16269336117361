<template>
  <div class="acc-list" style="display: block">
    <table class="">
      <thead>
        <tr>
          <th style=""></th>
          <th style="">Username</th>
          <th style="">Email</th>
          <th style="">Activation Code</th>
          <th style="">Activation Code Expires</th>
          <th style="">Activation Date</th>
          <th style="">Reset Pass Request</th>
          <th style="">Reset Pass Complete</th>
          <th style="">Blocked</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(itemx, index) in subusers">
          <td class="text-left">
            <a :href="'/profile/subuser/'+itemx.id">{{ itemx.agentId }}</a>
          </td>
          <td class="text-left">
            {{ itemx.fullName || itemx.agentDb && itemx.agentDb.name }}
          </td>
          <td class="text-left">
            {{ itemx.lastName }}
          </td>
          <td class="text-left">
            {{ itemx.email }}
          </td>
          <td class="text-right">
            
          </td>
        </tr>
      </tbody>
    </table>

    <portal to="actions">
      <ul>
        <template>
            <li v-for="(optLabel, optMethod) in actions" :class="{disabled: isDisabled(optMethod)}">
              <a href="" v-on:click.prevent="doAction(optMethod)">{{ optMethod }}</a>
            </li>
        </template>
      </ul>
    </portal>
  </div>
</template>

<script>
export default {
  props: ["item"],
//   mixins: [vueMixins.userProfile],
  data: ()=>({
    selectedUser: null,
    subusers: [],
    actions: {
      "Reset Activation": "Reset Activation",
      "Reset Password": "Reset Password",
      "Add Sub-user": "Add Sub-user",
      "Remove Sub-user": "Remove Sub-user",
    },
  }),
  computed: {
    usersById() {
      var result={};
      for(var i in this.users)
        result[this.users[i].id]=this.users[i];
      result[this.item.id]=this.item;
      return result;
    }
  },
  methods: {
    isDisabled(action) {
      if (action=='Reset Activation' && this.selectedUser!=this.item.id)
        return true;
      if (action=='Reset Activation' && this.item.status!='A07')
        return true;
      if (action=='Reset Password' && this.item.status!='A08')
        return true;
      if (action=='Reset Password' && !this.selectedUser)
        return true;
      if (action=='Remove Sub-user' && (!this.selectedUser || this.selectedUser==this.item.id))
        return true;
      return false;
    },

    async deleteSubuser(index) {
      var that=this;
      if (confirm("Are you sure?")) {
        startLoading();
        await api.delete("User", that.subusers[index].id);
        that.users.splice(index, 1);
        stopLoading("User deleted");
      }
    },

    async doAction(action) {
      if (action=='Reset Activation') {
        this.sendEmail('Welcome', true);
      }
      if (action=='Reset Password') {
        this.sendEmailUser('ResetPw');
      }
      if (action=='Add Sub-user') {
        this.newUserOpen=true;
      }
      if (action=='Remove Sub-user') {
        var subUserIndex=this.subusers.findIndex(a=>a.id==this.selectedUser);
        if (subUserIndex!=-1)
          this.deleteSubuser(subUserIndex);
      }
    },
  },
  async mounted() {
    var result = (await api.list('User', {applicationId: this.item.id})).items;
      if(result.length > 0) {
        var user = result[0];
      }
    this.subusers = (await api.list('User', {parentId: user.id})).items;
    this.subusers.unshift(user)
  },
}
</script>
