<template>
<div>
  <div class="content-table-container">
    <table class="content-table">
      <tbody>
        <tr>
          <th><label>
            Providers
          </label></th>
        </tr>
        <tr>
          <td style="text-align: left"  class="services auto-height">
            <div class="input-group" v-for="provider in providers">
              <label>{{ provider.providerName }}</label>
              <mnb-switch v-model="provider.switch" :disabled="!isNew && !editable" />
            </div>
          </td>
        </tr>
        <tr>
          <th><label>
            Duplicate Checker
          </label></th>
        </tr>
        <tr>
          <td style="text-align: left"  class="services auto-height">
            <div class="input-group countries">
              <div>
                Business
                <multiselect-dropdown :options="duplicateCheckerBusinessOptions.map(a=>({label: a, value: a}))" v-model="item.duplicateCheckerBusiness" :isMulti="true" :disabled="!isNew && !editable" />
              </div>
              <div>
                Contacts
                <multiselect-dropdown :options="duplicateCheckerContactsOptions.map(a=>({label: a, value: a}))" v-model="item.duplicateCheckerContacts" :isMulti="true" :disabled="!isNew && !editable" />
              </div>
              <div></div>
            </div>
          </td>
        </tr>
        <tr>
          <th><label>
            Countries
          </label></th>
        </tr>
        <tr>
          <td style="text-align: left"  class="services auto-height">
            <div class="input-group countries">
              <div>
                Business Formation
                <multiselect-dropdown :options="fullCountries.map(a=>({label: a.text, value: a.id}))" v-model="item.businessFormation" :isMulti="true" :disabled="!isNew && !editable" />
              </div>
              <div>
                Contacts
                <multiselect-dropdown :options="fullCountries.map(a=>({label: a.text, value: a.id}))" v-model="item.contacts" :isMulti="true" :disabled="!isNew && !editable" />
              </div>
              <div>
                
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <th>
            <label>
              Monthly plan
            </label>
            <button v-if="!monthlyPlanForm" v-on:click.prevent="monthlyPlanForm=true" class="btn btn-small" :disabled="!isNew && !editable" >Add</button>
            <form v-else v-on:submit="addMonthlyPlan">
              <input type="text" v-model="addMPlanName" />
              <button class="btn btn-small">Save</button>
              <button class="btn btn-small" v-on:click.prevent="monthlyPlanForm=false;addMPlanName=''">Cancel</button>
            </form>
          </th>
        </tr>
        <tr>
          <td style="text-align: left">
            <div class="services">
              <label v-for="method in item.allMethods" class="input-group">
                {{ method }}
                <span class="mn-switch">
                  <input v-show="false" type="checkbox" v-model="item.enabledMethods" :value="method" :disabled="!isNew && !editable" >
                  <span class="mn-slider" />
                </span>
              </label>
            </div>
          </td>
        </tr>
        <tr>
          <th><label>
            Billing
          </label></th>
        </tr>
        <tr>
          <td style="text-align: left">
            <div class="services auto-height">
              <div class="input-group">
                <label>Billing Option</label>
              </div>
              <div class="input-group">
                <label>Daily</label>
                <template v-if="providerChecked">
                  <template v-if="checkSwitch('billing', 'daily') == true">
                    <mnb-switch v-model="item.service.billing.daily" :disabled="!isNew && !editable" />
                  </template>
                  <template v-else>
                    <mnb-switch :disabled="true" :value="false" />
                  </template>
                </template>
                <template v-else>
                  <mnb-switch :disabled="true" :value="false" />
                </template>
              </div>
              <div class="input-group">
                <label>Monthly</label>
                <template v-if="providerChecked">
                  <template v-if="checkSwitch('billing', 'monthly') == true">
                    <mnb-switch v-model="item.service.billing.monthly" :disabled="!isNew && !editable" />
                  </template>
                  <template v-else>
                    <mnb-switch :disabled="true" :value="false" />
                  </template>
                </template>
                <template v-else>
                  <mnb-switch :disabled="true" :value="false" />
                </template>
              </div>
            </div>
            <br />
            <div class="services auto-height">
              <div class="input-group">
                <label>Billing Model</label>
              </div>
              <div class="input-group">
                <label>Flat Rate</label>
                <template v-if="providerChecked">
                  <template v-if="checkSwitch('billing', 'flatRate') == true">
                    <mnb-switch v-model="item.service.billing.flatRate" :disabled="!isNew && !editable" />
                  </template>
                  <template v-else>
                    <mnb-switch :disabled="true" :value="false" />
                  </template>
                </template>
                <template v-else>
                  <mnb-switch :disabled="true" :value="false" />
                </template>
              </div>
              <div class="input-group">
                <label>Interchange Plus Pricing</label>
                <template v-if="providerChecked">
                  <template v-if="checkSwitch('billing', 'interchangePlus') == true">
                    <mnb-switch v-model="item.service.billing.interchangePlus" :disabled="!isNew && !editable" />
                  </template>
                  <template v-else>
                    <mnb-switch :disabled="true" :value="false" />
                  </template>
                </template>
                <template v-else>
                  <mnb-switch :disabled="true" :value="false" />
                </template>
              </div>
              <div class="input-group">
                <label>Tiered</label>
                <template v-if="providerChecked">
                  <template v-if="checkSwitch('billing', 'tiered') == true">
                    <mnb-switch v-model="item.service.billing.tiered" :disabled="!isNew && !editable" />
                  </template>
                  <template v-else>
                    <mnb-switch :disabled="true" :value="false" />
                  </template>
                </template>
                <template v-else>
                  <mnb-switch :disabled="true" :value="false" />
                </template>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <th><label>
            Methods
          </label></th>
        </tr>
        <tr>
          <td style="text-align: left" class="services auto-height">
            <div class="input-group">
              <label>Visa</label>
              <template v-if="providerChecked">
                <template v-if="checkSwitch('methods', 'visa') == true">
                  <mnb-switch v-model="item.service.methods.visa" :disabled="!isNew && !editable" />
                </template>
                <template v-else>
                  <mnb-switch :disabled="true" :value="false" />
                </template>
              </template>
              <template v-else>
                <mnb-switch :disabled="true" :value="false" />
              </template>
            </div>
            <div class="input-group">
              <label>Mastercard</label>
              <template v-if="providerChecked">
                <template v-if="checkSwitch('methods', 'masterCard') == true">
                  <mnb-switch v-model="item.service.methods.masterCard" :disabled="!isNew && !editable" />
                </template>
                <template v-else>
                  <mnb-switch :disabled="true" :value="false" />
                </template>
              </template>
              <template v-else>
                <mnb-switch :disabled="true" :value="false" />
              </template>
            </div>
            <div class="input-group">
              <label>Discover </label>
              <template v-if="providerChecked">
                <template v-if="checkSwitch('methods', 'discover') == true">
                  <mnb-switch v-model="item.service.methods.discover" :disabled="!isNew && !editable" />
                </template>
                <template v-else>
                  <mnb-switch :disabled="true" :value="false" />
                </template>
              </template>
              <template v-else>
                <mnb-switch :disabled="true" :value="false" />
              </template>
            </div>
            <div class="input-group">
              <label>AMEX</label>
              <template v-if="providerChecked">
                <template v-if="checkSwitch('methods', 'amex') == true">
                  <mnb-switch v-model="item.service.methods.amex" :disabled="!isNew && !editable" />
                </template>
                <template v-else>
                  <mnb-switch :disabled="true" :value="false" />
                </template>
              </template>
              <template v-else>
                <mnb-switch :disabled="true" :value="false" />
              </template>
            </div>
            <div class="input-group">
              <label>JCB</label>
              <template v-if="providerChecked">
                <template v-if="checkSwitch('methods', 'jcb') == true">
                  <mnb-switch v-model="item.service.methods.jcb" :disabled="!isNew && !editable" />
                </template>
                <template v-else>
                  <mnb-switch :disabled="true" :value="false" />
                </template>
              </template>
              <template v-else>
                <mnb-switch :disabled="true" :value="false" />
              </template>
            </div>
            <div class="input-group">
              <label>Diners</label>
              <template v-if="providerChecked">
                <template v-if="checkSwitch('methods', 'diners') == true">
                  <mnb-switch v-model="item.service.methods.diners" :disabled="!isNew && !editable" />
                </template>
                <template v-else>
                  <mnb-switch :disabled="true" :value="false" />
                </template>
              </template>
              <template v-else>
                <mnb-switch :disabled="true" :value="false" />
              </template>
            </div>
            <div class="input-group">
              <label>Debit</label>
              <template v-if="providerChecked">
                <template v-if="checkSwitch('methods', 'debit') == true">
                  <mnb-switch v-model="item.service.methods.debit" :disabled="!isNew && !editable" />
                </template>
                <template v-else>
                  <mnb-switch :disabled="true" :value="false" />
                </template>
              </template>
              <template v-else>
                <mnb-switch :disabled="true" :value="false" />
              </template>
            </div>
            <div class="input-group">
              <label>Interac</label>
              <template v-if="providerChecked">
                <template v-if="checkSwitch('methods', 'interac') == true">
                  <mnb-switch v-model="item.service.methods.interac" :disabled="!isNew && !editable" />
                </template>
                <template v-else>
                  <mnb-switch :disabled="true" :value="false" />
                </template>
              </template>
              <template v-else>
                <mnb-switch :disabled="true" :value="false" />
              </template>
            </div>
          </td>
        </tr>
        <tr>
          <th>
            <label>Advanced Methods</label>
          </th>
        </tr>
        <tr>
          <td style="text-align: left" class="services auto-height">
            <div class="input-group">
              <label>Apple Pay</label>
              <template v-if="providerChecked">
                <template v-if="checkSwitch('advancedMethods', 'applePay') == true">
                  <mnb-switch v-model="item.service.advancedMethods.applePay" :disabled="!isNew && !editable" />
                </template>
                <template v-else>
                  <mnb-switch :disabled="true" :value="false" />
                </template>
              </template>
              <template v-else>
                <mnb-switch :disabled="true" :value="false" />
              </template>
          </div>
          <div class="input-group">
            <label>Google Pay</label>
            <template v-if="providerChecked">
              <template v-if="checkSwitch('advancedMethods', 'googlePay') == true">
                <mnb-switch v-model="item.service.advancedMethods.googlePay" :disabled="!isNew && !editable" />
              </template>
              <template v-else>
                <mnb-switch :disabled="true" :value="false" />
              </template>
            </template>
            <template v-else>
              <mnb-switch :disabled="true" :value="false" />
            </template>
          </div>
            <div class="input-group">
              <label>Samsung Pay</label>
              <template v-if="providerChecked">
                <template v-if="checkSwitch('advancedMethods', 'samsungPay') == true">
                  <mnb-switch v-model="item.service.advancedMethods.samsungPay" :disabled="!isNew && !editable" />
                </template>
                <template v-else>
                  <mnb-switch :disabled="true" :value="false" />
                </template>
              </template>
              <template v-else>
                <mnb-switch :disabled="true" :value="false" />
              </template>
            </div>
            <div class="input-group">
              <label>Interac Online</label>
              <template v-if="providerChecked">
                <template v-if="checkSwitch('advancedMethods', 'interacOnline') == true">
                  <mnb-switch v-model="item.service.advancedMethods.interacOnline" :disabled="!isNew && !editable" />
                </template>
                <template v-else>
                  <mnb-switch :disabled="true" :value="false" />
                </template>
              </template>
              <template v-else>
                <mnb-switch :disabled="true" :value="false" />
              </template>
            </div>
            <div class="input-group">
              <label>Interac Transfer</label>
              <template v-if="providerChecked">
                <template v-if="checkSwitch('advancedMethods', 'interacTransfer') == true">
                  <mnb-switch v-model="item.service.advancedMethods.interacTransfer" :disabled="!isNew && !editable" />
                </template>
                <template v-else>
                  <mnb-switch :disabled="true" :value="false" />
                </template>
              </template>
              <template v-else>
                <mnb-switch :disabled="true" :value="false" />
              </template>
            </div>
          </td>
        </tr>
        <tr>
          <th>
            <label>Bank</label>
          </th>
        </tr>
        <tr>
          <td style="text-align: left" class="services auto-height">
            <div class="input-group">
              <label>EFT</label>
              <template v-if="providerChecked">
                <template v-if="checkSwitch('bank', 'eft') == true">
                  <mnb-switch v-model="item.service.bank.eft" :disabled="!isNew && !editable" />
                </template>
                <template v-else>
                  <mnb-switch :disabled="true" :value="false" />
                </template>
              </template>
              <template v-else>
                <mnb-switch :disabled="true" :value="false" />
              </template>
          </div>
          <div class="input-group">
            <label>EFT Credits</label>
            <template v-if="providerChecked">
              <template v-if="checkSwitch('bank', 'eftCredits') == true">
                <mnb-switch v-model="item.service.bank.eftCredits" :disabled="!isNew && !editable" />
              </template>
              <template v-else>
                <mnb-switch :disabled="true" :value="false" />
              </template>
            </template>
            <template v-else>
              <mnb-switch :disabled="true" :value="false" />
            </template>
          </div>
          <div class="input-group">
            <label>ACH</label>
            <template v-if="providerChecked">
              <template v-if="checkSwitch('bank', 'ach') == true">
                <mnb-switch v-model="item.service.bank.ach" :disabled="!isNew && !editable" />
              </template>
              <template v-else>
                <mnb-switch :disabled="true" :value="false" />
              </template>
            </template>
            <template v-else>
              <mnb-switch :disabled="true" :value="false" />
            </template>
          </div>
          <div class="input-group">
            <label>ACH Credits</label>
            <template v-if="providerChecked">
              <template v-if="checkSwitch('bank', 'achCredits') == true">
                <mnb-switch v-model="item.service.bank.achCredits" :disabled="!isNew && !editable" />
              </template>
              <template v-else>
                <mnb-switch :disabled="true" :value="false" />
              </template>
            </template>
            <template v-else>
              <mnb-switch :disabled="true" :value="false" />
            </template>
            </div>
          </td>
        </tr>
        <tr>
          <th>
            <label>Modules</label>
            <template v-if="false">
            <button v-if="!modulesForm" v-on:click.prevent="modulesForm=true" class="btn btn-small" :disabled="!isNew && !editable" >Add</button>
            <form v-else v-on:submit="addModule">
              <input type="text" v-model="addMPlanName" />
              <button class="btn btn-small">Save</button>
              <button class="btn btn-small" v-on:click.prevent="modulesForm=false;addMPlanName=''">Cancel</button>
            </form>
            </template>
          </th>
        </tr>
        <tr>
          <td style="text-align: left">
            <div class="services">
              <label v-for="method in item.allModules" class="input-group">
                {{ method }}
                <span class="mn-switch">
                  <input v-show="false" type="checkbox" v-model="item.enabledMethods" :value="method" :disabled="!isNew && !editable" >
                  <span class="mn-slider" />
                </span>
              </label>
            </div>
          </td>
        </tr>
        <tr>
          <th>
            <label>Options</label>
          </th>
        </tr>
        <tr>
          <td style="text-align: left" class="services auto-height">
            <div class="input-group">
              <label>Multi-Currency</label>
              <template v-if="providerChecked">
                <template v-if="checkSwitch('options', 'multiCurrency') == true">
                  <mnb-switch v-model="item.service.options.multiCurrency" :disabled="!isNew && !editable" />
                </template>
                <template v-else>
                  <mnb-switch :disabled="true" :value="false" />
                </template>
              </template>
              <template v-else>
                <mnb-switch :disabled="true" :value="false" />
              </template>
            </div>
            <div class="input-group">
              <label>Subscription</label>
              <template v-if="providerChecked">
                <template v-if="checkSwitch('options', 'subscription') == true">
                  <mnb-switch v-model="item.service.options.subscription" :disabled="!isNew && !editable" />
                </template>
                <template v-else>
                  <mnb-switch :disabled="true" :value="false" />
                </template>
              </template>
              <template v-else>
                <mnb-switch :disabled="true" :value="false" />
              </template>
            </div>
            <div class="input-group">
              <label>Account Updater</label>
              <template v-if="providerChecked">
                <template v-if="checkSwitch('options', 'accountUpdater') == true">
                  <mnb-switch v-model="item.service.options.accountUpdater" :disabled="!isNew && !editable" />
                </template>
                <template v-else>
                  <mnb-switch :disabled="true" :value="false" />
                </template>
              </template>
              <template v-else>
                <mnb-switch :disabled="true" :value="false" />
              </template>
            </div>
            <div class="input-group">
              <label>Tokenization</label>
              <template v-if="providerChecked">
                <template v-if="checkSwitch('options', 'tokenization') == true">
                  <mnb-switch v-model="item.service.options.tokenization" :disabled="!isNew && !editable" />
                </template>
                <template v-else>
                  <mnb-switch :disabled="true" :value="false" />
                </template>
              </template>
              <template v-else>
                <mnb-switch :disabled="true" :value="false" />
              </template>
            </div>
            <div class="input-group">
              <label>Level 2</label>
              <template v-if="providerChecked">
                <template v-if="checkSwitch('options', 'level2') == true">
                  <mnb-switch v-model="item.service.options.level2" :disabled="!isNew && !editable" />
                </template>
                <template v-else>
                  <mnb-switch :disabled="true" :value="false" />
                </template>
              </template>
              <template v-else>
                <mnb-switch :disabled="true" :value="false" />
              </template>
            </div>
            <div class="input-group">
              <label>Level 3</label>
              <template v-if="providerChecked">
                <template v-if="checkSwitch('options', 'level3') == true">
                  <mnb-switch v-model="item.service.options.level3" :disabled="!isNew && !editable" />
                </template>
                <template v-else>
                  <mnb-switch :disabled="true" :value="false" />
                </template>
              </template>
              <template v-else>
                <mnb-switch :disabled="true" :value="false" />
              </template>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
</template>

<script>
import MnbSwitch from './switch.vue'
export default {
  components: {
      MnbSwitch,
    },
  props: ["item", "editable", "isNew"],
  data() { 
    return {
      providers: [],
      updateStatus: false,
      itemValue: {
        billing: {
          daily: false,
          monthly: false,
          flatRate: false,
          interchangePlus: false,
          tiered: false,
        },

        methods: {
          visa: false,
          masterCard: false,
          discover: false,
          amex: false,
          jcb: false,
          diners: false,
          debit: false,
          interac: false,
        },

        advancedMethods: {
          applePay: false,
          googlePay: false,
          samsungPay: false,
          interacOnline: false,
          interacTransfer: false,
        },

        bank: {
          eft: false,
          eftCredits: false,
          ach: false,
          achCredits: false,
        },

        options: {
          multiCurrency: false,
          subscription: false,
          accountUpdater: false,
          tokenization: false,
          level2: false,
          level3: false,
        },
      },
      monthlyPlanForm: false,
      modulesForm: false,
      addMPlanName: "",
      loaded: false,
    }
  },

  computed: {
    providerChecked() {
      if(this.providers.length > 0) {
        for(var prv of this.providers) {
            if(prv.switch == true) {
              return true;
            }
          
        }
      }
      return false
    },

    fullCountries() {
      return window.fullCountries
    },

    duplicateCheckerBusinessOptions () {
      return [
        "Business Name",
        "Business DBA",
        "Business Email",
        "Business Phone",
        "Business Bank Account",
      ]
    },

    duplicateCheckerContactsOptions () {
      return [
        "SSN/SIN",
        "Email",
        "Phone",
      ]
    }
  },

  watch: {
    item: {
      handler() {
        if (this.item && !this.item.enabledMethods) {
          this.item.enabledMethods=[];
        }
        if (this.item && !this.item.allMethods) {
          this.item.allMethods=[];
        }
        if (this.item && !this.item.allModules) {
          this.item.allModules=[];
        }
        if (this.item.allModules.join(",")!="Gateway,Vivpos Terminal,Virtual Terminal, Checkout, Invoicing") {
          this.item.allModules=["Gateway", "Vivpos Terminal", "Virtual Terminal", "Checkout", "Invoicing"];
        }
      },
      immediate: true,
    },

    providers: {
      handler() {
        if (!this.loaded)
          return;
        this.item.provider = []
        for(var provider of this.providers) {
          if(provider.switch == true) {
            this.item.provider.push(provider.id)
          }
        }
      },
      immediate: true,
      deep: true
    }
  },
  
  mounted() {
      
  },

  created() {
    this.loadData();
  },

  methods: {
    addMonthlyPlan() {
      if (this.addMPlanName)
        this.item.allMethods.push(this.addMPlanName);
      this.monthlyPlanForm=false;
      this.addMPlanName="";
    },
    addModule() {
      if (this.addMPlanName)
        this.item.allModules.push(this.addMPlanName);
      this.modulesForm=false;
      this.addMPlanName="";
    },
    loadData: async function() {
      var connexPromise=api.list("Provider", {providerType: 'Payments', status: ['!=', 'Archived']});
      var providers=(await connexPromise).items;
      if(this.item.provider == null) {
        this.item.provider = []
      }
      for(var provider of providers) {
        if(this.item.provider.length > 0) {
          for(var serviceProvider of this.item.provider) {
            if(provider.id == serviceProvider) {
              provider.switch = true
              break;
            }else{
              provider.switch = false
            }
          }
        }else{
          provider.switch = false
        }
        this.providers.push(provider);
      }
      if(this.item.service == null) {
        this.updateStatus = true
        this.item.service = {
          billing: {
            daily: false,
            monthly: false,
            flatRate: false,
            interchangePlus: false,
            tiered: false,
          },

          methods: {
            visa: false,
            masterCard: false,
            discover: false,
            amex: false,
            jcb: false,
            diners: false,
            debit: false,
            interac: false,
          },

          advancedMethods: {
            applePay: false,
            googlePay: false,
            samsungPay: false,
            interacOnline: false,
            interacTransfer: false,
          },

          bank: {
            eft: false,
            eftCredits: false,
            ach: false,
            achCredits: false,
          },

          options: {
            multiCurrency: false,
            subscription: false,
            accountUpdater: false,
            tokenization: false,
            level2: false,
            level3: false,
          },
        }
      }
      this.loaded=true;
    },

    checkSwitch(field1, field2) {
      if(this.providers.length > 0) {
        for(var prv of this.providers) {
          if(prv.switch == true) {
            if(prv.service.payments[field1][field2] == true) {
              if(this.updateStatus == true) {
                this.item.service[field1][field2] = true
              }
              return true;
            }
          }
        }
      }
      return false
    }
  }
}

</script>

<style scoped lang="scss">
  .mn-switch {
    display: block;
    position: relative;
    padding: 5px 0;
    width: 35px;
    .mn-slider {
      display: block;
      width: 100%;
      height: 8px;
      border-radius: 4px;
      background-color: #d9dce1;
      cursor: pointer;
      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 18px;
        height: 18px;
        border-radius: 9px;
        background-color: #777777;
        transition: all 0.3s ease;
        cursor: pointer;
      }
    }
    input:checked + .mn-slider::before {
      left: calc(100% - 18px);
      background-color: #13ce67;

    }
    input:disabled + .mn-slider {
      cursor: auto;
      opacity: .75;
      &::before {
        cursor: auto;
        opacity: .75;
      }
    }
  }
  .btn.btn-small {
    height: auto;
    line-height: 14px;
    font-size: 14px;
    text-transform: none;
    font-weight: normal;
    padding: 4px;
    min-width: 0;
    margin: 0 0 0 10px;
    width: 90px;
  }
  .content-table th form {
    margin: 0 0 0 10px;
    display: inline-flex;
    input {
      text-align: left;
      background: white;
      line-height: 14px;
      font-size: 14px;
      text-transform: none;
      margin: 0;
      padding: 4px;
    }
  }

  .countries {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
</style>

