<template>
  <div class="mcc-edit">
    <input type="text" :value="value" readonly v-if="readonly" />
    <autocomplete v-else :value="value" v-on:input="$emit('input', $event)" :options="mccCodesList" :short="short" />
    <a class="browse" href="" v-on:click.prevent="openBrowser">Select</a>
    <div class="popup shown" v-if="browser">
      <div>
        <div class="mcc-browser">
          <a class="close" href="" v-on:click.prevent="closeBrowser"></a>
          <table class="content-table">
            <thead>
              <tr>
                <th style="width: 9%">
                  <input type="text" v-model="q.status" placeholder="MCC code" />
                </th>
                <th style="width: 17%">
                  <dyn-select :options="categories" v-model="q.category" placeholder="Category" />
                </th>
                <th style="width: 30%">
                  <input type="text" v-model="q.description" placeholder="Description" />
                </th>
                <th style="width: 15%">
                  <dyn-select :options="classifications" v-model="q.classification" placeholder="Classification" />
                </th>
                <th style="width: 15%">
                  <dyn-select :options="designations" v-model="q.designation" placeholder="Designation" />
                </th>
                <th style="width: 30%">
                  Note
                </th>
                <th style="width: 1%">&nbsp;</th>
              </tr>
              <tr>
                <th class="spacer" colspan="7"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in shownCodes">
                <td class="text-center main-col">
                  {{ item.status }}
                </td>
                <td class="text-center">
                  {{ item.category }}
                </td>
                <td class="text-left">
                  {{ item.description }}
                </td>
                <td class="text-left">
                  {{ item.classification }}
                </td>
                <td class="text-left">
                  {{ item.designation }}
                </td>
                <td class="text-left">
                  {{ item.note }}
                </td>
                <td>
                  <a v-if="!readonly" class="select" href="" v-on:click.prevent="selectItem(index)">Select</a>
                </td>
              </tr>
            </tbody>
          </table>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["value", "short", "readonly"],
  data: ()=>({
    mccCodes: [],
    browser: false,
    q: {},
    categories: [],
    designations: [
      {value: "", label: "Designation"},
      {value: "Low Risk", label: "Low Risk"},
      {value: "Low Risk +", label: "Low Risk +"},
      {value: "Mid Risk", label: "Mid Risk"},
      {value: "High Risk", label: "High Risk"},
      {value: "High Risk +", label: "High Risk +"},
    ],
    classifications: [
      {value: "", label: "Classification"},
      {value: "Restricted", label: "Restricted"},
      {value: "Prohibited", label: "Prohibited"},
    ],
  }),
  computed: {
    mccCodesList() {
      return this.mccCodes.map(function(a) {
        return a.status+' - '+a.description;
      });
    },
    shownCodes() {
      return this.mccCodes.filter((a)=>{
        var q2={
          status: (this.q.status || "").toLowerCase(),
          category: (this.q.category || "").toLowerCase(),
          description: (this.q.description || "").toLowerCase(),
          designation: (this.q.designation || "").toLowerCase(),
          classification: (this.q.classification || "").toLowerCase(),
        };
        for(var field of ["status", "description", "category", "designation", "classification"])
          if (q2[field] && (a[field] || "").toLowerCase().indexOf(q2[field])==-1)
            return false;
        return true;
      });
    },
  },
  methods: {
    async loadMccCodes() {
      this.mccCodes=(await api.list("MCCCode")).items || [];
      var categories=[];
      for(var i in this.mccCodes) {
        var item=this.mccCodes[i];
        if (item.category && categories.indexOf(item.category)==-1)
          categories.push(item.category);
      }
      categories.sort();
      this.categories=categories.map((a)=>({value: a, label: a}));
      this.categories.unshift({value: "", label: "Category"});
    },
    openBrowser() {
      this.browser=true;
      this.q={};
    },
    closeBrowser() {
      this.browser=false;
      this.q={};
    },
    selectItem(index) {
      var item=this.shownCodes[index];
      if (item)
        this.$emit("input", item.status+" - "+item.description);
      this.closeBrowser();
    },
  },
  mounted() {
    this.loadMccCodes();
  },
}
</script>
