<template>
<div class="content-width">
  <form @submit.prevent>
    <div class="buttons">
      <button class="btn btn-xs" @click="save()">Submit</button>
      <button class="btn btn-xs" @click="openSaveAsTemplate()">Save as template</button>
      <button class="btn btn-xs" @click="clear()">Clear</button>
      <a v-if="loaded" :href="`/compliance/merchants-checklist/${loaded.id}/pdf`" target="_blank">
        <button type="button" class="btn btn-xs">View</button>
      </a>

      <div class="right">
        <span class="select-template">Select template:</span>
        <select v-model="loadedTemplate" placeholder="Select template to load" @change="loadTemplate()">
          <option v-for="template in templates" :key="template.id" :value="template.id">{{ template.docIndex }}</option>
        </select>
        <button v-if="loadedTemplate" class="btn btn-xs btn-danger delete" @click="deleteTemplate()">Delete</button>
      </div>
    </div>
    
    <div class="solution-detail" v-if="loadedTemplate">
      Solution: 
      <select v-model="solution" placeholder="solution">
        <option v-for="sol in solutions" :value="sol.id">{{ sol.nameSolution }}</option>
      </select>
      <button class="btn btn-xs" @click.prevent="setSolution">Set</button>
    </div>

    <div class="matrix-table-wrapper">
      <div class="matrix-header">
        <h2>CardBiller Merchant Underwriting Checklist</h2>
      </div>

     

      <table class="bordered small-padding">
        <tbody>
        <tr class="header"><td colspan="9">MERCHANT CHECKLIST</td></tr>
        <tr class="header text-center">
          <td>Class Note<br>Class</td>
          <td>LR-AA<br>00</td>
          <td>LR-CP<br>10</td>
          <td>LR-CP<br>20</td>
          <td>MR-MIX<br>30</td>
          <td>HR-CNP<br>40</td>
          <td>HR-CNP<br>50</td>
          <td>HRS-CP<br>98</td>
          <td>HRS-CNP<br>99</td>
        </tr> 
        <tr class="ver-allign text-center">
          <td>Criteria</td>
          <td>Credit 500 +<br>> 70% CP <br>Gets Pin or<br>Signature<br>Avg Ticket<br> < $1,000<br>< 1.2M Yr Vol<br><br>NOT MCC’s:<br>1520; 4816;<br>4829; 5734;<br>5999; 6051;<br>7321; 7375</td>
          <td>Credit 400 +<br>> 70% CP<br> Gets Pin or<br>Signature<br> Avg Ticket<br>< $1,000<br> < 6M Yr Vol</td>
          <td>Credit 400 +<br>> 70% CP<br> Gets Pin or<br>Signature<br> Avg Ticket<br>< $1,000 <br>> 6M Yr Vol</td>
          <td>Credit 400 +<br>CP &amp; CNP<br> Gets Pin or<br>Signature<br> Avg Ticket<br>< $1,000<br>< 10M Yr Vol</td>
          <td>Credit 400 +<br>> 30% CNP <br>Avg Ticket<br>< $1,000<br>< 3M Yr Vol</td>
          <td>Credit 400 +<br>> 30% CNP<br> Avg Ticket<br>< $1,000<br> > 3M Yr Vol</td>
          <td>Credit 400 +<br>> 70% CP<br> Gets Pin or<br>Signature<br>Avg Ticket<br>> $1,000<br>> 10M Yr Vol</td>
          <td>Credit 400 +<br>> 30% CNP<br> Avg Ticket<br>> $1,000<br> > 10M Yr Vol</td>
        </tr>
        <tr>
          <td>Select One</td>
          <td class="pen-checkbox" v-for="i in 8" :key="i"><i /></td>
        </tr>
      </tbody>
        <tbody v-for="(entry, idx) in list" :key="entry.name">
          <tr class="header text-left"><td colspan="9">{{ entry.name }}</td></tr>
          <tr class="header text-center">
            <td v-for="(column, index) in columns" :key="column + idx + index + 1">{{ column }}<br>{{ columns2[index] }}</td>
          </tr>
          <tr class="text-center" v-for="item in entry.items" :key="item">
            <td>{{ item }}</td>
            <td v-for="column in columns.length - 1" :key="column">
              <input v-model="form[formatName(entry.name, item, column)]" type="checkbox" class="checkbox">
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr class="header text-left"><td colspan="9">APPROVAL SCANS</td></tr>
          <tr>
            <td>Cristine Score</td>
            <td v-for="column in columns.length - 1" :key="column"></td>
          </tr>
          <tr>
            <td>Risk Calculator</td>
            <td>Credit-Chargeback Exposure</td>
            <td></td>
            <td>Delayed Delivery Exposure</td>
            <td></td>
            <td>Discount & Fee Exposure</td>
            <td></td>
            <td>Total Exposure</td>
            <td></td>
          </tr>
          <tr>
            <td colspan="2">Estimated Monthly Volume</td>
            <td colspan="2"></td>
            <td colspan="2">Estimated Annual Volume</td>
            <td colspan="2"></td>
            <td></td>
          </tr>
          <tr>
            <td>Approval Scan</td>
            <td class="pen-checkbox">YES <i /> NO <i /></td>
            <td>By</td>
            <td></td>
            <td>Date/Time</td>
            <td></td>
            <td>Result</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Final Approval Scan</td>
            <td class="pen-checkbox">YES <i /> NO <i /></td>
            <td>By</td>
            <td></td>
            <td>Date/Time</td>
            <td></td>
            <td>Result</td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  </form>

  <popup v-model="saveAsTemplate" @close="closeSaveTemplate()" auto-width>
    <div style="padding: 10px 30px 30px 30px; box-sizing: border-box;">
      <label style="display: block; margin-bottom: 10px">Choose template name</label>
      <input type="text" v-model="template_name">
      <select v-model="solution" placeholder="solution">
        <option v-for="sol in solutions" :value="sol.id">{{ sol.nameSolution }}</option>
      </select>
      
      <div class="buttons" style="margin-top: 10px;">
        <button class="ch-button btn-gray btn-sm no-margin" @click="closeSaveTemplate()">Cancel</button>
        <button class="ch-button btn-primary btn-sm no-margin" @click="saveTemplate()">Save template</button>
      </div>
    </div>
  </popup>
</div>
</template>

<script>
import MerchantsChecklistStaticTable from './MerchantsChecklistStaticTable'

export default {
  components: { MerchantsChecklistStaticTable },

  data () {
    return {
      solutions: [],
      solution: null,
      loadedTemplate: false,
      saveAsTemplate: false,
      loaded: null,
      template_name: null,
      form: {},
      columns: ['Class Note', 'LR-AA', 'LR-CP', 'LR-CP', 'MR-MIX', 'HR-CNP', 'HR-CNP', 'HRS-CP', 'HRS-CNP'],
      columns2: ['Class', '00', '10', '20', '30', '40', '50', '98', '99'],
      templates: [],
      list: [
        {
          name: 'REQUIRED DOCUMENTS',
          items: [
            'Incorporation Documents – Parent',
            'Incorporation Documents',
            'Passport Copy',
            'Recent Utility Bill',
            '3 Months Bank Statements or Signed Bank Letter',
            'Void Check or Signed Bank Letter'
          ]
        },
        {
          name: 'REQUIRED FOR NEW BUSINESS',
          items: [
            'Business Plan & Projections',
            'Bank Statement or Signed Bank Letter',
            'Complete Business Summary'
          ]
        },
        {
          name: 'CONDITIONAL DOCUMENTS',
          items: [
            '6 Months Processing Statements',
            'Corporate Structure Diagram',
            '2 Years Financial Statements',
            'Copy of License(s)',
            'PCI DSS Certificate or Adherence',
            'Sample PAD Authorization',
            'Completed MOTO/Internet Questionnaire',
            'Marketing Materials if no Website',
            'Complete Business Summary',
            'Personal Guarantee'
          ]
        },
        {
          name: 'APPLICATION & AGREEMENTS',
          items: [
            'Completed Merchant Submission Form – By Partner',
            'Complete & Signed Merchant Application',
            'Complete & Signed Merchant Services Agreement',
            'Merchant Fee & Service Schedule',
            'Merchant Application Summary',
            'Signed Bank Disclosure'
          ]
        },
        {
          name: 'WEBSITE & MARKETING MATERIALS',
          items: [
            'Complete Website Check ',
            'Screenshots of Websites and Payment Areas',
            'Marketing Materials Review'
          ]
        },
        {
          name: 'SCANS',
          items: [     
            'Internal Negative List',
            'Negative News',
            'Risk Calculator',
            'Credit Report/Score',
            'D&B or Experian Business Credit Report',
            'MATCH/VMAS ',
            'OFAC',
            'KYC',
            'IDM:  1',
            'IDM:  2',
            'IDM:  3',
            'IDM: 4',
            'G2:  1',
            'G2:  2',
            'G2:  3',
            'G2: 4',
            'MBT:  1',
            'MBT:  2',
            'MBT:  3',
            'MBT: 4',
            'FLINKS'
          ]
        },
        {
          name: 'ADDITIONAL VERIFICATIONS',
          items: [
            'Complete Site Survey',
            'Primary Supplier Reference Verified'
          ]
        }
      ]
    }
  },

  async created () {
    this.templates = (await api.list('ComplianceDocument', { type: 'MERCHANTS_CHECKLIST_TEMPLATE' })).items
    const data = (await api.list('ComplianceDocument', { type: 'MERCHANTS_CHECKLIST' })).items
    if (data.length) {
      this.loaded = data[0]
      this.setLoaded(data[0])
    }
    this.solutions = (await api.list('Solution', { solutionType: 'Merchants' })).items
  },
  
  computed: {
    currentSolution () {
      if (this.loadedTemplate) {
        const template = this.templates.find(item => item.id == this.loadedTemplate)
        if (template) {
          const solution = this.solutions.find(item => item.id == template.solution)
          return solution;
        }
      }
      return null;
    },
  },
  
  watch: {
    currentSolution() {
      this.solution = this.currentSolution ? this.currentSolution.id : null;
    },
  },

  methods: {
    loadTemplate () {
      if (this.loadedTemplate) {
        const template = this.templates.find(item => item.id == this.loadedTemplate)
        this.setLoaded(template)
        stopLoading('Template Loaded');
      }
    },

    async deleteTemplate () {
      if (confirm('Are you sure you want to delete selected template?')) {
        await api.update("ComplianceDocument", this.loadedTemplate, { form: {} })
        await api.delete('ComplianceDocument', this.loadedTemplate)
        const idx = this.templates.findIndex(item => item.id == this.loadedTemplate)
        this.loadedTemplate = null
        this.templates.splice(idx, 1)
        stopLoading('Template deleted!');
      }

    },

    async saveTemplate () {
      if (!this.template_name)
        return stopLoading("Profile name is required", "error");
      if (!this.solution)
        return stopLoading("Profile solution is required", "error");
      startLoading();
      const payload = this.getPayload()
      try {
        const response = await api.create("ComplianceDocument", {
          userId: window.appData.currentUser.id,
          type: 'MERCHANTS_CHECKLIST_TEMPLATE',
          docIndex: this.template_name,
          status: '',
          signed: null,
          created: 'CURRENT_TIMESTAMP',
          form: payload,
          solution: this.solution,
        }, true);
        this.templates.push(response.item)
        this.template_name = null
        this.solution = null
        this.saveAsTemplate = false
        stopLoading("Template saved!");
      } catch (e) {
        stopLoading(e, 'error');
      }
    },
  
    openSaveAsTemplate () {
      this.saveAsTemplate = true
    },

    closeSaveTemplate () {
      this.saveAsTemplate = false
      this.template_name = null
      this.solution = null
    },

    getPayload () {
      const payload = []
      Object.keys(this.form).forEach(name => {
        if (this.form[name]) {
          payload.push({ name, value: '1' })
        }
      })
      return payload
    },

    async save () {
      startLoading();
      const payload = this.getPayload()
      try {
        if (this.loaded) {
          await api.update("ComplianceDocument", this.loaded.id, { form: {} })
          await api.update("ComplianceDocument", this.loaded.id, { form: payload })
        } else {
          await api.create("ComplianceDocument", {
            userId: window.appData.currentUser.id,
            type: 'MERCHANTS_CHECKLIST',
            docIndex: 'GEN_DOC_ID',
            status: '',
            signed: null,
            created: 'CURRENT_TIMESTAMP',
            form: payload,
            solution: this.solution,
          });
        }
        stopLoading("Saved");
      } catch (e) {
        stopLoading(e, 'error');
      }
    },
    
    async setSolution() {
      startLoading();
      await api.update("ComplianceDocument", this.loadedTemplate, { solution: this.solution })
      stopLoading("Solution updated");
    },

    async setLoaded (data) {
      let parsedData = {}
      if (data) {
        data.form.forEach(item => {
          let value = item.value
          if (['1', '0'].includes(value)) value = !!(value === '1')
           parsedData[item.name] = value
        })
      }

      this.init(parsedData)
    },

    init (data) {
      this.form = {}
      this.list.forEach(list => {
        list.items.forEach((item, index) => {
          for(let i = 1; i <= 8; i++) {
            const key = this.formatName(list.name, item, i)
            const value = Object.keys(data).length && data[key] ? data[key] : false
            this.$set(this.form, key, value)
          }
        })
      })
    },

    formatName (prefix, text, index) {
      text = prefix.substring(0, 15) + ' ' +  text.substring(0, 35)
      text = text.replace(/\s+/g, '_').toLowerCase()
      text = text.replace(/[()',.\/%/]/g, '').toLowerCase()
      text += `.${index}`
      return text
    },

    clear () {
      Object.keys(this.form).forEach(key => {
        this.form[key] = false
      })
      this.loadedTemplate = null
      stopLoading('Cleared!');
    }
  }
}
</script>

<style lang="scss" scoped>
  .right {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .delete {
    margin-left: 10px;
    margin-right: 0;
  }
  .select-template {
    margin-right: 10px;
    line-height: 30px;
  }
  .solution-detail {
    margin: 10px 0 0;
    text-align: right;
  }
</style>
