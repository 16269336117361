<template>
<div>
  <div class="content-table-container">
    <table class="content-table">
      <thead>
        <tr>
          <th style="width: 20%">MCC code</th>
          <th style="width: 80%">Description</th>
        </tr>
        <tr>
          <th class="spacer" colspan="4"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items">
          <td class="text-center main-col">
            {{ item.status }}
          </td>
          <td class="text-center">
            {{ item.description }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
</template>

<script>
export default {
  data() { return {
    items: [],
  }},
  methods: {
    reload() {
      var that=this;
      api.list("MCCCode", {}, ["`status`:ASC"]).then(function(data) {
        that.items=JSON.parse(JSON.stringify(data.items));
      });
    },
  },
  mounted() {
    this.reload();
  },
}
</script>

