<template>
<div>
      <div class="action-buttons">
        <a href="" :class="{disabled: item.agentDb.status!='A02'}" v-on:click.prevent="item.agentDb.status=='A02' && startAgreement()">Start Agreement</a>
<!--         <a href="" v-on:click.prevent="">Upload Signed Agreement</a> -->
<!--         <a href="" v-on:click.prevent="">View Agreement</a> -->
      </div>
      <table class="content-table file-list">
        <thead>
          <tr>
            <th style="width: 1%">#</th>
            <th>Date Created</th>
            <th>Agreement</th>
            <th>Date Signed</th>
            <th>Signed Agreement</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(agreement, ai) in agreements">
            <td>{{ ai+1 }}</td>
            <td>{{ formatDate(agreement.created, "YYYY-MM-DD hh:mm") }}</td>
            <td><a target="_blank" :href="agreementPath(agreement)">{{ agreement.name }}</a></td>
            <td><a target="_blank" v-if="agreement.filename" :href="fileLink(agreement.filename)">{{ agreement.name+' signed' }}</a></td>
            <td>{{ formatDate(agreement.signed, "YYYY-MM-DD hh:mm") }}</td>
            <td><a href="" class="button" v-on:click.prevent="uploadSigned(agreement, ai)">Upload</a></td>
          </tr>
        </tbody>
      </table>
</div>

</template>

<script>
export default {
  props: ["item"],
  data: ()=>({
    agreements: [],
  }),
  computed: {
  },
  methods: {
    fileLink(fileName, docId) {
      if (!fileName)
        return null;
      if (docId)
        return "download/PartnerDocument/"+docId;
      return "files/agent/"+this.item.agentDb.id+"/"+fileName;
    },
    agreementPath(agreement) {
      return window.appData.agreementPdf.replace("%d", agreement.id);
    },
    listAgreements: async function() {
      this.agreements=(await api.list("PartnerAgreement", {agentId: this.item.id}, ["id:DESC"])).items || [];
    },
    uploadSigned(agreement, index) {
      var that=this;
      var input=$("<input />", {type: "file"});
      input.on("change", async function(e) {
        var file=this.files[0];
        startLoading();
        if (!window.validateFileExt(file))
          return stopLoading("Invalid file extension", "error");
        await api.update("PartnerAgreement", agreement.id, {signed: "CURRENT_TIMESTAMP"});
        await api.upload("PartnerAgreement", agreement.id, file);
        that.$set(that.agreements, index, (await api.get("PartnerAgreement", agreement.id)).item);
        stopLoading("File uploaded");
      });
      input.click();
    },
  },
  mounted() {
    this.listAgreements();
  },
  mixins: [vueMixins.dateFormatter],
}
</script>
