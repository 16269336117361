<template>
<div>
<form class="scan-filter" v-on:submit.prevent="activateFilter">
  <div class="actions">
    <button class="btn" disabled>+ New</button>
  </div>
  <ul class="filter">
    <li>
      <label>Date search</label>
      <datepicker v-model="filter.dateFrom" />
      <datepicker v-model="filter.dateTo" />
    </li>
    <li>
      <label>Account</label>
<!--       <dyn-select :options="applicationsToShow" v-model="filter.account" /> -->
      <input type="text" v-model="filter.accountNo" />
    </li>
    <li>
      <label>Result</label>
      <dyn-select :options="yesNoNull" v-model="filter.complete" />
    </li>
    <li class="actions">
      <button class="btn">Search</button>
    </li>
  </ul>
</form>
<ul class="acc-results">
  <li class="active">
    <h4><span class="label">Acuant Scans</span></h4>
    <div class="acc-list" style="display: block;">
        <table>
            <thead>
                <tr>
                    <th>Account ID</th>
                    <th>Company Name</th>
                    <th>Date</th>
                    <th>Result</th>
                    <th>&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in itemsToShow">
                  <td><a :href="'/applications/'+item.applicationId+'#compliance-idm'">{{ applications[item.applicationId] && applications[item.applicationId].accountId }}</a></td>
                  <td>{{ applications[item.applicationId] && applications[item.applicationId].companyName }}</td>
                  <td>{{ item.createdDate }}</td>
                  <td>{{ item.response && item.response.res }}</td>
                  <td v-if="item.response && item.response">
                    <a href="" class="tb-btn" v-on:click.prevent="viewResult(item)">View</a>
                  </td>
                  <td v-else>
<!--                     <a href="" class="tb-btn" v-on:click.prevent="refresh(item.id)">Refresh</a> -->
                    &nbsp;
                  </td>
                </tr>
            </tbody>
        </table>
    </div>
</li>
</ul>
  <div class="popup shown api-log-pop" v-if="selectedResponse">
    <div><div class="pop-inner">
      <a class="close" href="" v-on:click.prevent="selectedResponse=null"></a>
      <div class="log-body">
        <div class="response-data">
          <label>Response:</label>
          <json-viewer :value="selectedResponse" />
        </div>
      </div>
    </div></div>
  </div>

</div>
</template>
<script>
export default {
  data: ()=>({
    filter: {
      dateFrom: null,
      dateTo: null,
      account: null,
      accountNo: null,
      complete: null,
    },
    activeFilter: {},
    items: [],
    applications: {},
    yesNoNull: [
      {value: null, label: "-"},
      {value: "ACCEPT", label: "ACCEPT"},
    ],
    selectedResponse: null,
  }),
  created() {
    this.listItems();
  },
  computed: {
    applicationsToShow() {
      var result=[{value: null, label: "-"}];
      for(var id in this.applications)
        result.push({value: id, label: this.applications[id].accountId});
      return result;
    },
    itemsToShow() {
      return this.items.filter(a=>{
        if (this.activeFilter.complete && (!a.response || a.response.res!=this.activeFilter.complete)) return false;
        if (this.activeFilter.account && a.applicationId!=this.activeFilter.account) return false;
        if (this.activeFilter.dateFrom && a.createdDate<this.activeFilter.dateFrom) return false;
        if (this.activeFilter.dateTo && a.createdDate>this.activeFilter.dateTo+" 24") return false;
        if (this.activeFilter.accountNo) {
          var application=this.applications[a.applicationId];
          if (!application || !application.accountId || application.accountId.indexOf(this.filter.accountNo)==-1)
            return false;
        }
        return true;
      });
    },
  },
  methods: {
    viewResult(item) {
      this.selectedResponse=item.response;
    },
    activateFilter() {
      this.activeFilter=JSON.parse(JSON.stringify(this.filter));
    },
    printUrl(item) {
      if (!item)
        return null;
      if (!item.response || !item.response.pdf_url)
        return null;
      return item.response.pdf_url;
    },
    async refresh(id) {
      startLoading();
      await api.update("ExtCheck", id, {type: "g2"});
      await this.listItems();
      stopLoading();
    },
    async listItems() {
      this.items=(await api.list("ExtCheck", {type: "idm"}, ["id:DESC"])).items;
      var appIds=this.items.filter((a)=>a.applicationId*1).map((a)=>a.applicationId*1);
      var apps=await api.list("Application", {id: ["IN ("+appIds.join(",")+")"]});
      for(var app of apps.items)
        this.$set(this.applications, app.id, app);
    },
  },
}
</script>
<style lang="scss">
.scan-filter {
  display: flex;
  margin-bottom: 20px;
  .actions button {
    text-decoration: none;
    padding: 4px 20px;
    border: 1px solid black;
    border-radius: 3px;
    color: white;
    background: rgb(50,50,50);
    background: linear-gradient(to bottom, rgba(50,50,50,1) 0%,rgba(5,5,5,1) 100%);
  }
  .filter {
    margin-left: 20px;
    display: flex;
    li {
      margin-right: 20px;
      display: flex;
      align-items: middle;
      label {
        margin-right: 6px;
        line-height: 26px;
      }
      .hasDatepicker {
        width: 120px;
      }
    }
  }
}
</style>
