<template>
  <div class="tab" :class="{ active }" @click="$emit('click')">
    {{ title }}
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },

    active: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.tab {
  display: flex;
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  white-space: nowrap;
  margin-right: 20px;
  position: relative;
  cursor: pointer;
  font-weight: 600;
  color: #777;
  transition: all ease 0.2s;
  &:hover {
    color: #000;
  }
  &.active {
    color: #51b13e;
    &:after {
      content: "";
      display: block;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: -1px;
      height: 1px;
      background-color: #51b13e;
    }
  }
}
</style>