<template>
<div>
    <company-select v-model="itemId" :isNew="isNew" :type.sync="type" />
  <div class="ncontent">
    <div class="header">
      <company-edit :itemId="itemId" :isNew="isNew" :type="type" />
    </div>
  </div>
</div>
</template>

<script>
export default {
  props: ["isNew"],
  data: ()=>({
    itemId: null,
    type: "Employees",
  }),
}
</script>
