<template>
<div>
  <view-app-precheck ref="precheck" key="precheck" :uwList="itemList" />
</div>
</template>
<script>
export default {
  props: ["item", "merchant"],
  data: ()=>({
    itemList: [],
  }),
  computed: {
  },
  methods: {
    listPreChecks: async function() {
      this.itemList=(await api.list("PreCheck", {applicationId: this.item.id}, ["id:DESC"])).items || [];
    },
  },
  mounted() {
    this.listPreChecks();
  },
}
</script>
