<template>
<input type="text" :value="valueToDisplay" v-on:input="updateValue" v-on:focus="focused=true" v-on:blur="focused=false" @keypress="allowLetterNumber($event, { letters: false, numbers: true, allow: [46] })" />
</template>

<script>
export default {
  mixins: [ vueMixins.helpers ],

  data() { return {
    focused: false,
  }},
  computed: {
    valueToDisplay() {
      if (!this.focused) {
        if(this.value) {
          var value=((this.value || "0")+"").replace(/[, ]/g, "")*1;
          if (this.digits*1) {
            value=value.toFixed(this.digits*1);
          } else {
            value=value.toFixed(4);
            for(var i=value.length-8;i>0;i-=3)
              value=value.substring(0, i)+","+value.substring(i);
          }
          return (this.prefix || "")+value+(this.suffix || "");
        }
      }
      return this.value;
    },
  },
  props: ["value", "prefix", "suffix", "digits"],
  methods: {
    updateValue($event) {
      var value=(($event.target.value || "0")+"").replace(/[, ]/g, "")*1;
//       if (value>=10000000000)
//         value%=10000000000;
      this.$emit("input", value);
    },
  },
}
</script>
