<template>
<div>
  <business-menu />

  <div class="ncontent touch-it">
    <div class="header split">
      <div>
        <ul class="breadcrumbs">
          <li><a>Orders Overview</a></li>
        </ul>
        <div class="toolbar" />
        <form class="search-form">
          <h3>Search Options</h3>
          <div class="f2split">
            <div>
              <div class="field">
                <label>Date Range</label>
                <div class="field-group">
                  <input type="text" id="dp1569254393711" class="hasDatepicker">
                  <input type="text" id="dp1569254393712" class="hasDatepicker">
                </div>
              </div>
              <div class="field">
                <label>Trans ID</label>
                <input type="text">
              </div>
              <div class="field">
                <label>TID</label>
                <select>
                  <option value="">(all)</option>
                  <option value="2">Allium</option>
                  <option value="3">Acme Inc.</option>
                  <option value="4">Internal Agent</option>
                  <option value="5">David Collins Jr</option>
                  <option value="7">GBWEB</option>
                  <option value="14"></option>
                  <option value="15"></option>
                  <option value="20"></option>
                </select>
              </div>
              <div class="field">
                <label>Trans Type</label>
                <select>
                  <option value="">(all)</option>
                  <option value="A00">A00 - To be Assessed</option>
                  <option value="A01">A01 - Pre-Qualification Complete</option>
                  <option value="A02">A02 - New Application</option>
                  <option value="A03">A03 - In Process</option>
                  <option value="A04">A04 - Approved</option>
                  <option value="A05">A05 - Denied</option>
                  <option value="A06">A06 - Duplicate</option>
                </select>
              </div>
              <div class="field">
                <label>Entry Type</label>
                <select>
                  <option value="">(all)</option>
                  <option value="A00">A00 - To be Assessed</option>
                  <option value="A01">A01 - Pre-Qualification Complete</option>
                  <option value="A02">A02 - New Application</option>
                  <option value="A03">A03 - In Process</option>
                  <option value="A04">A04 - Approved</option>
                  <option value="A05">A05 - Denied</option>
                  <option value="A06">A06 - Duplicate</option>
                </select>
              </div>
            </div>
            <div>
              <div class="field">
                <label>Scheme</label>
                <select>
                  <option value="">(all)</option>
                  <option value="A00">A00 - To be Assessed</option>
                  <option value="A01">A01 - Pre-Qualification Complete</option>
                  <option value="A02">A02 - New Application</option>
                  <option value="A03">A03 - In Process</option>
                  <option value="A04">A04 - Approved</option>
                  <option value="A05">A05 - Denied</option>
                  <option value="A06">A06 - Duplicate</option>
                </select>
              </div>
              <div class="field">
                <label>Card</label>
                <input type="text">
              </div>
              <div class="field">
                <label>Ammount</label>
                <input type="text">
              </div>
              <div class="field">
                <label>Batch</label>
                <input type="text">
              </div>
              <div class="field">
                <label>Status</label>
                <select>
                  <option value="">(all)</option>
                  <option value="Pending">Pending</option>
                  <option value="Complete">Complete</option>
                  <option value="Expired">Expired</option>
                  <option value="Cancel">Cancel</option>
                  <option value="Resolved">Resolved</option>                
                </select>
              </div>
            </div>
          </div>
          <div class="buttons"><button class="btn btn-large btn-primary">Search</button></div>
        </form>
      </div>
      <aside>
        <h3>Summary Report</h3>
        <table class="breakdown-table">
          <thead>
            <tr>
              <th>Status</th>
              <th>Count</th>              
            </tr>
            <tr>
              <th colspan="2" class="spacer"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in list">
              <td>{{ item.name }}</td>
              <td>{{ item.data.length }}</td>             
            </tr>
          </tbody>
        </table>
      </aside>
    </div>
    <div class="hr"></div>
    <div class="content">
      <div>
        <p class="result-count">Your search returned <span>0</span> result(s).</p>
        <ul class="acc-results">
          <li v-for="(item, type) in list" :key="type" :class="{ active: show[type] }">
            <h4 @click="show[type] = !show[type]"><span class="label">{{ item.name }}</span></h4>
            <div v-show="show[type]" class="acc-list" style="display: block;">
              <table>
                <thead>
                  <tr>
                    <th>Empl ID</th>
                    <th>Company</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Username</th>
                    <th>Email</th>
                    <th>Title</th>
                    <th>Dept</th>
                    <th>Last Login</th>
                    <th>Options</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="emp in item.data" :key="data.id">
                    <td>
                      <a :href="`/employees/view/${emp.id}`" class="link">{{ formatID('81', emp.id) }}</a>
                    </td>
                    <td>{{ emp.companyId.name }}</td>
                    <td>{{ emp.first_name }}</td>
                    <td>{{ emp.last_name }}</td>
                    <td>{{ emp.username }}</td>
                    <td>{{ emp.email }}</td>
                    <td>{{ emp.title }}</td>
                    <td>{{ userGroup(emp.role) }}</td>
                    <td>{{ emp.lastLogin ? formatDate(emp.lastLogin, 'MM/DD/YYYY') : 'N/A' }}</td>
                    <td>-</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  mixins: [ vueMixins.helpers, vueMixins.dateFormatter ],

  data () {
    return {
      show: {
        MERCHANTS: false,
        FACILITATORS: false,
        SUBMERCHANTS: false,
        AGENTS: false
      },

      data: []
    }
  },

  computed: {
    list () {
      return {
        MERCHANTS: {
          name: "Pending Orders",
          data: [],
        },
        FACILITATORS: {
          name: "Completed Orders",
          data: [],
        },
        SUBMERCHANTS: {
          name: "Paused Orders",
          data: [],
        },
        AGENTS: {
          name: "Cancelled Orders",
          data: [],
        },
      }
    }
  },

  created () {
    this.load()
  },

  methods: {
    openPreview (emp) {
      console.log('preview', emp)
    },

    async load () {
      this.data = []; // TODO
    }
  }
}
</script>
