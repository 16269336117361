<template>
<div class="business-length">
  <input :value="years" type="text" v-on:input="update($event, 'years')" v-on:keypress="kp">
  <label>years</label>
  <input :value="months" type="text" v-on:input="update($event, 'months')" v-on:keypress="kp">
  <label>months</label>
</div>
</template>

<script>
export default {
  props: ["value"],
  computed: {
    years() {
      return Math.min(99, Math.floor((this.value || 0)/12));
    },
    months() {
      return Math.round((this.value || 0)%12);
    },
  },
  methods: {
    kp($e) {
      if (["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"].indexOf($e.key)==-1)
        $e.preventDefault();
    },
    update($e, mode) {
      var val=$e.target.value;
      var newValue=this.value || 0;
      if (mode=="years")
        newValue=Math.round(val)*12+(newValue%12);
      if (mode=="months")
        newValue=Math.floor(newValue/12)*12+Math.round(val%12);
      this.$emit("input", newValue);
    },
  },
}
</script>
