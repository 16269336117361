<template>
<div>
  <div class="content-table-container">
    <table v-if="loaded" class="content-table">
      <tbody>
        <tr>
          <th><label>
            Providers
          </label></th>
        </tr>
        <tr>
          <td style="text-align: left"  class="services auto-height">
            <div class="input-group" v-for="provider in providers">
              <label>{{ provider.providerName }}</label>
              <mnb-switch v-model="provider.switch" :disabled="!isNew && !editable"  v-on:input="handleExclusiveProvider(provider, $event)" />
            </div>
          </td>
        </tr>
        <tr>
          <th><label>
            Duplicate Checker
          </label></th>
        </tr>
        <tr>
          <td style="text-align: left"  class="services auto-height">
            <div class="input-group countries">
              <div>
                Business
                <multiselect-dropdown :options="duplicateCheckerBusinessOptions.map(a=>({label: a, value: a}))" v-model="item.duplicateCheckerBusiness" :isMulti="true" :disabled="!isNew && !editable" />
              </div>
              <div>
                Contacts
                <multiselect-dropdown :options="duplicateCheckerContactsOptions.map(a=>({label: a, value: a}))" v-model="item.duplicateCheckerContacts" :isMulti="true" :disabled="!isNew && !editable" />
              </div>
              <div></div>
            </div>
          </td>
        </tr>
        <tr>
          <th><label>
            Countries
          </label></th>
        </tr>
        <tr>
          <td style="text-align: left"  class="services auto-height">
            <div class="input-group countries">
              <div>
                Business Formation
                <multiselect-dropdown :options="fullCountries.map(a=>({label: a.text, value: a.id}))" v-model="item.businessFormation" :isMulti="true" :disabled="!isNew && !editable" />
              </div>
              <div>
                Contacts
                <multiselect-dropdown :options="fullCountries.map(a=>({label: a.text, value: a.id}))" v-model="item.contacts" :isMulti="true" :disabled="!isNew && !editable" />
              </div>
              <div>
              
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <th>
            <label>Monthly plan</label>
            <button v-if="!monthlyPlanForm" v-on:click.prevent="monthlyPlanForm=true" class="btn btn-small" :disabled="!isNew && !editable" >Add</button>
            <form v-else v-on:submit="addMonthlyPlan">
              <input type="text" v-model="addMPlanName" />
              <button class="btn btn-small">Save</button>
              <button class="btn btn-small" v-on:click.prevent="monthlyPlanForm=false;addMPlanName=''">Cancel</button>
            </form>
          </th>
        </tr>
        <tr>
          <td style="text-align: left">
            <div class="services">
              <label v-for="method in item.allMethods" class="input-group">
                {{ method }}
                <span class="mn-switch">
                  <input v-show="false" type="checkbox" v-model="item.enabledMethods" :value="method" :disabled="!isNew && !editable" >
                  <span class="mn-slider" />
                </span>
              </label>
            </div>
          </td>
        </tr>
        <tr>
          <th>
            <label>Pricing Model</label>
          </th>
        </tr>
        <tr>
          <td style="text-align: left">
            <div class="services auto-height">
              <div class="input-group">
                <label>Billing Option</label>
              </div>
              <label class="input-group">
                PrePay
                <span v-if="settings.modules.banking.prepay" class="mn-switch">
                  <input v-show="false" type="checkbox" v-model="item.enabledMethods" value="PrePay" :disabled="!isNew && !editable" >
                  <span class="mn-slider" />
                </span>
                <span v-else class="mn-switch">
                  <input v-show="false" type="checkbox" v-model="item.enabledMethods" value="" :disabled="true" >
                  <span class="mn-slider" />
                </span>
              </label>
              <label class="input-group">
                PostPay
                <span v-if="settings.modules.banking.postpay" class="mn-switch">
                  <input v-show="false" type="checkbox" v-model="item.enabledMethods" value="PostPay" :disabled="!isNew && !editable" >
                  <span class="mn-slider" />
                </span>
                <span v-else class="mn-switch">
                  <input v-show="false" type="checkbox" v-model="item.enabledMethods" value="" :disabled="true" >
                  <span class="mn-slider" />
                </span>
              </label>
            </div>
          </td>
        </tr>
        <tr>
          <th>
            <label>Accounts</label>
          </th>
        </tr>
        <tr>
          <td style="text-align: left" class="services auto-height">
            <div class="input-group">
              <label>KYC</label>
              <template v-if="providerChecked">
                <template v-if="checkSwitch('accounts', 'kyc') == true">
                  <mnb-switch v-model="item.service.accounts.kyc" :disabled="!isNew && !editable" />
                </template>
                <template v-else>
                  <mnb-switch :disabled="true" :set="item.service.accounts.kyc = false" />
                </template>
              </template>
              <template v-else>
                <mnb-switch :disabled="true" :set="item.service.accounts.kyc = false" />
              </template>
            </div>
            <div class="input-group">
              <label>AML</label>
              <template v-if="providerChecked">
                <template v-if="checkSwitch('accounts', 'aml') == true">
                  <mnb-switch v-model="item.service.accounts.aml" :disabled="!isNew && !editable" />
                </template>
                <template v-else>
                  <mnb-switch :disabled="true" :set="item.service.accounts.aml = false" />
                </template>
              </template>
              <template v-else>
                <mnb-switch :disabled="true" :set="item.service.accounts.aml = false" />
              </template>
            </div>
          </td>
          </tr>
        <tr>
          <th>
            <label>Methods</label>
          </th>
        </tr>
        <tr>
          <td style="text-align: left" class="services auto-height">
            <div class="input-group">
              <label>ACH</label>
              <template v-if="providerChecked">
                <template v-if="checkSwitch('methods', 'ach') == true">
                  <mnb-switch v-model="item.service.methods.ach" :disabled="!isNew && !editable" />
                </template>
                <template v-else>
                  <mnb-switch :disabled="true" :set="item.service.methods.ach = false"  />
                </template>
              </template>
              <template v-else>
                <mnb-switch :disabled="true" :set="item.service.methods.ach = false"  />
              </template>
            </div>
            <div class="input-group">
              <label>Account Transfer</label>
              <template v-if="providerChecked">
                <template v-if="checkSwitch('methods', 'accountTransfer') == true">
                  <mnb-switch v-model="item.service.methods.accountTransfer" :disabled="!isNew && !editable" />
                </template>
                <template v-else>
                  <mnb-switch :disabled="true" :set="item.service.methods.accountTransfer = false" />
                </template>
              </template>
              <template v-else>
                <mnb-switch :disabled="true" :set="item.service.methods.accountTransfer = false" />
              </template>
            </div>
            <div class="input-group">
              <label>Checks</label>
              <template v-if="providerChecked">
                <template v-if="checkSwitch('methods', 'checks') == true">
                  <mnb-switch v-model="item.service.methods.checks" :disabled="!isNew && !editable" />
                </template>
                <template v-else>
                  <mnb-switch :disabled="true" :set="item.service.methods.checks = false" />
                </template>
              </template>
              <template v-else>
                <mnb-switch :disabled="true" :set="item.service.methods.checks = false" />
              </template>
            </div>
            <div class="input-group">
              <label>Remote Deposit</label>
              <template v-if="providerChecked">
                <template v-if="checkSwitch('methods', 'remoteDeposit') == true">
                  <mnb-switch v-model="item.service.methods.remoteDeposit" :disabled="!isNew && !editable" />
                </template>
                <template v-else>
                  <mnb-switch :disabled="true" :set="item.service.methods.remoteDeposit = false"  />
                </template>
              </template>
              <template v-else>
                <mnb-switch :disabled="true" :set="item.service.methods.remoteDeposit = false" />
              </template>
            </div>
            <div class="input-group">
              <label>Domestic Wires</label>
              <template v-if="providerChecked">
                <template v-if="checkSwitch('methods', 'domesticWires') == true">
                  <mnb-switch v-model="item.service.methods.domesticWires" :disabled="!isNew && !editable" />
                </template>
                <template v-else>
                  <mnb-switch :disabled="true" :set="item.service.methods.domesticWires = false" />
                </template>
              </template>
              <template v-else>
                <mnb-switch :disabled="true" :set="item.service.methods.domesticWires = false" />
              </template>
            </div>
            <div class="input-group">
              <label>International Wires</label>
              <template v-if="providerChecked">
                <template v-if="checkSwitch('methods', 'internationalWires') == true">
                  <mnb-switch v-model="item.service.methods.internationalWires" :disabled="!isNew && !editable" />
                </template>
                <template v-else>
                  <mnb-switch :disabled="true" :set="item.service.methods.internationalWires = false"  />
                </template>
              </template>
              <template v-else>
                <mnb-switch :disabled="true" :set="item.service.methods.internationalWires = false"  />
              </template>
            </div>
          </td>
        </tr>
        <tr>
          <th>
            <label>Advanced Methods</label>
          </th>
        </tr>
        <tr>
          <td style="text-align: left" class="services auto-height">
            <div class="input-group">
              <label>Debit Cards</label>
              <template v-if="settings.modules.banking.cardIssuing">
                <template v-if="providerChecked">
                  <template v-if="checkSwitch('options', 'debitCards') == true">
                    <mnb-switch v-model="item.service.options.debitCards" :disabled="!isNew && !editable" />
                  </template>
                  <template v-else>
                    <mnb-switch :disabled="true"  :set="item.service.options.debitCards = false"  />
                  </template>
                </template>
                <template v-else>
                  <mnb-switch :disabled="true"  :set="item.service.options.debitCards = false" />
                </template>
              </template>
              <template v-else>
                <mnb-switch :disabled="true"  :set="item.service.options.debitCards = false" />
              </template>
            </div>
            <div class="input-group">
              <label>Digital Assets</label>
              <template v-if="settings.modules.banking.digitalAssets">
                <template v-if="providerChecked">
                  <template v-if="checkSwitch('advancedMethods', 'digitalAssets') == true">
                    <mnb-switch v-model="item.service.advancedMethods.digitalAssets" :disabled="!isNew && !editable" />
                  </template>
                  <template v-else>
                    <mnb-switch :disabled="true"  :set="item.service.advancedMethods.digitalAssets = false"  />
                  </template>
                </template>
                <template v-else>
                  <mnb-switch :disabled="true"  :set="item.service.advancedMethods.digitalAssets = false"  />
                </template>
              </template>
              <template v-else>
                <mnb-switch :disabled="true"  :set="item.service.advancedMethods.digitalAssets = false"  />
              </template>
            </div>
          </td>
        </tr>
        <tr>
          <th>
            <label>Modules</label>
          </th>
        </tr>
        <tr>
          <td style="text-align: left" class="services auto-height">
            <div class="input-group">
              <label>Exchange Desk</label>
              <template v-if="settings.modules.banking.exchangeDesk">
                <mnb-switch v-model="item.service.advancedMethods.exchangeDesk" :disabled="!isNew && !editable" />
              </template>
              <template v-else>
                <mnb-switch :set="item.service.advancedMethods.exchangeDesk = false" :disabled="true" />
              </template>
            </div>
            <div class="input-group">
              <label>Trade Desk</label>
              <template v-if="settings.modules.banking.tradeDesk">
                <template v-if="item.service.advancedMethods.digitalAssets">
                  <mnb-switch v-model="item.service.advancedMethods.tradeDesk" :disabled="!isNew && !editable" />
                </template>
                <template v-else>
                  <mnb-switch v-model="item.service.advancedMethods.tradeDesk" :disabled="true" />
                </template>
              </template>
              <template v-else>
                <mnb-switch v-model="item.service.advancedMethods.tradeDesk" :disabled="true" />
              </template>
            </div>
          </td>
        </tr>
        <tr>
          <th>
            <label>Options</label>
          </th>
        </tr>
        <tr>
          <td style="text-align: left" class="services auto-height">
            <div class="input-group">
              <label>Multi-currency</label>
              <template v-if="settings.modules.banking.multiCurrency">
                <template v-if="providerChecked">
                  <template v-if="checkSwitch('options', 'multiCurrency') == true">
                    <mnb-switch v-model="item.service.options.multiCurrency" :disabled="!isNew && !editable" />
                  </template>
                  <template v-else>
                    <mnb-switch :disabled="true" :value="false"  :set="item.service.options.multiCurrency = false"   />
                  </template>
                </template>
                <template v-else>
                  <mnb-switch :disabled="true" :value="false"  :set="item.service.options.multiCurrency = false"   />
                </template>
              </template>
              <template v-else>
                <mnb-switch :disabled="true" :value="false"  :set="item.service.options.multiCurrency = false"   />
              </template>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
</template>

<script>
import MnbSwitch from './switch.vue'
export default {
  components: {
      MnbSwitch,
    },
  props: ["item", "editable", "isNew"],
  data() { 
    return {
      providers: [],
      updateStatus: false,
      itemValue: {
        accounts: {
          kyc: false,
          aml: false,
        },

        methods: {
          ach: false,
          accountTransfer: false,
          checks: false,
          remoteDeposit: false,
          domesticWires: false,
          internationalWires: false,
        },

        advancedMethods: {
          digitalAssets: false,
        },

        options: {
          multiCurrency: false,
          debitCards: false,
        },
      },

      monthlyPlanForm: false,
      modulesForm: false,
      addMPlanName: "",
      loaded: false,
      settings: null,
    }
  },

  computed: {
    providerChecked() {
      if(this.providers.length > 0) {
        for(var prv of this.providers) {
            if(prv.switch == true) {
              return true;
            }
        }
      }
      return false
    },

    fullCountries() {
      return window.fullCountries
    },

    duplicateCheckerBusinessOptions () {
      return [
        "Business Name",
        "Business DBA",
        "Business Email",
        "Business Phone",
        "Business Bank Account",
      ]
    },

    duplicateCheckerContactsOptions () {
      return [
        "SSN/SIN",
        "Email",
        "Phone",
      ]
    }
  },

  watch: {
    item: {
      handler() {
        if (this.item && !this.item.enabledMethods) {
          this.$set(this.item, "enabledMethods", []);
        }
        if (this.item && !this.item.allMethods) {
          this.item.allMethods=[];
        }
        if (this.item && !this.item.allModules) {
          this.item.allModules=[];
        }
        if (this.item.allModules.join(",")!="Exchange Desk,Trade Desk") {
          this.item.allModules=["Exchange Desk", "Trade Desk"];
        }
        if (this.item.service?.advancedMethods?.digitalAssets == false) {
          this.item.service.advancedMethods.tradeDesk = false
        }
      },
      immediate: true,
      deep: true
    },

    "item.enabledMethods": function() {
      if (!this.item.enabledMethods || !this.item.enabledMethods.length)
        return;
      if (this.item.enabledMethods[this.item.enabledMethods.length-1]=="PostPay") {
        var index=this.item.enabledMethods?.findIndex(itm=>itm=='PrePay');
        if (index!=-1)
          this.item.enabledMethods.splice(index, 1);
      }
      if (this.item.enabledMethods[this.item.enabledMethods.length-1]=="PrePay") {
        var index=this.item.enabledMethods?.findIndex(itm=>itm=='PostPay');
        if (index!=-1)
          this.item.enabledMethods.splice(index, 1);
      }
    },

    providers: {
      handler() {
        if (!this.loaded)
          return;
        this.item.provider = []
        if(this.providers.length > 1) {
          for(var provider of this.providers) {
            if(provider.switch == true) {
              this.item.provider.push(provider.id)
            }
          }
        }else{
          this.item.services = {
            accounts: {
              kyc: false,
              aml: false,
            },

            methods: {
              ach: false,
              accountTransfer: false,
              checks: false,
              remoteDeposit: false,
              domesticWires: false,
              internationalWires: false,
            },

            advancedMethods: {
              digitalAssets: false,
            },

            options: {
              multiCurrency: false,
              debitCards: false,
            },
          }
        }
        
      },
      immediate: true,
      deep: true
    },
  },

  mounted() {
      
  },

  created() {
    this.loadData();
  },

  methods: {
    handleExclusiveProvider(provider, value) {
      if (value == false)
        return;
      for(var prov of this.providers)
      if (provider.id!=prov.id)
        prov.switch=false;
    },

    addMonthlyPlan() {
      if (this.addMPlanName)
        this.item.allMethods.push(this.addMPlanName);
      this.monthlyPlanForm=false;
      this.addMPlanName="";
    },
    addModule() {
      if (this.addMPlanName)
        this.item.allModules.push(this.addMPlanName);
      this.modulesForm=false;
      this.addMPlanName="";
    },

    loadData: async function() {
      var connexPromise=api.list("Provider", {providerType: 'Banking', status: ['!=', 'Archived']});
      var providers=(await connexPromise).items;
      if(this.item.provider == null) {
        this.item.provider = []
      }
      for(var provider of providers) {
        if(this.item.provider.length > 0) {
          for(var serviceProvider of this.item.provider) {
            if(provider.id == serviceProvider) {
              provider.switch = true
              break;
            }else{
              provider.switch = false
            }
          }
        }else{
          provider.switch = false
        }
        this.providers.push(provider);
      }
      if(this.item.service == null) {
        this.updateStatus = true
        this.item.service = {
          accounts: {
            kyc: false,
            aml: false,
          },

          methods: {
            ach: false,
            accountTransfer: false,
            checks: false,
            remoteDeposit: false,
            domesticWires: false,
            internationalWires: false,
          },

          advancedMethods: {
            digitalAssets: false,
            exchangeDesk: false,
            tradeDesk: false,
          },

          options: {
            multiCurrency: false,
            debitCards: false,
          },
        }
      }
      this.loaded=true;
      this.settings = (await api.get('Settings', 1)).item
    },

    checkSwitch(field1, field2) {
      if(this.providers.length > 0) {
        for(var prv of this.providers) {
          if(prv.switch == true) {
            if(prv.service.banking[field1][field2] == true) {
              if(this.updateStatus == true) {
                this.item.service[field1][field2] = true
              }
              return true;
            }
          }
        }
      }
      return false
    }
  },
}

</script>

<style scoped lang="scss">
  .mn-switch {
    display: block;
    position: relative;
    padding: 5px 0;
    width: 35px;
    .mn-slider {
      display: block;
      width: 100%;
      height: 8px;
      border-radius: 4px;
      background-color: #d9dce1;
      cursor: pointer;
      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 18px;
        height: 18px;
        border-radius: 9px;
        background-color: #777777;
        transition: all 0.3s ease;
        cursor: pointer;
      }
    }
    input:checked + .mn-slider::before {
      left: calc(100% - 18px);
      background-color: #13ce67;

    }
    input:disabled + .mn-slider {
      cursor: auto;
      opacity: .75;
      &::before {
        cursor: auto;
        opacity: .75;
      }
    }
  }
  .btn.btn-small {
    height: auto;
    line-height: 14px;
    font-size: 14px;
    text-transform: none;
    font-weight: normal;
    padding: 4px;
    min-width: 0;
    margin: 0 0 0 10px;
    width: 90px;
  }
  .content-table th form {
    margin: 0 0 0 10px;
    display: inline-flex;
    input {
      text-align: left;
      background: white;
      line-height: 14px;
      font-size: 14px;
      text-transform: none;
      margin: 0;
      padding: 4px;
    }
  }
  
  .countries {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
</style>

