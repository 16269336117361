<template>
  <div class="content-table-container file-list-container">
    <table class="file-list content-table">
      <thead>
        <tr>
          <th>Type</th>
          <th>Created</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="email in emails">
          <td>{{ email.emailType }}</td>
          <td>{{ formatDate(email.created, "YYYY-MM-DD HH:mm:ss") }}</td>
        </tr>
      </tbody>
    </table>

    <portal to="actions">
      <ul>
        <li v-if="this.agreements.length > 0 && this.item.agentDb.status == 'A03'">
          <a href="" v-on:click.prevent="!emailDisabled.Agreement && sendEmailUser('Agreement')">Partner Agreement</a>
        </li>
        <li v-if="this.item.agentDb.status == 'A05'">
          <a href="" v-on:click.prevent="!emailDisabled.Declined && sendEmailUser('Declined')">Partner Declined</a>
        </li>
        <li v-if="this.item.agentDb.status == 'A07'">
          <a href="" v-on:click.prevent="!emailDisabled.Welcome && sendEmail1('Welcome', true)">Partner Welcome</a>
        </li>
        <template v-if="item.agentDb.emailConfirmation === false">
          <template v-if="settings.settings.emailNotifications.emailConfirmation">
            <template v-if="item.agentDb.status == 'A02'">
              <li>
                <a href="" v-on:click.prevent="sendConfirmationEmail1()">Email Confirmation</a>
              </li>
            </template>
          </template>
        </template>
      </ul>
    </portal>
  </div>
</template>

<script>
export default {
  props: ["item", "emailDisabled"],
  data: ()=>({
    emails: [],
    agreements: [],
    settings: [],
  }),
  computed: {
  },
  methods: {
    listEmails: async function() {
      this.emails=(await api.list("PartnerEmail", {agentId: this.item.agentDb.id}, ["id:DESC"])).items || [];
    },
    async listAgreements() {
      this.agreements=(await api.list("PartnerAgreement", {agentId: this.item.id}, ["id:DESC"])).items || [];
    },
    sendEmailUser: async function(type, resetPw) {
      if (!confirm("Send "+type+" email?"))
        return;
      await this.sendEmail(type, resetPw);
    },
    sendEmail: async function(type, resetPw) {
      if (type=='Welcome' && !confirm("Are you sure?"))
        return;
      startLoading();
      if (resetPw)
        var res=await api.create("PartnerEmail", {agentId: this.item.agentDb.id, emailType: type, created: "CURRENT_TIMESTAMP", resetPassword: true});
      else
        var res=await api.create("PartnerEmail", {agentId: this.item.agentDb.id, emailType: type, created: "CURRENT_TIMESTAMP"});
      stopLoading("Email sent");
      var newItem=await api.get("PartnerEmail", res.id);
      if (newItem.item)
        this.emails.unshift(newItem.item);
    },

    sendConfirmationEmail1: async function(type) {
      startLoading();
      var result=await api.action("Partner", this.item.agentDb.id, "emailConfirmation");
      stopLoading("Email sent");
    },
  },

  async created() {
    this.listEmails();
    this.listAgreements();
    this.settings = (await api.get("Settings", 1)).item;
  },
  mixins: [vueMixins.dateFormatter],
}
</script>
