<template>
<form class="edit-system search-form">
  <div class="twocol">
    <div>
      <div class="field">
        <label>Solution Account Prefix:</label>
        <input v-model="item.solutionAccountPrefix"
               oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
               type = "number"
               maxlength = "4"
               class="pin-code"
        />
      </div>
      <div class="field">
        <label>Solution?:</label>
        <input v-model="item.nameSolution" />
      </div>
      <div class="field">
        <label>Solution Name:</label>
        <input v-model="item.name" />
      </div>
      <div class="field">
        <label>Solution DBA Name:</label>
        <input v-model="item.nameDba" />
      </div>
      <div class="field">
        <label>Solution Address:</label>
        <input v-model="item.address" />
      </div>
      <div class="field">
        <label>Solution City:</label>
        <input v-model="item.city" />
      </div>
      <div class="field">
        <label>Solution State:</label>
        <input v-model="item.state" />
      </div>
      <div class="field">
        <label>Solution Zip/Postal Code:</label>
        <input v-model="item.postcode" />
      </div>
<!--      <div class="field">
        <label>Fax:</label>
        <input v-model="item.fax" />
      </div>-->
      <div class="field">
        <label>Send-From Email:</label>
        <input v-model="item.sendFrom" />
      </div>
      <div class="field">
        <label>Logo:</label>
        <photo-obj :basepath="basePath" v-model="logoFile" />
      </div>
    </div>
    <div>
<!--      <div class="field">
        <label>Telephone:</label>
        <input v-model="item.telephone" />
      </div>-->
      <div class="field">
        <label>Solution Merchant Services Telephone?:</label>
        <input v-model="item.servicesTel" />
      </div>
      <div class="field">
        <label>Solution Support Telephone?:</label>
        <input v-model="item.supportTel" />
      </div>
      <div class="field">
        <label>Solution Email:</label>
        <input v-model="item.email" />
      </div>
      <div class="field">
        <label>Solution Application Email:</label>
        <input v-model="item.applicationEmail" />
      </div>
      <div class="field">
        <label>Solution Merchant Services Email:</label>
        <input v-model="item.servicesEmail" />
      </div>
<!--      <div class="field">
        <label>Business Services Email:</label>
        <input v-model="item.businessServicesEmail" />
      </div>-->
      <div class="field">
        <label>Solution Partners Email:</label>
        <input v-model="item.partnerServicesEmail" />
      </div>
      <div class="field">
        <label>Solution Support Email:</label>
        <input v-model="item.supportEmail" />
      </div>
      <div class="field">
        <label>Solution Website:</label>
        <input v-model="item.website" />
      </div>
      <div class="field">
        <label>Solution Slogan:</label>
        <input v-model="item.slogan" />
      </div>
    </div>
  </div>
  
  <edit-payment-opts ref="payOpts" :hide-submit="true" />
  
<!--   <sales-brands-edit ref="brandsEdit" :companyId="item && item.id"></sales-brands-edit> -->
  
  
  <h2>Agent signup form</h2>
  <div class="twocol">
    <div>
      <div class="field">
        <label>Header Assistance Text:</label>
        <input v-model="item.agentHeadText" />
      </div>
      <div class="field">
        <label>Header Assistance Email:</label>
        <input v-model="item.agentHeadEmail" />
      </div>
      <div class="field">
        <label>Header Assistance Phone:</label>
        <input v-model="item.agentHeadPhone" />
      </div>
    </div>
    <div>
      <div class="field">
        <label>Header Logo:</label>
        <photo-obj :basepath="basePath" v-model="agentHeadLogo" />
      </div>
    </div>
  </div>
  <div class="field">
    <label style="width: calc(16% - 10px)">Header Description:</label>
    <textarea v-model="item.agentHeadDesc" style="width: 84%"></textarea>
  </div>
  
  
  <h2>Merchant application form</h2>
  <div class="twocol">
    <div>
      <div class="field">
        <label>Header Assistance Text:</label>
        <input v-model="item.appHeadText" />
      </div>
      <div class="field">
        <label>Header Assistance Email:</label>
        <input v-model="item.appHeadEmail" />
      </div>
      <div class="field">
        <label>Header Assistance Phone:</label>
        <input v-model="item.appHeadPhone" />
      </div>
    </div>
    <div>
      <div class="field">
        <label>Header Logo:</label>
        <photo-obj :basepath="basePath" v-model="appHeadLogo" />
      </div>
    </div>
  </div>
  <div class="field">
    <label style="width: calc(16% - 10px)">Header Description:</label>
    <textarea v-model="item.appHeadDesc" style="width: 84%"></textarea>
  </div>
  
  
  <h2>Merchant prequalification form</h2>
  <div class="twocol">
    <div>
      <div class="field">
        <label>Header Assistance Text:</label>
        <input v-model="item.preqHeadText" />
      </div>
      <div class="field">
        <label>Header Assistance Email:</label>
        <input v-model="item.preqHeadEmail" />
      </div>
      <div class="field">
        <label>Header Assistance Phone:</label>
        <input v-model="item.preqHeadPhone" />
      </div>
    </div>
    <div>
      <div class="field">
        <label>Header Logo:</label>
        <photo-obj :basepath="basePath" v-model="preqHeadLogo" />
      </div>
    </div>
  </div>
  <div class="field">
    <label style="width: calc(16% - 10px)">Header Description:</label>
    <textarea v-model="item.preqHeadDesc" style="width: 84%"></textarea>
  </div>
  
  
  <h2>Merchant intake</h2>
  <div class="twocol">
    <div>
      <div class="field">
        <label>Header Assistance Text:</label>
        <input v-model="item.intakeHeadText" />
      </div>
      <div class="field">
        <label>Header Assistance Email:</label>
        <input v-model="item.intakeHeadEmail" />
      </div>
      <div class="field">
        <label>Header Assistance Phone:</label>
        <input v-model="item.intakeHeadPhone" />
      </div>
    </div>
    <div>
      <div class="field">
        <label>Header Logo:</label>
        <photo-obj :basepath="basePath" v-model="intakeHeadLogo" />
      </div>
    </div>
  </div>
  <div class="field">
    <label style="width: calc(16% - 10px)">Header Description:</label>
    <textarea v-model="item.intakeHeadDesc" style="width: 84%"></textarea>
  </div>
  
  <h2>Agent Setup</h2>
  <div class="field">
    <label>Agent setup form template:</label>
    <dyn-select v-model="item.setupTemplate" :options="setupTemplates" />
  </div>
  
  
  <div class="buttons">
    <button class="btn btn-large btn-primary" v-on:click.prevent="updateSettings">Save</button>
  </div>  
</form>
</template>

<script>
export default {
  data() { return {
    item: {},
    logoFile: null,
    agentHeadLogo: null,
    appHeadLogo: null,
    preqHeadLogo: null,
    intakeHeadLogo: null,
    basePath: "",
    itemId: 0,
    setupTemplates: [
      {value: "ca", label: "Cardbiller CA"},
    ],
  }},
  mounted() {
    this.refresh();
    window.companyEdit=this;
  },
  watch: {
    itemId() {
      this.refresh();
    },
  },
  methods: {
    refresh() {
      if (this.itemId) {
        var that=this;
        api.get("Company", this.itemId).then(function(data) {
          that.item=data.item;
          that.logoFile=data.item.file_name;
          that.basePath=data.desc.file_name.basepath;
          that.agentHeadLogo=data.item.agentHeadLogo;
          that.appHeadLogo=data.item.appHeadLogo;
          that.preqHeadLogo=data.item.preqHeadLogo;
          that.intakeHeadLogo=data.item.intakeHeadLogo;
        });
      } else {
        this.item={};
        this.logoFile=null;
      }
    },
    updateSettings: async function() {
      await this.$refs.payOpts.updateSettings(true);
      startLoading();
      var newItem=JSON.parse(JSON.stringify(this.item));
      delete newItem.file_name;
      if (this.logoFile===null)
        newItem.file_name=null;
      var itemId=this.itemId;
      if (itemId)
        await api.update("Company", this.itemId, newItem);
      else
        itemId=(await api.create("Company", newItem)).id;

      if (this.logoFile instanceof File) {
        if (!window.validateFileExt(this.logoFile))
          return stopLoading("Invalid file extension", "error");
        await api.upload("Company", itemId, this.logoFile);
      }

      if (this.agentHeadLogo instanceof File) {
        if (!window.validateFileExt(this.agentHeadLogo))
          return stopLoading("Invalid file extension", "error");
        await api.upload("Company", itemId, this.agentHeadLogo, {field: "agentHeadLogo"});
      }

      if (this.appHeadLogo instanceof File) {
        if (!window.validateFileExt(this.appHeadLogo))
          return stopLoading("Invalid file extension", "error");
        await api.upload("Company", itemId, this.appHeadLogo, {field: "appHeadLogo"});
      }

      if (this.preqHeadLogo instanceof File) {
        if (!window.validateFileExt(this.preqHeadLogo))
          return stopLoading("Invalid file extension", "error");
        await api.upload("Company", itemId, this.preqHeadLogo, {field: "preqHeadLogo"});
      }

      if (this.intakeHeadLogo instanceof File) {
        if (!window.validateFileExt(this.intakeHeadLogo))
          return stopLoading("Invalid file extension", "error");
        await api.upload("Company", itemId, this.intakeHeadLogo, {field: "intakeHeadLogo"});
      }
      

//       if (!this.$refs.brandsEdit.updateStatusCodes())
//         return;

      stopLoading("Company information updated");
      if (window.companySelect) {
        await window.companySelect.refresh();
        window.companySelect.selectCompany(itemId);
      }
      this.refresh();
    },
  },
}
</script>
