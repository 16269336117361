<template>
<section>
  <Portal to="actions">
  </Portal>
  <h3>Personal Info</h3>
  <ul class="appview-list">
    <li>
      <label>Address</label>
      <div class="validator">
        <validator v-if="editMode" :value="form.address" reqs="required">
          <input type="text" v-model="form.address">
        </validator>
        <span v-else>{{ employee.address }}</span>
      </div>
    </li>
    <li>
      <label>City</label>
      <div class="validator">
        <validator v-if="editMode" :value="form.city" reqs="required">
          <input type="text" v-model="form.city" @keypress="allowLetterNumber($event)">
        </validator>
        <span v-else>{{ employee.city }}</span>
      </div>
    </li>
    <li>
      <label>Province</label>
      <div class="validator">
        <validator v-if="editMode" :value="form.province" reqs="required">
          <input type="text" v-model="form.province" maxlength="2" @keypress="allowLetterNumber($event)">
        </validator>
        <span v-else>{{ employee.province }}</span>
      </div>
    </li>
    <li>
      <label>Postal</label>
      <div class="validator">
        <validator v-if="editMode" :value="form.postal" reqs="required">
          <input type="text" v-model="form.postal" maxlength="7" @keypress="allowLetterNumber($event, postalRule)">
        </validator>
        <span v-else>{{ employee.postal }}</span>
      </div>
    </li>
    <li>
      <label>Country</label>
      <div class="validator">
        <validator v-if="editMode" :value="form.country" reqs="required">
          <input type="text" v-model="form.country" @keypress="allowLetterNumber($event)">
        </validator>
        <span v-else>{{ employee.country }}</span>
      </div>
    </li>
    <li>
      <label>Email</label>
      <div class="validator">
        <validator v-if="editMode" :value="form.personalEmail" reqs="required">
          <input type="text" v-model="form.personalEmail">
        </validator>
        <span v-else>{{ employee.personalEmail }}</span>
      </div>
    </li>
    <li>
      <label>Phone</label>
      <div class="validator">
        <validator v-if="editMode" :value="form.personalPhone" reqs="required">
          <input type="text" v-model="form.personalPhone">
        </validator>
        <span v-else>{{ employee.personalPhone }}</span>
      </div>
    </li>
  </ul>
  <h3>Emergency Contact</h3>
  <ul class="appview-list">
    <li>
      <label>First Name</label>
      <div class="validator">
        <validator v-if="editMode" :value="form.ec_first_name" reqs="required">
          <input type="text" v-model="form.ec_first_name">
        </validator>
        <span v-else>{{ employee.ec_first_name }}</span>
      </div>
    </li>
    <li>
      <label>Last Name</label>
      <div class="validator">
        <validator v-if="editMode" :value="form.ec_last_name" reqs="required">
          <input type="text" v-model="form.ec_last_name">
        </validator>
        <span v-else>{{ employee.ec_last_name }}</span>
      </div>
    </li>
    <li>
      <label>Email</label>
      <div class="validator">
        <validator v-if="editMode" :value="form.ec_email" reqs="required email">
          <input type="email" v-model="form.ec_email">
        </validator>
        <span v-else>{{ employee.ec_email }}</span>
      </div>
    </li>
    <li>
      <label>Phone</label>
      <div class="validator">
        <validator v-if="editMode" :value="form.ec_phone" reqs="required">
          <input type="text" v-model="form.ec_phone">
        </validator>
        <span v-else>{{ employee.ec_phone }}</span>
      </div>
    </li>
  </ul>
  <portal to="actions">
    <ul>
      <li>
        <a href="" v-on:click.prevent="$emit('update:editMode', true)">Edit</a>
      </li>
    </ul>
  </portal>
</section>
</template>

<script>
export default {
  mixins: [ vueMixins.helpers ],

  props: {
    employee: {
      type: Object,
      required: true
    },

    value: {
      type: Object,
      required: true
    },

    editMode: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {}
  },

  computed: {
    form: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>
