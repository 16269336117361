export default {
  computed: {
    typeOptions() {
      var options = [];
      if(this.category == 'Business') {
        options.push(
          {
            id: 'Articles of Incorporation/Formation',
            text: 'Articles of Incorporation/Formation'  
          },
          {
            id: 'Certificate of Incorporation',
            text: 'Certificate of Incorporation'  
          },
          {
            id: 'Operating Agreement/Bylaws',
            text: 'Operating Agreement/Bylaws'  
          },
          {
            id: 'EIN Document',
            text: 'EIN Document'  
          },
          {
            id: 'DBA Document',
            text: 'DBA Document'  
          },
          {
            id: 'Certificate of Good Standing',
            text: 'Certificate of Good Standing'  
          },
          {
            id: 'Utility Bill',
            text: 'Utility Bill'  
          },
          {
            id: 'Bank Statement',
            text: 'Bank Statement'  
          },
          {
            id: 'Signed Agreement',
            text: 'Signed Agreement'  
          },
          {
            id: 'Other',
            text: 'Other'  
          },
        )
      }

      if(this.category == 'Contact') {
        options.push(
          {
            id: 'Passport',
            text: 'Passport'  
          },
          {
            id: 'Drivers Licence',
            text: 'Drivers Licence'  
          },
          {
            id: 'Citizenship Card',
            text: 'Citizenship Card'  
          },
          {
            id: 'Green Card',
            text: 'Green Card'  
          },
          {
            id: 'Other Government ID',
            text: 'Other Government ID'  
          },
          {
            id: 'Utility Bill',
            text: 'Utility Bill'  
          },
          {
            id: 'Bank Statement',
            text: 'Bank Statement'  
          },
          {
            id: 'Personal Agreement',
            text: 'Personal Agreement'  
          },
          {
            id: 'Signed Agreement',
            text: 'Signed Agreement'  
          },
          {
            id: 'Other',
            text: 'Other'  
          },
        )
      }

      return options
    }
  },
}
