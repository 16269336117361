<template>
<section>
  <ul class="appview-list">
    <li>
      <label>Setup Date</label>
      <div class="validator">
        <span>{{ formatDate(employee.created, 'MM/DD/YYYY') }}</span>
      </div>
    </li>
    <li>
      <label>Employee Solution</label>
      <div class="validator">
        <validator v-if="editMode" :value="form.companyId" reqs="required">
          <select v-model="form.companyId">
            <option :value="company.id" v-for="company in companies">{{ company.nameSolution }}</option>
          </select>
        </validator>
        <span v-else>{{ employee.companyId.nameSolution }}</span>
      </div>
    </li>
    <li>
      <label>Title</label>
      <div class="validator">
        <validator v-if="editMode" :value="form.title" reqs="required">
          <input type="text" v-model="form.title">
        </validator>
        <span v-else>{{ employee.title }}</span>
      </div>
    </li>
    <li>
      <label>Role</label>
      <div class="validator">
        <validator v-if="editMode" :value="form.role" reqs="required">
          <select v-model="form.role">
            <option v-for="group in titleGroups" :key="group" :value="group">{{ groups[group] }}</option>
          </select>
        </validator>
        <span v-else>{{ userGroup(employee.role) }}</span>
      </div>
    </li>
    <li>
      <label>Emp ID</label>
      <div class="validator">
        <span>{{ employee.employeeId }}</span>
      </div>
    </li>
    <li>
      <label>First Name</label>
      <div class="validator">
        <validator v-if="editMode" :value="form.first_name" reqs="required">
          <input type="text" v-model="form.first_name" @keypress="allowLetterNumber($event, { numbers: false })">
        </validator>
        <span v-else>{{ employee.first_name }}</span>
      </div>
    </li>
    <li>
      <label>Last Name</label>
      <div class="validator">
        <validator v-if="editMode" :value="form.last_name" reqs="required">
          <input type="text" v-model="form.last_name" @keypress="allowLetterNumber($event, { numbers: false })">
        </validator>
        <span v-else>{{ employee.last_name }}</span>
      </div>
    </li>
  </ul>
</section>
</template>

<script>
export default {
  mixins: [ vueMixins.helpers, vueMixins.dateFormatter ],

  props: {
    employee: {
      type: Object,
      required: true
    },

    value: {
      type: Object,
      required: true
    },

    editMode: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      companies: [],
      groups: window.userGroups,
      titleGroups: window.employeeRoles
    }
  },

  computed: {
    form: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    async loadCompanies() {
      this.companies=(await api.list("Solution", {solutionType: "Employees"})).items;
    },
  },

  created () {
    this.loadCompanies();
  }
}
</script>
