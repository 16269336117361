<template>
<div class="combo-select">
  <select :value="value" v-on:input="updateValue" tabindex="-1">
    <option v-for="opt in options" :value="opt">{{ opt }}</option>
  </select>
  <input :value="value" v-on:input="updateValue" />
</div>
</template>

<script>
export default {
  props: ["value", "options"],
  methods: {
    updateValue($e) {
      this.$emit('input', $e.target.value)
    },
  },
}
</script>
