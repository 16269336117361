<template>
<div class="ncontent specpad custom-width">
  <div class="tabs">
    <a class="btn" v-on:click.prevent="currentTab='reports'">Boarding Scan</a>
  </div>
<scan-list-acuant type="idm" v-if="currentTab=='reports'"></scan-list-acuant>
</div>
</template>

<script>
export default {
  data: ()=>({
    currentTab: null,
  }),
}
</script>
