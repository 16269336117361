<template>
<input type="checkbox" v-model="actualValue" v-on:change="updateVal">
</template>

<script>
export default {
  data() { return {
    actualValue: false,
  }},
  props: ["value", "yes", "no"],
  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        this.actualValue=newVal==this.yesVal;
      },
    },
  },
  computed: {
    yesVal() {
      return this.yes===undefined?'1':this.yes;
    },
    noVal() {
      return this.no===undefined?'0':this.no;
    },
  },
  methods: {
    updateVal() {
      this.$emit("input", this.actualValue?this.yesVal:this.noVal);
    },
  },
}
</script>
