<template>
    <form class="search-form">
      <h3>Search Options</h3>
      <div class="f2split">
        <div>
          <div class="field">
            <label>Merchant ID</label>
            <input type="text" v-model="search.merchantId" />
          </div>
          <div class="field">
            <label>Source</label>
            <dyn-select v-model="search.source" :options="sourcesOpt" />
          </div>
          <div class="field">
            <label>Status</label>
            <dyn-select v-model="search.status" :options="statusesOpt" />
          </div>
        </div>
        <div>
          <div class="field">
            <label>Account ID</label>
            <input type="text" v-model="search.accountId" />
          </div>
          <div class="field">
            <label>Company Name</label>
            <input type="text" v-model="search.companyName" />
          </div>
          <div class="field">
            <label>Email</label>
            <input type="text" v-model="search.email" />
          </div>
        </div>
      </div>

      <div class="buttons">
        <button class="btn btn-large btn-primary" v-on:click.prevent="updateResults">Search</button>
      </div>
    </form>
</template>

<script>
export default {
  props: ["followup", "statuses"],
  data() { return {
    search: {
      source: "",
      status: "",
      accountId: "",
      merchantId: "",
      companyName: "",
      email: "",
    },
    sources: [],
  }; },
  computed: {
    statusesOpt() {
      var result=[{value: "", label: "(all)"}];
      for(var i in this.statuses)
        result.push({
          value: this.statuses[i].status,
          label: this.statuses[i].status+" - "+this.statuses[i].description});
      return result;
    },
    sourcesOpt() {
      var result=[{value: "", label: "(all)"}];
      for(var i in this.sources)
        result.push({
          value: this.sources[i].id,
          label: this.sources[i].companyName ||
                 this.sources[i].fullName});
      return result;
    }
  },
  methods: {
    updateResults() {
      var q={}; // TODO
      var that=this;
      api.list("NegativeList", q, ["id:ASC"]).then(function(data) {
        accordeonResults.items=data.items.map(function(a) {
          a.type="Application";
          return a;
        });
        breakdownTable.items=data.items;
      });
    },
  },
  mounted() {
    this.sources=JSON.parse(JSON.stringify(appData.sources));
    this.updateResults();
  },
}
</script>
