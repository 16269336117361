<template>
  <div>
    <div class="action-sub-buttons">
      <a class="btn" v-if="uwSelected" :href="'/applications/'+this.item.id+'/intakepdf/'+uwSelected" target="_blank">View</a>
      <a class="btn" v-if="this.item.hasIntake && uwSelected && uwSelected==uwList[0].id" v-on:click.prevent="updateApp">Update</a>
    </div>
    <table class="file-list content-table">
      <thead>
        <tr>
          <th style="width: 1%"></th>
          <th>ID</th>
          <th>Legal Name</th>
          <th>Date</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(uwItem, uwI) in uwList">
          <td><input type="radio" v-model="uwSelected" :value="uwItem.id"></td>
          <td>{{ uwList.length-uwI }}</td>
          <td>{{ item.companyName }}</td>
          <td>{{ formatDate(uwItem.submitted, "YYYY-MM-DD HH:mm") }}</td>
        </tr>
      </tbody>
    </table>

    <portal to="actions">
      <ul>
        <li v-if="uwSelected">
          <a :href="'/applications/'+item.id+'/intakepdf/'+uwSelected" target="_blank">View</a>
        </li>
      </ul>
    </portal>
  </div>
</template>


<script>
export default {
  props: ["item"],
  data() { return {
    appIds: {},
    uwSelected: null,
    uwShown: null,    
    currencies: {
      "036": "AUD",
      "986": "BRL",
      "124": "CAD",
      "756": "CHF",
      "978": "EUR",
      "826": "GBP",
      "344": "HKD",
      "392": "JPY",
      "554": "NZD",
      "840": "USD",
    },
    shownItem: 0,
    accountTypes: [
      {value: "master", label: "Master Merchant"},
      {value: "merchant", label: "Merchant"},
      {value: "sub", label: "Sub-Account"},
    ],
    facilitators: appData.facilitators || [],
    solutions: appData.solutions || [],
    uwList: [],
  }},
  mounted() {
    this.listIntake();
  },
  methods: {
    listIntake: async function() {
      this.uwList=(await api.list("Intake", {appId: this.item.id}, ["id:DESC"])).items;
    },
    accountType(id) {
      for(var accountType of this.accountTypes)
        if (id==accountType.value)
          return accountType.label;
      return "";
    },
    facilitator(id) {
      for(var facilitator of this.facilitators)
        if (id*1==facilitator.value*1)
          return facilitator.label;
      return "";
    },
    solution(id) {
      for(var solution of this.solutions)
        if (id*1==solution.value*1)
          return solution.label;
      return "";
    },
    updateApp: async function() {
      startLoading();
      await api.update("Application", this.item.id, {hasIntake: null});
      this.$set(this.item, "hasIntake", null);
      stopLoading();
    },
  },
  computed: {
  },
  mixins: [vueMixins.dateFormatter],  
}
</script>
