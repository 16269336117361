<template>
<div>
<!--   <view-app-extcheck type="idm" :uwList="itemList" v-on:update="listIDM" ref="extcheck" /> -->

      <div>
        <div class="action-sub-buttons" v-if="false">
        </div>
        <table class="file-list content-table" v-if="itemList && itemList.length">
          <thead>
            <tr>
              <th>Entity</th>
              <th>Status</th>
              <th>Options</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="item.accountType == 'Personal'">
              <td>{{ item.personal?item.personal.firstName+" "+item.personal.lastName : item.business.companyName+" "+item.business.companyType }}</td>
              <td>
                {{ itemList[0].response && itemList[0].response.res || 'ERROR' }}
                <a href="" class="tb-btn" v-on:click.prevent="sendIDVPersonal(item.personal.id)">IDV</a>
              </td>
              <td><a href="" class="tb-btn" v-on:click.prevent="viewResult(itemList[0])">View</a></td>
            </tr>
            <tr v-if="item.accountType == 'Business'">
              <td>{{ item.personal?item.personal.firstName+" "+item.personal.lastName : item.business.companyName+" "+item.business.companyType }}</td>
              <td>{{ itemList[0].response && itemList[0].response.res || 'ERROR' }}</td>
              <td><a href="" class="tb-btn" v-on:click.prevent="viewResult(itemList[0])">View</a></td>
            </tr>
            <tr v-for="person in itemList[0].response.people">
              <td>{{ dbPerson(person.personId) }}</td>    
              <td>
                {{ person.res || 'ERROR' }}
                <a href="" class="tb-btn" v-on:click.prevent="sendIDVBusiness(person.personId)">IDV</a>
              </td>
              <td><a href="" class="tb-btn" v-on:click.prevent="viewResult(itemList[0])">View</a></td>
            </tr>
          </tbody>
        </table>
        <br />
        <table class="file-list content-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Date</th>
              <th>Type</th>
              <th>Filename</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(uwItem, uwI) in itemList">
              <td>{{ itemList.length-uwI }}</td>
              <td>{{ formatDate(uwItem.createdDate, "YYYY-MM-DD HH:mm") }}</td>
              <td>Acuant</td>
              <td v-if="uwItem.type=='g2'"><a :href="printUrlForItem(uwItem)" target="_blank">{{ docNameForItem(uwItem) }}</a></td>
              <td v-else></td>
              <td v-if="uwItem.type=='g2'">{{ uwItem.response.complete?'Completed':'Pending' }}</td>
              <td v-else>
                {{ item.personal?item.personal.firstName+" "+item.personal.lastName:item.business.companyName+" "+item.business.companyType }} |
                {{ uwItem.response && uwItem.response.res || 'ERROR' }}
                <template v-for="person in uwItem.response.people || []">
                  <br />
                  {{ dbPerson(person.personId) }} |
                  {{ person.res || 'ERROR' }}
                </template>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="popup shown api-log-pop" v-if="selectedResponse">
        <div><div class="pop-inner">
          <a class="close" href="" v-on:click.prevent="selectedResponse=null"></a>
          <div class="log-body">
            <div class="response-data">
              <label>Response:</label>
              <json-viewer :value="selectedResponse" />
            </div>
          </div>
        </div></div>
      </div>

  <portal to="actions" v-if="true">
    <ul>
      <li>
        <a href="" v-on:click.prevent="newCheck">Run New</a>
      </li>
      <li v-if="false && itemList && itemList.length">
        <a v-on:click.prevent="refresh(itemList[0])">Refresh</a>
      </li>
    </ul>
  </portal>
</div>
</template>
<script>
export default {
  props: ["item", "merchant"],
  data: ()=>({
    itemList: [],
    uwSelected: null,
    selectedResponse: null,
  }),
  computed: {
  },
  methods: {
    dbPerson(id) {
      var person=this.item.business.contacts.find(ubo=>ubo.id==id);
      if (person)
        return person.firstName+' '+person.lastName;
      return "";
    },
    listIDM: async function() {
      this.itemList=(await api.list("ExtCheck", {treasuryId: this.item.id, type: "idm"}, ["id:DESC"])).items || [];
    },
    refresh: async function() {
      startLoading();
      await api.update("ExtCheck", id, {type: "idm"});
      await this.listIDM();
      stopLoading();
    },
    newCheck: async function() {
      startLoading();
      if (this.preq)
        var id=(await api.create("ExtCheck", {
          type: 'idm',
          userId: appData.currentUser.id,
          createdDate: "CURRENT_TIMESTAMP",
          preqId: this.item.id,
        })).id;
      else
        var id=(await api.create("ExtCheck", {
          type: 'idm',
          userId: appData.currentUser.id,
          createdDate: "CURRENT_TIMESTAMP",
          treasuryId: this.item.id,
        })).id;

      var newItem={}

      await this.listIDM();
//       this.doShowItems(this.uwList[this.uwList.length-1].id);
      this.$emit('update');
      stopLoading();
    },
    printUrlForItem(item) {
      if (!item)
        return null;
      if (!item.response || !item.response.pdf_url)
        return null;
      return item.response.pdf_url;
    },
    docNameForItem(item) {
      var date=moment(item.createdDate);
      return this.item.accountId+" "+date.format("D M YYYY")+".pdf";
    },
    viewResult(item) {
      this.selectedResponse=item.response;
    },

    sendIDVBusiness: async function(personId) {
      startLoading();
      var result=await api.action("ExtCheck", this.itemList[0].id, "emailIDVTreasuryBusiness",  {
        contactId: personId
      });
      stopLoading("Email sent");
    },

    sendIDVPersonal: async function(personId) {
      startLoading();
      var result=await api.action("ExtCheck", this.itemList[0].id, "emailIDVTreasuryPersonal",  {
        personId: personId
      });
      stopLoading("Email sent");
    },
  },
  mounted() {
    this.listIDM();
  },
  mixins: [vueMixins.dateFormatter],
}
</script>
