<template>
<form class="form-step h3lg">
  <section>
    <h2>Contact Information</h2>
    <template v-for="xtype in typesWithContact">
      <h3 class="list-heading" :class="{emph: xtype.emph}" :key="'chead'+xtype"><em v-html="xtype.title"></em><label v-if="!xtype.emph"><input type="checkbox" v-on:change="checkType(xtype.contact, $event)">Same as General</label></h3>
      <ul class="column-form type2" :key="'clist'+xtype">
        <li>
          <label>First name</label>
          <div class="field">
            <validator :value="xtype.contact.firstName" reqs="required city">
              <input type="text" v-model="xtype.contact.firstName" />
            </validator>
          </div>
        </li>
        <li>
          <label>Last name</label>
          <div class="field">
            <validator :value="xtype.contact.lastName" reqs="required city">
              <input type="text" v-model="xtype.contact.lastName" />
            </validator>
          </div>
        </li>
        <li>
          <label>E-mail address</label>
          <div class="field">
            <validator :value="xtype.contact.email" reqs="required email">
              <input type="text" v-model="xtype.contact.email" />
            </validator>
          </div>
        </li>
        <li>
          <label>Telephone number <sup>1</sup></label>
          <div class="field">
            <validator :value="xtype.contact.phone" reqs="required phone">
              <input type="text" v-model="xtype.contact.phone" />
            </validator>
          </div>
        </li>
        <!--<li v-if="xtype.emph">
          <label>Fax number <sup>1</sup></label>
          <div class="field">
            <validator :value="xtype.contact.fax" reqs="phone">
              <input type="text" v-model="xtype.contact.fax" />
            </validator>
          </div>
        </li>-->
      </ul>
    </template>
    <ul class="footnotes">
      <li><sup>1</sup> Including international country code (e.g. +1 for USA, +852 for Hong Kong, etc.)</li>
    </ul>
  </section>
  <section>
    <h2 class="bline">Additional Comments</h2>
    <ul class="column-form type2">
      <li class="fwidth">
        <label>Please let us know if you have any further comments or information:</label>
        <div class="field">
          <validator :value="app.additionalComments" reqs="char1000">
            <textarea type="text" v-model="app.additionalComments"></textarea>
          </validator>
        </div>
      </li>
    </ul>
  </section>
  <div class="consent">
    <p>Please ensure that your application form and additional documentation is complete and correct. Application requirements can be found in our separate ‘Merchant Application Checklist’.</p>
    <p>By submitting this form you confirm that all information provided is accurate, complete and truthful and you consent to credit and information verification checks being performed.</p>
    <p>By completing this form you agree that your personal data may be shared with third parties for onboarding and Know-Your-Customer purposes.</p>
  </div>
  
  <div class="buttons">
    <a class="btn btn-large btn-plain btn-back" v-on:click.prevent="$emit('prev')">Back</a>
    <a class="btn btn-large btn-warn" v-on:click.prevent="$emit('save')">Save</a>
    <a class="btn btn-large btn-success btn-next" v-on:click.prevent="nextStep">Next Step</a>
  </div>  
</form>
</template>


<script>
export default {
  props: ["app"],
  data() { return {
    ctypes: [
      {type: "general", emph: true, title: "General Contact <span>(Primary)</span>"},
      {type: "technical", emph: false, title: "Technical Contact"},
      {type: "financial", emph: false, title: "Financial Contact"},
      {type: "risk", emph: false, title: "Risk / Chargeback Contact"},
    ],
  }},
  computed: {
    typesWithContact() {
      var that=this;
      return this.ctypes.map(function(a) {
        a.contact=that.findContact(a.type);
        return a;
      });
    },
  },
  methods: {
    findContact(type) {
      for(var i in this.app.contacts)
        if (this.app.contacts[i].type==type)
          return this.app.contacts[i];
      return {};
    },
    nextStep() {
      if (!this.validate())
        return stopLoading("Please fill all required fields", "error");
      this.$emit("next");      
    },
    checkType(xtype, event) {
      if (event.target.checked) {
        var fields=["firstName", "lastName", "email", "phone"];
        for(var i in fields)
          this.$set(xtype, fields[i], this.findContact("general")[fields[i]]);
      } else {
        var fields=["firstName", "lastName", "email", "phone"];
        for(var i in fields)
          this.$set(xtype, fields[i], "");
      }
    },
  },
  mixins: [formValidation.mixin]
}
</script>
