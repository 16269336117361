<template>
<select :value="value" v-on:input="$emit('input', $event.target.value)">
  <option v-for="opt in options" :value="opt.value" :selected="opt.value==value" :disabled="opt.disabled">{{ opt.label }}</option>
</select>
</template>

<script>
export default {
  props: ["value", "options"],
}
</script>
