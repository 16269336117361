<template>
    <form class="search-form">
      <h3>Filter</h3>
      <div class="f2split">
        <div>
          <div class="field">
            <label>Created </label>
            <div class="field-group">
              <datepicker type="text" v-model="search.dateStart" />
              <datepicker type="text" v-model="search.dateEnd" />
            </div>
          </div>
          <div class="field">
            <label>Type</label>
            <dyn-select v-model="search.type" :options="types" />
          </div>
          <div class="field">
            <label>Status</label>
            <dyn-select v-model="search.status" :options="statusesOpt" />
          </div>
        </div>
        <div>
          <div class="field">
            <label>Profile</label>
            <input type="number" v-model="search.profile" />
          </div>
          <div class="field">
            <label>Name</label>
            <input type="text" v-model="search.name" />
          </div>
        </div>
      </div>
      <div class="f2split">
        <div class="buttons">
          <button class="btn btn-small btn-primary" v-on:click.prevent="updateResults">Search</button>
          <button class="btn btn-small btn-default" v-on:click.prevent="resetResults">Reset</button>
        </div>
        <div>
          &nbsp;
        </div>
      </div>
    </form>
</template>

<script>
export default {
  props: ["saved", "isPartners", "partnerId"],
  data() { return {
    search: {
      dateStart: moment().subtract(7, "day").format("YYYY-MM-DD"),
      dateEnd: moment().format("YYYY-MM-DD"),
      type: "",
      status: "",
      name: "",
      profile: "",
    },
    sources: [], // TODO
    types: [
      {value: null, label: ""},
      {value: "Personal", label: "Personal"}, 
      {value: "Business", label: "Business"}, 
      {value: "Omnibus", label: "Omnibus"},
    ],
    statuses: [],
  }; },
  computed: {
    statusesOpt() {
      var result=[{value: "", label: ""}];
      for(var i in this.statuses)
        result.push({
          value: this.statuses[i].status,
          label: this.statuses[i].status+" - "+this.statuses[i].description});
      return result;
    },
    sourcesOpt() {
      var result=[{value: "", label: ""}];
      for(var i in this.sources)
        result.push({
          value: this.sources[i].id,
          label: this.sources[i].agentId+" - "+(this.sources[i].agentDb?this.sources[i].agentDb.name:(this.sources[i].fullName+" "+this.sources[i].lastName))});
      return result;
    }
  },
  methods: {
    async updateResults() {
      var qB={};
      var qP1={};
      var qP2={};

      if(this.isPartners == 1) {
        qB["partner.agentDb.id"] = this.partnerId;
        qP1["partner.agentDb.id"] = this.partnerId
        qP2["partner.agentDb.id"] = this.partnerId
      }

      if (this.search.dateStart) {
        qB.dateStart=[">=", this.search.dateStart, "createdAt"];
        qP1.dateStart=[">=", this.search.dateStart, "createdAt"];
        qP2.dateStart=[">=", this.search.dateStart, "createdAt"];
      }
        
      if (this.search.dateEnd) {
        qB.dateEnd=["<=", this.search.dateEnd+' 23:59:59', "createdAt"];
        qP1.dateEnd=["<=", this.search.dateEnd+' 23:59:59', "createdAt"];
        qP2.dateEnd=["<=", this.search.dateEnd+' 23:59:59', "createdAt"];
      }
        
      if (this.search.profile) {
        qB["business.profileNumber"]=["LIKE", "%"+this.search.profile+"%"];
        qP1["personal.profileNumber"]=["LIKE", "%"+this.search.profile+"%"];
        qP2["personal.profileNumber"]=["LIKE", "%"+this.search.profile+"%"];
      }
        
      if (this.search.type) {
        qB["accountType"]=this.search.type;
        qP1["accountType"]=this.search.type;
        qP2["accountType"]=this.search.type;
      }
      if (this.search.name) {
        qB["business.companyName"]=["LIKE", "%"+this.search.name+"%"];
        qP1["personal.firstName"]=["LIKE", "%"+this.search.name+"%"];
        qP2["personal.lastName"]=["LIKE", "%"+this.search.name+"%"];
      }
      if (this.search.status) {
        qB['business.status']=this.search.status;
        qP1['personal.status']=this.search.status;
        qP2['personal.status']=this.search.status;
      }
        

      var results = Array.prototype.concat(
        ...(await Promise.all([
          api.list("TreasuryAccount", qB),
          api.list("TreasuryAccount", qP1),
          api.list("TreasuryAccount", qP2),
      ])).map(a=>a.items))

      breakdownTable.items = accordeonResults.items = [...new Map(results.map((m) => [m.id, m])).values()];

      // api.list("TreasuryAccount", q, ["id:ASC"]).then(function(data) {
      //   accordeonResults.items=data.items.map(function(a) {
      //     return a;
      //   });
      //   breakdownTable.items=data.items;
      // });
    },

    async resetResults() {
      this.search = {
        dateStart: "",
        dateEnd: "",
        type: "",
        status: "",
        profile: "",
        name: "",
      };
      await this.updateResults();
    },
  },
  async created() {
    this.statuses = (await api.list('Status', {category: 'Profiles / Accounts'}, ["status:ASC"])).items;
    this.updateResults();
  },
}
</script>
