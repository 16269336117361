<template>
<view-profile :header="['Employee Detail']">
  <template v-slot:tabsmain>
    <ul>
      <li v-for="(tabData, tabId) in tabs" :class="{active: tab==tabId}">
        <a href="" v-on:click.prevent="tab=tabId">{{ tabData.label }}</a>
      </li>
    </ul>
    <div class="status" v-if="employee">
      <label>Employee ID: <strong>{{ employee.employeeId }}</strong></label>
      <label>Name: <strong>{{ employee.first_name }} {{ employee.last_name }}</strong></label>
      <label>Status: <strong>{{ employee.status }}</strong></label>
    </div>
  </template>
  <template v-slot:tabssub v-if="tab=='general'">
    <ul>
      <li v-for="(tabData, tabId) in profileTabs1" :class="{active: subTab==tabId}">
        <a href="" v-on:click.prevent="subTab=tabId">{{ tabData.label }}</a>
      </li>
    </ul>
  </template>
  <template v-slot:tabssub v-else-if="tab=='employee'">
    <ul>
      <li v-for="(tabData, tabId) in profileTabs2" :class="{active: subTab==tabId}">
        <a href="" v-on:click.prevent="subTab=tabId">{{ tabData.label }}</a>
      </li>
    </ul>
  </template>
  <template v-slot:tabssub v-else-if="tab=='notes'">
    <ul>
      <li v-for="(tabData, tabId) in profileTabs3" :class="{active: subTab==tabId}">
        <a href="" v-on:click.prevent="subTab=tabId">{{ tabData.label }}</a>
      </li>
    </ul>
  </template>
  <template v-slot:tabssub v-else-if="tab=='access'">
    <ul>
      <li v-for="(tabData, tabId) in profileTabs4" :class="{active: subTab==tabId}">
        <a href="" v-on:click.prevent="subTab=tabId">{{ tabData.label }}</a>
      </li>
    </ul>
  </template>
  <template v-slot:tabssub v-else-if="tab=='documents'">
    <ul>
      <li v-for="(tabData, tabId) in []" :class="{active: subTab==tabId}">
        <a href="" v-on:click.prevent="subTab=tabId">{{ tabData.label }}</a>
      </li>
    </ul>
  </template>
  <template v-slot:tabssub v-else-if="tab=='general'">
    <ul>
      <li v-for="(tabData, tabId) in generalTabs" :class="{active: subTab==tabId}">
        <a href="" v-on:click.prevent="subTab=tabId">{{ tabData.label }}</a>
      </li>
    </ul>
  </template>
  <template v-slot:actions>
    <PortalTarget name="actions"></PortalTarget>
  </template>
  
  <div class="merchant-panel">
  <div class="data-content" v-if="(tab=='general' || tab=='employee' || tab=='notes' || tab=='access') && employee">
  
  
      <div class="main">

        <employee-data-activity
          v-show="subTab === 'activity'"
          :employee="employee" />
        
        <form class="preq-form preq-view">
          <notes v-if="subTab === 'notes'" class="appl-notes" :notes="notes" type="Employee" :id="id" ref="notes" />

          <employee-data-general2
            v-if="subTab === 'general'"
            :employee="employee"
            :value="form"
            :editMode.sync="editMode" />

          <employee-data-general
            v-if="subTab === 'general-old'"
            :employee="employee"
            :value="form"
            :editMode.sync="editMode" />

          <employee-data-profile
            v-if="subTab === 'profile'"
            :employee="employee"
            :value="form"
            :editMode.sync="editMode" />

          <employee-data-personal
            v-if="subTab === 'personal'"
            :employee="employee"
            :value="form"
            :editMode.sync="editMode" />

          <employee-data-access
            v-if="subTab === 'access'"
            :employee="employee"
            :value="form"
            :editMode.sync="editMode"
            @active="employee.userId.active = $event" ref="access" />
            
        </form>

        <div class="form-buttons" v-if="editMode">
          <button type="button" class="btn btn-small" @click="edit()">Save</button>
          <button type="button" class="btn btn-small" @click="editMode = false">Cancel</button>
        </div>
      </div>
  
  </div>
  </div>
  
</view-profile>
</template>

<script>
export default {
  props: ["id"],
  data: ()=>({
    tab: "general",
    subTab: "general",
    tabs: {
      general: {label: "General", def: "general"},
      employee: {label: "Employee", def: "profile"},
      notes: {label: "Notes", def: "notes"},
      access: {label: "Access", def: "access"},
      documents: {label: "Documents", def: "general"},
    },
    profileTabs1: {
      general: {label: 'General', edit: true},
    },
    profileTabs2: {
      profile: {label: 'Profile', edit: true},
      personal: {label: 'Personal', edit: true},
    },
    profileTabs3: {
      notes: {label: 'Notes'},
    },
    profileTabs4: {
      access: {label: 'User', edit: true},
      activity: {label: 'Activity'},
    },
    generalTabs: {
      general: {label: "General"},
    },
    editMode: false,
    employee: null,
    notes: [],
    form: {},
  }),
  watch: {
    tab() {
      this.subTab=this.tabs[this.tab].def;
    },
  },
  methods: {

    async loadNotes () {
      const filter = {
        type: 'Employee',
        parentId: this.id
      }
      this.notes = (await api.list("Note", filter, ['id:ASC'])).items || [];
    },
    async loadEmployee () {
      const response = await api.get('Employee', this.id)
      this.employee = response.item
      this.clearForm()
    },

    clearForm () {
      const values = ['first_name', 'last_name', 'title', 'role', 'address', 'city', 'province', 'postal', 'country', 'ec_first_name', 'ec_last_name', 'ec_email', 'ec_phone', 'portal', 'phone', 'phone_ext']
      values.forEach(key => {
        this.$set(this.form, key, this.employee[key])
      })
      this.$set(this.form, 'companyId', this.employee?.companyId?.id)
      this.$set(this.form, 'salesBrandId', this.employee?.salesBrandId?.id)
    },
  },
  mounted() {
    this.loadNotes();
    this.loadEmployee();
  },
}
</script>
