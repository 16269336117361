<template>
<div :class="{'form-popup': !inline}" v-show="inline || shown"><div>
  <div class="ncontent appf appnat">
    <a v-if="!inline" class="close" href="" v-on:click.prevent="dismiss">&times;</a>
    <h3 v-if="!inline">CardBiller Merchant Underwriting Checklist</h3>

<form class="form-step">
  <section>
    <div class="matrix-table-wrapper matrix-table-ext">
      <table class="bordered small-padding">
        <tbody>
          <tr class="header text-left"><td colspan="4">MERCHANT UNDERWRITING CHECKLIST – CARDBILLER-CA v1.0</td></tr>
          <tr>
            <td>Start Date:</td>
            <td>&nbsp;</td>
            <td>End Date:</td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>Analyst:</td>
            <td>&nbsp;</td>
            <td>Approved By:</td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>Account ID:</td>
            <td><input type="text" readonly :value="appl.accountId" /></td>
            <td>Account Class:</td>
            <td><dyn-select :options="classesToShow" v-model="appl.classId"></dyn-select></td>
          </tr>
          <tr>
            <td>Payment Methods Requested:</td>
            <td>{{ (appl.cardTypes || []).join(", ") }}</td>
            <td>Partner:</td>
            <td><dyn-select :options="agentsToShow" v-model="appl.sourceId"></dyn-select></td>
          </tr>
          <tr class="header text-left"><td colspan="4">GENERAL APPLICATION INFORMATION</td></tr>
          <tr>
            <td>New Business:</td>
            <td class="pen-checkbox">
              <formel-toggle v-model="appl.companyNew" />
            </td>
            <td>Parent Company:</td>
            <td class="pen-checkbox">
              <formel-toggle v-model="appl.hasParent" />
            </td>
          </tr>
          <tr>
            <td>Company Name:</td>
            <td><input type="text" v-model="appl.companyName" /></td>
            <td>Contact Name:</td>
            <td><input type="text" v-model="mainContact.firstName" /><input type="text" v-model="mainContact.lastName" /></td>
          </tr>
          <tr>
            <td>Company Email:</td>
            <td><input type="text" v-model="appl.companyEmail" /></td>
            <td>Company Website:</td>
            <td><input type="text" v-model="appl.website" /></td>
          </tr>
          <tr>
            <td>Company Country:</td>
            <td><input type="text" v-model="appl.companyAddressCountry" /></td>
            <td>Solution:</td>
            <td><dyn-select :options="solutions" v-model="appl.solution" disabled></dyn-select></td>
          </tr>
          <tr>
            <td>Merchant Type:</td>
            <td class="pen-checkbox">
              <dyn-select :options="merchantTypes" v-model="appl.merchantType" />
            </td>
            <td>Account Type:</td>
            <td><dyn-select :options="accountTypes" v-model="appl.accountType"></dyn-select></td>
          </tr>
          <tr>
            <td>Monthly Vol Requested</td>
            <td><input type="text" v-model="appl.volume" /></td>
            <td>x 12 = Yearly Estimate</td>
            <td><input type="text" readonly :value="(appl.volume*12).toFixed(2)" /></td>
          </tr>
          <tr>
            <td>Annual Vol Requested</td>
            <td><input type="text" readonly :value="(appl.volume*12).toFixed(2)" /></td>
            <td>/ 12 = Monthly Estimate</td>
            <td><input type="text" readonly :value="(appl.volume*1).toFixed(2)" /></td>
          </tr>
          <tr>
            <td>Business Type Identified</td>
            <td class="pen-checkbox">
              <formel-toggle v-model="appl.underwriting.businesstype" />
            </td>
            <td>Business Description</td>
            <td><dyn-select v-model="appl.industry" :options="industries" /></td>
          </tr>
          <tr>
            <td>Site Survey Completed</td>
            <td class="pen-checkbox">
              <formel-toggle v-model="appl.underwriting.sitesurvey" />
            </td>
            <td>Site Survey By</td>
            <td><input type="text" v-model="appl.underwriting.survey" /></td>
          </tr>
          <tr>
            <td>Credit Report Score</td>
            <td><input type="text" v-model="appl.underwriting.creditscore" /></td>
            <td>Proposed MCC</td>
            <td><mcc-edit v-model="appl.mcc" /></td>
          </tr>
          <tr>
            <td>Licensed Business</td>
            <td class="pen-checkbox">
              <formel-toggle v-model="appl.underwriting.licensed" />
            </td>
            <td>Have Copy of License(s)</td>
            <td>
              <formel-toggle v-model="appl.underwriting.licenses" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>    
    
    
  </section>
  <div class="buttons">
    <a class="btn btn-large btn-success" v-on:click.prevent="save">Set</a>
  </div>
</form>
  </div>
  
  
</div></div>
</template>

<script>
export default {
  props: ["inline"],
  data() { return {
    portals: [{value: appData.settings.partnerLoginURL, label: appData.settings.partnerLoginURL}],
    shown: false,
    appl: {underwriting: {}},
    toggleYesNo: [
      {label: "Yes", value: 1},
      {label: "No", value: 0},
    ],
    solutions: [],
    solution: null,
    loadedTemplate: false,
    saveAsTemplate: false,
    loaded: null,
    template_name: null,
    form: {},
    columns: ['Class Note', 'LR-AA', 'LR-CP', 'LR-CP', 'MR-MIX', 'HR-CNP', 'HR-CNP', 'HRS-CP', 'HRS-CNP'],
    columns2: ['Class', '00', '10', '20', '30', '40', '50', '98', '99'],
    templates: [],
    classes: [],
    agents: appData.agents || [],
    managers: [],
    referrers: [],
    accountTypes: [
      {value: "master", label: "Payfac"},
      {value: "merchant", label: "Merchant"},
      {value: "sub", label: "Sub-Account"},
    ],
    industries: [
      {value: "Hospitality", label: "Hospitality"},
      {value: "Retail", label: "Retail"},
      {value: "Spa/Salon/Gym", label: "Spa/Salon/Gym"},
      {value: "Gas & Oil", label: "Gas & Oil"},
      {value: "E-Commerce", label: "E-Commerce"},
      {value: "Professional Services", label: "Professional Services"},
      {value: "Convenience & Supermarket", label: "Convenience & Supermarket"},
      {value: "Tourism", label: "Tourism"},
      {value: "Community Groups & Orgs", label: "Community Groups & Orgs"},
      {value: "Business to Business", label: "Business to Business"},
      {value: "Taxi & Delivery", label: "Taxi & Delivery"},
      {value: "Utilities", label: "Utilities"},
      {value: "Other", label: "Other"},
    ],
    merchantTypes: [
      {value: "POS", label: "Card Present - POS"},
      {value: "E-Commerce", label: "Card not Present - E-Commerce"},
//       {value: "Hybrid", label: "Hybrid"},
    ],
  }},
  async mounted() {
    window.appUnderwritingPopup=this;
    if (this.inline)
      this.show(this.inline);
    this.classes = (await api.list("AccountClass")).items || [];
    this.managers = (await api.list("User", {groups: 'manager-account', deleted :['<>', 'Yes']}, ['id:ASC'])).items || [];
    this.referrers = (await api.list("User", {groups: 'merchant'}, ['id:ASC'])).items || [];
    var appsolutions = (await api.list("Solution", {solutionType: 'Merchants'}, ['id:ASC'])).items || [];
    this.solutions=appsolutions.map(function(a) {
      return {value: a.id, label: a.nameSolution};
    });
  },
  computed: {
    paymentMethods() {
      var methods=[];
      if (this.appl.methodECommerce) methods.push("E-Commerce");
      if (this.appl.methodPOS) methods.push("POS");
      if (this.appl.methodMPOS) methods.push("M-POS");
      if (this.appl.methodMCommerce) methods.push("M-Commerce");
      if (this.appl.methodMOTO) methods.push("MOTO");
      if (this.appl.methodApp) methods.push("In-App");
      return methods.join(", ");
    },
    sourcesToShow() {
      return [];
    },
    classesToShow() {
      return this.classes.map(function(a) {
        return {
          value: a.id,
          label: a.name+" "+a.description,
        };
      });
    },
    agentsToShow() {
      return this.agents.map(function(a) {
        var name=a.fullName;
        if (!name && a.contacts && a.contacts[0]) {
          name=a.contacts[0].firstName+" "+a.contacts[0].lastName;
        }
        var cn=a.companyName;
        if (!cn) {
          
        }
        return {
          value: a.id,
          label: (a.agentId || "")+" - "+name+" - "+cn,
        };
      });
    },
    managersToShow() {
      var items=this.managers.map(function(a) {
        return {
          value: a.id,
          label: a.username,
        };
      });
      items.unshift({
        value: null,
        label: "(no manager)",
      });
      return items;
    },
    referrersToShow() {
      var items=this.referrers.map(function(a) {
        return {
          value: a.id,
          label: a.username,
        };
      });
      items.unshift({
        value: null,
        label: "(no referrer)",
      });
      return items;
    },
    mainContact() {
      for(var i in this.appl.contacts)
        if (this.appl.contacts[i].type=='general')
          return this.appl.contacts[i];
      return {};
    },
  },
  watch: {
  },
  methods: {
    formatName (prefix, text, index) {
      text = prefix.substring(0, 15) + ' ' +  text.substring(0, 35)
      text = text.replace(/\s+/g, '_').toLowerCase()
      text = text.replace(/[()',.\/%/]/g, '').toLowerCase()
      text += `.${index}`
      return text
    },
    dismiss() {
      this.shown=false;
      this.appl={underwriting: {}};
    },
    show(item) {
      if (item) {
        this.shown=true;
        this.appl=item;
        this.appl.underwriting=this.appl.underwriting || {
          licensed: "1",
          licenses: "1",
          sitesurvey: "1",
          businesstype: "1",
        };
      }
    },
    async save() {
      if (!this.validate())
        return stopLoading("Please fill all required fields", "error");      
      startLoading();
      this.$set(this.appl.underwriting, "created", this.dateNow("YYYY-MM-DD HH:mm:ss"));
      var item=(await api.update("Application", this.appl.id, {
        underwriting: this.appl.underwriting,
        classId: this.appl.classId,
        sourceId: this.appl.sourceId,
        companyNew: this.appl.companyNew,
        hasParent: this.appl.hasParent,
        companyName: this.appl.companyName,
        companyEmail: this.appl.companyEmail,
        companyWebsite: this.appl.companyWebsite,
        companyAddressCountry: this.appl.companyAddressCountry,
        solution: this.appl.solution,
        accountType: this.appl.accountType,
        volume: this.appl.volume,
        mcc: this.appl.mcc
      }, true)).item;
      stopLoading("Merchant updated");
      if (!this.inline)
        this.dismiss();
    },
  },
  mixins: [formValidation.mixin, vueMixins.dateFormatter]
}
</script>

<style lang="scss">
.form-popup .matrix-table-wrapper table tr td {
  height: auto;
}
</style>
