import { render, staticRenderFns } from "./formview-app-main.vue?vue&type=template&id=4b449e76&scoped=true&"
import script from "./formview-app-main.vue?vue&type=script&lang=js&"
export * from "./formview-app-main.vue?vue&type=script&lang=js&"
import style0 from "./formview-app-main.vue?vue&type=style&index=0&id=4b449e76&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b449e76",
  null
  
)

export default component.exports