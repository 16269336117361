<template>
<div>
<!--   <employees-menu /> -->
  
  <div class="ncontent nc-flat custom-width">
    <div class="ncontent touch-it">
      <div class="merchant-panel">
        <div class="data-content">
          <ul class="tabs">
            <li v-for="(tab, idx) in tabs" :class="{ active: activeTab == idx }">{{ tab }}</li>
          </ul>
        </div>
      </div>

      <div v-if="activeTab === 0" class="custom-form pad20">
        <div class="input-group">
          <label>First Name</label>
          <validator :value="form.first_name" reqs="required">
            <input type="text" v-model="form.first_name" @keypress="allowLetterNumber($event, { numbers: false })">
          </validator>
        </div>

        <div class="input-group">
          <label>Last Name</label>
          <validator :value="form.last_name" reqs="required">
            <input type="text" v-model="form.last_name" @keypress="allowLetterNumber($event, { numbers: false })">
          </validator>
        </div>
        <div class="input-group">
          <label>Email</label>
          <validator :value="form.email" reqs="required email">
            <input type="text" v-model="form.email">
          </validator>
        </div>
        <div class="input-group">
          <label>Phone</label>
          <validator :value="form.phone" reqs="required">
            <input type="text" v-model="form.phone" class="">
          </validator>
        </div>
        <div class="input-group">
          <label>Username</label>
          <validator :value="form.username" reqs="required">
            <input type="text" v-model="form.username" @keypress="allowLetterNumber($event, { numbers: true })">
          </validator>
        </div>
        <div class="input-group">
          &nbsp;
        </div>
      </div>
      <div v-if="activeTab === 0" class="custom-form pad20">
      </div>
      <div v-if="activeTab === 1" class="custom-form pad20">
        <h3 class="subtitle">Personal Information</h3>
        <div class="input-group large">
          <label>Address</label>
          <validator :value="form.address" reqs="required">
            <input type="text" v-model="form.address">
          </validator>
        </div>
        <div class="input-group medium">
          <label>City</label>
          <validator :value="form.city" reqs="required">
            <input type="text" v-model="form.city" @keypress="allowLetterNumber($event)">
          </validator>
        </div>
        <div class="input-group small">
          <label>Province</label>
          <validator :value="form.province" reqs="required">
            <input type="text" v-model="form.province" maxlength="2" @keypress="allowLetterNumber($event)">
          </validator>
        </div>
        <div class="input-group small">
          <label>Postal</label>
          <validator :value="form.postal" reqs="required">
            <input type="text" v-model="form.postal" maxlength="7" @keypress="allowLetterNumber($event, postalRule)">
          </validator>
        </div>
        <div class="input-group medium">
          <label>Country</label>
          <validator :value="form.country" reqs="required">
            <input type="text" v-model="form.country" @keypress="allowLetterNumber($event)">
          </validator>
        </div>

        <h3 class="subtitle">Emergency Contact</h3>

        <div class="input-group w25">
          <label>First Name</label>
          <validator :value="form.ec_first_name" reqs="required">
            <input type="text" v-model="form.ec_first_name">
          </validator>
        </div>
        <div class="input-group w25">
          <label>Last Name</label>
          <validator :value="form.ec_last_name" reqs="required">
            <input type="text" v-model="form.ec_last_name">
          </validator>
        </div>
        <div class="input-group w25">
          <label>Email</label>
          <validator :value="form.ec_email" reqs="required email">
            <input type="email" v-model="form.ec_email">
          </validator>
        </div>
        <div class="input-group w25">
          <label>Phone</label>
          <validator :value="form.ec_phone" reqs="required">
            <input type="text" v-model="form.ec_phone">
          </validator>
        </div>
      </div>

      <div v-if="activeTab === 2" class="custom-form pad20 inline-form">
      </div>

      <div v-if="activeTab === 3" class="pad20">
        <notes
          ref="notes"
          class="appl-notes"
          :notes="[]"
          type="User"
          :has-filter="false"
          await />
      </div>
      <div class="buttons clear-after">
        <button v-if="activeTab > 0" class="btn btn-large btn-primary btn-back" @click="prevNext(false)">Previous Step</button>
        <button v-if="activeTab < 3" class="btn btn-large btn-primary floatr no-margin" @click="prevNext()">Next Step</button>
        <button v-if="activeTab === 3" class="btn btn-large btn-primary floatr no-margin" @click="save()">FINALIZE</button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  mixins: [ vueMixins.helpers, formValidation.mixin ],

  data () {
    return {
      activeTab: 0,
      tabs: ['Profile', 'Personal', 'Schedule A', 'Notes'],
      salesBrand: [],
      companies: null,
      groups: window.userGroups,
      titleGroups: window.employeeRoles,
      form: {},
      uniqueEmail: null,
      postalRule: {
        numbers: true,
        allow: [45]
      }
    }
  },

  computed: {
    portalOptions () {
      return window.employeePortals
    },
  },

  created () {
    this.initForm()
    api.list("SalesBrand").then((data) => {
      this.salesBrand = data.items
    })
    api.get("Solution", 1).then((data) => {
      this.companies = data.item
    })
  },

  methods: {
    async save () {
      if (!this.validate()) return stopLoading('Please fill all required fields', 'error')
      startLoading()
//       try {
        const userId = (await this.createUser()).id
        
        await api.action("User", userId, "WelcomeEmailPassword")
        await this.addNote('Welcome email sent', userId)

        
        if (this.$refs.notes.newNote) this.$refs.notes.createNote()

        await this.$refs.notes.bulkCreate(userId)
        
        this.initForm()
        this.validateReset()
        stopLoading("Saved")
        setTimeout(()=>location.href="/your-profile/"+appData.currentUser.username, 3000);
//       } catch (e) {
//         stopLoading(e, 'error')
//       }
    },

    async createUser () {
      let payload = {
        active: "Yes",
        parent: appData.currentUser.id,
        agentId: appData.currentUser.agentId,
        generateAgentSubId: true,
        username: this.form.username,
        fullName: this.form.first_name,
        lastName: this.form.last_name,
        email: this.form.email,
        title: this.form.title,
        groups: this.form.role,
        phone: this.form.phone,
        phoneExt: this.form.phone_ext,
        passChange: true,
        skipPasswordEmail: true,
        configuration: {subuser: this.form},
      }
      var user = (await api.create("User", payload) );

      payload = {
        partnerID: user.id,
        firstName: this.form.first_name,
        lastName: this.form.last_name,
        email: this.form.email,
        phone: this.form.phone,
        address: this.form.address,
        address2: this.form.address2,
        city: this.form.city,
        province: this.form.province,
        postal: this.form.postal,
        country: this.form.country,
        emergencyContact: {
          firstName: this.form.ec_first_name,
          lastName: this.form.ec_last_name,
          email: this.form.ec_email,
          phone: this.form.ec_phone,
        },
      }

      await api.action("SubAgent", "new", "Add", payload)
      return user
    },

    addNote (message, userId) {
      return api.create("Note", {
        message,
        parentId: userId,
        type: 'User',
        created: "CURRENT_TIMESTAMP",
        author: appData.currentUser.id,
        system: 1
      })
    },

    formatName (name) {
      return name.replace(/\s/g, '.').toLowerCase()
    },

    async prevNext (next = true) {
      startLoading()
      if (this.activeTab === 0 && next && this.autogeneratedEmail) {
        const response = await api.list('Employee', { email: this.autogeneratedEmail })
        this.uniqueEmail = response.items.length ? false : this.autogeneratedEmail
      }
      this.$nextTick(() => {
        if (next && !!this.validate()) {
          this.validateReset()
          this.activeTab++
        }
        if (!next) this.activeTab--
        if (!this.uniqueEmail && this.autogeneratedEmail) stopLoading('This email already exists.', 'error')
        else stopLoading()
      })
    },

    initForm () {
      this.activeTab = 0
      this.$set(this, 'form', {
        username: null,
        first_name: null,
        email_first_name: null,
        last_name: null,
        title: null,
        companyId: 1,
        salesBrandId: null,
        email: null,
        phone: null,
        phone_ext: null,
        role: null,
        address: null,
        city: null,
        province: null,
        postal: null,
        country: null,
        ec_first_name: null,
        ec_last_name: null,
        ec_phone: null,
        ec_email: null,
        portal: null
      })
    }
  },
}
</script>
