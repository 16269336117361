<template>
  <div>
    <div class="new-btn">
      <button class="btn btn-small btn-primary mb-20" v-on:click.prevent="newItem = true">New</button>
    </div>
    <br/>
    <template v-if="newItem">
      <div class="provider-form pad20">
        <div class="input-group">
          <label>Type</label>
          <validator :value="form.providerType" reqs="required">
            <dyn-select v-model="form.providerType" :options="types" />
          </validator>
        </div>
        <div class="input-group">
          <label>Name</label>
          <validator :value="form.providerName" reqs="required">
            <input v-model="form.providerName" />
          </validator>
        </div>
        <div class="input-group">
          <label>Label</label>
          <validator :value="form.label" reqs="required">
            <input v-model="form.label" />
          </validator>
        </div>
        <div class="input-group">
          <label>Version</label>
          <validator :value="form.version" reqs="required">
            <input v-model="form.version" />
          </validator>
        </div>
        <div class="input-group">
          <label>Environment</label>
          <validator :value="form.environment" reqs="required">
            <dyn-select v-model="form.environment" :options="environments" />
          </validator>
        </div>
        <div class="input-group">
          <label>Service Level</label>
          <validator :value="form.serviceLevel" reqs="required">
            <dyn-select v-model="form.serviceLevel" :options="serviceLevels" />
          </validator>
        </div>
        <div class="input-group">
          <label>Username</label>
          <input v-model="form.username" />
        </div>
        <div class="input-group">
          <label>Password</label>
          <input v-model="form.providerPassword" />
        </div>
        <div class="input-group">
          <label>Profile</label>
          <input v-model="form.profile" />
        </div>
        <div class="input-group">
          <label>Key</label>
          <input v-model="form.providerKey" />
        </div>
        <div class="input-group">
          <label>Key Expiry</label>
          <datepicker v-model="form.providerKeyExpiry" />
        </div>
        <div class="input-group">
          <label>ORG ID</label>
          <input v-model="form.orgId" />
        </div>
        <div class="input-group">
          <label>Vault ID</label>
          <input v-model="form.vaultId" />
        </div>
        <div class="input-group">
          <label>URL</label>
          <validator :value="form.url" reqs="required">
            <input v-model="form.url" />
          </validator>
        </div>
        <div class="input-group">
          <label>Client ID</label>
          <input v-model="form.clientId" />
        </div>
        <div class="input-group">
          <label>Client Secret</label>
          <input v-model="form.clientSecret" />
        </div>
        <div class="input-group">
          <label>Certification</label>
          <datepicker v-model="form.certifiedDate" />
        </div>
        <div class="input-group">
          <label>Notes</label>
          <input v-model="form.notes" />
        </div>
        <div class="input-group">
          <label>Transaction Countries</label>
          <multiselect-dropdown :options="fullCountries.map(a=>({label: a.text, value: a.id}))" v-model="form.transactionCountries" :isMulti="true" />
        </div>
      </div>
      <h4 class="services-text">Provider Services</h4>
      <br/>
      <div v-if="form.providerType == 'Payments'">
        <h5 class="services-subtitle">Billing</h5>
        <div class="services">
          <div class="input-group">
            <label>Daily</label>
            <mnb-switch v-model="form.service.payments.billing.daily" />
          </div>
          <div class="input-group">
            <label>Monthly</label>        
            <mnb-switch v-model="form.service.payments.billing.monthly" />
          </div>
          <div class="input-group">
            <label>Flat Rate</label>
            <mnb-switch v-model="form.service.payments.billing.flatRate" />
          </div>
          <div class="input-group">
            <label>Interchange Plus</label>
            <mnb-switch v-model="form.service.payments.billing.interchangePlus" />
          </div>
          <div class="input-group">
            <label>Tiered</label>
            <mnb-switch v-model="form.service.payments.billing.tiered" />
          </div>
        </div>

        <h5 class="services-subtitle">Methods</h5>
        <div class="services">
          <div class="input-group">
            <label>Visa</label>
            <mnb-switch v-model="form.service.payments.methods.visa" />
          </div>
          <div class="input-group">
            <label>MasterCard</label>
            <mnb-switch v-model="form.service.payments.methods.masterCard" />
          </div>
          <div class="input-group">
            <label>Discover</label>
            <mnb-switch v-model="form.service.payments.methods.discover" />
          </div>
          <div class="input-group">
            <label>AMEX</label>
            <mnb-switch v-model="form.service.payments.methods.amex" />
          </div>
          <div class="input-group">
            <label>JCB</label>
            <mnb-switch v-model="form.service.payments.methods.jcb" />
          </div>
          <div class="input-group">
            <label>Diners</label>
            <mnb-switch v-model="form.service.payments.methods.diners" />
          </div>
          <div class="input-group">
            <label>Debit</label>
            <mnb-switch v-model="form.service.payments.methods.debit" />
          </div>
          <div class="input-group">
            <label>Interac</label>
            <mnb-switch v-model="form.service.payments.methods.interac" />
          </div>
        </div>

        <h5 class="services-subtitle">Advanced Methods</h5>
        <div class="services">
          <div class="input-group">
            <label>Apple Pay</label>
            <mnb-switch v-model="form.service.payments.advancedMethods.applePay" />
          </div>
          <div class="input-group">
            <label>Google Pay</label>
            <mnb-switch v-model="form.service.payments.advancedMethods.googlePay" />
          </div>
          <div class="input-group">
            <label>Samsung Pay</label>
            <mnb-switch v-model="form.service.payments.advancedMethods.samsungPay" />
          </div>
          <div class="input-group">
            <label>Interac Online</label>
            <mnb-switch v-model="form.service.payments.advancedMethods.interacOnline" />
          </div>
          <div class="input-group">
            <label>Interac Transfer</label>
            <mnb-switch v-model="form.service.payments.advancedMethods.interacTransfer" />
          </div>
        </div>

        <h5 class="services-subtitle">Bank</h5>
        <div class="services">
          <div class="input-group">
            <label>EFT</label>
            <mnb-switch v-model="form.service.payments.bank.eft" />
          </div>
          <div class="input-group">
            <label>EFT Credits</label>
            <mnb-switch v-model="form.service.payments.bank.eftCredits" />
          </div>
          <div class="input-group">
            <label>ACH</label>
            <mnb-switch v-model="form.service.payments.bank.ach" />
          </div>
          <div class="input-group">
            <label>ACH Credits</label>
            <mnb-switch v-model="form.service.payments.bank.achCredits" />
          </div>
        </div>

        <h5 class="services-subtitle">Options</h5>
        <div class="services">
          <div class="input-group">
            <label>Multi-Currency</label>
            <mnb-switch v-model="form.service.payments.options.multiCurrency" />
          </div>
          <div class="input-group">
            <label>Subscription</label>
            <mnb-switch v-model="form.service.payments.options.subscription" />
          </div>
          <div class="input-group">
            <label>Account Updater</label>
            <mnb-switch v-model="form.service.payments.options.accountUpdater" />
          </div>
          <div class="input-group">
            <label>Tokenization</label>
            <mnb-switch v-model="form.service.payments.options.tokenization" />
          </div>
          <div class="input-group">
            <label>Level 2</label>
            <mnb-switch v-model="form.service.payments.options.level2" />
          </div>
          <div class="input-group">
            <label>Level 3</label>
            <mnb-switch v-model="form.service.payments.options.level3" />
          </div>
        </div>
      </div>

      <div v-if="form.providerType == 'Treasury'">
        <h5 class="services-subtitle">Accounts</h5>
        <div class="services">
          <div class="input-group">
            <label>KYC</label>
            <mnb-switch v-model="form.service.banking.accounts.kyc" />
          </div>
          <div class="input-group">
            <label>AML</label>        
            <mnb-switch v-model="form.service.banking.accounts.aml" />
          </div>
        </div>

        <h5 class="services-subtitle">Methods</h5>
        <div class="services">
          <div class="input-group">
            <label>ACH</label>
            <mnb-switch v-model="form.service.banking.methods.ach" />
          </div>
          <div class="input-group">
            <label>Account Transfer</label>
            <mnb-switch v-model="form.service.banking.methods.accountTransfer" />
          </div>
          <div class="input-group">
            <label>Checks</label>
            <mnb-switch v-model="form.service.banking.methods.checks" />
          </div>
          <div class="input-group">
            <label>Remote Deposit</label>
            <mnb-switch v-model="form.service.banking.methods.remoteDeposit" />
          </div>
          <div class="input-group">
            <label>Domestic Wires</label>
            <mnb-switch v-model="form.service.banking.methods.domesticWires" />
          </div>
          <div class="input-group">
            <label>International Wires</label>
            <mnb-switch v-model="form.service.banking.methods.internationalWires" />
          </div>
        </div>

        <h5 class="services-subtitle">Advanced Methods</h5>
        <div class="services">
          <div class="input-group">
            <label>Debit Cards</label>
            <mnb-switch v-model="form.service.banking.options.debitCards" />
          </div>
          <div class="input-group">
            <label>Digital Assets</label>
            <mnb-switch v-model="form.service.banking.advancedMethods.digitalAssets" />
          </div>
        </div>

        <h5 class="services-subtitle">Options</h5>
        <div class="services">
          <div class="input-group">
            <label>Multi-Currency</label>
            <mnb-switch v-model="form.service.banking.options.multiCurrency" />
          </div>
        </div>
      </div>

      <div v-if="form.providerType == 'Compliance'">
        <h5 class="services-subtitle">Accounts</h5>
        <div class="services">
          <div class="input-group">
            <label>KYC</label>
            <mnb-switch v-model="form.service.compliance.accounts.kyc" />
          </div>
          <div class="input-group">
            <label>AML</label>        
            <mnb-switch v-model="form.service.compliance.accounts.aml" />
          </div>
          <div class="input-group">
            <label>Fraud</label>        
            <mnb-switch v-model="form.service.compliance.accounts.fraud" />
          </div>
          <div class="input-group">
            <label>Monitoring</label>        
            <mnb-switch v-model="form.service.compliance.accounts.monitoring" />
          </div>
          <div class="input-group">
            <label>Chargebacks</label>        
            <mnb-switch v-model="form.service.compliance.accounts.chargebacks" />
          </div>
        </div>
      </div>
      <button class="btn btn-small btn-primary mb-20" v-on:click.prevent="saveNewItem()">Add</button>
      <button class="btn btn-small btn-danger mb-20" v-on:click.prevent="resetItem()">Reset</button>
    </template>
    
    <h4 class="services-text mb-20">Providers</h4>
    <ul class="acc-results">
      <li class="">
        <div class="acc-list" style="display: block">
          <table>
            <thead>
              <tr>
                <th style="text-align: left">Date</th>
                <th style="text-align: left">Type</th>
                <th style="text-align: left">Name</th>
                <th style="text-align: left">Label</th>
                <th style="text-align: left">Version</th>
                <th style="text-align: left">URL</th>
                <th style="text-align: left">Environment</th>
                <th style="text-align: left">Service Level</th>
                <th style="text-align: left">Certification</th>
                <th style="text-align: left">Status</th>
                <th style="text-align: left">Notes</th>
                <th>Options</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in providers">
                <td style="text-align: left">
                  <span v-if="item.dateAdded">{{ item.dateAdded.substr(0, 10) }}</span>
                </td>
                <td style="text-align: left">
                  <span>{{ item.providerType }}</span>
                </td>
                <td style="text-align: left">
                  <a href="" v-on:click.prevent="showItem(item)">{{ item.providerName }}</a>
                </td>
                <td style="text-align: left">
                  <span v-if="!item.editing">{{ item.label }}</span>
                  <input v-else v-model="item.label" type="text" />
                </td>
                <td style="text-align: left">
                  <span>{{ item.version }}</span>
                </td>
                <td style="text-align: left">
                  <span>{{ item.url }}</span>
                </td>
                <td style="text-align: left">
                  <span>{{ item.environment }}</span>
                </td>
                <td style="text-align: left">
                  <span>{{ item.serviceLevel }}</span>
                </td>
                <td style="text-align: left">
                  <template v-if="item.certifiedDate">
                    <span>{{ item.certifiedDate.substr(0, 10) }}</span>
                  </template>
                </td>
                <td style="text-align: left">
                  <span v-if="!item.editing">{{ item.status }}</span>
                  <dyn-select v-else v-model="item.status" :options="statuses" />
                </td>
                <td style="text-align: left">
                  <span>{{ item.notes }}</span>
                </td>
                <td>
                  <a href="" v-if="!item.editing && item.status=='Disabled'" v-on:click.prevent="doDelete(item)">Delete</a>
                  <a href="" v-if="!item.editing" v-on:click.prevent="item.editing=true">Edit</a>
                  <a href="" v-else v-on:click.prevent="saveItem(item)">Save</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </li>
    </ul>
  </div>
  </template>
  
  <script>
    import MnbSwitch from './switch.vue'

  export default {
    components: {
      MnbSwitch,
    },
    
    data() { return {
      statuses: [
        {value: "Review", label: "Review"},
        {value: "Testing", label: "Testing"},
        {value: "Active", label: "Active"},
        {value: "Disabled", label: "Disabled"},
      ],
  
      types: [
        {value: "", label: ""},
        {value: "Payments", label: "Payments"},
        {value: "Treasury", label: "Treasury"},
        {value: "Compliance", label: " Compliance"},
      ],
  
      environments: [
        {value: "Live", label: "Live"},
        {value: "Sandbox", label: "Sandbox"},
      ],
  
      serviceLevels: [
        {value: "Full", label: "Full"},
        {value: "Partial", label: "Partial"},
      ],
  
      form : {
        providerType: null,
        providerName: null,
        version: null,
        environment: null,
        serviceLevel: null,
        username: null,
        providerPassword: null,
        profile: null,
        providerKey: null,
        status: 'Review',
        notes: null,
        certifiedDate: null,
        providerKeyExpiry: null,
        orgId: null,
        vaultId: null,
        url: null,
        clientId: null,
        clientSecret: null,
        updatedAt: 'CURRENT_TIMESTAMP',
        dateAdded: 'CURRENT_TIMESTAMP',
        service: {
          payments: {
            billing: {
              daily: false,
              monthly: false,
              flatRate: false,
              interchangePlus: false,
              tiered: false,
            },

            methods: {
              visa: false,
              masterCard: false,
              discover: false,
              amex: false,
              jcb: false,
              diners: false,
              debit: false,
              interac: false,
            },

            advancedMethods: {
              applePay: false,
              googlePay: false,
              samsungPay: false,
              interacOnline: false,
              interacTransfer: false,
            },

            bank: {
              eft: false,
              eftCredits: false,
              ach: false,
              achCredits: false,
            },

            options: {
              multiCurrency: false,
              subscription: false,
              accountUpdater: false,
              tokenization: false,
              level2: false,
              level3: false,
            },
          },

          banking: {
            accounts: {
              kyc: false,
              aml: false,
            },

            methods: {
              ach: false,
              accountTransfer: false,
              checks: false,
              remoteDeposit: false,
              domesticWires: false,
              internationalWires: false,
            },

            advancedMethods: {
              digitalAssets: false,
            },

            options: {
              multiCurrency: false,
              debitCards: false,
            },
          },

          compliance: {
            accounts: {
              kyc: false,
              aml: false,
              fraud: false,
              monitoring: false,
              chargebacks: false,
            },
          },
        },
        transactionCountries: null,
      },
      
      newItem: false,
      providers: [],
    }},

    computed: {
      fullCountries() {
        return window.fullCountries
      }
    },

    mounted() {
      this.loadData();
    },

    methods: {
      doDelete: async function(item) {
        item.status = 'Archived'
        await api.update("Provider", item.id, item);
        this.providers = []
        this.loadData();
      },

      loadData: async function() {
        var connexPromise=api.list("Provider", {status: ['!=', 'Archived']});
        var providers=(await connexPromise).items;
        for(var provider of providers) {
            provider.editing = false
            this.providers.push(provider);
        }
      },
  
      newProvider() {
        this.providers.push({
          id: null,
          providerType: "",
          providerName: "",
          label: "",
          version: "",
          username: "",
          providerPassword: "",
          providerKey: "",
          status: "",
          notes: "",
          certifiedDate: null,
          providerKeyExpiry: null,
          updatedAt: 'CURRENT_TIMESTAMP',
          editing: true,
        });
      },
      saveItem: async function(item) {
        if (item.id)
          await api.update("Provider", item.id, item);
        else
          await api.create("Provider", item)
  //      item.editing=false;
        this.providers = []
        this.loadData()
      },

      saveNewItem: async function() {
        if (!this.validate())
          return stopLoading("Please fill all required fields", "error");
        await api.create("Provider", this.form)
        this.providers = []
        this.form = {
          providerType: null,
          providerName: null,
          label: null,
          version: null,
          environment: null,
          serviceLevel: null,
          username: null,
          providerPassword: null,
          profile: null,
          providerKey: null,
          status: 'Review',
          notes: null,
          certifiedDate: null,
          providerKeyExpiry: null,
          orgId: null,
          vaultId: null,
          url: null,
          clientId: null,
          clientSecret: null,
          updatedAt: 'CURRENT_TIMESTAMP',
          dateAdded: 'CURRENT_TIMESTAMP',
          service: {
            payments: {
              billing: {
                daily: false,
                monthly: false,
                flatRate: false,
                interchangePlus: false,
                tiered: false,
              },

              methods: {
                visa: false,
                masterCard: false,
                discover: false,
                amex: false,
                jcb: false,
                diners: false,
                debit: false,
                interac: false,
              },

              advancedMethods: {
                applePay: false,
                googlePay: false,
                samsungPay: false,
                interacOnline: false,
                interacTransfer: false,
              },

              bank: {
                eft: false,
                eftCredits: false,
                ach: false,
                achCredits: false,
              },

              options: {
                multiCurrency: false,
                subscription: false,
                accountUpdater: false,
                tokenization: false,
                level2: false,
                level3: false,
              },
            },

            banking: {
              accounts: {
                kyc: false,
                aml: false,
              },

              methods: {
                ach: false,
                accountTransfer: false,
                checks: false,
                remoteDeposit: false,
                domesticWires: false,
                internationalWires: false,
              },

              advancedMethods: {
                digitalAssets: false,
              },

              options: {
                multiCurrency: false,
                debitCards: false,
              },
            },

            compliance: {
              accounts: {
                kyc: false,
                aml: false,
                fraud: false,
                monitoring: false,
                chargebacks: false,
              },
            },
          },
          transactionCountries: null,
        }
        this.loadData()
        return stopLoading("Provider Added.", "success");
      },
      
      resetItem: async function() {
        this.form = {
          providerType: null,
          providerName: null,
          label: null,
          version: null,
          environment: null,
          serviceLevel: null,
          username: null,
          providerPassword: null,
          profile: null,
          providerKey: null,
          status: 'Review',
          notes: null,
          certifiedDate: null,
          providerKeyExpiry: null,
          orgId: null,
          vaultId: null,
          url: null,
          clientId: null,
          clientSecret: null,
          updatedAt: 'CURRENT_TIMESTAMP',
          dateAdded: 'CURRENT_TIMESTAMP',
          service: {
            payments: {
              billing: {
                daily: false,
                monthly: false,
                flatRate: false,
                interchangePlus: false,
                tiered: false,
              },

              methods: {
                visa: false,
                masterCard: false,
                discover: false,
                amex: false,
                jcb: false,
                diners: false,
                debit: false,
                interac: false,
              },

              advancedMethods: {
                applePay: false,
                googlePay: false,
                samsungPay: false,
                interacOnline: false,
                interacTransfer: false,
              },

              bank: {
                eft: false,
                eftCredits: false,
                ach: false,
                achCredits: false,
              },

              options: {
                multiCurrency: false,
                subscription: false,
                accountUpdater: false,
                tokenization: false,
                level2: false,
                level3: false,
              },
            },

            banking: {
              accounts: {
                kyc: false,
                aml: false,
              },

              methods: {
                ach: false,
                accountTransfer: false,
                checks: false,
                remoteDeposit: false,
                domesticWires: false,
                internationalWires: false,
              },

              advancedMethods: {
                digitalAssets: false,
              },

              options: {
                multiCurrency: false,
                debitCards: false,
              },
            },

            compliance: {
              accounts: {
                kyc: false,
                aml: false,
                fraud: false,
                monitoring: false,
                chargebacks: false,
              },
            },
          },
          transactionCountries: null,
        }
      },

      showItem(item) {
        providerEdit.showPopup(item);
      },
    },
    mixins: [vueMixins.dateFormatter, formValidation.mixin],
  }
  </script>
  
