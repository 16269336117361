<template>
  <div class="static-page privacy-popup">
    <h2><strong>{{ legal.termsAndConditionsHeader }}</strong></h2>
    <p>
      Last updated: {{ legal.termsAndConditionsDate }}
    </p>
    <div v-html="legal.termsAndConditionsText"></div>
  </div>
</template>

<script>

export default {
  data: ()=>({
      legal: {},
    }),

    methods: {
      async loadItems() {
        var results=(await api.list("Disclaimers", {type: "general"})).items;
        this.legal = results[0]
      },
    },
    
    async created() {
      this.loadItems();
    },
}
</script>
<style lang="scss" scoped>
  .privacy-popup {
    line-height: 22px;
    color: #21364c;
    text-align: left;
  }

  .privacy-popup h2{
    border-bottom: none;
  }

  .privacy-popup p {
    margin-bottom: 18px;
    line-height: 22px;
    color: #21364c;
  }

  .privacy-popup a {
    color: #00f;
    text-decoration: underline;
  }

  .privacy-popup strong {
    font-weight: 700;
  }
</style>
