<template>
<div>
  <panel>
    <template v-slot:actions>
      <ch-button color="gray" :disabled="!selected" @click="viewEvent()">View Event</ch-button>
      <ch-button color="gray" :disabled="!selected || eventNotCreatedByMe" @click="editEvent()">Edit Event</ch-button>
      <ch-button color="gray" :disabled="!selected || eventNotCreatedByMe" @click="deleteEvent()">Delete Event</ch-button>
      <ch-button @click="addEvent()">Add new Event</ch-button>
    </template>

    <template v-slot:title>
      <calendar-date-select
        :day="day"
        :month.sync="month"
        :year.sync="year"
        @day="day = $event" />
    </template>
    <div class="event-list">
      <h1>Events</h1>
      <template v-if="events">
        <ul v-if="events.length" class="list">
          <li
            v-for="event in events"
            :key="event.id"
            class="list-item"
            :class="{ selected: selected === event }"
            @click="toggleSelect(event)">
            <div
              class="circle"
              :class="event.color ? `event-${event.color.toLowerCase()}` : 'event-blue'" />
            <b>{{ formatEventTime(event) }} <a v-if="event.employees.length" class="link"> - created by {{ event.createdBy.username }}</a></b>: {{ event.subject }}
            <span v-if="event.note"><br>{{ event.note }}</span>
            <template v-if="event.employees.length">
              <br><ul class="tags small">
                <li v-for="item in event.employees">{{ item.employee_id.first_name }} {{ item.employee_id.last_name }}
                  <span v-if="event.createdBy.id === userId || item.employee_id.userId.id === userId" class="close" @click="removeEmployee(event, item)">x</span>
                </li>
              </ul>
            </template>
          </li>
        </ul>
        <span v-else>There are no events for selected date.</span>
      </template>
      <div v-else>
        Loading...
      </div>
    </div>
  </panel>

  <manage-event-modal
    ref="eventModal"
    v-model="showEvent"
    :type="type"
    :preselected-date="add ? new Date(selectedDate) : null"
    :event="add ? null : selected"
    :edit="editingEvent"
    :viewOnly="eventNotCreatedByMe"
    @setEdit="editingEvent = $event"
    @save="onSaveEvent($event)"
    @remove-tag="removeTag($event)"
    @delete="onEventDelete($event)" />
</div>
</template>

<script>
export default {
  mixins: [vueMixins.helpers],

  props: {
    date: {
      type: String,
      default: null
    },

    type: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      currentPage: {
        text: 'Calendar'
      },
      showEvent: false,
      add: true,
      view: false,
      editingEvent: false,
      selected: null,
      month: (new Date()).getMonth(),
      year: (new Date()).getFullYear(),
      day: 1,
      events: null
    }
  },

  computed: {
    userId () {
      return window.appData.currentUser.id
    },

    isScheduler () {
      return this.type === 'SCHEDULER'
    },

    selectedDate () {
      var year=this.year;
      var month=this.month+1;
      var day=this.day;
      return moment(`${year}-${month}-${day}`, 'YYYY-M-D').format('YYYY-MM-DD')
    },

    eventNotCreatedByMe () {
      return this.selected ? this.isScheduler && this.selected.createdBy.id !== this.userId : false
    }
  },

  watch: {
    async selectedDate (value) {
      if (value) {
        this.loadEvents()
      }
    }
  },

  created () {    
    const date = moment(this.date, 'YYYY-MM-DD')
    if (date) {
      this.day = parseInt(date.format('DD'))
      this.month = parseInt(date.format('MM')) - 1
      this.year = parseInt(date.format('YYYY'))
    }
  },

  methods: {
    removeTag({ event, item }) {
      this.removeEmployee(event, item)
    },

    async removeEmployee (event, item) {
      const notTag = (event.createdBy.id === this.userId)
      const msg = notTag
        ? `Are you sure to remove ${item.employee_id.first_name} ${item.employee_id.last_name} from this event?`
        : 'Are you sure to remove this event from your scheduler?'
      if (confirm(msg)) {
        startLoading()
        await api.delete('EventEmployee', item.id)
        if (notTag) {
          const idx = event.employees.findIndex(emp => emp.id === item.id)
          event.employees.splice(idx, 1)
        } else {
          if (this.showEvent) {
            this.showEvent = false
            this.selected = null
          }
          const idx = this.events.findIndex(ev => ev.id === event.id)
          this.events.splice(idx, 1)
          this.selected = null
        }
        stopLoading('Tag removed successfully.')
      }
    },

    addEvent () {
      this.add = true
      this.showEvent = true
    },

    viewEvent () {
      this.view = true
      this.editingEvent = false
      this.add = false
      this.showEvent = true
    },

    editEvent () {
      this.editingEvent = true
      this.add = false
      this.showEvent = true
    },

    deleteEvent () {
      this.$refs.eventModal.deleteEvent(this.selected.id)
    },

    onEventDelete (id) {
      this.selected = null
      this.showEvent = false
      const index = this.events.findIndex(event => event.id === id)
      this.events.splice(index, 1)
    },

    toggleSelect (event) {
      this.selected = this.selected === event ? null : event
    },

    formatEventTime (event) {
      const from = moment(event.date_from, 'YYYY-MM-DD HH:mm:ss').format('h:mm A')
      const to = moment(event.date_to, 'YYYY-MM-DD HH:mm:ss').format('h:mm A')
      return `${from} - ${to}`
    },

    onSaveEvent (event) {
      const index = this.events.findIndex(e => e.id === event.id)
      let date = null
      if (index >= 0) {
        date = this.events[index].date_from
        this.events[index] = event
        if (this.selected) {
          this.editingEvent = false
          this.selected = this.events[index]
        }
      } else {
        this.showEvent = false
      }
      let currentDate = moment(event.date_from).format('YYYY-MM-DD')
      if (currentDate === this.selectedDate || (date && moment(date).format('YYYY-MM-DD') !== currentDate))
        this.loadEvents()
    },

    async loadEvents () {
      const pastSelect = this.selected ? this.selected.id : null
      this.selected = null
      this.events = null
      const payload = {
        type: this.type,
        date_from: ["LIKE", `${this.selectedDate}%`]
      }
      if (this.isScheduler) {
        payload.createdBy = this.userId
      }
      this.events = (await api.list("Event", payload)).items

      if (this.isScheduler) {
        delete payload.createdBy
        payload['employees.user_id'] = this.userId
        this.events = this.events.concat((await api.list("Event", payload)).items)
      }

      const preselect = this.getUrl('event') || pastSelect
      if (preselect) {
        const find = this.events.find(event => event.id == preselect)
        if (find) {
          this.selected = find
          this.viewEvent()
        }
      }
    }
  }
}
</script>
