<template>
<tr class="chat">
  <td colspan="7">
    <div class="chat-con">
      <ul class="chat-info">
        <li><label>Ticket #:</label><em>{{ ticket.id }}</em></li>
        <li><label>Type:</label><em>{{ ticket.type }}</em></li>
        <li><label>Account ID:</label><em>{{ ticket.user && ticket.user.agentId }}</em></li>
        <li><label>Name:</label><em>{{ ticket.user && (ticket.user.fullName || ticket.user.companyName || (ticket.user.contacts && ticket.user.contacts[0] && (ticket.user.contacts[0].firstName+' '+ticket.user.contacts[0].lastName))) }}</em></li>
        <li><label>E-Mail:</label><em>{{ ticket.user && (ticket.user.email || ticket.user.companyEmail || (ticket.user.agentDb && ticket.user.agentDb.email)) }}</em></li>
        <li><label>Subject:</label><em>{{ subjOptions[ticket.subject] || "Other" }}</em></li>
        <li><label>Status:</label><em>{{ ticket.status }}</em></li>
        <li class="close" style="margin-left: auto"><button class="btn" v-on:click.prevent="$parent.currentTicket=null">Minimize</button></li>
      </ul>
      <ul class="cmessages" ref="messagesbox">
        <template v-for="update in ticket.updates">
          <li :class="{in: ticket.user && update.user && ticket.user.id==update.user.id, out: ticket.user && update.user && ticket.user.id!=update.user.id}" v-if="update.newStatus==null">
            <p v-html="ticketMessage(update)"></p>
            <date>{{ showUsersName(update.user) }} | {{ formatDate(update.created, "MM/DD/YYYY h:mmA") }}</date>
          </li>
          <li class="sta" v-else>
            {{ formatDate(update.created, "MM/DD/YYYY h:mmA") }} | <strong>{{ showUsersName(update.user) }}</strong> has changed status to <em v-html="update.newStatus"></em>
          </li>
        </template>
      </ul>
      <div class="chat-form">
        <form>
          <textarea v-model="newMessage"></textarea>
          <div class="chf-act">
            <button class="main" v-on:click.prevent="createMessage" :disabled="ticket.status=='Closed' || ticket.status=='Resolved'" :style="{opacity: (ticket.status=='Closed' || ticket.status=='Resolved')?0.5:1}">Send</button>
            <button v-if="admin && (ticket.status=='Pending' || ticket.status=='Closed')" v-on:click.prevent="setStatus('Open')">Open</button>
            <button v-if="admin && ticket.status=='Open'" v-on:click.prevent="setStatus('Resolved')">Resolve</button>
            <button v-if="admin && (ticket.status=='Pending' || ticket.status=='Open')" v-on:click.prevent="setStatus('Closed')">Close</button>
          </div>
        </form>
      </div>
      <div class="updates-table" v-if="admin">
        <h3>Ticket Actions History:</h3>
        <table>
          <tr v-for="update in [ticket.updates[0]]">
            <td>{{ formatDate(update.created, "MM/DD/YYYY h:mmA") }}</td>
            <td>ticket started by <strong>{{ showUsersName(update.user) }}</strong></td>
          </tr>
          <tr v-for="update in ticket.updates" v-if="update.newStatus">
            <td>{{ formatDate(update.created, "MM/DD/YYYY h:mmA") }}</td>
            <td><strong>{{ showUsersName(update.user) }}</strong> has changed status to <em v-html="update.newStatus"></em></td>
          </tr>
        </table>
      </div>
    </div>
  </td>
</tr>
</template>

<script>
export default {
  props: ["admin", "ticket"],
  mixins: [vueMixins.dateFormatter],
  data() { return {
    newMessage: "",
    subjOptions: {
      "1": "Subject 1",
      "2": "Subject 2",
      "3": "Subject 3",
      "4": "Subject 4",
      "5": "Subject 5",
      "6": "Other",
    },
  }},
  methods: {
    showUsersName(user) {
      if (user.contacts && user.contacts[0])
        return user.contacts[0].firstName+" "+user.contacts[0].lastName;
      if (user.fullName)
        return user.fullName;
      if (user.companyName)
        return user.companyName;
      return user.username;
    },
    ticketMessage(update) {
      var msg=update.message || "";
      msg=msg.replace(/\</g, "&lt;").replace(/\>/g, "^gt;").replace(/\n/g, "<br />\n");
      return msg;
    },
    createMessage: async function() {
      startLoading();
      var id=(await api.create("SupportUpdate", {
        ticketId: this.ticket.id,
        user: this.admin?appData.currentUser.id:this.ticket.user.id,
        created: 'CURRENT_TIMESTAMP',
        message: this.newMessage,
      })).id;
      this.newMessage="";
      stopLoading("Reply posted");
      this.ticket.updates=(await api.list("SupportUpdate", {ticketId: this.ticket.id})).items;
      var that=this;
      setTimeout(function() {
        $(that.$refs.messagesbox).scrollTop(100000);
      }, 48);
    },
    setStatus: async function(status) {
      startLoading();
      var r1=api.create("SupportUpdate", {
        ticketId: this.ticket.id,
        user: appData.currentUser.id,
        created: 'CURRENT_TIMESTAMP',
        newStatus: status,
      });
      var r2=api.update("SupportTicket", this.ticket.id, {status: status});
      await r1;
      await r2;
      stopLoading("Status updated");
      this.$parent.changeTab(status);
    },
  },
  mounted() {
    $(this.$refs.messagesbox).scrollTop(100000);
    var updateRead=this.admin?{adminRead: "CURRENT_TIMESTAMP"}:{userRead: "CURRENT_TIMESTAMP"};
    api.update("SupportTicket", this.ticket.id, updateRead);
  },
}
</script>
