<template>
<div class="content-width">
  <form @submit.prevent="save()">
    <div class="buttons">
      <button type="submit" class="btn btn-xs">Submit</button>
      <button type="button" class="btn btn-xs" @click="clear()">Clear</button>
      <a v-if="loaded" :href="`/compliance/recmatrix/recordkeeping-matrix/merchants_matrix/${loaded.id}/pdf`" target="_blank">
        <button type="button" class="btn btn-xs">View</button>
      </a>
    </div>

    <div class="matrix-table-wrapper">
      <div class="matrix-header">
        <h2>Merchants Matrix
          <span class="float-r">Required</span>
        </h2>
        <h3>Identity Information To Be Obtained By Company</h3>
      </div>

      <table>
        <tbody v-for="(category, idx) in list" :key="idx">
          <tr class="header">
            <td colspan="2">{{ category.name }}:</td>
          </tr>
          <tr v-for="(item, index) in category.items" :key="index">
            <td>{{ item.name }}:</td>
            <td width="200">
              <select v-model="form[item.key]" v-if="getSelectOptions(item.key)">
                <option
                  v-for="option in getSelectOptions(item.key)"
                  :key="option">{{ option }}</option>
              </select>
              <input v-else v-model="form[item.key]" type="checkbox" class="checkbox">
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </form>
</div>
</template>

<script>
export default {
  data () {
    return {
      loaded: null,
      form: {},
      list: [
        {
          name: 'FOR SOLE PROPRIETORS',
          items: [
            'Full Legal Surname and First Name',
            'Date of Birth (DOB)',
            'Physical Home Address',
            'Physical Business Address, if any',
            'Contact Phone Number (mobile)',
            'Email Address',
            'Registration Name and Address for Ficticious of Bank, Business RoutingName / Swift(DBA)',
            'Number, and Bank Account Number',
            'Website Address for Individual\'s Business',
            'Description of Individual\'s Business (nature of business, years in business, products and services offered, business of any affiliated company)',
            'Description of Goods or Services Merchant Offers',
            'Tax Registration Certificate (uploaded or hardcopy) if issued for sole proprietor'
          ]
        },
        {
          name: 'FOR ENTITIES',
          items: [
            'Legal Entity Name',
            'All Tradenames / Doing Business As (DBAs)',
            'Articles of Incorporation',
            'Date and Province of incorporation',
            'Copy Identification of Business CardLicense for Legal(uploaded Representative or hardcopy)',
            'Tax Registration Certificate (uploaded or hardcopy)',
            'Physical Address for Principal Place of Business',
            'Mailing Address for Business',
            'Business Telephone Number',
            'Email Name Address',
            'Number, and Bank Account Number',
            'Website Address for Business',
            'Description of the Entity\'s Business (nature of business, years in business, products and services offered, whether entity is a subsidiary of or otherwise affiliated with another company, business of any affiliated company)',
            'Description of Goods or Services Merchant Offers',
            'Expected Merchant Transaction volume per week/month/year average',
            'Name',
            'Point Person Email Address',
            'Point Person Mobile Phone Number',
            'Merchant Principals\' Information (all 25% and above Shareholders and the Person who will Control and Administer Account with Company (unless that Control Person is already disclosed as a 25% or greater Shareholder) (not needed for public corporations or government entities)',
            'Full Legal Surname and First Name',
            'Date of Birth (DOB)',
            'Mobile Telephone',
            'Email Address',
            'Title (SH, Director, Authorized Signer)',
            'If SH, Ownership Percentage',
            'Home Address (street, city, state, province, country, zip or postal code)',
            'Govt Issued Photo Identification Card, current and unaltered, showing name, DOB and place of issuance, issued by govt authority (uploaded in color or hardcopy provided in color or photo:'
          ]
        },
        {
          name: 'DOCUMENTS',
          items: [
            'jpg, jpeg, gif, tif, tiff, and pdf'
          ]
        },
        {
          name: 'AGREEMENTS',
          items: [
            'Merchant Prequalifying Application',
            'Merchant Application. Form',
            'Merchant Services Agreement',
            'Merchant Fee Schedule',
            'Merchant Application Summary',
            'List of Underwriting Required Documents',
            'Signed Bank Disclosure'
          ]
        },
        {
          name: 'DUE DILIGENCE CHECKS AND VERIFICATIONS DONE BY COMPANY AT TIME OF REGISTRATION SUBMISSION',
          items: [
            'Missing info or typos',
            'Mismatched location provide vs website advertiesed business address',
            'Mismatch in legal name on business documents name on registration/agreement',
            'Suspicious email address (computer generated or random)',
            'Scan Comparison quality of permits name,alladdress, info to besignature legible and besignature legible and pictures',
            'No signs of forgery or alteration of document',
            'Address which does not exist',
            'Dissimilar legal name and DBA without supporting documentation to explain relationship',
            'Legal name does not match tax registration certificate',
            'Internet search and review of website(s) to see that they are both consistent with information provided',
            'Credit Report check for signers, owners of 25% or more interest; Record date of check and scores',
            'OFAC/Sanctions List check for signers, owners of 25% or more interest , directors, signing authority officers, and entity legal name; Record date of check',
            'OFAC/Sanctions List potential match? Match reviewed? Final outcome? Record dates of each',
            'Confirm submitted information with State and/or Commercial Incorporation/ Business database',
            'Merchant Website Review - details/date of review and who conducted the review',
            'Recent Bill / Stmt showing name and current'
          ]
        },
        {
          name: 'SOLE PROPRIETOR AND ENTITY RISK ANALYSIS: EXAMPLES OF ITEMS TO BE TAKEN INTO CONSIDERATION AND RISK RATED BY COMPANY',
          items: [
            'Length of time in business',
            'Types of good and services and related industry risk',
            'Results of website reviews',
            'Marketing Materials if no website',
            'Complete MOTO/Internet Questionnaire',
            'Results of negative news review',
            'Transaction Volumes - Weekly/monthly/yearly',
            'Previous history with Company',
            'Years Merchant has had an Account with Company'
          ]
        },
        {
          name: 'WHERE ENHANCED DUE DILIGENCE IS WARRANTED: SOME OR ALL OF THE FOLLOWING MAY BE REQUIRED (additional information in the Enhanced Due Diligence Policy)',
          items: [
            'Bank Statement for Merchant\'s Main Account that Reflects Daily Cash Flows for the preceding 6 months (uploaded or hardcopy)',
            'Bill of Lading / Waybill/ product shipping details',
            'Transaction Descriptions (what was purchased,date, time, purchaser information including name and address)',
            'Proof of Items being shipped and delivered',
            'Personal Guarantee',
            '3 Months Processing Statements',
            '3 Months Bank Statements or Signed Bank Letter',
            'Void Check or Signed Bank Letter',
            '2 Years Financial Statements',
            'Complete Summary: Transaction Flow, Risk & Loss Prevention Strategy, Financial & Credit Analysis'
          ]
        },
        {
          name: 'TRANSACTION RECORDKEEPING TO BE DONE BY COMPANY',
          items: [
            'Date and Time Stamp',
            'Transaction Type (if and when applicable - e.g.reversal /charge back)',
            'All Communications with Merchant Regarding Transactions'
          ]
        },
        {
          name: 'STR AND OFAC RECORDKEEPING TO BE DONE BY COMPANY',
          items: [
            'Internal Notes / Memos',
            'Internal Unusual Activity Report (IUAR)',
            'Suspicious Transaction Reports Filed (STR)',
            'AML Recordkeeping Period (minimum)'
          ]
        },              
        {
          name: 'MISCELLANEOUS RECORDKEEPING TO BE DONE BY COMPANY',
          items: [
            'Merchant Name (Account Number)',
            'Account Opening Date',
            'Account Closing Date',
            'Account / Relationship Termination for Cause (with record of reasons)',
            'Recordkeeping period (minimum) for all identity documentation and validation',
            'Historical Notes by Staff as Result of Merchant Contact',
            'Reason and date for registration declined/rejection noted in system',
            'If Reserves are Kept, Reserve Balance, Reserve Returned, Reserve Calculation Details'
          ]
        }
      ]
    }
  },

  computed: { 
    monthOptions () {
      return this.generateOptions(6, "month", "months");
    },
    
    yearOptions () {
      return this.generateOptions(7, "year", "years");
    }
  },

  created () {
    this.load()
    
  },

  methods: {
    clear () {
      Object.keys(this.form).forEach(key => {
        this.form[key] = false
      })
    },

    async load () {
      const data = await api.list('ComplianceDocument', { type: 'MERCHANTS_MATRIX' })
      let parsedData = {}
      if (data.items.length) {
        this.loaded = data.items[0]
        this.loaded.form.forEach(item => {
          let value = item.value
          if (['1', '0'].includes(value)) value = !!(value === '1')
           parsedData[item.name] = value
        })
      }

      this.init(parsedData)
    },

    async save () {
      startLoading();
      let props = []
      Object.keys(this.form).forEach(name => {
        let value = this.form[name]
        if (typeof value === 'boolean') value = value ? '1' : '0' 
        props.push({ name, value })
      })

      try {
        if (this.loaded) {
          await api.update("ComplianceDocument", this.loaded.id, { form: {} })
          await api.update("ComplianceDocument", this.loaded.id, { form: props })
        } else {
          await api.create("ComplianceDocument", {
            userId: window.appData.currentUser.id,
            type: 'MERCHANTS_MATRIX',
            docIndex: 'GEN_DOC_ID',
            status: 'PENDING',
            signed: null,
            created: 'CURRENT_TIMESTAMP',
            form: props
          });
        }
        stopLoading("Saved");
      } catch (e) {
        stopLoading(e, 'error');
      }
    },

    init (data) {
      this.form = {}
      this.list.forEach((cat, index) => {
        const items = cat.items.map(item => {
          return {
            key: this.formatName(cat.name, item),
            name: item
          }
        })
        cat.items.forEach(item => {
          const key = this.formatName(cat.name, item)
          const value = Object.keys(data).length ? data[key] : false
          this.$set(this.form, key, value)
        })
        this.list[index].items = items
      })
    },

    formatName (prefix, text) {
      text = prefix.substring(0, 15) + ' ' + text.substring(0, 35)
      text = text.replace(/\s/g, '_').toLowerCase()
      text = text.replace(/[()',.\/%/]/g, '').toLowerCase()
      return text
    },

    getSelectOptions (key) {
      if (['due_diligence_c_internet_search_and_review_of_websi', 'due_diligence_c_merchant_website_review_-_detailsd'].includes(key)) return this.monthOptions
      if (['str_and_ofac_re_aml_recordkeeping_period_minimum', 'miscellaneous_r_recordkeeping_period_minimum_for_'].includes(key)) return this.yearOptions
      return null
    },

    generateOptions (max, singular, plural) {
      var data = [];
      for (let i = 1; i <= max; i++) {
        let word = i == 1 ? singular : plural;
        data.push(`${i} ${word}`);
      }
      return data;
    }
  },

  mixins: [
    window.vueMixins.dateFormatter
  ]
}
</script>
