<template>
  <div>
    <p>Compliance</p>
    <br/>
    <div class="content-table-container file-list-container">
      <table class="file-list content-table">
        <thead>
          <tr>
            <td>Type</td>
            <td></td>
            <td>Result</td>
            <td>Options</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>KYC Boarding Report</td>
            <td class="partner-boarding-doc-actions">
              <button v-on:click.prevent="startKyc">Start</button>
              <button v-on:click.prevent="skipKyc">Skip</button>
            </td>
            <td v-if="kycResult">
              {{ kycResult }}
            </td>
            <td v-else>Pending</td>
            <td></td>
          </tr>
          <tr>
            <td>Scans</td>
            <td class="partner-boarding-doc-actions">
              <button v-on:click.prevent="startBoarding">Start</button>
              <button v-on:click.prevent="skipBoarding">Skip</button>
            </td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>{{ item.agentDb.name }}</td>
            <td class="partner-boarding-doc-actions">
              
            </td>
            <td v-if="boardingResult">
              {{ boardingResult }}
            </td>
            <td v-else>Pending</td>
            <td></td>
          </tr>
          <tr v-for="(ubo, ui) in item.contacts">
            <td>{{ ubo.firstName }} {{ ubo.lastName }}</td>
            <td class="partner-boarding-doc-actions">
              
            </td>
            <td v-if="boardingResult">
              {{ boardingResult }}
            </td>
            <td v-else>Pending</td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="actions">
      <button v-on:click.prevent="goBack">Back</button>
      <button :disabled="this.kycResult == null || this.boardingResult == null" v-on:click.prevent="doNext">Save & Continue</button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["item"],
  data: ()=>({
    kycResult: null,
    boardingResult: null,
    boardingMessage: null,
    documentResult: null,
  }),
  methods: {
    goBack() {
      this.$emit("back");
    },
    doNext() {
      this.$emit("next");
    },
    async startKyc() {
      var extradata=this.item.agentDb.extradata || {};
      extradata.kycResult="Pass";
      this.item.agentDb.extradata=extradata;
      await api.update("Partner", this.item.agentDb.id, {extradata});
      this.kycResult="Pass";
    },
    async skipKyc() {
      var extradata=this.item.agentDb.extradata || {};
      extradata.kycResult="Skipped";
      this.item.agentDb.extradata=extradata;
      await api.update("Partner", this.item.agentDb.id, {extradata});
      this.kycResult="Skipped";
      this.boardingMessage=null;
    },
    async startBoarding() {
      var extradata=this.item.agentDb.extradata || {};
      extradata.boardingResult="Pass";
      this.item.agentDb.extradata=extradata;
      await api.update("Partner", this.item.agentDb.id, {extradata});
      this.boardingResult="Pass";
      this.boardingMessage="All checks passed";
    },
    async skipBoarding() {
      var extradata=this.item.agentDb.extradata || {};
      extradata.boardingResult="Skipped";
      this.item.agentDb.extradata=extradata;
      await api.update("Partner", this.item.agentDb.id, {extradata});
      this.boardingResult="Skipped";
      this.boardingMessage=null;
    },
    async startDocument() {
      var extradata=this.item.agentDb.extradata || {};
      extradata.documentResult="Pass";
      this.item.agentDb.extradata=extradata;
      await api.update("Partner", this.item.agentDb.id, {extradata});
      this.documentResult = 'Pass'
    }
  },
  mounted() {
    this.kycResult=(this.item.agentDb.extradata || {}).kycResult;
    this.boardingResult=(this.item.agentDb.extradata || {}).boardingResult;
    this.documentResult=(this.item.agentDb.extradata || {}).documentResult;
    if (this.boardingResult) {
      this.boardingMessage="All checks passed";
    }
  },
}
</script>
<style scoped>
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  margin-right: 8px;
  color: black;
  font-size: 16px;
  padding: 6px 20px;
  box-sizing: border-box;
  border: 1px solid #b3b3b3;
  border-radius: 6px;
  background: #e4e4e4;
  background: linear-gradient(to bottom, #e4e4e4 0%, #cfcfcf 100%);
}
button:hover, button:focus {
  background: #e9f2ff;
  background: linear-gradient(to bottom, #e9f2ff 0%, #deebff 100%);
  border-color: #6192fc;
  color: #6192fc;
}
</style>
