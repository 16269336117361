<template>
  <div class="status-page">
    <div class="ss-head">
      <div class="publish-content">
        <h1>System Status</h1>
        <div class="next-schedule" style="">
          <label style="font-weight:bold">Scheduled maintenance or downtime:</label>
          <br/>
          <p style="font-size:14px">{{ scheduled || "no downtime scheduled" }}</p>
        </div>
      </div>
    </div>
    <div class="status-list">
      <ul>
        <li v-for="(item, index) in items" :class="{opened: opened[index]}">
          <div v-on:click.prevent="toggleOpened(index)">
            <a class="toggle" href="">
              <!--{{ opened[index]?'-':'+' }}-->
              {{ item.title }}
            </a>
          </div>
          <ul v-if="opened[index]">
            <li v-for="(subitem, si) in item.items">
              <div>{{ subitem.name }}</div>
              <div>{{ subitem.message }}</div>
              <div class="color" :class="subitem.color"></div>
              <div class="err" v-if="subitem.downtime">{{ subitem.downtime }}</div>
              <div v-else>Operational</div>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
  </template>
  
  <script>
  export default {
    data() { return {
      items: [],
      scheduled: null,
      opened: [],
    }},
    methods: {
      toggleOpened(index) {
        this.$set(this.opened, index, !this.opened[index]);
      },
      async loadItems() {
        var result=(await api.get("SystemStatus", 1)).item

        this.items.push({
          'title' : result.section1Name,
          'items' : result.section1Items,
        })
        this.items.push({
          'title' : result.section2Name,
          'items' : result.section2Items,
        })
        this.items.push({
          'title' : result.section3Name,
          'items' : result.section3Items,
        })
        this.items.push({
          'title' : result.section4Name,
          'items' : result.section4Items,
        })
        this.scheduled=result.maintenanceMessage;
        this.opened=this.items.map(a=>false);
      },
    },

    mounted() {
      this.loadItems();
    },
  }
  </script>
  <style lang="scss" scoped>
    .publish-content {
      width: 1000px;
    }
  </style>