<template>
  <div class="drawer" @click="close()">
    <div class="drawer-panel" :style="{ width: size + 'px' }" @click.stop>
      <div class="close" @click="close()">
        <img src="/assets/img/close.png" />
      </div>
      <div>
        <div class="drawer-title">
          {{ title }}
        </div>
        <div class="drawer-content">
          <slot />
        </div>
      </div>
      <div
        v-if="$slots.footer"
        class="drawer-footer">
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },

    size: {
      type: Number,
      default: 450
    },
  },

  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
  .drawer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 99;
    background-color: rgba(0,0,0,0.7);
    display: flex;
    justify-content: flex-end;
    .drawer-panel {
      background-color: #fff;
      height: 100%;
      margin: 0;
      padding: 20px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      .close {
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
      }
      .drawer-title {
        font-size: 26px;
        padding-right: 40px;
      }
      .drawer-content {
        margin-top: 40px;
      }
      .drawer-footer {

      }
    }
  }
</style>