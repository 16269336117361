<template>
  <div>
    <p>Partner Onboarding</p>
    <br/>
    
    <div v-show="buildOpen">
      <agent-setup-popup ref="agentSetupPop" :popup="true" />
    </div>

    <div class="actions">
      <button v-on:click.prevent="goBack">Back</button>
      <button v-if="item.agentDb.status == 'A04'" v-on:click.prevent="doNext">Complete</button>
    </div>
  </div>
</template>

<script>
export default {
  mixins: [vueMixins.dateFormatter],
  props: ["item"],
  data: ()=>({
    builds: [],
    buildOpen: false,
  }),
  methods: {
    goBack() {
      this.$emit("back");
    },
    async doNext() {
      if (!this.$refs.agentSetupPop.validate())
        return stopLoading("Please fill all required fields", "error");
      await this.$refs.agentSetupPop.save();
      this.$emit("next");
    },
    openBuild(setup) {
      this.buildOpen=true;
      this.$refs.agentSetupPop.showExisting(setup, this.item);
    },
    async loadBuilds() {
      this.builds=(await api.list("PartnerSetup", {agentId: this.item.id})).items;
      if(!this.builds.length) {
        this.startSetup()
      }else{
        this.resumeSetup()
      }
    },
    async startSetup() {
      var id=(await api.create("PartnerSetup", {
        agentId: this.item.id,
        created: "CURRENT_TIMESTAMP",
        name: this.item.agentDb.companyName+" "+this.item.agentDb.agentId,
        accClass: "",
        managerId: this.item.agentDb.manager,
        filename: null,
        signed: null,
        salesBrand: null,
        solutions: [],
        regions: [],
        portal: 'trak',
        pin: null,
        username: null,
        buildId: "APP_ID_GEN_MERCHANTBUILD",
        buildBy: appData.currentUser.id,
      })).id;
      var build=(await api.get("PartnerSetup", id)).item;
      this.openBuild(build);
    },
    async resumeSetup() {
      var setup=(await api.list("PartnerSetup", {agentId: this.item.id}, ["id:DESC"])).items[0];
      if (setup)
        this.openBuild(setup);
    },
    async resetSetup(item) {
      var setup=(await api.list("PartnerSetup", {agentId: this.item.id}, ["id:DESC"])).items[0];
      if (setup && setup.id) {
        await api.delete("PartnerSetup", setup.id);
        this.builds=[];
        this.buildOpen=false;
      }
      appNotifications.show("Partner setup reset", "success")
    },
  },
  created() {
    this.loadBuilds();
  },
}
</script>
<style scoped>
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  margin-right: 8px;
  color: black;
  font-size: 16px;
  display: block;
  padding: 6px 20px;
  box-sizing: border-box;
  border: 1px solid #b3b3b3;
  border-radius: 6px;
  background: #e4e4e4;
  background: linear-gradient(to bottom, #e4e4e4 0%, #cfcfcf 100%);
}
button:hover, button:focus {
  background: #e9f2ff;
  background: linear-gradient(to bottom, #e9f2ff 0%, #deebff 100%);
  border-color: #6192fc;
  color: #6192fc;
}
</style>
