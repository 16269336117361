<template>
<form class="form-step">
  <section>
    <h2 class="bline">Banking profile</h2>
    <p>Please put the details for your settlement bank. Account must be in the same name as company</p>
    <div class="bank-accounts">
      <div v-for="(account, i) in app.accounts" class="bank-account-box">
        <a v-if="app.accounts.length>1" class="remove" href="" v-on:click.prevent="app.accounts.splice(i, 1)">&times;</a>
        <!--<div class="currency-large-box">
          <label>Settlement currency</label>
          <validator :value="account.accountCurrency" reqs="required">
            <dyn-select v-model="account.accountCurrency" :options="currencies" />
          </validator>
        </div>-->
        <div class="f2splitx">
          <ul class="column-form type2 row5">
            <li>
              <label>Name on Bank Account</label>
              <div class="field">
                <validator :value="account.accountHolder" reqs="required legalName">
                  <input type="text" v-model="account.accountHolder" />
                </validator>
              </div>
            </li>
            <li>
              <label>Bank Account Number</label>
              <div class="field">
                <validator :value="account.accountNumber" reqs="required num20">
                  <input type="text" v-model="account.accountNumber" />
                </validator>
              </div>
            </li>
            <li>
              <label>Institution Number</label>
              <div class="field">
                <validator :value="account.accountSwift" reqs="required num3e">
                  <input type="text" v-model="account.accountSwift" />
                </validator>
              </div>
            </li>
            <li>
              <label>Transit/Branch Number</label>
              <div class="field">
                <validator :value="account.accountIBAN" reqs="required num5e">
                  <input type="text" v-model="account.accountIBAN" />
                </validator>
              </div>
            </li>
            <li v-if="false">
              <label>Bank sort code <sup>1</sup></label>
              <div class="field">
                <validator :value="account.accountBankCode" reqs="alnum20">
                  <input type="text" v-model="account.accountBankCode" />
                </validator>
              </div>
            </li>
            <li>
              <label>ABA / Routing Number <sup>1</sup></label>
              <div class="field">
                <validator :value="account.accountRouting" reqs="num9e">
                  <input type="text" v-model="account.accountRouting" />
                </validator>
              </div>
            </li>
          </ul>
          <ul class="column-form type2">
            <li>
              <label>Bank Name</label>
              <div class="field">
                <validator :value="account.bankName" reqs="required alnum30">
                  <input type="text" v-model="account.bankName" />
                </validator>
              </div>
            </li>
            <li>
              <label>Bank Address</label>
              <div class="field">
                <validator :value="account.bankAddress" reqs="required address">
                  <input type="text" v-model="account.bankAddress" />
                </validator>
              </div>
            </li>
            <li>
              <label>Postal Code</label>
              <div class="field">
                <validator :value="account.bankAddressPostCode" reqs="required postcode">
                  <input type="text" v-model="account.bankAddressPostCode" />
                </validator>
              </div>
            </li>
            <li>
              <label>City</label>
              <div class="field">
                <validator :value="account.bankAddressCity" reqs="required city">
                  <input type="text" v-model="account.bankAddressCity" />
                </validator>
              </div>
            </li>
            <li>
              <label>Province</label>
              <div class="field">
                <validator :value="account.bankAddressState" reqs="required state">
                  <div class="combo-select">
                  <dyn-select :options="validProvinces" v-model="account.bankAddressState" />
                  </div>
                </validator>
              </div>
            </li>
            <li>
              <label>Country</label>
              <div class="field">
                <validator :value="account.bankAddressCountry" reqs="required country">
                  <div class="combo-select">
                  <dyn-select :options="validCountries" v-model="account.bankAddressCountry" />
                  </div>
                </validator>
              </div>
            </li>
            <li>
              <label>Bank Telephone Number<!-- <sup>2</sup>--></label>
              <div class="field">
                <validator :value="account.bankPhone" reqs="required phone">
                  <input type="text" v-model="account.bankPhone" />
                </validator>
              </div>
            </li>
            <li>&nbsp;</li>
          </ul>
        </div>
        <ul class="footnotes" v-if="i==0">
          <li><sup>1</sup> This information is required in certain countries. Please make sure these fields are completed if applicable.</li>
          <li><sup>2</sup> Including international country code (e.g. +1 for USA, +852 for Hong Kong, etc.)</li>
        </ul>      
      </div>
    </div>
    <div class="add-dir adx" v-if="app.accounts.length<2">
      <a class="" v-on:click="newAccount">Add another settlement bank<span>+</span></a>
      <!--<a class="new-large" v-on:click="newAccount">Add another settlement bank</a>-->
    </div>
  </section>
  
  <div class="buttons">
    <a class="btn btn-large btn-plain btn-back" v-on:click.prevent="$emit('prev')">Back</a>
    <a class="btn btn-large btn-warn" v-on:click.prevent="$emit('save')">Save</a>
    <a class="btn btn-large btn-success btn-next" v-on:click.prevent="nextStep">Next Step</a>
  </div>
</form>
</template>


<script>
export default {
  props: ["app"],
  data() { return {
    validCountries: [
      {label: "Canada", value: "Canada"},
//       {label: "United States", value: "United States"},
    ],
    validProvinces: [
      {label: "AB – Alberta", value: "AB – Alberta"},
      {label: "BC – British Columbia", value: "BC – British Columbia"},
      {label: "MB – Manitoba", value: "MB – Manitoba"},
      {label: "NB – New Brunswick", value: "NB – New Brunswick"},
      {label: "NL – Newfoundland", value: "NL – Newfoundland"},
      {label: "NT – Northwest Territories", value: "NT – Northwest Territories"},
      {label: "NS – Nova Scotia", value: "NS – Nova Scotia"},
      {label: "NU – Nunavut", value: "NU – Nunavut"},
      {label: "ON – Ontario", value: "ON – Ontario"},
      {label: "PE – Prince Edward Island", value: "PE – Prince Edward Island"},
      {label: "QC – Quebec", value: "QC – Quebec"},
      {label: "SK – Saskatchewan", value: "SK – Saskatchewan"},
      {label: "YT – Yukon Territories", value: "YT – Yukon Territories"},
    ],
    currencies: [
      {value: "124", label: "CAD"},
      {value: "840", label: "USD"},
//       {value: "036", label: "AUD"},
//       {value: "986", label: "BRL"},
//       {value: "756", label: "CHF"},
//       {value: "978", label: "EUR"},
//       {value: "826", label: "GBP"},
//       {value: "344", label: "HKD"},
//       {value: "392", label: "JPY"},
//       {value: "554", label: "NZD"},
    ],
  }},
  methods: {
    nextStep() {
      if (!this.validate())
        return stopLoading("Please fill all required fields", "error");
      this.$emit("next");      
    },
    newAccount() {
      this.app.accounts.push({accountCurrency: "124"});
    },
  },
  mixins: [formValidation.mixin]
}
</script>
