<template>

<form class="edit-system search-form">
  <div>
    <ul class="breadcrumbs">
      <li><a>Organization Settings</a></li>
    </ul>
  </div>
  <div class="twocol">
    <div>
      <h4 class="settings-text">Settings</h4>
      <h5 class="settings-subtitle">General</h5>
      <div class="services-input-group">
        <label>Business Services</label>
        <div class="switch">
          <mnb-switch :disabled="!editing" v-model="form.settings.general.businessServices" />
        </div>
      </div>
      <div class="services-input-group">
        <label>Custom Fees</label>
        <div class="switch">
        <mnb-switch :disabled="!editing" v-model="form.settings.general.customFees" />
      </div>
      </div>
      <div class="services-input-group">
        <label>Compliance</label>
        <div class="switch">
          <mnb-switch :disabled="!editing" v-model="form.settings.general.compliance" />
        </div>
      </div>
      <div class="services-input-group">
        <label class="pl-20">Boarding</label>
        <div class="switch">
          <mnb-switch :disabled="!form.settings.general.compliance || !editing" v-model="form.settings.general.boarding" />
        </div>
      </div>
      <div class="services-input-group">
        <label class="pl-20">Monitoring</label>
        <div class="switch">
          <mnb-switch :disabled="!form.settings.general.compliance || !editing" v-model="form.settings.general.monitoring" />
        </div>
      </div>
      <div class="services-input-group">
        <label>Sandbox</label>
        <div class="switch">
          <mnb-switch :disabled="!editing" v-model="form.settings.general.sandbox" />
        </div>
      </div>
      <div class="services-input-group">
        <label>Invoicing</label>
        <div class="switch">
          <mnb-switch :disabled="!editing" v-model="form.settings.general.invoicing" />
        </div>
      </div>
      <div class="services-input-group">
        <label>MailCast</label>
        <div class="switch">
          <mnb-switch :disabled="!editing" v-model="form.settings.general.mailCast" />
        </div>
      </div>
      <div class="services-input-group">
        <label>Scheduler</label>
        <div class="switch">
          <mnb-switch :disabled="!editing" v-model="form.settings.general.scheduler" />
        </div>
      </div>
      <div class="services-input-group">
        <label>Themes</label>
        <div class="switch">
          <mnb-switch :disabled="!editing" v-model="form.settings.general.themes" />
        </div>
      </div>

      <h5 class="settings-subtitle">Email Notifications</h5>
      <div class="services-input-group">
        <label>Alerts</label>
        <div class="switch">
          <mnb-switch :disabled="!editing" v-model="form.settings.emailNotifications.alerts" />
        </div>
      </div>
      <div class="services-input-group">
        <label>Email Confirmation</label>
        <div class="switch">
          <mnb-switch :disabled="!editing" v-model="form.settings.emailNotifications.emailConfirmation" />
        </div>
      </div>
      <div class="services-input-group">
        <label>Enrollment</label>
        <div class="switch">
          <mnb-switch :disabled="!editing" v-model="form.settings.emailNotifications.enrollment" />
        </div>
      </div>
      <div class="services-input-group">
        <label>New Account Request</label>
        <div class="switch">
          <mnb-switch :disabled="!editing" v-model="form.settings.emailNotifications.newAccountRequest" />
        </div>
      </div>
      <div class="services-input-group">
        <label>Notifications</label>
        <div class="switch">
          <mnb-switch :disabled="!editing" v-model="form.settings.emailNotifications.notifications" />
        </div>
      </div>
      <div class="services-input-group">
        <label>Statement</label>
        <div class="switch">
          <mnb-switch :disabled="!editing" v-model="form.settings.emailNotifications.statement" />
        </div>
      </div>
      <div class="services-input-group">
        <label>Trade</label>
        <div class="switch">
          <mnb-switch :disabled="!editing" v-model="form.settings.emailNotifications.trade" />
        </div>
      </div>
      <div class="services-input-group">
        <label>Transaction</label>
        <div class="switch">
          <mnb-switch :disabled="!editing" v-model="form.settings.emailNotifications.transaction" />
        </div>
      </div>
      <div class="services-input-group">
        <label>Welcome Activation</label>
        <div class="switch">
          <mnb-switch :disabled="!editing" v-model="form.settings.emailNotifications.welcomeActivation" />
        </div>
      </div>

      <h5 class="settings-subtitle">Enrollment</h5>
      <div class="services-input-group">
        <label>Payments</label>
        <div class="switch">
          <mnb-switch v-if="form.modules.rPayments" :disabled="!editing" v-model="form.settings.enrollmentForms.payments" />
          <mnb-switch v-else :disabled="true" :set="form.settings.enrollmentForms.payments = false" />
        </div>
      </div>
      <div class="services-input-group">
        <label class="pl-20">Facilitators</label>
        <div class="switch">
          <mnb-switch :disabled="!form.settings.enrollmentForms.payments || !editing" v-model="form.settings.enrollmentForms.facilitators" />
        </div>
      </div>
      <div class="services-input-group">
        <label class="pl-20">Merchants</label>
        <div class="switch">
          <mnb-switch :disabled="!form.settings.enrollmentForms.payments || !editing" v-model="form.settings.enrollmentForms.merchants" />
          </div>
      </div>
      <div class="services-input-group">
        <label>Treasury</label>
        <div class="switch">
          <mnb-switch v-if="form.modules.rBanking" :disabled="!editing" v-model="form.settings.enrollmentForms.treasury" />
          <mnb-switch v-else :disabled="true" :set="form.settings.enrollmentForms.treasury = false" />
        </div>
      </div>
      <div class="services-input-group">
        <label class="pl-20">Business</label>
        <div class="switch">
          <mnb-switch :disabled="!form.settings.enrollmentForms.treasury || !editing" v-model="form.settings.enrollmentForms.business" />
        </div>
      </div>
      <div class="services-input-group">
        <label class="pl-20">Personal</label>
        <div class="switch">
          <mnb-switch :disabled="!form.settings.enrollmentForms.treasury || !editing" v-model="form.settings.enrollmentForms.personal" />
          </div>
      </div>
      <div class="services-input-group">
        <label class="pl-20">Omnibus</label>
        <div class="switch">
          <mnb-switch v-if="form.modules.banking.omnibus" :disabled="!form.settings.enrollmentForms.treasury || !editing" v-model="form.settings.enrollmentForms.omnibus" />
          <mnb-switch v-else :disabled="true" :set="form.settings.enrollmentForms.omnibus = false" />
          </div>
      </div>
      <div class="services-input-group">
        <label>Partners</label>
        <div class="switch">
          <mnb-switch :disabled="!editing" v-model="form.settings.enrollmentForms.partners" />
          </div>
      </div>

      <h5 class="settings-subtitle">Partners</h5>
      <div class="services-input-group">
        <label>Amadel</label>
        <div class="switch">
          <mnb-switch :disabled="!editing" v-model="form.settings.partners.amadel" />
        </div>
      </div>
      <div class="services-input-group">
        <label>Scheduler</label>
        <div class="switch">
          <mnb-switch :disabled="!editing" v-model="form.settings.partners.scheduler" />
        </div>
      </div>
      <div class="services-input-group">
        <label>Partner Advance</label>
        <div class="switch">
          <mnb-switch :disabled="!editing" v-model="form.settings.partners.partnerAdvance" />
        </div>
      </div>
    </div>
    <div>
      <h4 class="settings-text">Components</h4>

      <div class="services-input-group">
        <h5 class="settings-subtitle">Payments</h5>
        <div class="switch">
          <mnb-switch :disabled="!editing" v-model="form.modules.rPayments" />
        </div>
      </div>
      <div class="services-input-group">
        <label>Daily Limit</label>
        <div class="switch">
          $ <input type="text" :disabled="!form.modules.rPayments || !editing" v-model="form.modules.payments.dailyLimit" />
        </div>  
      </div>
      <div class="services-input-group">
        <label>Monthly Limit</label>
        <div class="switch">
          $ <input type="text" :disabled="!form.modules.rPayments || !editing" v-model="form.modules.payments.monthlyLimit" />
        </div>  
      </div>
      <div class="services-input-group">
        <label>Account Reconciliation</label>
        <div class="switch">
          <mnb-switch :disabled="!form.modules.rPayments || !editing" v-model="form.modules.payments.accountReconciliation" />
        </div>  
      </div>
      <div class="services-input-group">
        <label>Account Updater</label>
        <div class="switch">
          <mnb-switch :disabled="!form.modules.rPayments || !editing" v-model="form.modules.payments.accountUpdater" />
        </div>  
      </div>
      <div class="services-input-group">
        <label>Gateway</label>
        <div class="switch"><mnb-switch :disabled="!form.modules.rPayments || !editing" v-model="form.modules.payments.gateway" /></div>
      </div>
      <div class="services-input-group">
        <label>Gift</label>
        <div class="switch"><mnb-switch :disabled="!form.modules.rPayments || !editing" v-model="form.modules.payments.gift" /></div>
      </div>
      <div class="services-input-group">
        <label>Gift & Loyalty</label>
        <div class="switch"><mnb-switch :disabled="!form.modules.rPayments || !editing" v-model="form.modules.payments.giftLoyalty" /></div>
      </div>
      <div class="services-input-group">
        <label>Multi-Account</label>
        <div class="switch"><mnb-switch :disabled="!form.modules.rPayments || !editing" v-model="form.modules.payments.multiAccount" /></div>
      </div>
      <div class="services-input-group">
        <label>Multi-Currency</label>
        <div class="switch"><mnb-switch :disabled="!form.modules.rPayments || !editing" v-model="form.modules.payments.multiCurrency" /></div>
      </div>
      <div class="services-input-group">
        <label>Payment Facilitators</label>
        <div class="switch"><mnb-switch :disabled="!form.modules.rPayments || !editing" v-model="form.modules.payments.paymentFacilitators" /></div>
      </div>
      <div class="services-input-group">
        <label>Subscription</label>
        <div class="switch"><mnb-switch :disabled="!form.modules.rPayments || !editing" v-model="form.modules.payments.subscription" /></div>
      </div>
      <div class="services-input-group">
        <label>Titan</label>
        <div class="switch"><mnb-switch :disabled="!form.modules.rPayments || !editing" v-model="form.modules.payments.titan" /></div>
      </div>
      <div class="services-input-group">
        <label>Tokenization</label>
        <div class="switch"><mnb-switch :disabled="!form.modules.rPayments || !editing" v-model="form.modules.payments.tokenization" /></div>
      </div>
      <div class="services-input-group">
        <label>Virtual Terminal</label>
        <div class="switch"><mnb-switch :disabled="!form.modules.rPayments || !editing" v-model="form.modules.payments.virtualTerminal" /></div>
      </div>

      <div class="services-input-group">
        <h5 class="settings-subtitle">Treasury</h5>
        <div class="switch"><mnb-switch :disabled="!editing" v-model="form.modules.rBanking" /></div>
      </div>
      <div class="services-input-group">
        <label>Daily Limit</label>
        <div class="switch">
          $ <input type="text" :disabled="!form.modules.rBanking || !editing" v-model="form.modules.banking.dailyLimit" />
        </div>  
      </div>
      <div class="services-input-group">
        <label>Monthly Limit</label>
        <div class="switch">
          $ <input type="text" :disabled="!form.modules.rBanking || !editing" v-model="form.modules.banking.monthlyLimit" />
        </div>  
      </div>
      <div class="services-input-group">
        <label>Account Reconciliation</label>
        <div class="switch"><mnb-switch :disabled="!form.modules.rBanking || !editing" v-model="form.modules.banking.accountReconciliation" /></div>
      </div>
      <div class="services-input-group">
        <label>Advanced Deposit</label>
        <div class="switch"><mnb-switch :disabled="!form.modules.rBanking || !editing" v-model="form.modules.banking.advancedDeposit" /></div>
      </div>
      <div class="services-input-group">
        <label>Billing Model</label>
        <div class="switch"><mnb-switch :disabled="!form.modules.rBanking || !editing" v-model="form.modules.banking.billingModel" /></div>
      </div>
      <div class="services-input-group">
        <label class="pl-20">Prepay</label>
        <div class="switch"><mnb-switch :disabled="!form.modules.rBanking || !form.modules.banking.billingModel || !editing" v-model="form.modules.banking.prepay" /></div>
      </div>
      <div class="services-input-group">
        <label class="pl-20">Postpay</label>
        <div class="switch"><mnb-switch :disabled="!form.modules.rBanking || !form.modules.banking.billingModel || !editing" v-model="form.modules.banking.postpay" /></div>
      </div>
      <div class="services-input-group">
        <label>Card Issuing</label>
        <div class="switch"><mnb-switch :disabled="!form.modules.rBanking || !editing" v-model="form.modules.banking.cardIssuing" /></div>
      </div>
      <div class="services-input-group">
        <label class="pl-20">Physical Cards</label>
        <div class="switch"><mnb-switch :disabled="!form.modules.rBanking || !form.modules.banking.cardIssuing || !editing" v-model="form.modules.banking.physicalCards" /></div>
      </div>
      <div class="services-input-group">
        <label class="pl-20">Virtual Cards</label>
        <div class="switch"><mnb-switch :disabled="!form.modules.rBanking || !form.modules.banking.cardIssuing || !editing" v-model="form.modules.banking.virtualCards" /></div>
      </div>
      <div class="services-input-group">
        <label class="pl-20">Dispute Manager</label>
        <div class="switch"><mnb-switch :disabled="!form.modules.rBanking || !form.modules.banking.cardIssuing || !editing" v-model="form.modules.banking.disputeManager" /></div>
      </div>
      <div class="services-input-group">
        <label>Digital Assets</label>
        <div class="switch"><mnb-switch :disabled="!form.modules.rBanking || !editing" v-model="form.modules.banking.digitalAssets" /></div>
      </div>
      <div class="services-input-group">
        <label class="pl-20">Trade Desk</label>
        <div class="switch"><mnb-switch :disabled="!form.modules.rBanking || !form.modules.banking.digitalAssets || !editing" v-model="form.modules.banking.tradeDesk" /></div>
      </div>
      <div class="services-input-group">
        <label>Omnibus</label>
        <div class="switch"><mnb-switch :disabled="!form.modules.rBanking || !editing" v-model="form.modules.banking.omnibus" /></div>
      </div>
      <div class="services-input-group">
        <label>Multi-Account</label>
        <div class="switch"><mnb-switch :disabled="!form.modules.rBanking || !editing" v-model="form.modules.banking.multiAccount" /></div>
      </div>
      <div class="services-input-group">
        <label>Multi-Currency</label>
        <div class="switch"><mnb-switch :disabled="!form.modules.rBanking || !editing" v-model="form.modules.banking.multiCurrency" /></div>
      </div>
      <div class="services-input-group">
        <label class="pl-20">Exchange Desk</label>
        <div class="switch"><mnb-switch :disabled="!form.modules.rBanking || !form.modules.banking.multiCurrency || !editing" v-model="form.modules.banking.exchangeDesk" /></div>
      </div>
      <div class="services-input-group">
        <label>Remote Deposit</label>
        <div class="switch"><mnb-switch :disabled="!form.modules.rBanking || !editing" v-model="form.modules.banking.remoteDeposit" /></div>
      </div>
      <div class="services-input-group">
        <label>Statements</label>
        <div class="switch"><mnb-switch :disabled="!form.modules.rBanking || !editing" v-model="form.modules.banking.statements" /></div>
      </div>
      <div class="services-input-group">
        <label>Withdrawal</label>
        <div class="switch"><mnb-switch :disabled="!form.modules.rBanking || !editing" v-model="form.modules.banking.withdrawal" /></div>
      </div>
      <div class="services-input-group">
        <label class="pl-20">Funds</label>
        <div class="switch"><mnb-switch :disabled="!form.modules.rBanking || !form.modules.banking.withdrawal || !editing" v-model="form.modules.banking.funds" /></div>
      </div>
      <div class="services-input-group">
        <label class="pl-20">Assets</label>
        <div class="switch"><mnb-switch :disabled="!form.modules.rBanking || !form.modules.banking.withdrawal || !editing" v-model="form.modules.banking.assets" /></div>
      </div>
    </div>
  </div>
  <div class="buttons" v-if="!readonly">
    <button v-if="!editing" class="btn btn-small btn-primary" v-on:click.prevent="editing = true">Update</button>
    <template v-else>
      <button class="btn btn-small btn-primary" v-on:click.prevent="updateSettings">Save</button>
      <button class="btn btn-small btn-danger" v-on:click.prevent="editing = false">Cancel</button>
    </template>
  </div>  
</form>


</template>

<script>
  import MnbSwitch from './switch.vue'
export default {
  components: {
      MnbSwitch,
    },
  props: ["readonly"],
  data: ()=>({
    editing: false,
    form: {
      settings: {
        general: {
          businessServices: false,
          customFees: false,
          compliance: false,
          boarding: false,
          monitoring: false,
          sandbox: false,
          invoicing: false,
          mailCast: false,
          scheduler: false,
          themes: false,
        },
        emailNotifications: {
          enrollment: false,
          emailConfirmation: false,
          welcomeActivation: false,
          transaction: false,
          trade: false,
          statement: false,
          alerts: false,
          notifications: false,
          newAccountRequest: false,
        },
        enrollmentForms: {
          payments: false,
          partners: false,
          merchants: false,
          personal: false,
          business: false,
          facilitators: false,
          treasury: false,
          omnibus: false,
        },
        partners: {
          amadel: false,
          scheduler: false,
          partnerAdvance: false
        }
      },
      
      modules: {
        rPayments: false,
        payments: {
          dailyLimit: null,
          monthlyLimit: null,
          accountReconciliation: false,
          accountUpdater: false,
          gateway: false,
          gift: false,
          giftLoyalty: false,
          multiAccount: false,
          multiCurrency: false,
          paymentFacilitators: false,
          subscription: false,
          titan: false,
          tokenization: false,
          virtualTerminal: false,
        },
        rBanking: false,
        banking: {
          dailyLimit: null,
          monthlyLimit: null,
          accountReconciliation: false,
          advancedDeposit: false,
          billingModel: false,
          prepay: false,
          postpay: false,
          cardIssuing: false,
          physicalCards: false,
          virtualCards: false,
          disputeManager: false,
          digitalAssets: false,
          tradeDesk: false,
          omnibus: false,
          multiAccount: false,
          multiCurrency: false,
          exchangeDesk: false,
          remoteDeposit: false,
          statements: false,
          withdrawal: false,
          funds: false,
          assets: false,
        }
      }
    },
  }),

  watch: {
    'form.modules.rPayments' () {
      if(this.form.modules.rPayments == false) {
        this.form.modules.payments = {
          accountReconciliation: false,
          gateway: false,
          multiAccount: false,
          paymentFacilitators: false,
          virtualTerminal: false,
        }
      }
    },

    'form.modules.rBanking' () {
      if(this.form.modules.rBanking == false) {
        this.form.modules.banking = {
          accountReconciliation: false,
          advancedDeposit: false,
          cardIssuing: false,
          physicalCards: false,
          virtualCards: false,
          disputeManager: false,
          digitalAssets: false,
          tradeDesk: false,
          omnibus: false,
          multiAccount: false,
          multiCurrency: false,
          exchangeDesk: false,
          remoteDeposit: false,
          statements: false,
          withdrawal: false,
          funds: false,
          assets: false,
        }
      }
    },

    'form.settings.general.compliance' () {
      if(this.form.settings.general.compliance == false) {
        this.form.settings.general.boarding = false
        this.form.settings.general.monitoring = false
      }
    },

    'form.modules.banking.cardIssuing' () {
      if(this.form.modules.banking.cardIssuing == false) {
        this.form.modules.banking.physicalCards = false
        this.form.modules.banking.virtualCards = false
        this.form.modules.banking.disputeManager = false
      } 
    },

    'form.modules.banking.digitalAssets' () {
      if(this.form.modules.banking.digitalAssets == false) {
        this.form.modules.banking.tradeDesk = false
      } 
    },

    'form.modules.banking.multiCurrency' () {
      if(this.form.modules.banking.multiCurrency == false) {
        this.form.modules.banking.exchangeDesk = false
      } 
    },

    'form.modules.banking.withdrawal' () {
      if(this.form.modules.banking.withdrawal == false) {
        this.form.modules.banking.funds = false
        this.form.modules.banking.assets = false
      } 
    },

    'form.modules.banking.billingModel' () {
      if(this.form.modules.banking.billingModel == false) {
        this.form.modules.banking.prepay = false
        this.form.modules.banking.postpay = false
      } 
    },

    'form.settings.enrollmentForms.payments' () {
      if(this.form.settings.enrollmentForms.payments == false) {
        this.form.settings.enrollmentForms.facilitators = false
        this.form.settings.enrollmentForms.merchants = false
      } 
    },

    'form.settings.enrollmentForms.treasury' () {
      if(this.form.settings.enrollmentForms.treasury == false) {
        this.form.settings.enrollmentForms.business = false
        this.form.settings.enrollmentForms.personal = false
        this.form.settings.enrollmentForms.omnibus = false
      } 
    }
  },

  mounted() {
    this.refresh();
  },
  methods: {
    async refresh() {
      var item = (await api.get("Settings", 1)).item;
      if(item.settings) {
        this.form.settings = item.settings
      }
      if(item.modules) {
        this.form.modules = item.modules
      }
    },
    
    async updateSettings() {
      startLoading();
      await api.update("Settings", 1, this.form);
      stopLoading("Settings updated");
      this.editing = false
    },
  },
}
</script>
