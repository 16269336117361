<template>
<ul class="multiselect wd">
  <li v-for="(option, oi) in options || []">
    <label :class="{checked: actualValue.includes(option)}">
      <input :disabled="readonly" type="checkbox" :value="option" v-model="actualValue" v-on:change="update" />
      <span></span>
      <i class="bn-con" v-html="(labels || [])[oi] || option"></i>
    </label>
  </li>
  <li class="other" v-if="hasOther">
    <label :class="{checked: isOther}">
      <input :disabled="readonly" type="checkbox" value="Other" v-model="isOther" v-on:change="updateOther"><span></span>
      Other
    </label>
    <validator :value="other" :reqs="isOther?'required':''">
      <input v-model="actualOther" v-on:input="updateOther" :disabled="!isOther" />
    </validator>
  </li>
  <div class="desc">
    <slot />
  </div>
</ul>
</template>

<script>
export default {
  props: ["value", "options", "other", "hasOther", "readonly", "labels"],
  data() { return {
    actualValue: null,
    actualOther: "",
    isOther: false,
  }},
  watch: {
    value: {
      immediate: true,
      handler() {
        this.actualValue=this.value || [];
      }
    },
    other: {
      immediate: true,
      handler() {
        this.actualOther=this.other;
        this.isOther=!!this.other;
      }
    },
  },
  methods: {
    update() {
      this.$emit("input", this.actualValue);
    },
    updateOther() {
      this.$emit("update:other", this.isOther?this.actualOther:"");
    },
  },
}
</script>
