<template>
<div>
  <div class="content-table-container file-list-container">
    <table class="file-list content-table">
      <tbody>
        <tr v-if="false">
          <td>Completed Signup Form</td>
          <td></td>
          <td><a :href="appPdfUrl" target="_blank">{{ item.appId }} - {{ item.name }}</a></td>
        </tr>
        <tr v-for="(signedDoc, si) in signedApplications" v-if="si==signedApplications.length-1">
          <td>Signed Signup Form</td>
          <td class="partner-boarding-doc-actions">
            <button v-on:click.prevent="uploadSignup">Browse</button>
<!--             <input type="file" v-on:change="uploadSignup"> -->
          </td>
          <td>
            <a target="_blank" :href="fileLink(signedDoc.filename, signedDoc.id)" style="display: block"><!--{{ item.applicationId }}--> {{ item.name }} signed signup form</a>
          </td>
        </tr>
        <tr v-if="!signedApplications.length">
          <td>Signed Signup Form</td>
          <td>
            <button v-on:click.prevent="uploadSignup">Browse</button>
<!--             <div class="file-upload"><input type="file" ref="signupform" v-on:change="uploadSignup"></div> -->
          </td>
          <td>No file chosen</td>
        </tr>
        <tr>
          <td>Create Agreement</td>
          <td class="partner-boarding-doc-actions"><button v-on:click.prevent="startAgreement">Start</button></td>
          <td v-if="agreements.length">
            <a :href="agreementPath(agreements[0])" target="_blank">{{ item.name }} agreement</a>
          </td>
          <td v-else>No agreement created</td>
        </tr>
        <tr>
          <td>Signed Agreement</td>
          <td class="partner-boarding-doc-actions">
            <button v-on:click.prevent="uploadAgreement">Browse</button>
<!--             <div class="file-upload"><input type="file" ref="agreementform" v-on:change="uploadAgreement"></div> -->
          </td>
          <td v-if="agreements.length && agreements[0].filename">
            <a :href="fileLink(agreements[0].filename)" target="_blank">{{ item.name }} signed agreement</a>
          </td>
          <td v-else>No file chosen</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="actions">
    <button v-on:click.prevent="goBack">Back</button>
    <button v-on:click.prevent="doNext">Next</button>
  </div>
</div>
</template>

<script>
export default {
  props: ["item"],
  data: ()=>({
    agreements: [],
  }),
  computed: {
    appPdfUrl() {
      return location.href+"/pdf";
    },
    signedPdfUrl() {
      if (!this.documents.signedApplication)
        return "";
      return this.fileLink(this.documents.signedApplication.filename);
    },
    signedApplications() {
      var i=0;
      var result=[];
      var docs={};
      for(var j in this.item.documents) {
        docs[this.item.documents[j].type]=this.item.documents[j];
      }
      while(docs['signedApplication'+(i?i:"")]) {
        result.push(docs['signedApplication'+(i?i:"")]);
        i++;
      }
      return result;
    },
    signedApplicationField() {
      var i=this.signedApplications.length;
      return "signedApplication"+(i?i:"");
    },
  },
  methods: {
    fileLink(fileName, docId) {
      if (!fileName)
        return null;
      if (docId)
        return "download/ApplicationDocument/"+docId;
      return "files/application/"+this.item.id+"/"+fileName;
    },
    uploadFile(field=null) {
      if (field)
        this.selectedFile=field;
      var that=this;
      var input=$("<input />", {type: "file"});
      input.on("change", async function(e) {
        var file=this.files[0];
        startLoading();
        if (!window.validateFileExt(file))
          return stopLoading("Invalid file extension", "error");
        var id=(await api.create("ApplicationDocument", {appId: that.item.id, type: that.selectedFile, created: "CURRENT_TIMESTAMP"})).id;
        if (id) {
          await api.upload("ApplicationDocument", id, file);
          that.item.documents=(await api.get("Application", that.item.id)).item.documents;
        }

        stopLoading("File uploaded");

      });
      input.click();
    },
    goBack() {
      this.$emit("back");
    },
    doNext() {
      if (!this.agreements.length || !this.agreements[0].filename)
        return false;
      this.$emit("next");
    },
    async listAgreements() {
      this.agreements=(await api.list("Agreement", {applicationId: this.item.id}, ["id:DESC"])).items || [];
    },
    agreementPath(agreement) {
      return `applications/agreement/${agreement.id}/pdf`;
    },
    startAgreement() {
      agreementPopup.show(this.item);
      agreementPopup.returnLink=location.href.replace(/#.*/, "")+"#boarding/documents";
      agreementPopup.returnCb=()=>this.listAgreements();
    },
    uploadSignup() {
      var f=$("<input type=\"file\">");
      f.on("change", async ($event)=>{
        var file=$event.target.files[0];
        startLoading();
        if (!window.validateFileExt(file))
          return stopLoading("Invalid file extension", "error");
        var id=(await api.create("ApplicationDocument", {appId: this.item.id, type: this.signedApplicationField, created: "CURRENT_TIMESTAMP"})).id;
        if (id) {
          await api.upload("ApplicationDocument", id, file);
          this.item.documents=(await api.get("Application", this.item.id)).item.documents;
        }
        stopLoading("File uploaded");
      });
      f.click();
    },
    uploadAgreement($event) {
      var f=$("<input type=\"file\">");
      f.on("change", async ($event)=>{
        var agreement=this.agreements[0];
        var file=$event.target.files[0];
        startLoading();
        if (!window.validateFileExt(file))
          return stopLoading("Invalid file extension", "error");
        await api.update("Agreement", agreement.id, {signed: "CURRENT_TIMESTAMP"});
        await api.upload("Agreement", agreement.id, file);
        this.$set(this.agreements, 0, (await api.get("Agreement", agreement.id)).item);
        stopLoading("File uploaded");
      });
      f.click();
    },
  },
  mounted() {
    this.listAgreements();
  },
  mixins: [vueMixins.dateFormatter],
}
</script>
<style scoped>
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  margin-right: 8px;
  color: black;
  font-size: 16px;
  display: block;
  padding: 6px 20px;
  box-sizing: border-box;
  border: 1px solid #b3b3b3;
  border-radius: 6px;
  background: #e4e4e4;
  background: linear-gradient(to bottom, #e4e4e4 0%, #cfcfcf 100%);
}
button:hover, button:focus {
  background: #e9f2ff;
  background: linear-gradient(to bottom, #e9f2ff 0%, #deebff 100%);
  border-color: #6192fc;
  color: #6192fc;
}
</style>
