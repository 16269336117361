<template>
<section>
  <ul class="appview-list">
    <li>
      <label>Title</label>
      <div class="validator">
        <validator v-if="editMode" :value="form.title" reqs="required">
          <input type="text" v-model="form.title">
        </validator>
        <span v-else>{{ employee.title }}</span>
      </div>
    </li>
    <li>
      <label>Role</label>
      <div class="validator">
        <validator v-if="editMode" :value="form.role" reqs="required">
          <select v-model="form.role">
            <option v-for="group in titleGroups" :key="group" :value="group">{{ groups[group] }}</option>
          </select>
        </validator>
        <span v-else>{{ userGroup(employee.role) }}</span>
      </div>
    </li>
    <li>
      <label>First Name</label>
      <div class="validator">
        <validator v-if="editMode" :value="form.first_name" reqs="required">
          <input type="text" v-model="form.first_name" @keypress="allowLetterNumber($event, { numbers: false })">
        </validator>
        <span v-else>{{ employee.first_name }}</span>
      </div>
    </li>
    <li>
      <label>Last Name</label>
      <div class="validator">
        <validator v-if="editMode" :value="form.last_name" reqs="required">
          <input type="text" v-model="form.last_name" @keypress="allowLetterNumber($event, { numbers: false })">
        </validator>
        <span v-else>{{ employee.last_name }}</span>
      </div>
    </li>
    <li v-if="false">
      <label>Emp ID</label>
      <div class="validator">
        <span>{{ employee.employeeId }}</span>
      </div>
    </li>
    <li v-if="false">
      <label>Employee Solution</label>
      <div class="validator">
        <validator v-if="editMode" :value="form.companyId" reqs="required">
          <select v-model="form.companyId">
            <option :value="company.id" v-for="company in companies">{{ company.nameSolution }}</option>
          </select>
        </validator>
        <span v-else>{{ employee.companyId.nameSolution }}</span>
      </div>
    </li>
    <li v-if="false">
      <label>Company Email</label>
      <div class="validator">
        <span>{{ employee.companyId.emailSuffix }}</span>
      </div>
    </li>
    <li>
      <label>Email</label>
      <div class="validator">
        <span>{{ employee.email }}</span>
      </div>
    </li>
    <li>
      <label>Phone</label>
      <div class="validator">
        <validator v-if="editMode" :value="form.phone" reqs="required">
          <input type="text" v-model="form.phone" class="w100">
        </validator>
        <span v-else>{{ employee.phone }}</span>
      </div>
    </li>
    <li>
      <label>Ext</label>
      <div class="validator">
        <validator v-if="editMode" :value="form.phone_ext" reqs="">
          <input type="text" v-model="form.phone_ext" maxlength="5">
        </validator>
        <span v-else>{{ employee.phone_ext }}</span>
      </div>
    </li>
  </ul>
  <portal to="actions">
    <ul>
      <li>
        <a href="" v-on:click.prevent="$emit('update:editMode', true)">Edit</a>
      </li>
    </ul>
  </portal>
</section>
</template>

<script>
export default {
  mixins: [ vueMixins.helpers, vueMixins.dateFormatter ],

  props: {
    employee: {
      type: Object,
      required: true
    },

    value: {
      type: Object,
      required: true
    },

    editMode: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      companies: [],
      groups: window.userGroups,
      titleGroups: window.employeeRoles,
      salesBrand: []
    }
  },

  computed: {
    form: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    async loadCompanies() {
      this.companies=(await api.list("Solution", {solutionType: "Employees"})).items;
    },
  },

  created () {
    this.loadCompanies();
    api.list("SalesBrand").then((data) => this.salesBrand = data.items)
  }
}
</script>
