<template>
<view-profile :header="['Merchant Detail']">
  <template v-slot:tabsmain v-if="item">
    <ul>
      <li v-for="(tabData, tabId) in tabs" :class="{active: tab==tabId}" v-if="!tabData.act || merchant">
        <a href="" v-on:click.prevent="tab=tabId, editMode=false">{{ tabData.label }}</a>
      </li>
    </ul>
    <div class="status" v-if="item && statusDescription">
      <label><strong>{{ item.businessProfile.profileNumber || item.id }}</strong></label>
      <label><strong>{{ item.companyName }}</strong></label>
      <label><strong>{{ merchant?merchant.status:item.status }} - {{ statusDescription }}</strong></label>
    </div>
    <div class="status2" v-if="item">
      <label>Account Reviews: <strong>{{ accountReview }} {{ accountReview!=1?'Days':'Day' }}</strong></label>
      <label v-if="item.status=='A02' || item.status=='A03'" :class="agoClass(item.submitDate, now)">
        Approval counter
        <strong>{{ agoTimer(item.submitDate, now) }}</strong>
      </label>
      <label v-else-if="item.status=='A04'" :class="agoClass(item.submitDate, item.approveDate)">
        Approval counter
        <strong>{{ agoTimer(item.submitDate, item.approveDate) }}</strong>
      </label>
      <label v-if="item.status=='A04' && (!merchant || merchant.status=='M00')"  :class="agoClass(item.approveDate || item.submitDate, now)">
        Approval counter
        <strong>{{ agoTimer(item.approveDate || item.submitDate, now) }}</strong>
      </label>
      <label v-else>
        Approval counter
        <strong>00:00:00</strong>
      </label>
    </div>
  </template>
  <template v-slot:tabssub v-for="subTabs in [sTabs[tab]]">
    <ul>
      <li v-for="(tabData, tabId) in subTabs" :class="{active: subTab==tabId}">
        <a href="" v-on:click.prevent="subTab=tabId, editMode=false">{{ tabData.label }}</a>
      </li>
    </ul>
  </template>

  <template v-slot:actions>
    <PortalTarget name="actions"></PortalTarget>
  </template>

  <div class="merchant-panel">
  <div class="data-content" v-if="item">
    <formview-app-main :item="item" :merchant="merchant" :tab="formTabs[subTab]" :notes="notes" :messagesApi="messagesApi" :editMode.sync="editMode" :lastAgreement="lastAgreement" v-if="formTabs[subTab]!==undefined" :edit="sTabs[tab][subTab].edit" />

    <form class="preq-form" v-if="subTab=='notes'">
      <notes class="appl-notes" :notes="notes" type="Application" :id="item.id" ref="notes" />
    </form>
    <merchant-documents :item="item" v-if="subTab=='documents'" ref="documents"  v-on:reloadnotes="loadNotes" />
    <merchant-emails :item="item" v-else-if="subTab=='emails'" ref="emails" />
    <merchant-accounts :item="item" v-else-if="subTab=='accounts'" ref="accounts" />

    <merchant-chk-website :item="item" :merchant="merchant" v-if="subTab=='wscheck'" ref="wscheck" />
    <merchant-chk-pre :item="item" :merchant="merchant" v-if="subTab=='precheck'" ref="precheck" />
    <merchant-chk-g2 :item="item" :merchant="merchant" v-if="subTab=='g2check'" ref="g2check" />
    <merchant-chk-acuant :item="item" :merchant="merchant" v-if="subTab=='idmcheck'" ref="idmcheck" />
    <view-app-risk :item2="item" v-if="subTab=='risk'" ref="risk" />
    <view-app-intake :item="item" v-if="subTab=='merchantsubmission'" ref="submission" />
    <merchant-profile-activity :item="item" :editMode.sync="editMode" v-if="subTab=='agents' || subTab=='activity'" />
    <merchant-profile-users :item="item" :editMode.sync="editMode" v-if="subTab=='users'" />

    <app-underwriting-popup :inline="item" v-if="subTab=='underwriting'" />
    <merchant-boarding :item="item" :merchant="merchant" v-if="subTab=='boarding'" />

  </div>
  </div>

</view-profile>
</template>

<script>
export default {
  props: ["applicationId", "merchantId", "negativeListId"],
  data: ()=>({
    tab: "general",
    subTab: "general",

    tabs: {
      boarding: {label: "Boarding", def: "boarding"},
      general: {label: "General", def: "general"},
      merchant: {label: "Merchant", def: "company"},
      documents: {label: "Documents", def: "documents"},
      accounts: {label: "Accounts", def: "accounts"},
      compliance: {label: "Compliance", def: "status"},
      notes: {label: "Notes", def: "notes"},
      emails: {label: "Emails", def: "emails"},
      access: {label: "Access", def: "users"},
      profile: {label: "Settings", def: "settings"},
    },
    sTabs: {
      boarding: {
        boarding: {label: "Onboarding"},
        underwriting: {label: 'Underwriting'},
        precheck: {label: 'Pre-check'},
      },
      general: {
        general: {label: 'Profile', edit: true},
      },
      merchant: {
        company: {label: 'Company', edit: true},
        directors: {label: 'Directors', edit: true},
        banking: {label: 'Banking', edit: true},
        contacts: {label: 'Contacts', edit: true},
      },
      documents: {
        documents: {label: 'Documents'},
      },
      accounts: {
        accounts: {label: 'Accounts'}
      },
      emails: {
        emails: {label: 'Emails'},
      },
      notes: {
        notes: {label: 'Notes'},
      },
      compliance: {
        status: {label: 'Status'},
        wscheck: {label: 'Website Check'},
        g2check: {label: 'G2'},
        idmcheck: {label: 'Scans'},
        merchantsubmission: {label: 'Merchant Submission'},
        risk: {label: 'Risk Exposure Calculator'},
      },
      access: {
        users: {label: "Users"},
        activity: {label: "Activity"},
      },
      profile: {
        settings: {label: "Profile Settings"},
      },
    },
    formTabs: {
      general: 0,
      company: 1,
      directors: 2,
      business: 333,
      banking: 4,
      contacts: 5,
      csettings: 16,
      settings: 17,
      status: 999,
      schedulea: 123,


// dataTab=7 Account
// dataTab=8 Methods
// dataTab=9 Modules
// dataTab=10 Options
// dataTab=11 Equipment
// dataTab=16 Compliance
// dataTab=13 Financial
// dataTab=14 Processing
// dataTab=15 Accounting
// dataTab=17 Access

    },
    editMode: false,
    newFile: false,
    item: null,
    merchant: null,
    messagesApi: null,
    notes: [],
    form: {},
    agreements: [],
    webList: [],
    precheckList: [],
    agents: [],
    setups: [],
    isWsCheck: false,
    isPreCheck: false,
    isSubmission: false,
    isRisk: false,
    allActions: appData.allActions,
    approvalList: [],allActions: appData.allActions,
    canUploadDoc: false,
    documents: [],
    now: 0,
    statusDescription: null
  }),
  watch: {
    tab() {
      this.subTab=this.tabs[this.tab].def;
      this.isPreCheck=false;
      this.isWsCheck=false;
      this.isSubmission=false;
      this.isRisk=false;
    },
    subTab() {
      this.isPreCheck=false;
      this.isWsCheck=false;
      this.isSubmission=false;
      this.isRisk=false;
      if (this.subTab=='wscheck') {
        setTimeout(()=>this.isWsCheck=true, 60);
      }
      if (this.subTab=='precheck') {
        setTimeout(()=>this.isPreCheck=true, 60);
      }
      if (this.subTab=='merchantsubmission') {
        setTimeout(()=>this.isSubmission=true, 60);
      }
      if (this.subTab=='risk') {
        setTimeout(()=>this.isRisk=true, 60);
      }
    },
  },
  computed: {
    lastAgreement() {
      return this.agreements && this.agreements[0] || {};
    },
    lastSetup() {
      return this.setups[0];
    },
    emailDisabled() {
      return {
        Agreement: this.agreements.length==0,
        Declined: false,
        Closed: this.item.status!="A07",
      };
    },
    signedApplications() {
      var i=0;
      var result=[];
      var docs={};
      for(var j in this.item.documents) {
        docs[this.item.documents[j].type]=this.item.documents[j];
      }
      while(docs['signedApplication'+(i?i:"")]) {
        result.push(docs['signedApplication'+(i?i:"")]);
        i++;
      }
      return result;
    },
    accountReview() {
      if (this.merchant && this.merchant.reviewDate) {
        var p=moment(this.merchant.reviewDate);
        p.add(serverDiff, "seconds");
        var diff=moment.duration(p.diff(moment())).asDays();
        if (diff<0)
          return "0";
        return Math.floor(diff);
      }
      return "---";
    },
  },
  methods: {
    goToTab(tab, subTab) {
      this.tab=tab;
      setTimeout(()=>this.subTab=subTab, 30);
    },
    isDisabledAction(action) {
      if (!this.item)
        return true;
      var current=this.item.status;
      if (action=="FUP")
        return this.item.followup*1;
      if (action=="NFU")
        return !(this.item.followup*1);
      if (action=="NEG")
        return !!this.negativeList;
      if (action=="RNE")
        return !this.negativeList;

      var enabledMatrix=appData.actions;
      return !(enabledMatrix[current] || [])[action];
    },
    doAction: async function(targetMethod) {
      var item=this.item;
      if (targetMethod=="A03") {
//         var error="";
        var classId=item.classId || item.class && item.class.id;
        if (!classId)
          return appErrors.show("Cannot proceed", "Account class is not assigned");
        var managerId=item.managerId || item.manager && item.manager.id;
        if (!item.managerId)
          return appErrors.show("Cannot proceed", "Account Manager is not assigned");
        var sourceAgentId=item.sourceId || item.source && item.source.id;
        if (!sourceAgentId)
          return appErrors.show("Cannot proceed", "Source Agent is missing");
        if (!this.agent(sourceAgentId))
          return appErrors.show("Cannot proceed", "Source Agent is missing");
        if (!(await api.list("Intake", {appId: this.item.id})).items.length)
          return appErrors.show("Cannot proceed", "Merchant profile form is not submitted");
        if (!item.mcc)
          return appErrors.show("Cannot proceed", "Mcc code is not selected");
        if (!item.accountType)
          return appErrors.show("Cannot proceed", "Account type is not set");
//         if (!item.merchantType)
//           return appErrors.show("Cannot proceed", "Merchant type is not set");
        if (!item.language)
          return appErrors.show("Cannot proceed", "Language is not set");
      }
      if (targetMethod=="A04") {
        this.showFinalApproval=true;
        this.listFinalChecks();
        return;
      }
      if (targetMethod=="A05") {
        await this.$refs.emails && this.$refs.emails.sendEmail("Declined");
      }
      startLoading();
      if (targetMethod=="negative") {
        var id=(await api.create("NegativeList", {application: item.id})).id;
        stopLoading("Added to negative list");
        this.negativeList=(await api.get("NegativeList", id)).item;
      } else if (targetMethod=="removeNeg") {
        await api.delete("NegativeList", this.negativeList.id);
        stopLoading("Removed from negative list");
        this.negativeList=null;
      } else if (targetMethod=="followUp") {
        await api.update("Application", item.id, {followup: "CURRENT_TIMESTAMP"});
        item.followup=true;
        stopLoading("Added to follow up");
      } else if (targetMethod=="removeFup") {
        await api.update("Application", item.id, {followup: null});
        item.followup=null;
        stopLoading("Removed from follow up");
      } else {
        if (targetMethod=="A05" && item.status=="A04") {
          if (!confirm("Are you sure you want to delete all related merchant data?")) {
            stopLoading();
            return;
          }
          await this.deleteRelatedMerchant(item);
        }
        await api.update("Application", item.id, {status: targetMethod});
        item.status=targetMethod;
        stopLoading("Status updated");
        if (targetMethod=="A05") {
          location.replace("/applications/"+item.id);
        }
      }
    },
    createNote() {
      if (this.$refs.notes)
        this.$refs.notes.createNote();
    },
    async loadNotes () {
      const filter = {
        type: 'Application',
        parentId: this.item.id
      }
      this.notes = (await api.list("Note", filter, ['id:ASC'])).items || [];
    },
    async loadMerchant() {
      var result;
      this.item = (await api.get('Application', this.applicationId)).item;
      result = (await api.list('Merchant', {appId: this.applicationId})).items;
      if(result.length > 0) {
        this.merchant = result[0];
      }

      if(this.merchant) {
        var status = (await api.list('Status', { status: this.merchant.status})).items
      }else{
        var status = (await api.list('Status', { status: this.item.status})).items
      }
      this.statusDescription = status[0].description
      
      result = (await api.list('NegativeList', {appId: this.applicationId})).items;
      if(result.length > 0) {
        this.negativeList = result[0];
      }
      
      for(var j in this.item.documents) {
        this.documents[this.item.documents[j].type]=this.item.documents[j];
      }

      var filter = {
        type: 'Application',
        parentId: this.item.id
      }
      this.notes = (await api.list("Note", filter, ['id:ASC'])).items || [];

      this.messagesApi = (await api.list("MessageApi", filter, ['id:ASC'])).items || [];

      if (window.appData.actions)
        this.actions=JSON.parse(JSON.stringify(window.appData.actions));
      if (window.appData.allActions)
        this.allActions=JSON.parse(JSON.stringify(window.appData.allActions));
      
      this.listAgreements();
    },
    listAgreements: async function() {
      this.agreements=(await api.list("Agreement", {applicationId: this.item.id}, ["id:DESC"])).items || [];
    },
    listWebsiteChecks: async function() {
      this.webList=(await api.list("WebsiteCheck", {agentId: this.item.id}, ["id:DESC"])).items || [];
    },
    listSetups: async function() {
      this.setups=(await api.list("PartnerSetup", {agentId: this.item.id}, ["id:DESC"])).items || [];
    },
    uploadSigned(agreement, index) {
      var that=this;
      var input=$("<input />", {type: "file"});
      input.on("change", async function(e) {
        var file=this.files[0];
        startLoading();
        if (!window.validateFileExt(file))
          return stopLoading("Invalid file extension", "error");
        await api.update("PartnerAgreement", agreement.id, {signed: "CURRENT_TIMESTAMP"});
        await api.upload("PartnerAgreement", agreement.id, file);
        that.$set(that.agreements, index, (await api.get("PartnerAgreement", agreement.id)).item);
        stopLoading("File uploaded");
      });
      input.click();
    },
    uploadFile(field=null) {
      if (field)
        this.selectedFile=field;
      var that=this;
      var input=$("<input />", {type: "file"});
      input.on("change", async function(e) {
        var file=this.files[0];
        startLoading();
        if (!window.validateFileExt(file))
          return stopLoading("Invalid file extension", "error");
        var id=(await api.create("PartnerDocument", {agentId: that.item.agentDb.id, type: that.selectedFile, created: "CURRENT_TIMESTAMP"})).id;
        if (id) {
          await api.upload("PartnerDocument", id, file);
          that.item.agentDb.documents=(await api.get("Partner", that.item.agentDb.id)).item.documents;
        }

        stopLoading("File uploaded");

      });
      input.click();
    },
    async listFinalChecks() {
      this.approvalList=(await api.list("PartnerApprovalCheck", {agentDbId: this.item.id}, ["id:DESC"])).items || [];
//       this.subTab='finalcheck';
    },
    agreementPath(agreement) {
      return `applications/agreement/${agreement.id}/pdf`
    },
    fileLink(fileName, docId) {
      if (!fileName)
        return null;
      if (docId)
        return "download/ApplicationDocument/"+docId;
      return "files/application/"+this.item.id+"/"+fileName;
    },
    fileLinkCustom(fileName, docId) {
      if (!fileName)
        return null;
      if (docId)
        return "download/ApplicationCustomDocument/"+docId;
      return "files/application/"+this.item.id+"/"+fileName;
    },
  },
  async created() {
    this.loadMerchant();
//     if (location.hash.indexOf("#boarding/")==0) {
//       this.tab="boarding";
//       this.subTab="boarding";
//     }
  },
  mixins: [vueMixins.dateFormatter],
}
</script>
