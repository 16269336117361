<template>
<div :class="{grbackground: tab>0}">
  <div class="status" v-if="tab==0">
    <em><i>Account Reviews</i>{{ accountReview }} {{ accountReview!=1?'Days':'Day' }}</em>
    <label>Current Status: <strong>{{ computedStatus }} ({{ statusDesc(computedStatus) }})</strong></label>
    <span v-if="item.status=='A02' || item.status=='A03'" :class="agoClass(item.submitDate, now)">
      {{ agoTimer(item.submitDate, now) }}
    </span>
    <span v-else-if="item.status=='A04'" :class="agoClass(item.submitDate, item.approveDate)">
      {{ agoTimer(item.submitDate, item.approveDate) }}
    </span>
    <span v-if="item.status=='A04' && (!merchant || merchant.status=='M00')"  :class="agoClass(item.approveDate || item.submitDate, now)">
      {{ agoTimer(item.approveDate || item.submitDate, now) }}
    </span>
    <span v-else>
      00:00:00
    </span>
  </div>
  <form class="preq-form preq-view" v-if="tab==0">
    <section>
          <ul class="appview-list">
            <li>
              <label>Created</label>
              <div class="validator">
                <span>{{ formatDate(item.createdDate, 'YYYY-MM-DD') }}</span>
              </div>
            </li>
            <li>
              <label>Solution</label>
              <div class="validator">
                <dyn-select v-if="editMode" :options="solutions" v-model="item.solution"></dyn-select>
                <span v-else>{{ solution(item.solution) }}</span>
              </div>
            </li>
            <li>
              <label>Profile</label>
              <div class="validator">
                <dyn-select v-if="editMode" :options="managersToShow" v-model="item.managerId"></dyn-select>
                <span v-else>{{ item.manager && (item.manager.username || item.manager.id) }}</span>
              </div>
            </li>
            <li>
              <label>Merchant Type</label>
              <div class="validator">
                <dyn-select v-if="editMode" :options="merchantTypes" v-model="item.merchantType"></dyn-select>
                <span v-else>{{ merchantType(item.merchantType) }}</span>
              </div>
            </li>
            <li>
              <label>Industry</label>
              <div class="validator">
                <dyn-select v-if="editMode" :options="industries" v-model="item.industry"></dyn-select>
                <span v-else>{{ item.industry }}</span>
              </div>
            </li>
            <li>
              <label>Partner</label>
              <div class="validator">
                <dyn-select v-if="editMode" :options="agentsToShow" v-model="item.sourceId"></dyn-select>
                <span v-else>{{ item.source && (item.source.agentId || "internal") || "internal" }}</span>
              </div>
            </li>
            <li>
              <label>Referrer</label>
              <div class="validator">
                <dyn-select v-if="editMode" :options="referrersToShow" v-model="item.referrerId"></dyn-select>
                <span v-else>{{ item.referrer && (item.source.referrerId || item.referrer.id) }}</span>
              </div>
            </li>
            <li>
              <label>Approval Date</label>
              <div class="validator">
                <span>{{ formatDate(item.approveDate, 'YYYY-MM-DD') }}</span>
              </div>
            </li>
            <li>
              <label>Closed Date</label>
              <div class="validator">
                <span>{{ formatDate(item.closedDate, 'YYYY-MM-DD') }}</span>
              </div>
            </li>
          </ul>
    </section>
    <div class="form-actions" v-if="editMode">
      <button class="btn btn-large" v-on:click.prevent="saveItem">Save</button>
    </div>
  </form>
  <form class="preq-form" v-else-if="tab==1">
    <section class="border">
      <div class="light-grey">
        <div class="leftrightcontent">
          <label>New Business</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.companyNew==1?'Yes':'No' }}</span>
            <formel-toggle v-else v-model="item.companyNew" />
          </div>
        </div>
        <div class="leftrightcontent">
          <label>Has parent</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.hasParent==1?'Yes':'No' }}</span>
            <formel-toggle v-else v-model="item.hasParent" />
          </div>
        </div>
      </div>

      <ul class="appview-list">
        <li class="title">
          <div class="validator">
            <span>Applying Company</span>
          </div>
        </li>
      </ul>
      <ul class="appview-list column6">
        <li>
          <label>Legal name</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.companyName }}</span>
            <input v-else type="text" v-model="item.companyName" />
          </div>
          <div class="validator" v-if="false">
            <span v-if="!editMode">{{ item.parentName }}</span>
            <input v-else type="text" v-model="item.parentName" />
          </div>
        </li>
        <li>
          <label>DBA</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.companyNameTrade }}</span>
            <input v-else type="text" v-model="item.companyNameTrade" />
          </div>
          <div class="validator" v-if="false">
            <span v-if="!editMode">{{ item.parentNameTrade }}</span>
            <input v-else type="text" v-model="item.parentNameTrade" />
          </div>
        </li>
        <li>
          <label>Type</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.companyType }}</span>
            <input v-else type="text" v-model="item.companyType" />
          </div>
          <div class="validator" v-if="false">
            <span v-if="!editMode">{{ item.parentType }}</span>
            <input v-else type="text" v-model="item.parentType" />
          </div>
        </li>
        <li>
          <label>Registration number</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.companyRegnum }}</span>
            <input v-else type="text" v-model="item.companyRegnum" />
          </div>
          <div class="validator" v-if="false">
            <span v-if="!editMode">{{ item.parentRegnum }}</span>
            <input v-else type="text" v-model="item.parentRegnum" />
          </div>
        </li>
        <li>
          <label>Tax ID</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.companyTaxId }}</span>
            <input v-else type="text" v-model="item.companyTaxId" />
          </div>
          <div class="validator" v-if="false">
            <span v-if="!editMode">{{ item.parentTaxId }}</span>
            <input v-else type="text" v-model="item.parentTaxId" />
          </div>
        </li>
        <li>
          <label>Incorporation date</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.companyDate }}</span>
            <datepicker v-else v-model="item.companyDate" />
          </div>
          <div class="validator" v-if="false">
            <span v-if="!editMode">{{ item.parentDate }}</span>
            <datepicker v-else v-model="item.parentDate" />
          </div>
        </li>
      </ul>
      <ul class="appview-list">
        <li>
          <label>ADDRESS</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.companyAddress }}</span>
            <input v-else type="text" v-model="item.companyAddress" />
          </div>
          <div class="validator" v-if="false">
            <span v-if="!editMode">{{ item.parentAddress }}</span>
            <input v-else type="text" v-model="item.parentAddress" />
          </div>
        </li>
        <li>
          <label>City</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.companyAddressCity }}</span>
            <input v-else type="text" v-model="item.companyAddressCity" />
          </div>
          <div class="validator" v-if="false">
            <span v-if="!editMode">{{ item.parentAddressCity }}</span>
            <input v-else type="text" v-model="item.parentAddressCity" />
          </div>
        </li>
        <li>
          <label>PROVINCE/STATE</label>
          <div class="validator">
            <template v-if="editMode">
              <province-select v-if="item.companyAddressCountry=='CA' || item.companyAddressCountry=='US'" :value="item.companyAddressState" v-model="item.companyAddressState" :country="item.companyAddressCountry"  />
              <input type="text" v-else v-model="item.companyAddressState" />
            </template>
            <span v-else>{{ item.companyAddressState }}</span>
          </div>
        </li>
        <li>
          <label>POSTAL/ZIP CODE</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.companyAddressPostcode }}</span>
            <input v-else type="text" v-model="item.companyAddressPostcode" />
          </div>
          <div class="validator" v-if="false">
            <span v-if="!editMode">{{ item.parentAddressPostcode }}</span>
            <input v-else type="text" v-model="item.parentAddressPostcode" />
          </div>
        </li>
        <li>
          <label>Country</label>
          <div class="validator">
            <dyn-select v-if="editMode" :options="validCountries" v-model="item.companyAddressCountry" />
            <span v-else>{{ item.companyAddressCountry }}</span>
          </div>
        </li>
      </ul>
      <ul class="appview-list">
        <li>
          <label>Company Email</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.companyEmail }}</span>
            <input v-else type="text" v-model="item.companyEmail" />
          </div>
          <div class="validator" v-if="false">
            <span v-if="!editMode">{{ item.parentEmail }}</span>
            <input v-else type="text" v-model="item.parentEmail" />
          </div>
        </li>
        <li>
          <label>Phone</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.companyPhone }}</span>
            <input v-else type="text" v-model="item.companyPhone" />
          </div>
          <div class="validator" v-if="false">
            <span v-if="!editMode">{{ item.parentPhone }}</span>
            <input v-else type="text" v-model="item.parentPhone" />
          </div>
        </li>
        <li>
          <label>WEBSITE</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.companyFax }}</span>
            <input v-else type="text" v-model="item.companyFax" />
          </div>
          <div class="validator" v-if="false">
            <span v-if="!editMode">{{ item.parentFax }}</span>
            <input v-else type="text" v-model="item.parentFax" />
          </div>
        </li>
      </ul>
      <ul class="appview-list column6">
        <li>
          <label>Turnover</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.companyTurnover }} {{ currency(item.companyTurnoverCurrency) }}</span>
            <money v-else :amount.sync="item.companyTurnover" :currency.sync="item.companyTurnoverCurrency" />
          </div>
          <div class="validator" v-if="false">
            <span v-if="!editMode">{{ item.parentTurnover }} {{ currency(item.parentTurnoverCurrency) }}</span>
            <money v-else :amount.sync="item.parentTurnover" :currency.sync="item.parentTurnoverCurrency" />
          </div>
        </li>
        <li>
          <label>Is public</label>
          <div class="validator">
            <mnb-switch  :disabled="!editMode" v-model="item.companyIsPublic" />
          </div>
        </li>
        <li>
          <label>Is non-profit</label>
          <div class="validator">
            <mnb-switch  :disabled="!editMode" v-model="item.companyIsNonProfit" />
          </div>
        </li>
        <li>
          <label>Lawsuits</label>
          <div class="validator">
            <mnb-switch  :disabled="!editMode" v-model="item.companyLawsuit" />
          </div>
        </li>
        <li>
          <label>Declined for processing</label>
          <div class="validator">
            <mnb-switch  :disabled="!editMode" v-model="item.companyDeclined" />
          </div>
        </li>
        <li>
          <label>Terminated for processing</label>
          <div class="validator">
            <mnb-switch  :disabled="!editMode" v-model="item.companyTerminated" />
          </div>
        </li>
      </ul>
      <ul class="appview-list" v-if="item.hasParent==1">
        <li class="title">
<!--           <label></label> -->
          <div class="validator">
            <span>Parent Company</span>
          </div>
        </li>
        <li>
          <label>Registration number</label>

          <div class="validator">
            <span v-if="!editMode">{{ item.parentRegnum }}</span>
            <input v-else type="text" v-model="item.parentRegnum" />
          </div>
        </li>
        <li>
          <label>Legal name</label>

          <div class="validator">
            <span v-if="!editMode">{{ item.parentName }}</span>
            <input v-else type="text" v-model="item.parentName" />
          </div>
        </li>
        <li>
          <label>Trade name</label>
 
          <div class="validator">
            <span v-if="!editMode">{{ item.parentNameTrade }}</span>
            <input v-else type="text" v-model="item.parentNameTrade" />
          </div>
        </li>
        <li>
          <label>Type</label>

          <div class="validator">
            <span v-if="!editMode">{{ item.parentType }}</span>
            <input v-else type="text" v-model="item.parentType" />
          </div>
        </li>
        <li>
          <label>Street</label>

          <div class="validator">
            <span v-if="!editMode">{{ item.parentAddress }}</span>
            <input v-else type="text" v-model="item.parentAddress" />
          </div>
        </li>
        <li>
          <label>Number</label>

          <div class="validator">
            <span v-if="!editMode">{{ item.parentAddressNum }}</span>
            <input v-else type="text" v-model="item.parentAddressNum" />
          </div>
        </li>
        <li>
          <label>Postcode</label>

          <div class="validator">
            <span v-if="!editMode">{{ item.parentAddressPostcode }}</span>
            <input v-else type="text" v-model="item.parentAddressPostcode" />
          </div>
        </li>
        <li>
          <label>City</label>

          <div class="validator">
            <span v-if="!editMode">{{ item.parentAddressCity }}</span>
            <input v-else type="text" v-model="item.parentAddressCity" />
          </div>
        </li>
        <li>
          <label>State</label>

          <div class="validator">
            <span v-if="!editMode">{{ item.parentAddressState }}</span>
            <input v-else type="text" v-model="item.parentAddressState" />
          </div>
        </li>
        <li>
          <label>Country</label>

          <div class="validator">
            <span v-if="!editMode">{{ item.parentAddressCountry }}</span>
            <input v-else type="text" v-model="item.parentAddressCountry" />
          </div>
        </li>
        <li>
          <label>Phone</label>

          <div class="validator">
            <span v-if="!editMode">{{ item.parentPhone }}</span>
            <input v-else type="text" v-model="item.parentPhone" />
          </div>
        </li>
        <li>
          <label>Fax</label>

          <div class="validator">
            <span v-if="!editMode">{{ item.parentFax }}</span>
            <input v-else type="text" v-model="item.parentFax" />
          </div>
        </li>
        <li>
          <label>Company Email</label>

          <div class="validator">
            <span v-if="!editMode">{{ item.parentEmail }}</span>
            <input v-else type="text" v-model="item.parentEmail" />
          </div>
        </li>
        <li>
          <label>Turnover</label>

          <div class="validator">
            <span v-if="!editMode">{{ item.parentTurnover }} {{ currency(item.parentTurnoverCurrency) }}</span>
            <money v-else :amount.sync="item.parentTurnover" :currency.sync="item.parentTurnoverCurrency" />
          </div>
        </li>
        <li>
          <label>Incorporation date</label>

          <div class="validator">
            <span v-if="!editMode">{{ item.parentDate }}</span>
            <datepicker v-else v-model="item.parentDate" />
          </div>
        </li>
        <li>
          <label>Tax ID</label>
 
          <div class="validator">
            <span v-if="!editMode">{{ item.parentTaxId }}</span>
            <input v-else type="text" v-model="item.parentTaxId" />
          </div>
        </li>
        <li>
          <label>Is public</label>
 
          <div class="validator">
            <span v-if="!editMode">{{ item.parentIsPublic==1?'Yes':'No' }}</span>
            <formel-toggle v-else v-model="item.parentIsPublic" />
          </div>
        </li>
        <li>
          <label>Is non-profit</label>

          <div class="validator">
            <span v-if="!editMode">{{ item.parentIsNonProfit==1?'Yes':'No' }}</span>
            <formel-toggle v-else v-model="item.parentIsNonProfit" />
          </div>
        </li>
        <li>
          <label>Lawsuits</label>

          <div class="validator">
            <span v-if="!editMode">{{ item.parentLawsuit==1?'Yes':'No' }}</span>
            <formel-toggle v-else v-model="item.parentLawsuit" />
          </div>
        </li>
        <li>
          <label>Declined for processing</label>
 
          <div class="validator">
            <span v-if="!editMode">{{ item.parentDeclined==1?'Yes':'No' }}</span>
            <formel-toggle v-else v-model="item.parentDeclined" />
          </div>
        </li>
        <li>
          <label>Terminated for processing</label>
 
          <div class="validator">
            <span v-if="!editMode">{{ item.parentTerminated==1?'Yes':'No' }}</span>
            <formel-toggle v-else v-model="item.parentTerminated" />
          </div>
        </li>
      </ul>
    </section>
    <div class="form-actions" v-if="editMode">
      <button class="btn btn-large" v-on:click.prevent="saveItem">Save</button>
    </div>
  </form>
  <form class="preq-form" v-else-if="tab==2">
    <section>
      <ul class="appview-list" v-for="(ubo, ui) in item.directors">
        <li class="title">Director #{{ ui*1+1 }} <a v-if="editMode" class="remove" v-on:click.prevent="item.directors.splice(ui, 1)">&times;</a></li>
        <li>
          <label>First Name</label>
          <div class="validator">
            <span v-if="!editMode">{{ ubo.firstName }}</span>
            <input v-else type="text" v-model="ubo.firstName" />
          </div>
        </li>
        <li>
          <label>Last Name</label>
          <div class="validator">
            <span v-if="!editMode">{{ ubo.lastName }}</span>
            <input v-else type="text" v-model="ubo.lastName" />
          </div>
        </li>
        <li>
          <label>DOB</label>
          <div class="validator">
            <span v-if="!editMode">{{ ubo.dob }}</span>
            <datepicker v-else v-model="ubo.dob" />
          </div>
        </li>
        <li>
          <label>SIN/SSN</label>
          <div class="validator">
            <span v-if="!editMode">{{ ubo.ssn }}</span>
            <input v-else type="text" v-model="ubo.ssn" />
          </div>
        </li>
        <li>
          <label>ID NUMBER</label>
          <div class="validator">
            <span v-if="!editMode">{{ ubo.passportNum }}</span>
            <input v-else type="text" v-model="ubo.passportNum" />
          </div>
        </li>
        <li>
          <label>ADDRESS</label>
          <div class="validator">
            <span v-if="!editMode">{{ ubo.address }}</span>
            <input v-else type="text" v-model="ubo.address" />
          </div>
        </li>
        <li>
          <label>City</label>
          <div class="validator">
            <span v-if="!editMode">{{ ubo.addressCity }}</span>
            <input v-else type="text" v-model="ubo.addressCity" />
          </div>
        </li>
        <li>
          <label>State/Province</label>
          <div class="validator">
            <template v-if="editMode">
              <province-select v-if="ubo.addressCountry=='CA' || ubo.addressCountry=='US'" :value="ubo.addressState" v-model="ubo.addressState" :country="ubo.addressCountry"  />
              <input type="text" v-else v-model="ubo.addressState" />
            </template>
            <span v-else>{{ ubo.addressState }}</span>
          </div>
        </li>
        <li>
          <label>POSTAL/ZIP CODE</label>
          <div class="validator">
            <span v-if="!editMode">{{ ubo.addressPostcode }}</span>
            <input v-else type="text" v-model="ubo.addressPostcode" />
          </div>
        </li>
        <li>
          <label>Country</label>
          <div class="validator">
            <dyn-select v-if="editMode" :options="validContactCountries" v-model="ubo.addressCountry" />
            <span v-else>{{ ubo.addressCountry }}</span>
          </div>
        </li>
        <li>
          <label>Phone</label>
          <div class="validator">
            <span v-if="!editMode">{{ ubo.phone }}</span>
            <input v-else type="text" v-model="ubo.phone" />
          </div>
        </li>
        <li>
          <label>Email</label>
          <div class="validator">
            <span v-if="!editMode">{{ ubo.email }}</span>
            <input v-else type="text" v-model="ubo.email" />
          </div>
        </li>
      </ul>
      <a v-if="editMode && item.directors.length<5" class="new" v-on:click.prevent="item.directors.length<5 && item.directors.push({})">+</a>
      <ul class="appview-list" v-for="(ubo, ui) in item.ubos">
        <li class="title">UBO #{{ ui*1+1 }} <a v-if="editMode" class="remove" v-on:click.prevent="item.directors.splice(ui, 1)">&times;</a></li>
        <li>
          <label>First Name</label>
          <div class="validator">
            <span v-if="!editMode">{{ ubo.firstName }}</span>
            <input v-else type="text" v-model="ubo.firstName" />
          </div>
        </li>
        <li>
          <label>Last Name</label>
          <div class="validator">
            <span v-if="!editMode">{{ ubo.lastName }}</span>
            <input v-else type="text" v-model="ubo.lastName" />
          </div>
        </li>
        <li>
          <label>DOB</label>
          <div class="validator">
            <span v-if="!editMode">{{ ubo.dob }}</span>
            <datepicker v-else v-model="ubo.dob" />
          </div>
        </li>
        <li>
          <label>SIN/SSN</label>
          <div class="validator">
            <span v-if="!editMode">{{ ubo.ssn }}</span>
            <input v-else type="text" v-model="ubo.ssn" />
          </div>
        </li>
        <li>
          <label>ID NUMBER</label>
          <div class="validator">
            <span v-if="!editMode">{{ ubo.passportNum }}</span>
            <input v-else type="text" v-model="ubo.passportNum" />
          </div>
        </li>
        <li>
          <label>ADDRESS</label>
          <div class="validator">
            <span v-if="!editMode">{{ ubo.address }}</span>
            <input v-else type="text" v-model="ubo.address" />
          </div>
        </li>
        <li>
          <label>City</label>
          <div class="validator">
            <span v-if="!editMode">{{ ubo.addressCity }}</span>
            <input v-else type="text" v-model="ubo.addressCity" />
          </div>
        </li>
        <li>
          <label>State/Province</label>
          <div class="validator">
            <template v-if="editMode">
              <province-select v-if="ubo.addressCountry=='CA' || ubo.addressCountry=='US'" :value="ubo.addressState" v-model="ubo.addressState" :country="ubo.addressCountry"  />
              <input type="text" v-else v-model="ubo.addressState" />
            </template>
            <span v-else>{{ ubo.addressState }}</span>
          </div>  
        </li>
        <li>
          <label>POSTAL/ZIP CODE</label>
          <div class="validator">
            <span v-if="!editMode">{{ ubo.addressPostcode }}</span>
            <input v-else type="text" v-model="ubo.addressPostcode" />
          </div>
        </li>
        <li>
          <label>Country</label>
          <div class="validator">
            <dyn-select v-if="editMode" :options="validContactCountries" v-model="ubo.addressCountry" />
            <span v-else>{{ ubo.addressCountry }}</span>
          </div>
        </li>
        <li>
          <label>Phone</label>
          <div class="validator">
            <span v-if="!editMode">{{ ubo.phone }}</span>
            <input v-else type="text" v-model="ubo.phone" />
          </div>
        </li>
        <li>
          <label>Email</label>
          <div class="validator">
            <span v-if="!editMode">{{ ubo.email }}</span>
            <input v-else type="text" v-model="ubo.email" />
          </div>
        </li>
        <li>
          <label>Ownership % </label>
          <div class="validator">
            <span v-if="!editMode">{{ ubo.ownership }}</span>
            <input v-else type="text" v-model="ubo.ownership" />
          </div>
        </li>
      </ul>
      <a v-if="editMode && item.ubos.length<5" class="new" v-on:click.prevent="item.ubos.length<5 && item.ubos.push({})">+</a>
    </section>
    <div class="form-actions" v-if="editMode">
      <button class="btn btn-large" v-on:click.prevent="saveItem">Save</button>
    </div>
  </form>
  <form class="preq-form" v-else-if="tab==3">
    <section>
      <ul class="column-form appview-list">
        <li>
          <label>Description of products</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.businessDescription}}</span>
            <textarea v-else v-model="item.businessDescription"></textarea>
          </div>
        </li>
        <li>
          <label>Time in business</label>
          <div class="validator">
            <span v-if="!editMode">{{ Math.floor(item.businessLength/12) }} years {{ item.businessLength%12 }} months</span>
            <field-businesslength v-else v-model="item.businessLength" />
          </div>
        </li>
        <li>
          <label>Website(s)</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.hasWebsite && !item.website?"No website":item.website }}</span>
            <input v-else type="text" v-model="item.website" />
          </div>
        </li>
        <li>
          <label>Login details</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.websiteUsername }} / {{ item.websitePassword }}</span>
            <div v-else style="display: flex">
              <input type="text" v-model="item.websiteUsername" />
              <input type="text" v-model="item.websitePassword" />
            </div>
          </div>
        </li>
        <li>
          <label>Beta website(s)</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.hasWebsite?"No website":item.websiteBeta }}</span>
            <input v-else type="text" v-model="item.websiteBeta" />
          </div>
        </li>
        <li>
          <label>Descriptor</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.descriptor1 }}</span>
            <input v-else type="text" v-model="item.descriptor1" />
          </div>
        </li>
        <li>
          <label>DBA/City</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.descriptor2 }}</span>
            <input v-else type="text" v-model="item.descriptor2" />
          </div>
        </li>
        <li>
          <label>Dynamic descriptors</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.dynamicDescriptors==1?"Yes":"No" }}</span>
            <formel-toggle v-else v-model="item.dynamicDescriptors" />
          </div>
        </li>
        <li>
          <label>Bankruptcy</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.bankruptcy==1?"Yes":"No" }}</span>
            <formel-toggle v-else v-model="item.bankruptcy" />
          </div>
        </li>
        <li>
          <label>Bankruptcy date</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.bankruptcyDate }}</span>
            <datepicker v-else v-model="item.bankruptcyDate" />
          </div>
        </li>
        <li>
          <label>Current/previous Payment Provider</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.lastProvider }}</span>
            <input v-else type="text" v-model="item.lastProvider" />
          </div>
        </li>
        <li>
          <label>Reason for leaving</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.lastReason }}</span>
            <textarea v-else v-model="item.lastReason"></textarea>
          </div>
        </li>
        <li>
          <label>Accepted cards</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.acceptedCards==1?"Yes":"No" }}</span>
            <formel-toggle v-else v-model="item.acceptedCards" />
          </div>
        </li>
        <li>
          <label>Accepted cards</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.acceptedCardsMonths || 0 }} months</span>
            <input v-else type="text" v-model="item.acceptedCardsMonths" />
          </div>
        </li>
        <li>
          <label>Violation</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.violation==1?"Yes":"No" }}</span>
            <formel-toggle v-else v-model="item.violation" />
          </div>
        </li>
        <li>
          <label>Violation details</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.violationDetails }}</span>
            <textarea v-else type="text" v-model="item.violationDetails"></textarea>
          </div>
        </li>
        <li>
          <label>History currency</label>
          <div class="validator">
            <span v-if="!editMode">{{ currency(item.historyCurrency) }}</span>
            <dyn-select v-else v-model="item.historyCurrency" :options="currencies" />
          </div>
        </li>
      </ul>
      <!--<h2 class="list-heading">History</h2>-->
      <ul class="column-form ext-cform">
        <li class="head">
          <label>History</label>
          <div>
            <div>Last month</div>
            <div>2 months ago</div>
            <div>3 months ago</div>
            <div>4 months ago</div>
            <div>5 months ago</div>
            <div>6 months ago</div>
          </div>
        </li>
      <li>
        <label>Number of transactions</label>
        <div class="field cols">
          <div class="validator" v-for="(ago, i) in item.history" :key="'ccph1x'+i">
            <span v-if="!editMode">{{ ago.tsCount }}</span>
            <input v-else type="text" v-model="ago.tsCount" />
          </div>
        </div>
      </li>
      <li>
        <label>Transaction volume</label>
        <div class="field cols">
          <div class="validator" v-for="(ago, i) in item.history" :key="'ccph1x'+i">
            <span v-if="!editMode">{{ ago.tsVolume }}</span>
            <input v-else type="text" v-model="ago.tsVolume" />
          </div>
        </div>
      </li>
      <li>
        <label>Number of chargebacks</label>
        <div class="field cols">
          <div class="validator" v-for="(ago, i) in item.history" :key="'ccph1x'+i">
            <span v-if="!editMode">{{ ago.cbCount }}</span>
            <input v-else type="text" v-model="ago.cbCount" />
          </div>
        </div>
      </li>
      <li>
        <label>Chargeback volume</label>
        <div class="field cols">
          <div class="validator" v-for="(ago, i) in item.history" :key="'ccph1x'+i">
            <span v-if="!editMode">{{ ago.cbVolume }}</span>
            <input v-else type="text" v-model="ago.cbVolume" />
          </div>
        </div>
      </li>
      <li>
        <label>Number of refunds</label>
        <div class="field cols">
          <div class="validator" v-for="(ago, i) in item.history" :key="'ccph1x'+i">
            <span v-if="!editMode">{{ ago.refundCount }}</span>
            <input v-else type="text" v-model="ago.refundCount" />
          </div>
        </div>
      </li>
      <li>
        <label>Refund volume</label>
        <div class="field cols">
          <div class="validator" v-for="(ago, i) in item.history" :key="'ccph1x'+i">
            <span v-if="!editMode">{{ ago.refundVolume }}</span>
            <input v-else type="text" v-model="ago.refundVolume" />
          </div>
        </div>
      </li>
      </ul>
      <h2 class="list-heading">Finance</h2>
      <ul class="column-form appview-list">
        <li>
          <label>Est monthly sales</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.volume }} {{ currency(item.volumeCurrency) }}</span>
            <money v-else :amount.sync="item.volume" :currency.sync="item.volumeCurrency" />
          </div>
        </li>
        <li>
          <label>Avg transaction</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.avgTransaction }} {{ currency(item.avgTransactionCurrency) }}</span>
            <money v-else :amount.sync="item.avgTransaction" :currency.sync="item.avgTransactionCurrency" />
          </div>
        </li>
        <li>
          <label>Max transaction</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.maxTransaction }} {{ currency(item.maxTransactionCurrency) }}</span>
            <money v-else :amount.sync="item.maxTransaction" :currency.sync="item.maxTransactionCurrency" />
          </div>
        </li>
        <li>
          <label>Transaction currencies</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.transactionCurrencies }}</span>
            <input v-else type="text" v-model="item.transactionCurrencies" />
          </div>
        </li>
        <li>
          <label>Payment Types:</label>
          <div class="validator">
            <span v-if="!editMode">{{ (item.cardTypes || []).join(", ") }}</span>
            <multiselect v-else v-model="item.cardTypes" :other.sync="item.cardTypesOther" hasOther="1" :options="['Visa', 'MasterCard', 'Discover', 'AMEX', 'China Union Pay', 'Apple Pay', 'Google Pay', 'Interac Online', 'ACH/EFT', 'Cryptocurrency']" />
          </div>
        </li>
        <li>
          <label>Origin: Domestic</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.originDomestic }} %</span>
            <input v-else type="text" v-model="item.originDomestic" />
          </div>
        </li>
        <li>
          <label>Origin: Europe</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.originEurope }} %</span>
            <input v-else type="text" v-model="item.originEurope" />
          </div>
        </li>
        <li>
          <label>Origin: USA</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.originUSA }} %</span>
            <input v-else type="text" v-model="item.originUSA" />
          </div>
        </li>
        <li>
          <label>Origin: Asia</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.originAsia }} %</span>
            <input v-else type="text" v-model="item.originAsia" />
          </div>
        </li>
        <li>
          <label>Origin: Rest of world</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.originOther }} %</span>
            <input v-else type="text" v-model="item.originOther" />
          </div>
        </li>
        <li>
          <label>Method: E-Commerce</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.methodECommerce }} %</span>
            <input v-else type="text" v-model="item.methodECommerce" />
          </div>
        </li>
        <li>
          <label>Method: POS</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.methodPOS }} %</span>
            <input v-else type="text" v-model="item.methodPOS" />
          </div>
        </li>
        <li>
          <label>Method: M-POS</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.methodMPOS }} %</span>
            <input v-else type="text" v-model="item.methodMPOS" />
          </div>
        </li>
        <li>
          <label>Method: M-Commerce</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.methodMCommerce }} %</span>
            <input v-else type="text" v-model="item.methodMCommerce" />
          </div>
        </li>
        <li>
          <label>Method: MOTO</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.methodMOTO }} %</span>
            <input v-else type="text" v-model="item.methodMOTO" />
          </div>
        </li>
        <li>
          <label>Method: In-App</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.methodApp }} %</span>
            <input v-else type="text" v-model="item.methodApp" />
          </div>
        </li>
        <li>
          <label>Payment time</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.paymentMoment.join(", ") }}</span>
            <multiselect v-else v-model="item.paymentMoment" :other.sync="item.paymentMomentOther" hasOther="1" :options="['Upon purchase', 'With download', 'On delivery']" />
          </div>
        </li>
        <li>
          <label>Payment frequency</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.frequency.join(", ") }}</span>
            <multiselect v-else v-model="item.frequency" :hasOther="false" :options="['One-time payment', 'Recurring payment (subscription)']" />
          </div>
        </li>
        <li>
          <label>Delivery: Immediately</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.deliveryTime1 }} %</span>
            <input v-else type="text" v-model="item.deliveryTime1" />
          </div>
        </li>
        <li>
          <label>Delivery: &lt;= 4 weeks</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.deliveryTime2 }} %</span>
            <input v-else type="text" v-model="item.deliveryTime2" />
          </div>
        </li>
        <li>
          <label>Delivery: 5-14 weeks</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.deliveryTime3 }} %</span>
            <input v-else type="text" v-model="item.deliveryTime3" />
          </div>
        </li>
        <li>
          <label>Delivery: &gt; 14 weeks</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.deliveryTime4 }} %</span>
            <input v-else type="text" v-model="item.deliveryTime4" />
          </div>
        </li>
        <li>
          <label>Affiliate program</label>
          <div class="validator">
            <span v-if="!editMode"> {{ item.affiliate==1?"Yes":"No" }}</span>
            <formel-toggle v-else v-model="item.affiliate" />
          </div>
        </li>
        <li>
          <label>Affiliate details</label>
          <div class="validator">
            <span v-if="!editMode"> {{ item.affiliateDetails }}</span>
            <input v-else type="text" v-model="item.affiliateDetails" />
          </div>
        </li>
        <li>
          <label>Customer service email</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.emailService }}</span>
            <input v-else type="text" v-model="item.emailService" />
          </div>
        </li>
        <li>
          <label>Customer service phone</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.phoneService }}</span>
            <input v-else type="text" v-model="item.phoneService" />
          </div>
        </li>
        <li>
          <label>Chargeback notification email</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.emailChargeback }}</span>
            <input v-else type="text" v-model="item.emailChargeback" />
          </div>
        </li>
      </ul>
      <h2 class="list-heading">Security measures</h2>
      <ul class="column-form appview-list">
        <li>
          <label>Security measures</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.securityMeasures }}</span>
            <textarea v-else v-model="item.securityMeasures"></textarea>
          </div>
        </li>
        <li>
          <label>3D Secure</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.use3dSecure==1?"Yes":"No" }} {{ item.use3dSecureMPI?"MPI: "+item.use3dSecureMPI:"" }}</span>
            <div v-else>
              <formel-toggle v-model="item.use3dSecure" />
              <input type="text" v-model="item.use3dSecureMPI" />
            </div>
          </div>
        </li>
        <li>
          <label>CVC</label>
          <div class="validator">
            <span v-if="!editMode"> {{ item.useCVC==1?"Yes":"No" }}</span>
            <formel-toggle v-else v-model="item.useCVC" />
          </div>
        </li>
        <li>
          <label>AVS</label>
          <div class="validator">
            <span v-if="!editMode"> {{ item.useAVS==1?"Yes":"No" }}</span>
            <formel-toggle v-else v-model="item.useAVS" />
          </div>
        </li>
      </ul>
      
    </section>
    <div class="form-actions" v-if="editMode">
      <button class="btn btn-large" v-on:click.prevent="saveItem">Save</button>
    </div>
  </form>
  <form class="preq-form" v-else-if="tab==4">
    <section>
      <div v-for="(account, i) in item.accounts">
        <ul class="column-form appview-list">
          <li class="title">Account #{{ i+1 }} <a v-if="editMode" class="remove" v-on:click.prevent="item.accounts.splice(i, 1)">&times;</a></li>
          <li>
            <label>CURRENCY</label>
            <div class="validator">
              <span v-if="!editMode">{{ currency(account.accountCurrency) }}</span>
              <dyn-select v-else v-model="account.accountCurrency" :options="currencies" />
            </div>
          </li>
          <li>
            <label>ACCOUNT NAME</label>
            <div class="validator">
              <span v-if="!editMode">{{ account.accountHolder }}</span>
              <input v-else type="text" v-model="account.accountHolder" />
            </div>
          </li>
          <li>
            <label>ACCOUNT NUMBER</label>
            <div class="validator">
              <span v-if="!editMode">{{ account.accountNumber }}</span>
              <input v-else type="text" v-model="account.accountNumber" />
            </div>
          </li>
          <li>
            <label>Institution Number</label>
            <div class="validator">
              <span v-if="!editMode">{{ account.accountSwift }}</span>
              <input v-else type="text" v-model="account.accountSwift" />
            </div>
          </li>
          <li>
            <label>Branch/Transit Number</label>
            <div class="validator">
              <span v-if="!editMode">{{ account.accountIBAN }}</span>
              <input v-else type="text" v-model="account.accountIBAN" />
            </div>
          </li>
          <li>
            <label>ROUTING NUMBER</label>
            <div class="validator">
              <span v-if="!editMode">{{ account.accountRouting }}</span>
              <input v-else type="text" v-model="account.accountRouting" />
            </div>
          </li>
          <li>
            <label>Bank name</label>
            <div class="validator">
              <span v-if="!editMode">{{ account.bankName }}</span>
              <input v-else type="text" v-model="account.bankName" />
            </div>
          </li>
          <li>
            <label>Bank address</label>
            <div class="validator">
              <span v-if="!editMode">{{ account.bankAddress }}</span>
              <input v-else type="text" v-model="account.bankAddress" />
            </div>
          </li>
          <li>
            <label>POSTAL/ZIP CODE</label>
            <div class="validator">
              <span v-if="!editMode">{{ account.bankAddressPostCode }}</span>
              <input v-else type="text" v-model="account.bankAddressPostCode" />
            </div>
          </li>
          <li>
            <label>City</label>
            <div class="validator">
              <span v-if="!editMode">{{ account.bankAddressCity }}</span>
              <input v-else type="text" v-model="account.bankAddressCity" />
            </div>
          </li>
          <li>
            <label>PROVINCE/STATE</label>
            <div class="validator">
              <template v-if="editMode">
                <province-select v-if="account.bankAddressCountry=='CA' || account.bankAddressCountry=='US'" :value="account.bankAddressState" v-model="account.bankAddressState" :country="account.bankAddressCountry"  />
                <input type="text" v-else v-model="account.bankAddressState" />
              </template>
              <span v-else>{{ account.bankAddressState }}</span>
            </div>
          </li>
          <li>
            <label>Country</label>
            <div class="validator">
              <dyn-select v-if="editMode" :options="validCountries" v-model="account.bankAddressCountry" />
              <span v-else>{{ account.bankAddressCountry }}</span>
            </div>
          </li>
          <li>
            <label>Bank telephone number</label>
            <div class="validator">
              <span v-if="!editMode">{{ account.bankPhone }}</span>
              <input v-else type="text" v-model="account.bankPhone" />
            </div>
          </li>
        </ul>
      </div>
      <a v-if="editMode && item.accounts.length<5" class="new" v-on:click.prevent="item.accounts.length<5 && item.accounts.push({})">+</a>
    </section>
    <div class="form-actions" v-if="editMode">
      <button class="btn btn-large" v-on:click.prevent="saveItem">Save</button>
    </div>
  </form>
  <form class="preq-form" v-else-if="tab==5">
    <section>
      <template v-for="xtype in typesWithContact">
        <h2 class="list-heading" :class="{emph: xtype.emph}" v-html="xtype.title" :key="'chead'+xtype.title"></h2>
        <ul class="column-form appview-list" :key="'clist'+xtype.title">
          <li>
            <label>First name</label>
            <div class="validator">
              <span v-if="!editMode">{{ xtype.contact.firstName }}</span>
              <input v-else type="text" v-model="xtype.contact.firstName" />
            </div>
          </li>
          <li>
            <label>Last name</label>
            <div class="validator">
              <span v-if="!editMode">{{ xtype.contact.lastName }}</span>
              <input v-else type="text" v-model="xtype.contact.lastName" />
            </div>
          </li>
          <li>
            <label>E-mail address</label>
            <div class="validator">
              <span v-if="!editMode">{{ xtype.contact.email }}</span>
              <input v-else type="text" v-model="xtype.contact.email" />
            </div>
          </li>
          <li>
            <label>Telephone number</label>
            <div class="validator">
              <span v-if="!editMode">{{ xtype.contact.phone }}</span>
              <input v-else type="text" v-model="xtype.contact.phone" />
            </div>
          </li>
          <li v-if="xtype.emph">
            <label>Fax number</label>
            <div class="validator">
              <span v-if="!editMode">{{ xtype.contact.fax }}</span>
              <input v-else type="text" v-model="xtype.contact.fax" />
            </div>
          </li>
        </ul>
      </template>
    </section>
    <div class="form-actions" v-if="editMode">
      <button class="btn btn-large" v-on:click.prevent="saveItem">Save</button>
    </div>
  </form>
  <form class="preq-form" v-else-if="tab==6">
    <section>
      <div class="appl-notes">
        <h4>Application Notes</h4>
        <div class="note-text" v-if="!editMode" v-html="multilineText(item.additionalComments)"></div>
        <textarea v-else v-model="item.additionalComments"></textarea>
      </div>
    </section>
    <div class="form-actions" v-if="editMode">
      <button class="btn btn-large" v-on:click.prevent="saveItem">Save</button>
    </div>
    <messages-api v-if="item.flyid" class="appl-notes" :notes="messagesApi" type="Application" :id="item.id" :application="item" />
    <notes class="appl-notes" :notes="notes" type="Application" :id="item.id" />

  </form>
  <form class="preq-form preq-view" v-if="tab==7"> <!-- Account -->
    <section>
      <ul class="appview-list">
        <li>
          <label>Account Class</label>
          <div class="validator">
            <dyn-select v-if="editMode" :options="classesToShow" v-model="item.classId"></dyn-select>
            <span v-else>{{ item.class && item.class.name }}</span>
          </div>
        </li>
        <li>
          <label>Industry</label>
          <div class="validator">
            <dyn-select v-if="editMode" :options="industries" v-model="item.industry"></dyn-select>
            <span v-else>{{ item.industry }}</span>
          </div>
        </li>
        <li>
          <label>MCC Code</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.mcc }}</span>
            <mcc-edit v-else v-model="item.mcc" />
          </div>
        </li>
        <li>
          <label>Merchant Type</label>
          <div class="validator">
            <dyn-select v-if="editMode" :options="merchantTypes" v-model="item.merchantType"></dyn-select>
            <span v-else>{{ item.merchantType }}</span>
          </div>
        </li>
        <li>
          <label>Batch Close Time</label>
          <div class="validator">
            <span v-if="!editMode">a</span>
            <input v-else type="text" />
          </div>
        </li>
        <li>
          <label>Security PIN</label>
          <div class="validator">
            <span v-if="!editMode">a</span>
            <input v-else type="text" />
          </div>
        </li>
        <li>
          <label>Cash Advance (Select Company)</label>
          <div class="validator">
            <span v-if="!editMode">a</span>
            <input v-else type="text" />
          </div>
        </li>
        <li>
          <label>Platform</label>
          <div class="validator">
            <span v-if="!editMode">a</span>
            <input v-else type="text" />
          </div>
        </li>
        <li>
          <label>Platform Login/Username</label>
          <div class="validator">
            <span v-if="!editMode">a</span>
            <input v-else type="text" />
          </div>
        </li>
        <li>
          <label>TID &amp; KEY Assign &amp; Generate</label>
          <div class="validator">
            <span v-if="!editMode">a</span>
            <input v-else type="text" />
          </div>
        </li>
        <li>
          <label>Alerts Email</label>
          <div class="validator">
            <span v-if="!editMode">a</span>
            <input v-else type="text" />
          </div>
        </li>
        <li>
          <label>CB Notification Email</label>
          <div class="validator">
            <span v-if="!editMode">a</span>
            <input v-else type="text" />
          </div>
        </li>
        <li>
          <label>CS Email</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.emailService }}</span>
            <input v-else type="text" v-model="item.emailService" />
          </div>
        </li>
        <li>
          <label>CS Number</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.phoneService }}</span>
            <input v-else type="text" v-model="item.phoneService" />
          </div>
        </li>
        <li>
          <label>Descriptor</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.descriptor1 }}</span>
            <input v-else type="text" v-model="item.descriptor1" />
          </div>
        </li>
        <li>
          <label>Website</label>
          <div class="validator">
            <span v-if="!editMode">{{ item.website }}</span>
            <input v-else type="text" v-model="item.website" />
          </div>
        </li>
        
      </ul>
    </section>
    <div class="form-actions" v-if="editMode">
      <button class="btn btn-large" v-on:click.prevent="saveItem">Save</button>
    </div>
  </form>
  
  <form class="preq-form preq-view" v-if="tab==8"> <!-- Methods -->
    <section>
      <ul class="appview-list">
        <li>
          <label>Payment Methods &amp; Types</label>
          <div class="validator">
            <span v-if="!editMode">{{ payTypes['CardBiller'].filter((a)=>(merchant.cardTypes || []).indexOf(a)!=-1).join(", ") }}</span>
            <input v-else type="text" />
          </div>
        </li>
        <li>
          <label>Advanced Payment Methods &amp; Types</label>
          <div class="validator">
            <span v-if="!editMode">{{ payTypes['CardBiller Plus'].filter((a)=>(merchant.cardTypes || []).indexOf(a)!=-1).join(", ") }}</span>
            <input v-else type="text" />
          </div>
        </li>
        <li>
          <label>Bank Clearing &amp; Types</label>
          <div class="validator">
            <span v-if="!editMode">{{ payTypes['BankBiller'].filter((a)=>(merchant.cardTypes || []).indexOf(a)!=-1).join(", ") }}</span>
            <input v-else type="text" />
          </div>
        </li>
        <li>
          <label>CoinBiller &amp; Types</label>
          <div class="validator">
            <span v-if="!editMode">{{ payTypes['CoinBiller'].filter((a)=>(merchant.cardTypes || []).indexOf(a)!=-1).join(", ") }}</span>
            <input v-else type="text" />
          </div>
        </li>
      </ul>
    </section>
    <div class="form-actions" v-if="editMode">
      <button class="btn btn-large" v-on:click.prevent="saveItem">Save</button>
    </div>
  </form>
  
  <form class="preq-form preq-view" v-if="tab==9"> <!-- Modules -->
    <section>
      <ul class="appview-list">
        <li>
          <label>InvoBiller</label>
          <div class="validator">
            <span v-if="!editMode">{{ lastAgreement.invoBiller }}</span>
            <input v-else type="text" />
          </div>
        </li>
      </ul>
    </section>
    <div class="form-actions" v-if="editMode">
      <button class="btn btn-large" v-on:click.prevent="saveItem">Save</button>
    </div>
  </form>
  
  <form class="preq-form preq-view" v-if="tab==10"> <!-- Options -->
    <section>
      <ul class="appview-list">
        <li v-for="option in merchantOptions">
          <label>{{ option.name }}</label>
          <div class="validator">
            <span v-if="!editMode">{{ option.count?'Yes':'No' }}</span>
            <input v-else type="checkbox" />
          </div>
        </li>
        <li>
          <label>Chammp KEY # Included and can be changed and can generate new</label>
          <div class="validator">
            <span v-if="!editMode">a</span>
            <input v-else type="text" />
          </div>
        </li>
        <li>
          <label>Chammp TID # Included and can be changed and can generate new</label>
          <div class="validator">
            <span v-if="!editMode">a</span>
            <input v-else type="text" />
          </div>
        </li>
      </ul>
    </section>
    <div class="form-actions" v-if="editMode">
      <button class="btn btn-large" v-on:click.prevent="saveItem">Save</button>
    </div>
  </form>
  
  <form class="appf pfx" v-if="tab==11"> <!-- Equipment -->
    <section v-if="intake" v-for="q in [intake.formData]" class="form-step">

    <h2>EQUIPMENT</h2>
    
    <ul class="column-form">
      <li class="fwidth fwx" v-if="false">
        <label>Special Offers</label>
        <div class="field">
          <validator :value="q.specialOffer&&q.specialOffer.length" reqs="">
            <multiselect-desc v-model="q.specialOffer" :options="['Free Terminal Program']">
              Must ensure that merchant meets program criteria.
            </multiselect-desc>
          </validator>
        </div>
      </li>
      
      <template v-for="ti in terminals">
      <h2 style="font-size: 1.2em">Terminals Type #{{ti}}</h2>
      <li class="">
        <label>Model</label>
        <div class="field complex">
          <validator :value="q['terminal'+ti+'Type']" reqs="">
            <dyn-select  v-model="q['terminal'+ti+'Type']" :options="equipmentOpt" /> <br />
            ${{ (equi(ti)[q['terminal'+ti+'PurchaseOpt']?'purchase':(q['terminal'+ti+'RentalOpt']?'rental':'reprogram')] || 0).toFixed(2) }}
<!--             <input type="text" v-model="q['terminal'+ti+'Type']" /> -->
          </validator>
        </div>
      </li>
      <li class="">
        <label>QTY</label>
        <div class="field complex">
          <validator :value="q['terminal'+ti+'Qty']" reqs="">
            <input type="text" v-model="q['terminal'+ti+'Qty']" />
          </validator>
        </div>
      </li>
      <li class="">
        <label>Purchase</label>
        <div class="field conditional">
          <checkbox-plain2 v-model="q['terminal'+ti+'PurchaseOpt']" v-on:input="$set(q, 'terminal'+ti+'RentalOpt', false); $set(q, 'terminal'+ti+'LeasingOpt', false); $set(q, 'terminal'+ti+'ReprogramOpt', false)" :disabled="!equi(ti).purchase" />
<!--           <span>Qty:</span> -->
<!--           <input type="text" v-model="q['terminal'+ti+'Purchase']" :disabled="!q['terminal'+ti+'PurchaseOpt']" /> -->
        </div>
      </li>
      <li class="">
        <label>Rental</label>
        <div class="field conditional">
          <checkbox-plain2 v-model="q['terminal'+ti+'RentalOpt']" v-on:input="$set(q, 'terminal'+ti+'PurchaseOpt', false); $set(q, 'terminal'+ti+'LeasingOpt', false); $set(q, 'terminal'+ti+'ReprogramOpt', false)" :disabled="!equi(ti).rental" />
<!--           <span>Qty:</span> -->
<!--           <input type="text" v-model="q['terminal'+ti+'Rental']" :disabled="!q['terminal'+ti+'RentalOpt']" /> -->
        </div>
      </li>
      <li class="">
        <label>Reprogram</label>
        <div class="field conditional">
          <checkbox-plain2 v-model="q['terminal'+ti+'ReprogramOpt']" v-on:input="$set(q, 'terminal'+ti+'PurchaseOpt', false); $set(q, 'terminal'+ti+'RentalOpt', false); $set(q, 'terminal'+ti+'LeasingOpt', false)" />
<!--           <span>Qty:</span> -->
<!--           <input type="text" v-model="q['terminal'+ti+'Reprogram']" :disabled="!q['terminal'+ti+'ReprogramOpt']" /> -->
        </div>
      </li>
      <li class="">
        <label>Leasing</label>
        <div class="field conditional">
          <checkbox-plain2 disabled v-model="q['terminal'+ti+'LeasingOpt']" v-on:input="$set(q, 'terminal'+ti+'PurchaseOpt', false); $set(q, 'terminal'+ti+'RentalOpt', false); $set(q, 'terminal'+ti+'ReprogramOpt', false)" />
<!--           <span>Qty:</span> -->
<!--           <input type="text" v-model="q['terminal'+ti+'Leasing']" :disabled="!q['terminal'+ti+'LeasingOpt']" /> -->
        </div>
      </li>
      <li v-if="false" class="" style="width: calc(12% - 5px)">
        <label>Merchant Own</label>
        <div class="field conditional">
          <checkbox-plain2 v-model="q['terminal'+ti+'OwnOpt']" />
          <span>Qty:</span>
          <input type="text" v-model="q['terminal'+ti+'Own']" :disabled="!q['terminal'+ti+'OwnOpt']" />
        </div>
      </li>
      <li v-if="false" class="" style="width: calc(12% - 5px)">
        <label>Financing</label>
        <div class="field conditional">
          <checkbox-plain2 v-model="q['terminal'+ti+'FinancingOpt']" />
          <span>Qty:</span>
          <input type="text" v-model="q['terminal'+ti+'Financing']" :disabled="!q['terminal'+ti+'FinancingOpt']" />
        </div>
      </li>
      <li v-if="false" class="" style="width: calc(12% - 5px)">
        <label>Insurance</label>
        <div class="field conditional">
          <checkbox-plain2 v-model="q['terminal'+ti+'InsuranceOpt']" />
          <span>Qty:</span>
          <input type="text" v-model="q['terminal'+ti+'Insurance']" :disabled="!q['terminal'+ti+'InsuranceOpt']" />
        </div>
      </li>
      </template>
      <li style="width: 100%" v-if="false">
        <a class="terminals-del" href="" v-on:click.prevent="terminalCount--" v-if="terminalCount>1">‒</a>
        <a class="terminals-add" href="" v-on:click.prevent="terminalCount++">+</a>
      </li>
      
      
      <li class="fwidth fwx">
        <label>Communication</label>
        <div class="field">
          <validator :value="q.communicationMethod&&q.communicationMethod.length" reqs="">
            <multiselect v-model="q.communicationMethod" :options="['IP', 'Wireless', 'SIM Card Required', 'Batch Auto Close Time!time']" :time.sync="q.communicationTime">
            </multiselect>
          </validator>
        </div>
      </li>

    </ul>



    </section>
    <div class="form-actions" v-if="editMode">
      <button class="btn btn-large" v-on:click.prevent="saveItem">Save</button>
    </div>
  </form>
  
  <form class="" v-if="tab==12 && intake"> <!-- Pricing -->
    <section>
      <pricing-details :currentTemplate="intake.formData" :solution="item.solution" />
    </section>
    <div class="form-actions" v-if="editMode">
      <button class="btn btn-large" v-on:click.prevent="saveItem">Save</button>
    </div>
  </form>
  
  <form class="appf pfx" v-if="tab==16 && intake"> <!-- Compliance -->
    <section v-if="setup" v-for="item in [setup]" class="form-step">


    <h2>Risk &amp; Compliance Settings</h2>
    <ul class="column-form column-form-compact">
      <li>
        <label>Compliance Review Days</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly v-model="item.merchant.reviewDays" />
          </validator>
        </div>
      </li>
      <li>
        <label>Account Reserve (%)</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly v-model="item.merchant.reserve" />
          </validator>
        </div>
      </li>
      <li>
        <label>Account Reserve (Days)</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly v-model="item.merchant.reserveHold" />
          </validator>
        </div>
      </li>
      <li>
        <label>Settlement Delay (Days)</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly v-model="item.merchant.settlementDays" />
          </validator>
        </div>
      </li>
      <li>
        <label>Fraud Prevention</label>
        <div class="field">
          <validator :value="0" reqs="">
            <dyn-select v-model="item.setupData.fraudPrevention" :options="[]" />
          </validator>
        </div>
      </li>
      <li class="double">
        <label>Billing Descriptor</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input readonly type="text" :value="item.app.descriptor1" />
          </validator>
        </div>
      </li>
      <li class="triple">
        <label>Account Class</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input readonly type="text" :value="item.app.class.name+' '+item.app.class.description" />
          </validator>
        </div>
      </li>
      <li class="quintuple">
        <label>MCC</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input readonly type="text" :value="item.app.mcc" />
          </validator>
        </div>
      </li>
    </ul>


    </section>
    <div class="form-actions" v-if="editMode">
      <button class="btn btn-large" v-on:click.prevent="saveItem">Save</button>
    </div>
  </form>
  
  <form class="appf pfx" v-if="tab==17"> <!-- Compliance -->
    <section v-if="setup" v-for="item in [setup]" class="form-step">


    <h2>Access Information</h2>
    <ul class="column-form column-form-compact">
      <li>
        <label>Portal</label>
        <div class="field">
          <validator :value="0" reqs="">
            <dyn-select v-model="item.setupData.portal" :options="portals" />
          </validator>
        </div>
      </li>
      <li>
        <label>Username</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" v-model="item.setupData.username" />
          </validator>
        </div>
      </li>
      <li>
        <label>Send welcome email</label>
        <div class="field">
          <validator :value="item.setupData.sendEmail" reqs="required">
            <dyn-select v-model="item.setupData.sendEmail" :options="toggleYesNo" />
          </validator>
        </div>
      </li>
      <li>
        <label>PIN</label>
        <div class="field">
          <validator :value="item.setupData.pin" reqs="required">
            <input type="text" readonly v-model="item.setupData.pin" />
            <button v-on:click.prevent="generatePin">Generate PIN</button>
          </validator>
        </div>
      </li>
    </ul>


    </section>
    <div class="form-actions" v-if="editMode">
      <button class="btn btn-large" v-on:click.prevent="saveItem">Save</button>
    </div>
  </form>
  
  <form class="preq-form preq-view" v-if="tab==13"> <!-- Financial -->
    <section>
      
    </section>
    <div class="form-actions" v-if="editMode">
      <button class="btn btn-large" v-on:click.prevent="saveItem">Save</button>
    </div>
  </form>
  
  <form class="appf pfx" v-if="tab==14"> <!-- Processing -->
    <section v-if="setup" v-for="item in [setup]" class="form-step">
    <h2>Connectivity</h2>
    <ul class="column-form column-form-compact">
      <li>
        <label>Gateway</label>
        <div class="field">
          <validator :value="0" reqs="">
            <dyn-select v-model="item.app.gateway" :options="gatewaysList" />
          </validator>
        </div>
      </li>
      <li>
        <label>Processor</label>
        <div class="field">
          <validator :value="0" reqs="">
            <dyn-select v-model="item.app.processor" :options="processorsList" />
          </validator>
        </div>
      </li>
    </ul>
    <h2>Payment Methods</h2>
    <ul class="column-form column-form-compact">
      <li>
        <label>
        <input type="checkbox" v-model="item.setupData.cardPresent" style="margin-right: 4px; vertical-align: middle" />
        Card Present / POS</label>
<!--        <div class="field">
          <validator :value="0" reqs="" style="display: flex">
            <input readonly type="text" :value="item.app.methodPOS" :disabled="!item.setupData.cardPresent" />
          </validator>
        </div>-->
      </li>
      <li style="width: 25%">
        <label>
        <input type="checkbox" v-model="item.setupData.cardNotPresent" style="margin-right: 4px; vertical-align: middle" />
        Card not Present / E-Commerce</label>
<!--        <div class="field">
          <validator :value="0" reqs="" style="display: flex">
            <input readonly type="text" :value="item.app.methodECommerce" :disabled="!item.setupData.cardNotPresent" />
          </validator>
        </div>-->
      </li>
    </ul>
    <h2>Payment Settings</h2>
    <ul class="column-form column-form-compact column-form-compact-3">
      <li>
        <label>Transaction Currencies</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input readonly type="text" :value="item.app.transactionCurrencies" />
          </validator>
        </div>
      </li>
      <li>
        <label>Monthly Transaction Volume Limit</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" readonly :value="item.merchant.monthlyLimit" />
          </validator>
        </div>
      </li>
      <li>
        <label>Annual Transaction Volume Limit</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" :value="item.merchant.annualLimit" />
          </validator>
        </div>
      </li>
    </ul>
    <ul class="column-form column-form-compact column-form-compact-3">
      <li>
        <label>Minimum Transaction Amount</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input type="text" :value="item.setupData.minTransaction" />
          </validator>
        </div>
      </li>
      <li>
        <label>Average Transaction Amount</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input readonly type="text" :value="item.app.avgTransaction" />
          </validator>
        </div>
      </li>
      <li>
        <label>Maximum Transaction Amount</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input readonly type="text" :value="item.app.maxTransaction" />
          </validator>
        </div>
      </li>
    </ul>
    <h2 style="font-size: 1.3em">EFT / ACH</h2>
    <ul class="column-form column-form-compact column-form-compact-3">
      <li>
        <label>Monthly Transaction Volume Limit</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input readonly type="text" :value="item.setupData.eftMonthlyLimit" />
          </validator>
        </div>
      </li>
      <li>
        <label>Annual Transaction Volume Limit</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input readonly type="text" :value="item.setupData.eftAnnualLimit" />
          </validator>
        </div>
      </li>
    </ul>
    <ul class="column-form column-form-compact column-form-compact-3">
      <li>
        <label>Minimum Transaction Amount</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input readonly type="text" :value="item.setupData.eftMinTransaction" />
          </validator>
        </div>
      </li>
      <li>
        <label>Average Transaction Amount</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input readonly type="text" :value="item.setupData.eftAvgTransaction" />
          </validator>
        </div>
      </li>
      <li>
        <label>Maximum Transaction Amount</label>
        <div class="field">
          <validator :value="0" reqs="">
            <input readonly type="text" :value="item.setupData.eftMaxTransaction" />
          </validator>
        </div>
      </li>
    </ul>
    <h2>TIDS &amp; KEYS Settings</h2>
    <ul class="column-form column-form-compact">
<!--      <li>
        <label></label>
        <div class="field">
          <validator :value="0" reqs="">
            <input readonly type="text" :value="item.a" />
          </validator>
        </div>
      </li>-->
    </ul>



      <ul class="appview-list" v-if="false">
        <li>
          <label>Acquirer</label>
          <div class="validator">
            <span v-if="!editMode">a</span>
            <input v-else type="text" />
          </div>
        </li>
        <li>
          <label>Acquirer Merchant ID</label>
          <div class="validator">
            <span v-if="!editMode">a</span>
            <input v-else type="text" />
          </div>
        </li>
        <li>
          <label>Gateway</label>
          <div class="validator">
            <span v-if="!editMode">a</span>
            <input v-else type="text" />
          </div>
        </li>
        <li>
          <label>Gateway Merchant ID</label>
          <div class="validator">
            <span v-if="!editMode">a</span>
            <input v-else type="text" />
          </div>
        </li>
        <li>
          <label>Transaction Currencies</label>
          <div class="validator">
            <span v-if="!editMode">a</span>
            <input v-else type="text" />
          </div>
        </li>
        <li>
          <label>Payment Types Allowed (POS, Mobile, E-Commerce, In-App)</label>
          <div class="validator">
            <span v-if="!editMode">a</span>
            <input v-else type="text" />
          </div>
        </li>
        <li>
          <label>Minimum Transaction Amount</label>
          <div class="validator">
            <span v-if="!editMode">a</span>
            <input v-else type="text" />
          </div>
        </li>
        <li>
          <label>Maximum Transaction Amount</label>
          <div class="validator">
            <span v-if="!editMode">a</span>
            <input v-else type="text" />
          </div>
        </li>
        <li>
          <label>Monthly Processing Limit</label>
          <div class="validator">
            <span v-if="!editMode">a</span>
            <input v-else type="text" />
          </div>
        </li>
        <li>
          <label>Recurring (Yes/No)</label>
          <div class="validator">
            <span v-if="!editMode">a</span>
            <input v-else type="text" />
          </div>
        </li>
        <li>
          <label>Multi-Currency (Yes/No)</label>
          <div class="validator">
            <span v-if="!editMode">a</span>
            <input v-else type="text" />
          </div>
        </li>
      </ul>
    </section>
    <div class="form-actions" v-if="editMode">
      <button class="btn btn-large" v-on:click.prevent="saveItem">Save</button>
    </div>
  </form>
  
  <form class="preq-form preq-view" v-if="tab==15"> <!-- Accounting -->
    <section>
      <ul class="appview-list">
        <li>
          <label>Settlements</label>
          <div class="validator">
            <span v-if="!editMode">a</span>
            <input v-else type="text" />
          </div>
        </li>
        <li>
          <label>Statements</label>
          <div class="validator">
            <span v-if="!editMode">a</span>
            <input v-else type="text" />
          </div>
        </li>
        <li>
          <label>Invoices</label>
          <div class="validator">
            <span v-if="!editMode">a</span>
            <input v-else type="text" />
          </div>
        </li>
        <li>
          <label>Payment History</label>
          <div class="validator">
            <span v-if="!editMode">a</span>
            <input v-else type="text" />
          </div>
        </li>
      </ul>
    </section>
    <div class="form-actions" v-if="editMode">
      <button class="btn btn-large" v-on:click.prevent="saveItem">Save</button>
    </div>
  </form>

  <portal to="actions">
    <ul>
      <li v-if="edit">
        <a href="" v-on:click.prevent="$emit('update:editMode', true)">Edit</a>
      </li>
      <li v-for="(optLabel, optMethod) in statusActions" v-if="tab==999":class="{disabled: activeStatuses.includes(item.status)}">
        <a href="" v-on:click.prevent="!$parent.$parent.doAction(optMethod)" :title="optLabel">{{ optMethod }}</a>
      </li>
    </ul>
  </portal>
</div>
</template>

<script>
import MnbSwitch from './switch.vue'
export default {
  components: {
    MnbSwitch,
  },
  props: ["item", "tab", "notes", "messagesApi", "editMode", "merchant", "lastAgreement", "edit"],
  data() { return {
    portals: [{value: appData.settings.merchantLoginURL, label: appData.settings.merchantLoginURL}],
    toggleYesNo: [{label: "Yes", value: 1}, {label: "No", value: 0}],
    industries: [
      {value: "Hospitality", label: "Hospitality"},
      {value: "Retail", label: "Retail"},
      {value: "Spa/Salon/Gym", label: "Spa/Salon/Gym"},
      {value: "Gas & Oil", label: "Gas & Oil"},
      {value: "E-Commerce", label: "E-Commerce"},
      {value: "Professional Services", label: "Professional Services"},
      {value: "Convenience & Supermarket", label: "Convenience & Supermarket"},
      {value: "Tourism", label: "Tourism"},
      {value: "Community Groups & Orgs", label: "Community Groups & Orgs"},
      {value: "Business to Business", label: "Business to Business"},
      {value: "Taxi & Delivery", label: "Taxi & Delivery"},
      {value: "Utilities", label: "Utilities"},
      {value: "Other", label: "Other"},
    ],
    currencies: [
      {value: "AUD", label: "AUD"},
      {value: "BRL", label: "BRL"},
      {value: "CAD", label: "CAD"},
      {value: "CHF", label: "CHF"},
      {value: "EUR", label: "EUR"},
      {value: "GBP", label: "GBP"},
      {value: "HKD", label: "HKD"},
      {value: "JPY", label: "JPY"},
      {value: "NZD", label: "NZD"},
      {value: "USD", label: "USD"},
    ],
    facilitatorTypes: [
      {value: "full", label: "Full Service"},
      {value: "partial", label: "Partial"},
      {value: "pass", label: "Pass-Through"},
    ],
    ctypes: [
      {type: "general", emph: true, title: "General Contact"},
      {type: "technical", emph: false, title: "Technical Contact"},
      {type: "financial", emph: false, title: "Financial Contact"},
      {type: "risk", emph: false, title: "Risk / Chargeback Contact"},
    ],
    accountTypes: [
      {value: "master", label: "Payfac"},
      {value: "merchant", label: "Merchant"},
      {value: "sub", label: "Sub-Account"},
    ],
    languages: [
      {value: "English", label: "English"},
    ],
    solutions: [
      {value: "cardbiller-ca", label: "CardBiller - CA"},
    ],
    merchantTypes: [
      {value: "POS", label: "Card Present - POS"},
      {value: "E-Commerce", label: "Card not Present - E-Commerce"},
//       {value: "Hybrid", label: "Hybrid"},
    ],
    agents: appData.agents || [],
    managers: [],
    classes: [],
    referrers: [],
    facilitators: [],
    now: 0,
    payTypes: {
      CardBiller: ['Visa', 'Mastercard', 'Discover', 'AMEX', 'China UnionPay'],
      'CardBiller Plus': ['Apple Pay', 'Google Pay', 'Interac Online'],
      BankBiller: ['EFT', 'ACH'],
      CoinBiller: ['Bitcoin'],
    },
    options: [],
    intake: null,
    setup: null,
    gatewaysList: [],
    processorsList: [],
    equipment: [],
    quantities: [],
    terminalCount: 1,
    statusActions: {
      "A08": "Active",
      "A09": "Review",
      "A10": "Restricted",
      "A11": "Dormant",
      "A12": "Closed",
    },
    activeStatuses: [
      "A02",
      "A03",
      "A04",
      "A05",
    ],
    itemSolution: null,
  }},
  computed: {
    merchantOptions() {
      var result=[];
      for(var i in this.options) {
        var option=JSON.parse(JSON.stringify(this.options[i]));
        for(var j in this.lastAgreement.options) {
          var opt=this.lastAgreement.options[j];
          if (opt.id==option.id)
            option.count=true;
        }
        result.push(option);
      }
      return result;
    },
    accountReview() {
      if (this.merchant && this.merchant.reviewDate) {
        var p=moment(this.merchant.reviewDate);
        p.add(serverDiff, "seconds");
        var diff=moment.duration(p.diff(moment())).asDays();
        if (diff<0)
          return "0";
        return Math.floor(diff);
      }
      return "---";
    },
    computedStatus() {
      return this.merchant?this.merchant.status:this.item.status;
    },
    typesWithContact() {
      var that=this;
      return this.ctypes.map(function(a) {
        a.contact=that.findContact(a.type);
        return a;
      });
    },
    agentsToShow() {
      return this.agents.map(function(a) {
        var name=a.fullName;
        if (!name && a.contacts && a.contacts[0]) {
          name=a.contacts[0].firstName+" "+a.contacts[0].lastName;
        }
        var cn=a.companyName || null;
        if (!cn) {
          
        }
        return {
          value: a.id,
          label: (a.agentId || "")+" - "+name+" - "+cn,
        };
      });
    },
    classesToShow() {
      return this.classes.map(function(a) {
        return {
          value: a.id,
          label: a.name+" "+a.description,
        };
      });
    },
    managersToShow() {
      var items=this.managers.map(function(a) {
        return {
          value: a.id,
          label: a.username,
        };
      });
      items.unshift({
        value: null,
        label: "(no manager)",
      });
      return items;
    },
    referrersToShow() {
      var items=this.referrers.map(function(a) {
        return {
          value: a.id,
          label: a.username,
        };
      });
      items.unshift({
        value: null,
        label: "(no referrer)",
      });
      return items;
    },
    equipmentOpt() {
      return this.equipment.map(a=>({
        value: a.id,
        label: a.name,
        purchase: a.purchase*1 || 0,
        rental: a.rental*1 || 0,
        reprogram: a.reprogram*1 || 0,
      }));
    },
    terminals() {
      var result=[];
      for(var i=0;i<this.terminalCount;i++)
        result.push(i+1);
      return result;
    },
    actualParent() {
      return this.$parent.item?this.$parent:this.$parent.$parent;
    },

    validCountries() {
      var countries = [];
      var lists = [];
      if(this.itemSolution.businessFormation) {
        lists = window.fullCountries.filter(a => {
          return this.itemSolution.businessFormation.includes(a.id)
        })
      }

      lists.forEach(item => {
        countries.push({
          label: item.text,
          value: item.id
        })
      })

      return countries
    },

    validContactCountries() {
      var countries = [];
      var lists = [];
      if(this.itemSolution.contacts) {
        lists = window.fullCountries.filter(a => {
          return this.itemSolution.contacts.includes(a.id)
        })
      }

      lists.forEach(item => {
        countries.push({
          label: item.text,
          value: item.id
        })
      })

      return countries
    }
  },
  watch: {
    "item.source": {
      handler() {
        this.$set(this.actualParent.item, "sourceId", this.item.source && this.item.source.id);
      },
      immediate: true,
    },
    "item.class": {
      handler() {
        this.$set(this.actualParent.item, "classId", this.item.class && this.item.class.id);
      },
      immediate: true,
    },
    "item.manager": {
      handler() {
        this.$set(this.actualParent.item, "managerId", this.item.manager && this.item.manager.id);
      },
      immediate: true,
    },
    "item.referrer": {
      handler() {
        this.$set(this.actualParent.item, "referrerId", this.item.referrer && this.item.referrer.id);
      },
      immediate: true,
    },
    "item.id": {
      handler() {
        if (this.item.id)
          this.loadIntake();
          this.loadSetup();
      },
      immediate: true,
    }
  },
  async mounted() {
    setInterval(()=>{ this.now++; }, 1000);
    this.loadOptions();
    this.loadSolution();
//     this.loadIntake();
    var appsolutions = (await api.list("Solution", {solutionType: 'Merchants'}, ['id:ASC'])).items || [];
    this.solutions=appsolutions.map(function(a) {
      return {value: a.id, label: a.nameSolution};
    });
    this.classes = (await api.list("AccountClass")).items || [];
    this.managers = (await api.list("User", {groups: 'manager-account', deleted :['<>', 'Yes']}, ['id:ASC'])).items || [];
    this.referrers = (await api.list("User", {groups: 'merchant'}, ['id:ASC'])).items || [];
    var appfacilitators = (await api.list("Application", {auxType: 'Facilitator'}, ['id:ASC'])).items || [];
    this.facilitators=appfacilitators.map(function(a) {
      return {value: a.id, label: a.companyName};
    });
  },
  methods: {
    equi(index) {
      return this.equipmentOpt.find((a)=>a.value==this.intake.formData['terminal'+index+'Type']) || {};
    },
    async loadIntake() {
      var intake=(await api.list("Intake", {appId: this.item.id}, ["id:DESC"])).items[0];
      this.intake=intake;
    },
    async loadSetup() {
      var setup=(await api.list("MerchantSetup", {appId: this.item.id}, ["id:DESC"])).items[0];
      this.setup=setup;
      var providers=(await api.list("Provider")).items;
      this.gatewaysList=providers.filter(a=>a.providerType=='Gateway').map(a=>({
        label: a.providerName,
        value: a.id
      }));
      this.processorsList=providers.filter(a=>a.providerType=='Processor').map(a=>({
        label: a.providerName,
        value: a.id
      }));
      this.equipment=(await api.list("Equipment", {enabled: 1})).items || [];
      for(var i in this.equipment)
        this.quantities[i]=0;
    },
    loadOptions: async function() {
      this.options=(await api.list("CompanyOption")).items || [];
    },
    findContact(type) {
      for(var i in this.item.contacts)
        if (this.item.contacts[i].type==type)
          return this.item.contacts[i];
      return {};
    },
    currency(code) {
      for(var currency of this.currencies)
        if (code==currency.value)
          return currency.label;
      return "";
    },
    facilitator(id) {
      for(var facilitator of this.facilitators)
        if (id*1==facilitator.value*1)
          return facilitator.label;
      return "";
    },
    solution(id) {
      for(var solution of this.solutions)
        if (id*1==solution.value*1)
          return solution.label;
      return "";
    },
    facilitatorType(id) {
      for(var facilitatorType of this.facilitatorTypes)
        if (id==facilitatorType.value)
          return facilitatorType.label;
      return "";
    },
    accountType(id) {
      for(var accountType of this.accountTypes)
        if (id==accountType.value)
          return accountType.label;
      return "";
    },
    merchantType(id) {
      for(var merchantType of this.merchantTypes)
        if (id==merchantType.value)
          return merchantType.label;
      return "";
    },
    multilineText(text) {
      return (""+text).replace(/\</g, "&lt;")
                      .replace(/\>/g, "&gt;")
                      .replace(/\"/g, "&quot;")
                      .replace(/\&/g, "&amp;")
                      .replace(/\n/g, "<br />\n");
    },
    async statusDesc(status) {
      var result = (await api.list('Status', {})).items;
      for(var i in result) {
        var xstatus=result;
        if (xstatus.status==status)
          return xstatus.description;
      }
      return "";
    },
    saveItem: async function() {
      startLoading();
      var updateItem=JSON.parse(JSON.stringify(this.item));
      updateItem.source=updateItem.sourceId;
      delete updateItem.sourceId;
      updateItem.manager=updateItem.managerId;
      delete updateItem.managerId;
      updateItem.class=updateItem.classId;
      delete updateItem.classId;
      updateItem.referrer=updateItem.referrerId;
      delete updateItem.referrerId;
      await api.update("Application", this.item.id, updateItem);
      if (this.merchant) {
        var merchantItem=JSON.parse(JSON.stringify(this.merchant));
        delete merchantItem.app;
        await api.update("Merchant", this.merchant.id, merchantItem);
      }
      stopLoading("Application updated");
      this.actualParent.item=(await api.get("Application", this.item.id)).item;
      this.$emit("update:editMode", false);
      this.actualParent.updateNotes();
    },

    async loadSolution () {
      this.itemSolution = (await api.get("Solution", this.item.solution)).item
    },
  },
  mixins: [vueMixins.dateFormatter],
}
</script>
<style scoped>
  .column6 li {
    width: 16%;
  }
</style>
