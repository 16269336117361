<template>
<div class="ph-prev">
  <div class="ph-pr-in">
    <div v-if="value && !changed" class="org"><img :src="basepath+'/'+value" /></div>
    <div v-else-if="value && changed" class="chg"><img :src="bloburl" /></div>
  </div>
  <div class="file-obj">
    <a v-if="!value">(no file)</a>
    <a v-else-if="changed">{{ fileName }}</a>
    <a v-else target="_blank" :href="basepath+'/'+value">{{ fileName }}</a>
    <a class="btn-upload">
      +
      <input type="file" v-on:change="setFile" />
    </a>
    <a class="btn-remove" v-if="value" v-on:click="removeFile">&times;</a>
  </div>
</div>
</template>

<script>
export default {
  props: ["value", "basepath"],
  computed: {
    fileName() {
      return this.value.name || this.value;
    },
  },
  data() { return {
    changed: false,
    bloburl: null,
  }},
  methods: {
    setFile($e) {
      this.changed=true;
      var file=$e.target.files[0];
      this.$emit("input", file);
      this.bloburl=URL.createObjectURL(file);
    },
    removeFile($e) {
      this.changed=true;
      this.$emit("input", null);
    },
  },
}
</script>
