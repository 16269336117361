<template>

<form class="edit-system search-form company-branding">
  <div>
    <ul class="breadcrumbs">
      <li><a>Organization Branding</a></li>
    </ul>
  </div>
  <div class="twocol">
    <div>
      <div class="field">
        <label>System Name:</label>
        <input v-model="form.systemName" />
      </div>
      <h1>HQ Management Portal</h1>
      <div class="field">
        <label>Login URL:</label>
        <input v-model="form.loginURL" />
      </div>
      <div class="field">
        <label>Login Logo:</label>
        <photo-obj :basepath="basePath" v-model="files.loginLogo" />
      </div>
      <div class="field">
        <label>Login Text:</label>
        <input v-model="form.loginText" />
      </div>
      <div class="field">
        <label>Main Logo:</label>
        <photo-obj :basepath="basePath" v-model="files.logo" />
      </div>
      <div class="field">
        <label>Favicon:</label>
        <photo-obj :basepath="basePath" v-model="files.favicon" />
      </div>
    </div>
    <div>
      <div class="field">
        <label> &nbsp;</label>
      </div>
      <h1>Footer</h1>
      <div class="field">
        <label>Logo:</label>
        <photo-obj :basepath="basePath" v-model="files.footerLogo" />
      </div>
      <div class="field">
        <label>Text:</label>
        <input v-model="form.footerText" />
      </div>
    </div>
  </div>
  <div class="twocol">
    <div>
      <h1>Partner Portal</h1>
      <div class="field">
        <label>Login URL:</label>
        <input v-model="form.partnerLoginURL" />
      </div>
      <div class="field">
        <label>Login Logo:</label>
        <photo-obj :basepath="basePath" v-model="files.partnerLoginLogo" />
      </div>
      <div class="field">
        <label>Login Text:</label>
        <input v-model="form.partnerLoginText" />
      </div>
      <div class="field">
        <label>Main Logo:</label>
        <photo-obj :basepath="basePath" v-model="files.partnerLogo" />
      </div>
      <div class="field">
        <label>Favicon:</label>
        <photo-obj :basepath="basePath" v-model="files.partnerFavicon" />
      </div>
    </div>
    <div>
      <h1>Merchant Portal</h1>
      <div class="field">
        <label>Login URL:</label>
        <input v-model="form.merchantLoginURL" />
      </div>
      <div class="field">
        <label>Login Logo:</label>
        <photo-obj :basepath="basePath" v-model="files.merchantLoginLogo" />
      </div>
      <div class="field">
        <label>Login Text:</label>
        <input v-model="form.merchantLoginText" />
      </div>
      <div class="field">
        <label>Main Logo:</label>
        <photo-obj :basepath="basePath" v-model="files.merchantLogo" />
      </div>
      <div class="field">
        <label>Favicon:</label>
        <photo-obj :basepath="basePath" v-model="files.merchantFavicon" />
      </div>
    </div>
  </div>
  <div class="twocol">
    <div>
      <h1>Dashboard Portal</h1>
      <div class="field">
        <label>Login URL:</label>
        <input v-model="form.dashboardLoginURL" />
      </div>
      <div class="field">
        <label>Login Logo:</label>
        <photo-obj :basepath="basePath" v-model="files.dashboardLoginLogo" />
      </div>
      <div class="field">
        <label>Login Text:</label>
        <input v-model="form.dashboardLoginText" />
      </div>
      <div class="field">
        <label>Main Logo:</label>
        <photo-obj :basepath="basePath" v-model="files.dashboardLogo" />
      </div>
      <div class="field">
        <label>Favicon:</label>
        <photo-obj :basepath="basePath" v-model="files.dashboardFavicon" />
      </div>
    </div>
    <div>
      <h1>Sandbox</h1>
      <div class="field">
        <label>Login URL:</label>
        <input v-model="form.sandboxLoginURL" />
      </div>
      <div class="field">
        <label>Login Logo:</label>
        <photo-obj :basepath="basePath" v-model="files.sandboxLoginLogo" />
      </div>
      <div class="field">
        <label>Login Text:</label>
        <input v-model="form.sandboxLoginText" />
      </div>
      <div class="field">
        <label>Main Logo:</label>
        <photo-obj :basepath="basePath" v-model="files.sandboxLogo" />
      </div>
      <div class="field">
        <label>Favicon:</label>
        <photo-obj :basepath="basePath" v-model="files.sandboxFavicon" />
      </div>
    </div>
  </div>
  <div class="buttons">
    <button class="btn btn-large btn-primary" v-on:click.prevent="updateSettings">Submit</button>
  </div>  
</form>


</template>

<script>
export default {
  data: ()=>({
    files: {
      loginLogo: null,
      logo: null,
      footerLogo: null,
      partnerLoginLogo: null,
      partnerLogo: null,
      merchantLoginLogo: null,
      merchantLogo: null,
      favicon: null,
      merchantFavicon: null,
      partnerFavicon: null,
      dashboardLoginLogo: null,
      dashboardLogo: null,
      dashboardFavicon: null,
      sandboxLoginLogo: null,
      sandboxLogo: null,
      sandboxFavicon: null,
    },
    form: {
      loginLogo: null,
      loginText: '',
      logo: null,
      footerLogo: null,
      footerText: '',
      partnerLoginLogo: null,
      partnerLoginText: '',
      partnerLogo: null,
      merchantLoginLogo: null,
      merchantLoginText: '',
      merchantLogo: null,
      loginURL: null,
      partnerLoginURL: null,
      merchantLoginURL: null,
      systemName: null,
      dashboardLoginURL: null,
      dashboardLoginText: null,
      sandboxLoginURL: null,
      sandboxLoginText: null,
    },
    opts: {
      pin: '',
    },
    basePath: '',
  }),
  mounted() {
    this.refresh2();
  },
  methods: {
    async refresh2() {
      var data=await api.get("Settings", 1);
      this.form=data.item;
      this.basePath=data.desc.logo.basepath;
      for(var field in this.files)
        this.files[field]=this.form[field];
    },
    async updateSettings() {
      startLoading();
      var toUpdate={
        loginText: this.form.loginText,
        footerText: this.form.footerText,
        partnerLoginText: this.form.partnerLoginText,
        merchantLoginText: this.form.merchantLoginText,
        dashboardLoginText: this.form.dashboardLoginText,
        sandboxLoginText: this.form.sandboxLoginText,
        loginURL: this.form.loginURL,
        partnerLoginURL: this.form.partnerLoginURL,
        merchantLoginURL: this.form.merchantLoginURL,
        dashboardLoginURL: this.form.dashboardLoginURL,
        sandboxLoginURL: this.form.sandboxLoginURL,
        systemName: this.form.systemName,
      };
      for(var field in this.files) {
        if (this.files[field] instanceof File) {
          if (!window.validateFileExt(this.files[field]))
            return stopLoading("Invalid file extension", "error");
          await api.upload("Settings", 1, this.files[field], {field});
        }
      }
      for(var field in this.files)
        if (this.files[field]===null)
          toUpdate[field]=null;
      await api.update("Settings", 1, toUpdate);
      stopLoading("Settings updated");
      await this.refresh2();
    },
  },
}
</script>
