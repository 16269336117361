<template>
  <div class="company-select">
    <dyn-select :options="types" :value="type" v-on:input="$emit('update:type', $event)" />
    <dyn-select v-if="!isNew" :options="itemsToSelect" :value="value" v-on:input="selectCompany" />
  </div>
</template>


<script>
export default {
  props: ["isNew", "value", "type"],
  data() { return {
    items: [],
    types: [
      {value: 'Employees', label: 'Employees'},
      {value: 'Merchants', label: 'Payments'},
      {value: 'Banking', label: 'Treasury'},
      {value: 'Partners', label: 'Partners'},
    ],
  }},
  watch: {
    value() {
      this.refresh();
    },
    type() {
      if (!this.isNew) {
        if (this.itemsToSelect.length) {
          this.$emit("input", this.itemsToSelect[0].value);
        } else {
          this.$emit("input", null);
        }
      }
    },
  },
  computed: {
    itemsToSelect() {
      var items=this.items.filter((a)=>a.type==this.type);
      return items;
    },
  },
  methods: {
    selectCompany($event) {
      this.$emit("input", $event);
    },
    refresh: async function() {
      var items=(await api.list("Solution", {}, ["id:ASC"])).items;
      this.items=[];
      for(var item of items)
        this.items.push({value: item.id, label: item.nameSolution, type: item.solutionType});
      if (!this.isNew && this.itemsToSelect.length && !this.value) {
        this.$emit("input", this.itemsToSelect[0].value);
      }
    },
  },
  mounted() {
    window.companySelect=this;
    this.refresh();
  },
}
</script>

