<template>
<div>
  <div class="content-table-container">
    <table class="content-table">
      <thead>
        <tr>
          <th style="width: 9%">MCC code</th>
          <th style="width: 17%">Category</th>
          <th style="width: 30%">Description</th>
          <th style="width: 15%">Classification</th>
          <th style="width: 15%">Designation</th>
          <th style="width: 30%">Note</th>
          <th style="width: 1%">&nbsp;</th>
        </tr>
        <tr>
          <th class="spacer" colspan="4"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items">
          <td class="text-center main-col">
            <input type="text" v-model="item.status" />
          </td>
          <td class="text-center">
            <combo-select v-model="item.category" :options="categories" />
          </td>
          <td class="text-left">
            <input type="text" v-model="item.description" />
          </td>
          <td class="text-left">
            <dyn-select :options="classifications" v-model="item.classification" />
          </td>
          <td class="text-left">
            <dyn-select :options="designations" v-model="item.designation" />
          </td>
          <td class="text-left">
            <input type="text" v-model="item.note" />
          </td>
          <td>
            <a class="remove" href="" v-on:click.prevent="deleteItem(index)">&times;</a>
          </td>
        </tr>
        <tr class="new-row">
          <td colspan="7">
            <a class="add" href="" v-on:click.prevent="newItem">+</a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="buttons">
    <button class="btn btn-large btn-primary" v-on:click.prevent="updateStatusCodes">Submit</button>
  </div>
</div>
</template>

<script>
export default {
  data() { return {
    items: [],
    origItems: [],
    categories: [],
    designations: [
      {value: "", label: ""},
      {value: "Low Risk", label: "Low Risk"},
      {value: "Low Risk +", label: "Low Risk +"},
      {value: "Mid Risk", label: "Mid Risk"},
      {value: "High Risk", label: "High Risk"},
      {value: "High Risk +", label: "High Risk +"},
    ],
    classifications: [
      {value: "", label: ""},
      {value: "Restricted", label: "Restricted"},
      {value: "Prohibited", label: "Prohibited"},
    ],
  }},
  methods: {
    reload() {
      var that=this;
      api.list("MCCCode", {}, ["`status`:ASC"]).then(function(data) {
        that.origItems=JSON.parse(JSON.stringify(data.items));
        that.items=JSON.parse(JSON.stringify(data.items));
        that.categories=[];
        for(var i in that.items) {
          var item=that.items[i];
          if (that.categories.indexOf(item.category)==-1)
            that.categories.push(item.category);
        }
        that.categories.sort();
      });
    },
    deleteItem(index) {
      var that=this;
      if (confirm("Are you sure?")) {
        api.list("Application", {status: this.items[index].status}, ["id:ASC"], 1).then(function(data) {
          if (data.items.length)
            alert("Can't delete status: used by application or merchant");
          else {
            that.items.splice(index, 1);
          }
        });
      }
    },
    newItem() {
      this.items.push({
        status: "",
        category: "",
        description: "",
      });
    },
    validate() {
      var statuses={};
      for(var i in this.items) {
        this.items[i].status=(this.items[i].status || "").toUpperCase();
        if (statuses[this.items[i].status])
          return false;
        statuses[this.items[i].status]=true;
      }
      
      return true;
    },
    updateStatusCodes() {
      if (!this.validate()) {
        stopLoading("Duplicate codes detected", "error");
        return false;
      }
      var itemsToDelete=[];
      for(var index in this.origItems) {
        var item=this.origItems[index];
        if (!this.items.find(function(e) { return e.id==item.id; }))
          itemsToDelete.push(item);
      }
      var itemsToAdd=[];
      var itemsToUpdate=[];
      for(var index in this.items) {
        var item=this.items[index];
        var foundItem=this.origItems.find(function(e) { return e.id==item.id; });
        if (!foundItem)
          itemsToAdd.push(item);
        else if (JSON.stringify(foundItem)!=JSON.stringify(item))
          itemsToUpdate.push(item);
      }
      this.updateStatusApi(itemsToUpdate, itemsToAdd, itemsToDelete);
      this.origItems=JSON.parse(JSON.stringify(this.items));
    },
    updateStatusApi: async function(update, add, remove) {
      startLoading();
      for(var i in update) {
        var item=update[i];
        await api.update("MCCCode", item.id, item);
      }
      for(var i in add) {
        var item=add[i];
        await api.create("MCCCode", item);
      }
      for(var i in remove) {
        var item=remove[i];
        await api.delete("MCCCode", item.id);
      }
      stopLoading("Updated status codes");
    },
  },
  mounted() {
    this.reload();
  },
}
</script>

